export interface MaritalStatusResponse {
    id: number;
    name: string;
}

export class MaritalStatus {
    id: number;
    name: string;

    static fromResponse(response: MaritalStatusResponse): MaritalStatus {
        const maritalStatus = new MaritalStatus();
        maritalStatus.id = response.id;
        maritalStatus.name = response.name;
        return maritalStatus;
    }
}
