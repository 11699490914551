<app-group-section-base
  [templateRef]="additionalIncomeGroupFreelance"
  [isActive]="isMainStepActive"
  [statementFullYear]="additionalIncomeService.mainStatement?.fullYear"
  [selectedCounter]="selectedCounter"
  [selectButtons]="selectButtons"
  [title]="groupTitle"
  [description]="description"
  [titleTooltip]="'ADDITIONAL_INCOME.PENSION_GROUP.TOOLTIP.TITLE' | translate"
  [messageTooltip]="'ADDITIONAL_INCOME.PENSION_GROUP.TOOLTIP.MESSAGE' | translate"
>
</app-group-section-base>

<ng-template #additionalIncomeGroupFreelance>
  <ng-container *ngIf="subSteps">
    <ng-container *ngIf="isSubStepActive(pensionSteps.PENSION)">
      <app-group-section-edit-single-base
        [templateRef]="pension"
        [overTitle]="groupTitle"
        [title]="'ADDITIONAL_INCOME.PENSION_GROUP.PENSION.TITLE'"
        [description]="'ADDITIONAL_INCOME.PENSION_GROUP.PENSION.DESCRIPTION'"
      >
      </app-group-section-edit-single-base>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #pension>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.PENSION_GROUP.PENSION.TITLE'"
    [formTemplateRef]="inputs"
    [list]="pensionIncomeManager.getPension(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      pensionIncomeManager.getPension(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.pensionIncomeLimit
    "
    (removeButtonClickEmitter)="pensionIncomeManager.removePension(additionalIncomeService.currentStatement, $event)"
    (addButtonClickEmitter)="pensionIncomeManager.createPension(additionalIncomeService.currentStatement)"
  ></app-form-list>
</ng-template>

<ng-template #inputs let-item="item" let-index="index">
  <app-additional-income-form-pension [pensionIncome]="item" [index]="index"></app-additional-income-form-pension>
</ng-template>
