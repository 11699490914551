import {Action} from '@ngrx/store';
import {User} from 'app/users';

export const LOAD_USER_DATA = '[User] Data Load';
export const REMOVE_USER_DATA = '[User] Data Remove';

export class LoadUserDataAction implements Action {
  readonly type = LOAD_USER_DATA;

  constructor(public payload: User) { }
}

export class RemoveUserDataAction implements Action {
  readonly type = REMOVE_USER_DATA;

  constructor(public payload: any) { }
}

export type Actions = LoadUserDataAction | RemoveUserDataAction;
