<ng-container *ngIf="!contentLoaded">
    <app-content-loader></app-content-loader>
</ng-container>
<ng-container *ngIf="contentLoaded">
    <div class="vollmacht-authorization columns is-desktop step-container">
        <!--aside-bar start-->
        <app-aside-bar [showEvenWithoutStatement]=true [statement]="statement"></app-aside-bar>
        <!--aside-bar end-->
        <div class="vollmacht">
            <h1 class="title_42">
                {{ 'VOLLMACHTAUTHORIZATION.TITLE' | translate }}
            </h1>
            <P class="subdesc">
                {{ 'VOLLMACHTAUTHORIZATION.DESCRIPTION' | translate }}
            </P>
            <div class="vollmacht__content">
                <div class="vollmacht__regulations_wrap">
                    <h2 class="title_24">
                        {{ 'VOLLMACHTAUTHORIZATION.REGULATIONS_TITLE' | translate }}
                    </h2>
                    <div class="desc_wrap">
                        <p>{{ 'VOLLMACHTAUTHORIZATION.DECLARE_1' | translate }}</p>
                        <p>{{ 'VOLLMACHTAUTHORIZATION.DECLARE_2' | translate }}</p>
                        <p>{{ 'VOLLMACHTAUTHORIZATION.DECLARE_3' | translate }}</p>
                    </div>
                </div>

                <div class="vollmacht-accept_wrap">
                    <div class="regulations_wrap_accept">
                        <div class="lhs">{{ 'VOLLMACHTAUTHORIZATION.SEEMORE' | translate }}</div>
                        <div class="rhs">
                            <div class="link_wrap">
                                <a class="link is-bold" (click)="downloadPdf()">{{ 'VOLLMACHTAUTHORIZATION.POWER_OF_ATTORNEY_DOCUMENT' | translate }}</a>
                            </div>
                            <div class="link_wrap">
                                <a class="link is-bold" (click)="displaypopup()">{{ 'VOLLMACHTAUTHORIZATION.TAX_ADVISOR_AGREEMENT' | translate }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="accept_wrap" *ngIf="statement">
                        <mat-checkbox
                          [checked]="acceptThePowerOfAttorney"
                          (change)="clickedAcceptAtthorney()"
                          [attr.data-cy]="'checkboxAcceptThePowerOfAttorney'"
                        >
                        </mat-checkbox>
                        <div class="label_wrap">
                            {{'VOLLMACHTAUTHORIZATION.CONFIRM' | translate }}
                            <span id="downloadPdfButton" (click)="downloadPdf()">
                                {{ 'VOLLMACHTAUTHORIZATION.POWER_OF_ATTORNEY' | translate }}
                            </span>
                            <span (click)="displaypopup()" class="simulate_url">{{'VOLLMACHTAUTHORIZATION.ACCEPTS_THE_POWER_OF_ATTORNEY_URL' | translate }}</span>
                            {{'VOLLMACHTAUTHORIZATION.CONFIRM_END' | translate }}
                        </div>
                    </div>
<!--
                    <div class="accept_wrap"
                        *ngIf="statement && !statement.canSendWithoutResignationFromClaim">
                        <mat-checkbox [checked]="isResigningFromClaim" (change)="clickedCheckbox()">
                        </mat-checkbox>
                        <div class="label_wrap"> {{'VOLLMACHTAUTHORIZATION.REAL_DATA_ACCEPT' | translate }}
                        </div>
                    </div>-->
                </div>
                <div class="alert-box" *ngIf="showAlert">{{ 'VOLLMACHTAUTHORIZATION.DOWNLOAD' | translate }}</div>

                <!-- <div class="sepa_wrap" *ngIf="showSepa">
                    <div class="sepa_desc_wrap">
                        <p class="sepa_desc">
                            {{ 'VOLLMACHTAUTHORIZATION.SEPA_TITLE' | translate }}
                        </p>
                    </div>
                    <img class="sepa_img visible-xs" src="../../../assets/img/lastschriftlogo-qf-en_rgb.png">
                    <div class="inputs_cont">
                        <div class="inputs_wrap">
                            <div class="single_input_wrap">
                                <input [attr.disabled]="true" class="sepa_input" [(ngModel)]="sepaFirstName"
                                    max_length="255" placeholder="{{ 'INPUT.FIRST_NAME.PLACEHOLDER' | translate }}"
                                    #sepaFN>
                            </div>
                            <div class="single_input_wrap">
                                <input [attr.disabled]="true" class="sepa_input" [(ngModel)]="sepaLastName"
                                    max_length="255" placeholder="{{ 'INPUT.LAST_NAME.PLACEHOLDER' | translate }}"
                                    #sepaLN>
                            </div>
                            <div class="single_input_wrap">
                                <input [attr.disabled]="true" class="sepa_input nrcard" [(ngModel)]="sepaBankAccount"
                                    max_length="34" placeholder="{{ 'INPUT.IBAN.PLACEHOLDER' | translate }}" #sepaNr>
                            </div>
                        </div>
                        <div class="sepa_mandate">
                            <p>{{ 'CHECKOUT.SEPA_STB_MANDATE' | translate }}</p>
                        </div>
                        <div class="alert-box" *ngIf="showDisabledError">
                            {{ 'CHECKOUT.SEPA_NOT_SAVED' | translate }}</div>
                        <img class="regulations_img" src="../../../assets/svg/va_sepa_icon.svg">
                    </div>
                    <img class="sepa_img hidden-xs" src="../../../assets/img/lastschriftlogo-qf-en_rgb.png">
                </div> -->
            </div>
        </div>
        <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
    </div>
</ng-container>
