import { Component, OnInit, Input } from '@angular/core';
import { Statement } from 'app/statements';
import { StatementService } from 'app/statements/statement.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SubscribingComponent, SnackbarConfig } from '../../common';
import { takeUntil } from 'rxjs/operators';
import { UserDataService, User } from 'app/users';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-phone-number-dialog',
  templateUrl: './phone-number-dialog.component.html',
  styleUrls: ['./phone-number-dialog.component.scss']
})
export class PhoneNumberDialogComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  @Input() toggleField;
  errors:any = [];
  public userData: User;
  constructor(private statementService: StatementService,
    private userDataService: UserDataService,
    private dialogRef: MatDialogRef<PhoneNumberDialogComponent>,
    private translate: TranslateService) { super(); }

  ngOnInit() {
    this.userDataService.getUserData().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.userData = user;
      });
  }

  public save() {
    if (this.statement.emailAddress && this.statement.phoneNumber) {
      const toResponse = this.statement.emailPhoneToResponse();
      const spouse = this.statement.spouse;
      if (spouse) {
        Object.assign(toResponse, { spouses: [{ id: spouse.id }] });
      }
      this.statementService.updateStatement(this.statement.id, toResponse).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.userData.phoneNumber = this.statement.phoneNumber;
          this.userDataService.updateUserData(this.userData)
            .subscribe({
              next: () => {
                this.dialogRef.close();
              }, error: (errors: any) => {
                this.errors = errors.error;
              }
            });
        }, error: (errors: any) => {
          this.errors = errors.error;
        }
      })
    } else {
      let field_error = this.translate.instant("COMMON.FIELD_REQUIRED");
      if (!this.statement.phoneNumber){
        this.errors.phone_number = [];
        this.errors.phone_number.push(field_error);
      }
      if (!this.statement.emailAddress){
        this.errors.email_address = [];
        this.errors.email_address.push(field_error);
      }
    }
  }

  public changePhoneNumber(phoneNumber){
    this.statement.phoneNumber = phoneNumber;
  }

  public changeEmailAddress(emailAddress){
    this.statement.emailAddress = emailAddress;
  }
}
