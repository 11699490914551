import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-partner-info',
  templateUrl: './partner-info.component.html',
  styleUrls: ['./partner-info.component.scss'],
})
export class PartnerInfoComponent {
  @Input() text: string;
  @Input() partnersList: [];

  constructor(
  ) {
  }

  public trackBy(index: number): number {
    return index;
  }
}
