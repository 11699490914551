import { SubscribingComponent } from 'app/common';

export abstract class AbstractComponent extends SubscribingComponent {
  protected loadingStatementData = false;
  protected loadingUserType = false;
  protected loadingSelectsData = false;
  protected loading = false;
  protected _isInitialized = false;

  public get isInitialized(): boolean {
    return !this.loadingSelectsData && !this.loadingUserType && this._isInitialized;
  }

  public get isLoading(): boolean {
    return !this.isInitialized || this.loadingStatementData || this.loading;
  }
}
