import { WorkflowAdditionalIncomeSpouseSubBaseService } from 'app/core/workflow/workflow-steps/spouse/sub/workflow-additional-income-spouse-sub-base.service';

export class WorkflowAdditionalIncomeSpouseThreeService extends WorkflowAdditionalIncomeSpouseSubBaseService {
  public get title(): string {
    return 'ASIDE_BAR.ADDITIONAL_INCOME_SUBLIST.FREELANCE_JOB';
  }

  public get subStepNumber(): number {
    return 3;
  }
}
