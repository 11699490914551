import {Component, OnInit} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-payment-cancellation',
  templateUrl: './payment-cancellation.component.html',
  styleUrls: ['./payment-cancellation.component.scss']
})
export class PaymentCancellationComponent implements OnInit {

  language = 'DE';
  LANGUAGES = {
    'pl': 'PL',
    'en': 'EN',
    'de': 'DE'
  };

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.language = this.LANGUAGES[this.translate.currentLang] || this.language;
  }

}
