<div class="home-service-info">
  <div class="main-text row">
    <span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.HAS_BUSINES_REAL_ESTATE.TOOLTIP.TEXT_1' | translate"></span>
    <span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.HAS_BUSINES_REAL_ESTATE.TOOLTIP.TEXT_2' | translate"></span>
  </div>
  <div class="list-box row">
    <h3
      class="list-box-title"
      [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.HAS_BUSINES_REAL_ESTATE.TOOLTIP.LIST_1.TITLE' | translate"
    ></h3>
    <ul>
      <li>
        <span
          [innerHtml]="
            'ADDITIONAL_INCOME.FREELANCE_GROUP.HAS_BUSINES_REAL_ESTATE.TOOLTIP.LIST_1.LIST_ELEMENT_1' | translate
          "
        ></span>
      </li>
      <li>
        <span
          [innerHtml]="
            'ADDITIONAL_INCOME.FREELANCE_GROUP.HAS_BUSINES_REAL_ESTATE.TOOLTIP.LIST_1.LIST_ELEMENT_2' | translate
          "
        ></span>
      </li>
    </ul>
  </div>
</div>
