import { Component, OnInit, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { State, getUser } from "../../reducers";
import { SubscribingComponent } from "../../common";
import { takeUntil } from "rxjs/operators";
import { Statement } from "../../statements/statement";
import { StatementService } from "app/statements/statement.service";
import { CustomLabels } from "../labels-edit/CustomLabels";
import { User } from "app/users";

@Component({
  selector: "app-labels-edit",
  templateUrl: "./labels-edit.component.html",
  styleUrls: ["./labels-edit.component.scss"]
})
export class LabelsEditComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  @Input() currentId = null;
  public isMasterAccount = false;
  public isSent = false;
  public isError = false;
  public labelList: CustomLabels[] = [];
  public statementCurrentLabelList: CustomLabels[] = [];
  public selectedLabel: CustomLabels = null;

  constructor(
    private store: Store<State>,
    private statmentService: StatementService
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          if (user.isMasterAccount || user.isSubaccount) this.isMasterAccount = true;
          if (this.statement && this.isMasterAccount) {
            this.isSent = this.statement.status === "sent" ? true : false;
            if (!this.isSent) this.getLabelsList();
          } else {
            this.isError = true;
          }
        }
      });
  }

  public showLabels(): boolean {
    if (!this.isMasterAccount) return false;
    if (this.isSent) return false;
    if (this.labelList.length == 0) return false;
    return true;
  }

  private getLabelsList() {
    this.labelList = [];
    this.statmentService
      .getAllLabels()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response: any) => {
          response.forEach((item) => {
            const label = new CustomLabels();
            label.map(item);
            this.labelList.push(label);
          });
          this.getCurrentStatementLabel();
        },
        (err) => {
          this.isError = true;
        }
      );
  }

  private getCurrentStatementLabel() {
    this.statementCurrentLabelList = [];
    this.statmentService
      .getStatementCurrentLabels(this.statement.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response: any) => {
          response.forEach((item) => {
            const label = new CustomLabels();
            label.map(item);
            this.statementCurrentLabelList.push(label);
          });
          this.setActiveLabel();
        },
        (err) => {
          console.log("err ", err);
        }
      );
  }

  private setActiveLabel() {
    if (this.labelList.length > 0 && this.statementCurrentLabelList.length > 0) {
      this.selectedLabel = this.labelList.filter((kk) => kk.id == this.statementCurrentLabelList[0].id)[0];
    }
  }

  public setStatementLabel(label: CustomLabels) {
    this.statmentService
      .saveLabel(this.statement.id, this.selectedLabel ? this.selectedLabel.id : 0)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response: any) => {},
        (error) => {
          console.log("error", error);
        }
      );
  }
}
