import { WorkflowAdditionalIncomeSpouseSubBaseService } from 'app/core/workflow/workflow-steps/spouse/sub/workflow-additional-income-spouse-sub-base.service';

export class WorkflowAdditionalIncomeSpouseTwoService extends WorkflowAdditionalIncomeSpouseSubBaseService {
  public get title(): string {
    return 'ASIDE_BAR.ADDITIONAL_INCOME_SUBLIST.PENSIONS';
  }

  public get subStepNumber(): number {
    return 2;
  }
}
