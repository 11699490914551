import { EventEmitter, Injectable } from '@angular/core';
import { UtilityHelper } from 'app/core/helpers/utility.helper';
import { GroupSectionStep } from 'app/shared/modules/group-section/components/group-section-base.abstract.component';
import {
  ToolsBarButtonCommonTitle,
  ToolsBarButtonInterface,
  ToolsBarButtonType
} from 'app/shared/components/tools-bar/tools-bar.component';
import { takeUntil } from 'rxjs/operators';
import { ArrayHelper } from 'app/core/helpers/array.helper';
import { Subject } from 'rxjs';

interface InitOptionsInterface {
  steps: GroupSectionStep[];
  step: number;
  resetOnInit?: boolean;
  ngUnsubscribe: Subject<void>;
}

export interface SubStepSaveDataInterface {
  index: number;
  callback: (result: boolean) => void;
}

@Injectable()
export class StepsControllerService {
  public subSteps: GroupSectionStep[];
  public subStepSaveEmitter = new EventEmitter<SubStepSaveDataInterface>();
  public stepsEndedForward = new EventEmitter<void>();
  public stepsEndedBackward = new EventEmitter<void>();
  public selectedButtonsChangedEmitter = new EventEmitter<number>();
  public proceedEmitter = new EventEmitter<void>();
  public backEmitter = new EventEmitter<void>();
  public reInitEmitter = new EventEmitter<void>();
  public buttons: ToolsBarButtonInterface[] = [];
  public get steps(): GroupSectionStep[] {
    return this._steps;
  }
  public set steps(_steps: GroupSectionStep[]) {
    this._steps = _steps;
    this.handleStepChanges();
  }
  public get step(): number {
    return this._step;
  }
  public set step(_step: number) {
    this._step = _step;
    this.handleStepChanges();
  }
  private _steps: GroupSectionStep[];
  private _step: number;
  private ngUnsubscribe: Subject<void>;

  constructor(
  ) {
    this.resetInitialVariables();
  }

  public init(options: InitOptionsInterface): void {
    if (options.resetOnInit) {
      this.resetInitialVariables();
    }

    this.ngUnsubscribe = options.ngUnsubscribe;

    this.steps = options.steps;

    this.step = options.step;

    this.handleStepChanges();

    this.initEventListeners();
  }

  public setActiveStep(index: number): void {
    this.resetActiveStep();
    if (this.steps[index]) {
      this.steps[index].isActive = true;
    }

    UtilityHelper.scrollToTop();
  }

  public getActiveStep(): GroupSectionStep {
    return ArrayHelper.arrayFinder(this.steps, 'isActive', true);
  }

  public stepIsActive(stepId: string): boolean {
    const step: GroupSectionStep = ArrayHelper.arrayFinder(this.steps, 'id', stepId);
    return step?.isActive;
  }

  public goBack(): void {
    this.backEmitter.emit();
  }

  public proceed(): void {
    this.proceedEmitter.emit();
  }

  private initEventListeners(): void {
    this.selectedButtonsChangedEmitter.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (counter: number) => {
        if (counter) {
          this.buttons[1].type = ToolsBarButtonType.proceed;
          this.buttons[1].label = ToolsBarButtonCommonTitle.proceed;
        } else {
          this.buttons[1].type = ToolsBarButtonType.skip;
          this.buttons[1].label = ToolsBarButtonCommonTitle.skip;
        }
      }
    });
  }

  private resetActiveStep(): void {
    this.steps.some(el => {
      el.isActive = false;
    });
  }

  private resetInitialVariables(): void {
    this.steps = [];
    this.subSteps = [];
    this.subStepSaveEmitter = new EventEmitter<SubStepSaveDataInterface>();
    this.stepsEndedForward = new EventEmitter<void>();
    this.stepsEndedBackward = new EventEmitter<void>();
    this.selectedButtonsChangedEmitter = new EventEmitter<number>();
    this.proceedEmitter = new EventEmitter<void>();
    this.backEmitter = new EventEmitter<void>();
    this.reInitEmitter = new EventEmitter<void>();
    this.step = undefined;
    this.buttons = [
      {
        type: ToolsBarButtonType.back,
        label: ToolsBarButtonCommonTitle.back,
        action: this.goBack.bind(this)
      },
      {
        type: ToolsBarButtonType.proceed,
        label: ToolsBarButtonCommonTitle.proceed,
        action: this.proceed.bind(this)
      }
    ];
  }


  private handleStepChanges(): void {
    if (this.step > 0 && this.step <= this.steps.length) {
      this.setActiveStep(this.step - 1);
    } else {
      this.setActiveStep(0);
    }
  }
}
