import { finalize, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { State, getUserType, getSelectsData } from '../../reducers';
import { SHOW_SNACKBAR } from '../../reducers/actions/snackbar';
import { UPDATE_REFUND_DETAILS } from '../../reducers/actions/refund-details';
import { Statement } from '../statement';
import { SubscribingComponent, SnackbarConfig } from 'app/common';
import { StatementService } from '../statement.service';
import { HideViewSpinnerAction, ShowViewSpinnerAction } from 'app/reducers/actions/loader';
import { FirebaseEventsService } from 'app/common/firebase-events.service';
import { SelectsData, SelectsDataInterface } from '../selects-data';
import { UserType } from 'app/users/user-type';
import { combineLatest } from 'rxjs';
import { AbroadIncomeComponentPath } from 'app/core/workflow/page-path/statement-page-path/abroad-income-component.path';
import { StatementErrors } from 'app/modules/statement/models/statement/statement.errors.interface';
import { ErrorMessage, ErrorMessageContent } from 'app/core/form-validator/validatorInterface';
import { ValidateFieldService } from 'app/core/form-validator/validate-field.service';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';

@Component({
  selector: 'app-b2b-abroad-income-info',
  templateUrl: './b2b-abroad-income-info.component.html',
  styleUrls: ['./b2b-abroad-income-info.component.scss']
})
export class B2bAbroadIncomeInfoComponent extends SubscribingComponent implements OnInit {
  @Input() isSpouse = false;
  @Input() isNested = false;
  @Input() nestedStatement: Statement;
  statementId: number;
  statement: Statement;
  loadingStatementData = true;
  errors: StatementErrors;
  isBusiness = false;
  public contentLoaded = false;
  public epValidator: ErrorMessage[] = [];
  public dataSelects: SelectsDataInterface = new SelectsData();
  public loadingSelectsData = false;
  public orderForEmployerPeriod = false;
  public orderApplyForMessage = false;

  buttons = [
    {
      type: 'back',
      label: 'COMMON.BACK',
      action: this.goBack.bind(this)
    },
    {
      type: 'proceed',
      label: 'COMMON.PROCEED',
      action: this.proceed.bind(this)
    }
  ];

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private firebaseEventsService: FirebaseEventsService,
    private validatorService: ValidateFieldService,
    public workFlowController: WorkflowControllerService
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new ShowViewSpinnerAction());

    this.loadingSelectsData = true;
    this.store.select(getSelectsData).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.loadingSelectsData = false)
    ).subscribe((selectsData: SelectsDataInterface) => {
      if (selectsData) {
        this.dataSelects = selectsData;
      }
    });

    combineLatest([
      this.route.params,
      this.store.select(getUserType),
    ]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([params, userType]) => {
        if (params && userType) {
          this.statementId = parseInt(params['statementId'], 10);

          this.isBusiness = userType === UserType.business;

          if (userType === UserType.individual) {
            this.router.navigate([AbroadIncomeComponentPath.fullUrl(this.statementId)]);
            return;
          }

          if (this.nestedStatement) {
            this.statement = this.nestedStatement;
            this.workFlowController.init(this.statement);
            this.loadingStatementData = false;
          } else {
            this.getStatementData(this.statementId);
          }

          this.clearErrors();
        }
      });
  }

  clearErrors() {
    this.errors = {};
  }

  getStatementData(id: number) {
    this.loadingStatementData = true;
    this.statementService.getStatement(id).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => {
        this.loadingStatementData = false;
        this.contentLoaded = true;
        this.store.dispatch(new HideViewSpinnerAction());
      }))
      .subscribe((response: Statement) => {
        this.statement = response;
        this.workFlowController.init(this.statement);
      }, (error: Error) => {
        this.router.navigate(['/new']);
      });
  }

  updateStatement(goForward = false, goBack = false, responseToSave = false, updateRefund = false) {
    const handleResponse = (response: Statement) => {
      Object.assign(this.statement, response);

      // TAX-2569 income_abroad_success
      if ( !updateRefund ) {
        const incomeAbroadSuccess = response.hasAbroadIncome ? 'true' : 'false';
        this.firebaseEventsService.logEvent('income_abroad_success', incomeAbroadSuccess, this.statementId);
      }

      this.onDataUpdateSuccess(goForward, goBack, updateRefund);
    };

    const handleErrors = (error: any) => {
      this.contentLoaded = true;
      this.errors = error.error;
    };

    this.checkCanBeFilledTogether();
    
    if(!this.checkIfIsResident()){
      if(this.statement.additional_abroad_incomes.length > 0) this.statement.additional_abroad_incomes = [];
      if(this.statement.spouse?.additional_abroad_incomes.length > 0) this.statement.spouse.additional_abroad_incomes = [];
    }

    if (
      Number(this.statement.abroadIncome.abroadIncome)
      || Number(this.statement.abroadIncome.spouseAbroadIncome)
      || this.statement.abroadIncome.abroadIncomeCountry
      || this.statement.abroadIncome.spouseAbroadIncomeCountry
      || this.statement.additional_abroad_incomes.length > 0
      || this.statement.spouse?.additional_abroad_incomes.length > 0
    ) {
      this.statement.hasAbroadIncome = true;
    }
    const defaultValue = responseToSave ? undefined : '';
    const toResponse = this.statement.abroadIncomeToResponse(defaultValue, 'abroadIncome');

    this.contentLoaded = false;
    this.statementService.updateStatement(this.statement.id, toResponse).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(handleResponse, handleErrors);
  }

  onDataUpdateSuccess(goForward = false, goBack = false, updateRefund = false) {
    this.clearErrors();
    this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig('SNACKBAR.STATEMENT_UPDATED') });
    this.contentLoaded = false;

    if (goForward) {
      this.workFlowController.goToTheNextStep();
    }

    if (goBack) {
      this.workFlowController.goToThePreviousStep();
    }

    if (updateRefund) {
      this.contentLoaded = true;
      this.store.dispatch({ type: UPDATE_REFUND_DETAILS });
    }
  }

  isLoaded() {
    return !this.loadingStatementData;
  }

  goBack() {
    this.save();
  }

  save() {
    if(this.statement.abroadIncome.liveAbroad || this.statement.abroadIncome.spouseLiveAbroad){
      this.formValidation();
      if (this.epValidator.length) {
        return;
      }
    } 
    this.updateStatement(false, true, true, true);
  }

  private addDateEpValidatorMessage(field_name: string, message : string): void {
    const errorMessage = new ErrorMessage();
    errorMessage.fieldName = field_name;
    errorMessage.index = 0;
    errorMessage.messages = [new ErrorMessageContent(message)];
    this.epValidator.push(errorMessage);
    
  }

  proceed() {
    let isValidated = true;

    if(this.statement.abroadIncome.liveAbroad || this.statement.abroadIncome.spouseLiveAbroad){
      this.formValidation();

      if(this.statement.abroadIncome.days_employed == 0){
        this.orderForEmployerPeriod = true;
        this.addDateEpValidatorMessage('period_of_employments', 'ABROAD_INCOME_EMPLOYER_PERIOD');
        isValidated = false ;
      }
  
      if(!this.checkIfIsResident() && !this.statement.abroadIncome.apply_for_unlimited_tax_liability && !this.statement.abroadIncome.accepted_tax_residency_message){
        this.orderApplyForMessage = true;
        isValidated = false ;
      }
  
      if (this.epValidator.length > 0)
        isValidated = false ;
    } 
    if(!isValidated) return;

    this.updateStatement(true, false);
  }

  public updateAIFlag(_statement: Statement) {
    this.statement.hasAbroadIncome = _statement.hasAbroadIncome;
    this.statement.abroadIncome.liveAbroad = _statement.abroadIncome.liveAbroad;
    this.statement.abroadIncome.spouseLiveAbroad = _statement.abroadIncome.spouseLiveAbroad;
  }

  public checkIfIsResident(): boolean{
    return this.statement.abroadIncome.days_employed > 183 ? true : false;    
  }

  private formValidation() {
    this.epValidator = [];
    this.validatorService.currentYear = this.statement.fullYear;
    this.validatorService.clearErrorMessages();
    this.validatorService.index = 0;

    if (!this.statement) {
      return;
    }    
    
    if(this.checkIfIsResident() && !this.statement.abroadIncome.apply_for_unlimited_tax_liability_with_relief){
      if (this.statement.abroadIncome.liveAbroad&& this.statement.additional_abroad_incomes.length > 0) {
        let additionalAbroadIncome = this.statement.additional_abroad_incomes;

        additionalAbroadIncome.forEach((income, index)=>{
          this.validatorService.index = index;

          if (!this.validatorService.validateField('ai_income', income.abroad_income)) {
            this.epValidator.push(this.validatorService.errorMessages);
          }
          if (!this.validatorService.validateField('ai_income_type', income.abroad_income)) {
            this.epValidator.push(this.validatorService.errorMessages);
          }
          if (!this.validatorService.validateField('ai_currency', income.abroad_income_currency)) {
            this.epValidator.push(this.validatorService.errorMessages);
          }
          if (!this.validatorService.validateField('ai_country', income.abroad_income_country)) {
            this.epValidator.push(this.validatorService.errorMessages);
          }
        })
      }

      if (this.statement.abroadIncome.spouseLiveAbroad && this.statement.spouse?.additional_abroad_incomes.length > 0) {
        let additionalAbroadIncome = this.statement.spouse.additional_abroad_incomes;

        additionalAbroadIncome.forEach((income, index)=>{
          this.validatorService.index = index;

          if (!this.validatorService.validateField('ais_income', income.abroad_income)) {
            this.epValidator.push(this.validatorService.errorMessages);
          }
          if (!this.validatorService.validateField('ais_income', income.abroad_income)) {
            this.epValidator.push(this.validatorService.errorMessages);
          }
          if (!this.validatorService.validateField('ais_currency', income.abroad_income_currency)) {
            this.epValidator.push(this.validatorService.errorMessages);
          }
          if (!this.validatorService.validateField('ais_country', income.abroad_income_country)) {
            this.epValidator.push(this.validatorService.errorMessages);
          }
        })
      }      
    }else{
      if (this.statement.abroadIncome.liveAbroad) {        
        if (!this.validatorService.validateField('ai_income', this.statement.abroadIncome.abroadIncome)) {
          this.epValidator.push(this.validatorService.errorMessages);
        }
        if (!this.validatorService.validateField('ai_currency', this.statement.abroadIncome.abroadIncomeCurrency)) {
          this.epValidator.push(this.validatorService.errorMessages);
        }
        if (!this.validatorService.validateField('ai_country', this.statement.abroadIncome.abroadIncomeCountry)) {
          this.epValidator.push(this.validatorService.errorMessages);
        }
      }
      if (this.statement.abroadIncome.spouseLiveAbroad) {
        if (!this.validatorService.validateField('ais_income', this.statement.abroadIncome.spouseAbroadIncome)) {
          this.epValidator.push(this.validatorService.errorMessages);
        }
        if (!this.validatorService.validateField('ais_currency', this.statement.abroadIncome.spouseAbroadIncomeCurrency)) {
          this.epValidator.push(this.validatorService.errorMessages);
        }
        if (!this.validatorService.validateField('ais_country', this.statement.abroadIncome.spouseAbroadIncomeCountry)) {
          this.epValidator.push(this.validatorService.errorMessages);
        }
        if (!this.validatorService.validateField('ais_gr_income', this.statement.spouseCalcIncome)) {
          this.epValidator.push(this.validatorService.errorMessages);
        }
      }
    }
    
  }

  private checkCanBeFilledTogether() {
    const canBeFilledTogether = this.statement.check90ConditionOrSmallAbroadIncome(this.dataSelects);
    const work183days = this.checkIfIsResident();

    if (!canBeFilledTogether && !work183days && !this.statement.abroadIncome.accepted_tax_residency_message) {
      this.statement.filedTogether = false;
      this.statement.abroadIncome.spouseLiveAbroad = false;
      this.statement.abroadIncome.spouseAbroadIncome = '0.00';
      this.statement.abroadIncome.spouseAbroadIncomeCountry = null

      if(this.statement.spouse){
        this.statement.spouse.hasAbroadIncome = false;
        this.statement.spouse.additional_abroad_incomes = [];
      }
      
    }
  }
}
