<div class="epic_statement_card" *ngIf="statement">
  <div class="epic_statement_card_head_wrap">
    <div class="epic_statement_card_head">
      {{ statement.getFullname() }}
    </div>
    <progress max="100" [value]="statement | statementProgressPipe"></progress>
  </div>
  <div class="epic_statement_card_cont_wrap">
    <div class="label_title">{{ "CARD.REFUND_DETAILS" | translate }}</div>
    <div class="refund" (click)="openRefundDetailsDialog()">
      <div
        class="refund_value"
        [class.green]="statement.estimatedRefund > 0"
        [class.red]="statement.estimatedRefund < 0"
        [class.grey]="
          statement.serviceType === 'tax_adviser' && (statement.status === 'paid' || statement.status === 'paid_ready')
        "
      >
        <span *ngIf="statement.estimatedRefund < 0">-</span>
        &euro; {{ statement.getAbsoluteRefund() }}
      </div>
    </div>
    <div class="epic_statement_card_info" [class.custom]="!statement.clonedFrom">
      <div class="single_info">
        <div class="label_title sm_m">{{ "CARD.DATE_TITLE" | translate }}</div>
        <div class="desc_title">{{ statement.createdDate }}</div>
      </div>
      <div class="single_info" *ngIf="statement.clonedFrom">
        <div class="label_title sm_m">{{ "CARD.CLONED_TITLE" | translate }}</div>
        <div class="desc_title">ID: {{ statement.clonedFrom }}</div>
      </div>
      <div class="single_info status">
        <div class="label_title sm_m">{{ "CARD.STATUS_TITLE" | translate }}</div>
        <div class="desc_title">
          <span class="status_txt" [innerHTML]="getDashboardStatus() | translate">
            <!-- {{ }} -->
            <!-- {{ statement.statusText }} -->
          </span>
          <ng-container *ngIf="statement.dashboardStatus === 'ORDERED'">
            <app-more-info-tooltip
              [isXSmallIcon]="true"
              [message]="'TOOLTIP.DASHBOARD_ORDERED'"
              [showText]="false"
            ></app-more-info-tooltip>
          </ng-container>
          <ng-container *ngIf="statement.dashboardStatus === 'IN_APPROVAL'">
            <app-more-info-tooltip
              [isXSmallIcon]="true"
              [message]="'TOOLTIP.DASHBOARD_IN_APPROVAL'"
              [showText]="false"
            ></app-more-info-tooltip>
          </ng-container>
          <ng-container *ngIf="statement.dashboardStatus === 'SUBMITTED'">
            <ng-container *ngIf="statement.hasCertificate">
              <app-more-info-tooltip
                [isXSmallIcon]="true"
                [message]="'TOOLTIP.DASHBOARD_SUBMITTED_CERTIFICATE'"
                [showText]="false"
              ></app-more-info-tooltip>
            </ng-container>
            <ng-container *ngIf="!statement.hasCertificate">
              <app-more-info-tooltip
                [isXSmallIcon]="true"
                [message]="'TOOLTIP.DASHBOARD_SUBMITTED_NO_CERTIFICATE'"
                [showText]="false"
              ></app-more-info-tooltip>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="single_info t_right">
        <div class="label_title sm_m">{{ "CARD.PROGRESS_TITLE" | translate }}</div>
        <div class="desc_title"
          ><b>{{ getDashboardFinishPrecent() }}%</b></div
        >
      </div>
    </div>
    <!--TAX-2611-->
    <!--        <div>-->
    <!--            <div class="single_info" *ngIf="steuerSendDate">-->
    <!--                <div class="label_title sm_m">{{ 'CARD.TAX_ADVISER_WILL_SEND_IN_DAYS' | translate }}</div>-->
    <!--                <div class="desc_title">{{ steuerSendDate }}</div>-->
    <!--            </div>-->
    <!--        </div>-->
  </div>
  <div class="epic_statement_card_bottom_wrap">
    <button
      class="btn_icon"
      mat-button
      [attr.data-cy]="'btnEditStatementB2C'"
      (click)="editStatement()"
      [disabled]="
        statement.isProcessing() ||
        (statement.status === 'paid_ready' && statement.serviceType === 'tax_adviser' && !isTaxAdviser)
      "
      *ngIf="
        !hideEditing &&
        !statement.isSent() &&
        !statement.isForClientApproval &&
        !statement.isReportedErrors &&
        !statement.isApproved
      "
    >
      <img src="../../../assets/svg/edit_icon.svg" alt="edit" />
      <span>{{ "COMMON.EDIT" | translate }}</span>
    </button>

    <button
      class="btn_icon"
      mat-button
      (click)="editStatement()"
      [attr.data-cy]="'btnConfirmStatementB2C'"
      [disabled]="
        statement.isProcessing() ||
        (statement.status === 'paid_ready' && statement.serviceType === 'tax_adviser' && !isTaxAdviser)
      "
      *ngIf="!hideEditing && !statement.isSent() && statement.isForClientApproval"
    >
      <img src="../../../assets/svg/checkout_check.svg" alt="edit" />
      <span>{{ "COMMON.CONFIRM" | translate }}</span>
    </button>

    <button
      class="btn_icon"
      mat-button
      [class.is-loading]="statement.downloadingInvoice"
      [disabled]="statement.downloadingInvoice"
      *ngIf="!hideInvoice && statement.isPaid"
      (click)="emitDownloadInvoice()"
    >
      <img src="../../../assets/svg/upload_pdf.svg" alt="invoice" />
      <span i18n>{{ "COMMON.INVOICE" | translate }}</span>
    </button>

    <!-- <button
      class="btn_icon"
      mat-button
      [class.is-loading]="statement.downloadingPdf"
      [disabled]="statement.downloadingPdf"
      *ngIf="!hidePdf && statement.canDownloadPdf"
      (click)="emitDownloadPdf()"
    >
      <img src="../../../assets/svg/download.svg" alt="download" />
      <span i18n>{{ "COMMON.FORM" | translate }}</span>
    </button> -->

    <button
      class="btn_icon downloads"
      mat-button
      title="Downloads"
      i18n-title="Downloads"
      (click)="emitShowDownloadDialog()"
      *ngIf="displayDownloadsButton() && statement.numberOfAttachments > 0"
    >
      <span>{{ statement.numberOfAttachments }}</span>
      <img src="../../../assets/svg/download.svg" alt="download" />
    </button>

    <button class="btn_icon" mat-button (click)="emitCloneStatment()" *ngIf="statement.isSent()">
      <img src="../../../assets/svg/duplicate.svg" alt="clone" />
      <span i18n>{{ "COMMON.CLONE" | translate }}</span>
    </button>

    <button
      class="btn_icon red"
      mat-button
      [class.is-loading]="statement.deleting"
      [disabled]="statement.deleting"
      *ngIf="!hideRemoving && !(statement.isProcessing() || statement.isSent() || statement.isPaid)"
      (click)="emitDeleteStatement()"
      [attr.data-cy]="'btnDeleteStatement'"
    >
      <img src="../../../assets/svg/del_icon.svg" alt="delete" />
      <span i18n>{{ "COMMON.CARD_DELETE" | translate }}</span>
    </button>

    <app-mini-hamburger-menu [template]="hamburgerElements" *ngIf="shouldShowHamburgerMenu"></app-mini-hamburger-menu>
  </div>

  <ng-template #hamburgerElements>
    <button
      class="btn_icon hamburger-button"
      mat-button
      [class.is-loading]="statement.deleting"
      [disabled]="statement.deleting"
      (click)="openAppealDecisionDialogComponent()"
      [attr.data-cy]="'btnDeleteStatement'"
      *ngIf="statement.isSent()"
    >
      <span i18n>{{ "COMMON.SEND_APPEAL" | translate }}</span>
    </button>
    <button
      class="btn_icon hamburger-button"
      mat-button
      [class.is-loading]="statement.deleting"
      [disabled]="statement.deleting"
      (click)="openSendNewMessageDialogComponent()"
      [attr.data-cy]="'btnDeleteStatement'"
      *ngIf="statement.isSent()"
    >
      <span i18n>{{ "COMMON.SEND_MESSAGE" | translate }}</span>
    </button>
    <button
      class="btn_icon hamburger-button"
      mat-button
      [class.is-loading]="statement.deleting"
      [disabled]="statement.deleting"
      (click)="openSendAttachmentDialogComponent()"
      [attr.data-cy]="'btnDeleteStatement'"
      *ngIf="statement.isSent()"
    >
      <span i18n>{{ "COMMON.SEND_ATTACHMENT" | translate }}</span>
    </button>
    <button
      class="btn_icon hamburger-button"
      mat-button
      [class.is-loading]="statement.deleting"
      [disabled]="statement.deleting"
      (click)="openSendEwrDialogComponent()"
      [attr.data-cy]="'btnDeleteStatement'"
      *ngIf="statement.isSent()"
    >
      <span i18n>{{ "COMMON.SEND_EWR" | translate }}</span>
    </button>
    <button
      class="btn_icon hamburger-button"
      mat-button
      [class.is-loading]="statement.deleting"
      [disabled]="statement.deleting"
      (click)="openSaveEwrDialogComponent()"
      [attr.data-cy]="'btnDeleteStatement'"
      *ngIf="!statement.isSent() && (statement.hasAbroadIncome || statement.abroadIncome.spouseLiveAbroad)"
    >
      <span i18n>{{ "COMMON.ATTACH_EWR" | translate }}(0)</span>
    </button>
  </ng-template>
</div>
