<div
  class="form-input"
  (clickElsewhere)="closeTooltip()"
  [class.certInput]="certInput"
  [class.employerStage]="employerStage"
>
  <div class="form-input__header level" *ngIf="!disableLabel">
    <div class="level-left" [class.full_xs]="hiddenTooltipOnMobile" (click)="closeTooltip()">
      <label class="form-input__label" [class.center]="centerLabel">
        <span class="form-input__label-tx">{{ label | translate }}</span>
        <span class="form-input__currency-code" *ngIf="currencyCode">({{ setCurrencyCode() }})</span>
      </label>
    </div>
    <app-more-info-tooltip
      class="tooltipInInput"
      *ngIf="message"
      [class.hidden_xs]="hiddenTooltipOnMobile"
      [isSmallIcon]="true"
      [message]="message"
      [isSmall]="true"
      [showText]="false"
      [additionalDeductions]="true"
      [tooltipInInput]=true
    ></app-more-info-tooltip>
  </div>
  <div
    class="form-input__content"
    [class.isNotDefault]="isNotDefault"
    [class.active]="activeInput && !textArea"
    [class.notEmpty]="inputChange"
    [class.error]="errors && errors.length"
    [class.is-loading]="isLoading"
  >
    <ng-container *ngIf="textArea; then textarea; else input"></ng-container>
    <ng-template #textarea>
      <textarea
        class="form-input__textarea"
        [placeholder]="placeholder | translate"
        [formControl]="control"
        (blur)="onBlur($event)"
        (focus)="onFocus($event)"
        (ngModelChange)="change($event)"
        [class.form-input--error]="errors && errors.length"
        [class.form-input--verification]="isVerification"
        [class.isEmployer]="isEmployer"
        [class.center]="centerTxtInput"
        [ngClass]="addClassB2B ? classB2B : ''"
      ></textarea>
    </ng-template>
    <ng-template #input>
      <input
        class="form-input__input"
        [type]="getInputType"
        [placeholder]="placeholder | translate"
        (blur)="onBlur($event)"
        (focus)="onFocus($event)"
        (ngModelChange)="change($event)"
        [readonly]="isReadonly"
        [formControl]="control"
        step="0.01"
        [class.form-input--error]="errors && errors.length"
        [class.form-input--verification]="isVerification"
        [class.isEditableSummaryEmployer]="isEditableSummaryEmployer"
        [class.isEmployer]="isEmployer"
        [attr.id]="currentInputId"
        [class.center]="centerTxtInput"
        [ngClass]="addClassB2B ? classB2B : ''"
        [attr.maxlength]="maxLength ? maxLength : null"
        [attr.required]="required ? required : null"
        [attr.autofocus]="autofocus ? autofocus : null"
        [attr.name]="name ? name : null"
        [attr.autocomplete]="autocomplete ? autocomplete : null"
        [attr.inputMode]="inputMode ? inputMode : null"
      />
      <ng-container *ngIf="appShowPassword">
       <span
         class="fa fa-fw field-icon"
         (click)="showPasswordToggle()"
         [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
       ></span>
      </ng-container>
      <ng-container *ngIf="iconSrc">
        <span class="field-icon pt-0_5"><img src="{{ iconSrc }}" alt="Input icon" /></span>
      </ng-container>
    </ng-template>
    <span
      class="form-input__currency"
      [class.isNotDefault]="isNotDefault"
      [class.isVerification]="isVerification"
      [class.isEditableSummaryEmployer]="isEditableSummaryEmployer"
      *ngIf="isCurrency || currencyCode"
    >{{ setCurrencyCode() }}</span>
  </div>
  <div class="form-input__error" *ngIf="errors">{{ errors[0] }}</div>
</div>
