export const ValidatorRules = {
  IS_REQUIRED: "IS_REQUIRED",
  IS_REQUIRED_IF_NOT_UNDEFINED: "IS_REQUIRED_IF_NOT_UNDEFINED",
  IS_NOT_ZERO_NUMBER: "IS_NOT_ZERO",
  FIELD_IS_INTEGER_IN_RANGE_EQ: "FIELD_IS_INTEGER_IN_RANGE",
  IS_REQUIRED_INDEX: "IS_REQUIRED_INDEX",
  FIELD_LENGTH_MIN: "FIELD_LENGTH_MIN",
  FIELD_LENGTH_MAX: "FIELD_LENGTH_MAX",
  FIELD_IS_INTIGER: "FIELD_IS_INTIGER",
  FIELD_IS_DECIMAL: "FIELD_IS_DECIMAL",
  FIELD_NUMBER_MIN: "FIELD_NUMBER_MIN",
  FIELD_NUMBER_MAX: "FIELD_NUMBER_MAX",
  FIELD_IS_REG_EX: "FIELD_IS_REG_EX",
  FIELD_IS_DATE: "IS_DATE",
  FIELD_YEAR_IS_CURRENT: "FIELD_YEAR_IS_CURRENT",
  FIELD_YEAR_MIN: "FIELD_YEAR_MIN",
  FIELD_YEAR_MAX: "FIELD_YEAR_MAX",
  FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL: "FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL",
  FIELD_EMP_DATE_IS_LOWER_OR_EQUAL: "FIELD_EMP_DATE_IS_LOWER_OR_EQUAL",
  FIELD_EMP_DATE_IS_BIGGER: "FIELD_EMP_DATE_IS_BIGGER",
  FIELD_EMP_DATE_IS_LOWER: "FIELD_EMP_DATE_IS_LOWER",
  FIELD_EMP_DATE_IS_NOT_IN_RANGE: "FIELD_EMP_DATE_IS_NOT_IN_RANGE",
  IS_NOT_NULL: "IS_NOT_NULL",
  ONLY_VALUE_IN_LIST: "ONLY_VALUE_IN_LIST"
};

export const ValidatorErrorMessage = {
  FIELD_IS_REQUIRED: "FIELD_IS_REQUIRED",
  ONE_OF_THIS_IS_REQUIRED: "ONE_OF_THIS_IS_REQUIRED",
  FIELD_IS_NOT_ZERO: "FIELD_IS_NOT_ZERO",
  FIELD_LENGTH_TO_SHORT: "FIELD_LENGTH_TO_SHORT",
  FIELD_LENGTH_TO_LONG: "FIELD_LENGTH_TO_LONG",
  FIELD_SHOULD_BE_INTIGER: "FIELD_SHOULD_BE_INTIGER",
  FIELD_SHOULD_BE_NUMBER: "FIELD_SHOULD_BE_NUMBER",
  FILED_NUMBER_TO_LOW: "FILED_NUMBER_TO_LOW",
  FIELD_NUMBER_TO_BIG: "FIELD_NUMBER_TO_BIG",
  FIELD_WRONG_FORMAT: "FIELD_WRONG_FORMAT",
  FIELD_IS_NULL: "FIELD_IS_NULL",
  FIELD_IS_DATE: "FIELD_IS_DATE",
  FIELD_YEAR_IS_CURRENT: "FIELD_YEAR_IS_CURRENT",
  FIELD_YEAR_MIN: "FIELD_YEAR_MIN",
  FIELD_YEAR_MAX: "FIELD_YEAR_MAX",
  FIELD_HAVE_MIN_16_YEARS: "FIELD_HAVE_MIN_16_YEARS",
  FIELD_IS_DECIMAL_NEGATIVE_15CHAR: "FIELD_IS_DECIMAL_NEGATIVE_15CHAR",
  FIELD_IS_DECIMAL_NEGATIVE_12CHAR: "FIELD_IS_DECIMAL_NEGATIVE_12CHAR",
  FIELD_IS_DECIMAL_NEGATIVE_8CHAR: "FIELD_IS_DECIMAL_NEGATIVE_8CHAR",
  FIELD_IS_DECIMAL_NEGATIVE_7CHAR: "FIELD_IS_DECIMAL_NEGATIVE_7CAHR",
  FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR: "FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR",
  FIELD_IS_DECIMAL_NON_NEGATIVE_12CHAR: "FIELD_IS_DECIMAL_NON_NEGATIVE_12CHAR",
  FIELD_IS_DECIMAL_NON_NEGATIVE_9CHAR: "FIELD_IS_DECIMAL_NON_NEGATIVE_9CHAR",
  FIELD_IS_DECIMAL_NON_NEGATIVE_8CHAR: "FIELD_IS_DECIMAL_NON_NEGATIVE_8CHAR",
  FIELD_IS_DECIMAL_NON_NEGATIVE_7CHAR: "FIELD_IS_DECIMAL_NON_NEGATIVE_7CHAR",
  FIELD_IS_DECIMAL_NON_NEGATIVE_6CHAR: "FIELD_IS_DECIMAL_NON_NEGATIVE_6CHAR",
  FIELD_IS_NOT_STEUER: "FIELD_IS_NOT_STEUER",
  FIELD_IS_NOT_SOCIAL_SECURITY_NUMBER: "FIELD_IS_NOT_SOCIAL_SECURITY_NUMBER",
  FIELD_IS_NOT_AGREEMENT_NUMBERS: "FIELD_IS_NOT_AGREEMENT_NUMBERS",
  FIELD_IS_NOT_VALID_FORMAT: "FIELD_IS_NOT_VALID_FORMAT",
  FIELD_EMP_DATE_FROM_WRONG_FORMAT: "FIELD_EMP_DATE_FROM_WRONG_FORMAT",
  FIELD_EMP_DATE_TO_WRONG_FORMAT: "FIELD_EMP_DATE_TO_WRONG_FORMAT",
  FIELD_EMP_DATE_IS_BIGGER_THAN: "FIELD_EMP_DATE_IS_BIGGER_THAN",
  FIELD_EMP_DATE_IS_LOWER_THAN: "FIELD_EMP_DATE_IS_LOWER_THAN",
  FIELD_EMP_DATE_SHOULD_BE_DIFFERENT_FROM_PREV_DATE_RANGE: "FIELD_EMP_DATE_SHOULD_BE_DIFFERENT_FROM_PREV_DATE_RANGE",
  FIELD_EMP_DATE_SHOULD_BE_DIFFERENT_FROM_NEXT_DATE_RANGE: "FIELD_EMP_DATE_SHOULD_BE_DIFFERENT_FROM_NEXT_DATE_RANGE",
  ONLY_VALUE_IN_LIST: "ONLY_VALUE_IN_LIST",
  FIELD_IS_INTEGER_IN_RANGE_EQ: "FIELD_IS_INTEGER_IN_RANGE_EQ",
  FIELDS_SUM_TOO_BIG: "FIELDS_SUM_TOO_BIG",
  FIELDS_SUM_IS_INTEGER_IN_RANGE_EQ: "FIELDS_SUM_IS_INTEGER_IN_RANGE_EQ",
  FIELD_IS_POSTAL_CODE_DE: "FIELD_IS_POSTAL_CODE_DE",
  FIELD_WITHOUT_SPECIAL_CHARACTERS: "FIELD_WITHOUT_SPECIAL_CHARACTERS"
};

export const RegExValidator = {
  // IS_DATE: /^[0-9]{2}[\.][0-9]{2}[\.][0-9]{4}$/g,
  IS_EMAIL: /^[a-zA-Z0-9]*[\@][a-zA-Z0-9]*[\.][a-zA-Z0-9]*$/g,
  NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15: /^[\-]?(?!0\d)\d{1,12}([.]\d{1,2})$|^[\-]?[\d]{1,12}$/g,
  NUMBER_2_DECIMAl_ALLOW_NEGATIVE_12: /^[\-]?(?!0\d)\d{1,9}([.]\d{1,2})$|^[\-]?[\d]{1,9}$/g,
  NUMBER_2_DECIMAL_ALLOW_NEGATIVE_8: /^[\-]?(?!0\d)\d{1,5}([.]\d{1,2})$|^[\-]?[\d]{1,5}$/g,
  NUMBER_2_DECIMAL_ALLOW_NEGATIVE_7: /^[\-]?(?!0\d)\d{1,4}([.]\d{1,2})$|^[\-]?[\d]{1,4}$/g,
  NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15: /^(?!0\d)\d{1,12}([.]\d{1,2})$|^[\d]{1,12}$/g,
  NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_12: /^(?!0\d)\d{1,9}([.]\d{1,2})$|^[\d]{1,9}$/g,
  NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_9: /^\d{1,6}[.,]?\d{1,2}$/g,
  NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_8: /^(?!0\d)\d{1,5}([.]\d{1,2})$|^[\d]{1,5}$/g,
  NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_7: /^(?!0\d)\d{1,4}([.]\d{1,2})$|^[\d]{1,4}$/g,
  NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_6: /^(?!0\d)\d{1,3}([.]\d{1,2})$|^[\d]{1,3}$/g,
  NUMEBR_NOT_DECIMAL: /^[0-9]*$/g,
  STEUER_NUMBER: /^[\d]{5}[\/][\d]{5}|[\d]{2,3}[\/][\d]{3,4}[\/][\d]{4,5}|[\d]{3} [\d]{5}$/g,
  SOCIAL_SECURITY_NUMBER: /^(?=.{12}$)[0]{12}|[0-9]{8}[A-Za-z][0-9]{3}/g,
  INTEGER_6: /^(?=.{1,6}$)(?!0\d)\d{1,6}/g,
  INTEGER_12: /^(?=.{1,12}$)(?!0\d)\d{1,12}/g,
  INTEGER_15: /^(?=.{1,15}$)(?!0\d)\d{1,15}/g,
  AGREEMENT_NUMBERS: /^(?=.{1,2}$)(?=.*[1-9].*)(?!0\d)\d{1,15}/g,
  DATE_NO_YEAR: /^[0-9]{1,2}[\/][0-9]{1,2}$/g,
  POSTAL_CODE_DE: /^\d{5}$/g,
  ALLOWED_CHARACTERS_ERIC: /^[!"#$%&'()*+,-.\/0-9:;<=>?@A-Za-ö-w-я\\[\]^_`€ ]*$/gm,
  FREELANCE_INCOMES_VALUE: /^(?=-?.{4,15}$)(-(?=.*[1-9].*))?(?!0\d)\d{1,12}(,\d{2,2})$/
};
