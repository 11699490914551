import { Component } from '@angular/core';
import { CtrBaseComponent } from '../ctr-base.component';

@Component({
    ...CtrBaseComponent.metaData,
    selector: 'app-ctr-personal-info',
    templateUrl: './ctr-personal-info.component.html',
    styleUrls: ['../ctr-base.component.scss'],
})
export class CtrPersonalInfoComponent extends CtrBaseComponent {
}
