export class IosAppHelpers {
  private static rateAppEventKey = 'RateApp';
  private static iosAppRatedEventKey = 'iosAppRatedEvent';

  public static callRateApp(): void {
    IosAppHelpers.sendDataToIos(this.rateAppEventKey);
  }

  public static sendDataToIos(data: string): void {
    const webkit = (window as any).webkit;
    if (
      webkit
      && webkit.messageHandlers
      && webkit.messageHandlers.iosCommunication
    ) {
      webkit.messageHandlers.iosCommunication.postMessage(data);
    }
  }

  public static addEventIosAppRated(callback: () => any = () => {}): void {
    // window[this.iosAppRatedEventKey] = () => {
    //   callback();
    // };
  }

  public static removeEventIosAppRated(): void {
    // window[this.iosAppRatedEventKey] = null;
  }
}
