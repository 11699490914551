import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';

export class WorkflowTaxAdvisorAdditionalValidationService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.statement && !this.statement.identificationNumberIsMissing;
  }

  public get isRequired(): boolean {
    return this.statement
      && (
        this.statement.isPaid
        || this.statement.isSent()
      )
      && !this.statement.isForClientApproval
      && !this.statement.isApproved
      && !this.statement.isReportedErrors
      && !this.statement.batchId
      && !this.isBusiness
      && !this.isTaxAdviser
      && this.statement.isPaid
      && this.statement.isPremiumPackage
      && this.statement.vollmachtSigned;
  }

  public get checkCanGo(): boolean {
    return this.statement
      && this.isRequired;
  }

  public get urlRegex(): RegExp {
    return /(\/statement\/)\d+(\/tax-advisor-additional-validation)((\/?#?)|(\/?#.*?))$/;
  }

  public get url(): string {
    return `statement/${this.getStatementId}/tax-advisor-additional-validation`;
  }
}
