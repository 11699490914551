import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TooltipBaseComponent } from '../tooltip-base/tooltip-base.component';

@Component({
  ...TooltipBaseComponent.metaData,
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent extends TooltipBaseComponent {
  @Input() label: string;
  @Input() model: any;
  @Output() modelChange = new EventEmitter();
  get errors(): any {
    return this._errors;
  }
  @Input() set errors(_errors: any) {
    this._errors = _errors && _errors.length ? _errors : null;
  }
  @Input() placeholder: string;
  @Input() isDisabled = false;
  @Input() choices: any[];
  @Input() translateChoices = true;
  @Input() valueName: number | string;
  @Input() optionName: number | string;
  @Input() disableOptionCentering = false;
  @Input() currentIndex = 0;
  @Input() currentName = 'select_';
  @Input() isVerification = false;
  @Input() addClassB2B = false;
  @Input() classB2B = '';
  @Input() employerStage = false;
  @Input() dataCyName: string;
  @Input() autofocus: string;
  private _errors: any;

  constructor() {
    super();
  }

  toggleTooltip() {
    if (!this.isDisabled) {
      super.toggleTooltip();
    }
  }

  change(newValue) {
    this.model = newValue.value;
    this.modelChange.emit(newValue.value);
  }

  public trackBy(index: number, item: any): any {
    return this.valueName != null ? item[this.valueName] : item;
  }
}
