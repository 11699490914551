import { EventEmitter, Injectable } from '@angular/core';
import { ErrorMessage, FieldValidationRules } from 'app/core/form-validator/validatorInterface';
import { FieldRulesList } from 'app/core/form-validator/validtorField';
import { ValidatorErrorMessage, ValidatorRules } from 'app/core/form-validator/validatorRules';
import { ObjectHelper } from 'app/core/helpers/object.helper';
import { RecognizerHelper } from 'app/core/helpers/recognizer.helper';
import { DateHelper } from 'app/core/helpers/date.helper';

@Injectable()
export class ValidateFieldService {
  public currentYear = 0;
  public fieldName = '';
  public field: any = null;
  public fieldToCompare: any = null;
  public errorMessages: ErrorMessage;
  public rule: FieldValidationRules = null;
  public isValid = true;
  public index: number = -1;
  public isNestedArrayField = false;
  public validatorChangedEmitter = new EventEmitter<any>();
  private rules: FieldValidationRules[];

  constructor() {
    this.rules = FieldRulesList;
  }

  public validateFieldAndGetMessage(
    objectForValidation: {},
    validationFieldKeys: string[][]
  ): ErrorMessage[] {
    const epValidatorMessages = [];

    validationFieldKeys.forEach(el => {
      const fieldOne = ObjectHelper.getFieldByString(objectForValidation, el[1] || el[0]);
      let fieldTwo = el[2] ? ObjectHelper.getFieldByString(objectForValidation, el[2]) : null;
      let fieldThree = el[3] ? ObjectHelper.getFieldByString(objectForValidation, el[3]) : null;
      if (this.isNestedArrayField && Array.isArray(fieldOne)) {
        fieldOne.forEach((item, index) => {
          if (!fieldTwo && el[2]) {
            fieldTwo = ObjectHelper.getFieldByString(
              objectForValidation,
              el[2].replace('$index$', index.toString())
            );
          }
          if (!fieldThree && el[3]) {
            fieldThree = ObjectHelper.getFieldByString(
              objectForValidation,
              el[3].replace('$index$', index.toString())
            );
          }
          const firstElToValid = el[3]
            ? fieldTwo
            : item;
          const secondElToValid = el[3]
            ? (Array.isArray(fieldThree) ? fieldThree[index] : fieldThree)
            : (Array.isArray(fieldTwo) ? fieldTwo[index] : fieldTwo);


          if (!this.validateField(el[0], firstElToValid, secondElToValid)) {
            this.errorMessages.index = index;
            epValidatorMessages.push(this.errorMessages);

            this.validatorChangedEmitter.emit();
          }
        });
      } else {
        if (!this.validateField(el[0], fieldOne, fieldTwo)) {
          epValidatorMessages.push(this.errorMessages);

          this.validatorChangedEmitter.emit();
        }
      }
    });

    return epValidatorMessages;
  }

  public validateField(_fieldName: string, _field: any, _fieldToCompare: any = null): boolean {
    this.clearErrorMessages();
    this.fieldName = _fieldName;
    this.fieldToCompare = _fieldToCompare;
    this.field = _field;
    this.setRule();

    this.isValid = true;

    if (!this.rule || !this.rule.year.includes(this.currentYear)) {
      return this.isValid;
    }

    if (!this.field) {
      if (this.rule.fieldRules.length) {
        const rulesIsRequired = this.rule.fieldRules.find(kk => kk === ValidatorRules.IS_REQUIRED);

        if (rulesIsRequired) {
          this.isValid = false;
          this.setErrorMessage(ValidatorErrorMessage.FIELD_IS_REQUIRED);
          return this.isValid;
        }
      }
    }

    let i = 0;
    this.rule.fieldRules.forEach((item: string) => {
      switch (item) {
        case ValidatorRules.IS_REQUIRED:
          this.isRequired(i);
          break;
        case ValidatorRules.IS_NOT_ZERO_NUMBER:
          this.isNotZeroNumber(i);
          break;
        case ValidatorRules.IS_REQUIRED_INDEX:
          this.isRequiredIndex(i);
          break;
        case ValidatorRules.FIELD_LENGTH_MIN:
          this.fieldLengthTooShort(i);
          break;
        case ValidatorRules.FIELD_LENGTH_MAX:
          this.fieldLengthTooLong(i);
          break;
        case ValidatorRules.FIELD_IS_INTIGER:
          this.fieldIsInteger(i);
          break;
        case ValidatorRules.FIELD_IS_DECIMAL:
          this.fieldIsNumber(i);
          break;
        case ValidatorRules.FIELD_NUMBER_MIN:
          this.fieldNumberIsTooSmall(i);
          break;
        case ValidatorRules.FIELD_NUMBER_MAX:
          this.fieldNumberIsTooBig(i);
          break;
        case ValidatorRules.FIELD_IS_REG_EX:
          this.fieldIsFormated(i);
          break;
        case ValidatorRules.FIELD_IS_DATE:
          this.fieldIsDate(i);
          break;
        case ValidatorRules.FIELD_YEAR_IS_CURRENT:
          this.fieldYearIsCurrent(i);
          break;
        case ValidatorRules.FIELD_YEAR_MAX:
          this.fieldYearMax(i);
          break;
        case ValidatorRules.FIELD_YEAR_MIN:
          this.fieldYearMin(i);
          break;
        case ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL:
          this.fieldDateIsLowerOrEqual(i);
          break;
        case ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL:
          this.fieldDateIsBiggerOrEqual(i);
          break;
        case ValidatorRules.FIELD_EMP_DATE_IS_LOWER:
          this.fieldDateIsLower(i);
          break;
        case ValidatorRules.FIELD_EMP_DATE_IS_BIGGER:
          this.fieldDateIsBigger(i);
          break;
        case ValidatorRules.IS_NOT_NULL:
          this.isNotNull(i);
          break;
      }

      i++;
    });

    return this.isValid;
  }

  public setRule(): void {
    let fieldRules: FieldValidationRules[];
    fieldRules = this.rules.filter(kk => kk.fieldName === this.fieldName);

    if (!fieldRules.length) {
      return;
    }

    if (fieldRules.length && fieldRules.length < 2) {
      if (fieldRules[0].year.filter(kk => kk === this.currentYear).length > 0) {
        this.rule = fieldRules[0];
      } else {
        this.rule = null;
      }
    } else {
      console.error(`TO MANY RULES FOR FIELD ${this.fieldName}. FIELD SHOULD HAVE ONLY ONE RULE`);
    }
  }

  public clearErrorMessages(): void {
    this.errorMessages = new ErrorMessage();
  }

  private isRequired(ruleIndex: number): void {
    if (this.rule.fieldRulesValue[ruleIndex]) {
      if (!this.field || !this.field.toString().length) {
        this.isValid = false;
        this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
      }
    }
  }

  private isNotZeroNumber(ruleIndex: number): void {
    if (this.rule.fieldRulesValue[ruleIndex]) {
      if (Number(this.field) === 0) {
        this.isValid = false;
        this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
      }
    }
  }

  private isRequiredIndex(ruleIndex: number): void {
    if (this.rule.fieldRulesValue[ruleIndex]) {
      if (RecognizerHelper.isNullOrUndefined(this.field) || !this.field.toString().length) {
        this.isValid = false;
        this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
      }
    }
  }

  private fieldLengthTooShort(ruleIndex: number): void {
    if (this.field.toString().length < this.rule.fieldRulesValue[ruleIndex]) {
      this.isValid = false;
      this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
    }
  }

  private fieldLengthTooLong(ruleIndex: number): void {
    if (this.field.toString().length > this.rule.fieldRulesValue[ruleIndex]) {
      this.isValid = false;
      this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
    }
  }

  private fieldIsInteger(ruleIndex: number): void {
    this.fieldIsNumber(ruleIndex);
    if (!this.isValid) {
      return;
    }
    const splitedData = this.field.toString().split('.');
    if (splitedData.length > 2) {
      this.isValid = false;
      this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
    } else if (splitedData.length === 2) {
      const decimalsValue = Number(splitedData[1]);
      if (decimalsValue) {
        this.isValid = false;
        this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
      }
    }
  }

  private fieldIsNumber(ruleIndex: number): void {
    if (isNaN(this.field)) {
      this.isValid = false;
      this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
    }
  }

  private fieldNumberIsTooSmall(ruleIndex): void {
    const fieldValidation = Number(this.field);
    if (fieldValidation < this.rule.fieldRulesValue[ruleIndex]) {
      this.isValid = false;
      this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
    }
  }

  private fieldNumberIsTooBig(ruleIndex): void {
    const fieldValidation = Number(this.field);
    if (fieldValidation > this.rule.fieldRulesValue[ruleIndex]) {
      this.isValid = false;
      this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
    }
  }

  private fieldIsFormated(ruleIndex): void {
    if (!this.field) {
      return;
    }
    const regExTest = this.rule.fieldRulesValue[ruleIndex];
    regExTest.lastIndex = 0;
    const regExValid = this.rule.fieldRulesValue[ruleIndex].test(this.field.toString());
    if (regExValid === false) {
      this.isValid = false;
      this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
    }
  }

  private fieldIsDate(ruleIndex): void {
    if (this.rule.fieldRulesValue[ruleIndex]) {
      if (isNaN(Date.parse(this.field))) {
        this.isValid = false;
        this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
      } else if (!DateHelper.getInstance(this.field).isValid()) {
        this.isValid = false;
        this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
      }
    }
  }

  private fieldYearIsCurrent(ruleIndex): void {
    if (this.rule.fieldRulesValue[ruleIndex]) {
      const dateSplit = this.field.toString().split('.');
      let year;
      if (isNaN(Number(dateSplit[2]))) {
        year = new Date(this.field).getFullYear();
      } else {
        year = Number(dateSplit[2]);
      }

      if (year !== this.currentYear) {
        this.isValid = false;
        this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
      }
    }
  }

  private fieldYearMax(ruleIndex): void {
    if (isNaN(Date.parse(this.field)) || typeof this.field.getFullYear !== 'function') {
      return;
    }

    if (this.rule.fieldRulesValue[ruleIndex]) {
      const year = this.field.getFullYear();
      let maxYear;
      if (this.rule.fieldRulesValue[ruleIndex] === 'STATEMENT_YEAR') {
        maxYear = this.currentYear;
      } else {
        maxYear = Number(this.rule.fieldRulesValue[ruleIndex]);
      }

      if (year > maxYear) {
        this.isValid = false;
        this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
      }
    }
  }

  private fieldYearMin(ruleIndex): void {
    if (isNaN(Date.parse(this.field)) || typeof this.field.getFullYear !== 'function') {
      return;
    }

    if (this.rule.fieldRulesValue[ruleIndex]) {
      const year = this.field.getFullYear();
      if (year < this.rule.fieldRulesValue[ruleIndex]) {
        this.isValid = false;
        this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
      }
    }
  }

  private fieldDateIsLowerOrEqual(ruleIndex): void {
    const field1 = new Date(this.field);
    const field2 = new Date(this.fieldToCompare);
    if (field1 > field2) {
      this.isValid = false;
      this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
    }
  }

  private fieldDateIsBiggerOrEqual(ruleIndex): void {
    const field1 = new Date(this.field);
    const field2 = new Date(this.fieldToCompare);
    if (field1 < field2) {
      this.isValid = false;
      this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
    }
  }

  private fieldDateIsLower(ruleIndex) {
    const field1 = new Date(this.field);
    const field2 = new Date(this.fieldToCompare);
    if (field1 >= field2) {
      this.isValid = false;
      this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
    }
  }

  private fieldDateIsBigger(ruleIndex) {
    const field1 = new Date(this.field);
    const field2 = new Date(this.fieldToCompare);
    if (field1 <= field2) {
      this.isValid = false;
      this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
    }
  }

  private isNotNull(ruleIndex: number) {
    if (this.rule.fieldRulesValue[ruleIndex]) {
      if (RecognizerHelper.isNullOrUndefined(this.field)) {
        this.isValid = false;
        this.setErrorMessage(this.rule.fieldErrorMessage[ruleIndex]);
      }
    }
  }

  private setErrorMessage(_message): void {
    if (!this.errorMessages) {
      this.errorMessages = new ErrorMessage();
    }
    this.errorMessages.index = this.index;
    this.errorMessages.fieldName = this.fieldName;
    this.errorMessages.messages.push(_message);
  }
}
