import { Component, Input } from "@angular/core";
import { ButtonBaseComponent } from "app/shared/components/buttons/button-base.component";

@Component({
  ...ButtonBaseComponent.metaData,
  selector: "app-button-radio-two",
  templateUrl: "./button-radio-two.component.html"
})
export class ButtonRadioTwoComponent extends ButtonBaseComponent {
  @Input() selectedOption?: boolean = null;
  @Input() btnTextFirst = "COMMON.NO";
  @Input() btnTextSecond = "COMMON.YES";
  @Input() changeSelectOption = true;

  public handleSelect(value: boolean): void {
    this.clickEmitter.emit(value);

    if (this.changeSelectOption) {
      this.selectedOption = value;
    }
  }
}
