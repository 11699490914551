import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Country } from 'app/statements';

@Component({
  selector: 'app-country-field',
  templateUrl: './country-field.component.html',
  styleUrls: ['./country-field.component.scss']
})
export class CountryFieldComponent implements OnInit {

  @Input() label: string;
  @Input() model: any;
  @Input() errors: any;
  @Input() set countries(countries: Country[]) {
    this._countries = countries;
    if (this.defaultValue) {
      this.change(this.defaultValue.code);
    }
  }
  @Input() defaultValue?: Country;
  @Output() modelChange = new EventEmitter();
  @Input() _countries: Country[];

  constructor() { }

  ngOnInit() { }

  change(newValue) {
    this.model = newValue;
    this.modelChange.emit(newValue);
  }
}
