import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { AdditionalIncomeSpouseComponentPath } from 'app/core/workflow/page-path/statement-page-path/additional-income-spouse-component.path';

export class WorkflowAdditionalIncomeSpouseService extends WorkflowStepsBaseService {
  public get isRequired(): boolean {
    return !this.isBusiness && this.hasSpouseAndFilledTogether;
  }

  public get isVisible(): boolean {
    return this.isRequired;
  }

  public get isFakeStep(): boolean {
    return !this.isBusiness;
  }

  public get title(): string {
    return 'ASIDE_BAR.INCOME_INFO';
  }

  public get isDone(): boolean {
    return this.statement && this.statement.spouse && this.statement.spouse.stageAdditionalIncome;
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.isVisible &&
      this.isSpouseStageEmployerDone;
  }

  public get isActiveOnStepsBar(): boolean {
    return window.location.href.search(AdditionalIncomeSpouseComponentPath.regex()) !== -1;
  }

  public get isActive(): boolean {
    return false;
  }

  public get stepClass(): string {
    return this.classHasSubs + this.classLeftLineNone;
  }

  public get urlRegex(): RegExp {
    return AdditionalIncomeSpouseComponentPath.regex();
  }

  public get url(): string {
    return AdditionalIncomeSpouseComponentPath.url(this.getStatementId, this.getSpouseId, 1);
  }
}
