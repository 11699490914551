import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import {
  GroupSectionBaseAbstractComponent,
  SelectButtonsData
} from 'app/shared/modules/group-section/components/group-section-base.abstract.component';
import { AdditionalIncomeService } from 'app/modules/statement/services/additional-income.service';
import { StepsControllerService, SubStepSaveDataInterface } from 'app/core/workflow/steps-controller/steps-controller.service';
import { PensionSubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/pension.sub-steps';
import { PensionIncomeManager } from 'app/modules/statement/models/additional-income/pension-income.manager';
import { DateHelper } from 'app/core/helpers/date.helper';
import { FreelanceJobSubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/freelance-job.sub-steps';
import { AutofocusHelper } from 'app/core/helpers/autofocus.helper';

@Component({
  ...GroupSectionBaseAbstractComponent.metaData,
  selector: 'app-additional-income-group-pension',
  templateUrl: './additional-income-group-pension.component.html',
})
export class AdditionalIncomeGroupPensionComponent extends GroupSectionBaseAbstractComponent implements OnInit {
  public groupTitle: string;
  public pensionSteps = PensionSubSteps;
  public pensionIncomeManager = PensionIncomeManager;
  public dateHelper = DateHelper;

  constructor(
    public additionalIncomeService: AdditionalIncomeService,
    public dialog: MatDialog,
    public store: Store<State>,
    public stepsControllerService: StepsControllerService,
  ) {
    super(dialog, store, stepsControllerService);
  }

  ngOnInit() {
    this.groupTitle = this.title;
    this.init();

    this.stepsControllerService.reInitEmitter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.init();
    });

    this.stepsControllerService.subStepSaveEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data: SubStepSaveDataInterface) => this.saveGroup(data),
      });

    super.ngOnInit();
  }

  public isSelected = (selectButtonName: string): boolean => {
    switch (selectButtonName) {
      case PensionSubSteps.PENSION:
        return !!PensionIncomeManager.getPension(this.additionalIncomeService.currentStatement).length;
    }

    return false;
  }

  public selectButtonClickAction = (selectButtonName: string): void => {
    switch (selectButtonName) {
      case PensionSubSteps.PENSION:
        if (
          !!PensionIncomeManager.getPension(this.additionalIncomeService.currentStatement).length
        ) {
          this.showAreYouSurePopup((dialog) => {
            PensionIncomeManager.removeAllPension(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          PensionIncomeManager.createPension(this.additionalIncomeService.currentStatement);
        }
        break;
    }

    this.selectedButtonsChanged();
  }

  private setSelectButtonsData = (): void => {
    const selectButtonsData: SelectButtonsData[] = [
      {
        id: PensionSubSteps.PENSION,
        image: 'assets/svg/additional_income/pension_income.svg',
        title: 'ADDITIONAL_INCOME.PENSION_GROUP.PENSION.BTN_TITLE',
      },
    ];

    this.setSelectButtons(selectButtonsData);
  }

  private setSteps(): void {
    this.subSteps = [
      {
        id: PensionSubSteps.MAIN,
        isActive: true,
      },
      {
        id: PensionSubSteps.PENSION,
        isActive: false,
      },
    ];
  }

  private updateStatement(callback = () => {}): void {
    this.additionalIncomeService.updateAdditionalIncome(
      this.getActiveSubStep().id as FreelanceJobSubSteps,
      callback
    );
  }

  private saveGroup({index, callback}: SubStepSaveDataInterface): void {
    const currentStep = this.subSteps[index];

    if (currentStep && currentStep.id !== PensionSubSteps.MAIN) {
      this.additionalIncomeService.formValidationErrors(currentStep.id as PensionSubSteps);
      if (this.additionalIncomeService.epValidator.length) {
        callback(false);
        return;
      }
    }

    callback(true);
    AutofocusHelper.autofocusMatSelectMainContent();
  }

  private init(): void {
    this.setSteps();
    this.setSelectButtonsData();

    AutofocusHelper.autofocusMatSelectMainContent();
  }
}
