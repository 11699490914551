<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
          <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content">
        <div class="lhs">
            <div class="title">
                {{ 'ADDITIONAL_INCOME_OPTIONS_DIALOG.TITLE' | translate }} <span class="green">{{ 'ADDITIONAL_INCOME_OPTIONS_DIALOG.STEUER' | translate }}</span>
            </div>
            <div class="desc">
                {{ 'ADDITIONAL_INCOME_OPTIONS_DIALOG.DESCRIPTION' | translate }}
                <span class="green">{{ 'ADDITIONAL_INCOME_OPTIONS_DIALOG.STEUER' | translate }}</span>
                {{ 'ADDITIONAL_INCOME_OPTIONS_DIALOG.DESCRIPTION_2' | translate }}
            </div>
        </div>
        <div class="rhs" (click)="close()">
            <picture>
                <img src="../../../assets/img/add_options/rozumiem_grafika_taxando.png" class="dialog_img" alt="Dialog image">
            </picture>
            <button class="btn btn--primary">
                {{ 'ADDITIONAL_INCOME_OPTIONS_DIALOG.BUTTON_TEXT' | translate }}
            </button>
        </div>
    </div>
</div>