import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { reducers } from 'app/reducers';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { NgxMaskDirective } from 'ngx-mask';
import { SharedModule } from 'app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CoreModule } from 'app/core/core.module';
import { SmartAppBannerModule } from 'app/shared/modules/smart-app-banner/smart-app-banner.module';
import { AnalyticsModule } from 'app/shared/modules/analytics/analytics.module';
import { GoogleAnalyticsModule } from 'app/shared/modules/google-analytics/google-analytics.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function playerFactory() {
  return player;
}

export const appModuleImports = [
  AppRoutingModule,
  BrowserModule,
  BrowserAnimationsModule,
  LottieModule.forRoot({ player: playerFactory }),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    }
  }),
  StoreModule.forRoot(reducers, {
    runtimeChecks: {
      strictStateImmutability: false,
      strictActionImmutability: false,
    }
  }),
  HttpClientModule,
  ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
  NgxMaskDirective,
  CoreModule,
  SharedModule,
  SmartAppBannerModule,
  AnalyticsModule,
  GoogleAnalyticsModule,
];
