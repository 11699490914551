export class ArrayHelper {
  static isArrayAndNotEmpty(array: any[]): boolean {
    return Array.isArray(array) && !!array.length;
  }

  static arrayFinder(array: Array<any>, key: string, value: any): any {
    return array.find(x => x[key] === value);
  }

  static arrayFilter(array: Array<any>, key: string, value: any): any {
    return array.filter(x => x[key] === value);
  }

  static isArrayAndNotEmptyAndFirstValueIsTrue(array: any[], isFalseAccepted = false): boolean {
    return Array.isArray(array) && !!array.length && (!!array[0] || isFalseAccepted);
  }

  static getSum(array: any[], key: string): number {
    return array.reduce((a, b) => Number(a) + Number(b[key]), 0);
  }
}
