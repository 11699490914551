<div class="choose-lang__lang" [class.login_modal]="loginModal" [class.choose-lang__lang--header]="mode === 'header'">
    <div class="choose-lang__lang-active" (click)="toggleList()">
        <span class="choose-lang__lang-text">{{ activeLang?.title }}</span>
    </div>
    <div class="choose-lang__lang-list" [ngClass]="{ 'choose-lang__lang-list--opened': isListOpened }">
        <div
          class="choose-lang__lang-position"
          *ngFor="let lang of langList" (click)="setActiveLanguage(lang)"
          [class.active]="activeLang?.code === lang.code"
        >
            <span class="choose-lang__lang-text">{{ lang.title }}</span>
        </div>
    </div>
</div>
