<app-ctr-base [templateRef]="checkIfIsResident() ? residentAbroadIncome : abroadIncome" [title]="title" [categoryProperties]="categoryProperties" [dataSelects]="dataSelects" [summary]="summary"></app-ctr-base>

<ng-template #abroadIncome>
    <div class="card card-white shadow-0 mb-2" id="abroadIncomeTab">
        <div class="card__row mb-1">
            <div class="table">
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'CONFIRM_TAX_RETURN.ABROAD_INCOME.TOTAL_AMOUNT' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input
                          [attr.data-cy]="'verificationAbroadIncome_' + person"
                          readonly
                          value=" {{dataSelects.getCountryName(this.person === 'applicant' ? this.statement.abroadIncome.abroadIncomeCountry : this.statement.abroadIncome.spouseAbroadIncomeCountry)}}: {{ (person === 'applicant' ? statement.abroadIncome.abroadIncome : statement.abroadIncome.spouseAbroadIncome) | number: '1.2-2' }} {{ getCurrency(this.person === 'applicant' ? this.statement.abroadIncome.abroadIncomeCurrency : this.statement.abroadIncome.spouseAbroadIncomeCurrency) }}"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #residentAbroadIncome>
    <div class="card card-white shadow-0 mb-2" id="abroadIncomeTab">
        <div class="card__row mb-1">
            <div class="table">
                <div class="table__row" *ngFor="let income of getAbroadIncome(), let index = index">
                    <div class="table__col">
                        <div class="row__title" *ngIf="index == 0">{{ 'CONFIRM_TAX_RETURN.ABROAD_INCOME.TOTAL_AMOUNT' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input
                          [attr.data-cy]="'verificationAbroadIncome_' + person"
                          readonly
                          value="{{dataSelects.getCountryName(income.abroad_income_country)}}: {{ income.abroad_income | number: '1.2-2' }} {{ getCurrency(income.abroad_income_currency) }}"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
