<app-statement-base-template
  [appShowFormError]
  [epValidator]="additionalIncomeService.epValidator"
  [errors]="additionalIncomeService.errors"
  [statement]="statement"
  [isBusiness]="isBusiness"
  [isLoading]="isLoading"
  [isInitialized]="isInitialized"
  [templateRef]="additionalIncomePage"
  [buttons]="stepsControllerService.buttons"
></app-statement-base-template>

<ng-template #additionalIncomePage>
  <div class="additional-income" *ngIf="this.isInitialized">
    <div>
      <ng-container *ngIf="stepsControllerService.stepIsActive(additionalIncomeGroups.SOCIAL_INCOME)">
        <app-additional-income-group-social
          [title]="'ADDITIONAL_INCOME.SOCIAL_GROUP.TITLE'"
          [description]="'ADDITIONAL_INCOME.SOCIAL_GROUP.DESCRIPTION'"
        >
        </app-additional-income-group-social>
      </ng-container>
      <ng-container *ngIf="stepsControllerService.stepIsActive(additionalIncomeGroups.PENSION_INCOME)">
        <app-additional-income-group-pension
          [title]="'ADDITIONAL_INCOME.PENSION_GROUP.TITLE'"
          [description]="'ADDITIONAL_INCOME.PENSION_GROUP.DESCRIPTION'"
        >
        </app-additional-income-group-pension>
      </ng-container>
      <ng-container *ngIf="stepsControllerService.stepIsActive(additionalIncomeGroups.FREELANCE_INCOME)">
        <app-additional-income-group-freelance
          [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.TITLE'"
          [description]="'ADDITIONAL_INCOME.FREELANCE_GROUP.DESCRIPTION'"
        >
        </app-additional-income-group-freelance>
      </ng-container>
      <ng-container *ngIf="stepsControllerService.stepIsActive(additionalIncomeGroups.CRYPTOCURRENCY_INCOME)">
        <app-additional-income-group-cryptocurrency
          [title]="'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.TITLE'"
          [description]="'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.DESCRIPTION'"
        >
        </app-additional-income-group-cryptocurrency>
      </ng-container>
    </div>
  </div>
</ng-template>
