import { finalize, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { Package } from 'app/payment/package';
import { SnackbarConfig, SubscribingComponent } from 'app/common';
import { PaymentService } from 'app/payment/payment.service';
import { ErrorDialogComponent } from 'app/common/error-dialog/error-dialog.component';
import { PaymentChoices, PaymentPlatforms, preparePaymentChoicesList } from 'app/payment/payment';
import { SHOW_SNACKBAR } from 'app/reducers/actions/snackbar';
import { ScriptHelper } from 'app/core/helpers/script.helper';
import { environment } from 'environments/environment';
import { StatementPaymentService } from 'app/statements/statement-payment.service';
import { StatementPayment } from 'app/statements/statement-payment';

declare const window;

@Component({
  selector: 'app-buy-credits-dialog',
  templateUrl: './buy-credits-dialog.component.html',
  styleUrls: ['./buy-credits-dialog.component.scss']
})
export class BuyCreditsDialogComponent extends SubscribingComponent implements OnInit {
  @Input() batchId: number;
  public packages: Package[] = [];
  public selectedPackage = null;
  public paymentChoices = PaymentChoices;
  public selectedPaymentType: PaymentPlatforms = PaymentPlatforms.przelewy24;
  public stripe: any;
  private packagesFetching = false;
  private waitingForRedirection = false;

  constructor(
    private store: Store<State>,
    private paymentService: PaymentService,
    private statementPaymentService: StatementPaymentService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<BuyCreditsDialogComponent>,
  ) {
    super();
  }

  ngOnInit() {
    this.packagesFetching = true;
    this.paymentService.getPackages()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.packagesFetching = false)
      )
      .subscribe({
        next: (response: Package[]) => {
          this.packages = response;
        }
      });

    this.loadStripe();
    this.paymentOrderChoice();
  }

  public get isButtonDisabled(): boolean {
    return !this.selectedPackage || this.isButtonLoading;
  }

  public get isButtonLoading(): boolean {
    return this.waitingForRedirection;
  }

  public getTaxFilesText(quantity: number): string {
    if (quantity === 1) {
      return 'BUY_CREDITS.TAX_FILES_1';
    }
    if (quantity > 1 && quantity < 5) {
      return 'BUY_CREDITS.TAX_FILES_2';
    }
    return 'BUY_CREDITS.TAX_FILES_5';
  }

  public getPackages(): Package[] {
    if (this.selectedPaymentType === PaymentPlatforms.paypal) {
      return this.packages;
    }

    const availableCurrency = this.selectedPaymentType === PaymentPlatforms.przelewy24 ? 'PLN' : 'EUR';
    return this.packages.filter(item => item.currencyCode === availableCurrency);
  }

  public makePayment(): void {
    this.waitingForRedirection = true;
    this.statementPaymentService.getPaymentRedirect(this.selectedPackage, this.selectedPaymentType, this.batchId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response: StatementPayment) => {
          if (response) {
            const url = response.redirectUrl;
            if (url) {
              window.location.href = url;
              return;
            }
            if (response.stripeSessionId) {
              this.pay(response.stripeSessionId);
              return;
            }
          }
        },
        error: (error: any) => {
          this.waitingForRedirection = false;
          const messages = error.error;
          if (messages && messages.length && typeof messages[0] === 'string') {
            this.showErrorDialog(messages[0]);
          } else {
            this.store.dispatch({
              type: SHOW_SNACKBAR,
              payload: new SnackbarConfig('SNACKBAR.PAYMENT_ERROR', 'ERROR')
            });
          }
        }

      });
  }

  private pay(stripeSessionId): void {
    this.stripe = window['Stripe'](environment.stripe);

    this.stripe.redirectToCheckout({
      sessionId: stripeSessionId
    });
  }

  private loadStripe(): void {
    ScriptHelper.loadStripe();
  }

  private showErrorDialog(message: string): void {
    const dialogRef = this.dialog.open(ErrorDialogComponent);
    dialogRef.componentInstance.message = message;
  }

  private paymentOrderChoice(): void {
    this.paymentChoices = preparePaymentChoicesList({isBusiness: true});
  }
}
