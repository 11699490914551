import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CookiesLawService } from 'app/core/services/cookies-law.service';
import { CookiesLawDialogComponent } from 'app/core/components/cookies-law-dialog/cookies-law-dialog.component';
import { TermsFilesService } from 'app/core/services/terms-files.service';
import { ImpressumComponent } from 'app/core/impressum/impressum.component';

@Component({
  selector: 'app-cookies-law-banner',
  templateUrl: './cookies-law-banner.component.html',
  styleUrls: ['./cookies-law-banner.component.scss']
})
export class CookiesLawBannerComponent {
  constructor(
    private dialogRef: MatDialogRef<CookiesLawBannerComponent>,
    public cookiesLawService: CookiesLawService,
    private dialog: MatDialog,
    private termsFilesService: TermsFilesService,
  ) {
  }

  public onClickMore(): void {
    this.termsFilesService.openPrivacyPolicy();
  }

  public onClickCustomize(): void {
    this.dialog.open(CookiesLawDialogComponent);
  }

  public onClickAllow(): void {
    this.cookiesLawService.allowAll();
  }

  public onClickDisable(): void {
    this.cookiesLawService.disallowAll();
  }

  showPrivacyPolicyDialog() {
    this.termsFilesService.openPrivacyPolicy();
  }

  showTermsAndConditionsDialog() {
    this.termsFilesService.openTermsAndConditions();
  }

  showImpressumDialog() {
    this.dialog.open(ImpressumComponent);
  }
}
