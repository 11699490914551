import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges,
  ViewChild
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {TaxAuthority} from 'app/statements';
import {TooltipBaseComponent} from '../tooltip-base/tooltip-base.component';

@Component({
  selector: 'app-personal-info-tax-authority',
  templateUrl: './personal-info-tax-authority.component.html',
  styleUrls: ['./personal-info-tax-authority.component.scss']
})
export class PersonalInfoTaxAuthorityComponent extends TooltipBaseComponent implements OnChanges, OnInit {
  @Input() taxAuthority: number;
  @Input() errors;
  @Input() taxAuthorities: TaxAuthority[];
  @Input() isLoading = false;
  @Input() isDisabled = false;
  @Input() isVerification: boolean = false;
  @Input() addClassB2B: boolean = false;
  @Output() setTaxAuthority = new EventEmitter<number>();
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  @ViewChild('searchInput', {read: MatAutocompleteTrigger, static: false}) autoComplete: MatAutocompleteTrigger;
  taxAuthoritiesControl: UntypedFormControl = new UntypedFormControl();
  filteredTaxAuthorities: Observable<TaxAuthority[]>;

  public activeInput: boolean = false;
  public inputChange: boolean = false;

  constructor() {
    super();
  }

  ngOnInit() {
    this.setUpTaxAuthoritiesAutocomplete();
    this.setDefaultInputValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    const taxAuthority: SimpleChange = changes.taxAuthority;
    if (taxAuthority && taxAuthority.currentValue) {
      this.setDefaultInputValue();
    }
  }

  setDefaultInputValue() {
    const activeTaxAuthority = this.taxAuthorities.find(item => item.id === this.taxAuthority);
    if (activeTaxAuthority) {
      setTimeout(() => {
        this.searchInput.nativeElement.value = activeTaxAuthority.name;
        if(this.searchInput.nativeElement.value.toString() !== ''){
          this.inputChange = true;
        }
      });
    }
  }

  displayWith(taxAuthority?: TaxAuthority): string | undefined {
    return taxAuthority ? taxAuthority.name : undefined;
  }

  openAutocomplete() {
    this.autoComplete.openPanel();
  }

  expandList(event: Event) {
    event.stopPropagation();
    this.autoComplete.openPanel();
  }

  private setUpTaxAuthoritiesAutocomplete() {
    this.filteredTaxAuthorities = this.taxAuthoritiesControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? this.filterTaxAuthorities(value) : this.getAllTaxAuthorities())
      );
  }

  private getAllTaxAuthorities() {
    if (this.taxAuthority) {
      this.setTaxAuthority.emit(this.taxAuthority);
    }
    return this.taxAuthorities.slice();
  }

  private filterTaxAuthorities(value: string | TaxAuthority): TaxAuthority[] {
    if (typeof value !== 'string') {
      this.setTaxAuthority.emit(value.id);
      return;
    }
    this.setTaxAuthority.emit();
    return this.taxAuthorities.filter(option =>
      option.name.toLowerCase().includes(value.toLowerCase()));
  }

  public onBlur($event:any) {
    let thisItem = $event.target;
    this.activeInput = false;
    if(thisItem.value.toString() === ''){
      this.inputChange = false;
    }
  }

  public onFocus($event:any) {
    this.activeInput = true;
  }

  public change($event:any) {
    ///show/hide placeholder///
    if($event.toString() !== ''){
      this.inputChange = true;
    }else {
      this.inputChange = false;
    }
  }
}
