import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { AbroadIncomeComponentPath } from 'app/core/workflow/page-path/statement-page-path/abroad-income-component.path';
import { B2bAbroadIncomeComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.abroad-income-component.path';

export class WorkflowAbroadIncomeService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.statement && this.statement.stageAbroadIncome;
  }

  public get isVisible(): boolean {
    return true;
  }

  public get title(): string {
    return 'ASIDE_BAR.ABROAD_INCOME';
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.statement && this.statement.stageProfile;
  }

  public get urlRegex(): RegExp {
    if (this.isBusiness) {
      return B2bAbroadIncomeComponentPath.regex();
    }

    return AbroadIncomeComponentPath.regex();
  }

  public get url(): string {
    if (this.isBusiness) {
      return B2bAbroadIncomeComponentPath.url(this.getStatementId);
    }

    return AbroadIncomeComponentPath.url(this.getStatementId);
  }
}
