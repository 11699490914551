import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { State, getIsUserLogged } from '../reducers';
import { AuthService } from './auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

  isUserLogged = false;
  private intervalId;


  constructor(private store: Store<State>,
    private router: Router,
    private authService: AuthService) {
    this.store
      .select(getIsUserLogged)
      .subscribe((isUserLogged: boolean) => {
        return this.isUserLogged = isUserLogged;
      });

    
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    if (localStorage.getItem('user') && !this.isUserLogged) {
      let self = this;
      let maxTry = 1;
      this.intervalId = setInterval(() => {
        if (self.isUserLogged){
          clearInterval(self.intervalId);
        }
          
        if (maxTry > 10){
          if (!self.isUserLogged){
            this.authService.logout();      
          }
          clearInterval(self.intervalId);
        }
        maxTry++;
      }, 500);
    }

    return this.checkLogin(url); 
    // if (!this.isUserLoggedIn()) {
    //   this.authService.logout();
    // }
    // return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.isUserLogged) { 
      return this.isUserLogged; 
    }

    this.authService.redirectUrl = url;
    this.router.navigate(['/login']);
    return false;
  }
}

