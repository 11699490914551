import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AuthHttpService } from "./auth-http.service";
import { ExtendPersonalInfo, User } from "./user";
import { getApiPath } from "../common";
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class UserDataService {
  constructor(private authHttp: AuthHttpService) {}

  public getUserData(): Observable<User> {
    return this.authHttp.get(`${getApiPath()}accounts/profile/`).pipe(
      map((response: any) => User.fromResponse(response)),
      catchError(() => of(null))
    );
  }

  public updateUserData(user: User): Observable<any> {
    return this.authHttp.put(`${getApiPath()}accounts/profile/`, user.toResponse);
  }

  public acceptLicenseAgreement() {
    return this.authHttp.patch(`${getApiPath()}accounts/accept-license/`, { accepted_license_agreement: true });
  }

  public acceptGdprLicenseAgreement() {
    return this.authHttp.patch(`${getApiPath()}accounts/accept-gdpr-license/`, {
      accepted_gdpr_license_agreement: true
    });
  }

  public decideNewsletter(decision: boolean) {
    return this.authHttp.patch(`${getApiPath()}accounts/decide-newsletter/`, { receive_newsletters: decision });
  }

  public getUserPersonalInfo(): Observable<ExtendPersonalInfo> {
    return this.authHttp
      .get(`${getApiPath()}accounts/personal-info`)
      .pipe(map((response: any) => ExtendPersonalInfo.fromResponse(response)));
  }

  public getOwnCertificate() {
    return this.authHttp.get(`${getApiPath()}accounts/own-certificate`);
  }

  public uploadOwnCertificate(file: File, pin: number) {
    const data = new FormData();
    data.append("data_file", file, file.name);
    data.append("pin", String(pin));

    return this.authHttp.post(`${getApiPath()}accounts/own-certificate`, data, {
      headers: new HttpHeaders().append("Content-Disposition", `attachment; filename=${file.name}`)
    });
  }

  public updateOwnCertificate(file?: File, pin?: number) {
    const data = new FormData();
    if (file) {
      data.append("data_file", file, file.name);
    }
    if (pin) {
      data.append("pin", String(pin));
    }
    return this.authHttp.put(`${getApiPath()}accounts/own-certificate`, data);
  }

  public deleteOwnCertificate() {
    return this.authHttp.remove(`${getApiPath()}accounts/own-certificate`);
  }
}
