<div class="card-list-wrap">
  <div class="card-list-row" *ngFor="let dataItem of dataList; let i = index">
    <div class="card-list-item" [attr.data-cy-index]="i">
      <div class="card-list-top">
        <div class="card-title">
          {{ dataItem.titleShort | translate }} {{ dataItem.titleShortAfter | translate }}
        </div>
        <div
          class="card-text-one"
          *ngIf="dataItem.textOneShort"
        >
          {{ dataItem.textOneShort }}
        </div>
      </div>

      <div class="card-list-label" [class.error]="dataItem.error" [class.warning]="dataItem.warning">
        <ng-container *ngIf="dataItem.error">
          <img
            src="../../../../assets/svg/employer_erorr.svg"
            class="alert_icon"
            alt="Alert"
          >
        </ng-container>
        <ng-container *ngIf="dataItem.warning">
          <img
            src="../../../../assets/svg/employer_warning.svg"
            class="alert_icon"
            alt="Alert"
          >
        </ng-container>
        <div class="card-title">
          <span>{{ dataItem.title | translate }} {{ dataItem.titleAfter }}</span>
        </div>
        <div
          class="card-text-one"
          *ngIf="dataItem.textOne"
        >
          {{ dataItem.textOne }}
        </div>
        <div
          class="card-text-two notFull"
          [class.m-0-mobile]="!dataItem.textOne"
        >
          {{ dataItem.textTwo }}
        </div>
      </div>

      <app-button-edit
        [withText]="false"
        (clickEmitter)="clickButtonEditEvent(dataItem.idForActionButtons)"
      ></app-button-edit>
      <app-button-delete
        [isLoading]="dataItem.buttonDeleteLoading"
        [withText]="false"
        (clickEmitter)="clickButtonDeleteEvent(dataItem.idForActionButtons)"
      ></app-button-delete>
    </div>
    <div class="alert-info-wrap">
      <ng-container *ngIf="dataItem.warning">
        <div class="alert-info warning">
          {{ dataItem.warning | translate }}
        </div>
      </ng-container>
      <div class="alert-info" [class.error]="dataItem.error">
        <ng-container *ngIf="!dataItem.error && dataItem.noError">
          {{ dataItem.noError | translate }}
        </ng-container>
        <ng-container *ngIf="dataItem.error">
          {{ dataItem.error | translate }}
        </ng-container>
      </div>
    </div>
  </div>

  <div class="button-add-wrap" *ngIf="isButtonAddShown">
    <app-button-add
      (clickEmitter)="clickButtonAddEvent()"
      [title]="buttonAddTitle"
    >
    </app-button-add>
  </div>
</div>

