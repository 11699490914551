<div>
  <ng-container *ngIf="isActive">
    <div>
      <div class="title_w_tooltip">
        <div *ngIf="selectedCounter" class="font-size-30 font-weight-light mb-1_25">
          {{ "DEDUCTIONS.SELECTED" | translate }} ({{ selectedCounter }}/{{ selectButtons.length }})
        </div>
        <h1 class="title_42">
          {{ title | translate }}
          <span class="tooltip_wrap" *ngIf="titleTooltip || messageTooltip">
            <app-more-info-tooltip
              [titleTooltip]="titleTooltip"
              [message]="messageTooltip"
              [confirmButtonText]="confirmButtonText"
              [confirmButtonTitle]="confirmButtonTitle"
              [confirmButtonBottomText]="confirmButtonBottomText"
              [isInnerHTML]="true"
              [contentTemplateRef]="homeServiceTooltipTemplate"
              [showCustomTemplateView]="isHomeService"
              [tooltipAutoShow]="tooltipAutoShow"
            >
            </app-more-info-tooltip>

            <ng-template #homeServiceTooltipTemplate>
              <app-tooltip-template-home-service></app-tooltip-template-home-service>
            </ng-template>
          </span>
        </h1>
      </div>
      <p class="subdesc" *ngIf="description" [innerHTML]="description | translate: { year: statementFullYear }"> </p>
    </div>
    <div *ngIf="selectButtons">
      <app-select-buttons [buttons]="selectButtons"></app-select-buttons>
    </div>
  </ng-container>
  <ng-container *ngTemplateOutlet="templateRef"></ng-container>
</div>
