import { interval, interval as observableInterval } from "rxjs";
import { mergeMap, filter, takeUntil, startWith } from "rxjs/operators";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogConfig } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { DateAdapter } from "@angular/material/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { getIsUserLogged, getSnackbarInfo, getUser, State } from "app/reducers";
import { State as SnackbarState } from "app/reducers/snackbar";
import { SubscribingComponent } from "app/common";
import { AuthService } from "app/users";
import { USER_LOGIN, TOKEN_REFRESH } from "app/reducers/actions/auth-user";
import { REFRESH_TOKEN_INTERVAL } from "app/common";
import { User } from "app/users";
import { LOAD_USER_DATA } from "app/reducers/actions/user";
import { UserDataService } from "app/users";
import { environment } from "environments/environment";
import { SideNavService } from "app/core/side-nav.service";
import { StatementService } from "app/statements/statement.service";
import { SelectsDataInterface } from "app/statements";
import { LOAD_SELECTS_DATA } from "app/reducers/actions/selects-data";
import { RESET_SNACKBAR } from "app/reducers/actions/snackbar";
import { LicenseAgreementComponent, GdprLicenseAgreementComponent } from "app/core";
import { ReceiveNewsletterDialogComponent } from "app/common/receive-newsletter-dialog/receive-newsletter-dialog.component";
import { CookiesLawService } from "app/core/services/cookies-law.service";
import { RateAppService } from "app/modules/rateapp/services/rate-app.service";
import { AuthModel } from "app/modules/user/models/auth/auth.model";
import { SmartAppBannerService } from "app/shared/modules/smart-app-banner/services/smart-app-banner.service";
import { LanguageSelectService } from "app/core/services/language-select.service";
import { RateAppPagePath } from "app/core/workflow/page-path/rate-app-page.path";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent extends SubscribingComponent implements OnInit {
  @ViewChild("sidenav", { static: true }) public sidenav: MatSidenav;
  private isLicenseAgreementOpened = false;
  private isGdprLicenseAgreementOpened = false;
  public isUserLogged: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private userDataService: UserDataService,
    private store: Store<State>,
    private sideNavService: SideNavService,
    private el: ElementRef,
    private router: Router,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private statementService: StatementService,
    private dialog: MatDialog,
    public cookiesLawService: CookiesLawService,
    public rateAppService: RateAppService,
    public readonly smartAppBannerService: SmartAppBannerService,
    public readonly languageSelectService: LanguageSelectService
  ) {
    super();

    if (!environment.production) {
      console.log("Production", environment.production);
    }

    this.languageSelectService.init(this.ngUnsubscribe);
    this.rateAppService.init(this.ngUnsubscribe);
    this.smartAppBannerService.initialize();
  }

  ngOnInit() {
    if (this.getUserFromStorage()) {
      this.initAppWithRefresh(); //TAX-3025
    } else {
      this.initApp();
    }

    this.refreshToken();
    this.setupSnackbar();
    this.sideNavService.setSideNav(this.sidenav);
  }

  private initAppWithRefresh() {
    this.authService.refreshToken().subscribe({
      next: (authUser: AuthModel) => {
        if (authUser) {
          //init refreshed token
          this.store.dispatch({ type: TOKEN_REFRESH, payload: authUser });
          this.authService.updateTokenInLocalStorageAndCookies(authUser);

          this.initApp();
        } else {
          this.authService.logout();
        }
      },
      error: (error: any) => {
        console.log(error);
        this.authService.logout();
      }
    });
  }

  private initApp() {
    this.store
      .select(getIsUserLogged)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isLogged: boolean) => {
        if (isLogged) {
          this.userDataService
            .getUserData()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (user: User) => {
                this.store.dispatch({
                  type: LOAD_USER_DATA,
                  payload: user
                });

                if (user) {
                  this.showLicenseAgreement(user);
                  this.showGdprLicenseAgreement(user);
                  this.getSelectsData(); //call with authorization header (TAX-3146)
                }
              }
            });

          this.isUserLogged = isLogged;
        } else {
          this.getSelectsData(); //call without authorization header (TAX-3146)
        }
      });

    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (user: User) => {
          if (user && user.receiveNewsletters === null) {
            const dialogRef = this.dialog.open(ReceiveNewsletterDialogComponent, { disableClose: true });
            dialogRef.componentInstance.user = user;
          }
        }
      });

    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe({
        next: (event) => window.scroll(0, 0)
      });
  }

  private getSelectsData(): void {
    this.statementService
      .getSelectsData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (selectsData: SelectsDataInterface) => {
          if (selectsData) {
            this.store.dispatch({
              type: LOAD_SELECTS_DATA,
              payload: selectsData
            });
          }
        }
      });
  }

  private getUserFromStorage(): boolean {
    const user: AuthModel = this.authService.getUserFromStorage();
    if (user) {
      this.store.dispatch({ type: USER_LOGIN, payload: user });
      return true; //if user exists in storage TAX-3040
    } else {
      return false; //if user does not exists in storage TAX-3040
    }
  }

  private refreshToken() {
    interval(REFRESH_TOKEN_INTERVAL)
      .pipe(mergeMap(() => this.authService.refreshToken()))
      .subscribe({
        next: (authUser: AuthModel | null) => {
          if (authUser) {
            this.store.dispatch({ type: TOKEN_REFRESH, payload: authUser });
            this.authService.updateTokenInLocalStorageAndCookies(authUser);
          } else if (this.isUserLogged) {
            this.authService.logout();
          }
        }
      });
  }

  private setupSnackbar() {
    this.store
      .select(getSnackbarInfo)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((snackbarState: SnackbarState) => snackbarState.isSnackbarShown)
      )
      .subscribe({
        next: (snackbarState: SnackbarState) => {
          this.showSnackbar(snackbarState);
          this.store.dispatch({ type: RESET_SNACKBAR });
        }
      });
  }

  private showSnackbar(snackbarState: SnackbarState) {
    if (typeof snackbarState.snackbarConfig.message !== "string") {
      return;
    }

    const config = new MatSnackBarConfig();
    config.duration = snackbarState.snackbarConfig.duration;

    this.translate
      .get(snackbarState.snackbarConfig.message)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (message: string) => this.snackBar.open(message, null, config)
      });
  }

  private showLicenseAgreement(user: User) {
    if (user.haveToShowLicenseAgreement && !this.isLicenseAgreementOpened) {
      const config = new MatDialogConfig();
      config.disableClose = true;

      this.isLicenseAgreementOpened = true;
      this.dialog
        .open(LicenseAgreementComponent, config)
        .afterClosed()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (decision: boolean) => {
            this.isLicenseAgreementOpened = false;
            if (decision) {
              this.showGdprLicenseAgreement(user);
            }
          }
        });
    }
  }

  private showGdprLicenseAgreement(user: User) {
    if (user.haveToShowGdprLicenseAgreement && !this.isLicenseAgreementOpened && !this.isGdprLicenseAgreementOpened) {
      const config = new MatDialogConfig();
      config.disableClose = true;

      this.isGdprLicenseAgreementOpened = true;
      this.dialog
        .open(GdprLicenseAgreementComponent, config)
        .afterClosed()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => (this.isGdprLicenseAgreementOpened = false)
        });
    }
  }
}
