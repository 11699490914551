import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

import {Store} from '@ngrx/store';

import {getIsUserLogged, State} from '../reducers';


@Injectable()
export class NotAuthGuard implements CanActivate {

  isUserLogged = false;

  constructor(private store: Store<State>, private router: Router) {
    this.store
      .select(getIsUserLogged)
      .subscribe((isUserLogged: boolean) => this.isUserLogged = isUserLogged);
  }

  canActivate(): boolean {
    if (this.isUserLogged) {
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
