import { DeductionInfoSpouseComponentPath } from 'app/core/workflow/page-path/statement-page-path/deduction-info-spouse-component.path';
import { WorkflowDeductionBaseService } from 'app/core/workflow/workflow-steps/deduction/workflow-deduction-base.service';

export class WorkflowDeductionSpouseSubBaseService extends WorkflowDeductionBaseService {
  public get subStepNumber(): number {
    return 1;
  }

  public get isRequired(): boolean {
    return !this.isBusiness && !!this.statement && this.hasSpouseAndFilledTogether
      && this.isDeductionOptionAdd;
  }

  public get isDone(): boolean {
    return this.checkCanGo;
  }

  public get isVisible(): boolean {
    return this.isRequired
      && window.location.href.search(DeductionInfoSpouseComponentPath.regex()) !== -1;
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.isRequired && this.statement.stageDeductionInfo;
  }

  public get urlRegex(): RegExp {
    return DeductionInfoSpouseComponentPath.regex(this.subStepNumber);
  }

  public get url(): string {
    return DeductionInfoSpouseComponentPath.url(this.getStatementId, this.getSpouseId, this.subStepNumber);
  }

  public get isSubStep(): boolean {
    return true;
  }

  public get stepClass(): string {
    return this.classLeftLineNone + this.classSubSubMenuItem;
  }
}
