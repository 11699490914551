<div class="dialog-box" [attr.id]="id">
  <div class="dialog-box__header">
    <button mat-icon-button mat-dialog-close="" tabindex="-1">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>
  </div>
  <div class="dialog-box__content">
    <div class="title mb-2" [innerHTML]="title | translate"></div>
    <div class="desc text-center mb-2" [innerHTML]="description | translate"></div>
    <div class="dialog-box__buttons text-center">
      <button
        *ngIf="isCancelButtonShown"
        class="btn btn--longer btn--secondary"
        (click)="cancelAction($event)"
      >
        {{ cancelButtonTitle | translate }}
      </button>
      <button
        *ngIf="isSubmitButtonShown"
        class="btn btn--longer btn--primary"
        [class.is-loading]="isSubmitButtonLoading"
        (click)="submitAction($event)"
        [attr.data-cy]="'confirmCancelDialogConfirmBtn'"
      >
        {{ submitButtonTitle | translate }}
      </button>
    </div>
  </div>
</div>
