<div class="dialog-box">
    <div class="dialog-box__header">
      <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon aria-hidden="true">close</mat-icon>
      </button>
    </div>
    <div class="dialog-box__content">
      <div class="title">{{ 'VALIDATION_ERROR_DIALOG.TITLE' | translate }}</div>
      <div class="p-0_5 mb-0_5 bg-note" *ngFor="let item of formattedValidationErrors; let i = index;">
        <ng-container>
          <b>{{i + 1}}. </b><b *ngIf="item.key !== 'errorString'">{{item.key}}: </b>
          <span [class.text-danger]="item.key === 'errorString'">{{item.value}}</span>
        </ng-container>
      </div>
      <div class="text-center text-danger font-weight-bolder" *ngIf="!formattedValidationErrors || !formattedValidationErrors.length">
        {{ 'COMMON.GLOBAL_ERROR' | translate }}
      </div>
      <div class="dialog-box__buttons mt-1">
        <button class="btn btn--danger" mat-dialog-close id="jsonValidationErrorsBtnClose">
          {{ 'VALIDATION_ERROR_DIALOG.BTN_CLOSE' | translate }}
        </button>
      </div>
    </div>
</div>
