import {Action} from '@ngrx/store';

import {SnackbarConfig} from 'app/common';


export const SHOW_SNACKBAR = '[Snackbar] Show snackbar';
export const RESET_SNACKBAR = '[Snackbar] Reset snackbar';

export class ShowSnackbarAction implements Action {
  readonly type = SHOW_SNACKBAR;

  constructor(public payload: SnackbarConfig) { }
}

export class ResetSnackbarAction implements Action {
  readonly type = RESET_SNACKBAR;

  constructor() { }
}

export type Actions = ShowSnackbarAction | ResetSnackbarAction;
