<ng-container *ngIf="isLoading">
	<app-content-loader></app-content-loader>
</ng-container>

<ng-container *ngIf="isInitialized">
	<div class="step-container columns is-desktop" [class.d-none]="isLoading">
		<app-aside-bar
      [showEvenWithoutStatement]="false"
      [statement]="statement"
      [isBusiness]="isBusiness"
      [workFlowController]="workFlowController"
    >
		</app-aside-bar>

    <div class="employer">
      <!-- DO YOU WANT TO ENTER EMPOYER DATA -->
      <ng-container *ngIf="currentStep === STEPS.QUESTION_ABOUT_TAX_CARD">
        <div class="step_1_wrap step_wrapper">
          <div class="step_1_cont step_cont">

            <h2 class="title_42 step_2">
              <ng-container *ngIf="!isSpouse">
                <span class="title__stage">{{ 'EMPLOYER.STEP_1_TITLE_1' | translate }}</span>
                <span class="title__stage">{{ 'EMPLOYER.STEP_1_TITLE_2' | translate }}</span>
              </ng-container>
              <ng-container *ngIf="isSpouse">
                <span class="title__stage">
                {{ 'EMPLOYER.SPOUSE.QUESTION_ABOUT_PAYSLIP.TITLE' | translate }}
              </span>
              </ng-container>
            </h2>

            <app-button-radio-two
              [selectedOption]="taxCardIncomeInfoService.addTaxCardToStatement"
              (clickEmitter)="taxCardIncomeInfoService.selectHasEmployerCard($event)"
            ></app-button-radio-two>
          </div>
        </div>
      </ng-container>

      <!-- SELECT HOW YOU WANT TO ADD TAXCARD -->
      <ng-container *ngIf="currentStep === STEPS.METHOD_OF_DATA_ENTRY">
        <div class="step_2_wrap step_wrapper">
          <div class="step_2_cont step_cont">
            <h2 class="title_42">
              <ng-container *ngIf="!isSpouse">
                {{ 'EMPLOYER.STEP_2_TITLE' | translate }}
              </ng-container>
              <ng-container *ngIf="isSpouse">
                {{ 'EMPLOYER.SPOUSE.METHOD_OF_DATA_ENTRY.TITLE' | translate }}
              </ng-container>
            </h2>

            <app-tax-card-add
              [taxCardAddService]="taxCardAddService"
            ></app-tax-card-add>
          </div>
        </div>
      </ng-container>

      <!-- FILL TAXCARD MANUALY -->
      <ng-container *ngIf="currentStep === STEPS.TAX_CARD_EDIT">
        <div class="step_3_wrap step_wrapper">
          <div class="step_3_cont step_cont">
            <div class="title-row">
              <h2 class="title_42">
                <ng-container *ngIf="!isSpouse">
                  {{ 'EMPLOYER.TAX_CARD_TITLE' | translate }}
                </ng-container>
                <ng-container *ngIf="isSpouse">
                  {{ 'EMPLOYER.SPOUSE.ANNUAL_PAYSLIP.TITLE' | translate }}
                </ng-container>
                <span class="index">{{ taxCardIncomeInfoService.validatorIndex + 1 }}</span>
              </h2>
            </div>

            <ng-container *ngIf="taxCardIncomeInfoService.currentEmployer">
              <app-employer-edit
                [isBusiness]="isBusiness"
                [employer]="taxCardIncomeInfoService.currentEmployer"
                [errors]="taxCardIncomeInfoService.formsError[taxCardIncomeInfoService.validatorIndex]"
                [appShowFormError]
                [currentIndex]="taxCardIncomeInfoService.validatorIndex"
                [epValidator]="taxCardIncomeInfoService.epValidator"
                [isEmployerCom]="true"
                [empWarningList]="taxCardIncomeInfoService.empWarningList"
              >
              </app-employer-edit>
            </ng-container>

          </div>
        </div>
      </ng-container>

      <!--LIST OF ADDED EMPLOYERS CARD-->
      <ng-container *ngIf="currentStep === STEPS.TAX_CARDS_LIST">
        <div class="step_4_wrap step_wrapper">
          <div class="step_4_cont step_cont">
            <h2 class="title_42">
              <ng-container *ngIf="!isSpouse">
                {{ 'EMPLOYER.TAX_LIST_TITLE' | translate }}
              </ng-container>
              <ng-container *ngIf="isSpouse">
                {{ 'EMPLOYER.SPOUSE.ANNUAL_PAYSLIPS.TITLE' | translate }}
              </ng-container>
            </h2>

            <app-tax-cards-list
              [currentStatement]="currentStatement"
              [taxCardIncomeInfoService]="taxCardIncomeInfoService"
              [empWarningList]="taxCardIncomeInfoService.empWarningList"
              (clickButtonAddEmitter)="goToStepMethodOfDataEntry()"
              (editSelectedEmployerEmitter)="editSelectedEmployer($event)"
            >
            </app-tax-cards-list>

          </div>
        </div>
      </ng-container>
    </div>

		<app-tools-bar [statement]="statement" [buttons]="buttons">

		</app-tools-bar>
	</div>
</ng-container>
