import {
  PersonalInfoChildAdultComponentPath
} from 'app/core/workflow/page-path/statement-page-path/personal-info-child-adult-component-path';
import {
  PersonalInfoChildSummaryComponentPath
} from 'app/core/workflow/page-path/statement-page-path/personal-info-child-summary-component-path';
import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { PersonalInfoChildComponentPath } from 'app/core/workflow/page-path/statement-page-path/personal-info-child-component.path';

export class WorkflowPersonalInfoChildSubService extends WorkflowStepsBaseService {
  public get isRequired(): boolean {
    return this.hasChildren;
  }

  public get isDone(): boolean {
    return this.statement && this.hasChildren && this.statement.children.length
      && this.statement.stagePersonalInfoChildren;
  }

  public get isActiveOnStepsBar(): boolean {
    const locationHref = window.location.href;

    return locationHref.search(PersonalInfoChildComponentPath.regex(this.subStepNumber)) !== -1
      || locationHref.search(PersonalInfoChildAdultComponentPath.regex(this.subStepNumber)) !== -1;
  }

  public get isVisible(): boolean {
    const locationHref = window.location.href;

    return this.isRequired && this.statement.children.length > 1
      && (
        locationHref.search(PersonalInfoChildComponentPath.regex()) !== -1
        || locationHref.search(PersonalInfoChildAdultComponentPath.regex()) !== -1
        || locationHref.search(PersonalInfoChildSummaryComponentPath.regex()) !== -1
      );
  }

  public get checkCanGo(): boolean {
    if (!this.blockedDefaultWorkflow && this.isRequired) {
      if (this.hasSpouseAndFilledTogether) {
        if (this.isBusiness) {
          return this.isSpouseStageEmployerDone;
        }

        return this.statement.spouse.stageAdditionalIncome;
      }

      return this.statement.stagePersonalInfo;
    }
    return false;
  }

  public get isSubStep(): boolean {
    return true;
  }

  public get stepClass(): string {
    return this.classLeftLineNone;
  }

  public get urlRegex(): RegExp {
    return PersonalInfoChildComponentPath.regex(this.subStepNumber);
  }

  public get url(): string {
    return PersonalInfoChildComponentPath.url(this.getStatementId, this.subStepNumber);
  }

  public get goBackUrl(): string {
    return PersonalInfoChildSummaryComponentPath.url(this.getStatementId);
  }

  public get skipWhenGoBack(): boolean {
    return true;
  }
}
