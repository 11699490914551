import {Component, Input, OnInit} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguagesConfig } from 'app/configs/languages.config';
import { SubscribingComponent } from 'app/common/subscribing-component';
import { takeUntil } from 'rxjs/operators';
import { LanguageSelectService } from 'app/core/services/language-select.service';
import { LanguageInterface } from 'app/core/models/language.interface';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-choose-language',
  templateUrl: './choose-language.component.html',
  styleUrls: ['./choose-language.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
  ]
})
export class ChooseLanguageComponent extends SubscribingComponent implements OnInit {
  @Input() mode: string;
  @Input() loginModal: boolean;
  activeLang: any;
  langList = LanguagesConfig.languagesList;
  isListOpened = false;

  constructor(
    private readonly languageSelectService: LanguageSelectService,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.activeLang = this.langList.find(lang => lang.code === this.translate.currentLang);
    if (!this.activeLang) {
      this.setActiveLanguage(this.langList[0]);
    }

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.activeLang = this.langList.find(lang => lang.code === this.translate.currentLang);
      if (!this.activeLang) {
        this.setActiveLanguage(this.langList[0]);
      }
    });
  }

  toggleList() {
    this.isListOpened = !this.isListOpened;
  }

  setActiveLanguage(lang: LanguageInterface) {
    if (!this.activeLang || lang.code !== this.activeLang.code) {
      this.languageSelectService.useLanguage(lang.code, {reload: true, firebaseLog: true, setIsSelected: true});
      this.isListOpened = false;
    }
  }
}
