import { HttpResponseInterface } from 'app/core/models/http/http-response.interface';

export class HttpResponseModel<B, E = any> implements HttpResponseInterface<B, E> {
  public success: boolean;
  public body: B;
  public error: E;
  public code?: number;

  public static fromResponse<B, E = any>(response: B, code?: number): HttpResponseModel<B, E> {
    return new HttpResponseModel<B, E>(true, response, undefined, code);
  }

  public static fromError<E, B = any>(error: E, code?: number): HttpResponseModel<B, E> {
    return new HttpResponseModel<B, E>(false, null, error, code);
  }

  constructor(success: boolean, body: B, error?: E, code?: number) {
    this.success = success;
    this.body = body;
    this.error = error;
    this.code = code;
  }
}
