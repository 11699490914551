<app-menu-bar-mobile></app-menu-bar-mobile>
<div class="payment-confirmation">
    <div class="payment-confirmation__header">
      <h1 class="title">{{ 'PAYMENT_CONFIRMATION.TITLE' | translate }}</h1>
    </div>
    <div class="payment-confirmation__message">
        <span>{{ 'PAYMENT_CONFIRMATION.MESSAGE' | translate }}</span>
        <div class="payment-confirmation__message__links">
            <a class="aside-menu__stage" routerLink="/dashboard">{{ 'MENU.DASHBOARD' | translate }}</a>
            <a class="aside-menu__stage" routerLink="/packages">{{ 'MENU.BUY_PACKAGES' | translate }}</a>
        </div>
    </div>
</div>
