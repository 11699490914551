import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State, } from 'app/reducers';
import { Statement } from 'app/statements/statement';
import { StatementService } from 'app/statements/statement.service';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';
import { B2bTaxCardComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.tax-card-component.path';
import { B2bTaxCardSpouseComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.tax-card-spouse-component.path';
import { AdditionalIncomeService } from 'app/modules/statement/services/additional-income.service';
import { StepsControllerService } from 'app/core/workflow/steps-controller/steps-controller.service';
import { CryptocurrencySubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/cryptocurrency.sub-steps';
import { SocialSubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/social-sub.steps';
import { PensionSubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/pension.sub-steps';
import { FreelanceJobSubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/freelance-job.sub-steps';
import { StatementBasePage } from 'app/shared/modules/statement-base/pages/statement-base/statement-base.page';
import { StatementComponentInterface } from 'app/statements/statement-component.interface';
import { GroupSectionStep } from 'app/shared/modules/group-section/components/group-section-base.abstract.component';

export enum AdditionalIncomeGroups {
  SOCIAL_INCOME = 'socialIncome',
  PENSION_INCOME = 'pensionIncome',
  FREELANCE_INCOME = 'freelanceIncome',
  CRYPTOCURRENCY_INCOME = 'cryptocurrencyIncome'
}

@Component({
  selector: 'app-b2c-additional-income',
  templateUrl: './b2c.additional-income.page.html',
})
export class B2cAdditionalIncomePage extends StatementBasePage implements StatementComponentInterface, OnDestroy {
  public currentStatement: Statement;
  public additionalIncomeGroups = AdditionalIncomeGroups;
  public steps: GroupSectionStep[];

  constructor(
    public store: Store<State>,
    public statementService: StatementService,
    public router: Router,
    public route: ActivatedRoute,
    public workFlowController: WorkflowControllerService,
    public stepsControllerService: StepsControllerService,
    public additionalIncomeService: AdditionalIncomeService,
  ) {
    super(store, statementService, route, router, workFlowController);

    this.stepsControllerService.init({
      steps: B2cAdditionalIncomePage.getStepsList,
      step: this.stepNo,
      ngUnsubscribe: this.ngUnsubscribe,
    });
    
    this.stepsControllerService.stepsEndedForward.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.additionalIncomeService.loading = true;
        this.additionalIncomeService.updateAdditionalIncome(
          this.getStepGroupType(),
          (result) => {
            
            
            if (result) {
              this.proceed();
            } else {
              this.stepsControllerService.reInitEmitter.emit();
            }
          }
        );
      });

    this.stepsControllerService.stepsEndedBackward.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.goBack();
      });
  }

  public get isSpouse(): boolean {
    return !!this.spouseId || (this.currentStatement && this.currentStatement.isSpouse());
  }

  public get isInitialized(): boolean {
    return !!this.statement && !!this.currentStatement && !this.loadingUserType;
  }

  public get isLoading(): boolean {
    return this.loadingStatementData
      || this.loading
      || this.loadingUserType
      || this.additionalIncomeService.isLoading;
  }

  public onGetStatementDataSuccess(): void {
    if (this.isBusiness) {
      if (this.spouseId) {
        this.router.navigate([
          B2bTaxCardSpouseComponentPath.fullUrl(this.statement.id, this.spouseId)
        ]);
        return;
      } else {
        this.router.navigate([B2bTaxCardComponentPath.fullUrl(this.statement.id)]);
        return;
      }
    }

    this.currentStatement = this.isSpouse && this.statement.spouse ? this.statement.spouse : this.statement;

    this.additionalIncomeService._init(this.statement, this.currentStatement, this.ngUnsubscribe);

    this.stepsControllerService.step = this.stepNo;
    this.stepsControllerService.steps = B2cAdditionalIncomePage.getStepsList;
    this.stepsControllerService.subSteps = undefined;
    this.stepsControllerService.reInitEmitter.emit();

    this._isInitialized = true;
    this.loading = false;
  }

  // public onEnterKeyDown(): void {
  //   this.stepsControllerService.proceed();
  // }

  public goBack(): void {
    this.loading = true;
    this.workFlowController.goToThePreviousStep();
  }

  public proceed(): void {
    this.loading = true;
    this.workFlowController.goToTheNextStep();
  }

  private static get getStepsList(): GroupSectionStep[] {
    return [
      {
        id: AdditionalIncomeGroups.SOCIAL_INCOME,
        isActive: true,
      },
      {
        id: AdditionalIncomeGroups.PENSION_INCOME,
        isActive: false,
      },
      {
        id: AdditionalIncomeGroups.FREELANCE_INCOME,
        isActive: false,
      },
      {
        id: AdditionalIncomeGroups.CRYPTOCURRENCY_INCOME,
        isActive: false,
      },
    ];
  }

  private getStepGroupType(): SocialSubSteps|PensionSubSteps|FreelanceJobSubSteps|CryptocurrencySubSteps {    
    switch (this.stepsControllerService.getActiveStep().id) {
      case AdditionalIncomeGroups.SOCIAL_INCOME:
        return SocialSubSteps.INJURY;
      case AdditionalIncomeGroups.PENSION_INCOME:
        return PensionSubSteps.PENSION;
      case AdditionalIncomeGroups.FREELANCE_INCOME:
        return FreelanceJobSubSteps.FREELANCE;
      case AdditionalIncomeGroups.CRYPTOCURRENCY_INCOME:
        return CryptocurrencySubSteps.CRYPTOCURRENCY;
    }
  }
}
