import { Injectable } from "@angular/core";
import { AuthHttpService } from "app/users";
import { getApiPath } from "../common";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StatementPayment } from "app/statements/statement-payment";

@Injectable()
export class StatementPaymentService {
  constructor(private authHttp: AuthHttpService) {}

  public createPayment(statementId: number, platform: string, couponCode?: string): Observable<StatementPayment> {
    return this.authHttp
      .post(`${getApiPath()}statements/${statementId}/payment/`, { platform: platform, coupon_code: couponCode })
      .pipe(map((response: any) => StatementPayment.fromResponse(response)));
  }

  public markPaymentCompleteTaxAdviser(statementId: number, couponCode?: string): Observable<any> {
    return this.authHttp.post(`${getApiPath()}statements/${statementId}/stb-payment/`, { coupon_code: couponCode });
  }

  public getPaymentRedirect(packageId: number, platform: string, batchId?: number): Observable<StatementPayment> {
    const urlBatchPart = batchId ? `?batch_id=${batchId}` : "";
    const url = `${getApiPath()}payments/${urlBatchPart}`;
    return this.authHttp
      .post(url, { statement_package_id: packageId, platform: platform })
      .pipe(map((response: any) => StatementPayment.fromResponse(response)));
  }
}
