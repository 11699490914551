import { finalize, mergeMap, takeUntil } from "rxjs/operators";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { FirebaseHttpService } from "app/firebase/firebase.http.service";
import { AuthService, PersonalInfoOnLoginInterface, User, UserDataService } from "app/users";
import { MatDialogRef } from "@angular/material/dialog";
import { TaxCardComponentPath } from "app/core/workflow/page-path/statement-page-path/tax-card-component.path";
import { DashboardPagePath } from "app/core/workflow/page-path/dashboard-page.path";
import { SubscribingComponent } from "app/common/subscribing-component";
import { SelectsData, SelectsDataInterface, Statement } from "app/statements";
import { ValidationErrorsDialogComponent } from "app/common/validation-errors-dialog/validation-errors-dialog.component";
import { CanBeFilledResponse, StatementService, StatementsResponse } from "app/statements/statement.service";
import { StatementManagementService } from "app/statements/statement-management.service";
import { ValidatorHelper } from "app/core/helpers/validator.helper";
import {
  ALLOWED_EXTENSION,
  ERIC_ALLOWED_EXTENSION,
  MAX_NUMBER_OF_OPENED_STATEMENT,
  REFRESH_STATEMENTS_INTERVAL,
  SnackbarConfig
} from "app/common";
import { Employer } from "app/statements/employer";
import { environment } from "environments/environment";
import {
  AuthenticationDialogFlag,
  LoginDialogComponent
} from "app/shared/standalone/components/login-dialog/login-dialog.component";
import { State, getUser, getSelectsData } from "app/reducers";
import { ProcessingPlaceholderComponent } from "app/statements/processing-placeholder/processing-placeholder.component";
import { BehaviorSubject, of } from "rxjs";
import { B2bTaxCardComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.tax-card-component.path";
import { SHOW_SNACKBAR } from "app/reducers/actions/snackbar";
import { CouponHttpService } from "app/standalone-pages/coupons/services/coupon.http.service";
import { CouponsPopup } from "app/standalone-pages/coupons/coupons-popup/coupons-popup.component";

enum BasicSteps {
  TAX_YEAR_SELECT = "taxYearSelect",
  APPLICANT_INFO = "applicantInfo",
  QUESTION_ABOUT_CAN_BE_FILLED = "questionAboutCanBeFilled"
}

@Component({
  selector: "app-starter-board",
  templateUrl: "./starter-board.page.html",
  styleUrls: ["./starter-board.page.scss"]
})
export class StarterBoardPage extends SubscribingComponent implements OnInit, AfterViewInit {
  public STEPS = BasicSteps;
  public currentStep = BasicSteps.TAX_YEAR_SELECT;
  public personalInfoOnLogin: PersonalInfoOnLoginInterface = {
    firstname: "",
    lastname: "",
    emailAddress: ""
  };
  public personalInfoOnLoginErrors = {
    firstname: [],
    lastname: [],
    emailAddress: []
  };
  public user: User;
  public isLogged = false;
  public isBusiness = false;
  public uploadingScan = false;
  public statement: Statement = new Statement();
  public dataSelects: SelectsDataInterface = new SelectsData();
  public loading = false;
  public loadingSelectsData = false;
  public loadingUserData = false;
  public loadingCanBeFilledData = false;
  public processingDialogRef;
  public creatingBatch = false;
  public buttons = [];
  public validationErrorsDialogRef: MatDialogRef<ValidationErrorsDialogComponent>;
  private canBeFilledResult: boolean;
  private processingDialogOpenedTime: number;
  private jsonValidationErrors: any;
  couponsArr: any;
  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private statementManagementService: StatementManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private firebaseService: FirebaseHttpService,
    private authService: AuthService,
    private userDataService: UserDataService,
    private couponHttpService: CouponHttpService
  ) {
    super();
  }

  ngOnInit() {
    this.loadingSelectsData = true;
    this.store
      .select(getSelectsData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectsData: SelectsDataInterface) => {
        this.dataSelects = selectsData;
        // set incative for every tax year on page load
        if (this.dataSelects) {
          if (this.dataSelects.statementYears) {
            this.dataSelects.statementYears.forEach((item) => {
              item.active = false;
            });
          }
        }
      });

    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.isLogged = !!user;
        if (user) {
          this.loadingUserData = false;

          this.user = user;

          const firenbaseDeviceToken = localStorage.getItem("firebaseDeviceToken");
          const userEmail = user.email;
          if (firenbaseDeviceToken) {
            this.firebaseService
              .updateUserFirebaseDeviceToken(firenbaseDeviceToken, userEmail)
              .subscribe((Response) => {});
          }

          this.isBusiness = !!user.isBusiness;

          this.checkOpenedStatementNumber();

          if (!this.isBusiness) {
            this.loadingCanBeFilledData = true;

            this.statementService.getCanBeFilled().subscribe(
              (response: CanBeFilledResponse) => this.canBeFilledHandleResponse(response, user),
              console.error,
              () => (this.loading = false)
            );
          }
        } else {
          this.user = null;
          this.isBusiness = false;
        }

        if (this.isLogged) {
          this.activateReferalCodePopup();
        }

        this.setButtons();
      });

    // TODO: Powinien być modal Tak/Nie czy przywrocić zeznanie
    if (!this.isBusiness) {
      if (this.statementService.isExistsInLocalStorage()) {
        this.statement = this.statementService.getFromStorage;
      } else {
        this.statement.employers = [];
      }
    }
  }

  ngAfterViewInit() {
    this.route.data.subscribe(({ openedDialog }) => {
      if (this.hasAuthDialogFlag(openedDialog) && !this.authService.hasUserInStorage()) {
        if (Number(localStorage.getItem("siteVisited")) === 1) {
          this.openAuthenticationModal(openedDialog);
        }
      }
      this.setVisitedOnThisDevice();
    });
  }

  public isLoading(): boolean {
    return this.loading || this.loadingSelectsData || this.loadingUserData || this.loadingCanBeFilledData;
  }

  public onBlurPersonalInfoOnLogin(): void {
    this.personalInfoOnLoginErrors.firstname = [];
    this.personalInfoOnLoginErrors.emailAddress = [];
    if (!this.personalInfoOnLogin.firstname) {
      this.personalInfoOnLoginErrors.firstname = [""];
    }
    if (
      !this.personalInfoOnLogin.emailAddress ||
      !ValidatorHelper.emailInputValidation(this.personalInfoOnLogin.emailAddress)
    ) {
      this.personalInfoOnLoginErrors.emailAddress = [""];
    }
  }

  public setCanBeFilled(value: boolean): void {
    this.statement.canBeFilled = value;
  }

  public showEndingYear(index: number) {
    if (index !== this.dataSelects.statementYears.length - 1) {
      return 0;
    }
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    let dayToEnd = 0;
    if (month === 11) {
      dayToEnd = 31 + (30 - day);
      if (dayToEnd <= 60) {
        return dayToEnd;
      }

      return 0;
    } else if (month === 12) {
      dayToEnd = 31 - day;

      return dayToEnd;
    }

    return 0;
  }

  public createBatchManually(): void {
    this.creatingBatch = true;
    this.statementService
      .createBatch()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.creatingBatch = false))
      )
      .subscribe((response: Response) => {
        const url = `/new/batch/${response["id"]}/client-details`;
        this.router.navigate([url]);
      });
  }

  public setStatementYear(year): void {
    this.dataSelects.statementYears.filter((item) => (item.active = false));
    year.active = true;
    this.statement.fullYear = year.year;
    this.statement.year = year.id;
  }

  public onScanUpload(inputFile: EventTarget, isEric = false, many = false): void {
    this.onTaxCardUpload(inputFile, isEric, many);
  }

  private canBeFilledHandleResponse(response: CanBeFilledResponse, user: User): void {
    this.canBeFilledResult = response.result;
    if (response.result) {
      if (
        !this.personalInfoOnLogin.firstname ||
        !this.personalInfoOnLogin.lastname ||
        !this.personalInfoOnLogin.emailAddress
      ) {
        this.userDataService
          .getUserData()
          .pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => (this.loadingCanBeFilledData = false))
          )
          .subscribe((userResponse: User) => {
            this.setPersonalInfo(userResponse.firstName, userResponse.lastName, userResponse.email, true);
          });
      }
    } else {
      this.loadingCanBeFilledData = false;
    }

    let userFirstname = "",
      userLastName = "",
      userEmail = "",
      canBeFilled = false;
    if (sessionStorage.getItem("personalInfoOnLogin")) {
      const data: PersonalInfoOnLoginInterface = JSON.parse(
        sessionStorage.getItem("personalInfoOnLogin")
      ) as PersonalInfoOnLoginInterface;
      userFirstname = data.firstname;
      userLastName = data.lastname;
      userEmail = data.emailAddress;
      canBeFilled = true;
    }
    if (user) {
      if (!userFirstname) {
        userFirstname = user.extendPersonalInfo.applicantFirstName;
      }
      if (!userLastName) {
        userLastName = user.extendPersonalInfo.applicantLastName;
      }
      if (!userEmail) {
        userEmail = user.email;
      }
    }

    this.setPersonalInfo(userFirstname, userLastName, userEmail, canBeFilled || response.result);
  }

  private setPersonalInfo(firstName: string, lastName: string, emailAddress: string, canBeFilled = true): void {
    if (canBeFilled) {
      if (!this.personalInfoOnLogin.firstname) {
        this.personalInfoOnLogin.firstname = firstName;
      }
      if (!this.personalInfoOnLogin.lastname) {
        this.personalInfoOnLogin.lastname = lastName;
      }
    }
    if (!this.personalInfoOnLogin.emailAddress) {
      this.personalInfoOnLogin.emailAddress = emailAddress;
    }
  }

  // check if user have open statement for selected year
  private checkOpenedStatementNumber() {
    if (this.isBusiness) {
      return;
    }

    if (this.isLogged) {
      this.statementService.getAllOpenedStatement().subscribe((response: any) => {
        let numberOfOpenedStatement = 0;
        if (response.statements_count) {
          numberOfOpenedStatement = Number(response.statements_count);
        }
        if (numberOfOpenedStatement > MAX_NUMBER_OF_OPENED_STATEMENT) {
          this.router.navigate([DashboardPagePath.fullUrl()]);
        } else {
          this.loadingSelectsData = false;
        }
      });
    }
  }

  private createStatementB2c(): void {
    if (this.isLogged) {
      this.loading = true;

      const callback = (employers?: Employer, statement?: Statement) => {
        sessionStorage.removeItem("personalInfoOnLogin");
        this.statementService.removeFromLocalStorage();

        if (!environment.production) {
          console.log("[INFO] New statement", statement);
        }

        this.statement = statement;

        this.router.navigate([TaxCardComponentPath.fullUrl(this.statement.id)]);
      };

      this.statementManagementService.createOrAssignStatement(this.statement, this.ngUnsubscribe, null, callback);
    }
  }

  private uploadImg(scan): void {
    this.statementService
      .uploadStatement(scan, null, false, false)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.uploadingScan = false))
      )
      .subscribe((response) => {
        if (this.isBusiness) {
          this.observeBatchProcessing(response["batch"]["id"], response["batch"]["task_id"]);
        } else {
          this.observeStatementProcessing(response["statement_id"]);
        }
      });
  }

  private uploadJson(file, isEric = false, many = false): void {
    this.jsonValidationErrors = [];

    this.statementService
      .uploadEricJson(file, many)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.uploadingScan = false))
      )
      .subscribe(
        (response) => {
          if (this.isBusiness) {
            if (response && response["batch"] && response["batch"]["id"]) {
              this.observeBatchProcessing(response["batch"]["id"], "", isEric);
            } else {
              this.closeProcessingPlaceholder(true);
            }
          }
        },
        (error: any) => {
          if (error.error) {
            this.jsonValidationErrors = error.error;
          }
          this.closeProcessingPlaceholder(!!error.error);
        }
      );
  }

  private showProcessingPlaceholder(): void {
    this.processingDialogOpenedTime = performance.now();
    this.processingDialogRef = this.dialog.open(ProcessingPlaceholderComponent, {
      width: "975px",
      disableClose: true
    });
  }

  private closeProcessingPlaceholder(showErrorsDialog = false): void {
    const close = () => {
      if (this.processingDialogRef) {
        this.processingDialogRef.close();
      }
      if (showErrorsDialog) {
        this.validationErrorsDialogRef = this.dialog.open(ValidationErrorsDialogComponent);
        this.validationErrorsDialogRef.componentInstance.validationErrors = this.jsonValidationErrors;
      }
    };
    const minTime = 2000;
    const timeLeft = this.processingDialogOpenedTime ? performance.now() - this.processingDialogOpenedTime : 0;
    if (timeLeft < minTime) {
      setTimeout(() => {
        close();
      }, minTime - timeLeft);
    } else {
      close();
    }
  }

  private observeBatchProcessing(batchId: number, taskId: string, isEric = false): void {
    this.statementService
      .getBatchStatements(batchId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        mergeMap((response: StatementsResponse) => {
          if (response.statements && response.statements.length && isEric === true) {
            this.batchProcessingOnFinish(batchId);
            return of(null);
          } else if (response.statements && response.statements.length && isEric === false) {
            this.batchProcessingOnFinish(batchId);
            return of(null);
          } else {
            return this.statementService.getBatchStatus(taskId);
          }
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((response) => {
        if (!response) {
          return;
        }

        if (response["ready"]) {
          this.batchProcessingOnFinish(batchId);
          return;
        }

        this.checkBatchProcessing(batchId, taskId);
      });
  }

  private checkBatchProcessing(batchId: number, taskId: string): void {
    setTimeout(this.observeBatchProcessing.bind(this, batchId, taskId), REFRESH_STATEMENTS_INTERVAL);
  }

  private batchProcessingOnFinish(batchId: number): void {
    this.closeProcessingPlaceholder();
    this.router.navigate([`new/batch/${batchId}/client-details`]);
  }

  private observeStatementProcessing(statementId: number): void {
    this.statementService
      .getStatement(statementId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: Statement) => {
        this.checkStatementProcessing(response);
      });
  }

  private checkStatementProcessing(statement: Statement): void {
    if (statement.isProcessing()) {
      setTimeout(this.observeStatementProcessing.bind(this, statement.id), REFRESH_STATEMENTS_INTERVAL);
    } else {
      this.closeProcessingPlaceholder();
      if (this.isBusiness) {
        this.router.navigate([B2bTaxCardComponentPath.fullUrl(this.statement.id, statement.employers[0].id)]);
      } else {
        this.router.navigate([TaxCardComponentPath.fullUrl(this.statement.id)]);
      }
    }
  }

  private openAuthenticationModal(flag: AuthenticationDialogFlag): void {
    setTimeout(() => {
      this.dialog.open(LoginDialogComponent, {
        backdropClass: "backdropBackgroundWhite",
        data: { loginFlag: flag },
        panelClass: ["login_dialog_wrap", "new-dialog-wrap"]
      });
    });
  }

  private isValidFile(file: File, isEric = false): boolean {
    const ext = file.name.split(".").pop().toLocaleLowerCase();
    return isEric ? ERIC_ALLOWED_EXTENSION.includes(ext) : ALLOWED_EXTENSION.includes(ext);
  }

  private onTaxCardUpload(inputFile: EventTarget, isEric = false, many = false): void {
    if (!inputFile["files"].length) {
      return;
    }

    const scan: File = inputFile["files"].item(0);
    if (this.isValidFile(scan, isEric)) {
      this.showProcessingPlaceholder();
      this.uploadingScan = true;
      if (isEric) {
        this.uploadJson(scan, isEric, many);
      } else {
        this.uploadImg(scan);
      }

      return;
    }

    if (isEric) {
      inputFile["value"] = "";
      this.store.dispatch({
        type: SHOW_SNACKBAR,
        payload: new SnackbarConfig("SNACKBAR.INVALID_EXTENSION_JSON", "ERROR")
      });
    } else {
      inputFile["value"] = "";
      this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig("SNACKBAR.INVALID_EXTENSION", "ERROR") });
    }
  }

  private setVisitedOnThisDevice(): void {
    const visited = localStorage.getItem("siteVisited");
    if (!visited) {
      localStorage.setItem("siteVisited", "1");
    }
  }

  private get dataDiffer(): boolean {
    return (
      (this.user &&
        this.user.extendPersonalInfo &&
        this.personalInfoOnLogin.firstname !== this.user.extendPersonalInfo.applicantFirstName) ||
      this.personalInfoOnLogin.lastname !== this.user.extendPersonalInfo.applicantLastName ||
      this.personalInfoOnLogin.emailAddress !== this.user.email
    );
  }

  private goBack(): void {
    switch (this.currentStep) {
      case BasicSteps.TAX_YEAR_SELECT:
        this.router.navigate([DashboardPagePath.fullUrl()]);
        break;
      case BasicSteps.APPLICANT_INFO:
        this.currentStep = this.STEPS.TAX_YEAR_SELECT;
        break;
      case BasicSteps.QUESTION_ABOUT_CAN_BE_FILLED:
        this.currentStep = this.STEPS.APPLICANT_INFO;
        break;
    }

    this.setButtons();
  }

  private proceed(): void {
    switch (this.currentStep) {
      case BasicSteps.TAX_YEAR_SELECT:
        this.currentStep = this.STEPS.APPLICANT_INFO;
        this.statementService.setToLocalStorage(this.statement);
        break;
      case BasicSteps.APPLICANT_INFO:
        this.statement.firstName = this.personalInfoOnLogin.firstname;
        this.statement.lastName = this.personalInfoOnLogin.lastname;
        this.statement.emailAddress = this.personalInfoOnLogin.emailAddress;

        if (this.isLogged) {
          if (this.canBeFilledResult) {
            this.statement.canBeFilled = !this.dataDiffer;
            this.statement.canBeSaved = !this.dataDiffer;
          } else {
            this.statement.canBeFilled = false;
            this.statement.canBeSaved = true;
          }
        } else {
          this.statement.canBeFilled = undefined;
          this.statement.canBeSaved = undefined;
        }

        this.statementService.setToLocalStorage(this.statement);
        sessionStorage.setItem("personalInfoOnLogin", JSON.stringify(this.personalInfoOnLogin));

        if (this.isLogged) {
          if (this.dataDiffer || !this.canBeFilledResult) {
            this.createStatementB2c();
            return;
          } else {
            this.currentStep = this.STEPS.QUESTION_ABOUT_CAN_BE_FILLED;
          }
        } else {
          const config = {
            data: { loginFlag: AuthenticationDialogFlag.REGISTRATION },
            panelClass: ["login_dialog_wrap", "new-dialog-wrap"],
            backdropClass: "backdropBackgroundWhite"
          };
          const loginDialog = this.dialog.open(LoginDialogComponent, config);
          const afterLogin = (_value: CloseLoginDialogValue): void => {
            if (_value) {
              if (_value.next) {
                this.createStatementB2c();
              }
            }
          };
          loginDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(afterLogin);
        }

        break;
      case BasicSteps.QUESTION_ABOUT_CAN_BE_FILLED:
        this.statementService.setToLocalStorage(this.statement);
        this.createStatementB2c();

        break;
    }

    this.setButtons();
  }

  private setButtons(): void {
    const proceed = {
      type: "proceed",
      label: "COMMON.PROCEED",
      action: this.proceed.bind(this),
      disabled: () => !this.statement || !this.statement.year
    };
    let back = {
      type: "standard",
      label: "COMMON.BACK",
      action: this.goBack.bind(this)
    };

    switch (this.currentStep) {
      case BasicSteps.TAX_YEAR_SELECT:
        proceed.disabled = () => !this.statement || !this.statement.year;
        back.label = "COMMON.GO_TO_DASHBOARD";
        if (!this.isLogged) {
          back = null;
        }
        break;
      case BasicSteps.APPLICANT_INFO:
        proceed.disabled = () => this.validateApplicantInfo();
        break;
      case BasicSteps.QUESTION_ABOUT_CAN_BE_FILLED:
        proceed.disabled = () => this.statement && typeof this.statement.canBeFilled !== "boolean";
        break;
    }

    this.buttons = [];

    if (back) {
      this.buttons.push(back);
    }

    if (proceed) {
      this.buttons.push(proceed);
    }
  }

  private validateApplicantInfo(): boolean {
    if (this.isLogged) {
      return !(this.personalInfoOnLogin.firstname && this.personalInfoOnLogin.lastname);
    }

    return !(
      this.personalInfoOnLogin.firstname &&
      this.personalInfoOnLogin.emailAddress &&
      ValidatorHelper.emailInputValidation(this.personalInfoOnLogin.emailAddress)
    );
  }

  private hasAuthDialogFlag(flag: AuthenticationDialogFlag): boolean {
    return (
      flag === AuthenticationDialogFlag.FORGOT ||
      flag === AuthenticationDialogFlag.REGISTRATION ||
      flag === AuthenticationDialogFlag.LOGIN
    );
  }

  activateReferalCodePopup() {
    this.couponHttpService
      .getCoupons()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response) => {
          this.couponsArr = response;
          if (this.couponsArr?.length == 0) {
            const modal = this.dialog.open(CouponsPopup, { panelClass: "mat-coupons-popup" });
            setTimeout(() => {
              modal;
            }, 500);
          }
        }
      });
  }
}

export interface CloseLoginDialogValue {
  next: boolean;
  statement?: Statement;
  employers?: Employer[];
}
