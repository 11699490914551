import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Statement} from '../statement';
import {StatementService} from '../statement.service';
import {catchError, finalize, map, takeUntil} from 'rxjs/operators';
import {SubscribingComponent} from '../../common/subscribing-component';
import {PackageOptions} from '../package-options';
import {FirebaseHttpService} from 'app/firebase/firebase.http.service';
import {getUserType, State} from 'app/reducers';
import {AuthHttpService, User, UserDataService} from 'app/users';
import {Store} from '@ngrx/store';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ALLOWED_EXTENSION, getApiPath, SnackbarConfig} from 'app/common';
import {SHOW_SNACKBAR} from 'app/reducers/actions/snackbar';
import {of} from "rxjs";
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';

interface Forms {
  applicant: UntypedFormGroup;
  spouse: UntypedFormGroup;
}

enum MethodOptions {
  ENTER_OPTION = 'enter',
  UPLOAD_OPTION = 'upload',
  SKIP_OPTION = 'skip',
}

@Component({
  selector: 'app-tax-advisor-additional-validation',
  templateUrl: './tax-advisor-additional-validation.component.html',
  styleUrls: ['./tax-advisor-additional-validation.component.scss']
})
export class TaxAdvisorAdditionalValidationComponent extends SubscribingComponent implements OnInit {
  public packageOption = PackageOptions;

  public statementId: number;

  public statement: Statement = null;
  public userData: User;
  public loadingData = false;
  public loadingStatementData = true;
  public contentLoaded = false;
  public isBusiness = false;
  public uploadingScan = false;
  public buttons: any[] = [];
  public methodOptions = MethodOptions;

  loading = true;

  public forms: Forms = {
    applicant: new UntypedFormGroup({
      method: new UntypedFormControl(null),
      identificationNumber: new UntypedFormControl(null, Validators.required),
      cartIdFile: new UntypedFormControl(null),
      skip: new UntypedFormControl(null),
    }),
    spouse: new UntypedFormGroup({
      method: new UntypedFormControl(null),
      identificationNumber: new UntypedFormControl(null, Validators.required),
      cartIdFile: new UntypedFormControl(null),
      skip: new UntypedFormControl(null),
    })
  };

  private httpResponse = {
    handle: (response, data) => {
      if (response['empty']) {
        return;
      }

      if (this.statement) {
        if (data.applicant) {
          this.statement.identifikationsnummer = data.applicant;
        }
        if (data.applicant_has_no_id) {
          this.statement.hasNoId = data.applicant_has_no_id;
        }
        if (this.statement.spouse) {
          if (data.spouse) {
            this.statement.spouse.identifikationsnummer = data.spouse;
          }
          if (data.spouse_has_no_id) {
            this.statement.spouse.hasNoId = data.spouse_has_no_id;
          }
        }
      }
    },
    error: (error) => {
      if (error && error.error) {
        this.forms.applicant.get('identificationNumber').setErrors(null);
        this.forms.spouse.get('identificationNumber').setErrors(null);
        if (error.error['identifikationsnummer']) {
          this.forms.applicant.get('identificationNumber')
            .setErrors([error.error['identifikationsnummer']]);
        }
        if (error.error['spouse_identifikationsnummer']) {
          this.forms.spouse.get('identificationNumber')
            .setErrors([error.error['spouse_identifikationsnummer']]);
        }
      }
      console.log(error);
      this.contentLoaded = true;
      this.setButtons();
    }
  };

  private pdf = {
    isValid: (file: File): boolean =>
      ALLOWED_EXTENSION.includes(file.name.split('.').pop().toLocaleLowerCase()),

    upload: (file: File, spouse: boolean = false): any => {
      if (!file) {
        return of({'empty': true}).pipe();
      }
      this.contentLoaded = false;
      this.uploadingScan = true;
      this.setButtons();

      const statement = spouse ? this.statement.spouse.id : this.statement.id;

      return  this.statementService.uploadStatement(file, statement, false, true)
        .pipe(map((response: Response) => response), catchError(error => of(error)));
    },

    onSuccess: (response, spouse: boolean): void => {
      if (response['empty']) {
        return;
      }

      this.setButtons();
      this.uploadingScan = false;

      if (spouse) {
        this.statement.spouse.hasUploadedFile = true;
      } else {
        this.statement.hasUploadedFile = true;
      }
    },

    onError: (error, spouse: boolean = false): void => {
      this.setButtons();
      this.uploadingScan = false;

      if (error && error.error && Array.isArray(error.error) && error.error[0]) {
        if (spouse) {
          this.forms.spouse.get('cartIdFile').setErrors({'errorText': error.error[0]});
        } else {
          this.forms.applicant.get('cartIdFile').setErrors({'errorText': error.error[0]});
        }
      } else {
        if (spouse) {
          this.forms.spouse.get('cartIdFile').setErrors({'error': true});
        } else {
          this.forms.applicant.get('cartIdFile').setErrors({'error': true});
        }
      }

      console.error(error);
    }
  };

  constructor(
    private router: Router,
    private statementService: StatementService,
    private route: ActivatedRoute,
    private firebaseService: FirebaseHttpService,
    private store: Store<State>,
    private userDataService: UserDataService,
    private translateService: TranslateService,
    private authHTTP: AuthHttpService,
    public workFlowController: WorkflowControllerService,
  ) {
    super();
  }

  public isApplicant(): boolean {
    return !!this.statement
      && !this.statement.identifikationsnummer
      && !this.statement.hasUploadedFile
      && !this.statement.hasNoId;
  }

  public isSpouse(): boolean {
    return !!this.statement
      && this.statement.spouse
      && !this.statement.spouse.identifikationsnummer
      && !this.statement.spouse.hasUploadedFile
      && !this.statement.spouse.hasNoId;
  }

  public isApplicantHasIncomes(): boolean {
    if (!this.statement.employers || !this.statement.employers.length) {
      return false;
    }

    return !!this.statement
      && !!this.statement.employers
      && Array.isArray(this.statement.employers)
      && this.statement.employers.length > 0;
  }

  public isSpouseHasIncomes(): boolean {
    return !!this.statement
      && this.statement.spouse
      && !!this.statement.spouse.employers.length;
  }

  public onPDFUpload($event, spouse = false): void {
    if ($event.target.files.length > 0) {
      const file: File = $event.target.files.item(0);

      if (spouse) {
        this.forms.spouse.get('cartIdFile').setErrors(null);
      } else {
        this.forms.applicant.get('cartIdFile').setErrors(null);
      }

      if (!this.pdf.isValid(file)) {
        this.store.dispatch({
          type: SHOW_SNACKBAR,
          payload: new SnackbarConfig('SNACKBAR.INVALID_EXTENSION', 'ERROR')
        });
        if (spouse) {
          this.forms.spouse.get('cartIdFile').setErrors(['error']);
        } else {
          this.forms.applicant.get('cartIdFile').setErrors(['error']);
        }
      } else {
        if (spouse) {
          this.forms.spouse.get('cartIdFile').patchValue(file);
        } else {
          this.forms.applicant.get('cartIdFile').patchValue(file);
        }
      }
    }

    this.setButtons();
  }

  ngOnInit(): void {
    this.userDataService.getUserData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.userData = user;
      });

    this.store.select(getUserType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userType: string) => {
        if (userType) {
          this.isBusiness = userType === 'business';
        }
      });

    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.statementId = parseInt(params['statementId'], 10);
        this.getData();
      });

    this.setButtons();
  }

  private goNext(statement: Statement) {
    this.contentLoaded = true;
    if (!statement) {
      return;
    }

    Object.assign(this.statement, statement);

    if (!statement.identificationNumberIsMissing) {
      const handleResponse = (response) => {
        this.statement.hasVerifiedIdentity = response['has_verified_identity'];
        this.workFlowController.goToTheNextStep();
      };

      const handleErrors = (errors) => {
        this.contentLoaded = true;
        console.log(errors);
      };

      this.contentLoaded = false;
      this.statementService.checkOnfidoVollmacht(this.statementId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(handleResponse, handleErrors);
    }
  }

  private clear(): void {
    if ( this.forms.applicant.get('method').value !== MethodOptions.ENTER_OPTION ) {
      this.forms.applicant.get('identificationNumber').patchValue(null);
    }
    if ( this.forms.applicant.get('method').value !== MethodOptions.UPLOAD_OPTION ) {
      this.forms.applicant.get('cartIdFile').patchValue(null);
    }
    if ( this.forms.spouse.get('method').value !== MethodOptions.ENTER_OPTION ) {
      this.forms.spouse.get('identificationNumber').patchValue(null);
    }
    if ( this.forms.spouse.get('method').value !== MethodOptions.UPLOAD_OPTION ) {
      this.forms.spouse.get('cartIdFile').patchValue(null);
    }
  }

  private getData() {
    this.loadingData = true;

    const error = () => {
      this.contentLoaded = true;
      this.router.navigate(['/new']);
    };

    const handle = (response: Statement): void => {
      this.contentLoaded = true;
      this.statement = response;
      this.workFlowController.init(this.statement);

      this.goNext(response);

      if (response.identifikationsnummer) {
        this.forms.applicant.get('method').patchValue(MethodOptions.ENTER_OPTION);
        this.forms.applicant.get('identificationNumber').patchValue(response.identifikationsnummer);
      }

      if (response.isSpouse() && response.spouse.identifikationsnummer) {
        this.forms.spouse.get('method').patchValue(MethodOptions.ENTER_OPTION);
        this.forms.spouse.get('identificationNumber').patchValue(response.identifikationsnummer);
      }
    };

    this.statementService
      .getStatement(this.statementId)
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.loadingData = false))
      .subscribe(handle, error);
  }

  private setButtons(): void {
    this.buttons = [
      {
        type: 'proceed',
        label: 'COMMON.PROCEED',
        action: this.proceed.bind(this),
        disabled: () => this.formValidation()
      }
    ];
  }

  private formValidation(): boolean {
    const applicant = (): boolean => {
      if (!this.statement) {
        return false;
      }  else if (this.statement.hasUploadedFile) {
        return false;
      } else if (this.statement.identifikationsnummer) {
        return false;
      } else if (this.statement.hasNoId) {
        return false;
      } else if (this.forms.applicant.get('method').value === MethodOptions.UPLOAD_OPTION) {
        return !(this.forms.applicant.get('cartIdFile').valid && this.forms.applicant.get('cartIdFile').value);
      } else if (this.forms.applicant.get('method').value === MethodOptions.SKIP_OPTION) {
        return false;
      } else {
        return !(this.forms.applicant.get('method').value === MethodOptions.ENTER_OPTION
          && this.forms.applicant.get('identificationNumber').valid);
      }
    };

    const spouse = (): boolean => {
      if (!!this.statement && !this.statement.spouse) {
        return false;
      } else if (this.statement.spouse.hasUploadedFile) {
        return false;
      } else if (this.statement.spouse.identifikationsnummer) {
        return false;
      } else if (this.statement.spouse.hasNoId) {
        return false;
      } else if (this.forms.spouse.get('method').value === MethodOptions.UPLOAD_OPTION) {
        return !(this.forms.spouse.get('cartIdFile').valid && this.forms.spouse.get('cartIdFile').value);
      } else if (this.forms.spouse.get('method').value === MethodOptions.SKIP_OPTION) {
        return false;
      } else {
        return !(this.forms.spouse.get('method').value === MethodOptions.ENTER_OPTION
          && this.forms.spouse.get('identificationNumber').valid);
      }
    };

    return applicant() || spouse();
  }

  private get getRequest() {
    const applicant = this.forms.applicant.value;
    const spouse = this.forms.spouse.value;
    const formData: FormData = new FormData();

    if (applicant.method) {
      if (applicant.identificationNumber) formData.append('applicant', applicant.identificationNumber);
      if (applicant.cartIdFile) formData.append('applicant_tax_card_file', applicant.cartIdFile, applicant.cartIdFile.name);
      if (applicant.method === MethodOptions.SKIP_OPTION) formData.append('applicant_has_no_id', 'true');
    }

    if (spouse.method) {
      if (spouse.identificationNumber) formData.append('spouse', spouse.identificationNumber);
      if (spouse.cartIdFile) formData.append('spouse_tax_card_file', spouse.cartIdFile, spouse.cartIdFile.name);
      if (spouse.method === MethodOptions.SKIP_OPTION) formData.append('spouse_has_no_id', 'true');
    }

    return formData;
  }

  private proceed(): void {
    this.clear();

    if (this.formValidation()) {
      return;
    }

    const request = this.getRequest;
    this.contentLoaded = false;

    if (this.formIsValid) {
      this.updateStatement(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
        this.goNext(Statement.fromResponse(response));
      }, this.httpResponse.error);
    } else {
      this.contentLoaded = true;
    }
  }

  private get formIsValid(): boolean {
    const applicant = this.forms.applicant.value;
    const applicantIsValid = this.isApplicant()
      ? !!applicant.identificationNumber || !!applicant.cartIdFile || applicant.method === MethodOptions.SKIP_OPTION
      : true;

    const spouse = this.forms.spouse.value;
    const spouseIsValid = this.isSpouse()
      ? !!spouse.identificationNumber || !!spouse.cartIdFile || spouse.method === MethodOptions.SKIP_OPTION
      : true;

    return applicantIsValid && spouseIsValid;
  }

  private updateStatement(data): any {
    return this.authHTTP.put(`${getApiPath()}statements/${this.statement.id}/update-identification-number/`, data);
  }
}
