
import {takeUntil} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {TranslateService} from '@ngx-translate/core';

import {UserDataService} from '../../users/user-data.service';
import {SubscribingComponent} from '../../common';
import {AuthService} from '../../users/auth.service';

@Component({
  selector: 'app-license-agreement',
  templateUrl: './license-agreement.component.html',
  styleUrls: ['./license-agreement.component.scss']
})
export class LicenseAgreementComponent extends SubscribingComponent implements OnInit {

  language = 'EN';
  LANGUAGES = {
    'pl': 'PL',
    'en': 'EN'
  };

  constructor(private dialogRef: MatDialogRef<LicenseAgreementComponent>,
              private userDataService: UserDataService,
              private authService: AuthService,
              private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.language = this.LANGUAGES[this.translate.currentLang] || this.language;
  }

  decline() {
    this.authService.logout();
    this.dialogRef.close(false);
  }

  accept() {
    this.userDataService.acceptLicenseAgreement().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.dialogRef.close(true));
  }

}
