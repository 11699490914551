<ng-container *ngIf="!contentLoaded">
    <app-content-loader></app-content-loader>
</ng-container>
<ng-container *ngIf="contentLoaded">
    <div class="step-container columns is-desktop">
        <app-aside-bar
          [showEvenWithoutStatement]="false"
          [statement]="statement"
          [isBusiness]="isBusiness"
          [workFlowController]="workFlowController"
        >
        </app-aside-bar>
        <div class="verification">
            <div class="verification__form" [class.no_margin]="!isBusiness">
                <h1 class="title_42">
                    {{ 'PERSONAL_INFO.BANK_DETAILS' | translate }}
                </h1>
                <div class="verification__stage" *ngIf="statement && statement.employers">
                    <app-bank-details [statement]="statement" [errors]="errors" [appShowFormError] [isBusiness]="isBusiness"
                        [iban]="statement.iban">
                    </app-bank-details>
                </div>
            </div>
        </div>
        <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
    </div>
</ng-container>
