import { Component } from '@angular/core';
import { ButtonBaseComponent } from 'app/shared/components/buttons/button-base.component';

@Component({
  ...ButtonBaseComponent.metaData,
  selector: 'app-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss']
})
export class ButtonLinkComponent extends ButtonBaseComponent {
}
