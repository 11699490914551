export interface PaymentResponse {
  id: number;
  amount: number;
  quantity: number;
  currency_code: string;
  creation_date: string;
  cleared_date: string;
  status: string;
  can_download_invoice: boolean;
}

export class Payment {
  id: number;
  amount: number;
  quantity: number;
  currency: string;
  creationDate: string;
  clearedDate: string;
  status: string;
  canDownloadInvoice: boolean;

  static fromResponse(response: PaymentResponse): Payment {
    const payment = new Payment();
    payment.id = response.id;
    payment.quantity = response.quantity;
    payment.amount = response.amount;
    payment.currency = response.currency_code;
    payment.creationDate = response.creation_date;
    payment.clearedDate = response.cleared_date;
    payment.status = response.status;
    payment.canDownloadInvoice = response.can_download_invoice;
    return payment;
  }
}

export enum PaymentPlatforms {
  paypal = 'paypal',
  przelewy24 = 'przelewy24',
  stripe = 'stripe',
  klarna= 'klarna'
}

export interface PaymentChoiceInterface {
  name: string;
  platform: PaymentPlatforms;
  ico: string;
  order: number[]; // Web, Ios, Android, B2B
  showOnPlatform: boolean[]; // Web, Ios, Android
  isStb: boolean; // if True sort list for tax adviser
  isB2B: boolean; // if True sort list for b2b
  hasCoupon: boolean; // if True sort list for coupons
}

export const PaymentChoices: PaymentChoiceInterface[] = [
  {
    name: 'Paypal',
    platform: PaymentPlatforms.paypal,
    ico: '/assets/svg/paypal_logo.svg',
    order: [2, 2, 2, 0],
    showOnPlatform: [true, true, true],
    isStb: true,
    isB2B: false,
    hasCoupon: true,
  },
  {
    name: 'Klarna',
    platform: PaymentPlatforms.klarna,
    ico: '/assets/svg/klarna.svg',
    order: [3, 3, 3, 1],
    showOnPlatform: [true, true, true],
    isStb: true,
    isB2B: true,
    hasCoupon: true,
  },
  {
    name: 'Przelewy 24',
    platform: PaymentPlatforms.przelewy24,
    ico: '/assets/svg/przelewy24_logo.svg',
    order: [6, 6, 6, 4],
    showOnPlatform: [true, true, true],
    isStb: false,
    isB2B: true,
    hasCoupon: true,
  },
  {
    name: 'Credit/Debit Card',
    platform: PaymentPlatforms.stripe,
    ico: '/assets/svg/debit_card.svg',
    order: [4, 4, 4, 2],
    showOnPlatform: [true, true, true],
    isStb: true,
    isB2B: true,
    hasCoupon: true,
  },
  {
    name: 'Apple Pay',
    platform: PaymentPlatforms.stripe,
    ico: '/assets/svg/Apple_Pay_Mark_RGB_041619.svg',
    order: [0, 1, 0, 0],
    showOnPlatform: [false, true, false],
    isStb: true,
    isB2B: false,
    hasCoupon: true,
  },
  {
    name: 'Google Pay',
    platform: PaymentPlatforms.stripe,
    ico: '/assets/svg/google-pay-mark_800_gray.svg',
    order: [0, 0, 1, 0],
    showOnPlatform: [false, false, true],
    isStb: true,
    isB2B: false,
    hasCoupon: true,
  },
];

export function preparePaymentChoicesList(
  options: {
    paymentChoices?: PaymentChoiceInterface[],
    isWeb?: boolean,
    isBusiness?: boolean,
    isIos?: boolean,
    isAndroid?: boolean,
  } = {}
): PaymentChoiceInterface[] {
  const paymentChoices = options.paymentChoices || PaymentChoices;
  const isWeb = options.isWeb || true;
  const isBusiness = options.isBusiness || false;
  const isIos = options.isIos || false;
  const isAndroid = options.isAndroid || false;

  const result = paymentChoices.filter(x => {
    if (isWeb) {
      return x.showOnPlatform[0];
    } else if (isIos) {
      return x.showOnPlatform[1];
    } else if (isAndroid) {
      return x.showOnPlatform[2];
    } else if (isBusiness) {
      return x.isB2B;
    }

    return true;
  });

  return result.sort((k, l) => {
    if (isWeb) {
      return k.order[0] >= l.order[0] ? 1 : -1;
    } else if (isIos) {
      return k.order[1] >= l.order[1] ? 1 : -1;
    } else if (isAndroid) {
      return k.order[2] >= l.order[2] ? 1 : -1;
    } else if (isBusiness) {
      return k.order[3] >= l.order[3] ? 1 : -1;
    }

    return 1;
  });
}
