<div class="dialog-box">
    <div class="dialog-box__content">
        <div class="title_dialog">
            <img src="../../../assets/svg/send_cert.svg">
            <span>{{ 'SEND_WITH_CERT.TITLE' | translate }}</span>
            <button mat-icon-button mat-dialog-close tabindex="-1">
                <mat-icon aria-hidden="true">close</mat-icon>
            </button>
        </div>
        <div class="send-with-cert">
            <div class="send-with-cert__stage form-content flex_box">
                <div class="left_wrap">    
                    <label class="file-upload-button">
                        <input type="file" accept=".pfx" (change)="onCertSelect()" #certField>
                        <div class="label_text">
                            {{ 'SEND_WITH_CERT.LABEL_UPLOAD' | translate }}
                        </div>
                        <div class="btn_upload" *ngIf="!sending">
                            {{ 'SEND_WITH_CERT.UPLOAD' | translate }}
                        </div>
                        <button class="btn_upload" disabled *ngIf="sending">
                            {{ 'SEND_WITH_CERT.UPLOAD' | translate }}
                        </button>
                    </label>
                   
                    <div class="send-with-cert__cert" *ngIf="cert">
                        <span class="send-with-cert__cert-title">{{ 'SEND_WITH_CERT.CERT' | translate }}:</span>
                        <span class="send-with-cert__cert-name">{{ cert.name }}</span>
                    </div>
                </div>

                <div class="right_wrap">
                    <app-form-input
                            [label]="'PIN:'"
                            [(model)]="pin"
                            [inputType]="'password'"
                            [placeholder]="'SEND_WITH_CERT.ENTER_PIN'"
                            [certInput]="true"
                    ></app-form-input>
                </div>
            </div>
            <ng-container *ngIf="errors">
                <div class="send-with-cert__stage" *ngIf="errors[0]">
                    <div class="alert-box">{{ errors[0] }}</div>
                </div>
            </ng-container>
            <div class="send-with-cert__stage" *ngIf="info">
                <div class="alert-box alert-box--info">{{ info }}</div>
            </div>
           
            <div class="dialog-box__form-buttons">
                <button class="btn btn--secondary btn--small"  mat-dialog-close>{{ 'COMMON.CANCEL' | translate }}</button>
                <button class="btn btn--primary btn--longer" (click)="onSubmit()" [disabled]="sending">
                    {{ 'COMMON.SAVE' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
