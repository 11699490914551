export interface TaxAuthorityResponse {
    id: number;
    name: string;
}

export class TaxAuthority {
    id: number;
    name: string;

    static fromResponse(response: TaxAuthorityResponse): TaxAuthority {
        const taxAuthority = new TaxAuthority();
        taxAuthority.id = response.id;
        taxAuthority.name = response.name;
        return taxAuthority;
    }
}
