import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FirebaseEventsService } from '../firebase-events.service';

@Component({
  selector: 'app-ocr-failed-dialog',
  templateUrl: './ocr-failed-dialog.component.html',
  styleUrls: ['./ocr-failed-dialog.component.scss']
})

export class OcrFailedDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<OcrFailedDialogComponent>,
    private firebaseEventsService: FirebaseEventsService,
  ) {}

  ngOnInit() {
    this.firebaseEventsService.logEvent('lohnsteuer_unsuccess_pdf', null);
  }
}
