<ng-container *ngIf="displayJobSummit()">
  <app-ctr-base
    [templateRef]="freelanceJob"
    [title]="title"
    [categoryProperties]="categoryProperties"
    [dataSelects]="dataSelects"
    [summary]="summary"
  ></app-ctr-base>

  <ng-template #freelanceJob>
    <div class="card card-white shadow-0 mb-2" id="templateID()">
      <div class="card__row mb-1" *ngFor="let item of businessNameList; let index = index">
        <div class="table">
          <div class="table__row" *ngIf="displayElement()">
            <div class="table__col">
              <div class="row__title"
                ><strong>{{ "CONFIRM_TAX_RETURN.FREELANCE_JOBS.BUSINESS_NAME" | translate }}</strong></div
              >
            </div>
            <div class="table__col d-flex">
              <input
                [attr.data-cy]="'verificationFreelanceJobTotalAmount_' + person"
                readonly
                value="{{ displayBusinessName(index) }} "
              />
            </div>
          </div>
          <div class="table__row" *ngIf="displayElement()">
            <div class="table__col">
              <div class="row__title">{{ "CONFIRM_TAX_RETURN.FREELANCE_JOBS.INCOME_SUM" | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input
                [attr.data-cy]="'verificationFreelanceJobTotalAmount_' + person"
                readonly
                value="{{ displayIncomeSum(index) | number: '1.2-2' }} €"
              />
            </div>
          </div>
          <div class="table__row" *ngIf="displayElement()">
            <div class="table__col">
              <div class="row__title">{{ "CONFIRM_TAX_RETURN.FREELANCE_JOBS.COST_SUM" | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input
                [attr.data-cy]="'verificationFreelanceJobTotalAmount_' + person"
                readonly
                value="{{ displayCostSum(index) | number: '1.2-2' }} €"
              />
            </div>
          </div>
          <div class="table__row">
            <div class="table__col">
              <div class="row__title">{{ "CONFIRM_TAX_RETURN.FREELANCE_JOBS.TOTAL_AMOUNT" | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input
                [attr.data-cy]="'verificationFreelanceJobTotalAmount_' + person"
                readonly
                value="{{ displaySum(index) | number: '1.2-2' }} €"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
