import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { TooltipBaseComponent } from 'app/common/tooltip-base/tooltip-base.component';
import { CurrencyUtil } from 'app/common/currency.util';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { ClickElsewhereDirective } from 'app/shared/standalone/directives/click-elsewhere.directive';
import { CommonModule } from '@angular/common';
import { MoreInfoTooltipComponent } from 'app/shared/standalone/components/more-info-tooltip/more-info-tooltip.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-form-input-reactive',
  templateUrl: './form-input-reactive.component.html',
  styleUrls: ['./form-input-reactive.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ClickElsewhereDirective,
    MoreInfoTooltipComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ]
  // viewProviders: [
  //   {
  //     provide: ControlContainer,
  //     useFactory: (container: ControlContainer) => container,
  //     deps: [[new SkipSelf(), ControlContainer]]
  //   }
  // ],
})
export class FormInputReactiveComponent extends TooltipBaseComponent implements OnInit, OnChanges {
  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() model: any;
  @Output() modelChange = new EventEmitter();
  @Output() blur = new EventEmitter();
  get errors(): any {
    return this._errors;
  }
  @Input() set errors(_errors: any) {
    this._errors = _errors && _errors.length ? _errors : null;
  }
  @Input() isCurrency: boolean;
  @Input() currencyCode: string;
  @Input() placeholder: string;
  @Input() isDisabled = false;
  @Input() isReadonly = false;
  @Input() isLoading = false;
  @Input() inputType = 'text';
  @Input() isVerification = false;
  @Input() currentInputId = null;
  @Input() centerLabel = false;
  @Input() centerTxtInput = false;
  @Input() certInput = false;
  @Input() addClassB2B = false;
  @Input() classB2B = '';
  @Input() textArea = false;
  @Input() employerStage = false;
  @Input() isEmployer = false;
  @Input() isEditableSummaryEmployer = false;
  @Input() hiddenTooltipOnMobile = false;
  @Input() disableLabel = false;
  @Input() maxLength = 0;
  @Input() required?: boolean;
  @Input() autofocus?: boolean;
  @Input() appShowPassword?: boolean;
  @Input() name?: string;
  @Input() autocomplete?: string;
  @Input() inputMode?: string;
  @Input() dataCyName: string;
  @Input() iconSrc?: string;
  public activeInput = false;
  public inputChange = false;
  public isNotDefault = false;
  public showPassword = false;
  private _errors: any;

  constructor(
    private util: CurrencyUtil
  ) {
    super();
  }

  ngOnInit() {
    // show/hide placeholder and active line if input is not empty///
    if (this.model !== undefined && this.model !== null) {
      if (this.model.toString() !== '') {
        this.inputChange = true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.model) {
      this.inputChange = true;
    }
  }

  public change(newValue: any): void {
    if (this.isDisabled || this.isReadonly) {
      return;
    }
    if (this.isCurrency) {
      newValue = this.util.replaceLimiters(newValue);
    }
    this.model = newValue;
    this.modelChange.emit(newValue);

    this.inputChange = newValue.toString() !== '';
  }

  public onBlur($event: any) {
    const thisItem = $event.target;

    this.activeInput = false;

    if (thisItem.value.toString() === '') {
      this.inputChange = false;
    }
    if (this.isEmployer) {
      if (thisItem.value.toString() === '') {
        thisItem.value = '0.00';
        this.isNotDefault = false;
        this.modelChange.emit(thisItem.value);
        this.inputChange = true;
      }
    }

    this.blur.emit();
  }

  public onFocus($event: any) {
    const thisItem = $event.target;
    this.activeInput = true;
    if (this.isEmployer) {
      this.isNotDefault = true;
      if (thisItem.value.toString() === '0.00' || thisItem.value.toString() === '0') {
        thisItem.value = '';
        this.inputChange = false;
      }
    }
  }

  setCurrencyCode() {
    return !this.currencyCode ? '€' : this.currencyCode === 'EUR' ? '€' : this.currencyCode;
  }

  public showPasswordToggle(): void {
    this.showPassword = !this.showPassword;
  }

  public get getInputType(): string {
    return this.inputType === 'password' && this.appShowPassword && this.showPassword ? 'text' : this.inputType;
  }
}
