<div class="step-container columns is-desktop">
  <app-aside-bar [isLogged]="isLogged" [isBusiness]="isBusiness"></app-aside-bar>
  <div class="starter-board" [class.container-user-not-logged]="!isLogged">
    <ng-container *ngIf="!isBusiness">
      <ng-container *ngIf="currentStep === STEPS.TAX_YEAR_SELECT">
        <h1 class="title_42">
          <span class="title__stage">{{ 'COMMON.HI' | translate }}</span>
          <span class="title__stage">{{ 'START.LETS_START' | translate }}</span>
        </h1>

        <div class="big-label-container" *ngIf="dataSelects">
          <div class="ep_new_tiles_wrap top">
            <ng-container *ngFor="let statementYear of dataSelects.statementYears; let key = index;">
              <div class="ep_new_single_tile_wrap">
                <div class="ep_new_single_tile center" (click)="setStatementYear(statementYear)"
                     [class.active]="statement.year === statementYear.id || statementYear.active">
                  <div class="ep_desc_wrap slg">
                    <div class="icon_tile_wrap slg">
                      <img src="../../../assets/svg/years_icons/year_vs{{key}}.svg"
                           class="icon_tile" />
                    </div>
                    <h3 class="title_tile_lg">{{statementYear.year}}</h3>
                  </div>
                </div>
                <div *ngIf="showEndingYear(key) > 0" class="tooltip_w_desc_wrap">
                  <div class="desc">
                    {{ 'START.LAST_YEAR_INFO_PART1' | translate }}
                    {{showEndingYear(key)}}
                    {{ 'START.LAST_YEAR_INFO_PART2' | translate }}
                  </div>
                  <div class="tooltip_wrap">
                    <app-more-info-tooltip [alignLeft]="true" [isSmall]="true" [isSmallIcon]="true"
                                           [showText]="false" [message]="'START.LAST_YEAR_INFO_TOOLTIP'">
                    </app-more-info-tooltip>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentStep === STEPS.APPLICANT_INFO">
        <div class="step_wrapper">
          <div class="step_cont">
            <h1 class="title_42">
              <span class="title__stage">{{ 'EMPLOYER.NOT_LOGEDIN_FORM_TITLE' | translate }}</span>
            </h1>
            <div class="form_wrap">
              <div class="form_col">
                <app-form-input
                  [errors]=""
                  [label]="'EMPLOYER.NOT_LOGEDIN_FORM_FIRST_NAME_LABEL'"
                  [placeholder]="'EMPLOYER.NOT_LOGEDIN_FORM_FIRST_NAME'"
                  [(model)]="personalInfoOnLogin.firstname"
                  (blur)="onBlurPersonalInfoOnLogin()"
                  [autocomplete]="'given-name'"
                  [name]="'firstName'"
                >
                </app-form-input>
              </div>

              <div class="form_col" *ngIf="isLogged">
                <app-form-input
                  [errors]=""
                  [label]="'EMPLOYER.NOT_LOGEDIN_FORM_LAST_NAME_LABEL'"
                  [placeholder]="'EMPLOYER.NOT_LOGEDIN_FORM_LAST_NAME'"
                  [(model)]="personalInfoOnLogin.lastname"
                  (blur)="onBlurPersonalInfoOnLogin()"
                  [autocomplete]="'family-name'"
                  [name]="'lastName'"
                >
                </app-form-input>
              </div>

              <div class="form_col full">
                <app-form-input
                  *ngIf="!isLogged"
                  [errors]=""
                  [label]="'EMPLOYER.NOT_LOGEDIN_FORM_EMAIL_LABEL'"
                  [placeholder]="'EMPLOYER.NOT_LOGEDIN_FORM_EMAIL'"
                  [(model)]="personalInfoOnLogin.emailAddress"
                  (blur)="onBlurPersonalInfoOnLogin()"
                  [inputType]="'email'"
                  [autocomplete]="'email'"
                  [name]="'email'"
                >
                </app-form-input>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentStep === STEPS.QUESTION_ABOUT_CAN_BE_FILLED && isLogged">
        <div class="step_wrapper">
          <div class="step_cont">
            <div class="title_w_tooltip">
              <h1 class="title_42 mb-0_625">
                {{ 'EMPLOYER.STEP_0_TITLE_1' | translate }}{{ personalInfoOnLogin.firstname }}
                <br>{{ 'EMPLOYER.STEP_0_TITLE_2' | translate }}
                <span class="tooltip_wrap">
                    <app-more-info-tooltip
                      [titleTooltip]="'EMPLOYER.STEP_0_TOOLTIP_TITLE'"
                      [message]="'EMPLOYER.STEP_0_TOOLTIP_DESC'"
                      [isInnerHTML]="true"
                    >
                    </app-more-info-tooltip>
                  </span>
              </h1>
            </div>

            <h4 class="desc_20 mb-2">
              <span class="title__stage">{{ 'EMPLOYER.STEP_0_DESCRIPTION' | translate }}</span>
            </h4>

            <app-button-radio-two
              [selectedOption]="statement.canBeFilled"
              (clickEmitter)="setCanBeFilled($event)"
            ></app-button-radio-two>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isBusiness">
      <div class="starter_business_wrap">
        <h1 class="title">
                    <span class="title__stage">
                        <span class="welcome">{{ 'COMMON.HI' | translate }}</span> {{ user?.firstName }}, <span>{{ 'START.LETS_START_WITH' |
                      translate }}</span>
                    </span>
          <span class="title__stage">{{ 'START.BUSINESS_TAXCARD' | translate }}</span>
        </h1>
        <div class="description">{{ 'START.BUSINESS_DESCRIPTION' | translate }}</div>
        <div class="starter-board__modes">
          <div class="form-content mx-0">
            <div class="starter-board__mode hide-ocr-ios">
              <div class="starter-board__mode-btn">
                <label class="file-upload-button">
                  <input
                    type="file"
                    accept="application/pdf,image/*"
                    [disabled]="uploadingScan"
                    (change)="onScanUpload($event.target)"
                  >
                  <a
                    class="btn btn--primary btn--block"
                    *ngIf="!uploadingScan"
                  >
                    {{ 'START.UPLOAD_TAXCARDS' | translate }}
                  </a>
                  <button class="btn btn--primary btn--block" disabled *ngIf="uploadingScan">
                    {{ 'START.UPLOAD_TAXCARDS' | translate }}
                  </button>
                </label>
              </div>
              <div class="starter-board__mode-description is-small">
                {{ 'START.UPLOAD_TAXCARD_DESCRIPTION' | translate }}
              </div>
            </div>
            <div class="starter-board__mode hide-ocr-ios">
              <div class="starter-board__mode-btn">
                <label class="file-upload-button">
                  <input
                    type="file"
                    accept="application/json/*"
                    [disabled]="uploadingScan"
                    (change)="onScanUpload($event.target, true, true)"
                  >
                  <a
                    class="btn btn--primary btn--block"
                    *ngIf="!uploadingScan"
                  >
                    {{ 'START.UPLOAD_TAXCARDS_JSON' | translate }}
                  </a>
                  <button class="btn btn--primary btn--block" disabled *ngIf="uploadingScan">
                    {{ 'START.UPLOAD_TAXCARDS_JSON' | translate }}
                  </button>
                </label>
              </div>
              <div class="starter-board__mode-description is-small">
                {{ 'START.UPLOAD_TAXCARD_DESCRIPTION' | translate }}
              </div>
            </div>
            <div class="starter-board__mode">
              <div class="starter-board__mode-btn">
                <button class="btn btn--primary btn--block" [disabled]="creatingBatch"
                        (click)="createBatchManually()"
                        [attr.data-cy]="'btnCreateStatementBatchManually'"
                >
                  {{ 'START.CREATE_EMPTY_BATCH' | translate }}
                </button>
              </div>
              <div class="starter-board__mode-description is-small">
                {{ 'START.CREATE_EMPTY_BATCH_DESCRIPTION' | translate }}
              </div>
            </div>

          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <app-tools-bar *ngIf="!isBusiness" [statement]="statement" [buttons]="buttons" [isLogged]="isLogged"></app-tools-bar>
</div>
