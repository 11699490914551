import { Coupon } from 'app/statements/coupon';

export interface StatementPaymentAmountResponse {
  premium_amount_total: string;
  premium_amount_vat: string;
  standard_amount_total: string;
  standard_amount_vat: string;
  should_be_individual_service: boolean;
}

export interface StatementPaymentAmountInterface {
  premiumAmountTotal: number;
  premiumAmountVat: number;
  standardAmountTotal: number;
  standardAmountVat: number;
  premiumNet: string;
  standardNet: string;
  vatPercentFormatted: string;
  currencySymbol: string;
  shouldBeIndividualService: boolean;
  premiumAmountTotalFormatted(coupon?: Coupon): string;
  standardAmountTotalFormatted(coupon?: Coupon): string;
}

export class StatementPaymentAmount implements StatementPaymentAmountInterface {
  premiumAmountTotal = 0;
  premiumAmountVat = 0;
  standardAmountTotal = 0;
  standardAmountVat = 0;
  shouldBeIndividualService = false;

  premiumAmountTotalBase : number;
  standardAmountTotalBase : number;

  static fromResponse(response: StatementPaymentAmountResponse): StatementPaymentAmount {
    const statementPayment = new StatementPaymentAmount();
    statementPayment.premiumAmountTotal = Number(response.premium_amount_total);
    statementPayment.premiumAmountVat = Number(response.premium_amount_vat);
    statementPayment.standardAmountTotal = Number(response.standard_amount_total);
    statementPayment.standardAmountVat = Number(response.standard_amount_vat);
    statementPayment.shouldBeIndividualService = response.should_be_individual_service;

    statementPayment.premiumAmountTotalBase = Number(response.premium_amount_total);
    statementPayment.standardAmountTotalBase = Number(response.standard_amount_total);

    return statementPayment;
  }

  public get isFree(): boolean {
    return this.standardAmountTotal === 0;
  }

  public get vatPercentFormatted(): string {
    return (String)(Math.round(Number(this.premiumAmountVat) / Number(this.premiumAmountTotal) * 100)) + '%';
  }

  public get premiumNet(): string {
    return (Number(this.premiumAmountTotal) - Number(this.premiumAmountVat)).toFixed(2);
  }

  public get standardNet(): string {
    return (Number(this.standardAmountTotal) - Number(this.standardAmountVat)).toFixed(2);
  }

  public get currencySymbol(): string {
    return '€';
  }

  public standardAmountTotalFormatted(coupon?: Coupon): string {
    if (coupon && coupon.couponAmount) {
      return (Number(this.standardAmountTotal) - Number(coupon.amount(this.standardAmountTotal))).toFixed(2);
    }

    return this.standardAmountTotal.toFixed(2);
  }

  public premiumAmountTotalFormatted(coupon?: Coupon): string {
    if (coupon && coupon.couponAmount) {
      return (Number(this.premiumAmountTotal) - Number(coupon.amount(this.premiumAmountTotal))).toFixed(2);
    }

    return this.premiumAmountTotal.toFixed(2);
  }
}
