import { EventEmitter, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
  DISABLE_ANALYTICS_COOKIES_COOKIE_KEY,
  DISABLE_TARGETING_COOKIES_COOKIE_KEY,
} from 'app/common';
import { BehaviorSubject } from 'rxjs';
import { DateHelper } from 'app/core/helpers/date.helper';

@Injectable()
export class CookiesLawService {
  public changesEmitter = new EventEmitter();
  public isBannerRequired: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly analyticsCookiesKeysList = [
    '_ga',
    '_gid',
    '_dd_s',
  ];
  // "disable-targeting-cookies" is not in use now. May need to be removed.
  private readonly targetingCookiesKeysList = [
    '_gcl_au',
    '_fbp',
  ];

  constructor(
    private cookieService: CookieService,
  ) {
    setTimeout(() => {
      this.checkIfBannerIsRequired();
    }, 100);
  }

  public allowAll(): void {
    this.enableAnalytics();
    this.enableTargeting();
  }

  public disallowAll(): void {
    this.disableAnalytics();
    this.disableTargeting();
  }

  public checkIfBannerIsRequired(): void {
    this.isBannerRequired.next(
      !(
        ['true', 'false'].indexOf(this.cookieService.get(DISABLE_ANALYTICS_COOKIES_COOKIE_KEY)) !== -1
        && ['true', 'false'].indexOf(this.cookieService.get(DISABLE_TARGETING_COOKIES_COOKIE_KEY)) !== -1
      )
    );
  }

  public activeCookieBanner(): void {
    this.isBannerRequired.next(
      (
        ['true', 'false'].indexOf(this.cookieService.get(DISABLE_ANALYTICS_COOKIES_COOKIE_KEY)) !== -1
        && ['true', 'false'].indexOf(this.cookieService.get(DISABLE_TARGETING_COOKIES_COOKIE_KEY)) !== -1
      )
    );
  }

  public disableAnalytics(): void {
    this.cookieService.set(
      DISABLE_ANALYTICS_COOKIES_COOKIE_KEY,
      'true',
      {
        expires: DateHelper.getInstance().add(1, 'year').toDate(),
        path: '/',
        secure: true,
        sameSite: 'Strict'
      }
    );

    this.removeAnalyticsCookies();
    this.removeTargetingCookies();

    this.onChanges();
  }

  public disableTargeting(): void {
    this.cookieService.set(
      DISABLE_TARGETING_COOKIES_COOKIE_KEY,
      'true',
      {
        expires: DateHelper.getInstance().add(1, 'year').toDate(),
        path: '/',
        secure: true,
        sameSite: 'Strict'
      }
    );

    this.removeTargetingCookies();

    this.onChanges();
  }

  public get isAnalyticsEnabled(): boolean {
    return this.cookieService.get(DISABLE_ANALYTICS_COOKIES_COOKIE_KEY) === 'false';
  }

  public get isTargetingEnabled(): boolean {
    return this.cookieService.get(DISABLE_TARGETING_COOKIES_COOKIE_KEY) === 'false';
  }

  public enableAnalytics(): void {
    this.cookieService.set(
      DISABLE_ANALYTICS_COOKIES_COOKIE_KEY,
      'false',
      {
        expires: DateHelper.getInstance().add(1, 'year').toDate(),
        path: '/',
        secure: true,
        sameSite: 'Strict'
      }
    );

    this.onChanges();
  }

  public enableTargeting(): void {
    this.cookieService.set(
      DISABLE_TARGETING_COOKIES_COOKIE_KEY,
      'false',
      {
        expires: DateHelper.getInstance().add(1, 'year').toDate(),
        path: '/',
        secure: true,
        sameSite: 'Strict'
      }
    );

    this.onChanges();
  }

  public removeAnalyticsCookies(): void {
    this.analyticsCookiesKeysList.forEach(key => {
      this.cookieService.delete(key);
    });

    Object.keys(this.cookieService.getAll()).forEach(key => {
      if (key.indexOf('_ga') === 0) {
        this.cookieService.delete(key);
      }
    });
  }

  public removeTargetingCookies(): void {
    this.targetingCookiesKeysList.forEach(key => {
      this.cookieService.delete(key);
    });
  }

  private onChanges(): void {
    this.checkIfBannerIsRequired();
    this.changesEmitter.emit();
  }
}
