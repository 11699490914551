export interface StatementPaymentResponse {
  redirect_url: string;
  stripe_session_id: string;
}

export interface StatementPaymentInterface {
  redirectUrl: string;
  stripeSessionId: string;
}

export class StatementPayment implements StatementPaymentInterface{
  redirectUrl;
  stripeSessionId;

  static fromResponse(response: StatementPaymentResponse): StatementPayment {
    const statementPayment = new StatementPayment();
    statementPayment.redirectUrl = response.redirect_url;
    statementPayment.stripeSessionId = response.stripe_session_id;

    return statementPayment;
  }
}
