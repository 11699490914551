<div class="btn_simple_gr">
  <button
    type="button"
    class="btn_simple"
    (click)="handleSelect(false)"
    [class.active]="selectedOption === false"
    [attr.data-cy]="'btnRadioNo'"
  >
    <span class="txt">{{ btnTextFirst | translate }}</span>
  </button>
  <button
    type="button"
    class="btn_simple"
    (click)="handleSelect(true)"
    [class.active]="selectedOption === true"
    [attr.data-cy]="'btnRadioYes'"
  >
    <span class="txt">{{ btnTextSecond | translate }}</span>
  </button>
</div>
