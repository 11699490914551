import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AuthenticationDialogFlag, LoginDialogComponent } from "app/core";
import { ImpressumComponent } from "app/core/impressum/impressum.component";
import { TermsFilesService } from "app/core/services/terms-files.service";
import { CookiesLawService } from "app/core/services/cookies-law.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"]
})
export class MyAccountComponent {
  @Input() mobile: boolean;
  showRegisterPopup = false;
  public readonly authenticationDialogFlag = AuthenticationDialogFlag;

  constructor(
    private dialog: MatDialog,
    private termsFilesService: TermsFilesService,
    private cookiesLawService: CookiesLawService
  ) {}

  toggleRegisterPopup() {
    if (window.innerWidth > 767) {
      this.showRegisterPopup = !this.showRegisterPopup;
    } else {
      this.dialog.open(LoginDialogComponent, {
        data: { loginFlag: AuthenticationDialogFlag.REGISTRATION },
        panelClass: ["login_dialog_wrap", "new-dialog-wrap"],
        backdropClass: "backdropBackgroundWhite"
      });
    }
  }

  openLoginDialog(flag: AuthenticationDialogFlag) {
    this.dialog.open(LoginDialogComponent, {
      backdropClass: "backdropBackgroundWhite",
      panelClass: ["login_dialog_wrap", "new-dialog-wrap"],
      data: { loginFlag: flag }
    });

    this.showRegisterPopup = false;
  }

  showPrivacyPolicyDialog() {
    this.showRegisterPopup = false;
    this.termsFilesService.openPrivacyPolicy();
  }

  showTermsAndConditionsDialog() {
    this.showRegisterPopup = false;
    this.termsFilesService.openTermsAndConditions();
  }

  showCookieBanner(): void {
    this.showRegisterPopup = false;
    this.cookiesLawService.activeCookieBanner();
  }

  showImpressumDialog() {
    this.dialog.open(ImpressumComponent);
  }
}
