import {Component, Input, Output, EventEmitter} from '@angular/core';


@Component({
  selector: 'app-single-field-edit',
  templateUrl: './single-field-edit.component.html',
  styleUrls: ['./single-field-edit.component.scss']
})
export class SingleFieldEditComponent {
  @Input() field: string;
  @Input() activeField: string;
  @Input() offset = false;
  @Input() offsetRight = false;
  @Input() toRight: boolean = false;
  @Input() moreBot: boolean = false;
  @Input() employerEdit: boolean = false;
  @Input() employerSelectEdit: boolean = false;
  @Output() toggle = new EventEmitter();

  toggleField(event) {
    event.stopPropagation();
    this.toggle.emit(this.field);
  }
}
