<app-ctr-base [templateRef]="abroadIncome" [title]="title" [categoryProperties]="categoryProperties"
              [dataSelects]="dataSelects" [summary]="summary" ></app-ctr-base>

<ng-template #abroadIncome>
  <div class="card card-white shadow-0 mb-2" id="bankDetailTab">
    <div class="card__row mb-1">
      <div class="table">
        <div class="table__row">
          <div class="table__col">
            <div class="row__title">{{ 'INPUT.IBAN.LABEL' | translate }}</div>
          </div>
          <div class="table__col d-flex">
            <input readonly value="{{ statement.iban || '-' }}"/>
          </div>
        </div>
        <div class="table__row" *ngIf="statement.bic">
          <div class="table__col">
            <div class="row__title">{{ 'INPUT.BIC_SWIFT.LABEL' | translate }}</div>
          </div>
          <div class="table__col d-flex">
            <input readonly value="{{ statement.bic }}"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
