<div class="income-list" *ngIf="list.length > 0">
  <ul class="income-list__list">
    <li class="income-list__list--header">
      <p class="type" *ngIf="incomeType === 'incomes'">{{ "PRICE_TYPES.INCOME" | translate }}</p>
      <p class="type" *ngIf="incomeType === 'costs'">{{ "PRICE_TYPES.COSTS" | translate }}</p>
      <div class="price-types">
        <p class="vat" *ngIf="incomeType === 'incomes'">{{ "PRICE_TYPES.VAT" | translate }}</p>
        <p class="net" *ngIf="incomeType === 'incomes'">{{ "PRICE_TYPES.NET" | translate }}</p>
        <p class="gross" *ngIf="incomeType === 'incomes'">{{ "PRICE_TYPES.GROSS" | translate }}</p>
      </div>
    </li>
    <li class="income-list__list--element" *ngFor="let element of list; let i = index">
      <div class="income-list__info desktop">
        <span class="name">{{ i + 1 }}. {{ displayName(element.name) }}</span>
        <button
          class="action add"
          (click)="handleEdit(i)"
          *ngIf="!isEditVisible[i] && !!element.value; else removeAction"
          >Edytuj</button
        >
        <ng-template #removeAction>
          <button class="action remove" (click)="removeValue(i)"></button>
        </ng-template>
        <div class="action-container">
          <ng-container *ngIf="!isEditVisible[i] && !!element.value">
            <span class="price vat" *ngIf="incomeType === 'incomes'">{{ displayVat(i) }}</span>
            <span class="price net" *ngIf="incomeType === 'incomes'">{{ displayNet(i) }}</span>
            <span class="price gross">{{ displayGross(i) }}</span>
          </ng-container>
        </div>
      </div>

      <div class="income-list__info mobile">
        <span class="name"
          >{{ displayName(element.name) }}
          <button class="action remove" (click)="removeValue(i)" *ngIf="isEditVisible[i]"></button
        ></span>
        <div class="values-container" *ngIf="!isEditVisible[i] && !!element.value">
          <div class="price-types">
            <p class="vat" *ngIf="incomeType === 'incomes'">{{ "PRICE_TYPES.VAT" | translate }}</p>
            <p class="net" *ngIf="incomeType === 'incomes'">{{ "PRICE_TYPES.NET" | translate }}</p>
            <p class="gross" *ngIf="incomeType === 'incomes'">{{ "PRICE_TYPES.GROSS" | translate }}</p>
          </div>
          <div class="action-container">
            <button class="action add" (click)="handleEdit(i)">Edytuj</button>
            <!-- <ng-template #removeAction>
              <button class="action remove" (click)="removeValue(i)"></button>
            </ng-template> -->
            <ng-container>
              <span class="price vat" *ngIf="incomeType === 'incomes'">{{ displayVat(i) }}</span>
              <span class="price net" *ngIf="incomeType === 'incomes'">{{ displayNet(i) }}</span>
              <span class="price gross">{{ displayGross(i) }}</span>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- EDIT INPUT PLACE -->
      <div class="edit-data" [ngClass]="{ show: isEditVisible[i] }">
        <app-income-edit
          [value]="element"
          [btnTextFirst]="btnTextFirst"
          [btnTextSecond]="btnTextSecond"
          (hideEdit)="handleEdit($event)"
          [editIndex]="i"
          [list]="list"
          [inputLabel]="inputLabel"
          [inputPlaceholder]="inputPlaceholder"
          [vatInputLabel]="vatInputLabel"
          [vatInputPlaceholder]="vatInputPlaceholder"
          [alertInfo]="alertInfo"
          [incomeType]="incomeType"
        ></app-income-edit>
      </div>
    </li>
  </ul>
  <div class="income-list__summary" *ngIf="!isLump">
    <span class="income-list__summary--title">Podsumowanie</span>
    <span class="income-list__summary--value">{{ summary() }}</span>
  </div>
</div>
