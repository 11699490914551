<button
  class="button button-outline-primary {{ extraClasses }}"
  [class.is-loading]="isLoading"
  [class.button-sm]="isSmall"
  [class.button-sm-mobile]="isSmallMobile"
  mat-button
  [disabled]="isDisabled"
  (click)="clickEvent($event)"
  type="button"
>
  <ng-container *ngIf="title">
    {{ title | translate }}
  </ng-container>
  <ng-content></ng-content>
</button>
