import { Injectable } from '@angular/core';
import { Statement } from 'app/statements';
import { Store } from '@ngrx/store';
import { getIsUserLogged, getUser, State } from 'app/reducers';
import { StatementService } from 'app/statements/statement.service';
import { DeviceRecognizerHelper } from 'app/core/helpers/device-recognizer.helper';
import { AndroidAppHelpers } from 'app/core/helpers/mobile-apps/android-app.helpers';
import { IosAppHelpers } from 'app/core/helpers/mobile-apps/ios-app.helpers';
import { RateAppHttpService } from 'app/modules/rateapp/services/http/rate-app.http.service';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Subject } from 'rxjs';
import { RateAppConfig } from 'app/configs/rate-app.config';
import { Router } from '@angular/router';
import { RateAppPagePath } from 'app/core/workflow/page-path/rate-app-page.path';
import { DashboardPagePath } from 'app/core/workflow/page-path/dashboard-page.path';
import { OpinionRequest } from 'app/core/models/opinion-request/OpinionRequest.model';

@Injectable({
  providedIn: 'root',
})
export class RateAppService {
  public mainStatement: Statement;
  public loading: boolean;
  public opinionRequest = new OpinionRequest();
  public ngUnsubscribe: Subject<void>;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private router: Router,
    private rateAppHttpService: RateAppHttpService,
  ) {
  }

  public init(_ngUnsubscribe?: Subject<void>) {
    if (_ngUnsubscribe) {
      this.ngUnsubscribe = _ngUnsubscribe;

      const ngUnsubscribe = this.ngUnsubscribe.subscribe({
        next: () => {
          AndroidAppHelpers.removeEventAndroidAppRated(this.androidAppRatedHandler.bind(this));
          IosAppHelpers.removeEventIosAppRated();

          ngUnsubscribe.unsubscribe();
        }
      });
    }

    if (DeviceRecognizerHelper.isAndroidWebview()) {
      AndroidAppHelpers.addEventAndroidAppRated(this.androidAppRatedHandler.bind(this));
    }

    if (DeviceRecognizerHelper.isIosWebview()) {
      IosAppHelpers.addEventIosAppRated(this.iosAppRatedHandler.bind(this));
    }

    combineLatest([
      this.store.select(getIsUserLogged),
      this.store.select(getUser),
    ]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: async ([isLoggedIn, user]) => {
          if (
            isLoggedIn
            && user
            && user.isIndividual
            && ['cypress_user@lightcode.eu', 'cypress_user_w_finished@lightcode.eu'].indexOf(user.email) === -1
          ) {
            const opinionRequestResponse = await this.rateAppHttpService.getOpinionRequest();

            if (opinionRequestResponse.success) {
              this.opinionRequest = opinionRequestResponse.body;
              setTimeout(() => {
                this.callRateAppView();
              }, RateAppConfig.timeoutCallRateAppAfterLogin);
            }
          }
        }
      });
  }

  public callRateAppView(): void {
    this.router.navigate([RateAppPagePath.fullUrl()]);
  }

  public onClickGoTrustPilot(stars?: number): void {
    this.onAppRateCompleted();

    window.open(RateAppConfig.trustPilotUrl(stars), '_blank');
  }

  public androidAppRatedHandler(): void {
    this.onAppRateCompleted();
    window.open(RateAppConfig.googlePlayUrl(), '_blank');
  }

  public iosAppRatedHandler(): void {
    this.onAppRateCompleted();
    window.open(RateAppConfig.iosUrl(), '_blank');
  }

  private async onAppRateCompleted(): Promise<void> {
    this.opinionRequest.popupViewed = true;
    this.opinionRequest.requestCompleted = true;

    await this.rateAppHttpService.markThatOpinionIsSent(this.opinionRequest.payload());

    this.router.navigate([DashboardPagePath.fullUrl()]);
  }
}
