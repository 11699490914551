import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonBaseComponent } from "app/shared/components/buttons/button-base.component";

@Component({
  ...ButtonBaseComponent.metaData,
  selector: "app-button-two",
  templateUrl: "./button-two.component.html",
  styleUrls: ["./button-two.component.scss"]
})
export class ButtonAddTwoComponent extends ButtonBaseComponent {
  @Input() btnTextFirst: string;
  @Input() btnTextSecond: string;
  @Input() isDisabled = false;
  @Output() cancelEmitter = new EventEmitter();
  @Output() addEmitter = new EventEmitter();

  public cancel() {
    this.cancelEmitter.emit();
  }

  public add() {
    this.addEmitter.emit();
  }
}
