export const VALIDATOR_FIELD_NAMES_PERSONAL_INFO = {
  first_name: 'pi_first_name',
  last_name: 'pi_last_name',
  birth_date: 'pi_birth_date',
  id_number: 'pi_id_number',
  religion: 'pi_religion',
  // citizenship: 'pi_citizenship', //Code was commented becouse of task TAX-2985 In that task we dont want to validate and display on front select box with info if person live_in_germany 
  street: 'pi_street',
  house_nr: 'pi_house_nr',
  additional_address_info: 'pi_additional_address_info',
  post_code: 'pi_post_code',
  city: 'pi_city',
  country: 'pi_country',
  phone_number: 'pi_phone_number',
  tax_authority: 'pi_tax_authority',
  tax_number: 'pi_tax_number',
  // live_in_germany: 'pi_live_in_germany', //Code was commented becouse of task TAX-2984 In that task we dont want to validate and display on front select box with info if person live_in_germany 
};
