<app-ctr-base
  [templateRef]="additionalIncome"
  [title]="title"
  [categoryProperties]="categoryProperties"
  [dataSelects]="dataSelects"
  [summary]="summary"
></app-ctr-base>

<ng-template #additionalIncome>
  <div class="card card-white shadow-0 mb-2" id="additionalIncomeTab">
    <div class="card__row mb-1">
      <div class="table">
        <div
          class="table__row"
          *ngIf="
            findInArray(
              statement.additionalIncome,
              'incomeType',
              additionalIncomeTypes.UNEMPLOYMENT,
              'amount'
            ) as additionalIncomeValue
          "
        >
          <div class="table__col">
            <div class="row__title">{{ "CONFIRM_TAX_RETURN.ADDITIONAL_INCOME.UNEMPLOYMENT" | translate }}</div>
          </div>
          <div class="table__col d-flex">
            <input readonly value="{{ additionalIncomeValue | number: '1.2-2' }} €" />
          </div>
        </div>
        <div
          class="table__row"
          *ngIf="
            findInArray(
              statement.additionalIncome,
              'incomeType',
              additionalIncomeTypes.PARENTAL,
              'amount'
            ) as additionalIncomeValue
          "
        >
          <div class="table__col">
            <div class="row__title">{{ "CONFIRM_TAX_RETURN.ADDITIONAL_INCOME.PARENTAL" | translate }}</div>
          </div>
          <div class="table__col d-flex">
            <input readonly value="{{ additionalIncomeValue | number: '1.2-2' }} €" />
          </div>
        </div>
        <div
          class="table__row"
          *ngIf="
            findInArray(
              statement.additionalIncome,
              'incomeType',
              additionalIncomeTypes.INSOLVENCY,
              'amount'
            ) as additionalIncomeValue
          "
        >
          <div class="table__col">
            <div class="row__title">{{ "CONFIRM_TAX_RETURN.ADDITIONAL_INCOME.INSOLVENCY" | translate }}</div>
          </div>
          <div class="table__col d-flex">
            <input readonly value="{{ additionalIncomeValue | number: '1.2-2' }} €" />
          </div>
        </div>
        <div
          class="table__row"
          *ngIf="
            findInArray(
              statement.additionalIncome,
              'incomeType',
              additionalIncomeTypes.SICKNESS,
              'amount'
            ) as additionalIncomeValue
          "
        >
          <div class="table__col">
            <div class="row__title">{{ "CONFIRM_TAX_RETURN.ADDITIONAL_INCOME.SICKNESS" | translate }}</div>
          </div>
          <div class="table__col d-flex">
            <input readonly value="{{ additionalIncomeValue | number: '1.2-2' }} €" />
          </div>
        </div>
        <div
          class="table__row"
          *ngIf="
            findInArray(
              statement.additionalIncome,
              'incomeType',
              additionalIncomeTypes.MATERNITY,
              'amount'
            ) as additionalIncomeValue
          "
        >
          <div class="table__col">
            <div class="row__title">{{ "CONFIRM_TAX_RETURN.ADDITIONAL_INCOME.MATERNITY" | translate }}</div>
          </div>
          <div class="table__col d-flex">
            <input readonly value="{{ additionalIncomeValue | number: '1.2-2' }} €" />
          </div>
        </div>
        <div
          class="table__row"
          *ngIf="
            findInArray(
              statement.additionalIncome,
              'incomeType',
              additionalIncomeTypes.TRANSITION,
              'amount'
            ) as additionalIncomeValue
          "
        >
          <div class="table__col">
            <div class="row__title">{{ "CONFIRM_TAX_RETURN.ADDITIONAL_INCOME.TRANSITION" | translate }}</div>
          </div>
          <div class="table__col d-flex">
            <input readonly value="{{ additionalIncomeValue | number: '1.2-2' }} €" />
          </div>
        </div>
        <div
          class="table__row"
          *ngIf="
            findInArray(
              statement.additionalIncome,
              'incomeType',
              additionalIncomeTypes.OTHER,
              'amount'
            ) as additionalIncomeValue
          "
        >
          <div class="table__col">
            <div class="row__title">{{ "CONFIRM_TAX_RETURN.ADDITIONAL_INCOME.OTHER" | translate }}</div>
          </div>
          <div class="table__col d-flex">
            <input readonly value="{{ additionalIncomeValue | number: '1.2-2' }} €" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
