import { Injectable } from '@angular/core';
import { AuthHttpService } from 'app/users';
import { of as observableOf, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FirebaseNotification } from './notification';
import { getApiPath } from 'app/common';

@Injectable({
  providedIn: 'root'
})
export class FirebaseHttpService {

  constructor(private authHttp: AuthHttpService) { }


  getFirebaseNotifications(): Observable<any> {
    return this.authHttp.get(`${getApiPath()}firebase/notifications/`).pipe(
      map((response: any) => FirebaseNotification.fromResponse(response)));
  }


  udpateFirebaseNotification(firebaseNotification): Observable<any> {
    return this.authHttp.post(`${getApiPath()}firebase/notifications/${firebaseNotification.user}/${firebaseNotification.statement}/${firebaseNotification.notificationName}`, firebaseNotification.toResponse()).pipe(
      map((response: Response) => response),
      catchError((error: any) => observableOf(error)));
  }

  updateUserFirebaseDeviceToken(token: string, userEmail: string) {
    let data = {
      firebaseDeviceToken: token,
      userEmail: userEmail
    }
    return this.authHttp.post(`${getApiPath()}firebase/updateNotificationToken/`, data).pipe(
      map((reposnse: Response) => reposnse),
      catchError((error: any) => observableOf(error)));
  }

}
