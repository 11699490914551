<ng-container *ngIf="!contentLoaded">
    <app-content-loader></app-content-loader>
</ng-container>

<div class="profile-edit" *ngIf="contentLoaded">
    <div class="profile-edit__section" *ngIf="currentStep===1">

        <div class="ep_new_tiles_wrap" *ngIf="tilesStatuses.length">
            <div class="ep_new_single_tile_wrap" *ngFor="let tileStatuse of tilesStatuses; let i = index;">
                <div
                  class="ep_new_single_tile"
                  [ngClass]="isBusiness ? 'marital_status_tile_'+i : ''"
                  [class.is_input]="i > 0"
                  (click)="selectStatus(tileStatuse)" [class.active]="tileStatuse.active"
                  [attr.data-cy]="'btnMaritalStatus_' + tileStatuse.status"
                >
                    <div class="ep_desc_wrap" >
                        <div class="icon_tile_wrap">
                            <img src="../../../assets/svg/profile/{{ tileStatuse.icon }}" class="icon_tile">
                        </div>
                        <h4 class="title_tile_sm" [innerHTML]="tileStatuse.name | translate"></h4>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="profile-edit__section" *ngIf="currentStep===2" #jojointSettlementin>
        <!-- <div *ngIf="canFilTogether()">
            <app-form-input [errors]="errors?.solidarity_surcharge"
                [label]="'PROFILE_BC.SPOUSE_FIRST_NAME' | translate" [placeholder]="''"
                [(model)]="spouseFirstName">
            </app-form-input>
        </div>
        <div *ngIf="canFilTogether()">
            <app-form-input [errors]="errors?.solidarity_surcharge"
                [label]="'PROFILE_BC.SPOUSE_LAST_NAME' | translate" [placeholder]="''"
                [(model)]="spouseLastName">
            </app-form-input>
        </div> -->
        <div class="input_wrap">
            <div class="single_input" *ngIf="status===2">
                <app-form-date-input
                  [errors]="errors?.date_of_marriage"
                  [warnings]="warnings?.date_of_marriage"
                  [label]="'PROFILE_BC.DATE_OF_MARRIAGE'"
                  [(model)]="statement.dateOfMarriage"
                  (modelChange)="dateChange($event)"
                  [attr.data-cy]="'inputDateOfMarriage'"
                >
                </app-form-date-input>
                <app-validator-error-message [epValidator]="epValidator" [index]="0"
                    [fieldName]="'chose_profile_marriage_data'">
                </app-validator-error-message>
            </div>
            <div class="single_input" *ngIf="status===3">
                <app-form-date-input
                  [errors]="errors?.date_of_partnership"
                  [warnings]="warnings?.date_of_partnership"
                  [label]="'PROFILE_BC.DATE_OF_PARTNERSHIP'"
                  [(model)]="statement.dateOfPartnership"
                  (modelChange)="dateChange($event)"
                >
                </app-form-date-input>

                <app-validator-error-message [epValidator]="epValidator" [index]="0"
                    [fieldName]="'chose_profile_partners_data'">
                </app-validator-error-message>
            </div>
            <div class="single_input" *ngIf="status===4">
                <app-form-date-input
                  [errors]="errors?.date_of_widowed"
                  [warnings]="warnings?.date_of_widowed"
                  [label]="'PROFILE_BC.DATE_OF_WIDOWED'"
                  [(model)]="statement.dateOfWidowed"
                  (modelChange)="dateChange($event)"
                >
                </app-form-date-input>
                <app-validator-error-message [epValidator]="epValidator" [index]="0"
                    [fieldName]="'chose_profile_widower_end_date'">
                </app-validator-error-message>
            </div>
            <div class="single_input" *ngIf="status===5">
                <app-form-date-input
                  [errors]="errors?.date_of_separation"
                  [warnings]="warnings?.date_of_separation"
                  [label]="'PROFILE_BC.DATE_OF_SEPARATION'"
                  [(model)]="statement.dateOfSeparation"
                  (modelChange)="dateChange($event)"
                >
                </app-form-date-input>
                <app-validator-error-message [epValidator]="epValidator" [index]="0"
                    [fieldName]="'chose_profile_separation_end_date'">
                </app-validator-error-message>
            </div>
            <div class="single_input" *ngIf="status===6">
                <app-form-date-input
                  [errors]="errors?.date_of_divorce"
                  [warnings]="warnings?.date_of_divorce"
                  [label]="'PROFILE_BC.DATE_OF_DIVORCE'"
                  [(model)]="statement.dateOfDivorce"
                  (modelChange)="dateChange($event)"
                >
                </app-form-date-input>

                <app-validator-error-message [epValidator]="epValidator" [index]="0"
                    [fieldName]="'chose_profile_divorce_end_date'">
                </app-validator-error-message>
            </div>
        </div>
        <div class="desc_w_tooltip" *ngIf="canFilTogether()">
            <div class="tooltip_desc single_input">
                <mat-checkbox [(ngModel)]="statement.filedTogether" [attr.data-cy]="'checkboxFiledTogether'">
                    <span class="checkbox_txt">{{'PROFILE_BC.JOINT_TAX_RETURN' | translate}}</span>
                </mat-checkbox>
            </div>
            <div class="tooltip_wrap">
                <app-more-info-tooltip [message]="'PROFILE_BC.TOOLTIP_DESC'">
                </app-more-info-tooltip>
            </div>
        </div>
    </div>

    <div class="profile-edit__section" #childrensection *ngIf="currentStep===3">
        <div class="btn_simple_gr">
            <button
              class="btn_simple normal"
              [class.active]="!statement.hasChildren"
              (click)="selectHasChildren(false)"
              [attr.data-cy]="'btnHaveChildrenNo'"
            >
              {{ 'PROFILE_BC.CHILDREN_NO' | translate }}
            </button>
            <button
              class="btn_simple normal"
              [class.active]="statement.hasChildren"
              (click)="selectHasChildren(true)"
              [attr.data-cy]="'btnHaveChildrenYes'"
            >
              {{ 'PROFILE_BC.CHILDREN_YES' | translate }}
            </button>
        </div>
    </div>
</div>
