import { Component, OnInit } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { State } from "app/reducers";
import {
  GroupSectionBaseAbstractComponent,
  SelectButtonsData
} from "app/shared/modules/group-section/components/group-section-base.abstract.component";
import { AdditionalIncomeService } from "app/modules/statement/services/additional-income.service";
import {
  StepsControllerService,
  SubStepSaveDataInterface
} from "app/core/workflow/steps-controller/steps-controller.service";
import { SocialSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/social-sub.steps";
import { SocialIncomeManager } from "app/modules/statement/models/additional-income/social-income.manager";
import { FreelanceJobSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/freelance-job.sub-steps";
import { AutofocusHelper } from "app/core/helpers/autofocus.helper";

@Component({
  ...GroupSectionBaseAbstractComponent.metaData,
  selector: "app-additional-income-group-social",
  templateUrl: "./additional-income-group-social.component.html"
})
export class AdditionalIncomeGroupSocialComponent extends GroupSectionBaseAbstractComponent implements OnInit {
  public groupTitle: string;
  public additionalSubSteps = SocialSubSteps;
  public socialIncomeManager = SocialIncomeManager;

  constructor(
    public additionalIncomeService: AdditionalIncomeService,
    public dialog: MatDialog,
    public store: Store<State>,
    public stepsControllerService: StepsControllerService
  ) {
    super(dialog, store, stepsControllerService);
  }

  ngOnInit() {
    this.groupTitle = this.title;
    this.init();

    this.stepsControllerService.reInitEmitter.pipe(takeUntil(this.ngUnsubscribe)).subscribe((index) => {
      this.init();
    });

    this.stepsControllerService.subStepSaveEmitter.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: SubStepSaveDataInterface) => {
        this.saveGroup(data);
      }
    });

    super.ngOnInit();
  }

  public isSelected = (selectButtonName: string): boolean => {
    switch (selectButtonName) {
      case SocialSubSteps.UNEMPLOYMENT:
        return !!SocialIncomeManager.getUnemployment(this.additionalIncomeService.currentStatement).length;
      case SocialSubSteps.PARENTAL:
        return !!SocialIncomeManager.getParental(this.additionalIncomeService.currentStatement).length;
      case SocialSubSteps.INSOLVENCY:
        return !!SocialIncomeManager.getInsolvency(this.additionalIncomeService.currentStatement).length;
      case SocialSubSteps.SICKNESS:
        return !!SocialIncomeManager.getSickness(this.additionalIncomeService.currentStatement).length;
      case SocialSubSteps.MATERNITY:
        return !!SocialIncomeManager.getMaternity(this.additionalIncomeService.currentStatement).length;
      case SocialSubSteps.INJURY:
        return !!SocialIncomeManager.getInjury(this.additionalIncomeService.currentStatement).length;
      case SocialSubSteps.TRANSITION:
        return !!SocialIncomeManager.getTransition(this.additionalIncomeService.currentStatement).length;
      case SocialSubSteps.OTHER:
        return !!SocialIncomeManager.getOther(this.additionalIncomeService.currentStatement).length;
    }

    return false;
  };

  public selectButtonClickAction = (selectButtonName: string): void => {
    switch (selectButtonName) {
      case SocialSubSteps.UNEMPLOYMENT:
        if (SocialIncomeManager.getUnemployment(this.additionalIncomeService.currentStatement).length) {
          this.showAreYouSurePopup((dialog) => {
            SocialIncomeManager.removeAllUnemployment(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          SocialIncomeManager.createUnemployment(this.additionalIncomeService.currentStatement);
        }
        break;
      case SocialSubSteps.PARENTAL:
        if (SocialIncomeManager.getParental(this.additionalIncomeService.currentStatement).length) {
          this.showAreYouSurePopup((dialog) => {
            SocialIncomeManager.removeAllParental(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          SocialIncomeManager.createParental(this.additionalIncomeService.currentStatement);
        }
        break;
      case SocialSubSteps.INSOLVENCY:
        if (SocialIncomeManager.getInsolvency(this.additionalIncomeService.currentStatement).length) {
          this.showAreYouSurePopup((dialog) => {
            SocialIncomeManager.removeAllInsolvency(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          SocialIncomeManager.createInsolvency(this.additionalIncomeService.currentStatement);
        }
        break;
      case SocialSubSteps.SICKNESS:
        if (SocialIncomeManager.getSickness(this.additionalIncomeService.currentStatement).length) {
          this.showAreYouSurePopup((dialog) => {
            SocialIncomeManager.removeAllSickness(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          SocialIncomeManager.createSickness(this.additionalIncomeService.currentStatement);
        }
        break;
      case SocialSubSteps.MATERNITY:
        if (SocialIncomeManager.getMaternity(this.additionalIncomeService.currentStatement).length) {
          this.showAreYouSurePopup((dialog) => {
            SocialIncomeManager.removeAllMaternity(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          SocialIncomeManager.createMaternity(this.additionalIncomeService.currentStatement);
        }
        break;
      case SocialSubSteps.INJURY:
        if (SocialIncomeManager.getInjury(this.additionalIncomeService.currentStatement).length) {
          this.showAreYouSurePopup((dialog) => {
            SocialIncomeManager.removeAllInjury(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          SocialIncomeManager.createInjury(this.additionalIncomeService.currentStatement);
        }
        break;
      case SocialSubSteps.TRANSITION:
        if (SocialIncomeManager.getTransition(this.additionalIncomeService.currentStatement).length) {
          this.showAreYouSurePopup((dialog) => {
            SocialIncomeManager.removeAllTransition(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          SocialIncomeManager.createTransition(this.additionalIncomeService.currentStatement);
        }
        break;
      case SocialSubSteps.OTHER:
        if (SocialIncomeManager.getOther(this.additionalIncomeService.currentStatement).length) {
          this.showAreYouSurePopup((dialog) => {
            SocialIncomeManager.removeAllOther(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          SocialIncomeManager.createOther(this.additionalIncomeService.currentStatement);
        }
        break;
    }

    this.selectedButtonsChanged();
  };

  private setSelectButtonsData = (): void => {
    const selectButtonsData: SelectButtonsData[] = [
      {
        id: SocialSubSteps.UNEMPLOYMENT,
        image: "assets/svg/additional_income/unemployment_benefit.svg",
        title: "ADDITIONAL_INCOME.SOCIAL_GROUP.UNEMPLOYMENT.BTN_TITLE"
      },
      {
        id: SocialSubSteps.PARENTAL,
        image: "assets/svg/additional_income/parental_benefits.svg",
        title: "ADDITIONAL_INCOME.SOCIAL_GROUP.PARENTAL.BTN_TITLE"
      },
      {
        id: SocialSubSteps.INSOLVENCY,
        image: "assets/svg/additional_income/insolvency_allowance.svg",
        title: "ADDITIONAL_INCOME.SOCIAL_GROUP.INSOLVENCY.BTN_TITLE"
      },
      {
        id: SocialSubSteps.SICKNESS,
        image: "assets/svg/additional_income/sickness_benefit.svg",
        title: "ADDITIONAL_INCOME.SOCIAL_GROUP.SICKNESS.BTN_TITLE"
      },
      {
        id: SocialSubSteps.MATERNITY,
        image: "assets/svg/additional_income/maternity_benefit.svg",
        title: "ADDITIONAL_INCOME.SOCIAL_GROUP.MATERNITY.BTN_TITLE"
      },
      {
        id: SocialSubSteps.INJURY,
        image: "assets/svg/additional_income/accident_benefit.svg",
        title: "ADDITIONAL_INCOME.SOCIAL_GROUP.INJURY.BTN_TITLE"
      },
      {
        id: SocialSubSteps.TRANSITION,
        image: "assets/svg/additional_income/compensatory_allowance.svg",
        title: "ADDITIONAL_INCOME.SOCIAL_GROUP.TRANSITION.BTN_TITLE"
      },
      {
        id: SocialSubSteps.OTHER,
        image: "assets/svg/deductions/deduction_other.svg",
        title: "ADDITIONAL_INCOME.SOCIAL_GROUP.OTHER.BTN_TITLE"
      }
    ];

    this.setSelectButtons(selectButtonsData);
  };

  private setSteps(): void {
    this.subSteps = [
      {
        id: SocialSubSteps.MAIN,
        isActive: true
      },
      {
        id: SocialSubSteps.UNEMPLOYMENT,
        isActive: false
      },
      {
        id: SocialSubSteps.PARENTAL,
        isActive: false
      },
      {
        id: SocialSubSteps.INSOLVENCY,
        isActive: false
      },
      {
        id: SocialSubSteps.SICKNESS,
        isActive: false
      },
      {
        id: SocialSubSteps.MATERNITY,
        isActive: false
      },
      {
        id: SocialSubSteps.INJURY,
        isActive: false
      },
      {
        id: SocialSubSteps.TRANSITION,
        isActive: false
      },
      {
        id: SocialSubSteps.OTHER,
        isActive: false
      }
    ];
  }

  private updateStatement(callback = () => {}): void {
    this.additionalIncomeService.updateAdditionalIncome(this.getActiveSubStep().id as FreelanceJobSubSteps, callback);
  }

  private saveGroup({ index, callback }: SubStepSaveDataInterface): void {
    const currentStep = this.subSteps[index];

    if (currentStep && currentStep.id !== SocialSubSteps.MAIN) {
      this.additionalIncomeService.formValidationErrors(currentStep.id as SocialSubSteps);
      if (this.additionalIncomeService.epValidator.length) {
        callback(false);
        return;
      }
    }

    callback(true);
    AutofocusHelper.autofocusInputMainContent();
  }

  private init(): void {
    this.setSteps();
    this.setSelectButtonsData();
    AutofocusHelper.autofocusInputMainContent();
  }
}
