import { Injectable } from '@angular/core';
import { FirebaseAnalyticsService } from 'app/shared/modules/firebase/services/firebase-analytics.service';

@Injectable()
export class AnalyticsService {
  constructor(
    private readonly firebaseAnalyticsService: FirebaseAnalyticsService,
  ) {
  }

  public logSignUpEvent(): void {
    this.firebaseAnalyticsService.logSignUpEvent();
  }

  public logStandardPurchase(amount: number, currency: string): void {
    this.firebaseAnalyticsService.logStandardPurchase(
      amount,
      currency
    );
  }

  public logPremiumPurchase(amount: number, currency: string): void {
    this.firebaseAnalyticsService.logPremiumPurchase(
      amount,
      currency
    );
  }
}
