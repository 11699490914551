import { PersonalInfoChildSummaryComponentPath } from 'app/core/workflow/page-path/statement-page-path/personal-info-child-summary-component-path';
import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';

export class WorkflowPersonalInfoChildSummaryService extends WorkflowStepsBaseService {
  public get isRequired(): boolean {
    return this.hasChildren && !!this.getFirstChildId;
  }

  public get isDone(): boolean {
    let validChildren = false;

    if (this.statement && this.statement.children.length) {
      validChildren = !this.statement.children.find(child => !child.isFilledBasicInfo);

      // if (validChildren) {
      //   validChildren = !this.statement.children
      //     .find(child => child.isAdult(this.statement.fullYear) && !child.isAdultSectionFilled);
      // }
    }

    return this.statement && this.hasChildren && this.statement.children.length
      && this.statement.stagePersonalInfoChildren && validChildren;
  }

  public get isVisible(): boolean {
    return false;
  }

  public get checkCanGo(): boolean {
    if (!this.blockedDefaultWorkflow && this.isRequired) {
      if (this.hasSpouseAndFilledTogether) {
        if (this.isBusiness) {
          return this.isSpouseStageEmployerDone;
        }

        return this.statement.spouse.stageAdditionalIncome;
      }

      return this.statement.stagePersonalInfo;
    }
    return false;
  }

  public get urlRegex(): RegExp {
    return PersonalInfoChildSummaryComponentPath.regex();
  }

  public get url(): string {
    return PersonalInfoChildSummaryComponentPath.url(this.getStatementId);
  }
}
