<ng-container *ngIf="freelanceJobIncomeType === freelanceJobSteps.VOLUNTERING_INCOME; else defaultForm">
  <div class="mb-1">
    <app-form-select
      [errors]="additionalIncomeService.getError('name', index)"
      [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INPUT.NAME.LABEL'"
      [placeholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INPUT.NAME.PLACEHOLDER'"
      [(model)]="freelanceJob.name"
      (modelChange)="displayType($event)"
      [choices]="dataSelects.volunteeringIncomeTypes"
      [valueName]="0"
      [optionName]="1"
    >
    </app-form-select>
    <app-validator-error-message
      [epValidator]="additionalIncomeService.epValidator"
      [index]="index"
      [fieldName]="additionalIncomeService.VALIDATOR_KEYS.freelance_name"
    >
    </app-validator-error-message>
  </div>
  <div class="mb-1">
    <app-form-input
      [errors]="additionalIncomeService.getError('income', index)"
      [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INPUT.INCOME.LABEL'"
      [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
      [(model)]="freelanceJob.income"
      [isCurrency]="true"
      [attr.data-cy]="'inputAdditionalIncomeFreelanceSectionIncome_' + index"
    ></app-form-input>
    <app-validator-error-message
      [epValidator]="additionalIncomeService.epValidator"
      [index]="index"
      [fieldName]="additionalIncomeService.VALIDATOR_KEYS.freelance_income"
    ></app-validator-error-message>
  </div>
</ng-container>

<ng-template #defaultForm>
  <div class="input-container mb-50">
    <app-section-title [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_TYPE.TITLE'"></app-section-title>
    <div class="row">
      <div class="column">
        <app-form-input
          [errors]="additionalIncomeService.getError('business_type')"
          [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_TYPE.LABEL'"
          [placeholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_TYPE.PLACEHOLDER'"
          [(model)]="freelanceJob.business_type"
          [isCurrency]="false"
          [autofocus]="false"
        ></app-form-input>
        <app-validator-error-message
          [epValidator]="additionalIncomeService.epValidator"
          [index]="index"
          [fieldName]="additionalIncomeService.VALIDATOR_KEYS.business_type"
        ></app-validator-error-message>
      </div>
    </div>
  </div>
  <app-bool-value-chooser
    [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.STEUERNUMMER.QUESTION'"
    [templateRef]="steuernummer"
    [selectedOption]="freelanceJob.has_steuernummer"
    (changeValueEvent)="handleFieldsValue('has_steuernummer', $event)"
    [titleTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.STEUERNUMMER.TOOLTIP_TITLE' | translate"
    [messageTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.STEUERNUMMER.TOOLTIP_TEXT' | translate"
    *ngIf="!checkIfItIs2020()"
  ></app-bool-value-chooser>
  <app-bool-value-chooser
    [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_NAME.QUESTION'"
    [templateRef]="companyName"
    [selectedOption]="freelanceJob.has_business_name"
    (changeValueEvent)="handleFieldsValue('has_business_name', $event)"
    *ngIf="!checkIfItIs2020()"
  ></app-bool-value-chooser>
  <div class="input-container mb-50">
    <app-section-title
      [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_ADDRESS.TITLE'"
      [titleTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_ADDRESS.TOOLTIP_TITLE' | translate"
      [message]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_ADDRESS.TOOLTIP_TEXT' | translate"
    ></app-section-title>
    <div class="deductions-info">
      <div class="deductions-info__wrap">
        <img src="../../../../../../assets/svg/warning-icon.svg" class="alert_icon" />
        <div class="deductions-info__wrap-text">
          <p class="deductions-info__text">
            {{ "ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_ADDRESS.ALERT_TEXT" | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <app-form-input
          [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_ADDRESS.LABEL_1'"
          [errors]="additionalIncomeService.getError('business_street')"
          [placeholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_ADDRESS.PLACEHOLDER_1'"
          [(model)]="freelanceJob.business_street"
        ></app-form-input>
        <app-validator-error-message
          [epValidator]="additionalIncomeService.epValidator"
          [index]="index"
          [fieldName]="additionalIncomeService.VALIDATOR_KEYS.business_street"
        ></app-validator-error-message>
      </div>
      <div class="column">
        <app-form-input
          [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_ADDRESS.LABEL_2'"
          [errors]="additionalIncomeService.getError('business_postal_code')"
          [placeholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_ADDRESS.PLACEHOLDER_2'"
          [(model)]="freelanceJob.business_postal_code"
        ></app-form-input>
        <app-validator-error-message
          [epValidator]="additionalIncomeService.epValidator"
          [index]="index"
          [fieldName]="additionalIncomeService.VALIDATOR_KEYS.business_postal_code"
        ></app-validator-error-message>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <app-form-input
          [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_ADDRESS.LABEL_3'"
          [errors]="additionalIncomeService.getError('business_city')"
          [placeholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_ADDRESS.PLACEHOLDER_3'"
          [(model)]="freelanceJob.business_city"
        ></app-form-input>
        <app-validator-error-message
          [epValidator]="additionalIncomeService.epValidator"
          [index]="index"
          [fieldName]="additionalIncomeService.VALIDATOR_KEYS.business_city"
        ></app-validator-error-message>
      </div>
    </div>
  </div>
  <app-bool-value-chooser
    [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.HAS_BUSINES_REAL_ESTATE.TITLE'"
    [selectedOption]="freelanceJob.has_sold_business_real_estate"
    (changeValueEvent)="handleFieldsValue('has_sold_business_real_estate', $event)"
    *ngIf="!checkIfItIs2020()"
    [titleTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.HAS_BUSINES_REAL_ESTATE.TOOLTIP.TITLE' | translate"
    [tooltipContentRef]="hasBusinessTooltip"
  ></app-bool-value-chooser>
  <app-add-value-list
    [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.QUESTION'"
    [valueListViewTemplate]="valueIncomeListTemplate"
    [addValueViewTemplate]="valueIncomeInputTemplate"
    [showAddValueViewTemplate]="showIncomeViewTemplate"
    [showAddButton]="true"
    (addNewValue)="initAddValue('income')"
    [epValidatorField]="additionalIncomeService.VALIDATOR_KEYS.incomes"
    [epValidator]="additionalIncomeService.epValidator"
    [buttonText]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.BUTTON_3'"
    [titleTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.TOOLTIP_TITLE'"
    [messageTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.TOOLTIP_CONTENT'"
  ></app-add-value-list>
  <app-bool-value-chooser
    [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.QUESTION'"
    [templateRef]="addCostsTemplate"
    [handleModal]="true"
    [selectedOption]="freelanceJob.has_lump_sum_costs"
    (changeValueEvent)="handleLumpSum($event)"
    *ngIf="!checkIfItIs2020()"
    [titleTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.TOOLTIP_TITLE'"
    [tooltipContentRef]="lumpTooltipContent"
  ></app-bool-value-chooser>
  <app-add-value-list
    [title]="
      freelanceJob.has_lump_sum_costs
        ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.QUESTION_2'
        : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.QUESTION'
    "
    [valueListViewTemplate]="valueCostsListTemplate"
    [list]="freelanceJob.income_costs"
    [isLump]="freelanceJob.has_lump_sum_costs"
    [addValueViewTemplate]="valueCostsInputTemplate"
    [showAddValueViewTemplate]="showCostsViewTemplate"
    [showAddButton]="true"
    (addNewValue)="initAddValue('cost')"
    [epValidatorField]="additionalIncomeService.VALIDATOR_KEYS.income_costs"
    [epValidator]="additionalIncomeService.epValidator"
    [buttonText]="
      freelanceJob.has_lump_sum_costs
        ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.BUTTON_3'
        : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.BUTTON_3'
    "
    [inputType]="'costs'"
    [titleTooltip]="freelanceJob.has_lump_sum_costs ? null : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP_TITLE'"
    [messageTooltip]="
      freelanceJob.has_lump_sum_costs ? null : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP_CONTENT'
    "
  ></app-add-value-list>
  <div class="input-container mb-50" *ngIf="!checkIfItIs2020()">
    <app-section-title
      [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.PAY_WITHDRAW.TITLE'"
      [titleTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.PAY_WITHDRAW.TOOLTIP.TITLE'"
      [tooltipContentRef]="tooltipPayWithdraw"
    ></app-section-title>
    <div class="row">
      <div class="column">
        <app-form-input
          [errors]="additionalIncomeService.getError('total_payments')"
          [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.PAY_WITHDRAW.TOTAL_PAYMENT_LABEL'"
          [placeholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.PAY_WITHDRAW.PLACEHOLDER'"
          [(model)]="freelanceJob.total_payments"
          [classB2B]="'total_payments_input_pit'"
          [isCurrency]="true"
        ></app-form-input>
        <app-validator-error-message
          [epValidator]="additionalIncomeService.epValidator"
          [index]="index"
          [fieldName]="additionalIncomeService.VALIDATOR_KEYS.total_payments"
        ></app-validator-error-message>
      </div>
      <div class="column">
        <app-form-input
          [errors]="additionalIncomeService.getError('total_withdrawals')"
          [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.PAY_WITHDRAW.TOTAL_WITHDRAWLS_LABEL'"
          [placeholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.PAY_WITHDRAW.PLACEHOLDER'"
          [(model)]="freelanceJob.total_withdrawals"
          [classB2B]="'total_withdrawals_input_pit'"
          [isCurrency]="true"
        ></app-form-input>
        <app-validator-error-message
          [epValidator]="additionalIncomeService.epValidator"
          [index]="index"
          [fieldName]="additionalIncomeService.VALIDATOR_KEYS.total_withdrawals"
        ></app-validator-error-message>
      </div>
    </div>

    <ng-template #tooltipPayWithdraw>
      <app-tooltip-template-pay-withdraw></app-tooltip-template-pay-withdraw>
    </ng-template>
  </div>

  <app-bool-value-chooser
    *ngIf="!checkIfItIs2020() && aksForCorona()"
    [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.HAS_CORONA.TITLE'"
    [selectedOption]="freelanceJob.has_corona"
    (changeValueEvent)="handleFieldsValue('has_corona', $event)"
    [templateRef]="coronaInput"
  ></app-bool-value-chooser>

  <ng-template #hasBusinessTooltip
    ><app-tooltip-template-business-real-estate></app-tooltip-template-business-real-estate
  ></ng-template>
</ng-template>

<!-- TEMPLATES -->

<ng-template #steuernummer>
  <div class="input-container">
    <div class="row">
      <div class="column">
        <app-personal-info-tax-authority
          [taxAuthority]="freelanceJob.tax_authority"
          (setTaxAuthority)="freelanceJob.tax_authority = $event"
          [taxAuthorities]="dataSelects.taxAuthorities"
          [isLoading]="isTaxAuthorityLoading"
        ></app-personal-info-tax-authority>
        <app-validator-error-message
          [epValidator]="additionalIncomeService.epValidator"
          [index]="index"
          [fieldName]="additionalIncomeService.VALIDATOR_KEYS.tax_authority"
        ></app-validator-error-message>
      </div>
      <div class="column">
        <app-form-input
          [errors]="additionalIncomeService.getError('business_steuernummer')"
          [label]="'INPUT.STEUERNUMMER.LABEL'"
          [currentInputId]="'applicantSteuernummer'"
          [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
          [(model)]="freelanceJob.business_steuernummer"
          [classB2B]="'steuernr_input_pit'"
        ></app-form-input>
        <app-validator-error-message
          [epValidator]="additionalIncomeService.epValidator"
          [index]="index"
          [fieldName]="additionalIncomeService.VALIDATOR_KEYS.business_steuernummer"
        ></app-validator-error-message>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #valueIncomeListTemplate>
  <app-income-list
    [list]="freelanceJob.income_data"
    (initEdit)="initEdit($event, 'income')"
    [inputLabel]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.LABEL_2'"
    [inputPlaceholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.PLACEHOLDER_2'"
    [vatInputLabel]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.VAT_LABEL'"
    [vatInputPlaceholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.PLACEHOLDER_2'"
    [btnTextFirst]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.EDIT_BUTTON_1'"
    [btnTextSecond]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.EDIT_BUTTON_2'"
    [alertInfo]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.INCOME_INPUT_ALERT'"
    [incomeType]="'incomes'"
    [dataSelects]="dataSelects.vat_types"
  ></app-income-list>
</ng-template>

<ng-template #valueCostsListTemplate>
  <app-income-list
    [list]="freelanceJob.income_costs"
    (initEdit)="initEdit($event, 'income_costs')"
    [inputLabel]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.LABEL_2'"
    [inputPlaceholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.PLACEHOLDER_2'"
    [btnTextFirst]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.EDIT_BUTTON_1'"
    [btnTextSecond]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.EDIT_BUTTON_2'"
    [alertInfo]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.COST_INPUT_ALERT'"
    [incomeType]="'costs'"
    [dataSelects]="freelanceJob.has_lump_sum_costs ? getLumpDataSelect() : getCostsDataSelect()"
    [isLump]="freelanceJob.has_lump_sum_costs"
    [currentIndex]="editIndex"
  ></app-income-list>
</ng-template>

<ng-template #valueIncomeInputTemplate>
  <app-income-input
    [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.TITLE'"
    [selectLabel]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.LABEL_1'"
    [selectPlaceholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.PLACEHOLDER_1'"
    [inputLabel]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.LABEL_2'"
    [inputPlaceholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.PLACEHOLDER_2'"
    [vatInputLabel]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.VAT_LABEL'"
    [vatInputPlaceholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.PLACEHOLDER_2'"
    [button1]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.BUTTON_1'"
    [button2]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.BUTTON_2'"
    [editIndex]="editIndex"
    [inputValueList]="freelanceJob.income_data"
    [selectValueList]="incomeSelectValueList"
    [showIncomeInput]="showIncomeViewTemplate"
    [dataSelects]="dataSelects.vat_types"
    (changeShowIcomeInput)="changeShowIncomeViewTemplate($event)"
    [btnTextFirst]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.BUTTON_1'"
    [btnTextSecond]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.BUTTON_2'"
    [inputType]="'incomes'"
    [alertInfo]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INCOMES.INCOME_INPUT_ALERT'"
  ></app-income-input>
</ng-template>

<ng-template #valueCostsInputTemplate>
  <app-income-input
    [title]="
      !!freelanceJob.has_lump_sum_costs
        ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.TITLE'
        : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TITLE'
    "
    [selectLabel]="
      !!freelanceJob.has_lump_sum_costs
        ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.LABEL_1'
        : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.LABEL_1'
    "
    [selectPlaceholder]="
      !!freelanceJob.has_lump_sum_costs
        ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.PLACEHOLDER_1'
        : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.PLACEHOLDER_1'
    "
    [inputLabel]="
      !!freelanceJob.has_lump_sum_costs
        ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.LABEL_2'
        : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.LABEL_2'
    "
    [button1]="
      !!freelanceJob.has_lump_sum_costs
        ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.BUTTON_1'
        : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.BUTTON_1'
    "
    [button2]="
      !!freelanceJob.has_lump_sum_costs
        ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.BUTTON_2'
        : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.BUTTON_2'
    "
    [editIndex]="editIndex"
    [inputValueList]="freelanceJob.income_costs"
    [selectValueList]="costsSelectValueList"
    [dataSelects]="freelanceJob.has_lump_sum_costs ? getLumpDataSelect() : getCostsDataSelect()"
    [showIncomeInput]="showCostsViewTemplate"
    (changeShowIcomeInput)="changeShowCostsViewTemplate($event)"
    [btnTextFirst]="
      !!freelanceJob.has_lump_sum_costs
        ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.BUTTON_1'
        : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.BUTTON_1'
    "
    [btnTextSecond]="
      !!freelanceJob.has_lump_sum_costs
        ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.BUTTON_2'
        : 'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.BUTTON_2'
    "
    [inputType]="'costs'"
    [alertInfo]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.COST_INPUT_ALERT'"
    [isLump]="!!freelanceJob.has_lump_sum_costs"
  ></app-income-input>
</ng-template>
<ng-template #lumpTooltipContent>
  <app-tooltip-template-costs></app-tooltip-template-costs>
</ng-template>
<ng-template #companyName>
  <div class="row">
    <div class="column">
      <app-form-input
        [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_NAME.LABEL_1'"
        [(model)]="freelanceJob.business_name"
        [errors]="additionalIncomeService.getError('business_name')"
        [placeholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.BUSINESS_NAME.PLACEHOLDER_1'"
      ></app-form-input>
      <app-validator-error-message
        [epValidator]="additionalIncomeService.epValidator"
        [index]="index"
        [fieldName]="additionalIncomeService.VALIDATOR_KEYS.business_name"
      ></app-validator-error-message>
    </div>
  </div>
</ng-template>

<ng-template #coronaInput>
  <div class="row">
    <div class="column">
      <app-form-input
        [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.HAS_CORONA.INPUT.LABEL'"
        [(model)]="freelanceJob.corona_value"
        [errors]="additionalIncomeService.getError('corona_value')"
        [placeholder]="'ADDITIONAL_INCOME.FREELANCE_GROUP.HAS_CORONA.INPUT.PLACEHOLDER'"
        [isCurrency]="true"
      ></app-form-input>
      <app-validator-error-message
        [epValidator]="additionalIncomeService.epValidator"
        [index]="index"
        [fieldName]="additionalIncomeService.VALIDATOR_KEYS.corona_value"
      ></app-validator-error-message>
    </div>
  </div>
</ng-template>
