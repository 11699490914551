import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthHttpService } from 'app/users';
import { getApiPath } from 'app/common';
import { ChildKindergeld } from 'app/modules/statement/models/child-kindergeld';

@Injectable({
  providedIn: 'any',
})
export class ChildKindergeldHttpService {
  constructor(
    private authHttp: AuthHttpService
  ) {}

  public getKindergeldAmount(statementId: number, childId: number): Observable<ChildKindergeld> {
    return this.authHttp.get(`${getApiPath()}statements/${statementId}/children/${childId}/kindergeld`)
      .pipe(map((response: any) => ChildKindergeld.fromResponse(response)));
  }
}
