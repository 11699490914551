<div class="deductions-info" *ngIf="deductionInfoText">
  <div class="deductions-info__wrap">
    <img src="../../../../../../assets/svg/warning-icon.svg" class="alert_icon" />
    <div class="deductions-info__wrap-text">
      <p class="deductions-info__text">
        {{ deductionInfoText | translate }}
      </p>
    </div>
  </div>
</div>
<ng-container *ngTemplateOutlet="beforeTemplateRef"></ng-container>

<div class="alert-box alert-box--info mb-1" *ngIf="!list.length">
  {{ listIsEmptyMessage | translate }}
</div>
<div class="mb-1_5 box_container" *ngFor="let item of list; let index = index; let last = last; trackBy: trackBy">
  <div class="d-flex justify-content-between mb-1">
    <div class="font-size-24 font-weight-bold">
      <span>{{ itemTitle | translate }}</span> {{ index + 1 }}
    </div>
    <div>
      <app-button-delete
        *ngIf="isRemoveButtonEnabled"
        [withText]="true"
        [autoFocus]="false"
        (clickEmitter)="removeButtonClickEmitter.emit(index)"
      >
        {{ "COMMON.REMOVE" | translate }}
      </app-button-delete>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="formTemplateRef; context: { item: item, index: index, last: last }"></ng-container>
</div>

<div class="mb-1 add-another" *ngIf="isAddButtonEnabled">
  <app-button-add
    (clickEmitter)="addButtonClickEmitter.emit()"
    [isDisabled]="isAddButtonDisabled"
    [title]="addButtonTitle"
  >
  </app-button-add>
</div>
