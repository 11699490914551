export const VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD = {
  first_name: 'child_first_name',
  last_name: 'child_last_name',
  birth_date: 'child_birth_date',
  identifikationsnummer: 'child_identifikationsnummer',
  propinquity: 'child_propinquity',
  propinquity_to_spouse: 'child_propinquity_to_spouse',
  different_address: 'child_different_address',
  child_residence_address: 'child_residence_address',
  child_residence_country: 'child_residence_country',
  child_residence_period_lower: 'child_residence_period_lower',
  child_residence_period_upper: 'child_residence_period_upper',
  second_parent_first_name: 'child_second_parent_first_name',
  second_parent_last_name: 'child_second_parent_last_name',
  second_parent_birth_date: 'child_second_parent_birth_date',
  propinquity_to_second_parent: 'child_propinquity_to_second_parent',
  propinquity_from_lower: 'child_propinquity_from_lower',
  propinquity_from_upper: 'child_propinquity_from_upper',
  last_known_address: 'child_last_known_address',
  second_parent_lived_abroad_from_lower: 'child_second_parent_lived_abroad_from_lower',
  second_parent_lived_abroad_from_upper: 'child_second_parent_lived_abroad_from_upper',
  second_parent_death_date: 'child_second_parent_death_date',
  familienkasse: 'child_familienkasse',
  kindergeld: 'child_kindergeld',
  kindergeld_extra: 'child_kindergeld_extra',
  school_names: 'child_school_names',
  education_dates_lower: 'child_education_dates_lower',
  education_dates_upper: 'child_education_dates_upper',
  lack_of_training_space_dates_lower: 'child_lack_of_training_space_dates_lower',
  lack_of_training_space_dates_upper: 'child_lack_of_training_space_dates_upper',
  voluntary_year_dates_lower: 'child_voluntary_year_dates_lower',
  voluntary_year_dates_upper: 'child_voluntary_year_dates_upper',
  transitional_period_dates_lower: 'child_transitional_period_dates_lower',
  transitional_period_dates_upper: 'child_transitional_period_dates_upper',
  unemployment_dates_lower: 'child_unemployment_dates_lower',
  unemployment_dates_upper: 'child_unemployment_dates_upper',
  disability_before_25_dates_lower: 'child_disability_before_25_dates_lower',
  disability_before_25_dates_upper: 'child_disability_before_25_dates_upper',
  minijob_dates_lower: 'child_minijob_dates_lower',
  minijob_dates_upper: 'child_minijob_dates_upper',
  minijob_number_of_hours: 'child_minijob_number_of_hours',
  employment_dates_lower: 'child_employment_dates_lower',
  employment_dates_upper: 'child_employment_dates_upper',
  employment_number_of_hours: 'child_employment_number_of_hours',
  is_studying_or_completed_checkboxes: 'child_is_studying_or_completed_checkboxes',
  have_job_checkboxes: 'child_have_job_checkboxes',
};
