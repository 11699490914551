<div
  class="form-input"
  (clickElsewhere)="closeTooltip()"
  [class.whiteColor]="whiteColor"
  [class.employerStage]="employerStage"
  [attr.data-cy]="cyName"
>
  <div class="form-input__header level">
    <div class="level-left" (click)="closeTooltip()">
      <label class="form-input__label" [class.center]="centerLabel" [class.sm]="smLabel">
        <span class="form-input__label-tx">{{ label | translate }}</span>
      </label>
    </div>
    <app-more-info-tooltip
      class="tooltipInInput"
      *ngIf="message"
      [message]="message"
      [isSmall]="true"
      [showText]="false"
      [isSmallIcon]="true"
      [additionalDeductions]="true"
      [tooltipInInput]=true
    ></app-more-info-tooltip>
  </div>
  <div
    class="form-input__content"
    [class.disabled]="isDisabled"
    [class.active]="activeInput"
    [class.notEmpty]="inputChange"
    [class.error]="errors && errors.length"
    [class.warning]="warnings && warnings.length"
    [class.sm]="smLabel"
  >
    <input
      *ngIf="noYear"
      [placeholder]="placeholder | translate"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      (ngModelChange)="change($event)"
      [maxlength]="noYear ? 5 : 10"
      [attr.id]="currentInputId"
      [ngModel]="formatDate(model)"
      [class.form-input--error]="errors && errors.length"
      [class.form-input--warning]="warnings && warnings.length"
      [class.center]="centerTxtInput"
      [ngClass]="addClassB2B ? classB2B : ''"
      [class.isEditableSummaryEmployer]="isEditableSummaryEmployer"
      [dropSpecialCharacters]="false"
      [mask]="'d0/M0'"
      [disabled]="isDisabled"
    >
    <input
      *ngIf="!noYear"
      [placeholder]="placeholder | translate"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      (ngModelChange)="change($event)"
      [maxlength]="noYear ? 5 : 10"
      [attr.id]="currentInputId"
      [ngModel]="formatDate(model)"
      [class.form-input--error]="errors && errors.length"
      [class.form-input--warning]="warnings && warnings.length"
      [class.center]="centerTxtInput"
      [ngClass]="addClassB2B ? classB2B : ''"
      [class.isEditableSummaryEmployer]="isEditableSummaryEmployer"
      [disabled]="isDisabled"
    >
  </div>
  <div class="form-input__error" *ngIf="errors && errors[0]">{{ errors[0] }}</div>
  <div class="form-input__warning" *ngIf="(!errors || !errors.length) && warnings">{{ warnings[0] }}</div>
</div>
