import {NativeDateAdapter} from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      const day = this.to2digits(date.getDate());
      const month = this.to2digits(date.getMonth() + 1);
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    } else {
      return date.toDateString();
    }
  }

  parse(value: any): Date | null {
    if (typeof value !== 'string') {
      return null;
    }

    let separator;
    if (value.indexOf('.') > -1) {
      separator = '.';
    } else if (value.indexOf('-') > -1) {
      separator = '-';
    } else if (value.indexOf('/') > -1) {
      separator = '/';
    } else {
      return null;
    }

    const parts = value.split(separator);
    if (!parts || parts.length < 3) {
      return null;
    }
    const day = Number(parts[0]);
    const month = Number(parts[1]) - 1;
    const year = Number(parts[2]);

    if (day.toString().length > 2 || month.toString().length > 2 || year.toString().length !== 4) {
      return null;
    }

    return isNaN(day) || isNaN(month) || isNaN(year) ? null : new Date(year, month, day);
  }

  private to2digits(n: number): string {
    return `00${n}`.slice(-2);
  }
}

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'numeric'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};
