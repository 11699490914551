import { ClientType } from "./client-type";
import { UserType } from "./user-type";

export interface ExtendPersonalInfoReponse {
  applicant_first_name: string;
  applicant_last_name: string;
  applicant_street: string;
  applicant_city: string;
  applicant_country: string;
  applicant_postal_code: string;
  applicant_house_number: string;
  applicant_additional_address_info: string;
  applicant_phone_number: string;
  applicant_birth_date: string;
  applicant_tax_class_id: number;
  applicant_religion_id: number;
  applicant_tax_authority_id: number;
  applicant_identifikationsnummer: string;
  applicant_iban: string;
  applicant_bic: string;
  applicant_money_institution: string;
  spouse_first_name: string;
  spouse_last_name: string;
  spouse_street: string;
  spouse_city: string;
  spouse_country: string;
  spouse_postal_code: string;
  spouse_house_number: string;
  spouse_additional_address_info: string;
  spouse_birth_date: string;
  spouse_tax_class_id: number;
  spouse_religion_id: number;
  spouse_identifikationsnummer?: string;
  child_first_name: string;
  child_last_name: string;
  child_birth_date: string;
  child_identifikationsnummer?: string;
}

export interface UserResponse {
  first_name: string;
  last_name: string;
  country: string;
  city: string;
  postal_code: string;
  street: string;
  street_number: string;
  phone_number: string;
  language: string;
  nip: string;
  user: {
    id: number;
    email: string;
  };
  available_tokens: number;
  client_type: ClientType;
  is_subaccount: boolean;
  is_master: boolean;
  can_send_elster_messages: boolean;
  have_to_show_license_agreement?: boolean;
  have_to_show_gdpr_license_agreement?: boolean;
  receive_newsletters?: boolean | null;
  is_tax_adviser?: boolean;
  extend_personal_info: ExtendPersonalInfoReponse;
  trade_condition?: number;
  email_verified?: boolean;
  citizenship?: string;
}

export class ExtendPersonalInfo {
  applicantFirstName: string;
  applicantLastName: string;
  applicantStreet: string;
  applicantCity: string;
  applicantCountry: string;
  applicantPostalCode: string;
  applicantHouseNumber: string;
  applicantAdditionalAddressInfo: string;
  applicantPhoneNumber: string;
  applicantBirthdate: string;
  applicantTaxClassId: number;
  applicantReligionId: number;
  applicantTaxAuthorityId: number;
  applicantIdentifikationsnummer: string;
  applicantIban: string;
  applicantBic: string;
  applicantMoneyInstitution: string;
  spouseFirstName: string;
  spouseLastName: string;
  spouseStreet: string;
  spouseCity: string;
  spouseCountry: string;
  spousePostalCode: string;
  spouseHouseNumber: string;
  spouseAdditionalAddressInfo: string;
  spouseBirthdate: string;
  spouseTaxClassId: number;
  spouseReligionId: number;
  spouseIdentifikationsnummer: string;
  childFirstName: string;
  childLastName: string;
  childBirthDate: string;
  childIdentifikationsnummer: string;

  public static fromResponse(_data: ExtendPersonalInfoReponse): ExtendPersonalInfo {
    const extendPersonalInfo = new ExtendPersonalInfo();
    extendPersonalInfo.applicantFirstName = _data.applicant_first_name ? _data.applicant_first_name : null;
    extendPersonalInfo.applicantLastName = _data.applicant_last_name ? _data.applicant_last_name : null;
    extendPersonalInfo.applicantStreet = _data.applicant_street ? _data.applicant_first_name : null;
    extendPersonalInfo.applicantCity = _data.applicant_city ? _data.applicant_city : null;
    extendPersonalInfo.applicantCountry = _data.applicant_country ? _data.applicant_country : null;
    extendPersonalInfo.applicantPostalCode = _data.applicant_postal_code ? _data.applicant_postal_code : null;
    extendPersonalInfo.applicantHouseNumber = _data.applicant_house_number ? _data.applicant_house_number : null;
    extendPersonalInfo.applicantAdditionalAddressInfo = _data.applicant_additional_address_info
      ? _data.applicant_additional_address_info
      : null;
    extendPersonalInfo.applicantPhoneNumber = _data.applicant_phone_number ? _data.applicant_phone_number : null;
    extendPersonalInfo.applicantBirthdate = _data.applicant_birth_date ? _data.applicant_birth_date : null;
    extendPersonalInfo.applicantTaxClassId = _data.applicant_tax_class_id ? _data.applicant_tax_class_id : 0;
    extendPersonalInfo.applicantReligionId = _data.applicant_religion_id ? _data.applicant_religion_id : 0;
    extendPersonalInfo.applicantTaxAuthorityId = _data.applicant_tax_authority_id
      ? _data.applicant_tax_authority_id
      : 0;
    extendPersonalInfo.applicantIdentifikationsnummer = _data.applicant_identifikationsnummer
      ? _data.applicant_identifikationsnummer
      : null;
    extendPersonalInfo.applicantIban = _data.applicant_iban ? _data.applicant_iban : null;
    extendPersonalInfo.applicantBic = _data.applicant_bic ? _data.applicant_bic : null;
    extendPersonalInfo.applicantMoneyInstitution = _data.applicant_money_institution
      ? _data.applicant_money_institution
      : null;
    extendPersonalInfo.spouseFirstName = _data.spouse_first_name ? _data.spouse_first_name : null;
    extendPersonalInfo.spouseLastName = _data.spouse_last_name ? _data.spouse_last_name : null;
    extendPersonalInfo.spouseStreet = _data.spouse_street ? _data.spouse_street : null;
    extendPersonalInfo.spouseCity = _data.spouse_city ? _data.spouse_city : null;
    extendPersonalInfo.spouseCountry = _data.spouse_country ? _data.spouse_country : null;
    extendPersonalInfo.spousePostalCode = _data.spouse_postal_code ? _data.spouse_postal_code : null;
    extendPersonalInfo.spouseHouseNumber = _data.spouse_house_number ? _data.spouse_house_number : null;
    extendPersonalInfo.spouseAdditionalAddressInfo = _data.spouse_additional_address_info
      ? _data.spouse_additional_address_info
      : null;
    extendPersonalInfo.spouseBirthdate = _data.spouse_birth_date ? _data.spouse_birth_date : null;
    extendPersonalInfo.spouseTaxClassId = _data.spouse_tax_class_id ? _data.spouse_tax_class_id : 0;
    extendPersonalInfo.spouseReligionId = _data.spouse_religion_id ? _data.spouse_religion_id : 0;
    extendPersonalInfo.spouseIdentifikationsnummer = _data.spouse_identifikationsnummer
      ? _data.spouse_identifikationsnummer
      : null;
    extendPersonalInfo.childFirstName = _data.child_first_name ? _data.child_first_name : null;
    extendPersonalInfo.childLastName = _data.child_last_name ? _data.child_last_name : null;
    extendPersonalInfo.childBirthDate = _data.child_birth_date ? _data.child_birth_date : null;
    extendPersonalInfo.childIdentifikationsnummer = _data.child_identifikationsnummer
      ? _data.child_identifikationsnummer
      : null;

    return extendPersonalInfo;
  }

  public get toResponse(): ExtendPersonalInfoReponse {
    return {
      applicant_first_name: this.applicantFirstName,
      applicant_last_name: this.applicantLastName,
      applicant_street: this.applicantStreet,
      applicant_city: this.applicantCity,
      applicant_country: this.applicantCountry,
      applicant_postal_code: this.applicantPostalCode,
      applicant_house_number: this.applicantHouseNumber,
      applicant_additional_address_info: this.applicantAdditionalAddressInfo,
      applicant_phone_number: this.applicantPhoneNumber,
      applicant_birth_date: this.applicantBirthdate,
      applicant_tax_class_id: this.applicantTaxClassId,
      applicant_religion_id: this.applicantReligionId,
      applicant_tax_authority_id: this.applicantTaxAuthorityId,
      applicant_identifikationsnummer: this.applicantIdentifikationsnummer,
      applicant_iban: this.applicantIban,
      applicant_bic: this.applicantBic,
      applicant_money_institution: this.applicantMoneyInstitution,
      spouse_first_name: this.spouseFirstName,
      spouse_last_name: this.spouseLastName,
      spouse_street: this.spouseStreet,
      spouse_city: this.spouseCity,
      spouse_country: this.spouseCountry,
      spouse_postal_code: this.spousePostalCode,
      spouse_house_number: this.spouseHouseNumber,
      spouse_additional_address_info: this.spouseAdditionalAddressInfo,
      spouse_birth_date: this.spouseBirthdate,
      spouse_tax_class_id: this.spouseTaxClassId,
      spouse_religion_id: this.spouseReligionId,
      spouse_identifikationsnummer: this.spouseIdentifikationsnummer,
      child_first_name: this.childFirstName,
      child_last_name: this.childLastName,
      child_birth_date: this.childBirthDate,
      child_identifikationsnummer: this.childIdentifikationsnummer
    };
  }
}

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  postalCode: string;
  street: string;
  streetNumber: string;
  phoneNumber: string;
  language: string;
  nip: string;
  available_tokens: number;
  clientType: ClientType;
  haveToShowLicenseAgreement: boolean;
  haveToShowGdprLicenseAgreement: boolean;
  isSubaccount: boolean;
  isMasterAccount: boolean;
  receiveNewsletters?: boolean | null;
  isTaxAdviser = false;
  extendPersonalInfo?: ExtendPersonalInfo | null;
  tradeCondition?: number;
  emailVerified?: boolean;
  citizenship?: string;
  can_send_elster_messages?: boolean;

  public static fromResponse(response: UserResponse): User {
    const user = new User();
    user.firstName = response.first_name;
    user.lastName = response.last_name;
    user.id = response.user.id;
    user.email = response.user.email;
    user.country = response.country;
    user.city = response.city;
    user.postalCode = response.postal_code;
    user.street = response.street;
    user.streetNumber = response.street_number;
    user.phoneNumber = response.phone_number;
    user.language = response.language;
    user.nip = response.nip;
    user.available_tokens = response.available_tokens;
    user.isSubaccount = response.is_subaccount;
    user.isMasterAccount = response.is_master;
    user.can_send_elster_messages = response.can_send_elster_messages;
    user.clientType = response.client_type;
    user.haveToShowLicenseAgreement = response.have_to_show_license_agreement;
    user.haveToShowGdprLicenseAgreement = response.have_to_show_gdpr_license_agreement;
    user.receiveNewsletters = response.receive_newsletters;
    user.isTaxAdviser = response.is_tax_adviser;
    user.tradeCondition = response.trade_condition;
    user.emailVerified = response.email_verified;
    user.citizenship = response.citizenship;
    if (response.extend_personal_info) {
      user.extendPersonalInfo = ExtendPersonalInfo.fromResponse(response.extend_personal_info);
    }
    return user;
  }

  public get toResponse(): UserResponse {
    const blank = undefined;
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      country: this.country,
      city: this.city,
      postal_code: this.postalCode,
      street: this.street,
      street_number: this.streetNumber,
      phone_number: this.phoneNumber,
      language: this.language,
      nip: this.nip,
      user: {
        id: this.id,
        email: this.email
      },
      available_tokens: this.available_tokens,
      client_type: this.clientType,
      receive_newsletters: this.receiveNewsletters,
      is_subaccount: this.isSubaccount,
      is_master: this.isMasterAccount,
      can_send_elster_messages: this.can_send_elster_messages,
      extend_personal_info: this.extendPersonalInfo ? this.extendPersonalInfo.toResponse : blank,
      trade_condition: this.tradeCondition,
      citizenship: this.citizenship
    };
  }

  public get isBusiness(): boolean {
    return this.clientType === UserType.business;
  }

  public get isBusinessNotTaxAdviser(): boolean {
    return this.clientType === UserType.business && !this.isTaxAdviser;
  }

  public get isIndividual(): boolean {
    return this.clientType === UserType.individual;
  }

  public get getFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
