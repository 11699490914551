<div class="payment-details" *ngIf="user">
  <div class="payment-details__content">
    <div class="payment-details__content-text">{{ "PAYMENT_DETAILS.TEXT_FIRST" | translate }}</div>
    <div class="payment-details__content-text">{{ "PAYMENT_DETAILS.TEXT_SECOND" | translate }}</div>
  </div>
  <div class="payment-details__credits">
    <div class="payment-details__credits-title">{{ "PAYMENT_DETAILS.AVAILABLE_TOKENS" | translate }}</div>
    <div class="payment-details__credits-available">
      <span class="payment-details__credits-available-currency"></span>
      <span class="payment-details__credits-available-value">{{ user.available_tokens }}</span>
    </div>
    <div class="payment-details__credits-buy" *ngIf="!user.isSubaccount">
      <button class="btn btn--primary btn--block" (click)="buyCredits()">{{
        "PAYMENT_DETAILS.BUY_CREDITS" | translate
      }}</button>
    </div>
  </div>
</div>
