<div class="search-engine">
  <div
    class="search-engine__overlay"
    (click)="closeResults()"
    [ngClass]="{ 'search-engine__overlay--opened': isOpened }"
  ></div>
  <div class="search-engine__row">
    <div class="search-engine__input">
      <i class="fa fa-search"></i>
      <input
        type="text"
        [(ngModel)]="value"
        placeholder="{{ 'COMMON.SEARCH_ENGINE' | translate }}"
        (keyup.enter)="search()"
        (keyup.escape)="closeResults()"
      >
    </div>
    <div class="search-engine__btn" (click)="search()">{{ 'COMMON.SEARCH' | translate }}</div>
  </div>
  <div class="search-engine__results" [ngClass]="{ 'search-engine__results--opened': isOpened }">
    <div class="search-engine__list" *ngIf="!loading">
      <div *ngIf="!isBusiness">
        <app-list-view-b2b
          *ngIf="filteredStatements && filteredStatements.length"
          [statements]="filteredStatements"
          (downloadInvoice)="downloadInvoice($event)"
          [hideRemoving]="true"
        >
        </app-list-view-b2b>
        <div
          class="search-engine__empty"
          *ngIf="!filteredStatements || !filteredStatements.length"
        >
          {{ 'COMMON.SEARCH_EMPTY' | translate }}
        </div>
      </div>
      <div *ngIf="isBusiness">
        <div class="search-engine__batch" *ngFor="let batch of filteredBatches; let i = index">
          <div>
            <div class="search-engine__batch-text">
              <span>{{ 'COMMON.BATCH_FILE' | translate }}</span>
              <div class="search-engine__buttons">
                <button
                  class="btn btn--info btn--small"
                  *ngIf="!hideEditButton(i)"
                  (click)="editBatch(i)"
                  [attr.data-cy]="'btnEditStatementBatch_'+i"
                >
                  {{ 'COMMON.EDIT_BATCH' | translate }}
                </button>
              </div>
            </div>
            <div class="search-engine__batch-content">
              <app-accordion
                [title]="batch.code"
                [titleFrame]="true"
                [transparentBg]="true"
                [noPadding]="true"
                [canCollapse]="false"
              >
                <app-list-view-b2b
                  [statements]="batch.statements"
                  (deleteStatement)="deleteBatchStatement($event, i)"
                  [hideEditing]="true"
                  [hideInvoice]="true"
                ></app-list-view-b2b>
                <div
                  class="search-engine__batch-empty"
                  *ngIf="!batch?.statements?.length"
                >
                  {{ 'COMMON.BATCH_EMPTY' | translate }}
                </div>
              </app-accordion>
            </div>
          </div>
        </div>
        <div
          class="search-engine__empty"
          *ngIf="!filteredBatches|| !filteredBatches.length"
        >
          {{ 'COMMON.SEARCH_EMPTY_BATCHES' | translate }}
        </div>
      </div>
    </div>
    <div class="search-engine__loading" *ngIf="loading"></div>
  </div>
</div>
