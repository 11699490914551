<div class="add-value-list">
  <div class="add-value-list__title">
    <app-section-title
      [title]="title"
      [titleTooltip]="titleTooltip"
      [message]="messageTooltip"
      [isInnerHTML]="true"
      [tooltipContentRef]="tooltipContentRef"
    ></app-section-title>
  </div>
  <div class="add-value-list__content">
    <div class="add-value-list__content--list">
      <ng-container *ngTemplateOutlet="valueListViewTemplate"></ng-container>
    </div>
    <div class="add-value-list__content--action" *ngIf="displayAddButton()">
      <app-button-add (clickEmitter)="handleAddAction()" [title]="buttonText" [isDisabled]="handleIsDisabledButton()">
      </app-button-add>
      <div class="error-field" *ngIf="epValidatorField">
        <app-validator-error-message
          [epValidator]="epValidator"
          [index]="0"
          [fieldName]="epValidatorField"
        ></app-validator-error-message>
      </div>
    </div>
    <div class="add-value-list__content--inputs" *ngIf="showAddValueViewTemplate">
      <ng-container *ngTemplateOutlet="addValueViewTemplate"></ng-container>
    </div>
  </div>
</div>
