import {DateRange, DateRangeErrors, DateRangeResponse} from '../common/dateRange';

export interface ResidenceResponse {
  id: number;
  address: string;
  country: string;
  residence_period: DateRangeResponse;
}

export interface  ResidenceErrors {
  address: string;
  country: string;
  residence_period: DateRangeErrors;
}

export class Residence {
  id: number;
  address: string;
  country: string;
  residencePeriod: DateRange = new DateRange();

  static fromResponse(response: ResidenceResponse): Residence {
    const child = new Residence();
    child.id = response.id;
    child.address = response.address;
    child.country = response.country;
    child.residencePeriod = DateRange.fromResponse(response.residence_period);
    return child;
  }

  static fromStorage(response: Residence): Residence {
    const child = new Residence();
    child.id = response.id;
    child.address = response.address;
    child.country = response.country;
    child.residencePeriod = DateRange.fromResponse(response.residencePeriod);
    return child;
  }

  toResponse(defaultValue): ResidenceResponse {
    return {
      id: this.id ? this.id  : undefined,
      address: this.address ? this.address : defaultValue,
      country: this.country ? this.country : defaultValue,
      residence_period: this.residencePeriod ? this.residencePeriod.toResponse(defaultValue) : defaultValue
    };
  }
}
