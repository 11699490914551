import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { PersonalInfoComponentPath } from 'app/core/workflow/page-path/statement-page-path/personal-info-component.path';

export class WorkflowPersonalInfoService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.statement && this.statement.stagePersonalInfo
      && !!this.statement.firstName
      && !!this.statement.lastName
      && !!this.statement.birthDate
      && !!this.statement.street
      && !!this.statement.houseNumberWithSupplement
      && !!this.statement.postalCode
      && !!this.statement.city
      && !!this.statement.taxAuthority;
  }

  public get isVisible(): boolean {
    return true;
  }

  public get title(): string {
    return 'ASIDE_BAR.PERSONAL_INFO';
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.statement && !!this.statement.stageAbroadIncome;
  }

  public get urlRegex(): RegExp {
    return PersonalInfoComponentPath.regex();
  }

  public get url(): string {
    return PersonalInfoComponentPath.url(this.getStatementId);
  }
}
