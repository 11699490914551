<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content">
        <div class="terms-and-conditions">
            <h1>Steuerberatungsrahmen- und Vergütungsvereinbarung</h1>
            <div class="terms-and-conditions__stage">
                <p>zwischen</p>

                <p>{{statement.firstName}} {{statement.lastName}}<br>
                    {{statement.street}} {{statement.houseNumberWithSupplement}}<br>
                    <ng-container *ngIf="statement.additionalAddressInfo">
                        {{statement.additionalAddressInfo}} <br>
                    </ng-container>
                    {{statement.postalCode}} {{statement.city}}<br>
                    {{displayCountry()}}
                </p>

                <p>– nachfolgend „Mandant“ –</p>
                <br>
                <p>und</p>
                <br>
                <p>ETX Niecko Legal Rechtsanwaltsgesellschaft mbH</p>
                <p>Zweigstelle Teltow</p>
                <p>Adresse: Potsdamer Str. 62 14513 Teltow</p>
                <p>– nachfolgend „Rechtsanwaltsgesellschaft“ –</p>
                <br>
                <h1 class="terms-and-conditions__title terms-and-conditions__title--main">
                    Teil A. Steuerberatungsrahmenvereinbarung
                </h1>
                <div class="sub-header">§ 1 Umfang und Ausführung des Auftrags</div>
                <ol>
                    <li>
                        Die Rechtsanwaltsgesellschaft berät den Mandanten in steuerlichen Angelegenheiten. Nachstehendes
                        Leistungsspektrum
                        bietet die Rechtsanwaltsgesellschaft dem Mandanten zur Annahme durch Einzelvereinbarung nach Maßgabe des Abs. 2
                        an:
                        <ol>
                            <li>Allgemeine steuerliche Beratung in laufenden Angelegenheiten einschließlich der
                                Vertretung der
                                Interessen des Mandanten gegenüber den Finanzbehörden (die Erteilung einer Vollmacht ist
                                hiermit nicht
                                verbunden);
                            </li>
                            <li>
                                Erstellung von Stellungnahmen zu steuerrechtlichen Einzelfragen,
                                Steuerbelastungsvergleiche,
                                Investitions- und Finanzierungsrechnungen und vergleichbare Tätigkeiten, Testate für
                                Wirtschaftsförderung;
                            </li>
                            <li>
                                steuerliche Gestaltungsberatung;
                            </li>
                            <li>
                                Erstellung von Steuererklärungen;
                            </li>
                            <li>
                                Prüfung von Steuerbescheiden, Abrechnungsbescheiden und anderen
                                Fiskalverwaltungsakten;
                            </li>
                            <li>
                                außergerichtliche und gerichtliche Rechtsbehelfe in Abgabenangelegenheiten;
                            </li>
                            <li>
                                Erstellung von Umsatzsteuervoranmeldungen;
                            </li>
                        </ol>
                    </li>
                    <li>
                        Der Gegenstand der Beratungsleistung iSd. Abs. 1 wird zwischen den Parteien einvernehmlich
                        jeweils
                        durch Einzelvereinbarung festgelegt. Auftragserteilung und Auftragsannahme zu der jeweils
                        einzelnen
                        steuerlichen Angelegenheit erfolgen in Schrift- oder Textform.
                    </li>
                    <li>
                        Dieser Steuerberatungsrahmenvertrag gilt für alle Einzelaufträge, die der Mandant der
                        Rechtsanwaltsgesellschaft
                        erteilt, soweit nichts Abweichendes vereinbart ist.
                    </li>
                    <li>
                        Die Beratung erfolgt ausschließlich in deutschem Steuerrecht. Ausländisches Steuerrecht oder
                        sonstige rechtliche Beratung sind von der Beauftragung nicht erfasst.
                    </li>

                    <li>
                        Die Rechtsanwaltsgesellschaft wird die vom Mandanten genannten Tatsachen, insbesondere Zahlenangaben, als
                        richtig
                        zugrunde legen. Die Prüfung der Richtigkeit, Vollständigkeit und Ordnungsmäßigkeit der
                        übergebenen
                        Unterlagen und Zahlen, insbesondere der Buchführung und Bilanz, gehört nur zum Auftrag, wenn
                        dies
                        schriftlich vereinbart ist.
                    </li>
                </ol>
                <div class="sub-header">§ 2 Vergütung</div>
                <p>
                    Die Vergütung für die in § 1 bezeichneten Tätigkeiten bestimmen die Parteien mittels gesonderter
                    Vergütungsvereinbarung (Teil B.). Die Rechtsanwaltsgesellschaft weist den Mandanten darauf hin, dass in Textform eine
                    höhere oder niedrigere als die gesetzliche Vergütung vereinbart werden kann.
                </p>
                <div class="sub-header">§ 3 Verschwiegenheit</div>
                <p>
                    Die Rechtsanwaltsgesellschaft ist nach Maßgabe der gesetzlichen Vorgaben zur Verschwiegenheit verpflichtet. Darüber
                    hinaus besteht keine Verschwiegenheitspflicht, soweit dies zur Durchführung eines
                    Zertifizierungsaudits
                    in der Kanzlei der Rechtsanwaltsgesellschaft erforderlich ist und die insoweit tätigen Personen ihrerseits über ihre
                    Verschwiegenheitspflicht belehrt und zur Verschwiegenheit verpflichtet worden sind. Der Mandant
                    erklärt
                    sich damit einverstanden, dass durch einen entsprechenden Zertifizierer/Auditor Einsicht in seine –
                    von
                    der Rechtsanwaltsgesellschaft abgelegte und geführte – Handakte genommen werden darf. Ferner ist die Rechtsanwaltsgesellschaft
                    gegenüber
                    ihren Kooperationspartnern von der Verschwiegenheitspflicht befreit, soweit die Kooperationspartner
                    ebenfalls berufsmäßig zur Verschwiegenheit verpflichtet sind.
                </p>
                <div class="sub-header">§ 4 Mitwirkung Dritter</div>
                <p>
                    Die Rechtsanwaltsgesellschaft ist berechtigt, zur Ausführung des Auftrags Mitarbeiter, fachkundige Dritte sowie
                    datenverarbeitende Unternehmen heranzuziehen, die berufsmäßig oder durch entsprechende vertragliche
                    Vereinbarung mit der Rechtsanwaltsgesellschaft zur Verschwiegenheit iSd. § 3 verpflichtet sind. Die Rechtsanwaltsgesellschaft ist
                    berechtigt, allgemeinen Vertretern (§ 69 StBerG) sowie Praxistreuhändern (§ 71 StBerG) im Fall ihrer
                    Bestellung Einsichtnahme in die Handakten iSd. § 66 Abs. 2 StBerG zu verschaffen.
                </p>
                <div class="sub-header">§ 5 Gewährleistung</div>
                <ol>
                    <li>
                        Die Rechtsanwaltsgesellschaft ist nach Maßgabe der gesetzlichen Vorgaben zur Gewährleistung verpflichtet.
                    </li>
                    <li>
                        Offenbare Unrichtigkeiten (zB Schreibfehler, Rechenfehler) können von der Rechtsanwaltsgesellschaft jederzeit
                        Dritten
                        gegenüber berichtigt werden. Sonstige Mängel darf die Rechtsanwaltsgesellschaft Dritten gegenüber mit
                        Einwilligung des
                        Mandanten berichtigen. Die Einwilligung ist nicht erforderlich, wenn berechtigte Interessen von
                        der
                        Rechtsanwaltsgesellschaft den Interessen des Mandanten vorgehen.
                    </li>
                </ol>
                <div class="sub-header">
                    § 6 Haftung
                </div>
                <ol>
                    <li>
                        Die Haftung der Rechtsanwaltsgesellschaft für einen Schaden, der aus einer oder – bei einheitlicher
                        Schadensfolge –
                        aus mehreren Pflichtverletzungen anlässlich der Erfüllung eines Auftrags resultiert, wird auf
                        einen
                        Betrag iHv. € 1.000.000,– begrenzt. Die Beschränkung bezieht sich allein auf einfache
                        Fahrlässigkeit.
                        Die Haftung für grobe Fahrlässigkeit und Vorsatz bleibt insoweit unberührt. Von der
                        Haftungsbeschränkung
                        ausgenommen sind Haftungsansprüche für Schäden aus der Verletzung des Lebens, des Körpers oder
                        der
                        Gesundheit.
                    </li>
                    <li>
                        Die Haftungsbeschränkung nach Abs. 1. gilt für die gesamte Tätigkeit der Rechtsanwaltsgesellschaft für den
                        Mandanten,
                        also insbesondere für sämtliche nach § 1 erteilte Aufträge und Folgeaufträge des Mandanten.
                        Einer
                        erneuten Vereinbarung der Haftungsbeschränkung bedarf es für diese Aufträge nicht.
                    </li>
                    <li>
                        Die vereinbarte Haftungsbeschränkung nach Abs. 1 bis 2 gilt von Beginn der Mandatsbeziehung
                        mit dem
                        Mandanten an, hat ggf. also rückwirkende Kraft. Die Rechtsanwaltsgesellschaft versichert, dass ihr im Zeitpunkt
                        der
                        Zeichnung dieser Vereinbarung entstandene Haftungsansprüche nicht bekannt sind. Die
                        Haftungsbeschränkung
                        gilt auch für neu in die Rechtsanwaltsgesellschaft eintretende Sozien.
                    </li>
                    <li>
                        Die vereinbarten Haftungsbeschränkungen nach Abs. 1 bis 3 gelten auch gegenüber Dritten,
                        soweit
                        diese in den Schutzbereich des Mandatsverhältnisses fallen. § 334 BGB wird nicht abbedungen.
                    </li>
                    <li>
                        Einzelvertragliche Haftungsbegrenzungsvereinbarungen gehen dieser Vereinbarung vor, lassen
                        die Wirksamkeit dieser Haftungsbegrenzungsvereinbarung – soweit nicht ausdrücklich anders
                        geregelt - aber unberührt.
                    </li>
                </ol>
                <div class="sub-header">
                    § 7 Pflichten des Mandanten
                </div>
                <ol>
                    <li>
                        Der Mandant ist zur Mitwirkung verpflichtet, soweit es zur ordnungsgemäßen Erledigung des
                        Auftrags
                        erforderlich ist. Insbesondere hat er der Rechtsanwaltsgesellschaft unaufgefordert alle für die Ausführung des
                        Auftrags
                        notwendigen Unterlagen vollständig und so rechtzeitig zu übergeben, dass der Rechtsanwaltsgesellschaft eine
                        angemessene
                        Bearbeitungszeit zur Verfügung steht. Entsprechendes gilt für die Unterrichtung über alle
                        Vorgänge und
                        Umstände, die für die Ausführung des jeweiligen Auftrags von Bedeutung sein können. Der Mandant
                        ist
                        verpflichtet, alle schriftlichen und mündlichen Mitteilungen der Rechtsanwaltsgesellschaft zur Kenntnis zu nehmen
                        und bei
                        Zweifelsfragen Rücksprache zu halten.
                    </li>
                    <li>
                        Setzt die Rechtsanwaltsgesellschaft beim Mandanten in dessen Räumen Datenverarbeitungsprogramme ein, so ist der
                        Mandant verpflichtet, den Hinweisen der Rechtsanwaltsgesellschaft zur Installation und Anwendung der Programme
                        nachzukommen. Des Weiteren ist der Mandant nur berechtigt, die Programme in dem von der Rechtsanwaltsgesellschaft
                        vorgeschriebenen Umfang zu vervielfältigen oder zu verbreiten. Die Rechtsanwaltsgesellschaft bleibt Inhaber der
                        Nutzungsrechte. Der Mandant hat alles zu unterlassen, was der Ausübung der Nutzungsrechte an den
                        Programmen durch die Rechtsanwaltsgesellschaft entgegensteht.
                    </li>
                    <li>
                        Unterlässt der Mandant eine ihm nach § 7 Abs. 1 bis 2 oder sonst obliegende Mitwirkung oder
                        kommt er mit der Annahme der von der Rechtsanwaltsgesellschaft angebotenen Leistung in Verzug, ist die Rechtsanwaltsgesellschaft
                        nach
                        schriftlicher Setzung einer angemessenen Frist für die Mitwirkung oder Leistung berechtigt, den
                        Vertrag
                        fristlos zu kündigen. Unberührt bleibt der Anspruch der Rechtsanwaltsgesellschaft auf Ersatz der ihr durch den
                        Verzug oder
                        die unterlassene Mitwirkung des Mandanten entstandenen Mehraufwendungen sowie des verursachten
                        Schadens, und zwar auch dann, wenn die Rechtsanwaltsgesellschaft von dem Kündigungsrecht keinen Gebrauch macht.
                    </li>
                </ol>
                <div class="sub-header">
                    § 8 Beendigung des Vertrags
                </div>
                <ol>
                    <li>
                        Der Vertrag wird auf unbestimmte Zeit geschlossen. Der Vertrag endet nicht durch den Tod,
                        durch den
                        Eintritt der Geschäftsunfähigkeit des Mandanten – oder sofern es sich bei dem Mandanten um eine
                        Gesellschaft handelt – durch Gesamtrechtsnachfolge oder Auflösung des Mandanten oder durch
                        Gesamtrechtsnachfolge oder Auflösung der Rechtsanwaltsgesellschaft.
                    </li>
                    <li>
                        Der Vertrag kann von jedem Vertragspartner jederzeit mit sofortiger Wirkung – außer zur
                        Unzeit –
                        gekündigt werden; die Kündigung hat schriftlich zu erfolgen.
                    </li>
                    <li>
                        Bei Kündigung des Vertrags durch die Rechtsanwaltsgesellschaft sind zur Vermeidung von Rechtsverlusten des
                        Mandanten
                        in jedem Fall noch diejenigen Handlungen vorzunehmen, die zumutbar sind und keinen Aufschub
                        dulden.
                    </li>
                    <li>
                        Nach Beendigung des Mandatsverhältnisses sind die der Rechtsanwaltsgesellschaft übergebenen Unterlagen von
                        der Rechtsanwaltsgesellschaft bereitzustellen und durch den Mandanten am Sitz der Rechtsanwaltsgesellschaft abzuholen. Sofern die
                        Handakte elektronisch geführt wird, ist die Rechtsanwaltsgesellschaft nach ihrer Wahl berechtigt, die Handakte
                        dem
                        Mandanten entweder elektronisch auf einem verkehrsüblichen Datenspeicher (zB USB-Stick, CD-ROM,
                        DVD) in
                        maschinenlesbarer Form zur Verfügung zu stellen oder in Papierform oder durch elektronische
                        Datenübergabe über den Softwareanbieter, sofern hierzu die technische Möglichkeit besteht und
                        der Mandant oder der neue Steuerberater des Mandanten dieser Vorgehensweise zugestimmt hat, zu
                        übergeben. Die Rechtsanwaltsgesellschaft ist verpflichtet, dem Mandant die erforderlichen Nachrichten über den
                        Bearbeitungsstand im Mandant zu geben, auf Verlangen über den Stand der Angelegenheit Auskunft
                        zu erteilen und
                        Rechenschaft abzulegen. § 9 bleibt unberührt.
                    </li>
                    <li>
                        Mit Beendigung des Vertrags hat der Mandant der Rechtsanwaltsgesellschaft die bei ihm zur Ausführung des
                        Auftrags eingesetzten Datenverarbeitungsprogramme einschließlich angefertigter Kopien sowie
                        sonstige
                        Programmunterlagen unverzüglich herauszugeben bzw. von der Festplatte zu löschen. § 9 bleibt
                        unberührt.
                    </li>
                </ol>
                <div class="sub-header">
                    § 9 Aufbewahrung, Herausgabe
                </div>
                <ol>
                    <li>
                        Die Rechtsanwaltsgesellschaft hat die Handakten nach Beendigung des Auftrags zehn Jahre, in jedem Fall aber
                        bis zum Ablauf der für die Aufbewahrung der Akten für den Mandanten bestimmten gesetzlichen
                        Fristen,
                        aufzubewahren. Diese Verpflichtung erlischt schon vor Beendigung dieses Zeitraums, wenn die
                        Rechtsanwaltsgesellschaft den Mandanten schriftlich aufgefordert hat, die Handakten binnen einer Frist von drei
                        Monaten ab
                        Zugang des Aufforderungsschreibens in Empfang zu nehmen und der Mandant dieser Aufforderung
                        binnen drei
                        Monaten, nachdem er das Aufforderungsschreiben erhalten hat, nicht nachgekommen ist.
                    </li>
                    <li>
                        Auf Anforderung des Mandanten hat die Rechtsanwaltsgesellschaft dem Mandanten die Handakten vorbehaltlich
                        eines gesetzlichen Zurückbehaltungsrechts innerhalb einer angemessenen Frist zur Abholung
                        bereitzustellen. Die Rechtsanwaltsgesellschaft kann von Unterlagen, die sie an den Mandanten zurückgibt,
                        Abschriften oder Fotokopien
                        anfertigen und zurückbehalten.
                    </li>
                </ol>
                <div class="sub-header">
                    § 10 Datenschutz
                </div>
                <ol>
                    <li>
                        Die Rechtsanwaltsgesellschaft speichert Informationen über den Mandanten in ihrem EDV-System. Der Mandant ist
                        einverstanden, dass die Rechtsanwaltsgesellschaft ihr EDV-System durch qualifiziertes, externes Fachpersonal
                        warten lässt.
                    </li>
                    <li>
                        Die Rechtsanwaltsgesellschaft ist als „Verantwortliche“ im Sinne der EU-Datenschutz-Grundverordnung (DS-GVO)
                        unter den folgenden Kontaktdaten erreichbar: ETX Niecko Legal Rechtsanwaltsgesellschaft mbH, Postanschrift: Potsdamer Str. 62, 14513 Teltow, E-Mail: office@etxlegal.de, Telefon: +49 3328-4772586.
                    </li>
                    <li>
                        Der betriebliche Datenschutzbeauftragte der Rechtsanwaltsgesellschaft, Herr Radoslaw Niecko ist zu erreichen per E Mail unter: office@etxlegal.de.
                    </li>
                    <li>
                        Für die durchgeführte Datenverarbeitung gelten im Übrigen die nachfolgenden Hinweise.
                        Wenn Sie uns mandatieren, erheben wir folgende Informationen:
                        <ol>
                            <li>
                                Anrede, Vorname, Nachname,
                            </li>
                            <li>
                                eine oder mehrere gültige E-Mail-Adressen,
                            </li>
                            <li>
                                postalische Anschrift,
                            </li>
                            <li>
                                Telefonnummer (Festnetz und/oder Mobilfunk),
                            </li>
                            <li>
                                darüber hinausgehende Informationen, die für die Beratung und die Tätigkeit im Rahmen
                                des Auftrags iSd § 1 notwendig sind.
                            </li>
                        </ol>
                        <p>
                            Die Erhebung dieser Daten erfolgt, um Sie als unseren Mandanten identifizieren zu können und
                            um Sie
                            angemessen steuerlich beraten und vertreten zu können sowie zur Korrespondenz mit Ihnen, zur
                            Rechnungsstellung.
                        </p>
                    </li>
                    <li>
                        Wir informieren Sie über die Auftragsbeziehung hinaus gerne über aktuelle steuerrechtliche
                        Fragen,
                        über Entwicklungen unserer Rechtsanwaltsgesellschaft oder über von uns organisierte Veranstaltungen. Zu diesen
                        Zwecken
                        nutzen wir insbesondere auch die E-Mail-Adressen, die uns im Rahmen des Auftrags von Ihnen oder
                        Ihren
                        Mitarbeitern mitgeteilt werden. Jeder Empfänger kann uns bei Erhalt einer solchen E-Mail
                        unmittelbar
                        durch einen dort vorgesehenen Link mitteilen, dass er zukünftig keine weiteren Nachrichten unter
                        der
                        verwendeten E-Mail-Adresse erhalten möchte.
                    </li>
                    <li>
                        Die Datenverarbeitung erfolgt auf Ihre Anfrage hin. Mit der Unterzeichnung dieser
                        Vereinbarung
                        erklären Sie insoweit nach Art. 6 Abs. 1 Satz 1 Buchst. a DS-GVO Ihre Einwilligung zu der
                        beschriebenen
                        Datenverarbeitung. Sie ist zudem in dem unter Ziffer 4 beschriebenen Umfang nach Art. 6 Abs. 1
                        Satz 1
                        Buchst. b DS-GVO für die angemessene Bearbeitung des Auftrags iSd § 1 und für die beidseitige
                        Erfüllung
                        von Verpflichtungen aus der vorliegenden Vereinbarung erforderlich.
                    </li>
                    <li>
                        Die für die Mandatierung von uns erhobenen personenbezogenen Daten werden in Anlehnung an
                        die
                        steuerlichen Aufbewahrungsfristen für einen Zeitraum von zehn Jahren nach Ablauf des
                        Kalenderjahrs, in
                        dem das Mandat beendet wurde, gespeichert und danach gelöscht, es sei denn, dass wir gemäß
                        Art. 6 Abs. 1
                        Satz 1 Buchst. c DS-GVO aufgrund von gesetzlichen Aufbewahrungs- und Dokumentationspflichten
                        (beispielsweise aus dem StGB oder Steuergesetzen) zu einer längeren Speicherung verpflichtet
                        sind. Mit
                        der Unterzeichnung der vorliegenden Vereinbarung erklären Sie nach Art. 6 Abs. 1 Satz 1
                        Buchst. a DS-GVO
                        ihre Einwilligung zu dieser Vorgehensweise.
                    </li>
                    <li>
                        Sämtliche Daten und Informationen, die uns im Rahmen des Auftragsverhältnisses bekannt
                        werden,
                        unterliegen der berufsmäßigen Verschwiegenheit. Darüber hinaus findet eine Übermittlung Ihrer
                        persönlichen Daten an Dritte unter datenschutzrechtlichen Gesichtspunkten nur statt, soweit Sie
                        nach
                        Art. 6 Abs. 1 Satz 1 Buchst. a DS-GVO zugestimmt haben oder dies nach Art. 6 Abs. 1 Satz 1
                        Buchst. b
                        DS-GVO für die Abwicklung von Auftragsverhältnissen mit Ihnen erforderlich ist. Hierzu gehört
                        beispielsweise die Weitergabe an Vertrags- und Verhandlungspartner, Verfahrensgegner und deren
                        Vertreter
                        (insbesondere deren Rechtsanwälte) sowie Gerichte und andere öffentliche Behörden zum Zwecke der
                        Korrespondenz sowie zur Geltendmachung und Verteidigung Ihrer Rechte.
                    </li>
                    <li>
                        Sie haben hinsichtlich der Daten die aus den Vorgaben der DS-GVO folgenden Betroffenenrechte
                        (Auskunft, Berichtigung oder Vervollständigung, Löschung, Einschränkung der Verarbeitung,
                        Beschwerde bei
                        der Aufsichtsbehörde und/oder Widerspruch gegen eine Nutzung auf der Grundlage von berechtigten
                        Interessen). Wegen der Einzelheiten verweisen wir auf die allgemeine Datenschutzerklärung der
                        Rechtsanwaltsgesellschaft,
                        die auf unserer Internetseite (www.etxlegal.de) in der jeweils aktuellen Form verfügbar ist.
                    </li>
                    <li>
                        Der Mandant erklärt sein Einverständnis, dass die Kommunikation zwischen Rechtsanwaltsgesellschaft und
                        Mandant, aber
                        auch gegenüber sonst in das Mandat eingebundenen Dritten auch mittels unverschlüsselter E-Mail
                        erfolgen
                        kann. Im Hinblick auf den Einsatz von unverschlüsselten E-Mails weist die Rechtsanwaltsgesellschaft vorsorglich
                        auf
                        folgende Risiken und Umstände hin:
                        <ol>
                            <li>
                                Derzeit besteht bei jeder unverschlüsselten Versendung von Informationen und
                                Dokumenten per E-Mail
                                ein technisch unvermeidbares Risiko, dass
                                <ol>
                                    <li>
                                        sich Dritte Zugang zu den enthaltenen Daten verschaffen und damit Kenntnis von
                                        ihrem Inhalt erlangen;
                                    </li>
                                    <li>
                                        E-Mails Viren enthalten;
                                    </li>
                                    <li>
                                        theoretisch andere Internet-Teilnehmer den Inhalt der E-Mails modifizieren
                                        können;
                                    </li>
                                    <li>
                                        nicht vollständig sichergestellt ist, dass E-Mails tatsächlich von dem
                                        Absender stammen, der angegeben
                                        ist.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Da gegenwärtig ein strafrechtlicher Schutz für E-Mails nicht besteht (z.B fallen sie
                                nicht unter den
                                Schutz des Postgeheimnisses), ist die rechtliche Zugriffsschranke für Dritte gering.
                                Entsprechend kann
                                die Rechtsanwaltsgesellschaft eine Haftung für die Sicherheit der übermittelten Daten und Informationen
                                nicht übernehmen
                                und haftet für ggf. entstehende Schäden nicht.
                            </li>
                            <li>
                                Grundsätzlich hat der Mandant einen datenschutzrechtlichen Anspruch auf
                                verschlüsselte
                                E-Mail-Korrespondenz. Nach Maßgabe der Einverständniserklärung in Satz 1 dieses Absatzes
                                verzichtet der
                                Mandant ausdrücklich auf diesen Anspruch.

                            </li>
                        </ol>
                    </li>
                </ol>
                <h1 class="terms-and-conditions__title terms-and-conditions__title--main">
                    Teil B. Vergütungsvereinbarung
                </h1>
                <ol>
                    <li>
                        Die Vergütung (Gebühren und Auslagenersatz) der Rechtsanwaltsgesellschaft für ihre Berufstätigkeit bemisst sich nach der Vergütungsverordnung für Steuerberater, Steuerbevollmächtigte und Steuerberatungsgesellschaften (StBVV) bzw. nach dem Rechtsanwaltsvergütungsgesetz (RVG).
                    </li>
                    <li>
                        Eine Aufrechnung gegenüber einem Vergütungsanspruch der Rechtsanwaltsgesellschaft ist nur mit unbestrittenen
                        oder
                        rechtskräftig festgestellten Forderungen zulässig.
                    </li>
                    <li>
                        Für bereits entstandene oder voraussichtlich entstehende Vergütungsansprüche und Auslagen
                        kann die
                        Rechtsanwaltsgesellschaft einen Vorschuss fordern. Die Rechtsanwaltsgesellschaft kann die Aufnahme der Tätigkeit von der Zahlung
                        des
                        Vorschusses abhängig machen.
                    </li>
                    <li>
                        Soweit Honorarrechnungen für Vergütungsansprüche nach Maßgabe der vorliegenden Vereinbarung
                        von der
                        Rechtsanwaltsgesellschaft an den Mandanten gestellt werden, erklärt der Mandant sein ausdrückliches
                        Einverständnis, dass
                        diese Rechnungen auch in einfach elektronischer, verkehrsüblicher Form, insbesondere in Form
                        einer
                        pdf-Datei, an den Mandanten übermittelt werden dürfen und dass diese Rechnungen nicht
                        unterzeichnet sein
                        müssen.
                    </li>
                    <li>
                        Befreiung von der Verschwiegenheitspflicht zur Durchsetzung von Gebührenforderungen nach § 64 Abs. 2 StBerG
                            <ol>
                                <li>
                                    Der Auftraggeber erteilt ausdrücklich die Einwilligung dazu, dass der Steuerberater eine gegen den Auftraggeber bestehende Gebührenforderungen an einen nicht als Steuerberater oder als Steuerbevollmächtigten zugelassenen Dritten abtreten oder ihre Einziehung übertragen kann.
                                </li>
                                <li>
                                    Ein Widerruf dieser Einwilligung ist nur aus wichtigem Grund zulässig. Der Widerruf ist in Textform zu erklären.
                                </li>
                                <li>
                                    Davon unberührt bleibt das Recht des Steuerberaters nach § 64 Abs. 2 Satz 1 StBerG, die Gebührenforderungen an einen anderen Steuerberater oder Steuerbevollmächtigten abzutreten oder zur Einziehung zu übertragen.
                                </li>
                            </ol>
                    </li>
                </ol>
                <h1 class="terms-and-conditions__title terms-and-conditions__title--main">
                    Teil C. Sonstiges
                </h1>
                <ol>
                    <li>
                        Die Steuerberatungsrahmenvereinbarung (Teil A.), die Vergütungsvereinbarung (Teil B.) sowie
                        sämtliche Aufträge, die nach Maßgabe des § 1 der Rechtsanwaltsgesellschaft erteilt werden, unterliegen
                        ausschließlich deutschem Recht.
                    </li>
<!--                    <li>
                        Sofern der Mandant Kaufmann, juristische Person des öffentlichen Rechts oder
                        öffentlich-rechtliches
                        Sondervermögen ist oder keinen allgemeinen Gerichtsstand im Inland hat, wird für sämtliche
                        Streitigkeiten aus oder in Zusammenhang mit dem Auftragsverhältnis …… als ausschließlicher
                        Gerichtsstand
                        vereinbart.
                    </li>-->
                    <li>
                        Ansprüche aus der Steuerberaterrahmen-Vereinbarung (Teil A.), der Vergütungsvereinbarung
                        (Teil B.)
                        sowie aus Aufträgen, die nach Maßgabe des § 1 der Rechtsanwaltsgesellschaft erteilt worden sind, können vom
                        Mandanten
                        nicht an Dritte abgetreten werden.
                    </li>
                    <li>
                        Falls einzelne Bestimmungen in Teil A. und B. dieser Vereinbarungen unwirksam sein oder
                        werden
                        sollten, wird die Wirksamkeit der übrigen Bestimmungen dadurch nicht berührt. Die unwirksame
                        Bestimmung
                        ist durch eine gültige zu ersetzen, die dem angestrebten Ziel möglichst nahekommt.
                    </li>
                    <li>
                        Änderungen und Ergänzungen bedürfen der Schriftform. Dies gilt auch für die Aufhebung des
                        Schriftformerfordernisses.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>
