<div class="home-service-info">
  <div class="main-text row">
    <span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.TEXT_1' | translate"></span>
    <span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.TEXT_2' | translate"></span>
  </div>
  <div class="list-box row">
    <ol>
      <li class="ol-element">
        <span
          class="mb-15"
          [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_1.TITLE' | translate"
        ></span>
        <ul>
          <li
            ><span
              [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_1.LIST_ELEMENT_1' | translate"
            ></span
          ></li>
          <li
            ><span
              [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_1.LIST_ELEMENT_2' | translate"
            ></span
          ></li>
        </ul>
      </li>
      <li class="ol-element">
        <span
          class="mb-15"
          [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_2.TITLE' | translate"
        ></span>
        <ul>
          <li
            ><span
              [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_2.LIST_ELEMENT_1' | translate"
            ></span
          ></li>
          <li
            ><span
              [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_2.LIST_ELEMENT_2' | translate"
            ></span
          ></li>
        </ul>
        <p
          ><span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_2.BOTTOM_TEXT' | translate"></span
        ></p>
      </li>
      <li class="ol-element">
        <span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_3.TITLE' | translate"></span>
      </li>
      <li>
        <span
          class="mb-15"
          [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_4.TITLE' | translate"
        ></span>
        <ul class="mb-15">
          <li
            ><span
              [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_4.LIST_ELEMENT_1' | translate"
            ></span
          ></li>
          <li
            ><span
              [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_4.LIST_ELEMENT_2' | translate"
            ></span
          ></li>
        </ul>
        <p
          ><span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_4.BOTTOM_TEXT' | translate"></span
        ></p>
      </li>
    </ol>
  </div>
  <div class="list-box row">
    <p
      ><span class="mb-15" [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.TEXT_3' | translate"></span
    ></p>
    <ul>
      <li
        ><span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_5.LIST_ELEMENT_1' | translate"></span
      ></li>
      <li
        ><span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.LIST_5.LIST_ELEMENT_2' | translate"></span
      ></li>
    </ul>
  </div>
  <div class="bottom-text">
    <span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.BOTTOM_TEXT_1' | translate"></span><br />
    <span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.COSTS.TOOLTIP.BOTTOM_TEXT_2' | translate"></span>
  </div>
</div>
