import {Action} from '@ngrx/store';
import { SelectsDataInterface } from 'app/statements';

export const LOAD_SELECTS_DATA = '[SelectsData] Data Load';
export const REMOVE_SELECTS_DATA = '[SelectsData] Data Remove';

export class LoadSelectsDataAction implements Action {
  readonly type = LOAD_SELECTS_DATA;

  constructor(public payload: SelectsDataInterface) { }
}

export class RemoveSelectsDataAction implements Action {
  readonly type = REMOVE_SELECTS_DATA;

  constructor(public payload: any) { }
}

export type Actions = LoadSelectsDataAction | RemoveSelectsDataAction;
