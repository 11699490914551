
import {finalize, takeUntil} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {StatementService} from '../../statements/statement.service';
import {SubscribingComponent} from '../subscribing-component';

@Component({
  selector: 'app-remove-batch-dialog',
  templateUrl: './remove-batch-dialog.component.html',
  styleUrls: ['./remove-batch-dialog.component.scss']
})
export class RemoveBatchDialogComponent extends SubscribingComponent implements OnInit {
  @Input() batchId: number;
  @Input() onSuccessCallback: any;
  @Input() onFailureCallback: any;
  batchRemoving: boolean;

  constructor(private statementService: StatementService,
              private dialogRef: MatDialogRef<RemoveBatchDialogComponent>) {
    super();
  }

  ngOnInit() {
  }

  removeBatch() {
    this.batchRemoving = true;
    this.statementService.deleteBatch(this.batchId).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.batchRemoving = false),)
      .subscribe(() => {
        if (this.onSuccessCallback) {
          this.onSuccessCallback();
          this.dialogRef.close();
        }
      }, () => {
        this.onFailureCallback();
      });
  }
}
