import { DateRange, DateRangeErrors, DateRangeResponse } from '../common/dateRange';
import { DateHelper } from 'app/core/helpers/date.helper';

export interface EmployerResponse {
  id?: number;
  gross_working_wages: string;
  income_tax: string;
  solidarity_surcharge: string;
  pension_insurance_employer: string;
  pension_insurance_employee: string;
  health_insurance_employer_subsidies: string;
  private_health_insurance_employer_subsidies: string;
  care_insurance_employer_subsidies: string;
  health_insurance_employee_contributions: string;
  longterm_care_insurance: string;
  unemployment_insurance: string;
  proven_contributions_to_private_health_insurance: string;
  church_tax: string;
  retained_church_tax: string;
  business_trips_costs: string;
  trips_to_work_costs: string;
  wages_not_covered_by_reduced_tax: string;
  taxable_grants_for_external_activities: string;
  tax_free_employer_benefits: string;
  shortterm_allowance: string;
  tax_class?: number;
  period_of_employment?: DateRangeResponse;
}

export interface EmployerErrors {
  id?: string[];
  gross_working_wages?: string[];
  income_tax?: string[];
  solidarity_surcharge?: string[];
  pension_insurance_employer?: string[];
  pension_insurance_employee?: string[];
  health_insurance_employer_subsidies?: string[];
  private_health_insurance_employer_subsidies?: string[];
  care_insurance_employer_subsidies?: string[];
  health_insurance_employee_contributions?: string[];
  longterm_care_insurance?: string[];
  unemployment_insurance?: string[];
  proven_contributions_to_private_health_insurance?: string[];
  church_tax?: string[];
  retained_church_tax?: string[];
  business_trips_costs?: string[];
  trips_to_work_costs?: string[];
  wages_not_covered_by_reduced_tax?: string[];
  taxable_grants_for_external_activities?: string[];
  tax_free_employer_benefits?: string[];
  shortterm_allowance?: string[];
  tax_class?: string[];
  period_of_employment?: DateRangeErrors[];
}

export class Employer {
  id: number = null;
  displayAmount = '';
  usedOCR = false;

  set ib03(ib: string) {
    this._ib03 = ib;
    let parsed = typeof ib === 'string' ? Number(ib.replace(',', '.')) : 0;
    parsed = isNaN(parsed) || parsed > 1e20 || parsed < 0 ? 0 : parsed;
    this.displayAmount = parsed.toFixed(2) + ' €';
  }

  get ib03(): string {
    return this._ib03;
  }

  ib04 = '';
  ib05 = '';
  ib06 = '';
  ib07 = '';
  ib15 = '';
  ib17 = '';
  ib18 = '';
  ib19 = '';
  ib20 = '';
  ib21 = '';
  ib22a = '';
  ib23a = '';
  ib24a = '';
  ib24b = '';
  ib24c = '';
  ib25 = '';
  ib26 = '';
  ib27 = '';
  ib28 = '';
  taxClass?: number;
  periodOfEmployment: DateRange = new DateRange();
  workDateStart = '';
  workDateEnd = '';

  protected _ib03 = '';

  static fromResponse(response: EmployerResponse): Employer {
    const employer = new Employer();
    employer.id = response.id;
    employer.ib03 = response.gross_working_wages;
    employer.ib04 = response.income_tax;
    employer.ib05 = response.solidarity_surcharge;
    employer.ib06 = response.church_tax;
    employer.ib07 = response.retained_church_tax;
    employer.ib15 = response.shortterm_allowance;
    employer.ib17 = response.business_trips_costs;
    employer.ib18 = response.trips_to_work_costs;
    employer.ib19 = response.wages_not_covered_by_reduced_tax;
    employer.ib20 = response.taxable_grants_for_external_activities;
    employer.ib21 = response.tax_free_employer_benefits;
    employer.ib22a = response.pension_insurance_employer;
    employer.ib23a = response.pension_insurance_employee;
    employer.ib24a = response.health_insurance_employer_subsidies;
    employer.ib24b = response.private_health_insurance_employer_subsidies;
    employer.ib24c = response.care_insurance_employer_subsidies;
    employer.ib25 = response.health_insurance_employee_contributions;
    employer.ib26 = response.longterm_care_insurance;
    employer.ib27 = response.unemployment_insurance;
    employer.ib28 = response.proven_contributions_to_private_health_insurance;
    employer.taxClass = response.tax_class;
    employer.periodOfEmployment = DateRange.fromResponse(response.period_of_employment);

    if (response.period_of_employment) {
      if (response.period_of_employment.lower) {
        employer.workDateStart = DateHelper.getFormatted(employer.periodOfEmployment.lower, 'DD/MM');
      }
      if (response.period_of_employment.upper) {
        employer.workDateEnd = DateHelper.getFormatted(employer.periodOfEmployment.upper, 'DD/MM');
      }
    } else {
      employer.workDateStart = null;
      employer.workDateEnd = null;
    }


    return employer;
  }

  static fromStorage(response: Employer): Employer {
    const employer = new Employer();
    employer.id = response.id;
    employer.ib03 = response._ib03;
    employer.ib04 = response.ib04;
    employer.ib05 = response.ib05;
    employer.ib06 = response.ib06;
    employer.ib07 = response.ib07;
    employer.ib15 = response.ib15;
    employer.ib17 = response.ib17;
    employer.ib18 = response.ib18;
    employer.ib19 = response.ib19;
    employer.ib20 = response.ib20;
    employer.ib21 = response.ib21;
    employer.ib22a = response.ib22a;
    employer.ib23a = response.ib23a;
    employer.ib24a = response.ib24a;
    employer.ib24b = response.ib24b;
    employer.ib24c = response.ib24c;
    employer.ib25 = response.ib25;
    employer.ib26 = response.ib26;
    employer.ib27 = response.ib27;
    employer.ib28 = response.ib28;
    employer.taxClass = response.taxClass;
    employer.periodOfEmployment = DateRange.fromResponse(response.periodOfEmployment);
    employer.workDateStart = response.workDateStart;
    employer.workDateEnd = response.workDateEnd;
    return employer;
  }

  toResponse(defaultValue = undefined): EmployerResponse {
    const employer = {
      gross_working_wages: parseFloat(this.ib03).toFixed(2),
      income_tax: parseFloat(this.ib04).toFixed(2),
      solidarity_surcharge: parseFloat(this.ib05).toFixed(2),
      pension_insurance_employer: parseFloat(this.ib22a).toFixed(2),
      pension_insurance_employee: parseFloat(this.ib23a).toFixed(2),
      health_insurance_employer_subsidies: parseFloat(this.ib24a).toFixed(2),
      private_health_insurance_employer_subsidies: parseFloat(this.ib24b).toFixed(2),
      care_insurance_employer_subsidies: parseFloat(this.ib24c).toFixed(2),
      health_insurance_employee_contributions: parseFloat(this.ib25).toFixed(2),
      longterm_care_insurance: parseFloat(this.ib26).toFixed(2),
      unemployment_insurance: parseFloat(this.ib27).toFixed(2),
      proven_contributions_to_private_health_insurance: parseFloat(this.ib28).toFixed(2),
      church_tax: parseFloat(this.ib06).toFixed(2),
      retained_church_tax: parseFloat(this.ib07).toFixed(2),
      business_trips_costs: parseFloat(this.ib17).toFixed(2),
      trips_to_work_costs: parseFloat(this.ib18).toFixed(2),
      wages_not_covered_by_reduced_tax: parseFloat(this.ib19).toFixed(2),
      taxable_grants_for_external_activities: parseFloat(this.ib20).toFixed(2),
      tax_free_employer_benefits: parseFloat(this.ib21).toFixed(2),
      shortterm_allowance: parseFloat(this.ib15).toFixed(2),
      tax_class: this.taxClass ? this.taxClass : defaultValue,
      period_of_employment: this.periodOfEmployment.toResponse(),
    };

    if (this.id) {
      employer['id'] = this.id;
    }

    return employer;
  }

  isEmpty(): boolean {
    const keys = Object.keys(this);
    const employerInputFields = [
      'ib03', 'ib04', 'ib05', 'ib06', 'ib07', 'ib15', 'ib17', 'ib18', 'ib19', 'ib20',
      'ib21', 'ib22a', 'ib23a', 'ib24a', 'ib24b', 'ib24c', 'ib25', 'ib26', 'ib27', 'ib28'
    ];

    for (const key of keys) {
      if (['id', 'periodOfEmployment', 'taxClass', 'displayAmount'].indexOf(key) < 0) {
        for (const inputKey of employerInputFields) {
          if (this[inputKey] !== '0.00') {
            return false;
          }
        }
      }
    }

    return !(this.periodOfEmployment.lower !== null ||
      this.periodOfEmployment.upper !== null ||
      this.taxClass !== null);
  }

  clear(): void {
    this.periodOfEmployment = new DateRange();
    this.periodOfEmployment.lower = null;
    this.periodOfEmployment.upper = null;
    this.taxClass = null;
    this.formatEmployerFields(this);
  }

  public formatPeriodOfEmployment(fullYear: string | number): void {
    let dateStart, dateEnd;
    if (this.workDateStart) {
      this.workDateStart = this.workDateStart.replace('.', '/')
        .replace('-', '/');

      dateStart = DateHelper.getInstance(this.workDateStart + '/' + fullYear, 'DD/MM/YYYY', true);
    }
    if (dateStart && dateStart.isValid()) {
      this.workDateStart = dateStart.format('DD/MM');
      this.periodOfEmployment.lower = dateStart.format('YYYY-MM-DD');
    } else {
      this.periodOfEmployment.lower = null;
    }

    if (this.workDateEnd) {
      this.workDateEnd = this.workDateEnd.replace('.', '/')
        .replace('-', '/');

      dateEnd = DateHelper.getInstance(this.workDateEnd + '/' + fullYear, 'DD/MM/YYYY', true);
    }
    if (dateEnd && dateEnd.isValid()) {
      this.workDateEnd = dateEnd.format('DD/MM');
      this.periodOfEmployment.upper = dateEnd.format('YYYY-MM-DD');
    } else {
      this.periodOfEmployment.upper = null;
    }
  }

  private formatEmployerFields(employer): void {
    const fieldsToCalc = Object.keys(this).filter(key => key.includes('ib'));
    fieldsToCalc.forEach(field => {
      employer[field] = '0.00';
    });
    this.ib03 = '0.00';
  }
}
