import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { SubscribingComponent } from 'app/common';
import { ValidateFieldV2Service } from 'app/core/form-validator/validate-field-v2.service';
import { VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME } from 'app/core/form-validator/validator-field-names/validator-field-names-additional-income';
import { ErrorMessage } from 'app/core/form-validator/validatorInterface';
import { INPUT_TYPE } from 'app/modules/statement/components/additional-income-form/additional-income-form-freelance/forms-elements/income-input/income-input.component';

@Component({
  selector: 'app-income-edit',
  templateUrl: './income-edit.component.html',
  styleUrls: ['./income-edit.component.scss'],
})
export class IncomeEdit extends SubscribingComponent implements OnInit{
  @Input() list : Array<any> = [];
  @Input() incomeInputValue: string;
  @Input() value: any;
  @Input() inputLabel: string;
  @Input() btnTextFirst = 'COMMON.NO';
  @Input() btnTextSecond = 'COMMON.YES';
  @Input() editIndex: number;
  @Input() inputPlaceholder: string = '';
  @Input() vatInputPlaceholder: string = '';
  @Input() vatInputLabel: string = '';
  @Input() alertInfo : string = ""
  @Input() incomeType : INPUT_TYPE = INPUT_TYPE.INCOMES

  @Output() hideEdit: EventEmitter<any> = new EventEmitter<any>();

  public localInputValue : string = '0.00'
  public localVatValue : string = '0.00'

  public fieldsNames = VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME
  public epValidator: ErrorMessage[];
  public hasVat: boolean = false
  
  constructor(
    private validateFieldV2Service: ValidateFieldV2Service,
  ) {
    super()
  }

  ngOnInit() {
    this.localInputValue = this.value?.value || '';
    if(this.value?.vat_value && this.incomeType == INPUT_TYPE.INCOMES){
      this.hasVat = true;
      this.localVatValue = this.value?.vat_value
    }else{
      this.localVatValue = '';
    }
  }

  public validateValues(){
    this.epValidator = [];

    const validationFieldKeys = [
      VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.income_value
    ];

    const dataForValidation = [{income_value : this.localInputValue}];
    
    this.epValidator = [
      ...this.validateFieldV2Service.validateFieldAndGetMessageV2(
        dataForValidation,
        validationFieldKeys
      )
    ];

    return this.epValidator.length == 0
  }

  public handleAddAction(editValue : boolean){
    if(editValue){
      if(this.validateValues()){
        this.list[this.editIndex].value = this.localInputValue;
        this.list[this.editIndex].vat_value = this.localVatValue;
        this.hideEdit.emit(this.editIndex);
      }      
    }else{
      this.localInputValue = this.value?.value || '';
      this.localVatValue = this.value?.vat_value || '';
      this.hideEdit.emit(this.editIndex);
    }
  }

  public activeAddButton(){
    if(this.hasVat){
      return !!this.localInputValue && !!this.localVatValue;
    }

    return !!this.localInputValue;
  }
}
