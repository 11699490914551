import { Component, Input } from "@angular/core";
import { NumberFormatHelper } from "app/core/helpers/number-format.helper";

@Component({
  selector: "app-tooltip-template-business-real-estate",
  templateUrl: "./tooltip-template-business-real-estate.component.html",
  styleUrls: ["./tooltip-template-business-real-estate.component.scss"]
})
export class BusinessRealEstateTooltipTemplate {
  constructor() {}
}
