import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, getUserType } from '../../reducers';
import { SubscribingComponent } from 'app/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-abort',
  templateUrl: './payment-abort.component.html',
  styleUrls: ['./payment-abort.component.scss']
})
export class PaymentAbortComponent extends SubscribingComponent implements OnInit {
  public statementId: string;
  public isBusiness: boolean = false;

  constructor(private store: Store<State>,
              private router: Router,
              private route: ActivatedRoute ) {
                super();
  }

  ngOnInit() {

    let id = this.route.snapshot.params.statementId;
    this.statementId = id;

    this.store.select(getUserType).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((userType: string) => {
        if (userType) {
          this.isBusiness = userType === 'business';
        }
      });
  }

  public goBack() {
    this.router.navigate([`new/statement/${this.statementId}/payment-checkout`]);
    return false;
  }
}
