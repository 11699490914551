<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content">
        <div class="impressum" *ngIf="language === LANGUAGES.de">
            <h1 class="impressum__title impressum__title--main is-bold">Impressum</h1>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">taxando GmbH<br></span>
                    Kurfürstendamm 226<br>
                    10719 Berlin
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    vertreten durch ihre Geschäftsführer:<br>
                    Maciej Tadeusz Szewczyk<br>
                    Maciej Wawrzyniak
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    Telefon: +49 30 75436995 (kein telefonischer Support unter dieser Nummer)<br>
                    E-Mail: office@taxando.de<br>
                    E-Mail für technischen Support: support@taxando.de
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Verantwortlich für redaktionelle Inhalte gemäß RStV<br></span>
                    Maciej Tadeusz Szewczyk
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz<br></span>
                    DE313306417
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Eintrag im Handelsregister<br></span>
                    Amtsgericht Charlottenburg (Berlin), HRB 189179 B
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Verbraucher-Streitschlichtung<br></span>
                    Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten betreffend
                    vertraglicher Verpflichtungen aus Online-Verträgen geschaffen (OS-Plattform). Sie können die
                    OS-Plattform unter dem folgenden Link erreichen: http://ec.europa.eu/consumers/odr/. Wir sind nicht
                    bereit und nicht verpflichtet an einem Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle teilzunehmen.
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Haftung für Links<br></span>
                    Externe Links unserer Internetseite/App führen zu Inhalten fremder Anbieter. Für diese Inhalte ist
                    allein der jeweilige Anbieter verantwortlich. Bei bekannt werden von Rechtsverletzungen werden diese
                    Links umgehend entfernt.
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Urheberrechtshinweis<br></span>
                    Die durch uns erstellten Inhalte und Werke auf dieser Website/App unterliegen dem deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb
                    der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                    Erstellers. Soweit die Inhalte auf dieser Seite/App nicht von uns selbst erstellt wurden, werden
                    Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                    Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, wird um einen
                    entsprechenden Hinweis gebeten. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                    Inhalte umgehend entfernen.
                </p>
            </div>
        </div>

        <div class="impressum" *ngIf="language === LANGUAGES.en">
            <h1 class="impressum__title impressum__title--main is-bold">Imprint</h1>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">taxando GmbH<br></span>
                    Kurfürstendamm 226<br>
                    10719 Berlin
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    Represented by their managing directors:<br>
                    Maciej Tadeusz Szewczyk<br>
                    Maciej Wawrzyniak
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    Phone: +49 30 75436995 (no telephone support through this number)<br>
                    E-mail: office@taxando.de<br>
                    E-mail for technical support: support@taxando.de
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Responsible for editorial content according to RStV<br></span>
                    Maciej Tadeusz Szewczyk
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Sales tax identification number according to § 27 a sales tax law<br></span>
                    DE313306417
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Entry in the commercial register<br></span>
                    Amtsgericht Charlottenburg (Berlin), HRB 189179 B
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Consumer Dispute Resolution<br></span>
                    The EU Commission has created an Internet platform for the online settlement of disputes concerning
                    contractual obligations arising from online contracts (OS platform). You can access the OS platform
                    at the following link: http://ec.europa.eu/consumers/odr/. We are neither willing nor obliged to
                    participate in dispute resolution proceedings before a consumer arbitration board.
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Liability for links<br></span>
                    External links on our website/app lead to contents of other providers. The respective provider is
                    solely responsible for these contents. These links will be removed immediately if infringements are
                    discovered.
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Copyright notice<br></span>
                    The contents and works created by us on this website/app are subject to German copyright law. The
                    reproduction, editing, distribution and any kind of use outside the limits of copyright law require
                    the written consent of the respective author or creator. Insofar as the content on this website/app
                    was not created by us, the copyrights of third parties are respected. Contents of third parties are
                    particularly indicated as such. Should you nevertheless become aware of a copyright infringement,
                    please inform us accordingly. We will remove such contents immediately as soon as we become aware of
                    any infringements.
                </p>
            </div>
        </div>

        <div class="impressum" *ngIf="language === LANGUAGES.pl">
            <h1 class="impressum__title impressum__title--main is-bold">Impressum</h1>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">taxando GmbH<br></span>
                    Kurfürstendamm 226<br>
                    10719 Berlin
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    Spółka reprezentowana przez:<br>
                    Macieja Tadeusza Szewczyka<br>
                    Macieja Wawrzyniaka
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    Telefon: +49 30 75436995 (nie świadczymy telefonicznej pomocy technicznej)<br>
                    E-mail: office@taxando.de<br>
                    Pomoc techniczna: support@taxando.de
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Odpowiedzialność za treść redakcyjną zgodnie z umową państwową w sprawie
                        stacji telewizyjnych (RStV)<br></span>
                    Maciej Tadeusz Szewczyk
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Numer VAT zgodnie z § 27 a ustawy o podatku obrotowym<br></span>
                    DE313306417
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Wpis do rejestru handlowego<br></span>
                    Amtsgericht Charlottenburg (Berlin), HRB 189179 B
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Procedura rozwiązywania sporów konsumenckich<br></span>
                    Komisja Europejska stworzyła platformę internetową do rozstrzygania online sporów dotyczących
                    zobowiązań wynikających z umów zawieranych przez Internet (platforma ODR: On-line Dispute
                    Resolution). Platforma ODR jest dostępna pod poniższym linkiem: http://ec.europa.eu/consumers/odr/.
                    Nie jesteśmy przygotowani ani zobowiązani do uczestniczenia w procesie rozstrzygania sporów przed
                    konsumenckim biurem mediacyjnym.
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Odpowiedzialność za linki odsyłające<br></span>
                    Zewnętrzne linki na nasze stronie internetowej/w aplikacji prowadzą do treści zewnętrznych
                    dostawców. Za treści te odpowiada wyłącznie ich dostawca. W przypadku ujawnionych naruszeń prawnych
                    związanych z tymi treściami linki odsyłające do tych treści zostaną usunięte z naszej
                    strony/aplikacji.
                </p>
            </div>

            <div class="impressum__stage">
                <p class="impressum__content">
                    <span class="is-bold">Prawa autorskie<br></span>
                    Treści i dzieła stworzone przez nas i znajdujące się na naszej stronie internetowej/w naszej
                    aplikacji podlegają niemieckiemu prawu autorskiemu. Powielanie, zmienianie, rozpowszechnianie i
                    każde inne wykorzystanie tych treści wykraczające poza granice prawa autorskiego wymaga pisemnej
                    zgody autora. Jeżeli treści znajdujące się na naszej stronie internetowej/w aplikacji nie zostały
                    stworzone przez nas, uwzględnione zostały prawa autorskie podmiotów trzecich. Treści należące do
                    podmiotów trzecich zostały jako takie oznaczone. Jeżeli zauważą Państwo naruszenie przez nas praw
                    autorskich, prosimy o informację na ten temat. W przypadku uzasadnionego naruszenia praw autorskich
                    odpowiednie treści na naszej stronie/w aplikacji zostaną niezwłocznie usunięte.
                </p>
            </div>
        </div>
    </div>
</div>
