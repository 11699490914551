import { WorkflowAdditionalIncomeSpouseSubBaseService } from 'app/core/workflow/workflow-steps/spouse/sub/workflow-additional-income-spouse-sub-base.service';

export class WorkflowAdditionalIncomeSpouseFourService extends WorkflowAdditionalIncomeSpouseSubBaseService {
  public get title(): string {
    return 'ASIDE_BAR.CRYPTOCURRENCY';
  }

  public get subStepNumber(): number {
    return 4;
  }
}
