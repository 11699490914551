import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import {
  GroupSectionBaseAbstractComponent,
  SelectButtonsData
} from 'app/shared/modules/group-section/components/group-section-base.abstract.component';
import { AdditionalIncomeService } from 'app/modules/statement/services/additional-income.service';
import { StepsControllerService, SubStepSaveDataInterface } from 'app/core/workflow/steps-controller/steps-controller.service';
import { CryptocurrencySubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/cryptocurrency.sub-steps';
import { CryptocurrencyIncomeManager } from 'app/modules/statement/models/additional-income/cryptocurrency.manager';
import { AutofocusHelper } from 'app/core/helpers/autofocus.helper';
import { CryptoCurrencyPartnersHttpService } from './services/cryptocurrency-partners.http.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  ...GroupSectionBaseAbstractComponent.metaData,
  selector: 'app-additional-income-group-cryptocurrency',
  templateUrl: './additional-income-group-cryptocurrency.component.html',
  providers: [CryptoCurrencyPartnersHttpService]
})
export class AdditionalIncomeGroupCryptocurrencyComponent extends GroupSectionBaseAbstractComponent implements OnInit {
  public groupTitle: string;
  public cryptocurrencySteps = CryptocurrencySubSteps;
  public cryptocurrencyIncomeManager = CryptocurrencyIncomeManager;
  public cryptoCurrencyPartners: Array<Object> = [];

  constructor(
    public additionalIncomeService: AdditionalIncomeService,
    public dialog: MatDialog,
    public store: Store<State>,
    public stepsControllerService: StepsControllerService,
    public cryptoCurrencyPartnersHttpService: CryptoCurrencyPartnersHttpService,
    public sanitizer: DomSanitizer
  ) {
    super(dialog, store, stepsControllerService);
  }

  ngOnInit() {
    this.groupTitle = this.title;
    this.init();

    this.stepsControllerService.reInitEmitter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.init();
    });

    this.stepsControllerService.subStepSaveEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data: SubStepSaveDataInterface) => this.saveGroup(data),
      });

    this.cryptoCurrencyPartnersHttpService.getPartners()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: (response) => {
        if (response) {
          for (let index in response) {
            this.cryptoCurrencyPartnersHttpService.getPartnerLogo(response[index].logo)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe(blob => {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                  let partnerObj = {
                    logo: event.target.result,
                    link: response[index].link
                  };
          
                  this.cryptoCurrencyPartners.push(partnerObj);
                };
                reader.readAsDataURL(blob);
              });
          }
          
          
        }
      },
      error: (error) => {
        console.error("Error occurred:", error);
      }
    });

    super.ngOnInit();
  }

  public isSelected = (selectButtonName: string): boolean => {
    switch (selectButtonName) {
      case CryptocurrencySubSteps.CRYPTOCURRENCY:
        return !!CryptocurrencyIncomeManager.getCryptoCurrencyPension(this.additionalIncomeService.currentStatement).length;
    }

    return false;
  }

  public selectButtonClickAction = (selectButtonName: string): void => {
    switch (selectButtonName) {
      case CryptocurrencySubSteps.CRYPTOCURRENCY:
        if (
          !!CryptocurrencyIncomeManager.getCryptoCurrencyPension(this.additionalIncomeService.currentStatement).length
        ) {
          this.showAreYouSurePopup((dialog) => {
            CryptocurrencyIncomeManager.removeAllCryptoCurrencyPension(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          CryptocurrencyIncomeManager.createCryptoCurrencyPension(this.additionalIncomeService.currentStatement);
        }
        break;
    }

    this.selectedButtonsChanged();
  }

  private setSelectButtonsData = (): void => {
    const selectButtonsData: SelectButtonsData[] = [
      {
        id: CryptocurrencySubSteps.CRYPTOCURRENCY,
        image: 'assets/img/additional_income/cryptocurrency.png',
        title: 'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.BTN_TITLE',
      },
    ];

    this.setSelectButtons(selectButtonsData);
  }

  private setSteps(): void {
    this.subSteps = [
      {
        id: CryptocurrencySubSteps.MAIN,
        isActive: true,
      },
      {
        id: CryptocurrencySubSteps.CRYPTOCURRENCY,
        isActive: false,
      },
    ];
  }

  private updateStatement(callback = () => {}): void {
    this.additionalIncomeService.updateAdditionalIncome(
      this.getActiveSubStep().id as CryptocurrencySubSteps,
      callback
    );
  }

  private saveGroup({index, callback}: SubStepSaveDataInterface): void {
    const currentStep = this.subSteps[index];

    if (currentStep && currentStep.id !== CryptocurrencySubSteps.MAIN) {
      this.additionalIncomeService.formValidationErrors(currentStep.id as CryptocurrencySubSteps);
      if (this.additionalIncomeService.epValidator.length) {
        callback(false);
        return;
      }
    }

    callback(true);
    AutofocusHelper.autofocusMatSelectMainContent();
  }

  private getCryptoPartners(){

  }

  private init(): void {
    this.setSteps();
    this.setSelectButtonsData();

    AutofocusHelper.autofocusMatSelectMainContent();
  }
}
