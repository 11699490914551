import { Injectable } from '@angular/core';
import { FirebaseConfig } from 'app/shared/modules/firebase/configs/firebase.config';
import { timer } from 'rxjs';
import {
  Analytics,
  getAnalytics,
  logEvent,
  EventNameString,
  AnalyticsCallOptions,
  setConsent,
} from '@firebase/analytics';
import { FirebaseService } from 'app/shared/modules/firebase/services/firebase.service';
import { DeviceRecognizerHelper } from 'app/core/helpers/device-recognizer.helper';
import { CookiesLawService } from 'app/core/services/cookies-law.service';

@Injectable()
export class FirebaseAnalyticsService {
  constructor(
    private readonly firebaseService: FirebaseService,
    private readonly cookiesLawService: CookiesLawService,
  ) {
  }

  public logSignUpEvent(): void {
    this.logEvent('sign_up');
  }

  public logStandardPurchase(amount: number, currency: string): void {
    const params = {};
    params['content_type'] = 'standard';
    params['value'] = amount;
    params['currency'] = currency;
    this.logEvent('purchase', params);
  }

  public logPremiumPurchase(amount: number, currency: string): void {
    const params = {};
    params['content_type'] = 'premium';
    params['value'] = amount;
    params['currency'] = currency;
    this.logEvent('purchase', params);
  }

  public logEvent(
    eventName: EventNameString,
    eventParams?: object,
    callOptions?: AnalyticsCallOptions,
    attempt = 0
  ): void {
    if (!this.analytics) {
      if (attempt < FirebaseConfig.maxLogAttempts) {
        timer(FirebaseConfig.logAttemptTimeout).subscribe({
          next: () => this.logEvent(eventName, eventParams, callOptions, attempt++),
        });
      }

      return;
    }

    if (!eventParams) {
      eventParams = {};
      eventParams['_device'] = this.device;
    }


    logEvent(this.analytics, eventName as string, eventParams, callOptions);
  }

  private get analytics(): Analytics | undefined {
    if (!this.firebaseService.app) {
      return undefined;
    }

    const analytics = getAnalytics(this.firebaseService.app);

    setConsent({
      analytics_storage: this.cookiesLawService.isAnalyticsEnabled ? 'granted' : 'denied',
      // ad_storage: this.cookiesLawService.isTargetingEnabled ? 'granted' : 'denied',
      // personalization_storage: this.cookiesLawService.isTargetingEnabled ? 'granted' : 'denied',
      ad_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
    });

    return analytics;
  }

  private get device(): string {
    if (DeviceRecognizerHelper.isWebView()) {
      return 'Mobile Webview';
    }

    return 'Web';
  }
}
