import { finalize, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State, getUser, getSelectsData } from 'app/reducers';
import { Statement } from '../statement';
import { StatementService } from '../statement.service';
import { PackageOptions } from '../package-options';
import { SubscribingComponent } from 'app/common';
import { FirebaseEventsService } from 'app/common/firebase-events.service';
import { FirebaseNotification } from 'app/firebase/notification';
import { FirebaseHttpService } from 'app/firebase/firebase.http.service';
import { SwiperComponent } from 'ngx-useful-swiper';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';
import { ToolsBarButtonInterface } from '../../shared/components/tools-bar/tools-bar.component';
import { NumberFormatHelper } from 'app/core/helpers/number-format.helper';
import { StatementPaymentAmountService } from '../statement-payment-amount.service';
import { combineLatest } from 'rxjs';
import { SelectsDataInterface } from '../selects-data';
import { SHOW_SNACKBAR } from 'app/reducers/actions/snackbar';
import { SnackbarConfig } from 'app/common';
import { StatementPaymentAmount } from 'app/statements/statement-payment-amont';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-package-selection',
  templateUrl: './package-selection.component.html',
  styleUrls: ['./package-selection.component.scss']
})
export class PackageSelectionComponent extends SubscribingComponent implements OnInit {
  @ViewChild('navSwiper', { static: false }) navSwiper: SwiperComponent;
  @ViewChild('contSwiper', { static: false }) contSwiper: SwiperComponent;
  public statement: Statement;
  public statementPaymentAmount: StatementPaymentAmount;
  public packageOption = PackageOptions;
  public isBusiness = false;
  public selectedPackage = '';
  public activeStandardTab = false;
  public activePremiumTab = false;
  public dataSelect: SelectsDataInterface;
  public numberFormatHelper = NumberFormatHelper;
  public buttons: ToolsBarButtonInterface[] = [
    {
      type: 'back',
      label: 'COMMON.BACK',
      action: this.goBack.bind(this)
    },
    {
      type: 'proceed',
      label: 'COMMON.PROCEED',
      action: this.proceed.bind(this),
      disabled: () => {
        return !this.statement || !this.statement.serviceType;
      }
    }
  ];
  public currentSlide = 0;
  public configNav: SwiperOptions = {
    speed: 500,
    spaceBetween: 16,
    slidesPerView: 'auto',
    freeMode: true,
    centeredSlides: false,
    breakpoints: {
      767: {
        slidesPerView: 2,
        freeMode: false,
      },
      1024: {
        slidesPerView: 'auto',
        freeMode: true,
      },
    }
  };
  public configCont: SwiperOptions = {
    slidesPerView: 1,
    speed: 700,
    spaceBetween: 20,
    breakpoints: {
      1024: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    },
    on: {
      slideChangeTransitionStart: (slider) => {
        this.currentSlide = slider.activeIndex || 0;
        this.navSwiper.swiper.slideTo(this.currentSlide, 700);
      }
    },
  };
  private user_id;
  private statementId: number;
  private _isInitialized = false;
  private loadingStatementData = true;
  private loadingStatementPaymentAmount = true;
  private loadingSelectsData = true;
  private loading = false;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private statementPaymentAmountService: StatementPaymentAmountService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private firebaseEventsService: FirebaseEventsService,
    private firebaseService: FirebaseHttpService,
    public workFlowController: WorkflowControllerService
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this.store.select(getUser),
      this.store.select(getSelectsData),
      this.route.params
    ]).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: ([user, dataSelect, params]) => {
          if (user && dataSelect && params) {
            this.user_id = user.id;
            this.isBusiness = user.isBusiness;

            this.dataSelect = dataSelect;
            this.loadingSelectsData = false;

            this.statementId = parseInt(params['statementId'], 10);

            if (this.statementId) {
              this._isInitialized = false;
              this.getStatementData();
              this.getStatementPaymentAmount();
            }

            if (this.statementId && this.user_id) {
              this.updateFirebase();
            }
          }
        }
      });
  }

  public get isInitialized(): boolean {
    return !this.loadingStatementData && !this.loadingStatementPaymentAmount
      && !this.loadingSelectsData && this._isInitialized;
  }

  public get isLoading(): boolean {
    return this.loading || !this.isInitialized;
  }

  public selectPackageClick(type: PackageOptions) {
    if (type === this.packageOption.tax_adviser) {
      this.statement.setPremiumPackage();
    }
    if (type === this.packageOption.paid) {
      this.statement.setStandardPackage();
    }
  }

  public changeSlide(index: number) {
    this.currentSlide = index;
    this.contSwiper.swiper.slideTo(index, 700);
  }

  private defaultActiveTab() {
    this.activeStandardTab = true;

    if (!this.statement.isStandardPackageAvailable) {
      this.activeStandardTab = false;
      this.activePremiumTab = true;
      this.statement.setPremiumPackage();
    }
    if (!this.statement.isPremiumPackageAvailable) {
      this.activeStandardTab = true;
      this.activePremiumTab = false;
      this.statement.setStandardPackage();
    }
  }

  private goBack() {
    this.workFlowController.goToThePreviousStep();
  }

  private proceed() {
    if (!this.statement.isStandardPackageAvailable) {
      this.statement.setPremiumPackage();
    }
    if (!this.statement.isPremiumPackageAvailable) {
      this.statement.setStandardPackage();
    }

    let fbPackageType = 'standard';
    if (this.statement.serviceType === PackageOptions.tax_adviser) {
      fbPackageType = 'steuer++';
    }

    this.firebaseEventsService.logEvent(
      'package_selection_screen',
      { type: fbPackageType },
      this.statement.id
    );
    this.firebaseEventsService.webEvent(
      'package_selection_screen',
      { type: fbPackageType },
      this.statement.id
    );

    this.loading = true;
    this.statementService.updateStatement(this.statement.id, this.statement.packageSelectionStepPayload())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response: Statement) => {
          Object.assign(this.statement, response);
          this.workFlowController.goToTheNextStep();
        },
        error: () => {
          this.loading = false;
          this.store.dispatch({
            type: SHOW_SNACKBAR,
            payload: new SnackbarConfig('COMMON.GLOBAL_ERROR', 'ERROR')
          });
        }});
  }

  private updateFirebase() {
    const notification = new FirebaseNotification();
    notification.user = this.user_id;
    notification.statement = this.statementId;
    notification.notificationName = 'use_premium_steuerberater';
    notification.eventOneTriggered = true;
    this.firebaseService.udpateFirebaseNotification(notification).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        error: error => {
          console.error(error);
        }
      });
  }

  private getStatementPaymentAmount(): void {
    this.loadingStatementPaymentAmount = true;

    this.statementPaymentAmountService.getStatementPaymentAmount(this.statementId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response: StatementPaymentAmount) => {
          this.loadingStatementPaymentAmount = false;
          this.statementPaymentAmount = response;
        },
        error: (error) => {
          console.error(error);
        }
      });
  }

  private getStatementData(): void {
    this.loadingStatementData = true;
    this.statementService.getStatement(this.statementId).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => {
        this.loadingStatementData = false;
      }))
      .subscribe({
        next: (response: Statement) => {
          this.statement = response;
          this.workFlowController.init(this.statement);

          if (this.statement.stagePackageSelection) {
            if (this.statement.isStandardPackage) {
              const sliderCheck = setInterval(() => {
                if (this.navSwiper && this.contSwiper) {
                  this.navSwiper.swiper.slideTo(1, 0);
                  this.contSwiper.swiper.slideTo(1, 0);
                  clearInterval(sliderCheck);
                }
              }, 10);
            }
          }

          if (
            !this.statement.isPremiumPackage
            && (
              !this.statement.stagePackageSelection
              || this.statement.freelanceJobs.length
              || this.statement.hasExtraIncomeOption
              || (this.statement.spouse && this.statement.spouse.freelanceJobs.length)
            )
          ) {
            this.selectPackageClick(this.packageOption.tax_adviser);
          }

          this.defaultActiveTab();

          this._isInitialized = true;
        },
        error: () => {
          this.router.navigate(['/new']);
        }
      });
  }
}
