<div class="processing-placeholder" [attr.data-cy]="'popupProcessingPlaceholder'">
  <div class="processing-placeholder__top">
    <div class="processing-placeholder__top-title processing-placeholder__top-title--mobile">{{ 'PROCESSING_DIALOG.TITLE' | translate }}</div>
    <div class="processing-placeholder__ico">
      <ng-lottie [options]="lottieConfig" ></ng-lottie>
    </div>
    <div class="processing-placeholder__top-title processing-placeholder__top-title--default">{{ 'PROCESSING_DIALOG.TITLE' | translate }}</div>
  </div>
  <div class="processing-placeholder__bottom">
    <div class="processing-placeholder__subtitle">{{ 'PROCESSING_DIALOG.SUBTITLE' | translate }}</div>
    <div class="processing-placeholder__list processing-placeholder__list--default">
      <div>
        <div class="d-flex justify-content-center">
          <img class="processing-placeholder__icon" src="../../../assets/svg/package-selection/tooltip_package_1.svg">
        </div>
        <div class="mt-0_75 mb-1_5 px-0_75">{{'PROCESSING_DIALOG.HOW_IT_WORKS.OPTION_1' | translate}}</div>
      </div>
      <div class="d-flex align-items-center processing-placeholder__arrow">
        <img class="arrow_right" src="../../../assets/svg/arrow_right.svg">
      </div>
      <div>
        <div class="d-flex justify-content-center">
          <img class="processing-placeholder__icon" src="../../../assets/svg/package-selection/tooltip_package_2.svg">
        </div>
        <div class="mt-0_75 mb-1_5 px-0_75">{{'PROCESSING_DIALOG.HOW_IT_WORKS.OPTION_2' | translate}}</div>
      </div>
      <div class="d-flex align-items-center processing-placeholder__arrow">
        <img class="arrow_right" src="../../../assets/svg/arrow_right.svg">
      </div>
      <div>
        <div class="d-flex justify-content-center">
          <img class="processing-placeholder__icon" src="../../../assets/svg/package-selection/tooltip_package_4.svg">
        </div>
        <div class="mt-0_75 mb-1_5 px-0_75">{{'PROCESSING_DIALOG.HOW_IT_WORKS.OPTION_3' | translate}}</div>
      </div>
      <div class="d-flex align-items-center processing-placeholder__arrow">
        <img class="arrow_right" src="../../../assets/svg/arrow_right.svg">
      </div>
      <div>
        <div class="d-flex justify-content-center">
          <img class="processing-placeholder__icon" src="../../../assets/svg/package-selection/tooltip_package_5.svg">
        </div>
        <div class="mt-0_75 mb-1_5 px-0_75">{{'PROCESSING_DIALOG.HOW_IT_WORKS.OPTION_4' | translate}}</div>
      </div>
    </div>
    <div class="processing-placeholder__swiper">
      <swiper [config]="config">
        <div class="swiper-wrapper processing-placeholder__list">
          <div class="swiper-slide">
            <div>
              <div>
                <img class="processing-placeholder__icon" src="../../../assets/svg/package-selection/tooltip_package_1.svg">
              </div>
              <div class="mt-0_75 px-1 font-size-14">{{'PROCESSING_DIALOG.HOW_IT_WORKS.OPTION_1' | translate}}</div>
            </div>
          </div>
          <div class="swiper-slide">
            <div>
              <div>
                <img class="processing-placeholder__icon" src="../../../assets/svg/package-selection/tooltip_package_2.svg">
              </div>
              <div class="mt-0_75 px-1 font-size-14">{{'PROCESSING_DIALOG.HOW_IT_WORKS.OPTION_2' | translate}}</div>
            </div>
          </div>
          <div class="swiper-slide">
            <div>
              <div>
                <img class="processing-placeholder__icon" src="../../../assets/svg/package-selection/tooltip_package_4.svg">
              </div>
              <div class="mt-0_75 px-1 font-size-14">{{'PROCESSING_DIALOG.HOW_IT_WORKS.OPTION_3' | translate}}</div>
            </div>
          </div>
          <div class="swiper-slide">
            <div>
              <div>
                <img class="processing-placeholder__icon" src="../../../assets/svg/package-selection/tooltip_package_5.svg">
              </div>
              <div class="mt-0_75 px-1 font-size-14">{{'PROCESSING_DIALOG.HOW_IT_WORKS.OPTION_4' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="processing-placeholder__switch processing-placeholder__switch--next">
          <img class="arrow_right" src="../../../assets/svg/arrow_right.svg">
        </div>
        <div class="processing-placeholder__switch processing-placeholder__switch--prev">
          <img class="arrow_left" src="../../../assets/svg/arrow_left.svg">
        </div>
      </swiper>
    </div>
  </div>
</div>
