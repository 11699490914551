import { Injectable } from "@angular/core";
import { Country, SelectsData, SelectsDataInterface, Statement } from "app/statements";
import { ErrorMessage } from "app/core/form-validator/validatorInterface";
import {
  CARE_COST_NAMES,
  DeductionBusinessTripsSteps,
  DeductionDonationsAndMembershipsSteps,
  DeductionFlagOptions,
  DeductionHealthSteps,
  DeductionHomeServiceSteps,
  DeductionHouseSteps,
  DeductionInsuranceSteps,
  DeductionOtherCostsSteps,
  DeductionsErrors,
  DeductionWorkAndOfficeSteps,
  DISABILITY_COST_NAMES,
  GERMAN_HOLIDAYS,
  HEALTH_COST_NAMES,
  HOME_OFFICE_COST_NAME_CHOICES,
  HOME_OFFICE_COVID_YEARS,
  MOVING_EXPANSES_COST_NAME_CHOICES,
  PROFESSION_INSURANCE_CHOICES,
  SHOW_ADDITIONAL_LONG_TERM_CARE_INSURANCE_YEARS,
  SHOW_RIESTER_AGREEMENT_NUMBERS_FIELD_YEARS,
  SHOW_RIESTER_SOCIAL_SECURITY_NUMBERS_FIELD_YEARS,
  TRAINING_COST_NAME_CHOICES
} from "app/shared/modules/statement-deduction-base/models/deductions";
import { filter, take, takeUntil } from "rxjs/operators";
import { SHOW_SNACKBAR } from "app/reducers/actions/snackbar";
import { SnackbarConfig } from "app/common";
import { StatementService } from "app/statements/statement.service";
import { Store } from "@ngrx/store";
import { getSelectsData, State } from "app/reducers";
import { ValidateFieldV2Service } from "app/core/form-validator/validate-field-v2.service";
import { VALIDATOR_FIELD_NAMES_DEDUCTIONS } from "app/core/form-validator/validator-field-names/validator-field-names-deductions";
import { StatementErrors } from "app/modules/statement/models/statement/statement.errors.interface";
import { HttpStatusCodes } from "app/core/enums/http-status-codes.enum";
import { UPDATE_REFUND_DETAILS } from "app/reducers/actions/refund-details";
import { ErrorDialogComponent } from "app/common/error-dialog/error-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { DateHelper } from "app/core/helpers/date.helper";
import { City } from "app/statements/city";
import { DeductionDictionaryConstant } from "app/shared/modules/statement-deduction-base/constants/deduction-dictionary.constant";
import { DeductionDisabilityCareTaker } from "app/shared/modules/statement-deduction-base/enums/deduction-disability-care-taker.enum";

interface Errors extends DeductionsErrors, StatementErrors {}

@Injectable()
export class DeductionsService {
  public originalStatement: Statement;
  public mainStatement: Statement;
  public currentStatement: Statement;
  public errors: Errors = {};
  public epValidator: ErrorMessage[] = [];
  public TRAINING_COSTS_NAME_CHOICES = TRAINING_COST_NAME_CHOICES;
  public HOME_OFFICE_COST_NAME_CHOICES = HOME_OFFICE_COST_NAME_CHOICES;
  public MOVING_EXPANSES_COST_NAME_CHOICES = MOVING_EXPANSES_COST_NAME_CHOICES;
  public PROFESSION_INSURANCE_CHOICES = PROFESSION_INSURANCE_CHOICES;
  public CARE_COST_NAMES = CARE_COST_NAMES;
  public HEALTH_COST_NAMES = HEALTH_COST_NAMES;
  public DISABILITY_COST_NAMES = DISABILITY_COST_NAMES;
  public DISABILITY_PFLEGEGRAD_CARETAKER_NAMES = DeductionDictionaryConstant.DISABILITY_CARE_PFLEGEGRAD_CARETAKER_NAMES;
  public DISABILITY_PFLEGEGRAD_PFLEGEGRAD_NAMES =
    DeductionDictionaryConstant.DISABILITY_CARE_PFLEGEGRAD_PFLEGEGRAD_NAMES;
  public disabilityLevels = [];
  public countries: Country[] = [];
  public loading = false;
  public fullYear: number;
  public statementChanged = new Subject<void>();
  public dataSelects: SelectsDataInterface = new SelectsData();
  public readonly otherCity = City.TRAVEL_ABROAD_OTHER_CITY;
  public readonly inputIdBase = "deduction-input__";
  public readonly deductionFlagOptions = DeductionFlagOptions;
  public readonly VALIDATOR_KEYS = VALIDATOR_FIELD_NAMES_DEDUCTIONS;
  public readonly MAIN_STEP = "main";
  private readonly GERMAN_HOLIDAYS = GERMAN_HOLIDAYS;
  private errorDialog = ErrorDialogComponent;
  private ngUnsubscribe: Subject<void>;

  constructor(
    public store: Store<State>,
    private statementService: StatementService,
    private validateFieldV2Service: ValidateFieldV2Service,
    private dialog: MatDialog
  ) {}

  public _init(
    _mainStatement: Statement,
    _currentStatement: Statement,
    _ngUnsubscribe: Subject<void>,
    _originalStatement?: Statement
  ): void {
    this.clearErrors();

    if (_originalStatement) {
      this.originalStatement = _originalStatement;
    } else {
      this.originalStatement = _mainStatement.clone();
    }

    this.mainStatement = _mainStatement;
    this.currentStatement = _currentStatement;

    this.ngUnsubscribe = _ngUnsubscribe;

    this.loading = true;
    this.store
      .select(getSelectsData)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((data) => !!data)
      )
      .subscribe((selectsData: SelectsDataInterface) => {
        console.log("selectsData", selectsData);
        this.disabilityLevels = selectsData.getDisabilityPercentages(this.mainStatement.fullYear);
        this.countries = selectsData.countries;
        this.dataSelects = selectsData;
        this.loading = false;
      });

    this.validateFieldV2Service._init(this.mainStatement);
    this.statementChanged.next();

    this.fullYear = this.mainStatement.fullYear;
  }

  public get isLoading(): boolean {
    return this.loading;
  }

  public clearErrors(): void {
    this.errors = {};
  }

  public setDatePlaceHolder(): string {
    return DateHelper.getDatePlaceholder(this.mainStatement);
  }

  public getError(key: string, index = 0): string[] {
    if (this.errors && this.errors[key]) {
      if (Array.isArray(this.errors[key][index])) {
        return this.errors[key][index];
      } else if (index === 0) {
        return this.errors[key];
      }
    }

    return [];
  }

  public updateDeductions(
    group: string,
    callbackSuccess: (result: boolean, error?: boolean) => void,
    assignResponse = false
  ): void {
    this.clearErrors();

    if (group === this.MAIN_STEP) {
      group = null;
    }

    this.currentStatement.hasDeductions = !!this.currentStatement.deductions;
    this.currentStatement.stageDeductionInfo = true;

    const toResponse = this.mainStatement.deductionsInfoToResponse(
      "",
      this.currentStatement.isSpouse() ? "deductionInfo" : "",
      group
    );

    this.statementService
      .updateStatement(this.mainStatement.id, toResponse)
      .pipe(take(1), takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response: Statement) => {
          if (assignResponse) {
            Object.assign(this.mainStatement, response);
            this.updateAssignCurrentStatement();
            this.updateDeductionFlags();
          }

          this.updateOriginalStatement();

          this.store.dispatch({ type: UPDATE_REFUND_DETAILS });

          callbackSuccess(true);

          this.loading = false;
        },
        error: (errors) => {
          if (errors.status === HttpStatusCodes.INTERNAL_SERVER_ERROR) {
            this.store.dispatch({
              type: SHOW_SNACKBAR,
              payload: new SnackbarConfig("COMMON.GLOBAL_ERROR")
            });
          } else {
            const error = errors.error
              ? errors.error.spouses && errors.error.spouses.length
                ? errors.error.spouses[0]
                : errors.error
              : null;
            if (error && error.deductions) {
              this.errors = error.deductions;
            } else {
              this.errors = error;
            }
          }

          callbackSuccess(false, true);

          this.loading = false;
        }
      });
  }

  public setFromOriginalStatement(): void {
    Object.assign(this.mainStatement, this.originalStatement.clone());

    this.updateAssignCurrentStatement();
  }

  public updateOriginalStatement(): void {
    Object.assign(this.originalStatement, this.mainStatement.clone());
  }

  public addWorkAndOfficeTrainingCosts(): void {
    this.currentStatement.deductions.addTrainingCost();
  }

  public removeWorkAndOfficeTrainingCosts(index: number): void {
    this.currentStatement.deductions.removeTrainingCost(index);
  }

  public addJobEquipmentCost(): void {
    this.currentStatement.deductions.addJobEquipmentCost();
  }

  public removeJobEquipmentCost(index: number): void {
    this.currentStatement.deductions.removeJobEquipmentCost(index);
  }

  public addPremiumForTradeUnions(): void {
    this.currentStatement.deductions.addPremiumForTradeUnions();
  }

  public removePremiumForTradeUnions(index: number): void {
    this.currentStatement.deductions.removePremiumForTradeUnions(index);
  }

  public addHomeOfficeCost(): void {
    this.currentStatement.deductions.addHomeOfficeCost(this.showHomeOfficeCovid19);
  }

  public removeHomeOfficeCost(index: number): void {
    this.currentStatement.deductions.removeHomeOfficeCost(index);
  }

  public addRecruitmentCost(): void {
    this.currentStatement.deductions.addRecruitmentCost();
  }

  public removeRecruitmentCost(index: number): void {
    this.currentStatement.deductions.removeRecruitmentCost(index);
  }

  public addOtherCost(): void {
    this.currentStatement.deductions.addOtherCost();
  }

  public removeOtherRecruitmentCost(index: number): void {
    this.currentStatement.deductions.removeOtherRecruitmentCost(index);
  }

  public addWorkTripCost(): void {
    this.currentStatement.deductions.addWorkTripCost();
  }

  public removeWorkTripCost(index: number): void {
    this.currentStatement.deductions.removeWorkTripCost(index);
  }

  public addWinterbauCost(): void {
    this.currentStatement.deductions.addWinterbauCost();
  }

  public removeWinterbauCost(index: number): void {
    this.currentStatement.deductions.removeWinterbauCost(index);
  }

  public addBusinessTripsCost(): void {
    this.currentStatement.deductions.addBusinessTripsCost();
  }

  public removeBusinessTripsCost(index: number): void {
    this.currentStatement.deductions.removeBusinessTripsCost(index);
  }

  public addFreeMealsOnTravel(): void {
    this.currentStatement.deductions.addFreeMealsOnTravel();
  }

  public removeFreeMealsOnTravel(index: number): void {
    this.currentStatement.deductions.removeFreeMealsOnTravel(index);
  }

  public addFreeMealsOnTravelAbroad(): void {
    this.currentStatement.deductions.addFreeMealsOnTravelAbroad();
  }

  public removeFreeMealsOnTravelAbroad(index: number): void {
    this.currentStatement.deductions.removeFreeMealsOnTravelAbroad(index);
  }

  public addDoubleHouseholdCost(): void {
    this.currentStatement.deductions.addDoubleHouseholdCost();
  }

  public removeDoubleHouseholdCost(index: number): void {
    this.currentStatement.deductions.removeDoubleHouseholdCost(index);
  }

  public addMovingExpansesCost(): void {
    this.currentStatement.deductions.addMovingExpansesCost();
  }

  public removeMovingExpansesCost(index: number): void {
    this.currentStatement.deductions.removeMovingExpansesCost(index);
  }

  public get isDisabledAddHouseHoldMiniJobsCost(): boolean {
    return this.currentStatement.deductions.houseHoldMiniJobsCosts.length >= 12;
  }

  public addHouseHoldMiniJobsCost(): void {
    this.currentStatement.deductions.addHouseHoldMiniJobsCost();
  }

  public removeHouseHoldMiniJobsCost(index: number): void {
    this.currentStatement.deductions.removeHouseHoldMiniJobsCost(index);
  }

  public get isDisabledAddHouseHoldServicesCost(): boolean {
    return this.currentStatement.deductions.houseHoldServicesCosts.length >= 12;
  }

  public addHouseHoldServicesCost(): void {
    this.currentStatement.deductions.addHouseHoldServicesCost();
  }

  public removeHouseHoldServicesCost(index: number): void {
    this.currentStatement.deductions.removeHouseHoldServicesCost(index);
  }

  public get isDisabledAddCraftsmanServicesCost(): boolean {
    return this.currentStatement.deductions.craftsmanServicesCosts.length >= 12;
  }

  public addCraftsmanServicesCost(): void {
    this.currentStatement.deductions.addCraftsmanServicesCost();
  }

  public removeCraftsmanServicesCost(index: number): void {
    this.currentStatement.deductions.removeCraftsmanServicesCost(index);
  }

  public get isDisabledAddProfessionalInsuranceCost(): boolean {
    return this.currentStatement.deductions.professionInsuranceCostNames.length >= 20;
  }

  public addProfessionalInsuranceCost(): void {
    this.currentStatement.deductions.addProfessionalInsuranceCost();
  }

  public removeProfessionalInsuranceCost(index: number): void {
    this.currentStatement.deductions.removeProfessionalInsuranceCost(index);
  }

  public get isDisabledAddDonationsDe(): boolean {
    return this.currentStatement.deductions.donationDeValue.length >= 10;
  }

  public addDonationsDe(): void {
    this.currentStatement.deductions.addDonationsDe();
  }

  public removeDonationsDe(index: number): void {
    this.currentStatement.deductions.removeDonationsDe(index);
  }

  public get isDisabledAddDonationsEu(): boolean {
    return this.currentStatement.deductions.donationEuValue.length >= 10;
  }

  public addDonationsEu(): void {
    this.currentStatement.deductions.addDonationsEu();
  }

  public removeDonationsEu(index: number): void {
    this.currentStatement.deductions.removeDonationsEu(index);
  }

  public get isDisabledAddDonationsPolitical(): boolean {
    return this.currentStatement.deductions.donationPoliticalValue.length >= 10;
  }

  public addDonationsPolitical(): void {
    this.currentStatement.deductions.addDonationsPolitical();
  }

  public removeDonationsPolitical(index: number): void {
    this.currentStatement.deductions.removeDonationsPolitical(index);
  }

  public addTaxSettlementCost(): void {
    this.currentStatement.deductions.addTaxSettlementCost();
  }

  public removeTaxSettlementCost(index: number): void {
    this.currentStatement.deductions.removeTaxSettlementCost(index);
  }

  public addPhoneCost(): void {
    this.currentStatement.deductions.addPhoneCost();
  }

  public removePhoneCost(index: number): void {
    this.currentStatement.deductions.removePhoneCost(index);
  }

  public addBankCost(): void {
    this.currentStatement.deductions.addBankCost();
  }

  public removeBankCost(index: number): void {
    this.currentStatement.deductions.removeBankCost(index);
  }

  public addCareCost(): void {
    this.currentStatement.deductions.addCareCost();
  }

  public removeCareCost(index: number): void {
    this.currentStatement.deductions.removeCareCost(index);
  }

  public addHealthCost(): void {
    this.currentStatement.deductions.addHealthCost();
  }

  public removeHealthCost(index: number): void {
    this.currentStatement.deductions.removeHealthCost(index);
  }

  public addDisabilityCost(): void {
    this.currentStatement.deductions.addDisabilityCost();
  }

  public removeDisabilityCost(index: number): void {
    this.currentStatement.deductions.removeDisabilityCost(index);
  }

  public get showForeignSicknessBenefitReimbursements(): boolean {
    return !!Number(this.currentStatement.deductions.healthAndForeignInsuranceContributionsRefunds);
  }

  public get showSicknessBenefitReimbursements(): boolean {
    return !!Number(this.currentStatement.deductions.healthAndInsuranceContributionsRefunds);
  }

  public get showAdditionalLongTermCareInsurance(): boolean {
    return SHOW_ADDITIONAL_LONG_TERM_CARE_INSURANCE_YEARS.indexOf(this.mainStatement.fullYear) !== -1;
  }

  public get showRiesterAgreementNumbersField(): boolean {
    return SHOW_RIESTER_AGREEMENT_NUMBERS_FIELD_YEARS.indexOf(this.mainStatement.fullYear) !== -1;
  }

  public get showRiesterSocialSecurityNumbersField(): boolean {
    return SHOW_RIESTER_SOCIAL_SECURITY_NUMBERS_FIELD_YEARS.indexOf(this.mainStatement.fullYear) !== -1;
  }

  public get showHomeOfficeCovid19(): boolean {
    return HOME_OFFICE_COVID_YEARS.indexOf(this.mainStatement.fullYear) !== -1;
  }

  public get showRiesterIndirectlyFavoredField(): boolean {
    if (
      this.currentStatement.isSpouse() &&
      this.mainStatement.hasRiester &&
      this.mainStatement.deductions.indirectlyFavored
    ) {
      this.currentStatement.deductions.indirectlyFavored = false;
      return false;
    }

    if (!this.mainStatement.spouse) {
      this.currentStatement.deductions.indirectlyFavored = false;
      return false;
    }

    if (
      !this.currentStatement.isSpouse() &&
      this.mainStatement.spouse.hasRiester &&
      this.mainStatement.spouse.deductions.indirectlyFavored
    ) {
      this.mainStatement.deductions.indirectlyFavored = false;
      return false;
    }

    return true;
  }

  public riesterIndirectlyFavoredChangeEvent(): void {
    if (this.currentStatement.deductions.indirectlyFavored) {
      if (!this.currentStatement.deductions.contributionsAmount) {
        this.currentStatement.deductions.contributionsAmount = "";
      }
      this.currentStatement.deductions.lastYearIncome = undefined;
    } else {
      if (!this.currentStatement.deductions.lastYearIncome) {
        this.currentStatement.deductions.lastYearIncome = "";
      }
    }
  }

  public hasJobCostsLumpSumChangeEvent() {
    this.currentStatement.deductions.purchasedJobEquipment = false;
    this.currentStatement.deductions.removeAllJobEquipmentCost();

    this.currentStatement.deductions.haveWorkwear = false;
    this.currentStatement.deductions.clearWorkWearCost();
  }

  public hasPurchasedJobEquipmentCostChangeEvent(): void {
    if (
      !this.currentStatement.deductions.jobEquipmentCosts.length &&
      this.currentStatement.deductions.purchasedJobEquipment
    ) {
      this.currentStatement.deductions.addJobEquipmentCost();
    } else if (!this.currentStatement.deductions.purchasedJobEquipment) {
      this.currentStatement.deductions.removeAllJobEquipmentCost();
    }
  }

  public hasTrainingCostsChangeEvent(): void {
    if (!this.currentStatement.deductions.trainingCosts.length && this.currentStatement.deductions.haveTrainingCosts) {
      this.currentStatement.deductions.addTrainingCost();
    } else if (!this.currentStatement.deductions.haveTrainingCosts) {
      this.currentStatement.deductions.removeAllTrainingCost();
    }
  }

  public homeOfficeCovid19ChangeEvent(): void {
    if (this.isHomeOfficeCovid19Selected && !this.currentStatement.deductions.isHomeOfficeCostExists) {
      this.currentStatement.deductions.addHomeOfficeCost(true);
    }
  }

  public setWorkingDays(i: number): void {
    const workingDays = this.countPossibleWorkingDays(i);

    if (!workingDays) {
      return;
    }

    this.currentStatement.deductions.presencesAtWork[i] = workingDays.toString();
  }

  public workingDaysPerWeekChangeEvent(index: number): void {
    if (parseInt(this.currentStatement.deductions.workingDaysPerWeek[index], 10)) {
      setTimeout(() => {
        const workingDays = this.countPossibleWorkingDays(index);
        if (workingDays === 0) {
          return;
        }
        this.currentStatement.deductions.presencesAtWork[index] = workingDays.toString();
      }, 500);
    }
  }

  public disabilityWithLumpChangeEvent(): void {
    if (this.currentStatement.deductions.applyingForDisabilityWithLump) {
      this.currentStatement.deductions.applyingForDisabilityWithoutLump = false;
      if (!this.currentStatement.deductions.disabilityPercent) {
        this.currentStatement.deductions.disabilityPercent = "";
      }
    }
  }

  public disabilityWithoutLumpChangeEvent(): void {
    if (this.currentStatement.deductions.applyingForDisabilityWithoutLump) {
      this.currentStatement.deductions.applyingForDisabilityWithLump = false;
      if (!this.currentStatement.deductions.disabilityCostsNames.length) {
        this.addDisabilityCost();
      }
    }
  }

  public updateDeductionFlags(): void {
    if (this.currentStatement) {
      this.currentStatement.updateDeductionsFlag();
    }
  }

  public haveSeparatedHouseholdChangeEvent(): void {
    if (!this.currentStatement.deductions.householdPostalCode.length) {
      this.addDoubleHouseholdCost();
    }
  }

  public get isJobEquipmentCostsShow(): boolean {
    return (
      !this.currentStatement.deductions.haveJobCostsLumpSum && this.currentStatement.deductions.purchasedJobEquipment
    );
  }

  public get isWorkWearCostShow(): boolean {
    return !this.currentStatement.deductions.haveJobCostsLumpSum && this.currentStatement.deductions.haveWorkwear;
  }

  public get isTrainingCostsShow(): boolean {
    return this.currentStatement.deductions.haveTrainingCosts;
  }

  public get isHomeOfficeCovid19Selected(): boolean {
    return this.currentStatement.deductions.homeOfficeCovid19[0];
  }

  public haveCarTravels(index: number): boolean {
    return this.currentStatement.deductions.haveCarTravels[index];
  }

  public havePurchasedTravelTickets(index: number): boolean {
    return this.currentStatement.deductions.purchasedTravelTickets[index];
  }

  public haveAccommodationCosts(index: number): boolean {
    return this.currentStatement.deductions.haveAccommodationCosts[index];
  }

  public haveEmployerRefunds(index: number): boolean {
    return this.currentStatement.deductions.haveEmployerRefunds[index];
  }

  public isFreeMealsDietAmountShown(index: number): boolean {
    return (
      this.currentStatement.deductions.haveFreeMealsOnTravel[index] &&
      !this.currentStatement.deductions.haveRegularFreeMealsOnTravel[index]
    );
  }

  public isFreeMealsDietAmountAbroadShown(index: number): boolean {
    return (
      this.currentStatement.deductions.haveFreeMealsOnTravelAbroad[index] &&
      !this.currentStatement.deductions.haveRegularFreeMealsOnTravelAbroad[index]
    );
  }

  public get isMovingExpansesCostsShow(): boolean {
    return !this.currentStatement.deductions.haveMovingExpanses;
  }

  public checkDeductionFields(): void {
    if (
      !this.currentStatement.deductions.hasDisabilityCarePflegegrad ||
      !this.currentStatement.deductions.disabledPersonData?.length
    ) {
      this.currentStatement.deductions.clearDisabilityCarePflegegrad();
    } else {
      if (
        !this.currentStatement.isJointStatement &&
        this.currentStatement.deductions.disabilityPflegegradCaretaker.length > 1
      ) {
        this.currentStatement.deductions.disabilityPflegegradCaretaker = [DeductionDisabilityCareTaker.APPLICANT];
      }

      if (!this.currentStatement.deductions.disabilityPflegegradCaretaker.length) {
        this.currentStatement.deductions.disabilityPflegegradCaretaker = [null];
      }

      if (!this.currentStatement.deductions.disabilityPflegegrad.length) {
        this.currentStatement.deductions.disabilityPflegegrad = [null];
      }
    }

    if (!this.isJobEquipmentCostsShow) {
      this.currentStatement.deductions.removeAllJobEquipmentCost();
    }

    if (!this.isWorkWearCostShow) {
      this.currentStatement.deductions.clearWorkWearCost();
    } else {
      if (this.currentStatement.deductions.workwearCost === undefined) {
        this.currentStatement.deductions.workwearCost = "0.00";
      }
      if (this.currentStatement.deductions.workwearWashingCost === undefined) {
        this.currentStatement.deductions.workwearWashingCost = "0.00";
      }
    }

    if (!this.isTrainingCostsShow) {
      this.currentStatement.deductions.removeAllTrainingCost();
    } else if (!this.currentStatement.deductions.trainingCosts.length) {
      this.currentStatement.deductions.haveTrainingCosts = false;
    }

    if (!this.isMovingExpansesCostsShow) {
      this.currentStatement.deductions.removeAllMovingExpansesCost(true);
    }

    if (this.currentStatement.hasHomeOffice) {
      if (!this.showHomeOfficeCovid19) {
        this.currentStatement.deductions.homeOfficeCovid19 = [];
        this.currentStatement.deductions.homeOfficeCovid19DayInHome = [];
      }

      if (this.isHomeOfficeCovid19Selected) {
        this.currentStatement.deductions.homeOfficeCosts = [];
        this.currentStatement.deductions.homeOfficeCostsNames = [];
      } else {
        this.currentStatement.deductions.homeOfficeCovid19 = [];
        this.currentStatement.deductions.homeOfficeCovid19DayInHome = [];
        if (!this.currentStatement.deductions.isHomeOfficeCostExists) {
          this.currentStatement.deductions.homeOfficeCosts.push("0.00");
          this.currentStatement.deductions.homeOfficeCostsNames.push("");
        }
      }
    }

    this.currentStatement.deductions.haveCarTravels.some((el, index) => {
      if (!this.haveCarTravels(index)) {
        this.currentStatement.deductions.carTravelsDistance[index] = undefined;
        this.currentStatement.deductions.carTravelsNumber[index] = undefined;
      } else {
        if (!this.currentStatement.deductions.carTravelsDistance[index]) {
          this.currentStatement.deductions.carTravelsDistance[index] = "";
        }
        if (!this.currentStatement.deductions.carTravelsNumber[index]) {
          this.currentStatement.deductions.carTravelsNumber[index] = "";
        }
      }
    });

    this.currentStatement.deductions.purchasedTravelTickets.some((el, index) => {
      if (!this.havePurchasedTravelTickets(index)) {
        this.currentStatement.deductions.travelTicketsCosts[index] = undefined;
      } else if (!this.currentStatement.deductions.travelTicketsCosts[index]) {
        this.currentStatement.deductions.travelTicketsCosts[index] = "";
      }
    });

    this.currentStatement.deductions.haveAccommodationCosts.some((el, index) => {
      if (!this.haveAccommodationCosts(index)) {
        this.currentStatement.deductions.accommodationCosts[index] = undefined;
      } else if (!this.currentStatement.deductions.accommodationCosts[index]) {
        this.currentStatement.deductions.accommodationCosts[index] = "";
      }
    });

    this.currentStatement.deductions.haveEmployerRefunds.some((el, index) => {
      if (!this.haveEmployerRefunds(index)) {
        this.currentStatement.deductions.employerRefunds[index] = undefined;
      } else if (!this.currentStatement.deductions.employerRefunds[index]) {
        this.currentStatement.deductions.employerRefunds[index] = "";
      }
    });

    this.currentStatement.deductions.haveFreeMealsOnTravel.some((el, index) => {
      if (!this.isFreeMealsDietAmountShown(index)) {
        this.currentStatement.deductions.freeMealsDietAmount[index] = undefined;
      } else if (!this.currentStatement.deductions.freeMealsDietAmount[index]) {
        this.currentStatement.deductions.freeMealsDietAmount[index] = "";
      }
    });

    this.currentStatement.deductions.haveFreeMealsOnTravelAbroad.some((el, index) => {
      if (!this.isFreeMealsDietAmountAbroadShown(index)) {
        this.currentStatement.deductions.freeMealsDietAmountAbroad[index] = undefined;
      } else if (!this.currentStatement.deductions.freeMealsDietAmountAbroad[index]) {
        this.currentStatement.deductions.freeMealsDietAmountAbroad[index] = "";
      }
    });

    this.riesterIndirectlyFavoredChangeEvent();
    this.currentStatement.updateHaveRiester();

    if (this.showSicknessBenefitReimbursements) {
      if (!this.currentStatement.deductions.sicknessBenefitReimbursements) {
        this.currentStatement.deductions.sicknessBenefitReimbursements = "";
      }
    } else {
      this.currentStatement.deductions.sicknessBenefitReimbursements = undefined;
    }

    if (this.currentStatement.deductions.applyingForDisabilityWithLump) {
      this.currentStatement.deductions.applyingForDisabilityWithoutLump = false;
      if (!this.currentStatement.deductions.disabilityPercent) {
        this.currentStatement.deductions.disabilityPercent = "";
      }
    } else {
      this.currentStatement.deductions.disabilityPercent = undefined;
    }

    if (this.currentStatement.deductions.applyingForDisabilityWithoutLump) {
      this.currentStatement.deductions.applyingForDisabilityWithLump = false;
      if (!this.currentStatement.deductions.disabilityCostsNames.length) {
        this.addDisabilityCost();
      }
      this.currentStatement.deductions.disabilityPercent = undefined;
    } else {
      this.currentStatement.deductions.removeAllDisabilityCost();
    }
  }

  public formValidationErrors(group: string): void {
    this.checkDeductionFields();

    this.epValidator = [];
    if (!this.mainStatement) {
      return;
    }

    let validationFieldKeys = [];

    switch (group) {
      case DeductionWorkAndOfficeSteps.EQUIPMENT_COST:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.job_equipment_cost_name,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.job_equipment_costs,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.work_wear_cost,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.work_wear_washing_cost,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.training_cost_names,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.training_costs
        ];
        break;
      case DeductionWorkAndOfficeSteps.TRADE_UNION_FEES:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.trade_union_name,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.trade_union_contribution
        ];
        break;
      case DeductionWorkAndOfficeSteps.HOME_OFFICE:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.home_office_covid19_day_in_home,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.home_office_costs_name,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.home_office_costs
        ];
        break;
      case DeductionWorkAndOfficeSteps.RECRUITMENT_COST:
        validationFieldKeys = [VALIDATOR_FIELD_NAMES_DEDUCTIONS.recruitment_costs];
        break;
      case DeductionWorkAndOfficeSteps.WORK_TRIP_COST:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.place_employment_address,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.period_employment_lower,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.period_employment_upper,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.working_days_per_week,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.absences_work,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.presences_work,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.distance_from_work,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.public_transport_cost
        ];
        break;
      case DeductionBusinessTripsSteps.BUSINESS_TRIP_COST:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.car_travels_distance,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.car_travels_number,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.travel_tickets_cost_value,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.accommodation_costs,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.employer_refund_value
        ];
        break;
      case DeductionBusinessTripsSteps.DAILY_ALLOWANCE:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_one_day_travel,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_travel_to_work,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_travel_from_work,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_in_multi_day_travel,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.free_meals_diet_amount
        ];
        break;
      case DeductionBusinessTripsSteps.DAILY_ALLOWANCE_ABROAD:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.travel_abroad_country,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.travel_abroad_city,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_in_one_day_travel_abroad,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_in_travel_abroad_to_work,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_in_travel_abroad_from_work,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_in_multi_day_travel_abroad,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.free_meals_diet_amount_abroad
        ];
        break;
      case DeductionBusinessTripsSteps.DRIVER_LUMP_SUM:
        validationFieldKeys = [VALIDATOR_FIELD_NAMES_DEDUCTIONS.driver_lump_sum];
        break;
      case DeductionHouseSteps.MOVING:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.moving_expanses_costs_names,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.moving_expanses_costs
        ];
        break;
      case DeductionHouseSteps.DOUBLE_HOUSEHOLD:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.household_postal_code,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.household_city,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.household_country,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.household_date_from,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.residence_at_place_of_employment_postal_code,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.residence_at_place_of_employment_city,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.period_of_residence_at_place_of_employment_lower,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.period_of_residence_at_place_of_employment_upper,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.residence_at_place_of_employment_costs,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.first_and_last_travel_kilometers,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.first_and_last_travel_ticket_costs,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.weekend_trips_distance,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.weekend_trips_number,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.weekend_trips_ticket_costs,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_spent_in_travel,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_spent_at_place_of_employment,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.other_costs_name,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.other_costs
        ];
        break;
      case DeductionHomeServiceSteps.HOUSEHOLD_MINI_JOBS_COSTS:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.house_hold_mini_jobs_costs_names,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.house_hold_mini_jobs_costs
        ];
        break;
      case DeductionHomeServiceSteps.HOUSEHOLD_SERVICES_COSTS:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.house_hold_services_costs_names,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.house_hold_services_costs
        ];
        break;
      case DeductionHomeServiceSteps.CRAFTSMAN_SERVICES_COSTS:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.craftsman_services_costs_names,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.craftsman_services_costs
        ];
        break;
      case DeductionInsuranceSteps.RIESTER:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.social_security_number,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.agreement_numbers,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_amount,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.last_year_income
        ];
        break;
      case DeductionInsuranceSteps.PRIVATE_INSURANCE:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_to_private_health_insurance,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.private_long_term_care_insurance_contributions,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_and_private_insurance_contributions_refunds,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_and_private_insurance_contributions_grants,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.private_elective_and_supplementary_insurance,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.additional_long_term_care_insurance
        ];
        break;
      case DeductionInsuranceSteps.STATUTORY_INSURANCE:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_to_health_insurance,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_to_sickness_benefit,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.long_term_care_insurance_contributions,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_and_insurance_contributions_refunds,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.sickness_benefit_reimbursements,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_and_insurance_contributions_grants,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.elective_and_supplementary_insurance
        ];
        break;
      case DeductionInsuranceSteps.FOREIGN_INSURANCE:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_to_foreign_health_insurance,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_to_foreign_sickness_benefit,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.foreign_long_term_care_insurance_contributions,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_and_foreign_insurance_contributions_refunds,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.foreign_sickness_benefit_reimbursements,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.foreign_elective_and_supplementary_insurance
        ];
        break;
      case DeductionInsuranceSteps.FOREIGN_SOCIAL_SECURITY:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.foreign_social_insurance_employee_contributions,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.foreign_social_insurance_employer_contributions
        ];
        break;
      case DeductionInsuranceSteps.PROFESSIONAL_AND_LIABILITY_INSURANCE:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.profession_insurance_cost_names,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.profession_insurance_costs
        ];
        break;
      case DeductionDonationsAndMembershipsSteps.DONATIONS_IN_GERMANY:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_de_name,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_de_value
        ];
        break;
      case DeductionDonationsAndMembershipsSteps.DONATIONS_IN_EU:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_eu_name,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_eu_value
        ];
        break;
      case DeductionDonationsAndMembershipsSteps.DONATIONS_TO_POLITICAL_PARTIES:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_political_name,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_political_value
        ];
        break;
      case DeductionOtherCostsSteps.TAX_RETURN_COSTS:
        validationFieldKeys = [VALIDATOR_FIELD_NAMES_DEDUCTIONS.tax_settlement_costs];
        break;
      case DeductionOtherCostsSteps.PHONE_AND_INTERNET_COSTS:
        validationFieldKeys = [VALIDATOR_FIELD_NAMES_DEDUCTIONS.phone_and_internet_costs];
        break;
      case DeductionOtherCostsSteps.WINTERBAU_COSTS:
        validationFieldKeys = [VALIDATOR_FIELD_NAMES_DEDUCTIONS.winterbau_costs];
        break;
      case DeductionOtherCostsSteps.BANK_COSTS:
        validationFieldKeys = [VALIDATOR_FIELD_NAMES_DEDUCTIONS.bank_costs];
        break;
      case DeductionOtherCostsSteps.OTHER_COSTS:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.other_recruitment_cost_names,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.other_recruitment_costs
        ];
        break;
      case DeductionHealthSteps.HEALTH_COSTS:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_cost_name,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_cost,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_cost_refund
        ];
        break;
      case DeductionHealthSteps.CARE_COSTS:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.care_cost_name,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.care_cost,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.care_cost_refund
        ];
        break;
      case DeductionHealthSteps.DISABILITY_COSTS:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_percent,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_cost_name,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_cost,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_refund_amount
        ];
      case DeductionHealthSteps.DISABILITY_CARE_PFLEGEGRAD:
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_pflegegrad_caretaker,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_pflegegrad,
          VALIDATOR_FIELD_NAMES_DEDUCTIONS.disabled_person_data
        ];
        break;
    }

    this.epValidator = [
      ...this.validateFieldV2Service.validateFieldAndGetMessageV2(this.currentStatement.deductions, validationFieldKeys)
    ];
  }

  public showErrorSpouseRiesterDialog(callback: () => any = () => {}): void {
    const refDialog = this.dialog.open(this.errorDialog);
    refDialog.componentInstance.isCustom = false;
    refDialog.componentInstance.message = "REISTER_ERROR.SPOUSE_REISTER_ERROR";
    refDialog.componentInstance.understandButton = true;
    refDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: callback
    });
  }

  public removeAllRiester(callbackSuccess: (result: boolean) => void): void {
    this.mainStatement.deductions.clearRiester();
    this.mainStatement.updateDeductionsFlag();
    if (this.mainStatement.spouse && this.mainStatement.spouse.deductions) {
      this.mainStatement.spouse.deductions.clearRiester();
      this.mainStatement.spouse.updateDeductionsFlag();
    }

    this.updateDeductions(DeductionInsuranceSteps.RIESTER, callbackSuccess);
  }

  public assignMainStatement(_mainStatement: Statement): void {
    Object.assign(this.mainStatement, _mainStatement);
    this.updateAssignCurrentStatement();
    this.updateOriginalStatement();
  }

  public updateAssignCurrentStatement(): void {
    if (this.currentStatement.isSpouse() && this.mainStatement.spouse) {
      Object.assign(this.currentStatement, this.mainStatement.spouse);
    } else {
      Object.assign(this.currentStatement, this.mainStatement);
    }
    this.statementChanged.next();
  }

  private countPossibleWorkingDays(i: number): number {
    let dayPerWeek = Number(this.currentStatement.deductions.workingDaysPerWeek[i]);
    const absenceDays = Number(this.currentStatement.deductions.absencesAtWork[i]);

    if (
      !this.currentStatement ||
      !this.currentStatement.deductions ||
      !this.currentStatement.deductions.workingDaysPerWeek ||
      !this.currentStatement.deductions.periodOfEmployment[i] ||
      !this.currentStatement.deductions.periodOfEmployment[i].lower ||
      !this.currentStatement.deductions.periodOfEmployment[i].upper ||
      !dayPerWeek
    ) {
      return 0;
    }

    if (dayPerWeek > 7) {
      dayPerWeek = 7;
    }

    const dateFrom: Date = new Date(this.currentStatement.deductions.periodOfEmployment[i].lower);
    const dateTo: Date = new Date(this.currentStatement.deductions.periodOfEmployment[i].upper);

    if (
      dateFrom.getFullYear() !== this.mainStatement.fullYear ||
      dateTo.getFullYear() !== this.mainStatement.fullYear ||
      dateTo < dateFrom
    ) {
      return 0;
    }

    let wDays = 0;
    let index = 0;

    const currentYearHolidays = this.GERMAN_HOLIDAYS.filter((x) => x.year === this.currentStatement.fullYear);

    while (dateFrom <= dateTo) {
      if (index > 0) {
        dateFrom.setDate(dateFrom.getDate() + 1);
      }

      // is NOT weekend
      if (dateFrom.getDay() !== 0 && dateFrom.getDay() !== 6) {
        const currentTestDate = DateHelper.getFormatted(dateFrom, "YYYY.MM.DD");
        if (!currentYearHolidays.find((x) => x.holidayDate === currentTestDate)) {
          wDays++;
        }
      }
      index++;
    }

    wDays = Math.round((wDays * dayPerWeek) / 5);
    wDays = wDays - absenceDays;

    return wDays;
  }
}
