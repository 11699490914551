import { Component, OnInit, Input } from '@angular/core';
import { LohnsteuerValidator } from '../lohnsteuerValidator';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lohnsteuer-validator-dialog',
  templateUrl: './lohnsteuer-validator-dialog.component.html',
  styleUrls: ['./lohnsteuer-validator-dialog.component.scss']
})
export class LohnsteuerValidatorDialogComponent implements OnInit {

  @Input() public statementsValidator:LohnsteuerValidator[] = [];

  constructor(private dialogRef: MatDialogRef<LohnsteuerValidatorDialogComponent>) { }

  ngOnInit() {}

  public close(){
    this.dialogRef.close(false);
  }

  public proceed(){
    this.dialogRef.close(true);
  }
}
