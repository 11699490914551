import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { getUser, State } from 'app/reducers';
import { SubscribingComponent, SnackbarConfig } from 'app/common';
import { UserChangePasswordHttpService } from 'app/modules/user/services/http/user-change-password.http.service';
import { ChangePasswordModel } from 'app/modules/user/models/change-password/change-password.model';
import { ChangePasswordErrorInterface } from 'app/modules/user/models/change-password/change-password.error.interface';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/users/user';
import { SHOW_SNACKBAR } from 'app/reducers/actions/snackbar';
import { AuthModel } from 'app/modules/user/models/auth/auth.model';
import { TOKEN_REFRESH } from 'app/reducers/actions/auth-user';
import { AuthService } from 'app/users';

interface FormError {
  currentPassword: string[];
  newPassword: string[];
  newPasswordRepeat: string[];
}

@Component({
  selector: 'app-user-change-password-dialog',
  templateUrl: './user-change-password-dialog.component.html',
})
export class UserChangePasswordDialogComponent extends SubscribingComponent implements OnInit {
  public email: string;
  public data: ChangePasswordModel;
  public errors: ChangePasswordErrorInterface;
  public formError: FormError = {
    currentPassword: [],
    newPassword: [],
    newPasswordRepeat: [],
  };
  public form = new UntypedFormGroup({
    currentPassword: new UntypedFormControl(
      {value: '', disabled: false},
      [Validators.required]
    ),
    newPassword: new UntypedFormControl(
      {value: '', disabled: false},
      [Validators.required]
    ),
    newPasswordRepeat: new UntypedFormControl(
      {value: '', disabled: false},
      [Validators.required]
    ),
  });
  public set isFormLoading(_isForgotPasswordLoading: boolean) {
    this._isFormLoading = _isForgotPasswordLoading;
    if (this._isFormLoading) {
      this.form.disable();
      return;
    }

    this.form.enable();
  }
  public get isFormLoading(): boolean {
    return this._isFormLoading;
  }
  private _isFormLoading = false;

  constructor(
    private store: Store<State>,
    private dialogRef: MatDialogRef<UserChangePasswordDialogComponent>,
    private userChangePasswordHttpService: UserChangePasswordHttpService,
    private translate: TranslateService,
    private authService: AuthService,
  ) {
    super();
  }

  ngOnInit() {
    this.data = new ChangePasswordModel();

    this.store.select(getUser).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          this.email = user.email;
        }
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public get formCurrentPassword(): UntypedFormControl {
    return this.form.get('currentPassword') as UntypedFormControl;
  }

  public get formNewPassword(): UntypedFormControl {
    return this.form.get('newPassword') as UntypedFormControl;
  }

  public get formNewPasswordRepeat(): UntypedFormControl {
    return this.form.get('newPasswordRepeat') as UntypedFormControl;
  }

  public clearErrors(): void {
    this.errors = {};
  }

  public async onSubmit(): Promise<void> {
    this.formErrorCheck();

    if (this.form.valid) {
      const rowValue = this.form.getRawValue();
      this.data.currentPassword = rowValue.currentPassword;
      this.data.newPassword = rowValue.newPassword;
      this.data.repeatNewPassword = rowValue.newPasswordRepeat;
      if (!this.data.isPasswordsEqual()) {
        this.formError.newPassword = [this.translate.instant('EP_VALIDATOR.PASSWORDS_MISMATCH')];
        this.formError.newPasswordRepeat = [this.translate.instant('EP_VALIDATOR.PASSWORDS_MISMATCH')];
        return;
      }

      this.dialogRef.disableClose = true;
      this.isFormLoading = true;
      const response = await this.userChangePasswordHttpService.changePassword(this.data.payload);
      this.isFormLoading = false;

      if (response.success) {
        this.onUpdatePasswordSuccess(response.body);
        return;
      }

      this.handleFetchErrors(response.error);
    }
  }

  private onUpdatePasswordSuccess(auth: AuthModel): void {
    this.store.dispatch({ type: TOKEN_REFRESH, payload: auth });
    this.authService.updateTokenInLocalStorageAndCookies(auth);

    this.closeDialog();
    this.onSetPasswordSuccess();
  }

  private onSetPasswordSuccess(): void {
    this.store.dispatch({
      type: SHOW_SNACKBAR,
      payload: new SnackbarConfig('SNACKBAR.PASSWORD_CHANGE'),
    });
  }

  private clearFormErrors(): void {
    this.formError.currentPassword = [];
    this.formError.newPassword = [];
    this.formError.newPasswordRepeat = [];
  }

  private handleFetchErrors(_errors: ChangePasswordErrorInterface): void {
    this.clearFormErrors();
    this.errors = _errors;

    if (this.errors?.current_password?.length) {
      this.formError.currentPassword = this.errors.current_password;
    }

    if (this.errors?.new_password?.length) {
      this.formError.newPassword = this.errors.new_password;
    }

    if (this.errors?.re_new_password?.length) {
      this.formError.newPasswordRepeat = this.errors.re_new_password;
    }
  }

  private formErrorCheck(): void {
    this.clearFormErrors();

    if (this.formCurrentPassword.invalid) {
      if (this.formCurrentPassword.errors?.['required']) {
        this.formError.currentPassword = [this.translate.instant('EP_VALIDATOR.FIELD_IS_REQUIRED')];
      }
    }
    if (this.formNewPassword.invalid) {
      if (this.formNewPassword.errors?.['required']) {
        this.formError.newPassword = [this.translate.instant('EP_VALIDATOR.FIELD_IS_REQUIRED')];
      }
    }
    if (this.formNewPasswordRepeat.invalid) {
      if (this.formNewPasswordRepeat.errors?.['required']) {
        this.formError.newPasswordRepeat = [this.translate.instant('EP_VALIDATOR.FIELD_IS_REQUIRED')];
      }
    }
  }
}
