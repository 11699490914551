<button
  mat-button
  class="button button-edit button-icon {{ extraClasses }}"
  [class.is-loading]="isLoading"
  [class.button-icon-text]="withText"
  [class.disabled]="isDisabled"
  (click)="clickEvent($event)"
  type="button"
>
  <img src="assets/svg/edit_icon.svg" alt="edit" />
  <span *ngIf="withText">{{ title | translate }}</span>
</button>
