import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { AdditionalIncomeSpouseComponentPath } from 'app/core/workflow/page-path/statement-page-path/additional-income-spouse-component.path';

export class WorkflowAdditionalIncomeSpouseSubBaseService extends WorkflowStepsBaseService {
  public get subStepNumber(): number {
    return 1;
  }

  public get isDone(): boolean {
    return this.statement && this.statement.spouse && this.statement.spouse.stageAdditionalIncome;
  }

  public get isRequired(): boolean {
    return !this.isBusiness && this.hasSpouseAndFilledTogether;
  }

  public get isVisible(): boolean {
    return this.isRequired
      && window.location.href.search(AdditionalIncomeSpouseComponentPath.regex()) !== -1;
  }

  public get title(): string {
    return 'ASIDE_BAR.ADDITIONAL_INCOME_SUBLIST.HEALTH_BENEFITS';
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.isRequired && this.isSpouseStageEmployerDone;
  }

  public get urlRegex(): RegExp {
    return AdditionalIncomeSpouseComponentPath.regex(this.subStepNumber);
  }

  public get url(): string {
    return AdditionalIncomeSpouseComponentPath.url(this.getStatementId, this.getSpouseId, this.subStepNumber);
  }

  public get stepClass(): string {
    return this.classLeftLineNone;
  }

  public get isSubStep(): boolean {
    return true;
  }
}
