import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SubscribingComponent } from 'app/common';
import { getUser, State } from 'app/reducers';
import { UserChangeDataComponent } from 'app/users';
import { SideNavService } from '../side-nav.service';
import { User } from 'app/users';
import { AuthService } from 'app/users';
import { ImpressumComponent } from '../impressum/impressum.component';
import { FirebaseEventsService } from 'app/common/firebase-events.service';
import { UserChangePasswordDialogComponent } from 'app/shared/components/user-change-password-dialog/user-change-password-dialog.component';
import { TermsFilesService } from 'app/core/services/terms-files.service';
import { getAuthUser } from 'app/reducers';
import { AuthModel } from 'app/modules/user/models/auth/auth.model';
import { CookiesLawService } from 'app/core/services/cookies-law.service';
import { Router } from '@angular/router';
import { CouponsPagePath } from '../workflow/page-path/coupons.path';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss']
})
export class AsideMenuComponent extends SubscribingComponent implements OnInit {
  fullName: string;
  isBusiness = false;
  currentLang: string;
  userLogin : string;
  constructor(
    private store: Store<State>,
    private dialog: MatDialog,
    private sideNavService: SideNavService,
    private authService: AuthService,
    private translate: TranslateService,
    private firebaseEventsService: FirebaseEventsService,
    private termsFilesService: TermsFilesService,
    public cookiesLawService: CookiesLawService,
    private router: Router
  ) {
    super();
    
  }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;

    this.store.select(getUser).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          this.fullName = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : '';
          this.isBusiness = user.isBusiness;
          this.userLogin = user.email;
        }
      });
  }

  public showChangePasswordDialog(): void {
    this.dialog.open(UserChangePasswordDialogComponent,
      {
        panelClass: ['login_dialog_wrap', 'new-dialog-wrap'],
        backdropClass: 'backdropBackgroundWhite',
      });
    this.closeSideNav();
  }

  public showChangeDataDialog(): void {
    this.dialog.open(UserChangeDataComponent, {
      backdropClass: 'backdropBackgroundWhite',
      panelClass: ['personal-info-dialog-wrap', 'new-dialog-wrap']
    });
    this.closeSideNav();
  }

  showPrivacyPolicyDialog() {
    this.termsFilesService.openPrivacyPolicy();
  }

  showTermsAndConditionsDialog() {
    this.termsFilesService.openTermsAndConditions();
  }

  showImpressumDialog() {
    this.dialog.open(ImpressumComponent);
  }

  closeSideNav() {
    this.sideNavService.close();
  }

  logout() {
    this.firebaseEventsService.logEvent('logout', null)
    this.closeSideNav();
    this.authService.logout();
  }

  showCookieBanner(): void {
    this.closeSideNav();
    this.cookiesLawService.activeCookieBanner();
  }

  public openCoupons(): void {
    this.closeSideNav();
    this.router.navigate([CouponsPagePath.path()]);
  }
}
