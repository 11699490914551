<div class="personal-info-edit__stage">
  <div class="form-select" (clickElsewhere)="closeTooltip()">
    <div class="form-select__header level">
      <div class="level-left" (click)="closeTooltip()">
        <label class="form-select__label is-small">
          <span class="form-select__label-tx">{{ 'SELECT.TAX_AUTHORITY.LABEL' | translate }}</span>
        </label>
      </div>
      <app-more-info-tooltip class="tooltipInInput" *ngIf="message" [message]="message" [isSmall]="true" [showText]="false" [additionalDeductions]="true" [tooltipInInput]=true></app-more-info-tooltip>
      <!-- <div class="level-right tooltip-ico" *ngIf="message" (click)="toggleTooltip()">
          <span class="more-info__ico info-ico is-unselectable">i</span>
      </div> -->
    </div>

    <div
      class="form-select__content"
      [class.active]="activeInput"
      [class.notEmpty]="inputChange"
      [attr.data-cy]="'selectTaxAuthority'"
      [class.is-loading]="isLoading"
    >
      <input *ngIf="!isDisabled; else disabledInput" #searchInput
        placeholder="{{ 'SELECT.TAX_AUTHORITY.PLACEHOLDER' | translate }}"
        [ngClass]="{ 'form-select--error': errors, 'taxaut_input_pit' : addClassB2B }" [matAutocomplete]="taxAuthoritiesAutocomplete"
        name="taxAuthorities" [formControl]="taxAuthoritiesControl" (ngModelChange)="change($event)" (blur)="onBlur($event)" (focus)="onFocus($event)" (click)="openAutocomplete()"
        required>
      <ng-template #disabledInput>
        <input #searchInput placeholder="{{ 'SELECT.TAX_AUTHORITY.PLACEHOLDER' | translate }}"
          [ngClass]="{ 'form-select--error': errors, 'taxaut_input_pit' : addClassB2B }" [matAutocomplete]="taxAuthoritiesAutocomplete"
          name="taxAuthorities" [formControl]="taxAuthoritiesControl" (ngModelChange)="change($event)" (blur)="onBlur($event)" (focus)="onFocus($event)" (click)="openAutocomplete()"
          required disabled>
      </ng-template>
      <!-- <span class="placeholder">
        {{ 'SELECT.TAX_AUTHORITY.PLACEHOLDER' | translate }}
      </span> -->

      <div class="mat-select-arrow-wrapper" (click)="expandList($event)" [class.offsetRightArrow]="isVerification">
        <div class="mat-select-arrow"></div>
      </div>
    </div>

    <mat-autocomplete #taxAuthoritiesAutocomplete="matAutocomplete" class="statement-create__select mat-select-white" [displayWith]="displayWith">
      <mat-option *ngFor="let taxAuthority of filteredTaxAuthorities | async" [value]="taxAuthority" [attr.data-id]="taxAuthority.id">
        {{ taxAuthority.name }}
      </mat-option>
    </mat-autocomplete>

    <!-- <div class="tooltip-container top" *ngIf="displayTooltip" role="tooltip">
      <div class="tooltip-container__close">
        <span class="fa fa-times-circle" (click)="closeTooltip()"></span>
      </div>
      <div class="tooltip-container__text">{{ 'TOOLTIP.TAX_AUTHORITY' | translate }}</div>
    </div> -->

    <div class="form-select__error"
         *ngIf="errors">{{ errors[0] }}
    </div>
  </div>
</div>
