<div class="group-section-edit">
  <div class="form_header_wrap">
    <h2 class="title_30" *ngIf="overTitle">{{ overTitle | translate }}</h2>
    <h1 class="title_42" *ngIf="title">
      {{ title | translate }}
      <span class="tooltip_wrap" *ngIf="titleTooltip || messageTooltip">
        <app-more-info-tooltip
          [class]="'v-align-mid'"
          [titleTooltip]="titleTooltip"
          [message]="messageTooltip"
          [isInnerHTML]="true"
          [contentTemplateRef]="templateRef2"
        >
        </app-more-info-tooltip>
      </span>
    </h1>
    <p class="subdesc" *ngIf="description">{{ description | translate }}</p>
  </div>

  <div *ngIf="errorsNonField">
    <div class="alert-box mb-1">{{ errorsNonField[0] }}</div>
  </div>

  <ng-container *ngTemplateOutlet="templateRef"></ng-container>
</div>
