<ng-container *ngIf="currentStatement">
  <app-cards-items-list
    [isButtonAddShown]="true"
    [buttonAddTitle]="'COMMON.ADD_TAXCARD'"
    [dataList]="dataList"
    (clickButtonAddEmitter)="clickButtonAddEmitter.emit()"
    (clickButtonEditEmitter)="editSelectedEmployerEmitter.emit($event)"
    (clickButtonDeleteEmitter)="taxCardIncomeInfoService.deleteSelectedEmployer($event)"
  >
  </app-cards-items-list>
</ng-container>
