import { of as observableOf } from 'rxjs';
import { finalize, catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Statement } from '../statement';
import { StatementService } from '../statement.service';
import { SubscribingComponent } from 'app/common';
import { FinishPagePath } from 'app/core/workflow/page-path/statement-page-path/finish-page.path';

@Component({
  selector: 'app-send-with-cert',
  templateUrl: './send-with-cert.component.html',
  styleUrls: ['./send-with-cert.component.scss']
})
export class SendWithCertComponent extends SubscribingComponent implements OnInit {
  sending = false;
  @ViewChild('certField', { static: false }) scanField: ElementRef;
  @Input() statement: Statement;
  pin: string;
  info: string;
  errors = [];
  cert: File;

  constructor(private statementService: StatementService,
    private router: Router,
    private dialogRef: MatDialogRef<SendWithCertComponent>) {
    super();
  }

  ngOnInit() {
  }

  onSubmit() {
    this.clearResponseInfo();
    this.sending = true;
    this.statementService.sendStatementWithCert(this.statement.id, this.cert).pipe(
      takeUntil(this.ngUnsubscribe),
      mergeMap((response: Response) => {
        return this.statementService.sendStatementWithCert(this.statement.id, false, this.pin);
      }),
      catchError((error: any) => {
        this.errors = error.error;
        this.clearPin();
        return observableOf();
      }),
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.sending = false))
      .subscribe((response: Response) => {
        const resp = response;
        this.handleResponse(resp);
      }, (error: any) => {
        this.errors = error.error;
      });
  }

  onCertSelect() {
    if (this.scanField.nativeElement.files.length > 0) {
      this.cert = this.scanField.nativeElement.files.item(0);
    }
  }

  clearPin() {
    this.pin = '';
  }

  clearResponseInfo() {
    this.errors = [];
    this.info = '';
  }

  handleResponse(response) {
    if (response['is_sent']) {
      this.dialogRef.close();
      this.goToCongratulationsView();
    } else if (response['content']) {
      this.info = response['content'];
    }
  }

  private goToCongratulationsView() {
    this.router.navigate([FinishPagePath.fullUrl(this.statement.id)]);
  }
}
