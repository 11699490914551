<div class="income-edit">
  <div class="deductions-info" *ngIf="alertInfo">
    <div class="deductions-info__wrap">
      <img
          src="../../../../../../assets/svg/warning-icon.svg"
          class="alert_icon"
        />
      <div class="deductions-info__wrap-text">
        <p class="deductions-info__text">
          {{ alertInfo | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="income-edit__wrapper" [class.columns]="hasVat">
    <div class="income-edit__inputs" [class.column]="hasVat">
      <app-form-input [label]="inputLabel" [currencyCode]="" [placeholder]="inputPlaceholder" [isCurrency]="true"
        [(model)]="localInputValue"></app-form-input>
      <app-validator-error-message [epValidator]="epValidator" [index]="0" [fieldName]="fieldsNames.income_value">
      </app-validator-error-message>
    </div>
    <div class="income-edit__inputs" [class.column]="hasVat" *ngIf="hasVat">
      <app-form-input [label]="vatInputLabel" [currencyCode]="" [placeholder]="vatInputPlaceholder" [isCurrency]="true"
        [(model)]="localVatValue"></app-form-input>
      <app-validator-error-message [epValidator]="epValidator" [index]="0" [fieldName]="fieldsNames.vat_value">
      </app-validator-error-message>
    </div>
  </div>
  <div class="income-edit__actions">
    <!-- <app-button-radio-two [btnTextFirst]="button1" [btnTextSecond]="button2"
      (clickEmitter)="handleAddAction($event)"></app-button-radio-two> -->
    <button type="button" class="btn_cancel" (click)="handleAddAction(false)" [attr.data-cy]="'btnRadioNo'">
      <span class="txt">{{ btnTextFirst | translate }}</span>
    </button>
    <button type="button" class="btn_add" (click)="handleAddAction(true)"
      [class.active]="activeAddButton()" [attr.data-cy]="'btnRadioYes'">
      <span class="txt">{{ btnTextSecond | translate }}</span>
    </button>
  </div>
</div>