export interface CouponResponse {
  coupon_amount: number;
}

export interface CouponInterface {
  couponAmount: number;
}

export class Coupon implements CouponInterface {
  couponAmount: number;

  static fromResponse(response: CouponResponse): Coupon {
    const coupon = new Coupon();
    coupon.couponAmount = response.coupon_amount;
    return coupon;
  }

  public amount(oldPrice: string|number): number {
    const amount = Number(oldPrice) - this.couponAmount;

    return Number(oldPrice) < amount ? Number(oldPrice) : amount;
  }

  public newPrice(): number {
    return this.couponAmount;
  }

  public percent(oldPrice: string|number): number {
    return 1 - this.newPrice() / Number(oldPrice);
  }

  public percentFormatted(oldPrice: string|number): number {
    return Math.round(this.percent(oldPrice) * 100);
  }
}
