import { Component, OnInit, Input, Output, EventEmitter, Inject, LOCALE_ID } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { getUserType, State } from "../../reducers";
import { SubscribingComponent } from "../../common";

import { Statement } from "../../statements";
import { RefundDialogComponent } from "../../statements/refund-dialog/refund-dialog.component";
import { PackageOptions } from "app/statements/package-options";
import { takeUntil } from "rxjs/operators";
import { StatementService } from "app/statements/statement.service";
import { formatDate } from "@angular/common";
import { FirebaseEventsService } from "app/common/firebase-events.service";
import { UserType } from "../../users/user-type";
import { ConfirmTaxReturnComponentPath } from "app/core/workflow/page-path/statement-page-path/confirm-tax-return-component.path";
import { StatementStatuses } from "app/modules/statement/models/statement/statement.statuses";
import { DownloadFileDialogComponent } from "app/common/decision-dialog/download-file-dialog.component";
import {
  NewMessageDialogComponent,
  MESSAGE_DOCUMENT_TYPE,
  ATTACHMENT_SUBTYPE
} from "app/common/send-message-dialog/send-message-dialog.component";

@Component({
  selector: "app-card-view-item",
  templateUrl: "./card-view-item.component.html",
  styleUrls: ["./card-view-item.component.scss"]
})
export class CardViewItemComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  @Input() hideEditing = false;
  @Input() hideRemoving = false;
  @Input() hideInvoice = false;
  @Input() hideCloning = false;
  @Input() hidePdf = false;
  @Output() deleteStatement = new EventEmitter<void>();
  @Output() downloadPdf = new EventEmitter<void>();
  @Output() downloadInvoice = new EventEmitter<void>();
  @Output() showDownloadDialog = new EventEmitter<void>();
  @Output() cloneStatement = new EventEmitter<void>();
  public isTaxAdviser = false;
  public isBusiness = false;
  public userType: string;
  public steuerSendDate = "";
  public statementStatuses = StatementStatuses;
  public numberOfFilesToDownload = 0;

  public showHamburgerMenu = false;
  constructor(
    private dialog: MatDialog,
    private store: Store<State>,
    private router: Router,
    private statementService: StatementService,
    @Inject(LOCALE_ID) private locale: string,
    private firebaseEventsService: FirebaseEventsService
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(getUserType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userType: string) => {
        if (userType) {
          this.isTaxAdviser = userType === "tax_adviser";
          this.isBusiness = userType === "business";
          this.userType = userType;
        }
      });

    if (
      this.userType === UserType.individual &&
      this.statement.serviceType === PackageOptions.tax_adviser &&
      !this.statement.isSent()
    ) {
      this.hidePdf = true;
    }

    if (!this.hideInvoice) {
      this.hideInvoice = !this.statement.canDownloadInvoice;
    }

    if (
      this.userType === UserType.individual &&
      this.statement.serviceType === PackageOptions.tax_adviser &&
      this.statement.statusPaidReady()
    ) {
      const sendDate = new Date();
      if (this.statement.taxAdviserWillSendInDays) {
        this.steuerSendDate = formatDate(
          sendDate.setDate(
            new Date(this.statement.onfidoCompletedDate).getDate() +
              parseInt(this.statement.taxAdviserWillSendInDays, 10)
          ),
          "dd/MM/yyyy",
          this.locale
        );
      } else {
        this.statementService
          .getConstantTaxAdviserWillSendInDays()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            (response) => {
              this.steuerSendDate = formatDate(
                sendDate.setDate(
                  new Date(this.statement.onfidoCompletedDate).getDate() +
                    parseInt(response["tax_adviser_will_send_in_days"], 10)
                ),
                "dd/MM/yyyy",
                this.locale
              );
            },
            (error) => {
              console.log("constant does not exist");
            }
          );
      }
    }
  }

  openRefundDetailsDialog() {
    const dialogRef = this.dialog.open(RefundDialogComponent);
    dialogRef.componentInstance.statement = this.statement;
  }

  emitDownloadPdf() {
    this.downloadPdf.emit();
  }

  emitDownloadInvoice() {
    this.downloadInvoice.emit();
  }

  emitShowDownloadDialog() {
    this.showDownloadDialog.emit();
  }

  emitDeleteStatement() {
    this.firebaseEventsService.logEvent("click_delete_tax_card", null, this.statement.id);
    this.deleteStatement.emit();
  }

  editStatement() {
    if (!this.isTaxAdviser) {
      let urlPath;
      const employerId =
        this.statement.employers && this.statement.employers.length ? this.statement.employers[0].id : 0;

      if (this.statement.isStandardPackage) {
        if (this.isBusiness) {
          urlPath = this.statement.isPaid ? "thank-you" : `employer/${employerId}`;
        } else {
          urlPath = this.statement.isPaid ? "thank-you" : `b2c/tax-card`;
        }
      } else if (this.statement.isPremiumPackage) {
        if (this.statement.isForClientApproval) {
          this.router.navigate([ConfirmTaxReturnComponentPath.fullUrl(this.statement.id)]);
          return;
        }
        if (this.statement.isPaid) {
          if (
            (this.userType === "individual" && !this.statement.identifikationsnummer) ||
            (this.statement.isSpouse() && !this.statement.spouse.identifikationsnummer)
          ) {
            urlPath = "tax-advisor-additional-validation";
          } else if (this.statement.hasVerifiedIdentity === null && !this.statement.vollmachtSigned) {
            urlPath = "vollmacht-authorization";
          } else if (this.statement.hasVerifiedIdentity === null && this.statement.vollmachtSigned) {
            urlPath = "thank-you";
          }
        } else {
          if (this.isBusiness) {
            urlPath = `employer/${employerId}`;
          } else {
            urlPath = `b2c/tax-card`;
          }
        }
      }

      const url = `new/statement/${this.statement.id}/${urlPath}`;
      this.router.navigate([url]);
    } else {
      const employerId =
        this.statement.employers && this.statement.employers.length ? this.statement.employers[0].id : 0;
      let urlPath = "";
      if (this.isBusiness) {
        urlPath = this.statement.isPaid ? `employer/${employerId}` : "dashboard";
        console.log("isBusiness");
      } else {
        // uploading the tax card or supplementing the ID number
        if (
          !this.statement.identifikationsnummer ||
          (this.statement.isSpouse() && !this.statement.spouse.identifikationsnummer)
        ) {
          urlPath = "tax-advisor-additional-validation";
          console.log("tax-advisor-additional-validation");
        } else {
          urlPath = this.statement.isPaid ? `b2c/tax-card` : "dashboard";
          console.log("employerbc");
        }
      }
      const url = `new/statement/${this.statement.id}/${urlPath}`;
      this.router.navigate([url]);
    }
  }

  public getDashboardStatus() {
    return `DASHBOARD.STATUSES.${this.statement.dashboardStatus}`;
  }

  public getDashboardFinishPrecent() {
    let percent = 0;

    if (this.statement.status === "on_hold") {
      percent = 0;
    } else if (
      this.statement.status === "created" ||
      this.statement.status === "processing" ||
      this.statement.status === "updated"
    ) {
      if (this.statement.stageVerification) percent = 80;
      else if (this.statement.stageDeductionInfo) percent = 75;
      else if (this.statement.stagePackageSelection) percent = 70;
      else if (this.statement.stageChildrenInfo) percent = 65;
      else if (this.statement.stagePersonalInfo) percent = 60;
      else if (this.statement.stageAbroadIncome) percent = 50;
      else if (this.statement.stageSteuerAdditionalOption) percent = 45;
      else if (this.statement.stageAdditionalIncome) percent = 40;
      else if (this.statement.stageEmployers) percent = 30;
    } else if (this.statement.status === "ready") {
      percent = 85;
    } else if (this.statement.status === "paid") {
      percent = 90;
    } else if (this.statement.status === "paid_ready") {
      percent = 93;
    } else if (this.statement.isForClientApproval || this.statement.isReportedErrors) {
      percent = 95;
    } else if (this.statement.isApproved) {
      percent = 98;
    } else if (this.statement.status === "sent") {
      percent = 100;
    }

    return percent;
  }

  public emitCloneStatment() {
    this.firebaseEventsService.logEvent("click_clone_tax_card", null, this.statement.id);
    this.cloneStatement.emit();
  }

  public displayDownloadsButton(): boolean {
    const currentView = this.router.url.split("/").pop();

    switch (currentView) {
      case "thank-you":
        return this.statement.isPaid || this.statement.statusPaidReady();
      default:
        return (
          this.statement.isSent() ||
          this.statement.isApproved ||
          this.statement.isForClientApproval ||
          this.statement.isReportedErrors
        );
    }
  }

  public openAppealDecisionDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-new-message-dialog",
      data: {
        statementData: this.statement,
        titleMaxLength: 99,
        textMaxLength: 1500,
        messageType: MESSAGE_DOCUMENT_TYPE.APPEAL,
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.APPEAL.MODAL_TITLE",
          titleLabel: "SEND_MESSAGE_DIALOG.APPEAL.TITLE_LABEL",
          messageLabel: "SEND_MESSAGE_DIALOG.APPEAL.MESSAGE_LABEL",
          addFileButton: "SEND_MESSAGE_DIALOG.APPEAL.ADD_FILE_BUTTON",
          checkboxText: "SEND_MESSAGE_DIALOG.APPEAL.CHECKBOX_TEXT",
          titleTooltip: "SEND_MESSAGE_DIALOG.APPEAL.TOOLTIP.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.APPEAL.TOOLTIP.MESSAGE"
        }
      }
    });
  }

  public openSendNewMessageDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-new-message-dialog",
      data: {
        statementData: this.statement,
        titleMaxLength: 99,
        textMaxLength: 1500,
        messageType: MESSAGE_DOCUMENT_TYPE.MESSAGE,
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.MESSAGE.MODAL_TITLE",
          titleLabel: "SEND_MESSAGE_DIALOG.MESSAGE.TITLE_LABEL",
          messageLabel: "SEND_MESSAGE_DIALOG.MESSAGE.MESSAGE_LABEL",
          addFileButton: "SEND_MESSAGE_DIALOG.MESSAGE.ADD_FILE_BUTTON",
          titleTooltip: "SEND_MESSAGE_DIALOG.MESSAGE.TOOLTIP.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.MESSAGE.TOOLTIP.MESSAGE"
        }
      }
    });
  }

  public openSendAttachmentDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-attachment-dialog",
      data: {
        statementData: this.statement,
        messageType: MESSAGE_DOCUMENT_TYPE.ATTACHMENT,
        subtype: null,
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.ATTACHMENT.MODAL_TITLE",
          addFileButton: "SEND_MESSAGE_DIALOG.ATTACHMENT.ADD_FILE_BUTTON",
          titleTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.MESSAGE"
        }
      }
    });
  }

  public openSendEwrDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-attachment-dialog",
      data: {
        statementData: this.statement,
        messageType: MESSAGE_DOCUMENT_TYPE.ATTACHMENT,
        subtype: ATTACHMENT_SUBTYPE.EWR,
        text: {
          modalTitle: "COMMON.SEND_EWR",
          addFileButton: "SEND_MESSAGE_DIALOG.ATTACHMENT.ADD_FILE_BUTTON",
          titleTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.SEND_EWR.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.SEND_EWR.MESSAGE"
        }
      }
    });
  }

  public openSaveEwrDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-attachment-dialog",
      data: {
        statementData: this.statement,
        messageType: MESSAGE_DOCUMENT_TYPE.ATTACHMENT,
        subtype: ATTACHMENT_SUBTYPE.EWR,
        onlySave: true,
        text: {
          modalTitle: "COMMON.ATTACH_EWR",
          addFileButton: "SEND_MESSAGE_DIALOG.ATTACHMENT.ADD_FILE_BUTTON",
          titleTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.ATTACH_EWR.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.ATTACH_EWR.MESSAGE",
          onResponse: {
            successTitle: "SEND_MESSAGE_DIALOG.SUCCESS.SEND_EWR.TITLE",
            successMessage: "SEND_MESSAGE_DIALOG.SUCCESS.SEND_EWR.REQUEST"
          }
        }
      }
    });
  }

  public get shouldShowHamburgerMenu(): boolean {
    return (
      this.statement.isSent() ||
      (!this.statement.isSent() && (this.statement.hasAbroadIncome || this.statement.abroadIncome.spouseLiveAbroad))
    );
  }
}
