import { DateHelper } from "app/core/helpers/date.helper";
import { NumberFormatHelper } from "app/core/helpers/number-format.helper";
import { FreelanceJobTypes } from "app/modules/statement/models/additional-income/freelance-job.types";

export interface FreelanceJobResponse {
  id: number;
  name?: string;
  income?: string;
  costs?: string;
  income_type?: FreelanceJobTypes;
  //TAX-3030 variables
  income_data?: [];
  income_costs?: [];
  has_steuernummer?: boolean;
  has_lump_sum_costs?: boolean;
  has_business_name?: boolean;
  has_business_address?: boolean;
  has_abroad_private_address?: boolean;
  tax_authority?: number;
  business_steuernummer?: number;
  business_name?: string;
  business_owner_name?: string;
  business_street?: string;
  business_city?: string;
  business_postal_code?: string;
  business_house_number?: string;
  business_house_number_supplement?: string;
  private_street?: string;
  private_city?: string;
  private_postal_code?: string;
  private_house_number?: string;
  private_house_number_supplement?: string;
  business_start?: string;
  business_end?: string;
  total_payments?: string;
  total_withdrawals?: string;
  has_corona?: boolean;
  corona_value?: string;
  business_type?: string;
  has_sold_business_real_estate?: boolean;
}

export interface FreelanceJobErrors {
  name?: string[];
  income?: string[];
  costs?: string[];
  income_type?: string[];
  //TAX-3030 variables
  income_data?: [];
  income_costs?: [];
  has_steuernummer?: boolean;
  has_lump_sum_costs?: boolean;
  has_business_name?: boolean;
  has_business_address?: boolean;
  has_abroad_private_address?: boolean;
  tax_authority?: number;
  business_steuernummer?: number;
  business_name?: string;
  business_owner_name?: string;
  business_street?: string;
  business_city?: string;
  business_postal_code?: string;
  business_house_number?: string;
  business_house_number_supplement?: string;
  private_street?: string;
  private_city?: string;
  private_postal_code?: string;
  private_house_number?: string;
  private_house_number_supplement?: string;
  business_start?: string;
  business_end?: string;
  total_payments?: string;
  total_withdrawals?: string;
  has_corona?: boolean;
  corona_value?: string;
  business_type?: string;
  has_sold_business_real_estate?: boolean;
}

export interface FreelanceJobInterface {
  id: number;
  incomeType?: FreelanceJobTypes;
  name: string;
  income: number;
  costs?: number;
  incomeNet: number;

  //TAX-3030 variables
  income_data?: [];
  income_costs?: [];
  has_steuernummer?: boolean;
  has_lump_sum_costs?: boolean;
  has_business_name?: boolean;
  has_business_address?: boolean;
  has_abroad_private_address?: boolean;
  tax_authority?: number;
  business_steuernummer?: number;
  business_name?: string;
  business_owner_name?: string;
  business_street?: string;
  business_city?: string;
  business_postal_code?: string;
  business_house_number?: string;
  business_house_number_supplement?: string;
  private_street?: string;
  private_city?: string;
  private_postal_code?: string;
  private_house_number?: string;
  private_house_number_supplement?: string;
  business_start?: string;
  business_end?: string;
  total_payments?: string;
  total_withdrawals?: string;
  has_corona?: boolean;
  corona_value?: string;
  business_type?: string;
  has_sold_business_real_estate?: boolean;
}

export class FreelanceJob {
  id: number;
  incomeType?: FreelanceJobTypes;
  name = "";
  income? = 0.0;
  costs? = 0.0;

  //TAX-3030 variables
  income_data: any = [];
  income_costs: any = [];
  has_steuernummer: boolean = false;
  has_lump_sum_costs?: boolean = false;
  has_business_name: boolean = false;
  has_business_address: boolean = false;
  has_abroad_private_address: boolean = false;
  tax_authority: number = null;
  business_steuernummer: number = null;
  business_name: string = "";
  business_owner_name: string = "";
  business_street: string = "";
  business_city: string = "";
  business_postal_code: string = "";
  business_house_number: string = "";
  business_house_number_supplement: string = "";
  private_street: string = "";
  private_city: string = "";
  private_postal_code: string = "";
  private_house_number: string = "";
  private_house_number_supplement: string = "";
  business_start: string = "";
  business_end: string = "";
  total_payments?: string;
  total_withdrawals?: string;
  has_corona?: boolean = false;
  corona_value?: string;
  business_type?: string = "";
  has_sold_business_real_estate?: boolean = false;

  public static fromResponse(response: FreelanceJobResponse): FreelanceJob {
    const freelanceJob = new FreelanceJob();
    freelanceJob.id = response.id;
    freelanceJob.incomeType = response.income_type;
    freelanceJob.name = response.name;
    freelanceJob.income = Number(response.income) || 0.0;
    freelanceJob.costs = Number(response.costs) || 0.0;

    //TAX-3030 variables
    freelanceJob.income_data = response.income_data;
    freelanceJob.income_costs = response.income_costs;
    freelanceJob.has_steuernummer = response.has_steuernummer;
    freelanceJob.has_lump_sum_costs = response.has_lump_sum_costs;
    freelanceJob.has_business_name = response.has_business_name;
    freelanceJob.has_business_address = response.has_business_address;
    freelanceJob.has_abroad_private_address = response.has_abroad_private_address;
    freelanceJob.tax_authority = response.tax_authority;
    freelanceJob.business_steuernummer = response.business_steuernummer;
    freelanceJob.business_name = response.business_name;
    freelanceJob.business_owner_name = response.business_owner_name;
    freelanceJob.business_street = response.business_street;
    freelanceJob.business_city = response.business_city;
    freelanceJob.business_postal_code = response.business_postal_code;
    freelanceJob.business_house_number = response.business_house_number;
    freelanceJob.business_house_number_supplement = response.business_house_number_supplement;
    freelanceJob.private_street = response.private_street;
    freelanceJob.private_city = response.private_city;
    freelanceJob.private_postal_code = response.private_postal_code;
    freelanceJob.private_house_number = response.private_house_number;
    freelanceJob.private_house_number_supplement = response.private_house_number_supplement;
    freelanceJob.business_start = response.business_start;
    freelanceJob.business_end = response.business_end;
    freelanceJob.total_payments = response.total_payments;
    freelanceJob.total_withdrawals = response.total_withdrawals;
    freelanceJob.has_corona = response.has_corona;
    freelanceJob.corona_value = response.corona_value;
    freelanceJob.business_type = response.business_type;
    freelanceJob.has_sold_business_real_estate = response.has_sold_business_real_estate;

    return freelanceJob;
  }

  public static fromStorage(response: FreelanceJob): FreelanceJob {
    const freelanceJob = new FreelanceJob();
    freelanceJob.id = response.id;
    freelanceJob.incomeType = response.incomeType;
    freelanceJob.name = response.name;
    freelanceJob.income = Number(response.income) || 0.0;
    freelanceJob.costs = Number(response.costs) || 0.0;

    //TAX-3030 variables
    freelanceJob.income_data = response.income_data;
    freelanceJob.income_costs = response.income_costs;
    freelanceJob.has_steuernummer = response.has_steuernummer;
    freelanceJob.has_lump_sum_costs = response.has_lump_sum_costs;
    freelanceJob.has_business_name = response.has_business_name;
    freelanceJob.has_business_address = response.has_business_address;
    freelanceJob.has_abroad_private_address = response.has_abroad_private_address;
    freelanceJob.tax_authority = response.tax_authority;
    freelanceJob.business_steuernummer = response.business_steuernummer;
    freelanceJob.business_name = response.business_name;
    freelanceJob.business_owner_name = response.business_owner_name;
    freelanceJob.business_street = response.business_street;
    freelanceJob.business_city = response.business_city;
    freelanceJob.business_postal_code = response.business_postal_code;
    freelanceJob.business_house_number = response.business_house_number;
    freelanceJob.business_house_number_supplement = response.business_house_number_supplement;
    freelanceJob.private_street = response.private_street;
    freelanceJob.private_city = response.private_city;
    freelanceJob.private_postal_code = response.private_postal_code;
    freelanceJob.private_house_number = response.private_house_number;
    freelanceJob.private_house_number_supplement = response.private_house_number_supplement;
    freelanceJob.business_start = response.business_start;
    freelanceJob.business_end = response.business_end;
    freelanceJob.total_payments = response.total_payments;
    freelanceJob.total_withdrawals = response.total_payments;
    freelanceJob.has_corona = response.has_corona;
    (freelanceJob.corona_value = response.corona_value), (freelanceJob.business_type = response.business_type);
    freelanceJob.has_sold_business_real_estate = response.has_sold_business_real_estate;

    return freelanceJob;
  }

  constructor(incomeType?: FreelanceJobTypes) {
    if (incomeType) {
      this.incomeType = incomeType;
    }
  }

  public get incomeNet(): string {
    return NumberFormatHelper.toFixed2(this.income - this.costs);
  }

  public payload(defaultValue = undefined): FreelanceJobResponse {
    switch (this.incomeType) {
      case FreelanceJobTypes.VOLUNTERING_INCOME:
        return {
          id: this.id || undefined,
          name: this.name || defaultValue,
          income: NumberFormatHelper.toFixed2(this.income)
        };
      default:
        return {
          id: this.id || undefined,
          // name: this.name || defaultValue,
          // income: NumberFormatHelper.toFixed2(this.income),
          // costs: NumberFormatHelper.toFixed2(this.costs),
          income_type: this.incomeType || defaultValue,
          //TAX-3030 variables
          income_data: this.income_data,
          income_costs: this.income_costs,
          has_steuernummer: this.has_steuernummer,
          has_business_name: this.has_business_name,
          has_business_address: this.has_business_address,
          has_abroad_private_address: this.has_abroad_private_address,
          tax_authority: this.tax_authority,
          business_steuernummer: this.business_steuernummer,
          business_name: this.business_name,
          business_owner_name: this.business_owner_name,
          business_street: this.business_street,
          business_city: this.business_city,
          business_postal_code: this.business_postal_code,
          business_house_number: this.business_house_number,
          business_house_number_supplement: this.business_house_number_supplement,
          private_street: this.private_street,
          private_city: this.private_city,
          private_postal_code: this.private_postal_code,
          private_house_number: this.private_house_number,
          private_house_number_supplement: this.private_house_number_supplement,
          business_start: DateHelper.getFormatted(this.business_start, "YYYY-MM-DD"),
          business_end: DateHelper.getFormatted(this.business_end, "YYYY-MM-DD"),
          total_payments: this.total_payments,
          total_withdrawals: this.total_withdrawals,
          has_corona: this.has_corona,
          corona_value: this.corona_value,
          business_type: this.business_type,
          has_sold_business_real_estate: this.has_sold_business_real_estate
        };
    }
  }
}
