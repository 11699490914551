<div class="step-container columns is-desktop">
    <div class="sending-placeholder" *ngIf=isPlaceholderVisible>
        <div class="sending-placeholder__title">{{ 'COMMON.SENDING_PLACEHOLDER' | translate }}</div>
    </div>
    <app-aside-bar [isBusiness]="true"></app-aside-bar>
    <div class="thank-you">
        <h1 class="title">{{ 'THANK_YOU.TITLE_BUSINESS' | translate }}</h1>
        <div class="description">{{ 'THANK_YOU.DESCRIPTION_BUSINESS' | translate }}</div>
        <div class="thank-you__actions">
<!--            <div class="thank-you__actions-description">-->
<!--                <div class="thank-you__actions-description-stage">{{ 'THANK_YOU.ACTION_TEXT_FIRST' | translate }}</div>-->
<!--                <div class="thank-you__actions-description-stage">{{ 'THANK_YOU.ACTION_TEXT_SECOND' | translate }}</div>-->
<!--            </div>-->
            <div class="thank-you__actions-buttons p-0">
                <div class="thank-you__actions-buttons-stage" *ngIf="isMasterOrSubaccount()">
                    <button
                      class="btn btn--primary btn--block"
                      (click)="sendStatement()"
                      [disabled]="isSending || isButtonDisabled()"
                    >
                      {{'THANK_YOU.SEND_MASTER_OR_SUBACCOUNT' | translate}}
                    </button>
                </div>
                <div class="thank-you__actions-buttons-stage" *ngIf="!isMasterOrSubaccount()">
                    <button
                      class="btn btn--primary btn--block mb-0_5"
                      (click)="sendWithCommonCert()"
                      [disabled]="isSending || isButtonDisabled()"
                    >
                      {{ 'THANK_YOU.SEND_WITH_COMMON_CERT' | translate }}
                    </button>
                    <app-more-info-tooltip [message]="'TOOLTIP.SEND_WITH_CERT_BUSINESS'"></app-more-info-tooltip>
                </div>
            </div>
        </div>
        <div class="thank-you__summary" *ngIf="statements && statements.length">
            <div class="thank-you__batch">
                <div class="client-summary__batch-text">{{ 'COMMON.BATCH_FILE' | translate }}</div>
                <div class="client-summary__batch-content">
                    <app-accordion [title]="batchCode" [titleFrame]="true" [transparentBg]="true"
                                   [noPadding]="true">
                        <app-list-view-b2b
                                [statements]="statements"
                                [hideInvoice]="true"
                                [hideEditing]="true"
                                [hideRemoving]="true"
                                [hideDecision]="true"
                        ></app-list-view-b2b>
                    </app-accordion>
                </div>
            </div>
        </div>
    </div>
</div>
