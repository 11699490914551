import { NgModule } from "@angular/core";
import { sharedModuleDeclarations } from "app/shared/shared.module.declarations";
import { sharedModuleImports } from "app/shared/shared.module.imports";
import { TranslateModule } from "@ngx-translate/core";
import { LottieModule } from "ngx-lottie";
import { MaterialModule } from "app/shared/modules/material/material.module";
import { sharedModuleProviders } from "app/shared/shared.module.providers";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";
import { RatingModule } from "app/shared/modules/rating/rating.module";

@NgModule({
  imports: [...sharedModuleImports, NgxMaskDirective],
  declarations: [...sharedModuleDeclarations],
  providers: [...sharedModuleProviders, provideNgxMask({ validation: false })],
  exports: [
    ...sharedModuleDeclarations,
    ...sharedModuleImports,
    NgxMaskDirective,
    TranslateModule,
    LottieModule,
    MaterialModule,
    RatingModule
  ]
})
export class SharedModule {}
