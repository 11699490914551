<app-ctr-base
  [templateRef]="pensionIncome"
  [title]="title"
  [categoryProperties]="categoryProperties"
  [dataSelects]="dataSelects"
  [summary]="summary"
></app-ctr-base>

<ng-template #pensionIncome>
    <div class="card card-white shadow-0 mb-2" id="pensionIncomeTab">
        <div class="card__row mb-1">
            <div class="table">
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'CONFIRM_TAX_RETURN.PENSION_INCOME.TOTAL_AMOUNT' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ refundDetails[person].pensionTotalAmount | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="arrayHelper.getSum(statement.pensionIncome, 'pensionAdjustment') as adjustment">
                    <div class="table__col">
                        <div class="row__title">{{ 'CONFIRM_TAX_RETURN.PENSION_INCOME.ADJUSTMENT_AMOUNT' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ adjustment | number: '1.2-2' }} €"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
