<ng-container *ngIf="!contentLoaded">
    <app-content-loader></app-content-loader>
</ng-container>

<ng-container *ngIf="contentLoaded">
    <div class="step-container columns is-desktop">
        <app-aside-bar
          [showEvenWithoutStatement]="false"
          [statement]="statement"
          [isBusiness]="isBusiness"
          [workFlowController]="workFlowController"
        >
        </app-aside-bar>
        <div class="tax-advisor-additional-validation">
            <div class="tax-advisor-additional-validation__header">
                <h1 class="title_42">
                    {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.TITLE' | translate }}
                </h1>
                <p class="subdesc">
                    {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.SUBTITLE' | translate }}
                </p>
            </div>

<!--            form for the main applicant -->
<!--            visible when there is no identification number in the statement -->
            <ng-container *ngIf="isApplicant()">
                <div class="tax-advisor-additional-validation__content">
                    <h2 class="title_24">
                        {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.OPTIONS_TITLE' | translate }}
                        &nbsp;
                        <app-more-info-tooltip
                                [class]="'v-align-mid'"
                                [message]="'TAXADVISOR_ADDITIONAL_VALIDATION.TOOLTIP_DESC'"
                        >
                        </app-more-info-tooltip>
                    </h2>
                    <mat-radio-group class="tax-advisor-additional-validation__options"
                                     [formControl]="forms.applicant.get('method')">
<!--                        "I have ‘Identifikationsnummer’ with me" -->
                        <mat-radio-button value="{{ methodOptions.ENTER_OPTION }}" class="tax-advisor-additional-validation__option">
                            <span class="desc">
                                {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.OPTION_1_TITLE' | translate }}
                            </span>
                        </mat-radio-button>

                        <div class="option_check_wrap" [hidden]="forms.applicant.get('method').value !== methodOptions.ENTER_OPTION">
                            <app-form-input [disableLabel]="true"
                                            (modelChange)="forms.applicant.get('identificationNumber').patchValue($event)"
                                            [placeholder]="'TAXADVISOR_ADDITIONAL_VALIDATION.INPUT_PLACEHOLDER'"
                                            [errors]="forms.applicant.get('identificationNumber').errors">
                            </app-form-input>
                        </div>

<!--                        "I can upload a tax card for reference" -->
                        <mat-radio-button value="{{ methodOptions.UPLOAD_OPTION }}" class="tax-advisor-additional-validation__option" *ngIf="isApplicantHasIncomes()">
                            <span class="desc">
                                {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.OPTION_2_TITLE' | translate }}
                            </span>
                        </mat-radio-button>

                        <div class="option_check_wrap" [hidden]="forms.applicant.get('method').value !== methodOptions.UPLOAD_OPTION" *ngIf="isApplicantHasIncomes()">
                            <div class="upload_taxcard_wrap"
                                 [class.error]="forms.applicant.get('cartIdFile').errors"
                            >
                                <label>
                                    <input type="file"
                                           accept="application/pdf"
                                           [disabled]="uploadingScan"
                                           (change)="onPDFUpload($event)">
                                    <span class="file_desc">
                                        <img src="../../../assets/svg/upload_pdf.svg" alt="Taxcard pdf">
                                        <span class="desc">
                                            {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.TAXCARD_DESC' | translate }}
                                        </span>
                                    </span>
                                </label>
                                <div class="file_uploaded_desc" *ngIf="!forms.applicant.get('cartIdFile').errors && forms.applicant.get('cartIdFile').value as file">
                                    {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.UPLOADED' | translate }}
                                </div>
                                <div class="file_uploaded_desc" *ngIf="forms.applicant.get('cartIdFile').getError('error')">
                                    {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.ERROR' | translate }}
                                </div>
                                <div class="file_uploaded_desc" *ngIf="forms.applicant.get('cartIdFile').getError('errorText') as errorText">
                                    {{ errorText }}
                                </div>
                            </div>
                        </div>

                        <mat-radio-button
                          value="{{ methodOptions.SKIP_OPTION }}"
                          class="tax-advisor-additional-validation__option"
                          [attr.data-cy]="'radioIdNumberSkipApplicant'"
                        >
                            <span class="desc">
                                {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.OPTION_3_TITLE' | translate }}
                            </span>
                        </mat-radio-button>

                    </mat-radio-group>
                </div>
            </ng-container>

<!--            form for spouse -->
            <ng-container *ngIf="isSpouse()">

                <div class="tax-advisor-additional-validation__content">

                    <h2 class="title_24">
                        {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.OPTIONS_TITLE_SPOUSE' | translate }}
                        &nbsp;
                        <app-more-info-tooltip
                                [class]="'v-align-mid'"
                                [message]="'TAXADVISOR_ADDITIONAL_VALIDATION.TOOLTIP_DESC_SPOUSE'"
                        >
                        </app-more-info-tooltip>
                    </h2>

                    <mat-radio-group class="tax-advisor-additional-validation__options" [formControl]="forms.spouse.get('method')">
                        <mat-radio-button value="{{ methodOptions.ENTER_OPTION }}" class="tax-advisor-additional-validation__option">
                            <span class="desc">
                                {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.OPTION_1_TITLE_SPOUSE' | translate }}
                            </span>
                        </mat-radio-button>

                        <div class="option_check_wrap" [hidden]="forms.spouse.get('method').value !== methodOptions.ENTER_OPTION">
                            <app-form-input [disableLabel]="true" (modelChange)="forms.spouse.get('identificationNumber').patchValue($event)"
                                            [errors]="forms.spouse.get('identificationNumber').errors"
                                            [placeholder]="'TAXADVISOR_ADDITIONAL_VALIDATION.INPUT_PLACEHOLDER_SPOUSE'"
                            >
                            </app-form-input>
                        </div>

                        <mat-radio-button value="{{ methodOptions.UPLOAD_OPTION }}" class="tax-advisor-additional-validation__option" *ngIf="isSpouseHasIncomes()">
                            <span class="desc">
                                {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.OPTION_2_TITLE_SPOUSE' | translate }}
                            </span>
                        </mat-radio-button>

                        <div class="option_check_wrap" [hidden]="forms.spouse.get('method').value !== methodOptions.UPLOAD_OPTION" *ngIf="isSpouseHasIncomes()">
                            <div class="upload_taxcard_wrap">
                                <label
                                    [class.error]="forms.spouse.get('cartIdFile').errors"
                                >
                                    <input
                                            type="file"
                                            accept="application/pdf"
                                            (change)="onPDFUpload($event, true)"
                                            [disabled]="uploadingScan"
                                    >
                                    <span class="file_desc">
                                        <img src="../../../assets/svg/upload_pdf.svg" alt="Taxcard pdf">
                                        <span class="desc">
                                            {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.TAXCARD_DESC_SPOUSE' | translate }}
                                        </span>
                                    </span>
                                </label>
                                <div class="file_uploaded_desc" *ngIf="!forms.spouse.get('cartIdFile').errors && forms.spouse.get('cartIdFile').value as file">
                                    {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.UPLOADED' | translate }}
                                </div>
                                <div class="file_uploaded_desc" *ngIf="forms.spouse.get('cartIdFile').getError('error')">
                                    {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.ERROR' | translate }}
                                </div>
                                <div class="file_uploaded_desc" *ngIf="forms.spouse.get('cartIdFile').getError('errorText') as errorText">
                                    {{ errorText }}
                                </div>
                            </div>
                        </div>

                        <mat-radio-button
                          value="{{ methodOptions.SKIP_OPTION }}"
                          class="tax-advisor-additional-validation__option"
                          [attr.data-cy]="'radioIdNumberSkipSpouse'"
                        >
                            <span class="desc">
                                {{ 'TAXADVISOR_ADDITIONAL_VALIDATION.OPTION_3_TITLE_SPOUSE' | translate }}
                            </span>
                        </mat-radio-button>

                    </mat-radio-group>
                </div>
            </ng-container>

        </div>
        <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
    </div>
</ng-container>
