import { DateHelper } from 'app/core/helpers/date.helper';

export interface DateRangeResponse {
  lower?: string;
  upper?: string;
}

export interface DateRangeErrors {
  lower?: string[];
  upper?: string[];
}

export class DateRange {
  lower?: string;
  upper?: string;

  static fromResponse(response: DateRangeResponse): DateRange {
    const datesRange = new DateRange();
    datesRange.lower = response && response.lower ? response.lower : null;
    datesRange.upper = response && response.upper ? response.upper : null;
    return datesRange;
  }

  toResponse(defaultValue = undefined): DateRangeResponse {
    if (!this.lower && !this.upper) {
      return null;
    } else {
      return {
        lower: this.lower ? toResponseDate(this.lower) : defaultValue,
        upper: this.upper ? toResponseDate(this.upper) : defaultValue
      };
    }
  }
}

export function toResponseDate(date : any) {
  return DateHelper.getFormatted(date, 'YYYY-MM-DD');
}
