<button class="btn_simple green" (click)="showFilters()">{{ 'DASHBOARD.SHOW_FILTERS' | translate }}</button>
<div class="sort" [ngClass]="{ 'sort--opened': isOpened }">
    <ng-container *ngIf="isBusiness">
        <div class="sort__title">{{ 'FILTER.BY' | translate}}<span class="sort__close" (click)="close()"><i>+</i></span>
        </div>
        <div class="sort__list">
            <mat-form-field>
                <input matInput placeholder="{{ 'FILTER.ID' | translate }}" [(ngModel)]="searchId">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="{{ 'FILTER.IDENT_NUMBER' | translate}}" [(ngModel)]="nrIdent">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="{{ 'FILTER.ST_NUMBER' | translate }}" [(ngModel)]="stNumber">
            </mat-form-field>
            <ng-container *ngIf="taxAuthorities">
                <mat-form-field>
                    <input type="text" placeholder="{{ 'FILTER.OFFICE' | translate}}" aria-label="Number" matInput
                        [(ngModel)]="fOffice" [matAutocomplete]="auto" (keyup)="filterOffice()">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let taxAuthoritie of taxAuthorities" [value]="taxAuthoritie.name"
                            (onSelectionChange)="selectOffice(taxAuthoritie)">
                            {{taxAuthoritie.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
            <mat-form-field>
                <input matInput [(ngModel)]="birthDate" maxlength="10" />
              <mat-placeholder>{{ 'FILTER.BIRTHDATE' | translate}}</mat-placeholder>
            </mat-form-field>
            <mat-form-field *ngIf="labelList.length>0">
                <mat-label>{{ 'FILTER.LABEL' | translate}}</mat-label>
                <mat-select [(ngModel)]="label">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let label of labelList" [value]="label"> {{label.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="filter_button_wraper">
                <button class="btn btn--info btn--small" (click)="startFilter()" [disabled]="filterButtonDisabled">Filtruj</button>
            </div>
        </div>
    </ng-container>

    <div class="sort__title">{{ 'SORT.BY' | translate }} <span class="sort__close" (click)="close()"><i>+</i></span>
    </div>
    <div class="sort__list">
        <mat-radio-group name="sort_type" [(ngModel)]="sortType" (ngModelChange)="sort()">
            <div class="sort__stage" *ngIf="!isBusiness">
                <div class="sort__stage-title">{{ 'INPUT.FIRST_NAME.LABEL' | translate }}</div>
                <div class="sort__stage-options">
                    <mat-radio-button [value]="'firstNameInc'">
                        <span>({{ 'SORT.AZ' | translate }})</span>
                    </mat-radio-button>
                    <mat-radio-button [value]="'firstNameDec'">
                        <span>({{ 'SORT.ZA' | translate }})</span>
                    </mat-radio-button>
                </div>
            </div>
            <div class="sort__stage" *ngIf="!isBusiness">
                <div class="sort__stage-title">{{ 'INPUT.LAST_NAME.LABEL' | translate }}</div>
                <div class="sort__stage-options">
                    <mat-radio-button [value]="'lastNameInc'">
                        <span>({{ 'SORT.AZ' | translate }})</span>
                    </mat-radio-button>
                    <mat-radio-button [value]="'lastNameDec'">
                        <span>({{ 'SORT.ZA' | translate }})</span>
                    </mat-radio-button>
                </div>
            </div>
            <div class="sort__stage" *ngIf="!isBusiness">
                <div class="sort__stage-title">{{ 'LIST.TAX_YEAR' | translate }}</div>
                <div class="sort__stage-options">
                    <mat-radio-button [value]="'statementYearDec'">
                        <span>({{ 'SORT.NEWEST_YEAR' | translate }})</span>
                    </mat-radio-button>
                    <mat-radio-button [value]="'statementYearInc'">
                        <span>({{ 'SORT.OLDEST_YEAR' | translate }})</span>
                    </mat-radio-button>
                </div>
            </div>
            <div class="sort__stage">
                <div class="sort__stage-title">{{ 'PAYMENT.CREATION_DATE' | translate }}</div>
                <div class="sort__stage-options">
                    <mat-radio-button [value]="'creationDateDec'">
                        <span>({{ 'SORT.NEWEST_DATE' | translate }})</span>
                    </mat-radio-button>
                    <mat-radio-button [value]="'creationDateInc'">
                        <span>({{ 'SORT.OLDEST_DATE' | translate }})</span>
                    </mat-radio-button>
                </div>
            </div>
            <div class="sort__stage" *ngIf="!isBusiness">
                <div class="sort__stage-title">{{ 'INPUT.IDENTIFIKATIONSNUMMER.LABEL' | translate }}</div>
                <div class="sort__stage-options">
                    <mat-radio-button [value]="'identifikationsnummerInc'">
                        <span>({{ 'SORT.SMALLEST' | translate }})</span>
                    </mat-radio-button>
                    <mat-radio-button [value]="'identifikationsnummerDec'">
                        <span>({{ 'SORT.BIGGEST' | translate }})</span>
                    </mat-radio-button>
                </div>
            </div>
        </mat-radio-group>
    </div>
</div>
