<app-notification-alert-box
  [notificationMessage]="notificationMessage"
  [buttonStandardTitle]="'NOTIFICATION.RIESTER.REMOVE'"
  [buttonPrimaryTitle]="'NOTIFICATION.RIESTER.CORRECT'"
  [buttonPrimaryExtraClasses]="buttonPrimaryExtraClasses"
  [buttonStandardExtraClasses]="buttonStandardExtraClasses"
  [buttonStandardIsLoading]="buttonStandardIsLoading"
  (buttonStandardClickEmitter)="buttonStandardClickEmitter.emit()"
  (buttonPrimaryClickEmitter)="buttonPrimaryClickEmitter.emit()"
>
</app-notification-alert-box>
