export const AttachmentType: { [ key: string ]: { i18n: string, id: string } } = {
  BELEGE_FOR_FINANZAMT : {
    id: 'belege_for_finanzamt',
    i18n: 'BELEGE_FOR_FINANZAMT'
  },

  VOLLMACHT_FOR_TAX_ADVISOR : {
    id : 'vollmacht_for_tax_advisor',
    i18n : 'VOLLMACHT_FOR_TAX_ADVISOR'
  }

	// FOREIGN_INCOME_CERTIFICATE: {
	// 	i18n: "FOREIGN_INCOME_CERTIFICATE",
	// 	id: "foreign_income_certificate"
	// },
	//
	// FOREIGN_EMPLOYER_DOC: {
	// 	i18n: "FOREIGN_EMPLOYER_DOC",
	// 	id: "foreign_employer_doc"
	// },
	//
	// POWER_OF_ATTORNEY: {
	// 	i18n: "POWER_OF_ATTORNEY",
	// 	id: "power_of_attorney"
	// }
}
