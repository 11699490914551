import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { AdditionalIncomeComponentPath } from 'app/core/workflow/page-path/statement-page-path/additional-income-component.path';
import { B2bAdditionalIncomeComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.additional-income-component.path';

export class WorkflowAdditionalIncomeService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.statement && this.statement.stageAdditionalIncome;
  }

  public get isVisible(): boolean {
    return true;
  }

  public get isFakeStep(): boolean {
    return !this.isBusiness;
  }

  public get title(): string {
    return 'ASIDE_BAR.INCOME_INFO';
  }

  public get isRequired(): boolean {
    return this.isBusiness;
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.statement
      && this.isStageEmployerDone;
  }

  public get isActiveOnStepsBar(): boolean {
    const locationHref = window.location.href;

    return locationHref.search(AdditionalIncomeComponentPath.regex()) !== -1
      || locationHref.search(B2bAdditionalIncomeComponentPath.regex()) !== -1;
  }

  public get isActive(): boolean {
    if (this.isBusiness) {
      return this.defaultIsActiveFunction;
    }

    return false;
  }

  public get urlRegex(): RegExp {
    if (this.isBusiness) {
      return B2bAdditionalIncomeComponentPath.regex();
    }

    return AdditionalIncomeComponentPath.regex();
  }

  public get url(): string {
    if (this.isBusiness) {
      return B2bAdditionalIncomeComponentPath.url(this.getStatementId);
    }

    return AdditionalIncomeComponentPath.url(this.getStatementId, 1);
  }

  public get stepClass(): string {
    if (this.isBusiness) {
      return '';
    }

    return this.classHasSubs;
  }
}
