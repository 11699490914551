import { NgModule } from '@angular/core';
import { FirebaseAnalyticsService } from 'app/shared/modules/firebase/services/firebase-analytics.service';
import { FirebaseService } from 'app/shared/modules/firebase/services/firebase.service';

@NgModule({
  providers: [
    FirebaseService,
    FirebaseAnalyticsService,
  ],
})
export class FirebaseModule {
}
