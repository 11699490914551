import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { PaymentCheckoutComponentPath } from 'app/core/workflow/page-path/statement-page-path/payment-checkout-component.path';

export class WorkflowPaymentCheckoutService extends WorkflowStepsBaseService {
  public get isRequired(): boolean {
    return (!this.isBusiness || !(this.statement && this.statement.batchId)) && !this.isTaxAdviser;
  }

  public get isDone(): boolean {
    return this.statement && this.statement.isPaid;
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.isRequired && this.statement && this.statement.stageVerification
      && this.statement.isReady();
  }

  public get urlRegex(): RegExp {
    return PaymentCheckoutComponentPath.regex();
  }

  public get url(): string {
    return PaymentCheckoutComponentPath.url(this.getStatementId);
  }
}
