import * as loader from './actions/loader';

export interface State {
  viewSpinner: boolean;
}

export const initialState: State = {
  viewSpinner: false
};

export function reducer(state = initialState, action: loader.Actions): State {
  switch (action.type) {
    case loader.SHOW_VIEW_SPINNER: {
      return {viewSpinner: true};
    }

    case loader.HIDE_VIEW_SPINNER: {
      return {viewSpinner: false};
    }

    default: {
      return state;
    }
  }
}

export const getViewSpinner = (state: State) => state.viewSpinner;
