import { Statement } from 'app/statements';
import { Pipe, PipeTransform } from '@angular/core';
import { StatementStatuses } from 'app/modules/statement/models/statement/statement.statuses';

@Pipe({ name: 'statementProgressPipe' })
export class StatementProgressPipe implements PipeTransform {
  transform(statement: Statement): number {
    return this.calculateB2C(statement);
  }

  private calculateB2C(statement: Statement): number {
    if (statement.status === StatementStatuses.ON_HOLD) {
      return  0;
    }

    if (statement.isSent()) {
      return 100;
    }
    if (statement.isApproved) {
      return 96;
    }
    if (statement.isForClientApproval || statement.isReportedErrors) {
      return 93;
    }
    if (statement.hasVerifiedIdentity) {
      return 90;
    }
    if (statement.vollmachtSigned) {
      return 87;
    }
    if (statement.status === StatementStatuses.PAID_READY) {
      return 85;
    }
    if (statement.status === StatementStatuses.PAID) {
      return 82;
    }
    if (statement.isReady()) {
      return 79;
    }

    if (statement.isCreated() || statement.isProcessing() || statement.isUpdated()) {
      if (statement.stageVerification) {
        return 77;
      }
      if ((statement.stageDeductionInfo || statement.stageTaxReliefs) && statement.iban) {
        return 75;
      }
      if (statement.spouse && statement.filedTogether && (statement.spouse.stageDeductionInfo || statement.spouse.hasDeductions)) {
        return 72;
      }
      if (statement.stageDeductionInfo || statement.stageTaxReliefs) {
        if (statement.hasDeductions) {
          return 69;
        }
        return 68;
      }
      if (statement.stageDeductionInfoChildren) {
        if (statement.hasChildrenDeductions) {
          return 66;
        }
        return 65;
      }
      if (statement.stagePackageSelection) {
        if (statement.hasVerifiedPhoneNumber) {
          return 62;
        }
        return 61;
      }
      if (statement.stageChildrenInfo) {
        if (statement.children.length) {
          return 59;
        }
        return 58;
      }
      if (statement.spouse && statement.filedTogether) {
        if (statement.spouse.additionalIncome) {
          if (statement.spouse.freelanceJobStep) {
            return 56;
          }
          if (statement.spouse.pensionsStep) {
            return 55;
          }
          if (statement.spouse.healthBenefitsStep) {
            return 54;
          }
          return 53;
        }
        if (statement.spouse.stageEmployers) {
          if (statement.spouse.hasEmployers) {
            return 52;
          }
          return 51;
        }
        if (statement.spouse.stagePersonalInfo) {
          return 50;
        }
      }
      if (statement.stagePersonalInfo) {
        return 47;
      }
      if (statement.stageAbroadIncome) {
        return 44;
      }
      if (statement.stageProfile) {
        return 41;
      }
      if (statement.stageSteuerAdditionalOption) {
        return 38;
      }
      if (statement.stageAdditionalIncome) {
        if (statement.freelanceJobStep) {
          return 35;
        }
        if (statement.pensionsStep) {
          return 32;
        }
        if (statement.healthBenefitsStep) {
          return 29;
        }
        return 26;
      }
      if (statement.hasEmployers) {
        return 23;
      }
      if (statement.stageEmployers) {
        return 20;
      }
    }

    return 0;
  }
}
