<app-group-section-base
  [templateRef]="additionalIncomeGroupFreelance"
  [isActive]="isMainStepActive"
  [statementFullYear]="additionalIncomeService.mainStatement?.fullYear"
  [selectedCounter]="selectedCounter"
  [selectButtons]="selectButtons"
  [title]="groupTitle"
  [description]="description"
  [titleTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.TOOLTIP.TITLE' | translate"
  [messageTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.TOOLTIP.MESSAGE' | translate"
  [confirmButtonText]="'ADDITIONAL_INCOME.FREELANCE_GROUP.TOOLTIP.CONFIRM_SECTION.BUTTON' | translate"
  [confirmButtonTitle]="'ADDITIONAL_INCOME.FREELANCE_GROUP.TOOLTIP.CONFIRM_SECTION.TOP_TEXT' | translate"
  [confirmButtonBottomText]="'ADDITIONAL_INCOME.FREELANCE_GROUP.TOOLTIP.CONFIRM_SECTION.BOTTOM_TEXT' | translate"
  [tooltipAutoShow]="false"
>
</app-group-section-base>

<ng-template #additionalIncomeGroupFreelance>
  <ng-container *ngIf="subSteps">
    <ng-container *ngIf="isSubStepActive(freelanceJobSteps.FREELANCE)">
      <app-group-section-edit-single-base
        [templateRef]="freelance"
        [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.FREELANCE.TITLE'"
        [description]=""
        [titleTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.FREELANCE_TOOLTIP.TOOLTIPT_TITLE'"
        [messageTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.FREELANCE_TOOLTIP.TOOLTIPT_TEXT'"
      >
      </app-group-section-edit-single-base>
    </ng-container>
    <ng-container *ngIf="isSubStepActive(freelanceJobSteps.GEWERBE)">
      <app-group-section-edit-single-base
        [templateRef]="gewerbe"
        [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.GEWERBE.TITLE'"
        [description]=""
        [titleTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.GEWERBE_TOOLTIP.TOOLTIPT_TITLE'"
        [messageTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.GEWERBE_TOOLTIP.TOOLTIPT_TEXT'"
      >
      </app-group-section-edit-single-base>
    </ng-container>
    <ng-container *ngIf="isSubStepActive(freelanceJobSteps.VOLUNTERING_INCOME)">
      <app-group-section-edit-single-base
        [templateRef]="VOLUNTERING_INCOME"
        [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.VOLUNTERING_INCOME.TITLE'"
        [description]=""
        [titleTooltip]="'ADDITIONAL_INCOME.FREELANCE_GROUP.VOLUNTERING_INCOME.TOOLTIP.TITLE'"
        [templateRef2]="volunteringIncomeTooltipRef"
      >
      </app-group-section-edit-single-base>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #freelance>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.FREELANCE_GROUP.FREELANCE.TITLE'"
    [formTemplateRef]="inputs"
    [list]="freelanceJobManager.getFreelance(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      freelanceJobManager.getFreelance(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.freelanceIncomeLimit
    "
    (removeButtonClickEmitter)="freelanceJobManager.removeFreelance(additionalIncomeService.currentStatement, $event)"
    (addButtonClickEmitter)="freelanceJobManager.createFreelance(additionalIncomeService.currentStatement)"
    [addButtonTitle]="'ADDITIONAL_INCOME.FREELANCE_GROUP.FREELANCE.ADD_BUTTON_TEXT'"
  ></app-form-list>
</ng-template>

<ng-template #gewerbe>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.FREELANCE_GROUP.GEWERBE.TITLE'"
    [formTemplateRef]="inputs"
    [list]="freelanceJobManager.getGewerbe(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      freelanceJobManager.getGewerbe(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.freelanceIncomeLimit
    "
    (removeButtonClickEmitter)="freelanceJobManager.removeGewerbe(additionalIncomeService.currentStatement, $event)"
    (addButtonClickEmitter)="freelanceJobManager.createGewerbe(additionalIncomeService.currentStatement)"
    [addButtonTitle]="'ADDITIONAL_INCOME.FREELANCE_GROUP.GEWERBE.ADD_BUTTON_TEXT'"
  ></app-form-list>
</ng-template>

<ng-template #VOLUNTERING_INCOME>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.FREELANCE_GROUP.VOLUNTERING_INCOME.TITLE'"
    [formTemplateRef]="inputs"
    [list]="freelanceJobManager.getVolunteringIncome(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      freelanceJobManager.getVolunteringIncome(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.freelanceIncomeLimit
    "
    (removeButtonClickEmitter)="freelanceJobManager.removeVoluntering(additionalIncomeService.currentStatement, $event)"
    (addButtonClickEmitter)="freelanceJobManager.createVolunteringIncome(additionalIncomeService.currentStatement)"
  ></app-form-list>
</ng-template>

<ng-template #inputs let-item="item" let-index="index">
  <app-additional-income-form-freelance
    [freelanceJobIncomeType]="item.incomeType"
    [freelanceJob]="item"
    [index]="index"
  ></app-additional-income-form-freelance>
</ng-template>

<!-- TOOLTIPS TEMPLATES -->
<ng-template #volunteringIncomeTooltipRef>
  <app-tooltip-voluntering-income></app-tooltip-voluntering-income>
</ng-template>
