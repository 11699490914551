declare const window;

export class ScriptHelper {
  public static loadStripe(): void {
    this.load({id: 'stripe-script', src: 'https://js.stripe.com/v3/'});
  }

  public static load(options: {
    id: string,
    src: string,
    type?: string,
    defer?: boolean,
    onload?: (e) => any,
    onerror?: (e) => any,
  }): boolean {
    const id = options.id,
      src = options.src,
      type = options.type || 'text/javascript',
      onload = options.onload || (() => {}),
      onerror = options.onerror || (() => {}),
      defer = options.defer;

    if (window.document.getElementById(id)) {
      return false;
    }

    const s = window.document.createElement('script');
    s.id = id;
    s.type = type;
    s.async = true;
    s.onload = onload;
    s.onerror = (e) => {
      s.parentNode.removeChild(s);
      onerror(e)
    };

    if (defer) {
      s.defer = defer;
    }

    if (src) {
      s.src = src;
    }

    window.document.body.appendChild(s);
    return true;
  }

  public static isInjected(id: string): boolean {
    return !!window.document.getElementById(id);
  }

  public static remove(id: string): void {
    const script = window.document.getElementById(id);
    if (script) {
      script.parentNode.removeChild(script);
    }
  }
}
