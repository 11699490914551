<ng-container *ngIf="isLoading">
  <app-content-loader></app-content-loader>
</ng-container>
<ng-container *ngIf="isInitialized">
  <div class="step-container columns is-desktop">
    <app-aside-bar
      [showEvenWithoutStatement]="false"
      [statement]="statement"
      [workFlowController]="workFlowController"
    ></app-aside-bar>
    <div class="main-content__container confirm-tax-return" *ngIf="statement && refundDetails">
      <app-ctr-base-info
        [statement]="statement"
        [title]="'CONFIRM_TAX_RETURN.TITLE' | translate"
        [desc]="'CONFIRM_TAX_RETURN.DESCRIPTION' | translate"
      ></app-ctr-base-info>
      <app-ctr-tax-card
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.taxCard"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.taxCard) +
          '. ' +
          ('CONFIRM_TAX_RETURN.TAX_CARD' | translate)
        "
        [dataSelects]="dataSelects"
      ></app-ctr-tax-card>

      <app-ctr-additional-income
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.additionalIncome"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.additionalIncome) +
          '. ' +
          ('CONFIRM_TAX_RETURN.ADDITIONAL_INCOME.TITLE' | translate)
        "
        [dataSelects]="dataSelects"
      ></app-ctr-additional-income>

      <app-ctr-pension-income
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.pensionIncome"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.pensionIncome) +
          '. ' +
          ('CONFIRM_TAX_RETURN.PENSION_INCOME.TITLE' | translate)
        "
        [dataSelects]="dataSelects"
      ></app-ctr-pension-income>

      <app-ctr-freelance-job
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.freelanceJobs"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.freelanceJobs) +
          '. ' +
          ('CONFIRM_TAX_RETURN.FREELANCE_JOBS.TITLE.FREELANCE' | translate)
        "
        [dataSelects]="dataSelects"
      ></app-ctr-freelance-job>

      <app-ctr-freelance-job
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.freelanceJobsGewerbe"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.freelanceJobsGewerbe) +
          '. ' +
          ('CONFIRM_TAX_RETURN.FREELANCE_JOBS.TITLE.GEWERBE' | translate)
        "
        [dataSelects]="dataSelects"
      ></app-ctr-freelance-job>

      <app-ctr-freelance-job
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.freelanceJobsVoluntering"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.freelanceJobsVoluntering) +
          '. ' +
          ('CONFIRM_TAX_RETURN.FREELANCE_JOBS.TITLE.VOLUNTEERING' | translate)
        "
        [dataSelects]="dataSelects"
      ></app-ctr-freelance-job>

      <app-ctr-cryptocurrency
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.cryptocurrencyIncome"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.cryptocurrencyIncome) +
          '. ' +
          ('CONFIRM_TAX_RETURN.CRYPTOCURRENCY_INCOME.TITLE' | translate)
        "
        [dataSelects]="dataSelects"
      ></app-ctr-cryptocurrency>

      <app-ctr-abroad-income
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.abroadIncome"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.abroadIncome) +
          '. ' +
          ('CONFIRM_TAX_RETURN.ABROAD_INCOME.TITLE' | translate)
        "
        [dataSelects]="dataSelects"
      ></app-ctr-abroad-income>

      <app-ctr-personal-info
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.personalInfo"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.personalInfo) +
          '. ' +
          ('CONFIRM_TAX_RETURN.PERSONAL_INFO.TITLE' | translate)
        "
        [dataSelects]="dataSelects"
      ></app-ctr-personal-info>

      <app-ctr-children
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.children"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.children) +
          '. ' +
          ('CONFIRM_TAX_RETURN.CHILDREN.TITLE' | translate)
        "
        [dataSelects]="dataSelects"
      ></app-ctr-children>

      <app-ctr-deductions
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.deductions"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.deductions) +
          '. ' +
          ('CONFIRM_TAX_RETURN.DEDUCTIONS.TITLE' | translate)
        "
        [dataSelects]="dataSelects"
      ></app-ctr-deductions>
      <app-ctr-bank-detail
        [statement]="statement"
        [categoryProperties]="summaryStatementCategories.bankDetails"
        [refundDetails]="refundDetails"
        [person]="'applicant'"
        [title]="
          summaryStatementCategories.counter(summaryStatementCategories.bankDetails) +
          '. ' +
          ('TAX_RETURN_SUMMARY.BANK_ACCOUNT' | translate)
        "
        [dataSelects]="dataSelects"
        [summary]="false"
      ></app-ctr-bank-detail>

      <ng-container *ngIf="statement.spouse">
        <div class="card card-white shadow-0 mt-3">
          <div class="font-size-24 font-weight-bold mb-1 pl-0_5">
            {{ "CONFIRM_TAX_RETURN.SPOUSE.TITLE" | translate }} {{ statement.spouse.firstName }}
            {{ statement.spouse.lastName }}
          </div>

          <app-ctr-tax-card
            [statement]="statement.spouse"
            [categoryProperties]="summaryStatementCategoriesSpouse.taxCard"
            [refundDetails]="refundDetails"
            [person]="'spouse'"
            [title]="
              summaryStatementCategoriesSpouse.counter(summaryStatementCategoriesSpouse.taxCard) +
              '. ' +
              ('CONFIRM_TAX_RETURN.TAX_CARD' | translate)
            "
            [dataSelects]="dataSelects"
          ></app-ctr-tax-card>

          <app-ctr-additional-income
            [statement]="statement.spouse"
            [categoryProperties]="summaryStatementCategoriesSpouse.additionalIncome"
            [refundDetails]="refundDetails"
            [person]="'spouse'"
            [title]="
              summaryStatementCategoriesSpouse.counter(summaryStatementCategoriesSpouse.additionalIncome) +
              '. ' +
              ('CONFIRM_TAX_RETURN.ADDITIONAL_INCOME.TITLE' | translate)
            "
            [dataSelects]="dataSelects"
          ></app-ctr-additional-income>

          <app-ctr-pension-income
            [statement]="statement.spouse"
            [categoryProperties]="summaryStatementCategoriesSpouse.pensionIncome"
            [refundDetails]="refundDetails"
            [person]="'spouse'"
            [title]="
              summaryStatementCategoriesSpouse.counter(summaryStatementCategoriesSpouse.pensionIncome) +
              '. ' +
              ('CONFIRM_TAX_RETURN.PENSION_INCOME.TITLE' | translate)
            "
            [dataSelects]="dataSelects"
          ></app-ctr-pension-income>

          <app-ctr-freelance-job
            [statement]="statement"
            [categoryProperties]="summaryStatementCategories.freelanceJobs"
            [refundDetails]="refundDetails"
            [person]="'spouse'"
            [title]="
              summaryStatementCategories.counter(summaryStatementCategories.freelanceJobs) +
              '. ' +
              ('CONFIRM_TAX_RETURN.FREELANCE_JOBS.TITLE.FREELANCE' | translate)
            "
            [dataSelects]="dataSelects"
          ></app-ctr-freelance-job>

          <app-ctr-freelance-job
            [statement]="statement"
            [categoryProperties]="summaryStatementCategories.freelanceJobsGewerbe"
            [refundDetails]="refundDetails"
            [person]="'spouse'"
            [title]="
              summaryStatementCategories.counter(summaryStatementCategories.freelanceJobsGewerbe) +
              '. ' +
              ('CONFIRM_TAX_RETURN.FREELANCE_JOBS.TITLE.GEWERBE' | translate)
            "
            [dataSelects]="dataSelects"
          ></app-ctr-freelance-job>

          <app-ctr-freelance-job
            [statement]="statement"
            [categoryProperties]="summaryStatementCategories.freelanceJobsVoluntering"
            [refundDetails]="refundDetails"
            [person]="'spouse'"
            [title]="
              summaryStatementCategories.counter(summaryStatementCategories.freelanceJobsVoluntering) +
              '. ' +
              ('CONFIRM_TAX_RETURN.FREELANCE_JOBS.TITLE.VOLUNTEERING' | translate)
            "
            [dataSelects]="dataSelects"
          ></app-ctr-freelance-job>

          <app-ctr-cryptocurrency
            [statement]="statement.spouse"
            [categoryProperties]="summaryStatementCategoriesSpouse.cryptocurrencyIncome"
            [refundDetails]="refundDetails"
            [person]="'spouse'"
            [title]="
              summaryStatementCategoriesSpouse.counter(summaryStatementCategoriesSpouse.cryptocurrencyIncome) +
              '. ' +
              ('CONFIRM_TAX_RETURN.CRYPTOCURRENCY_INCOME.TITLE' | translate)
            "
            [dataSelects]="dataSelects"
          ></app-ctr-cryptocurrency>

          <app-ctr-abroad-income
            [statement]="statement"
            [categoryProperties]="summaryStatementCategoriesSpouse.abroadIncome"
            [refundDetails]="refundDetails"
            [person]="'spouse'"
            [title]="
              summaryStatementCategoriesSpouse.counter(summaryStatementCategoriesSpouse.abroadIncome) +
              '. ' +
              ('CONFIRM_TAX_RETURN.ABROAD_INCOME.TITLE' | translate)
            "
            [dataSelects]="dataSelects"
          ></app-ctr-abroad-income>

          <app-ctr-personal-info
            [statement]="statement.spouse"
            [categoryProperties]="summaryStatementCategoriesSpouse.personalInfo"
            [refundDetails]="refundDetails"
            [person]="'spouse'"
            [title]="
              summaryStatementCategoriesSpouse.counter(summaryStatementCategoriesSpouse.personalInfo) +
              '. ' +
              ('CONFIRM_TAX_RETURN.PERSONAL_INFO.TITLE' | translate)
            "
            [dataSelects]="dataSelects"
          ></app-ctr-personal-info>

          <app-ctr-deductions
            [statement]="statement.spouse"
            [categoryProperties]="summaryStatementCategoriesSpouse.deductions"
            [refundDetails]="refundDetails"
            [person]="'spouse'"
            [title]="
              summaryStatementCategoriesSpouse.counter(summaryStatementCategoriesSpouse.personalInfo) +
              '. ' +
              ('CONFIRM_TAX_RETURN.DEDUCTIONS.TITLE' | translate)
            "
            [dataSelects]="dataSelects"
          ></app-ctr-deductions>
        </div>
      </ng-container>

      <ng-container *ngIf="reportError">
        <button class="btn-add-outline" (click)="handleAdditionalCommentClick(textareaError)">
          <img src="../../../assets/svg/add_new.svg" alt="add" />
          <span>{{ "CONFIRM_TAX_RETURN.ADDITIONAL_COMMENT.BTN_LABEL" | translate }}</span>
        </button>

        <div #textareaError class="mb-2 mt-1" [hidden]="!addAdditionalComment">
          <app-form-input
            [textArea]="true"
            [placeholder]="'CONFIRM_TAX_RETURN.ADDITIONAL_COMMENT.TEXTAREA_PLACEHOLDER'"
            [label]="'CONFIRM_TAX_RETURN.ADDITIONAL_COMMENT.TEXTAREA_LABEL'"
            [(model)]="additionalCommentMsg"
          ></app-form-input>
        </div>
      </ng-container>
    </div>
    <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
  </div>
</ng-container>
