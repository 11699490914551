import { Component, Input } from '@angular/core';
import { ButtonBaseComponent } from 'app/shared/components/buttons/button-base.component';

@Component({
  ...ButtonBaseComponent.metaData,
  selector: 'app-button-edit',
  templateUrl: './button-edit.component.html',
  styleUrls: ['./button-edit.component.scss']
})
export class ButtonEditComponent extends ButtonBaseComponent {
  @Input() title = 'COMMON.EDIT';
  @Input() withText = true;
}
