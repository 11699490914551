import {Action} from '@ngrx/store';
import { AuthModel } from 'app/modules/user/models/auth/auth.model';

export const USER_LOGIN = '[AuthUser] Logged in';
export const USER_LOGOUT = '[AuthUser] Logged out';
export const TOKEN_REFRESH = '[AuthUser] Token refresh';

export class LoginUserAction implements Action {
  readonly type = USER_LOGIN;

  constructor(public payload: AuthModel) { }
}

export class LogoutUserAction implements Action {
  readonly type = USER_LOGOUT;

  constructor(public payload: any) { }
}

export class TokenRefreshAction implements Action {
  readonly type = TOKEN_REFRESH;

  constructor(public payload: AuthModel) { }
}


export type Actions = LoginUserAction | LogoutUserAction | TokenRefreshAction;
