import {takeUntil} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {Statement} from 'app/statements';
import {StatementService, StatementsResponse} from 'app/statements/statement.service';
import {SubscribingComponent} from 'app/common';
import {ErrorDialogComponent} from 'app/common/error-dialog/error-dialog.component';

@Component({
  selector: 'app-client-summary',
  templateUrl: './client-summary.component.html',
  styleUrls: ['./client-summary.component.scss']
})
export class ClientSummaryComponent extends SubscribingComponent implements OnInit {
  batchId: number;
  statements: Statement[];
  batchCode: string;
  batchCreatedBy: string;
  buttons = [
    {
      type: 'back',
      label: 'COMMON.BACK',
      action: this.goBack.bind(this)
    },
    {
      type: 'proceed',
      label: 'COMMON.PAY',
      action: this.proceed.bind(this)
    }
  ];

  constructor(
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.batchId = parseInt(params['batchId'], 10);
        this.getBatchStatements();
      });
  }

  goBack() {
    this.router.navigate([`new/batch/${this.batchId}/client-details`]);
  }

  proceed() {    
    if (!this.statements || !this.statements[0]) {
      return;
    }
    
    
    const batchCode = this.statements[0].batchCode;
    this.statementService.verifyBatch(batchCode).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((response: Response) => {
        this.checkStatementValidation(response['statements']);
      }, (error: Response) => {
        const dialogRef = this.dialog.open(ErrorDialogComponent);
        const message = error['detail'];
        dialogRef.componentInstance.message = message;
      });
  }

  checkStatementValidation(statementsResponse: any[]) {
    let isValid = true;
    statementsResponse.forEach(statement => {
      if (!statement['is_ready']) {
        const target = this.statements.find(st => st.id === statement['statement_id']);
        target.hasErrors = true;
        isValid = false;
      }
    });

    if (isValid) {
      this.router.navigate([`/new/batch/${this.batchId}/thank-you`]);
    } else {
      const dialogRef = this.dialog.open(ErrorDialogComponent);
      dialogRef.componentInstance.message = 'Some of statements are invalid. Please check all fields and try again.';
    }
  }

  getBatchStatements() {
    this.statementService.getBatchStatements(this.batchId).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((response: StatementsResponse) => {
        this.statements = response.statements;
        if (this.statements.filter(x => !x.isReady()).length) {
          this.router.navigate([`/new/batch/${this.batchId}/client-details`]);
        }
        this.getBatchCode();
      });
  }

  getBatchCode() {
    if (this.statements.length) {
      this.batchCode = this.statements[0].batchCode;
      this.batchCreatedBy = this.statements[0].batchCreatedBy;
    }
  }
}
