<div class="income-input">
  <div class="income-input__title">
    <app-section-title
      [title]="title"
      [titleTooltip]="titleTooltip"
      [message]="messageTooltip"
      [isInnerHTML]="true"
      [tooltipContentRef]="tooltipContentRef"
    ></app-section-title>
  </div>
  <div class="income-input__inputs">
    <div class="income-input__inputs row">
      <div class="income-input__inputs">
        <app-form-select
          [label]="selectLabel"
          [choices]="filterDataSelectList()"
          [optionName]="2"
          [(model)]="localSelectValue"
          [placeholder]="selectPlaceholder"
        ></app-form-select>
        <app-validator-error-message
          [epValidator]="epValidator"
          [index]="0"
          [fieldName]="fieldsNames.operating_vat_type"
        >
        </app-validator-error-message>
      </div>
      <div
        class="deductions-info"
        *ngIf="
          alertInfo &&
          localSelectValue &&
          localSelectValue &&
          localSelectValue.length === 5 &&
          (!isLump || localSelectValue[4])
        "
      >
        <div class="deductions-info__wrap">
          <img src="../../../../../../assets/svg/warning-icon.svg" class="alert_icon" />
          <div class="deductions-info__wrap-text">
            <p class="deductions-info__text">
              {{ alertInfo | translate }}
            </p>
          </div>
        </div>
      </div>
      <div
        *ngIf="localSelectValue && !!localSelectValue.length"
        [class.columns]="localSelectValue && localSelectValue.length === 5 && localSelectValue[4]"
      >
        <div
          *ngIf="localSelectValue && localSelectValue.length === 5 && (!isLump || localSelectValue[4])"
          class="income-input__inputs"
          [class.column]="localSelectValue && localSelectValue.length === 5 && localSelectValue[4]"
        >
          <app-form-input
            [label]="inputLabel"
            [currencyCode]=""
            [placeholder]="inputPlaceholder"
            [isCurrency]="true"
            [(model)]="localInputValue"
          ></app-form-input>
          <app-validator-error-message [epValidator]="epValidator" [index]="0" [fieldName]="fieldsNames.income_value">
          </app-validator-error-message>
        </div>
        <div
          *ngIf="localSelectValue && localSelectValue.length === 5 && localSelectValue[4] && !isLump"
          [class.column]="localSelectValue && localSelectValue.length === 5 && localSelectValue[4]"
        >
          <div class="income-input__inputs">
            <app-form-input
              [label]="vatInputLabel"
              [currencyCode]=""
              [placeholder]="vatInputPlaceholder"
              [isCurrency]="true"
              [(model)]="localVatValue"
            ></app-form-input>
            <app-validator-error-message [epValidator]="epValidator" [index]="0" [fieldName]="fieldsNames.vat_value">
            </app-validator-error-message>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="income-input__actions">
    <button type="button" class="btn_cancel" (click)="handleAddAction(false)" [attr.data-cy]="'btnRadioNo'">
      <span class="txt">{{ btnTextFirst | translate }}</span>
    </button>
    <button
      type="button"
      class="btn_add"
      (click)="handleAddAction(true)"
      [class.active]="activeAddButton()"
      [attr.data-cy]="'btnRadioYes'"
    >
      <span class="txt">{{ btnTextSecond | translate }}</span>
    </button>
  </div>
</div>
