import { environment } from 'environments/environment';

export class GoogleTagManagerConfig {
  public static get isEnabled(): boolean {
    return true;
  }

  public static get tagId(): string {
    return environment.googleTagManagerId;
  }

  public static get scriptId(): string {
    return 'gtm-script';
  }
}
