import { EventEmitter, Injectable } from "@angular/core";
import {
  ErrorMessage,
  FIELD_RULE_VALUE_OPERATORS,
  FieldRuleValue,
  FieldValidationRules,
  FIELD_OPERATOR_HELPER,
  ErrorMessageContent
} from "app/core/form-validator/validatorInterface";
import { FieldRulesList } from "app/core/form-validator/validtorField";
import { ValidatorRules } from "app/core/form-validator/validatorRules";
import { ObjectHelper } from "app/core/helpers/object.helper";
import { RecognizerHelper } from "app/core/helpers/recognizer.helper";
import { Statement } from "app/statements";
import { environment } from "environments/environment";
import { DateRange } from "app/common/dateRange";
import { StringFormatHelper } from "app/core/helpers/string-format.helper";
import { DateHelper } from "app/core/helpers/date.helper";

@Injectable()
export class ValidateFieldV2Service {
  public validatorChangedEmitter = new EventEmitter<any>();
  private rules: FieldValidationRules[];
  private _index = -1;
  private currentYear = 0;
  private errorMessages: ErrorMessage;
  private _objectForValidation: Record<string, unknown>;

  constructor() {
    this.rules = FieldRulesList;
  }

  public _init(_mainStatement: Statement, _rules: FieldValidationRules[] = null) {
    this.currentYear = _mainStatement.fullYear;

    if (_rules) {
      this.rules = _rules;
    }

    this.clearErrorMessages();
  }

  public validateFieldAndGetMessageV2(
    _objectForValidation: {} | {}[],
    validationFieldNames: string[],
    index = 0
  ): ErrorMessage[] {
    if (Array.isArray(_objectForValidation)) {
      const message = [];
      _objectForValidation.forEach((el, i) =>
        message.push(...this.validateFieldAndGetMessageV2(el, validationFieldNames, i))
      );
      return message;
    }

    this.objectForValidation = _objectForValidation;
    this.clearErrorMessages();
    const epValidatorMessages = [];

    validationFieldNames.forEach((el) => {
      const currentRule = this.setRule(el);

      if (currentRule) {
        const fieldName = currentRule.classFieldName.replace(
          FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE,
          index.toString()
        );
        const objectField = ObjectHelper.getFieldByString(this.objectForValidation, fieldName);

        if (currentRule.nestedArrayFieldName && Array.isArray(objectField)) {
          objectField.forEach((item, i) => {
            if (
              currentRule.nestedArrayFieldName === FIELD_OPERATOR_HELPER.NESTED_ARRAY_IN_NESTED_ARRAY &&
              Array.isArray(item)
            ) {
              item.forEach((nestedItem, nestedIndex) => {
                this.index = nestedIndex;
                if (!this.validateField(currentRule, this.getNestedArrayField(currentRule, nestedItem))) {
                  epValidatorMessages.push(this.errorMessages);
                }
              });
            } else {
              this.index = i;
              if (!this.validateField(currentRule, this.getNestedArrayField(currentRule, item))) {
                epValidatorMessages.push(this.errorMessages);
              }
            }
          });
        } else {
          this.index = index;
          if (!this.validateField(currentRule, objectField)) {
            epValidatorMessages.push(this.errorMessages);
          }
        }
      }
    });

    if (epValidatorMessages.length) {
      this.validatorChangedEmitter.emit();
    }

    return epValidatorMessages;
  }

  private getNestedArrayField(currentRule: FieldValidationRules, field: any): any {
    if (!field) {
      return field;
    }

    if (currentRule.nestedArrayFieldName === true) {
      return field;
    }

    const fieldName = currentRule.nestedArrayFieldName
      .toString()
      .replace(FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE, this.index.toString());
    const fieldFound = ObjectHelper.getFieldByString(field, fieldName);

    if (field instanceof DateRange && !fieldFound) {
      return "";
    }

    return fieldFound;
  }

  private validateField(currentRule: FieldValidationRules, field: any): boolean {
    this.clearErrorMessages();

    if (!field) {
      if (
        !currentRule.fieldRules.length ||
        !currentRule.fieldRules.find(
          (x) =>
            [
              ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
              ValidatorRules.IS_REQUIRED,
              ValidatorRules.IS_REQUIRED_INDEX,
              ValidatorRules.IS_NOT_NULL,
              ValidatorRules.IS_NOT_ZERO_NUMBER
            ].indexOf(x) !== -1
        ) ||
        (RecognizerHelper.isUndefined(field) &&
          currentRule.fieldRules.find((x) => [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED].indexOf(x) !== -1))
      ) {
        return true;
      }
    }

    let valid = true;

    currentRule.fieldRules.some((item: string, i: number) => {
      switch (item) {
        case ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED:
          valid = this.isRequiredIfNotUndefined(currentRule, i, field);
          break;
        case ValidatorRules.IS_REQUIRED:
          valid = this.isRequired(currentRule, i, field);
          break;
        case ValidatorRules.IS_NOT_ZERO_NUMBER:
          valid = this.isNotZeroNumber(currentRule, i, field);
          break;
        case ValidatorRules.IS_REQUIRED_INDEX:
          valid = this.isRequiredIndex(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_LENGTH_MIN:
          valid = this.fieldLengthTooShort(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_LENGTH_MAX:
          valid = this.fieldLengthTooLong(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_IS_INTIGER:
          valid = this.fieldIsInteger(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_IS_DECIMAL:
          valid = this.fieldIsNumber(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_NUMBER_MIN:
          valid = this.fieldNumberIsTooSmall(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_NUMBER_MAX:
          valid = this.fieldNumberIsTooBig(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_IS_REG_EX:
          valid = this.fieldIsFormatted(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_IS_DATE:
          valid = this.fieldIsDate(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_YEAR_IS_CURRENT:
          valid = this.fieldYearIsCurrent(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_YEAR_MAX:
          valid = this.fieldYearMax(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_YEAR_MIN:
          valid = this.fieldYearMin(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL:
          valid = this.fieldDateIsLowerOrEqual(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL:
          valid = this.fieldDateIsBiggerOrEqual(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_EMP_DATE_IS_LOWER:
          valid = this.fieldDateIsLower(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_EMP_DATE_IS_BIGGER:
          valid = this.fieldDateIsBigger(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_EMP_DATE_IS_NOT_IN_RANGE:
          valid = this.fieldDateIsNotInRange(currentRule, i, field);
          break;
        case ValidatorRules.IS_NOT_NULL:
          valid = this.isNotNull(currentRule, i, field);
          break;
        case ValidatorRules.ONLY_VALUE_IN_LIST:
          valid = this.onlyValueInList(currentRule, i, field);
          break;
        case ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ:
          valid = this.isIntegerInRangeEq(currentRule, i, field);
          break;
      }

      if (!valid) {
        return true;
      }
    });

    return valid;
  }

  private setRule(fieldName: string): FieldValidationRules | null {
    let fieldRule = this.rules.filter((kk) => kk.fieldName === fieldName && kk.year.includes(this.currentYear)); //each year can have only one rule

    if (!fieldRule.length) {
      if (!environment.production) {
        console.error(`FIELD RULE IS NOT EXISTS ${fieldName}.`);
      }
      return;
    }

    if (fieldRule.length && fieldRule.length < 2) {
      if (fieldRule[0].year.filter((kk) => kk === this.currentYear).length) {
        return fieldRule[0];
      } else {
        if (!environment.production) {
          console.error(`RULE YEAR IS NOT MATCHED FOR FIELD ${fieldName}.`);
        }
        return null;
      }
    } else {
      if (!environment.production) {
        console.error(
          `TO MANY RULES FOR FIELD ${fieldName} AND YEAR ${this.currentYear}. FIELD SHOULD HAVE ONLY ONE RULE FOR EACH YEAR`
        ); //each year can have only one rule
      }
    }

    return null;
  }

  private clearErrorMessages(): void {
    this.errorMessages = new ErrorMessage();
  }

  private prepareFieldRuleValue(rule: FieldValidationRules, ruleIndex: number, fieldRuleValue: any = null): any {
    if (RecognizerHelper.isNullOrUndefined(fieldRuleValue)) {
      fieldRuleValue = rule.fieldRulesValue[ruleIndex];
    }

    if (fieldRuleValue instanceof FieldRuleValue) {
      let result;

      fieldRuleValue.classFieldNames.forEach((fieldName, fieldIndex) => {
        let isNotOperator = false;
        let toNumber = false;
        let objectField;
        if (typeof fieldName === "string") {
          isNotOperator = fieldName.indexOf(FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR) !== -1;
          toNumber = fieldName.indexOf(FIELD_OPERATOR_HELPER.TO_NUMBER) !== -1;
          fieldName = fieldName.replace(FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE, this.index.toString());
          fieldName = fieldName.replace(FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR, "");
          fieldName = fieldName.replace(FIELD_OPERATOR_HELPER.TO_NUMBER, "");
          objectField = ObjectHelper.getFieldByString(this.objectForValidation, fieldName);
        } else {
          objectField = fieldName;
        }

        switch (fieldRuleValue.operator) {
          case FIELD_RULE_VALUE_OPERATORS.PLUS:
            if (!result) {
              result = 0;
            }

            if (fieldRuleValue.isNestedArrayField && Array.isArray(objectField)) {
              result += objectField.reduce((a, b) => Number(a) + Number(b), 0);
            } else {
              result += Number(objectField);
            }

            break;
          case FIELD_RULE_VALUE_OPERATORS.MINUS:
            if (fieldRuleValue.isNestedArrayField && Array.isArray(objectField)) {
              if (fieldIndex === 0) {
                result = objectField.reduce((a, b) => Number(a) + Number(b), 0);
              } else {
                result -= objectField.reduce((a, b) => Number(a) + Number(b), 0);
              }
            } else {
              if (fieldIndex === 0) {
                result = Number(objectField);
              } else {
                result -= Number(objectField);
              }
            }

            break;
          case FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND:
          case FIELD_RULE_VALUE_OPERATORS.ARRAY:
            if (!result) {
              result = [];
            }

            if (fieldRuleValue.isNestedArrayField && Array.isArray(objectField)) {
              objectField.forEach((el) => {
                let preparedValue = el;
                if (toNumber) {
                  preparedValue = Number(preparedValue);
                }
                if (isNotOperator) {
                  preparedValue = !preparedValue;
                }
                result.push(preparedValue);
              });
            } else {
              let preparedValue = objectField;
              if (toNumber) {
                preparedValue = Number(preparedValue);
              }
              if (isNotOperator) {
                preparedValue = !preparedValue;
              }
              result.push(preparedValue);
            }
            break;
          default:
            result = objectField;
            break;
        }
      });

      switch (fieldRuleValue.operator) {
        case FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND:
          result.some((el) => {
            if (!el) {
              result = false;
              return true;
            }
          });

          if (result !== false) {
            result = true;
          }

          break;
      }

      return result;
    }

    return fieldRuleValue;
  }

  private isRequired(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (this.prepareFieldRuleValue(rule, ruleIndex)) {
      if (!RecognizerHelper.isBoolean(field) && field !== 0 && (!field || !StringFormatHelper.toString(field).length)) {
        this.setErrorMessage(rule, ruleIndex);
        return false;
      }
    }

    return true;
  }

  private isRequiredIfNotUndefined(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (this.prepareFieldRuleValue(rule, ruleIndex)) {
      if (!field && !RecognizerHelper.isUndefined(field)) {
        this.setErrorMessage(rule, ruleIndex);
        return false;
      }
    }

    return true;
  }

  private isNotZeroNumber(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (this.prepareFieldRuleValue(rule, ruleIndex)) {
      if (Number(field) === 0) {
        this.setErrorMessage(rule, ruleIndex);
        return false;
      }
    }

    return true;
  }

  private isRequiredIndex(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (this.prepareFieldRuleValue(rule, ruleIndex)) {
      if (RecognizerHelper.isNullOrUndefined(field) || !StringFormatHelper.toString(field).length) {
        this.setErrorMessage(rule, ruleIndex);
        return false;
      }
    }

    return true;
  }

  private fieldLengthTooShort(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (StringFormatHelper.toString(field).length < this.prepareFieldRuleValue(rule, ruleIndex)) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private fieldLengthTooLong(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (StringFormatHelper.toString(field).length > this.prepareFieldRuleValue(rule, ruleIndex)) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private fieldIsInteger(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (!this.fieldIsNumber(rule, ruleIndex, field)) {
      return false;
    }

    const splitData = StringFormatHelper.toString(field).split(".");
    if (splitData.length > 2) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    } else if (splitData.length === 2) {
      const decimalsValue = Number(splitData[1]);
      if (decimalsValue) {
        this.setErrorMessage(rule, ruleIndex);
        return false;
      }
    }

    return true;
  }

  private fieldIsNumber(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    const numberRegex = /^-?\d+(\.\d+)?$/;
    if (!numberRegex.test(field)) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private fieldNumberIsTooSmall(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    const fieldValidation = Number(field);
    if (fieldValidation < this.prepareFieldRuleValue(rule, ruleIndex)) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private fieldNumberIsTooBig(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    const fieldValidation = Number(field);
    if (fieldValidation > this.prepareFieldRuleValue(rule, ruleIndex)) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private fieldIsFormatted(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (!field) {
      return true;
    }
    const regExTest = rule.fieldRulesValue[ruleIndex];
    regExTest.lastIndex = 0;
    const regExValid = rule.fieldRulesValue[ruleIndex].test(StringFormatHelper.toString(field));
    if (regExValid === false) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private fieldIsDate(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (this.prepareFieldRuleValue(rule, ruleIndex)) {
      if (isNaN(Date.parse(field))) {
        this.setErrorMessage(rule, ruleIndex);
        return false;
      } else if (!DateHelper.getInstance(field).isValid()) {
        this.setErrorMessage(rule, ruleIndex);
        return false;
      }
    }

    return true;
  }

  private fieldYearIsCurrent(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (this.prepareFieldRuleValue(rule, ruleIndex)) {
      const dateSplit = StringFormatHelper.toString(field).split(".");
      let year;
      if (isNaN(Number(dateSplit[2]))) {
        year = new Date(field).getFullYear();
      } else {
        year = Number(dateSplit[2]);
      }

      if (year !== this.currentYear) {
        this.setErrorMessage(rule, ruleIndex);
        return false;
      }
    }

    return true;
  }

  private fieldYearMax(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (isNaN(Date.parse(field)) || typeof field.getFullYear !== "function") {
      return true;
    }

    const fieldRuleValue = this.prepareFieldRuleValue(rule, ruleIndex);

    if (fieldRuleValue) {
      const year = field.getFullYear();
      let maxYear;
      if (fieldRuleValue === "STATEMENT_YEAR") {
        maxYear = this.currentYear;
      } else {
        maxYear = Number(fieldRuleValue);
      }

      if (year > maxYear) {
        this.setErrorMessage(rule, ruleIndex);
        return false;
      }
    }

    return true;
  }

  private fieldYearMin(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (isNaN(Date.parse(field)) || typeof field.getFullYear !== "function") {
      return true;
    }

    const fieldRuleValue = Number(this.prepareFieldRuleValue(rule, ruleIndex));

    if (fieldRuleValue) {
      const year = field.getFullYear();
      if (year < fieldRuleValue) {
        this.setErrorMessage(rule, ruleIndex);
        return false;
      }
    }

    return true;
  }

  private fieldDateIsLowerOrEqual(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    const fieldRuleValue = this.prepareFieldRuleValue(rule, ruleIndex);

    const field1 = new Date(field);
    const field2 = new Date(fieldRuleValue);
    if (field1 > field2) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private fieldDateIsBiggerOrEqual(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    const fieldRuleValue = this.prepareFieldRuleValue(rule, ruleIndex);

    const field1 = new Date(field);
    const field2 = new Date(fieldRuleValue);
    if (field1 < field2) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private fieldDateIsLower(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    const fieldRuleValue = this.prepareFieldRuleValue(rule, ruleIndex);

    const field1 = new Date(field);
    const field2 = new Date(fieldRuleValue);
    if (field1 >= field2) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private fieldDateIsBigger(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    const fieldRuleValue = this.prepareFieldRuleValue(rule, ruleIndex);

    const field1 = new Date(field);
    const field2 = new Date(fieldRuleValue);
    if (field1 <= field2) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private fieldDateIsNotInRange(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    const fieldRuleValue = this.prepareFieldRuleValue(rule, ruleIndex);

    if (!fieldRuleValue[0] || !fieldRuleValue[1]) {
      return true;
    }

    const dateField = new Date(field);
    const rangeField1 = new Date(fieldRuleValue[0]);
    const rangeField2 = new Date(fieldRuleValue[1]);
    if (dateField <= rangeField2 && dateField >= rangeField1) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private isNotNull(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (this.prepareFieldRuleValue(rule, ruleIndex)) {
      if (RecognizerHelper.isNullOrUndefined(field)) {
        this.setErrorMessage(rule, ruleIndex);
        return false;
      }
    }

    return true;
  }

  private onlyValueInList(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    if (
      this.prepareFieldRuleValue(rule, ruleIndex).indexOf(field) === -1 &&
      this.prepareFieldRuleValue(rule, ruleIndex).indexOf(Number(field)) === -1
    ) {
      this.setErrorMessage(rule, ruleIndex);
      return false;
    }

    return true;
  }

  private isIntegerInRangeEq(rule: FieldValidationRules, ruleIndex: number, field: any): boolean {
    const preparedField = Number(field);
    const fieldRuleValue = rule.fieldRulesValue[ruleIndex];
    let firstValue, secondValue;

    if (Array.isArray(fieldRuleValue)) {
      firstValue = this.prepareFieldRuleValue(rule, ruleIndex, fieldRuleValue[0]);
      secondValue = this.prepareFieldRuleValue(rule, ruleIndex, fieldRuleValue[1]);
    } else {
      const preparedRule = this.prepareFieldRuleValue(rule, ruleIndex);
      firstValue = preparedRule[0];
      secondValue = preparedRule[1];
    }

    if (
      !Number.isInteger(preparedField) ||
      (firstValue !== undefined &&
        secondValue !== undefined &&
        (firstValue > preparedField || secondValue < preparedField))
    ) {
      this.setErrorMessage(rule, ruleIndex, null, { from: firstValue, to: secondValue });
      return false;
    }

    return true;
  }

  private setErrorMessage(
    rule: FieldValidationRules,
    ruleIndex: number,
    message: string = null,
    args: Record<string, unknown> = null
  ): void {
    if (!this.errorMessages) {
      this.errorMessages = new ErrorMessage();
    }
    this.errorMessages.index = this.index;
    this.errorMessages.fieldName = rule.fieldName;
    this.errorMessages.messages.push(
      new ErrorMessageContent(
        rule.fieldErrorMessage[ruleIndex] || message,
        rule.fieldErrorMessageArguments && rule.fieldErrorMessageArguments[ruleIndex]
          ? rule.fieldErrorMessageArguments[ruleIndex]
          : args
      )
    );
  }

  private set index(_index: number) {
    this._index = _index;
  }

  private get index(): number {
    return this._index;
  }

  private set objectForValidation(_objectForValidation: Record<string, unknown>) {
    this._objectForValidation = _objectForValidation;
  }

  private get objectForValidation(): Record<string, unknown> {
    return this._objectForValidation;
  }
}
