export interface ReligionResponse {
    id: number;
    name: string;
    order: number;
}

export class Religion {
    id: number;
    name: string;
    order: number;

    static fromResponse(response: ReligionResponse): Religion {
        const religion = new Religion();
        religion.id = response.id;
        religion.name = response.name;
        religion.order = response.order;
        return religion;
    }
}
