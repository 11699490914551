import { Injectable } from '@angular/core';
import { SelectsData, SelectsDataInterface, Statement } from 'app/statements';
import {
  Child,
  ChildErrors,
  FAMILIENKASSE_CHOICES,
  PROPINQUITY_CHOICES,
  PROPINQUITY_TO_SECOND_PARENT_CHOICES,
} from 'app/statements/child';
import { DateRange } from 'app/common/dateRange';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { ChildrenService } from 'app/statements/children.service';
import { SHOW_SNACKBAR } from 'app/reducers/actions/snackbar';
import { SnackbarConfig } from 'app/common';
import { Store } from '@ngrx/store';
import { getSelectsData, State } from 'app/reducers';
import { DeductionsErrors } from 'app/shared/modules/statement-deduction-base/models/deductions';
import { StatementService } from 'app/statements/statement.service';
import { firstValueFrom, Subject } from 'rxjs';
import {
  VALIDATOR_FIELD_RULES_PERSONAL_INFO_CHILD
} from 'app/core/form-validator/validator-field-rules/validator-field-rules-personal-info-child';
import { ChildKindergeld } from 'app/modules/statement/models/child-kindergeld';
import { StatementErrors } from 'app/modules/statement/models/statement/statement.errors.interface';
import { StatementResponse } from 'app/modules/statement/models/statement/statement.response.interface';
import { ErrorMessage, ErrorMessageContent } from 'app/core/form-validator/validatorInterface';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';
import { ChildKindergeldHttpService } from 'app/modules/statement/services/http/child-kindergeld.http.service';
import { ValidateFieldV2Service } from 'app/core/form-validator/validate-field-v2.service';
import {
  VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD
} from 'app/core/form-validator/validator-field-names/validator-field-names-personal-info-child';

@Injectable()
export class PersonalInfoChildService {
  public mainStatement: Statement;
  public child: Child;
  public errors: DeductionsErrors | ChildErrors | StatementErrors;
  public loading: boolean;
  public isKindergeldLoading: boolean;
  public readonly PROPINQUITY_CHOICES = PROPINQUITY_CHOICES;
  public readonly PROPINQUITY_TO_SECOND_PARENT_CHOICES = PROPINQUITY_TO_SECOND_PARENT_CHOICES;
  public readonly FAMILIENKASSE_CHOICES = FAMILIENKASSE_CHOICES;
  public readonly VALIDATOR_KEYS = VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD;
  public epValidator: ErrorMessage[];
  private dataSelects: SelectsDataInterface = new SelectsData();
  private isUpdateChildPending = false;
  private initialBirthDate: string;
  private ngUnsubscribe: Subject<void>;

  constructor(
    private store: Store<State>,
    private childrenService: ChildrenService,
    private statementService: StatementService,
    private validateFieldV2Service: ValidateFieldV2Service,
    private workFlowController: WorkflowControllerService,
    private childKindergeldService: ChildKindergeldHttpService,
  ) {
  }

  public _init(
    _mainStatement: Statement,
    _child: Child,
    _ngUnsubscribe: Subject<void>,
  ): void {
    this.mainStatement = _mainStatement;
    this.child = _child;
    this.initialBirthDate = this.child.birthDate;

    this.validateFieldV2Service._init(this.mainStatement, VALIDATOR_FIELD_RULES_PERSONAL_INFO_CHILD);

    this.clearErrors();

    this.loading = false;

    this.ngUnsubscribe = _ngUnsubscribe;

    this.store.select(getSelectsData).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dataSelect: SelectsDataInterface) => {
        if (dataSelect) {
          this.dataSelects = dataSelect;
        }
      });
  }

  public get isLoading(): boolean {
    return this.loading;
  }

  public clearErrors(): void {
    this.errors = {};
    this.epValidator = [];
  }

  public updateChild(
    options: {
      goForward?: boolean,
      goBack?: boolean,
      responseToSave?: boolean,
      callback?: Function
    } = {}
  ): void {
    this.isUpdateChildPending = true;

    const goForward = options.goForward || false,
      goBack = options.goBack || false,
      responseToSave = options.responseToSave || false,
      callback = options.callback || (() => {});

    this.loading = true;

    const complete = (statementResponse: Statement) => {
      if (statementResponse) {
        Object.assign(this.mainStatement, statementResponse);
      }

      this.store.dispatch({
        type: SHOW_SNACKBAR,
        payload: new SnackbarConfig('SNACKBAR.CHILDREN_UPDATED')
      });

      if (goForward) {
        this.loading = true;
        this.workFlowController.goToTheNextStep();
      } else if (goBack) {
        this.loading = true;
        this.workFlowController.goToThePreviousStep();
      }

      callback();
    };

    this.child.updateChildDeductionsFlags();
    this.childrenService.updateChild(this.mainStatement.id, this.child.id, this.child.toResponse(responseToSave ? undefined : ''))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.initialBirthDate = this.child.birthDate;
          if (this.mainStatement.stagePersonalInfoChildren) {
            complete(null);
          } else {
            const toResponse: StatementResponse = this.mainStatement.stagePersonalInfoChildrenToResponse;
            this.statementService.updateStatement(this.mainStatement.id, toResponse).subscribe({
              next: complete,
              error: error => {
                this.loading = false;
                this.store.dispatch({
                  type: SHOW_SNACKBAR,
                  payload: new SnackbarConfig('COMMON.GLOBAL_ERROR', 'ERROR')
                });
                console.error(error);
              }
            });
          }
        },
        error: (errors) => {
          this.loading = false;

          errors = errors.error;

          if (Array.isArray(errors)) {
            this.errors = errors[0];
          } else {
            this.errors = errors;
          }
        },
        complete: () => this.isUpdateChildPending = false
      });
  }

  public getError(index: number, key: string): any {
    if (!this.errors) {
      return [];
    }

    const filteredErr = [];

    if (this.errors && this.errors[key] && this.errors[key][index]) {
      // filteredErr.push(...Object.values(this.errors[key]));
      filteredErr.push(this.errors[key][index]);
    }

    return filteredErr.length ? filteredErr : null;
  }

  public formValidationErrors(isAdultStep = false): void {
    this.resetUnnecessaryChildFields(isAdultStep);

    this.epValidator = [];
    if (!this.child) {
      return;
    }

    let validationFieldKeys;

    if (isAdultStep) {
      validationFieldKeys = [
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.school_names,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.education_dates_lower,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.education_dates_upper,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.lack_of_training_space_dates_lower,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.lack_of_training_space_dates_upper,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.voluntary_year_dates_lower,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.voluntary_year_dates_upper,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.transitional_period_dates_lower,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.transitional_period_dates_upper,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.unemployment_dates_lower,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.unemployment_dates_upper,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.disability_before_25_dates_lower,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.disability_before_25_dates_upper,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.minijob_dates_lower,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.minijob_dates_upper,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.minijob_number_of_hours,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.employment_dates_lower,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.employment_dates_upper,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.employment_number_of_hours,
      ];
    } else {
      validationFieldKeys = [
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.first_name,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.last_name,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.birth_date,
        // VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.identifikationsnummer,
        // VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.propinquity,
        // VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.propinquity_to_spouse,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.different_address,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.child_residence_address,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.child_residence_country,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.child_residence_period_lower,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.child_residence_period_upper,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_first_name,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_last_name,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_birth_date,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.propinquity_to_second_parent,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.propinquity_from_lower,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.propinquity_from_upper,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.last_known_address,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_lived_abroad_from_lower,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_lived_abroad_from_upper,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_death_date,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.familienkasse,
        // VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.kindergeld,
        VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.kindergeld_extra,
      ];


      //Validate identifikationsnummer only when child lives in germany minimum 1 year and if currentYear is >= 2023 TAX-3005
      let currenYear = this.mainStatement.fullYear;
      let currentYearDays = this.getCurrentYearDays();
      let childResidenc = this.child.residence;
      let residenceDuration : number = 0;
      let bornInCurrentYear = (new Date(this.child.birthDate).getFullYear()) == currenYear;

      if (bornInCurrentYear){
        let lower = `${currenYear}-01-01`;
        let upper = this.child.birthDate;
        let correctionDays : number = 0;

        let dif = new Date(upper).getTime() - new Date(lower).getTime();

        correctionDays += Math.round(dif / (24 * 60 * 60 * 1000));
        currentYearDays -= correctionDays
      }

      childResidenc.forEach(residence=>{

        let lower = residence.residencePeriod.lower;
        let upper = residence.residencePeriod.upper;

        if(lower && upper){
          let dif = new Date(upper).getTime() - new Date(lower).getTime();

          residenceDuration += Math.round(dif / (24 * 60 * 60 * 1000)) + 1;
        }


      })
      
      if(currenYear >= 2023 && currentYearDays > residenceDuration + 1){
        validationFieldKeys.push(VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.identifikationsnummer)
      }

    }

    this.epValidator = [
      ...this.validateFieldV2Service.validateFieldAndGetMessageV2(this.child, validationFieldKeys)
    ];

    if (isAdultStep) {
      if (this.child.isStudyingOrCompleted) {
        if (!this.child.completedFirstDegree && !this.isSchoolSectionActive) {
          const errorMessages = new ErrorMessage();
          errorMessages.index = 0;
          errorMessages.fieldName = VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.is_studying_or_completed_checkboxes;
          errorMessages.messages.push(
            new ErrorMessageContent('CHILD.ADULT.CHOOSE_AT_LEAST_ONE')
          );
          this.epValidator.push(errorMessages);
        }
      }

      if (
        this.isChildWasWorkingSectionActive
        && this.child.haveJob
        && !this.isJobSectionActive
        && !this.isMiniJobSectionActive
      ) {
        const errorMessages = new ErrorMessage();
        errorMessages.index = 0;
        errorMessages.fieldName = VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.have_job_checkboxes;
        errorMessages.messages.push(
          new ErrorMessageContent('CHILD.ADULT.CHOOSE_AT_LEAST_ONE')
        );
        this.epValidator.push(errorMessages);
      }
    }
  }

  public getCurrentYearDays(): number{
    let currenYear = this.mainStatement.fullYear;

    if ((currenYear % 4 === 0 && currenYear % 100 !== 0) || currenYear % 400 === 0) {
      return 366;
    } else {
        return 365;
    }
  }

  public addResidence(): void {
    this.child.addResidence();
  }

  public removeResidence(residenceIndex: number): void {
    this.child.removeResidence(residenceIndex);
  }

  public removeSchool(schoolIndex: number) {
    this.child.removeSchool(schoolIndex);
  }

  public addSchool() {
    this.child.addSchool();
  }

  public removeLackOfTrainingSpaceDates(index: number): void {
    this.child.removeLackOfTrainingSpaceDates(index);
  }

  public addLackOfTrainingSpaceDates(): void {
    this.child.addLackOfTrainingSpaceDates();
  }

  public removeVoluntaryYearDates(index: number): void {
    this.child.removeVoluntaryYearDates(index);
  }

  public addVoluntaryYearDates(): void {
    this.child.addVoluntaryYearDates();
  }

  public removeTransitionalPeriodDates(index: number): void {
    this.child.removeTransitionalPeriodDates(index);
  }

  public addTransitionalPeriodDates(): void {
    this.child.addTransitionalPeriodDates();
  }

  public removeUnemploymentDates(index: number): void {
    this.child.removeUnemploymentDates(index);
  }

  public addUnemployment(): void {
    this.child.addUnemployment();
  }

  public removeDisabilityBefore25(index: number): void {
    this.child.removeDisabilityBefore25(index);
  }

  public addDisabilityBefore25(): void {
    this.child.addDisabilityBefore25();
  }

  public removeMiniJob(index: number): void {
    this.child.removeMiniJob(index);
  }

  public addMiniJob(): void {
    this.child.addMiniJob();
  }

  public removeEmployment(index: number): void {
    this.child.removeEmployment(index);
  }

  public addEmployment(): void {
    this.child.addEmployment();
  }

  public get isSchoolSectionActive(): boolean {
    return this.child.isStudying && this.child.isStudyingOrCompleted;
  }

  public get isChildWasWorkingSectionActive(): boolean {
    return this.child.isStudyingOrCompleted && this.child.completedFirstDegree;
  }

  public get isMiniJobSectionActive(): boolean {
    return this.isChildWasWorkingSectionActive
      && this.child.haveJob && this.child.haveMinijobs;
  }

  public get isJobSectionActive(): boolean {
    return this.isChildWasWorkingSectionActive
      && this.child.haveJob && this.child.wasEmployed;
  }

  public onChangeLivedWholeYear(): void {
    this.child.livedWholeYear = !this.child.livedWholeYear;

    if (!this.child.livedWholeYear && !this.child.residence.length) {
      this.addResidence();
    }
  }

  public onChangeChildSecondParent(): void {
    this.child.secondParent = !this.child.secondParent;
    if (this.child.secondParent) {
      this.child.propinquityToSpouse = null;
    } else {
      this.child.propinquityToSpouse = '1';
    }
    // this.setDeafultKindergeld();
  }

  public onChangeIsPropinquityPeriod(): void {
    if (this.child.isPropinquityPeriod && !this.child.propinquityFrom) {
      this.child.propinquityFrom = new DateRange();
    }
  }

  public onChangeSecondParentLivedAbroad(): void {
    if (this.child.isPropinquityPeriod && !this.child.secondParentLivedAbroadFrom) {
      this.child.secondParentLivedAbroadFrom = new DateRange();
    }
  }

  public setDeafultKindergeld(): void {
    if (this.child.birthDate && this.child.identifikationsnummer) {
      if (this.child.birthDate === this.initialBirthDate) {
        this.getKindergeldAmount();
      } else if (!this.isUpdateChildPending) {
        this.updateChildBirthDate(() => {
          this.getKindergeldAmount();
        });
      }
    }

    this.child.savedKindergeld = true;
    if (this.isSingle2020()) {
      this.child.secondParent = true;
    }
    if (!this.child.isAdult(this.mainStatement.fullYear)) {
      this.child.clearAdultSection();
    }
  }

  public isSingle2020(): boolean {
    return this.mainStatement.fullYear >= 2020
      && (
        this.mainStatement.maritalStatus === this.dataSelects.getSingleMaritalStatusId()
        || !this.mainStatement.filedTogether
      );
  }

  public resetUnnecessaryChildFields(isAdultStep = false): void {
    if (isAdultStep) {
      if (!this.child.isStudyingOrCompleted) {
        this.child.completedFirstDegree = false;
        this.child.isStudying = false;
      }

      if (!this.isSchoolSectionActive) {
        this.child.removeAllSchools();
      } else {
        if (!this.child.schoolNames.length) {
          this.child.schoolNames.push('');
        }
        if (!this.child.educationDates.length) {
          this.child.educationDates.push(new DateRange());
        }
      }

      if (!this.child.lackOfTrainingSpace) {
        this.child.removeAllLackOfTrainingSpaceDates();
      } else {
        if (!this.child.lackOfTrainingSpaceDates.length) {
          this.child.lackOfTrainingSpaceDates.push(new DateRange());
        }
      }

      if (!this.child.hasVoluntaryYear) {
        this.child.removeAllVoluntaryYearDates();
      } else {
        if (!this.child.voluntaryYearDates.length) {
          this.child.voluntaryYearDates.push(new DateRange());
        }
      }

      if (!this.child.beenInTransitionalPeriod) {
        this.child.removeAllTransitionalPeriodDates();
      } else {
        if (!this.child.transitionalPeriodDates.length) {
          this.child.transitionalPeriodDates.push(new DateRange());
        }
      }

      if (!this.child.isUnemployed) {
        this.child.removeAllUnemploymentDates();
      } else {
        if (!this.child.unemploymentDates.length) {
          this.child.unemploymentDates.push(new DateRange());
        }
      }

      if (!this.child.disabilityBefore25) {
        this.child.removeAllDisabilityBefore25Dates();
      } else {
        if (!this.child.disabilityBefore25Dates.length) {
          this.child.disabilityBefore25Dates.push(new DateRange());
        }
      }

      if (!this.isChildWasWorkingSectionActive) {
        this.child.haveJob = false;
        this.child.haveMinijobs = false;
        this.child.wasEmployed = false;
      }

      if (!this.child.haveJob) {
        this.child.haveMinijobs = false;
        this.child.wasEmployed = false;
      }

      if (!this.isMiniJobSectionActive) {
        this.child.removeAllMiniJobDates();
      } else {
        if (!this.child.minijobDates.length) {
          this.child.minijobDates.push(new DateRange());
        }
        if (!this.child.minijobNumberOfHours.length) {
          this.child.minijobNumberOfHours.push('');
        }
      }

      if (!this.isJobSectionActive) {
        this.child.removeAllEmployment();
      } else {
        if (!this.child.employmentDates.length) {
          this.child.employmentDates.push(new DateRange());
        }
        if (!this.child.employmentNumberOfHours.length) {
          this.child.employmentNumberOfHours.push('');
        }
      }
    } else {
      if (!this.child.hasDifferentAddress) {
        this.child.differentAddress = undefined;
      } else if (!this.child.differentAddress) {
        this.child.differentAddress = '';
      }
      if (this.child.livedWholeYear) {
        this.child.removeAllResidence();
      } else if (!this.child.residence.length)  {
        this.child.addResidence();
      }
      if (!this.child.secondParent) {
        this.child.secondParentFirstName = undefined;
        this.child.secondParentLastName = undefined;
        this.child.secondParentBirthDate = undefined;
        this.child.propinquityToSecondParent = undefined;
        this.child.lastKnownAddress = undefined;

        this.child.dontKnowAddress = false;
        this.child.isPropinquityPeriod = false;
        this.child.secondParentLivedAbroad = false;
        this.child.secondParentDied = false;
      } else {
        if (!this.child.secondParentFirstName) {
          this.child.secondParentFirstName = '';
        }
        if (!this.child.secondParentLastName) {
          this.child.secondParentLastName = '';
        }
        if (!this.child.secondParentBirthDate) {
          this.child.secondParentBirthDate = '';
        }
        if (!this.child.propinquityToSecondParent) {
          this.child.propinquityToSecondParent = '';
        }
        if (this.child.dontKnowAddress) {
          this.child.lastKnownAddress = undefined;
        } else if (!this.child.lastKnownAddress) {
          this.child.lastKnownAddress = '';
        }
      }
      if (!this.child.isPropinquityPeriod) {
        this.child.propinquityFrom = undefined;
      } else if (!this.child.propinquityFrom) {
        this.child.propinquityFrom = new DateRange();
      }
      if (!this.child.secondParentLivedAbroad) {
        this.child.secondParentLivedAbroadFrom = undefined;
      } else if (!this.child.secondParentLivedAbroadFrom) {
        this.child.secondParentLivedAbroadFrom = new DateRange();
      }
      if (!this.child.secondParentDied) {
        this.child.secondParentDeathDate = undefined;
      } else if (!this.child.secondParentDeathDate) {
        this.child.secondParentDeathDate = '';
      }
      if (this.child.kindergeldDefault) {
        this.child.kindergeldExtra = undefined;
      } else if (!this.child.kindergeldExtra) {
        this.child.kindergeldExtra = '';
      }
    }
  }

  public async removeAdultChildren(childToRemove: Child = null): Promise<any> {
    const adultChildrenIds = [];

    this.loading = true;

    if (childToRemove) {
      adultChildrenIds.push(childToRemove.id);
    } else {
      this.mainStatement.children.forEach(child => {
        if (child.isAdult(this.mainStatement.fullYear)) {
          adultChildrenIds.push(child.id);
        }
      });
    }

    await firstValueFrom(
      this.childrenService.deleteMultipleChildren(this.mainStatement.id, adultChildrenIds)
        .pipe(finalize(() => this.loading = false))
    );

    this.mainStatement.children = this.mainStatement.children
      .filter(child => adultChildrenIds.indexOf(child.id) === -1);

    if (!this.mainStatement.children.length) {
      this.loading = true;
      this.mainStatement.hasChildren = false;
      await firstValueFrom(
        this.statementService.updateStatement(this.mainStatement.id, this.mainStatement.hasChildrenPayload())
          .pipe(finalize(() => this.loading = false))
      );
    }
  }

  private getKindergeldAmount(nextCallback: (r: ChildKindergeld) => any = () => {}): void {
    this.isKindergeldLoading = true;
    this.childKindergeldService.getKindergeldAmount(this.mainStatement.id, this.child.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response: ChildKindergeld) => {
          this.child.kindergeld = response.amountFormatted;
          nextCallback(response);
        },
        error: () => {
          console.warn('Kindergeld default calculation error');
        },
        complete: () => {
          this.isKindergeldLoading = false;
        }
      });
  }

  private updateChildBirthDate(nextCallback = () => {}): void {
    this.isKindergeldLoading = true;
    
    this.childrenService.updateChild(this.mainStatement.id, this.child.id, {...this.child.payloadBirtDate(), identifikationsnummer: this.child.identifikationsnummer})
      .pipe(takeUntil(this.ngUnsubscribe), take(1))
      .subscribe({
        next: () => {
          this.initialBirthDate = this.child.birthDate;
          nextCallback();
        },
        error: () => {
          setTimeout(() => {
            this.isKindergeldLoading = false;
          }, 400);
        }
      });
  }
}
