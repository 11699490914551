import {Action} from '@ngrx/store';


export const UPDATE_REFUND_DETAILS = '[Refund details] Update refund details';
export const RESET_REFUND_DETAILS = '[Refund details] Reset refund details';

export class UpdateRefundDetailsAction implements Action {
  readonly type = UPDATE_REFUND_DETAILS;

  constructor() { }
}

export class ResetRefundDetailsAction implements Action {
  readonly type = RESET_REFUND_DETAILS;

  constructor() { }
}

export type Actions = UpdateRefundDetailsAction | ResetRefundDetailsAction;
