import {finalize, takeUntil} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {StatementService} from '../statement.service';
import {Statement} from '../statement';
import {getUser, State} from 'app/reducers';
import {SubscribingComponent} from 'app/common';
import {HideViewSpinnerAction, ShowViewSpinnerAction} from 'app/reducers/actions/loader';
import {WorkflowControllerService} from 'app/core/workflow/workflow-controller/workflow-controller.service';
import {AuthHttpService, ExtendPersonalInfo, UserDataService} from 'app/users';
import { TaxCardAddService } from 'app/common/tax-card-add.service';
import { TaxCardIncomeInfoService } from 'app/common/tax-card-income-info.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToolsBarButtonInterface } from 'app/shared/components/tools-bar/tools-bar.component';
import { ArrayHelper } from 'app/core/helpers/array.helper';
import { RecognizerHelper } from 'app/core/helpers/recognizer.helper';
import { DashboardPagePath } from 'app/core/workflow/page-path/dashboard-page.path';

enum TaxCardSteps {
  QUESTION_ABOUT_TAX_CARD = 'questionAboutPayslip',
  METHOD_OF_DATA_ENTRY = 'methodOfDataEntry',
  TAX_CARD_EDIT = 'taxCardEdit',
  TAX_CARDS_LIST = 'taxCardsList',
}

@Component({
  selector: 'app-tax-card',
  templateUrl: './tax-card.component.html',
  styleUrls: ['./tax-card.component.scss'],
  providers: [TaxCardAddService, TaxCardIncomeInfoService],
})
export class TaxCardComponent extends SubscribingComponent implements OnInit {
  public STEPS = TaxCardSteps;
  public currentStep: TaxCardSteps = this.STEPS.QUESTION_ABOUT_TAX_CARD;
  public buttons: ToolsBarButtonInterface[] = [];
  public isBusiness = false;
  public loading = false;
  public loadingStatementData = true;
  public loadingPersonalData = false;
  public loadingUserData = true;
  public statement: Statement;
  public currentStatement: Statement;
  public statementId: number;
  public spouseId: number;
  private _isInitialized = false;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    private authHttp: AuthHttpService,
    private userDataService: UserDataService,
    public workFlowController: WorkflowControllerService,
    public taxCardAddService: TaxCardAddService,
    public taxCardIncomeInfoService: TaxCardIncomeInfoService,
  ) {
    super();
  }

  public ngOnInit() {
    this.store.dispatch(new ShowViewSpinnerAction());

    this.store.select(getUser).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(user => {
        if (user) {
          this.isBusiness = user.isBusiness;
          this.setButtons();

          this.loadingUserData = false;
        }
      });

    this.route.params.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.statementId = parseInt(params['statementId'], 10);
        this.spouseId = parseInt(params['spouseId'], 10);
        this._isInitialized = false;

        if (this.statementId) {
          this.getStatementData();
        }
      });
  }

  public get isSpouse(): boolean {
    return !!this.spouseId || (this.currentStatement && this.currentStatement.isSpouse());
  }

  public get isInitialized(): boolean {
    return !this.loadingUserData && this._isInitialized;
  }

  public get isLoading(): boolean {
    return this.loading || this.loadingStatementData || this.loadingUserData || this.loadingPersonalData;
  }

  public updateEmployers(): void {
    this.taxCardIncomeInfoService.setPeriodOfEmployment();
    this.taxCardIncomeInfoService.formValidationErrors();
    if (this.taxCardIncomeInfoService.epValidator.length) {
      return;
    }

    this.taxCardIncomeInfoService.updateEmployer();
  }

  public editSelectedEmployer(_index: number): void {
    const currentEmployer = this.currentStatement.employers && this.currentStatement.employers[_index]
      ? this.currentStatement.employers[_index]
      : null;

    if (!currentEmployer) {
      return;
    }

    this.taxCardIncomeInfoService.currentEmployer = currentEmployer;
    this.taxCardIncomeInfoService.validatorIndex = _index;
    this.currentStep = this.STEPS.TAX_CARD_EDIT;
    this.setButtons();
  }

  public goToStepMethodOfDataEntry(): void {
    this.taxCardIncomeInfoService.currentEmployer = null;
    this.taxCardIncomeInfoService.validatorIndex = 0;
    this.currentStep = this.STEPS.METHOD_OF_DATA_ENTRY;
    this.setButtons();
  }

  private initServices(): void {
    this.taxCardIncomeInfoService._init(
      this.statement,
      this.currentStatement,
      this.ngUnsubscribe,
    );
    this.taxCardIncomeInfoService.removeEmployerCallbackEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.taxCardIncomeInfoService.formValidationWarnings();

        if (!this.currentStatement.employers.length) {
          this.currentStep = this.STEPS.QUESTION_ABOUT_TAX_CARD;
          this.setButtons();
          this.currentStatement.hasEmployers = false;
        }
      });
    this.taxCardIncomeInfoService.onAddEmployerCardEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.currentStep = this.STEPS.TAX_CARD_EDIT;
        this.setButtons();
      });

    this.taxCardAddService._init(
      this.statement,
      this.currentStatement,
      this.ngUnsubscribe,
    );
    this.taxCardAddService.onManuallyTaxCardAddedEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.taxCardIncomeInfoService.addNewEmployerCard();
      });
    this.taxCardAddService.removeEmployerEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: number) => {
        this.taxCardIncomeInfoService.removeEmployer(value);
      });
    this.taxCardAddService.statementCreatedEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.taxCardIncomeInfoService.clearErrors();
        this.currentStep = this.STEPS.TAX_CARDS_LIST;
        this.setButtons();
      });
    this.taxCardAddService.onOcrFailedEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.taxCardIncomeInfoService.validatorIndex = 0;
        this.taxCardIncomeInfoService.currentEmployer = null;
        this.currentStep = this.STEPS.METHOD_OF_DATA_ENTRY;
        this.setButtons();
      });
  }

  private getStatementData(): void {
    this.loadingStatementData = true;
    this.statementService.getStatement(this.statementId)
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
        this._isInitialized = true;
        this.store.dispatch(new HideViewSpinnerAction());
      }))
      .subscribe({
        next: (response: Statement) => {
          this.statement = response;
          this.currentStatement = this.isSpouse && this.statement.spouse ? this.statement.spouse : this.statement;

          this.workFlowController.init(this.statement);
          this.initServices();

          if (this.statement.canBeFilled) {
            this.loadingPersonalData = true;
            this.userDataService.getUserPersonalInfo().pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (extendPersonalInfo: ExtendPersonalInfo) => {
                  if (!this.isSpouse) {
                    this.taxCardIncomeInfoService.defaultTaxClass = extendPersonalInfo.applicantTaxClassId;
                  } else {
                    this.taxCardIncomeInfoService.defaultTaxClass = extendPersonalInfo.spouseTaxClassId;
                  }
                  this.loadingPersonalData = false;
                },
                error: (error) => {
                  console.error(error);
                  this.loadingPersonalData = false;
                }
              });
          }

          if (this.currentStatement.hasEmployers && ArrayHelper.isArrayAndNotEmpty(this.currentStatement.employers)) {
            this.currentStep = this.STEPS.TAX_CARDS_LIST;
            this.setButtons();
          }

          if (this.currentStep === this.STEPS.TAX_CARDS_LIST) {
            this.taxCardIncomeInfoService.formValidationWarnings();
          }

          this.loadingStatementData = false;
        },
        error: (error: Error) => {
          console.error(error);
          this.router.navigate(['/new']);
          this.loadingStatementData = false;
        }
      });
  }

  private goBack(): void {
    this.taxCardIncomeInfoService.clearErrors();

    switch (this.currentStep) {
      case this.STEPS.QUESTION_ABOUT_TAX_CARD:
        this.workFlowController.goToThePreviousStep();
        break;
      case this.STEPS.METHOD_OF_DATA_ENTRY:
        this.currentStep = this.STEPS.QUESTION_ABOUT_TAX_CARD;
        break;
      case this.STEPS.TAX_CARD_EDIT:
        if (!this.taxCardIncomeInfoService.isCurrentEmployerHasId) {
          this.taxCardIncomeInfoService.deleteSelectedEmployer(this.currentStatement.employers.length - 1);
        }
        if ((ArrayHelper.isArrayAndNotEmpty(this.currentStatement.employers))) {
          this.currentStep = this.STEPS.TAX_CARDS_LIST;
        } else {
          this.currentStep = this.STEPS.METHOD_OF_DATA_ENTRY;
        }
        break;
      case this.STEPS.TAX_CARDS_LIST:
        if (ArrayHelper.isArrayAndNotEmpty(this.currentStatement.employers)) {
          this.workFlowController.goToThePreviousStep();
        } else {
          this.currentStep = this.STEPS.METHOD_OF_DATA_ENTRY;
        }
        break;
    }

    this.taxCardIncomeInfoService.formValidationWarnings();
    this.setButtons();
  }

  private proceed(): void {
    switch (this.currentStep) {
      case this.STEPS.QUESTION_ABOUT_TAX_CARD:
        if (this.currentStatement.hasEmployers) {
          this.currentStep = this.STEPS.METHOD_OF_DATA_ENTRY;
        } else {
          this.loading = true;
          this.taxCardIncomeInfoService.updateHasEmployers(this.isSpouse).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: response => {
                Object.assign(this.statement, response);

                this.currentStatement.employers = [];
                this.workFlowController.goToTheNextStep();
              },
              error: (e) => {
                this.loading = false;
                console.error(e);
              }
            });
        }
        break;
      case this.STEPS.METHOD_OF_DATA_ENTRY:
        this.currentStep = this.STEPS.TAX_CARD_EDIT;
        break;
      case this.STEPS.TAX_CARD_EDIT:
        if (ArrayHelper.isArrayAndNotEmpty(this.currentStatement.employers)) {
          this.taxCardIncomeInfoService.formValidationWarnings();
          this.updateEmployers();
          if (!this.taxCardIncomeInfoService.epValidator.length) {
            this.currentStep = this.STEPS.TAX_CARDS_LIST;
          }
        } else {
          this.currentStep = this.STEPS.QUESTION_ABOUT_TAX_CARD;
          this.currentStatement.hasEmployers = false;
          this.taxCardIncomeInfoService.addTaxCardToStatement = null;
        }
        break;
      case this.STEPS.TAX_CARDS_LIST:
        if (ArrayHelper.isArrayAndNotEmpty(this.currentStatement.employers)) {
          this.taxCardIncomeInfoService.formValidationWarnings();

          this.loading = true;
          const id = this.currentStatement.id;
          const payload = this.currentStatement.employers.map(employer => employer.toResponse(null));
          this.statementService.updateEmployers(id, payload).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                const goNext = (statementResponse: Statement) => {
                  Object.assign(this.statement, statementResponse);
                  this.taxCardIncomeInfoService.onDataUpdateSuccess(true);
                  this.workFlowController.goToTheNextStep();
                };

                const handleErrors = (errors) => {
                  console.error(errors);
                  this.loading = false;
                };

                const toResponse = this.statement.stageEmployersToResponse(this.isSpouse);
                this.statementService.updateStatement(this.statement.id, toResponse)
                  .pipe(takeUntil(this.ngUnsubscribe)).subscribe({next: goNext, error: handleErrors});
              },
              error: (errors: any) => {
                this.loading = false;
                this.taxCardIncomeInfoService.handleErrors(errors.error);
              }
            });
        }
        break;
    }

    this.setButtons();
  }

  private get disableProceed(): boolean {
    if (this.taxCardIncomeInfoService.disableOnProceed) {
      return true;
    }

    if (this.currentStep === this.STEPS.QUESTION_ABOUT_TAX_CARD) {
      return !RecognizerHelper.isBoolean(this.taxCardIncomeInfoService.addTaxCardToStatement);
    }

    return false;
  }

  private get disableGoBack(): boolean {
    if (this.currentStep === this.STEPS.TAX_CARD_EDIT) {
      if (!this.taxCardIncomeInfoService.isCurrentEmployerHasId) {
        return false;
      }

      return ArrayHelper.isArrayAndNotEmpty(this.currentStatement.employers);
    }

    return false;
  }

  private setButtons(): void {
    let proceed: ToolsBarButtonInterface;
    let back: ToolsBarButtonInterface = {
      type: 'back',
      label: 'COMMON.BACK',
      action: this.goBack.bind(this),
      disabled: () => false,
    };

    if (!this.isSpouse) {
      switch (this.currentStep) {
        case TaxCardSteps.QUESTION_ABOUT_TAX_CARD:
          back = {
            type: 'back',
            label: 'COMMON.GO_TO_DASHBOARD',
            action: () => this.router.navigate([DashboardPagePath.fullUrl()]),
            disabled: () => false,
          };
          break;
        case TaxCardSteps.TAX_CARDS_LIST:
          back = null;
          break;
      }
    }

    if (this.currentStep !== this.STEPS.METHOD_OF_DATA_ENTRY) {
      proceed = {
        type: 'proceed',
        label: 'COMMON.PROCEED',
        action: this.proceed.bind(this),
        disabled: () => this.disableProceed
      };
      if (this.disableGoBack) {
        back = null;
      }
    }

    this.buttons = [];

    if (back) {
      this.buttons.push(back);
    }

    if (proceed) {
      this.buttons.push(proceed);
    }
  }
}
