import { finalize, takeUntil, take } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { StatementService } from '../statement.service';
import { SHOW_SNACKBAR } from '../../reducers/actions/snackbar';
import { SubscribingComponent, SnackbarConfig } from '../../common';
import { Statement } from '../statement';
import { SelectsDataInterface } from '../selects-data';
import { getSelectsData, State, getUserType, getUser } from '../../reducers';
import { UPDATE_REFUND_DETAILS } from '../../reducers/actions/refund-details';
import { Currency } from '../currency';
import { ProfileEditComponent } from '../../common/profile-edit/profile-edit.component';
import { HideViewSpinnerAction, ShowViewSpinnerAction } from '../../reducers/actions/loader';
import { FirebaseEventsService } from 'app/common/firebase-events.service';
import { FirebaseNotification } from 'app/firebase/notification';
import { User } from 'app/users';
import { FirebaseHttpService } from 'app/firebase/firebase.http.service';
import { StatementErrors } from 'app/modules/statement/models/statement/statement.errors.interface';
import { ErrorMessage } from 'app/core/form-validator/validatorInterface';
import { ValidateFieldService } from 'app/core/form-validator/validate-field.service';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';
import { DateHelper } from 'app/core/helpers/date.helper';

@Component({
  selector: 'app-choose-profile',
  templateUrl: './choose-profile.component.html',
  styleUrls: ['./choose-profile.component.scss']
})
export class ChooseProfileComponent extends SubscribingComponent implements OnInit, AfterViewInit {
  @ViewChild('error', { static: false }) errorContainer: ElementRef;
  @ViewChild('profileEditRef', { static: false }) profileEditRef: ProfileEditComponent;

  statementId: number;
  statement: Statement;
  loadingStatementData = true;
  selectsData: SelectsDataInterface;
  errors: StatementErrors;
  isBusiness = false;
  public currentStep = 1;
  public user_id;
  public contentLoaded = false;
  public epValidator: ErrorMessage[] = [];
  public buttons = [];

  constructor(
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>,
    private firebaseEventsService: FirebaseEventsService,
    private firebaseService: FirebaseHttpService,
    private validatorService: ValidateFieldService,
    public workFlowController: WorkflowControllerService
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new ShowViewSpinnerAction());

    this.store.select(getSelectsData)
      .subscribe(selectsData => {
        if (selectsData) {
          this.selectsData = selectsData;
        }
      });

    this.store.select(getUserType)
      .subscribe(userType => {
        if (userType) {
          this.isBusiness = userType === 'business';
        }
      });

    this.route.params.pipe(takeUntil(this.ngUnsubscribe), take(1))
      .subscribe(params => {
        this.statementId = parseInt(params['statementId'], 10);

        if (!this.statement && this.statementId) {
          this.getStatementData(this.statementId);
        }
      });
  }

  ngAfterViewInit() {
    this.setButtons();
  }

  isLoading() {
    return this.loadingStatementData;
  }

  goBack() {
    if (this.currentStep === 1) {
      this.save();
    }

    if (this.currentStep === 3) {
      if (this.statement.maritalStatus > 1) {
        this.currentStep = 2;
      } else {
        this.currentStep = 1;
      }
    } else if (this.currentStep === 2) {
      this.currentStep = 1;
    }
  }

  updateStatement(callbackOnSuccess: Function) {
    this.checkMaritalStatus();
    this.contentLoaded = false;
    this.statementService.updateStatement(this.statement.id, this.statement.chooseProfileToResponse())
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: Statement) => {
        Object.assign(this.statement, response);
        callbackOnSuccess();
        this.contentLoaded = true;
      }, (error: any) => {
        this.contentLoaded = true;
        this.errors = error.error;
        this.scrollToError();
      });
  }

  checkMaritalStatus() {
    const single = this.selectsData.maritalStatuses.find(item => item.name.toLowerCase() === 'single');
    if (single && this.statement.maritalStatus === single.id) {
      this.statement.abroadIncome.resetSpouseAbroadIncome();
    }

    if (!this.statement.abroadIncome.abroadIncomeCurrency) {
      const eur = Currency.findEUR(this.selectsData.currencies);
      this.statement.abroadIncome.abroadIncomeCurrency = eur ? eur.id : this.selectsData.currencies[0].id;
    }
  }

  proceed() {
    // this.formValidation();
    // console.log(this.epValidator);
    // if (this.epValidator.length > 0)
    //   return;

    if (this.currentStep === 3 || this.isBusiness) {
      this.firebaseEventsService.logEvent('click_section_child', { child: this.statement.hasChildren ? 'true' : 'false' }, this.statementId);
      this.updateStatement(() => {
        this.onDataUpdateSuccess(true, false, null);
      });

      return;
    }

    if (this.currentStep == 1) {
      // TAX-2569 marital_status_success
      const matrialStatus = this.getMaritalStatus() || 'single';
      this.firebaseEventsService.logEvent('marital_status_success', { matrial_status: matrialStatus }, this.statement.id);

      if (this.statement.maritalStatus !== 1) this.currentStep = 2;
      else this.currentStep = 3;
    }

    else if (this.currentStep == 2) {
      this.updateStatement(() => {
        this.currentStep = 3;
      });
    }
  }

  save(_goBackUrl: string = null) {
    this.updateStatement(() => {
      this.onDataUpdateSuccess(false, true, true);
    });
  }

  clearErrors() {
    this.errors = {};
  }

  public statementChangedEvent(_statement: Statement) {
    if (_statement) {
      Object.assign(this.statement, _statement);
    }
  }

  private getMaritalStatus(): string {
    if ( this.statement ) {

      if ( this.statement.maritalStatus === 1 ) return 'single';
      else if ( this.statement.relationshipStatus === 2 ) return 'marriage';
      else if ( this.statement.relationshipStatus === 3 ) return 'partnership';
      else if ( this.statement.relationshipStatus === 4 ) return 'widowed';
      else if ( this.statement.relationshipStatus === 5 ) return 'separated';
      else if ( this.statement.relationshipStatus === 6 ) return 'divorced';

    } else return null;
  }

  private getStatementData(id: number) {
    this.loadingStatementData = true;
    this.statementService.getStatement(id).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => {
        this.loadingStatementData = false;
        this.store.dispatch(new HideViewSpinnerAction());
        this.contentLoaded = true;
      }))
      .subscribe(
        (response: Statement) => {
          this.statement = response;
          this.workFlowController.init(this.statement);

          this.updateNotification();

        },
        () => this.router.navigate(['/new'])
      );
  }

  private onDataUpdateSuccess(goForward = false, goBack = false, updateRefund = false) {
    this.clearErrors();
    this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig('SNACKBAR.STATEMENT_UPDATED') });
    if (goForward) {
      this.contentLoaded = false;
      this.workFlowController.goToTheNextStep();
    }
    if (goBack) {
      this.contentLoaded = false;
      this.workFlowController.goToThePreviousStep();
    }
    if (updateRefund) {
      this.store.dispatch({ type: UPDATE_REFUND_DETAILS });
    }
  }

  private scrollToError() {
    if (this.errorContainer && this.errorContainer.nativeElement) {
      (this.errorContainer.nativeElement as HTMLElement).scrollIntoView();
    }
  }

  private updateNotification() {
    this.store.select(getUser).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          this.user_id = user.id;
        }
        const notification = new FirebaseNotification();
        notification.user = this.user_id;
        notification.notificationName = 'only_a_few_steps_more';
        notification.statement = this.statement.id;
        notification.eventTwoTriggered = true;

        this.firebaseService.udpateFirebaseNotification(notification)
          .subscribe(response => { }, error => {
            console.log(error);
          });
      });
  }

  private formValidation() {
    if (this.isBusiness) {
      this.epValidator = [];
      return;
    }

    this.epValidator = [];
    this.validatorService.currentYear = this.statement.fullYear;
    this.validatorService.clearErrorMessages();
    this.validatorService.index = 0;

    if (!this.statement)
      return;
    if (this.statement.maritalStatus == 1)
      return;

    if (this.statement.relationshipStatus == 2) {//marriage
      if (!this.validatorService.validateField('chose_profile_marriage_data', this.statement.dateOfMarriage)) {
        this.epValidator.push(this.validatorService.errorMessages);
      }
    } else if (this.statement.relationshipStatus == 3) {//partners
      if (!this.validatorService.validateField('chose_profile_partners_data', this.statement.dateOfPartnership))
        this.epValidator.push(this.validatorService.errorMessages);
    } else if (this.statement.relationshipStatus == 4) {//widowed
      // if (!this.validatorService.validateField('chose_profile_widower_start_date', this.statement.dateOfMarriage))
      //   this.epValidator.push(this.validatorService.errorMessages);
      if (!this.validatorService.validateField('chose_profile_widower_end_date', this.statement.dateOfWidowed))
        this.epValidator.push(this.validatorService.errorMessages);
    } else if (this.statement.relationshipStatus == 5) {//separation
      // if (!this.validatorService.validateField('chose_profile_separation_start_date', this.statement.dateOfMarriage))
      //   this.epValidator.push(this.validatorService.errorMessages);
      if (!this.validatorService.validateField('chose_profile_separation_end_date', this.statement.dateOfSeparation))
        this.epValidator.push(this.validatorService.errorMessages);
    } else if (this.statement.relationshipStatus == 6) {//date_of_divorce
      // if (!this.validatorService.validateField('chose_profile_divorce_start_date', this.statement.dateOfMarriage))
      //   this.epValidator.push(this.validatorService.errorMessages);
      if (!this.validatorService.validateField('chose_profile_divorce_end_date', this.statement.dateOfDivorce))
        this.epValidator.push(this.validatorService.errorMessages);
    }
  }

  private setButtons() {
    this.buttons = [
      {
        type: 'back',
        label: 'COMMON.BACK',
        action: this.goBack.bind(this),
      },
      // {
      //   type: 'save',
      //   label: 'COMMON.SAVE',
      //   action: this.save.bind(this),
      // },
      {
        type: 'proceed',
        label: 'COMMON.PROCEED',
        action: this.proceed.bind(this),
        disabled: () => {
          if (this.currentStep == 1) {
            return this.isStatusSelected();
          } else if (this.currentStep == 2) {
            return !this.isDateFormatsCorrectly();
          } else if (this.currentStep === 3) {
            return false;
          }
        },
      }
    ];
  }

  private isStatusSelected() {
    if (!this.statement.maritalStatus) {
      return true;
    }
    if (this.getMaritalStatus()) {
      return false;
    }
    // if (this.statement.maritalStatus === 1)
    //   return false;
    // if (this.statement.isMarried || this.statement.isWidowed || this.statement.isSeparated || this.statement.isDivorced) {
    //   return false;
    // }
    return true;
  }

  private get status(): number {
    const check = (value: any): boolean => !!value;

    if (check(this.statement.dateOfDivorce)) return 6;
    else if (check(this.statement.dateOfSeparation)) return 5;
    else if (check(this.statement.dateOfWidowed)) return 4;
    else if (check(this.statement.dateOfPartnership)) return 2;
    else if (check(this.statement.dateOfMarriage)) return 2;
    else return 1;
  }


  private isDateFormatsCorrectly(): boolean {
    if (!this.statement) {
      return false;
    }

    switch (this.status) {
      case 1: {
        return false;
      }
      case 2: {
        if (this.statement.dateOfMarriage)
          return ChooseProfileComponent.isCorrectDate(this.statement.dateOfMarriage);
        else if (this.statement.dateOfPartnership)
          return ChooseProfileComponent.isCorrectDate(this.statement.dateOfPartnership);
        else
          return false;
      }
      case 4: {
        return ChooseProfileComponent.isCorrectDate(this.statement.dateOfWidowed);
      }
      case 5: {
        return ChooseProfileComponent.isCorrectDate(this.statement.dateOfSeparation);
      }
      case 6: {
        return ChooseProfileComponent.isCorrectDate(this.statement.dateOfDivorce);
      }
      default: {
        return true;
      }
    }
  }

  private static isCorrectDate(date: string | Date) {
    if (typeof date != 'string')  {
      return DateHelper.getInstance(date).diff(DateHelper.getInstance()) < 0;
    } else if (date && DateHelper.getInstance(date, 'YYYY-MM-DD', true).isValid()) {
      return DateHelper.getInstance(date, 'YYYY-MM-DD', true).diff(DateHelper.getInstance()) < 0;
    }

    return false;
  }
}
