import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SubscribingComponent } from 'app/common/subscribing-component';
import { Statement } from 'app/statements/statement';
import { WorkflowStepsDialogComponent } from 'app/common/workflow-steps-dialog/workflow-steps-dialog.component';
import {WorkflowControllerService} from 'app/core/workflow/workflow-controller/workflow-controller.service';

@Component({
  selector: 'app-aside-bar',
  templateUrl: './aside-bar.component.html',
  styleUrls: ['./aside-bar.component.scss']
})
export class AsideBarComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  @Input() isLogged = true;
  @Input() isBusiness = false;
  @Input() isNested = false;
  @Input() workFlowController: WorkflowControllerService;
  @Input() showEvenWithoutStatement = true;
  @Output() changeIncomInfoStepEvent = new EventEmitter();

  constructor(
      private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
  }

  openDialog() {
    const dialogRef = this.dialog.open(WorkflowStepsDialogComponent, {
      panelClass: ['dialogbox_full_width_xs'],
    });
    dialogRef.componentInstance.statement = this.statement;
    dialogRef.componentInstance.isBusiness = this.isBusiness;
    dialogRef.componentInstance.isLogged = this.isLogged;
  }
}
