<!-- <div class="list-item-item row list-item"
    [ngClass]="{ 'list-item--opened': openedItems[index], 'list-item--ready': statement.isReady() }">
    <div class="list-item__row columns is-mobile">
        <div class="list-item__col column row__column">
            <div class="list-item__col-title" i18n>Id</div>
            <div class="list-item__col-value">{{ statement.id }}</div>
        </div>
        <div class="list-item__col column row__column">
            <div class="list-item__col-title" i18n>Date</div>
            <div class="list-item__col-value">{{ statement.createdDate }}</div>
        </div>
        <div class="list-item__col column row__column">
            <div class="list-item__col-title" i18n>Name</div>
            <div class="list-item__col-value">{{ statement.firstName }}</div>
        </div>
        <div class="list-item__col column row__column">
            <div class="list-item__col-title" i18n>Surname</div>
            <div class="list-item__col-value">{{ statement.lastName }}</div>
        </div>
        <div class="list-item__col column row__column">
            <div class="list-item__col-title" i18n>Steurer No.</div>
            <div class="list-item__col-value">{{ statement.steuernummer }}</div>
        </div>
        <div class="list-item__col column row__column">
            <div class="list-item__col-title" i18n>DOB</div>
            <div class="list-item__col-value">{{ statement.birthDate }}</div>
        </div>
    </div>
    <div class="list-item__row columns is-mobile">
        <div class="list-item__col column row__column">
            <div class="list-item__col-title" i18n>Tax Year</div>
            <div class="list-item__col-value">{{ statement.fullYear }}</div>
        </div>
        <div class="list-item__col column row__column">
            <div class="list-item__col-title" i18n>Status</div>
            <div class="list-item__col-value">{{ statement.statusText }}</div>
        </div>
        <div class="list-item__col list-item__col--refund column row__column">
            <div class="list-item__col-title" i18n>Refund/Tax (€)</div>
            <div class="list-item__col-value row__refund-column">
                <span class="row__refund-value">{{ getRefund() }}</span>
                <span class="icon is-small row__refund-icon" (click)="openRefundDetailsDialog()">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </span>
            </div>
        </div>
        <div class="list-item__col column row__column row__actions-column">
            <div class="list-item__col-title" i18n>Actions</div>
            <div class="list-item__col-value">
                <button class="btn btn--info btn--icon btn--small" title="Edit" i18n-title="Edit"
                    (click)="editStatement()" [disabled]="statement.isProcessing()"
                    *ngIf="!hideEditing && !statement.isSent() && statement.employers && statement.employers.length > 0">
                    <span class="icon is-small">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                    </span>
                </button>

                <button class="btn btn--info btn--icon btn--small" title="Download PDF" i18n-title="Download PDF"
                    [class.is-loading]="statement.downloadingPdf" [disabled]="statement.downloadingPdf"
                    *ngIf="!hidePdf && statement.canDownloadPdf" (click)="emitDownloadPdf()">
                    <span class="icon is-small">
                        <i class="fa fa-download" aria-hidden="true"></i>
                    </span>
                </button>

                <button class="btn btn--info btn--icon btn--small" title="DownloadInvoice" i18n-title="DownloadInvoice"
                    [class.is-loading]="statement.downloadingInvoice" [disabled]="statement.downloadingInvoice"
                    *ngIf="!hideInvoice && statement.isPaid" (click)="emitDownloadInvoice()">
                    <span class="icon is-small">
                        <i class="fa fa-euro" aria-hidden="true"></i>
                    </span>
                </button>

                <button class="btn btn--info btn--icon btn--small" title="Attach Ewr" i18n-title="Attach Ewr"
                    *ngIf="!statement.isSent() && statement.batchCode" (click)="uploadEWRAttatchment()">
                    <span class="icon is-small">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                    </span>
                </button>

                <button class="btn btn--info btn--icon btn--small" title="Delete statement"
                    i18n-title="Delete statement" [class.is-loading]="statement.deleting"
                    [disabled]="statement.deleting"
                    *ngIf="!(statement.isProcessing() || statement.isSent() || statement.isPaid || hideRemoving)"
                    (click)="emitDeleteStatement()">
                    <span class="icon is-small">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </span>
                </button>

                <button class="btn btn--info btn--icon btn--small" title="Show decision" i18n-title="Show decision"
                    *ngIf="!hideDecision && statement.isSent() && statement.canDownloadDecision"
                    (click)="emitShowDecision()">
                    <span class="icon is-small">
                        <i class="fa fa-institution" aria-hidden="true"></i>
                    </span>
                </button>
                <ng-container *ngIf="showEditButton">
                    <button class="btn btn--info btn--icon btn--small" (click)="emitImportStatement()" title="{{'CARD.IMPORT_STATEMENT' | translate}}">
                        <span class="icon is-small">
                            <i class="fa fa-file-o" aria-hidden="true"></i>
                        </span>
                    </button>
                </ng-container>
                <button class="btn btn--info btn--icon btn--small" (click)="emitCloneStatement()" title="{{'CARD.CLONE_STATEMENT' | translate}}">
                    <span class="icon is-small">
                        <i class="fa fa-clone" aria-hidden="true"></i>
                    </span>
                </button>
                <button class="btn btn--xsmall btn--info"
                    *ngIf="showEditButton && !statement.isSent() && !statement.isPaid"
                    (click)="emitOnEditButton()">{{ 'COMMON.EDIT' | translate }}</button>
            </div>
        </div>
        <div class="list-item__col column row__column row__actions-column">
            <app-labels-edit-batches [(statement)]="statement" [(labelList)]="labelList"
                *ngIf="labelList.length>0 && statement"></app-labels-edit-batches>
        </div>
    </div>
</div> -->

<div
  class="list-item-item row list-item"
  [ngClass]="{ 'list-item--opened': openedItems[index], 'list-item--ready': statement.isReady() }"
>
  <div class="list-item__col column row__column tax_year">
    <div class="list-item__col-title" i18n>Tax Year</div>
    <div class="list-item__col-value">
      <b>{{ statement.fullYear ? statement.fullYear : emptyCol }}</b>
    </div>
  </div>

  <div class="list-item__col column row__column name">
    <div class="list-item__col-title" i18n>Name</div>
    <div class="list-item__col-value">{{ statement.firstName ? statement.firstName : emptyCol }}</div>
  </div>

  <div class="list-item__col column row__column surname">
    <div class="list-item__col-title" i18n>Surname</div>
    <div class="list-item__col-value">{{ statement.lastName ? statement.lastName : emptyCol }}</div>
  </div>

  <div class="list-item__col column row__column id">
    <div class="list-item__col-title" i18n>Id</div>
    <div class="list-item__col-value">{{ statement.id ? statement.id : emptyCol }}</div>
  </div>

  <div class="list-item__col column row__column date">
    <div class="list-item__col-title" i18n>Date</div>
    <div class="list-item__col-value">{{ statement.createdDate ? statement.createdDate : emptyCol }}</div>
  </div>

  <div class="list-item__col list-item__col--refund column row__column refund_tax" (click)="openRefundDetailsDialog()">
    <div class="list-item__col-title" i18n>Refund/Tax (€)</div>
    <div class="list-item__col-value row__refund-column">
      <span
        class="row__refund-value"
        [class.red]="statement.estimatedRefund < 0"
        [class.green]="statement.estimatedRefund > 0"
        [class.grey]="
          statement.serviceType === 'tax_adviser' && (statement.status === 'paid' || statement.status === 'paid_ready')
        "
      >
        <b [id]="'statement_refund_tax_' + statement.id">{{ getRefund() }}</b>
      </span>
    </div>
  </div>

  <div class="list-item__col column row__column steuer_no">
    <div class="list-item__col-title" i18n>Steurer No.</div>
    <div class="list-item__col-value">{{ statement.steuernummer ? statement.steuernummer : emptyCol }}</div>
  </div>

  <div class="list-item__col column row__column dob">
    <div class="list-item__col-title" i18n>DOB</div>
    <div class="list-item__col-value">{{ statement.birthDate ? statement.birthDate : emptyCol }}</div>
  </div>

  <div class="list-item__col column row__column status">
    <div class="list-item__col-title" i18n>Status</div>
    <div class="list-item__col-value"
      ><b>{{ statement.statusText ? statement.statusText : emptyCol }}</b></div
    >
  </div>

  <!-- <div class="list-item__col column row__column attachments">
    <div class="list-item__col-title" i18n>Attachments</div>
    <div class="list-item__col-value">
      <button
        type="button"
        class="btn_att"
        (click)="showAttachments()"
        [id]="'statement_show_attachments_' + statement.id"
      >
        <span>{{ statement.numberOfAttachments }}</span>
        <img src="assets/svg/attachments.svg" />
      </button>
    </div>
  </div> -->

  <div class="list-item__col column row__column row__actions-column actions">
    <div class="list-item__col-title" i18n>Actions</div>
    <div class="list-item__col-value">
      <button type="button" class="btn_att" (click)="showDownloads()" *ngIf="statement.numberOfAttachments > 0">
        <span>{{ statement.numberOfAttachments }}</span>
        <img src="assets/svg/download.svg" class="download" />
      </button>

      <button
        class="btn btn--info btn--icon btn--small"
        title="Edit"
        i18n-title="Edit"
        [id]="'statement_edit_' + statement.id"
        (click)="editStatement()"
        [disabled]="statement.isProcessing()"
        *ngIf="!hideEditing && !statement.isSent()"
      >
        <img src="assets/svg/edit_icon.svg" />
      </button>

      <!-- <button
        class="btn btn--info btn--icon btn--small"
        title="Download PDF"
        i18n-title="Download PDF"
        [class.is-loading]="statement.downloadingPdf"
        [disabled]="statement.downloadingPdf"
        *ngIf="statement.canDownloadPdf"
        (click)="emitDownloadPdf()"
      >
        <img src="../../../assets/svg/download.svg" class="download_icon" alt="Download" />
      </button> -->

      <button
        class="btn btn--info btn--icon btn--small"
        title="DownloadInvoice"
        i18n-title="DownloadInvoice"
        [class.is-loading]="statement.downloadingInvoice"
        [disabled]="statement.downloadingInvoice"
        *ngIf="!hideInvoice && statement.isPaid"
        (click)="emitDownloadInvoice()"
      >
        <span class="icon is-small">
          <i class="fa fa-euro" aria-hidden="true"></i>
        </span>
      </button>

      <button
        class="btn btn--info btn--icon btn--small"
        title="Delete statement"
        i18n-title="Delete statement"
        [class.is-loading]="statement.deleting"
        [disabled]="statement.deleting"
        *ngIf="!(statement.isProcessing() || statement.isSent() || statement.isPaid || hideRemoving)"
        (click)="emitDeleteStatement()"
      >
        <img src="assets/svg/del_icon.svg" />
      </button>

      <ng-container *ngIf="showEditButton">
        <button
          class="btn btn--info btn--icon btn--small"
          (click)="emitImportStatement()"
          title="{{ 'CARD.IMPORT_STATEMENT' | translate }}"
        >
          <span class="icon is-small">
            <i class="fa fa-file-o" aria-hidden="true"></i>
          </span>
        </button>
      </ng-container>
      <ng-container *ngIf="showCloneButton">
        <button
          class="btn btn--info btn--icon btn--small clone"
          (click)="emitCloneStatement()"
          title="{{ 'CARD.CLONE_STATEMENT' | translate }}"
        >
          <img src="assets/svg/duplicate.svg" />
        </button>
      </ng-container>
      <button
        class="btn btn--xsmall btn--info"
        [attr.data-cy]="'btnEditStatementB2B'"
        [id]="'statement_edit_' + statement.id"
        *ngIf="showEditButton && !statement.isSent() && !statement.isPaid"
        (click)="emitOnEditButton()"
        >{{ "COMMON.EDIT" | translate }}</button
      >
    </div>
  </div>

  <div class="list-item__col column row__column row__actions-column labels" *ngIf="labelList.length > 0">
    <app-labels-edit-batches
      [(statement)]="statement"
      [(labelList)]="labelList"
      *ngIf="labelList.length > 0 && statement"
    ></app-labels-edit-batches>
  </div>

  <div class="list-item__col column row__column row__actions-column hamburger">
    <app-mini-hamburger-menu [template]="hamburgerElements" *ngIf="shouldShowHamburgerMenu"></app-mini-hamburger-menu>
  </div>
</div>

<ng-template #hamburgerElements>
  <button
    class="btn_icon hamburger-button"
    mat-button
    [class.is-loading]="statement.deleting"
    [disabled]="statement.deleting"
    (click)="openAppealDecisionDialogComponent()"
    *ngIf="displayMessageButtons()"
  >
    <span i18n>{{ "COMMON.SEND_APPEAL" | translate }}</span>
  </button>
  <button
    class="btn_icon hamburger-button"
    mat-button
    [class.is-loading]="statement.deleting"
    [disabled]="statement.deleting"
    (click)="openSendNewMessageDialogComponent()"
    *ngIf="displayMessageButtons()"
  >
    <span i18n>{{ "COMMON.SEND_MESSAGE" | translate }}</span>
  </button>
  <button
    class="btn_icon hamburger-button"
    mat-button
    [class.is-loading]="statement.deleting"
    [disabled]="statement.deleting"
    (click)="openSendAttachmentDialogComponent()"
    *ngIf="displayMessageButtons()"
  >
    <span i18n>{{ "COMMON.SEND_ATTACHMENT" | translate }}</span>
  </button>
  <button
    class="btn_icon hamburger-button"
    mat-button
    [class.is-loading]="statement.deleting"
    [disabled]="statement.deleting"
    (click)="openSendBellegeDialogComponent()"
    *ngIf="displayMessageBellege()"
  >
    <span i18n>{{ "COMMON.SEND_BELLEGE" | translate }}</span>
  </button>
  <button
    class="btn_icon hamburger-button"
    mat-button
    [class.is-loading]="statement.deleting"
    [disabled]="statement.deleting"
    (click)="openSendVollmachtDialogComponent()"
    *ngIf="isMasterAccount"
  >
    <span i18n>{{ "COMMON.SEND_VOLMACHT" | translate }}</span>
  </button>
</ng-template>
