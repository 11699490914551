<div class="single-field-edit"
     [class.employerSelectEdit]="employerSelectEdit"
     [class.employerEdit]="employerEdit"
     [class.moreBot]="moreBot"
     [class.save]="activeField === field"
     [class.toRight]="toRight"
     [class.offset]="offset"
     [class.offsetRight]="offsetRight"
     (click)="toggleField($event)"
>
    <div class="single-field-edit__button" *ngIf="activeField !== field">
        <img src="../../assets/svg/new_edit.svg" alt="edit" class="single-field-edit__ico" />
    </div>

    <div class="single-field-edit__button" *ngIf="activeField === field">
        <img src="../../assets/svg/new_save.svg" alt="edit" class="single-field-edit__ico-save" />
    </div>
</div>
