import { Component, Inject} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { SubscribingComponent } from 'app/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-bool-value-popup-confirm",
  templateUrl: "./bool-value-popup-confirm.html",
  styleUrls: ["./bool-value-popup-confirm.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule],
})
export class BoolValuePopupConfirm extends SubscribingComponent {

  constructor(
    public dialogRef: MatDialogRef<BoolValuePopupConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
  
}
