import { WorkflowDeductionApplicantSubBaseService } from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-applicant-sub-base.service';

export class WorkflowDeductionInfoHealthService extends WorkflowDeductionApplicantSubBaseService {
  public get title(): string {
    return 'ASIDE_BAR.APPLICANT_DEDUCTION_SUBLIST.HEALTH';
  }

  public get subStepNumber(): number {
    return 8;
  }
}
