import {Component, Input, Output, ElementRef, EventEmitter, ViewChild} from '@angular/core';
import { Statement } from 'app/statements';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {
  @Input() isCollapsed = false;
  @Input() isSmall = false;
  @Input() transparentBg = false;
  @Input() titleFrame = false;
  @Input() noPadding = false;
  @Input() title = '';
  @Input() postfix = '';
  @Input() prefix = '';
  @Input() isRemoveShown = true;
  @Input() isRemoveActive = true;
  @Input() translateTitle = true;
  @Input() showRemove = false;
  @Input() isVerification = false;
  @Input() showCurrIndex = false;
  @Input() currentIndex = 1;
  @Input() deleteLabel = '';
  @Input() transalteDelLabel = false;
  @Input() subTitle = '';
  @Input() preIndex: number|string = '';
  @Input() postIndex = '';
  @Input() postfixLabel = '';
  @Input() prefixLabel = '';
  @Input() noBg = false;
  @Input() showActiveNr = false;
  @Input() currActiveNr = 0;
  @Input() allActiveNr = 0;
  @Input() btnRemoveMargin = false;
  @Input() titleChildTranslate = false;
  @Input() isChildVerification = false;
  @Input() childFirstName = '';
  @Input() childLastName = '';
  @Output() removeClicked = new EventEmitter();
  @Output() toggle = new EventEmitter();
  @Input() showScanAlert: boolean;
  @Input() isBusiness: boolean;
  @Input() usedOCR: boolean;
  @Input() isEmployer = false;
  @Input() canCollapse = true;
  @Input() currentId: string = null;
  @Input() showHeader = true;
  @Input() contentClass = '';
  @Input() statement : Statement
  @ViewChild('accordionHeader', { static: false }) accordionHeader: ElementRef;

  constructor(public element: ElementRef) {}

  toggleCollapse() {
    if (this.canCollapse) {
      this.isCollapsed = !this.isCollapsed;
      if (!this.isCollapsed && this.accordionHeader !== undefined) {
        const timeout = setTimeout(() => {
          this.accordionHeader.nativeElement.scrollIntoView();
          clearTimeout(timeout);
        }, 100);
      }
      this.toggle.emit();
    }
  }

  open() {
    this.isCollapsed = false;
  }

  onRemoveClicked(event) {
    event.stopPropagation();
    if (this.isRemoveActive) {
      this.removeClicked.emit();
    }
  }
}
