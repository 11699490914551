import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { State } from "app/reducers";
import { StepsControllerService } from "app/core/workflow/steps-controller/steps-controller.service";
import { GroupSectionBaseAbstractComponent } from "app/shared/modules/group-section/components/group-section-base.abstract.component";

@Component({
  ...GroupSectionBaseAbstractComponent.metaData,
  selector: "app-group-section-edit-single-base",
  templateUrl: "./group-section-edit-single-base.component.html",
  styleUrls: ["./group-section-edit-single-base.component.scss"]
})
export class GroupSectionEditSingleBaseComponent extends GroupSectionBaseAbstractComponent implements OnInit {
  @Input() templateRef: TemplateRef<any>;
  @Input() templateRef2: TemplateRef<any>;
  @Input() overTitle: string;
  @Input() title: string;
  @Input() description: string;
  @Input() errorsNonField: string[];
  @Input() titleTooltip: string;
  @Input() messageTooltip: string;

  constructor(
    public dialog: MatDialog,
    public store: Store<State>,
    public stepsControllerService: StepsControllerService
  ) {
    super(dialog, store, stepsControllerService);
  }

  ngOnInit() {}
}
