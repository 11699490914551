import { captureException, init } from '@sentry/angular';
import { environment } from 'environments/environment';
import { version } from 'environments/version';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    if (environment.sentryDsn) {
      init({
        environment: environment.environment,
        dsn: environment.sentryDsn,
        release: version.shortSHA || '1.0.0',
        ignoreErrors: [
          'Non-Error exception captured',
          'Non-Error promise rejection captured',
        ]
        /**
         * PERFORMANCE MONITORING: Enable Tracing
         * import { Integrations } from '@sentry/tracing';
         *
         * integrations: [
         *  new Integrations.BrowserTracing({
         *    tracingOrigins: [environment.url, /^\//],
         *    routingInstrumentation: routingInstrumentation,
         *  }),
         * ],
         * tracesSampleRate: environment.environment === 'production' ? 0.2 : 1,
         */
      });
    }
  }

  public handleError(error: any): void {
    console.error(error.originalError || error.error || error);
    captureException(error.originalError || error.error || error);
  }
}
