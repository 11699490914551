import { Component, OnInit, Input, HostListener } from "@angular/core";

import { TABLET_SIZE, DESKTOP_SIZE, WIDESCREEN_SIZE } from "../settings";

export interface SelectButton {
  currentId?: string;
  name?: string;
  label: string;
  isSelected: Function;
  imageSrc: string;
  imageSrcAction?: Function;
  clickAction: Function;
  deleteAction?: Function;
  goToAction?: Function;
  message?: string;
  extenderActive?: boolean;
  textTop?: string;
  extenderMessage?: string;
  hidden?: boolean;
  hasError?: boolean;
  isLoading?: boolean | Function;
}

@Component({
  selector: "app-select-buttons",
  templateUrl: "./select-buttons.component.html",
  styleUrls: ["./select-buttons.component.scss"]
})
export class SelectButtonsComponent implements OnInit {
  @Input() buttons: SelectButton[] = [];
  @Input() buttonsData?: any;
  @Input() extraContainerClasses: string;
  usedColumnLength: number;
  inRow = 4;
  mit_sellect_button_style = true;

  constructor() {}

  ngOnInit() {
    this.checkSize();
  }

  @HostListener("window:resize")
  checkSize() {
    if (window.innerWidth < TABLET_SIZE) {
      this.inRow = 1;
    } else if (window.innerWidth >= DESKTOP_SIZE && window.innerWidth < WIDESCREEN_SIZE) {
      this.inRow = 2;
    } else {
      this.inRow = 4;
    }
  }

  isMarginOpenedInRow(index: number) {
    if (this.inRow === 1) {
      return this.buttons[index].extenderActive;
    }

    const startRow = Math.floor(index / this.inRow);
    const start = startRow * this.inRow;
    const end = start + this.inRow > this.buttons.length ? this.buttons.length : start + this.inRow;

    for (let i = start; i < end; ++i) {
      if (this.buttons[i].extenderActive) {
        return true;
      }
    }

    return false;
  }
}
