export class SmartAppBannerConfig {
  public static readonly author = 'Taxando GmbH';
  public static readonly ratingIos = 4.9;
  public static readonly ratingAndroid = 4.9;
  public static readonly icon = 'assets/img/pwa/logo-512x512.png';
  public static readonly daysHidden = 10;
  public static readonly daysReminder = 30;
  public static readonly urlIos = 'https://apps.apple.com/pl/app/taxando-zwrot-podatku/id1445008039';
  public static readonly urlAndroid = 'https://play.google.com/store/apps/details?id=pl.dlabs.taxando.android';
}
