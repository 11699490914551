export class TaxCardSingleRow {
  public title: string;
  public labelBold: boolean;
  public inputLabel: string;
  public inputPlaceholder: string;
  public inputIsDisabled: Function;
  public inputSummaryLabel: string;
  public taxCardKey: string;
  public errors: string[];
  public validationErrorFieldNames: string[];
  public validationWarningFieldNames: string[];

  constructor(
    private _title?: string,
    private _labelBold?: boolean,
    private _inputLabel?: string,
    private _inputPlaceholder?: string,
    private _inputIsDisabled?: Function,
    private _inputSummaryLabel?: string,
    private _taxCardKey?: string,
    private _errors?: string[],
    private _validationErrorFieldNames?: string[],
    private _validationWarningFieldNames?: string[],
  ) {
    this.title = _title || '';
    this.labelBold = _labelBold || false;
    this.inputLabel = _inputLabel || '';
    this.inputPlaceholder = _inputPlaceholder || '';
    this.inputIsDisabled = _inputIsDisabled || (() => {});
    this.inputSummaryLabel = _inputSummaryLabel || '';
    this.taxCardKey = _taxCardKey || '';
    this.errors = _errors || [];
    this.validationErrorFieldNames = _validationErrorFieldNames || [];
    this.validationWarningFieldNames = _validationWarningFieldNames || [];
  }
}
