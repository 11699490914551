<div class="dialog-box" [attr.data-cy]="'popupOnfindo'">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content onfido">
        <div class="onfido__left">
            <img src="assets/svg/logo_sm.svg">
            <div class="onfido__title">
                <h1>{{ 'ONFIDO.CONFIRM_DIALOG_TITLE' | translate }}</h1>
                <img src="assets/img/own_cert.png">
            </div>
            <div class="onfido__message">{{ 'ONFIDO.CONFIRM_DIALOG_MESSAGE' | translate }}</div>
        </div>
        <div class="onfido__right">
            <button class="btn btn-dark" (click)="initOnfido()">
                {{ 'COMMON.AGREE' | translate }}
                <img src="assets/img/forward.png">
            </button>
        </div>
    </div>
</div>
