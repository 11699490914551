import { LanguageInterface } from 'app/core/models/language.interface';

export class LanguagesConfig {
  public static get languagesList(): LanguageInterface[] {
    return [
      {
        title: 'DE',
        code: 'de',
        originalFullName: 'Deutsch',
        translatedFullNameTransKey: 'LANGUAGES.DE',
      },
      {
        title: 'EN',
        code: 'en',
        originalFullName: 'English',
        translatedFullNameTransKey: 'LANGUAGES.EN',
      },
      {
        title: 'PL',
        code: 'pl',
        originalFullName: 'Polski',
        translatedFullNameTransKey: 'LANGUAGES.PL',
      },
      {
        title: 'RU',
        code: 'ru',
        originalFullName: 'Русский',
        translatedFullNameTransKey: 'LANGUAGES.RU',
      },
      {
        title: 'IT',
        code: 'it',
        originalFullName: 'Italiano',
        translatedFullNameTransKey: 'LANGUAGES.IT',
      },
      {
        title: 'BG',
        code: 'bg',
        originalFullName: 'Български',
        translatedFullNameTransKey: 'LANGUAGES.BG',
      },
      {
        title: 'RO',
        code: 'ro',
        originalFullName: 'Română',
        translatedFullNameTransKey: 'LANGUAGES.RO',
      },
      {
        title: 'UA',
        code: 'ua',
        originalFullName: 'Українська',
        translatedFullNameTransKey: 'LANGUAGES.UA',
      }
    ];
  }
}
