<div class="abroad-income-edit">
    <!-- FIRST ABROAD INCOME -->
    <div class="ep_header" *ngIf="!isSpouse" [class.isCollapse]="!coolapseAbroadIncome"
        (click)="toggleCollapseAbroad()">
        <div class="left_item">
            <h2 class="ep_title">
                1. {{ 'ABROAD_INCOME.ABROAD_INCOME_LABEL' | translate }}
            </h2>
            <h3 class="ep_sub" translate="ABROAD_INCOME.ABROAD_INCOME_SUBLABEL"></h3>
        </div>
        <div class="right_item">
            <img src="../../../assets/svg/minus_accordion.svg" class="icon_up">
            <img src="../../../assets/svg/plus_accordion.svg" class="icon_down">
        </div>
    </div>

    <ng-container *ngIf="coolapseAbroadIncome">
        <div class="ep_new_tiles_wrap top">

            <div class="ep_new_single_tile_wrap" *ngIf="!isSpouse">
                <div class="ep_new_single_tile top" [ngClass]="isBusiness ? 's_abr_inc_la' : ''"
                    [class.active]="statement.abroadIncome.liveAbroad" (click)="updateLiveAbroad()">
                    <div class="delete_icon">
                        <img src="../../../assets/svg/delete_simply_gray_new.svg" />
                    </div>
                    <div class="ep_desc_wrap" [attr.data-cy]="'abroadIncome'">
                        <div class="icon_tile_wrap">
                            <img src="../../../assets/svg/income_from_abroad/taxpayer.svg" class="icon_tile custom">
                        </div>
                        <h4 class="title_tile_sm">
                            {{ 'INPUT.LIVE_ABROAD.LABEL' | translate:translationsValue }}
                        </h4>
                    </div>
                </div>
            </div>

            <div class="ep_new_single_tile_wrap" *ngIf="statement.relationshipStatus !== 1 && statement.filedTogether">
                <div class="ep_new_single_tile top" [ngClass]="isBusiness ? 's_abr_inc_spa' : ''"
                    [class.active]="statement.abroadIncome.spouseLiveAbroad" (click)="updateSpouseLiveAbroad()">
                    <div class="delete_icon">
                        <img src="../../../assets/svg/delete_simply_gray_new.svg" />
                    </div>
                    <div class="ep_desc_wrap">
                        <div class="icon_tile_wrap">
                            <img src="../../../assets/svg/income_from_abroad/partner.svg" class="icon_tile custom">
                        </div>
                        <h4 class="title_tile_sm">
                            {{ 'INPUT.SPOUSE_LIVE_ABROAD.LABEL' | translate:translationsValue }}
                        </h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="date-inputs" *ngIf="showFieldsInputs()">
            <div class="title-section">
                <h3 class="font-size-24 font-weight-bold mb-1">{{ 'ABROAD_INCOME.STEP_3.EMPLOYE_PERIOD_TITLE' | translate: {year: statement.fullYear} }}
                </h3>
                <app-more-info-tooltip [message]="'TOOLTIP.ABROAD_INCOME_EMPLOYER_PERIOD'"></app-more-info-tooltip>
            </div>
            <p class="subdesc mb-2">{{ 'ABROAD_INCOME.STEP_3.EMPLOYE_PERIOD_TEXT' | translate }}</p>
            <!-- <app-form-list [itemTitle]="''" [formTemplateRef]="emploterDateForm" [list]="statement.abroadIncome.period_of_employment"
                [isAddButtonDisabled]="false" (removeButtonClickEmitter)="removeEmployerDate($event)"
                (addButtonClickEmitter)="addEmployerDate()"></app-form-list>
            <ng-template #emploterDateForm let-item="item" let-index="index"> -->                
            <!-- </ng-template> -->

            <div class="residency-counter">
                <div class="days">
                    <div class="icon"></div>
                    <span>{{abroadIncomeStatement.days_employed}}</span>
                </div>
            </div>

            <div class="employer-periods" *ngIf="abroadIncomeStatement.period_of_employment.length > 0">
                <ul class="employer-periods__list">
                    <li *ngFor="let period of abroadIncomeStatement.period_of_employment; index as i">
                        <span>{{i+1}}. {{period.lower}} - {{period.upper}}</span><span class="is-bold" (click)="editEmployerDate(i)"> {{
                            'COMMON.EDIT' | translate }}</span>
                        <app-button-delete [withText]="true" [autoFocus]="false"
                            (clickEmitter)="removeEmployerDate(i)">
                            {{ 'COMMON.REMOVE' | translate }}
                        </app-button-delete>
                    </li>
                </ul>
            </div>

            <div class="abroad-income-edit__section" *ngIf="statement.abroadIncome.days_employed == 0">
                <app-validator-error-message [epValidator]="epValidator" [index]="0" [fieldName]="'period_of_employments'">
                </app-validator-error-message>
                <div  [ngClass]="orderForEmployerPeriod ? 'deductions-info error is_icon'  : 'deductions-info warning is_icon'">
                    <div class="deductions-info__wrap top">
                        <div class="deductions-info__toltip-wrap alert_wrap">
                            <img src="../../../../../../assets/svg/warning-icon.svg" class="alert_icon">
                        </div>
                        <div class="deductions-info__wrap-text">
                            <p class="deductions-info__text">
                                {{ 'ABROAD_INCOME.STEP_3.PERIOD_INFO' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="date-inputs__box">
                <app-button-add (clickEmitter)="createEmployerDate()" [title]="'PERIOD.ADD'">
                </app-button-add>
                <div class="date-inputs__form" *ngIf="showPeriodForm">
                    <div class="inputs">
                        <div class="form-input">
                            <app-form-date-input [(model)]="employerDateFormValue.lower"
                                [noYear]="true" [placeholder]="'INPUT.PERIOD_OF_EMPLOYMENT_LOWER.PLACEHOLDER'"
                                (modelChange)="countResidencyDays()">
                            </app-form-date-input>
                            <app-validator-error-message [epValidator]="epValidator" [index]="0"
                                [fieldName]="'abroad_income_period_lower'">
                            </app-validator-error-message>
                        </div>
                        <div class="date-inputs__delimiter"></div>
                        <div class="form-input">
                            <app-form-date-input [(model)]="employerDateFormValue.upper"
                                [noYear]="true" [placeholder]="'INPUT.PERIOD_OF_EMPLOYMENT_UPPER.PLACEHOLDER'"
                                (modelChange)="countResidencyDays()">
                            </app-form-date-input>
                            <app-validator-error-message [epValidator]="epValidator" [index]="0"
                                [fieldName]="'abroad_income_period_upper'">
                            </app-validator-error-message>
                        </div>
                    </div>
                    <button class="date-inputs__action-button" (click)="periodFormType == 'edit' ? updateEmployerDate() : addEmployerDate()">OK</button>
                </div>
                
            </div>
        </div>

        <div class="ep_form_wrap" *ngIf="!isSpouse && shouldShowAdditionalQuestions()">
            <div class="title-section">
                <h3 class="font-size-24 font-weight-bold mb-1">{{ 'ABROAD_INCOME.INFO_TITLE' | translate }}</h3>
                <app-more-info-tooltip [message]="'TOOLTIP.ABROAD_INCOME_FORM'"></app-more-info-tooltip>
            </div>
            <div class="form-container" *ngIf="!isResident; else residentAplicantTemplate">
                <!-- <div class="form_activities_header">
                    <div class="form_title">
                        {{ nrLiveAbroad }}. <span>{{ 'ABROAD_INCOME.INFO_TITLE' | translate }}</span>
                    </div>
                    <div class="form_remove">
                        <span class="btn_remove" (click)="updateLiveAbroad()">
                            {{ 'COMMON.REMOVE' | translate }}
                        </span>
                    </div>
                </div> -->
                <div class="abroad-income-edit__row columns">
                    <div class="abroad-income-edit__item column">
                        <app-form-select [errors]="errors?.abroad_income_currency"
                            [label]="'SELECT.ABROAD_INCOME_CURRENCY.LABEL'"
                            [placeholder]="'SELECT.ABROAD_INCOME_CURRENCY.PLACEHOLDER'"
                            [(model)]="statement.abroadIncome.abroadIncomeCurrency" (modelChange)="setCurrencyCode()"
                            [choices]="dataSelects.currencies" [valueName]="'id'" [optionName]="'code'"
                            [dataCyName]="'selectIncomeCurrency'"></app-form-select>
                        <app-validator-error-message [epValidator]="epValidator" [index]="0"
                            [fieldName]="'ai_currency'">
                        </app-validator-error-message>
                    </div>
                    <div class="abroad-income-edit__item column">
                        <app-country-field [label]="'INPUT.ABROAD_INCOME_COUNTRY.LABEL'"
                            [errors]="errors?.abroad_income_country"
                            [(model)]="statement.abroadIncome.abroadIncomeCountry"
                            [countries]="dataSelects.abroadIncomeCountries"
                            [attr.data-cy]="'selectIncomeCountry'"></app-country-field>
                        <app-validator-error-message [epValidator]="epValidator" [index]="0" [fieldName]="'ai_country'">
                        </app-validator-error-message>
                    </div>
                </div>
                <div class="abroad-income-edit__row columns">
                    <div class="abroad-income-edit__item column">
                        <app-form-input [label]="'INPUT.ABROAD_INCOME.LABEL'" [errors]="errors?.abroad_income"
                            [currencyCode]="currencyCode" [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                            [isCurrency]="true" [(model)]="statement.abroadIncome.abroadIncome"></app-form-input>
                        <app-validator-error-message [epValidator]="epValidator" [index]="0" [fieldName]="'ai_income'">
                        </app-validator-error-message>
                    </div>
                    <div class="abroad-income-edit__item column"></div>
                </div>

            </div>

            <ng-template #residentAplicantTemplate>
                <app-form-list [itemTitle]="''" [formTemplateRef]="residentAplicantTemplateForm" [list]="getResidentAbroadIncome()"
                    [isAddButtonDisabled]="false" (removeButtonClickEmitter)="removeResidenAbroadIncome($event)"
                    (addButtonClickEmitter)="addResidenAbroadIncome()" [addButtonTitle]="'ABROAD_INCOME.STEP_3.FORM_ADD_BUTTON'"></app-form-list>
            </ng-template>

            <ng-template #residentAplicantTemplateForm let-item="item" let-index="index">
                <div class="form-container">
                  <div class="abroad-income-edit__row columns">
                    <div class="abroad-income-edit__item column">
                      <app-form-select
                        [errors]="errors?.abroad_income?.abroad_income_currency"
                        [label]="'SELECT.ABROAD_INCOME_CURRENCY.LABEL'"
                        [placeholder]="'SELECT.ABROAD_INCOME_CURRENCY.PLACEHOLDER'"
                        [(model)]="item.abroad_income_currency"
                        (modelChange)="setCurrencyCode()"
                        [choices]="dataSelects.currencies" [valueName]="'id'"
                        [optionName]="'code'"
                        [dataCyName]="'selectIncomeCurrency'"
                      ></app-form-select>
                      <app-validator-error-message [epValidator]="epValidator" [index]="index" [fieldName]="'ai_currency'">
                      </app-validator-error-message>
                    </div>
                    <div class="abroad-income-edit__item column">
                      <app-country-field
                        [label]="'INPUT.ABROAD_INCOME_COUNTRY.LABEL'"
                        [errors]="errors?.abroad_income?.abroad_income_country"
                        [(model)]="item.abroad_income_country"
                        [countries]="dataSelects.abroadIncomeCountries"
                        [attr.data-cy]="'selectIncomeCountry'"
                      ></app-country-field>
                      <app-validator-error-message [epValidator]="epValidator" [index]="index" [fieldName]="'ai_country'">
                      </app-validator-error-message>
                    </div>
                  </div>
                  <div class="abroad-income-edit__row columns">
                    <div class="abroad-income-edit__item column">
                      <app-form-input
                        [label]="'INPUT.ABROAD_INCOME.LABEL'"
                        [errors]="errors?.abroad_income?.abroad_income"
                        [currencyCode]="setAdditionalIncomeCurrencyCode(item)" [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                        [isCurrency]="true"
                        [(model)]="item.abroad_income"
                        [attr.data-cy]="'inputAbroadIncome'"
                      ></app-form-input>
                      <app-validator-error-message [epValidator]="epValidator" [index]="index" [fieldName]="'ai_income'">
                      </app-validator-error-message>
                    </div>
                    <div class="abroad-income-edit__item column">
                      <app-form-select
                        [errors]="errors?.abroad_income?.income_type"
                        [label]="'SELECT.ABROAD_INCOME_TYPE.LABEL'"
                        [placeholder]="'SELECT.ABROAD_INCOME_TYPE.PLACEHOLDER'"
                        [(model)]="item.income_type"
                        [choices]="dataSelects.incomeTypes" [valueName]="0"
                        [optionName]="1"
                        [dataCyName]="'selectIncomeCurrency'"
                      ></app-form-select>
                      <app-validator-error-message [epValidator]="epValidator" [index]="index" [fieldName]="'ai_income_type'">
                      </app-validator-error-message>
                    </div>
                  </div>
                </div>
              </ng-template>
        </div>

        <ng-container *ngIf="shouldShowSpouseAdditionalQuestions()">
            <div class="ep_form_wrap">
                <h3 class="font-size-24 font-weight-bold mb-1">{{ 'ABROAD_INCOME.SPOUSE_INFO_TITLE' | translate }}</h3>
                <div class="form-container" *ngIf="!isResident; else residentSpouseTemplate">
                    <!-- <div class="form_activities_header">
                    <div class="form_title">
                        {{ nrSpouse }}. <span>{{ 'ABROAD_INCOME.SPOUSE_INFO_TITLE' | translate }}</span>
                    </div>
                    <div class="form_remove">
                        <span class="btn_remove" (click)="updateSpouseLiveAbroad()">
                            {{ 'COMMON.REMOVE' | translate }}
                        </span>
                    </div>
                    </div> -->

                    <div class="abroad-income-edit__row columns">
                        <div class="abroad-income-edit__item column">
                            <app-form-select [errors]="errors?.spouse_abroad_income_currency"
                                [label]="'SELECT.ABROAD_INCOME_SPOUSE_CURRENCY.LABEL'"
                                [placeholder]="'SELECT.ABROAD_INCOME_SPOUSE_CURRENCY.PLACEHOLDER'"
                                [(model)]="statement.abroadIncome.spouseAbroadIncomeCurrency"
                                (modelChange)="setSpouseCurrencyCode()" [choices]="dataSelects.currencies"
                                [valueName]="'id'" [optionName]="'code'"></app-form-select>
                            <app-validator-error-message [epValidator]="epValidator" [index]="0"
                                [fieldName]="'ais_currency'">
                            </app-validator-error-message>
                        </div>
                        <div class="abroad-income-edit__item column">
                            <app-country-field [label]="'INPUT.SPOUSE_ABROAD_INCOME_COUNTRY.LABEL'"
                                [errors]="errors?.spouse_abroad_income_country"
                                [(model)]="statement.abroadIncome.spouseAbroadIncomeCountry"
                                [countries]="dataSelects.abroadIncomeCountries"
                                [attr.data-cy]="'selectIncomeCountrySpouse'"></app-country-field>
                            <app-validator-error-message [epValidator]="epValidator" [index]="0"
                                [fieldName]="'ais_country'">
                            </app-validator-error-message>
                        </div>
                    </div>
                    <div class="abroad-income-edit__row columns">
                        <div class="abroad-income-edit__item column">
                            <app-form-input [label]="'INPUT.SPOUSE_ABROAD_INCOME.LABEL'"
                                [errors]="errors?.spouse_abroad_income" [currencyCode]="spouseCurrencyCode"
                                [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'" [isCurrency]="true"
                                [(model)]="statement.abroadIncome.spouseAbroadIncome"></app-form-input>
                            <app-validator-error-message [epValidator]="epValidator" [index]="0"
                                [fieldName]="'ais_income'">
                            </app-validator-error-message>
                        </div>
                        <div class="abroad-income-edit__item column"></div>
                    </div>
                </div>
            </div>
            <div class="abroad-income-edit__section" *ngIf="!isResident && shouldShowAdditionalQuestions()">
                <div class="deductions-info warning is_icon">
                    <div class="deductions-info__wrap top">
                        <div class="deductions-info__toltip-wrap alert_wrap">
                            <img src="../../../../../../assets/svg/warning-icon.svg" class="alert_icon">
                        </div>
                        <div class="deductions-info__wrap-text">
                            <p class="deductions-info__text-attention ">
                                {{'ABROAD_INCOME.ATTENTION' | translate}}
                            </p>
                            <p class="deductions-info__text">
                                {{'ABROAD_INCOME.FILE_TOGETHER' | translate}}
                            </p>
                            <div class="deductions-info__input">
                                <app-form-input [label]="'ABROAD_INCOME.SPOUSE_SUMMARY_INCOME'"
                                    [errors]="errors?.spouse_calc_income" [isCurrency]="true"
                                    [currencyCode]="spouseCurrencyCode" [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                                    [(model)]="statement.spouseCalcIncome" [dataCyName]="'inputAbroadIncomeValue'">
                                </app-form-input>
                                <app-validator-error-message [epValidator]="epValidator" [index]="0"
                                    [fieldName]="'ais_gr_income'">
                                </app-validator-error-message>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #residentSpouseTemplate>
                <app-form-list [itemTitle]="''" [formTemplateRef]="residentSpouseAplicantTemplateForm"
                    [list]="getResidentSpouseAbroadIncome()" [isAddButtonDisabled]="false"
                    (removeButtonClickEmitter)="removeResidenSpouseAbroadIncome($event)"
                    (addButtonClickEmitter)="addSpouseResidenAbroadIncome()" [addButtonTitle]="'ABROAD_INCOME.STEP_3.FORM_ADD_BUTTON'"></app-form-list>
            </ng-template>

            <ng-template #residentSpouseAplicantTemplateForm let-item="item" let-index="index">
                <div class="form-container">
                  <div class="abroad-income-edit__row columns">
                    <div class="abroad-income-edit__item column">
                      <app-form-select
                        [errors]="errors?.abroad_income?.abroad_income_currency"
                        [label]="'SELECT.ABROAD_INCOME_CURRENCY.LABEL'"
                        [placeholder]="'SELECT.ABROAD_INCOME_CURRENCY.PLACEHOLDER'"
                        [(model)]="item.abroad_income_currency"
                        (modelChange)="setCurrencyCode()"
                        [choices]="dataSelects.currencies" [valueName]="'id'"
                        [optionName]="'code'"
                        [dataCyName]="'selectIncomeCurrency'"
                      ></app-form-select>
                      <app-validator-error-message [epValidator]="epValidator" [index]="index" [fieldName]="'ais_currency'">
                      </app-validator-error-message>
                    </div>
                    <div class="abroad-income-edit__item column">
                      <app-country-field
                        [label]="'INPUT.ABROAD_INCOME_COUNTRY.LABEL'"
                        [errors]="errors?.abroad_income?.abroad_income_country"
                        [(model)]="item.abroad_income_country"
                        [countries]="dataSelects.abroadIncomeCountries"
                        [attr.data-cy]="'selectIncomeCountry'"
                      ></app-country-field>
                      <app-validator-error-message [epValidator]="epValidator" [index]="index" [fieldName]="'ais_country'">
                      </app-validator-error-message>
                    </div>
                  </div>
                  <div class="abroad-income-edit__row columns">
                    <div class="abroad-income-edit__item column">
                      <app-form-input
                        [label]="'INPUT.ABROAD_INCOME.LABEL'"
                        [errors]="errors?.abroad_income?.abroad_income"
                        [currencyCode]="setAdditionalIncomeCurrencyCode(item)" [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                        [isCurrency]="true"
                        [(model)]="item.abroad_income"
                        [attr.data-cy]="'inputAbroadIncome'"
                      ></app-form-input>
                      <app-validator-error-message [epValidator]="epValidator" [index]="index" [fieldName]="'ais_income'">
                      </app-validator-error-message>
                    </div>
                    <div class="abroad-income-edit__item column">
                      <app-form-select
                        [errors]="errors?.abroad_income?.income_type"
                        [label]="'SELECT.ABROAD_INCOME_TYPE.LABEL'"
                        [placeholder]="'SELECT.ABROAD_INCOME_TYPE.PLACEHOLDER'"
                        [(model)]="item.income_type"
                        [choices]="dataSelects.incomeTypes" [valueName]="0"
                        [optionName]="1"
                        [dataCyName]="'selectIncomeCurrency'"
                      ></app-form-select>
                      <app-validator-error-message [epValidator]="epValidator" [index]="index" [fieldName]="'ais_income_type'">
                      </app-validator-error-message>
                    </div>
                  </div>
                </div>
              </ng-template>
        </ng-container>
        <div class="abroad-income-edit__section" *ngIf="!isResident && showEogAplicationInfo() && canApplyForTaxResidencyStatus();">
            <div class="deductions-info warning is_icon">
              <div class="deductions-info__wrap top">
                <div class="deductions-info__toltip-wrap alert_wrap">
                  <img src="../../../../../../assets/svg/warning-icon.svg" class="alert_icon">
                </div>
                <div class="deductions-info__wrap-text" >
                  <p class="deductions-info__text">
                    {{ 'ABROAD_INCOME.STEP_3.NO_RESIDENCY_INFO' | translate }}
                  </p>                  
                </div>
              </div>
            </div>
        </div>
        <div class="abroad-income-edit__section" *ngIf="!isResident && canApplyForTaxResidencyStatus() && showEogAplicationInfo()">
            <app-validator-error-message [epValidator]="epValidator" [index]="0"
                                    [fieldName]="'eog_application'">
                                </app-validator-error-message>
            <div [ngClass]="orderApplyForMessage ? 'deductions-info error is_icon'  : 'deductions-info acceptance is_icon'">
                <div class="deductions-info__wrap top">
                    <div class="deductions-info__acceptance-wrap acceptance_wrap">
                        <mat-checkbox (change)="handleCheckbox('apply_for_unlimited_tax_liability')" [checked]="abroadIncomeStatement.apply_for_unlimited_tax_liability"></mat-checkbox>
                    </div>
                    <div class="deductions-info__wrap-text">
                        <p class="deductions-info__text">
                            {{ 'ABROAD_INCOME.STEP_3.EOG_APPLICATION' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3_125" *ngIf="canApplyForTaxResidencyStatus() && showEogAplicationInfo()  && (abroadIncomeStatement.accepted_tax_residency_message || abroadIncomeStatement.apply_for_unlimited_tax_liability)">
            <div class="abroad-income-edit__section">
                <div class="deductions-info warning is_icon">
                    <div class="deductions-info__wrap top">
                        <div class="deductions-info__toltip-wrap alert_wrap">
                            <img src="../../../../../../assets/svg/warning-icon.svg" class="alert_icon">
                        </div>
                        <div class="deductions-info__wrap-text">
                            <p class="deductions-info__text">
                                {{ 'ABROAD_INCOME.STEP_3.EOG_CERTIFICATE_INFO' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p class="font-size-20 mb-0_625">{{ 'ABROAD_INCOME.STEP_3.EWR_DOWNLOAD_LABEL' | translate }}</p>
                <a class="btn btn_simple w_icon ewr-btn-download" href="{{ getEWRUrl() }}" target="_blank">
                    <img src="../../../../../../assets/svg/download-icon.svg" alt="donwload ewr" />
                    <span>{{ 'ABROAD_INCOME.STEP_3.EWR_DOWNLOAD_BTN' | translate }}</span>
                </a>
            </div>
        </div>
    </ng-container>

    



    <!-- FIRST ABROAD INCOME END -->

    <!-- SECOND ABROAD INCOME -->
    <!-- <ng-container *ngIf="statement!=null && statement!==undefined">
        <ng-container *ngIf="statement.abroadIncome!=null && statement.abroadIncome!=undefined">
            <ng-container *ngIf="statement.abroadIncome.liveAbroad || statement.abroadIncome.spouseLiveAbroad">

                <div class="ep_header" [class.isCollapse]="!coolapseAbroadIncomeReport"
                    (click)="toggleCollapseAbroadReport()">
                    <div class="left_item">
                        <h2 class="ep_title">
                            2. {{ 'ABROAD_INCOME.ABROAD_INCOME_REPORT_LABEL' | translate }}
                        </h2>
                        <h3 class="ep_sub" translate="ABROAD_INCOME.ABROAD_INCOME_REPORT_SUBLABEL"></h3>
                    </div>
                    <div class="right_item">
                        <img src="../../../assets/svg/minus_accordion.svg" class="icon_up">
                        <img src="../../../assets/svg/plus_accordion.svg" class="icon_down">
                    </div>
                </div>

                <ng-container *ngIf="coolapseAbroadIncomeReport">
                    <div class="ep_new_tiles_wrap top">
                        <div class="ep_new_single_tile_wrap">
                            <div class="ep_new_single_tile top" [ngClass]="isBusiness ? 's_abr_inc_lig' : ''"
                                [class.active]="statement.abroadIncome.liveInGermany" (click)="updateLiveInGermany()">
                                <div class="delete_icon">
                                    <img src="../../../assets/svg/delete_simply_gray_new.svg" />
                                </div>
                                <div class="ep_desc_wrap">
                                    <div class="icon_tile_wrap">
                                        <img src="../../../assets/svg/income_from_abroad/report_taxpayer.svg"
                                            class="icon_tile custom">
                                    </div>
                                    <h4 class="title_tile_sm">
                                        {{'INPUT.LIVE_ABROAD_LIVE_GERMANY.LABEL' | translate }}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="abroad-income-edit__section"
                        *ngIf="shouldShowAdditionalQuestions() || shouldShowSpouseAdditionalQuestions()">

                        <div class="deductions-info"
                            *ngIf="canApplyForTaxResidencyStatus() && statement.spouse && !statement.filedTogether && !hideNotification">
                            <div class="deductions-info__section">
                                <strong translate="ABROAD_INCOME.ATTENTION"></strong>
                                <p translate="ABROAD_INCOME.FILE_TOGETHER_AVAILABLE"></p>
                                <p translate="ABROAD_INCOME.FILE_TOGETHER_TURN_ON"></p>
                            </div>
                            <div class="deductions-info__section">
                                <button class="btn btn--secondary btn--longer btn--small"
                                    (click)="hideNotification = true">{{ 'COMMON.NO' | translate }}</button>
                                <button class="btn btn--primary btn--longer btn--small"
                                    (click)="reinstantiateFilledTogetherState()">{{ 'COMMON.YES' | translate }}</button>
                            </div>
                        </div>

                        <div class="deductions-info is_icon" *ngIf="canApplyForTaxResidencyStatus()">
                            <div class="deductions-info__wrap">
                                <div class="deductions-info__toltip-wrap">
                                    <app-more-info-tooltip class="tooltip"
                                        [message]="'TOOLTIP.I_WANT_OPT_FOR_TAX_RESIDENCY' | translate" [isSmall]="true"
                                        [showText]="false" [lgImgMobile]="true" [additionalDeductions]="true"
                                        [tooltipInInput]=true>
                                    </app-more-info-tooltip>
                                </div>

                                <div class="deductions-info__wrap-text">
                                    <span class="deductions-info__text">
                                        {{ 'ABROAD_INCOME.I_WANT_OPT_FOR_TAX_RESIDENCY' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="deductions-info bg is_icon" *ngIf="canApplyForTaxResidencyStatus()">
                            <div class="deductions-info__wrap top">
                                <div class="deductions-info__toltip-wrap info_wrap">
                                    <img src="../../../assets/svg/info_icon.svg" class="alert_icon">
                                </div>
                                <div class="deductions-info__wrap-text">
                                    <div class="deductions-info__section">
                                        <p class="deductions-info__text">
                                            <strong translate="ABROAD_INCOME.ATTENTION"></strong>
                                            {{ 'ABROAD_INCOME.ATTENTION_STATUS' | translate }}
                                            {{ 'ABROAD_INCOME.FORM_CONFIRMED' | translate }}
                                        </p>
                                    </div>
                                    <div class="deductions-info__section flexbox">
                                        <div class="left-item">
                                            <strong translate="ABROAD_INCOME.DOWNLOAD_EWR_TITLE"></strong>
                                            <p translate="ABROAD_INCOME.DOWNLOAD_EWR_DESCRIPTION"></p>
                                        </div>
                                        <div class="right-item">
                                            <a class="btn btn--primary download-ewr" href="{{ getEWRUrl() }}"
                                                target="_blank">
                                                <img src="../../../assets/svg/download_icon.svg" alt="donwload ewr" />
                                                <span>{{ 'ABROAD_INCOME.DOWNLOAD_EWR' | translate }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </ng-container>
        </ng-container>
    </ng-container> -->

    <!-- SECOND ABROAD INCOME END -->

    <div class="abroad-income-edit__section">
        <div class="deductions-info error is_icon"
            *ngIf="(!canApplyForTaxResidencyStatus() || showApplyForResidencyStatusNotification) && showFieldsInputs()">
            <div class="deductions-info__wrap top">
                <div class="deductions-info__toltip-wrap alert_wrap">
                    <img src="../../../../../../assets/svg/error_icon.svg" class="alert_icon">
                </div>
                <div class="deductions-info__wrap-text">
                    <p class="deductions-info__text">
                        <strong>{{ 'ABROAD_INCOME.ATTENTION' | translate }}</strong>
                        {{ 'ABROAD_INCOME.ATTENTION_CONDITIONS' | translate }}
                        {{ 'ABROAD_INCOME.WONT_ADD_DEDUCTIONS' | translate }}
                        {{ 'ABROAD_INCOME.WONT_ADD_DEDUCTIONS_FEE' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="abroad-income-edit__section" *ngIf="!isResident && !canApplyForTaxResidencyStatus() && showFieldsInputs()">
        <div [ngClass]="orderApplyForMessage ? 'deductions-info error is_icon'  : 'deductions-info acceptance is_icon'">
            <div class="deductions-info__wrap top">
                <div class="deductions-info__acceptance-wrap acceptance_wrap">
                    <mat-checkbox (change)="handleCheckbox('accepted_tax_residency_message')" [checked]="abroadIncomeStatement.accepted_tax_residency_message"></mat-checkbox>
                </div>
                <div class="deductions-info__wrap-text">
                    <p class="deductions-info__text">
                        {{ 'ABROAD_INCOME.STEP_3.NO_RESIDENCY_CONDITION_INFO' | translate }}
                    </p>
                </div>
            </div>
        </div>

        <div class="mb-3_125" *ngIf="abroadIncomeStatement.accepted_tax_residency_message || abroadIncomeStatement.apply_for_unlimited_tax_liability">
            <div class="abroad-income-edit__section" >
                <div class="deductions-info warning is_icon">
                    <div class="deductions-info__wrap top">
                        <div class="deductions-info__toltip-wrap alert_wrap">
                            <img src="../../../../../../assets/svg/warning-icon.svg" class="alert_icon">
                        </div>
                        <div class="deductions-info__wrap-text">
                            <p class="deductions-info__text">
                                {{ 'ABROAD_INCOME.STEP_3.EOG_CERTIFICATE_INFO' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p class="font-size-20 mb-0_625">{{ 'ABROAD_INCOME.STEP_3.EWR_DOWNLOAD_LABEL' | translate }}</p>
                <a class="btn btn_simple w_icon ewr-btn-download" href="{{ getEWRUrl() }}" target="_blank">
                    <img src="../../../../../../assets/svg/download-icon.svg" alt="donwload ewr" />
                    <span>{{ 'ABROAD_INCOME.STEP_3.EWR_DOWNLOAD_BTN' | translate }}</span>
                </a>
            </div>
        </div>
    </div>

</div>