import { Component } from '@angular/core';
import { ButtonBaseComponent } from 'app/shared/components/buttons/button-base.component';

@Component({
  ...ButtonBaseComponent.metaData,
  selector: 'app-button-tile-loader',
  templateUrl: './button-tile-loader.component.html',
  styleUrls: ['./button-tile-loader.component.scss'],
  standalone: true,
})
export class ButtonTileLoaderComponent extends ButtonBaseComponent {
}
