import { AfterContentInit, Component, Input, OnInit } from "@angular/core";
import { ErrorMessage } from "app/core/form-validator/validatorInterface";
import { SocialIncomeTypes } from "app/modules/statement/models/additional-income/social-income.types";
import { SocialIncomeManager } from "app/modules/statement/models/additional-income/social-income.manager";
import { PensionIncomeTypes } from "app/modules/statement/models/additional-income/pension-income.types";
import { FreelanceJobTypes } from "app/modules/statement/models/additional-income/freelance-job.types";
import { Statement } from "app/statements";
import { PensionIncomeManager } from "app/modules/statement/models/additional-income/pension-income.manager";
import { AdditionalIncomeService } from "app/modules/statement/services/additional-income.service";
import { FreelanceJobManager } from "app/modules/statement/models/additional-income/freelance-job.manager";
import { CryptocurrencyIncomeManager } from "app/modules/statement/models/additional-income/cryptocurrency.manager";
import { CryptocurrencyIncomeTypes } from "app/modules/statement/models/additional-income/cryptocurrency.types";

@Component({
  selector: "app-additional-income-dialog-edit",
  templateUrl: "./additional-income-dialog-edit.component.html",
  styleUrls: ["./additional-income-dialog-edit.component.scss"]
})
export class AdditionalIncomeDialogEditComponent {
  @Input() fullYear = 0;
  @Input() title = "";
  @Input() mainStatement: Statement;
  @Input() currentStatement: Statement;
  @Input() incomeType: SocialIncomeTypes | PensionIncomeTypes | FreelanceJobTypes | CryptocurrencyIncomeTypes;
  @Input() additionalIncomeName: SocialIncomeTypes;
  @Input() errors: any[] = [];
  @Input() epValidator: ErrorMessage[];
  @Input() isBusiness: boolean;
  @Input() description = "";
  @Input() icon: string = null;

  public sectionErrors: any[] = [];
  public socialIncomeManager = SocialIncomeManager;
  public pensionIncomeManager = PensionIncomeManager;
  public freelanceJobManager = FreelanceJobManager;
  public cryptocurrencyIncomeManager = CryptocurrencyIncomeManager;
  public freelanceJobTypes = FreelanceJobTypes;

  constructor(public additionalIncomeService: AdditionalIncomeService) {}

  public getDeductionInfoText(): string {
    if (SocialIncomeTypes.OTHER === this.incomeType) {
      return "ADDITIONAL_INCOME.SOCIAL_GROUP.OTHER.DEDUCTION_INFO";
    }
    return "";
  }
}
