<div class="bank-details">
    <div class="bank-details__row" *ngIf="statement.hasBankAccount">
        <div class="bank-details__stage mb-1_5">
            <app-form-input
              [errors]="errors?.iban" [label]="'INPUT.IBAN.LABEL'"
              [placeholder]="'INPUT.IBAN.PLACEHOLDER'" [(model)]="statement.iban" [currentInputId]="'iban'"
              [isVerification]="true" [addClassB2B]="isBusiness" [classB2B]="'iban_bank_details'"
              (modelChange)="this.ibanChanged();"
              [message]="'TOOLTIP.BANK_DETAILS'"
            ></app-form-input>
        </div>
    </div>

    <ng-container *ngIf="statement.hasBankAccount ">

        <ng-container *ngIf="swiftRequired">
            <div class="bank-details__row columns">
                <div class="bank-details__stage column">
                    <app-form-input [errors]="errors?.bic" [label]="'INPUT.BIC_SWIFT.LABEL'"
                        [placeholder]="'INPUT.BIC_SWIFT.PLACEHOLDER'" [(model)]="statement.bic" [currentInputId]="'bic'"
                        [isVerification]="true" [addClassB2B]="isBusiness" [classB2B]="'bicswift_bank_details'">
                    </app-form-input>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="moneyInstitutionRequired">
            <div class="bank-details__row columns">
                <div class="bank-details__stage column">
                    <app-form-input [errors]="errors?.money_institution" [label]="'INPUT.BANK_NAME.LABEL'"
                        [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'" [(model)]="statement.moneyInstitution"
                        [currentInputId]="'moneyInstitution'" [isVerification]="true" [addClassB2B]="isBusiness"
                        [classB2B]="'bankname_bank_details'"></app-form-input>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <div class="bank-details__row columns" *ngIf="isBusiness">
        <div class="bank-details__stage column">
            <mat-checkbox [checked]="!statement.hasBankAccount" (change)="changeHasNoBankAccount()" [attr.data-cy]="'checkboxHasBankAccount'">
                {{ 'INPUT.HAS_NO_BANK_ACCOUNT.LABEL' | translate }}
            </mat-checkbox>
        </div>
    </div>
    <div class="bank-details__row columns" *ngIf="statement.hasBankAccount">
        <div class="bank-details__stage column">
            <mat-checkbox [checked]="statement.otherPersonOwnsBankAccount" (change)="toggleOtherOwner()" [attr.data-cy]="'checkboxOtherPersonOwnsBankAccount'">
                {{ 'INPUT.OTHER_PERSON_OWNS_BANK_ACCOUNT.LABEL' | translate }}
            </mat-checkbox>
        </div>
    </div>

    <ng-container *ngIf="statement.hasBankAccount">
        <div class="bank-details__row columns"
            *ngIf="statement.isMarried && statement.filedTogether && statement.otherPersonOwnsBankAccount">
            <div class="bank-details__stage column">
                <mat-checkbox [checked]="statement.spouseOwnsBankAccount" (change)="toggleSpouseOwner()">
                    {{ 'INPUT.SPOUSE_OWNS_BANK_ACCOUNT.LABEL' | translate }}
                </mat-checkbox>
            </div>
        </div>

        <div class="bank-details__row columns"
            *ngIf="statement.otherPersonOwnsBankAccount && (!statement.spouseOwnsBankAccount || !statement.isMarried)">
            <div class="bank-details__stage column">
                <app-form-input [errors]="errors?.bank_account_holder" [label]="'INPUT.BANK_HOLDER.LABEL'"
                    [placeholder]="'INPUT.BANK_HOLDER.PLACEHOLDER'" [(model)]="statement.bankAccountHolder"
                    [isVerification]="true"></app-form-input>
            </div>
        </div>
    </ng-container>

</div>
