import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {Payment} from '../payment';

@Component({
  selector: 'app-payment-list-item',
  templateUrl: './payment-list-item.component.html',
  styleUrls: ['./payment-list-item.component.scss']
})
export class PaymentListItemComponent implements OnInit {
  @Input() openedItems: boolean[];
  @Input() index: number;
  @Input() payment: Payment;
  @Output() downloadInvoice = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

  emitDownloadInvoice() {
    this.downloadInvoice.emit();
  }
}
