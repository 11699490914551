<div class="step-container columns is-desktop">
    <app-aside-bar [isBusiness]="true"></app-aside-bar>
    <div class="client-summary">
        <div class="title">{{ 'CLIENT_SUMMARY.TITLE' | translate }}</div>
        <div class="description"></div>
        <div class="client-summary__content">
            <div class="client-summary__stage">
                <app-accordion [title]="'ACCORDION.TAXANDO_CHARGES'" [titleFrame]="true" [transparentBg]="true"
                               [noPadding]="true">
                    <app-charges-summary [statements]="statements"></app-charges-summary>
                </app-accordion>
            </div>
            <div class="client-summary__stage">
                <app-accordion [title]="'ACCORDION.PAYMENT_DETAILS'" [titleFrame]="true" [transparentBg]="true"
                               [noPadding]="true">
                    <app-payment-details [batchId]="batchId"></app-payment-details>
                </app-accordion>
            </div>
            <div class="client-summary__batch" *ngIf="statements && statements.length">
                <div class="client-summary__batch-text">{{ 'COMMON.BATCH_FILE' | translate }}</div>
                <div class="client-summary__batch-content">
                    <app-accordion [title]="batchCode + ' - ' + batchCreatedBy" [titleFrame]="true" [transparentBg]="true"
                                   [noPadding]="true">
                        <app-list-view-b2b
                                [statements]="statements"
                                [hidePdf]="true"
                                [hideInvoice]="true"
                                [hideEditing]="true"
                                [hideRemoving]="true"
                                [hideDecision]="true"
                        ></app-list-view-b2b>
                    </app-accordion>
                </div>
            </div>
        </div>
    </div>
    <app-tools-bar [buttons]="buttons"></app-tools-bar>
</div>
