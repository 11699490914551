import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { PackageOptions } from 'app/statements/package-options';
import { FinishPagePath } from 'app/core/workflow/page-path/statement-page-path/finish-page.path';

export class WorkflowFinishService extends WorkflowStepsBaseService {
  public get isRequired(): boolean {
    return false;
  }

  public get checkCanGo(): boolean {
    if (!this.isBusiness && this.statement && (this.statement.isPaid || this.statement.isSent())) {
      switch (this.statement.serviceType) {
        case PackageOptions.paid:
          return true;
        case PackageOptions.tax_adviser:
          return this.statement.hasVerifiedIdentity
            && !this.statement.identificationNumberIsMissing
            && !this.statement.isForClientApproval
            && !this.statement.isReportedErrors;
      }
    }
    return false;
  }

  public get urlRegex(): RegExp {
    return FinishPagePath.regex();
  }

  public get url(): string {
    return FinishPagePath.url(this.getStatementId);
  }
}
