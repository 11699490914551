import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { SubscribingComponent } from '../../common';
import { RefundDetails, SelectsDataInterface, Statement } from 'app/statements';
import { Router } from '@angular/router';
import { CategoryProperties } from './ctr-categories';
import { ArrayHelper } from 'app/core/helpers/array.helper';
import { SocialIncomeTypes } from 'app/modules/statement/models/additional-income/social-income.types';

@Component({
  selector: 'app-ctr-base',
  templateUrl: './ctr-base.component.html',
  styleUrls: ['./ctr-base.component.scss'],
})
export class CtrBaseComponent extends SubscribingComponent {
  static metaData = {
    inputs: ['statement', 'refundDetails', 'person', 'categoryProperties', 'title', 'dataSelects', 'summary', 'desc'],
  };
  @Input() templateRef: TemplateRef<any>;
  @Input() statement: Statement;
  @Input() refundDetails: RefundDetails;
  @Input() person: 'applicant' | 'spouse';
  @Input() categoryProperties: CategoryProperties;
  @Input() title: string;
  @Input() dataSelects: SelectsDataInterface;
  @Input() summary: boolean;
  @Input() step: string;
  @Input() desc: string;
  @ViewChild('textareaError', { static: false }) textareaError: ElementRef;
  public additionalIncomeTypes = SocialIncomeTypes;
  public arrayHelper = ArrayHelper;

  constructor(
    private router: Router
  ) {
    super();
  }

  public get isSpouse(): boolean {
    return this.person === 'spouse';
  }

  public handleCheckboxError(value: ElementRef): void {
    setTimeout(() => {
      value.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
    }, 50);
  }

  public findInArray(array, key: string, value: string | number, keyToReturn: string): any {
    const found = array.find(x => x[key] === value);
    if (found) {
      return found[keyToReturn];
    }
    return '';
  }

  public goToStepWorkflow(): void {
    if (this.categoryProperties.stepUrl) {
      this.router.navigate([this.categoryProperties.stepUrl]);
    }
  }

  public getCurrency(currencyId: number): string {
    if (!this.dataSelects || !this.dataSelects.currencies) {
      return '€';
    }

    return this.findInArray(
      this.dataSelects.currencies,
      'id',
      currencyId,
      'code'
    ) || '€';
  }
}
