import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Statement } from '../../statements';
import { SubscribingComponent } from '../subscribing-component';
import {Attachment} from '../../statements/attachment';
import {StatementService} from '../../statements/statement.service';

@Component({
  selector: 'app-attachment-view-row',
  templateUrl: './attachment-view-row.component.html',
})
export class AttachmentViewRowComponent extends SubscribingComponent {
  @Input() statement: Statement;
  @Input() attachment: Attachment;
  @Input() loadingDeleteAttachment = false;
  @Input() error = false;
  @Output() onDeleteAttachmentEvent = new EventEmitter();
  @Output() onViewAttachmentEvent = new EventEmitter();

  constructor(
    private statementService: StatementService
  ) {
    super();
  }
  public onDeleteAttachment(): void {
    this.onDeleteAttachmentEvent.emit(this.attachment);
  }

  public onViewAttachment(): void {
    this.onViewAttachmentEvent.emit(this.attachment);

    if (this.attachment.id) {
      this.statementService.attachment.get(this.statement.id, this.attachment.id)
        .subscribe((response) => {
          const byteArray = new Uint8Array(atob(response['base64'])
            .split('')
            .map(char => char.charCodeAt(0)));

          const blob = new Blob([byteArray], {type: 'application/pdf'});
          const blobURL = URL.createObjectURL(blob);

          window.open(blobURL, '_blank');
        });
    } else {
      const file = this.attachment.file;
      const blobURL = URL.createObjectURL(file);

      window.open(blobURL, '_blank');
    }
  }
}
