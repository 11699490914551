import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-form-multiinput',
  templateUrl: './form-multiinput.component.html',
  styleUrls: ['./form-multiinput.component.scss']
})
export class FormMultiInputComponent implements OnInit {
  @Input() label: string;
  @Input() model: any;
  @Output() modelChange = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Input() errors: any;
  @Input() length;  // numbers of inputs
  @Input() lengthOne = 1;
  @Input() placeholder = '';
  @Input() currentInputId = null;
  @Input() centerLabel = false;
  @Input() centerTxtInput = true;
  @Input() hiddenTooltipOnMobile = false;

  public innerModel: any;
  public tmpValue: any;
  public activeInput = [];

  ngOnInit() {
    this.innerModel = Array(this.length);
    this.tmpValue = Array(this.length);
    this.activeInput = Array(this.length);

    if (this.model.length > 0) {
      this.model.split('').forEach((el, i) => {
        this.tmpValue[i] = el;
      });
    }
  }

  public onFocus(index) {
    this.activeInput = Array(this.length);
    this.activeInput[index] = true;
  }

  public onKeyUp(event) {
    const elem = event.target;
    const n = elem.name;
    let goNext = true;

    if (event.code === 'Process') {
      return;
    } else {
      if (event.code === 'Backspace'
      ) {
        event.target.value = '';
        this.tmpValue[n] = '';
      } else if (isNaN(parseInt(event.key, 0))) {
        if (isNaN(parseInt(this.tmpValue[n], 0))) {
          event.target.value = '';
          this.tmpValue[n] = '';
          if (event.code !== 'ArrowRight' && event.code !== 'ArrowLeft') {
            goNext = false;
          }
        } else {
          event.target.value = this.tmpValue[n];
        }
      } else {
        this.tmpValue[n] = event.key;      // elem.value;
        event.target.value = event.key;    // overwrite current value
      }
    }

    this.model = this.tmpValue.join('');

    if (goNext) {
      let element;
      if (event.code !== 'Backspace' || event.code === 'ArrowRight') {
        if (event.target.parentElement.nextElementSibling) {
          element = event.target.parentElement.nextElementSibling.firstChild;
        }
      }

      if (event.code === 'Backspace' || event.code === 'ArrowLeft') {
        if (event.target.parentElement.previousElementSibling) {
          element = event.target.parentElement.previousElementSibling.firstChild;
        }
      }

      if (element) {
        element.focus();
      }
    }

    this.modelChange.emit(this.model);
  }
}
