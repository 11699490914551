import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { INPUT_TYPE } from "../income-input/income-input.component";
@Component({
  selector: "app-income-list",
  templateUrl: "./income-list.component.html",
  styleUrls: ["./income-list.component.scss"]
})
export class IncomeList implements OnInit {
  @Input() list: Array<any> = [];
  @Input() selectValueList: Array<any> = [];
  @Input() inputLabel: string = "";
  @Input() vatInputLabel: string = "";
  @Input() inputPlaceholder: string = "";
  @Input() vatInputPlaceholder: string = "";
  @Input() btnTextFirst: string = "";
  @Input() btnTextSecond: string = "";
  @Input() alertInfo: string = "";
  @Input() dataSelects: [] = [];
  @Input() incomeType: INPUT_TYPE = INPUT_TYPE.INCOMES;
  @Input() isLump: Boolean = false;
  @Input() currentIndex: number = 0;
  @Output() initEdit: EventEmitter<any> = new EventEmitter<any>();

  isEditVisible: boolean[] = [];

  constructor() {}

  ngOnInit(): void {
    this.list.forEach((_, index) => this.isEditVisible.push(false));
  }

  ngOnChanges(changes) {
    if (changes.list) {
      this.isEditVisible = this.isEditVisible.map((el) => (el = false));
    }
  }

  public handleEdit(index: number) {
    this.isEditVisible[index] = !this.isEditVisible[index];
  }

  public changeValue(valueToChange: string, editIndex: number) {
    this.list[editIndex] = valueToChange;
    this.isEditVisible[editIndex] = false;
  }

  public removeValue(editIndex: number) {
    this.list.splice(editIndex, 1);
    this.selectValueList.splice(editIndex, 1);
    this.isEditVisible[editIndex] = false;
  }

  public summary() {
    const sum = this.list.reduce((partialSum, element) => {
      // summaries values
      if (element.vat_value) {
        partialSum += parseFloat(element.vat_value);
      }

      return partialSum + parseFloat(element.value);
    }, 0.0);

    return sum.toFixed(2);
  }

  public show() {
    console.log(this.isEditVisible);
  }

  public displayNet(index: number) {
    if (this.list[index].vat_value && this.incomeType === INPUT_TYPE.INCOMES)
      return parseFloat(this.list[index].value).toFixed(2);
    return "";
  }

  public displayVat(index: number) {
    if (this.list[index].vat_value && this.incomeType === INPUT_TYPE.INCOMES)
      return parseFloat(this.list[index].vat_value).toFixed(2);
    return "";
  }

  public displayGross(index: number) {
    let sum = 0;
    if (this.list[index].vat_value && this.incomeType === INPUT_TYPE.INCOMES) {
      sum = parseFloat(this.list[index].value) + parseFloat(this.list[index].vat_value);
    } else {
      sum = parseFloat(this.list[index].value);
    }

    return sum.toFixed(2);
  }

  public displayName(value: string) {
    for (let i = 0; i < this.dataSelects.length; i++) {
      if (this.dataSelects[i][1] === value) return this.dataSelects[i][2];
    }

    return value;
  }
}
