import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notice-message',
  templateUrl: './notice-message.component.html',
})
export class NoticeMessageComponent {
  @Input() header = 'COMMON.PLEASE_NOTE';
  @Input() message: string;
  @Input() additionalClasses = '';
}
