<app-menu-bar-mobile></app-menu-bar-mobile>
<div class="payment-history content--full-center">
    <h1 class="payment-history__title title">{{ 'COMMON.PAYMENTS' | translate }}</h1>
    <div class="payment-history__content">
        <div class="payment-history__batch">
            <div class="payment-history__batch-content">
                <app-accordion [title]="'COMMON.HISTORY'" [titleFrame]="true" [transparentBg]="true"
                               [noPadding]="true">
                    <app-payment-list [payments]="payments"
                                   (downloadInvoice)="downloadInvoice($event)"
                    ></app-payment-list>
                </app-accordion>
            </div>
        </div>
    </div>
</div>
