<app-ctr-base
  [templateRef]="children"
  [title]="title"
  [categoryProperties]="categoryProperties"
  [dataSelects]="dataSelects"
  [summary]="summary"
></app-ctr-base>

<ng-template #children>
    <div class="card card-white shadow-0 mb-2" id="childrenTab">
        <div class="card__row mb-1" *ngFor="let child of statement.children; let index = index;">
          <ng-container *ngIf="!child.secondParent || person === 'applicant'">
            <div class="table">
              <div class="table__row">
                <div class="table__col">
                  <div class="row__title">
                    {{ 'CONFIRM_TAX_RETURN.CHILDREN.NAME_AND_SURNAME' | translate }}
                  </div>
                </div>
                <div class="table__col d-flex">
                  <input
                    [attr.data-cy]="'verificationChildName_' + index"
                    readonly
                    value="{{ child.firstName }} {{ child.lastName }}"
                  />
                </div>
              </div>
              <div class="table__row">
                <div class="table__col">
                  <div class="row__title">{{ 'CONFIRM_TAX_RETURN.CHILDREN.BIRTH_DATE' | translate }}</div>
                </div>
                <div class="table__col d-flex">
                  <input
                    [attr.data-cy]="'verificationChildBirthDate_' + index"
                    readonly
                    value="{{ child.birthDate }}"
                  />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
    </div>
</ng-template>
