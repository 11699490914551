<div class="employer-edit__row">
  <div class="employer-edit__label_wrap" [class.font-weight-bold]="row.labelBold">
    {{ row.title | translate }}
  </div>
  <div class="employer-edit__stage" id="{{row.taxCardKey}}">
    <app-form-input
      [errors]="row.errors"
      [label]="row.inputLabel"
      [isCurrency]="true"
      [placeholder]="row.inputPlaceholder"
      [employerStage]="true"
      [isDisabled]="row.inputIsDisabled()"
      [isEmployer]="true"
      [isEditableSummaryEmployer]="isEditableSummaryEmployer"
      [isVerification]="isVerification || isSummary"
      [(model)]="employer[row.taxCardKey]"
      [addClassB2B]="isBusiness"
      [classB2B]="'card_' + currentIndex + '_eid_input_' + row.taxCardKey"
      [attr.data-cy]="'input_'+row.taxCardKey+'_'+currentIndex" >
    </app-form-input>

    <app-single-field-edit
      *ngIf="isVerification"
      [field]="codes[row.taxCardKey]"
      [activeField]="unlocked"
      (toggle)="toggleField($event)"
      [toRight]="true"
      [employerEdit]="employerFieldEdit"
    >
    </app-single-field-edit>
  </div>
  <app-employer-field-summary
    *ngIf="isSummary"
    [employers]="employers"
    [fieldName]="row.inputSummaryLabel"
    [fieldSymbol]="codes[row.taxCardKey]"
    [spouseId]="spouseId"
  >
  </app-employer-field-summary>
</div>
<div class="employer-edit__error_wrapper">
  <div class="employer-edit__error_bc" *ngIf="row.errors && row.errors.length">
    {{ row.errors[0] }}
  </div>
  <app-validator-error-message
    [epValidator]="epValidator"
    [employerStage]="true"
    [index]="currentIndex"
    [fieldName]="row.taxCardKey"
  >
  </app-validator-error-message>
  <ng-container *ngIf="empWarningList[currentIndex]?.selectedKeysToArray(row.validationWarningFieldNames) as warnings">
    <div
      class="employer-edit__warrning_bc"
      [class.no_border]="(row.errors && row.errors.length) || findValidationByFieldName(row.validationErrorFieldNames)"
      *ngIf="warnings.length"
    >
      <div *ngFor="let warning of warnings">
        {{ warning | translate }}
      </div>
    </div>
  </ng-container>
</div>
