import {Statement} from 'app/statements/statement';

export enum DeductionInputValues {
  ADD_OPTION = 1,
  SKIP_OPTION = 2,
}

export interface DeductionInterface {
    statement: Statement;
    flagName: string;
    section: string;
    isSpouse: boolean;
    title: string;
    icon: string;
    tooltipDesc?: string;
}
