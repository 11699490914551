import { Component, Input, OnInit } from "@angular/core";

import { Statement } from "../../statements";
import { SnackbarConfig, SubscribingComponent } from "..";
import { StatementService } from "../../statements/statement.service";
import * as FileSaver from "file-saver";
import { DOWNLOAD_FILE_TYPE } from "../../statements/file-decision-types";
import { DeviceRecognizerHelper } from "app/core/helpers/device-recognizer.helper";
import { finalize, map, Observable, takeUntil } from "rxjs";
import { SHOW_SNACKBAR } from "app/reducers/actions/snackbar";
import { MessageSenderHttpService } from "../send-message-dialog/http-service/message-sender.http.service";
import { MESSAGE_DOCUMENT_TYPE, ATTACHMENT_SUBTYPE } from "../send-message-dialog/send-message-dialog.component";
import { Store } from "@ngrx/store";
import { State } from "app/reducers";

type FileType = DOWNLOAD_FILE_TYPE & MESSAGE_DOCUMENT_TYPE & ATTACHMENT_SUBTYPE;

@Component({
  selector: "app-download-file-dialog",
  templateUrl: "./download-file-dialog.component.html",
  styleUrls: ["./download-file-dialog.component.scss"],
  providers: [MessageSenderHttpService]
})
export class DownloadFileDialogComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  public files: { name: string; type: string }[] = [];
  public error = "";
  public filesToDownload: Array<{ file_type: DOWNLOAD_FILE_TYPE; data?: unknown; id?: number }> = [];

  constructor(
    private statementService: StatementService,
    public messageSenderHttpService: MessageSenderHttpService,
    private store: Store<State>
  ) {
    super();
  }

  ngOnInit() {
    for (const [key, value] of Object.entries(this.statement.documents)) {
      if (Array.isArray(value)) {
        value.forEach((valueElement) => {
          if (valueElement && typeof valueElement === "object") {
            this.filesToDownload.push({ file_type: key, ...valueElement });
          } else {
            this.filesToDownload.push({ file_type: key as DOWNLOAD_FILE_TYPE, data: valueElement });
          }
        });
      } else if (value !== null && value !== "" && value !== undefined) {
        if (typeof value === "object") {
          this.filesToDownload.push({ file_type: key as DOWNLOAD_FILE_TYPE, ...value });
        } else {
          this.filesToDownload.push({ file_type: key as DOWNLOAD_FILE_TYPE, data: value });
        }
      }
    }

    this.getMessageAttachmentList();
  }

  public getMessageAttachmentList() {
    this.messageSenderHttpService.getMessageAttachmentList(this.statement.id).subscribe({
      next: (response: any) => {
        response.forEach((message) => {
          if (message.confirmation_ready) {
            this.filesToDownload.push({
              file_type: message.subtype ? message.subtype : message.type,
              id: message.id
            });
          }
        });
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  public onDownload(file: string, file_type: FileType, documentPK: number) {
    const isMobile = DeviceRecognizerHelper.isAndroidWebview() || DeviceRecognizerHelper.isIosWebview();
    const isSpecialType = [
      MESSAGE_DOCUMENT_TYPE.ATTACHMENT,
      MESSAGE_DOCUMENT_TYPE.MESSAGE,
      MESSAGE_DOCUMENT_TYPE.APPEAL,
      ATTACHMENT_SUBTYPE.BELEGE,
      ATTACHMENT_SUBTYPE.EWR
    ].includes(file_type);

    if (isMobile) {
      let downloadObservable: Observable<any>;
      if (isSpecialType) {
        downloadObservable = this.statementService.downloadDecisionPDF(this.statement.id, "confirmation", documentPK);
      } else {
        downloadObservable = this.statementService.downloadDecisionPDF(this.statement.id, file);
      }

      this.handleMobileObservableResponse(downloadObservable);
    } else {
      let downloadObservable: Observable<Blob>;

      if (isSpecialType) {
        downloadObservable = this.messageSenderHttpService.downloadConfirmation(this.statement.id, documentPK).pipe(
          map((res: Blob) => {
            if (!res) {
              throw new Error("Response is null or undefined");
            }
            return res;
          })
        );
      } else {
        downloadObservable = this.statementService.downloadDecisionPDF_desktop(this.statement.id, file);
      }

      this.handleObservableResponse(downloadObservable, (res: Blob) => this.saveFile(res, file, file_type));
    }
  }

  showPDFError() {
    this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig("SNACKBAR.PDF_NOT_AVAILABLE", "ERROR") });
  }

  //freelance, gewerbe, voluntering
  public onDownloadFreelance(file: string, fileId: number, file_type?: FileType) {
    if (DeviceRecognizerHelper.isAndroidWebview() || DeviceRecognizerHelper.isIosWebview()) {
      const downloadObservable: Observable<any> = this.statementService.downloadDecisionPDF(
        this.statement.id,
        file,
        fileId
      ); //solution for mobile on IOS and android

      this.handleMobileObservableResponse(downloadObservable);
    } else {
      const downloadObservable: Observable<Blob> = this.statementService.downloadDecisionFreelancePDF(
        this.statement.id,
        fileId
      );

      this.handleObservableResponse(downloadObservable, (res: Blob) => this.saveFile(res, file, file_type));
    }
  }

  private handleMobileObservableResponse(downloadObservable: Observable<any>): void {
    downloadObservable.subscribe({
      next: (response: any) => {
        console.log(response);
        const link = document.createElement("a");
        link.href = response.redirect_url;
        link.target = "_self";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      error: (error: any) => {
        console.error("Error occurred:", error);
        this.showPDFError(); // Wywołanie metody komponentu przy błędzie
      }
    });
  }

  private handleObservableResponse(downloadObservable: Observable<any>, saveFileCallback: (res: Blob) => void): void {
    downloadObservable
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.statement.downloadingPdf = false))
      )
      .subscribe({
        next: (res: Blob) => saveFileCallback(res),
        error: (error: any) => {
          this.showPDFError();
          console.error(error);
        }
      });
  }

  public displayFreelanceDownloadButton(file: DOWNLOAD_FILE_TYPE): boolean {
    return [
      DOWNLOAD_FILE_TYPE.FREELANCE_EUER,
      DOWNLOAD_FILE_TYPE.GEWERBE_EUER,
      DOWNLOAD_FILE_TYPE.SPOUSE_FREELANCE_EUER,
      DOWNLOAD_FILE_TYPE.SPOUSE_GEWERBE_EUER
    ].includes(file);
  }

  public generateFileName(file: string, file_type: FileType) {
    switch (file_type) {
      case MESSAGE_DOCUMENT_TYPE.APPEAL:
        return `einspruch-${this.statement.id}.pdf`;
      case ATTACHMENT_SUBTYPE.BELEGE:
      case MESSAGE_DOCUMENT_TYPE.ATTACHMENT:
        return `beleg-${this.statement.id}.pdf`;
      case ATTACHMENT_SUBTYPE.EWR:
        return `ewr-${this.statement.id}.pdf`;
      case MESSAGE_DOCUMENT_TYPE.MESSAGE:
        return `nachricht-${this.statement.id}.pdf`;
      case DOWNLOAD_FILE_TYPE.ERIC_DECISION:
      case DOWNLOAD_FILE_TYPE.ERIC_DIVA_DECISION:
      case DOWNLOAD_FILE_TYPE.STATEMENT:
      case DOWNLOAD_FILE_TYPE.FREELANCE_EUER:
      case DOWNLOAD_FILE_TYPE.GEWERBE_EUER:
      case DOWNLOAD_FILE_TYPE.SPOUSE_FREELANCE_EUER:
      case DOWNLOAD_FILE_TYPE.SPOUSE_GEWERBE_EUER: {
        const fileName = file.split(".")[0].replace(/_/g, "-");
        return `${fileName}-${this.statement.id}.pdf`;
      }
      default:
        throw new Error("DownloadFileDialogComponent generateFileName ERROR: Nieobsługiwany typ pliku");
    }
  }

  public generateTypeName(fileType: FileType): string {
    const fileTypeMap: Record<FileType, string> = {
      [DOWNLOAD_FILE_TYPE.ERIC_DECISION]: "DOWNLOAD_FILES_DIALOG.ERIC_DECISION",
      [DOWNLOAD_FILE_TYPE.ERIC_DIVA_DECISION]: "DOWNLOAD_FILES_DIALOG.ERIC_DIVA_DECISION",
      [DOWNLOAD_FILE_TYPE.STATEMENT]: "DOWNLOAD_FILES_DIALOG.STATEMENT",
      [DOWNLOAD_FILE_TYPE.FREELANCE_EUER]: "DOWNLOAD_FILES_DIALOG.FREELANCE_EUER",
      [DOWNLOAD_FILE_TYPE.GEWERBE_EUER]: "DOWNLOAD_FILES_DIALOG.GEWERBE_EUER",
      [DOWNLOAD_FILE_TYPE.SPOUSE_FREELANCE_EUER]: "DOWNLOAD_FILES_DIALOG.SPOUSE_FREELANCE_EUER",
      [DOWNLOAD_FILE_TYPE.SPOUSE_GEWERBE_EUER]: "DOWNLOAD_FILES_DIALOG.SPOUSE_GEWERBE_EUER",
      [MESSAGE_DOCUMENT_TYPE.MESSAGE]: "DOWNLOAD_FILES_DIALOG.MESSAGE",
      [MESSAGE_DOCUMENT_TYPE.APPEAL]: "DOWNLOAD_FILES_DIALOG.MESSAGE",
      [MESSAGE_DOCUMENT_TYPE.ATTACHMENT]: "DOWNLOAD_FILES_DIALOG.ATTACHMENT",
      [ATTACHMENT_SUBTYPE.BELEGE]: "DOWNLOAD_FILES_DIALOG.ATTACHMENT",
      [ATTACHMENT_SUBTYPE.EWR]: "DOWNLOAD_FILES_DIALOG.EWR"
    };

    return fileTypeMap[fileType] ?? "DEFAULT_FILE_NAME";
  }

  private saveFile = (response: Blob, file: string, file_type: FileType) => {
    const blob = response;
    FileSaver.saveAs(blob, this.generateFileName(file, file_type));
  };
}
