import { RefundDetails, Statement } from "app/statements";
import { TaxCardComponentPath } from "app/core/workflow/page-path/statement-page-path/tax-card-component.path";
import { PersonalInfoComponentPath } from "app/core/workflow/page-path/statement-page-path/personal-info-component.path";
import { BankDetailComponentPath } from "app/core/workflow/page-path/statement-page-path/bank-detail-component.path";
import { TaxCardSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/tax-card-spouse-component.path";
import { AdditionalIncomeComponentPath } from "app/core/workflow/page-path/statement-page-path/additional-income-component.path";
import { AdditionalIncomeSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/additional-income-spouse-component.path";
import { PersonalInfoSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/personal-info-spouse-component.path";
import { DeductionInfoSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/deduction-info-spouse-component.path";
import { DeductionInfoComponentPath } from "app/core/workflow/page-path/statement-page-path/deduction-info-component.path";
import { B2bTaxCardComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.tax-card-component.path";
import { B2bTaxCardSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.tax-card-spouse-component.path";
import { B2bAdditionalIncomeComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.additional-income-component.path";
import { AbroadIncomeComponentPath } from "app/core/workflow/page-path/statement-page-path/abroad-income-component.path";
import { B2bAbroadIncomeComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.abroad-income-component.path";
import { B2bDeductionInfoComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.deduction-info-component.path";
import { B2bDeductionInfoSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.deduction-info-spouse-component.path";
import { PersonalInfoChildSummaryComponentPath } from "app/core/workflow/page-path/statement-page-path/personal-info-child-summary-component-path";

export class CategoryProperties {
  public valueChanged: (key, oldVal, newVal) => void;

  private _key: string;
  get key(): string {
    return this._key;
  }
  set key(key: string) {
    this._key = key;
  }

  private _reportError = false;
  get reportError(): boolean {
    return this._reportError;
  }
  set reportError(reportError: boolean) {
    this._reportError = reportError;
    this.valueChanged("reportError", this._reportError, reportError);
  }

  private _errorMessage = "";
  get errorMessage(): string {
    return this._errorMessage;
  }
  set errorMessage(errorMessage: string) {
    this._errorMessage = errorMessage;
  }

  private _stepUrl = "";
  get stepUrl(): string {
    return this._stepUrl;
  }
  set stepUrl(stepUrl: string) {
    this._stepUrl = stepUrl;
  }

  private _enabled = true;
  get enabled(): boolean {
    return this._enabled;
  }
  set enabled(enabled: boolean) {
    this._enabled = enabled;
  }

  private _isEmpty = true;
  get isEmpty(): boolean {
    return this._isEmpty;
  }
  set isEmpty(isEmpty: boolean) {
    this._isEmpty = isEmpty;
  }

  private _counter = 0;
  get counter(): number {
    return this._counter;
  }
  set counter(counter: number) {
    this._counter = counter;
  }

  constructor(_key: string, valueChanged?: (key, oldVal, newVal) => void, _isEmpty?: boolean, _stepUrl?: string) {
    this.valueChanged = valueChanged || (() => {});

    this.key = _key;
    this.isEmpty = _isEmpty;
    this.stepUrl = _stepUrl;
  }
}

export class SummaryStatementCategories {
  public taxCard: CategoryProperties;
  public additionalIncome: CategoryProperties;
  public pensionIncome: CategoryProperties;
  public freelanceJobs: CategoryProperties;

  public freelanceJobsGewerbe: CategoryProperties;
  public freelanceJobsVoluntering: CategoryProperties;

  public cryptocurrencyIncome: CategoryProperties;
  public abroadIncome: CategoryProperties;
  public personalInfo: CategoryProperties;
  public children: CategoryProperties;
  public deductions: CategoryProperties;
  public bankDetails: CategoryProperties;
  public _counter = 0;

  constructor(
    private onValueChange?: (key, oldVal, newVal) => void,
    private refundDetails?: RefundDetails,
    private statement?: Statement,
    private currentStatement?: Statement,
    private isBusiness = false
  ) {
    const taxCardUrl =
      statement && currentStatement
        ? !currentStatement.isSpouse()
          ? isBusiness
            ? B2bTaxCardComponentPath.fullUrl(statement.id, statement.firstEmployerId)
            : TaxCardComponentPath.fullUrl(statement.id, statement.firstEmployerId)
          : isBusiness
            ? B2bTaxCardSpouseComponentPath.fullUrl(statement.id, statement.spouseId)
            : TaxCardSpouseComponentPath.fullUrl(statement.id, statement.spouseId, statement.firstEmployerId)
        : "";
    const additionalIncomeUrl =
      statement && currentStatement
        ? !currentStatement.isSpouse()
          ? isBusiness
            ? B2bAdditionalIncomeComponentPath.fullUrl(statement.id)
            : AdditionalIncomeComponentPath.fullUrl(statement.id, 1)
          : isBusiness
            ? B2bTaxCardSpouseComponentPath.fullUrl(statement.id, statement.spouseId)
            : AdditionalIncomeSpouseComponentPath.fullUrl(statement.id, statement.spouseId, 1)
        : "";
    const pensionIncomeUrl =
      statement && currentStatement
        ? !currentStatement.isSpouse()
          ? isBusiness
            ? B2bAdditionalIncomeComponentPath.fullUrl(statement.id)
            : AdditionalIncomeComponentPath.fullUrl(statement.id, 2)
          : isBusiness
            ? B2bTaxCardSpouseComponentPath.fullUrl(statement.id, statement.spouseId)
            : AdditionalIncomeSpouseComponentPath.fullUrl(statement.id, statement.spouseId, 2)
        : "";
    const freelanceJobsUrl =
      statement && currentStatement
        ? !currentStatement.isSpouse()
          ? isBusiness
            ? B2bAdditionalIncomeComponentPath.fullUrl(statement.id)
            : AdditionalIncomeComponentPath.fullUrl(statement.id, 3)
          : isBusiness
            ? B2bTaxCardSpouseComponentPath.fullUrl(statement.id, statement.spouseId)
            : AdditionalIncomeSpouseComponentPath.fullUrl(statement.id, statement.spouseId, 3)
        : "";
    const cryptoCurrencyUrl =
      statement && currentStatement
        ? !currentStatement.isSpouse()
          ? isBusiness
            ? B2bAdditionalIncomeComponentPath.fullUrl(statement.id)
            : AdditionalIncomeComponentPath.fullUrl(statement.id)
          : isBusiness
            ? B2bTaxCardSpouseComponentPath.fullUrl(statement.id, statement.spouseId)
            : AdditionalIncomeSpouseComponentPath.fullUrl(statement.id, statement.spouseId)
        : "";
    const abroadIncomeUrl =
      statement && currentStatement
        ? isBusiness
          ? B2bAbroadIncomeComponentPath.fullUrl(statement.id)
          : AbroadIncomeComponentPath.fullUrl(statement.id)
        : "";
    const personalInfoUrl =
      statement && currentStatement
        ? !currentStatement.isSpouse()
          ? PersonalInfoComponentPath.fullUrl(statement.id)
          : PersonalInfoSpouseComponentPath.fullUrl(statement.id, statement.spouseId)
        : "";
    const childrenUrl =
      statement && currentStatement ? PersonalInfoChildSummaryComponentPath.fullUrl(statement.id) : "";
    const deductionUrl =
      statement && currentStatement
        ? !currentStatement.isSpouse()
          ? isBusiness
            ? B2bDeductionInfoComponentPath.fullUrl(statement.id)
            : DeductionInfoComponentPath.fullUrl(statement.id, 1)
          : isBusiness
            ? B2bDeductionInfoSpouseComponentPath.fullUrl(statement.id, statement.spouseId)
            : DeductionInfoSpouseComponentPath.fullUrl(statement.id, statement.spouseId, 1)
        : "";
    const bankDetailsUrl =
      statement && currentStatement
        ? !currentStatement.isSpouse()
          ? BankDetailComponentPath.fullUrl(statement.id)
          : ""
        : "";
    const person = statement && currentStatement && currentStatement.isSpouse() ? "spouse" : "applicant";
    const taxCardIsEmpty = refundDetails ? !refundDetails[person].einkunfteAusNichtselbstandigerArbeit : true;
    const additionalIncomeIsEmpty = statement && currentStatement ? !currentStatement.additionalIncome.length : true;
    const pensionIncomeIsEmpty = refundDetails ? !refundDetails[person].pensionTotalAmount : true;

    const checkIfFreelanceIsEmpty = () => {
      const values = [refundDetails[person].einkunfte_aus_selbstandiger_arbeit_freelance_name];

      if (refundDetails && values.some((value) => value)) return false;
      return true;
    };

    const freelanceJobsIsEmpty = checkIfFreelanceIsEmpty();

    const checkIfFreelanceGewerbeIsEmpty = () => {
      const values = [refundDetails[person].einkunfte_aus_selbstandiger_arbeit_gewerbe_name];

      if (refundDetails && values.some((value) => value)) return false;
      return true;
    };

    const freelanceGewerbeJobsIsEmpty = checkIfFreelanceGewerbeIsEmpty();

    const checkIfFreelanceVolunteringIsEmpty = () => {
      const values = [refundDetails[person].einkunfte_aus_selbstandiger_arbeit_volunteering];

      if (refundDetails && values.some((value) => value)) return false;
      return true;
    };

    const freelanceVolunteringJobsIsEmpty = checkIfFreelanceVolunteringIsEmpty();

    const cryptoCurrencyIncomeIsEmpty = refundDetails ? !refundDetails[person].cryptoCurrencyIncome : true;

    let abroadIncomeIsEmpty =
      statement && currentStatement
        ? !currentStatement.isSpouse()
          ? !statement.abroadIncome.abroadIncome || statement.abroadIncome.abroadIncome === "0.00"
          : !statement.abroadIncome.spouseAbroadIncome || statement.abroadIncome.spouseAbroadIncome === "0.00"
        : true;

    if (statement && currentStatement) {
      if (!currentStatement.isSpouse()) {
        if (statement.additional_abroad_incomes.length > 0) {
          abroadIncomeIsEmpty = false;
        }
      } else if (statement.spouse.additional_abroad_incomes.length > 0) abroadIncomeIsEmpty = false;
    }

    const childrenIsEmpty = statement && currentStatement ? !statement.hasChildren || !statement.children.length : true;
    const deductionsIsEmpty =
      statement && currentStatement && refundDetails
        ? refundDetails.isDeductionsEmpty(this.currentStatement.isSpouse())
        : true;
    this.taxCard = new CategoryProperties("tax_card", onValueChange, taxCardIsEmpty, taxCardUrl);
    this.additionalIncome = new CategoryProperties(
      "additional_income",
      onValueChange,
      additionalIncomeIsEmpty,
      additionalIncomeUrl
    );
    this.pensionIncome = new CategoryProperties(
      "pension_income",
      onValueChange,
      pensionIncomeIsEmpty,
      pensionIncomeUrl
    );

    //We want divide freelance Jobs for 3 sections and not treat that as one, it is on opposite to other sections logic but it is individual situation TAX-3092
    this.freelanceJobs = new CategoryProperties(
      "freelance_jobs", //freelance
      onValueChange,
      freelanceJobsIsEmpty,
      freelanceJobsUrl
    );

    this.freelanceJobsGewerbe = new CategoryProperties(
      "freelanceJobsGewerbe", //gewerbe
      onValueChange,
      freelanceGewerbeJobsIsEmpty,
      freelanceJobsUrl
    );
    this.freelanceJobsVoluntering = new CategoryProperties(
      "freelanceJobsVoluntering", //voluntering
      onValueChange,
      freelanceVolunteringJobsIsEmpty,
      freelanceJobsUrl
    );

    this.cryptocurrencyIncome = new CategoryProperties(
      "cryptocurrency_income",
      onValueChange,
      cryptoCurrencyIncomeIsEmpty,
      cryptoCurrencyUrl
    );
    this.abroadIncome = new CategoryProperties("abroad_income", onValueChange, abroadIncomeIsEmpty, abroadIncomeUrl);
    this.personalInfo = new CategoryProperties("personal_info", onValueChange, false, personalInfoUrl);

    this.children = new CategoryProperties("children", onValueChange, childrenIsEmpty, childrenUrl);
    this.children.enabled = this.currentStatement && !this.currentStatement.isSpouse();

    this.deductions = new CategoryProperties("deductions", onValueChange, deductionsIsEmpty, deductionUrl);

    if (this.currentStatement && !this.currentStatement.isSpouse()) {
      this.bankDetails = new CategoryProperties("bank_details", onValueChange, false, bankDetailsUrl);
    }
  }

  public counter(category: CategoryProperties): number {
    if (category.enabled && !category.isEmpty && !category.counter) {
      this._counter++;
      category.counter = this._counter;
    }

    return category.counter;
  }

  public get hasReportError(): boolean {
    return Object.keys(this).some((key) => this[key] instanceof CategoryProperties && this[key].reportError);
  }

  public get categoryPropertiesList(): CategoryProperties[] {
    return Object.keys(this)
      .map((key) => this[key])
      .filter((x) => x instanceof CategoryProperties);
  }
}
