import { SocialIncome } from "app/modules/statement/models/additional-income/social-income.model";
import { Statement } from "app/statements";
import { ArrayHelper } from "app/core/helpers/array.helper";
import { SocialIncomeTypes } from "app/modules/statement/models/additional-income/social-income.types";

export class SocialIncomeManager {
  public static getUnemployment(statement: Statement): SocialIncome[] {
    return SocialIncomeManager.get(statement, SocialIncomeTypes.UNEMPLOYMENT);
  }

  public static createUnemployment(statement: Statement): SocialIncome {
    return SocialIncomeManager.create(statement, SocialIncomeTypes.UNEMPLOYMENT);
  }

  public static removeAllUnemployment(statement: Statement): void {
    SocialIncomeManager.removeAll(statement, SocialIncomeTypes.UNEMPLOYMENT);
  }

  public static removeUnemployment(statement: Statement, index: number): void {
    SocialIncomeManager.remove(statement, SocialIncomeTypes.UNEMPLOYMENT, index);
  }

  public static getParental(statement: Statement): SocialIncome[] {
    return SocialIncomeManager.get(statement, SocialIncomeTypes.PARENTAL);
  }

  public static createParental(statement: Statement): SocialIncome {
    return SocialIncomeManager.create(statement, SocialIncomeTypes.PARENTAL);
  }

  public static removeAllParental(statement: Statement): void {
    SocialIncomeManager.removeAll(statement, SocialIncomeTypes.PARENTAL);
  }

  public static removeParental(statement: Statement, index: number): void {
    SocialIncomeManager.remove(statement, SocialIncomeTypes.PARENTAL, index);
  }

  public static getInsolvency(statement: Statement): SocialIncome[] {
    return SocialIncomeManager.get(statement, SocialIncomeTypes.INSOLVENCY);
  }

  public static createInsolvency(statement: Statement): SocialIncome {
    return SocialIncomeManager.create(statement, SocialIncomeTypes.INSOLVENCY);
  }

  public static removeAllInsolvency(statement: Statement): void {
    SocialIncomeManager.removeAll(statement, SocialIncomeTypes.INSOLVENCY);
  }

  public static removeInsolvency(statement: Statement, index: number): void {
    SocialIncomeManager.remove(statement, SocialIncomeTypes.INSOLVENCY, index);
  }

  public static getSickness(statement: Statement): SocialIncome[] {
    return SocialIncomeManager.get(statement, SocialIncomeTypes.SICKNESS);
  }

  public static createSickness(statement: Statement): SocialIncome {
    return SocialIncomeManager.create(statement, SocialIncomeTypes.SICKNESS);
  }

  public static removeAllSickness(statement: Statement): void {
    SocialIncomeManager.removeAll(statement, SocialIncomeTypes.SICKNESS);
  }

  public static removeSickness(statement: Statement, index: number): void {
    SocialIncomeManager.remove(statement, SocialIncomeTypes.SICKNESS, index);
  }

  public static getMaternity(statement: Statement): SocialIncome[] {
    return SocialIncomeManager.get(statement, SocialIncomeTypes.MATERNITY);
  }

  public static createMaternity(statement: Statement): SocialIncome {
    return SocialIncomeManager.create(statement, SocialIncomeTypes.MATERNITY);
  }

  public static removeAllMaternity(statement: Statement): void {
    SocialIncomeManager.removeAll(statement, SocialIncomeTypes.MATERNITY);
  }

  public static removeMaternity(statement: Statement, index: number): void {
    SocialIncomeManager.remove(statement, SocialIncomeTypes.MATERNITY, index);
  }

  public static getInjury(statement: Statement): SocialIncome[] {
    return SocialIncomeManager.get(statement, SocialIncomeTypes.INJURY);
  }

  public static createInjury(statement: Statement): SocialIncome {
    return SocialIncomeManager.create(statement, SocialIncomeTypes.INJURY);
  }

  public static removeAllInjury(statement: Statement): void {
    SocialIncomeManager.removeAll(statement, SocialIncomeTypes.INJURY);
  }

  public static removeInjury(statement: Statement, index: number): void {
    SocialIncomeManager.remove(statement, SocialIncomeTypes.INJURY, index);
  }

  public static getTransition(statement: Statement): SocialIncome[] {
    return SocialIncomeManager.get(statement, SocialIncomeTypes.TRANSITION);
  }

  public static createTransition(statement: Statement): SocialIncome {
    return SocialIncomeManager.create(statement, SocialIncomeTypes.TRANSITION);
  }

  public static removeAllTransition(statement: Statement): void {
    SocialIncomeManager.removeAll(statement, SocialIncomeTypes.TRANSITION);
  }

  public static removeTransition(statement: Statement, index: number): void {
    SocialIncomeManager.remove(statement, SocialIncomeTypes.TRANSITION, index);
  }

  public static getOther(statement: Statement): SocialIncome[] {
    return SocialIncomeManager.get(statement, SocialIncomeTypes.OTHER);
  }

  public static createOther(statement: Statement): SocialIncome {
    return SocialIncomeManager.create(statement, SocialIncomeTypes.OTHER);
  }

  public static removeAllOther(statement: Statement): void {
    SocialIncomeManager.removeAll(statement, SocialIncomeTypes.OTHER);
  }

  public static removeOther(statement: Statement, index: number): void {
    SocialIncomeManager.remove(statement, SocialIncomeTypes.OTHER, index);
  }

  public static get(statement: Statement, incomeType: SocialIncomeTypes): SocialIncome[] {
    return ArrayHelper.arrayFilter(statement.additionalIncome, "incomeType", incomeType);
  }

  public static create(statement: Statement, incomeType: SocialIncomeTypes): SocialIncome {
    const additionalIncome = new SocialIncome(incomeType);
    statement.additionalIncome.push(additionalIncome);

    return additionalIncome;
  }

  public static removeAll(statement: Statement, incomeType: SocialIncomeTypes): void {
    statement.additionalIncome = statement.additionalIncome.filter((x) => x.incomeType !== incomeType);
  }

  public static remove(statement: Statement, incomeType: SocialIncomeTypes, index: number): void {
    const additionalIncomesList = SocialIncomeManager.get(statement, incomeType);
    additionalIncomesList.splice(index, 1);
    SocialIncomeManager.removeAll(statement, incomeType);

    statement.additionalIncome = [...statement.additionalIncome, ...additionalIncomesList];
  }
}
