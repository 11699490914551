<app-menu-bar-mobile></app-menu-bar-mobile>
<div class="packages-list" *ngIf="isBusiness">
    <h1 class="packages-list__title title">{{ 'PACKAGES.TITLE' | translate }}</h1>
    <div class="packages-list__description description">{{ 'PACKAGES.DESCRIPTION' | translate }}</div>
    <div class="packages-list__content">
        <app-accordion [title]="'ACCORDION.PAYMENT_DETAILS'" [titleFrame]="true" [transparentBg]="true"
                       [noPadding]="true">
            <app-payment-details></app-payment-details>
        </app-accordion>
    </div>
</div>
