import { firstValueFrom, of } from 'rxjs';
import { getApiPath } from 'app/common';
import { AuthHttpService } from 'app/users';
import { ChangePasswordResponseInterface } from 'app/modules/user/models/change-password/change-password.response.interface';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpResponseModel } from 'app/core/models/http/http-response.model';
import { AuthModel } from 'app/modules/user/models/auth/auth.model';
import { HttpResponseInterface } from 'app/core/models/http/http-response.interface';
import { AuthResponseInterface } from 'app/modules/user/models/auth/auth.response.interface';
import { ChangePasswordErrorInterface } from 'app/modules/user/models/change-password/change-password.error.interface';

@Injectable({
  providedIn: 'root',
})
export class UserChangePasswordHttpService {
  constructor(
    private authHttp: AuthHttpService,
  ) {
  }

  public async changePassword(
    data: ChangePasswordResponseInterface
  ): Promise<HttpResponseInterface<AuthModel, ChangePasswordErrorInterface>> {
    return await firstValueFrom(
      this.authHttp.post<AuthResponseInterface>(`${getApiPath()}accounts/users/set_password/`, data)
        .pipe(
          map((response) =>
            HttpResponseModel.fromResponse<AuthModel>(response ? AuthModel.fromResponse(response) : null)),
          catchError(e => of(HttpResponseModel.fromError<ChangePasswordErrorInterface>(e.error))),
        )
    );
  }

  public async resetPasswordRequest(
    email: string,
    captchaToken?: string,
  ): Promise<HttpResponseInterface<null, ChangePasswordErrorInterface>> {
    return await firstValueFrom(
      this.authHttp.post<AuthResponseInterface>(`${getApiPath()}accounts/users/reset_password/`, {
        email,
        'cf-turnstile-response': captchaToken,
      })
        .pipe(
          map((response) =>
            HttpResponseModel.fromResponse(null)),
          catchError(e => of(HttpResponseModel.fromError<ChangePasswordErrorInterface>(e.error))),
        )
    );
  }

  public async resetPasswordChange(
    data: ChangePasswordResponseInterface
  ): Promise<HttpResponseInterface<null, ChangePasswordErrorInterface>> {
    return await firstValueFrom(
      this.authHttp.post<AuthResponseInterface>(`${getApiPath()}accounts/users/reset_password_confirm/`, data)
        .pipe(
          map((response) =>
            HttpResponseModel.fromResponse(null)),
          catchError(e => of(HttpResponseModel.fromError<ChangePasswordErrorInterface>(e.error))),
        )
    );
  }
}
