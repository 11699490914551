import { Component } from "@angular/core";
import { ButtonBaseComponent } from "app/shared/components/buttons/button-base.component";

@Component({
  ...ButtonBaseComponent.metaData,
  selector: "app-button-add",
  templateUrl: "./button-add.component.html",
  styleUrls: ["./button-add.component.scss"]
})
export class ButtonAddComponent extends ButtonBaseComponent {}
