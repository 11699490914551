import { Component, EventEmitter, Input, Output, SimpleChanges, TemplateRef } from "@angular/core";
import { SubscribingComponent } from "app/common";
import { ValidateFieldV2Service } from "app/core/form-validator/validate-field-v2.service";
import { VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME } from "app/core/form-validator/validator-field-names/validator-field-names-additional-income";
import { ErrorMessage, ErrorMessageContent } from "app/core/form-validator/validatorInterface";
import { AdditionalIncomeService } from "app/modules/statement/services/additional-income.service";

export enum INPUT_TYPE {
  INCOMES = "incomes",
  COSTS = "costs"
}

@Component({
  selector: "app-income-input",
  templateUrl: "./income-input.component.html",
  styleUrls: ["./income-input.component.scss"]
})
export class IncomeInput extends SubscribingComponent {
  @Input() showIncomeInput = false;
  @Input() inputValueList: any;
  @Input() selectValueList: any;
  @Input() title = "";
  @Input() selectLabel = "";
  @Input() selectPlaceholder = "";
  @Input() inputLabel = "";
  @Input() vatInputLabel = "";
  @Input() inputPlaceholder = "";
  @Input() vatInputPlaceholder = "";
  @Input() button1 = "";
  @Input() button2 = "";
  @Input() editIndex: number;
  @Input() titleTooltip: string;
  @Input() messageTooltip: string;
  @Input() tooltipContentRef: TemplateRef<any> = null;
  @Input() notShowSelectList: [];
  @Input() dataSelects: [];
  @Input() statement: any;
  @Input() btnTextFirst = "COMMON.NO";
  @Input() btnTextSecond = "COMMON.YES";
  @Input() inputType: INPUT_TYPE = INPUT_TYPE.INCOMES;
  @Input() alertInfo = "";
  @Input() isLump = false;

  @Output() changeShowIcomeInput: EventEmitter<any> = new EventEmitter<any>();

  public localInputValue: string = null;
  public localSelectValue: string = null;
  public localVatValue: string = null;
  public epValidator: ErrorMessage[];
  public activeSecondButton = false;
  public hasVatValue = false;

  public fieldsNames = VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME;

  constructor(
    public additionalIncomeService: AdditionalIncomeService,
    private validateFieldV2Service: ValidateFieldV2Service
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    //reset values when we change type of cost
    if (changes?.isLump?.currentValue) {
      if (this.localInputValue) this.localInputValue = null;
      if (this.localSelectValue) this.localSelectValue = null;
    }
  }

  private addListEpValidatorMessage(field_name: string, message: string): void {
    const errorMessage = new ErrorMessage();
    errorMessage.fieldName = field_name;
    errorMessage.index = 0;
    errorMessage.messages = [new ErrorMessageContent(message)];

    console.log(errorMessage);

    this.epValidator.push(errorMessage);
  }

  public validateValues() {
    this.epValidator = [];
    const dataForValidation = [
      { income_value: this.localInputValue, operating_vat_type: this.localSelectValue, vat_value: this.localVatValue }
    ];

    const validationFieldKeys = [VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.operating_vat_type];

    if (!this.isLump || (this.isLump && this.localSelectValue[4]))
      validationFieldKeys.push(VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.income_value); //if is lump cost

    if (this.isLump && this.localSelectValue[4]) {
      //TAX-3105 validation ad 3
      if (this.additionalIncomeService.getMainStatement().fullYear < 2023 && parseFloat(this.localInputValue) > 3600) {
        this.addListEpValidatorMessage(this.fieldsNames.income_value, "LUMP_COST_TOO_HEIGHT_YEAR_LOWER_THAN_2023");
      } else if (
        this.additionalIncomeService.getMainStatement().fullYear >= 2023 &&
        parseFloat(this.localInputValue) > 4800
      ) {
        this.addListEpValidatorMessage(this.fieldsNames.income_value, "LUMP_COST_TOO_HEIGHT_YEAR_HIGHER_THEN_2023");
      }
    }

    if (this.localSelectValue && this.localSelectValue.length === 5 && this.localSelectValue[4] && !this.isLump)
      validationFieldKeys.push(VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.vat_value); //if has vat

    this.epValidator = [
      ...this.epValidator,
      ...this.validateFieldV2Service.validateFieldAndGetMessageV2(dataForValidation, validationFieldKeys)
    ];

    return !this.epValidator.length;
  }

  public handleAddAction(addValue: boolean) {
    if (addValue) {
      if (this.editIndex > this.inputValueList.length) this.editIndex = this.inputValueList.length; //TAX-3103
      if (this.validateValues()) {
        switch (this.inputType) {
          case INPUT_TYPE.INCOMES:
            this.inputValueList[this.editIndex] = {
              freelance_type_id: this.localSelectValue[0],
              value: this.localInputValue,
              name: this.localSelectValue[1],
              vat_value: this.localVatValue
            };
            break;
          case INPUT_TYPE.COSTS:
            this.inputValueList[this.editIndex] = {
              freelance_type_id: this.localSelectValue[0],
              value: this.localInputValue,
              name: this.localSelectValue[1]
            };
            break;
        }
        this.selectValueList[this.editIndex] = [...this.localSelectValue];
        this.changeShowIcomeInput.emit(false);
      }
    } else {
      this.changeShowIcomeInput.emit(false);
    }
  }

  public filterDataSelectList() {
    //check if some dataSelects values are chosen, if yes filter dataSelects list and display only not chosen options
    if (this.inputValueList.length == 0) return this.dataSelects;

    return this.dataSelects.filter((value) => {
      if (!this.inputValueList.some((el) => el.freelance_type_id === value[0])) return value;
    });
  }

  public handleDisabledSecondButton() {
    if (!this.localInputValue && !this.localSelectValue) this.activeSecondButton = false;

    this.activeSecondButton = true;
  }

  public activeAddButton() {
    if (this.isLump) return !!this.localSelectValue;

    if (this.localSelectValue && this.localSelectValue.length === 5 && this.localSelectValue[4]) {
      return !!this.localInputValue && !!this.localVatValue && !!this.localSelectValue == true;
    }

    return !!this.localInputValue && !!this.localSelectValue == true;
  }
}
