import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-notification-base',
  template: '',
  styleUrls: ['./notification-base.component.scss']
})
export class NotificationBaseComponent {
  static metaData = {
    inputs: [
      'preTitle',
      'notificationMessage',
      'extraClasses',
      'buttonStandardTitle',
      'buttonPrimaryTitle',
      'buttonCloseIsEnabled',
      'buttonStandardIsDisabled',
      'buttonPrimaryIsDisabled',
      'buttonStandardIsLoading',
      'buttonPrimaryIsLoading',
      'buttonStandardExtraClasses',
      'buttonPrimaryExtraClasses',
    ],
    outputs: [
      'buttonStandardClickEmitter',
      'buttonPrimaryClickEmitter',
      'buttonCloseClickEmitter',
    ],
  };
  @Input() preTitle = 'NOTIFICATION.ERROR_TXT';
  @Input() notificationMessage: string;
  @Input() extraClasses: string;
  @Input() buttonStandardTitle: string;
  @Input() buttonPrimaryTitle: string;
  @Input() buttonCloseIsEnabled: boolean;
  @Input() buttonStandardIsLoading: boolean;
  @Input() buttonPrimaryIsLoading: boolean;
  @Input() buttonStandardIsDisabled: boolean;
  @Input() buttonPrimaryIsDisabled: boolean;
  @Input() buttonStandardExtraClasses: string;
  @Input() buttonPrimaryExtraClasses: string;
  @Output() buttonStandardClickEmitter = new EventEmitter();
  @Output() buttonPrimaryClickEmitter = new EventEmitter();
  @Output() buttonCloseClickEmitter = new EventEmitter();

  public buttonStandardClickEvent(): void {
    if (this.buttonStandardIsDisabled) {
      return;
    }

    this.buttonStandardClickEmitter.emit();
  }

  public buttonPrimaryClickEvent(): void {
    if (this.buttonPrimaryIsDisabled) {
      return;
    }

    this.buttonPrimaryClickEmitter.emit();
  }

  public buttonCloseClickEvent(): void {
    this.buttonCloseClickEmitter.emit();
  }
}
