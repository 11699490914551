<div class="message-sender new-message-dialog">
  <app-section-title-h3
    [title]="data.text.modalTitle"
    [titleTooltip]="data.text.titleTooltip"
    [messageTooltip]="data.text.messageTooltip"
  ></app-section-title-h3>
  <div class="text-area-container">
    <ng-container *ngIf="requireTitle()">
      <div class="input-cotainer">
        <app-form-input [label]="data.text.titleLabel" [placeholder]="''" [(model)]="title"></app-form-input>
        <div class="text-counter" [class.too-long]="checkIfTextIsTooLong(title, titleMaxLength)"
          >{{ getTextLength(title) }}/{{ titleMaxLength }}</div
        >
      </div>
    </ng-container>
    <ng-container *ngIf="requireMessage()">
      <div class="input-cotainer">
        <app-form-input
          [label]="data.text.messageLabel"
          [placeholder]="''"
          [(model)]="text"
          [textArea]="true"
        ></app-form-input>
        <div class="text-counter" [class.too-long]="checkIfTextIsTooLong(text, textMaxLength)"
          >{{ getTextLength(text) }}/{{ textMaxLength }}</div
        >
      </div>
      <app-validator-error-message
        [epValidator]="epValidator"
        [index]="0"
        [fieldName]="validatorFieldNames.message"
      ></app-validator-error-message>
    </ng-container>
    <div class="attachments-container">
      <app-add-attachment-list
        [buttonText]="data.text.addFileButton"
        [attachmentsList]="attachmentsList"
        [maxFilesNumber]="getMaxFilesNumber()"
        [sendedAttachments]="sendedAttachments"
      ></app-add-attachment-list>
    </div>
    <ng-container *ngIf="requireEnforcement()">
      <div class="input-cotainer mb-20">
        <mat-checkbox [(ngModel)]="requireEnforcementValue" [attr.data-cy]="'checkboxFiledTogether'">
          <span class="checkbox_txt">{{ data.text.checkboxText | translate }}</span>
        </mat-checkbox>
      </div>
    </ng-container>
    <ng-container *ngIf="requireDecisionDate()">
      <div class="input-cotainer mb-20">
        <app-form-date-input
          [label]="'SEND_MESSAGE_DIALOG.APPEAL.DATE'"
          [(model)]="localDecisionDate"
          [noYear]="false"
          [placeholder]="'COMMON.DATE_FORMAT'"
        >
        </app-form-date-input>
      </div>
      <app-validator-error-message
        [epValidator]="epValidator"
        [index]="0"
        [fieldName]="validatorFieldNames.message"
      ></app-validator-error-message>
    </ng-container>
  </div>
  <ng-container *ngIf="requireSendByEmail()">
    <div class="input-cotainer mb-20">
      <mat-checkbox [(ngModel)]="sendByEmail" [attr.data-cy]="'checkboxFiledTogether'">
        <span class="checkbox_txt">{{ data.text.sendByEmailText | translate }}</span>
      </mat-checkbox>
    </div>
  </ng-container>
  <div class="text-area-container">
    <div class="input-cotainer mb-20" *ngIf="requireIdNumber()">
      <app-form-input
        [errors]="errors?.identifikationsnummer"
        [label]="'INPUT.IDENTIFIKATIONSNUMMER.LABEL'"
        [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
        [(model)]="localIdNumber"
      ></app-form-input>
      <app-validator-error-message
        [epValidator]="epValidator"
        [index]="0"
        [fieldName]="validatorFieldNames.id_number"
      ></app-validator-error-message>
    </div>
    <div class="input-cotainer mb-20" *ngIf="requireSteuernummer()">
      <app-form-input
        [errors]="errors?.steuernummer"
        [label]="'INPUT.STEUERNUMMER.LABEL'"
        [currentInputId]="'applicantSteuernummer'"
        [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
        [(model)]="localSteuernummer"
      ></app-form-input>
      <app-validator-error-message
        [epValidator]="epValidator"
        [index]="0"
        [fieldName]="validatorFieldNames.tax_number"
      ></app-validator-error-message>
    </div>
    <div class="input-cotainer mb-20" *ngIf="requireTaxAuthority()">
      <app-personal-info-tax-authority
        [taxAuthority]="localTaxAuthority"
        (setTaxAuthority)="localTaxAuthority = $event"
        [taxAuthorities]="dataSelects.taxAuthorities"
        [errors]="errors?.tax_authority"
      ></app-personal-info-tax-authority>
      <app-validator-error-message
        [epValidator]="epValidator"
        [index]="0"
        [fieldName]="validatorFieldNames.tax_authority"
      >
      </app-validator-error-message>
    </div>
  </div>
  <app-button-two
    [btnTextSecond]="data.onlySave ? 'SEND_MESSAGE_DIALOG.BUTTON_SAVE' : 'SEND_MESSAGE_DIALOG.BUTTON_SEND'"
    [btnTextFirst]="'SEND_MESSAGE_DIALOG.BUTTON_CANCEL'"
    (cancelEmitter)="closeModal()"
    (addEmitter)="handleSendMessage()"
    [isDisabled]="checkIfSendMessageIsDisabled()"
    [isLoading]="isLoading"
  ></app-button-two>
  <div *ngIf="isRequestError" class="error">{{ "SEND_MESSAGE_DIALOG.ERROR.REQUEST" | translate }}</div>
</div>
