import {finalize, takeUntil} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {PaymentService} from 'app/payment/payment.service';
import {Payment} from '../payment';
import {SubscribingComponent, saveFile} from '../../common';
import {getUser, State} from '../../reducers';
import {User} from '../../users';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import { DashboardPagePath } from 'app/core/workflow/page-path/dashboard-page.path';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent extends SubscribingComponent implements OnInit {
  payments: Payment[] = [];
  paymentsFetching = false;
  downloadingInvoice = false;

  constructor(
    private paymentService: PaymentService,
    private store: Store<State>,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.paymentsFetching = true;

    this.store.select(getUser).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          if (user.isBusiness) {
            this.paymentService.getPaymentHistory().pipe(
              takeUntil(this.ngUnsubscribe),
              finalize(() => this.paymentsFetching = false), )
              .subscribe((response: Payment[]) => {
                this.payments = response;
              });
          } else {
            this.router.navigate([DashboardPagePath.fullUrl()]);
          }
        }
      });
  }

  downloadInvoice(payment: Payment) {
    if (!this.downloadingInvoice) {
      this.downloadingInvoice = true;
      this.paymentService.downloadInvoice(payment.id).pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.downloadingInvoice = false),
      ).subscribe((file: Blob) => saveFile(file, `invoice-${payment.clearedDate}.pdf`));
    }
  }
}
