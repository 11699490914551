<div
  class="form-select"
  (clickElsewhere)="closeTooltip()"
  [class.disabled]="isDisabled"
  [class.offsetRightArrow]="isVerification"
  [class.employerStage]="employerStage"
  [attr.data-cy]="dataCyName"
>
    <div class="form-select__header level" *ngIf="label || message">
        <div class="level-left" (click)="closeTooltip()">
            <label class="form-select__label is-small">
                <span class="form-select__label-tx">{{ label | translate }}</span>
            </label>
        </div>
        <!-- <div class="level-right tooltip-ico" *ngIf="message" (click)="toggleTooltip()">
            <span class="more-info__ico info-ico is-unselectable">i</span>
        </div> -->
        <app-more-info-tooltip class="tooltipInInput" *ngIf="message" [message]="message" [isSmall]="true"
            [showText]="false" [isSmallIcon]="true" [additionalDeductions]="true" [tooltipInInput]=true></app-more-info-tooltip>
    </div>
    <mat-select
      [panelClass]="disableOptionCentering ? 'customPanelClass' : ''" class="mat-select-white"
      [placeholder]="placeholder | translate" [(ngModel)]="model" (openedChange)="onFocus($event)"
      (selectionChange)="change($event)" [class.form-select--error]="errors" [disabled]="isDisabled"
      id="{{currentName+'_'+currentIndex.toString()}}" [ngClass]="addClassB2B ? classB2B : ''" required
      [attr.autofocus]="autofocus ? autofocus : null"
    >
        <mat-option *ngFor="let choice of choices; let i = index; trackBy: trackBy;"
            class="{{currentName + '_' +currentIndex+ '_option_' + i}} mat-option"
            [value]="valueName != null ? choice[valueName] : choice">
            <span *ngIf="translateChoices">{{ (optionName != null ? choice[optionName] : choice) | translate }}</span>
            <span *ngIf="!translateChoices">{{ optionName != null ? choice[optionName] : choice }}</span>
        </mat-option>
    </mat-select>
    <!-- <div class="tooltip-container top" *ngIf="displayTooltip && message" role="tooltip">
        <div class="tooltip-container__close">
            <span class="fa fa-times-circle" (click)="closeTooltip()"></span>
        </div>taxClass ng-star-inserted
        <div class="tooltip-container__text">{{ message | translate }}</div>
    </div> -->
    <div class="form-select__error" *ngIf="errors">{{ errors[0] }}
    </div>
</div>
