import { DateRange, DateRangeErrors, DateRangeResponse, toResponseDate } from '../common/dateRange';
import { Residence, ResidenceErrors, ResidenceResponse } from './residence';
import { ADULT_AGE } from 'app/common';
import { RecognizerHelper } from 'app/core/helpers/recognizer.helper';
import { DateHelper } from 'app/core/helpers/date.helper';

export interface ChildResponse {
  id: number;
  first_name: string;
  last_name: string;
  birth_date: string;
  identifikationsnummer: string;
  has_different_address: boolean;
  different_address: string;
  kindergeld: string;
  kindergeld_extra: string;
  saved_kindergeld: boolean;
  familienkasse: string;
  propinquity: string;
  propinquity_to_spouse: string;
  second_parent?: boolean;
  second_parent_first_name: string;
  second_parent_last_name: string;
  second_parent_birth_date: string;
  last_known_address: string;
  dont_know_address: boolean;
  propinquity_to_second_parent: string;
  second_parent_death_date: string;
  second_parent_died: boolean;
  lived_whole_year: boolean;
  is_propinquity_period: boolean;
  propinquity_from: DateRangeResponse;
  second_parent_lived_abroad: boolean;
  second_parent_lived_abroad_from: DateRangeResponse;
  residence: ResidenceResponse[];

  // Submodule 3:
  is_studying_or_completed?: boolean;
  completed_first_degree?: boolean;
  is_studying?: boolean;
  school_names?: string[];
  education_dates?: DateRangeResponse[];
  lack_of_training_space?: boolean;
  lack_of_training_space_dates?: DateRangeResponse[];
  has_voluntary_year?: boolean;
  voluntary_year_dates?: DateRangeResponse[];
  been_in_transitional_period?: boolean;
  transitional_period_dates?: DateRangeResponse[];
  is_unemployed?: boolean;
  unemployment_dates?: DateRangeResponse[];
  disability_before_25?: boolean;
  disability_before_25_dates?: DateRangeResponse[];
  have_job?: boolean;
  have_minijobs?: boolean;
  minijob_dates?: DateRangeResponse[];
  minijob_number_of_hours?: string[];
  was_employed?: boolean;
  employment_dates?: DateRangeResponse[];
  employment_number_of_hours?: string[];

  // Submodule 4:
  paid_school_fees?: string[];
  paid_school_names?: string[];
  applicant_participation_in_school_costs?: string;

  // Submodule 5:
  childcare_types?: string[];
  childcare_name_and_address?: string[];
  childcare_dates?: DateRangeResponse[];
  childcare_costs?: string[];
  applicant_participation_in_childcare_costs?: string[];
  childcare_amount_of_funding?: string[];
  parents_didnt_live_with_child?: boolean;
  parents_didnt_live_with_child_dates?: DateRangeResponse[];
  child_didnt_live_with_applicant_whole_year?: boolean;
  child_lived_with_applicant_dates?: DateRangeResponse[];
  child_lived_with_second_parent_dates?: DateRangeResponse[];

  // Submodule 6:
  tax_free_allowance_request_second_parent?: boolean;
  payments_for_childcare_dates?: DateRangeResponse[];
  tax_free_allowance_request_residence?: boolean;
  not_registered_dates?: DateRangeResponse[];

  // Submodule 7:
  raising_child_lonely?: boolean;
  registered_in_applicants_flat_dates?: DateRangeResponse[];
  kindergeld_dates?: DateRangeResponse[];
  other_adults_registered?: boolean[];
  other_adults_registered_dates?: DateRangeResponse[];
  common_household?: boolean[];
  common_household_dates?: DateRangeResponse[];
  common_household_person_name?: string[][];
  common_household_person_propinquity?: string[][];
  common_household_person_profession?: string[][];

  // Submodule 8:
  studied_outside_place_of_residence?: boolean;
  studied_outside_place_of_residence_dates?: DateRangeResponse[];
  place_of_residence?: string[];
  studies_allowance_is_not_fifty_fifty?: boolean;
  participation_in_studies_allowance?: string;

  // Submodule 9:
  has_disability_or_is_bereaved?: boolean;
  is_bereaved?: boolean;
  has_disability?: boolean;
  is_blind?: boolean;
  is_impaired?: boolean;
  disability_level?: string;
  invalidity_card_date?: string[];
  invalidity_card_valid_from?: string[];
  invalidity_card_valid_to?: string[];
  invalidity_card_valid_forever?: boolean[];
  disability_allowance_is_not_fifty_fifty?: boolean;
  participation_in_disability_allowance?: string;
}

export interface ChildErrors {
  first_name?: string[];
  last_name?: string[];
  birth_date?: string[];
  has_different_address?: string[];
  different_address?: string[];
  identifikationsnummer?: string[];
  saved_kindergeld?: boolean[];
  kindergeld?: string[];
  kindergeld_extra?: string[];
  familienkasse?: string[];
  dont_know_address?: string[];
  last_known_address?: string[];
  propinquity_to_second_parent?: string[];
  propinquity?: string[];
  propinquity_to_spouse?: string[];
  second_parent_first_name?: string[];
  second_parent_last_name?: string[];
  second_parent_birth_date?: string[];
  second_parent_died?: string[];
  second_parent_death_date?: string[];
  lived_whole_year?: string[];
  is_propinquity_period?: string[];
  propinquity_from?: DateRangeErrors;
  lived_in_de_from?: DateRangeErrors;
  second_parent_lived_abroad?: string[];
  second_parent_lived_abroad_from?: DateRangeErrors;
  residence?: ResidenceErrors[];

  // Submodule 3:
  is_studying_or_completed?: string[];
  completed_first_degree?: string[];
  is_studying?: string[];
  school_names?: string[];
  education_dates?: string[];
  lack_of_training_space?: string[];
  lack_of_training_space_dates?: string[];
  has_voluntary_year?: string[];
  voluntary_year_dates?: string[];
  been_in_transitional_period?: string[];
  transitional_period_dates?: string[];
  is_unemployed?: string[];
  unemployment_dates?: string[];
  disability_before_25?: string[];
  disability_before_25_dates?: string[];
  have_job?: string[];
  have_minijobs?: string[];
  minijob_dates?: string[];
  minijob_number_of_hours?: string[];
  was_employed?: string[];
  employment_dates?: string[];
  employment_number_of_hours?: string[];

  // Submodule 4:
  paid_school_fees?: string[];
  paid_school_names?: string[];
  applicant_participation_in_school_costs?: string[];

  // Submodule 5:
  childcare_types?: string[];
  childcare_name_and_address?: string[];
  childcare_dates?: string[];
  childcare_costs?: string[];
  applicant_participation_in_childcare_costs?: string[];
  childcare_amount_of_funding?: string[];
  parents_lived_with_child_dates?: string[];
  parents_didnt_live_with_child?: string[];
  parents_didnt_live_with_child_dates?: string[];
  child_didnt_live_with_applicant_whole_year?: string[];
  child_lived_with_applicant_dates?: string[];
  child_lived_with_second_parent_dates?: string[];

  // Submodule 6:
  tax_free_allowance_request_second_parent?: string[];
  payments_for_childcare_dates?: string[];
  tax_free_allowance_request_residence?: string[];
  not_registered_dates?: string[];

  // Submodule 7:
  raising_child_lonely?: string[];
  registered_in_applicants_flat_dates?: string[];
  kindergeld_dates?: string[];
  other_adults_registered?: string[];
  other_adults_registered_dates?: string[];
  common_household?: string[];
  common_household_dates?: string[];
  common_household_person_name?: string[];
  common_household_person_propinquity?: string[];
  common_household_person_profession?: string[];

  // Submodule 8:
  studied_outside_place_of_residence?: string[];
  studied_outside_place_of_residence_dates?: string[];
  place_of_residence?: string[];
  studies_allowance_is_not_fifty_fifty?: string[];
  participation_in_studies_allowance?: string[];

  // Submodule 9:
  has_disability_or_is_bereaved?: string[];
  is_bereaved?: string[];
  has_disability?: string[];
  is_blind?: string[];
  is_impaired?: string[];
  disability_level?: string[];
  invalidity_card_date?: string[];
  invalidity_card_valid_from?: string[];
  invalidity_card_valid_to?: string[];
  invalidity_card_valid_forever?: string[];
  disability_allowance_is_not_fifty_fifty?: string[];
  participation_in_disability_allowance?: string[];

  non_field_errors?: string[];
}

export class Child {
  id: number;
  firstName = '';
  lastName = '';
  birthDate = '';
  hasDifferentAddress = false;
  differentAddress = '';
  identifikationsnummer = '';
  savedKindergeld = false;
  kindergeld = '';
  kindergeldDefault = true;
  kindergeldExtra = '';
  familienkasse = '';
  propinquity = '';
  propinquityToSpouse = '';
  lastKnownAddress = '';
  dontKnowAddress = false;
  secondParent = false;
  secondParentFirstName = '';
  secondParentLastName = '';
  secondParentBirthDate = '';
  propinquityToSecondParent = '';
  secondParentDeathDate = '';
  secondParentDied = false;
  livedWholeYear = false;
  isPropinquityPeriod = false;
  propinquityFrom: DateRange = new DateRange();
  secondParentLivedAbroad = false;
  secondParentLivedAbroadFrom: DateRange = new DateRange();
  residence: Residence[] = [];

  // Submodule 3:
  isStudyingOrCompleted?: boolean;
  completedFirstDegree?: boolean;
  isStudying?: boolean;
  schoolNames?: string[];
  educationDates?: DateRange[];
  lackOfTrainingSpace?: boolean;
  lackOfTrainingSpaceDates?: DateRange[];
  hasVoluntaryYear?: boolean;
  voluntaryYearDates?: DateRange[];
  beenInTransitionalPeriod?: boolean;
  transitionalPeriodDates?: DateRange[];
  isUnemployed?: boolean;
  unemploymentDates?: DateRange[];
  disabilityBefore25?: boolean;
  disabilityBefore25Dates?: DateRange[];
  haveJob?: boolean;
  haveMinijobs?: boolean;
  minijobDates?: DateRange[];
  minijobNumberOfHours?: string[];
  wasEmployed?: boolean;
  employmentDates?: DateRange[];
  employmentNumberOfHours?: string[];

  selectedInfos: string[] = [];

  // Submodule 4:
  paidSchoolAtendance: boolean;
  paidSchoolFees?: string[];
  paidSchoolNames?: string[];
  applicantParticipationInSchoolCosts?: string;

  // Submodule 5:
  childcareUsage: boolean;
  childcareTypes?: string[];
  childcareNameAndAddress?: string[];
  childcareDates?: DateRange[];
  childcareCosts?: string[];
  applicantParticipationInChildcareCosts?: string[];
  childcareAmountOfFunding?: string[];
  parentsDidntLiveWithChild?: boolean;
  parentsDidntLiveWithChildDates?: DateRange[];
  childDidntLiveWithApplicantWholeYear?: boolean;
  childLivedWithApplicantDates?: DateRange[];
  childLivedWithSecondParentDates?: DateRange[];

  // Submodule 6:
  taxFreeAllowanceRequestEnabled: boolean;
  taxFreeAllowanceRequestSecondParent?: boolean;
  paymentsForChildcareDates?: DateRange[];
  taxFreeAllowanceRequestResidence?: boolean;
  notRegisteredDates?: DateRange[];

  // Submodule 7:
  raisingChildLonely?: boolean;
  registeredInApplicantsFlatDates?: DateRange[];
  kindergeldDates?: DateRange[];
  otherAdultsRegistered?: boolean[];
  otherAdultsRegisteredDates?: DateRange[];
  commonHousehold?: boolean[];
  commonHouseholdDates?: DateRange[];
  commonHouseholdPersonName?: string[][];
  commonHouseholdPersonPropinquity?: string[][];
  commonHouseholdPersonProfession?: string[][];
  _commonHouseholdPersonDefault = ['', '', '', '', ''];

  // Submodule 8:
  studiedOutsidePlaceOfResidence?: boolean;
  studiedOutsidePlaceOfResidenceDates?: DateRange[];
  placeOfResidence?: string[];
  studiesAllowanceIsNotFiftyFifty?: boolean;
  participationInStudiesAllowance?: string;

  // Submodule 9:
  isBereaved?: boolean;
  hasDisabilityEnabled: boolean;
  hasDisability?: boolean;
  isBlind?: boolean;
  isImpaired?: boolean;
  disabilityLevel?: string;
  invalidityCardDate?: string[];
  invalidityCardValidFrom?: string[];
  invalidityCardValidTo?: string[];
  invalidityCardValidForever?: boolean[];
  disabilityAllowanceIsNotFiftyFifty?: boolean;
  participationInDisabilityAllowance?: string;

  static fromResponse(response: ChildResponse): Child {
    const toDateRange = (value: DateRangeResponse) => DateRange.fromResponse(value);

    const child = new Child();
    child.id = response.id;
    child.firstName = response.first_name;
    child.lastName = response.last_name;
    child.birthDate = response.birth_date;
    child.hasDifferentAddress = response.has_different_address;
    child.differentAddress = response.different_address;
    child.identifikationsnummer = response.identifikationsnummer;
    child.savedKindergeld = response.saved_kindergeld;
    child.kindergeld = response.kindergeld;
    child.kindergeldExtra = response.kindergeld_extra;
    child.kindergeldDefault = RecognizerHelper.isNullOrUndefined(child.kindergeldExtra) || child.kindergeldExtra === '';
    child.familienkasse = response.familienkasse;
    child.propinquity = response.propinquity;
    child.propinquityToSpouse = response.propinquity_to_spouse;
    child.lastKnownAddress = response.last_known_address;
    child.dontKnowAddress = response.dont_know_address;
    child.propinquityToSecondParent = response.propinquity_to_second_parent;
    child.secondParentDeathDate = response.second_parent_death_date;
    child.secondParentDied = response.second_parent_died;
    child.secondParent = response.second_parent;
    child.secondParentFirstName = response.second_parent_first_name;
    child.secondParentLastName = response.second_parent_last_name;
    child.secondParentBirthDate = response.second_parent_birth_date;
    child.livedWholeYear = response.lived_whole_year;
    child.isPropinquityPeriod = response.is_propinquity_period;
    child.propinquityFrom = DateRange.fromResponse(response.propinquity_from);
    child.secondParentLivedAbroad = response.second_parent_lived_abroad;
    child.secondParentLivedAbroadFrom = DateRange.fromResponse(response.second_parent_lived_abroad_from);
    child.residence = response.residence && response.residence.length ? response.residence.map(Residence.fromResponse) : [];

    // Submodule 3:
    child.isStudyingOrCompleted = response.is_studying_or_completed;
    child.completedFirstDegree = response.completed_first_degree;
    child.isStudying = response.is_studying;
    child.schoolNames = response.school_names;
    child.educationDates = response.education_dates.map(toDateRange);
    child.lackOfTrainingSpace = response.lack_of_training_space;
    child.lackOfTrainingSpaceDates = response.lack_of_training_space_dates.map(toDateRange);
    child.hasVoluntaryYear = response.has_voluntary_year;
    child.voluntaryYearDates = response.voluntary_year_dates.map(toDateRange);
    child.beenInTransitionalPeriod = response.been_in_transitional_period;
    child.transitionalPeriodDates = response.transitional_period_dates.map(toDateRange);
    child.isUnemployed = response.is_unemployed;
    child.unemploymentDates = response.unemployment_dates.map(toDateRange);
    child.disabilityBefore25 = response.disability_before_25;
    child.disabilityBefore25Dates = response.disability_before_25_dates.map(toDateRange);
    child.haveJob = response.have_job;
    child.haveMinijobs = response.have_minijobs;
    child.minijobDates = response.minijob_dates.map(toDateRange);
    child.minijobNumberOfHours = response.minijob_number_of_hours;
    child.wasEmployed = response.was_employed;
    child.employmentDates = response.employment_dates.map(toDateRange);
    child.employmentNumberOfHours = response.employment_number_of_hours;

    // Submodule 4:
    child.paidSchoolAtendance = response.paid_school_names && response.paid_school_names.length > 0;
    child.paidSchoolFees = response.paid_school_fees;
    child.paidSchoolNames = response.paid_school_names;
    child.applicantParticipationInSchoolCosts = response.applicant_participation_in_school_costs;

    // Submodule 5:
    child.childcareUsage = (response.childcare_types && response.childcare_types.length > 0) || response.parents_didnt_live_with_child;
    child.childcareTypes = response.childcare_types;
    child.childcareNameAndAddress = response.childcare_name_and_address;
    child.childcareDates = response.childcare_dates.map(toDateRange);
    child.childcareCosts = response.childcare_costs;
    child.applicantParticipationInChildcareCosts = response.applicant_participation_in_childcare_costs;
    child.childcareAmountOfFunding = response.childcare_amount_of_funding;
    child.parentsDidntLiveWithChild = response.parents_didnt_live_with_child;
    child.parentsDidntLiveWithChildDates = response.parents_didnt_live_with_child_dates.map(toDateRange);
    child.childDidntLiveWithApplicantWholeYear = response.child_didnt_live_with_applicant_whole_year;
    child.childLivedWithApplicantDates = response.child_lived_with_applicant_dates.map(toDateRange);
    child.childLivedWithSecondParentDates = response.child_lived_with_second_parent_dates.map(toDateRange);

    // Submodule 6:
    child.taxFreeAllowanceRequestEnabled =
      response.tax_free_allowance_request_second_parent || response.tax_free_allowance_request_residence;
    child.taxFreeAllowanceRequestSecondParent = response.tax_free_allowance_request_second_parent;
    child.paymentsForChildcareDates = response.payments_for_childcare_dates.map(toDateRange);
    child.taxFreeAllowanceRequestResidence = response.tax_free_allowance_request_residence;
    child.notRegisteredDates = response.not_registered_dates.map(toDateRange);

    // Submodule 7:
    child.raisingChildLonely = response.raising_child_lonely;
    child.registeredInApplicantsFlatDates = response.registered_in_applicants_flat_dates.map(toDateRange);
    child.kindergeldDates = response.kindergeld_dates.map(toDateRange);
    child.otherAdultsRegistered = response.other_adults_registered;
    child.otherAdultsRegisteredDates = response.other_adults_registered_dates.map(toDateRange);
    child.commonHousehold = response.common_household;
    child.commonHouseholdDates = response.common_household_dates.map(toDateRange);
    child.commonHouseholdPersonName = response.common_household_person_name;
    child.commonHouseholdPersonPropinquity = response.common_household_person_propinquity;
    child.commonHouseholdPersonProfession = response.common_household_person_profession;

    // Submodule 8:
    child.studiedOutsidePlaceOfResidence = response.studied_outside_place_of_residence;
    child.studiedOutsidePlaceOfResidenceDates = response.studied_outside_place_of_residence_dates.map(toDateRange);
    child.placeOfResidence = response.place_of_residence;
    child.studiesAllowanceIsNotFiftyFifty = response.studies_allowance_is_not_fifty_fifty;
    child.participationInStudiesAllowance = response.participation_in_studies_allowance;

    // Submodule 9:
    child.hasDisabilityEnabled =
      response.has_disability || response.is_blind || response.is_impaired || response.is_bereaved;
    child.isBereaved = response.is_bereaved;
    child.hasDisability = response.has_disability;
    child.isBlind = response.is_blind;
    child.isImpaired = response.is_impaired;
    child.disabilityLevel = response.disability_level;
    child.invalidityCardDate = response.invalidity_card_date;
    child.invalidityCardValidFrom = response.invalidity_card_valid_from;
    child.invalidityCardValidTo = response.invalidity_card_valid_to;
    child.invalidityCardValidForever = response.invalidity_card_valid_forever;
    child.disabilityAllowanceIsNotFiftyFifty = response.disability_allowance_is_not_fifty_fifty;
    child.participationInDisabilityAllowance = response.participation_in_disability_allowance;

    child.updateChildDeductionsFlags();

    return child;
  }

  static fromStorage(response: Child): Child {
    const toDateRange = (value: DateRangeResponse) => DateRange.fromResponse(value);

    const child = new Child();
    child.id = response.id;
    child.firstName = response.firstName;
    child.lastName = response.lastName;
    child.birthDate = response.birthDate;
    child.hasDifferentAddress = response.hasDifferentAddress;
    child.differentAddress = response.differentAddress;
    child.identifikationsnummer = response.identifikationsnummer;
    child.savedKindergeld = response.savedKindergeld;
    child.kindergeld = response.kindergeld;
    child.kindergeldExtra = response.kindergeldExtra;
    child.kindergeldDefault = RecognizerHelper.isNullOrUndefined(child.kindergeldExtra) || child.kindergeldExtra === '';
    child.familienkasse = response.familienkasse;
    child.propinquity = response.propinquity;
    child.propinquityToSpouse = response.propinquityToSpouse;
    child.lastKnownAddress = response.lastKnownAddress;
    child.dontKnowAddress = response.dontKnowAddress;
    child.propinquityToSecondParent = response.propinquityToSecondParent;
    child.secondParentDeathDate = response.secondParentDeathDate;
    child.secondParentDied = response.secondParentDied;
    child.secondParent = response.secondParent;
    child.secondParentFirstName = response.secondParentFirstName;
    child.secondParentLastName = response.secondParentLastName;
    child.secondParentBirthDate = response.secondParentBirthDate;
    child.livedWholeYear = response.livedWholeYear;
    child.isPropinquityPeriod = response.isPropinquityPeriod;
    child.propinquityFrom = DateRange.fromResponse(response.propinquityFrom);
    child.secondParentLivedAbroad = response.secondParentLivedAbroad;
    child.secondParentLivedAbroadFrom = DateRange.fromResponse(response.secondParentLivedAbroadFrom);
    child.residence = response.residence && response.residence.length ? response.residence.map(Residence.fromStorage) : [];

    // Submodule 3:
    child.isStudyingOrCompleted = response.isStudyingOrCompleted;
    child.completedFirstDegree = response.completedFirstDegree;
    child.isStudying = response.isStudying;
    child.schoolNames = [...response.schoolNames];
    child.educationDates = response.educationDates.map(toDateRange);
    child.lackOfTrainingSpace = response.lackOfTrainingSpace;
    child.lackOfTrainingSpaceDates = response.lackOfTrainingSpaceDates.map(toDateRange);
    child.hasVoluntaryYear = response.hasVoluntaryYear;
    child.voluntaryYearDates = response.voluntaryYearDates.map(toDateRange);
    child.beenInTransitionalPeriod = response.beenInTransitionalPeriod;
    child.transitionalPeriodDates = response.transitionalPeriodDates.map(toDateRange);
    child.isUnemployed = response.isUnemployed;
    child.unemploymentDates = response.unemploymentDates.map(toDateRange);
    child.disabilityBefore25 = response.disabilityBefore25;
    child.disabilityBefore25Dates = response.disabilityBefore25Dates.map(toDateRange);
    child.haveJob = response.haveJob;
    child.haveMinijobs = response.haveMinijobs;
    child.minijobDates = response.minijobDates.map(toDateRange);
    child.minijobNumberOfHours = [...response.minijobNumberOfHours];
    child.wasEmployed = response.wasEmployed;
    child.employmentDates = response.employmentDates.map(toDateRange);
    child.employmentNumberOfHours = [...response.employmentNumberOfHours];

    // TODO: maybe unnecessary
    child.selectedInfos = [...response.selectedInfos];

    // Submodule 4:
    child.paidSchoolAtendance = response.paidSchoolAtendance;
    child.paidSchoolFees = [...response.paidSchoolFees];
    child.paidSchoolNames = [...response.paidSchoolNames];
    child.applicantParticipationInSchoolCosts = response.applicantParticipationInSchoolCosts;

    // Submodule 5:
    child.childcareUsage = response.childcareUsage;
    child.childcareTypes = [...response.childcareTypes];
    child.childcareNameAndAddress = [...response.childcareNameAndAddress];
    child.childcareDates = response.childcareDates.map(toDateRange);
    child.childcareCosts = [...response.childcareCosts];
    child.applicantParticipationInChildcareCosts = [...response.applicantParticipationInChildcareCosts];
    child.childcareAmountOfFunding = [...response.childcareAmountOfFunding];
    child.parentsDidntLiveWithChild = response.parentsDidntLiveWithChild;
    child.parentsDidntLiveWithChildDates = response.parentsDidntLiveWithChildDates.map(toDateRange);
    child.childDidntLiveWithApplicantWholeYear = response.childDidntLiveWithApplicantWholeYear;
    child.childLivedWithApplicantDates = response.childLivedWithApplicantDates.map(toDateRange);
    child.childLivedWithSecondParentDates = response.childLivedWithSecondParentDates.map(toDateRange);

    // Submodule 6:
    child.taxFreeAllowanceRequestEnabled = response.taxFreeAllowanceRequestEnabled;
    child.taxFreeAllowanceRequestSecondParent = response.taxFreeAllowanceRequestSecondParent;
    child.paymentsForChildcareDates = response.paymentsForChildcareDates.map(toDateRange);
    child.taxFreeAllowanceRequestResidence = response.taxFreeAllowanceRequestResidence;
    child.notRegisteredDates = response.notRegisteredDates.map(toDateRange);

    // Submodule 7:
    child.raisingChildLonely = response.raisingChildLonely;
    child.registeredInApplicantsFlatDates = response.registeredInApplicantsFlatDates.map(toDateRange);
    child.kindergeldDates = response.kindergeldDates.map(toDateRange);
    child.otherAdultsRegistered = response.otherAdultsRegistered;
    child.otherAdultsRegisteredDates = response.otherAdultsRegisteredDates.map(toDateRange);
    child.commonHousehold = response.commonHousehold;
    child.commonHouseholdDates = response.commonHouseholdDates.map(toDateRange);
    child.commonHouseholdPersonName = [...response.commonHouseholdPersonName];
    child.commonHouseholdPersonPropinquity = [...response.commonHouseholdPersonPropinquity];
    child.commonHouseholdPersonProfession = [...response.commonHouseholdPersonProfession];

    // Submodule 8:
    child.studiedOutsidePlaceOfResidence = response.studiedOutsidePlaceOfResidence;
    child.studiedOutsidePlaceOfResidenceDates = response.studiedOutsidePlaceOfResidenceDates.map(toDateRange);
    child.placeOfResidence = [...response.placeOfResidence];
    child.studiesAllowanceIsNotFiftyFifty = response.studiesAllowanceIsNotFiftyFifty;
    child.participationInStudiesAllowance = response.participationInStudiesAllowance;

    // Submodule 9:
    child.hasDisabilityEnabled = response.hasDisabilityEnabled;
    child.isBereaved = response.isBereaved;
    child.hasDisability = response.hasDisability;
    child.isBlind = response.isBlind;
    child.isImpaired = response.isImpaired;
    child.disabilityLevel = response.disabilityLevel;
    child.invalidityCardDate = [...response.invalidityCardDate];
    child.invalidityCardValidFrom = [...response.invalidityCardValidFrom];
    child.invalidityCardValidTo = [...response.invalidityCardValidTo];
    child.invalidityCardValidForever = [...response.invalidityCardValidForever];
    child.disabilityAllowanceIsNotFiftyFifty = response.disabilityAllowanceIsNotFiftyFifty;
    child.participationInDisabilityAllowance = response.participationInDisabilityAllowance;

    return child;
  }

  public clone(): Child {
    return Child.fromStorage({...this});
  }

  public payloadBirtDate(): {birth_date: string} {
    return {
      birth_date: this.birthDate ? toResponseDate(this.birthDate) : '',
    };
  }

  toResponse(defaultValue = undefined, deductionGroup: string = null): ChildResponse {
    const toFixed = (value: string) => parseFloat(value).toFixed(2);
    const toDateRangeResponse = (value: DateRange) => value.toResponse(defaultValue);
    const clearRedundantDates = (booleanList: boolean[]) => {
      return (value: DateRange, index: number) => {
        if (!booleanList[index]) {
          return toDateRangeResponse(new DateRange());
        }
        return toDateRangeResponse(value);
      };
    };

    const clearHouseholdPersons = (sectionData: string[], index: number) => {
      if (!this.otherAdultsRegistered[index] && !this.commonHousehold[index]) {
        return this._commonHouseholdPersonDefault.slice();
      }
      if (!sectionData || sectionData.length !== this._commonHouseholdPersonDefault.length) {
        return this._commonHouseholdPersonDefault.slice();
      }
      return sectionData;
    };
    const dateDefaultValue = defaultValue === '' ? null : defaultValue;

    let paidSchoolGroup = {};
    if (!deductionGroup || deductionGroup === DeductionChildSteps.PAID_SCHOOL) {
      paidSchoolGroup = {
        paid_school_fees: this.paidSchoolFees && this.paidSchoolAtendance ? this.paidSchoolFees.map(toFixed) : [],
        paid_school_names: this.paidSchoolNames && this.paidSchoolAtendance ? this.paidSchoolNames : [],
        applicant_participation_in_school_costs:
          parseFloat(this.paidSchoolAtendance && this.paidSchoolFees.length > 0
            ? this.applicantParticipationInSchoolCosts || '0'
            : '0'
          ).toFixed(2),
      };
    }
    let childCareGroup = {};
    if (!deductionGroup || deductionGroup === DeductionChildSteps.CHILD_CARE) {
      childCareGroup = {
        childcare_types:
          this.childcareTypes && this.childcareUsage
            ? this.childcareTypes
            : [],
        childcare_name_and_address:
          this.childcareNameAndAddress && this.childcareUsage
            ? this.childcareNameAndAddress
            : [],
        childcare_dates:
          this.childcareDates && this.childcareUsage
            ? this.childcareDates.map(toDateRangeResponse)
            : [],
        childcare_costs:
          this.childcareCosts && this.childcareUsage
            ? this.childcareCosts.map(toFixed)
            : [],
        applicant_participation_in_childcare_costs:
          this.applicantParticipationInChildcareCosts && this.childcareUsage
            ? this.applicantParticipationInChildcareCosts.map(toFixed)
            : [],
        childcare_amount_of_funding:
          this.childcareAmountOfFunding && this.childcareUsage
            ? this.childcareAmountOfFunding.map(toFixed)
            : [],
        parents_didnt_live_with_child: this.parentsDidntLiveWithChild && this.childcareUsage,
        parents_didnt_live_with_child_dates:
          this.parentsDidntLiveWithChild && this.parentsDidntLiveWithChildDates
            ? this.parentsDidntLiveWithChildDates.map(toDateRangeResponse)
            : [],
        child_didnt_live_with_applicant_whole_year: this.childDidntLiveWithApplicantWholeYear && this.childcareUsage,
        child_lived_with_applicant_dates:
          this.childDidntLiveWithApplicantWholeYear && this.parentsDidntLiveWithChild
          && this.childLivedWithApplicantDates
            ? this.childLivedWithApplicantDates.map(toDateRangeResponse)
            : [],
        child_lived_with_second_parent_dates:
          this.childDidntLiveWithApplicantWholeYear && this.parentsDidntLiveWithChild
          && this.childLivedWithSecondParentDates && this.childcareUsage
            ? this.childLivedWithSecondParentDates.map(toDateRangeResponse)
            : [],
      };
    }

    let transferRelieve = {};
    if (!deductionGroup || deductionGroup === DeductionChildSteps.TRANSFER_RELIEVE) {
      transferRelieve = {
        tax_free_allowance_request_second_parent: this.taxFreeAllowanceRequestSecondParent && this.taxFreeAllowanceRequestEnabled,
        payments_for_childcare_dates:
          this.taxFreeAllowanceRequestSecondParent && this.paymentsForChildcareDates
            ? this.paymentsForChildcareDates.map(toDateRangeResponse)
            : [],
        tax_free_allowance_request_residence: this.taxFreeAllowanceRequestResidence && this.taxFreeAllowanceRequestEnabled,
        not_registered_dates:
          this.taxFreeAllowanceRequestResidence && this.notRegisteredDates
            ? this.notRegisteredDates.map(toDateRangeResponse)
            : [],
      };
    }
    let transferLonely = {};
    if (!deductionGroup || deductionGroup === DeductionChildSteps.TRANSFER_LONELY) {
      transferLonely = {
        raising_child_lonely: this.raisingChildLonely,
        registered_in_applicants_flat_dates:
          this.raisingChildLonely && this.registeredInApplicantsFlatDates
            ? this.registeredInApplicantsFlatDates.map(toDateRangeResponse)
            : [],
        kindergeld_dates:
          this.raisingChildLonely && this.kindergeldDates
            ? this.kindergeldDates.map(toDateRangeResponse)
            : [],
        other_adults_registered:
          this.raisingChildLonely && this.otherAdultsRegistered
            ? this.otherAdultsRegistered
            : [],
        other_adults_registered_dates:
          this.raisingChildLonely && this.otherAdultsRegisteredDates
            ? this.otherAdultsRegisteredDates.map(clearRedundantDates(this.otherAdultsRegistered))
            : [],
        common_household:
          this.raisingChildLonely && this.commonHousehold
            ? this.commonHousehold
            : [],
        common_household_dates:
          this.raisingChildLonely && this.commonHouseholdDates
            ? this.commonHouseholdDates.map(clearRedundantDates(this.commonHousehold))
            : [],
        common_household_person_name:
          this.raisingChildLonely && this.commonHouseholdPersonName
            ? this.commonHouseholdPersonName.map(clearHouseholdPersons)
            : [],
        common_household_person_propinquity:
          this.raisingChildLonely && this.commonHouseholdPersonPropinquity
            ? this.commonHouseholdPersonPropinquity.map(clearHouseholdPersons)
            : [],
        common_household_person_profession:
          this.raisingChildLonely && this.commonHouseholdPersonProfession
            ? this.commonHouseholdPersonProfession.map(clearHouseholdPersons)
            : [],
      };
    }
    let studyingOutside = {};
    if (!deductionGroup || deductionGroup === DeductionChildSteps.STUDYING_OUTSIDE) {
      studyingOutside = {
        studied_outside_place_of_residence: this.studiedOutsidePlaceOfResidence,
        studied_outside_place_of_residence_dates:
          this.studiedOutsidePlaceOfResidence && this.studiedOutsidePlaceOfResidenceDates
            ? this.studiedOutsidePlaceOfResidenceDates.map(toDateRangeResponse)
            : [],
        place_of_residence:
          this.studiedOutsidePlaceOfResidence && this.placeOfResidence
            ? this.placeOfResidence
            : [],
        studies_allowance_is_not_fifty_fifty: this.studiesAllowanceIsNotFiftyFifty,
        participation_in_studies_allowance:
          this.studiedOutsidePlaceOfResidence && this.studiesAllowanceIsNotFiftyFifty
            ? toFixed(this.participationInStudiesAllowance)
            : '0.00',
      };
    }
    let disability = {};
    if (!deductionGroup || deductionGroup === DeductionChildSteps.DISABILITY) {
      disability = {
        has_disability_or_is_bereaved: this.hasDisabilityEnabled,
        is_bereaved: this.isBereaved,
        has_disability: this.hasDisabilityEnabled && this.hasDisability,
        is_blind: this.hasDisabilityEnabled && this.isBlind,
        is_impaired: this.hasDisabilityEnabled && this.isImpaired,
        disability_level:
          this.hasDisability
            ? toFixed(this.disabilityLevel)
            : '0.00',
        invalidity_card_date:
          this.hasDisabilityEnabled && this.invalidityCardDate
            ? this.invalidityCardDate.map((date: string) => date ? toResponseDate(date) : null)
            : [],
        invalidity_card_valid_from:
          this.hasDisabilityEnabled && this.invalidityCardValidFrom
            ? this.invalidityCardValidFrom.map((date: string) => date ? toResponseDate(date) : null) : [],
        invalidity_card_valid_to:
          this.hasDisabilityEnabled && this.invalidityCardValidTo
            ? this.invalidityCardValidTo.map((date: string) => date ? toResponseDate(date) : null) : [],
        invalidity_card_valid_forever:
          this.hasDisabilityEnabled && this.invalidityCardValidForever
            ? this.invalidityCardValidForever
            : [],
        disability_allowance_is_not_fifty_fifty: this.disabilityAllowanceIsNotFiftyFifty,
        participation_in_disability_allowance:
          this.hasDisabilityEnabled && this.disabilityAllowanceIsNotFiftyFifty
            ? toFixed(this.participationInDisabilityAllowance)
            : '0.00'
      };
    }

    return {
      id: this.id ? this.id : undefined,
      first_name: this.firstName ? this.firstName : defaultValue,
      last_name: this.lastName ? this.lastName : defaultValue,
      birth_date: this.birthDate ? toResponseDate(this.birthDate) : dateDefaultValue,
      has_different_address: this.hasDifferentAddress,
      different_address: this.hasDifferentAddress && this.differentAddress ? this.differentAddress : defaultValue,
      identifikationsnummer: this.identifikationsnummer ? this.identifikationsnummer : defaultValue,
      saved_kindergeld: this.savedKindergeld ? this.savedKindergeld : false,
      kindergeld: this.kindergeld ? this.kindergeld : defaultValue,
      kindergeld_extra: !RecognizerHelper.isNullOrUndefined(this.kindergeldExtra) ? this.kindergeldExtra : null,
      familienkasse: this.familienkasse ? this.familienkasse : defaultValue,
      propinquity: this.propinquity ? this.propinquity : defaultValue,
      second_parent: this.secondParent,
      second_parent_first_name: this.secondParentFirstName ? this.secondParentFirstName : defaultValue,
      second_parent_last_name: this.secondParentLastName ? this.secondParentLastName : defaultValue,
      second_parent_birth_date: this.secondParentBirthDate ? toResponseDate(this.secondParentBirthDate) : dateDefaultValue,
      propinquity_to_spouse: this.propinquityToSpouse ? this.propinquityToSpouse : defaultValue,
      dont_know_address: this.dontKnowAddress,
      last_known_address: this.lastKnownAddress && !this.dontKnowAddress ? this.lastKnownAddress : defaultValue,
      propinquity_to_second_parent: this.propinquityToSecondParent,
      second_parent_died: this.secondParentDied,
      second_parent_death_date: this.secondParentDeathDate ? toResponseDate(this.secondParentDeathDate) : dateDefaultValue,
      lived_whole_year: this.livedWholeYear ? this.livedWholeYear : false,
      is_propinquity_period: this.isPropinquityPeriod,
      propinquity_from:
        this.isPropinquityPeriod
          ? this.propinquityFrom.toResponse(defaultValue)
          : null,
      second_parent_lived_abroad: this.secondParentLivedAbroad,
      second_parent_lived_abroad_from:
        this.secondParentLivedAbroad
          ? this.secondParentLivedAbroadFrom.toResponse(defaultValue)
          : null,
      residence: this.residence && this.residence.length && !this.livedWholeYear ? this.residence.map(r => r.toResponse(defaultValue)) : [],

      // Submodule 3:
      is_studying_or_completed: this.isStudyingOrCompleted,
      completed_first_degree: this.completedFirstDegree,
      is_studying: this.isStudying,
      school_names:
        this.isStudying && this.isStudyingOrCompleted && this.schoolNames
          ? this.schoolNames
          : [],
      education_dates:
        this.isStudying && this.isStudyingOrCompleted && this.educationDates
          ? this.educationDates.map(toDateRangeResponse)
          : [],
      lack_of_training_space: this.lackOfTrainingSpace,
      lack_of_training_space_dates:
        this.lackOfTrainingSpace && this.lackOfTrainingSpaceDates
          ? this.lackOfTrainingSpaceDates.map(toDateRangeResponse)
          : [],
      has_voluntary_year: this.hasVoluntaryYear,
      voluntary_year_dates:
        this.hasVoluntaryYear && this.voluntaryYearDates
          ? this.voluntaryYearDates.map(toDateRangeResponse)
          : [],
      been_in_transitional_period: this.beenInTransitionalPeriod,
      transitional_period_dates:
        this.beenInTransitionalPeriod && this.transitionalPeriodDates
          ? this.transitionalPeriodDates.map(toDateRangeResponse)
          : [],
      is_unemployed: this.isUnemployed,
      unemployment_dates:
        this.isUnemployed && this.unemploymentDates
          ? this.unemploymentDates.map(toDateRangeResponse)
          : [],
      disability_before_25: this.disabilityBefore25,
      disability_before_25_dates:
        this.disabilityBefore25 && this.disabilityBefore25Dates
          ? this.disabilityBefore25Dates.map(toDateRangeResponse)
          : [],
      have_job: this.haveJob,
      have_minijobs: this.haveMinijobs,
      minijob_dates:
        this.isStudyingOrCompleted && this.completedFirstDegree && this.haveJob && this.haveMinijobs && this.minijobDates
          ? this.minijobDates.map(toDateRangeResponse)
          : [],
      minijob_number_of_hours:
        this.isStudyingOrCompleted && this.completedFirstDegree && this.haveJob && this.haveMinijobs && this.minijobNumberOfHours
          ? this.minijobNumberOfHours.map(toFixed)
          : [],
      was_employed: this.wasEmployed,
      employment_dates:
        this.isStudyingOrCompleted && this.completedFirstDegree && this.haveJob && this.wasEmployed && this.employmentDates
          ? this.employmentDates.map(toDateRangeResponse)
          : [],
      employment_number_of_hours:
        this.isStudyingOrCompleted && this.completedFirstDegree && this.haveJob && this.wasEmployed && this.employmentNumberOfHours
          ? this.employmentNumberOfHours.map(toFixed)
          : [],

      // Submodule 4:
      ...paidSchoolGroup,

      // Submodule 5:
      ...childCareGroup,

      // Submodule 6:
      ...transferRelieve,

      // Submodule 7:
      ...transferLonely,

      // Submodule 8:
      ...studyingOutside,

      // Submodule 9:
      ...disability,
    };
  }

  public addResidence(): void {
    const residence = new Residence();
    residence.address = '';
    residence.country = '';
    residence.residencePeriod.lower = '';
    residence.residencePeriod.upper = '';
    this.residence.push(residence);
  }

  public removeResidence(index: number): void {
    this.residence.splice(index, 1);
  }

  public removeAllResidence(): void {
    this.residence.length = 0;
  }

  public addSchool(): void {
    this.schoolNames.push('');
    this.educationDates.push(new DateRange());
  }

  public removeSchool(index: number): void {
    this.schoolNames.splice(index, 1);
    this.educationDates.splice(index, 1);
  }

  public removeAllSchools(): void {
    this.schoolNames.length = 0;
    this.educationDates.length = 0;
  }

  public addLackOfTrainingSpaceDates(): void {
    this.lackOfTrainingSpaceDates.push(new DateRange());
  }

  public removeLackOfTrainingSpaceDates(index: number): void {
    this.lackOfTrainingSpaceDates.splice(index, 1);
  }

  public removeAllLackOfTrainingSpaceDates(): void {
    this.lackOfTrainingSpaceDates.length = 0;
  }

  public addVoluntaryYearDates(): void {
    this.voluntaryYearDates.push(new DateRange());
  }

  public removeVoluntaryYearDates(index: number): void {
    this.voluntaryYearDates.splice(index, 1);
  }

  public removeAllVoluntaryYearDates(): void {
    this.voluntaryYearDates.length = 0;
  }

  public addTransitionalPeriodDates(): void {
    this.transitionalPeriodDates.push(new DateRange());
  }

  public removeTransitionalPeriodDates(index: number): void {
    this.transitionalPeriodDates.splice(index, 1);
  }

  public removeAllTransitionalPeriodDates(): void {
    this.transitionalPeriodDates.length = 0;
  }

  public addUnemployment(): void {
    this.unemploymentDates.push(new DateRange());
  }

  public removeUnemploymentDates(index: number): void {
    this.unemploymentDates.splice(index, 1);
  }

  public removeAllUnemploymentDates(): void {
    this.unemploymentDates.length = 0;
  }

  public addDisabilityBefore25(): void {
    this.disabilityBefore25Dates.push(new DateRange());
  }

  public removeDisabilityBefore25(index: number): void {
    this.disabilityBefore25Dates.splice(index, 1);
  }

  public removeAllDisabilityBefore25Dates(): void {
    this.disabilityBefore25Dates.length = 0;
  }

  public addMiniJob(): void {
    this.minijobDates.push(new DateRange());
    this.minijobNumberOfHours.push('');
  }

  public removeMiniJob(index: number): void {
    this.minijobDates.splice(index, 1);
    this.minijobNumberOfHours.splice(index, 1);
  }

  public removeAllMiniJobDates(): void {
    this.minijobDates.length = 0;
    this.minijobNumberOfHours.length = 0;
  }

  public addEmployment(): void {
    this.employmentDates.push(new DateRange());
    this.employmentNumberOfHours.push('');
  }

  public removeEmployment(index: number): void {
    this.employmentDates.splice(index, 1);
    this.employmentNumberOfHours.splice(index, 1);
  }

  public removeAllEmployment(): void {
    this.employmentDates.length = 0;
    this.employmentNumberOfHours.length = 0;
  }

  public addPaidSchool(): void {
    this.paidSchoolNames.push('');
    this.paidSchoolFees.push('0.00');
  }

  public removePaidSchool(index: number): void {
    this.paidSchoolNames.splice(index, 1);
    this.paidSchoolFees.splice(index, 1);
  }

  public removeAllPaidSchool(): void {
    this.paidSchoolNames.length = 0;
    this.paidSchoolFees.length = 0;
  }

  public addChildcareService(): void {
    this.childcareTypes.push('');
    this.childcareNameAndAddress.push('');
    this.childcareDates.push(new DateRange());
    this.childcareCosts.push('0.00');
    this.applicantParticipationInChildcareCosts.push('0.00');
    this.childcareAmountOfFunding.push('0.00');
  }

  public removeChildcareService(index: number): void {
    this.childcareTypes.splice(index, 1);
    this.childcareNameAndAddress.splice(index, 1);
    this.childcareDates.splice(index, 1);
    this.childcareCosts.splice(index, 1);
    this.applicantParticipationInChildcareCosts.splice(index, 1);
    this.childcareAmountOfFunding.splice(index, 1);
  }

  public removeAllChildcareService(): void {
    this.childcareTypes.length = 0;
    this.childcareNameAndAddress.length = 0;
    this.childcareDates.length = 0;
    this.childcareCosts.length = 0;
    this.applicantParticipationInChildcareCosts.length = 0;
    this.childcareAmountOfFunding.length = 0;
    this.parentsDidntLiveWithChildDates.length = 0;
    this.childLivedWithApplicantDates.length = 0;
    this.childLivedWithSecondParentDates.length = 0;
    this.childcareUsage = false;
    this.parentsDidntLiveWithChild = false;
    this.childDidntLiveWithApplicantWholeYear = false;
  }

  public addLonelyRaisingChildrenSection(): void {
    this.registeredInApplicantsFlatDates.push(new DateRange());
    this.kindergeldDates.push(new DateRange());
    this.otherAdultsRegistered.push(false);
    this.otherAdultsRegisteredDates.push(new DateRange());
    this.commonHousehold.push(false);
    this.commonHouseholdDates.push(new DateRange());
    this.commonHouseholdPersonName.push([]);
    this.commonHouseholdPersonPropinquity.push([]);
    this.commonHouseholdPersonProfession.push([]);
  }

  public removeLonelyRaisingChildrenSection(index: number): void {
    this.registeredInApplicantsFlatDates.splice(index, 1);
    this.kindergeldDates.splice(index, 1);
    this.otherAdultsRegistered.splice(index, 1);
    this.otherAdultsRegisteredDates.splice(index, 1);
    this.commonHousehold.splice(index, 1);
    this.commonHouseholdDates.splice(index, 1);
    this.commonHouseholdPersonName.splice(index, 1);
    this.commonHouseholdPersonPropinquity.splice(index, 1);
    this.commonHouseholdPersonProfession.splice(index, 1);
  }

  public removeAllLonelyRaisingChildren(): void {
    this.raisingChildLonely = false;
    this.registeredInApplicantsFlatDates.length = 0;
    this.kindergeldDates.length = 0;
    this.otherAdultsRegistered.length = 0;
    this.otherAdultsRegisteredDates.length = 0;
    this.commonHousehold.length = 0;
    this.commonHouseholdDates.length = 0;
    this.commonHouseholdPersonName.length = 0;
    this.commonHouseholdPersonPropinquity.length = 0;
    this.commonHouseholdPersonProfession.length = 0;
  }

  public addCommonHouseholdAdultPerson(index: number): void {
    if (!this.commonHouseholdPersonName[index]) {
      this.commonHouseholdPersonName[index] = [];
    }
    if (!this.commonHouseholdPersonPropinquity[index]) {
      this.commonHouseholdPersonPropinquity[index] = [];
    }
    if (!this.commonHouseholdPersonProfession[index]) {
      this.commonHouseholdPersonProfession[index] = [];
    }
    this.commonHouseholdPersonName[index].push('');
    this.commonHouseholdPersonPropinquity[index].push('');
    this.commonHouseholdPersonProfession[index].push('');
  }

  public removeCommonHouseholdAdultPerson(index: number, personIndex: number): void {
    if (this.commonHouseholdPersonName[index]) {
      this.commonHouseholdPersonName[index].splice(personIndex, 1);
    }
    if (this.commonHouseholdPersonPropinquity[index]) {
      this.commonHouseholdPersonPropinquity[index].splice(personIndex, 1);
    }
    if (this.commonHouseholdPersonProfession[index]) {
      this.commonHouseholdPersonProfession[index].splice(personIndex, 1);
    }
  }

  public removeAllCommonHouseholdAdultPerson(index: number): void {
    if (this.commonHouseholdPersonName[index]) {
      this.commonHouseholdPersonName[index].length = 0;
    }
    if (this.commonHouseholdPersonPropinquity[index]) {
      this.commonHouseholdPersonPropinquity[index].length = 0;
    }
    if (this.commonHouseholdPersonProfession[index]) {
      this.commonHouseholdPersonProfession[index].length = 0;
    }
  }

  public addSchoolOutsideHome(): void {
    this.studiedOutsidePlaceOfResidenceDates.push(new DateRange());
    this.placeOfResidence.push('');
  }

  public removeSchoolOutsideHome(index: number): void {
    this.studiedOutsidePlaceOfResidenceDates.splice(index, 1);
    this.placeOfResidence.splice(index, 1);
  }

  public removeAllSchoolOutsideHome(): void {
    this.studiedOutsidePlaceOfResidence = false;
    this.studiedOutsidePlaceOfResidenceDates.length = 0;
    this.placeOfResidence.length = 0;
  }

  public addInvalidityCard(): void {
    this.invalidityCardDate.push('');
    this.invalidityCardValidFrom.push('');
    this.invalidityCardValidTo.push('');
    this.invalidityCardValidForever.push(false);
  }

  public removeInvalidityCard(index: number): void {
    this.invalidityCardDate.splice(index, 1);
    this.invalidityCardValidFrom.splice(index, 1);
    this.invalidityCardValidTo.splice(index, 1);
    this.invalidityCardValidForever.splice(index, 1);
  }

  public isAdult(statementYear: number): boolean {
    const birthDate = DateHelper.getInstance(this.birthDate, ['YYYY-MM-DD', 'DD.MM.YYYY']);
    return birthDate.isValid()
      && DateHelper.isSameOrBefore(
        birthDate,
        DateHelper.getInstance(`${statementYear - ADULT_AGE}-01-01`, 'YYYY-MM-DD')
      );
  }

  public get isAdultSectionFilled(): boolean {
    return !!(
      (this.isStudyingOrCompleted && this.isStudying && this.schoolNames.length)
      || (this.hasVoluntaryYear && this.voluntaryYearDates.length)
      || (this.beenInTransitionalPeriod && this.transitionalPeriodDates.length)
      || (this.isUnemployed && this.unemploymentDates.length)
      || (this.lackOfTrainingSpace && this.lackOfTrainingSpaceDates.length)
      || (this.disabilityBefore25 && this.disabilityBefore25Dates.length)
      || (this.haveJob && this.haveMinijobs && this.minijobDates.length)
      || (this.haveJob && this.wasEmployed && this.employmentDates.length)
    );
  }

  public get isFilledBasicInfo(): boolean {
    return !!(this.firstName && this.lastName && this.birthDate
      && (
        !this.secondParent
        || (
          this.secondParentFirstName
          || this.secondParentLastName
          || this.secondParentBirthDate
          || this.propinquityToSecondParent
        )
      )
    );
  }

  public updateChildDeductionsFlags(): void {
    this.studiedOutsidePlaceOfResidence = RecognizerHelper.isNotEmpty(this.studiedOutsidePlaceOfResidenceDates)
      && RecognizerHelper.isNotEmpty(this.placeOfResidence);
    this.taxFreeAllowanceRequestSecondParent = RecognizerHelper.isNotEmpty(this.paymentsForChildcareDates);
    this.taxFreeAllowanceRequestResidence = RecognizerHelper.isNotEmpty(this.notRegisteredDates);
    this.raisingChildLonely = RecognizerHelper.isNotEmpty(this.registeredInApplicantsFlatDates)
      && RecognizerHelper.isNotEmpty(this.kindergeldDates);

    this.commonHouseholdPersonName.forEach((el, index) => {
      if (
        !this.commonHouseholdPersonFilled(index)
      ) {
        this.removeAllCommonHouseholdAdultPerson(index);
        this.commonHousehold[index] = false;
        this.commonHouseholdDates[index] = undefined;
        this.otherAdultsRegistered[index] = false;
        this.otherAdultsRegisteredDates[index] = undefined;
      }
    });

    this.hasDisabilityEnabled = !!Number(this.disabilityLevel)
      || !!Number(this.participationInDisabilityAllowance) || this.isBereaved;

    if (!this.hasDisabilityEnabled) {
      this.clearDisabilityGroup();
    }

    if (
      !this.taxFreeAllowanceRequestResidence
      && !this.taxFreeAllowanceRequestSecondParent
    ) {
      this.taxFreeAllowanceRequestEnabled = false;
    }
  }

  public commonHouseholdPersonFilled(index: number): boolean {
    return RecognizerHelper.isNotEmpty(this.commonHouseholdPersonName[index])
      && RecognizerHelper.isNotEmpty(this.commonHouseholdPersonPropinquity[index])
      && RecognizerHelper.isNotEmpty(this.commonHouseholdPersonProfession[index]);
  }

  public clearAdultSection(): void {
    this.isStudyingOrCompleted = false;
    this.completedFirstDegree = false;
    this.isStudying = false;
    this.schoolNames.length = 0;
    this.educationDates.length = 0;
    this.lackOfTrainingSpace = false;
    this.lackOfTrainingSpaceDates.length = 0;
    this.hasVoluntaryYear = false;
    this.voluntaryYearDates.length = 0;
    this.beenInTransitionalPeriod = false;
    this.transitionalPeriodDates.length = 0;
    this.isUnemployed = false;
    this.unemploymentDates.length = 0;
    this.disabilityBefore25 = false;
    this.disabilityBefore25Dates.length = 0;
    this.haveJob = false;
    this.haveMinijobs = false;
    this.minijobDates.length = 0;
    this.minijobNumberOfHours.length = 0;
    this.wasEmployed = false;
    this.employmentDates.length = 0;
    this.employmentNumberOfHours.length = 0;
  }

  public clearDeductions(): void {
    this.residence.length = 0;
    this.schoolNames.length = 0;
    this.educationDates.length = 0;

    this.removeAllPaidSchool();

    this.removeAllChildcareService();

    this.removeAllLonelyRaisingChildren();

    this.removeAllSchoolOutsideHome();

    this.invalidityCardDate.length = 0;
    this.invalidityCardValidFrom.length = 0;
    this.invalidityCardValidTo.length = 0;
    this.invalidityCardValidForever.length = 0;
    this.parentsDidntLiveWithChild = false;
    this.childDidntLiveWithApplicantWholeYear = false;
    this.taxFreeAllowanceRequestSecondParent = false;
    this.taxFreeAllowanceRequestResidence = false;
    this.raisingChildLonely = false;
    this.studiedOutsidePlaceOfResidence = false;
    this.disabilityAllowanceIsNotFiftyFifty = false;
    this.hasDisabilityEnabled = false;
    this.hasDisability = false;
    this.isBlind = false;
    this.isImpaired = false;
    this.isBereaved = false;
    this.studiesAllowanceIsNotFiftyFifty = false;
  }

  public clearTransferRelieveGroup(): void {
    this.taxFreeAllowanceRequestSecondParent = false;
    this.taxFreeAllowanceRequestResidence = false;
    this.taxFreeAllowanceRequestEnabled = false;
  }

  public clearDisabilityGroup(): void {
    this.isBereaved = false;
    this.isBlind = false;
    this.hasDisability = false;
    this.isImpaired = false;
    this.disabilityLevel = '0';
    this.invalidityCardDate = [];
    this.participationInDisabilityAllowance = '0';
    this.disabilityAllowanceIsNotFiftyFifty = false;
    this.hasDisabilityEnabled = false;
  }
}

export enum DeductionChildSteps {
  MAIN = 'main',
  PAID_SCHOOL = 'paid_school',
  CHILD_CARE = 'child_care',
  TRANSFER_RELIEVE = 'transfer_relieve',
  TRANSFER_LONELY = 'transfer_lonely',
  STUDYING_OUTSIDE = 'studying_outside',
  DISABILITY = 'disability',
}

export const PROPINQUITY_CHOICES = [
  ['1', 'SELECT.PROPINQUITY.TYPES.BIOLOGICAL_ADOPTED_CHILD'],
  ['2', 'SELECT.PROPINQUITY.TYPES.FOSTER_CHILD'],
  ['3', 'SELECT.PROPINQUITY.TYPES.GRANDCHILD'],
];

export const FAMILIENKASSE_CHOICES = [
  'Baden-Württemberg Ost',
  'Baden-Württemberg West',
  'Bayern Nord',
  'Bayern Süd',
  'Berlin - Brandenburg',
  'Hessen',
  'Niedersachsen - Bremen',
  'Nord (Hamburg)',
  'Nordrhein-Westfalen Nord',
  'Nordrhein-Westfalen Ost',
  'Nordrhein-Westfalen West',
  'Rheinland-Pfalz - Saarland',
  'Sachsen',
  'Sachsen-Anhalt - Thüringen'
];

export const PROPINQUITY_TO_SECOND_PARENT_CHOICES = PROPINQUITY_CHOICES.slice(0, -1);

export const CHILD_CARE_TYPE_CHOICES = [
  ['Kindergarten', 'SELECT.CHILDCARE_TYPES.KINDERGARTEN'],
  ['Kinderhort', 'SELECT.CHILDCARE_TYPES.PRESCHOOL'],
  ['Tagesmutter', 'SELECT.CHILDCARE_TYPES.NANNY'],
  ['Hausaufgabenbetreuung', 'SELECT.CHILDCARE_TYPES.HOMEWORK_HELP'],
  ['Sonstige Kinderbetreuung', 'SELECT.CHILDCARE_TYPES.OTHER_CHILDCARE'],
];
