import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';

export class WorkflowBasicService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.statement && !!this.statement.fullYear;
  }

  public get isVisible(): boolean {
    return !this.isBusiness;
  }

  public get title(): string {
    return 'ASIDE_BAR.BASIC_INFO';
  }

  public get stepClass(): string {
    return this.classSplit + this.classMarginBottom + ' mt-0_75 mt-md-0 ';
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && !this.isDone;
  }

  public get urlRegex(): RegExp {
    return /(\/new)((\/?#?)|(\/?#.*?))$/;
  }

  public get url(): string {
    return `statement/${this.statement && this.statement.id ? this.statement.id : 0}/new`;
  }
}
