<div class="attachments__dialog-box">
    <div class="attachments__header">
        <h2 class="title_40" i18n>{{ 'DASHBOARD.ATTACHMENT.TITLE' | translate }}</h2>
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="attachments__content" [class.is-loading]="loading">
        <ul class="attachments__list" *ngIf="loadingData.disabled">

            <li class="attachments__single" *ngFor="let attachment of attachments; index as i">
                <div class="attachments__single_box">

                    <div class="attachments__single_name">
<!--						            file name -->
                        {{ attachment.file_name }}
                    </div>

                    <div class="attachments__single_size">
<!--						            file size -->
                        {{ attachment.formattedSize }}
                    </div>

                    <a (click)="onView(i)" target="_blank" class="attachments__single_view">
                        <span>View</span>
                        <img alt="view" src="../../../assets/svg/view_icon.svg">
                    </a>

                </div>

                <div class="attachments__single_select">
                    <mat-select [placeholder]="'Select an option'"
                                [(ngModel)]="attachment.type"
                                [disabled]="!!attachment.id"
                                class="mat-hide-arrow">

<!--						            file type -->
                        <mat-option *ngFor="let type of types | keyvalue" [value]="type.value.id">
                            <span i18n>{{ "DASHBOARD.ATTACHMENT.TYPE." + type.value.i18n | translate }}</span>
                        </mat-option>
                    </mat-select>
                </div>

                <button class="btn btn_w_icon btn_del" [class.is-loading]="loadingDelete" (click)="onDelete(i)">
                    <img alt="delete" src="../../../assets/svg/del_icon.svg">
                </button>

                <div class="attachments__single_year_wrap">
                    <div class="attachments__single_year" *ngIf="attachment.created_date" i18n>

<!--                        created date -->
                        {{ 'DASHBOARD.ATTACHMENT.UPLOADED' | translate }} {{ attachment.created_date }}
                    </div>
                    <div class="attachments__single_size">
                        78 kb
                    </div>
                </div>
            </li>
        </ul>

<!--        Add another document-->
        <label
          class="btn_add_form"
          *ngIf="newFiles.disabled"
          [id]="'statement_add_attachment_'+statementId"
        >
            <input type="file" name="file" (change)="onAdd($event)">
            <img src="../../../assets/svg/add.svg" alt="add">
            <span i18n>{{ 'DASHBOARD.ATTACHMENT.BUTTON.ADD' | translate }}</span>
        </label>

        <div *ngIf="newFiles.enabled" class="row__buttons">

<!--             Add another document -->
            <label
              class="btn_add_form"
              [id]="'statement_add_attachment_'+statementId"
            >
                <input type="file" name="file" (change)="onAdd($event)">
                <img src="../../../assets/svg/add.svg" alt="add">
                <span i18n>{{ 'DASHBOARD.ATTACHMENT.BUTTON.ADD' | translate }}</span>
            </label>

<!--            Save & Close -->
            <button
              class="btn btn_simple green__fill font-weight-bolder"
              (click)="onSave()"
              [class.is-loading]="loading"
              [disabled]="loading"
              [id]="'statement_save_attachments_'+statementId"
            >
                {{ 'DASHBOARD.ATTACHMENT.BUTTON.SAVE' | translate }}
            </button>

        </div>
    </div>
</div>
