import { Statement } from "app/statements";
import customParseFormat from "dayjs/plugin/customParseFormat";
// import dayjs from 'dayjs/esm';

import dayjs from "dayjs";

dayjs.extend(customParseFormat);

export class DateHelper {
  static readonly currentYear = new Date().getFullYear();
  static readonly lastTaxYear = DateHelper.currentYear - 1;

  static getFullYearFromString(date: string | Date, format: string = "YYYY-MM-DD"): number | null {
    if (date instanceof Date) {
      return date.getFullYear();
    }

    if (typeof date === "string" && DateHelper.getInstance(date, format, true).isValid()) {
      return new Date(date).getFullYear();
    }

    return null;
  }

  static removeYearFromDate(date: string | Date): string {
    return DateHelper.getInstance(date).format("DD/MM");
  }

  static getDatePlaceholder(year: number | string | Statement = null): string {
    if (year instanceof Statement) {
      year = year && year.fullYear ? year.fullYear : null;
    }

    if (!year) {
      return "DD.MM.YYYY";
    }

    return `DD.MM.${year}`;
  }

  static toPayload(date: string): string {
    let initialFormat = "",
      separator = "";

    if (date.indexOf(".") !== -1) {
      separator = ".";
    }
    if (date.indexOf(",") !== -1) {
      separator = ",";
    }
    if (date.indexOf("/") !== -1) {
      separator = "/";
    }
    if (date.indexOf("-") !== -1) {
      separator = "-";
    }
    if (date.indexOf(".") === 4 || date.indexOf(",") === 4 || date.indexOf("/") === 4 || date.indexOf("-") === 4) {
      initialFormat = `YYYY${separator}MM${separator}DD`;
    } else {
      initialFormat = `DD${separator}MM${separator}YYYY`;
    }

    return DateHelper.getInstance(date, initialFormat).format("YYYY-MM-DD");
  }

  static createListOfYearsBetween(from = 2016, to = DateHelper.lastTaxYear): number[] {
    const list = [];

    for (let i = from; i <= to; i++) {
      list.push(i);
    }

    return list;
  }

  static getInstance(date?: string | Date, format?: string | string[], isStrict?: boolean): dayjs.Dayjs {
    return dayjs(
      date || undefined,
      date instanceof Date ? undefined : format || undefined,
      date instanceof Date ? undefined : isStrict
    );
  }

  static getFormatted(date: string | Date, format: string): string {
    return DateHelper.getInstance(date || undefined).format(format);
  }

  static isSameOrBefore(date: dayjs.Dayjs, date2: dayjs.Dayjs): boolean {
    return date.isSame(date2) || date.isBefore(date2);
  }
}
