import { WorkflowDeductionSpouseSubBaseService } from 'app/core/workflow/workflow-steps/deduction/spouse/sub/workflow-deduction-spouse-sub-base.service';

export class WorkflowDeductionInfoSpouseInsuranceService extends WorkflowDeductionSpouseSubBaseService {
  public get title(): string {
    return 'ASIDE_BAR.SPOUSES_DEDUCTION_SUBLIST.INSURANCE';
  }

  public get subStepNumber(): number {
    return WorkflowDeductionInfoSpouseInsuranceService._subStepNumber;
  }

  public static get _subStepNumber(): number {
    return 5;
  }
}
