<div class="aside-menu">
    <div class="aside-menu__close">
        <div class="aside-menu__close-btn" (click)="closeSideNav()">+</div>
    </div>
    <div class="aside-menu__person">
        <div class="aside-menu__person-avatar">
            <i class="fa fa-user"></i>
        </div>
        <div class="aside-menu__person-fullname">{{ fullName }}</div>
        <div class="aside-menu__person-login level-item"><span>{{ (userLogin?.length>20)? (userLogin | slice:0:20)+'...':(userLogin || '') }}</span></div>
    </div>
    <div class="aside-menu__stages">
        <div
          class="aside-menu__stage"
          (click)="showChangeDataDialog()"
          [attr.data-cy]="'asideBaeMyAccountBtn'"
        >
          {{ 'MENU.ACCOUNT.TITLE' | translate }}
        </div>
        <div class="aside-menu__stage" (click)="showChangePasswordDialog()">{{ 'MENU.CHANGE_PASSWORD' | translate }}</div>
        <div class="aside-menu__stage" *ngIf="isBusiness">
            <a routerLink="/packages" (click)="closeSideNav()">{{ 'MENU.BUY_PACKAGES' | translate }}</a>
        </div>
        <a *ngIf="isBusiness" class="aside-menu__stage" routerLink="/payment-history" (click)="closeSideNav()">{{ 'MENU.PAYMENT_HISTORY' | translate }}</a>
        <a class="aside-menu__stage" routerLink="/dashboard" (click)="closeSideNav()">{{ 'MENU.DASHBOARD' | translate }}</a>
        <a *ngIf="!isBusiness" class="aside-menu__stage coupons__link" (click)="openCoupons()">{{"COUPONS_HEADER" | translate}}</a>
        <a class="aside-menu__stage" (click)="logout()">{{ 'MENU.LOG_OUT' | translate }}</a>
    </div>

    <!-- <app-choose-language></app-choose-language> -->

    <div class="aside-menu__stages aside-menu__no-border">
        <div class="aside-menu__wrap">
            <a href="mailto:support@taxando.de">
                <img src="../../../assets/svg/menu_help.svg" class="icon"/>
                <span>{{ 'MENU.HELP' | translate }}</span>
            </a>
        </div>
    </div>

    <div class="aside-menu__stages aside-menu__info-container">
        <a class="aside-menu__stage" (click)="showTermsAndConditionsDialog()">{{ 'COMMON.TERMS_AND_CONDITIONS' | translate }}</a>
        <a class="aside-menu__stage" (click)="showPrivacyPolicyDialog()">{{ 'COMMON.PRIVACY_POLICY' | translate }}</a>
        <a class="aside-menu__stage" (click)="showImpressumDialog()">Impressum</a>
        <a class="aside-menu__stage" (click)="showCookieBanner()">{{ 'COMMON.COOKIE_BANNER' | translate }}</a>
    </div>
</div>
