import { Injectable } from "@angular/core";
import { AuthHttpService } from "app/users";
import { getApiPath } from "app/common";
import { Message, MessageDataTypes } from "../send-message-dialog.component";
import { Observable } from "rxjs";

@Injectable()
export class MessageSenderHttpService {
  constructor(private authHttp: AuthHttpService) {}

  public sendMessage(statementID: number, data: MessageDataTypes) {
    const formData = this.prepareFormData(data);
    return this.authHttp.post(`${getApiPath()}statements/${statementID}/send-message`, formData);
  }

  public removeMessage(statementID: number, documentPK: number, data: MessageDataTypes) {
    return this.authHttp.remove(`${getApiPath()}statements/${statementID}/message/${documentPK}`, data);
  }

  public getMessageData(statementID: number): Observable<Message[]> {
    return this.authHttp.get(`${getApiPath()}statements/${statementID}/send-message`);
  }

  public removeMessageAttachment(statementID: number, messagePK: number, documentPK: number) {
    return this.authHttp.remove(
      `${getApiPath()}statements/${statementID}/message/${messagePK}/delete-file/${documentPK}`,
      {}
    );
  }

  public updateMessageData(statementID: number, documentPK: number, data: MessageDataTypes) {
    const formData = this.prepareFormData(data);
    return this.authHttp.patch(`${getApiPath()}statements/${statementID}/message/${documentPK}`, formData);
  }

  private prepareFormData(data: MessageDataTypes): FormData {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      //dont add subtype filed when value is null
      if (key === "subtype" && (value === null || value === undefined || value === "")) continue;

      if (typeof value === "string" || value instanceof Blob) {
        formData.append(key, value);
      } else if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else if (Array.isArray(value) && value.every((item) => item instanceof File)) {
        value.forEach((file) => formData.append(key, file));
      } else {
        formData.append(key, value.toString());
      }
    }

    return formData;
  }

  downloadConfirmation(statementID: number, documentPK: number): Observable<Blob> {
    return this.authHttp.get(`${getApiPath()}statements/${statementID}/download-confirmation/${documentPK}`, {
      responseType: "blob"
    });
  }

  getMessageAttachmentList(statementID: number) {
    return this.authHttp.get(`${getApiPath()}statements/${statementID}/send-message`);
  }
}
