import { Statement } from 'app/statements';
import { ArrayHelper } from 'app/core/helpers/array.helper';
import { FreelanceJobTypes } from 'app/modules/statement/models/additional-income/freelance-job.types';
import { FreelanceJob } from 'app/modules/statement/models/additional-income/freelance-job.model';

export class FreelanceJobManager {
  public static getFreelance(statement: Statement): FreelanceJob[] {
    return FreelanceJobManager.get(
      statement,
      FreelanceJobTypes.FREELANCE
    );
  }

  public static createFreelance(statement: Statement): FreelanceJob {
    return FreelanceJobManager.create(
      statement,
      FreelanceJobTypes.FREELANCE
    );
  }

  public static removeAllFreelance(statement: Statement): void {
    return FreelanceJobManager.removeAll(
      statement,
      FreelanceJobTypes.FREELANCE
    );
  }

  public static removeFreelance(statement: Statement, index: number): void {
    FreelanceJobManager.remove(
      statement,
      FreelanceJobTypes.FREELANCE,
      index
    );
  }

  public static getGewerbe(statement: Statement): FreelanceJob[] {
    return FreelanceJobManager.get(
      statement,
      FreelanceJobTypes.GEWERBE
    );
  }

  public static createGewerbe(statement: Statement): FreelanceJob {
    return FreelanceJobManager.create(
      statement,
      FreelanceJobTypes.GEWERBE
    );
  }

  public static removeAllGewerbe(statement: Statement): void {
    return FreelanceJobManager.removeAll(
      statement,
      FreelanceJobTypes.GEWERBE
    );
  }

  public static removeGewerbe(statement: Statement, index: number): void {
    FreelanceJobManager.remove(
      statement,
      FreelanceJobTypes.GEWERBE,
      index
    );
  }

  public static createVolunteringIncome(statement: Statement): FreelanceJob {
    return FreelanceJobManager.create(
      statement,
      FreelanceJobTypes.VOLUNTERING_INCOME
    );
  }

  public static getVolunteringIncome(statement: Statement): FreelanceJob[] {
    return FreelanceJobManager.get(
      statement,
      FreelanceJobTypes.VOLUNTERING_INCOME
    );
  }

  public static removeVolunteringIncome(statement: Statement): void {
    return FreelanceJobManager.removeAll(
      statement,
      FreelanceJobTypes.VOLUNTERING_INCOME
    );
  }

  public static removeVoluntering(statement: Statement, index: number): void {
    FreelanceJobManager.remove(
      statement,
      FreelanceJobTypes.VOLUNTERING_INCOME,
      index
    );
  }

  public static get(statement: Statement, incomeType: FreelanceJobTypes): FreelanceJob[] {
    return ArrayHelper.arrayFilter(
      statement.freelanceJobs,
      'incomeType',
      incomeType
    );
  }

  public static create(statement: Statement, incomeType: FreelanceJobTypes): FreelanceJob {
    const freelanceJob = new FreelanceJob(incomeType);
    statement.freelanceJobs.push(freelanceJob);

    return freelanceJob;
  }

  public static removeAll(statement: Statement, incomeType: FreelanceJobTypes): void {
    statement.freelanceJobs = statement.freelanceJobs.filter(x => x.incomeType !== incomeType);
  }

  public static remove(statement: Statement, incomeType: FreelanceJobTypes, index: number): void {
    const freelanceJobsList = FreelanceJobManager.get(statement, incomeType);
    freelanceJobsList.splice(index, 1);
    FreelanceJobManager.removeAll(statement, incomeType);

    statement.freelanceJobs = [...statement.freelanceJobs, ...freelanceJobsList];
  }
}
