import { SelectsDataInterface, Statement } from 'app/statements';
import { User } from 'app/users';
import { WorkflowStepsBaseClassesService } from './workflow-steps-base-classes.service';
import { RecognizerHelper } from 'app/core/helpers/recognizer.helper';
import { Child } from 'app/statements/child';
import {
  PersonalInfoChildSummaryComponentPath
} from 'app/core/workflow/page-path/statement-page-path/personal-info-child-summary-component-path';

export class WorkflowStepsBaseService extends WorkflowStepsBaseClassesService {
  private _subStepNumber = 0;
  private _title = '';
  private _titleFunc: () => string = null;

  constructor(
    public statement: Statement,
    public user: User,
    public selectsData: SelectsDataInterface
  ) {
    super();
  }

  protected get defaultIsActiveFunction(): boolean {
    if (this.urlRegex) {
      return (this.url || this.fullUrl) && window.location.href.search(this.urlRegex) !== -1;
    }

    return this.url && window.location.href.indexOf(this.url) !== -1;
  }

  protected get isBusiness(): boolean {
    return this.user ? this.user.isBusiness : false;
  }

  protected get isTaxAdviser(): boolean {
    return this.user ? this.user.isTaxAdviser : false;
  }

  public get url(): string {
    return '';
  }

  public get urlRegex(): RegExp {
    return null;
  }

  public get fullUrl(): string {
    return `/new/${this.url}`;
  }

  public get isDone(): boolean {
    return true;
  }

  public get isBlocked(): boolean {
    return false;
  }

  public get checkCanGo(): boolean {
    return true;
  }

  public get isActive(): boolean {
    return this.defaultIsActiveFunction;
  }

  public get isRequired(): boolean {
    return true;
  }

  public get canGo(): boolean {
    return !this.isBlocked && this.checkCanGo;
  }

  public get blockedDefaultWorkflow(): boolean {
    if (this.isTaxAdviser) {
      return this.statement && !this.statement.isPaid;
    }

    return this.statement && (this.statement.isPaid || this.statement.isSent());
  }

  public get isVisible(): boolean {
    return false;
  }

  public set subStepNumber(stepNumber: number) {
    this._subStepNumber = stepNumber;
  }

  public get subStepNumber(): number {
    return this._subStepNumber;
  }

  public set title(title: string) {
    this._title = title;
  }

  public get title(): string {
    return RecognizerHelper.isFunction(this._titleFunc) ? this._titleFunc() : this._title;
  }

  public set titleFunction(func: null|(() => string)) {
    this._titleFunc = func;
  }

  public get titleFunction(): null|(() => string) {
    return this._titleFunc;
  }

  public get stepClass(): string {
    return '';
  }

  public get isActiveOnStepsBar(): boolean {
    return false;
  }

  public get isSubStep(): boolean {
    return false;
  }

  public get isFakeStep(): boolean {
    return false;
  }

  public get skipWhenGoBack(): boolean {
    return false;
  }

  public get goBackUrl(): string {
    return null;
  }

  public get goNextUrl(): string {
    return null;
  }

  public get applicantHasEmployers(): boolean {
    return !!this.statement && this.statement.hasEmployers;
  }

  public get spouseHasEmployers(): boolean {
    return !!this.statement && !!this.statement.spouse && this.statement.spouse.hasEmployers;
  }

  public get hasSpouseAndFilledTogether(): boolean {
    return !!this.statement && !!this.statement.spouse && this.statement.filedTogether;
  }

  public get hasChildren(): boolean {
    return !!this.statement && this.statement.hasChildren;
  }

  public get isStageEmployerDone(): boolean {
    return this.statement && this.statement.stageEmployers
      || (!!this.statement.employers.length && !!this.statement.employers[0].taxClass);
  }

  public get isSpouseStageEmployerDone(): boolean {
    return this.statement && this.statement.spouse
      && (
        this.statement.spouse.stageEmployers
        || (
          !!this.statement.spouse.employers.length
          && !!this.statement.spouse.employers[0].taxClass
        )
      );
  }

  public get isBankAccountPassed(): boolean {
    if (this.isBusiness) {
      return this.statement
        && ((this.statement.hasBankAccount && !!this.statement.iban) || !this.statement.hasBankAccount);
    }

    return this.statement && !!this.statement.iban;
  }

  public get userTypeUrlSuffix(): string {
    return this.isBusiness ? '' : 'b2c/';
  }

  public get getStatementId(): number {
    return this.statement && this.statement.id ? this.statement.id : 0;
  }

  public get getSpouseId(): number {
    return this.statement && this.statement.spouse ? this.statement.spouse.id : 0;
  }

  public get getFirstChildId(): number {
    return this.statement && this.statement.children && this.statement.children.length
      ? this.statement.children[0].id
      : 0;
  }

  public get getChild(): Child {
    if (this.statement && this.hasChildren && this.subStepNumber) {
      return this.statement.children.find(x => x.id === this.subStepNumber);
    }

    return null;
  }

  public get getEmployerId(): number {
    return this.statement && this.statement.employers && this.statement.employers.length
      ? this.statement.employers[0].id
      : 0;
  }
}
