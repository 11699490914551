
    <div class="coupons-popup" #couponsPopup *ngIf="couponCode">
        <mat-dialog-actions>
            <div class="close" [mat-dialog-close]="true">+</div>
        </mat-dialog-actions>
        <span class="subdesc">{{"COUPONS.CUSTOMER_INFO" | translate}}</span>
        <span class="instruction">{{"COUPONS.POPUP_DESC" | translate}}</span>
        <div class="coupon-code">
            <input   class="coupon-input" readonly value="{{couponCode.referal_code || couponCode}}" />
            <button class="btn_simple green" (click)="getCouponCode(couponCode.referal_code)">{{"COUPONS.COPY_BUTTON" | translate}}</button>
        </div>
        <!-- SOCIAL MEDIA TEMPORERY REMOVE -->
        <!-- <span class="social-info">{{"COUPONS.POPUP_SOCIAL_TEXT" | translate}}</span>
        <div class="share-social">
            <div class="mail"></div>
            <div class="fb"></div>
            <div class="whatsup"></div>
        </div> -->
    </div>
