import { Inject, Injectable, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ScriptHelper } from 'app/core/helpers/script.helper';
import { GoogleTagManagerConfig } from 'app/shared/modules/google-analytics/configs/google-tag-manager.config';
import { debounceTime } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { CookiesLawService } from 'app/core/services/cookies-law.service';

@Injectable()
export class GoogleTagManagerService {
  private readonly window: Window;

  constructor(
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
    private cookiesLawService: CookiesLawService,
  ) {
    this.window = this.document.defaultView as unknown as Window;
    this.onChanges();

    this.cookiesLawService.changesEmitter.pipe(debounceTime(1000))
      .subscribe({
        next: () => this.onChanges()
      });
  }

  public enable(): void {
    this.cookiesLawService.enableAnalytics();
    this.cookiesLawService.enableTargeting();
  }

  public disable(): void {
    this.cookiesLawService.disableAnalytics();
    this.cookiesLawService.disableTargeting();
  }

  public get doesUserAllowAnalytics(): boolean {
    return this.cookiesLawService.isAnalyticsEnabled;
  }

  private remove(): void {
    ScriptHelper.remove(GoogleTagManagerConfig.scriptId);
  }

  private init(): void {
    if (!GoogleTagManagerConfig.isEnabled) {
      return;
    }

    if (ScriptHelper.isInjected(GoogleTagManagerConfig.scriptId)) {
      return;
    }

    this.zone.run(() => (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      });
      const f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.id = 'gtm-script';
      j['async'] = true;
      j['src'] = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(this.window, this.document, 'script', 'dataLayer', GoogleTagManagerConfig.tagId));
  }

  private onChanges(): void {
    if (this.doesUserAllowAnalytics) {
      this.init();
    } else {
      this.remove();
    }
  }
}
