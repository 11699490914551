
import {finalize, takeUntil} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Store} from '@ngrx/store';
import {State, getUser} from '../../reducers';
import {SHOW_SNACKBAR} from '../../reducers/actions/snackbar';
import {StatementService} from '../statement.service';
import {SubscribingComponent, saveFile, SnackbarConfig} from 'app/common';
import {Statement} from '../statement';
import {FinanzamtAddress} from './../finanzamt-address';
import {Events, FirebaseEventsService} from 'app/common/firebase-events.service';
import { FirebaseNotification } from 'app/firebase/notification';
import { FirebaseHttpService } from 'app/firebase/firebase.http.service';
import { User, UserDataService } from 'app/users';
import { EmailVerificationDialogComponent } from 'app/common/email-verification-dialog/email-verification-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {PackageOptions} from '../package-options';
import { FinishPagePath } from 'app/core/workflow/page-path/statement-page-path/finish-page.path';
@Component({
  selector: 'app-download-statement',
  templateUrl: './download-statement.component.html',
  styleUrls: ['./download-statement.component.scss']
})
export class DownloadStatementComponent extends SubscribingComponent implements OnInit {
  statement: Statement;
  buttons = [];
  error = '';
  statementId: number;
  downloaded = false;
  finanzamtAddress: FinanzamtAddress;
  public user_id;
  public emailVerified: boolean = false;
  public userData: User;

  private dialogComponentEmailVerification = EmailVerificationDialogComponent;


  constructor(private statementService: StatementService,
              private route: ActivatedRoute,
              private router: Router,
              private store: Store<State>,
              private dialog: MatDialog,
              private userDataService: UserDataService,
              private firebaseEventsService: FirebaseEventsService,
              private firebaseService: FirebaseHttpService,
  ) {
    super();
  }

  ngOnInit() {
    this.store.select(getUser).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user)
          this.user_id = user.id;
      });

      this.userDataService.getUserData().pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe((user: User) => {
          if (user?.emailVerified) {
            this.emailVerified = true;
          }
          this.userData = user;

          this.route.params.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
              this.statementId = parseInt(params['statementId'], 10);
              this.getStatementData(this.statementId);
            });
        });

  }

  /** TAX-2669 firebase events
   */
  private firebaseEvents = (): void => {

    if ( this.statement.serviceType === PackageOptions.free ) {

      // TAX-2569 purchase_complete_free
      if ( sessionStorage.getItem('purchase_complete_free') !== this.statement.id.toString() && !this.statement.eventPurchaseCompleteType ) {
        sessionStorage.setItem('purchase_complete_free', this.statement.id.toString());

        this.firebaseEventsService
          .logEvent(Events.purchase_free, null, this.statement.id)
        this.firebaseEventsService.webEvent(Events.purchase_free, null, this.statement.id)
      }

      // TAX-2046 use_premium_steuerberater
      if ( sessionStorage.getItem('use_premium_steuerberater') !== this.statement.id.toString() ) {
        sessionStorage.setItem('use_premium_steuerberater', this.statement.id.toString())

        const notification = new FirebaseNotification();

        notification.user = this.userData.id;
        notification.statement = this.statement.id;
        notification.notificationName = 'use_premium_steuerberater';
        notification.eventTwoTriggered = true;

        this.firebaseService
          .udpateFirebaseNotification(notification).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({error: (e) => console.log(e)});
      }
    }
  }

  getStatementData(id: number) {
    this.statementService.getStatement(id).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response: Statement) => {
          this.statement = response;
          this.downloaded = this.statement.isSent();

          this.firebaseEvents();
          this.getFinanzamtAddress();
          this.setButtons();
        },
        error: () => this.router.navigate(['/new'])
      });
  }

  setButtons() {
    this.buttons = [
      {
        type: 'proceed',
        label: 'COMMON.PROCEED',
        action: this.proceed.bind(this),
        disabled: () => !this.downloaded
      },
    ];
  }

  proceed() {
    this.firebaseEventsService.logEvent('click_finish', null, this.statementId)
    this.router.navigate([FinishPagePath.fullUrl(this.statementId)]);
  }

  downloadPdf() {
    this.firebaseEventsService.logEvent('click_save_and_download', null, this.statementId)

    if (!this.downloaded) {
      this.sendAndDownloadPdf();
    } else {
      this.downloadSentPdf();
    }
  }

  showPDFError() {
    this.store.dispatch({type: SHOW_SNACKBAR, payload: new SnackbarConfig('SNACKBAR.PDF_NOT_AVAILABLE', 'ERROR')});
  }

  private getFinanzamtAddress() {
    this.statementService.getFinanzamtAddress(this.statement.taxAuthority).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response: FinanzamtAddress) => {
          this.finanzamtAddress = response[0];
        },
        () => this.router.navigate(['/new'])
      );
  }

  private sendAndDownloadPdf() {
    this.reloadUserData();
  }

  private downloadSentPdf() {
    this.statementService.downloadStatementPdf(this.statement.id).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.statement.downloadingPdf = false),)
      .subscribe(
        (file: Blob) => {
          saveFile(file, `statement-${this.statement.id}.pdf`);
          this.downloaded = true;
        },
        this.showPDFError.bind(this)
      );
  }

  private showEmailVerDialog() {
    let refDialog = this.dialog.open(this.dialogComponentEmailVerification);
    refDialog.componentInstance.statement = this.statement;
  }

  private reloadUserData() {
    this.userDataService.getUserData().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user?.emailVerified) {
          this.emailVerified = true;
        }
        this.userData = user;
        if (this.emailVerified) {
          this.statement.downloadingPdf = true;
          this.statementService.sendStatement(this.statement.id).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(
              () => {
                this.statementService.downloadStatementPdf(this.statement.id).pipe(
                  takeUntil(this.ngUnsubscribe),
                  finalize(() => this.statement.downloadingPdf = false),)
                  .subscribe(
                    (file: Blob) => {
                      saveFile(file, `statement-${this.statement.id}.pdf`);
                      this.downloaded = true;
                    },
                    this.showPDFError.bind(this)
                  );
              },
              (error: any) => {
                const message = error.error[0];
                if (typeof message === 'string') {
                  this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig(message, 'ERROR') });
                }
              }
            );
        } else {
          this.showEmailVerDialog();
        }
      });
  }
}
