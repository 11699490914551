<div class="dialog-box position-relative" [class.is-loading]="isLoading">
    <div class="dialog-box__header">
        <div class="title_42">
            <img class="title_42__icon" src="assets/img/refund_header_icon.png">
            {{ 'COMMON.REFUND_DETAILS' | translate }}
        </div>
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content hidden-sm hidden-xs" *ngIf="isLoaded">
        <!-- alert-->
        <ng-container *ngIf="hasAbroadIncome()">
            <div class="deductions-info warning is_icon"
                *ngIf="!canApplyForTaxResidencyStatus() || showApplyForResidencyStatusNotification">
                <div class="deductions-info__wrap top">
                    <div class="deductions-info__toltip-wrap alert_wrap">
                        <img src="../../../assets/svg/error_icon.svg" class="alert_icon">
                    </div>
                    <div class="deductions-info__wrap-text">
                        <p class="deductions-info__text">
                            <strong>{{ 'ABROAD_INCOME.ATTENTION' | translate }}</strong>
                            {{ 'ABROAD_INCOME.ATTENTION_CONDITIONS' | translate }}
                            {{ 'ABROAD_INCOME.WONT_ADD_DEDUCTIONS' | translate }}
                            {{ 'ABROAD_INCOME.WONT_ADD_DEDUCTIONS_FEE' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- alert-->

        <ng-container *ngIf="!isLogged">
          <p class="font-size-20 mb-1" *ngIf="!isLogged">
            {{ 'REFUND_DETAILS.NOT_LOGGED.SUBDESC' | translate }}
          </p>
          <div class="details_table_wrap not_logged mb-1">
            <div class="details-table-container">
              <button
                class="btn btn--primary btn-open-modal"
                mat-dialog-close
                (click)="openRegisterModal()"
              >
                {{ 'REFUND_DETAILS.NOT_LOGGED.ENTER_INCOME' | translate }}
              </button>
              <table class="details-table">
                <tr>
                  <th class="details-table__black-header" colspan="5">
                    <div>
                      <span class="title_18">{{ 'REFUND_DETAILS.BERECHNUNG' | translate }}
                        <span *ngIf="statement.fullYear">
                          {{ 'REFUND_DETAILS.FUR' | translate }}
                          {{ statement.fullYear }}
                        </span>
                      </span>
                      {{ 'REFUND_DETAILS.UBER_EINKOMMENSTEUER_SOLIDARITATSZUSCHLAG_KIRCHENSTEUER' | translate }}
                    </div>
                  </th>
                </tr>
                <tr class="details-table__header-row">
                  <th></th>
                  <th class="details-table__green-header bg-grey">{{ 'REFUND_DETAILS.EINKOMMENSTEUER' | translate }}<br>€</th>
                  <th class="details-table__green-header bg-grey">{{ 'REFUND_DETAILS.KIRCHENSTEUER' | translate }}<br>€</th>
                  <th class="details-table__green-header bg-grey">{{ 'REFUND_DETAILS.SOLIDARITATSZUSCHLAG' | translate }}<br>€</th>
                  <th class="details-table__green-header dark">{{ 'REFUND_DETAILS.INSGESAMT' | translate }}<br>€</th>
                </tr>
                <tr>
                  <td class="details-table__value-name title_cell bg-white">{{ 'REFUND_DETAILS.FESTGESETZTER_STEUERBETRAG' | translate }}</td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                  <td class="div-cell br" rowspan="4">
                    <div></div>
                  </td>
                </tr>
                <tr>
                  <td class="details-table__value-name title_cell bg-white">{{ 'REFUND_DETAILS.BEREITS_GETILGT_STPFL_PARTNER_A' | translate }}</td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                </tr>
                <tr>
                  <td class="details-table__value-name title_cell bg-white">{{ 'REFUND_DETAILS.BEREITS_GETILGT_PARTNER_B' | translate }}</td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                </tr>
                <tr>
                  <td class="details-table__value-name title_cell bg-white div-cell bl">
                    <div class="pl-1_5">
                      {{ 'REFUND_DETAILS.VERBLEIBENDE_BETRAGE' | translate }}
                    </div>
                  </td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted">
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div>
            <span class="font-size-20 font-weight-bold">{{'REFUND_DETAILS.NOT_LOGGED.PREMIUM_PACKAGE.DESC' | translate}}</span>
            &nbsp;
            <span class="v-align-mid">
              <app-more-info-tooltip
                [showText]="false"
                [titleTooltip]="'PACKAGE_SELECTION2.HOW_DOES_IT_WORK'"
                [xlImgMobile]="true"
                [contentTemplateRef]="premiumPackageTooltipTemplate1"
              ></app-more-info-tooltip>
              <ng-template #premiumPackageTooltipTemplate1>
                <app-tooltip-template-package-info 
                  [isPremiumPackage]="true"
                  [isHowDoesItWork]="true">
                </app-tooltip-template-package-info>
              </ng-template>
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="isLogged">
          <div class="details_table_wrap">
            <table class="details-table">
              <tr>
                <th class="details-table__black-header" colspan="5">
                  <div>
                            <span class="title_18">{{ 'REFUND_DETAILS.BERECHNUNG' | translate }}<span *ngIf="statement.fullYear">
                                {{ 'REFUND_DETAILS.FUR' | translate }}
                              {{ statement.fullYear }}</span></span>
                    {{ 'REFUND_DETAILS.UBER_EINKOMMENSTEUER_SOLIDARITATSZUSCHLAG_KIRCHENSTEUER' | translate }}
                  </div>
                </th>
              </tr>
              <tr class="details-table__header-row">
                <th class="no_border"></th>
                <th class="details-table__green-header">{{ 'REFUND_DETAILS.EINKOMMENSTEUER' | translate }}<br>€</th>
                <th class="details-table__green-header">{{ 'REFUND_DETAILS.KIRCHENSTEUER' | translate }}<br>€</th>
                <th class="details-table__green-header">{{ 'REFUND_DETAILS.SOLIDARITATSZUSCHLAG' | translate }}<br>€</th>
                <th class="details-table__green-header dark">{{ 'REFUND_DETAILS.INSGESAMT' | translate }}<br>€</th>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.FESTGESETZTER_STEUERBETRAG' | translate }}</td>
                <td>{{ refundDetails.festgesetzteEinkommensteuer }}</td>
                <td>{{ refundDetails.festgesetzteKirchensteuersteuer }}</td>
                <td>{{ refundDetails.festgesetzterSolidaritatszuschlag }}</td>
                <td
                  rowspan="4"
                  class="bold_item"
                  [class.text-primary]="refundDetails.insgesamt < 0"
                  [class.text-danger]="refundDetails.insgesamt > 0"
                >
                  {{ refundDetails.insgesamt < 0 ? '+' : '' }}{{ refundDetails.insgesamt * -1 }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.BEREITS_GETILGT_STPFL_PARTNER_A' | translate }}</td>
                <td>{{ refundDetails.applicant.bereitsGetilgtEinkommensteuer }}</td>
                <td>{{ refundDetails.applicant.bereitsGetilgtKirchenssteuer }}</td>
                <td>{{ refundDetails.applicant.bereitsGetilgtSolidaritatszuschlag }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.BEREITS_GETILGT_PARTNER_B' | translate }}</td>
                <td>{{ refundDetails.spouse.bereitsGetilgtEinkommensteuer }}</td>
                <td>{{ refundDetails.spouse.bereitsGetilgtKirchenssteuer }}</td>
                <td>{{ refundDetails.spouse.bereitsGetilgtSolidaritatszuschlag }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.VERBLEIBENDE_BETRAGE' | translate }}</td>
                <td>{{ refundDetails.verbleibendeBetrageEinkommensteuer }}</td>
                <td>{{ refundDetails.verbleibendeBetrageKirchensteuer }}</td>
                <td>{{ refundDetails.verbleibendeBetrageSolidaritatszuschlag }}</td>
              </tr>
            </table>

            <table class="details-table">
              <tr class="details-table__header-row">
                <th class="details-table__green-header details-table__green-header--limited-space">{{ 'REFUND_DETAILS.BERECHNUNG_DES_VERSTEUERNDEN_EINKOMMENS' | translate }}</th>
                <th class="details-table__muted-header">{{ 'REFUND_DETAILS.STPFL_PARTNER_A' | translate }}<br>€</th>
                <th class="details-table__muted-header">{{ 'REFUND_DETAILS.PARTNER_B' | translate }}<br>€</th>
                <th class="details-table__muted-header">{{ 'REFUND_DETAILS.INSGESAMT' | translate }}<br>€</th>
              </tr>

              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.EINKUNFTE_AUS_NICHTSELBSTANDIGER_ARBEIT' | translate }}</td>
                <td>{{ refundDetails.applicant.einkunfteAusNichtselbstandigerArbeit }}</td>
                <td>{{ refundDetails.spouse.einkunfteAusNichtselbstandigerArbeit }}</td>
                <td>{{ refundDetails.applicant.einkunfteAusNichtselbstandigerArbeit + refundDetails.spouse.einkunfteAusNichtselbstandigerArbeit }}
                </td>
              </tr>
              <ng-container *ngIf="refundDetails.applicant.pensionTotalAmount || refundDetails.spouse.pensionTotalAmount">
                <tr>
                  <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.JAHRESBETRAG_DER_RENTE' | translate }}</td>
                  <td>{{ refundDetails.applicant.pensionTotalAmount }}</td>
                  <td>{{ refundDetails.spouse.pensionTotalAmount }}</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.STEUERPFLICHTIGER_TEIL_DER_RENTE' | translate }}</td>
                  <td>{{ refundDetails.applicant.pensionTotalTaxable }}</td>
                  <td>{{ refundDetails.spouse.pensionTotalTaxable }}</td>
                  <td>-</td>
                </tr>
              </ng-container>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.EINKUNFTE_AUS_SELBSTANDIGER_ARBEIT' | translate }}</td>
                <td>{{ refundDetails.applicant.einkunfteAusSelbstandigerArbeit }}</td>
                <td>{{ refundDetails.spouse.einkunfteAusSelbstandigerArbeit }}</td>
                <td>{{ refundDetails.applicant.einkunfteAusSelbstandigerArbeit + refundDetails.spouse.einkunfteAusSelbstandigerArbeit }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.AB_BETRAG_NACH_ABS_UND_ESTG' | translate }}</td>
                <td>{{ refundDetails.applicant.abBetragNach46 }}</td>
                <td>{{ refundDetails.spouse.abBetragNach46 }}</td>
                <td>{{ refundDetails.applicant.abBetragNach46 + refundDetails.spouse.abBetragNach46 }}</td>
              </tr>
              <ng-container *ngIf="refundDetails.applicant.cryptoCurrencyIncome || refundDetails.spouse.cryptoCurrencyIncome">
                <tr>
                  <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.CRYPTOCURRENCY_INCOME' | translate }}</td>
                  <td>{{ refundDetails.applicant.cryptoCurrencyIncome }}</td>
                  <td>{{ refundDetails.spouse.cryptoCurrencyIncome }}</td>
                  <td>{{ refundDetails.applicant.cryptoCurrencyIncome + refundDetails.spouse.cryptoCurrencyIncome }}
                  </td>
                </tr>
              </ng-container>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.WERBUNGSKOSTEN' | translate }}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.ARBEITNEHMER_PAUSCHBETRAG' | translate }}</td>
                <td>{{ refundDetails.applicant.getArbeitnehmerPauschbetrag() }}</td>
                <td>{{ refundDetails.spouse.getArbeitnehmerPauschbetrag() }}</td>
                <td>{{ refundDetails.getSumArbeitnehmerPauschbetrag() }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.WEGE_ZWISCHEN_WOHNUNG_ERSTER_TATIGKEITSSTATTE_ENTFERNUNGSPAUSCHALE' | translate }}</td>
                <td>{{ refundDetails.applicant.entfernungspauschale }}</td>
                <td>{{ refundDetails.spouse.entfernungspauschale }}</td>
                <td>{{ refundDetails.applicant.entfernungspauschale + refundDetails.spouse.entfernungspauschale }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.AUFWENDUNGEN_FUR_OFFENTLICHE_VERKEHRSMITTEL' | translate }}</td>
                <td>{{ refundDetails.applicant.aufwendungenFurOffentlicheVerkehrsmittel }}</td>
                <td>{{ refundDetails.spouse.aufwendungenFurOffentlicheVerkehrsmittel }}</td>
                <td>{{ refundDetails.applicant.aufwendungenFurOffentlicheVerkehrsmittel + refundDetails.spouse.aufwendungenFurOffentlicheVerkehrsmittel }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.AUSWARTSTATIGKEIT' | translate }}</td>
                <td>{{ refundDetails.applicant.auswartstatigkeit }}</td>
                <td>{{ refundDetails.spouse.auswartstatigkeit }}</td>
                <td>{{ refundDetails.applicant.auswartstatigkeit + refundDetails.spouse.auswartstatigkeit }}</td>
              </tr>
              <tr *ngIf="statement && statement.fullYear >= 2020">
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.UBERNACHTUNGSPAUSHALE_FUR_BERUFSKRAFTFAHRER' | translate }}</td>
                <td>{{ refundDetails.applicant.ubernachtungspaushaleFurBerufskraftfahrer }}</td>
                <td>{{ refundDetails.spouse.ubernachtungspaushaleFurBerufskraftfahrer }}</td>
                <td>
                  {{
                  refundDetails.applicant.ubernachtungspaushaleFurBerufskraftfahrer
                  + refundDetails.spouse.ubernachtungspaushaleFurBerufskraftfahrer
                  }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.BEITRAGE_BERUFSVERBANDEN' | translate }}</td>
                <td>{{ refundDetails.applicant.beitrageZuBerufsverbanden }}</td>
                <td>{{ refundDetails.spouse.beitrageZuBerufsverbanden }}</td>
                <td>{{ refundDetails.applicant.beitrageZuBerufsverbanden + refundDetails.spouse.beitrageZuBerufsverbanden }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.AUFWENDUNGEN_ARBEITSMITTEL' | translate }}</td>
                <td>{{ refundDetails.applicant.aufwendungenFurArbeitsmittel }}</td>
                <td>{{ refundDetails.spouse.aufwendungenFurArbeitsmittel }}</td>
                <td>{{ refundDetails.applicant.aufwendungenFurArbeitsmittel + refundDetails.spouse.aufwendungenFurArbeitsmittel }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.AUFWENDUNGEN_HAUSLICHES_ARBEITSZIMMER' | translate }}</td>
                <td>{{ refundDetails.applicant.aufwendungenFurEinHauslichesArbeitszimmer }}</td>
                <td>{{ refundDetails.spouse.aufwendungenFurEinHauslichesArbeitszimmer }}</td>
                <td>{{ refundDetails.applicant.aufwendungenFurEinHauslichesArbeitszimmer + refundDetails.spouse.aufwendungenFurEinHauslichesArbeitszimmer }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.FORTBILDUNGSKOSTEN' | translate }}</td>
                <td>{{ refundDetails.applicant.fortbildungskosten }}</td>
                <td>{{ refundDetails.spouse.fortbildungskosten }}</td>
                <td>{{ refundDetails.applicant.fortbildungskosten + refundDetails.spouse.fortbildungskosten }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.MEHRAUFWENDUNGEN_DOPPELTE_HAUSHALTSFUHRUNG' | translate }}</td>
                <td>{{ refundDetails.applicant.mehraufwendungenFurDoppelteHaushaltsfuhrung }}</td>
                <td>{{ refundDetails.spouse.mehraufwendungenFurDoppelteHaushaltsfuhrung }}</td>
                <td>{{ refundDetails.applicant.mehraufwendungenFurDoppelteHaushaltsfuhrung + refundDetails.spouse.mehraufwendungenFurDoppelteHaushaltsfuhrung }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.UMZUGSKOSTEN' | translate }}</td>
                <td>{{ refundDetails.applicant.umzugskosten }}</td>
                <td>{{ refundDetails.spouse.umzugskosten }}</td>
                <td>{{ refundDetails.applicant.umzugskosten + refundDetails.spouse.umzugskosten }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.BEWERBUNGSKOSTEN' | translate }}</td>
                <td>{{ refundDetails.applicant.bewerbungskosten }}</td>
                <td>{{ refundDetails.spouse.bewerbungskosten }}</td>
                <td>{{ refundDetails.applicant.bewerbungskosten + refundDetails.spouse.bewerbungskosten }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.KOSTEN_DER_STEUERERKLARUNG' | translate }}</td>
                <td>{{ refundDetails.applicant.kosten_der_steuererklarung }}</td>
                <td>{{ refundDetails.spouse.kosten_der_steuererklarung }}</td>
                <td>{{ refundDetails.applicant.kosten_der_steuererklarung + refundDetails.spouse.kosten_der_steuererklarung }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.TELEKOMMUNIKATIONSKOSTEN' | translate }}</td>
                <td>{{ refundDetails.applicant.telekommunikationskosten }}</td>
                <td>{{ refundDetails.spouse.telekommunikationskosten }}</td>
                <td>{{ refundDetails.applicant.telekommunikationskosten + refundDetails.spouse.telekommunikationskosten }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.WINTERBESCHAFTIGUNGSUMLAGE' | translate }}</td>
                <td>{{ refundDetails.applicant.winterbeschaftigungsumlage }}</td>
                <td>{{ refundDetails.spouse.winterbeschaftigungsumlage }}</td>
                <td>{{ refundDetails.applicant.winterbeschaftigungsumlage + refundDetails.spouse.winterbeschaftigungsumlage }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.KONTOFUHRUNGSGEBUHREN' | translate }}</td>
                <td>{{ refundDetails.applicant.kontofuhrungsgebuhren }}</td>
                <td>{{ refundDetails.spouse.kontofuhrungsgebuhren }}</td>
                <td>{{ refundDetails.applicant.kontofuhrungsgebuhren + refundDetails.spouse.kontofuhrungsgebuhren }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.UBRIGE_WERBUNGSKOSTEN' | translate }}</td>
                <td>{{ refundDetails.applicant.ubrigeWerbungskosten }}</td>
                <td>{{ refundDetails.spouse.ubrigeWerbungskosten }}</td>
                <td>{{ refundDetails.applicant.ubrigeWerbungskosten + refundDetails.spouse.ubrigeWerbungskosten }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.SUMME_WERBUNGSKOSTEN' | translate }}</td>
                <td>{{ refundDetails.applicant.summeWerbungskosten }}</td>
                <td>{{ refundDetails.spouse.summeWerbungskosten }}</td>
                <td>{{ refundDetails.applicant.summeWerbungskosten + refundDetails.spouse.summeWerbungskosten }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.BESCHRANKT_ABZIEHBARE_SONDERAUSGABEN' | translate }}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.SUMME_DER_ALTERSVORSORGEAUFWENDUNGEN' | translate }}</td>
                <td>{{ refundDetails.applicant.summeDerAltersvorsorgeaufwendungen }}</td>
                <td>{{ refundDetails.spouse.summeDerAltersvorsorgeaufwendungen }}</td>
                <td>{{ refundDetails.applicant.summeDerAltersvorsorgeaufwendungen + refundDetails.spouse.summeDerAltersvorsorgeaufwendungen }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.BEITRAGE_ZUR_KRANKENVERSICHERUNG' | translate }}</td>
                <td>{{ refundDetails.applicant.beitrageZurKrankenversicherung }}</td>
                <td>{{ refundDetails.spouse.beitrageZurKrankenversicherung }}</td>
                <td>{{ refundDetails.applicant.beitrageZurKrankenversicherung + refundDetails.spouse.beitrageZurKrankenversicherung }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.BEITRAGE_ZUR_PFLEGEVERSICHERUNG' | translate }}</td>
                <td>{{ refundDetails.applicant.beitrageZurPflegeversicherung }}</td>
                <td>{{ refundDetails.spouse.beitrageZurPflegeversicherung }}</td>
                <td>{{ refundDetails.applicant.beitrageZurPflegeversicherung + refundDetails.spouse.beitrageZurPflegeversicherung }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.SONSTIGE_VORSORGEAUFWENDUNGEN' | translate }}</td>
                <td>{{ refundDetails.applicant.sonstigeVorsorgeaufwendungen }}</td>
                <td>{{ refundDetails.spouse.sonstigeVorsorgeaufwendungen }}</td>
                <ng-container *ngIf="isOverMax(); then overMax; else underMax"> </ng-container>
                <ng-template #overMax>
                  <td>{{ refundDetails.applicant.max_sonstige_vorsorgeaufwendungen }}</td>
                </ng-template>
                <ng-template #underMax>
                  <td>{{ refundDetails.applicant.sonstigeVorsorgeaufwendungen + refundDetails.spouse.sonstigeVorsorgeaufwendungen }}
                  </td>
                </ng-template>
              </tr>

              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.UNBESCHRANKT_ABZIEHBARE_SONDERAUSGABEN' | translate }}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.SPENDEN_MITGLIEDSBEITRAGE' | translate }}</td>
                <td>{{ refundDetails.applicant.spendenUndMitgliedsbeitrage }}</td>
                <td>-</td>
                <td>{{ refundDetails.applicant.spendenUndMitgliedsbeitrage }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.ZUWENDUNGEN_AN_POLITISCHE_PARTEIEN' | translate }}</td>
                <td>{{ refundDetails.applicant.ZuwendungenAnPolitischeParteien }}</td>
                <td>-</td>
                <td>{{ refundDetails.applicant.ZuwendungenAnPolitischeParteien }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.GEZAHLTE_KIRCHENSTEUER' | translate }}</td>
                <td>{{ refundDetails.applicant.gezahlteKirchensteuer }}</td>
                <td>{{ refundDetails.spouse.gezahlteKirchensteuer }}</td>
                <td>{{ refundDetails.applicant.gezahlteKirchensteuer + refundDetails.spouse.gezahlteKirchensteuer }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.DER_KINDERBETREUUNGSKOSTEN' | translate }}</td>
                <td></td>
                <td></td>
                <td>{{ refundDetails.children.kinderbetreuungskosten }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.DES_SCHULGELDES' | translate }}</td>
                <td></td>
                <td></td>
                <td>{{ refundDetails.children.schulgeldes }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.PAUSCHBETRAG' | translate }}</td>
                <td></td>
                <td></td>
                <td>{{ refundDetails.getSumPauschbetrag() }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.AUSBILDUNGSKOSTEN_NACH_ABS_ESTG' | translate }}</td>
                <td></td>
                <td></td>
                <td>{{ refundDetails.children.ausbildungskosten }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.FREIBETRAGE_FUR_KINDER' | translate }}</td>
                <td></td>
                <td></td>
                <td>{{ refundDetails.children.freibetrageFurKinder }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.ENTLASTUNGSBETRAG_FUR_ALLEINERZIEHENDE' | translate }}</td>
                <td></td>
                <td></td>
                <td>{{ refundDetails.children.entlastungsbetragFurAlleinerziehende }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.HINTERBLIEBENEN_PAUSCHBETRAG_KINDER' | translate }}</td>
                <td></td>
                <td></td>
                <td>{{ refundDetails.children.hinterbliebenenPauschbetragFurKinder }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.BEHINDERTEN_PAUSCHBETRAG_KINDER' | translate }}</td>
                <td></td>
                <td></td>
                <td>{{ refundDetails.children.behindertenPauschbetragFurKinder }}</td>
              </tr>
              <ng-container *ngIf="getBehindertenPauschbetrag()">
                <tr>
                  <td class="details-table__value-name">{{ 'REFUND_DETAILS.BEHINDERTEN_PAUSCHBETRAG' | translate }}</td>
                  <td></td>
                  <td></td>
                  <td>{{ refundDetails.behindertenPauschbetrag }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="refundDetails.pflegePauschbetrag as refundValue">
                <tr>
                  <td class="details-table__value-name">{{ 'REFUND_DETAILS.DISABILITY_CARE_PFLEGEGRAD' | translate }}</td>
                  <td></td>
                  <td></td>
                  <td>{{ refundValue }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="getGesundheitskosten()">
                <tr>
                  <td class="details-table__value-name">{{ 'REFUND_DETAILS.GESUNDHEITSKOSTEN' | translate }}</td>
                  <td></td>
                  <td></td>
                  <td>{{ refundDetails.gesundheitskosten }}</td>
                </tr>
              </ng-container>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.VERSTEUERN_PROGRESSIONSVORBEHALT_DARUNTER_AUSLANDISCHE_EINKUNFTE' | translate }}</td>
                <td></td>
                <td></td>
                <td>{{ refundDetails.zuVersteuernMitProgressionsvorbehalt }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.VERSTEUERNDES_EINKOMMEN' | translate }}</td>
                <td></td>
                <td></td>
                <td>{{ refundDetails.zuVersteuerndesEinkommen }}</td>
              </tr>
            </table>

            <table class="details-table">
              <tr class="details-table__header-row">
                <th class="details-table__green-header details-table__green-header--limited-space">{{ 'REFUND_DETAILS.BERECHNUNG_STEUER' | translate }}
                </th>
                <th class="details-table__muted-header"></th>
              </tr>

              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.DURSCHNITTSSTEUERSATZ_PROGRESSIONSVORBEHALT' | translate }}</td>
                <td>{{ refundDetails.steuersatz }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.GRENZSTEUERSATZ' | translate }}</td>
                <td>{{ refundDetails.grenzsteuersatz }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.EINKOMMENSTEUER' | translate }} </td>
                <td>{{ refundDetails.einkommensteuer }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.ERMASSIGUNG_GERINGFUGIGE_BESCHAFTIGUNGEN_PRIVATHAUSHALT' | translate }}</td>
                <td>{{ refundDetails.reductionForMiniJobs20Percent | mathRound }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.ERMASSIGUNG_SOZIALVERSICHERUNGSPFLICHTIGE_BESCHAFTIGUNGEN_PRIVATHAUSHALT_HAUSHALTSNAHE_DIENSTLEISTUNGEN' | translate }}</td>
                <td>{{ refundDetails.reductionForPrivateHousehold20Percent | mathRound }}
                </td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.ERMASSIGUNG_HANDWERKERLEISTUNGEN' | translate }}</td>
                <td>{{ refundDetails.reductionForCraftsman20Percent | mathRound }}
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.ERMASSIGUNG_ZUWENDUNGEN_POLITISCHE_PARTEIEN' | translate }}</td>
                <td>{{ refundDetails.applicant.ermabigungFurZuwendungenAnPolitischeParteien }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.FESTGESETZTE_EINKOMMENSTEUER' | translate }}</td>
                <td>{{ refundDetails.festgesetzteEinkommensteuer }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.FESTGESETZTER_SOLIDARITATSZUSCHLAG' | translate }}</td>
                <td>{{ refundDetails.festgesetzterSolidaritatszuschlag }}</td>
              </tr>
              <tr>
                <td class="details-table__value-name title_cell">{{ 'REFUND_DETAILS.FESTGESETZTE_KIRCHENSTEUERSTEUER' | translate }}</td>
                <td>{{ refundDetails.festgesetzteKirchensteuersteuer }}</td>
              </tr>
            </table>
          </div>

          <div class="subdesc">
            {{ 'REFUND_DETAILS.HINWEIS' | translate }}
          </div>
        </ng-container>
    </div>

    <div class="dialog-box__content hidden-lg hidden-md pt-0" *ngIf="isLoaded">
        <!-- alert-->
        <ng-container *ngIf="hasAbroadIncome()">
            <div class="deductions-info warning is_icon"
                *ngIf="!canApplyForTaxResidencyStatus() || showApplyForResidencyStatusNotification">
                <div class="deductions-info__wrap top">
                    <div class="deductions-info__toltip-wrap alert_wrap">
                        <img src="../../../assets/svg/error_icon.svg" class="alert_icon">
                    </div>
                    <div class="deductions-info__wrap-text">
                        <p class="deductions-info__text">
                            <strong>{{ 'ABROAD_INCOME.ATTENTION' | translate }}</strong>
                            {{ 'ABROAD_INCOME.ATTENTION_CONDITIONS' | translate }}
                            {{ 'ABROAD_INCOME.WONT_ADD_DEDUCTIONS' | translate }}
                            {{ 'ABROAD_INCOME.WONT_ADD_DEDUCTIONS_FEE' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- alert-->

        <ng-container *ngIf="!isLogged">
          <p class="font-size-20 mb-1">
            {{ 'REFUND_DETAILS.NOT_LOGGED.SUBDESC' | translate }}
          </p>
          <div class="details_table_wrap not_logged mb-1">
            <div class="details-table-container">
              <button
                class="btn btn--primary btn-open-modal"
                mat-dialog-close=""
                (click)="openRegisterModal()"
              >
                {{ 'REFUND_DETAILS.NOT_LOGGED.ENTER_INCOME' | translate }}
              </button>
              <table class="details-table first-table">
                <tr>
                  <th class="details-table__black-header" colspan="3">
                    <div class="p-1">
                      {{ 'REFUND_DETAILS.BERECHNUNG' | translate }} <span *ngIf="statement.fullYear">{{ 'REFUND_DETAILS.FUR' | translate }}
                      {{ statement.fullYear }}</span>
                      {{ 'REFUND_DETAILS.UBER_EINKOMMENSTEUER_SOLIDARITATSZUSCHLAG_KIRCHENSTEUER' | translate }}
                    </div>
                  </th>
                </tr>
                <tr class="details-table__header-row">
                  <th class="details-table__green-header bg-grey">{{ 'REFUND_DETAILS.EINKOMMENSTEUER' | translate }}<br>€</th>
                  <th class="details-table__green-header bg-grey">{{ 'REFUND_DETAILS.KIRCHENSTEUER' | translate }}<br>€</th>
                  <th class="details-table__green-header bg-grey">{{ 'REFUND_DETAILS.SOLIDARITATSZUSCHLAG' | translate }}<br>€</th>
                </tr>
                <tr>
                  <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.FESTGESETZTER_STEUERBETRAG' | translate }}</td>
                </tr>
                <tr>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                </tr>
                <tr>
                  <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.BEREITS_GETILGT_STPFL_PARTNER_A' | translate }}</td>
                </tr>
                <tr>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                </tr>
                <tr>
                  <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.BEREITS_GETILGT_PARTNER_B' | translate }}</td>
                </tr>
                <tr>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                </tr>
                <tr>
                  <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.VERBLEIBENDE_BETRAGE' | translate }}</td>
                </tr>
                <tr>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                  <td class="bg-grey--muted"></td>
                </tr>
                <tr>
                  <td class="details-table__green-header" colspan="3">{{ 'REFUND_DETAILS.INSGESAMT' | translate }}<br>€</td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                </tr>
              </table>
            </div>
          </div>
          <div>
            <span class="font-size-20 font-weight-bold">{{'REFUND_DETAILS.NOT_LOGGED.PREMIUM_PACKAGE.DESC' | translate}}</span>
            &nbsp;
            <span class="v-align-mid">
              <app-more-info-tooltip
                [showText]="false"
                [titleTooltip]="'PACKAGE_SELECTION2.HOW_DOES_IT_WORK'"
                [xlImgMobile]="true"
                [contentTemplateRef]="premiumPackageTooltipTemplate2"
              ></app-more-info-tooltip>
              <ng-template #premiumPackageTooltipTemplate2>
                <app-tooltip-template-package-info 
                  [isPremiumPackage]="true"
                  [isHowDoesItWork]="true">
                </app-tooltip-template-package-info>
              </ng-template>
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="isLogged">
          <div class="details_table_wrap">
            <table class="details-table first-table">
              <tr>
                <th class="details-table__black-header" colspan="3">
                  <div class="p-1">
                    {{ 'REFUND_DETAILS.BERECHNUNG' | translate }} <span *ngIf="statement.fullYear">{{ 'REFUND_DETAILS.FUR' | translate }}
                    {{ statement.fullYear }}</span>
                    {{ 'REFUND_DETAILS.UBER_EINKOMMENSTEUER_SOLIDARITATSZUSCHLAG_KIRCHENSTEUER' | translate }}
                  </div>
                </th>
              </tr>
              <tr class="details-table__header-row">
                <th class="details-table__green-header">{{ 'REFUND_DETAILS.EINKOMMENSTEUER' | translate }}<br>€</th>
                <th class="details-table__green-header">{{ 'REFUND_DETAILS.KIRCHENSTEUER' | translate }}<br>€</th>
                <th class="details-table__green-header">{{ 'REFUND_DETAILS.SOLIDARITATSZUSCHLAG' | translate }}<br>€</th>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.FESTGESETZTER_STEUERBETRAG' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.festgesetzteEinkommensteuer }}</td>
                <td>{{ refundDetails.festgesetzteKirchensteuersteuer }}</td>
                <td>{{ refundDetails.festgesetzterSolidaritatszuschlag }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.BEREITS_GETILGT_STPFL_PARTNER_A' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.bereitsGetilgtEinkommensteuer }}</td>
                <td>{{ refundDetails.applicant.bereitsGetilgtKirchenssteuer }}</td>
                <td>{{ refundDetails.applicant.bereitsGetilgtSolidaritatszuschlag }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.BEREITS_GETILGT_PARTNER_B' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.spouse.bereitsGetilgtEinkommensteuer }}</td>
                <td>{{ refundDetails.spouse.bereitsGetilgtKirchenssteuer }}</td>
                <td>{{ refundDetails.spouse.bereitsGetilgtSolidaritatszuschlag }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.VERBLEIBENDE_BETRAGE' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.verbleibendeBetrageEinkommensteuer }}</td>
                <td>{{ refundDetails.verbleibendeBetrageKirchensteuer }}</td>
                <td>{{ refundDetails.verbleibendeBetrageSolidaritatszuschlag }}</td>
              </tr>

              <tr>
                <td class="details-table__green-header" colspan="3">{{ 'REFUND_DETAILS.INSGESAMT' | translate }}<br>€</td>
              </tr>
              <tr>
                <td
                  colspan="3"
                  [class.text-primary]="refundDetails.insgesamt < 0"
                  [class.text-danger]="refundDetails.insgesamt > 0"
                >
                  {{ refundDetails.insgesamt < 0 ? '+' : '' }}{{ refundDetails.insgesamt * -1 }}
                </td>
              </tr>
            </table>

            <table class="details-table">
              <tr>
                <th class="details-table__black-header" colspan="3">{{ 'REFUND_DETAILS.BERECHNUNG_DES_VERSTEUERNDEN_EINKOMMENS' | translate }}</th>
              </tr>
              <tr class="details-table__header-row">
                <th class="details-table__green-header">{{ 'REFUND_DETAILS.STPFL_PARTNER_A' | translate }}<br>€</th>
                <th class="details-table__green-header">{{ 'REFUND_DETAILS.PARTNER_B' | translate }}<br>€</th>
                <th class="details-table__green-header">{{ 'REFUND_DETAILS.INSGESAMT' | translate }}<br>€</th>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.EINKUNFTE_AUS_NICHTSELBSTANDIGER_ARBEIT' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.einkunfteAusNichtselbstandigerArbeit }}</td>
                <td>{{ refundDetails.spouse.einkunfteAusNichtselbstandigerArbeit }}</td>
                <td>{{ refundDetails.applicant.einkunfteAusNichtselbstandigerArbeit + refundDetails.spouse.einkunfteAusNichtselbstandigerArbeit }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.EINKUNFTE_AUS_SELBSTANDIGER_ARBEIT' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.einkunfteAusSelbstandigerArbeit }}</td>
                <td>{{ refundDetails.spouse.einkunfteAusSelbstandigerArbeit }}</td>
                <td>{{ refundDetails.applicant.einkunfteAusSelbstandigerArbeit + refundDetails.spouse.einkunfteAusSelbstandigerArbeit }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.AB_BETRAG_NACH_ABS_UND_ESTG' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.abBetragNach46 }}</td>
                <td>{{ refundDetails.spouse.abBetragNach46 }}</td>
                <td>{{ refundDetails.applicant.abBetragNach46 + refundDetails.spouse.abBetragNach46 }}</td>
              </tr>

              <tr>
                <td class="details-table__green-header" colspan="3">{{ 'REFUND_DETAILS.WERBUNGSKOSTEN' | translate }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.ARBEITNEHMER_PAUSCHBETRAG' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.getArbeitnehmerPauschbetrag() }}</td>
                <td>{{ refundDetails.spouse.getArbeitnehmerPauschbetrag() }}</td>
                <td>{{ refundDetails.getSumArbeitnehmerPauschbetrag() }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.WEGE_ZWISCHEN_WOHNUNG_ERSTER_TATIGKEITSSTATTE_ENTFERNUNGSPAUSCHALE' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.entfernungspauschale }}</td>
                <td>{{ refundDetails.spouse.entfernungspauschale }}</td>
                <td>{{ refundDetails.applicant.entfernungspauschale + refundDetails.spouse.entfernungspauschale }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.AUFWENDUNGEN_FUR_OFFENTLICHE_VERKEHRSMITTEL' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.aufwendungenFurOffentlicheVerkehrsmittel }}</td>
                <td>{{ refundDetails.spouse.aufwendungenFurOffentlicheVerkehrsmittel }}</td>
                <td>{{ refundDetails.applicant.aufwendungenFurOffentlicheVerkehrsmittel + refundDetails.spouse.aufwendungenFurOffentlicheVerkehrsmittel }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.AUSWARTSTATIGKEIT' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.auswartstatigkeit }}</td>
                <td>{{ refundDetails.spouse.auswartstatigkeit }}</td>
                <td>{{ refundDetails.applicant.auswartstatigkeit + refundDetails.spouse.auswartstatigkeit }}</td>
              </tr>

              <tr *ngIf="statement && statement.fullYear >= 2020">
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.UBERNACHTUNGSPAUSHALE_FUR_BERUFSKRAFTFAHRER' | translate }}</td>
              </tr>
              <tr *ngIf="statement && statement.fullYear >= 2020">
                <td>{{ refundDetails.applicant.ubernachtungspaushaleFurBerufskraftfahrer }}</td>
                <td>{{ refundDetails.spouse.ubernachtungspaushaleFurBerufskraftfahrer }}</td>
                <td>
                  {{
                  refundDetails.applicant.ubernachtungspaushaleFurBerufskraftfahrer
                  + refundDetails.spouse.ubernachtungspaushaleFurBerufskraftfahrer
                  }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.BEITRAGE_BERUFSVERBANDEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.beitrageZuBerufsverbanden }}</td>
                <td>{{ refundDetails.spouse.beitrageZuBerufsverbanden }}</td>
                <td>{{ refundDetails.applicant.beitrageZuBerufsverbanden + refundDetails.spouse.beitrageZuBerufsverbanden }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.AUFWENDUNGEN_ARBEITSMITTEL' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.aufwendungenFurArbeitsmittel }}</td>
                <td>{{ refundDetails.spouse.aufwendungenFurArbeitsmittel }}</td>
                <td>{{ refundDetails.applicant.aufwendungenFurArbeitsmittel + refundDetails.spouse.aufwendungenFurArbeitsmittel }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.AUFWENDUNGEN_HAUSLICHES_ARBEITSZIMMER' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.aufwendungenFurEinHauslichesArbeitszimmer }}</td>
                <td>{{ refundDetails.spouse.aufwendungenFurEinHauslichesArbeitszimmer }}</td>
                <td>{{ refundDetails.applicant.aufwendungenFurEinHauslichesArbeitszimmer + refundDetails.spouse.aufwendungenFurEinHauslichesArbeitszimmer }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.FORTBILDUNGSKOSTEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.fortbildungskosten }}</td>
                <td>{{ refundDetails.spouse.fortbildungskosten }}</td>
                <td>{{ refundDetails.applicant.fortbildungskosten + refundDetails.spouse.fortbildungskosten }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.MEHRAUFWENDUNGEN_DOPPELTE_HAUSHALTSFUHRUNG' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.mehraufwendungenFurDoppelteHaushaltsfuhrung }}</td>
                <td>{{ refundDetails.spouse.mehraufwendungenFurDoppelteHaushaltsfuhrung }}</td>
                <td>{{ refundDetails.applicant.mehraufwendungenFurDoppelteHaushaltsfuhrung + refundDetails.spouse.mehraufwendungenFurDoppelteHaushaltsfuhrung }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.UMZUGSKOSTEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.umzugskosten }}</td>
                <td>{{ refundDetails.spouse.umzugskosten }}</td>
                <td>{{ refundDetails.applicant.umzugskosten + refundDetails.spouse.umzugskosten }}</td>
              </tr>

              <tr>
                <td colspan="3" class="details-table__value-name">{{ 'REFUND_DETAILS.BEWERBUNGSKOSTEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.bewerbungskosten }}</td>
                <td>{{ refundDetails.spouse.bewerbungskosten }}</td>
                <td>{{ refundDetails.applicant.bewerbungskosten + refundDetails.spouse.bewerbungskosten }}</td>
              </tr>
              <tr>
                <td colspan="3" class="details-table__value-name">{{ 'REFUND_DETAILS.KOSTEN_DER_STEUERERKLARUNG' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.kosten_der_steuererklarung }}</td>
                <td>{{ refundDetails.spouse.kosten_der_steuererklarung }}</td>
                <td>{{ refundDetails.applicant.kosten_der_steuererklarung + refundDetails.spouse.kosten_der_steuererklarung }}
                </td>
              </tr>
              <tr>
                <td colspan="3" class="details-table__value-name">{{ 'REFUND_DETAILS.TELEKOMMUNIKATIONSKOSTEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.telekommunikationskosten }}</td>
                <td>{{ refundDetails.spouse.telekommunikationskosten }}</td>
                <td>{{ refundDetails.applicant.telekommunikationskosten + refundDetails.spouse.telekommunikationskosten }}
                </td>
              </tr>
              <tr>
                <td colspan="3" class="details-table__value-name">{{ 'REFUND_DETAILS.WINTERBESCHAFTIGUNGSUMLAGE' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.winterbeschaftigungsumlage }}</td>
                <td>{{ refundDetails.spouse.winterbeschaftigungsumlage }}</td>
                <td>{{ refundDetails.applicant.winterbeschaftigungsumlage + refundDetails.spouse.winterbeschaftigungsumlage }}
                </td>
              </tr>
              <tr>
                <td colspan="3" class="details-table__value-name">{{ 'REFUND_DETAILS.KONTOFUHRUNGSGEBUHREN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.kontofuhrungsgebuhren }}</td>
                <td>{{ refundDetails.spouse.kontofuhrungsgebuhren }}</td>
                <td>{{ refundDetails.applicant.kontofuhrungsgebuhren + refundDetails.spouse.kontofuhrungsgebuhren }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.UBRIGE_WERBUNGSKOSTEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.ubrigeWerbungskosten }}</td>
                <td>{{ refundDetails.spouse.ubrigeWerbungskosten }}</td>
                <td>{{ refundDetails.applicant.ubrigeWerbungskosten + refundDetails.spouse.ubrigeWerbungskosten }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.SUMME_WERBUNGSKOSTEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.summeWerbungskosten }}</td>
                <td>{{ refundDetails.spouse.summeWerbungskosten }}</td>
                <td>{{ refundDetails.applicant.summeWerbungskosten + refundDetails.spouse.summeWerbungskosten }}</td>
              </tr>

              <tr>
                <td class="details-table__green-header" colspan="3">{{ 'REFUND_DETAILS.BESCHRANKT_ABZIEHBARE_SONDERAUSGABEN' | translate }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.SUMME_DER_ALTERSVORSORGEAUFWENDUNGEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.summeDerAltersvorsorgeaufwendungen }}</td>
                <td>{{ refundDetails.spouse.summeDerAltersvorsorgeaufwendungen }}</td>
                <td>{{ refundDetails.applicant.summeDerAltersvorsorgeaufwendungen + refundDetails.spouse.summeDerAltersvorsorgeaufwendungen }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.BEITRAGE_ZUR_KRANKENVERSICHERUNG' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.beitrageZurKrankenversicherung }}</td>
                <td>{{ refundDetails.spouse.beitrageZurKrankenversicherung }}</td>
                <td>{{ refundDetails.applicant.beitrageZurKrankenversicherung + refundDetails.spouse.beitrageZurKrankenversicherung }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.BEITRAGE_ZUR_PFLEGEVERSICHERUNG' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.beitrageZurPflegeversicherung }}</td>
                <td>{{ refundDetails.spouse.beitrageZurPflegeversicherung }}</td>
                <td>{{ refundDetails.applicant.beitrageZurPflegeversicherung + refundDetails.spouse.beitrageZurPflegeversicherung }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.SONSTIGE_VORSORGEAUFWENDUNGEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.sonstigeVorsorgeaufwendungen }}</td>
                <td>{{ refundDetails.spouse.sonstigeVorsorgeaufwendungen }}</td>
                <td>{{ refundDetails.applicant.sonstigeVorsorgeaufwendungen + refundDetails.spouse.sonstigeVorsorgeaufwendungen }}
                </td>
              </tr>

              <tr>
                <td class="details-table__green-header" colspan="3">{{ 'REFUND_DETAILS.UNBESCHRANKT_ABZIEHBARE_SONDERAUSGABEN' | translate }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.SPENDEN_MITGLIEDSBEITRAGE' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.spendenUndMitgliedsbeitrage }}</td>
                <td>-</td>
                <td>{{ refundDetails.applicant.spendenUndMitgliedsbeitrage }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.ZUWENDUNGEN_AN_POLITISCHE_PARTEIEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.ZuwendungenAnPolitischeParteien }}</td>
                <td>-</td>
                <td>{{ refundDetails.applicant.ZuwendungenAnPolitischeParteien }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.GEZAHLTE_KIRCHENSTEUER' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.gezahlteKirchensteuer }}</td>
                <td>{{ refundDetails.spouse.gezahlteKirchensteuer }}</td>
                <td>{{ refundDetails.applicant.gezahlteKirchensteuer + refundDetails.spouse.gezahlteKirchensteuer }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.DER_KINDERBETREUUNGSKOSTEN' | translate }}</td>
              </tr>
              <tr>
                <td colspan="3">{{ refundDetails.children.kinderbetreuungskosten }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.DES_SCHULGELDES' | translate }}</td>
              </tr>
              <tr>
                <td colspan="3">{{ refundDetails.children.schulgeldes }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.PAUSCHBETRAG' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.getPauschbetrag() }}</td>
                <td>{{ refundDetails.spouse.getPauschbetrag() }}</td>
                <td>{{ refundDetails.getSumPauschbetrag() }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.AUSBILDUNGSKOSTEN_NACH_ABS_ESTG' | translate }}</td>
              </tr>
              <tr>
                <td colspan="3">{{ refundDetails.children.ausbildungskosten }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.FREIBETRAGE_FUR_KINDER' | translate }}</td>
              </tr>
              <tr>
                <td colspan="3">{{ refundDetails.children.freibetrageFurKinder }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.ENTLASTUNGSBETRAG_FUR_ALLEINERZIEHENDE' | translate }}</td>
              </tr>
              <tr>
                <td colspan="3">{{ refundDetails.children.entlastungsbetragFurAlleinerziehende }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.HINTERBLIEBENEN_PAUSCHBETRAG_KINDER' | translate }}</td>
              </tr>
              <tr>
                <td colspan="3">{{ refundDetails.children.hinterbliebenenPauschbetragFurKinder }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.BEHINDERTEN_PAUSCHBETRAG_KINDER' | translate }}</td>
              </tr>
              <tr>
                <td colspan="3">{{ refundDetails.children.behindertenPauschbetragFurKinder }}</td>
              </tr>

              <!-- <tr>
                  <td class="details-table__value-name" colspan="3">Behinderten-Pauschbetrag</td>
              </tr>
              <tr>
                  <td colspan="3">{{ refundDetails.behindertenPauschbetrag }}</td>
              </tr>

              <tr>
                  <td class="details-table__value-name" colspan="3">Gesundheitskosten</td>
              </tr>
              <tr>
                  <td colspan="3">{{ refundDetails.gesundheitskosten }}</td>
              </tr> -->

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.VERSTEUERN_PROGRESSIONSVORBEHALT_DARUNTER_AUSLANDISCHE_EINKUNFTE' | translate }}</td>
              </tr>
              <tr>
                <td colspan="3">{{ refundDetails.zuVersteuernMitProgressionsvorbehalt }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name" colspan="3">{{ 'REFUND_DETAILS.VERSTEUERNDES_EINKOMMEN' | translate }}</td>
              </tr>
              <tr>
                <td colspan="3">{{ refundDetails.zuVersteuerndesEinkommen }}</td>
              </tr>
            </table>

            <table class="details-table">
              <tr>
                <th class="details-table__black-header">{{ 'REFUND_DETAILS.BERECHNUNG_STEUER' | translate }}</th>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.DURSCHNITTSSTEUERSATZ_PROGRESSIONSVORBEHALT' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.steuersatz }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.GRENZSTEUERSATZ' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.grenzsteuersatz }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.EINKOMMENSTEUER' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.einkommensteuer }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.ERMASSIGUNG_GERINGFUGIGE_BESCHAFTIGUNGEN_PRIVATHAUSHALT' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.beschaftigungenImPrivathaushalt + refundDetails.spouse.beschaftigungenImPrivathaushalt }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.ERMASSIGUNG_SOZIALVERSICHERUNGSPFLICHTIGE_BESCHAFTIGUNGEN_PRIVATHAUSHALT_HAUSHALTSNAHE_DIENSTLEISTUNGEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.beschaftigungenImPrivathaushaltUndHaushaltsnaheDienstleistungen + refundDetails.spouse.beschaftigungenImPrivathaushaltUndHaushaltsnaheDienstleistungen }}
                </td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.ERMASSIGUNG_HANDWERKERLEISTUNGEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.handwerkerleistungen + refundDetails.spouse.handwerkerleistungen }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.ERMASSIGUNG_ZUWENDUNGEN_POLITISCHE_PARTEIEN' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.applicant.ermabigungFurZuwendungenAnPolitischeParteien }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.FESTGESETZTE_EINKOMMENSTEUER' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.festgesetzteEinkommensteuer }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.FESTGESETZTER_SOLIDARITATSZUSCHLAG' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.festgesetzterSolidaritatszuschlag }}</td>
              </tr>

              <tr>
                <td class="details-table__value-name">{{ 'REFUND_DETAILS.FESTGESETZTE_KIRCHENSTEUERSTEUER' | translate }}</td>
              </tr>
              <tr>
                <td>{{ refundDetails.festgesetzteKirchensteuersteuer }}</td>
              </tr>
            </table>
          </div>

          <div class="subdesc">
            {{'REFUND_DIALOG.DESCRIPTION' | translate}}
          </div>
        </ng-container>
    </div>
</div>
