import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { Store } from "@ngrx/store";
import { State, getUser } from "../../reducers";
import { SubscribingComponent, SnackbarConfig } from "../../common";
import { takeUntil } from "rxjs/operators";
import { Statement } from "../../statements/statement";
import { StatementService } from "app/statements/statement.service";
import { CustomLabels } from "../labels-edit/CustomLabels";
import { User } from "app/users";

@Component({
  selector: "app-labels-edit-batches",
  templateUrl: "./labels-edit-batches.component.html",
  styleUrls: ["./labels-edit-batches.component.scss"]
})
export class LabelsEditBatchesComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  @Input() labelList: CustomLabels[] = [];
  public isSent = false;
  public isError = false;

  public selectedLabel: CustomLabels = null;
  private intervalId: any = null;

  constructor(
    private store: Store<State>,
    private statmentService: StatementService
  ) {
    super();
  }

  ngOnInit() {
    if (this.labelList && this.statement.customLabels) {
      this.selectedLabel = this.labelList.filter((kk) => kk.id == this.statement.customLabels[0])[0];
    }
  }

  public showLabels(): boolean {
    if (this.isSent) return false;
    if (this.labelList.length == 0) return false;
    return true;
  }

  public setStatementLabel(label: CustomLabels) {
    this.statmentService.saveLabel(this.statement.id, this.selectedLabel ? this.selectedLabel.id : 0).subscribe(
      (response: any) => {},
      (error) => {
        console.log("error", error);
      }
    );
  }
}
