<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
          <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content">
        <div class="payment-cancellation" *ngIf="language === LANGUAGES.de">
            <h1 class="payment-cancellation__title payment-cancellation__title--main is-bold">Widerrufsbelehrung für Verbraucher bei Fernabsatzverträgen</h1>

            <div class="payment-cancellation__stage">
                <h2 class="payment-cancellation__title is-bold">Widerrufsbelehrung</h2>

                <h3 class="payment-cancellation__subtitle is-bold">Widerrufsrecht</h3>
                <br>
                <p class="payment-cancellation__content">
                    Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.<br><br>
                    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.<br><br>
                    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns <span class="is-italic">(taxando GmbH, Kurfürstendamm 226, 10719 Berlin, Telefon: +49 30 551 698 94, E-Mail: office@taxando.de)</span> mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.<br><br>
                    Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                </p>

                <h3 class="payment-cancellation__subtitle is-bold">Folgen des Widerrufs</h3>
                <br>
                <p class="payment-cancellation__content">
                    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.<br>
                    <br>
                    Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
                </p>

                <h3 class="payment-cancellation__subtitle is-bold">- Ende der Widerrufsbelehrung -</h3>
                <h3 class="payment-cancellation__subtitle is-bold">Besondere Hinweise zum vorzeitigen Erlöschen des Widerrufsrechts</h3>
                <p class="payment-cancellation__content">
                    Ihr Widerrufsrecht erlischt vorzeitig, wenn wir die Dienstleistung vollständig erbracht haben und mit der Ausführung der Dienstleistung erst begonnen haben, nachdem Sie dazu Ihre ausdrückliche Zustimmung gegeben haben und Sie gleichzeitig Ihre Kenntnis davon bestätigt haben, dass Sie Ihr Widerrufsrecht bei vollständiger Vertragserfüllung durch uns verlieren.
                </p>

                <div class="payment-cancellation__separator"></div>

                <h3 class="payment-cancellation__subtitle is-bold">Muster-Widerrufsformular</h3>
                <p class="payment-cancellation__content">
                    <br>
                    <span class="is-italic">(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</span><br>
                    <br>
                    - An taxando GmbH, Kurfürstendamm 226, 10719 Berlin, E-Mail: office@taxando.de:<br><br>
                    - Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung (*)<br>
                    - Bestellt am (*)/erhalten am (*)<br>
                    - Name des/der Verbraucher(s)<br>
                    - Anschrift des/der Verbraucher(s)<br>
                    - Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier) - Datum<br>
                    <br>
                    _______________<br>
                    <span class="is-italic">(*) Unzutreffendes streichen.</span>
                </p>
            </div>
        </div>

        <div class="payment-cancellation" *ngIf="language === LANGUAGES.en">
            <h1 class="payment-cancellation__title payment-cancellation__title--main is-bold">Widerrufsbelehrung für Verbraucher bei Fernabsatzverträgen</h1>

            <div class="payment-cancellation__stage">
                <h2 class="payment-cancellation__title is-bold">Widerrufsbelehrung</h2>

                <h3 class="payment-cancellation__subtitle is-bold">Widerrufsrecht</h3>
                <br>
                <p class="payment-cancellation__content">
                    Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.<br><br>
                    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.<br><br>
                    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns <span class="is-italic">(taxando GmbH, Kurfürstendamm 226, 10719 Berlin, Telefon: +49 30 551 698 94, E-Mail: office@taxando.de)</span> mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.<br><br>
                    Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                </p>

                <h3 class="payment-cancellation__subtitle is-bold">Folgen des Widerrufs</h3>
                <br>
                <p class="payment-cancellation__content">
                    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.<br>
                    <br>
                    Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
                </p>

                <h3 class="payment-cancellation__subtitle is-bold">- Ende der Widerrufsbelehrung -</h3>
                <h3 class="payment-cancellation__subtitle is-bold">Besondere Hinweise zum vorzeitigen Erlöschen des Widerrufsrechts</h3>
                <p class="payment-cancellation__content">
                    Ihr Widerrufsrecht erlischt vorzeitig, wenn wir die Dienstleistung vollständig erbracht haben und mit der Ausführung der Dienstleistung erst begonnen haben, nachdem Sie dazu Ihre ausdrückliche Zustimmung gegeben haben und Sie gleichzeitig Ihre Kenntnis davon bestätigt haben, dass Sie Ihr Widerrufsrecht bei vollständiger Vertragserfüllung durch uns verlieren.
                </p>

                <div class="payment-cancellation__separator"></div>

                <h3 class="payment-cancellation__subtitle is-bold">Muster-Widerrufsformular</h3>
                <p class="payment-cancellation__content">
                    <br>
                    <span class="is-italic">(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</span><br>
                    <br>
                    - An taxando GmbH, Kurfürstendamm 226, 10719 Berlin, E-Mail: office@taxando.de:<br><br>
                    - Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung (*)<br>
                    - Bestellt am (*)/erhalten am (*)<br>
                    - Name des/der Verbraucher(s)<br>
                    - Anschrift des/der Verbraucher(s)<br>
                    - Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier) - Datum<br>
                    <br>
                    _______________<br>
                    <span class="is-italic">(*) Unzutreffendes streichen.</span>
                </p>
            </div>
        </div>

        <div class="payment-cancellation" *ngIf="language === LANGUAGES.pl">
                       <h1 class="payment-cancellation__title payment-cancellation__title--main is-bold">Widerrufsbelehrung für Verbraucher bei Fernabsatzverträgen</h1>

            <div class="payment-cancellation__stage">
                <h2 class="payment-cancellation__title is-bold">Widerrufsbelehrung</h2>

                <h3 class="payment-cancellation__subtitle is-bold">Widerrufsrecht</h3>
                <br>
                <p class="payment-cancellation__content">
                    Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.<br><br>
                    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.<br><br>
                    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns <span class="is-italic">(taxando GmbH, Kurfürstendamm 226, 10719 Berlin, Telefon: +49 30 551 698 94, E-Mail: office@taxando.de)</span> mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.<br><br>
                    Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                </p>

                <h3 class="payment-cancellation__subtitle is-bold">Folgen des Widerrufs</h3>
                <br>
                <p class="payment-cancellation__content">
                    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.<br>
                    <br>
                    Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
                </p>

                <h3 class="payment-cancellation__subtitle is-bold">- Ende der Widerrufsbelehrung -</h3>
                <h3 class="payment-cancellation__subtitle is-bold">Besondere Hinweise zum vorzeitigen Erlöschen des Widerrufsrechts</h3>
                <p class="payment-cancellation__content">
                    Ihr Widerrufsrecht erlischt vorzeitig, wenn wir die Dienstleistung vollständig erbracht haben und mit der Ausführung der Dienstleistung erst begonnen haben, nachdem Sie dazu Ihre ausdrückliche Zustimmung gegeben haben und Sie gleichzeitig Ihre Kenntnis davon bestätigt haben, dass Sie Ihr Widerrufsrecht bei vollständiger Vertragserfüllung durch uns verlieren.
                </p>

                <div class="payment-cancellation__separator"></div>

                <h3 class="payment-cancellation__subtitle is-bold">Muster-Widerrufsformular</h3>
                <p class="payment-cancellation__content">
                    <br>
                    <span class="is-italic">(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</span><br>
                    <br>
                    - An taxando GmbH, Kurfürstendamm 226, 10719 Berlin, E-Mail: office@taxando.de:<br><br>
                    - Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung (*)<br>
                    - Bestellt am (*)/erhalten am (*)<br>
                    - Name des/der Verbraucher(s)<br>
                    - Anschrift des/der Verbraucher(s)<br>
                    - Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier) - Datum<br>
                    <br>
                    _______________<br>
                    <span class="is-italic">(*) Unzutreffendes streichen.</span>
                </p>
            </div>
        </div>
    </div>
</div>
