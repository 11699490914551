import {Component, Input, Output, EventEmitter} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-cancel-dialog',
  templateUrl: './confirm-cancel-dialog.component.html',
})
export class ConfirmCancelDialogComponent {
  @Input() id?: string;
  @Input() title: string;
  @Input() description: string;
  @Input() closeOnSubmit = true;
  @Input() isSubmitButtonLoading = false;
  @Input() isCancelButtonShown = true;
  @Input() isSubmitButtonShown = true;
  @Input() cancelButtonTitle = 'COMMON.CANCEL';
  @Input() submitButtonTitle = 'COMMON.CONFIRM';
  @Output() submitActionEmitter = new EventEmitter();
  @Output() cancelActionEmitter = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<ConfirmCancelDialogComponent>
  ) { }

  public submitAction(event) {
    this.isSubmitButtonLoading = true;
    this.submitActionEmitter.emit(event);
    if (this.closeOnSubmit) {
      this.dialogRef.close(true);
    }
  }

  public cancelAction(event) {
    this.cancelActionEmitter.emit(event);
    this.dialogRef.close();
  }
}
