<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close="" tabindex="-1">
          <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content pt-0 mt-n2_5">
        <app-workflow-steps
          (stepChange)="onChange()"
          [isLogged]="isLogged"
          [statement]="statement"
          [isBusiness]="isBusiness"
          [workFlowController]="workFlowController"
        ></app-workflow-steps>
    </div>
</div>
