import { Component, Input } from '@angular/core';
import { Statement } from '../statement';

@Component({
  selector: 'app-vollmacht-info-edit',
  templateUrl: './vollmacht-info-edit.component.html',
  styleUrls: ['./vollmacht-info-edit.component.scss']
})
export class VollmachtInfoEditComponent {
  @Input() statement: Statement;
  @Input() errors;

  constructor() { }
}
