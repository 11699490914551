<div class="smartbanner smartbanner" #content>
  <button
    (click)="exit()"
    class="smartbanner__exit"
    [attr.aria-label]="smartAppBannerService.settings.closeLabel"
  ></button>
  <div
    class="smartbanner__icon"
    [ngStyle]="{ 'background-image': 'url(' + icon + ')' }"
  ></div>
  <div class="smartbanner__info">
    <div class="smartbanner__info--title">
      {{ smartAppBannerService.settings.title }}
    </div>
    <div class="smartbanner__info--author">
      {{ smartAppBannerService.settings.author }}
    </div>
    <div class="smartbanner__info--rating" *ngIf="!smartAppBannerService.settings.hideRating">
      <ng-container
        *ngFor="let s of [0, 1, 2, 3, 4]; let i = index"
      >
        <ng-container *ngIf="reviewAverage <= i; else regular">
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="orange"
              d="M1201 1004l306-297-422-62-189-382-189 382-422 62 306 297-73 421 378-199 377 199zm527-357q0 22-26 48l-363 354 86 500q1 7 1 20 0 50-41 50-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
            />
          </svg>
        </ng-container>
        <ng-template #regular>
          <svg
            width="16px"
            height="16px"
            class="star-rating"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="orange"
              d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
            />
          </svg>
        </ng-template>
      </ng-container>
    </div>
    <div class="smartbanner__info--price">
      {{ smartAppBannerService.settings.price }}{{ priceSuffix }}
    </div>
  </div>
  <a
    href="{{ buttonUrl }}"
    target="_blank"
    (click)="view()"
    class="smartbanner__button"
    rel="noopener"
    [attr.aria-label]="smartAppBannerService.settings.buttonLabel"
  >
    {{ smartAppBannerService.settings.buttonLabel }}
  </a>
</div>
