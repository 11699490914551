import {Component, OnInit} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  language = 'DE';
  LANGUAGES = {
    'pl': 'PL',
    'en': 'EN',
    'de': 'DE'
  };

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.language = this.LANGUAGES[this.translate.currentLang] || this.language;
  }

}
