export class BasePagePath {
  public static get prefix(): string {
    return '';
  }

  public static componentUrl(path: string, ...args): string {
    const regex = /:[a-zA-Z0-9]+/;
    args.forEach(arg => {
      path = path.replace(regex, arg);
    });

    return path;
  }

  public static componentRegex(path: string, ...args): RegExp {
    const regexSearch = /:[a-zA-Z0-9]+/g;
    const regexReplace = /:[a-zA-Z0-9]+/;
    let regexResultString = path;
    const matches = regexResultString.match(regexSearch) || [];

    matches.forEach((match, index) => {
      const replaceVal = args[index] || args[0] || this.defaultPathParamRegex;

      regexResultString = regexResultString.replace(regexReplace, replaceVal);
    });

    return new RegExp('/' + regexResultString + '\$');
  }

  public static path(): string {
    return '';
  }

  public static regex(...args): RegExp {
    return this.componentRegex(this.path(), ...args);
  }

  public static url(...args): string {
    return this.componentUrl(this.path(), ...args);
  }

  public static fullUrl(...args): string {
    return this.prefix + this.url(...args);
  }

  public static get defaultPathParamRegex(): string {
    return '\\d+';
  }
}
