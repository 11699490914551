import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { PersonalInfoSpouseComponentPath } from 'app/core/workflow/page-path/statement-page-path/personal-info-spouse-component.path';

export class WorkflowPersonalInfoSpouseService extends WorkflowStepsBaseService {
  public get isRequired(): boolean {
    return this.hasSpouseAndFilledTogether;
  }

  public get isVisible(): boolean {
    return this.isRequired;
  }

  public get title(): string {
    return 'ASIDE_BAR.PERSONAL_INFO';
  }

  public get isDone(): boolean {
    return this.statement && this.statement.spouse
      && this.statement.spouse.stagePersonalInfo
      && !!this.statement.spouse.firstName
      && !!this.statement.spouse.lastName
      && !!this.statement.spouse.birthDate
      && !!this.statement.spouse.street
      && !!this.statement.spouse.houseNumberWithSupplement
      && !!this.statement.spouse.postalCode
      && !!this.statement.spouse.city;
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.isRequired && this.statement.stagePersonalInfo;
  }

  public get urlRegex(): RegExp {
    return PersonalInfoSpouseComponentPath.regex();
  }

  public get url(): string {
    return PersonalInfoSpouseComponentPath.url(this.getStatementId, this.getSpouseId);
  }
}
