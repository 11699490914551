import { WorkflowDeductionApplicantSubBaseService } from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-applicant-sub-base.service';

export class WorkflowDeductionInfoWorkAndOfficeService extends WorkflowDeductionApplicantSubBaseService {
  public get title(): string {
    return 'ASIDE_BAR.APPLICANT_DEDUCTION_SUBLIST.WORK_AND_OFFICE';
  }

  public get subStepNumber(): number {
    return WorkflowDeductionInfoWorkAndOfficeService._subStepNumber;
  }

  public static get _subStepNumber(): number {
    return 1;
  }

  public get isFakeStep(): boolean {
    return !this.isRequired;
  }

  public get checkCanGo(): boolean {
    return super.checkCanGo && this.isRequired;
  }

  public get isRequired(): boolean {
    return super.isRequired && this.applicantHasEmployers;
  }
}
