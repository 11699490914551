import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { Statement } from "../statement";
import { StatementService } from "app/statements/statement.service";
import { SnackbarConfig, SubscribingComponent } from "app/common";
import { SelectsDataInterface } from "../selects-data";
import { getSelectsData, State } from "app/reducers";
import { Store } from "@ngrx/store";
import { RefundDetails } from "../refund-details";
import { PackageOptions } from "../package-options";
import { SHOW_SNACKBAR } from "app/reducers/actions/snackbar";
import { ToolsBarButtonInterface } from "../../shared/components/tools-bar/tools-bar.component";
import { WorkflowControllerService } from "app/core/workflow/workflow-controller/workflow-controller.service";
import { SummaryStatementCategories } from "app/common/confirm-tax-return/ctr-categories";
import { combineLatest, zip } from "rxjs";
import { SocialIncomeTypes } from "app/modules/statement/models/additional-income/social-income.types";
import { FreelanceJobTypes } from "app/modules/statement/models/additional-income/freelance-job.types";

@Component({
  selector: "app-confirm-tax-return",
  templateUrl: "./confirm-tax-return.component.html",
  styleUrls: ["../../common/confirm-tax-return/ctr-base.component.scss"]
})
export class ConfirmTaxReturnComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  @Input() isBusiness = false;
  public summaryStatementCategories: SummaryStatementCategories;
  public summaryStatementCategoriesSpouse: SummaryStatementCategories;
  public reportError = false;
  public addAdditionalComment = false;
  public additionalCommentMsg = "";
  public incomeSummary: any = "0.00";
  public dataSelects: SelectsDataInterface;
  public refundDetails: RefundDetails;
  public taxBracket: string;
  public additionalIncomeTypes = SocialIncomeTypes;
  public buttons: ToolsBarButtonInterface[] = [
    {
      type: "proceed",
      label: "COMMON.CONFIRM",
      action: this.proceed.bind(this)
    }
  ];
  private loading = false;
  private _isInitialized = false;
  private loadingStatementData = true;
  private loadingSelectsData = true;
  private statementId: number;

  public freelanceJobTypes: FreelanceJobTypes;

  constructor(
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private store: Store<State>,
    public workFlowController: WorkflowControllerService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.route.params, this.store.select(getSelectsData)])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([params, selectsData]) => {
        if (params && selectsData) {
          this.dataSelects = selectsData;
          this.loadingSelectsData = false;

          this.statementId = parseInt(params["statementId"], 10);
          this.getStatementData();
        }
      });
  }

  public get isInitialized(): boolean {
    return (
      !!this.statement &&
      this._isInitialized &&
      !this.loadingSelectsData &&
      !!this.summaryStatementCategories &&
      !!this.summaryStatementCategoriesSpouse
    );
  }

  public get isLoading(): boolean {
    return this.loadingStatementData || this.loading || this.loadingSelectsData;
  }

  public handleAdditionalCommentClick(domElement: HTMLElement): void {
    setTimeout(() => {
      domElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 50);

    this.addAdditionalComment = !this.addAdditionalComment;
  }

  public handleReportError(keyChanged, oldValue, newValue): void {
    if (!this.summaryStatementCategories || !this.summaryStatementCategoriesSpouse) {
      return;
    }
    let reportError = false;

    if (keyChanged === "reportError" && newValue) {
      reportError = true;
    }

    if (!reportError) {
      reportError = this.summaryStatementCategories.hasReportError;
    }

    if (!reportError) {
      reportError = this.summaryStatementCategoriesSpouse.hasReportError;
    }

    if (reportError) {
      this.buttons = [
        {
          type: "danger",
          label: "COMMON.REPORT_ERRORS",
          action: this.proceed.bind(this)
        }
      ];
    } else {
      this.buttons = [
        {
          type: "proceed",
          label: "COMMON.CONFIRM",
          action: this.proceed.bind(this)
        }
      ];
    }

    this.reportError = reportError;
  }

  public absoluteValue(number: number): number {
    return Math.abs(number);
  }

  private getStatementData(): void {
    zip(this.statementService.getStatement(this.statementId), this.statementService.getRefundDetails(this.statementId))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: ([responseStatement, responseRefundDetails]) => {
          this.statement = responseStatement;

          this.refundDetails = responseRefundDetails;

          this.summaryStatementCategories = new SummaryStatementCategories(
            this.handleReportError.bind(this),
            this.refundDetails,
            this.statement,
            this.statement
          );
          this.summaryStatementCategoriesSpouse = new SummaryStatementCategories(
            this.handleReportError.bind(this),
            this.refundDetails,
            this.statement,
            this.statement.spouse
          );

          this.workFlowController.init(this.statement);
          if (!this.statement.isForClientApproval || this.statement.serviceType !== PackageOptions.tax_adviser) {
            this.workFlowController.goToTheDashboard();
          }

          this.loadingStatementData = false;
          this._isInitialized = true;
        },
        error: () => {
          this.router.navigate(["/new"]);
        }
      });
  }

  private updateStatementAndGoToNextStep(): void {
    const data = this.summaryStatementCategories.categoryPropertiesList;
    const dataSpouse = this.summaryStatementCategoriesSpouse.categoryPropertiesList;

    this.loading = true;
    this.statementService
      .confirmStatementOrReportErrors(
        this.statement.id,
        this.statement.confirmStatementToResponse(
          data,
          dataSpouse,
          this.addAdditionalComment ? this.additionalCommentMsg : ""
        )
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (r: { success: boolean }) => {
          if (r.success) {
            this.goToNextStep();
          } else {
            this.loading = false;
            this.store.dispatch({
              type: SHOW_SNACKBAR,
              payload: new SnackbarConfig("COMMON.GLOBAL_ERROR", "ERROR")
            });
          }
        },
        error: (error: Error) => {
          console.warn(error, "ERROR");
          this.loading = false;
          this.store.dispatch({
            type: SHOW_SNACKBAR,
            payload: new SnackbarConfig("COMMON.GLOBAL_ERROR", "ERROR")
          });
        }
      });
  }

  private goToNextStep(): void {
    this.workFlowController.goToTheDashboard();
  }

  private proceed(): void {
    this.updateStatementAndGoToNextStep();
  }
}
