<div class="step-container columns is-desktop">
    <app-aside-bar [isBusiness]="true"></app-aside-bar>
    <div class="client-details">
        <div class="title">{{ 'CLIENT_DETAILS.TITLE' | translate }}</div>
        <div class="description">
            <div>
                <span>{{ 'CLIENT_DETAILS.DESCRIPTION_FIRST' | translate }}</span>
                <span class="is-bold"> {{ 'COMMON.EDIT' | translate }}</span>
                <span>{{ 'CLIENT_DETAILS.DESCRIPTION_SECOND' | translate }}</span>
            </div>
            <div>
                <span>{{ 'CLIENT_DETAILS.DESCRIPTION_THIRD' | translate }}</span>
                <span class="is-bold"> {{ 'COMMON.PROCEED' | translate }}</span>
            </div>
        </div>
        <div class="client-details__content">
            <div class="client-details__batch">
                <div class="client-details__batch-text">
                    <span>{{ 'COMMON.BATCH_FILE' | translate }}</span>
                    <button class="btn btn--info btn--small" [disabled]="isStatementCreating"
                        (click)="createStatement()"
                        [attr.data-cy]="'btnAddStatementToBatch'"
                    >
                      {{ 'COMMON.ADD_STATEMENT' | translate }}
                    </button>
                </div>
                <div class="client-details__batch-content">
                    <app-accordion [title]="batchCode" [titleFrame]="true" [transparentBg]="true" [noPadding]="true">
                        <app-list-view-b2b [statements]="statements" [hidePdf]="true" [showEditButton]="true"
                            (editButton)="editButton($event)" (importStatement)="importStatement($event)"
                            [hideInvoice]="true" [hideEditing]="true" [hideRemoving]="true" [hideDecision]="true">
                        </app-list-view-b2b>
                        <div class="client-details__batch-empty" *ngIf="!statements?.length">
                            {{ 'COMMON.BATCH_EMPTY' | translate }}</div>
                    </app-accordion>
                </div>
            </div>
        </div>
    </div>
    <app-tools-bar [buttons]="buttons"></app-tools-bar>
</div>
<div style="display: none;"> <input type="file" accept="application/json/*" #ericField (change)="uploadFile()"></div>
