import { finalize, takeUntil } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Store } from "@ngrx/store";
import { getUserType, State, getUser } from "app/reducers";
import { SubscribingComponent, SnackbarConfig, saveFile, MAX_NUMBER_OF_OPENED_STATEMENT } from "app/common";
import { SHOW_SNACKBAR } from "app/reducers/actions/snackbar";
import {
  StatementService,
  StatementsResponse,
  BatchesPaginatedResponse,
  StatementsPaginatedResponse
} from "app/statements/statement.service";
import { Statement } from "app/statements";
import { PaymentService } from "app/payment/payment.service";
import { RemoveStatementDialogComponent } from "app/common/remove-statement-dialog/remove-statement-dialog.component";
import { DownloadFileDialogComponent } from "app/common/decision-dialog/download-file-dialog.component";
import { RemoveBatchDialogComponent } from "app/common/remove-batch-dialog/remove-batch-dialog.component";
import { User } from "app/users";
import { CustomLabels } from "app/common/labels-edit/CustomLabels";
import { Employer } from "app/statements/employer";
import { UntypedFormControl } from "@angular/forms";
import { PackageOptions } from "app/statements/package-options";
import { B2bTaxCardComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.tax-card-component.path";
import { TaxCardComponentPath } from "app/core/workflow/page-path/statement-page-path/tax-card-component.path";
import { TaxYearInterface } from "app/statements/tax-year.interface";
import { ConfirmTaxReturnComponentPath } from "app/core/workflow/page-path/statement-page-path/confirm-tax-return-component.path";
import { StatementManagementService } from "app/statements/statement-management.service";
import { StatementResponse } from "app/modules/statement/models/statement/statement.response.interface";
import { StatementStatuses } from "app/modules/statement/models/statement/statement.statuses";
import { FilterStatements } from "app/modules/statement/models/statement/filter-statements.model";
import { combineLatest } from "rxjs";
import { UserType } from "app/users/user-type";
import { DeviceRecognizerHelper } from "app/core/helpers/device-recognizer.helper";
import { DOWNLOAD_FILE_TYPE } from "app/statements/file-decision-types";
import { TranslateService } from "@ngx-translate/core";

enum ViewType {
  List,
  Card
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent extends SubscribingComponent implements OnInit {
  paginatedStatements: any;
  statements: Statement[] = [];
  paginatedStatementsList: Statement[] = [];
  rawStatements: Statement[] = [];
  viewTypeEnum = ViewType;
  viewType: ViewType = ViewType.Card;
  fetchingStatements = false;
  isBusiness = false;
  isTaxAdviser = false;
  batchRemoving = false;
  batches: any;
  rawBatches: any;
  public packageOptions = PackageOptions;
  public statementStatuses = StatementStatuses;
  public isMaster = false;
  public labelList: CustomLabels[] = [];
  public filterButtonDisabled = false;
  public bcFilterYear = 0;
  public bcFilterYearId = 0;
  statement: Statement = new Statement();
  public unfinishedStatements: Statement[] = [];

  public yearsList: TaxYearInterface[];
  public statementInProgressWarning = false;
  public showStatementInProggressWarning = true;

  // Pagination works only for business clients for now (batches).
  page: number;
  queryParamsPage: number;
  pageCount: number;

  private numberOfOpenedStatement: UntypedFormControl = new UntypedFormControl();
  private filterActive = false;
  private filterData: FilterStatements = new FilterStatements();

  constructor(
    private statementService: StatementService,
    private statementManagementService: StatementManagementService,
    private paymentService: PaymentService,
    private dialog: MatDialog,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    super();

    this.numberOfOpenedStatement.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onValueChanges);

    if (sessionStorage.getItem("personalInfoOnLogin")) {
      sessionStorage.removeItem("personalInfoOnLogin");
    }
    if (localStorage.getItem("statement")) {
      localStorage.removeItem("statement");
    }
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams, this.store.select(getUserType)])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([params, queryParams, userType]) => {
        if (params && queryParams && userType) {
          if (params["year"]) {
            this.bcFilterYear = Number(params["year"]);
          }
          if (params["yearid"]) {
            this.bcFilterYearId = Number(params["yearid"]);
          }

          this.isBusiness = userType === UserType.business;
          this.isTaxAdviser = userType === UserType.tax_adviser;

          this.page = Number(queryParams["page"]) || 1;
          this.queryParamsPage = this.page;
          this.filter(this.filterData);
        }
      });
  }

  public editStatement(_statement: Statement): void {
    if (_statement.isForClientApproval && _statement.isPremiumPackage) {
      this.router.navigate([ConfirmTaxReturnComponentPath.fullUrl(_statement.id)]);
      return;
    }

    if (_statement.isPaid) {
      this.router.navigate([TaxCardComponentPath.fullUrl(_statement.id)]);
      return;
    }

    this.router.navigate(["dashboard"]);
  }

  public showAllStatements() {
    this.router.navigate(["dashboard"]);
  }

  createStatementManually() {
    this.statement.fullYear = this.bcFilterYear;
    this.statement.year = this.bcFilterYearId;

    this.statementManagementService.createOrAssignStatement(
      this.statement,
      this.ngUnsubscribe,
      () => {},
      (employers?: Employer, statement?: Statement) => {
        if (statement) {
          this.statement = statement;
        }
        if (this.isBusiness) {
          this.router.navigate([B2bTaxCardComponentPath.fullUrl(this.statement.id, 0)]);
        } else {
          this.router.navigate([TaxCardComponentPath.fullUrl(this.statement.id)]);
        }
      }
    );
  }

  getStatements() {
    this.fetchingStatements = true;
    this.statementService
      .getStatements()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.fetchingStatements = false))
      )
      .subscribe((response: StatementsResponse) => {
        this.rawStatements = response.statements;
        this.statements = response.statements;
      });
  }

  public getPaginatedStatements() {
    this.fetchingStatements = true;
    if (this.bcFilterYear > 0) {
      this.statementService
        .getPaginatedStatementsForYear(this.page, this.bcFilterYear)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => (this.fetchingStatements = false))
        )
        .subscribe((response: any) => {
          this.onGetPaginatedStatements(response);
        });
    } else {
      this.statementService
        .getPaginatedStatements(this.page)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => (this.fetchingStatements = false))
        )
        .subscribe((response: StatementsPaginatedResponse) => {
          this.onGetPaginatedStatements(response);
        });
    }
  }

  public newStatement() {
    if (this.isBusiness || !this.paginatedStatementsList) {
      this.router.navigate(["/nev"]);
    }

    if (this.paginatedStatementsList.length === 0) {
      this.router.navigate(["/nev"]);
    } else {
      this.getAllOpenedStatement(true);
    }
  }

  getBatches() {
    this.filterButtonDisabled = true;
    this.statementService
      .getBatches(this.page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response: BatchesPaginatedResponse) => {
          this.pageCount = response.total_pages;
          this.batches = response.results;
          this.getLabbelsIfMaster();
          this.batches.map((batch) => {
            batch.statements = batch.statements.map(Statement.fromResponse, false);
            return batch.statements;
          });
        },
        complete: () => (this.filterButtonDisabled = false)
      });
  }

  getFilteredBatches() {
    this.filterButtonDisabled = true;

    this.statementService
      .filterBatches(this.filterData.payload())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response: BatchesPaginatedResponse) => {
          this.pageCount = response.total_pages;
          this.batches = [];
          this.batches = response.results;
          this.rawBatches = response.results;
          if (this.batches) {
            this.batches.map((batch) => {
              if (batch.statements) {
                batch.statements = batch.statements.map((el) => Statement.fromResponse(el, false));
                return batch.statements;
              }
            });
          }
        },
        complete: () => (this.filterButtonDisabled = false)
      });
  }

  public filter(filterData: FilterStatements) {
    this.filterData = filterData;

    if (this.queryParamsPage !== this.page) {
      this.changePage(this.page);
    }

    if (this.isBusiness) {
      if (filterData.isEmpty) {
        this.getBatches();
      } else {
        this.filterData.page = 1;
        this.getFilteredBatches();
      }
    } else {
      if (filterData.isEmpty) {
        this.getPaginatedStatements();
      } else {
        this.filterData.page = 1;
        this.filterStatement();
      }
    }
  }

  changeToCardView() {
    this.viewType = ViewType.Card;
  }

  changeToListView() {
    this.viewType = ViewType.List;
  }

  downloadPdf(statement: Statement) {
    statement.downloadingPdf = true;
    if (DeviceRecognizerHelper.isAndroidWebview() || DeviceRecognizerHelper.isIosWebview()) {
      this.statementService.downloadDecisionPDF(statement.id, DOWNLOAD_FILE_TYPE.STATEMENT).subscribe({
        next: (response: any) => {
          console.log(response);
          const link = document.createElement("a");
          link.href = response.redirect_url;
          link.target = "_self";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: (error: any) => {
          console.error("Error occurred:", error);
          this.showPDFError();
        }
      });
    } else {
      this.statementService
        .downloadStatementPdf(statement.id)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => (statement.downloadingPdf = false))
        )
        .subscribe((file: Blob) => {
          saveFile(file, `statement-${statement.id}.pdf`);
        }, this.showPDFError.bind(this));
    }
  }

  downloadInvoice(statement: Statement) {
    //TODO: Remove in the future if DownloadFileComponent will work correctly on b2c and b2b
    statement.downloadingPdf = true;
    this.paymentService
      .downloadInvoice(statement.payment)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (statement.downloadingPdf = false))
      )
      .subscribe((file: Blob) => saveFile(file, `invoice-${statement.id}.pdf`));
  }

  showDownloadDialog(statement: Statement) {
    //tax-3030, downlaod all files in one modal
    const dialogRef = this.dialog.open(DownloadFileDialogComponent);
    dialogRef.componentInstance.statement = statement;
  }

  deleteStatement({ statement, listIndex }: { statement: Statement; listIndex: number }) {
    const dialogRef = this.dialog.open(RemoveStatementDialogComponent);
    dialogRef.componentInstance.statement = statement;
    dialogRef.componentInstance.onSuccessCallback = () => {
      this.paginatedStatementsList.splice(listIndex, 1);

      const value = this.numberOfOpenedStatement.value - 1;
      this.numberOfOpenedStatement.setValue(value);
    };
  }

  deleteBatchStatement({ statement, listIndex }: { statement: Statement; listIndex: number }, batchIndex) {
    const dialogRef = this.dialog.open(RemoveStatementDialogComponent);
    dialogRef.componentInstance.statement = statement;
    dialogRef.componentInstance.onSuccessCallback = () => {
      this.batches[batchIndex].statements.splice(listIndex, 1);
    };
  }

  editBatch(index) {
    const batchId = this.batches[index].id;
    let urlPath = "";
    if (this.batches[index].statements.length) {
      urlPath = this.batches[index].statements[0].isPaid ? "thank-you" : "client-details";
    } else {
      urlPath = "client-details";
    }
    this.router.navigate([`new/batch/${batchId}/${urlPath}`]);
  }

  editStatementTaxAdviser(index) {
    const employerId =
      this.paginatedStatementsList[index].employers && this.paginatedStatementsList[index].employers.length
        ? this.paginatedStatementsList[index].employers[0].id
        : 0;
    let urlPath = "";
    if (this.isBusiness) {
      urlPath = this.paginatedStatementsList[index].isPaid ? `employer/${employerId}` : "dashboard";
    } else {
      urlPath = this.paginatedStatementsList[index].isPaid ? `b2c/tax-card` : "dashboard";
    }
    const url = `new/statement/${this.paginatedStatementsList[index].id}/${urlPath}`;
    this.router.navigate([url]);
  }

  removeBatch(index) {
    const batchId = this.batches[index].id;
    const dialogRef = this.dialog.open(RemoveBatchDialogComponent);
    dialogRef.componentInstance.batchId = batchId;
    dialogRef.componentInstance.onSuccessCallback = () => {
      this.batches.splice(index, 1);
      this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig("SNACKBAR.BATCH_DELETED") });
    };
    dialogRef.componentInstance.onFailureCallback = () => {
      this.store.dispatch({
        type: SHOW_SNACKBAR,
        payload: new SnackbarConfig("SNACKBAR.BATCH_DELETION_ERROR", "ERROR")
      });
    };
  }

  hideEditButton(index) {
    if (this.batches[index].statements.length) {
      return this.batches[index].statements[0].isSent();
    }
    return false;
  }

  hideEditButtonTaxAdviser(index) {
    if (this.paginatedStatementsList.length) {
      const statement = this.paginatedStatementsList[index];
      return statement.isSent();
    }
    return false;
  }

  hideRemoveButton(index) {
    if (this.batches[index].statements.length) {
      const statement = this.batches[index].statements[0];
      return statement.isSent() || statement.isPaid;
    }
    return false;
  }

  hideRemoveButtonTaxAdviser(index) {
    if (this.paginatedStatementsList.length) {
      const statement = this.paginatedStatementsList[index];
      return statement.isSent() || statement.isPaid;
    }
    return false;
  }

  public cloneStatement(statement: Statement) {
    this.statementService
      .cloneStatement(statement.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.isBusiness ? this.getBatches() : this.getPaginatedStatements();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  showPDFError() {
    this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig("SNACKBAR.PDF_NOT_AVAILABLE", "ERROR") });
  }

  changePage(page: number) {
    this.router.navigate(["."], { relativeTo: this.route, queryParams: { page } });
    window.scrollTo(0, 0);
  }

  public expandUnfinishedStatements(_item: Statement) {
    _item.isOpenDesc = !_item.isOpenDesc;
  }

  private onValueChanges = (value: number) => {
    if (value !== undefined && value >= MAX_NUMBER_OF_OPENED_STATEMENT) {
      this.statementInProgressWarning = true;
      this.showStatementInProggressWarning = true;
    } else if (value < MAX_NUMBER_OF_OPENED_STATEMENT) {
      this.statementInProgressWarning = false;
      this.showStatementInProggressWarning = false;
    }
  };

  private getStatementsInProgress() {
    this.unfinishedStatements = [];

    this.statementService
      .getStatementInProgress()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any[]) => {
        response.forEach((item: StatementResponse) => {
          this.unfinishedStatements.push(Statement.fromResponse(item, false));
        });
      });
  }

  private getAllOpenedStatement(_startNewStatement = false) {
    if (this.isBusiness || this.isTaxAdviser) {
      return;
    }

    this.statementService
      .getAllOpenedStatement()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        let numberOfOpenedStatement = 0;

        if (response.statements_count) {
          this.numberOfOpenedStatement.setValue(Number(response.statements_count));
          numberOfOpenedStatement = Number(response.statements_count);
        } else {
          this.numberOfOpenedStatement.setValue(0);
        }

        if (numberOfOpenedStatement >= MAX_NUMBER_OF_OPENED_STATEMENT) {
          this.statementInProgressWarning = true;
          this.showStatementInProggressWarning = true;
        } else {
          if (_startNewStatement) {
            this.router.navigate(["/new"]);
          }
        }
      });
  }

  private filterStatement() {
    this.filterButtonDisabled = true;

    this.statementService
      .filterStatementsTaxAdviser(this.filterData.payload())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response: StatementsPaginatedResponse) => {
          this.onGetPaginatedStatements(response);
        },
        complete: () => (this.filterButtonDisabled = false)
      });
  }

  private getLabbelsIfMaster() {
    this.labelList = [];
    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          if (user.isMasterAccount || user.isSubaccount) {
            this.isMaster = true;
          }
          if ((this.statements || this.batches) && this.isMaster) {
            this.getLabelsList();
          } else {
            this.labelList = [];
          }
        }
      });
  }

  private getLabelsList() {
    this.labelList = [];
    this.statementService
      .getAllLabels()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response: any) => {
          response.forEach((item) => {
            const label = new CustomLabels();
            label.map(item);
            this.labelList.push(label);
          });
        },
        (err) => {
          this.labelList = [];
        }
      );
  }

  private setYearList() {
    this.yearsList = [];

    if (this.paginatedStatementsList.length === 0) {
      return;
    }
    this.paginatedStatementsList.forEach((item) => {
      if (this.yearsList.length === 0) {
        this.yearsList.push({ year: item.fullYear, qty: 1, active: false });
      } else {
        const year = this.yearsList.find((kk) => kk.year === item.fullYear);
        if (year) {
          year.qty = year.qty + 1;
        } else {
          this.yearsList.push({ year: item.fullYear, qty: 1, active: false });
        }
      }
    });

    if (this.yearsList.length === 0) {
      return;
    }

    this.yearsList.sort((n1, n2) => {
      if (n1.year > n2.year) {
        return -1;
      } else if (n1.year < n2.year) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  private onGetPaginatedStatements(response: StatementsPaginatedResponse): void {
    this.pageCount = response.total_pages;
    this.rawStatements = response.results.map((el) => Statement.fromResponse(el, false));
    this.paginatedStatementsList = response.results.map((el) => Statement.fromResponse(el, false));
    this.setYearList();
    this.getStatementsInProgress();
    this.getAllOpenedStatement();
  }
}
