<div class="email-activation">
  <div class="container">
    <div class="email-activation__content"  *ngIf="!isSuccess">
      <div class="email-activation__failed">{{ errors }}</div>
    </div>
    <div class="email-activation__content" *ngIf="isSuccess">
      <div class="email-activation__success">{{ 'EMAIL_VERIFICATION.SUCCESS' | translate }}</div>
    </div>
  </div>
</div>

<app-footer></app-footer>

