import { Component } from '@angular/core';
import { CtrBaseComponent } from '../ctr-base.component';

@Component({
    ...CtrBaseComponent.metaData,
    selector: 'app-ctr-cryptocurrency',
    templateUrl: './ctr-cryptocurrency.component.html',
    styleUrls: ['../ctr-base.component.scss'],
})
export class CtrCryptocurrencyComponent extends CtrBaseComponent {
}
