
import {finalize, takeUntil} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {User} from '../../users/user';
import {SubscribingComponent} from '../subscribing-component';
import {UserDataService} from '../../users/user-data.service';

@Component({
  selector: 'app-receive-newsletter-dialog',
  templateUrl: './receive-newsletter-dialog.component.html',
  styleUrls: ['./receive-newsletter-dialog.component.scss']
})
export class ReceiveNewsletterDialogComponent extends SubscribingComponent implements OnInit {
  @Input() user: User;
  isSaving = false;

  constructor(private userDataService: UserDataService,
              private dialogRef: MatDialogRef<ReceiveNewsletterDialogComponent>) {
    super();
  }

  ngOnInit() {
  }

  accept() {
    this.user.receiveNewsletters = true;
    this.decideNewsletter();
  }

  decline() {
    this.user.receiveNewsletters = false;
    this.decideNewsletter();
  }

  decideNewsletter() {
    this.isSaving = true;
    this.userDataService.decideNewsletter(this.user.receiveNewsletters).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.isSaving = false),)
      .subscribe(() => this.dialogRef.close());
  }
}
