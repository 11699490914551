import { Component, OnInit, ViewChildren, QueryList, ElementRef, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  AuthenticationDialogFlag,
  LoginDialogComponent
} from "app/shared/standalone/components/login-dialog/login-dialog.component";
import { CookieService } from "ngx-cookie-service";
import { DateAdapter } from "@angular/material/core";
import { TranslateService } from "@ngx-translate/core";
import { FirebaseEventsService } from "app/common/firebase-events.service";
import { SelectsData, SelectsDataInterface } from "app/statements";
import { Store } from "@ngrx/store";
import { getSelectsData, State } from "app/reducers";
import { NumberFormatHelper } from "app/core/helpers/number-format.helper";
import { LanguagesConfig } from "app/configs/languages.config";
import { PageRecognizerService } from "app/core/services/page-recognizer.service";
import { SubscribingComponent } from "app/common";
import { LanguageSelectService } from "app/core/services/language-select.service";

@Component({
  selector: "app-welcome-modal",
  templateUrl: "./welcome-modal.component.html",
  styleUrls: ["./welcome-modal.component.scss"]
})
export class WelcomeModalComponent extends SubscribingComponent implements OnInit, AfterViewInit {
  public language = false;
  public step1 = false;
  public step2 = false;
  public step3 = false;
  public step4 = false;
  public loadingSelectsData = true;
  public numberFormatHelper = NumberFormatHelper;
  public selectsData: SelectsDataInterface = new SelectsData();
  public languagesList = LanguagesConfig.languagesList;

  @ViewChildren("navButton") navButton: QueryList<ElementRef>;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private cookieService: CookieService,
    private firebaseEventsService: FirebaseEventsService,
    private store: Store<State>,
    private pageRecognizerService: PageRecognizerService,
    private readonly languageSelectService: LanguageSelectService
  ) {
    super();

    pageRecognizerService.init(this.ngUnsubscribe);

    if (
      !pageRecognizerService.isActivationPage &&
      localStorage.getItem("siteVisited") !== "1" &&
      !pageRecognizerService.isPrivacyPolicyPage
    ) {
      if (!languageSelectService.isLanguageSelected()) {
        this.language = true;
      } else {
        this.step1 = true;
      }
    }
  }

  ngOnInit() {
    this.store.select(getSelectsData).subscribe((selectsData) => {
      if (selectsData) {
        this.selectsData = selectsData;
        this.loadingSelectsData = false;
      }
    });

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }

  ngAfterViewInit() {
    if (this.language || this.step1 || this.step2 || this.step3 || this.step4) this.checkButtonsTextWidth();

    this.checkButtonsTextWidth();
  }

  openLoginDialog(flag?: AuthenticationDialogFlag) {
    this.dialog.open(LoginDialogComponent, {
      backdropClass: "backdropBackgroundWhite",
      panelClass: ["login_dialog_wrap", "new-dialog-wrap"],
      data: { loginFlag: flag }
    });
  }

  public setLang(lang: string) {
    localStorage.removeItem("siteVisited");
    if (this.languageSelectService.compareLanguageCodes(lang)) {
      this.language = false;
      this.step1 = true;
      return;
    }

    this.languageSelectService.useLanguage(lang, {
      reload: true,
      firebaseLog: true,
      setIsSelected: true
    });
  }

  public nextStep(_current: boolean, _next: boolean) {
    this.checkButtonsTextWidth();
  }

  public checkButtonsTextWidth() {
    if (window.innerWidth < 767) {
      const fitText = window["fitText"];
      const checking = setInterval(() => {
        const navButton = document.querySelectorAll(".navButton");

        if (navButton.length) {
          navButton.forEach((itemEl) => {
            if (itemEl) {
              const itemElWidth = itemEl.clientWidth;
              const itemElChild = itemEl.querySelector("span");

              if (itemElChild) {
                const itemElChildWidth = itemElChild.offsetWidth;
                const itemElChildHeight = itemElChild.offsetHeight;

                if (itemElChildWidth != 0 && itemElChildHeight != 0) {
                  if (itemElChildWidth > itemElWidth || itemElChildHeight > 30) {
                    fitText(itemEl, 0.9, { maxFontSize: 18 });
                  }
                  itemEl.classList.add("active");

                  clearInterval(checking);
                }
              } else {
                clearInterval(checking);
              }
            } else {
              clearInterval(checking);
            }
          });
        } else {
          clearInterval(checking);
        }
      }, 50);
    }
  }
  public closeDialog() {
    this.language = false;
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    localStorage.setItem("siteVisited", "1");
    this.languageSelectService.setLanguageIsSelected();
  }

  public executeFirebaseEvent = (event: string, params: any) => this.firebaseEventsService.logEvent(event, params);
}
