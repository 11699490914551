<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
          <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content">
        <div *ngIf="isSpouse">
            <div class="title">{{ 'REMOVE_STATEMENT_SPOUSE.TITLE' | translate }}</div>
            <div class="description">
                {{ 'REMOVE_STATEMENT_SPOUSE.DESCRIPTION' | translate }}
            </div>
        </div>
        <div *ngIf="!isSpouse">
            <div class="title">{{ 'REMOVE_STATEMENT.TITLE' | translate }}</div>
            <div class="description">
                {{ 'REMOVE_STATEMENT.DESCRIPTION' | translate }}
            </div>
        </div>
        <div class="alert-box" *ngIf="errors[0]">{{ errors[0] }}</div>
        <div class="remove-statement-actions">
            <button class="btn btn--secondary btn--longer" mat-dialog-close>{{ 'COMMON.CANCEL' | translate }}</button>
            <button
              class="btn btn--primary btn--longer"
              (click)="removeStatement()"
              [disabled]="statement.deleting"
              [attr.data-cy]="'btnDeleteStatementConfirm'"
            >
              {{ 'COMMON.REMOVE' | translate }}
            </button>
        </div>
    </div>
</div>
