<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
          <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content">
        <div class="title">{{ 'THANK_YOU.EWR' | translate }}</div>
        <div class="ewr-attachment">
            <div class="ewr-attachment__stage">
                <label class="file-upload-button">
                    <input type="file" accept="application/pdf" (change)="onEWRSelect()" #ewrField>
                    <div class="btn btn--info btn--block" *ngIf="!sending">
                        {{ 'THANK_YOU.EWR_UPLOAD' | translate }}
                    </div>
                    <button class="btn btn--info btn--block" disabled *ngIf="sending">
                        {{ 'THANK_YOU.EWR_UPLOAD' | translate }}
                    </button>
                </label>
            </div>
            <div class="ewr-attachment__stage ewr-attachment__cert form-content" *ngIf="ewr">
                <span class="ewr-attachment__ewr-title">{{ 'SEND_WITH_CERT.CERT' | translate }}:</span>
                <span class="ewr-attachment__ewr-name">{{ ewr.name }}</span>
            </div>
            <div class="ewr-attachment__stage" *ngIf="errors[0]">
                <div class="alert-box">{{ errors[0] }}</div>
            </div>
            <div class="ewr-attachment__stage">
                <button class="btn btn--primary btn--block" (click)="onSubmit()" [disabled]="sending">
                    {{ 'THANK_YOU.EWR_ATTACH' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
