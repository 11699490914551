import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { PackageOptions } from 'app/statements/package-options';
import { ThankYouComponentPath } from 'app/core/workflow/page-path/statement-page-path/thank-you-component.path';

export class WorkflowThankYouService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return !!this.statement;
  }

  public get isRequired(): boolean {
    return false;
  }

  public get checkCanGo(): boolean {
    console.log(this.statement.status);
    if (
      !this.isBusiness
      && this.statement
      && this.statement.isPaid
      && !this.statement.isSent()
      && !this.statement.isForClientApproval
      && !this.statement.isApproved
      && !this.statement.isReportedErrors
    ) {
      switch (this.statement.serviceType) {
        case PackageOptions.paid:
          return true;
        case PackageOptions.tax_adviser:
          console.log(this.statement.hasVerifiedIdentity, this.statement.vollmachtSigned, this.statement.identificationNumberIsMissing)
          return !this.statement.hasVerifiedIdentity && !this.statement.identificationNumberIsMissing && this.statement.vollmachtSigned;
      }
    }
    return false;
  }

  public get urlRegex(): RegExp {
    return ThankYouComponentPath.regex();
  }

  public get url(): string {
    return ThankYouComponentPath.url(this.getStatementId);
  }
}
