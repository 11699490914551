<div class="form-multiinput">
  <div class="form-multiinput__container" [class.error]="errors && errors.length">
    <ng-container *ngFor="let m of innerModel; let n = index;">
      <div
        class="form-multiinput__content"
        [class.active]="(tmpValue[n] || activeInput[n]) && !(errors && errors.length)"
      >
        <input
          class="form-multiinput__input-{{n}}"
          maxlength="1"
          type="text"
          [(ngModel)]="tmpValue[n]"
          pattern="[0-9]"
          [placeholder]="placeholder | translate"
          (keyup)="onKeyUp($event)"
          (focus)="onFocus(n)"
          [class.form-multiinput--error]="errors && errors.length"
          [id]="currentInputId + '-' + n"
          [attr.name]="n"
          [attr.autocomplete]="n === 0 ? 'one-time-code' : null"
          [attr.inputmode]="'numeric'"
          [class.center]="centerTxtInput"
        />
      </div>
    </ng-container>
  </div>
  <div class="form-multiinput__error" *ngIf="errors">{{ errors[0] }}</div>
</div>
