import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import {DeductionInputValues} from 'app/shared/modules/statement-deduction-base/models/deduction';
import { B2bVerificationComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.verification-component.path';
import { VerificationComponentPath } from 'app/core/workflow/page-path/statement-page-path/verification-component.path';
import { BankDetailComponentPath } from 'app/core/workflow/page-path/statement-page-path/bank-detail-component.path';
import { PaymentCheckoutComponentPath } from 'app/core/workflow/page-path/statement-page-path/payment-checkout-component.path';

export class WorkflowSubmitService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    if (this.isBusiness) {
      return this.statement && this.isBankAccountPassed;
    }

    return this.statement
      && this.statement.stageVerification
      && !this.statement.isUpdated()
      && !this.statement.isProcessing()
      && !this.statement.isCreated();
  }

  public get isVisible(): boolean {
    return true;
  }

  public get title(): string {
    return 'ASIDE_BAR.VERIFY';
  }

  public get checkCanGo(): boolean {
    if (!this.blockedDefaultWorkflow && this.statement) {
      if (!this.isBusiness && !this.isBankAccountPassed) {
        return false;
      }

      if (this.statement.stageTaxReliefs === DeductionInputValues.SKIP_OPTION) {
        return true;
      }

      if (this.hasChildren) {
        return this.statement.stageDeductionInfoChildren;
      }

      if (this.hasSpouseAndFilledTogether) {
        return this.statement.spouse.stageDeductionInfo;
      }

      return this.statement.stageDeductionInfo;
    }

    return false;
  }

  public get isActiveOnStepsBar(): boolean {
    const locationHref = window.location.href;

    return locationHref.search(BankDetailComponentPath.regex()) !== -1
      || locationHref.search(VerificationComponentPath.regex()) !== -1
      || locationHref.search(B2bVerificationComponentPath.regex()) !== -1
      || locationHref.search(PaymentCheckoutComponentPath.regex()) !== -1;
  }

  public get urlRegex(): RegExp {
    if (this.isBusiness) {
      return B2bVerificationComponentPath.regex();
    }

    return VerificationComponentPath.regex();
  }

  public get url(): string {
    if (this.isBusiness) {
      return B2bVerificationComponentPath.url(this.getStatementId);
    }

    return VerificationComponentPath.url(this.getStatementId);
  }


  public get stepClass(): string {
    return this.classMarginTop;
  }
}
