import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import {WorkflowAbroadIncomeService} from 'app/core/workflow/workflow-steps/applicant/workflow-abroad-income.service';
import { SelectsData, Statement } from 'app/statements';
import { getSelectsData, getUser, State } from 'app/reducers';
import { Injectable } from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import {User} from 'app/users';
import {SubscribingComponent} from 'app/common';
import {Store} from '@ngrx/store';
import {WorkflowTaxCardService} from 'app/core/workflow/workflow-steps/applicant/workflow-tax-card.service';
import {WorkflowAdditionalIncomeService} from 'app/core/workflow/workflow-steps/applicant/workflow-additional-income.service';
import {WorkflowAdditionalOptionsService} from 'app/core/workflow/workflow-steps/applicant/workflow-additional-options.service';
import {WorkflowProfileService} from 'app/core/workflow/workflow-steps/applicant/workflow-profile.service';
import {WorkflowPersonalInfoService} from 'app/core/workflow/workflow-steps/applicant/workflow-personal-info.service';
import {Router} from '@angular/router';
import {WorkflowPersonalInfoSpouseService} from 'app/core/workflow/workflow-steps/spouse/workflow-personal-info-spouse.service';
import {WorkflowTaxCardSpouseService} from 'app/core/workflow/workflow-steps/spouse/workflow-tax-card-spouse.service';
import {WorkflowAdditionalIncomeSpouseService} from 'app/core/workflow/workflow-steps/spouse/workflow-additional-income-spouse.service';
import {WorkflowPersonalInfoChildService} from 'app/core/workflow/workflow-steps/child/workflow-personal-info-child.service';
import {WorkflowPackageSelectionService} from 'app/core/workflow/workflow-steps/other/workflow-package-selection.service';
import {WorkflowPhoneVerificationService} from 'app/core/workflow/workflow-steps/other/workflow-phone-verification.service';
import {WorkflowDeductionSkipService} from 'app/core/workflow/workflow-steps/deduction/workflow-deduction-skip.service';
import {WorkflowDeductionInfoService} from 'app/core/workflow/workflow-steps/deduction/applicant/workflow-deduction-info.service';
import {WorkflowDeductionInfoSpouseService} from 'app/core/workflow/workflow-steps/deduction/spouse/workflow-deduction-info-spouse.service';
import {WorkflowDeductionInfoChildService} from 'app/core/workflow/workflow-steps/deduction/child/workflow-deduction-info-child.service';
import {WorkflowBankDetailService} from 'app/core/workflow/workflow-steps/other/workflow-bank-detail.service';
import {WorkflowSubmitService} from 'app/core/workflow/workflow-steps/other/workflow-submit.service';
import {WorkflowPaymentCheckoutService} from 'app/core/workflow/workflow-steps/other/workflow-payment-checkout.service';
import {WorkflowThankYouService} from 'app/core/workflow/workflow-steps/after-checkout/workflow-thank-you.service';
import {WorkflowVollmachtAuthorizationService} from 'app/core/workflow/workflow-steps/after-checkout/workflow-vollmacht-authorization.service';
import {
  WorkflowTaxAdvisorAdditionalValidationService
} from 'app/core/workflow/workflow-steps/after-checkout/workflow-tax-advisor-additional-validation.service';
import {
  WorkflowConfirmTaxReturnService
} from 'app/core/workflow/workflow-steps/after-checkout/workflow-confirm-tax-return.service';
import {
  WorkflowDownloadStatementService
} from 'app/core/workflow/workflow-steps/after-checkout/workflow-download-statement.service';
import {
  WorkflowBasicService
} from 'app/core/workflow/workflow-steps/other/workflow-basic.service';
import {
  WorkflowAdditionalIncomeOneService
} from 'app/core/workflow/workflow-steps/applicant/sub/workflow-additional-income-one.service';
import {
  WorkflowAdditionalIncomeTwoService
} from 'app/core/workflow/workflow-steps/applicant/sub/workflow-additional-income-two.service';
import {
  WorkflowAdditionalIncomeThreeService
} from 'app/core/workflow/workflow-steps/applicant/sub/workflow-additional-income-three.service';
import {
  WorkflowAdditionalIncomeFourService
} from 'app/core/workflow/workflow-steps/applicant/sub/workflow-additional-income-four.service';
import {
  WorkflowAdditionalIncomeSpouseOneService
} from 'app/core/workflow/workflow-steps/spouse/sub/workflow-additional-income-spouse-one.service';
import {
  WorkflowAdditionalIncomeSpouseTwoService
} from 'app/core/workflow/workflow-steps/spouse/sub/workflow-additional-income-spouse-two.service';
import {
  WorkflowAdditionalIncomeSpouseThreeService
} from 'app/core/workflow/workflow-steps/spouse/sub/workflow-additional-income-spouse-three.service';
import {
  WorkflowAdditionalIncomeSpouseFourService
} from 'app/core/workflow/workflow-steps/spouse/sub/workflow-additional-income-spouse-four.service';
import {
  WorkflowBatchClientDetailsService
} from 'app/core/workflow/workflow-steps/batch/workflow-batch-client-details.service';
import {
  WorkflowBatchThankYouService
} from 'app/core/workflow/workflow-steps/batch/workflow-batch-thank-you.service';
import {
  WorkflowDeductionInfoWorkAndOfficeService
} from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-info-work-and-office.service';
import {
  WorkflowDeductionInfoBusinessTripsService
} from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-info-business-trips.service';
import {
  WorkflowDeductionInfoHouseService
} from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-info-house.service';
import {
  WorkflowDeductionInfoSpouseWorkAndOfficeService
} from 'app/core/workflow/workflow-steps/deduction/spouse/sub/workflow-deduction-info-spouse-work-and-office.service';
import {
  WorkflowDeductionInfoSpouseBusinessTripsService
} from 'app/core/workflow/workflow-steps/deduction/spouse/sub/workflow-deduction-info-spouse-business-trips.service';
import {
  WorkflowDeductionInfoSpouseHouseService
} from 'app/core/workflow/workflow-steps/deduction/spouse/sub/workflow-deduction-info-spouse-house.service';
import {
  WorkflowDeductionInfoChildSubService
} from 'app/core/workflow/workflow-steps/deduction/child/workflow-deduction-info-child-sub.service';
import {
  WorkflowDeductionInfoHomeServiceService
} from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-info-home-service.service';
import {
  WorkflowDeductionInfoInsuranceService
} from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-info-insurance.service';
import {
  WorkflowDeductionInfoDonationsAndMembershipsService
} from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-info-donations-and-memberships.service';
import {
  WorkflowDeductionInfoOtherCostsService
} from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-info-other-costs.service';
import {
  WorkflowDeductionInfoSpouseInsuranceService
} from 'app/core/workflow/workflow-steps/deduction/spouse/sub/workflow-deduction-info-spouse-insurance.service';
import {
  WorkflowDeductionInfoSpouseOtherCostsService
} from 'app/core/workflow/workflow-steps/deduction/spouse/sub/workflow-deduction-info-spouse-other-costs.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {
  WorkflowPersonalInfoChildSubService
} from 'app/core/workflow/workflow-steps/child/workflow-personal-info-child-sub.service';
import {
  WorkflowPersonalInfoChildAdultService
} from 'app/core/workflow/workflow-steps/child/workflow-personal-info-child-adult.service';
import {
  WorkflowPersonalInfoChildSummaryService
} from 'app/core/workflow/workflow-steps/child/workflow-personal-info-child-summary.service';
import {
  WorkflowDeductionInfoHealthService
} from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-info-health.service';
import {
  WorkflowDeductionInfoSpouseHealthService
} from 'app/core/workflow/workflow-steps/deduction/spouse/sub/workflow-deduction-info-spouse-health.service';
import { WorkflowFinishService } from 'app/core/workflow/workflow-steps/after-checkout/workflow-finish.service';
import { DashboardPagePath } from 'app/core/workflow/page-path/dashboard-page.path';
import { PageRecognizerService } from 'app/core/services/page-recognizer.service';

@Injectable()
export class WorkflowControllerService extends SubscribingComponent {
  public workflow: WorkflowStepsBaseService[] = [];
  public workflowForMenu: Array<{title?: Function, steps: WorkflowStepsBaseService[]}> = [];
  public statement: Statement;
  public isBusiness: boolean;
  private user = new User();
  private selectsData = new SelectsData();
  private isStatementInitialized = false;
  private isReadyForInitialize = false;
  private stepsBar: boolean;

  constructor(
    private store: Store<State>,
    private router: Router,
    private translate: TranslateService,
    private readonly pageRecognizerService: PageRecognizerService,
  ) {
    super();

    combineLatest([
      this.store.select(getUser),
      this.store.select(getSelectsData)
    ]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: ([user, selectsData]) => {
          if (user && selectsData) {
            this.isBusiness = user.isBusiness;
            Object.assign(this.user, user);
            Object.assign(this.selectsData, selectsData);

            this.isReadyForInitialize = true;

            if (this.isStatementInitialized) {
              this._init();
            }
          }
        }
      });
  }

  public init(_statement: Statement, stepsBar = false): void {
    if (_statement) {
      this.statement = _statement;
    }
    this.stepsBar = stepsBar;

    this.isStatementInitialized = true;

    if (this.isReadyForInitialize) {
      this._init();
    }

    this.pageRecognizerService.init(this.ngUnsubscribe);
  }

  public get isWorkflowHeaderProgressShown(): boolean {
    return !!this.statement && !this.isBusiness && this.pageRecognizerService.isStatementFlowPages;
  }

  public goToTheDashboard(): void {
    this.router.navigate([DashboardPagePath.fullUrl()]);
  }

  public reInit(_statement?: Statement): void {
    this.init(_statement);
  }

  // public get isInitialized(): boolean {
  //   return !!this.workflow && !!this.workflow.length;
  // }

  public get isMainWorkflowDisabled(): boolean {
    return this.statement && (this.statement.isPaid || this.statement.isSent());
  }

  public get currentStep(): WorkflowStepsBaseService {
    return this.workflow.find(x => x.isActive);
  }

  public get currentStepIndex(): number {
    return this.workflow.findIndex(x => x.isActive);
  }

  public stepIndexByUrl(url: string): number {
    let result = this.workflow.findIndex(x => url.search(x.urlRegex) !== -1);

    if (result === -1) {
      result = this.workflow.findIndex(x => ('/' + url).search(x.urlRegex) !== -1);
    }

    return result;
  }

  public goToTheNextStep(): WorkflowStepsBaseService {


    const currentStepIndex = this.currentStepIndex;
    let nextStepIndex = -1;

    if (currentStepIndex !== -1) {
      if (this.workflow[currentStepIndex].goNextUrl) {
        nextStepIndex = this.stepIndexByUrl(this.workflow[currentStepIndex].goNextUrl);
      }

      if (nextStepIndex === -1) {
        nextStepIndex = this.checkNextStep(currentStepIndex + 1);
      }

      if (nextStepIndex !== -1) {
        if (!environment.production) {
          console.log('[GO FORWARD]', this.workflow[nextStepIndex]);
        }
        this.router.navigate([this.workflow[nextStepIndex].fullUrl]);
        return this.workflow[nextStepIndex];
      } else {
        return this.workflow[currentStepIndex];
      }
    }

    return this.defaultRoute();
  }

  public goToThePreviousStep(): WorkflowStepsBaseService {
    const currentStepIndex = this.currentStepIndex;
    let prevStepIndex = -1;

    if (currentStepIndex !== -1) {
      if (this.workflow[currentStepIndex].goBackUrl) {
        prevStepIndex = this.stepIndexByUrl(this.workflow[currentStepIndex].goBackUrl);
      }

      if (prevStepIndex === -1) {
        prevStepIndex = this.checkPreviousStep(currentStepIndex - 1);
      }

      if (prevStepIndex !== -1) {
        if (!environment.production) {
          console.log('[GO BACK]', this.workflow[prevStepIndex]);
        }
        this.router.navigate([this.workflow[prevStepIndex].fullUrl]);
        return this.workflow[prevStepIndex];
      } else {
        return this.workflow[currentStepIndex];
      }
    }

    return this.defaultRoute();
  }

  public checkCurrentRoute(): void {
    const currentStepIndex = this.currentStepIndex;

    if (currentStepIndex !== -1) {
      let stepIndex = currentStepIndex;
      if (!environment.production) {
        console.log('[CURRENT]', this.workflow[stepIndex]);
      }
      if (!this.workflow[stepIndex].canGo) {
        if (this.isMainWorkflowDisabledAndIsBusiness) {
          this.redirectForDisabledWorkflowAndBusiness();
          return;
        }
        stepIndex = this.checkPreviousStep(stepIndex - 1);
      }

      stepIndex = this.checkPreviousStepsAreDone(stepIndex);

      if (currentStepIndex !== stepIndex) {
        if (stepIndex !== -1) {
          this.router.navigate([this.workflow[stepIndex].fullUrl]);
        } else {
          this.defaultRoute();
        }
      }
    } else {
      this.defaultRoute();
    }
  }

  private get isMainWorkflowDisabledAndIsBusiness(): boolean {
    return this.isMainWorkflowDisabled && this.user.isBusiness;
  }

  private redirectForDisabledWorkflowAndBusiness(): void {
    this.router.navigate([DashboardPagePath.fullUrl()], {queryParams: {statement_id: this.statement.id}});
  }

  private checkPreviousStep(index: number): number {
    if (index < 0) {
      return -1;
    }
    const prevStep = this.workflow[index];
    if (prevStep && prevStep.isDone && prevStep.canGo && !prevStep.isFakeStep && !prevStep.skipWhenGoBack) {
      return index;
    }

    return this.checkPreviousStep(index - 1);
  }

  private checkNextStep(index: number): number {
    if (index >= this.workflow.length) {
      return -1;
    }

    const nextStep = this.workflow[index];
    if (nextStep) {
      if (nextStep.canGo && !nextStep.isFakeStep) {
        return index;
      }
      if (!nextStep.isRequired || nextStep.isDone || nextStep.isFakeStep) {
        return this.checkNextStep(index + 1);
      } else {
        return index - 1;
      }
    }

    return -1;
  }

  private checkPreviousStepsAreDone(actualIndex: number): number {
    let result = actualIndex;
    this.workflow.some((el, i) => {
      if (i > actualIndex) {
        return true;
      }

      if (this.workflow[i].isRequired && !this.workflow[i].isDone && this.workflow[i].canGo) {
        result = i;
        return true;
      }
    });

    return result;
  }

  private defaultRoute() {
    let result = -1;
    this.workflow.some((el, i) => {
      if (this.workflow[i].canGo) {
        result = i;
        return true;
      }
    });

    if (result === -1) {
      this.router.navigate(['dashboard']);
      return null;
    }

    this.router.navigate([ this.workflow[result].fullUrl]);
    return this.workflow[result];
  }

  private _init(): void {
    const childrenPersonalInfo = [];
    const childrenDeductions = [];

    if (this.statement && this.statement.children.length > 0 && this.user) {
      const childTitleWithoutName = this.translate.instant('ASIDE_BAR.CHILD');
      this.statement.children.forEach((child, index) => {
        const workflowStepPersonalInfo =
          (new WorkflowPersonalInfoChildSubService(this.statement, this.user, this.selectsData));

        workflowStepPersonalInfo.titleFunction = () => child.firstName
          ? child.firstName
          : childTitleWithoutName + ' ' + (index + 1);
        workflowStepPersonalInfo.subStepNumber = child.id;

        childrenPersonalInfo.push(workflowStepPersonalInfo);

        const workflowStepPersonalInfoAdult =
          (new WorkflowPersonalInfoChildAdultService(this.statement, this.user, this.selectsData));
        workflowStepPersonalInfoAdult.subStepNumber = child.id;
        childrenPersonalInfo.push(workflowStepPersonalInfoAdult);

        if (!this.user.isBusiness && this.statement.children.length > 1 && child.firstName) {
          const workflowStepDeduction =
            (new WorkflowDeductionInfoChildSubService(this.statement, this.user, this.selectsData));

          workflowStepDeduction.title = child.firstName;
          workflowStepDeduction.subStepNumber = child.id;

          childrenDeductions.push(workflowStepDeduction);
        }
      });
    }

    this.workflowForMenu = [
      {
        title: () => !this.user.isBusiness ?
          (this.statement && this.statement.firstName ? 'ASIDE_BAR.TITLE_APPLICANT' : 'ASIDE_BAR.TAXPAYER_DATA')
          : 'ASIDE_BAR.TITLE_APPLICANT_B2B',
        steps: [
          new WorkflowBasicService(this.statement, this.user, this.selectsData),

          // applicant
          new WorkflowTaxCardService(this.statement, this.user, this.selectsData),
          new WorkflowAdditionalIncomeService(this.statement, this.user, this.selectsData),
          // start sub
          new WorkflowAdditionalIncomeOneService(this.statement, this.user, this.selectsData),
          new WorkflowAdditionalIncomeTwoService(this.statement, this.user, this.selectsData),
          new WorkflowAdditionalIncomeThreeService(this.statement, this.user, this.selectsData),
          new WorkflowAdditionalIncomeFourService(this.statement, this.user, this.selectsData),
          // end sub
          new WorkflowAdditionalOptionsService(this.statement, this.user, this.selectsData),
          new WorkflowProfileService(this.statement, this.user, this.selectsData),
          new WorkflowAbroadIncomeService(this.statement, this.user, this.selectsData),
          new WorkflowPersonalInfoService(this.statement, this.user, this.selectsData),
        ]
      }
    ];

    if (!!this.statement && !!this.statement.spouse && this.statement.filedTogether) {
      this.workflowForMenu = [
        ...this.workflowForMenu,
        {
          title: () => !!this.statement && !!this.statement.spouse && this.statement.filedTogether
            ? !this.user.isBusiness
              ? (this.statement.spouse.firstName ? 'ASIDE_BAR.TITLE_SPOUSE' : 'ASIDE_BAR.SPOUSES_DETAILS')
              : 'ASIDE_BAR.TITLE_SPOUSE_B2B'
            : '',
          steps: [
            // spouse
            new WorkflowPersonalInfoSpouseService(this.statement, this.user, this.selectsData),
            new WorkflowTaxCardSpouseService(this.statement, this.user, this.selectsData),
            new WorkflowAdditionalIncomeSpouseService(this.statement, this.user, this.selectsData),
            // start sub
            new WorkflowAdditionalIncomeSpouseOneService(this.statement, this.user, this.selectsData),
            new WorkflowAdditionalIncomeSpouseTwoService(this.statement, this.user, this.selectsData),
            new WorkflowAdditionalIncomeSpouseThreeService(this.statement, this.user, this.selectsData),
            new WorkflowAdditionalIncomeSpouseFourService(this.statement, this.user, this.selectsData)
            // end sub
          ]
        },
      ];
    }

    if (this.statement && this.statement.hasChildren) {
      this.workflowForMenu = [
        ...this.workflowForMenu,
        {
          title: () => this.statement && this.statement.hasChildren
            ? 'ASIDE_BAR.TITLE_CHILDREN'
            : '',
          steps: [
            // child
            new WorkflowPersonalInfoChildService(this.statement, this.user, this.selectsData),
            ...childrenPersonalInfo,
            new WorkflowPersonalInfoChildSummaryService(this.statement, this.user, this.selectsData),
          ]
        },
      ];
    }

    this.workflowForMenu = [
      ...this.workflowForMenu,
      {
        title: null,
        steps: [
          // others
          // package selection
          new WorkflowPackageSelectionService(this.statement, this.user, this.selectsData),
          new WorkflowPhoneVerificationService(this.statement, this.user, this.selectsData),
        ]
      },
      {
        title: null,
        steps: [
          // deductions
          new WorkflowDeductionSkipService(this.statement, this.user, this.selectsData),

          // children
          new WorkflowDeductionInfoChildService(this.statement, this.user, this.selectsData),
          ...childrenDeductions,

          // applicant
          new WorkflowDeductionInfoService(this.statement, this.user, this.selectsData),
          // start sub
          new WorkflowDeductionInfoWorkAndOfficeService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoBusinessTripsService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoHouseService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoHomeServiceService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoInsuranceService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoDonationsAndMembershipsService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoOtherCostsService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoHealthService(this.statement, this.user, this.selectsData),
          // end sub

          // spouse
          new WorkflowDeductionInfoSpouseService(this.statement, this.user, this.selectsData),
          // start sub
          new WorkflowDeductionInfoSpouseWorkAndOfficeService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoSpouseBusinessTripsService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoSpouseHouseService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoSpouseInsuranceService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoSpouseOtherCostsService(this.statement, this.user, this.selectsData),
          new WorkflowDeductionInfoSpouseHealthService(this.statement, this.user, this.selectsData),
          // end sub
        ]
      },
      {
        title: null,
        steps: [
          // submit/summary/checkout
          new WorkflowBankDetailService(this.statement, this.user, this.selectsData),
          new WorkflowSubmitService(this.statement, this.user, this.selectsData),
          new WorkflowPaymentCheckoutService(this.statement, this.user, this.selectsData),
        ]
      },
    ];

    this.workflow = [];

    this.workflowForMenu.forEach(el => {
      this.workflow.push(...el.steps);
    });

    this.workflow = [
      ...this.workflow,

      // business
      new WorkflowBatchClientDetailsService(this.statement, this.user, this.selectsData),
      new WorkflowBatchThankYouService(this.statement, this.user, this.selectsData),

      // after checkout/confirm steps
      new WorkflowVollmachtAuthorizationService(this.statement, this.user, this.selectsData),
      new WorkflowTaxAdvisorAdditionalValidationService(this.statement, this.user, this.selectsData),
      new WorkflowThankYouService(this.statement, this.user, this.selectsData),
      new WorkflowFinishService(this.statement, this.user, this.selectsData),
      new WorkflowConfirmTaxReturnService(this.statement, this.user, this.selectsData),
      new WorkflowDownloadStatementService(this.statement, this.user, this.selectsData),
    ];

    if (!this.stepsBar) {
      this.checkCurrentRoute();
    }
  }
}
