import { DateRange, DateRangeResponse, toResponseDate } from "app/common/dateRange";
import { ABROAD_INCOME_DEFAULT_COUNTRY, DEFAULT_COUNTRY } from "app/common";
import { City } from "app/statements/city";
import { RecognizerHelper } from "app/core/helpers/recognizer.helper";
import { NumberFormatHelper } from "app/core/helpers/number-format.helper";
import { DeductionDisabilityCarePflegegrad } from "app/shared/modules/statement-deduction-base/enums/deduction-disability-care-pflegegrad.enum";
import { DeductionDisabilityCareTaker } from "app/shared/modules/statement-deduction-base/enums/deduction-disability-care-taker.enum";
import { SelectChoiceListInterface } from "app/core/models/select-choice-list.interface";

export enum DeductionFlagOptions {
  DRIVER_LUMPSUM = "driverLumpsum"
}

export interface DeductionsResponse {
  id?: number;

  // Submodule 1:
  recruitment_costs?: string[];
  bank_costs?: string[];
  phone_and_internet_costs?: string[];
  tax_settlement_costs?: string[];
  tax_settlement_cost_names?: string[];
  profession_insurance_costs?: string[];
  profession_insurance_cost_names?: string[];
  winterbau_costs?: string[];
  winterbau_cost_names?: string[];
  other_recruitment_costs?: string[];
  other_recruitment_cost_names?: string[];

  //Submodule donation:
  donation_de_name?: string[];
  donation_de_value?: string[];
  donation_eu_name?: string[];
  donation_eu_value?: string[];
  donation_political_name?: string[];
  donation_political_value?: string[];

  // Submodule 2:
  have_job_costs_lump_sum?: boolean;
  purchased_job_equipment?: boolean;
  job_equipment_costs?: string[];
  job_equipment_cost_names?: string[];
  workwear_washing_cost?: string;
  have_workwear?: boolean;
  workwear_cost?: string;
  have_training_costs?: boolean;
  training_costs?: string[];
  training_cost_names?: string[];

  // Submodule 3:
  trade_union_contributions?: string[];
  trade_union_names?: string[];

  // Submodule 4:
  have_separated_household?: boolean;
  household_postal_code?: string[];
  household_city?: string[];
  household_country?: string[];
  household_date_from?: string[];

  residence_at_place_of_employment_postal_code?: string[];
  residence_at_place_of_employment_city?: string[];
  period_of_residence_at_place_of_employment?: DateRangeResponse[];
  residence_at_place_of_employment_costs?: string[];

  first_and_last_travel_kilometers?: string[];
  first_and_last_travel_ticket_costs?: string[];

  weekend_trips_distance?: string[];
  weekend_trips_number?: string[];
  weekend_trips_ticket_costs?: string[];

  days_spent_in_travel?: string[];
  days_spent_at_place_of_employment?: string[];
  other_costs?: string[];
  other_costs_name?: string[];

  // Submodule 5:
  place_of_employment_address?: string[];
  period_of_employment?: DateRangeResponse[];
  working_days_per_week?: string[];
  absences_at_work?: string[];
  presences_at_work?: string[];
  distance_from_work?: string[];
  public_transport_costs?: string[];

  // Submodule 6
  have_moving_expanses?: boolean;
  moving_expanses_costs?: string[];
  moving_expanses_costs_names?: string[];

  // Submodule 7:
  home_office_costs?: string[];
  home_office_costs_names?: string[];
  home_office_covid19?: boolean[];
  home_office_covid19_day_in_home?: string[];

  // Submodule 8:
  house_hold_mini_jobs_costs?: string[];
  house_hold_mini_jobs_costs_names?: string[];
  house_hold_services_costs?: string[];
  house_hold_services_costs_names?: string[];
  craftsman_services_costs?: string[];
  craftsman_services_costs_names?: string[];

  // Submodule 9.1:
  have_riester?: boolean;
  social_security_number?: string;
  insurance_number_changed?: boolean;
  indirectly_favored?: boolean;
  agreement_numbers?: string;
  contributions_amount?: string;
  last_year_income?: string;
  show_alterskasse?: boolean;
  // kinderzulage1?: string;
  // kinderzulage2?: string;

  // Submodule 9.2
  have_health_costs?: boolean;
  health_costs_names?: string[];
  health_costs?: string[];
  health_refund_amount?: string[];

  // Submodule 9.3
  have_care_costs?: boolean;
  care_costs_names?: string[];
  care_costs?: string[];
  care_refund_amount?: string[];

  //Submodule 9.4
  have_disability?: boolean;
  applying_for_disability_with_lump?: boolean;
  applying_for_disability_without_lump?: boolean;
  disability_percent?: string;
  disability_from?: string;
  disability_to?: string;
  require_constant_care?: boolean;
  mobility_impaired?: boolean;
  disability_costs_names?: string[];
  disability_costs?: string[];
  disability_refund_amount?: string[];

  // Sybmodule 7.9
  applying_for_disability_with_pflegegrad?: boolean;
  disability_pflegegrad_caretaker?: DeductionDisabilityCareTaker[];
  disability_pflegegrad?: DeductionDisabilityCarePflegegrad[];
  disabled_person_data?: string[];

  // Submodule 9:
  contributions_to_health_insurance?: string;
  contributions_to_sickness_benefit?: string;
  long_term_care_insurance_contributions?: string;
  health_and_insurance_contributions_refunds?: string;
  sickness_benefit_reimbursements?: string;
  health_and_insurance_contributions_grants?: string;
  elective_and_supplementary_insurance?: string;

  // Submodule 10:
  contributions_to_private_health_insurance?: string;
  private_long_term_care_insurance_contributions?: string;
  health_and_private_insurance_contributions_refunds?: string;
  health_and_private_insurance_contributions_grants?: string;
  private_elective_and_supplementary_insurance?: string;
  additional_long_term_care_insurance?: string;

  // Submodule 11:
  contributions_to_foreign_health_insurance?: string;
  contributions_to_foreign_sickness_benefit?: string;
  foreign_long_term_care_insurance_contributions?: string;
  health_and_foreign_insurance_contributions_refunds?: string;
  foreign_sickness_benefit_reimbursements?: string;
  foreign_elective_and_supplementary_insurance?: string;
  has_foreign_social_insurance?: boolean;
  foreign_social_insurance_employee_contributions?: string;
  foreign_social_insurance_employer_contributions?: string;

  // Submodule 12:
  have_business_trips?: boolean;
  have_car_travels?: boolean[];
  car_travels_distance?: string[];
  car_travels_number?: string[];
  purchased_travel_tickets?: boolean[];
  travel_tickets_costs?: string[];
  have_accommodation_costs?: boolean[];
  accommodation_costs?: string[];
  have_employer_refunds?: boolean[];
  employer_refunds?: string[];
  days_in_one_day_travel?: string[];
  days_in_travel_to_work?: string[];
  days_in_travel_from_work?: string[];
  days_in_multi_day_travel?: string[];
  have_free_meals_on_travel?: boolean[];
  have_regular_free_meals_on_travel?: boolean[];
  meals_in_travel?: boolean[][];
  meals_in_one_day_travel?: boolean[][];
  free_meals_diet_amount?: string[];
  travel_abroad_country?: string[];
  travel_abroad_city?: string[];
  days_in_one_day_travel_abroad?: string[];
  days_in_travel_abroad_to_work?: string[];
  days_in_travel_abroad_from_work?: string[];
  days_in_multi_day_travel_abroad?: string[];
  have_free_meals_on_travel_abroad?: boolean[];
  have_regular_free_meals_on_travel_abroad?: boolean[];
  meals_in_travel_abroad?: boolean[][];
  meals_in_one_day_travel_abroad?: boolean[][];
  free_meals_diet_amount_abroad?: string[];
  driver_lumpsum?: number;
}

export interface DeductionsErrors {
  // Submodule 1:
  recruitment_costs?: string[];
  bank_costs?: string[];
  phone_and_internet_costs?: string[];
  tax_settlement_costs?: string[];
  tax_settlement_cost_names?: string[];
  profession_insurance_costs?: string[];
  profession_insurance_cost_names?: string[];
  winterbau_costs?: string[];
  winterbau_cost_names?: string[];
  other_recruitment_costs?: string[];
  other_recruitment_cost_names?: string[];

  // Submodule donation:
  donation_de_name?: string[];
  donation_de_value?: string[];
  donation_eu_name?: string[];
  donation_eu_value?: string[];
  donation_political_name?: string[];
  donation_political_value?: string[];

  // Submodule 2:
  have_job_costs_lump_sum?: string[];
  purchased_job_equipment?: string[];
  job_equipment_costs?: string[];
  job_equipment_cost_names?: string[];
  workwear_washing_cost?: string[];
  have_workwear?: string[];
  workwear_cost?: string[];
  have_training_costs?: string[];
  training_costs?: string[];
  training_cost_names?: string[];

  // Submodule 3:
  trade_union_contributions?: string[];
  trade_union_names?: string[];

  // Submodule 4:
  have_separated_household?: string[];
  household_postal_code?: string[];
  household_city?: string[];

  household_country?: string[];
  household_date_from?: string[];

  residence_at_place_of_employment_postal_code?: string[];
  residence_at_place_of_employment_city?: string[];
  period_of_residence_at_place_of_employment?: string[];
  residence_at_place_of_employment_costs?: string[];

  first_and_last_travel_kilometers?: string[];
  first_and_last_travel_ticket_costs?: string[];

  weekend_trips_distance?: string[];
  weekend_trips_number?: string[];
  weekend_trips_ticket_costs?: string[];

  days_spent_in_travel?: string[];
  days_spent_at_place_of_employment?: string[];
  other_costs?: string[];
  other_costs_name?: string[];

  // Submodule 5:
  place_of_employment_address?: string[];
  period_of_employment?: string[];
  working_days_per_week?: string[];
  absences_at_work?: string[];
  presences_at_work?: string[];
  distance_from_work?: string[];
  public_transport_costs?: string[];

  // Submodule 6:
  have_moving_expanses?: string[];
  moving_expanses_costs?: string[];
  moving_expanses_costs_names?: string[];

  // Submodule 7:
  home_office_costs?: string[];
  home_office_costs_names?: string[];
  home_office_covid19?: string[];
  home_office_covid19_day_in_home?: string[];

  // Submodule 8:
  house_hold_mini_jobs_costs?: string[];
  house_hold_mini_jobs_costs_names?: string[];
  house_hold_services_costs?: string[];
  house_hold_services_costs_names?: string[];
  craftsman_services_costs?: string[];
  craftsman_services_costs_names?: string[];

  // Submodule 9.1:
  have_riester?: boolean[];
  social_security_number?: string[];
  insurance_number_changed?: string[];
  indirectly_favored?: string[];
  agreement_numbers?: string[];
  contributions_amount?: string[];
  last_year_income?: string[];
  show_alterskasse?: boolean;
  // kinderzulage1?: string[];
  // kinderzulage2?: string[];

  // Submodule 9:
  contributions_to_health_insurance?: string[];
  contributions_to_sickness_benefit?: string[];
  long_term_care_insurance_contributions?: string[];
  health_and_insurance_contributions_refunds?: string[];
  sickness_benefit_reimbursements?: string[];
  health_and_insurance_contributions_grants?: string[];
  elective_and_supplementary_insurance?: string[];

  // Submodule 9.2
  health_costs_names?: string[];
  health_costs?: string[];
  health_refund_amount?: string[];

  // Submodule 9.3
  care_costs_names?: string[];
  care_costs?: string[];
  care_refund_amount?: string[];

  // Submodule 9.4
  applying_for_disability_with_lump?: string[];
  applying_for_disability_without_lump?: string[];
  disability_percent?: string[];
  disability_from?: string[];
  disbility_to?: string[];
  require_constant_care?: string[];
  mobility_impaired?: string[];
  disability_costs_names?: string[];
  disability_costs?: string[];
  disability_refund_amount?: string[];

  // Sybmodule 7.9
  applying_for_disability_with_pflegegrad?: string[];
  disability_pflegegrad_caretaker?: string[];
  disability_pflegegrad?: string[];
  disabled_person_data?: string[];

  // Submodule 10:
  contributions_to_private_health_insurance?: string[];
  private_long_term_care_insurance_contributions?: string[];
  health_and_private_insurance_contributions_refunds?: string[];
  health_and_private_insurance_contributions_grants?: string[];
  private_elective_and_supplementary_insurance?: string[];
  additional_long_term_care_insurance?: string[];

  // Submodule 11:
  contributions_to_foreign_health_insurance?: string[];
  contributions_to_foreign_sickness_benefit?: string[];
  foreign_long_term_care_insurance_contributions?: string[];
  health_and_foreign_insurance_contributions_refunds?: string[];
  foreign_sickness_benefit_reimbursements?: string[];
  foreign_elective_and_supplementary_insurance?: string[];

  // Submodule 12:
  have_car_travels?: string[];
  car_travels_distance?: string[];
  car_travels_number?: string[];
  purchased_travel_tickets?: string[];
  travel_tickets_costs?: string[];
  have_accommodation_costs?: string[];
  accommodation_costs?: string[];
  have_employer_refunds?: string[];
  employer_refunds?: string[];
  days_in_one_day_travel?: string[];
  days_in_travel_to_work?: string[];
  days_in_multi_day_travel?: string[];
  have_free_meals_on_travel?: string[];
  have_regular_free_meals_on_travel?: string[];
  meals_in_travel?: string[];
  free_meals_diet_amount?: string[];
  travel_abroad_country?: string[];
  travel_abroad_city?: string[];
  days_in_one_day_travel_abroad?: string[];
  days_in_travel_abroad_to_work?: string[];
  days_in_multi_day_travel_abroad?: string[];
  have_free_meals_on_travel_abroad?: string[];
  have_regular_free_meals_on_travel_abroad?: string[];
  meals_in_travel_abroad?: string[];
  free_meals_diet_amount_abroad?: string[];
  driver_lumpsum?: number;

  non_field_errors?: string[];
}

export class Deductions {
  id?: number;

  // Submodule 1:
  recruitmentCosts?: string[];
  bankCosts?: string[];
  phoneAndInternetCosts?: string[];
  taxSettlementCosts?: string[];
  taxSettlementCostNames?: string[];
  professionInsuranceCosts?: string[];
  professionInsuranceCostNames?: string[];
  winterbauCosts?: string[];
  winterbauCostNames?: string[];
  otherRecruitmentCosts?: string[];
  otherRecruitmentCostNames?: string[];

  //Submodule donation:
  donationDeName?: string[];
  donationDeValue?: string[];
  donationEuName?: string[];
  donationEuValue?: string[];
  donationPoliticalName?: string[];
  donationPoliticalValue?: string[];

  // Submodule 2:
  haveJobCostsLumpSum?: boolean;
  purchasedJobEquipment?: boolean;
  jobEquipmentCosts?: string[];
  jobEquipmentCostNames?: string[];
  workwearWashingCost?: string;
  haveWorkwear?: boolean;
  workwearCost?: string;
  haveTrainingCosts?: boolean;
  trainingCosts?: string[];
  trainingCostNames?: string[];

  // Submodule 3:
  tradeUnionContributions?: string[];
  tradeUnionNames?: string[];

  // Submodule 4:
  haveSeparatedHousehold?: boolean;
  householdPostalCode?: string[];
  householdCity?: string[];
  householdStreet?: string[];
  householdHouseNumber?: string[];
  householdCountry?: string[];
  householdDateFrom?: string[];

  residenceAtPlaceOfEmploymentPostalCode?: string[];
  residenceAtPlaceOfEmploymentCity?: string[];
  residenceAtPlaceOfEmploymentStreet?: string[];
  residenceAtPlaceOfEmploymentHouseNumber?: string[];
  periodOfResidenceAtPlaceOfEmployment?: DateRange[];
  residenceAtPlaceOfEmploymentCosts?: string[];

  firstAndLastTravelKilometers?: string[];
  firstAndLastTravelTicketCosts?: string[];

  weekendTripsDistance?: string[];
  weekendTripsNumber?: string[];
  weekendTripsTicketCosts?: string[];

  daysSpentInTravel?: string[];
  daysSpentAtPlaceOfEmployment?: string[];
  otherCosts?: string[];
  otherCostsName?: string[];

  // Submodule 5:
  placeOfEmploymentAddress?: string[];
  periodOfEmployment?: DateRange[];
  workingDaysPerWeek?: string[];
  absencesAtWork?: string[];
  presencesAtWork?: string[];
  distanceFromWork?: string[];
  publicTransportCosts?: string[];

  // Submodule 6:
  haveMovingExpanses?: boolean;
  movingExpansesCosts?: string[];
  movingExpansesCostsNames?: string[];

  // Submodule 7:
  homeOfficeCosts?: string[];
  homeOfficeCostsNames?: string[];
  homeOfficeCovid19?: boolean[];
  homeOfficeCovid19DayInHome?: string[];

  // Submodule 8:
  houseHoldMiniJobsCosts?: string[];
  houseHoldMiniJobsCostsNames?: string[];
  houseHoldServicesCosts?: string[];
  houseHoldServicesCostsNames?: string[];
  craftsmanServicesCosts?: string[];
  craftsmanServicesCostsNames?: string[];

  // Submodule 9.1:
  haveRiester?: boolean;
  socialSecurityNumber?: string;
  retirementFundNumber?: string;
  indirectlyFavored?: boolean;
  agreementNumbers?: string;
  contributionsAmount?: string;
  lastYearIncome?: string;
  // kinderzulage1?: string;
  // kinderzulage2?: string;

  // Submodule 9.2
  haveHealthCosts?: boolean;
  healthCostsNames?: string[];
  healthCosts?: string[];
  healthRefundAmount?: string[];

  // Submodule 9.3
  haveCareCosts?: boolean;
  careCostsNames?: string[];
  careCosts?: string[];
  careRefundAmount?: string[];

  //Submodule 9.4
  haveDisability?: boolean;
  applyingForDisabilityWithLump?: boolean;
  applyingForDisabilityWithoutLump?: boolean;
  disabilityPercent?: string;
  disabilityFrom?: string[];
  disabilityTo?: string[];
  requireConstantCare?: boolean;
  mobilityImpaired?: boolean;
  disabilityCostsNames?: string[];
  disabilityCosts?: string[];
  disabilityRefundAmount?: string[];

  // Sybmodule 7.9
  hasDisabilityCarePflegegrad?: boolean;
  disabilityPflegegradCaretaker?: DeductionDisabilityCareTaker[];
  disabilityPflegegrad?: DeductionDisabilityCarePflegegrad[];
  disabledPersonData?: string[];

  // Submodule 9:
  contributionsToHealthInsurance: string;
  contributionsToSicknessBenefit: string;
  longTermCareInsuranceContributions: string;
  healthAndInsuranceContributionsRefunds: string;
  sicknessBenefitReimbursements: string;
  healthAndInsuranceContributionsGrants: string;
  electiveAndSupplementaryInsurance: string;

  // Submodule 10:
  contributionsToPrivateHealthInsurance: string;
  privateLongTermCareInsuranceContributions: string;
  healthAndPrivateInsuranceContributionsRefunds: string;
  healthAndPrivateInsuranceContributionsGrants: string;
  privateElectiveAndSupplementaryInsurance: string;
  additionalLongTermCareInsurance: string;

  // Submodule 11:
  contributionsToForeignHealthInsurance: string;
  contributionsToForeignSicknessBenefit: string;
  foreignLongTermCareInsuranceContributions: string;
  healthAndForeignInsuranceContributionsRefunds: string;
  foreignSicknessBenefitReimbursements: string;
  foreignElectiveAndSupplementaryInsurance: string;

  // Submodule 11a:
  foreignSocialInsuranceEmployeeContributions: string;
  foreignSocialInsuranceEmployerContributions: string;

  // Submodule 12:
  haveCarTravels: boolean[];
  carTravelsDistance: string[];
  carTravelsNumber: string[];
  purchasedTravelTickets: boolean[];
  travelTicketsCosts: string[];
  haveAccommodationCosts: boolean[];
  accommodationCosts: string[];
  haveEmployerRefunds: boolean[];
  employerRefunds: string[];
  daysInOneDayTravel: string[];
  daysInTravelToWork: string[];
  daysInTravelFromWork: string[];
  daysInMultiDayTravel: string[];
  haveFreeMealsOnTravel: boolean[];
  haveRegularFreeMealsOnTravel: boolean[];
  mealsInTravel: boolean[][];
  mealsInOneDayTravel: boolean[][];
  freeMealsDietAmount: string[];
  travelAbroadCountry: string[];
  travelAbroadCity: string[];
  daysInOneDayTravelAbroad: string[];
  daysInTravelAbroadToWork: string[];
  daysInTravelAbroadFromWork: string[];
  daysInMultiDayTravelAbroad: string[];
  haveFreeMealsOnTravelAbroad: boolean[];
  haveRegularFreeMealsOnTravelAbroad: boolean[];
  mealsInTravelAbroad: boolean[][];
  mealsInOneDayTravelAbroad: boolean[][];
  freeMealsDietAmountAbroad: string[];
  driverLumpsum?: number;

  static fromResponse(response: DeductionsResponse): Deductions {
    const deductions = new Deductions();
    const toDateRange = (value: DateRangeResponse) => {
      return DateRange.fromResponse(value);
    };
    deductions.id = response && response.id ? response.id : 0;

    // Submodule 1:
    deductions.recruitmentCosts = response && response.recruitment_costs ? response.recruitment_costs : [];
    deductions.bankCosts = response && response.bank_costs ? response.bank_costs : [];
    deductions.phoneAndInternetCosts =
      response && response.phone_and_internet_costs ? response.phone_and_internet_costs : [];
    deductions.taxSettlementCosts = response && response.tax_settlement_costs ? response.tax_settlement_costs : [];
    deductions.taxSettlementCostNames =
      response && response.tax_settlement_cost_names ? response.tax_settlement_cost_names : [];
    deductions.professionInsuranceCosts =
      response && response.profession_insurance_costs ? response.profession_insurance_costs : [];
    deductions.professionInsuranceCostNames =
      response && response.profession_insurance_cost_names ? response.profession_insurance_cost_names : [];
    deductions.winterbauCosts = response && response.winterbau_costs ? response.winterbau_costs : [];
    deductions.winterbauCostNames = response && response.winterbau_cost_names ? response.winterbau_cost_names : [];
    deductions.otherRecruitmentCosts =
      response && response.other_recruitment_costs ? response.other_recruitment_costs : [];
    deductions.otherRecruitmentCostNames =
      response && response.other_recruitment_cost_names ? response.other_recruitment_cost_names : [];

    //sumbodule donation
    deductions.donationDeName = response && response.donation_de_name ? response.donation_de_name : [];
    deductions.donationDeValue = response && response.donation_de_value ? response.donation_de_value : [];
    deductions.donationEuName = response && response.donation_eu_name ? response.donation_eu_name : [];
    deductions.donationEuValue = response && response.donation_eu_value ? response.donation_eu_value : [];
    deductions.donationPoliticalName =
      response && response.donation_political_name ? response.donation_political_name : [];
    deductions.donationPoliticalValue =
      response && response.donation_political_value ? response.donation_political_value : [];

    // Submodule 2:
    deductions.haveJobCostsLumpSum = response ? response.have_job_costs_lump_sum : false;
    deductions.purchasedJobEquipment = response ? response.purchased_job_equipment : false;
    deductions.jobEquipmentCosts = response && response.job_equipment_costs ? response.job_equipment_costs : [];
    deductions.jobEquipmentCostNames =
      response && response.job_equipment_cost_names ? response.job_equipment_cost_names : [];
    deductions.workwearWashingCost = response ? response.workwear_washing_cost : "0.00";
    deductions.haveWorkwear = response ? response.have_workwear : false;
    deductions.workwearCost = response ? response.workwear_cost : "0.00";
    deductions.haveTrainingCosts = response ? response.have_training_costs : false;
    deductions.trainingCosts = response && response.training_costs ? response.training_costs : [];
    deductions.trainingCostNames = response && response.training_cost_names ? response.training_cost_names : [];

    // Submodule 3:
    deductions.tradeUnionContributions =
      response && response.trade_union_contributions ? response.trade_union_contributions : [];
    deductions.tradeUnionNames = response && response.trade_union_names ? response.trade_union_names : [];

    // Submodule 4:
    deductions.haveSeparatedHousehold =
      response && response.have_separated_household ? response.have_separated_household : false;
    deductions.householdPostalCode = response && response.household_postal_code ? response.household_postal_code : [];
    deductions.householdCity = response && response.household_city ? response.household_city : [];
    deductions.householdCountry = response && response.household_country ? response.household_country : [];
    deductions.householdDateFrom = response && response.household_date_from ? response.household_date_from : [];

    deductions.residenceAtPlaceOfEmploymentPostalCode =
      response && response.residence_at_place_of_employment_postal_code
        ? response.residence_at_place_of_employment_postal_code
        : [];
    deductions.residenceAtPlaceOfEmploymentCity =
      response && response.residence_at_place_of_employment_city ? response.residence_at_place_of_employment_city : [];
    deductions.periodOfResidenceAtPlaceOfEmployment =
      response && response.period_of_residence_at_place_of_employment
        ? response.period_of_residence_at_place_of_employment.map(toDateRange)
        : [];
    deductions.residenceAtPlaceOfEmploymentCosts =
      response && response.residence_at_place_of_employment_costs
        ? response.residence_at_place_of_employment_costs
        : [];

    deductions.firstAndLastTravelKilometers =
      response && response.first_and_last_travel_kilometers ? response.first_and_last_travel_kilometers : [];
    deductions.firstAndLastTravelTicketCosts =
      response && response.first_and_last_travel_ticket_costs ? response.first_and_last_travel_ticket_costs : [];

    deductions.weekendTripsDistance =
      response && response.weekend_trips_distance ? response.weekend_trips_distance : [];
    deductions.weekendTripsNumber = response && response.weekend_trips_number ? response.weekend_trips_number : [];
    deductions.weekendTripsTicketCosts =
      response && response.weekend_trips_ticket_costs ? response.weekend_trips_ticket_costs : [];

    deductions.daysSpentInTravel = response && response.days_spent_in_travel ? response.days_spent_in_travel : [];
    deductions.daysSpentAtPlaceOfEmployment =
      response && response.days_spent_at_place_of_employment ? response.days_spent_at_place_of_employment : [];
    deductions.otherCosts = response && response.other_costs ? response.other_costs : [];
    deductions.otherCostsName = response && response.other_costs_name ? response.other_costs_name : [];

    // Submodule 5:
    deductions.placeOfEmploymentAddress =
      response && response.place_of_employment_address ? response.place_of_employment_address : [];
    deductions.periodOfEmployment =
      response && response.period_of_employment ? response.period_of_employment.map(toDateRange) : [];
    deductions.workingDaysPerWeek = response && response.working_days_per_week ? response.working_days_per_week : [];
    deductions.absencesAtWork = response && response.absences_at_work ? response.absences_at_work : [];
    deductions.presencesAtWork = response && response.presences_at_work ? response.presences_at_work : [];
    deductions.distanceFromWork = response && response.distance_from_work ? response.distance_from_work : [];
    deductions.publicTransportCosts =
      response && response.public_transport_costs ? response.public_transport_costs : [];

    // Submodule 6:
    deductions.haveMovingExpanses = response && response.have_moving_expanses ? response.have_moving_expanses : false;
    deductions.movingExpansesCosts = response && response.moving_expanses_costs ? response.moving_expanses_costs : [];
    deductions.movingExpansesCostsNames =
      response && response.moving_expanses_costs_names ? response.moving_expanses_costs_names : [];

    // Submodule 7:
    deductions.homeOfficeCosts = response && response.home_office_costs ? response.home_office_costs : [];
    deductions.homeOfficeCostsNames =
      response && response.home_office_costs_names ? response.home_office_costs_names : [];
    deductions.homeOfficeCovid19 = response && response.home_office_covid19 ? response.home_office_covid19 : [];
    deductions.homeOfficeCovid19DayInHome =
      response && response.home_office_covid19_day_in_home ? response.home_office_covid19_day_in_home : [];

    // Submodule 8:
    deductions.houseHoldMiniJobsCosts =
      response && response.house_hold_mini_jobs_costs ? response.house_hold_mini_jobs_costs : [];
    deductions.houseHoldMiniJobsCostsNames =
      response && response.house_hold_mini_jobs_costs_names ? response.house_hold_mini_jobs_costs_names : [];
    deductions.houseHoldServicesCosts =
      response && response.house_hold_services_costs ? response.house_hold_services_costs : [];
    deductions.houseHoldServicesCostsNames =
      response && response.house_hold_services_costs_names ? response.house_hold_services_costs_names : [];
    deductions.craftsmanServicesCosts =
      response && response.craftsman_services_costs ? response.craftsman_services_costs : [];
    deductions.craftsmanServicesCostsNames =
      response && response.craftsman_services_costs_names ? response.craftsman_services_costs_names : [];

    // Submodule 9.1:
    deductions.haveRiester = response && response.have_riester ? response.have_riester : false;
    deductions.socialSecurityNumber =
      response && response.social_security_number ? response.social_security_number : "";
    deductions.indirectlyFavored =
      response && response.indirectly_favored !== undefined ? response.indirectly_favored : false;
    deductions.agreementNumbers = response && response.agreement_numbers ? response.agreement_numbers : "";
    deductions.contributionsAmount = response && response.contributions_amount ? response.contributions_amount : "";
    deductions.lastYearIncome = response && response.last_year_income ? response.last_year_income : "";
    // deductions.kinderzulage1 = response && response.kinderzulage1 ? response.kinderzulage1 : '';
    // deductions.kinderzulage2 = response && response.kinderzulage2 ? response.kinderzulage2 : '';

    // Submodule 9.2
    deductions.haveHealthCosts = response && response.have_health_costs ? response.have_health_costs : false;
    deductions.healthCostsNames = response && response.health_costs_names ? response.health_costs_names : [];
    deductions.healthCosts = response && response.health_costs ? response.health_costs : [];
    deductions.healthRefundAmount = response && response.health_refund_amount ? response.health_refund_amount : [];

    //Submodule 9.3
    deductions.haveCareCosts = response && response.have_care_costs ? response.have_care_costs : false;
    deductions.careCostsNames = response && response.care_costs_names ? response.care_costs_names : [];
    deductions.careCosts = response && response.care_costs ? response.care_costs : [];
    deductions.careRefundAmount = response && response.care_refund_amount ? response.care_refund_amount : [];

    //SubModule 9.4
    deductions.haveDisability = response && response.have_disability ? response.have_disability : false;
    deductions.applyingForDisabilityWithLump =
      response &&
      response.applying_for_disability_with_lump !== null &&
      response.applying_for_disability_with_lump !== undefined
        ? response.applying_for_disability_with_lump
        : false;
    deductions.applyingForDisabilityWithoutLump =
      response &&
      response.applying_for_disability_without_lump !== null &&
      response.applying_for_disability_without_lump !== undefined
        ? response.applying_for_disability_without_lump
        : false;
    deductions.disabilityPercent =
      response && response.disability_percent ? response.disability_percent.toString() : "";
    deductions.disabilityFrom = response && response.disability_from ? [response.disability_from] : [];
    deductions.disabilityTo = response && response.disability_to ? [response.disability_to] : [];
    deductions.requireConstantCare =
      response && response.recruitment_costs !== null && response.require_constant_care !== undefined
        ? response.require_constant_care
        : false;
    deductions.mobilityImpaired =
      response && response.mobility_impaired !== null && response.mobility_impaired !== undefined
        ? response.mobility_impaired
        : false;
    deductions.disabilityCostsNames =
      response && response.disability_costs_names ? response.disability_costs_names : [];
    deductions.disabilityCosts = response && response.disability_costs ? response.disability_costs : [];
    deductions.disabilityRefundAmount =
      response && response.disability_refund_amount ? response.disability_refund_amount : [];

    // Submodule 7.9:
    deductions.hasDisabilityCarePflegegrad = response?.applying_for_disability_with_pflegegrad;
    deductions.disabilityPflegegradCaretaker = response?.disability_pflegegrad_caretaker ?? [];
    deductions.disabilityPflegegrad = response?.disability_pflegegrad ?? [];
    deductions.disabledPersonData = response?.disabled_person_data ?? [];

    // Submodule 9:
    deductions.contributionsToHealthInsurance =
      response && response.contributions_to_health_insurance ? response.contributions_to_health_insurance : "0.00";
    deductions.contributionsToSicknessBenefit =
      response && response.contributions_to_sickness_benefit ? response.contributions_to_sickness_benefit : "0.00";
    deductions.longTermCareInsuranceContributions =
      response && response.long_term_care_insurance_contributions
        ? response.long_term_care_insurance_contributions
        : "0.00";
    deductions.healthAndInsuranceContributionsRefunds =
      response && response.health_and_insurance_contributions_refunds
        ? response.health_and_insurance_contributions_refunds
        : "0.00";
    deductions.sicknessBenefitReimbursements =
      response && response.sickness_benefit_reimbursements ? response.sickness_benefit_reimbursements : "0.00";
    deductions.healthAndInsuranceContributionsGrants =
      response && response.health_and_insurance_contributions_grants
        ? response.health_and_insurance_contributions_grants
        : "0.00";
    deductions.electiveAndSupplementaryInsurance =
      response && response.elective_and_supplementary_insurance
        ? response.elective_and_supplementary_insurance
        : "0.00";

    // Submodule 10:
    deductions.contributionsToPrivateHealthInsurance =
      response && response.contributions_to_private_health_insurance
        ? response.contributions_to_private_health_insurance
        : "0.00";
    deductions.privateLongTermCareInsuranceContributions =
      response && response.private_long_term_care_insurance_contributions
        ? response.private_long_term_care_insurance_contributions
        : "0.00";
    deductions.healthAndPrivateInsuranceContributionsRefunds =
      response && response.health_and_private_insurance_contributions_refunds
        ? response.health_and_private_insurance_contributions_refunds
        : "0.00";
    deductions.healthAndPrivateInsuranceContributionsGrants =
      response && response.health_and_private_insurance_contributions_grants
        ? response.health_and_private_insurance_contributions_grants
        : "0.00";
    deductions.privateElectiveAndSupplementaryInsurance =
      response && response.private_elective_and_supplementary_insurance
        ? response.private_elective_and_supplementary_insurance
        : "0.00";
    deductions.additionalLongTermCareInsurance =
      response && response.additional_long_term_care_insurance ? response.additional_long_term_care_insurance : "0.00";

    // Submodule 11:
    deductions.contributionsToForeignHealthInsurance =
      response && response.contributions_to_foreign_health_insurance
        ? response.contributions_to_foreign_health_insurance
        : "0.00";
    deductions.contributionsToForeignSicknessBenefit =
      response && response.contributions_to_foreign_sickness_benefit
        ? response.contributions_to_foreign_sickness_benefit
        : "0.00";
    deductions.foreignLongTermCareInsuranceContributions =
      response && response.foreign_long_term_care_insurance_contributions
        ? response.foreign_long_term_care_insurance_contributions
        : "0.00";
    deductions.healthAndForeignInsuranceContributionsRefunds =
      response && response.health_and_foreign_insurance_contributions_refunds
        ? response.health_and_foreign_insurance_contributions_refunds
        : "0.00";
    deductions.foreignSicknessBenefitReimbursements =
      response && response.foreign_sickness_benefit_reimbursements
        ? response.foreign_sickness_benefit_reimbursements
        : "0.00";
    deductions.foreignElectiveAndSupplementaryInsurance =
      response && response.foreign_elective_and_supplementary_insurance
        ? response.foreign_elective_and_supplementary_insurance
        : "0.00";
    deductions.foreignSocialInsuranceEmployeeContributions =
      response && response.foreign_social_insurance_employee_contributions
        ? response.foreign_social_insurance_employee_contributions
        : "0.00";
    deductions.foreignSocialInsuranceEmployerContributions =
      response && response.foreign_social_insurance_employer_contributions
        ? response.foreign_social_insurance_employer_contributions
        : "0.00";

    // Submodule 12:
    deductions.haveCarTravels = response && response.have_car_travels ? response.have_car_travels : [];
    deductions.carTravelsDistance = response && response.car_travels_distance ? response.car_travels_distance : [];
    deductions.carTravelsNumber = response && response.car_travels_number ? response.car_travels_number : [];
    deductions.purchasedTravelTickets =
      response && response.purchased_travel_tickets ? response.purchased_travel_tickets : [];
    deductions.travelTicketsCosts = response && response.travel_tickets_costs ? response.travel_tickets_costs : [];
    deductions.haveAccommodationCosts =
      response && response.have_accommodation_costs ? response.have_accommodation_costs : [];
    deductions.accommodationCosts = response && response.accommodation_costs ? response.accommodation_costs : [];
    deductions.haveEmployerRefunds = response && response.have_employer_refunds ? response.have_employer_refunds : [];
    deductions.employerRefunds = response && response.employer_refunds ? response.employer_refunds : [];
    deductions.daysInOneDayTravel = response && response.days_in_one_day_travel ? response.days_in_one_day_travel : [];
    deductions.daysInTravelToWork = response && response.days_in_travel_to_work ? response.days_in_travel_to_work : [];
    deductions.daysInTravelFromWork =
      response && response.days_in_travel_from_work ? response.days_in_travel_from_work : [];
    deductions.daysInMultiDayTravel =
      response && response.days_in_multi_day_travel ? response.days_in_multi_day_travel : [];
    deductions.haveFreeMealsOnTravel =
      response && response.have_free_meals_on_travel ? response.have_free_meals_on_travel : [];
    deductions.haveRegularFreeMealsOnTravel =
      response && response.have_regular_free_meals_on_travel ? response.have_regular_free_meals_on_travel : [];
    deductions.mealsInTravel = response && response.meals_in_travel ? response.meals_in_travel : [];
    deductions.mealsInOneDayTravel =
      response && response.meals_in_one_day_travel ? response.meals_in_one_day_travel : [];
    deductions.freeMealsDietAmount = response && response.free_meals_diet_amount ? response.free_meals_diet_amount : [];
    deductions.travelAbroadCountry = response && response.travel_abroad_country ? response.travel_abroad_country : [];
    deductions.travelAbroadCity = response && response.travel_abroad_city ? response.travel_abroad_city : [];
    deductions.daysInOneDayTravelAbroad =
      response && response.days_in_one_day_travel_abroad ? response.days_in_one_day_travel_abroad : [];
    deductions.daysInTravelAbroadToWork =
      response && response.days_in_travel_abroad_to_work ? response.days_in_travel_abroad_to_work : [];
    deductions.daysInTravelAbroadFromWork =
      response && response.days_in_travel_abroad_from_work ? response.days_in_travel_abroad_from_work : [];
    deductions.daysInMultiDayTravelAbroad =
      response && response.days_in_multi_day_travel_abroad ? response.days_in_multi_day_travel_abroad : [];
    deductions.haveFreeMealsOnTravelAbroad =
      response && response.have_free_meals_on_travel_abroad ? response.have_free_meals_on_travel_abroad : [];
    deductions.haveRegularFreeMealsOnTravelAbroad =
      response && response.have_regular_free_meals_on_travel_abroad
        ? response.have_regular_free_meals_on_travel_abroad
        : [];
    deductions.mealsInTravelAbroad = response && response.meals_in_travel_abroad ? response.meals_in_travel_abroad : [];
    deductions.mealsInOneDayTravelAbroad =
      response && response.meals_in_one_day_travel_abroad ? response.meals_in_one_day_travel_abroad : [];
    deductions.freeMealsDietAmountAbroad =
      response && response.free_meals_diet_amount_abroad ? response.free_meals_diet_amount_abroad : [];
    deductions.driverLumpsum = response && response.driver_lumpsum ? response.driver_lumpsum : null;

    deductions.backwardCompatabilityForMealsInTravel();
    deductions.backwardCompatabilityForMealsInTravelAbroad();

    return deductions;
  }

  static fromStorage(deductionsStorage: Deductions): Deductions {
    const deductions = new Deductions();
    if (!deductionsStorage) {
      return deductions;
    }
    deductions.id = deductionsStorage.id;
    deductions.recruitmentCosts = deductionsStorage.recruitmentCosts;
    deductions.bankCosts = deductionsStorage.bankCosts;
    deductions.phoneAndInternetCosts = deductionsStorage.phoneAndInternetCosts;
    deductions.taxSettlementCosts = deductionsStorage.taxSettlementCosts;
    deductions.taxSettlementCostNames = deductionsStorage.taxSettlementCostNames;
    deductions.professionInsuranceCosts = deductionsStorage.professionInsuranceCosts;
    deductions.professionInsuranceCostNames = deductionsStorage.professionInsuranceCostNames;
    deductions.winterbauCosts = deductionsStorage.winterbauCosts;
    deductions.winterbauCostNames = deductionsStorage.winterbauCostNames;
    deductions.otherRecruitmentCosts = deductionsStorage.otherRecruitmentCosts;
    deductions.otherRecruitmentCostNames = deductionsStorage.otherRecruitmentCostNames;
    deductions.donationDeName = deductionsStorage.donationDeName;
    deductions.donationDeValue = deductionsStorage.donationDeValue;
    deductions.donationEuName = deductionsStorage.donationEuName;
    deductions.donationEuValue = deductionsStorage.donationEuValue;
    deductions.donationPoliticalName = deductionsStorage.donationPoliticalName;
    deductions.donationPoliticalValue = deductionsStorage.donationPoliticalValue;
    deductions.haveJobCostsLumpSum = deductionsStorage.haveJobCostsLumpSum;
    deductions.purchasedJobEquipment = deductionsStorage.purchasedJobEquipment;
    deductions.jobEquipmentCosts = deductionsStorage.jobEquipmentCosts;
    deductions.jobEquipmentCostNames = deductionsStorage.jobEquipmentCostNames;
    deductions.workwearWashingCost = deductionsStorage.workwearWashingCost;
    deductions.haveWorkwear = deductionsStorage.haveWorkwear;
    deductions.workwearCost = deductionsStorage.workwearCost;
    deductions.haveTrainingCosts = deductionsStorage.haveTrainingCosts;
    deductions.trainingCosts = deductionsStorage.trainingCosts;
    deductions.trainingCostNames = deductionsStorage.trainingCostNames;
    deductions.tradeUnionContributions = deductionsStorage.tradeUnionContributions;
    deductions.tradeUnionNames = deductionsStorage.tradeUnionNames;
    deductions.haveSeparatedHousehold = deductionsStorage.haveSeparatedHousehold;
    deductions.householdPostalCode = deductionsStorage.householdPostalCode;
    deductions.householdCity = deductionsStorage.householdCity;
    deductions.householdCountry = deductionsStorage.householdCountry;
    deductions.householdDateFrom = deductionsStorage.householdDateFrom;
    deductions.residenceAtPlaceOfEmploymentPostalCode = deductionsStorage.residenceAtPlaceOfEmploymentPostalCode;
    deductions.residenceAtPlaceOfEmploymentCity = deductionsStorage.residenceAtPlaceOfEmploymentCity;
    deductions.periodOfResidenceAtPlaceOfEmployment = deductionsStorage.periodOfResidenceAtPlaceOfEmployment;
    deductions.residenceAtPlaceOfEmploymentCosts = deductionsStorage.residenceAtPlaceOfEmploymentCosts;
    deductions.firstAndLastTravelKilometers = deductionsStorage.firstAndLastTravelKilometers;
    deductions.firstAndLastTravelTicketCosts = deductionsStorage.firstAndLastTravelTicketCosts;
    deductions.weekendTripsDistance = deductionsStorage.weekendTripsDistance;
    deductions.weekendTripsNumber = deductionsStorage.weekendTripsNumber;
    deductions.weekendTripsTicketCosts = deductionsStorage.weekendTripsTicketCosts;
    deductions.daysSpentInTravel = deductionsStorage.daysSpentInTravel;
    deductions.daysSpentAtPlaceOfEmployment = deductionsStorage.daysSpentAtPlaceOfEmployment;
    deductions.otherCosts = deductionsStorage.otherCosts;
    deductions.otherCostsName = deductionsStorage.otherCostsName;
    deductions.placeOfEmploymentAddress = deductionsStorage.placeOfEmploymentAddress;
    deductions.periodOfEmployment = deductionsStorage.periodOfEmployment;
    deductions.workingDaysPerWeek = deductionsStorage.workingDaysPerWeek;
    deductions.absencesAtWork = deductionsStorage.absencesAtWork;
    deductions.presencesAtWork = deductionsStorage.presencesAtWork;
    deductions.distanceFromWork = deductionsStorage.distanceFromWork;
    deductions.publicTransportCosts = deductionsStorage.publicTransportCosts;
    deductions.haveMovingExpanses = deductionsStorage.haveMovingExpanses;
    deductions.movingExpansesCosts = deductionsStorage.movingExpansesCosts;
    deductions.movingExpansesCostsNames = deductionsStorage.movingExpansesCostsNames;
    deductions.homeOfficeCosts = deductionsStorage.homeOfficeCosts;
    deductions.homeOfficeCostsNames = deductionsStorage.homeOfficeCostsNames;
    deductions.homeOfficeCovid19 = deductionsStorage.homeOfficeCovid19;
    deductions.homeOfficeCovid19DayInHome = deductionsStorage.homeOfficeCovid19DayInHome;
    deductions.houseHoldMiniJobsCosts = deductionsStorage.houseHoldMiniJobsCosts;
    deductions.houseHoldMiniJobsCostsNames = deductionsStorage.houseHoldMiniJobsCostsNames;
    deductions.houseHoldServicesCosts = deductionsStorage.houseHoldServicesCosts;
    deductions.houseHoldServicesCostsNames = deductionsStorage.houseHoldServicesCostsNames;
    deductions.craftsmanServicesCosts = deductionsStorage.craftsmanServicesCosts;
    deductions.craftsmanServicesCostsNames = deductionsStorage.craftsmanServicesCostsNames;
    deductions.haveRiester = deductionsStorage.haveRiester;
    deductions.socialSecurityNumber = deductionsStorage.socialSecurityNumber;
    deductions.retirementFundNumber = deductionsStorage.retirementFundNumber;
    deductions.indirectlyFavored = deductionsStorage.indirectlyFavored;
    deductions.agreementNumbers = deductionsStorage.agreementNumbers;
    deductions.contributionsAmount = deductionsStorage.contributionsAmount;
    deductions.lastYearIncome = deductionsStorage.lastYearIncome;
    deductions.haveHealthCosts = deductionsStorage.haveHealthCosts;
    deductions.healthCostsNames = deductionsStorage.healthCostsNames;
    deductions.healthCosts = deductionsStorage.healthCosts;
    deductions.healthRefundAmount = deductionsStorage.healthRefundAmount;
    deductions.haveCareCosts = deductionsStorage.haveCareCosts;
    deductions.careCostsNames = deductionsStorage.careCostsNames;
    deductions.careCosts = deductionsStorage.careCosts;
    deductions.careRefundAmount = deductionsStorage.careRefundAmount;
    deductions.careRefundAmount = deductionsStorage.careRefundAmount;
    deductions.haveDisability = deductionsStorage.haveDisability;
    deductions.applyingForDisabilityWithLump = deductionsStorage.applyingForDisabilityWithLump;
    deductions.applyingForDisabilityWithoutLump = deductionsStorage.applyingForDisabilityWithoutLump;
    deductions.applyingForDisabilityWithoutLump = deductionsStorage.applyingForDisabilityWithoutLump;
    deductions.disabilityPercent = deductionsStorage.disabilityPercent;
    deductions.disabilityFrom = deductionsStorage.disabilityFrom;
    deductions.disabilityTo = deductionsStorage.disabilityTo;
    deductions.requireConstantCare = deductionsStorage.requireConstantCare;
    deductions.mobilityImpaired = deductionsStorage.mobilityImpaired;
    deductions.disabilityCostsNames = deductionsStorage.disabilityCostsNames;
    deductions.disabilityCosts = deductionsStorage.disabilityCosts;
    deductions.disabilityRefundAmount = deductionsStorage.disabilityRefundAmount;
    deductions.hasDisabilityCarePflegegrad = deductionsStorage.hasDisabilityCarePflegegrad;
    deductions.disabilityPflegegradCaretaker = [...(deductionsStorage.disabilityPflegegradCaretaker || [])];
    deductions.disabilityPflegegrad = [...(deductionsStorage.disabilityPflegegrad || [])];
    deductions.disabledPersonData = [...(deductionsStorage.disabledPersonData || [])];
    deductions.contributionsToHealthInsurance = deductionsStorage.contributionsToHealthInsurance;
    deductions.contributionsToSicknessBenefit = deductionsStorage.contributionsToSicknessBenefit;
    deductions.longTermCareInsuranceContributions = deductionsStorage.longTermCareInsuranceContributions;
    deductions.healthAndInsuranceContributionsRefunds = deductionsStorage.healthAndInsuranceContributionsRefunds;
    deductions.sicknessBenefitReimbursements = deductionsStorage.sicknessBenefitReimbursements;
    deductions.healthAndInsuranceContributionsGrants = deductionsStorage.healthAndInsuranceContributionsGrants;
    deductions.electiveAndSupplementaryInsurance = deductionsStorage.electiveAndSupplementaryInsurance;
    deductions.contributionsToPrivateHealthInsurance = deductionsStorage.contributionsToPrivateHealthInsurance;
    deductions.privateLongTermCareInsuranceContributions = deductionsStorage.privateLongTermCareInsuranceContributions;
    deductions.healthAndPrivateInsuranceContributionsRefunds =
      deductionsStorage.healthAndPrivateInsuranceContributionsRefunds;
    deductions.healthAndPrivateInsuranceContributionsGrants =
      deductionsStorage.healthAndPrivateInsuranceContributionsGrants;
    deductions.privateElectiveAndSupplementaryInsurance = deductionsStorage.privateElectiveAndSupplementaryInsurance;
    deductions.additionalLongTermCareInsurance = deductionsStorage.additionalLongTermCareInsurance;
    deductions.contributionsToForeignHealthInsurance = deductionsStorage.contributionsToForeignHealthInsurance;
    deductions.contributionsToForeignSicknessBenefit = deductionsStorage.contributionsToForeignSicknessBenefit;
    deductions.foreignLongTermCareInsuranceContributions = deductionsStorage.foreignLongTermCareInsuranceContributions;
    deductions.healthAndForeignInsuranceContributionsRefunds =
      deductionsStorage.healthAndForeignInsuranceContributionsRefunds;
    deductions.foreignSicknessBenefitReimbursements = deductionsStorage.foreignSicknessBenefitReimbursements;
    deductions.foreignElectiveAndSupplementaryInsurance = deductionsStorage.foreignElectiveAndSupplementaryInsurance;
    deductions.foreignSocialInsuranceEmployeeContributions =
      deductionsStorage.foreignSocialInsuranceEmployeeContributions;
    deductions.foreignSocialInsuranceEmployerContributions =
      deductionsStorage.foreignSocialInsuranceEmployerContributions;
    deductions.haveCarTravels = deductionsStorage.haveCarTravels;
    deductions.carTravelsDistance = deductionsStorage.carTravelsDistance;
    deductions.carTravelsNumber = deductionsStorage.carTravelsNumber;
    deductions.purchasedTravelTickets = deductionsStorage.purchasedTravelTickets;
    deductions.travelTicketsCosts = deductionsStorage.travelTicketsCosts;
    deductions.haveAccommodationCosts = deductionsStorage.haveAccommodationCosts;
    deductions.accommodationCosts = deductionsStorage.accommodationCosts;
    deductions.haveEmployerRefunds = deductionsStorage.haveEmployerRefunds;
    deductions.employerRefunds = deductionsStorage.employerRefunds;
    deductions.daysInOneDayTravel = deductionsStorage.daysInOneDayTravel;
    deductions.daysInTravelToWork = deductionsStorage.daysInTravelToWork;
    deductions.daysInTravelFromWork = deductionsStorage.daysInTravelFromWork;
    deductions.daysInMultiDayTravel = deductionsStorage.daysInMultiDayTravel;
    deductions.haveFreeMealsOnTravel = deductionsStorage.haveFreeMealsOnTravel;
    deductions.haveRegularFreeMealsOnTravel = deductionsStorage.haveRegularFreeMealsOnTravel;
    deductions.mealsInTravel = deductionsStorage.mealsInTravel;
    deductions.mealsInTravel = deductionsStorage.mealsInTravel;
    deductions.mealsInOneDayTravel = deductionsStorage.mealsInOneDayTravel;
    deductions.freeMealsDietAmount = deductionsStorage.freeMealsDietAmount;
    deductions.travelAbroadCountry = deductionsStorage.travelAbroadCountry;
    deductions.travelAbroadCity = deductionsStorage.travelAbroadCity;
    deductions.daysInOneDayTravelAbroad = deductionsStorage.daysInOneDayTravelAbroad;
    deductions.daysInTravelAbroadToWork = deductionsStorage.daysInTravelAbroadToWork;
    deductions.daysInTravelAbroadFromWork = deductionsStorage.daysInTravelAbroadFromWork;
    deductions.daysInMultiDayTravelAbroad = deductionsStorage.daysInMultiDayTravelAbroad;
    deductions.haveFreeMealsOnTravelAbroad = deductionsStorage.haveFreeMealsOnTravelAbroad;
    deductions.haveRegularFreeMealsOnTravelAbroad = deductionsStorage.haveRegularFreeMealsOnTravelAbroad;
    deductions.mealsInTravelAbroad = deductionsStorage.mealsInTravelAbroad;
    deductions.mealsInOneDayTravelAbroad = deductionsStorage.mealsInOneDayTravelAbroad;
    deductions.freeMealsDietAmountAbroad = deductionsStorage.freeMealsDietAmountAbroad;
    deductions.driverLumpsum = deductionsStorage.driverLumpsum;

    deductions.backwardCompatabilityForMealsInTravel();
    deductions.backwardCompatabilityForMealsInTravelAbroad();

    return deductions;
  }

  toResponse(deductionFlag?: string): DeductionsResponse {
    const toFixed = (value: string) => {
      if (!value) {
        return "0.00";
      }
      return value.match(/^[0-9]+(.[0-9]{1,2})$/) ? parseFloat(value).toFixed(2) : value;
    };
    const toInt = (value: string) => parseFloat(value).toFixed(0);
    const toDateRangeResponse = (value: DateRange) => value.toResponse("");
    const response: DeductionsResponse = {};

    // Submodule 1:
    if (
      !deductionFlag ||
      deductionFlag === "hasRecruitmentCost" ||
      deductionFlag === DeductionWorkAndOfficeSteps.RECRUITMENT_COST
    ) {
      response.recruitment_costs = this.recruitmentCosts.map(toFixed);
    }

    if (
      !deductionFlag ||
      deductionFlag === "hasPhoneCost" ||
      deductionFlag === DeductionOtherCostsSteps.PHONE_AND_INTERNET_COSTS
    ) {
      response.phone_and_internet_costs = this.phoneAndInternetCosts.map(toFixed);
    }

    if (
      !deductionFlag ||
      deductionFlag === "hasProffesionalInsurance" ||
      deductionFlag === DeductionInsuranceSteps.PROFESSIONAL_AND_LIABILITY_INSURANCE
    ) {
      response.profession_insurance_costs = this.professionInsuranceCosts.map(toFixed);
      response.profession_insurance_cost_names = this.professionInsuranceCostNames;
    }

    if (
      !deductionFlag ||
      deductionFlag === "hasTaxSettlementCost" ||
      deductionFlag === DeductionOtherCostsSteps.TAX_RETURN_COSTS
    ) {
      response.tax_settlement_costs = this.taxSettlementCosts.map(toFixed);
      response.tax_settlement_cost_names = this.taxSettlementCostNames;
    }

    if (
      !deductionFlag ||
      deductionFlag === "hasWinterbauCost" ||
      deductionFlag === DeductionOtherCostsSteps.WINTERBAU_COSTS
    ) {
      response.winterbau_costs = this.winterbauCosts.map(toFixed);
      response.winterbau_cost_names = this.winterbauCostNames;
    }

    if (!deductionFlag || deductionFlag === "hasBankCost" || deductionFlag === DeductionOtherCostsSteps.BANK_COSTS) {
      response.bank_costs = this.bankCosts.map(toFixed);
    }

    if (!deductionFlag || deductionFlag === "hasOtherCost" || deductionFlag === DeductionOtherCostsSteps.OTHER_COSTS) {
      response.other_recruitment_costs = this.otherRecruitmentCosts.map(toFixed);
      response.other_recruitment_cost_names = this.otherRecruitmentCostNames;
    }

    //submodule donation GR
    if (
      !deductionFlag ||
      deductionFlag === "hasDonationsDe" ||
      deductionFlag === DeductionDonationsAndMembershipsSteps.DONATIONS_IN_GERMANY
    ) {
      response.donation_de_name = this.donationDeName;
      response.donation_de_value = this.donationDeValue.map(toFixed);
    }

    //submodule donation UE
    if (
      !deductionFlag ||
      deductionFlag === "hasDonationsEu" ||
      deductionFlag === DeductionDonationsAndMembershipsSteps.DONATIONS_IN_EU
    ) {
      response.donation_eu_name = this.donationEuName;
      response.donation_eu_value = this.donationEuValue.map(toFixed);
    }

    //submodule donation Political
    if (
      !deductionFlag ||
      deductionFlag === "hasDonationsPolitical" ||
      deductionFlag === DeductionDonationsAndMembershipsSteps.DONATIONS_TO_POLITICAL_PARTIES
    ) {
      response.donation_political_name = this.donationPoliticalName;
      response.donation_political_value = this.donationPoliticalValue.map(toFixed);
    }

    // Submodule 2:
    if (
      !deductionFlag ||
      deductionFlag === "hasEquipmentCost" ||
      deductionFlag === DeductionWorkAndOfficeSteps.EQUIPMENT_COST
    ) {
      response.have_job_costs_lump_sum = this.haveJobCostsLumpSum;
      response.purchased_job_equipment = this.purchasedJobEquipment;
      response.job_equipment_costs = this.jobEquipmentCosts.map(toFixed);
      response.job_equipment_cost_names = this.jobEquipmentCostNames;
      response.workwear_washing_cost = this.workwearWashingCost || "0";
      response.have_workwear = this.haveWorkwear;
      response.workwear_cost = this.workwearCost || "0";
      response.have_training_costs = this.haveTrainingCosts;
      response.training_costs = this.trainingCosts.map(toFixed);
      response.training_cost_names = this.trainingCostNames;
    }

    // Submodule 3:
    if (
      !deductionFlag ||
      deductionFlag === "hasPremiumForTradeUnions" ||
      deductionFlag === DeductionWorkAndOfficeSteps.TRADE_UNION_FEES
    ) {
      response.trade_union_contributions = this.tradeUnionContributions.map(toFixed);
      response.trade_union_names = this.tradeUnionNames;
    }

    // Submodule 4:
    if (
      !deductionFlag ||
      deductionFlag === "hasDoubleHousehold" ||
      deductionFlag === DeductionHouseSteps.DOUBLE_HOUSEHOLD
    ) {
      response.have_separated_household = this.haveSeparatedHousehold;
      response.household_postal_code = this.householdPostalCode ? this.householdPostalCode : [];
      response.household_city = this.householdCity ? this.householdCity : [];
      response.household_country = this.householdCountry ? this.householdCountry : [];
      response.household_date_from =
        this.householdDateFrom && this.householdDateFrom ? this.householdDateFrom.map(toResponseDate) : [];

      response.residence_at_place_of_employment_postal_code = this.residenceAtPlaceOfEmploymentPostalCode
        ? this.residenceAtPlaceOfEmploymentPostalCode
        : [];
      response.residence_at_place_of_employment_city = this.residenceAtPlaceOfEmploymentCity
        ? this.residenceAtPlaceOfEmploymentCity
        : [];
      response.period_of_residence_at_place_of_employment = this.periodOfResidenceAtPlaceOfEmployment
        ? this.periodOfResidenceAtPlaceOfEmployment.map(toDateRangeResponse)
        : [];
      response.residence_at_place_of_employment_costs = this.residenceAtPlaceOfEmploymentCosts
        ? this.residenceAtPlaceOfEmploymentCosts.map(toFixed)
        : [];

      response.first_and_last_travel_kilometers = this.firstAndLastTravelKilometers;
      response.first_and_last_travel_ticket_costs = this.firstAndLastTravelTicketCosts
        ? this.firstAndLastTravelTicketCosts.map(toFixed)
        : [];

      response.weekend_trips_distance = this.weekendTripsDistance;
      response.weekend_trips_number = this.weekendTripsNumber;
      response.weekend_trips_ticket_costs = this.weekendTripsTicketCosts
        ? this.weekendTripsTicketCosts.map(toFixed)
        : [];

      response.days_spent_in_travel = this.daysSpentInTravel;
      response.days_spent_at_place_of_employment = this.daysSpentAtPlaceOfEmployment;
      response.other_costs = this.otherCosts ? this.otherCosts.map(toFixed) : [];
      response.other_costs_name = this.otherCostsName ? this.otherCostsName : [];
    }
    // Submodule 5:
    if (
      !deductionFlag ||
      deductionFlag === "hasWorkTripCost" ||
      deductionFlag === DeductionWorkAndOfficeSteps.WORK_TRIP_COST
    ) {
      response.place_of_employment_address = this.placeOfEmploymentAddress;
      response.period_of_employment = this.periodOfEmployment ? this.periodOfEmployment.map(toDateRangeResponse) : [];
      response.working_days_per_week = this.workingDaysPerWeek;
      response.absences_at_work = this.absencesAtWork;
      response.presences_at_work = this.presencesAtWork;
      response.distance_from_work = this.distanceFromWork;
      response.public_transport_costs = this.publicTransportCosts;
    }

    // Submodule 6:
    if (!deductionFlag || deductionFlag === "hasRemovalExpanses" || deductionFlag === DeductionHouseSteps.MOVING) {
      response.have_moving_expanses = this.haveMovingExpanses;
      response.moving_expanses_costs = this.movingExpansesCosts;
      response.moving_expanses_costs_names = this.movingExpansesCostsNames;
    }

    // Submodule 7:
    if (
      !deductionFlag ||
      deductionFlag === "hasHomeOffice" ||
      deductionFlag === DeductionWorkAndOfficeSteps.HOME_OFFICE
    ) {
      if (RecognizerHelper.isTrue(this.homeOfficeCovid19)) {
        this.homeOfficeCosts = [];
        this.homeOfficeCostsNames = [];
      } else {
        this.homeOfficeCovid19 = [];
        this.homeOfficeCovid19DayInHome = [];
      }

      response.home_office_costs = this.homeOfficeCosts;
      response.home_office_costs_names = this.homeOfficeCostsNames;
      response.home_office_covid19 = this.homeOfficeCovid19;
      response.home_office_covid19_day_in_home = this.homeOfficeCovid19DayInHome;
    }

    // Submodule 8:
    if (
      !deductionFlag ||
      deductionFlag === "hasHouseHoldMiniJobsCosts" ||
      deductionFlag === DeductionHomeServiceSteps.HOUSEHOLD_MINI_JOBS_COSTS
    ) {
      response.house_hold_mini_jobs_costs = this.houseHoldMiniJobsCosts;
      response.house_hold_mini_jobs_costs_names = this.houseHoldMiniJobsCostsNames;
    }
    if (
      !deductionFlag ||
      deductionFlag === "hasHouseHoldServicesCosts" ||
      deductionFlag === DeductionHomeServiceSteps.HOUSEHOLD_SERVICES_COSTS
    ) {
      response.house_hold_services_costs = this.houseHoldServicesCosts;
      response.house_hold_services_costs_names = this.houseHoldServicesCostsNames;
    }
    if (
      !deductionFlag ||
      deductionFlag === "hasCraftsmanServicesCosts" ||
      deductionFlag === DeductionHomeServiceSteps.CRAFTSMAN_SERVICES_COSTS
    ) {
      response.craftsman_services_costs = this.craftsmanServicesCosts;
      response.craftsman_services_costs_names = this.craftsmanServicesCostsNames;
    }

    // Submodule 9.1:

    if (!deductionFlag || deductionFlag === "hasRiester" || deductionFlag === DeductionInsuranceSteps.RIESTER) {
      response.have_riester = this.haveRiester;
      response.social_security_number = this.socialSecurityNumber ? this.socialSecurityNumber : null;
      response.indirectly_favored = this.indirectlyFavored;
      response.agreement_numbers = NumberFormatHelper.integer(this.agreementNumbers) || null;
      response.contributions_amount = NumberFormatHelper.toFixed2(this.contributionsAmount) || null;
      response.last_year_income = NumberFormatHelper.toFixed2(this.lastYearIncome) || null;
      // response.kinderzulage1 = this.kinderzulage1 || this.kinderzulage1.toString() === '0' ? parseInt(this.kinderzulage1).toString() : null;
      // response.kinderzulage2 = this.kinderzulage2 || this.kinderzulage2.toString() === '0' ? parseInt(this.kinderzulage2).toString() : null;
    }

    // Submodule 9.2
    if (!deductionFlag || deductionFlag === "hasHealthCosts" || deductionFlag === DeductionHealthSteps.HEALTH_COSTS) {
      response.have_health_costs = this.haveHealthCosts;
      response.health_costs_names = this.healthCostsNames;
      response.health_costs = this.healthCosts;
      response.health_refund_amount = this.healthRefundAmount;
    }

    // Submodule 9.3
    if (!deductionFlag || deductionFlag === "hasCareCosts" || deductionFlag === DeductionHealthSteps.CARE_COSTS) {
      response.have_care_costs = this.haveCareCosts;
      response.care_costs_names = this.careCostsNames;
      response.care_costs = this.careCosts;
      response.care_refund_amount = this.careRefundAmount;
    }

    // Submodule 9.4
    if (
      !deductionFlag ||
      deductionFlag === "hasDisability" ||
      deductionFlag === DeductionHealthSteps.DISABILITY_COSTS
    ) {
      response.have_disability = this.haveDisability;
      response.applying_for_disability_with_lump = this.applyingForDisabilityWithLump;
      response.applying_for_disability_without_lump = this.applyingForDisabilityWithoutLump;
      response.disability_percent = this.disabilityPercent ? this.disabilityPercent : "0";
      response.disability_from = null;
      response.disability_to = null;
      response.require_constant_care = this.requireConstantCare;
      response.mobility_impaired = this.mobilityImpaired;
      response.disability_costs_names = this.disabilityCostsNames;
      response.disability_costs = this.disabilityCosts;
      response.disability_refund_amount = this.disabilityRefundAmount;
    }

    // Submodule 7.9
    if (!deductionFlag || deductionFlag === DeductionHealthSteps.DISABILITY_CARE_PFLEGEGRAD) {
      response.applying_for_disability_with_pflegegrad = this.hasDisabilityCarePflegegrad ?? false;
      response.disability_pflegegrad_caretaker = this.disabilityPflegegradCaretaker ?? [];
      response.disability_pflegegrad = this.disabilityPflegegrad ?? [];
      response.disabled_person_data = this.disabledPersonData ?? [];
    }

    // Submodule 9:
    if (
      !deductionFlag ||
      deductionFlag === "hasPublicInsurance" ||
      deductionFlag === DeductionInsuranceSteps.STATUTORY_INSURANCE
    ) {
      response.contributions_to_health_insurance = this.contributionsToHealthInsurance;
      response.contributions_to_sickness_benefit = this.contributionsToSicknessBenefit;
      response.long_term_care_insurance_contributions = this.longTermCareInsuranceContributions;
      response.health_and_insurance_contributions_refunds = this.healthAndInsuranceContributionsRefunds;
      response.sickness_benefit_reimbursements = parseFloat(this.healthAndInsuranceContributionsRefunds)
        ? this.sicknessBenefitReimbursements
        : "0.00";
      response.health_and_insurance_contributions_grants = this.healthAndInsuranceContributionsGrants;
      response.elective_and_supplementary_insurance = this.electiveAndSupplementaryInsurance;
    }

    // Submodule 10:
    if (
      !deductionFlag ||
      deductionFlag === "hasPrivateInsurance" ||
      deductionFlag === DeductionInsuranceSteps.PRIVATE_INSURANCE
    ) {
      response.contributions_to_private_health_insurance = this.contributionsToPrivateHealthInsurance;
      response.private_long_term_care_insurance_contributions = this.privateLongTermCareInsuranceContributions;
      response.health_and_private_insurance_contributions_refunds = this.healthAndPrivateInsuranceContributionsRefunds;
      response.health_and_private_insurance_contributions_grants = this.healthAndPrivateInsuranceContributionsGrants;
      response.private_elective_and_supplementary_insurance = this.privateElectiveAndSupplementaryInsurance;
      response.additional_long_term_care_insurance = this.additionalLongTermCareInsurance;
    }

    // Submodule 11:
    if (
      !deductionFlag ||
      deductionFlag === "hasForeignInsurance" ||
      deductionFlag === DeductionInsuranceSteps.FOREIGN_INSURANCE
    ) {
      response.contributions_to_foreign_health_insurance = this.contributionsToForeignHealthInsurance;
      response.contributions_to_foreign_sickness_benefit = this.contributionsToForeignSicknessBenefit;
      response.foreign_long_term_care_insurance_contributions = this.foreignLongTermCareInsuranceContributions;
      response.health_and_foreign_insurance_contributions_refunds = this.healthAndForeignInsuranceContributionsRefunds;
      response.foreign_sickness_benefit_reimbursements = parseFloat(this.healthAndForeignInsuranceContributionsRefunds)
        ? this.foreignSicknessBenefitReimbursements
        : "0.00";
      response.foreign_elective_and_supplementary_insurance = this.foreignElectiveAndSupplementaryInsurance;
    }

    // Submodule 11a:
    if (
      !deductionFlag ||
      deductionFlag === "hasForeignSocialInsurance" ||
      deductionFlag === DeductionInsuranceSteps.FOREIGN_SOCIAL_SECURITY
    ) {
      response.foreign_social_insurance_employee_contributions = this.foreignSocialInsuranceEmployeeContributions;
      response.foreign_social_insurance_employer_contributions = this.foreignSocialInsuranceEmployerContributions;
      response.has_foreign_social_insurance =
        Boolean(parseFloat(this.foreignSocialInsuranceEmployeeContributions)) ||
        Boolean(parseFloat(this.foreignSocialInsuranceEmployerContributions));
    }

    // Submodule 12:
    if (
      !deductionFlag ||
      deductionFlag === "hasBusinessTrips" ||
      deductionFlag === DeductionBusinessTripsSteps.BUSINESS_TRIP_COST
    ) {
      response.have_business_trips = RecognizerHelper.isTrue(this.haveCarTravels);
      response.have_car_travels = RecognizerHelper.isNotEmpty(this.haveCarTravels) ? this.haveCarTravels : [];
      response.car_travels_distance =
        RecognizerHelper.isTrue(this.haveCarTravels) && this.carTravelsDistance
          ? this.carTravelsDistance.map(toFixed)
          : [];
      response.car_travels_number =
        RecognizerHelper.isTrue(this.haveCarTravels) && this.carTravelsNumber ? this.carTravelsNumber.map(toInt) : [];
      response.purchased_travel_tickets = this.purchasedTravelTickets ? this.purchasedTravelTickets : [];
      response.travel_tickets_costs = this.travelTicketsCosts ? this.travelTicketsCosts.map(toFixed) : [];
      response.have_accommodation_costs = RecognizerHelper.isNotEmpty(this.haveAccommodationCosts)
        ? this.haveAccommodationCosts
        : [];
      response.accommodation_costs =
        RecognizerHelper.isTrue(this.haveAccommodationCosts) && this.accommodationCosts
          ? this.accommodationCosts.map(toFixed)
          : [];
      response.have_employer_refunds = RecognizerHelper.isNotEmpty(this.haveEmployerRefunds)
        ? this.haveEmployerRefunds
        : [];
      response.employer_refunds =
        RecognizerHelper.isTrue(this.haveEmployerRefunds) && this.employerRefunds
          ? this.employerRefunds.map(toFixed)
          : [];
    }
    if (
      !deductionFlag ||
      deductionFlag === "hasFreeMealsOnTravel" ||
      deductionFlag === DeductionBusinessTripsSteps.DAILY_ALLOWANCE
    ) {
      response.days_in_one_day_travel = this.daysInOneDayTravel ? this.daysInOneDayTravel.map(toInt) : [];
      response.days_in_travel_to_work = this.daysInTravelToWork ? this.daysInTravelToWork.map(toInt) : [];
      response.days_in_travel_from_work = this.daysInTravelFromWork ? this.daysInTravelFromWork.map(toInt) : [];
      response.days_in_multi_day_travel = this.daysInMultiDayTravel ? this.daysInMultiDayTravel.map(toInt) : [];
      response.have_free_meals_on_travel = RecognizerHelper.isNotEmpty(this.haveFreeMealsOnTravel)
        ? this.haveFreeMealsOnTravel
        : [];
      response.have_regular_free_meals_on_travel = RecognizerHelper.isNotEmpty(this.haveRegularFreeMealsOnTravel)
        ? this.haveRegularFreeMealsOnTravel
        : [];
      response.meals_in_travel = this.mealsInTravel ? this.mealsInTravel : [];
      response.meals_in_one_day_travel = this.mealsInOneDayTravel ? this.mealsInOneDayTravel : [];
      response.free_meals_diet_amount = this.freeMealsDietAmount ? this.freeMealsDietAmount.map(toFixed) : [];
    }
    if (
      !deductionFlag ||
      deductionFlag === "hasFreeMealsOnTravelAbroad" ||
      deductionFlag === DeductionBusinessTripsSteps.DAILY_ALLOWANCE_ABROAD
    ) {
      response.travel_abroad_country = this.travelAbroadCountry ? this.travelAbroadCountry : [];
      response.travel_abroad_city = this.travelAbroadCity ? this.travelAbroadCity : [];
      response.days_in_one_day_travel_abroad = this.daysInOneDayTravelAbroad
        ? this.daysInOneDayTravelAbroad.map(toInt)
        : [];
      response.days_in_travel_abroad_to_work = this.daysInTravelAbroadToWork
        ? this.daysInTravelAbroadToWork.map(toInt)
        : [];
      response.days_in_travel_abroad_from_work = this.daysInTravelAbroadFromWork
        ? this.daysInTravelAbroadFromWork.map(toInt)
        : [];
      response.days_in_multi_day_travel_abroad = this.daysInMultiDayTravelAbroad
        ? this.daysInMultiDayTravelAbroad.map(toInt)
        : [];
      response.have_free_meals_on_travel_abroad = RecognizerHelper.isNotEmpty(this.haveFreeMealsOnTravelAbroad)
        ? this.haveFreeMealsOnTravelAbroad
        : [];
      response.have_regular_free_meals_on_travel_abroad = RecognizerHelper.isNotEmpty(
        this.haveRegularFreeMealsOnTravelAbroad
      )
        ? this.haveRegularFreeMealsOnTravelAbroad
        : [];
      response.meals_in_travel_abroad = this.mealsInTravelAbroad ? this.mealsInTravelAbroad : [];
      response.meals_in_one_day_travel_abroad = this.mealsInOneDayTravelAbroad ? this.mealsInOneDayTravelAbroad : [];
      response.free_meals_diet_amount_abroad = this.freeMealsDietAmountAbroad
        ? this.freeMealsDietAmountAbroad.map(toFixed)
        : [];
    }
    if (
      !deductionFlag ||
      deductionFlag === DeductionFlagOptions.DRIVER_LUMPSUM ||
      deductionFlag === DeductionBusinessTripsSteps.DRIVER_LUMP_SUM
    ) {
      response.driver_lumpsum = this.driverLumpsum ? this.driverLumpsum : 0;
    }
    return response;
  }

  public deleteDeductionByFlag(flagName: string): void {
    switch (flagName) {
      case "hasHouseHoldMiniJobsCosts":
        this.removeAllHouseHoldMiniJobsCost();
        break;
      case "hasHouseHoldServicesCosts":
        this.removeAllHouseHoldServicesCost();
        break;
      case "hasCraftsmanServicesCosts":
        this.removeAllCraftsmanServicesCost();
        break;
      case "hasRemovalExpanses":
        this.haveMovingExpanses = false;
        this.removeAllMovingExpansesCost();
        break;
      case "hasDoubleHousehold":
        this.clearDoubleHouseholdGroup();
        break;
      case "hasEquipmentCost":
        this.clearEquipmentCostGroup();
        this.haveJobCostsLumpSum = false;

        this.purchasedJobEquipment = false;
        this.jobEquipmentCostNames = [];
        this.jobEquipmentCosts = [];

        this.haveWorkwear = false;
        this.workwearCost = "0";
        this.workwearWashingCost = "0";

        this.haveTrainingCosts = false;
        this.trainingCostNames = [];
        this.trainingCosts = [];
        break;
      case "hasPremiumForTradeUnions":
        this.tradeUnionContributions = [];
        this.tradeUnionNames = [];
        break;
      case "hasHomeOffice":
        this.homeOfficeCosts = [];
        this.homeOfficeCostsNames = [];
        this.homeOfficeCovid19 = [];
        this.homeOfficeCovid19DayInHome = [];
        break;
      case "hasRecruitmentCost":
        this.recruitmentCosts = [];
        break;
      case "hasWorkTripCost":
        this.absencesAtWork = [];
        this.distanceFromWork = [];
        this.placeOfEmploymentAddress = [];
        this.presencesAtWork = [];
        this.publicTransportCosts = [];
        this.workingDaysPerWeek = [];
        this.periodOfEmployment = [];
        break;
      case "hasBusinessTrips":
        this.haveCarTravels = [];
        this.carTravelsDistance = [];
        this.carTravelsNumber = [];

        this.purchasedTravelTickets = [];
        this.travelTicketsCosts = [];

        this.accommodationCosts = [];
        this.haveAccommodationCosts = [];

        this.employerRefunds = [];
        this.haveEmployerRefunds = [];
        break;
      case "hasFreeMealsOnTravel":
        this.daysInMultiDayTravel = [];
        this.daysInOneDayTravel = [];
        this.daysInTravelToWork = [];
        this.daysInTravelFromWork = [];
        this.freeMealsDietAmount = [];
        this.haveRegularFreeMealsOnTravel = [];
        this.haveFreeMealsOnTravel = [];
        this.mealsInTravel = [];
        this.mealsInOneDayTravel = [];
        break;
      case "hasFreeMealsOnTravelAbroad":
        this.daysInMultiDayTravelAbroad = [];
        this.daysInOneDayTravelAbroad = [];
        this.daysInTravelAbroadToWork = [];
        this.daysInTravelAbroadFromWork = [];
        this.freeMealsDietAmountAbroad = [];
        this.haveFreeMealsOnTravelAbroad = [];
        this.mealsInTravelAbroad = [];
        this.mealsInOneDayTravelAbroad = [];
        this.travelAbroadCity = [];
        this.travelAbroadCountry = [];
        this.haveRegularFreeMealsOnTravelAbroad = [];
        break;
      case DeductionFlagOptions.DRIVER_LUMPSUM:
        this.driverLumpsum = null;
        break;
      case "hasRiester":
        this.clearRiester();
        break;
      case "hasPrivateInsurance":
        this.clearPrivateInsuranceCosts();
        break;
      case "hasPublicInsurance":
        this.clearPublicInsuranceCosts();
        break;
      case "hasForeignInsurance":
        this.clearForeignInsuranceCosts();
        break;
      case "hasForeignSocialInsurance":
        this.clearForeignSocialInsuranceCosts();
        break;
      case "hasProffesionalInsurance":
        this.removeAllProfessionalInsuranceCost();
        break;
      case "hasCareCosts":
        this.haveCareCosts = false;
        this.careCostsNames = [];
        this.careCosts = [];
        this.careRefundAmount = [];
        break;
      case "hasHealthCosts":
        this.haveHealthCosts = false;
        this.healthCostsNames = [];
        this.healthCosts = [];
        this.healthRefundAmount = [];
        break;
      case DeductionHealthSteps.DISABILITY_CARE_PFLEGEGRAD:
        this.clearDisabilityCarePflegegrad();
        break;
      case "hasDisability":
        this.haveDisability = false;
        this.applyingForDisabilityWithLump = false;
        this.applyingForDisabilityWithoutLump = false;
        this.disabilityPercent = "0";
        this.disabilityFrom = [];
        this.disabilityTo = [];
        this.requireConstantCare = false;
        this.mobilityImpaired = false;
        this.disabilityCostsNames = [];
        this.disabilityCosts = [];
        this.disabilityRefundAmount = [];
        break;
      case "hasTaxSettlementCost":
        this.taxSettlementCostNames = [];
        this.taxSettlementCosts = [];
        break;
      case "hasPhoneCost":
        this.phoneAndInternetCosts = [];
        break;
      case "hasWinterbauCost":
        this.winterbauCostNames = [];
        this.winterbauCosts = [];
        break;
      case "hasBankCost":
        this.bankCosts = [];
        break;
      case "hasOtherCost":
        this.otherRecruitmentCostNames = [];
        this.otherRecruitmentCosts = [];
        break;
      case "hasDonationsDe":
        this.donationDeName = [];
        this.donationDeValue = [];
        break;
      case "hasDonationsEu":
        this.donationEuName = [];
        this.donationEuValue = [];
        break;
      case "hasDonationsPolitical":
        this.donationPoliticalName = [];
        this.donationPoliticalValue = [];
        break;
    }
  }

  public addHouseHoldMiniJobsCost(): void {
    this.houseHoldMiniJobsCosts.push("0.00");
    this.houseHoldMiniJobsCostsNames.push("");
  }

  public removeHouseHoldMiniJobsCost(index: number): void {
    this.houseHoldMiniJobsCosts.splice(index, 1);
    this.houseHoldMiniJobsCostsNames.splice(index, 1);
  }

  public removeAllHouseHoldMiniJobsCost(): void {
    this.houseHoldMiniJobsCosts = [];
    this.houseHoldMiniJobsCostsNames = [];
  }

  public addHouseHoldServicesCost(): void {
    this.houseHoldServicesCosts.push("0.00");
    this.houseHoldServicesCostsNames.push("");
  }

  public removeHouseHoldServicesCost(index: number): void {
    this.houseHoldServicesCosts.splice(index, 1);
    this.houseHoldServicesCostsNames.splice(index, 1);
  }

  public removeAllHouseHoldServicesCost(): void {
    this.houseHoldServicesCosts = [];
    this.houseHoldServicesCostsNames = [];
  }

  public addCraftsmanServicesCost(): void {
    this.craftsmanServicesCosts.push("0.00");
    this.craftsmanServicesCostsNames.push("");
  }

  public removeCraftsmanServicesCost(index: number): void {
    this.craftsmanServicesCosts.splice(index, 1);
    this.craftsmanServicesCostsNames.splice(index, 1);
  }

  public removeAllCraftsmanServicesCost(): void {
    this.craftsmanServicesCosts = [];
    this.craftsmanServicesCostsNames = [];
  }

  public addMovingExpansesCost(): void {
    this.movingExpansesCosts.push("0.00");
    this.movingExpansesCostsNames.push("");
  }

  public removeMovingExpansesCost(index: number): void {
    this.movingExpansesCosts.splice(index, 1);
    this.movingExpansesCostsNames.splice(index, 1);
  }

  public removeAllMovingExpansesCost(excludeHaveMovingExpansesFlag = false): void {
    this.movingExpansesCosts = [];
    this.movingExpansesCostsNames = [];

    if (!excludeHaveMovingExpansesFlag) {
      this.haveMovingExpanses = false;
    }
  }

  public addDonationsDe(): void {
    this.donationDeValue.push("0.00");
    this.donationDeName.push("");
  }

  public removeDonationsDe(index: number): void {
    this.donationDeValue.splice(index, 1);
    this.donationDeName.splice(index, 1);
  }

  public removeAllDonationsDe(): void {
    this.donationDeValue = [];
    this.donationDeName = [];
  }

  public addDonationsEu(): void {
    this.donationEuValue.push("0.00");
    this.donationEuName.push("");
  }

  public removeDonationsEu(index: number): void {
    this.donationEuValue.splice(index, 1);
    this.donationEuName.splice(index, 1);
  }

  public removeAllDonationsEu(): void {
    this.donationEuValue = [];
    this.donationEuName = [];
  }

  public addDonationsPolitical(): void {
    this.donationPoliticalValue.push("0.00");
    this.donationPoliticalName.push("");
  }

  public removeDonationsPolitical(index: number): void {
    this.donationPoliticalValue.splice(index, 1);
    this.donationPoliticalName.splice(index, 1);
  }

  public removeAllDonationsPolitical(): void {
    this.donationPoliticalValue = [];
    this.donationPoliticalName = [];
  }

  public addPremiumForTradeUnions(): void {
    this.tradeUnionContributions.push("0.00");
    this.tradeUnionNames.push("");
  }

  public removePremiumForTradeUnions(index: number): void {
    this.tradeUnionContributions.splice(index, 1);
    this.tradeUnionNames.splice(index, 1);
  }

  public removeAllPremiumForTradeUnions(): void {
    this.tradeUnionContributions = [];
    this.tradeUnionNames = [];
  }

  public addHealthCost(): void {
    this.healthCosts.push("0.00");
    this.healthRefundAmount.push("0.00");
    this.healthCostsNames.push("");
  }

  public removeHealthCost(index: number): void {
    this.healthCosts.splice(index, 1);
    this.healthRefundAmount.splice(index, 1);
    this.healthCostsNames.splice(index, 1);
  }

  public removeAllHealthCost(): void {
    this.healthCosts = [];
    this.healthRefundAmount = [];
    this.healthCostsNames = [];
  }

  public addCareCost(): void {
    this.careCosts.push("0.00");
    this.careRefundAmount.push("0.00");
    this.careCostsNames.push("");
  }

  public removeCareCost(index: number): void {
    this.careCosts.splice(index, 1);
    this.careRefundAmount.splice(index, 1);
    this.careCostsNames.splice(index, 1);
  }

  public removeAllCareCost(): void {
    this.careCosts = [];
    this.careRefundAmount = [];
    this.careCostsNames = [];
  }

  public addDisabilityCost() {
    this.disabilityCostsNames.push("");
    this.disabilityCosts.push("0.00");
    this.disabilityRefundAmount.push("0.00");
  }

  public removeDisabilityCost(index: number) {
    this.disabilityCostsNames.splice(index, 1);
    this.disabilityCosts.splice(index, 1);
    this.disabilityRefundAmount.splice(index, 1);
  }

  public removeAllDisabilityCost() {
    this.disabilityCostsNames = [];
    this.disabilityCosts = [];
    this.disabilityRefundAmount = [];
  }

  public clearDisabilityCostOptions() {
    this.removeAllDisabilityCost();
    this.disabilityPercent = "";
    this.requireConstantCare = false;
    this.mobilityImpaired = false;
    this.applyingForDisabilityWithoutLump = false;
    this.applyingForDisabilityWithLump = false;
  }

  public clearDisabilityCarePflegegrad() {
    this.hasDisabilityCarePflegegrad = false;
    this.disabilityPflegegradCaretaker = [];
    this.disabilityPflegegrad = [];
    this.disabledPersonData = [];
  }

  public addDisabilityCarePflegegrad(): void {
    this.hasDisabilityCarePflegegrad = true;
    this.disabilityPflegegradCaretaker.push(null);
    this.disabilityPflegegrad.push(null);
    this.disabledPersonData.push("");
  }

  public removeDisabilityCarePflegegrad(index: number): void {
    this.disabilityPflegegradCaretaker.splice(index, 1);
    this.disabilityPflegegrad.splice(index, 1);
    this.disabledPersonData.splice(index, 1);
  }

  public get isHomeOfficeCostExists(): boolean {
    return !!this.homeOfficeCosts.length;
  }

  public addHomeOfficeCost(isCovidYear = false): void {
    this.homeOfficeCosts.push("0.00");
    this.homeOfficeCostsNames.push("");
    if (!this.homeOfficeCovid19.length) {
      this.homeOfficeCovid19.push(isCovidYear);
    }
    if (!this.homeOfficeCovid19DayInHome.length) {
      this.homeOfficeCovid19DayInHome.push("0");
    }
  }

  public removeHomeOfficeCost(index: number): void {
    this.homeOfficeCosts.splice(index, 1);
    this.homeOfficeCostsNames.splice(index, 1);
    this.homeOfficeCovid19.splice(index, 1);
    this.homeOfficeCovid19DayInHome.splice(index, 1);
  }

  public removeAllHomeOfficeCost(): void {
    this.homeOfficeCosts = [];
    this.homeOfficeCostsNames = [];
    this.homeOfficeCovid19 = [];
    this.homeOfficeCovid19DayInHome = [];
  }

  public addRecruitmentCost(): void {
    this.recruitmentCosts.push("0.00");
  }

  public removeRecruitmentCost(index: number): void {
    this.recruitmentCosts.splice(index, 1);
  }

  public removeAllRecruitmentCost(): void {
    this.recruitmentCosts = [];
  }

  public addBusinessTripsCost(): void {
    this.haveCarTravels.push(false);
    this.purchasedTravelTickets.push(false);
    this.haveAccommodationCosts.push(false);
    this.haveEmployerRefunds.push(false);
  }

  public removeBusinessTripsCost(index: number): void {
    this.haveCarTravels.splice(index, 1);
    this.carTravelsDistance.splice(index, 1);
    this.carTravelsNumber.splice(index, 1);
    this.purchasedTravelTickets.splice(index, 1);
    this.travelTicketsCosts.splice(index, 1);
    this.haveAccommodationCosts.splice(index, 1);
    this.accommodationCosts.splice(index, 1);
    this.haveEmployerRefunds.splice(index, 1);
    this.employerRefunds.splice(index, 1);
  }

  public removeAllBusinessTripsCost(): void {
    this.haveCarTravels = [];
    this.carTravelsDistance = [];
    this.carTravelsNumber = [];
    this.purchasedTravelTickets = [];
    this.travelTicketsCosts = [];
    this.haveAccommodationCosts = [];
    this.accommodationCosts = [];
    this.haveEmployerRefunds = [];
    this.employerRefunds = [];
  }

  public addWorkTripCost(): void {
    this.periodOfEmployment.push(new DateRange());
    this.placeOfEmploymentAddress.push("");
    this.distanceFromWork.push("0");
    this.absencesAtWork.push("0");
    this.presencesAtWork.push("0");
    this.publicTransportCosts.push("0");
    this.workingDaysPerWeek.push("0");
  }

  public removeWorkTripCost(index: number): void {
    this.periodOfEmployment.splice(index, 1);
    this.placeOfEmploymentAddress.splice(index, 1);
    this.distanceFromWork.splice(index, 1);
    this.absencesAtWork.splice(index, 1);
    this.presencesAtWork.splice(index, 1);
    this.publicTransportCosts.splice(index, 1);
    this.workingDaysPerWeek.splice(index, 1);
  }

  public removeAllWorkTripCost(): void {
    this.periodOfEmployment = [];
    this.placeOfEmploymentAddress = [];
    this.distanceFromWork = [];
    this.absencesAtWork = [];
    this.presencesAtWork = [];
    this.publicTransportCosts = [];
    this.workingDaysPerWeek = [];
  }

  public addFreeMealsOnTravel(): void {
    this.daysInOneDayTravel.push("0");
    this.daysInTravelToWork.push("0");
    this.daysInTravelFromWork.push("0");
    this.daysInMultiDayTravel.push("0");
    this.haveFreeMealsOnTravel.push(false);
    this.haveRegularFreeMealsOnTravel.push(false);
    this.mealsInTravel.push(new Array(9).fill(false));
    this.mealsInOneDayTravel.push(new Array(3).fill(false));
    this.freeMealsDietAmount.push("0.0");
  }

  public removeFreeMealsOnTravel(index: number): void {
    this.daysInOneDayTravel.splice(index, 1);
    this.daysInTravelToWork.splice(index, 1);
    this.daysInTravelFromWork.splice(index, 1);
    this.daysInMultiDayTravel.splice(index, 1);
    this.haveFreeMealsOnTravel.splice(index, 1);
    this.haveRegularFreeMealsOnTravel.splice(index, 1);
    this.mealsInTravel.splice(index, 1);
    this.mealsInOneDayTravel.splice(index, 1);
    this.freeMealsDietAmount.splice(index, 1);
  }

  public removeAllFreeMealsOnTravel(): void {
    this.daysInOneDayTravel = [];
    this.daysInTravelToWork = [];
    this.daysInTravelFromWork = [];
    this.daysInMultiDayTravel = [];
    this.haveFreeMealsOnTravel = [];
    this.haveRegularFreeMealsOnTravel = [];
    this.mealsInTravel = [];
    this.mealsInOneDayTravel = [];
    this.freeMealsDietAmount = [];
  }

  public addFreeMealsOnTravelAbroad(): void {
    this.travelAbroadCountry.push(ABROAD_INCOME_DEFAULT_COUNTRY);
    this.travelAbroadCity.push(City.TRAVEL_ABROAD_OTHER_CITY.code);
    this.daysInOneDayTravelAbroad.push("0");
    this.daysInTravelAbroadToWork.push("0");
    this.daysInTravelAbroadFromWork.push("0");
    this.daysInMultiDayTravelAbroad.push("0");
    this.haveFreeMealsOnTravelAbroad.push(false);
    this.haveRegularFreeMealsOnTravelAbroad.push(false);
    this.mealsInTravelAbroad.push(new Array(9).fill(false));
    this.mealsInOneDayTravelAbroad.push(new Array(3).fill(false));
    this.freeMealsDietAmountAbroad.push("0.0");
  }

  public removeFreeMealsOnTravelAbroad(index: number): void {
    this.travelAbroadCountry.splice(index, 1);
    this.travelAbroadCity.splice(index, 1);
    this.daysInOneDayTravelAbroad.splice(index, 1);
    this.daysInTravelAbroadToWork.splice(index, 1);
    this.daysInTravelAbroadFromWork.splice(index, 1);
    this.daysInMultiDayTravelAbroad.splice(index, 1);
    this.haveFreeMealsOnTravelAbroad.splice(index, 1);
    this.haveRegularFreeMealsOnTravelAbroad.splice(index, 1);
    this.mealsInTravelAbroad.splice(index, 1);
    this.mealsInOneDayTravelAbroad.splice(index, 1);
    this.freeMealsDietAmountAbroad.splice(index, 1);
  }

  public removeAllFreeMealsOnTravelAbroad(): void {
    this.travelAbroadCountry = [];
    this.travelAbroadCity = [];
    this.daysInOneDayTravelAbroad = [];
    this.daysInTravelAbroadToWork = [];
    this.daysInTravelAbroadFromWork = [];
    this.daysInMultiDayTravelAbroad = [];
    this.haveFreeMealsOnTravelAbroad = [];
    this.haveRegularFreeMealsOnTravelAbroad = [];
    this.mealsInTravelAbroad = [];
    this.mealsInOneDayTravelAbroad = [];
    this.freeMealsDietAmountAbroad = [];
  }

  public addProfessionalInsuranceCost(): void {
    this.professionInsuranceCosts.push("0.00");
    this.professionInsuranceCostNames.push("");
  }

  public removeProfessionalInsuranceCost(index: number): void {
    this.professionInsuranceCosts.splice(index, 1);
    this.professionInsuranceCostNames.splice(index, 1);
  }

  public removeAllProfessionalInsuranceCost(): void {
    this.professionInsuranceCosts = [];
    this.professionInsuranceCostNames = [];
  }

  public addTaxSettlementCost(): void {
    this.taxSettlementCosts.push("0.00");
    this.taxSettlementCostNames.push(TAX_SETTLEMENT_COST_NAME_DEFAULT);
  }

  public removeTaxSettlementCost(index: number): void {
    this.taxSettlementCosts.splice(index, 1);
    this.taxSettlementCostNames.splice(index, 1);
  }

  public removeAllTaxSettlementCost(): void {
    this.taxSettlementCosts = [];
    this.taxSettlementCostNames = [];
  }

  public addPhoneCost(): void {
    this.phoneAndInternetCosts.push("0.00");
  }

  public removePhoneCost(index: number): void {
    this.phoneAndInternetCosts.splice(index, 1);
  }

  public removeAllPhoneCost(): void {
    this.phoneAndInternetCosts = [];
  }

  public addWinterbauCost(): void {
    this.winterbauCostNames.push(WINTERBAU_COST_NAME_DEFAULT);
    this.winterbauCosts.push("0.00");
  }

  public removeWinterbauCost(index: number): void {
    this.winterbauCostNames.splice(index, 1);
    this.winterbauCosts.splice(index, 1);
  }

  public removeAllWinterbauCost(): void {
    this.winterbauCostNames = [];
    this.winterbauCosts = [];
  }

  public addBankCost(): void {
    this.bankCosts.push("0.00");
  }

  public removeBankCost(index: number): void {
    this.bankCosts.splice(index, 1);
  }

  public removeAllBankCost(): void {
    this.bankCosts = [];
  }

  public addOtherCost(): void {
    this.otherRecruitmentCosts.push("0.00");
    this.otherRecruitmentCostNames.push("");
  }

  public removeOtherRecruitmentCost(index: number): void {
    this.otherRecruitmentCosts.splice(index, 1);
    this.otherRecruitmentCostNames.splice(index, 1);
  }

  public removeAllOtherCost(): void {
    this.otherRecruitmentCosts = [];
    this.otherRecruitmentCostNames = [];
  }

  public setReisterAmounts(): void {
    this.contributionsAmount = this.contributionsAmount ? this.contributionsAmount : "0.00";
    this.lastYearIncome = this.lastYearIncome ? this.lastYearIncome : "0.00";
  }

  public addTrainingCost(): void {
    this.trainingCosts.push("0.00");
    this.trainingCostNames.push("");
  }

  public removeTrainingCost(index: number): void {
    this.trainingCosts.splice(index, 1);
    this.trainingCostNames.splice(index, 1);
  }

  public removeAllTrainingCost(): void {
    this.trainingCosts = [];
    this.trainingCostNames = [];
  }

  public addJobEquipmentCost(): void {
    this.jobEquipmentCosts.push("0.00");
    this.jobEquipmentCostNames.push("");
  }

  public removeJobEquipmentCost(index: number): void {
    this.jobEquipmentCosts.splice(index, 1);
    this.jobEquipmentCostNames.splice(index, 1);
  }

  public removeAllJobEquipmentCost(): void {
    this.jobEquipmentCosts = [];
    this.jobEquipmentCostNames = [];
  }

  public clearWorkWearCost(): void {
    this.workwearCost = undefined;
    this.workwearWashingCost = undefined;
  }

  public clearEquipmentCostGroup(): void {
    this.haveTrainingCosts = false;
    this.removeAllTrainingCost();
    this.purchasedJobEquipment = false;
    this.removeAllJobEquipmentCost();
    this.haveWorkwear = false;
    this.clearWorkWearCost();
  }

  public addDoubleHouseholdCost(): void {
    this.householdPostalCode.push("");
    this.householdCity.push("");
    this.householdCountry.push(DEFAULT_COUNTRY);
    this.householdDateFrom.push("");
    this.residenceAtPlaceOfEmploymentPostalCode.push("");
    this.residenceAtPlaceOfEmploymentCity.push("");
    this.periodOfResidenceAtPlaceOfEmployment.push(new DateRange());
    this.residenceAtPlaceOfEmploymentCosts.push("0.00");
    this.firstAndLastTravelKilometers.push("0");
    this.firstAndLastTravelTicketCosts.push("0.00");
    this.weekendTripsDistance.push("0");
    this.weekendTripsNumber.push("0");
    this.weekendTripsTicketCosts.push("0.00");
    this.daysSpentInTravel.push("0");
    this.daysSpentAtPlaceOfEmployment.push("0");
    this.otherCosts.push("0.00");
    this.otherCostsName.push("");
  }

  public removeDoubleHouseholdCost(index: number): void {
    this.householdPostalCode.splice(index, 1);
    this.householdCity.splice(index, 1);
    this.householdCountry.splice(index, 1);
    this.householdDateFrom.splice(index, 1);
    this.residenceAtPlaceOfEmploymentPostalCode.splice(index, 1);
    this.residenceAtPlaceOfEmploymentCity.splice(index, 1);
    this.periodOfResidenceAtPlaceOfEmployment.splice(index, 1);
    this.residenceAtPlaceOfEmploymentCosts.splice(index, 1);
    this.firstAndLastTravelKilometers.splice(index, 1);
    this.firstAndLastTravelTicketCosts.splice(index, 1);
    this.weekendTripsDistance.splice(index, 1);
    this.weekendTripsNumber.splice(index, 1);
    this.weekendTripsTicketCosts.splice(index, 1);
    this.daysSpentInTravel.splice(index, 1);
    this.daysSpentAtPlaceOfEmployment.splice(index, 1);
    this.otherCosts.splice(index, 1);
    this.otherCostsName.splice(index, 1);
  }

  public clearDoubleHouseholdGroup(): void {
    this.haveSeparatedHousehold = false;
    this.daysSpentAtPlaceOfEmployment = [];
    this.daysSpentInTravel = [];
    this.firstAndLastTravelKilometers = [];
    this.firstAndLastTravelTicketCosts = [];
    this.householdCity = [];
    this.householdCountry = [];
    this.householdDateFrom = [];
    this.householdPostalCode = [];
    this.otherCosts = [];
    this.otherCostsName = [];
    this.residenceAtPlaceOfEmploymentCity = [];
    this.residenceAtPlaceOfEmploymentCosts = [];
    this.residenceAtPlaceOfEmploymentPostalCode = [];
    this.periodOfResidenceAtPlaceOfEmployment = [];
    this.weekendTripsDistance = [];
    this.weekendTripsNumber = [];
    this.weekendTripsTicketCosts = [];
  }

  public clearPrivateInsuranceCosts(): void {
    this.additionalLongTermCareInsurance = "0.00";
    this.contributionsToPrivateHealthInsurance = "0.00";
    this.healthAndPrivateInsuranceContributionsGrants = "0.00";
    this.healthAndPrivateInsuranceContributionsRefunds = "0.00";
    this.privateElectiveAndSupplementaryInsurance = "0.00";
    this.privateLongTermCareInsuranceContributions = "0.00";
  }

  public clearPublicInsuranceCosts(): void {
    this.contributionsToHealthInsurance = "0.00";
    this.contributionsToSicknessBenefit = "0.00";
    this.electiveAndSupplementaryInsurance = "0.00";
    this.healthAndInsuranceContributionsGrants = "0.00";
    this.healthAndInsuranceContributionsRefunds = "0.00";
    this.longTermCareInsuranceContributions = "0.00";
    this.sicknessBenefitReimbursements = "0.00";
  }

  public clearForeignInsuranceCosts(): void {
    this.contributionsToForeignHealthInsurance = "0.00";
    this.contributionsToForeignSicknessBenefit = "0.00";
    this.foreignElectiveAndSupplementaryInsurance = "0.00";
    this.foreignLongTermCareInsuranceContributions = "0.00";
    this.foreignSicknessBenefitReimbursements = "0.00";
    this.healthAndForeignInsuranceContributionsRefunds = "0.00";
  }

  public clearForeignSocialInsuranceCosts(): void {
    this.foreignSocialInsuranceEmployeeContributions = "0";
    this.foreignSocialInsuranceEmployerContributions = "0";
  }

  public clearRiester(): void {
    this.haveRiester = false;
    this.socialSecurityNumber = "";
    this.retirementFundNumber = "";
    this.indirectlyFavored = false;
    this.agreementNumbers = "";
    this.contributionsAmount = "0";
    this.lastYearIncome = "0";
    // this.kinderzulage1 = '';
    // this.kinderzulage2 = '';
  }

  private backwardCompatabilityForMealsInTravel(): void {
    if (
      this.mealsInTravelAbroad &&
      this.mealsInTravelAbroad.length &&
      (!this.mealsInOneDayTravelAbroad || !this.mealsInOneDayTravelAbroad.length)
    ) {
      this.mealsInTravelAbroad.forEach(() => {
        this.mealsInOneDayTravelAbroad.push(new Array(3).fill(false));
      });
    }
  }

  private backwardCompatabilityForMealsInTravelAbroad(): void {
    if (
      this.mealsInTravel &&
      this.mealsInTravel.length &&
      (!this.mealsInOneDayTravel || !this.mealsInOneDayTravel.length)
    ) {
      this.mealsInTravel.forEach(() => {
        this.mealsInOneDayTravel.push(new Array(3).fill(false));
      });
    }
  }
}

export const deductionsFlags = [
  "hasEquipmentCost",
  "hasPremiumForTradeUnions",
  "hasRemovalExpanses",
  "hasHomeOffice",
  "hasBudgetaryEmploymentCost",
  "hasDoubleHousehold",
  "hasWorkTripCost",
  "hasPrivateInsurance",
  "hasPublicInsurance",
  "hasForeignInsurance",
  "hasForeignSocialInsurance",
  "hasRecruitmentCost",
  "hasProffesionalInsurance",
  "hasRecruitmentCost",
  "hasWinterbauCost",
  "hasBankCost",
  "hasOtherCost",
  "hasBusinessTrips",
  "hasPhoneCost",
  "hasDonationsDe",
  "hasDonationsEu",
  "hasDonationsPolitical",
  "hasRiester",
  "hasHealthCosts",
  "hasCareCosts",
  "hasDisability"
];

export interface CostsChoiceNameInterface {
  optionName: string;
  valueName: string;
}

export const TAX_SETTLEMENT_COST_NAME_DEFAULT = "Steuersoftware bzw. Steuerberatung";
export const WINTERBAU_COST_NAME_DEFAULT = "Winterbeschäftigungsumlage";

export const TRAINING_COST_NAME_CHOICES: CostsChoiceNameInterface[] = [
  {
    optionName: "DEDUCTION_COSTS_NAMES.KURSGEBUHREN",
    valueName: "Kursgebühren"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.PRUFUNGSGEBUHREN",
    valueName: "Prüfungsgebühren"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.FACHLITERARUR",
    valueName: "Fachliteratur"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.SCHREIBMATERIAL",
    valueName: "Schreibmaterial"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.FAHRTKOSTEN",
    valueName: "Fahrtkosten"
  }
];

export const MOVING_EXPANSES_COST_NAME_CHOICES: CostsChoiceNameInterface[] = [
  {
    optionName: "DEDUCTION_COSTS_NAMES.KOSTEN_FUR_EIN_UMZUGSUNTERNEHMEN",
    valueName: "Kosten für ein Umzugsunternehmen"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.TRANSPORTKOSTEN",
    valueName: "Transportkosten"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.REISEKOSTEN_IM_ZUSAMMENHANG",
    valueName: "Reisekosten im Zusammenhang mit der Wohnungssuche bzw. am Umzugstag"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.DOPPELTE_MIETZAHLUNGEN",
    valueName: "Doppelte Mietzahlungen"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.MARKLERGEBUHREN",
    valueName: "Maklergebühren"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.KOSTEN_FUR_ZUSATZUNTERRICH",
    valueName: "Kosten für den Zusatzunterricht der Kinder"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.KOSTEN_FUR_EINEN_HERD",
    valueName: "Kosten für einen Herd / Ofen"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.SONSTIGE_KOSTEN_ZUSAMMENHANG",
    valueName: "Sonstige Kosten im Zusammenhang mit dem Umzug"
  }
];

export const HOME_OFFICE_COST_NAME_CHOICES: CostsChoiceNameInterface[] = [
  {
    optionName: "DEDUCTION_COSTS_NAMES.ANTELIGE_MIETKOSTEN",
    valueName: "Antelige Mietkosten"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.ANTELIGE_BETRIEBSKOSTEN",
    valueName: "Anteilige Betriebskosten"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.ANTEILIGE_GEBAUDEABEABSCGREIBUNG",
    valueName: "Anteilige Gebäudeabschreibung (bei Eigentumswohnung)"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.ANTEILIGE_HAUSGELD",
    valueName: "Anteiliges Hausgeld (bei Eigentumswohnung)"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.ANTEILIGE_SCHULDZINSEN",
    valueName: "Anteilige Schuldzinsen (bei Eigentumswohnung)"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.ANTEILIGE_GRUNDERWERBSTEUER",
    valueName: "Anteilige Grunderwerbsteuer (bei Eigentumswohnung)"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.ANTEILIGE_VERSICHERUNGEN",
    valueName: "Anteilige Kosten für Versicherungen (Hausrat, Gebäudeversicherung)"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.AUSSTATTUNG_HAUSLICHEN",
    valueName: "Kosten der Ausstattung des häuslichen Arbeitszimmers"
  }
];

export interface ProfessionInsuranceChoicesNameInterface {
  optionName: string;
  valueName: string;
}

export const PROFESSION_INSURANCE_CHOICES: ProfessionInsuranceChoicesNameInterface[] = [
  {
    optionName: "DEDUCTION_COSTS_NAMES.PROFESSIONAL_LIABILITY_INSURANCE",
    valueName: "Berufshaftpflichtversicherung"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.PROFESSION_INSURANCE_COSTS",
    valueName: "Rechtschutzversicherung Beruf"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.OCCUPATIONAL_DISABILITY_INSURANCE",
    valueName: "Erwerbs-/Berufsunfähigkeitsversicherung"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.UNEMPLOYMENT_INSURANCE",
    valueName: "Versicherung gegen Arbeitslosigkeit"
  },
  {
    optionName: "DEDUCTION_COSTS_NAMES.PRIVATE_LIABILITY_INSURANCE",
    valueName: "Unfall-/Haftpflicht-/Risikoversicherung"
  }
];

export interface HealthCostNamesInterface {
  optionName: string;
  valueName: string;
}

export const HEALTH_COST_NAMES: HealthCostNamesInterface[] = [
  { optionName: "HEALTH_COST.HEALTH_COST_NAMES.ARZTKOSTEN", valueName: "arztkosten" },
  { optionName: "HEALTH_COST.HEALTH_COST_NAMES.ZAHNARZTKOSTEN", valueName: "zahnarztkosten" },
  { optionName: "HEALTH_COST.HEALTH_COST_NAMES.ZAHNERSATZ", valueName: "zahnersatz" },
  { optionName: "HEALTH_COST.HEALTH_COST_NAMES.HEILPRAKTIKER", valueName: "heilpraktiker" },
  { optionName: "HEALTH_COST.HEALTH_COST_NAMES.LOGOPADIE", valueName: "logopädie" },
  { optionName: "HEALTH_COST.HEALTH_COST_NAMES.PSYCHOTHERAPIE", valueName: "psychotherapie" },
  { optionName: "HEALTH_COST.HEALTH_COST_NAMES.PHYSIOTHERAPIE", valueName: "physiotherapie" },
  { optionName: "HEALTH_COST.HEALTH_COST_NAMES.HORGERAT", valueName: "hörgerät" },
  { optionName: "HEALTH_COST.HEALTH_COST_NAMES.FITNESSSTUDIO", valueName: "fitnessstudio (krankheitsbedingt)" },
  {
    optionName: "HEALTH_COST.HEALTH_COST_NAMES.ALTERNATIVE_BEHANDLUNGSMETHODEN",
    valueName: "alternative behandlungsmethoden"
  },
  {
    optionName: "HEALTH_COST.HEALTH_COST_NAMES.KRANKHEITSBEDINGTER_UMBAU_DER_WOHNUNG",
    valueName: "krankheitsbedingter umbau der wohnung"
  },
  { optionName: "HEALTH_COST.HEALTH_COST_NAMES.KURKOSTEN", valueName: "kurkosten" },
  {
    optionName: "HEALTH_COST.HEALTH_COST_NAMES.KRANKHEITSBEDINGTE_FAHRTKOSTEN",
    valueName: "krankheitsbedingte fahrtkosten"
  },
  { optionName: "HEALTH_COST.HEALTH_COST_NAMES.KRANKENHAUSKOSTEN", valueName: "krankenhauskosten" }
];

export interface CareCostsNameInterface {
  optionName: string;
  valueName: string;
}

export const CARE_COST_NAMES: CareCostsNameInterface[] = [
  { optionName: "CARE_COST.CARE_COST_NAMES.HAUSLICHE_PFLEGE", valueName: "häusliche pflege" },
  { optionName: "CARE_COST.CARE_COST_NAMES.PFLEGEHEIM", valueName: "pflegeheim" },
  { optionName: "CARE_COST.CARE_COST_NAMES.ALTENHEIM", valueName: "altenheim" },
  { optionName: "CARE_COST.CARE_COST_NAMES.FAHRTKOSTEN", valueName: "fahrtkosten" }
];

export interface DisabilityCostNamesInterface {
  optionName: string;
  valueName: string;
}

export const DISABILITY_COST_NAMES: DisabilityCostNamesInterface[] = [
  {
    optionName: "DISABILITY_DEDUCTION.DISABILITY_COST_NAMES.BEHINDERUNGSBEDINGTE_UMBAUKOSTEN",
    valueName: "behinderungsbedingte umbaukosten"
  },
  {
    optionName: "DISABILITY_DEDUCTION.DISABILITY_COST_NAMES.BEHINDERUNGSBEDINGTE_BEHANDLUNGSKOSTEN",
    valueName: "behinderungsbedingte behandlungskosten"
  }
];

export interface GermanHolidaysInterface {
  year: number;
  holidayDate: string;
}

export const GERMAN_HOLIDAYS: GermanHolidaysInterface[] = [
  { year: 2017, holidayDate: "2017.04.14" },
  { year: 2017, holidayDate: "2017.04.17" },
  { year: 2017, holidayDate: "2017.05.01" },
  { year: 2017, holidayDate: "2017.05.25" },
  { year: 2017, holidayDate: "2017.06.05" },
  { year: 2017, holidayDate: "2017.10.03" },
  { year: 2017, holidayDate: "2017.10.31" },
  { year: 2017, holidayDate: "2017.12.25" },
  { year: 2017, holidayDate: "2017.12.26" },

  { year: 2018, holidayDate: "2018.01.01" },
  { year: 2018, holidayDate: "2018.03.30" },
  { year: 2018, holidayDate: "2018.04.02" },
  { year: 2018, holidayDate: "2018.05.01" },
  { year: 2018, holidayDate: "2018.05.10" },
  { year: 2018, holidayDate: "2018.05.21" },
  { year: 2018, holidayDate: "2018.10.03" },
  { year: 2018, holidayDate: "2018.12.25" },
  { year: 2018, holidayDate: "2018.12.26" },

  { year: 2019, holidayDate: "2019.01.01" },
  { year: 2019, holidayDate: "2019.04.19" },
  { year: 2019, holidayDate: "2019.04.22" },
  { year: 2019, holidayDate: "2019.05.01" },
  { year: 2019, holidayDate: "2019.05.30" },
  { year: 2019, holidayDate: "2019.06.10" },
  { year: 2019, holidayDate: "2019.10.03" },
  { year: 2019, holidayDate: "2019.12.25" },
  { year: 2019, holidayDate: "2019.12.26" },

  { year: 2020, holidayDate: "2020.01.01" },
  { year: 2020, holidayDate: "2020.04.10" },
  { year: 2020, holidayDate: "2020.04.13" },
  { year: 2020, holidayDate: "2020.05.01" },
  { year: 2020, holidayDate: "2020.05.21" },
  { year: 2020, holidayDate: "2020.06.01" },
  { year: 2020, holidayDate: "2020.12.25" },

  { year: 2021, holidayDate: "2021.01.01" },
  { year: 2021, holidayDate: "2021.04.02" },
  { year: 2021, holidayDate: "2021.04.04" },
  { year: 2021, holidayDate: "2021.05.13" },
  { year: 2021, holidayDate: "2021.05.23" },

  { year: 2022, holidayDate: "2022.04.15" },
  { year: 2022, holidayDate: "2022.04.18" },
  { year: 2022, holidayDate: "2022.05.26" },
  { year: 2022, holidayDate: "2022.06.06" },
  { year: 2022, holidayDate: "2022.10.03" },
  { year: 2022, holidayDate: "2022.12.26" }
];

export const HOME_OFFICE_COVID_YEARS = [2020, 2021, 2022, 2023, 2024];

export enum DeductionGroups {
  WORK_AND_OFFICE = "workAndOffice",
  BUSINESS_TRIPS = "businessTrips",
  HOUSE = "house",
  HOME_SERVICE = "homeService",
  INSURANCE = "insurance",
  DONATIONS_AND_MEMBERSHIPS = "donationsAndMemberships",
  OTHER_COSTS = "otherCosts",
  HEALTH = "health"
}

export enum DeductionWorkAndOfficeSteps {
  MAIN = "main",
  EQUIPMENT_COST = "equipmentCost",
  TRADE_UNION_FEES = "premiumForTradeUnions",
  HOME_OFFICE = "homeOffice",
  RECRUITMENT_COST = "recruitmentCost",
  WORK_TRIP_COST = "workTripCost"
}

export enum DeductionBusinessTripsSteps {
  MAIN = "main",
  BUSINESS_TRIP_COST = "businessTripCost",
  DAILY_ALLOWANCE = "dailyAllowance",
  DAILY_ALLOWANCE_ABROAD = "dailyAllowanceAbroad",
  DRIVER_LUMP_SUM = "driverLumpSum"
}

export enum DeductionHouseSteps {
  MAIN = "main",
  MOVING = "moving",
  DOUBLE_HOUSEHOLD = "doubleHousehold"
}

export enum DeductionHomeServiceSteps {
  MAIN = "main",
  HOUSEHOLD_MINI_JOBS_COSTS = "householdMiniJobsCosts",
  HOUSEHOLD_SERVICES_COSTS = "householdServicesCosts",
  CRAFTSMAN_SERVICES_COSTS = "craftsmanServicesCosts"
}

export enum DeductionInsuranceSteps {
  MAIN = "main",
  RIESTER = "riester",
  PRIVATE_INSURANCE = "privateInsurance",
  STATUTORY_INSURANCE = "statutoryInsurance",
  FOREIGN_INSURANCE = "foreignInsurance",
  FOREIGN_SOCIAL_SECURITY = "foreignSocialSecurity",
  PROFESSIONAL_AND_LIABILITY_INSURANCE = "professionalAndLiabilityInsurance"
}

export enum DeductionDonationsAndMembershipsSteps {
  MAIN = "main",
  DONATIONS_IN_GERMANY = "donationsInGermany",
  DONATIONS_IN_EU = "donationsInEu",
  DONATIONS_TO_POLITICAL_PARTIES = "donationsToPoliticalParties"
}

export enum DeductionOtherCostsSteps {
  MAIN = "main",
  TAX_RETURN_COSTS = "taxReturnCosts",
  PHONE_AND_INTERNET_COSTS = "phoneAndInternetCosts",
  WINTERBAU_COSTS = "winterbauCosts",
  BANK_COSTS = "bankCosts",
  OTHER_COSTS = "otherCosts"
}

export enum DeductionHealthSteps {
  MAIN = "main",
  HEALTH_COSTS = "healthCosts",
  CARE_COSTS = "careCosts",
  DISABILITY_COSTS = "disabilityCosts",
  DISABILITY_CARE_PFLEGEGRAD = "disabilityCareCosts"
}

export type DeductionSteps =
  | DeductionWorkAndOfficeSteps
  | DeductionBusinessTripsSteps
  | DeductionHouseSteps
  | DeductionHomeServiceSteps
  | DeductionInsuranceSteps
  | DeductionDonationsAndMembershipsSteps
  | DeductionOtherCostsSteps
  | DeductionHealthSteps;

export const SHOW_RIESTER_SOCIAL_SECURITY_NUMBERS_FIELD_YEARS = [2016, 2017, 2018];
export const SHOW_RIESTER_AGREEMENT_NUMBERS_FIELD_YEARS = [2016, 2017, 2018];
export const SHOW_ADDITIONAL_LONG_TERM_CARE_INSURANCE_YEARS = [2016, 2017, 2018, 2019];
