import {Action} from '@ngrx/store';


export const SET_DEDUCTIONS_INFO = '[Deductions info] Set deductions info';
export const RESET_DEDUCTIONS_INFO = '[Deductions info] Reset deductions info';
export const SET_SPOUSE_DEDUCTIONS_INFO = '[Deductions info] Set spouse deductions info';
export const RESET_SPOUSE_DEDUCTIONS_INFO = '[Deductions info] Reset spouse deductions info';

export class UpdateDeductionsInfoAction implements Action {
  readonly type = SET_DEDUCTIONS_INFO;

  constructor(public payload: string) { }
}

export class ResetDeductionsInfoAction implements Action {
  readonly type = RESET_DEDUCTIONS_INFO;

  constructor() { }
}

export class UpdateSpouseDeductionsInfoAction implements Action {
  readonly type = SET_SPOUSE_DEDUCTIONS_INFO;

  constructor(public payload: string) { }
}

export class ResetSpouseDeductionsInfoAction implements Action {
  readonly type = RESET_SPOUSE_DEDUCTIONS_INFO;

  constructor() { }
}

export type Actions =
  UpdateDeductionsInfoAction | ResetDeductionsInfoAction | UpdateSpouseDeductionsInfoAction | ResetSpouseDeductionsInfoAction;
