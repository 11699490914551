<div class="home-service-info">
  <div class="main-text row">
    <span>{{'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.TEXT.TOP_TEXT_1' | translate}}</span>
    <span>{{'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.TEXT.TOP_TEXT_2' | translate}}</span>
  </div>
  <div class="list-box row">
    <h3 class="list-box-title">{{'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_1.TITLE' | translate}}</h3>
    <ul class="list">
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_1.ELEMENT_1' | translate"></span></li>
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_1.ELEMENT_2' | translate"></span></li>
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_1.ELEMENT_3' | translate"></span></li>
    </ul>
  </div>
  <div class="list-box row">
    <h3 class="list-box-title">{{'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_2.TITLE' | translate}}</h3>
    <ul class="list">
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_2.ELEMENT_1' | translate"></span></li>
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_2.ELEMENT_2' | translate"></span></li>
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_2.ELEMENT_3' | translate"></span></li>
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_2.ELEMENT_4' | translate"></span></li>
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_2.ELEMENT_5' | translate"></span></li>
    </ul>
  </div>
  <div class="list-box row">
    <h3 class="list-box-title">{{'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_3.TITLE' | translate}}</h3>
    <ul class="list">
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_3.ELEMENT_1' | translate"></span></li>
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_3.ELEMENT_2' | translate"></span></li>
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_3.ELEMENT_3' | translate"></span></li>
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_3.ELEMENT_4' | translate"></span></li>
    </ul>
  </div>
  <div class="list-box row">
    <h3 class="list-box-title">{{'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_4.TITLE' | translate}}</h3>
    <ul class="list">
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_4.ELEMENT_1' | translate"></span></li>
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_4.ELEMENT_2' | translate"></span></li>
      <li><span [innerHtml]="'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.LIST_4.ELEMENT_3' | translate"></span></li>
    </ul>
  </div>
  <div class="bottom-text">
    <span>{{'DEDUCTIONS.HOME_SERVICE_GROUP.TOOLTIP.TEXT.BOTTOM_TEXT' | translate}}</span>
  </div>
</div>