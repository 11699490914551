<app-ctr-base
  [templateRef]="deductions"
  [title]="title"
  [categoryProperties]="categoryProperties"
  [dataSelects]="dataSelects"
  [summary]="summary"
>
</app-ctr-base>

<ng-template #deductions>
  <div class="card card-white shadow-0 mb-2" id="deductionsCard">
    <div class="card__row mb-1">

      <ng-container *ngIf="!refundDetails.isBewerbungskostenEmpty(isSpouse)">
        <div class="font-weight-bold pl-0_5 mt-1 mb-0_5">
          {{ 'CONFIRM_TAX_RETURN.DEDUCTIONS.BEWERBUNGSKOSTEN' | translate }}
        </div>
        <div class="table">
          <div class="table__row" *ngIf="refundDetails[person].arbeitnehmerPauschbetrag as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.ARBEITNEHMER_PAUSCHBETRAG' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].entfernungspauschale as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.WEGE_ZWISCHEN_WOHNUNG_ERSTER_TATIGKEITSSTATTE_ENTFERNUNGSPAUSCHALE' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].aufwendungenFurOffentlicheVerkehrsmittel as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.AUFWENDUNGEN_FUR_OFFENTLICHE_VERKEHRSMITTEL' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].auswartstatigkeit as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.AUSWARTSTATIGKEIT' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].ubernachtungspaushaleFurBerufskraftfahrer as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.UBERNACHTUNGSPAUSHALE_FUR_BERUFSKRAFTFAHRER' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].beitrageZuBerufsverbanden as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.BEITRAGE_BERUFSVERBANDEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].aufwendungenFurArbeitsmittel as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.AUFWENDUNGEN_ARBEITSMITTEL' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].aufwendungenFurEinHauslichesArbeitszimmer as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.AUFWENDUNGEN_HAUSLICHES_ARBEITSZIMMER' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].fortbildungskosten as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.FORTBILDUNGSKOSTEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].mehraufwendungenFurDoppelteHaushaltsfuhrung as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.MEHRAUFWENDUNGEN_DOPPELTE_HAUSHALTSFUHRUNG' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].umzugskosten as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.UMZUGSKOSTEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].bewerbungskosten as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.BEWERBUNGSKOSTEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].kosten_der_steuererklarung as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.KOSTEN_DER_STEUERERKLARUNG' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].telekommunikationskosten as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.TELEKOMMUNIKATIONSKOSTEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].winterbeschaftigungsumlage as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.WINTERBESCHAFTIGUNGSUMLAGE' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].kontofuhrungsgebuhren as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.KONTOFUHRUNGSGEBUHREN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].ubrigeWerbungskosten as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.UBRIGE_WERBUNGSKOSTEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].summeWerbungskosten as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.SUMME_WERBUNGSKOSTEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input class="font-weight-bold" readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!refundDetails.isBeschranktAbziehbareSonderausgabenEmpty(isSpouse)">
        <div class="font-weight-bold pl-0_5 mt-1 mb-0_5">
          {{ 'CONFIRM_TAX_RETURN.DEDUCTIONS.BESCHRANKT_ABZIEHBARE_SONDERAUSGABEN' | translate }}
        </div>
        <div class="table">
          <div class="table__row" *ngIf="refundDetails[person].summeDerAltersvorsorgeaufwendungen as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.SUMME_DER_ALTERSVORSORGEAUFWENDUNGEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].beitrageZurKrankenversicherung as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.BEITRAGE_ZUR_KRANKENVERSICHERUNG' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].beitrageZurPflegeversicherung as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.BEITRAGE_ZUR_PFLEGEVERSICHERUNG' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].sonstigeVorsorgeaufwendungen as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.SONSTIGE_VORSORGEAUFWENDUNGEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!refundDetails.isUnbeschranktAbziehbareSonderausgabenEmpty(isSpouse)">
        <div class="font-weight-bold pl-0_5 mt-1 mb-0_5">
          {{ 'CONFIRM_TAX_RETURN.DEDUCTIONS.UNBESCHRANKT_ABZIEHBARE_SONDERAUSGABEN' | translate }}
        </div>

        <div class="table">
          <div class="table__row" *ngIf="refundDetails[person].spendenUndMitgliedsbeitrage as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.SPENDEN_MITGLIEDSBEITRAGE' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].ZuwendungenAnPolitischeParteien as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.ZUWENDUNGEN_AN_POLITISCHE_PARTEIEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].gezahlteKirchensteuer as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.GEZAHLTE_KIRCHENSTEUER' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.children.kinderbetreuungskosten as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.DER_KINDERBETREUUNGSKOSTEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.children.schulgeldes as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.DES_SCHULGELDES' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="refundDetails[person].pauschbetrag as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.PAUSCHBETRAG' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.children.ausbildungskosten as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.AUSBILDUNGSKOSTEN_NACH_ABS_ESTG' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.children.freibetrageFurKinder as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.FREIBETRAGE_FUR_KINDER' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.children.entlastungsbetragFurAlleinerziehende as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.ENTLASTUNGSBETRAG_FUR_ALLEINERZIEHENDE' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.children.hinterbliebenenPauschbetragFurKinder as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.HINTERBLIEBENEN_PAUSCHBETRAG_KINDER' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.children.behindertenPauschbetragFurKinder as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.BEHINDERTEN_PAUSCHBETRAG_KINDER' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.behindertenPauschbetrag as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.BEHINDERTEN_PAUSCHBETRAG' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.pflegePauschbetrag as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.DISABILITY_CARE_PFLEGEGRAD' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.gesundheitskosten as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.GESUNDHEITSKOSTEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.zuVersteuernMitProgressionsvorbehalt as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.VERSTEUERN_PROGRESSIONSVORBEHALT_DARUNTER_AUSLANDISCHE_EINKUNFTE' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row" *ngIf="!isSpouse && refundDetails.zuVersteuerndesEinkommen as refundValue">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.VERSTEUERNDES_EINKOMMEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundValue | number: '1.2-2' }} €"/>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isSpouse">
        <div class="font-weight-bold pl-0_5 mt-1 mb-0_5">
          {{ 'CONFIRM_TAX_RETURN.DEDUCTIONS.HOUSEHOLD_SERVICES' | translate }}
        </div>

        <div class="table">
          <div class="table__row">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.ERMASSIGUNG_GERINGFUGIGE_BESCHAFTIGUNGEN_PRIVATHAUSHALT' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundDetails.reductionForMiniJobs20Percent | mathRound | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.ERMASSIGUNG_SOZIALVERSICHERUNGSPFLICHTIGE_BESCHAFTIGUNGEN_PRIVATHAUSHALT_HAUSHALTSNAHE_DIENSTLEISTUNGEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundDetails.reductionForPrivateHousehold20Percent | mathRound | number: '1.2-2' }} €"/>
            </div>
          </div>
          <div class="table__row">
            <div class="table__col">
              <div class="row__title">{{ 'REFUND_DETAILS.ERMASSIGUNG_HANDWERKERLEISTUNGEN' | translate }}</div>
            </div>
            <div class="table__col d-flex">
              <input readonly value="{{ refundDetails.reductionForCraftsman20Percent | mathRound | number: '1.2-2' }} €"/>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</ng-template>
