import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CookiesLawService } from 'app/core/services/cookies-law.service';

@Component({
  templateUrl: './cookies-law-dialog.component.html',
  styleUrls: ['./cookies-law-dialog.component.scss']
})
export class CookiesLawDialogComponent implements OnInit {
  public analyticsEnabled: boolean;
  public targetingEnabled: boolean;

  constructor(
    private dialogRef: MatDialogRef<CookiesLawDialogComponent>,
    public cookiesLawService: CookiesLawService,
  ) {
  }

  ngOnInit() {
    this.analyticsEnabled = this.cookiesLawService.isAnalyticsEnabled;
    this.targetingEnabled = this.cookiesLawService.isTargetingEnabled;
  }

  public onClickAllow(): void {
    this.cookiesLawService.allowAll();
    this.closeDialog();
  }

  public onClickDisable(): void {
    this.cookiesLawService.disallowAll();
    this.closeDialog();
  }

  public onClickSave(): void {
    if (this.analyticsEnabled) {
      this.cookiesLawService.enableAnalytics();
    } else {
      this.cookiesLawService.disableAnalytics();
    }

    if (this.targetingEnabled) {
      this.cookiesLawService.enableTargeting();
    } else {
      this.cookiesLawService.disableTargeting();
    }

    this.closeDialog();
  }

  private closeDialog(): void {
    this.dialogRef.close();
  }
}
