import { BaseStatementPagePath } from 'app/core/workflow/page-path/statement-page-path/base-statement-page.path';

export class VerificationComponentPath extends BaseStatementPagePath {
  public static path(): string {
    return 'statement/:statementId/b2c/submit';
  }

  public static regex(): RegExp {
    return /\/statement\/\d+\/b2c\/submit((\/?#?)|(\/?#.*?))$/;
  }

  public static moduleSubPath(): string {
    return 'b2c/submit';
  }
}
