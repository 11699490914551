import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { Statement } from "../../statements/statement";
import { CustomLabels } from "app/common/labels-edit/CustomLabels";

@Component({
  selector: "app-list-view",
  templateUrl: "./list-view.component.html",
  styleUrls: ["./list-view.component.scss"]
})
export class ListViewComponent implements OnInit {
  openedItems: boolean[] = [];
  @Input() statements: Statement[];
  @Input() hideEditing = false;
  @Input() hideRemoving = false;
  @Input() hideDecision = false;
  @Input() hideInvoice = false;
  @Input() hidePdf = false;
  @Input() showEditButton = false;
  @Input() labelList: CustomLabels[] = [];
  @Output() downloadPdf = new EventEmitter<Statement>();
  @Output() downloadInvoice = new EventEmitter<Statement>();
  @Output() showDownloadDialog = new EventEmitter<Statement>();
  @Output() showDecision = new EventEmitter<Statement>();
  @Output() deleteStatement = new EventEmitter<{ statement: Statement; listIndex: number }>();
  @Output() editButton = new EventEmitter<Statement>();
  @Output() cloneStatement = new EventEmitter<Statement>();
  @Output() importStatement = new EventEmitter<Statement>();

  private isSent = true;

  constructor() {}

  ngOnInit() {
    this.clearOpenedItems();
  }

  emitDownloadPdf(statement: Statement) {
    this.downloadPdf.emit(statement);
  }

  emitDownloadInvoice(statement: Statement) {
    this.downloadInvoice.emit(statement);
  }

  emitShowDecision(statement: Statement) {
    this.showDecision.emit(statement);
  }

  emitOnEditButton(statement: Statement) {
    this.editButton.emit(statement);
  }

  emitDeleteStatement(statement: Statement, listIndex: number) {
    this.deleteStatement.emit({ statement, listIndex });
  }

  emitShowDownloadDialog(statement: Statement) {
    this.showDownloadDialog.emit(statement);
  }

  public emitCloneStatement(statement: Statement) {
    this.cloneStatement.emit(statement);
  }

  public emitImportStatement(statement: Statement) {
    this.importStatement.emit(statement);
  }

  toggleItem(index) {
    this.clearOpenedItems();
    this.openedItems[index] = true;
  }

  clearOpenedItems() {
    this.openedItems = this.openedItems.map((item) => false);
  }
}
