<p class="font-weight-bold font-size-24 text-center pb-1" *ngIf="isHowDoesItWork && !isPackageBenefits && isPremiumPackage">
  ({{ "PACKAGE_SELECTION2.PREMIUM_PACKAGE_TITLE" | translate }})
</p>
<p class="font-weight-bold font-size-24 text-center pb-1" *ngIf="isHowDoesItWork && !isPackageBenefits && isStandardPackage">
  ({{ "PACKAGE_SELECTION2.STANDARD_PACKAGE_TITLE" | translate }})
</p>
<div class="package_info_cont">
  <div class="package_price_cont" *ngIf="!isHowDoesItWork || isPackageBenefits">
    <div class="desc_wrap" [innerHTML]="descTooltip | translate"></div>
    <div class="package_price_wrap">
      <div class="package_price">
        <div class="price">
          <span class="cur">{{ currencySymbol }}</span>
          <ng-container *ngIf="isPremiumPackage">
            <span class="lg_price">{{
              numberFormatHelper.integer(premiumAmount)
            }}</span>
            <span class="sm_price">{{
              numberFormatHelper.decimal(premiumAmount)
            }}</span>
          </ng-container>
          <ng-container *ngIf="isStandardPackage">
            <span class="lg_price">{{
              numberFormatHelper.integer(standardAmount)
            }}</span>
            <span class="sm_price">{{
              numberFormatHelper.decimal(standardAmount)
            }}</span>
          </ng-container>
        </div>
        <div class="price_info">
          {{ "PACKAGE_SELECTION2.STEUER.FOR_EVERY_STATEMENT" | translate }}
        </div>
        <ng-container *ngIf="isPremiumPackage">
          <div class="package_info_btn">
            {{ "PACKAGE_SELECTION2.STEUER.PAY_LATER" | translate }}
          </div>
        </ng-container>
      </div>
    </div>
    <ul class="package_choise_list" *ngIf="isPremiumPackage">
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_2" | translate }}</span>
        <img src="../../../assets/svg/true.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_3" | translate }}</span>
        <img src="../../../assets/svg/true.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_4" | translate }}</span>
        <img src="../../../assets/svg/true.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_5" | translate }}</span>
        <img src="../../../assets/svg/true.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_6" | translate }}</span>
        <img src="../../../assets/svg/true.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_7" | translate }}</span>
        <img src="../../../assets/svg/true.svg" />
      </li>
      <li>
        <span class="underline">{{
          "PACKAGE_SELECTION2.LIST.OPTION_8" | translate
        }}</span>
        <img src="../../../assets/svg/true.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_9" | translate }}</span>
        <img src="../../../assets/svg/true.svg" />
      </li>
    </ul>
    <ul class="package_choise_list" *ngIf="isStandardPackage">
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_2" | translate }}</span>
        <img src="../../../assets/svg/true.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_3" | translate }}</span>
        <img src="../../../assets/svg/true.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_4" | translate }}</span>
        <img src="../../../assets/svg/true.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_5" | translate }}</span>
        <img class="no" src="../../../assets/svg/false.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_6" | translate }}</span>
        <img class="no" src="../../../assets/svg/false.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_7" | translate }}</span>
        <img class="no" src="../../../assets/svg/false.svg" />
      </li>
      <li>
        <span class="underline">{{
          "PACKAGE_SELECTION2.LIST.OPTION_8" | translate
        }}</span>
        <img class="no" src="../../../assets/svg/false.svg" />
      </li>
      <li>
        <span>{{ "PACKAGE_SELECTION2.LIST.OPTION_9" | translate }}</span>
        <img class="no" src="../../../assets/svg/false.svg" />
      </li>
    </ul>
  </div>
  <hr
    class="how_does_it_work_divider"
    *ngIf="isHowDoesItWork && isPackageBenefits"
  />
  <div class="package_how_does_it_work text-center" *ngIf="isHowDoesItWork">
    <p class="mb-2 font-weight-bold font-size-24" *ngIf="isPackageBenefits">
      {{ "PACKAGE_SELECTION2.HOW_DOES_IT_WORK" | translate }}
    </p>
    <ol *ngIf="isPremiumPackage">
      <li>
        <span>{{
          "PACKAGE_SELECTION2.PREMIUM_PACKAGE.OPTION_1" | translate
        }}</span>
        <div>
          <img
            class="tooltip_package"
            src="../../../assets/svg/package-selection/tooltip_package_1.svg"
          />
        </div>
        <div class="visible_lg">
          <img class="arrow_down" src="../../../assets/svg/arrow_down.svg" />
        </div>
      </li>
      <li>
        <span>{{
          "PACKAGE_SELECTION2.PREMIUM_PACKAGE.OPTION_2" | translate
        }}</span>
        <div>
          <img
            class="tooltip_package"
            src="../../../assets/svg/package-selection/tooltip_package_2.svg"
          />
        </div>
        <div class="visible_lg">
          <img class="arrow_down" src="../../../assets/svg/arrow_down.svg" />
        </div>
      </li>
      <li>
        <span>{{
          "PACKAGE_SELECTION2.PREMIUM_PACKAGE.OPTION_3" | translate
        }}</span>
        <div>
          <img
            class="tooltip_package"
            src="../../../assets/svg/package-selection/tooltip_package_3.svg"
          />
        </div>
        <div class="visible_lg">
          <img class="arrow_down" src="../../../assets/svg/arrow_down.svg" />
        </div>
      </li>
      <li>
        <span>{{
          "PACKAGE_SELECTION2.PREMIUM_PACKAGE.OPTION_4" | translate
        }}</span>
        <div>
          <img
            class="tooltip_package"
            src="../../../assets/svg/package-selection/tooltip_package_4.svg"
          />
        </div>
        <div class="visible_lg">
          <img class="arrow_down" src="../../../assets/svg/arrow_down.svg" />
        </div>
      </li>
      <li>
        <span>{{
          "PACKAGE_SELECTION2.PREMIUM_PACKAGE.OPTION_5" | translate
        }}</span>
        <div>
          <img
            class="tooltip_package"
            src="../../../assets/svg/package-selection/tooltip_package_5.svg"
          />
        </div>
      </li>
    </ol>
    <ol *ngIf="isStandardPackage">
      <li>
        <span>{{
          "PACKAGE_SELECTION2.STANDARD_PACKAGE.OPTION_1" | translate
        }}</span>
        <div>
          <img
            class="tooltip_package"
            src="../../../assets/svg/package-selection/tooltip_package_1.svg"
          />
        </div>
        <div class="visible_lg">
          <img class="arrow_down" src="../../../assets/svg/arrow_down.svg" />
        </div>
      </li>
      <li>
        <span>{{
          "PACKAGE_SELECTION2.STANDARD_PACKAGE.OPTION_2" | translate
        }}</span>
        <div>
          <img
            class="tooltip_package"
            src="../../../assets/svg/package-selection/tooltip_package_5.svg"
          />
        </div>
        <div class="visible_lg">
          <img class="arrow_down" src="../../../assets/svg/arrow_down.svg" />
        </div>
      </li>
      <li>
        <span>{{
          "PACKAGE_SELECTION2.STANDARD_PACKAGE.OPTION_3" | translate
        }}</span>
        <div>
          <img
            class="tooltip_package"
            src="../../../assets/svg/package-selection/tooltip_package_2.svg"
          />
        </div>
      </li>
    </ol>
  </div>
  <hr
    class="how_does_it_work_divider"
    *ngIf="!isPremiumPackage && isHowDoesItWork && isPackageBenefits"
  />
  <div
    class="desc_wrap h-auto mb-2"
    *ngIf="!isPremiumPackage && isHowDoesItWork && isPackageBenefits"
    [innerHTML]="
      'PACKAGE_SELECTION2.STANDARD.PRICING_DETAILS_FREE.DESCRIPTION' | translate
    "
  ></div>
</div>
