import { User } from '../users';
import * as user from './actions/user';
import { UserType } from '../users/user-type';
import * as Sentry from '@sentry/angular';
import { environment } from 'environments/environment';

export interface State {
  user: User | null;
}

export const initialState: State = {
  user: null
};

export function reducer(state = initialState, action: user.Actions): State {
  switch (action.type) {
    case user.LOAD_USER_DATA: {
      const _user: User | null = action.payload;
      if (environment.sentryDsn && _user) {
        Sentry.setUser({
          id: String(_user.id),
        });
      }

      return {
        user: _user
      };
    }
    case user.REMOVE_USER_DATA: {
      return {
        user: null
      };
    }
    default: {
      return state;
    }
  }
}

export const getUser = (state: State) => state.user;
export const getUserType = (state: State) => {
  let userType = state.user && state.user.clientType ? state.user.clientType : '';
  userType = state.user && state.user.isTaxAdviser ? UserType.tax_adviser : userType;

  return userType;
};
