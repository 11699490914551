<div class="dialog-box position-relative" [class.is-loading]="loading">
  <div class="dialog-box__header">
    <button mat-icon-button mat-dialog-close tabindex="-1">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>
  </div>
  <div class="dialog-box__content">
    <div class="font-size-28 font-weight-bold mb-1_5 mt-n1_5">{{ 'MENU.ACCOUNT.TITLE' | translate }}</div>

    <ng-container *ngIf="!isMaster">
      <ng-container *ngTemplateOutlet="basicInfo"></ng-container>
    </ng-container>

    <ng-container *ngIf="isMaster">
      <mat-tab-group dynamicHeight>
        <mat-tab label="{{ 'MENU.ACCOUNT.TABS.BASIC_INFO' | translate }}">
          <ng-container *ngTemplateOutlet="basicInfo"></ng-container>
        </mat-tab>
        <mat-tab label="{{ 'MENU.ACCOUNT.TABS.TAX_CERTIFICATE' | translate }}">
          <ng-container *ngTemplateOutlet="financialInfo"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </div>
</div>

<ng-template #basicInfo>
  <div class="user-info py-1" *ngIf="userData">
    <div class="user-info__form" [errors]="errors" [appShowFormError]>
      <div class="user-info__form-list">
        <div class="user-info__form-stage">
          <app-form-input [errors]="errors?.first_name" [label]="'INPUT.FIRST_NAME.LABEL'"
                          [placeholder]="'INPUT.FIRST_NAME.PLACEHOLDER'" [(model)]="userData.firstName">
          </app-form-input>
        </div>
        <div class="user-info__form-stage">
          <app-form-input [errors]="errors?.last_name" [label]="'INPUT.LAST_NAME.LABEL'"
                          [placeholder]="'INPUT.LAST_NAME.PLACEHOLDER'" [(model)]="userData.lastName">
          </app-form-input>
        </div>


        <div class="user-info__form-stage">
          <app-form-select [errors]="errors?.country" [label]="'SELECT.COUNTRY.LABEL'"
                           [placeholder]="'SELECT.COUNTRY.PLACEHOLDER'" [(model)]="userData.country"
                           [choices]="dataSelects.countries" [valueName]="'code'" [optionName]="'name'">
          </app-form-select>
        </div>

        <div class="user-info__form-stage">
          <app-form-input [errors]="errors?.city" [label]="'INPUT.CITY.LABEL'"
                          [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'" [(model)]="userData.city"></app-form-input>
        </div>

        <div class="user-info__form-stage">
          <app-form-input [errors]="errors?.postal_code" [label]="'INPUT.POSTAL_CODE.LABEL'"
                          [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'" [(model)]="userData.postalCode">
          </app-form-input>
        </div>

        <div class="user-info__form-stage">
          <app-form-input [errors]="errors?.street" [label]="'INPUT.STREET.LABEL'"
                          [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'" [(model)]="userData.street"></app-form-input>
        </div>

        <div class="user-info__form-stage">
          <app-form-input [errors]="errors?.street_number" [label]="'INPUT.STREET_NUMBER.LABEL'"
                          [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'" [(model)]="userData.streetNumber">
          </app-form-input>
        </div>
        <ng-container *ngIf="userData.extendPersonalInfo && !isBusiness">
          <div class="user-info__form-stage">
            <app-form-input [errors]="errors?.additional_address_info"
                            [label]="'INPUT.ADDITIONAL_ADDRESS_INFO.LABEL'"
                            [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                            [(model)]="userData.extendPersonalInfo.applicantAdditionalAddressInfo"></app-form-input>
          </div>


          <div class="user-info__form-stage">
            <app-form-date-input [errors]="errors?.birth_date" [label]="'LIST.DOB'"
                                 [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                                 [(model)]="userData.extendPersonalInfo.applicantBirthdate"></app-form-date-input>
          </div>

          <div class="user-info__form-stage">
            <app-form-input [errors]="errors?.identifikationsnummer"
                            [label]="'INPUT.IDENTIFIKATIONSNUMMER.LABEL'"
                            [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                            [(model)]="userData.extendPersonalInfo.applicantIdentifikationsnummer"></app-form-input>
          </div>

          <div class="user-info__form-stage">
            <app-form-input [errors]="errors?.iban" [label]="'INPUT.IBAN.LABEL'"
                            [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                            [(model)]="userData.extendPersonalInfo.applicantIban"></app-form-input>
          </div>

          <div class="user-info__form-stage">
            <app-form-input [errors]="errors?.bic" [label]="'INPUT.BIC_SWIFT.LABEL'"
                            [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                            [(model)]="userData.extendPersonalInfo.applicantBic"></app-form-input>
          </div>

          <div class="user-info__form-stage">
            <app-form-input [errors]="errors?.money_institution" [label]="'INPUT.SHORT_BANK_NAME.LABEL'"
                            [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                            [(model)]="userData.extendPersonalInfo.applicantMoneyInstitution"></app-form-input>
          </div>

          <div class="user-info__form-stage">
            <app-form-select [errors]="errors?.religion" [label]="'SELECT.RELIGION.LABEL'"
                             [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                             [(model)]="userData.extendPersonalInfo.applicantReligionId"
                             [choices]="dataSelects.religions" [valueName]="'id'" [optionName]="'name'">
            </app-form-select>
          </div>

          <div class="user-info__form-stage">
            <app-form-select [errors]="errors?.religion" [label]="'SELECT.TAX_AUTHORITY.LABEL'"
                             [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                             [(model)]="userData.extendPersonalInfo.applicantTaxAuthorityId"
                             [choices]="dataSelects.taxAuthorities" [valueName]="'id'" [optionName]="'name'">
            </app-form-select>
          </div>

          <div class="user-info__form-stage">
            <app-form-select [errors]="errors?.tax_class" [label]="'INPUT.TAX_CLASS.LABEL'"
                             [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
                             [(model)]="userData.extendPersonalInfo.applicantTaxClassId"
                             [choices]="dataSelects.taxClasses" [valueName]="'id'" [optionName]="'name'">
            </app-form-select>
          </div>
        </ng-container>

        <div class="user-info__form-stage" *ngIf="userData.isBusiness">
          <app-form-input [errors]="errors?.nip" [label]="'INPUT.NIP.LABEL'"
                          [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'" [(model)]="userData.nip"></app-form-input>
        </div>

        <div class="user-info__form-stage">
          <app-form-input [errors]="errors?.phone_number" [label]="'INPUT.PHONE_NUMBER.LABEL'"
                          [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'" [(model)]="userData.phoneNumber">
          </app-form-input>
        </div>

        <div class="user-info__form-stage">
          <app-form-select
            [errors]="errors?.language"
            [label]="'EMAIL_LANG.TITLE'"
            [placeholder]="'EMAIL_LANG.TITLE'"
            [(model)]="userData.language"
            [choices]="langList"
            [valueName]="'code'"
            [optionName]="'translatedFullNameTransKey'"
          ></app-form-select>
        </div>

        <div class="user-info__form-stage" *ngIf="!isBusiness">
          <app-form-select
            [errors]="errors?.citizenship"
            [label]="'SELECT.CITIZENSHIP.LABEL'"
            [placeholder]="'SELECT.CITIZENSHIP.PLACEHOLDER'"
            [(model)]="userData.citizenship"
            [choices]="dataSelects.citizenships"
            [valueName]="'code'"
            [optionName]="'name'"
            [attr.data-cy]="'selectCitizenship'"
          >
          </app-form-select>
        </div>
        <div class="user-info__form-stage mt-1">
          <mat-checkbox
            [checked]="userData.receiveNewsletters"
            (change)="userData.receiveNewsletters = !userData.receiveNewsletters"
          >
            {{ 'RECEIVE_NEWSLETTERS.START' | translate }}
            <b class=" cursor-pointer" (click)="showPrivacyPolicyDialog($event)"
            >{{ 'RECEIVE_NEWSLETTERS.PRIVACY_POLICY' | translate }}</b>
            {{ 'RECEIVE_NEWSLETTERS.END' | translate }}
          </mat-checkbox>
        </div>
      </div>
      <div class="dialog-box__buttons mt-1">
        <button
          class="btn btn--secondary"
          mat-dialog-close
        >
          {{ 'COMMON.CANCEL' | translate }}
        </button>
        <button
          class="btn btn--primary"
          (click)="onSubmit()"
          [disabled]="loadingSave"
          [class.is-loading]="loadingSave"
        >
          {{ 'COMMON.SAVE' | translate }}
        </button>
      </div>
      <div class="policy">
        <a
          (click)="handleDeleteAccount()"
          [attr.data-cy]="'deleteMyAccountBtn'"
        >
          {{ 'MENU.ACCOUNT.DELETE_ACCOUNT_BTN' | translate }}
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #financialInfo>
  <div class="my-1">
    <ng-container *ngIf="isMaster">
      <div class="d-flex align-items-center mb-1 mt-1_5">
        <h2 class="font-size-20 font-weight-bold">{{ 'MENU.ACCOUNT.TAX_CERTIFICATE.TITLE' | translate }}</h2>
        <span>
          <app-more-info-tooltip
            class="ml-0_5 v-align-mid"
            [message]="'MENU.ACCOUNT.TAX_CERTIFICATE.TOOLTIP'"
            [isSmallIcon]="true"
          ></app-more-info-tooltip>
        </span>
      </div>
      <ng-container *ngIf="!ownCertificate && !certificateFile">
        <label
          class="btn_add_form"
        >
          <input type="file" name="data_file" (change)="onUploadCertificate($event)">
          <img src="../../../assets/svg/add.svg" alt="add">
          <span>{{ 'MENU.ACCOUNT.TAX_CERTIFICATE.UPLOAD' | translate }}</span>
        </label>
      </ng-container>
      <ng-container *ngIf="certificateFile || ownCertificate">
        <div class="d-flex">
          <div class="mr-0_5" style="width: 50%;">
            <div class="text-muted pl-0_5 mb-0_3125">{{ 'MENU.ACCOUNT.TAX_CERTIFICATE.LABEL' | translate }}</div>

            <label
              *ngIf="!certificateFile"
              class="btn_add_form"
            >
              <input type="file" name="data_file" (change)="onUploadCertificate($event)">
              <img src="../../../assets/svg/add.svg" alt="add">
              <span>{{ 'MENU.ACCOUNT.TAX_CERTIFICATE.UPLOAD_UPDATE' | translate }}</span>
            </label>

            <div
              class="attachments__single_box w-100"
              [class.form-error]="!!ownCertificateErrors?.data_file || !!ownCertificateErrors?.error"
              *ngIf="certificateFile"
            >

              <div class="attachments__single_name">
                {{ certificateFile.file_name }}
              </div>

              <div class="d-flex">
                <div class="attachments__single_size">
                  {{ certificateFile.formattedSize }}
                </div>
              </div>
            </div>
          </div>

          <div>
            <app-form-input
              [autocomplete]="'off'"
              [inputType]="'password'"
              [errors]="ownCertificateErrors?.pin || (!!ownCertificateErrors?.error ? [''] : null)"
              [label]="'INPUT.CERTIFICATE_PIN.LABEL'"
              [placeholder]="'INPUT.CERTIFICATE_PIN.PLACEHOLDER'"
              [(model)]="certificatePin"
            ></app-form-input>
          </div>

          <button
            class="btn btn_w_icon btn_del ml-0_5"
            style="margin-top: 25px;"
            [class.is-loading]="loadingDeleteCertificate"
            (click)="deleteCertificate()"
          >
            <img alt="delete" src="../../../assets/svg/del_icon.svg">
          </button>
        </div>
        <small
          class="text-danger--dark pl-0_5"
          *ngIf="ownCertificateErrors?.error"
        >
          {{ ownCertificateErrors?.error }}
        </small>
        <small
          class="text-muted pl-0_5"
          *ngIf="ownCertificate && ownCertificate.created_date && !certificateFile"
        >
          {{ 'COMMON.UPLOADED' | translate }} {{ ownCertificate.created_date }}
        </small>
      </ng-container>
    </ng-container>

    <div class="dialog-box__buttons mt-1">
      <button
        class="btn btn--secondary"
        mat-button
        mat-dialog-close
      >
        {{ 'COMMON.CANCEL' | translate }}
      </button>
      <button
        class="btn btn--primary"
        mat-button
        (click)="onSubmitCertificate()"
        [disabled]="loadingUploadCertificate"
        [class.is-loading]="loadingUploadCertificate"
      >
        {{ 'COMMON.SAVE' | translate }}
      </button>
    </div>
  </div>
</ng-template>
