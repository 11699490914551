<div class="d-flex" *ngIf="attachment">
  <div class="attachments__single_box" [class.error]="error">

    <div class="attachments__single_name">
      {{ attachment.file_name }}
    </div>

    <div class="d-flex">
      <div class="attachments__single_size">
        {{ attachment.formattedSize }}
      </div>

      <a
        (click)="onViewAttachment()"
        target="_blank"
        class="attachments__single_view"
      >
        <span>View</span>
        <img alt="view" src="../../../assets/svg/view_icon.svg">
      </a>
    </div>
  </div>

  <button
    class="btn btn_w_icon btn_del"
    [class.is-loading]="loadingDeleteAttachment"
    (click)="onDeleteAttachment()"
  >
    <img alt="delete" src="../../../assets/svg/del_icon.svg">
  </button>
</div>
<small class="text-muted pl-0_5" *ngIf="attachment.id && attachment.created_date">{{ attachment.created_date }}</small>
