import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { State, getUserType } from 'app/reducers';
import { PaymentService } from 'app/payment/payment.service';
import { SubscribingComponent } from 'app/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.scss']
})
export class PackageListComponent extends SubscribingComponent implements OnInit {
  public isBusiness = false;

  constructor(
    private store: Store<State>,
    private paymentService: PaymentService,
    private dialog: MatDialog,
    private route: Router
  ) {
    super();
  }

  ngOnInit() {
    this.store.select(getUserType).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((userType: string) => {
        if (userType) {
          this.isBusiness = userType === 'business';
          if (!this.isBusiness) {
            this.route.navigate(['']);
          }
        }
      });
  }
}
