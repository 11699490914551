import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { SubscribingComponent } from "app/common";
import { SelectsData, SelectsDataInterface, Statement } from "app/statements";
import { takeUntil } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ArrayHelper } from "app/core/helpers/array.helper";
import { UtilityHelper } from "app/core/helpers/utility.helper";
import { DateHelper } from "app/core/helpers/date.helper";
import { getSelectsData, State } from "app/reducers";
import { Store } from "@ngrx/store";
import { City } from "app/statements/city";
import { SelectButton } from "app/common/select-buttons/select-buttons.component";
import { StepsControllerService } from "app/core/workflow/steps-controller/steps-controller.service";
import { ConfirmCancelDialogComponent } from "app/shared/components/confirm-cancel-dialog/confirm-cancel-dialog.component";

export interface SelectButtonsData {
  id: string;
  image: string;
  title: string;
  tooltipContent?: string;
}

export interface GroupSectionStep {
  id: string;
  isActive: boolean;
}

@Component({
  template: ""
})
export abstract class GroupSectionBaseAbstractComponent extends SubscribingComponent implements OnInit {
  static metaData = {
    inputs: [
      "templateRef",
      "statement",
      "currentStatement",
      "selectButtons",
      "steps",
      "title",
      "description",
      "titleTooltip",
      "messageTooltip",
      "child"
    ]
  };
  @Input() templateRef: TemplateRef<any>;
  @Input() statement: Statement;
  @Input() currentStatement: Statement;
  @Input() selectButtons: SelectButton[];
  @Input() subSteps: GroupSectionStep[] = [];
  @Input() title: string;
  @Input() description: string;
  @Input() titleTooltip: string;
  @Input() messageTooltip: string;
  public arrayHelper = ArrayHelper;
  public dataSelects: SelectsDataInterface = new SelectsData();
  public MAIN_STEP = "main";
  public isMainStepActive = true;
  public selectedCounter: number;
  public readonly otherCity = City.TRAVEL_ABROAD_OTHER_CITY;

  constructor(
    public dialog: MatDialog,
    public store: Store<State>,
    public stepsControllerService: StepsControllerService
  ) {
    super();

    this.store
      .select(getSelectsData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectsData: SelectsDataInterface) => {
        if (selectsData) {
          this.dataSelects = selectsData;
        }
      });
  }

  ngOnInit() {
    this.stepsControllerService.proceedEmitter
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.goToTheNextStep());
    this.stepsControllerService.backEmitter
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.goToThePreviousStep());
    this.isMainStepActive = this.isSubStepActive(this.MAIN_STEP);
  }

  public goToTheNextStep(): void {
    let currentIndex = this.subSteps.findIndex((x) => x.isActive);

    const next = (index: number): void => {
      if (index >= this.subSteps.length) {
        this.stepsControllerService.stepsEndedForward.emit();
      } else if (this.checkIfCanGoToTheNextSubStep(index)) {
        this.setActiveSubStep(index);
      } else {
        next(index + 1);
      }
    };

    if (currentIndex !== -1) {
      this.stepsControllerService.subStepSaveEmitter.emit({
        index: currentIndex,
        callback: (result) => {
          if (result) {
            ++currentIndex;
            next(currentIndex);
          }
        }
      });
      return;
    } else {
      this.setActiveSubStep(0);
    }
  }

  public goToThePreviousStep(): void {
    let currentIndex = this.subSteps.findIndex((x) => x.isActive);

    const prev = (index: number): void => {
      if (index === -1) {
        this.stepsControllerService.stepsEndedBackward.emit();
      } else if (this.checkIfCanGoToTheNextSubStep(index)) {
        this.setActiveSubStep(index);
      } else {
        prev(index - 1);
      }
    };

    if (currentIndex !== -1) {
      --currentIndex;
      prev(currentIndex);
    } else {
      this.setActiveSubStep(0);
    }
  }

  public setSelectButtons(selectButtonsData: SelectButtonsData[]): void {
    this.selectButtons = [];

    selectButtonsData.forEach((el) => {
      this.selectButtons.push({
        currentId: el.id,
        name: el.id,
        label: el.title,
        isSelected: () => this.isSelected(el.id),
        imageSrc: el.image,
        clickAction: () => this.selectButtonClickAction(el.id),
        extenderActive: !!el.tooltipContent,
        extenderMessage: el.tooltipContent
      });
    });

    this.selectedButtonsChanged();
    this.isMainStepActive = this.isSubStepActive(this.MAIN_STEP);
  }

  public isSelected = (selectButtonName: string): boolean => {
    return false;
  };

  public selectButtonClickAction = (selectButtonName: string): void => {};

  public showAreYouSurePopup(callback: (d: MatDialogRef<ConfirmCancelDialogComponent>) => any = () => {}) {
    const dialog = this.dialog.open(ConfirmCancelDialogComponent);
    dialog.componentInstance.title = "ADDITIONAL_INCOME.ARE_YOU_SURE_POPUP.TITLE";
    dialog.componentInstance.description = "ADDITIONAL_INCOME.ARE_YOU_SURE_POPUP.DESCRIPTION";
    dialog.componentInstance.closeOnSubmit = false;
    dialog.componentInstance.isSubmitButtonLoading = false;
    dialog.componentInstance.submitActionEmitter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      dialog.disableClose = true;
      callback(dialog);
      this.selectedButtonsChanged();
    });
  }

  public trackBy(index: number): number {
    return index;
  }

  public setDatePlaceHolder(): string {
    return DateHelper.getDatePlaceholder(this.statement);
  }

  public selectedButtonsChanged(): void {
    this.selectedCounter = this.selectButtons.filter((x) => x.isSelected()).length;
    this.stepsControllerService.selectedButtonsChangedEmitter.emit(this.selectedCounter);
  }

  public getActiveSubStep(): GroupSectionStep {
    return this.subSteps.find((x) => x.isActive);
  }

  public isSubStepActive(stepId: string): boolean {
    const step: GroupSectionStep = ArrayHelper.arrayFinder(this.subSteps, "id", stepId);
    return step?.isActive;
  }

  private resetActiveSubStep(): void {
    this.subSteps.some((el) => {
      el.isActive = false;
    });
  }

  private setActiveSubStep(index: number): void {
    this.resetActiveSubStep();
    this.subSteps[index].isActive = true;

    UtilityHelper.scrollToTop();

    this.isMainStepActive = this.isSubStepActive(this.MAIN_STEP);
  }

  private checkIfCanGoToTheNextSubStep(index: number): boolean {
    const step = this.subSteps[index];

    if (step.id === this.MAIN_STEP) {
      return true;
    }

    const button = this.selectButtons.find((x) => x.currentId === step.id);

    if (button) {
      return button.isSelected();
    }

    return false;
  }
}
