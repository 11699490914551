import { Component, OnInit, Inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { IndividualCouponHttpService } from "../services/individualCoupon.http.service";
import { CommonModule } from "@angular/common";
import { AuthHttpService } from "app/users";
import { SubscribingComponent } from "app/common";
import { takeUntil } from "rxjs/operators";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { CouponsPage } from "../coupons.page";
import { HomeComponent } from "app/core";
import { StarterBoardPage } from "app/core/pages/starter-board-page/starter-board.page";

@Component({
  selector: "app-coupons-popup",
  templateUrl: "./coupons-popup.component.html",
  styleUrls: ["./coupons-popup.component.scss", "../coupons.page.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule],
  providers: [IndividualCouponHttpService, AuthHttpService]
})
export class CouponsPopup extends SubscribingComponent implements OnInit {
  couponCode: any;
  constructor(
    public dialogRef: MatDialogRef<CouponsPopup>,
    public individualCouponHttpService: IndividualCouponHttpService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    if (data) this.couponCode = data.code;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    if (!this.couponCode) {
      this.individualCouponHttpService
        .getIndividualCoupon()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (response) => {
            this.couponCode = response;
          }
        });
    }
  }

  getCouponCode() {
    const code = this.couponCode.referal_code || this.couponCode;
    navigator.clipboard.writeText(code);
  }
}
