<ng-container *ngIf="!contentLoaded">
    <app-content-loader></app-content-loader>
</ng-container>
<ng-container *ngIf="contentLoaded">
    <div class="step-container columns is-desktop">
        <app-aside-bar [showEvenWithoutStatement]="false" [statement]="statement" [isNested]="isNested"
            [isBusiness]="isBusiness"></app-aside-bar>
        <div class="abroad-income" [class.nested]="isNested">
            <div class="title-section">
                <h1 class="title">{{ 'ABROAD_INCOME.TITLE' | translate }}</h1>
                <app-more-info-tooltip [message]="'TOOLTIP.ABROAD_INCOME_MAIN'" [titleTooltip]="'ABROAD_INCOME.TITLE'"></app-more-info-tooltip>
            </div>
            <div class="description" translate="ABROAD_INCOME.DESCRIPTION"></div>
            <div class="abroad-income__form" *ngIf="isLoaded()">
                <app-b2b-abroad-income-edit #abroadIncomeForm [isSpouse]="isSpouse" [statement]="statement"
                    [errors]="errors?.abroad_income" [epValidator]="epValidator" [orderForEmployerPeriod]="orderForEmployerPeriod" [orderApplyForMessage]="orderApplyForMessage" [appShowFormError] [openAccordions]="true"
                    (updateStatementEvent)="updateAIFlag($event)"></app-b2b-abroad-income-edit>
            </div>
        </div>
        <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
    </div>
</ng-container>
