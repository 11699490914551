import { Component } from '@angular/core';
import { CtrBaseComponent } from '../ctr-base.component';

@Component({
    ...CtrBaseComponent.metaData,
    selector: 'app-ctr-children',
    templateUrl: './ctr-children.component.html',
    styleUrls: ['../ctr-base.component.scss'],
})
export class CtrChildrenComponent extends CtrBaseComponent {
}
