import { debounceTime, finalize, takeUntil } from "rxjs/operators";
import { Component, OnInit, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Statement } from "app/statements";
import { SelectsDataInterface } from "app/statements";
import { StatementService } from "app/statements/statement.service";
import { SubscribingComponent } from "app/common/subscribing-component";
import { TaxAuthority } from "app/statements";
import { getSelectsData, State } from "app/reducers";
import { Subject } from "rxjs";
import { ErrorMessage } from "app/core/form-validator/validatorInterface";
import { VALIDATOR_FIELD_NAMES_PERSONAL_INFO } from "app/core/form-validator/validator-field-names/validator-field-names-personal-info";
import { StatementErrors } from "app/modules/statement/models/statement/statement.errors.interface";

@Component({
  selector: "app-personal-info-edit",
  templateUrl: "./personal-info-edit.component.html",
  styleUrls: ["./personal-info-edit.component.scss"]
})
export class PersonalInfoEditComponent extends SubscribingComponent implements OnInit {
  @Input() formStatement: Statement;
  @Input() errors: StatementErrors;
  get epValidator(): ErrorMessage[] {
    return this._epValidator;
  }
  @Input() set epValidator(_epValidator: ErrorMessage[]) {
    this._epValidator = _epValidator;
  }
  @Input() isBusiness = false;
  public isVerification = false;
  public dataSelects: SelectsDataInterface;
  public validatorFieldNames = VALIDATOR_FIELD_NAMES_PERSONAL_INFO;
  public showLiveInGermanyField = false;
  public isTaxAuthorityLoading = false;
  public isTaxAuthorityShown = false;
  public yesNoList = [
    {
      name: "COMMON.YES",
      value: true
    },
    {
      name: "COMMON.NO",
      value: false
    }
  ];
  private postalCodeSubject$ = new Subject<{ postalCode: string; country: string }>();
  private _epValidator: ErrorMessage[] = [];

  constructor(
    private statementService: StatementService,
    private store: Store<State>
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(getSelectsData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectsData: SelectsDataInterface) => {
        this.dataSelects = selectsData;
      });

    this.postalCodeSubject$
      .asObservable()
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(3000))
      .subscribe(({ postalCode, country }) => {
        this.getTaxAuthorityByPostalCode(postalCode, country);
      });

    this.isTaxAuthorityShown = !this.formStatement.isSpouse();

    this.onPlaceChange(this.formStatement.postalCode, this.formStatement.country);
  }

  public onPlaceChange(postalCode: string, country: string): void {
    this.showLiveInGermanyField = this.formStatement.isLiveInGermanyFieldRequired && !this.isBusiness;

    if (!postalCode) {
      return;
    }

    this.postalCodeSubject$.next({ postalCode, country });
  }

  private getTaxAuthorityByPostalCode(postalCode: string, country: string): void {
    if (this.isTaxAuthorityShown) {
      return;
    }

    this.isTaxAuthorityLoading = true;
    this.statementService
      .getTaxAuthorityByPostalCode(postalCode, country)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.isTaxAuthorityLoading = false))
      )
      .subscribe((taxAuthorities: TaxAuthority[]) => {
        if (taxAuthorities.length > 0) {
          this.formStatement.taxAuthority = taxAuthorities[0].id;
        }
      });
  }
}
