import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentCancellationComponent } from 'app/core/payment-cancellation/payment-cancellation.component';
import { TaxAdvisorLicenceComponent } from 'app/core/tax-advisor-licence/tax-advisor-licence.component';
import { PackageOptions } from 'app/statements/package-options';
import { Statement } from 'app/statements/statement';
import { TermsFilesService } from 'app/core/services/terms-files.service';

@Component({
  selector: 'app-payment-regulations',
  templateUrl: './payment-regulations.component.html',
  styleUrls: ['./payment-regulations.component.scss']
})
export class PaymentRegulationsComponent {
  @Input() statement: Statement;
  @Input() termRegulationsIsChecked: boolean;
  @Input() termRealDataIsChecked: boolean;
  @Output() clickTermRegulations = new EventEmitter();
  @Output() clickTermRealData = new EventEmitter();
  public packageOption = PackageOptions;

  constructor(
    private dialog: MatDialog,
    private termsFilesService: TermsFilesService,
  ) { }

  openTermsAndConditions() {
    this.termsFilesService.openTermsAndConditions();
  }

  openPaymentCancellationDialog() {
    this.dialog.open(PaymentCancellationComponent);
  }

  openPrivacyPolicy() {
    this.termsFilesService.openPrivacyPolicy();
  }

  public openTaxAdvisorLicence() {
    this.dialog.open(TaxAdvisorLicenceComponent);
  }

  public showRegulationCheckBox(): boolean {
    return !!this.statement?.isPremiumPackage;
  }
}
