import { Component, Input, OnInit } from '@angular/core';
import { Statement } from '../statement';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent implements OnInit {
  @Input() statement: Statement;
  @Input() errors;
  @Input() isBusiness = false;
  @Input() iban: string;
  modifier = '';
  public swiftRequired = false;
  public moneyInstitutionRequired = false;

  constructor() { }

  ngOnInit() {
    if (this.statement.isSpouse()) {
      this.modifier = 'spouse.';
    }
    this.setSwiftRequired();
    this.setMoneyInstitutionRequired();
  }

  toggleOtherOwner() {
    this.statement.otherPersonOwnsBankAccount = !this.statement.otherPersonOwnsBankAccount;
    if (!this.statement.otherPersonOwnsBankAccount || !this.statement.isMarried) {
      this.statement.spouseOwnsBankAccount = false;
    }
    this.resetBankAccountHolder();
  }

  toggleSpouseOwner() {
    this.statement.spouseOwnsBankAccount = !this.statement.spouseOwnsBankAccount;
    this.resetBankAccountHolder();
  }

  private resetBankAccountHolder() {
    if (!this.statement.otherPersonOwnsBankAccount || (this.statement.isMarried && this.statement.spouseOwnsBankAccount)) {
      this.statement.bankAccountHolder = '';
    }
  }

  public setSwiftRequired() {
    this.swiftRequired = false;

    if (this.statement.fullYear === 2016) {
      this.swiftRequired = true;
    }

    if (!this.statement.iban)
      return;

    if (!this.statement.iban.toUpperCase().startsWith('DE')) {
      this.swiftRequired = true;
    }
  }

  public setMoneyInstitutionRequired() {
    this.moneyInstitutionRequired = false;
    if (this.statement.fullYear === 2016) {
      this.moneyInstitutionRequired = true;
    }
  }

  public toggleHasNoBankAccount() {
    this.statement.hasBankAccount = !this.statement.hasBankAccount;
  }

  private clearSwiftAndBankName() {
    if (!this.statement.iban)
      return;

    if (this.statement.fullYear !== 2016) {
      if (this.statement.iban.toUpperCase().startsWith('DE')) {
        this.statement.bic = '';
        this.statement.moneyInstitution = '';
      }
    }
  }

  public changeHasNoBankAccount(){
    this.statement.hasBankAccount = !this.statement.hasBankAccount;
  }

  public ibanChanged() {
    this.setSwiftRequired();
    this.setMoneyInstitutionRequired();
    this.clearSwiftAndBankName();
  }

}
