import { BaseStatementPagePath } from 'app/core/workflow/page-path/statement-page-path/base-statement-page.path';

export class B2bTaxCardComponentPath extends BaseStatementPagePath {
  public static path(): string {
    return 'statement/:statementId/employer/:employerId';
  }

  public static regex(): RegExp {
    return /(\/statement\/)\d+\/employer\/\d+((\/?#?)|(\/?#.*?))$/;
  }
}
