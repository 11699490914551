import { Component } from '@angular/core';

@Component({
  selector: 'app-tooltip-template-home-service',
  templateUrl: './tooltip-template-home-service.component.html',
  styleUrls: ['./tooltip-template-home-service.component.scss']
})

export class HomeServiceTooltipTemplate{
  constructor() {}
}
