import { Component } from '@angular/core';
import { NotificationBaseComponent } from 'app/shared/components/notifications/notification-base.component';

@Component({
  ...NotificationBaseComponent.metaData,
  selector: 'app-notification-alert-box',
  templateUrl: './notification-alert-box.component.html',
  styleUrls: ['./notification-alert-box.component.scss']
})
export class NotificationAlertBoxComponent extends NotificationBaseComponent {
}
