export class Loading {
  private _value: boolean

  constructor(value: boolean = true) {
    this._value = value
  }

  get enabled(): boolean {
    return this._value
  }

  get disabled(): boolean {
    return !this._value
  }

  public enable = (): boolean => this._value = true

  public disable = (): boolean => this._value = false

  set set(value: boolean) {
    this._value = value
  }
}
