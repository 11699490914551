<app-ctr-base
  [templateRef]="taxCard"
  [title]="title"
  [categoryProperties]="categoryProperties"
  [dataSelects]="dataSelects"
  [summary]="summary"
></app-ctr-base>

<ng-template #taxCard>
    <div class="card card-white shadow-0 mb-2" *ngIf="!summary" id="taxCardTab">
        <div class="card__row mb-1">
            <div class="table">
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'CONFIRM_TAX_RETURN.INCOME_SUM.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ refundDetails[person].einkunfteAusNichtselbstandigerArbeit | number: '1.2-2' }} €"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-white shadow-0 mb-2" *ngFor="let employer of statement.employers; let i = index;">
        <div class="card__row mb-2">
            <label class="input_label d-block">
                {{ 'INPUT.TAX_CLASS.LABEL' | translate }}
            </label>
            <input
              [attr.data-cy]="'verificationEmployerTaxClass_' + person"
              class="w-auto"
              readonly
              value="{{ findInArray(dataSelects.taxClasses, 'id', employer.taxClass, 'name') }}"
            />
        </div>
        <div class="card__row mb-1">
            <div class="table">
                <div class="table__row" *ngIf="employer.workDateStart || employer.workDateEnd">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.CERTIFICATION_PERIOD.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{employer.workDateStart}}"/>
                        <div class="table__sep"></div>
                        <input readonly value="{{employer.workDateEnd}}"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib03 && employer.ib03 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.GROSS_WORKING_WAGES.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input
                          [attr.data-cy]="'verificationEmployerIb03_' + person"
                          readonly
                          value="{{ employer.ib03 | number: '1.2-2' }} €"
                        />
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib04 && employer.ib04 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.INCOME_TAX.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib04 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib05 && employer.ib05 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.SOLIDARITY_SURCHARGE.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib05 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib06 && employer.ib06 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.CHURCH_TAX.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib06 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib07 && employer.ib07 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.RETAINED_CHURCH_TAX.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib07 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib15 && employer.ib15 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.SHORTTERM_ALLOWANCE.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib15 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib17 && employer.ib17 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.BUSINESS_TRIPS_COSTS.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib17 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib18 && employer.ib18 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.TRIPS_TO_WORK_COSTS.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib18 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib19 && employer.ib19 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.WAGES_NOT_COVERED_BY_REDUCED_TAX.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib19 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib20 && employer.ib20 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.TAXABLE_GRANTS_FOR_EXTERNAL_ACTIVITIES.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib20 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib21 && employer.ib21 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.TAX_FREE_EMPLOYER_BENEFITS.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib21 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib22a && employer.ib22a !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.PENSION_INCURANCE_EMPLOYER.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib22a | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib23a && employer.ib23a !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.PENSION_INCURANCE_EMPLOYEE.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib23a | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib24a && employer.ib24a !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.HEALTH_INSURANCE_EMPLOYER_SUBSIDIES.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib24a | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib24b && employer.ib24b !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.PRIVATE_HEALTH_INSURANCE_EMPLOYER_SUBSIDIES.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib24b | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib24c && employer.ib24c !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.CARE_INSURANCE_EMPLOYER_SUBSIDIES.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib24c | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib25 && employer.ib25 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.HEALTH_INSURANCE_EMPLOYEE_CONTRIBUTIONS.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib25 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib26 && employer.ib26 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.LONGTERM_CARE_INSURANCE.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib26 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib27 && employer.ib27 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.UNEMPLOYMENT_INSURANCE.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib27 | number: '1.2-2' }} €"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="employer.ib28 && employer.ib28 !== '0.00'">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.PROVEN_PRIVATE_HEALTH_INSURANCE.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ employer.ib28 | number: '1.2-2' }} €"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
