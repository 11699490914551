export class UtilityHelper {
  public static scrollToTop(): void {
    window.scroll(0, 0);
  }

  public static async sleep(options: {ms?: number, fn?: Function, args?: []} = {}): Promise<void> {
    const fn = options.fn || (() => {}), args = options.args || [], ms = options.ms || 1000;

    await new Promise(resolve => setTimeout(resolve, ms));

    return fn(...args);
  }
}
