import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartAppBannerComponent } from 'app/shared/modules/smart-app-banner/components/smart-app-banner/smart-app-banner.component';

@NgModule({
  declarations: [
    SmartAppBannerComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SmartAppBannerComponent,
  ]
})
export class SmartAppBannerModule {
}
