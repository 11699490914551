<div class="menu-bar-mobile">
  <div class="menu-bar-mobile__left">
    <a href="/dashboard" class="menu-bar-mobile__logo"></a>
    <div class="menu-bar-mobile__title"></div>
  </div>
  <div class="menu-bar-mobile__hamburger" (click)="toggleSideNav()" i18n>
    <div class="menu-bar-mobile__hamburger-stage"></div>
    <div class="menu-bar-mobile__hamburger-stage"></div>
    <div class="menu-bar-mobile__hamburger-stage"></div>
  </div>
  <ng-container *ngIf="workflowControllerService.statement && !workflowControllerService.isBusiness">
    <div id="headerMobileProgress" [style.width]="(workflowControllerService.statement | statementProgressPipe) + '%'"></div>
  </ng-container>
</div>

