<div class="tools-bar">
  <div class="tools-bar__row_helper hidden_md"></div>
  <div class="tools-bar__container main-content__container">
    <div class="tools-bar__content">
      <div class="tools-bar__content-left">
        <div
          class="tools-bar__refund-info"
          *ngIf="refundDetails && _statement"
          [class.refund]="refundDetails.total > 0"
          [class.surcharge]="refundDetails.total < 0"
          (click)="showRefundDialog()"
        >
          <img src="assets/svg/tools-bar-icon.svg" class="tools-bar__refund-img" alt="tools icon">
          <span class="tools-bar__refund is-bold"
                [ngClass]="{ 'tools-bar__refund--big': refundDetails.getAbsoluteRefund() && refundDetails.getAbsoluteRefund().length < 6 }">
                        <span class="hidden-xs">{{ refundDetails.getAbsoluteRefund() }}</span>
                        <span
                          class="visible-xs"
                          [class.font-size-mobile-20]="refundDetails.getAbsoluteRefundWithoutComma().length > 4"
                          [class.font-size-mobile-18]="refundDetails.getAbsoluteRefundWithoutComma().length > 5"
                          [class.font-size-mobile-16]="refundDetails.getAbsoluteRefundWithoutComma().length > 6"
                          [class.font-size-mobile-14]="refundDetails.getAbsoluteRefundWithoutComma().length > 7"
                        >
                          {{ refundDetails.getAbsoluteRefundWithoutComma() }}
                        </span>
                    </span>
          <span class="tools-bar__refund-arrow"></span>
        </div>
      </div>
      <div class="tools-bar__buttons">
        <button
          #buttons
          *ngFor="let button of buttons; let i = index"
          class="btn btn--longer text-wrap"
          [class.btn--standard]="button.type === 'standard'"
          [class.btn--info]="button.type === 'info'"
          [class.btn--primary]="button.type === 'proceed'"
          [class.btn--small]="button.type === 'standard' || button.type === 'info'"
          [class.btn--skip]="button.type === 'skip'"
          [class.btn--save]="button.type === 'save'"
          [class.btn--back]="button.type === 'back'"
          [class.btn--danger]="button.type === 'danger'"
          [id]="setButtonId(button.type)"
          (click)="button.action()"
          [disabled]="button.disabled$ ? (button.disabled$ | async) : (button.disabled ? button.disabled() : false)"
          mat-stroked-button
        >
          <span class="btn--tx">{{ (button.label) | translate }}</span>
          <ng-container *ngIf="button.type === 'back'">
            <img src="assets/svg/toolsbar/back_btn.svg" alt="back">
          </ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
