import {Component, Input, OnInit} from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { FirebaseEventsService } from 'app/common/firebase-events.service';

@Component({
  selector: 'app-send-statement-onfido',
  templateUrl: './send-statement-onfido.component.html',
  styleUrls: ['./send-statement-onfido.component.scss']
})
export class SendStatementOnfidoComponent implements OnInit {
  @Input() onConfirmation: any;

  constructor(private dialogRef: MatDialogRef<SendStatementOnfidoComponent>, private firebaseEventsService: FirebaseEventsService) {
  }

  ngOnInit() {
  }

  initOnfido() {
    this.firebaseEventsService.logEvent('click_accept_identificatiom', null)
    this.onConfirmation();
    this.dialogRef.close();
  }
}
