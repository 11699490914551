<div class="mb-1" *ngIf="requireIncomeName">
  <app-form-input
    [errors]="additionalIncomeService.getError('income_name', index)"
    [label]="'ADDITIONAL_INCOME.SOCIAL_GROUP.OTHER.LABEL'"
    [placeholder]="'ADDITIONAL_INCOME.SOCIAL_GROUP.OTHER.PLACEHOLDER'"
    [(model)]="socialIncome.income_name"
    [isCurrency]="true"
  ></app-form-input>
  <app-validator-error-message
    [epValidator]="additionalIncomeService.epValidator"
    [index]="index"
    [fieldName]="additionalIncomeService.VALIDATOR_KEYS.social_other_name"
  >
  </app-validator-error-message>
</div>
<div class="mb-1">
  <app-form-input
    [errors]="additionalIncomeService.getError('amount', index)"
    [label]="'INPUT.AMOUNT.LABEL'"
    [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
    [(model)]="socialIncome.amount"
    [isCurrency]="true"
  ></app-form-input>
  <app-validator-error-message
    [epValidator]="additionalIncomeService.epValidator"
    [index]="index"
    [fieldName]="additionalIncomeService.VALIDATOR_KEYS.social_amount"
  >
  </app-validator-error-message>
</div>
<ng-container *ngIf="additionalIncomeService.mainStatement.fullYear <= 2018">
  <div class="columns mb-1">
    <div class="column pb-0">
      <app-form-date-input
        [errors]="additionalIncomeService.getError('period_of_income', index)"
        [label]="'INPUT.START_DATE.LABEL'"
        [placeholder]="
          additionalIncomeService.dateHelper.getDatePlaceholder(additionalIncomeService.currentStatement.fullYear)
        "
        [autoYear]="true"
        [attr.data-cy]="'inputAdditionalIncomePeriodOfIncomeLower_' + index"
        [currentYear]="additionalIncomeService.mainStatement.fullYear"
        [(model)]="socialIncome.periodOfIncome.lower"
      >
      </app-form-date-input>
      <app-validator-error-message
        [epValidator]="additionalIncomeService.epValidator"
        [index]="index"
        [fieldName]="additionalIncomeService.VALIDATOR_KEYS.social_period_lower"
      ></app-validator-error-message>
    </div>
    <div class="column pb-0">
      <app-form-date-input
        [errors]="additionalIncomeService.getError('period_of_income', index)"
        [label]="'INPUT.END_DATE.LABEL'"
        [placeholder]="
          additionalIncomeService.dateHelper.getDatePlaceholder(additionalIncomeService.currentStatement.fullYear)
        "
        [autoYear]="true"
        [attr.data-cy]="'inputAdditionalIncomePeriodOfIncomeupper' + index"
        [currentYear]="additionalIncomeService.mainStatement.fullYear"
        [(model)]="socialIncome.periodOfIncome.upper"
      >
      </app-form-date-input>
      <app-validator-error-message
        [epValidator]="additionalIncomeService.epValidator"
        [index]="index"
        [fieldName]="additionalIncomeService.VALIDATOR_KEYS.social_period_upper"
      ></app-validator-error-message>
    </div>
  </div>
</ng-container>
