import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-additional-options-steuer-accept',
  templateUrl: './additional-options-steuer-accept.component.html',
  styleUrls: ['./additional-options-steuer-accept.component.scss']
})
export class AdditionalOptionsSteuerAcceptComponent implements OnInit {
  @Input() accepted: boolean = false;

  constructor(private dialogRef: MatDialogRef<AdditionalOptionsSteuerAcceptComponent>) { }

  ngOnInit() {
  }

  public close() {
    this.accepted = true;
    this.dialogRef.close();
  }

}
