export interface FinanzamtAddressResponse {
    name: string;
    street: string;
    postal_code: string;
    city: string;
    region: string;
}

export class FinanzamtAddress {
    name: string;
    street: string;
    postalCode: string;
    city: string;
    region: string;

    static fromResponse(response: FinanzamtAddressResponse) {
        const finanzamtAddress = new FinanzamtAddress;
        finanzamtAddress.name = response.name;
        finanzamtAddress.street = response.street;
        finanzamtAddress.postalCode = response.postal_code;
        finanzamtAddress.city = response.city;
        finanzamtAddress.region = response.region;

        return finanzamtAddress;
    }
}
