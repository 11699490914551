<div class="add-attachment-list">
  <ul class="attachment-list sended" *ngIf="sendedAttachments.length > 0">
    <li *ngFor="let attachment of sendedAttachments; let index = index" class="attachment">
      <span class="attachment-name">{{ index + 1 }}. {{ attachment.file }}</span>
      <button type="button" class="remove" (click)="handleRemoveSendedAttachment(index)" title="remove button"></button>
    </li>
  </ul>
  <ul class="attachment-list" *ngIf="attachmentsList.length > 0">
    <li *ngFor="let attachment of attachmentsList; let index = index" class="attachment">
      <span class="attachment-name">{{ index + 1 + sendedAttachments.length }}. {{ attachment.file.name }}</span>
      <button type="button" class="remove" (click)="handleRemoveAttachment(index)" title="remove button"></button>
      <span *ngIf="!attachment.isValidated" class="invalid-file">{{ "ADD_ATTACHMENT.INVALID_FILE" | translate }}</span>
    </li>
  </ul>
  <label class="add-attachment">
    <input class="attachment-input" type="file" (change)="handleAddAttachment($event)" multiple />
    {{ buttonText | translate }}
  </label>
  <div class="attachment-info">
    <span>{{ "ADD_ATTACHMENT.ONLY_PDF" | translate }}</span>
    <span>{{ "ADD_ATTACHMENT.MAX_FILE_SIZE_10_MB" | translate }}</span>
    <span [class.error]="maxFilesNumberError"
      >{{ "ADD_ATTACHMENT.MAX_FILE_NUMBER" | translate }} {{ maxFilesNumber }}</span
    >
    <span>{{ "ADD_ATTACHMENT.MAX_PAGE_NUMBER" | translate }}</span>
  </div>
</div>
