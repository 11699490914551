import { Statement } from 'app/statements';
import { PensionIncomeTypes } from 'app/modules/statement/models/additional-income/pension-income.types';
import { DateHelper } from 'app/core/helpers/date.helper';

export interface PensionIncomeResponse {
  id: number;
  pension_from: string;
  pension_amount: string;
  pension_start: string;
  pension_adjustment: string;
}

export interface PensionIncomeErrors {
  pension_from?: string[];
  pension_amount?: string[];
  pension_start?: string[];
  pension_adjustment?: string[];
}

export class PensionIncome {
  public id: number;
  public incomeType: PensionIncomeTypes;
  public from = '';
  public amount = 0.00;
  public start: any;
  public adjustment = 0.00;
  public showAdjustment = true;

  public static fromResponse(response: PensionIncomeResponse): PensionIncome {
    const pensionIncome = new PensionIncome();

    pensionIncome.id = response.id;
    pensionIncome.from = response.pension_from;
    pensionIncome.amount = Number(response.pension_amount) || 0.00;
    pensionIncome.start = response.pension_start ? new Date(response.pension_start) : '';
    pensionIncome.adjustment = Number(response.pension_adjustment) || 0.00;

    return pensionIncome;
  }

  public static fromStorage(response: PensionIncome): PensionIncome {
    const pensionIncome = new PensionIncome();
    pensionIncome.id = response.id;
    pensionIncome.from = response.from;
    pensionIncome.amount = Number(response.amount) || 0.00;
    pensionIncome.start = response.start;
    pensionIncome.adjustment = Number(response.adjustment) || 0.00;

    return pensionIncome;
  }

  constructor(incomeType?: PensionIncomeTypes) {
    this.incomeType = incomeType;
  }

  public payload(defaultValue = undefined): PensionIncomeResponse {
    return {
      id: this.id ? this.id : undefined,
      pension_from: this.from ? this.from : '0',
      pension_amount: this.amount ? String(Math.floor(this.amount)) : defaultValue,
      pension_start: this.start ? this.dateToString() : defaultValue,
      pension_adjustment: this.adjustment ? String(Math.floor(this.adjustment)) : '0'
    };
  }

  public dateToString() {
    return DateHelper.getFormatted((new Date(this.start)), 'YYYY-MM-DD');
  }

  public incomeStartChange(statement: Statement) {
    if (this.start instanceof Date) {
      const pensionYear: any = new Date(this.start).getFullYear();
      if (statement.fullYear - pensionYear >= 2) {
        this.showAdjustment = true;
      } else {
        this.adjustment = 0.00;
        this.showAdjustment = false;
      }
    }
  }
}

export interface PensionFromList {
  id: number;
  name: string;
}

export const pensionListBefore2019: PensionFromList[] = [
  {
    id: 1,
    name: 'PENSION_INCOME.PENSION_FROM_LIST.1'
  },
  {
    id: 3,
    name: 'PENSION_INCOME.PENSION_FROM_LIST.2'
  },
  {
    id: 2,
    name: 'PENSION_INCOME.PENSION_FROM_LIST.3'
  },
  {
    id: 9,
    name: 'PENSION_INCOME.PENSION_FROM_LIST.4'
  },
  {
    id: 4,
    name: 'PENSION_INCOME.PENSION_FROM_LIST.5'
  }
];

export const pensionList2019: PensionFromList[] = [
  {
    id: 0,
    name: 'PENSION_INCOME.PENSION_FROM_LIST.1'
  },
  {
    id: 0,
    name: 'PENSION_INCOME.PENSION_FROM_LIST.2'
  },
  {
    id: 0,
    name: 'PENSION_INCOME.PENSION_FROM_LIST.3'
  },
  {
    id: 1,
    name: 'PENSION_INCOME.PENSION_FROM_LIST.4'
  },
  {
    id: 0,
    name: 'PENSION_INCOME.PENSION_FROM_LIST.5'
  }
];
