<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
</div>

<h2 mat-dialog-title>{{ 'LOHNSTEUER_VALIDATOR.TITLE' | translate}}</h2>

<mat-dialog-content>
    <ng-container *ngIf="statementsValidator">
        <ng-container *ngFor="let employer of statementsValidator">
            <div class="valid_errors" *ngIf="!employer.isValid()">

                <h4>{{ 'LOHNSTEUER_VALIDATOR.SUBTITLE_1' | translate }}
                    <ng-container *ngIf="statementsValidator.length>1">
                        {{employer.employerNumber}}
                    </ng-container>
                    {{ 'LOHNSTEUER_VALIDATOR.SUBTITLE_2' | translate }}</h4>
                <ul>
                    <li *ngIf="employer.ib03">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB03_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB03' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib04">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB04_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB04' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib05">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB05_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB05' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib06">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB06_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB06' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib22a">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB022a_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB022a' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib23a">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB023a_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB023a' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib24c">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB024c_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB024c' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib25">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB025_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB025' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib26">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB026_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB026' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib27">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB027_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB027' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib04vsib03">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB03_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB04VSIB03' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib05vsib04">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB05_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB05VSIB04' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib06vsib04">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB06_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB06VSIB04' | translate }}
                        </div>
                    </li>
                    <li *ngIf="employer.ib07vsib04">
                        <div class="field_error">
                            <span>{{ 'LOHNSTEUER_VALIDATOR.IB07_1' | translate }}</span>
                            {{ 'LOHNSTEUER_VALIDATOR.IB07VSIB04' | translate }}
                        </div>
                    </li>
                </ul>
            </div>
        </ng-container>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="mat-raised-button btn btn--longer btn--back btn--small"
        (click)="close()">{{ 'LOHNSTEUER_VALIDATOR.CLOSE' | translate }}</button>
    <button class="mat-raised-button btn btn--longer btn--primary"
        (click)="proceed()">{{ 'LOHNSTEUER_VALIDATOR.CANCEL' | translate }}</button>
</mat-dialog-actions>