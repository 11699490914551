<h3 class="title_24">
  {{ 'STEUER_PAYMENT_BANNER.MAINTITLE' | translate }}
</h3>
<div class="payment-banner">
  <ng-container *ngIf="statement.isStandardPackage">
    <div class="payment-banner__left-wrap">
      <div class="payment-banner__title">
        {{ 'PAYMENT_BANNER.PREMIUM_MAIN_TITLE' | translate }}
      </div>
    </div>
    <div class="payment-banner__tooltip_wrap">
      <app-more-info-tooltip
        [showText]="false"
        [titleTooltip]="'CHECKOUT.STANDARD_TOOLTIP_TITLE'"
        [contentTemplateRef]="standardPackageTooltipTemplate"
      ></app-more-info-tooltip>

      <ng-template #standardPackageTooltipTemplate>
        <app-tooltip-template-package-info 
          [isStandardPackage]="true"
          [isPackageBenefits]="true"
          [isHowDoesItWork]="true"
          [standardAmount]="statementPaymentAmount.standardAmountTotal"
          [premiumAmount]="statementPaymentAmount.premiumAmountTotal"
          [currencySymbol]="statementPaymentAmount.currencySymbol">
        </app-tooltip-template-package-info>
      </ng-template>
    </div>

    <div class="payment-banner__right">
      <div class="payment-banner__price">
        <span
          class="payment-banner__price-currancy"
          [class.payment-banner__price-currancy-over-four]="statementPaymentAmount.standardAmountTotal.toString().length >= 2"
        >
          {{ statementPaymentAmount.currencySymbol }}
        </span>
        <span
          class="payment-banner__price-text"
          [class.payment-banner__price-text-over-four]="statementPaymentAmount.standardAmountTotal.toString().length >= 2"
        >
          {{ statementPaymentAmount.standardAmountTotal | number: '1.2-2' }}
        </span>
      </div>
      <div class="payment-banner__summary">
        <div class="payment-banner__netto">
          {{ 'CHECKOUT.ORDER_LIST_STANDARD_NETTO' | translate }}
          <b>{{ statementPaymentAmount.currencySymbol }} {{ statementPaymentAmount.standardNet | number: '1.2-2' }}</b></div>
        <div class="payment-banner__vat">
          {{ 'PAYMENT_BANNER.ZZGL' | translate }}
          {{ 'PAYMENT_BANNER.MWST' | translate }}
          <b>{{ statementPaymentAmount.currencySymbol }} {{ statementPaymentAmount.standardAmountVat | number: '1.2-2' }}</b>
          ({{ statementPaymentAmount.vatPercentFormatted }})
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="statement.isPremiumPackage">
    <div class="payment-banner__left-wrap">
      <div class="payment-banner__title">
        {{ 'PAYMENT_BANNER.TAX_ADVISER_TITLE' | translate }}
      </div>
      <img src="../../../assets/svg/steuer_icon.svg" />
    </div>
    <div class="payment-banner__tooltip_wrap">
      <app-more-info-tooltip
        [showText]="false"
        [titleTooltip]="'CHECKOUT.STEUER_TOOLTIP_TITLE'"
        [message]="'CHECKOUT.STEUER_TOOLTIP_DESC'"
        [contentTemplateRef]="premiumPackageTooltipTemplate"
      ></app-more-info-tooltip>

      <ng-template #premiumPackageTooltipTemplate>
        <app-tooltip-template-package-info 
          [isPremiumPackage]="true"
          [isPackageBenefits]="true"
          [isHowDoesItWork]="true"
          [standardAmount]="statementPaymentAmount.standardAmountTotal"
          [premiumAmount]="statementPaymentAmount.premiumAmountTotal"
          [currencySymbol]="statementPaymentAmount.currencySymbol">
        </app-tooltip-template-package-info>
      </ng-template>
    </div>

    <div class="payment-banner__right">
      <div class="d-flex align-items-center" *ngIf="statementPaymentAmount.shouldBeIndividualService">
        <div class="font-weight-bolder font-size-20" [attr.data-cy]="'isIndividualPrice'">
          {{ 'STEUER_PAYMENT_BANNER.SHOULD_BE_INDIVIDUAL_SERVICE' | translate }}
        </div>
        <app-more-info-tooltip
          class="v-align-mid pt-0_25 pl-0_5"
          [message]="'STEUER_PAYMENT_BANNER.SHOULD_BE_INDIVIDUAL_SERVICE_TOOLTIP'"
          [isSmallIcon]="true"
        ></app-more-info-tooltip>
      </div>
      <div class="payment-banner__price" *ngIf="!statementPaymentAmount.shouldBeIndividualService">
        <div class="payment-banner__total">
          {{ statementPaymentAmount.currencySymbol }}
          {{ statementPaymentAmount.premiumAmountTotal | number: '1.2-2' }}
        </div>
      </div>
      <div class="payment-banner__summary" *ngIf="!statementPaymentAmount.shouldBeIndividualService">
        <div class="payment-banner__netto">
          {{ 'CHECKOUT.ORDER_LIST_STANDARD_NETTO' | translate }}
          <b>{{ statementPaymentAmount.currencySymbol }} {{ statementPaymentAmount.premiumNet }}</b>
        </div>
        <div class="payment-banner__vat">
          {{ 'PAYMENT_BANNER.ZZGL' | translate }}
          {{ 'PAYMENT_BANNER.MWST' | translate }}
          <b>{{ statementPaymentAmount.currencySymbol }} {{ statementPaymentAmount.premiumAmountVat }}</b>
          ({{ statementPaymentAmount.vatPercentFormatted }})
        </div>
      </div>
    </div>
  </ng-container>
</div>
