import {Employer} from './employer';

export class LohnsteuerValidator {
  public employerNumber = 0;
  public ib03 = '';
  public ib04 = '';
  public ib05 = '';
  public ib06 = '';
  public ib07 = '';
  public ib22a = '';
  public ib23a = '';
  public ib24c = '';
  public ib25 = '';
  public ib26 = '';
  public ib27 = '';
  public ib04vsib03 = '';
  public ib05vsib04 = '';
  public ib06vsib04 = '';
  public ib07vsib04 = '';

  constructor(
    private employer: Employer,
    private index: number
  ) {
    this.employerNumber = this.index;

    // field 4 > field 3
    if (!this.ib03 && !this.ib04) {
      if (Number(this.employer.ib04) > Number(this.employer.ib03)) {
        this.ib04vsib03 = 'LOHNSTEUER_VALIDATOR.IB04VSIB03';
      }
    }
    // field 5 > 5,5% field 4
    if (!this.ib05 && !this.ib04) {
      const ib05vsib04 = Number(this.employer.ib04) * 0.055;
      if (Number(this.employer.ib05) > ib05vsib04) {
        this.ib05vsib04 = 'LOHNSTEUER_VALIDATOR.IB05VSIB04';
      }
    }
    // field 6 < 9% field 4
    if (!this.ib06 && !this.ib04) {
      const ib06vsib04 = Number(this.employer.ib04) * 0.09;
      if (Number(this.employer.ib06) > ib06vsib04) {
        this.ib06vsib04 = 'LOHNSTEUER_VALIDATOR.IB06VSIB04';
      }
    }
    // field 7 < 9% field 4
    if (!this.ib07 && !this.ib04) {
      const ib07vsib04 = Number(this.employer.ib04) * 0.09;
      if (Number(this.employer.ib07) > ib07vsib04) {
        this.ib07vsib04 = 'LOHNSTEUER_VALIDATOR.IB07VSIB04';
      }
    }
  }

  public selectedKeysToArray(selectedKeys: string[]): string[] {
    const result = [];
    Object.keys(this).forEach(key => {
      if (selectedKeys.indexOf(key) !== -1 && this[key]) {
        result.push(this[key]);
      }
    });

    return result;
  }

  public isValid() {
    return !(this.ib03
      || this.ib04
      || this.ib05
      || this.ib06
      || this.ib07
      || this.ib22a
      || this.ib23a
      || this.ib24c
      || this.ib25
      || this.ib26
      || this.ib27
      || this.ib04vsib03
      || this.ib05vsib04
      || this.ib06vsib04
      || this.ib07vsib04);
  }
}
