import { finalize, take, takeUntil } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Statement } from "../statement";
import { Coupon } from "../coupon";
import { StatementService } from "../statement.service";
import { SubscribingComponent } from "app/common";
import { PaymentChoices, PaymentPlatforms, preparePaymentChoicesList } from "app/payment/payment";
import { User, UserChangeDataComponent, UserDataService } from "app/users";
import { ErrorDialogComponent } from "app/common/error-dialog/error-dialog.component";
import { EmailVerificationDialogComponent } from "app/common/email-verification-dialog/email-verification-dialog.component";
import { getSelectsData, State } from "app/reducers";
import { Store } from "@ngrx/store";
import { PhoneNumberDialogComponent } from "app/common/phone-number-dialog/phone-number-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { Events, FirebaseEventsService } from "app/common/firebase-events.service";
import { WorkflowControllerService } from "app/core/workflow/workflow-controller/workflow-controller.service";
import { environment } from "environments/environment";
import { ScriptHelper } from "app/core/helpers/script.helper";
import { StatementPayment } from "app/statements/statement-payment";
import { StatementPaymentService } from "app/statements/statement-payment.service";
import { StatementPaymentAmount } from "app/statements/statement-payment-amont";
import { StatementPaymentAmountService } from "app/statements/statement-payment-amount.service";
import { BehaviorSubject, combineLatest, firstValueFrom } from "rxjs";
import { PaymentService } from "app/payment/payment.service";
import { ToolsBarButtonInterface } from "app/shared/components/tools-bar/tools-bar.component";
import { SelectsData, SelectsDataInterface } from "app/statements/selects-data";
import { FirebaseAnalyticsService } from "app/shared/modules/firebase/services/firebase-analytics.service";
import { AnalyticsService } from "app/shared/modules/analytics/services/analytics.service";

declare const window;

// TODO: migrate from normal vars to Subject/BehaviorSubject,  especially in the case of loader variables, statement etc
// TODO: migrate to standalone
@Component({
  selector: "app-payment-checkout",
  templateUrl: "./payment-checkout.component.html",
  styleUrls: ["./payment-checkout.component.scss"]
})
export class PaymentCheckoutComponent extends SubscribingComponent implements OnInit {
  public statement: Statement;
  public userData: User;
  public couponCode: string = null;
  public couponError: string[];
  public errors: any[] = [];
  public selectedPaymentType: PaymentPlatforms = null;
  public statementPaymentAmount: StatementPaymentAmount;
  public paymentPlatforms = PaymentPlatforms;
  public paymentChoices = PaymentChoices;
  public showSelectedPaymentError: boolean;
  public showAllPaymentsMethod = false;
  public showPaymentsButton = false;
  public buttons: ToolsBarButtonInterface[] = [];
  public showCoupon = false;
  public couponSuccess = false;
  public coupon: Coupon;
  public termRegulationsIsChecked = false;
  public termRealDataIsChecked = false;
  public termRegulationsIsChecked$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public termRealDataIsChecked$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showSquareLoader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showLoader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public dataSelects: SelectsDataInterface = new SelectsData();
  private statementId: number;
  private dialogComponentEmailVerification = EmailVerificationDialogComponent;
  private stripe: any;
  private isAndroid = false;
  private isIos = false;
  private isWeb = false;
  private loadingStatementData = true;
  private loadingStatementPaymentAmount = true;
  private loading = false;
  private loadingStatementData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loadingStatementPaymentAmount$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loadingSquareLoader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loadingSquareLoader = false;
  private _isInitialized = true;
  private statementPayment: StatementPayment;

  constructor(
    private statementService: StatementService,
    private statementPaymentAmountService: StatementPaymentAmountService,
    private statementPaymentService: StatementPaymentService,
    private userDataService: UserDataService,
    private paymentService: PaymentService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>,
    private dialog: MatDialog,
    private translate: TranslateService,
    private firebaseEventsService: FirebaseEventsService,
    public workFlowController: WorkflowControllerService,
    public analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit() {
    if (window.AnalyticsWebInterface) {
      this.isAndroid = true;
    } else if (window.webkit && window.webkit.messageHandlers) {
      this.isIos = true;
    } else {
      this.isWeb = true;
    }
    combineLatest([this.userDataService.getUserData(), this.store.select(getSelectsData), this.route.params])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: ([user, dataSelect, params]) => {
          if (user && dataSelect && params) {
            this.userData = user;
            this.dataSelects = dataSelect;

            this.statementId = parseInt(params["statementId"], 10);
            this._isInitialized$.next(false);
            this._isInitialized = false;
            this.getStatementData();
            this.getStatementPaymentAmount();
          }
        }
      });

    combineLatest([this.loadingStatementData$, this.loadingStatementPaymentAmount$, this._isInitialized$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: ([loadingStatementData, loadingStatementPaymentAmount, _isInitialized]) => {
          if (!loadingStatementData && !loadingStatementPaymentAmount && _isInitialized) {
            this.isInitialized$.next(true);
            return;
          }

          this.isInitialized$.next(false);
        }
      });

    combineLatest([this.loading$, this.isInitialized$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: ([loading, isInitialized]) => {
          if (loading || !isInitialized) {
            this.showLoader$.next(true);
            return;
          }

          this.showLoader$.next(false);
        }
      });

    combineLatest([this.showLoader$, this.loadingSquareLoader$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: ([showLoader, loadingSquareLoader]) => {
          if (!showLoader && loadingSquareLoader) {
            this.showSquareLoader$.next(true);
            return;
          }

          this.showSquareLoader$.next(false);
        }
      });

    this.loadStripe();
    this.clearErrors();
    this.paymentOrderChoice();
  }

  public get isInitialized(): boolean {
    return !this.loadingStatementData && !this.loadingStatementPaymentAmount && this._isInitialized;
  }

  public get isLoading(): boolean {
    return this.loading || !this.isInitialized;
  }

  public get isLoadingSquareLoader(): boolean {
    return !this.isLoading && this.loadingSquareLoader;
  }

  public couponChanged(): void {
    this.couponError = [];
    this.couponSuccess = false;
  }

  public addCode(): void {
    this.couponError = [];
    this.couponSuccess = false;
    if (this.couponCode) {
      this.paymentService
        .checkCoupon(this.statementId, this.couponCode.toUpperCase())
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (response: Coupon) => {
            this.coupon = response;
            this.couponSuccess = true;

            this.statementPaymentAmount.premiumAmountTotal = this.coupon.couponAmount;
            this.statementPaymentAmount.standardAmountTotal = this.coupon.couponAmount;

            this.paymentService
              .getVatRate(this.statementId)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (response: any) => {
                  this.statementPaymentAmount.premiumAmountVat = this.coupon.couponAmount * response.vat_rate;
                  this.statementPaymentAmount.standardAmountVat = this.coupon.couponAmount * response.vat_rate;
                }
              });
          },
          error: (errors: any) => {
            this.coupon = null;

            if (errors.error && errors.error.coupon) {
              this.couponError = [errors.error.coupon];
            } else {
              this.showErrorDialog(errors.error);
            }
            this.couponSuccess = false;
            setTimeout(() => (this.couponError = []), 3000);
          }
        });
    } else {
      this.couponError = [this.translate.instant("SNACKBAR.ENTER_COUPON")];
      setTimeout(() => (this.couponError = []), 3000);
    }
  }

  public setPremiumPackage(): void {
    this.firebaseEventsService.logEvent(Events.click_upgrade_premium, null, this.statement.id);
    this.loadingSquareLoader = true;
    this.loadingSquareLoader$.next(true);
    if (!this.statement.phoneNumber || !this.statement.emailAddress) {
      const dialogRef = this.dialog.open(PhoneNumberDialogComponent, { disableClose: true });
      dialogRef.componentInstance.statement = this.statement;

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.setPremiumPackageAfterPhoneVerification();
          }
        });
    } else {
      this.setPremiumPackageAfterPhoneVerification();
    }
  }

  public clickTermRegulations(isChecked: boolean): void {
    this.termRegulationsIsChecked = isChecked;
    this.termRegulationsIsChecked$.next(isChecked);
  }

  public clickTermRealData(isChecked: boolean): void {
    this.termRealDataIsChecked = isChecked;
    this.termRealDataIsChecked$.next(isChecked);
  }

  public setStandardPackage(): void {
    this.firebaseEventsService.logEvent(Events.click_downgrade_standard, null, this.statement.id);

    this.statement.setStandardPackage();
    this.updateStatementOnPackageChanges();
  }

  public scrollToSection(index: string): void {
    const element = document.getElementById(index);

    if (element) {
      element.scrollIntoView();
      setTimeout(() => (this.showSelectedPaymentError = false), 3000);
    }
  }

  public showAllPayments(): void {
    this.showAllPaymentsMethod = true;
  }

  private setupStripe(): void {
    this.stripe = window["Stripe"](environment.stripe);
  }

  private createPayment() {
    this.loading = true;
    this.loading$.next(true);
    this.statementPaymentService
      .createPayment(this.statementId, this.selectedPaymentType, this.couponCode)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (statementPaymentResponse: StatementPayment) => {
          this.statementPayment = statementPaymentResponse;
          this.analyticsService.logStandardPurchase(
            Number(this.statement.paymentAmount),
            this.statement.paymentCurrency
          );
          this.onSuccessCreatePaymentResponse();
        },
        error: (errors: any) => {
          this.loading = false;
          this.loading$.next(false);
          this.showErrorDialog(errors.error);
        }
      });
  }

  private setButtons(): void {
    this.buttons = [
      {
        type: "back",
        label: "COMMON.BACK",
        action: this.goBack.bind(this)
      },
      {
        type: "proceed",
        label: this.statement.isPremiumPackage || this.statement.canBeFreeService ? "COMMON.ORDER" : "COMMON.PAY",
        action: this.proceed.bind(this),
        disabled: this.isProceedDisabled.bind(this)
      }
    ];
  }

  private isProceedDisabled(): boolean {
    return (
      this.isLoading ||
      this.isLoadingSquareLoader ||
      !this.termRegulationsIsChecked ||
      !this.termRealDataIsChecked ||
      (!this.selectedPaymentType && this.statement.isStandardPackage && !this.statement.canBeFreeService)
    );
  }

  private clearErrors(): void {
    this.errors = [];
  }

  private getStatementPaymentAmount(): void {
    this.loadingStatementPaymentAmount = true;
    this.loadingStatementPaymentAmount$.next(true);

    this.statementPaymentAmountService
      .getStatementPaymentAmount(this.statementId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response: StatementPaymentAmount) => {
          this.loadingStatementPaymentAmount = false;
          this.loadingStatementPaymentAmount$.next(false);
          this.statementPaymentAmount = response;

          if (this.statementPaymentAmount.standardAmountTotal === 0) {
            this.selectedPaymentType = PaymentPlatforms.przelewy24;
          }
        },
        error: (error) => {
          console.error(error);
        }
      });
  }

  private getStatementData(): void {
    this.loadingStatementData = true;
    this.loadingStatementData$.next(true);
    this.statementService
      .getStatement(this.statementId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response: Statement) => {
          this.statement = response;
          this.workFlowController.init(this.statement);
          this._isInitialized$.next(true);
          this._isInitialized = true;
          this.showAllPaymentsMethod = true;

          this.afterPackageChanged();

          this.setButtons();
          this.loadingStatementData = false;
          this.loadingStatementData$.next(false);

          if (!this.statement.isStandardPackageAvailable && this.statement.isStandardPackage) {
            this.setPremiumPackage();
          }
          if (!this.statement.isPremiumPackageAvailable && this.statement.isPremiumPackage) {
            this.setStandardPackage();
          }
        },
        error: () => {
          this.router.navigate(["/new"]);
        }
      });
  }

  private goBack(): void {
    this.workFlowController.goToThePreviousStep();
  }

  private async proceed(): Promise<void> {
    if (this.isProceedDisabled()) {
      return;
    }

    this.firebaseEventsService.logEvent(
      Events.click_payment,
      {
        type: this.statement.isStandardPackage ? "standard" : "steuer++",
        payment_method: this.selectedPaymentType
      },
      this.statementId
    );

    this.loading = true;
    this.loading$.next(true);
    this.userData = await this.getUserData();

    if (!this.userData?.emailVerified) {
      this.showEmailVerDialog();
      this.loading$.next(false);
      this.loading = false;
      return;
    }

    if (this.statement.isPremiumPackage) {
      this.loading = true;
      this.loading$.next(true);
      this.statementPaymentService
        .markPaymentCompleteTaxAdviser(this.statementId, this.couponCode)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (response: any) => {
            this.statement.isPaid = true;
            this.analyticsService.logPremiumPurchase(
              Number(this.statement.paymentAmount),
              this.statement.paymentCurrency
            );

            const url = response.redirect_url;
            if (url) {
              window.location.href = url;
              return;
            }

            this.workFlowController.goToTheNextStep();
          },
          error: (errors: any) => {
            this.loading = false;
            this.loading$.next(false);
            this.showErrorDialog(errors.error);
          }
        });

      return;
    }

    if (!this.selectedPaymentType) {
      this.showSelectedPaymentError = true;
      const interval = setInterval(() => {
        const elementToScroll = document.getElementById("selectedPaymentAlertBox");
        if (elementToScroll) {
          this.scrollToSection("selectedPaymentAlertBox");
          clearInterval(interval);
        }
      }, 10);
    } else {
      this.clearErrors();
      this.createPayment();
    }
  }

  private showErrorDialog(errors: any): void {
    if (!errors || errors.length <= 0) {
      return;
    }
    if (errors.code === "profile_not_completed") {
      this.dialog.open(UserChangeDataComponent, {
        backdropClass: "backdropBackgroundWhite",
        panelClass: ["personal-info-dialog-wrap", "new-dialog-wrap"]
      });
      return;
    }

    let errorMessage = "";
    let understandButton = false;

    if (errors.detail) {
      errorMessage = errors.detail;
    } else if (errors.statement) {
      errorMessage = errors.statement;
      understandButton = true;
    } else if (errors.coupon) {
      errorMessage = errors.coupon;
      understandButton = true;
    } else {
      errorMessage = errors[0] ? errors[0] : "";
    }

    const dialogRef = this.dialog.open(ErrorDialogComponent);
    dialogRef.componentInstance.understandButton = understandButton;
    dialogRef.componentInstance.message = `<div class="text-center mb-0_25">${errorMessage}</div>`;
  }

  private onPaymentSuccess(url: string): void {
    if (!environment.production && window.location.host === "localhost:4200") {
      url = url.replace("https://localhost/", window.location.origin + "/");
    }

    window.location.href = url;
  }

  private onSuccessCreatePaymentResponse(): void {
    if (this.statementPayment) {
      if (this.statementPayment.redirectUrl) {
        this.onPaymentSuccess(this.statementPayment.redirectUrl);
        return;
      }
      if (this.statementPayment.stripeSessionId) {
        this.payStripe();
        return;
      }

      // this.statement.isPaid = true;
      // this.workFlowController.goToTheNextStep();
    }
  }

  private setPremiumPackageAfterPhoneVerification(): void {
    this.statement.setPremiumPackage();
    this.updateStatementOnPackageChanges();
  }

  private updateStatementOnPackageChanges(): void {
    this.loadingSquareLoader = true;
    this.loadingSquareLoader$.next(true);
    this.statementService
      .updateStatement(this.statement.id, { service_type: this.statement.serviceType })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response) => {
          Object.assign(this.statement, response);
          this.statementService
            .verifyStatement(this.statement.id)
            .pipe(
              takeUntil(this.ngUnsubscribe),
              finalize(() => {
                this.loadingSquareLoader$.next(false);
                this.loadingSquareLoader = false;

                this.addCode();
              })
            )
            .subscribe({
              next: () => {
                this.afterPackageChanged();
              },
              error: (errors) => {
                this.showErrorDialog(errors.error);
              }
            });
        },
        error: (errors) => {
          this.showErrorDialog(errors.error);
          this.loadingSquareLoader = false;
          this.loadingSquareLoader$.next(false);
        }
      });
  }

  private afterPackageChanged(): void {
    if (this.statement.isStandardPackage) {
      this.showAllPaymentsMethod = true;
      this.termRegulationsIsChecked = true;
      this.termRealDataIsChecked = true;
      this.termRegulationsIsChecked$.next(true);
      this.termRealDataIsChecked$.next(true);
    }
    if (this.statement.isPremiumPackage) {
      this.termRegulationsIsChecked = false;
      this.termRealDataIsChecked = false;
      this.termRegulationsIsChecked$.next(false);
      this.termRealDataIsChecked$.next(false);
      this.selectedPaymentType = null;
    }

    this.showCoupon = false;
    this.couponSuccess = false;
    this.setButtons();
  }

  private loadStripe(): void {
    ScriptHelper.loadStripe();
  }

  private payStripe(): void {
    this.setupStripe();
    this.stripe.redirectToCheckout({
      sessionId: this.statementPayment.stripeSessionId
    });
  }

  private paymentOrderChoice(): void {
    this.paymentChoices = preparePaymentChoicesList({
      isWeb: this.isWeb,
      isIos: this.isIos,
      isAndroid: this.isAndroid
    });
  }

  private showEmailVerDialog(): void {
    const refDialog = this.dialog.open(this.dialogComponentEmailVerification);
    refDialog.componentInstance.statement = this.statement;
  }

  private async getUserData(): Promise<User> {
    return await firstValueFrom(this.userDataService.getUserData().pipe(take(1)));
  }
}
