 <div class="step-container columns is-desktop">
    <app-aside-bar [isBusiness]="true"></app-aside-bar>
    <div class="congratulations">
        <h1 class="title">{{ 'CONGRATS.TITLE' | translate }}</h1>
        <div class="description">{{ 'CONGRATS.DESCRIPTION_SIGNED' | translate }}</div>
        <div class="congratulations__summary">
            <div class="dashboard-business__batch-text">
                    <span>Batch file</span>
                </div>
                <div class="dashboard-business__batch-content" *ngIf="statements && statements.length">
                    <app-accordion [title]="batchCode + ' - ' + batchCreatedBy" [titleFrame]="true" [transparentBg]="true"
                                   [noPadding]="true">
                        <app-list-view-b2b [statements]="statements"
                                       [hideEditing]="true"
                                       [hideRemoving]="true"
                                       [hideInvoice]="true"
                                       [hideDecision]="true"
                        ></app-list-view-b2b>
                    </app-accordion>
                </div>
        </div>
        <div class="congratulations__action">
            <div class="congratulations__action-description">{{ 'CONGRATS.THANKS_TEXT' | translate }}</div>
            <div class="congratulations__action-btn">
                <button class="btn btn--primary btn--block" [routerLink]="['/dashboard']">{{ 'CONGRATS.VIEW_DASHBOARD' | translate }}</button>
            </div>
        </div>
    </div>
</div>
