import { Platform } from '@angular/cdk/platform';
import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SmartAppBannerService } from 'app/shared/modules/smart-app-banner/services/smart-app-banner.service';

@Component({
  selector: 'app-smart-app-banner',
  templateUrl: './smart-app-banner.component.html',
  styleUrls: ['./smart-app-banner.component.scss'],
})
export class SmartAppBannerComponent {
  public modifier: string;

  constructor(
    private readonly platform: Platform,
    private readonly cookieService: CookieService,
    public readonly smartAppBannerService: SmartAppBannerService,
  ) {
    if (this.platform.ANDROID) {
      this.modifier = 'android';
    }

    if (this.platform.IOS) {
      this.modifier = 'ios';
    }
  }

  public get icon(): string | null {
    if (this.platform.ANDROID) {
      return this.smartAppBannerService.settings?.icon?.android;
    }

    if (this.platform.IOS) {
      return this.smartAppBannerService.settings?.icon?.ios;
    }

    return null;
  }

  public get buttonUrl(): string {
    if (this.platform.ANDROID) {
      return this.smartAppBannerService.settings?.buttonUrl?.android;
    }

    if (this.platform.IOS) {
      return this.smartAppBannerService.settings?.buttonUrl?.ios;
    }

    return '#';
  }

  public get priceSuffix(): string {
    if (this.platform.ANDROID) {
      return this.smartAppBannerService.settings.priceSuffix.android;
    }

    if (this.platform.IOS) {
      return this.smartAppBannerService.settings.priceSuffix.ios;
    }

    return '';
  }

  public exit(): void {
    this.cookieService.set('smartbanner_closed', '1', this.endDate('exit'));
    this.smartAppBannerService.hide();
  }

  public view(): void {
    this.cookieService.set('smartbanner_closed', '1', this.endDate('view'));
    this.smartAppBannerService.hide();
  }

  private endDate(type: string): Date | null {
    const date = new Date();

    if (!this.smartAppBannerService.settings.daysReminder) {
      return null;
    }

    const timeToLive =
      type === 'exit'
        ? this.smartAppBannerService.settings.daysHidden
        : this.smartAppBannerService.settings.daysReminder;

    date.setDate(date.getDate() + timeToLive);

    return date;
  }

  public get reviewAverage(): number {
    if (this.platform.ANDROID) {
      return this.smartAppBannerService.settings?.rating.android;
    }

    if (this.platform.IOS) {
      return this.smartAppBannerService.settings?.rating?.ios;
    }

    return 5;
  }
}
