import { BaseStatementPagePath } from 'app/core/workflow/page-path/statement-page-path/base-statement-page.path';

export class B2bDeductionInfoSpouseComponentPath extends BaseStatementPagePath {
  public static path(): string {
    return 'statement/:statementId/spouse/:spouseId/deduction-info';
  }

  public static regex(): RegExp {
    return /(\/statement\/)\d+\/spouse\/\d+\/deduction-info$/;
  }
}
