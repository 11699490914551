import {Component, Input, OnInit} from '@angular/core';
import {Statement} from 'app/statements';
import { MatDialogRef } from '@angular/material/dialog';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';

@Component({
  selector: 'app-workflow-steps-dialog',
  templateUrl: './workflow-steps-dialog.component.html',
  styleUrls: ['./workflow-steps-dialog.component.scss']
})
export class WorkflowStepsDialogComponent implements OnInit {
  @Input() statement: Statement;
  @Input() workFlowController: WorkflowControllerService;
  @Input() isBusiness = false;
  @Input() isLogged = false;

  constructor(
    private dialogRef: MatDialogRef<WorkflowStepsDialogComponent>,
  ) { }

  ngOnInit() {
  }

  onChange() {
    this.dialogRef.close();
  }
}
