import { FIELD_OPERATOR_HELPER, FieldRuleValue, FieldValidationRules } from 'app/core/form-validator/validatorInterface';
import { RegExValidator, ValidatorErrorMessage, ValidatorRules } from 'app/core/form-validator/validatorRules';
import {
  VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD
} from 'app/core/form-validator/validator-field-names/validator-field-names-personal-info-child';
import { FormValidatorConfig } from 'app/configs/form-validator.config';

export const VALIDATOR_FIELD_RULES_PERSONAL_INFO_CHILD: FieldValidationRules[] = [
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.first_name,
    classFieldName: 'firstName',
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX,
    ],
    fieldRulesValue: [
      true,
      255,
      RegExValidator.ALLOWED_CHARACTERS_ERIC,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS,
    ],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.last_name,
    classFieldName: 'lastName',
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX,
    ],
    fieldRulesValue: [
      true,
      255,
      RegExValidator.ALLOWED_CHARACTERS_ERIC,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS,
    ],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.birth_date,
    classFieldName: 'birthDate',
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DATE],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_DATE],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.identifikationsnummer,
    classFieldName: 'identifikationsnummer',
    fieldRules: [ValidatorRules.IS_REQUIRED,ValidatorRules.FIELD_IS_INTIGER],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED,ValidatorErrorMessage.FIELD_SHOULD_BE_INTIGER]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.different_address,
    classFieldName: 'differentAddress',
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX,
    ],
    fieldRulesValue: [
      true,
      255,
      RegExValidator.ALLOWED_CHARACTERS_ERIC,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS,
    ],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.child_residence_period_lower,
    classFieldName: `residence`,
    nestedArrayFieldName: 'residencePeriod.lower',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL,
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`residence.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.residencePeriod.upper`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN,
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.child_residence_period_upper,
    classFieldName: `residence`,
    nestedArrayFieldName: 'residencePeriod.upper',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL,
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`residence.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.residencePeriod.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN,
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.child_residence_address,
    classFieldName: 'residence',
    nestedArrayFieldName: 'address',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX,
    ],
    fieldRulesValue: [
      true,
      255,
      RegExValidator.ALLOWED_CHARACTERS_ERIC,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS,
    ],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.child_residence_country,
    classFieldName: 'residence',
    nestedArrayFieldName: 'country',
    fieldRules: [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_first_name,
    classFieldName: 'secondParentFirstName',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX,
    ],
    fieldRulesValue: [
      true,
      255,
      RegExValidator.ALLOWED_CHARACTERS_ERIC,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS,
    ],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_last_name,
    classFieldName: 'secondParentLastName',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX,
    ],
    fieldRulesValue: [
      true,
      255,
      RegExValidator.ALLOWED_CHARACTERS_ERIC,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS,
    ],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_birth_date,
    classFieldName: 'secondParentBirthDate',
    fieldRules: [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED, ValidatorRules.FIELD_IS_DATE],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_DATE],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.propinquity_to_second_parent,
    classFieldName: 'propinquityToSecondParent',
    fieldRules: [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.last_known_address,
    classFieldName: 'lastKnownAddress',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_IS_REG_EX,
    ],
    fieldRulesValue: [
      true,
      3,
      RegExValidator.ALLOWED_CHARACTERS_ERIC,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS,
    ],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.propinquity_from_lower,
    classFieldName: 'propinquityFrom.lower',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`propinquityFrom.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.propinquity_from_upper,
    classFieldName: 'propinquityFrom.upper',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`propinquityFrom.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_lived_abroad_from_lower,
    classFieldName: 'secondParentLivedAbroadFrom.lower',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`secondParentLivedAbroadFrom.upper`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_lived_abroad_from_upper,
    classFieldName: 'secondParentLivedAbroadFrom.upper',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`secondParentLivedAbroadFrom.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.second_parent_death_date,
    classFieldName: 'secondParentDeathDate',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
    ],
    fieldRulesValue: [
      true,
      true,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.familienkasse,
    classFieldName: 'familienkasse',
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
    ],
    fieldRulesValue: [
      true,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.kindergeld_extra,
    classFieldName: 'kindergeldExtra',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_REG_EX,
    ],
    fieldRulesValue: [
      true,
      RegExValidator.NUMBER_2_DECIMAL_ALLOW_NEGATIVE_7,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_7CHAR,
    ]
  },

  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.school_names,
    classFieldName: 'schoolNames',
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX,
    ],
    fieldRulesValue: [
      true,
      255,
      RegExValidator.ALLOWED_CHARACTERS_ERIC,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS,
    ],
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.education_dates_lower,
    classFieldName: 'educationDates',
    nestedArrayFieldName: 'lower',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`educationDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.education_dates_upper,
    classFieldName: 'educationDates',
    nestedArrayFieldName: 'upper',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`educationDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.lack_of_training_space_dates_lower,
    classFieldName: 'lackOfTrainingSpaceDates',
    nestedArrayFieldName: 'lower',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`lackOfTrainingSpaceDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.lack_of_training_space_dates_upper,
    classFieldName: 'lackOfTrainingSpaceDates',
    nestedArrayFieldName: 'upper',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`lackOfTrainingSpaceDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.voluntary_year_dates_lower,
    classFieldName: 'voluntaryYearDates',
    nestedArrayFieldName: 'lower',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`voluntaryYearDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.voluntary_year_dates_upper,
    classFieldName: 'voluntaryYearDates',
    nestedArrayFieldName: 'upper',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`voluntaryYearDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.transitional_period_dates_lower,
    classFieldName: 'transitionalPeriodDates',
    nestedArrayFieldName: 'lower',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`transitionalPeriodDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.transitional_period_dates_upper,
    classFieldName: 'transitionalPeriodDates',
    nestedArrayFieldName: 'upper',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`transitionalPeriodDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.unemployment_dates_lower,
    classFieldName: 'unemploymentDates',
    nestedArrayFieldName: 'lower',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`unemploymentDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.unemployment_dates_upper,
    classFieldName: 'unemploymentDates',
    nestedArrayFieldName: 'upper',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`unemploymentDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.disability_before_25_dates_lower,
    classFieldName: 'disabilityBefore25Dates',
    nestedArrayFieldName: 'lower',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`disabilityBefore25Dates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.disability_before_25_dates_upper,
    classFieldName: 'disabilityBefore25Dates',
    nestedArrayFieldName: 'upper',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      new FieldRuleValue([`disabilityBefore25Dates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.minijob_dates_lower,
    classFieldName: 'minijobDates',
    nestedArrayFieldName: 'lower',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`minijobDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.minijob_dates_upper,
    classFieldName: 'minijobDates',
    nestedArrayFieldName: 'upper',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`minijobDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.minijob_number_of_hours,
    classFieldName: 'minijobNumberOfHours',
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX,
    ],
    fieldRulesValue: [
      true,
      true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_6,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_6CHAR,
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.employment_dates_lower,
    classFieldName: 'employmentDates',
    nestedArrayFieldName: 'lower',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`employmentDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.employment_dates_upper,
    classFieldName: 'employmentDates',
    nestedArrayFieldName: 'upper',
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`employmentDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true),
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO_CHILD.employment_number_of_hours,
    classFieldName: 'employmentNumberOfHours',
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX,
    ],
    fieldRulesValue: [
      true,
      true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_6,
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_6CHAR,
    ]
  },
];
