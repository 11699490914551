import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';

export class WorkflowDownloadStatementService extends WorkflowStepsBaseService {
  public get isRequired(): boolean {
    return false;
  }

  public get checkCanGo(): boolean {
    return this.statement && !this.isBusiness
      && this.statement.isStandardPackage && (this.statement.isPaid || this.statement.isSent());
  }

  public get urlRegex(): RegExp {
    return /(\/statement\/)\d+(\/download-statement)((\/?#?)|(\/?#.*?))$/;
  }

  public get url(): string {
    return `statement/${this.statement && this.statement.id ? this.statement.id : 0}/download-statement`;
  }
}
