import { AuthResponseInterface } from 'app/modules/user/models/auth/auth.response.interface';
import { AuthModelInterface } from 'app/modules/user/models/auth/auth.model.interface';

export class AuthModel implements AuthModelInterface {
  username?: string;
  access: string;
  refresh: string;

  static fromResponse(response: AuthResponseInterface): AuthModel {
    const user = new AuthModel();
    // user.username = response.username; //
    user.access = response.access;
    user.refresh = response.refresh;
    return user;
  }

  constructor() { }
}
