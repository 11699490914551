<div class="income-info-edit" [class.inactive]="!active && !isNested">

  <!--END MAIN BUTTONS-->
  <div class="select-accordion-wrapper">
    <!--SOCIAL INCOME EDIT START-->
    <div class="single_accordio_wrap">
      <app-accordion
        [preIndex]="isNested ? 2 : 1"
        [title]="'ADDITIONAL_INCOME.SOCIAL_GROUP.TITLE'"
        [subTitle]="'ADDITIONAL_INCOME.SOCIAL_GROUP.DESCRIPTION_B2B'"
        [noBg]="true"
        [showActiveNr]="true"
        [allActiveNr]="selectButtonsSocial.length"
        [currActiveNr]="socialIncomeCount()"
        [currentId]="'benefits'"
      >
        <app-select-buttons [buttons]="selectButtonsSocial" [extraContainerClasses]="'p-0'"></app-select-buttons>
      </app-accordion>
    </div>
    <!--SOCIAL EDIT END-->

    <!--PESION START-->
    <div class="single_accordio_wrap">
      <app-accordion
        [preIndex]="isNested ? 3 : 2"
        [title]="'ADDITIONAL_INCOME.PENSION_GROUP.TITLE'"
        [subTitle]="'ADDITIONAL_INCOME.PENSION_GROUP.DESCRIPTION_B2B'"
        [noBg]="true"
        [showActiveNr]="true"
        [allActiveNr]="selectButtonsPension.length"
        [currActiveNr]="pensionIncomeCount()"
        [currentId]="'pension'"
      >
        <app-select-buttons [buttons]="selectButtonsPension" [extraContainerClasses]="'p-0'"></app-select-buttons>
      </app-accordion>
    </div>
    <!--PESNIONS END-->

    <!--FREELANCER EDIT START-->
    <div class="single_accordio_wrap">
      <app-accordion
        [preIndex]="isNested ? 4 : 3"
        [title]="'ADDITIONAL_INCOME.FREELANCE_GROUP.TITLE'"
        [subTitle]="'ADDITIONAL_INCOME.FREELANCE_GROUP.DESCRIPTION_B2B'"
        [noBg]="true"
        [showActiveNr]="true"
        [currentId]="'freelanceJobs'"
        [allActiveNr]="selectButtonsFreelance.length"
        [currActiveNr]="freelanceIncomeCount()"
      >
        <app-select-buttons [buttons]="selectButtonsFreelance" [extraContainerClasses]="'p-0'"></app-select-buttons>
      </app-accordion>
    </div>
    <!--FREELANCER EDIT END-->

     <!--CRYPTOCURRENCY EDIT START-->
    <div class="single_accordio_wrap">
      <app-accordion
        [preIndex]="isNested ? 5 : 4"
        [title]="'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.TITLE'"
        [subTitle]="'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.DESCRIPTION_B2B'"
        [noBg]="true"
        [showActiveNr]="true"
        [currentId]="'cryptocurrency'"
        [allActiveNr]="selectButtonsCryptocurrency.length"
        [currActiveNr]="cryptoCurrencyIncomeCount()"
        [statement]="currentStatement"
      >
        <app-select-buttons [buttons]="selectButtonsCryptocurrency" [extraContainerClasses]="'p-0'"></app-select-buttons>
      </app-accordion>
    </div>
    <!--CRYPTOCURRENCY EDIT END-->

  </div>
</div>
