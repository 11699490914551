<div class="pagination-container" *ngIf="_pageCount > 1">
    <button class="btn btn--info"
            (click)="jumpToPage(currentPage - 1)"
            [disabled]="currentPage === 1">
        {{ 'COMMON.PREVIOUS_PAGE' | translate }}
    </button>

    <mat-select [(ngModel)]="currentPage" (ngModelChange)="jumpToPage($event)">
        <mat-option *ngFor="let page of pages" [value]="page">
            <span translate [translateParams]="{page: page, pageCount: _pageCount}">COMMON.PAGE</span>
        </mat-option>
    </mat-select>

    <button class="btn btn--info"
            (click)="jumpToPage(currentPage + 1)"
            [disabled]="currentPage >= _pageCount">
        {{ 'COMMON.NEXT_PAGE' | translate }}
    </button>
</div>
