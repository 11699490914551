<div class="dialog-box">
    <div class="dialog-box__content">
        <div class="license-agreement" *ngIf="language === LANGUAGES.en">
            <h1 class="license-agreement__title license-agreement__title--main is-bold">Licencja klienta biznesowego</h1>
            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">I. DEFINICJE</h2>
                <div class="license-agreement__content">
                    Pojęcia użyte w niniejszym Regulaminie posiadają następujące znaczenie:
                    <ol class="license-agreement__ordered-list license-agreement__ordered-list--alpha">
                        <li>Aplikacja - oznacza oferowany w Serwisie przez Taxando GmbH program komputerowy Taxando.de, umożliwiający przygotowanie i wysłanie drogą elektroniczną zeznania podatkowego dotyczącego podatku za pracę zarobkową na terenie Niemiec. Część Aplikacji stanowi także panel użytkownika pozwalający m.in. na: dokonanie zamówienia na Pakiet Zeznań Podatkowych, przygotowanie zeznania, kontakt z Licencjodawcą oraz aktualizacje danych kontaktowych użytkownika. Program komputerowy udostępniany jest Licencjobiorcy do korzystania w wersji Online, z którego Licencjobiorca korzystać może na zasadach sformułowanych w Umowie Licencyjnej (Licencji). Aplikacja stanowi utwór w rozumieniu ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (t. j. Dz. U.  2017 poz. 880, z późn. zm. — dalej: Prawo autorskie);</li>
                        <li>Dokumentacja - oznacza dokumentację techniczną Aplikacji będącą w posiadaniu Taxando GmbH udostępnianą zgodnie z zasadami opisanymi w niniejszej Licencji;</li>
                        <li>Licencja - oznacza niniejszą umowę licencyjną, na podstawie której Taxando GmbH zezwala Licencjobiorcy na korzystanie z Aplikacji (zwaną także dalej Umową Licencji);</li>
                        <li>Licencjobiorca - oznacza podmiot korzystający z Aplikacji na warunkach wskazanych w Licencji;</li>
                        <li>Licencjodawca — Taxando Gmbh;</li>
                        <li>Taxando Gmbh- oznacza Taxando GmbH z siedzibą w Kurfürstendamm 226, 10719 Berlin, która udziela licencji na Aplikację.</li>
                        <li>Serwis - oznacza serwis internetowy, do którego wszelkie prawa, w tym prawa do treści w nim publikowanych, przysługują Taxando GmbH lub partnerom handlowym, w ramach którego Licencjobiorcom udostępniana jest Aplikacja w wersji online;</li>
                        <li>Regulamin - Regulamin świadczenia usług Taxando.de dostępny na stronie www.taxando.de;</li>
                        <li>Wersja Online - oznacza Aplikację udostępnianą Licencjobiorcy do korzystania za pomocą przeglądarki internetowej, bez konieczności pobierania jej na dysk twardy urządzenia Licencjobiorcy oraz instalowania na tym urządzeniu;</li>
                        <li>Formularz - Formularz wypełniany przez Licencjobiorcę za pośrednictwem Aplikacji w celu przygotowania zeznania podatkowego dotyczącego podatku za pracę zarobkową na terenie Niemiec (Einkommensteuererklärung);</li>
                        <li>Pakiet Zeznania Podatkowego - Jednorazowy dostęp do aplikacji umożliwiający złożenie jednego zeznania podatkowego (wniosku) dotyczącego podatku za pracę zarobkową na terenie Niemiec (Einkommensteuererklärung).</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">II. POSTANOWIENIA OGÓLNE</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Licencjodawca oświadcza, że korzystanie z Aplikacji na podstawie niniejszej umowy nie narusza żadnych praw własności intelektualnej osób trzecich, a zwłaszcza nie wymaga uzyskiwania jakiegokolwiek zezwolenia od osoby trzeciej. Licencjodawca oświadcza, że przysługują mu autorskie prawa majątkowe do Aplikacji w pełnym zakresie, bez jakichkolwiek ograniczeń na rzecz osób trzecich.</li>
                        <li>Licencjodawca udziela Licencjobiorcy zezwolenia na korzystanie z Aplikacji na warunkach wskazanych w Regulaminie oraz niniejszej Licencji. Licencja jest ograniczona, niewyłączna i nieprzenoszalna oraz zostaje udzielona na czas używania Aplikacji online na podstawie wykupionych Pakietów Zeznań Podatkowych. Licencja udzielona jest bez ograniczeń terytorialnych.</li>
                        <li>Aplikacja udostępniania jest przez Licencjodawcę w wersji Online.</li>
                        <li>Korzystanie z Aplikacji jest odpłatne. Warunki odpłatności zostały określone w Regulaminie świadczenia usług Taxando.de. Udostępnianie Aplikacji może zostać przerwane w razie jakichkolwiek zaległości w płatności za korzystanie z niej.</li>
                        <li>Do zawarcia Umowy Licencji dochodzi poprzez zaakceptowanie przez Licencjobiorcę warunków wskazanych w niniejszej Licencji oraz akceptacji Regulaminu, co stanowi warunek rozpoczęcia korzystania z Aplikacji i następuje przed pierwszym uruchomieniem Aplikacji udostępnionej Licencjobiorcy do korzystania w Serwisie.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">III. ZASADY KORZYSTANIA Z APLIKACJI</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Podczas pierwszego uruchomienia Aplikacji konieczne jest zawarcie Umowy Licencyjnej. Wyrażenie zgody na zawarcie Umowy Licencyjnej jest dobrowolne; jej niewyrażenie będzie skutkowało brakiem możliwości korzystania z Aplikacji.</li>
                        <li>Umowa Licencyjna wchodzi w życie z chwilą jej zaakceptowania przez Licencjobiorcę na ekranie Aplikacji, przed jej pierwszym uruchomieniem.</li>
                        <li>Licencjodawca udziela Licencjobiorcy licencji na korzystanie z Aplikacji na następujących polach eksploatacji:<br>
                        a) stosowanie Aplikacji zgodnie z jej przeznaczeniem i w tym celu uruchomienie i wyświetlanie Aplikacji w przeglądarce internetowej;<br>
                        b) używanie Aplikacji zgodnie z jej przeznaczeniem w celu złożenia zeznania podatkowego</li>
                        <li>Na podstawie jednostkowej Umowy Licencji, Licencjobiorca uzyskuje prawo do korzystania, w zakresie wskazanym w Umowie Licencji, z Aplikacji na nieograniczonej liczbie urządzeń, a pierwsze zainicjowanie korzystania z Aplikacji udostępnianej w wersji Online – wymaga zawarcia Umowy Licencji poprzez akceptację niniejszych postanowień.</li>
                        <li>Licencjobiorca akceptując warunki licencyjne nie nabywa w żaden sposób licencji na korzystanie z Aplikacji innej niż Taxando.de.</li>
                        <li>Aplikacja w celu należytego działania wymaga podania następujących danych osobowych: imienia, nazwiska, informacji dotyczących zameldowania, informacji dotyczących kraju ubezpieczenia społecznego, informacji dotyczących stanu cywilnego (w przypadku gdy klient pozostaje w związku małżeńskim: także imię, nazwisko, numer podatkowy, data urodzenia, informacje dotyczące dochodów współmałżonka), informacji dotyczących dochodów i odprowadzanych zaliczek na podatek, informacji dotyczących okresu pracy.</li>
                        <li>Licencjobiorca na podstawie niniejszej umowy powierza Licencjodawcy przetwarzanie danych osobowych swoich klientów w stosunku, do których wykorzystuje Aplikację poprzez nadanie zeznania podatkowego. Powierzone dane osobowe obejmują w szczególności: imię, nazwisko, informacje dotyczące zameldowania, informacje dotyczące kraju ubezpieczenia społecznego, informacje dotyczące stanu cywilnego (w przypadku gdy klient pozostaje w związku małżeńskim także imię, nazwisko, numer podatkowy, datę urodzenia i informacje dotyczące dochodów współmałżonka), informacje dotyczące dochodów i odprowadzanych zaliczek na podatek, informacje dotyczące okresu pracy  a także skan zaświadczenia EU-EWR.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">IV. PRZYGOTOWYWANIE FORMULARZY I ZEZNAŃ PODATKOWYCH</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Licencjobiorca może korzystać z Aplikacji na potrzeby składania zeznań podatkowych w imieniu własnym oraz w imieniu osób trzecich, korzystających z jego usług.</li>
                        <li>Licencjobiorca jest zobowiązany zapewnić należytą identyfikację osoby trzeciej, która korzysta z jego usług, a w szczególności zapewnić możliwość przypisania danego zeznania podatkowego konkretnej, jednoznacznie zidentyfikowanej osobie. W tym celu Licencjobiorca jest zobowiązany podjąć wszelkie dopuszczalne prawem środki do ustalenia tożsamości osoby trzeciej, która powierza mu przygotowanie i złożenie zeznania podatkowego.</li>
                        <li>Licencjobiorca może wypełniać Formularze i składać zeznania podatkowe za pośrednictwem Aplikacji tylko wówczas, gdy dysponuje pełnomocnictwem udzielonym przez osobę trzecią, korzystającą z jego usług. Udzielone pełnomocnictwo powinno obejmować co najmniej:<br>
                        a) upoważnienie do wypełnienia Formularza i przygotowania zeznania podatkowego;<br>
                        b) upoważnienie do weryfikacji danych w przygotowanym zeznaniu podatkowym;<br>
                        c) upoważnienie do złożenia zeznania podatkowego w imieniu osoby trzeciej do właściwego organu podatkowego za pośrednictwem Internetu (poprzez wysłanie), tj. upoważnienie do korzystania przez Licencjobiorcę z Aplikacji.</li>
                        <li>W przypadku, gdy osoba trzecia nie udzieliła Licencjobiorcy pełnomocnictwa do weryfikacji danych w przygotowanym zeznaniu podatkowym, Licencjobiorca jest zobowiązany, przed wysłaniem do właściwego organu podatkowego, przedstawić osobie trzeciej wypełnione zaznanie podatkowe do akceptacji.</li>
                        <li>Licencjobiorca  nie jest zobowiązany:<br>
                        a) posiadać i przechowywać dokumenty umożliwiające identyfikację osoby trzeciej, korzystającej z jego usług, jak również przypisanie danego zeznania podatkowego konkretnej, jednoznacznie zidentyfikowanej osobie;<br>
                        b) posiadać i przechowywać udzielone pełnomocnictwa;<br>
                        c) w sytuacji wskazanej w ust. 4 — posiadać i przechowywać dowody, że osoba trzecia, korzystająca z jego usług, sprawdziła i zaakceptowała treść przygotowanego zeznania podatkowego.</li>
                        <li>Licencjobiorca nie jest zobowiązany przechowywać dokumenty, pełnomocnictwa i dowody, o których mowa w ust. 5, przez co najmniej 5 lat, licząc od ostatniego dnia roku, w którym zeznanie podatkowe zostało złożone.</li>
                        <li>Licencjobiorca odpowiada za poprawność i kompletność wprowadzanych danych do Aplikacji, w tym w szczególności za poprawne zadeklarowanie okresu pracy i określenia wysokości przychodów w Polsce i w Niemczech. Licencjobiorca odpowiada za wprowadzenie danych zgodnych ze stanem faktycznym oraz obowiązującym stanem prawa polskiego, niemieckiego i prawa Unii Europejskiej.</li>
                        <li>Zatwierdzając ostateczny kształt zeznania podatkowego, Licencjobiorca oświadcza, że posiada pełnomocnictwo do dokonywania czynności związanych z przygotowaniem i złożeniem zeznania podatkowego, jak również oświadcza, że dane wskazane w zeznaniu podatkowym są poprawne.</li>
                        <li>Po wypełnieniu Formularza i sprawdzeniu treści przez Licencjobiorcę lub osobę trzecią zostaje dokonane zatwierdzenie zeznania i wysłanie do  walidacji, a następnie do właściwego organu podatkowego. Po otrzymaniu zwrotnego komunikatu z urzędu Finanzamt wraz z elektronicznym potwierdzeniem odbioru, status Aplikacji podaje informację, iż zeznanie podatkowe zostało wysłane.</li>
                        <li>Po złożeniu zeznania podatkowego Licencjobiorca jest zobowiązany wydrukować zeznanie podatkowe w formie papierowej i przekazać je osobie trzeciej, w imieniu której zeznanie zostało złożone. Licencjodawca nie jest zobowiązany przechowywać złożonych zeznań podatkowych w formie elektronicznej.</li>
                        <li>Licencjodawca nie odpowiada za błędy w odczycie technologią OCR (automatyczne odczytywanie danych z karty podatkowej). Każdorazowo odczyt powinien być zweryfikowany przez osobę wprowadzającą dane do zeznania podatkowego.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">V. ODPOWIEDZIALNOŚĆ I WSPARCIE TECHNICZNE</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Licencjobiorca jest uprawniony do korzystania z Aplikacji lub Serwisu w sposób zgodny z prawem i Licencją.</li>
                        <li>W ramach licencji Licencjobiorca ma prawo do korzystania ze wsparcia technicznego Licencjodawcy dotyczącego działania Aplikacji (nie obejmującego wsparcia merytorycznego, które mogłoby stanowić doradztwo podatkowe, prawne, finansowe bądź inne wymagające informacji specjalnych), w zakresie użytkowania Aplikacji, świadczonego wyłącznie za pośrednictwem e-mail oraz czat dostępnych pod adresem: www.taxando.de</li>
                        <li>Licencjobiorca nie ma prawa dostarczania do Aplikacji informacji o charakterze bezprawnym oraz niezgodnym z dobrymi obyczajami, a także informacji niezgodnych z prawdą.</li>
                        <li>Licencjodawca  zastrzega sobie prawo do dokonywania wszelkich zmian i aktualizacji Aplikacji.</li>
                        <li>Licencjobiorca przyjmuje do wiadomości, że Aplikacja stanowi narzędzie do wypełniania Formularzy oraz wykonywania innych czynności związanych z Formularzami, a wszelkie decyzje dotyczące sposobu wypełnienia Formularza, zastosowania określonego Formularza czy też zakresu przedstawianych w Formularzu informacji przez Licencjobiorcę, podejmowane są przez Licencjobiorcę samodzielnie, a żaden element Aplikacji nie może być traktowany jako sugestia dotycząca powyższych czynności. Licencjodawca nie ma wpływu na decyzje podejmowane przez Licencjobiorcę. Wszelkie treści lub informacje przedstawiane przez Licencjodawcę w Aplikacji mają charakter wyłącznie informacyjny i nie stanowią porady prawnej, technicznej czy doradztwa podatkowego, księgowego, finansowego czy jakiegokolwiek innego doradztwa.</li>
                        <li>Licencjodawca nie ponosi odpowiedzialności za decyzje organów administracji państwowej, urzędów publicznych oraz innych podmiotów, będących adresatami dokumentów powstałych z wykorzystaniem Formularzy w Aplikacji. Odpowiedzialności tej w szczególności nie wprowadza kwota prognozowanego zwrotu prezentowana w Aplikacji. Prezentowana kwota stanowi jedynie opinię Licencjodawcy i w żadnym zakresie nie może być traktowana jako gwarancja uzyskania decyzji podatkowej uwzględniającej przewidywany w Aplikacji zwrot. Ponadto Licencjobiorca godzi się z faktem, iż pomimo wysłanego za pośrednictwem Aplikacji zeznania podatkowego organ administracji, będący adresatem przesłanego zeznania, może wezwać do uzupełnienia bądź przesłania zeznania ponownie.</li>
                        <li>Licencjodawca nie udziela Licencjobiorcy gwarancji, że wypełniony przez niego Formularz będzie mógł być wykorzystany w sposób należyty i przez niego oczekiwany i nie jest odpowiedzialny wobec Licencjobiorcy lub osoby trzeciej za jakąkolwiek szkodę powstałą w związku z nienależytym używaniem Aplikacji. W szczególności Licencjodawca nie ponosi odpowiedzialności za dane przekazane do Aplikacji przez Licencjobiorcę.</li>
                        <li>Licencjodawca nie jest odpowiedzialny wobec osób trzecich, w imieniu których Użytkownik skorzystał z Aplikacji, z wykorzystaniem ich danych osobowych przy wysyłaniu zeznania podatkowego.</li>
                        <li>Licencjodawca deklaruje, że dokłada wszelkich starań, aby informacje prezentowane w Aplikacji były aktualne i rzetelne. Pomimo to Licencjobiorca przyjmuje do wiadomości, iż wszelkie ciążące na nim zobowiązania podatkowe czy prawne leżą w zakresie jego wyłącznej odpowiedzialności, a Licencjobiorca jest odpowiedzialny za terminowe i prawidłowe dokonywanie czynności podatkowych bądź innych czynności prawnych, do wykonania których zobowiązany jest przez obowiązujące przepisy prawa. Powyższe odnosi się także do zobowiązań podatkowych ciążących na osobach trzecich, w szczególności tych, w imieniu których Licencjobiorca używa Aplikacji.</li>
                        <li>Licencjobiorca przyjmuje do wiadomości, że Aplikacja jest programem komputerowym, na funkcjonowanie którego wpływ mogą mieć różne czynniki pozostające poza programem komputerowym, w tym współdziałanie z przeglądarkami www i innymi urządzeniami, w tym siecią Internet. Licencjobiorca w związku z powyższym dopuszcza, iż mogą zachodzić okoliczności wpływające na działanie Aplikacji, pozostające poza zakresem odpowiedzialności Licencjodawcy, a wynikające z wyżej wymienionych okoliczności, co do których nie jest możliwe wyczerpujące ich zbadanie oraz zapobieganie ewentualnym nieprawidłowościom funkcjonowania Aplikacji ze względu na nie.</li>
                        <li>Odpowiedzialność odszkodowawcza Licencjodawcy w zakresie, w jakim nie uległa wyłączeniu na podstawie postanowień niniejszej Licencji bądź Regulaminu, jest ograniczona do odpowiedzialności wyłącznie za rzeczywiście poniesione przez Licencjobiorcę straty, a ponadto do maksymalnej wysokości odszkodowania równego dwukrotności wniesionej przez Licencjobiorcę opłaty za Pakiet Zeznania Podatkowego zgodnie z warunkami Regulaminu.</li>
                        <li>Licencjodawca oświadcza, że proces  nadawania zeznań podatkowych do Finanzamt z wykorzystaniem certyfikatu firmy Licencjodawcy jest zgodny z przepisami obowiązującymi na terenie Niemiec (Abgabenordnung).</li>
                        <li>Licencjobiorca ponosi wobec Licencjodawcy odpowiedzialność za szkodę poniesioną przez Licencjodawcę na skutek niedopełnienia przez Licencjobiorcę obowiązków, o których mowa w punkcie IV, a w szczególności za naruszenie postanowień punkt IV ust. 5.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">VI. DZIAŁANIA NIEDOZWOLONE</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>O ile nie doszło do udzielenia zgody pisemnej, Licencjobiorca nie jest upoważniony do rozpowszechniania, (w szczególności użyczenia lub najmu, wydzierżawiania lub udostępniania w nieodpłatne korzystanie) dostępu online do Aplikacji.</li>
                        <li>Licencjobiorca nie jest uprawniony do trwałego lub czasowego zwielokrotniania Aplikacji w całości lub w części, a także próby odtworzenia kodu źródłowego Aplikacji.</li>
                        <li>W przypadku podjęcia przez Licencjobiorcę próby dekompilacji kodu Aplikacji lub próby odtworzenia jej kodu źródłowego, Umowa Licencyjna ulega natychmiastowemu rozwiązaniu.</li>
                        <li>Licencjodawca może kontrolować sposób korzystania z Aplikacji przez Licencjobiorcę pod kątem jego zgodności z postanowieniami niniejszej Umowy Licencji oraz przeznaczeniem Aplikacji.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">VII. GROMADZENIE i ZAPIS DANYCH</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Licencjodawca oświadcza, że dane gromadzone przy użyciu Aplikacji są przechowywane na serwerze firmy AWS Amazon. Dla zabezpieczenia wprowadzonych danych, Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane osobowe użytkowników przed udostępnieniem ich osobom nieupoważnionym, utratą czy uszkodzeniem.</li>
                        <li>W zakresie ,w jakim przetwarzanie przez Licencjodawcę przekazanych danych osobowych Licencjobiorcy miałoby wykraczać poza dozwolony sposób przetwarzania danych na potrzeby realizacji usług bądź płatności, Licencjodawca uzyska odrębną zgodę Licencjobiorcy na takie przetwarzanie jego danych osobowych.</li>
                        <li>Licencjodawca zastrzega sobie prawo do gromadzenia danych statystycznych z baz danych Aplikacji uzyskiwanych w oparciu o komunikację internetową, przy czym Licencjobiorca może nie wyrazić takiej zgody, a w takim przypadku dane, o których mowa wcześniej, nie są gromadzone. Dane te mogą być gromadzone przez Licencjodawcę w wyłącznym celu prowadzenia badań jakości i funkcjonalności Aplikacji oraz przygotowywania raportów o charakterze wyłącznie statystycznym. Dane, o których mowa powyżej, nie umożliwiają identyfikacji Licencjobiorcy, ani podmiotu, w związku z działalnością którego dochodzi do korzystania z Aplikacji. Tak powstałe raporty będą mogły być dowolnie rozpowszechniane przez Licencjodawcę i inne podmioty.</li>
                        <li>Licencjodawca ogranicza odpowiedzialność w przypadku, gdy dane zostaną utracone lub uszkodzone w wyniku cyberataku, pod warunkiem, że dochowa  swoich zobowiazań w zakresie odpowiedniego zabezpieczenia bazy danych.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">VIII. POSTANOWIENIA KOŃCOWE</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Aplikacja jest licencjonowana jako jedna całość, a jej części składowe nie mogą być rozdzielane.</li>
                        <li>W przypadku stwierdzenia naruszenia warunków Licencji Licencjodawca może niezwłocznie rozwiązać Umowę Licencyjną.</li>
                        <li>W sprawach nieuregulowanych niniejszą Umową Licencyjną mają zastosowanie odpowiednie przepisy prawa polskiego, w tym w szczególności, lecz nie wyłącznie, przepisy kodeksu cywilnego oraz ustawy o prawie autorskim i prawach pokrewnych.</li>
                        <li>Dla sporów mogących powstać na tle niniejszej licencji właściwy jest sąd siedziby Licencjobiorcy.</li>
                        <li>Zmiany w niniejszej Umowie Licencyjnej wymagają formy dokumentowej pod rygorem nieważności.</li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="license-agreement" *ngIf="language === LANGUAGES.pl">
            <h1 class="license-agreement__title license-agreement__title--main is-bold">Licencja klienta biznesowego</h1>
            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">I. DEFINICJE</h2>
                <div class="license-agreement__content">
                    Pojęcia użyte w niniejszym Regulaminie posiadają następujące znaczenie:
                    <ol class="license-agreement__ordered-list license-agreement__ordered-list--alpha">
                        <li>Aplikacja - oznacza oferowany w Serwisie przez Taxando GmbH program komputerowy Taxando.de, umożliwiający przygotowanie i wysłanie drogą elektroniczną zeznania podatkowego dotyczącego podatku za pracę zarobkową na terenie Niemiec. Część Aplikacji stanowi także panel użytkownika pozwalający m.in. na: dokonanie zamówienia na Pakiet Zeznań Podatkowych, przygotowanie zeznania, kontakt z Licencjodawcą oraz aktualizacje danych kontaktowych użytkownika. Program komputerowy udostępniany jest Licencjobiorcy do korzystania w wersji Online, z którego Licencjobiorca korzystać może na zasadach sformułowanych w Umowie Licencyjnej (Licencji). Aplikacja stanowi utwór w rozumieniu ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (t. j. Dz. U.  2017 poz. 880, z późn. zm. — dalej: Prawo autorskie);</li>
                        <li>Dokumentacja - oznacza dokumentację techniczną Aplikacji będącą w posiadaniu Taxando GmbH udostępnianą zgodnie z zasadami opisanymi w niniejszej Licencji;</li>
                        <li>Licencja - oznacza niniejszą umowę licencyjną, na podstawie której Taxando GmbH zezwala Licencjobiorcy na korzystanie z Aplikacji (zwaną także dalej Umową Licencji);</li>
                        <li>Licencjobiorca - oznacza podmiot korzystający z Aplikacji na warunkach wskazanych w Licencji;</li>
                        <li>Licencjodawca — Taxando Gmbh;</li>
                        <li>Taxando Gmbh- oznacza Taxando GmbH z siedzibą w Kurfürstendamm 226, 10719 Berlin, która udziela licencji na Aplikację.</li>
                        <li>Serwis - oznacza serwis internetowy, do którego wszelkie prawa, w tym prawa do treści w nim publikowanych, przysługują Taxando GmbH lub partnerom handlowym, w ramach którego Licencjobiorcom udostępniana jest Aplikacja w wersji online;</li>
                        <li>Regulamin - Regulamin świadczenia usług Taxando.de dostępny na stronie www.taxando.de;</li>
                        <li>Wersja Online - oznacza Aplikację udostępnianą Licencjobiorcy do korzystania za pomocą przeglądarki internetowej, bez konieczności pobierania jej na dysk twardy urządzenia Licencjobiorcy oraz instalowania na tym urządzeniu;</li>
                        <li>Formularz - Formularz wypełniany przez Licencjobiorcę za pośrednictwem Aplikacji w celu przygotowania zeznania podatkowego dotyczącego podatku za pracę zarobkową na terenie Niemiec (Einkommensteuererklärung);</li>
                        <li>Pakiet Zeznania Podatkowego - Jednorazowy dostęp do aplikacji umożliwiający złożenie jednego zeznania podatkowego (wniosku) dotyczącego podatku za pracę zarobkową na terenie Niemiec (Einkommensteuererklärung).</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">II. POSTANOWIENIA OGÓLNE</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Licencjodawca oświadcza, że korzystanie z Aplikacji na podstawie niniejszej umowy nie narusza żadnych praw własności intelektualnej osób trzecich, a zwłaszcza nie wymaga uzyskiwania jakiegokolwiek zezwolenia od osoby trzeciej. Licencjodawca oświadcza, że przysługują mu autorskie prawa majątkowe do Aplikacji w pełnym zakresie, bez jakichkolwiek ograniczeń na rzecz osób trzecich.</li>
                        <li>Licencjodawca udziela Licencjobiorcy zezwolenia na korzystanie z Aplikacji na warunkach wskazanych w Regulaminie oraz niniejszej Licencji. Licencja jest ograniczona, niewyłączna i nieprzenoszalna oraz zostaje udzielona na czas używania Aplikacji online na podstawie wykupionych Pakietów Zeznań Podatkowych. Licencja udzielona jest bez ograniczeń terytorialnych.</li>
                        <li>Aplikacja udostępniania jest przez Licencjodawcę w wersji Online.</li>
                        <li>Korzystanie z Aplikacji jest odpłatne. Warunki odpłatności zostały określone w Regulaminie świadczenia usług Taxando.de. Udostępnianie Aplikacji może zostać przerwane w razie jakichkolwiek zaległości w płatności za korzystanie z niej.</li>
                        <li>Do zawarcia Umowy Licencji dochodzi poprzez zaakceptowanie przez Licencjobiorcę warunków wskazanych w niniejszej Licencji oraz akceptacji Regulaminu, co stanowi warunek rozpoczęcia korzystania z Aplikacji i następuje przed pierwszym uruchomieniem Aplikacji udostępnionej Licencjobiorcy do korzystania w Serwisie.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">III. ZASADY KORZYSTANIA Z APLIKACJI</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Podczas pierwszego uruchomienia Aplikacji konieczne jest zawarcie Umowy Licencyjnej. Wyrażenie zgody na zawarcie Umowy Licencyjnej jest dobrowolne; jej niewyrażenie będzie skutkowało brakiem możliwości korzystania z Aplikacji.</li>
                        <li>Umowa Licencyjna wchodzi w życie z chwilą jej zaakceptowania przez Licencjobiorcę na ekranie Aplikacji, przed jej pierwszym uruchomieniem.</li>
                        <li>Licencjodawca udziela Licencjobiorcy licencji na korzystanie z Aplikacji na następujących polach eksploatacji:<br>
                        a) stosowanie Aplikacji zgodnie z jej przeznaczeniem i w tym celu uruchomienie i wyświetlanie Aplikacji w przeglądarce internetowej;<br>
                        b) używanie Aplikacji zgodnie z jej przeznaczeniem w celu złożenia zeznania podatkowego</li>
                        <li>Na podstawie jednostkowej Umowy Licencji, Licencjobiorca uzyskuje prawo do korzystania, w zakresie wskazanym w Umowie Licencji, z Aplikacji na nieograniczonej liczbie urządzeń, a pierwsze zainicjowanie korzystania z Aplikacji udostępnianej w wersji Online – wymaga zawarcia Umowy Licencji poprzez akceptację niniejszych postanowień.</li>
                        <li>Licencjobiorca akceptując warunki licencyjne nie nabywa w żaden sposób licencji na korzystanie z Aplikacji innej niż Taxando.de.</li>
                        <li>Aplikacja w celu należytego działania wymaga podania następujących danych osobowych: imienia, nazwiska, informacji dotyczących zameldowania, informacji dotyczących kraju ubezpieczenia społecznego, informacji dotyczących stanu cywilnego (w przypadku gdy klient pozostaje w związku małżeńskim: także imię, nazwisko, numer podatkowy, data urodzenia, informacje dotyczące dochodów współmałżonka), informacji dotyczących dochodów i odprowadzanych zaliczek na podatek, informacji dotyczących okresu pracy.</li>
                        <li>Licencjobiorca na podstawie niniejszej umowy powierza Licencjodawcy przetwarzanie danych osobowych swoich klientów w stosunku, do których wykorzystuje Aplikację poprzez nadanie zeznania podatkowego. Powierzone dane osobowe obejmują w szczególności: imię, nazwisko, informacje dotyczące zameldowania, informacje dotyczące kraju ubezpieczenia społecznego, informacje dotyczące stanu cywilnego (w przypadku gdy klient pozostaje w związku małżeńskim także imię, nazwisko, numer podatkowy, datę urodzenia i informacje dotyczące dochodów współmałżonka), informacje dotyczące dochodów i odprowadzanych zaliczek na podatek, informacje dotyczące okresu pracy  a także skan zaświadczenia EU-EWR.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">IV. PRZYGOTOWYWANIE FORMULARZY I ZEZNAŃ PODATKOWYCH</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Licencjobiorca może korzystać z Aplikacji na potrzeby składania zeznań podatkowych w imieniu własnym oraz w imieniu osób trzecich, korzystających z jego usług.</li>
                        <li>Licencjobiorca jest zobowiązany zapewnić należytą identyfikację osoby trzeciej, która korzysta z jego usług, a w szczególności zapewnić możliwość przypisania danego zeznania podatkowego konkretnej, jednoznacznie zidentyfikowanej osobie. W tym celu Licencjobiorca jest zobowiązany podjąć wszelkie dopuszczalne prawem środki do ustalenia tożsamości osoby trzeciej, która powierza mu przygotowanie i złożenie zeznania podatkowego.</li>
                        <li>Licencjobiorca może wypełniać Formularze i składać zeznania podatkowe za pośrednictwem Aplikacji tylko wówczas, gdy dysponuje pełnomocnictwem udzielonym przez osobę trzecią, korzystającą z jego usług. Udzielone pełnomocnictwo powinno obejmować co najmniej:<br>
                        a) upoważnienie do wypełnienia Formularza i przygotowania zeznania podatkowego;<br>
                        b) upoważnienie do weryfikacji danych w przygotowanym zeznaniu podatkowym;<br>
                        c) upoważnienie do złożenia zeznania podatkowego w imieniu osoby trzeciej do właściwego organu podatkowego za pośrednictwem Internetu (poprzez wysłanie), tj. upoważnienie do korzystania przez Licencjobiorcę z Aplikacji.</li>
                        <li>W przypadku, gdy osoba trzecia nie udzieliła Licencjobiorcy pełnomocnictwa do weryfikacji danych w przygotowanym zeznaniu podatkowym, Licencjobiorca jest zobowiązany, przed wysłaniem do właściwego organu podatkowego, przedstawić osobie trzeciej wypełnione zaznanie podatkowe do akceptacji.</li>
                        <li>Licencjobiorca  nie jest zobowiązany:<br>
                        a) posiadać i przechowywać dokumenty umożliwiające identyfikację osoby trzeciej, korzystającej z jego usług, jak również przypisanie danego zeznania podatkowego konkretnej, jednoznacznie zidentyfikowanej osobie;<br>
                        b) posiadać i przechowywać udzielone pełnomocnictwa;<br>
                        c) w sytuacji wskazanej w ust. 4 — posiadać i przechowywać dowody, że osoba trzecia, korzystająca z jego usług, sprawdziła i zaakceptowała treść przygotowanego zeznania podatkowego.</li>
                        <li>Licencjobiorca nie jest zobowiązany przechowywać dokumenty, pełnomocnictwa i dowody, o których mowa w ust. 5, przez co najmniej 5 lat, licząc od ostatniego dnia roku, w którym zeznanie podatkowe zostało złożone.</li>
                        <li>Licencjobiorca odpowiada za poprawność i kompletność wprowadzanych danych do Aplikacji, w tym w szczególności za poprawne zadeklarowanie okresu pracy i określenia wysokości przychodów w Polsce i w Niemczech. Licencjobiorca odpowiada za wprowadzenie danych zgodnych ze stanem faktycznym oraz obowiązującym stanem prawa polskiego, niemieckiego i prawa Unii Europejskiej.</li>
                        <li>Zatwierdzając ostateczny kształt zeznania podatkowego, Licencjobiorca oświadcza, że posiada pełnomocnictwo do dokonywania czynności związanych z przygotowaniem i złożeniem zeznania podatkowego, jak również oświadcza, że dane wskazane w zeznaniu podatkowym są poprawne.</li>
                        <li>Po wypełnieniu Formularza i sprawdzeniu treści przez Licencjobiorcę lub osobę trzecią zostaje dokonane zatwierdzenie zeznania i wysłanie do  walidacji, a następnie do właściwego organu podatkowego. Po otrzymaniu zwrotnego komunikatu z urzędu Finanzamt wraz z elektronicznym potwierdzeniem odbioru, status Aplikacji podaje informację, iż zeznanie podatkowe zostało wysłane.</li>
                        <li>Po złożeniu zeznania podatkowego Licencjobiorca jest zobowiązany wydrukować zeznanie podatkowe w formie papierowej i przekazać je osobie trzeciej, w imieniu której zeznanie zostało złożone. Licencjodawca nie jest zobowiązany przechowywać złożonych zeznań podatkowych w formie elektronicznej.</li>
                        <li>Licencjodawca nie odpowiada za błędy w odczycie technologią OCR (automatyczne odczytywanie danych z karty podatkowej). Każdorazowo odczyt powinien być zweryfikowany przez osobę wprowadzającą dane do zeznania podatkowego.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">V. ODPOWIEDZIALNOŚĆ I WSPARCIE TECHNICZNE</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Licencjobiorca jest uprawniony do korzystania z Aplikacji lub Serwisu w sposób zgodny z prawem i Licencją.</li>
                        <li>W ramach licencji Licencjobiorca ma prawo do korzystania ze wsparcia technicznego Licencjodawcy dotyczącego działania Aplikacji (nie obejmującego wsparcia merytorycznego, które mogłoby stanowić doradztwo podatkowe, prawne, finansowe bądź inne wymagające informacji specjalnych), w zakresie użytkowania Aplikacji, świadczonego wyłącznie za pośrednictwem e-mail oraz czat dostępnych pod adresem: www.taxando.de</li>
                        <li>Licencjobiorca nie ma prawa dostarczania do Aplikacji informacji o charakterze bezprawnym oraz niezgodnym z dobrymi obyczajami, a także informacji niezgodnych z prawdą.</li>
                        <li>Licencjodawca  zastrzega sobie prawo do dokonywania wszelkich zmian i aktualizacji Aplikacji.</li>
                        <li>Licencjobiorca przyjmuje do wiadomości, że Aplikacja stanowi narzędzie do wypełniania Formularzy oraz wykonywania innych czynności związanych z Formularzami, a wszelkie decyzje dotyczące sposobu wypełnienia Formularza, zastosowania określonego Formularza czy też zakresu przedstawianych w Formularzu informacji przez Licencjobiorcę, podejmowane są przez Licencjobiorcę samodzielnie, a żaden element Aplikacji nie może być traktowany jako sugestia dotycząca powyższych czynności. Licencjodawca nie ma wpływu na decyzje podejmowane przez Licencjobiorcę. Wszelkie treści lub informacje przedstawiane przez Licencjodawcę w Aplikacji mają charakter wyłącznie informacyjny i nie stanowią porady prawnej, technicznej czy doradztwa podatkowego, księgowego, finansowego czy jakiegokolwiek innego doradztwa.</li>
                        <li>Licencjodawca nie ponosi odpowiedzialności za decyzje organów administracji państwowej, urzędów publicznych oraz innych podmiotów, będących adresatami dokumentów powstałych z wykorzystaniem Formularzy w Aplikacji. Odpowiedzialności tej w szczególności nie wprowadza kwota prognozowanego zwrotu prezentowana w Aplikacji. Prezentowana kwota stanowi jedynie opinię Licencjodawcy i w żadnym zakresie nie może być traktowana jako gwarancja uzyskania decyzji podatkowej uwzględniającej przewidywany w Aplikacji zwrot. Ponadto Licencjobiorca godzi się z faktem, iż pomimo wysłanego za pośrednictwem Aplikacji zeznania podatkowego organ administracji, będący adresatem przesłanego zeznania, może wezwać do uzupełnienia bądź przesłania zeznania ponownie.</li>
                        <li>Licencjodawca nie udziela Licencjobiorcy gwarancji, że wypełniony przez niego Formularz będzie mógł być wykorzystany w sposób należyty i przez niego oczekiwany i nie jest odpowiedzialny wobec Licencjobiorcy lub osoby trzeciej za jakąkolwiek szkodę powstałą w związku z nienależytym używaniem Aplikacji. W szczególności Licencjodawca nie ponosi odpowiedzialności za dane przekazane do Aplikacji przez Licencjobiorcę.</li>
                        <li>Licencjodawca nie jest odpowiedzialny wobec osób trzecich, w imieniu których Użytkownik skorzystał z Aplikacji, z wykorzystaniem ich danych osobowych przy wysyłaniu zeznania podatkowego.</li>
                        <li>Licencjodawca deklaruje, że dokłada wszelkich starań, aby informacje prezentowane w Aplikacji były aktualne i rzetelne. Pomimo to Licencjobiorca przyjmuje do wiadomości, iż wszelkie ciążące na nim zobowiązania podatkowe czy prawne leżą w zakresie jego wyłącznej odpowiedzialności, a Licencjobiorca jest odpowiedzialny za terminowe i prawidłowe dokonywanie czynności podatkowych bądź innych czynności prawnych, do wykonania których zobowiązany jest przez obowiązujące przepisy prawa. Powyższe odnosi się także do zobowiązań podatkowych ciążących na osobach trzecich, w szczególności tych, w imieniu których Licencjobiorca używa Aplikacji.</li>
                        <li>Licencjobiorca przyjmuje do wiadomości, że Aplikacja jest programem komputerowym, na funkcjonowanie którego wpływ mogą mieć różne czynniki pozostające poza programem komputerowym, w tym współdziałanie z przeglądarkami www i innymi urządzeniami, w tym siecią Internet. Licencjobiorca w związku z powyższym dopuszcza, iż mogą zachodzić okoliczności wpływające na działanie Aplikacji, pozostające poza zakresem odpowiedzialności Licencjodawcy, a wynikające z wyżej wymienionych okoliczności, co do których nie jest możliwe wyczerpujące ich zbadanie oraz zapobieganie ewentualnym nieprawidłowościom funkcjonowania Aplikacji ze względu na nie.</li>
                        <li>Odpowiedzialność odszkodowawcza Licencjodawcy w zakresie, w jakim nie uległa wyłączeniu na podstawie postanowień niniejszej Licencji bądź Regulaminu, jest ograniczona do odpowiedzialności wyłącznie za rzeczywiście poniesione przez Licencjobiorcę straty, a ponadto do maksymalnej wysokości odszkodowania równego dwukrotności wniesionej przez Licencjobiorcę opłaty za Pakiet Zeznania Podatkowego zgodnie z warunkami Regulaminu.</li>
                        <li>Licencjodawca oświadcza, że proces  nadawania zeznań podatkowych do Finanzamt z wykorzystaniem certyfikatu firmy Licencjodawcy jest zgodny z przepisami obowiązującymi na terenie Niemiec (Abgabenordnung).</li>
                        <li>Licencjobiorca ponosi wobec Licencjodawcy odpowiedzialność za szkodę poniesioną przez Licencjodawcę na skutek niedopełnienia przez Licencjobiorcę obowiązków, o których mowa w punkcie IV, a w szczególności za naruszenie postanowień punkt IV ust. 5.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">VI. DZIAŁANIA NIEDOZWOLONE</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>O ile nie doszło do udzielenia zgody pisemnej, Licencjobiorca nie jest upoważniony do rozpowszechniania, (w szczególności użyczenia lub najmu, wydzierżawiania lub udostępniania w nieodpłatne korzystanie) dostępu online do Aplikacji.</li>
                        <li>Licencjobiorca nie jest uprawniony do trwałego lub czasowego zwielokrotniania Aplikacji w całości lub w części, a także próby odtworzenia kodu źródłowego Aplikacji.</li>
                        <li>W przypadku podjęcia przez Licencjobiorcę próby dekompilacji kodu Aplikacji lub próby odtworzenia jej kodu źródłowego, Umowa Licencyjna ulega natychmiastowemu rozwiązaniu.</li>
                        <li>Licencjodawca może kontrolować sposób korzystania z Aplikacji przez Licencjobiorcę pod kątem jego zgodności z postanowieniami niniejszej Umowy Licencji oraz przeznaczeniem Aplikacji.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">VII. GROMADZENIE i ZAPIS DANYCH</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Licencjodawca oświadcza, że dane gromadzone przy użyciu Aplikacji są przechowywane na serwerze firmy AWS Amazon. Dla zabezpieczenia wprowadzonych danych, Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane osobowe użytkowników przed udostępnieniem ich osobom nieupoważnionym, utratą czy uszkodzeniem.</li>
                        <li>W zakresie ,w jakim przetwarzanie przez Licencjodawcę przekazanych danych osobowych Licencjobiorcy miałoby wykraczać poza dozwolony sposób przetwarzania danych na potrzeby realizacji usług bądź płatności, Licencjodawca uzyska odrębną zgodę Licencjobiorcy na takie przetwarzanie jego danych osobowych.</li>
                        <li>Licencjodawca zastrzega sobie prawo do gromadzenia danych statystycznych z baz danych Aplikacji uzyskiwanych w oparciu o komunikację internetową, przy czym Licencjobiorca może nie wyrazić takiej zgody, a w takim przypadku dane, o których mowa wcześniej, nie są gromadzone. Dane te mogą być gromadzone przez Licencjodawcę w wyłącznym celu prowadzenia badań jakości i funkcjonalności Aplikacji oraz przygotowywania raportów o charakterze wyłącznie statystycznym. Dane, o których mowa powyżej, nie umożliwiają identyfikacji Licencjobiorcy, ani podmiotu, w związku z działalnością którego dochodzi do korzystania z Aplikacji. Tak powstałe raporty będą mogły być dowolnie rozpowszechniane przez Licencjodawcę i inne podmioty.</li>
                        <li>Licencjodawca ogranicza odpowiedzialność w przypadku, gdy dane zostaną utracone lub uszkodzone w wyniku cyberataku, pod warunkiem, że dochowa  swoich zobowiazań w zakresie odpowiedniego zabezpieczenia bazy danych.</li>
                    </ol>
                </div>
            </div>

            <div class="license-agreement__stage">
                <h2 class="license-agreement__title is-bold">VIII. POSTANOWIENIA KOŃCOWE</h2>
                <div class="license-agreement__content">
                    <ol class="license-agreement__ordered-list">
                        <li>Aplikacja jest licencjonowana jako jedna całość, a jej części składowe nie mogą być rozdzielane.</li>
                        <li>W przypadku stwierdzenia naruszenia warunków Licencji Licencjodawca może niezwłocznie rozwiązać Umowę Licencyjną.</li>
                        <li>W sprawach nieuregulowanych niniejszą Umową Licencyjną mają zastosowanie odpowiednie przepisy prawa polskiego, w tym w szczególności, lecz nie wyłącznie, przepisy kodeksu cywilnego oraz ustawy o prawie autorskim i prawach pokrewnych.</li>
                        <li>Dla sporów mogących powstać na tle niniejszej licencji właściwy jest sąd siedziby Licencjobiorcy.</li>
                        <li>Zmiany w niniejszej Umowie Licencyjnej wymagają formy dokumentowej pod rygorem nieważności.</li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="buttons">
            <a class="btn btn--secondary" (click)="decline()">{{ 'COMMON.DECLINE' | translate }}</a>
            <a class="btn btn--primary" (click)="accept()">{{ 'COMMON.ACCEPT' | translate }}</a>
        </div>
    </div>
</div>
