import { BaseStatementPagePath } from 'app/core/workflow/page-path/statement-page-path/base-statement-page.path';

export class TaxCardSpouseComponentPath extends BaseStatementPagePath {
  public static path(): string {
    return 'statement/:statementId/spouse/:spouseId/b2c/tax-card';
  }

  public static regex(): RegExp {
    return /\/statement\/\d+\/spouse\/\d+\/b2c\/tax-card((\/?#?)|(\/?#.*?))$/;
  }
}
