export class FirebaseConfig {
  public static get appName(): string {
    return 'taxando';
  }

  public static get maxLogAttempts(): number {
    return 20;
  }

  public static get logAttemptTimeout(): number {
    return 2000;
  }
}
