import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { getUser, getUserType, State } from "../../reducers";
import { takeUntil } from "rxjs/operators";
import { SubscribingComponent } from "../../common";

import { Statement } from "../../statements/statement";
import { RefundDialogComponent } from "../../statements/refund-dialog/refund-dialog.component";
import { SendEwrAttachmentComponent } from "../../statements/send-ewr-attachment/send-ewr-attachment.component";
import { CustomLabels } from "app/common/labels-edit/CustomLabels";
import { PackageOptions } from "app/statements/package-options";

import { ListViewAttachmentsComponent } from "../list-view-attachments/list-view-attachments.component";
import { ListViewDownloadsComponent } from "../list-view-downloads/list-view-downloads.component";
import { DownloadFileDialogComponent } from "app/common/decision-dialog/download-file-dialog.component";
import {
  MESSAGE_DOCUMENT_TYPE,
  NewMessageDialogComponent,
  ATTACHMENT_SUBTYPE
} from "app/common/send-message-dialog/send-message-dialog.component";
import { User } from "app/users";

@Component({
  selector: "app-list-view-item-b2b",
  templateUrl: "./list-view-item-b2b.component.html",
  styleUrls: ["./list-view-item-b2b.component.scss"]
})
export class ListViewItemB2bComponent extends SubscribingComponent implements OnInit {
  @Input() openedItems: boolean[];
  @Input() index: number;
  @Input() statement: Statement;
  @Input() hideEditing = false;
  @Input() hideRemoving = false;
  @Input() hideDecision = false;
  @Input() hideInvoice = false;
  @Input() hidePdf = false;
  @Input() showEditButton = false;
  @Input() showCloneButton = false;
  @Input() labelList: CustomLabels[] = [];
  @Output() deleteStatement = new EventEmitter<void>();
  @Output() downloadPdf = new EventEmitter<void>();
  @Output() downloadInvoice = new EventEmitter<void>();
  @Output() showDecision = new EventEmitter<void>();
  @Output() editButton = new EventEmitter<void>();
  @Output() cloneStatement = new EventEmitter<void>();
  @Output() importStatement = new EventEmitter<void>();

  public isBusiness = false;
  public isTaxAdviser = false;
  public numberOfFilesToDownload = 0;
  public isMasterAccount = false;
  public can_send_elster_messages = false;
  private attachmentsDialogComponent = ListViewAttachmentsComponent;
  private downloadsDialogComponent = ListViewDownloadsComponent;

  public emptyCol = "-";

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private store: Store<State>
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(getUserType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userType: string) => {
        if (userType) {
          this.isTaxAdviser = userType === "tax_adviser";
          this.isBusiness = userType === "business";
        }
      });

    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          this.isMasterAccount = user.isMasterAccount;
          this.can_send_elster_messages = user.can_send_elster_messages;
        }
      });
  }

  openRefundDetailsDialog() {
    const dialogRef = this.dialog.open(RefundDialogComponent);
    dialogRef.componentInstance.statement = this.statement;
  }

  emitDownloadPdf() {
    this.downloadPdf.emit();
  }

  emitDownloadInvoice() {
    this.downloadInvoice.emit();
  }

  emitDeleteStatement() {
    this.deleteStatement.emit();
  }

  emitOnEditButton() {
    this.editButton.emit();
  }

  getRefund(): string {
    let plusSign = "";
    if (this.statement.estimatedRefund > 0) {
      plusSign = "+";
    }
    return `${plusSign}${this.statement.estimatedRefund.toFixed(2)}`;
  }

  editStatement() {
    let urlPath = "";
    let url = "";
    if (!this.isTaxAdviser) {
      if (this.isBusiness) urlPath = this.statement.isPaid ? "thank-you" : `employer/${this.statement.employers[0].id}`;
      else urlPath = this.statement.isPaid ? "thank-you" : `b2c/tax-card`;
      urlPath = this.statement.isPaid && this.statement.serviceType === PackageOptions.tax_adviser ? "finish" : urlPath;

      url = `new/statement/${this.statement.id}/${urlPath}`;
    } else {
      const employerId =
        this.statement.employers && this.statement.employers.length ? this.statement.employers[0].id : 0;
      if (this.isBusiness) urlPath = this.statement.isPaid ? `employer/${employerId}` : "dashboard";
      else urlPath = this.statement.isPaid ? `b2c/tax-card` : "dashboard";
      url = `new/statement/${this.statement.id}/${urlPath}`;
    }

    this.router.navigate([url]);
  }

  uploadEWRAttatchment() {
    if (this.statement) {
      const dialogRef = this.dialog.open(SendEwrAttachmentComponent);
      dialogRef.componentInstance.statement = this.statement;
    }
  }

  public emitCloneStatement() {
    this.cloneStatement.emit();
  }

  public emitImportStatement() {
    this.importStatement.emit();
  }

  public showAttachments() {
    const refDialog = this.dialog.open(this.attachmentsDialogComponent, {
      panelClass: ["attachments_dialogbox"],
      backdropClass: "attachments_dialogbox_backdrop",
      data: { statement: this.statement }
    });

    refDialog.componentInstance.quantity.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: number) => {
      this.statement.numberOfAttachments = this.statement.numberOfAttachments + value;
    });
  }

  public showDownloads() {
    const dialogRef = this.dialog.open(DownloadFileDialogComponent);
    dialogRef.componentInstance.statement = this.statement;
  }

  public openAppealDecisionDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-new-message-dialog",
      data: {
        statementData: this.statement,
        titleMaxLength: 99,
        textMaxLength: 1500,
        messageType: MESSAGE_DOCUMENT_TYPE.APPEAL,
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.APPEAL.MODAL_TITLE",
          titleLabel: "SEND_MESSAGE_DIALOG.APPEAL.TITLE_LABEL",
          messageLabel: "SEND_MESSAGE_DIALOG.APPEAL.MESSAGE_LABEL",
          addFileButton: "SEND_MESSAGE_DIALOG.APPEAL.ADD_FILE_BUTTON",
          checkboxText: "SEND_MESSAGE_DIALOG.APPEAL.CHECKBOX_TEXT"
        }
      }
    });
  }

  public openSendNewMessageDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-new-message-dialog",
      data: {
        statementData: this.statement,
        titleMaxLength: 99,
        textMaxLength: 1500,
        messageType: MESSAGE_DOCUMENT_TYPE.MESSAGE,
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.MESSAGE.MODAL_TITLE",
          titleLabel: "SEND_MESSAGE_DIALOG.MESSAGE.TITLE_LABEL",
          messageLabel: "SEND_MESSAGE_DIALOG.MESSAGE.MESSAGE_LABEL",
          addFileButton: "SEND_MESSAGE_DIALOG.MESSAGE.ADD_FILE_BUTTON"
        }
      }
    });
  }

  public openSendAttachmentDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-attachment-dialog",
      data: {
        statementData: this.statement,
        messageType: MESSAGE_DOCUMENT_TYPE.ATTACHMENT,
        subtype: "",
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.ATTACHMENT.MODAL_TITLE",
          addFileButton: "SEND_MESSAGE_DIALOG.ATTACHMENT.ADD_FILE_BUTTON"
        }
      }
    });
  }

  public openSendBellegeDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-attachment-dialog",
      data: {
        statementData: this.statement,
        messageType: MESSAGE_DOCUMENT_TYPE.ATTACHMENT,
        subtype: ATTACHMENT_SUBTYPE.BELEGE,
        onlySave: true,
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.ATTACHMENT.BELLEGE_TITLE",
          addFileButton: "SEND_MESSAGE_DIALOG.ATTACHMENT.ADD_FILE_BUTTON",
          sendByEmailText: "SEND_MESSAGE_DIALOG.ATTACHMENT.SEND_BY_EMAIL",
          titleTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.SEND_BELEGE.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.SEND_BELEGE.MESSAGE",
          onResponse: {
            successTitle: "SEND_MESSAGE_DIALOG.SUCCESS.SEND_BELEGE.TITLE",
            successMessage: "SEND_MESSAGE_DIALOG.SUCCESS.SEND_BELEGE.REQUEST"
          }
        }
      }
    });
  }

  public openSendVollmachtDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-attachment-dialog",
      data: {
        statementData: this.statement,
        messageType: MESSAGE_DOCUMENT_TYPE.ATTACHMENT,
        subtype: ATTACHMENT_SUBTYPE.VOLMACHT,
        onlySave: true,
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.ATTACHMENT.VOLMACHT_TITLE",
          addFileButton: "SEND_MESSAGE_DIALOG.ATTACHMENT.ADD_FILE_BUTTON",
          titleTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.SEND_VOLLMACHT.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.SEND_VOLLMACHT.MESSAGE",
          onResponse: {
            successTitle: "SEND_MESSAGE_DIALOG.SUCCESS.SEND_VOLLMACHT.TITLE",
            successMessage: "SEND_MESSAGE_DIALOG.SUCCESS.SEND_VOLLMACHT.REQUEST"
          }
        }
      }
    });
  }

  public displayMessageButtons(): boolean {
    if (this.isMasterAccount) return this.statement.isSent();

    return this.statement.isSent() && this.can_send_elster_messages;
  }

  public displayMessageBellege(): boolean {
    if (this.isMasterAccount) return !this.statement.isSent();

    return !this.statement.isSent() && this.can_send_elster_messages;
  }

  get shouldShowHamburgerMenu(): boolean {
    return this.displayMessageButtons() || this.displayMessageBellege() || this.isMasterAccount;
  }
}
