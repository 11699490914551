import { Component, ElementRef, HostListener, Input, TemplateRef } from "@angular/core";

@Component({
  selector: "app-mini-hamburger-menu",
  templateUrl: "./mini-hamburger-menu.component.html",
  styleUrls: ["./mini-hamburger-menu.component.scss"]
})
export class MiniHamburgerMenuComponent {
  public isVisible = false;
  @Input() template: TemplateRef<any>;

  constructor(private elementRef: ElementRef) {}

  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.isVisible = false;
    } else if (!this.isVisible) {
      this.isVisible = true;
    }
  }

  public handleShowHamburgerMenu() {
    this.isVisible = !this.isVisible;
  }
}
