<div class="payment-regulations" [class.gray]="statement.serviceType!==packageOption.tax_adviser">
  <div class="regulations_cont">
    <div class="regulations_wrap">
      <div
        *ngIf="statement.isStandardPackage"
        class="wysiwyg"
        [innerHTML]="'CHECKOUT.PAYMENT_REGULATIONS_STANDARD' | translate"
      ></div>
      <div
        *ngIf="statement.isPremiumPackage"
        class="wysiwyg"
        [innerHTML]="'CHECKOUT.PAYMENT_REGULATIONS_PREMIUM' | translate"
      ></div>

      <div class="regulations_wrap_accept">
        <div class="lhs">{{ 'CHECKOUT.PAYMENT_REGULATIONS_LINKS' | translate }}</div>
        <div class="rhs">
          <div class="link_wrap">
            <a class="link is-bold"
               (click)="openTermsAndConditions()">{{ 'COMMON.TERMS_AND_CONDITIONS' | translate }}</a>
          </div>
          <div class="link_wrap">
            <a class="link is-bold"
               (click)="openPrivacyPolicy()">{{ 'COMMON.PRIVACY_POLICY' | translate }}</a>
          </div>
          <div class="link_wrap">
            <a class="link is-bold"
               (click)="openPaymentCancellationDialog()">{{ 'COMMON.PAYMENT_CANCELLATION' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="regulations_cont" *ngIf="showRegulationCheckBox()">
    <div class="regulations_wrap checkbox_wrap">
      <mat-checkbox
        [(ngModel)]="termRegulationsIsChecked"
        (change)="clickTermRegulations.emit(termRegulationsIsChecked)"
        [attr.data-cy]="'checkboxTaxAdviserRegulations'"
      ></mat-checkbox>
      <div class="label_wrap">
        <span class="i_accept">{{'COMMON.I_ACCEPT' | translate}}</span>
        <a class="link is-bold" (click)="openTaxAdvisorLicence()">
          {{ 'COMMON.TAX_ADVISER_LICENSE' | translate }}
        </a>
      </div>
    </div>
    <div class="regulations_wrap checkbox_wrap">
      <mat-checkbox
        [(ngModel)]="termRealDataIsChecked"
        (change)="clickTermRealData.emit(termRealDataIsChecked)"
        [attr.data-cy]="'checkboxTaxAdviserTermsRealData'"
      >
      </mat-checkbox>
      <div class="label_wrap"> {{'COMMON.REAL_DATA_ACCEPT' | translate }}</div>
    </div>
  </div>
</div>
