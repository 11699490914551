import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { PackageSelectionComponentPath } from 'app/core/workflow/page-path/statement-page-path/package-selection-component.path';
import { PhoneVerificationComponentPath } from 'app/core/workflow/page-path/statement-page-path/phone-verification-component.path';

export class WorkflowPackageSelectionService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.statement && this.statement.stagePackageSelection;
  }

  public get isVisible(): boolean {
    return this.isRequired;
  }

  public get isRequired(): boolean {
    return !this.isBusiness;
  }

  public get title(): string {
    return 'ASIDE_BAR.PACKAGE_SELECTION';
  }

  public get checkCanGo(): boolean {
    if (!this.blockedDefaultWorkflow && this.statement && this.isRequired) {
      if (this.hasChildren) {
        return this.statement.stagePersonalInfoChildren;
      }

      if (this.hasSpouseAndFilledTogether) {
        return this.statement.spouse.stageAdditionalIncome;
      }

      return this.statement.stagePersonalInfo;
    }

    return false;
  }

  public get isActiveOnStepsBar(): boolean {
    const locationHref = window.location.href;

    return locationHref.search(PackageSelectionComponentPath.regex()) !== -1
      || locationHref.search(PhoneVerificationComponentPath.regex()) !== -1;
  }

  public get urlRegex(): RegExp {
    return PackageSelectionComponentPath.regex();
  }

  public get url(): string {
    return PackageSelectionComponentPath.url(this.getStatementId);
  }

  public get stepClass(): string {
    return this.classMarginTop;
  }
}
