<div class="section-title">
  <h2>{{ title | translate }}</h2>
  <ng-container *ngIf="displayTooltip()">
    <app-more-info-tooltip
      [titleTooltip]="titleTooltip"
      [message]="message"
      [isInnerHTML]="true"
      [contentTemplateRef]="tooltipContentRef"
    >
    </app-more-info-tooltip>
  </ng-container>
</div>
