<ng-container *ngIf="!contentLoaded">
  <app-content-loader></app-content-loader>
</ng-container>
<ng-container *ngIf="contentLoaded">
  <div class="step-container columns is-desktop" >
    <app-aside-bar
      [showEvenWithoutStatement]="false"
      [statement]="statement"
      [isBusiness]="isBusiness"
      [workFlowController]="workFlowController"
    >
    </app-aside-bar>
    <div class="choose-profile" [class.grid]="!isBusiness">
      <ng-container *ngIf="isBusiness">
        <h1 class="title">
          <span>{{ 'PROFILE.TITLE_FIRST' | translate }}</span>
          <span>{{ 'PROFILE.TITLE_SECOND' | translate }}</span>
        </h1>
        <div class="description">
          {{ 'PROFILE.DESCRIPTION' | translate }}
        </div>
      </ng-container>
      <div class="choose-profile_wrap" [class.a_center]="currentStep===2 || currentStep===3">
        <ng-container *ngIf="!isBusiness">
          <ng-container *ngIf="currentStep===1">
            <h1 class="title_42" >
              <span>{{ 'PROFILE_BC.STEP_1_TITLE' | translate }}</span>
            </h1>
            <p class="subdesc">
              {{ 'PROFILE_BC.STEP_1_DESC' | translate }}
            </p>
          </ng-container>
          <ng-container *ngIf="currentStep===2">
            <h1 class="title_42" >
              <span *ngIf="statement.relationshipStatus===2">{{ 'PROFILE_BC.STEP_2_MARRIAGE_TITLE' | translate }}</span>
              <span *ngIf="statement.relationshipStatus===3">{{ 'PROFILE_BC.STEP_2_PARTNERSHIP_TITLE' | translate }}</span>
              <span *ngIf="statement.relationshipStatus===4">{{ 'PROFILE_BC.STEP_2_WIDOWED_TITLE' | translate }}</span>
              <span *ngIf="statement.relationshipStatus===5">{{ 'PROFILE_BC.STEP_2_SEPARATION_TITLE' | translate }}</span>
              <span *ngIf="statement.relationshipStatus===6">{{ 'PROFILE_BC.STEP_2_DIVORCE_TITLE' | translate }}</span>
            </h1>
            <p class="subdesc" *ngIf="statement.relationshipStatus===2">{{ 'PROFILE_BC.STEP_2_MARRIAGE_DESC' | translate }}</p>
            <p class="subdesc" *ngIf="statement.relationshipStatus===3">{{ 'PROFILE_BC.STEP_2_PARTNERSHIP_DESC' | translate }}</p>
            <p class="subdesc" *ngIf="statement.relationshipStatus===4">{{ 'PROFILE_BC.STEP_2_WIDOWED_DESC' | translate }}</p>
            <p class="subdesc" *ngIf="statement.relationshipStatus===5">{{ 'PROFILE_BC.STEP_2_SEPARATION_DESC' | translate }}</p>
            <p class="subdesc" *ngIf="statement.relationshipStatus===6">{{ 'PROFILE_BC.STEP_2_DIVORCE_DESC' | translate }}</p>
          </ng-container>
          <ng-container *ngIf="currentStep===3">
            <h1 class="title_42" >
              <span>{{ 'PROFILE_BC.STEP_3_TITLE' | translate }}</span>
            </h1>
            <p class="subdesc">
              {{ 'PROFILE_BC.STEP_3_DESC' | translate }}
            </p>
          </ng-container>
        </ng-container>
        <div class="choose-profile__content">
          <div class="alert-box" *ngIf="errors?.marital_status" #error>{{ errors.marital_status[0] }}</div>
          <div *ngIf="statement">
            <ng-container *ngIf="!isBusiness">
              <app-profile-edit-bc #profileEditRef [statement]="statement" [errors]="errors"
                                   [(epValidator)]="epValidator" [(currentStep)]="currentStep" (statementChangedEvent)="statementChangedEvent($event)"></app-profile-edit-bc>
            </ng-container>
            <ng-container *ngIf="isBusiness">
              <app-profile-edit #profileEditRef [statement]="statement" [errors]="errors"
                                [(epValidator)]="epValidator" [(currentStep)]="currentStep"></app-profile-edit>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <app-tools-bar [statement]="statement" [buttons]="buttons" *ngIf="statement"></app-tools-bar>
  </div>
</ng-container>
