import { SelectsDataInterface } from '../statements/selects-data';
import * as actions from './actions/selects-data';

export interface State {
  selectsData: SelectsDataInterface | null;
}

export const initialState: State = {
  selectsData: null
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_SELECTS_DATA: {
      return {
        selectsData: action.payload,
      };
    }
    case actions.REMOVE_SELECTS_DATA: {
      return {
        selectsData: null
      };
    }
    default: {
      return state;
    }
  }
}

export const getSelectsData = (state: State) => state.selectsData;
