import { Component } from "@angular/core";
import { CtrBaseComponent } from "../ctr-base.component";

@Component({
  ...CtrBaseComponent.metaData,
  selector: "app-ctr-additional-income",
  templateUrl: "./ctr-additional-income.component.html",
  styleUrls: ["../ctr-base.component.scss"]
})
export class CtrAdditionalIncomeComponent extends CtrBaseComponent {}
