<div class="dialog-box">
  <div class="dialog-box__content deductions-content">
    <div class="title d-flex align-items-center">
      <img *ngIf="selectButton.imageSrc" src="{{selectButton.imageSrc}}" />
      <span *ngIf="selectButton.label">
				{{ selectButton.label | translate }}
			</span>
      <button
        mat-icon-button
        mat-dialog-close=""
        tabindex="-1"
      >
        <mat-icon aria-hidden="true">close</mat-icon>
      </button>
    </div>

    <div class="description" *ngIf="tooltip">
      <app-more-info-tooltip
        [alignLeft]="true"
        [isSmall]="true"
        [showText]="true"
        [message]="tooltipMsg"
      ></app-more-info-tooltip>
    </div>

    <div class="dialog-box__form mt-1">
      <div class="dialog-box__form-content">
        <ng-container>
          <app-additional-income-dialog-edit
            [mainStatement]="mainStatement"
            [currentStatement]="currentStatement"
            [title]="selectButton.label"
            [incomeType]="type"
            [epValidator]="epValidator"
            [isBusiness]="isBusiness"
            [icon]="selectButton.imageSrc"
            [appShowFormError]
          ></app-additional-income-dialog-edit>
        </ng-container>
      </div>

      <div class="dialog-box__form-buttons">
        <button class="btn btn--secondary btn--small" mat-dialog-close>{{ 'COMMON.CANCEL' | translate }}</button>
        <button
          class="btn btn--primary btn--longer"
          [class.is-loading]="additionalIncomeService.isLoading"
          (click)="save()"
        >
          {{ 'COMMON.SAVE' | translate }}
        </button>
      </div>

    </div>
  </div>
</div>
