import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {Payment} from '../payment';


@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent implements OnInit {
  openedItems: boolean[] = [];
  @Input() payments: Payment[];
  @Output() downloadInvoice = new EventEmitter<Payment>();

  constructor() { }

  ngOnInit() {
    this.clearOpenedItems();
  }

  emitDownloadInvoice(payment: Payment) {
    this.downloadInvoice.emit(payment);
  }

  toggleItem(index) {
    this.clearOpenedItems();
    this.openedItems[index] = true;
  }

  clearOpenedItems() {
    this.openedItems = this.openedItems.map(item => false);
  }
}
