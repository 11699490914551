import { finalize, takeUntil } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

import { Store } from "@ngrx/store";

import { State, getUser } from "../../reducers";
import { SHOW_SNACKBAR } from "../../reducers/actions/snackbar";
import { Statement } from "../../statements";
import { StatementService, StatementsResponse } from "../../statements/statement.service";
import { REFRESH_STATEMENTS_INTERVAL, SubscribingComponent, SnackbarConfig, saveFile } from "../../common";
import { ErrorDialogComponent } from "../../common/error-dialog/error-dialog.component";
import { User } from "app/users";
import { TranslateService } from "@ngx-translate/core";

/**
 * w linku dodajemy mode=1 aby oznaczyc czy pierwszy raz wchodzi w ten widok
 */

@Component({
  selector: "app-thank-you-business",
  templateUrl: "./thank-you-business.component.html",
  styleUrls: ["./thank-you-business.component.scss"]
})
export class ThankYouBusinessComponent extends SubscribingComponent implements OnInit {
  batchId: number;
  statements: Statement[];
  isSending = false;
  batchCode = "";
  taskId: string;
  isPlaceholderVisible = false;
  isSubaccount = false;
  isMasterAccount = false;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.batchId = parseInt(params["batchId"], 10);
      this.getBatchStatements();
    });

    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          this.isSubaccount = user.isSubaccount;
          this.isMasterAccount = user.isMasterAccount;
        }
      });
  }

  getBatchStatements(checkStatements = false) {
    this.statementService
      .getBatchStatements(this.batchId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: StatementsResponse) => {
        console.log("get ", response);

        this.statements = response.statements;
        this.getBatchCode();
        if (checkStatements) {
          this.isPlaceholderVisible = false;
          if (this.statements && this.statements.some((statement) => !statement.isSent())) {
            this.showErrorDialog();
          } else {
            this.router.navigate([`/new/batch/${this.batchId}/congratulations`]);
          }
        }
      });
  }

  sendStatement() {
    this.isSending = true;
    this.statementService
      .sendBatch(this.batchCode)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.isSending = false))
      )
      .subscribe(
        (response: Response) => {
          this.checkIfSentSuccessed(response);
        },
        (error: Response) => {
          this.handleErrors(error);
        }
      );
  }

  sendWithCommonCert() {
    this.isSending = true;
    console.log(this.batchCode);

    this.statementService
      .sendBatch(this.batchCode, true)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.isSending = false))
      )
      .subscribe(
        (response: Response) => {
          this.checkIfSentSuccessed(response);
        },
        (error: Response) => {
          this.handleErrors(error);
        }
      );
  }

  handleErrors(errors: any) {
    const message = errors && errors.error && errors.error.length ? errors.error[0] : null;
    if (typeof message === "string") {
      this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig(message, "ERROR") });
    }
  }

  isTaskProcessing() {
    setTimeout(this.observeTaskProcessing.bind(this), REFRESH_STATEMENTS_INTERVAL);
  }

  observeTaskProcessing() {
    this.statementService
      .getBatchStatus(this.taskId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (!response) {
          return;
        }

        if (response["ready"]) {
          this.getBatchStatements(true);
          return;
        }

        this.isTaskProcessing();
      });
  }

  showErrorDialog() {
    const dialogRef = this.dialog.open(ErrorDialogComponent);
    dialogRef.componentInstance.message = this.translate.instant("COMMON.GLOBAL_ERROR");
  }

  checkIfSentSuccessed(response: {}) {
    this.taskId = response["task_id"];
    if (response && this.taskId) {
      this.isPlaceholderVisible = true;
      this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig("SNACKBAR.SENDING_STATEMENTS") });
      this.observeTaskProcessing();
    } else {
      this.store.dispatch({
        type: SHOW_SNACKBAR,
        payload: new SnackbarConfig("SNACKBAR.SENDING_STATEMENTS_ERROR", "ERROR")
      });
    }
  }

  isButtonDisabled() {
    return !this.statements || (this.statements && this.statements.some((statement) => statement.isSent()));
  }

  getBatchCode() {
    if (this.statements.length) {
      console.log(this.statements);
      this.batchCode = this.statements[0].batchCode;
    }
  }

  isMasterOrSubaccount() {
    return this.isSubaccount || this.isMasterAccount;
  }
}
