import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubscribingComponent } from 'app/common';

export interface CardItemInterface {
  id: number|string;
  idForActionButtons: number|string;
  titleShort: string;
  titleShortAfter?: string;
  title: string;
  titleAfter?: string;
  textOneShort: string;
  textOne: string;
  textTwo: string;
  warning: string;
  error: string;
  noError: string;
  buttonDeleteLoading?: boolean;
}

@Component({
  selector: 'app-cards-items-list',
  templateUrl: './cards-items-list.component.html',
  styleUrls: ['./cards-items-list.component.scss']
})
export class CardsItemsListComponent extends SubscribingComponent {
  @Input() isButtonAddShown = true;
  @Input() buttonAddTitle = 'COMMON.ADD';
  @Input() dataList: CardItemInterface[] = [];
  @Output() clickButtonAddEmitter = new EventEmitter();
  @Output() clickButtonEditEmitter = new EventEmitter();
  @Output() clickButtonDeleteEmitter = new EventEmitter();

  constructor(
  ) {
    super();
  }

  public clickButtonAddEvent(): void {
    this.clickButtonAddEmitter.emit();
  }

  public clickButtonEditEvent(index: number): void {
    this.clickButtonEditEmitter.emit(index);
  }

  public clickButtonDeleteEvent(index: number): void {
    this.clickButtonDeleteEmitter.emit(index);
  }
}
