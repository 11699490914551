import { Injectable } from '@angular/core';
import { AuthHttpService } from 'app/users';
import { getApiPath } from 'app/common';

@Injectable()
export class IndividualCouponHttpService {

  constructor(private authHttp: AuthHttpService) { }

  getIndividualCoupon() {
    return this.authHttp.post(`${getApiPath()}payments/send-individual-coupon/`, {}, true);
  }
}
