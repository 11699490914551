import { Injectable } from '@angular/core';
import { AuthHttpService } from 'app/users';
import { getApiPath } from 'app/common';

@Injectable()
export class CouponHttpService {
  coupons = [
    {
      discount : "-10 EUR",
      description: "Świetnie! Osoba, do której dotarł Twój kupon użyła go - aby skorzystać ze swojej zniżki użyj kodu poniżej",
      code: "aaaaa",
      link: null,
      active: true
    },
    {
      discount : "-10 EUR",
      description: "Kod jest aktywny! Skopiuj kod poniżej - możesz z niego skorzystać na stronie naszego partnera",
      code: "bbbbb",
      link: "https://angular.io/api/forms/NgModel",
      active: true
    },
    {
      discount : "-10 EUR",
      description: "Kod nie jest jeszcze aktywny - sprawdź jak go zdobyć:",
      code: null,
      link: null,
      active: false
    },
  ]

  constructor(private authHttp: AuthHttpService) { }

  getCoupons() {
    return this.authHttp.get(`${getApiPath()}payments/individual-coupons/`, {}, true);
  }

  // createCoupons(){
  //     this.http.post(this.apiUrl+"payments/individual-coupons/")
  // }
}
