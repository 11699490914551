<app-square-loader
  size="65"
  *ngIf="isLoading$ | async"
></app-square-loader>
<div class="alert-box" *ngIf="isFailed$ | async">
  {{ 'COMMON.ERROR' | translate }}
</div>
<div
  #turnstileContainer
  [class.d-none]="isLoading$ | async"
>
</div>
