import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { Statement } from '../statement';
import { StatementService } from '../statement.service';
import { takeUntil } from 'rxjs/operators';
import { SubscribingComponent } from 'app/common';
import { PackageOptions } from '../package-options';
import { FirebaseNotification } from 'app/firebase/notification';
import { FirebaseHttpService } from 'app/firebase/firebase.http.service';
import { getUser, State, getUserType } from 'app/reducers';
import { Store } from '@ngrx/store';
import {Events, FirebaseEventsService} from 'app/common/firebase-events.service';
import { RateAppService } from 'app/modules/rateapp/services/rate-app.service';
import { DashboardPagePath } from 'app/core/workflow/page-path/dashboard-page.path';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class FinishComponent extends SubscribingComponent implements OnInit {
  statementId: number;
  public statement: Statement;
  loadingStatementData = true;
  errors = [];
  packageOption = PackageOptions;
  public user_id;
  public isBusiness = false;
  public buttons = [
    {
      type: 'proceed',
      label: 'FINISH.GO_TO_DASHBOARD',
      action: this.navigateToDashboard.bind(this)
    }
  ];
  public contentLoaded = false;
  public lottieConfig: Object = {
    path: '../../../assets/anim/anim_finnish.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };

  constructor(
    private router: Router,
    private statementService: StatementService,
    private route: ActivatedRoute,
    private firebaseService: FirebaseHttpService,
    private store: Store<State>,
    private firebaseEventsService: FirebaseEventsService,
    private rateAppService: RateAppService,
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this.route.params,
      this.store.select(getUser),
      this.store.select(getUserType),
    ]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([params, user, userType]) => {
        if (params && user && userType) {
          this.statementId = parseInt(params['statementId'], 10);
          this.getStatementData(this.statementId);

          this.user_id = user.id;

          this.isBusiness = userType === 'business';
        }
      });

    this.clearErrors();

    this.firebaseEventsService.logEvent('end_screen', null, this.statementId);

  }

  clearErrors() {
    this.errors = [];
  }

  getStatementData(id: number) {
    this.loadingStatementData = true;
    this.statementService.getStatement(id).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: Statement) => {
        this.statement = response;

        if (this.statement.isStandardPackage) {
          this.rateAppService.callRateAppView();
          return;
        }

        if (this.statement.serviceType == PackageOptions.tax_adviser) {

          if (sessionStorage.getItem('purchase_complete') !== this.statement.id.toString()
              && !this.statement.eventPurchaseComplete) {

            sessionStorage.setItem('purchase_complete', this.statement.id.toString());

            const purchaseParams = {
                type: 'steuer++',
                currency: this.statement.paymentCurrency,
                value: this.statement.paymentAmount
            };

            this.firebaseEventsService.logEvent(Events.purchase, purchaseParams, this.statementId);
            this.firebaseEventsService.webEvent(Events.purchase, purchaseParams, this.statement.id);
          }

          if (sessionStorage.getItem('purchase_complete_steuer') !== this.statement.id.toString()
              && !this.statement.eventPurchaseCompleteType) {

            sessionStorage.setItem('purchase_complete_steuer', this.statement.id.toString());

            const steuerParams = {
                currency: this.statement.paymentCurrency,
                value: this.statement.paymentAmount
            };

            this.firebaseEventsService.logEvent(Events.purchase_premium, steuerParams, this.statementId);
            this.firebaseEventsService.webEvent(Events.purchase_premium, steuerParams, this.statementId);
          }
        }

        if (this.statement.status === 'sent') {
          const notification = new FirebaseNotification();
          notification.user = this.user_id;
          notification.statement = this.statement.id;
          this.firebaseService.udpateFirebaseNotification(notification)
              .subscribe(response => {}, error => {
                console.log(error);
              });
        }

        this.contentLoaded = true;
        this.loadingStatementData = false;
      }, (error: Error) => {
        this.loadingStatementData = false;
        this.router.navigate(['/new']);
      });
  }

  navigateToDashboard() {
    this.router.navigate([DashboardPagePath.fullUrl()]);
  }
}
