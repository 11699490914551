export const VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME = {
  social_name: "ai_social_name",
  social_amount: "ai_social_amount",
  social_other_name: "ai_social_other_name",
  social_period_lower: "ai_social_period_lower",
  social_period_upper: "ai_social_period_upper",
  freelance_name: "ai_freelance_name",
  freelance_income: "ai_freelance_income",
  freelance_costs: "ai_freelance_costs",
  freelance_income_net: "ai_freelance_income_net",
  cryptocurrency_income_net: "ai_cryptocurrency_income_net",
  pension_from: "ai_pension_from",
  pension_amount: "ai_pension_amount",
  pension_start: "ai_pension_start",
  pension_adjustment: "ai_pension_adjustment",
  //TAX-3030
  tax_authority: "ai_tax_authority",
  business_steuernummer: "ai_business_steuernummer",
  business_postal_code: "ai_postal_code",
  private_postal_code: "ai_private_postal_code",
  business_end: "ai_business_end",
  business_start: "ai_business_start",
  business_name: "ai_business_name",
  business_street: "ai_business_street",
  business_city: "ai_city",
  private_street: "ai_private_street",
  private_city: "ai_private_city",
  income_costs: "ai_income_costs",
  incomes: "ai_incomes",
  income_value: "ai_income_value",
  lump_income_value: "ai_lump_income_value",
  vat_value: "ai_vat_value",
  operating_vat_type: "ai_operating_vat_type",
  total_payments: "ai_total_payments",
  total_withdrawals: "ai_total_withdrawals",
  business_type: "ai_business_type",
  has_sold_business_real_estate: "ai_has_sold_business_real_estate",
  corona_value: "ai_corona_value"
};
