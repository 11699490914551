<div class="account-container">
    <a class="account-button" (click)="toggleRegisterPopup()">
        <span class="account-button-text">{{ 'COMMON.HAS_ACCOUNT' | translate }}</span>
        <span>{{ 'COMMON.LOG_IN' | translate }}</span>
    </a>
    <div [className]="mobile ? 'register-dialog mobile' : 'register-dialog'" [class.active]="showRegisterPopup">
        <div class="register-dialog-overlay" (click)="showRegisterPopup = false"></div>
        <div class="register-dialog-wrap">
            <div class="btn_close" (click)="showRegisterPopup = false"></div>

            <div class="btn-wrap">
                <p>{{ 'COMMON.ALREADY_REGISTERED' | translate }}</p>
                <div>
                    <a (click)="openLoginDialog(authenticationDialogFlag.LOGIN)" class="btn btn--primary btn--upper">{{ 'COMMON.LOG_IN' |
                        translate }}</a>
                </div>
            </div>
            <div class="btn-wrap sm_m">
                <p>{{ 'COMMON.NOT_YET_REGISTERED' | translate }}</p>
                <div>
                    <a (click)="openLoginDialog(authenticationDialogFlag.REGISTRATION)" class="btn btn--info btn--upper">{{ 'COMMON.SIGN_UP' |
                        translate }}</a>
                </div>
            </div>

            <div class="aside-menu__stages aside-menu__info-container d-flex flex-wrap">
                <a class="aside-menu__stage" (click)="showImpressumDialog()">Impressum</a>
                <span class="aside-menu_separator"></span>
                <a class="aside-menu__stage" (click)="showPrivacyPolicyDialog()">{{ 'COMMON.PRIVACY_POLICY' | translate }}</a>
                <span class="aside-menu_separator"></span>
                <a class="aside-menu__stage" (click)="showTermsAndConditionsDialog()">{{ 'COMMON.TERMS_AND_CONDITIONS_NOT_LOGGED' | translate }}</a>
                <span class="aside-menu_separator"></span>
                <a class="aside-menu__stage" (click)="showCookieBanner()">{{ 'COMMON.COOKIE_BANNER' | translate }}</a>
            </div>

            <div class="logo_wrap">
                <img src="../../../assets/svg/new_logo.svg" class="logo">
            </div>
        </div>
    </div>
</div>
