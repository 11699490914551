import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthHttpService } from 'app/users';
import { getApiPath } from 'app/common';
import { UserDeleteModel } from 'app/core/models/user/user-delete.model';
import { UserDeleteResponseInterface } from 'app/core/models/user/user-delete.response.interface';
import { HttpResponseModel } from 'app/core/models/http/http-response.model';
import { UserDeleteErrorInterface } from 'app/core/models/user/user-delete.error.interface';
import { HttpResponseInterface } from 'app/core/models/http/http-response.interface';

@Injectable({
  providedIn: 'any',
})
export class UserHttpService {
  constructor(
    private authHttp: AuthHttpService
  ) {}

  public async deleteAccount(
    payload?: UserDeleteResponseInterface
  ): Promise<HttpResponseInterface<UserDeleteModel, UserDeleteErrorInterface>> {
    return await firstValueFrom(
      this.authHttp.remove<UserDeleteResponseInterface>(`${getApiPath()}accounts/delete`, payload)
        .pipe(
          map((response) =>
            HttpResponseModel.fromResponse<UserDeleteModel>(response ? UserDeleteModel.fromResponse(response) : null)),
          catchError(e => of(HttpResponseModel.fromError<UserDeleteErrorInterface>(e.error))),
        )
    );
  }
}
