<div class="step-container columns is-desktop" *ngIf="statement">
    <app-aside-bar
      [showEvenWithoutStatement]="false"
      [statement]="statement"
    ></app-aside-bar>
    <div class="download-statement">
        <div class="download-statement__header">
            <h1 class="title_42" [innerHTML]="'DOWNLOAD_STATEMENT.TITLE' | translate"></h1>
            <p class="subdesc" [innerHTML]="'DOWNLOAD_STATEMENT.DESCRIPTION' | translate"></p>
        </div>

        <img src="../../../assets/svg/send_new.svg" class="page_icon">

        <div class="download-statement__wrap">
            <div class="download-statement__section grid">
                <h2 class="title_24">
                    {{ 'DOWNLOAD_STATEMENT.STEP_1_TITLE' | translate }}
                </h2>
                <button type="button" class="btn_simple black no_mw w_icon"
                    (click)="downloadPdf()">
                    <img src="../../../assets/svg/download_new.svg" class="icon">
                    <span>
                        {{ 'DOWNLOAD_STATEMENT.CONFIRM_AND_DOWNLOAD' | translate }}
                    </span>
                </button>
            </div>
            <div class="download-statement__section">
                <h2 class="title_24 m_bot">
                    {{ 'DOWNLOAD_STATEMENT.STEP_2_TITLE' | translate }}
                </h2>
                <div class="address_wrap">
                    <address class="wysiwyg" [innerHTML]="'DOWNLOAD_STATEMENT.ADDRESS' | translate"></address>
                </div>
            </div>
            <div class="download-statement__section">
                <h2 class="title_24 m_bot">
                    {{ 'DOWNLOAD_STATEMENT.STEP_3_TITLE' | translate }}
                </h2>
                <div class="wysiwyg" [innerHTML]="'DOWNLOAD_STATEMENT.STEP_3_DESC' | translate"></div>
            </div>
        </div>
    </div>
    <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
</div>
