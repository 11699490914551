<app-menu-bar-mobile></app-menu-bar-mobile>
<div class="dashboard">
  <div class="dashboard__header dashboard__header--main">
    <h1 class="title_42" [class.header_title]="!isBusiness">{{ "MENU.DASHBOARD" | translate }}</h1>
    <div class="dashboard__buttons" [class.dashboard__buttons-block]="bcFilterYear > 0">
      <!--            <div class="dashboard__buttons-filter hidden-xs" *ngIf="!isBusiness && !isTaxAdviser">-->
      <!--                <button class="btn&#45;&#45;dashboard-filter" [class.active]="viewType === viewTypeEnum.List" (click)="changeToListView()">-->
      <!--                    <img src="../../../assets/svg/dashboard_list.svg" alt="List">-->
      <!--                </button>-->
      <!--                <button class="btn&#45;&#45;dashboard-filter" [class.active]="viewType === viewTypeEnum.Card" (click)="changeToCardView()">-->
      <!--                    <img src="../../../assets/svg/dashboard_tiles.svg" alt="Tiles">-->
      <!--                 </button>-->
      <!--            </div>-->

      <!-- <div class="sort-wrap-btn" [class.hidden-xs]="!isBusiness">
                <app-sort-list [batches]="batches" [statements]="paginatedStatementsList" [isBusiness]="isBusiness"
                    [labelList]="labelList" [(filterButtonDisabled)]="filterButtonDisabled"
                    (filterEvent)="filter($event)"></app-sort-list>
            </div> -->
      <ng-container *ngIf="bcFilterYear > 0 && !isBusiness && !isTaxAdviser">
        <div class="sort-wrap-btn">
          <button class="btn_simple green" (click)="showAllStatements()">
            {{ "NEW_STATEMENT.DASHBOARD_SHOW_ALL" | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="bcFilterYear > 0">
        <button
          class="btn_simple green"
          (click)="createStatementManually()"
          [class.large_btn_xs]="!isBusiness && !isTaxAdviser"
          [attr.data-cy]="'btnCreateStatement'"
        >
          {{ "DASHBOARD.FILE_NEW" | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="bcFilterYear === 0">
        <ng-container *ngIf="isBusiness || isTaxAdviser">
          <app-sort-list
            [batches]="batches"
            [statements]="paginatedStatementsList"
            [isBusiness]="isBusiness || isTaxAdviser ? true : false"
            [labelList]="labelList"
            [(filterButtonDisabled)]="filterButtonDisabled"
            (filterEvent)="filter($event)"
            [isTaxAdviser]="isTaxAdviser ? true : false"
          ></app-sort-list>
        </ng-container>

        <button
          class="btn_simple green"
          [class.large_btn_xs]="!isBusiness"
          (click)="newStatement()"
          [attr.data-cy]="'btnCreateStatement'"
        >
          {{ "DASHBOARD.FILE_NEW" | translate }}
        </button>
      </ng-container>
    </div>
  </div>
  <div
    class="dashboard-individual"
    [class.dashboard__content--card-view]="viewType === viewTypeEnum.Card"
    [class.dashboard-individual__list]="viewType === viewTypeEnum.List"
    *ngIf="!isBusiness && !isTaxAdviser"
  >
    <div class="dashboard-individual__wrapper">
      <div class="alert_box" role="alert" *ngIf="statementInProgressWarning && showStatementInProggressWarning">
        <p [innerHTML]="'DASHBOARD.WARNING' | translate"></p>
        <div class="close_btn_wrap">
          <button
            class="close_btn"
            mat-icon-button
            aria-label="close"
            (click)="showStatementInProggressWarning = false"
          >
            <mat-icon aria-hidden="true">close</mat-icon>
          </button>
        </div>
      </div>
      <!-- TOP LIST OF STATEMENTS -->
      <div class="dashboard__single_info_cont" *ngIf="!isBusiness && !isTaxAdviser">
        <div class="dashboard__single_info_tile" *ngFor="let statement of unfinishedStatements">
          <div class="dashboard__single_info_tile_wrap">
            <div class="dashboard__single_info_tile_left">
              <div class="dashboard__single_info_tile_head">
                <span class="green">{{ statement.fullYear }}</span>
                <span>{{ statement.lastName }} {{ statement.firstName }}</span>
                <button (click)="expandUnfinishedStatements(statement)" class="visible-xs" mat-icon-button>
                  <mat-icon *ngIf="!statement.isOpenDesc">expand_more</mat-icon>
                  <mat-icon *ngIf="statement.isOpenDesc">expand_less</mat-icon>
                </button>
              </div>
              <div
                class="dashboard__single_info_tile_desc"
                [class.active]="statement.isOpenDesc"
                *ngIf="statement.serviceType === packageOptions.tax_adviser"
              >
                <ng-container
                  *ngIf="statement.vollmachtSigned && statement.status !== statementStatuses.CLIENT_APPROVAL"
                >
                  {{ "DASHBOARD.SERVICE_TILES.TAX_ADVISER.VOLMACHT" | translate }}
                </ng-container>
                <ng-container
                  *ngIf="!statement.vollmachtSigned && statement.status !== statementStatuses.CLIENT_APPROVAL"
                >
                  {{ "DASHBOARD.SERVICE_TILES.TAX_ADVISER.NO_VOLMACHT" | translate }}
                </ng-container>
                <ng-container *ngIf="statement.status === statementStatuses.CLIENT_APPROVAL">
                  {{ "DASHBOARD.SERVICE_TILES.TAX_ADVISER.FOR_CLIENT_APPROVAL.DESC" | translate }}
                </ng-container>
              </div>
              <div
                class="dashboard__single_info_tile_desc 2"
                [class.active]="statement.isOpenDesc"
                *ngIf="statement.serviceType === 'paid_service' && statement.status === 'paid'"
              >
                {{ "DASHBOARD.SERVICE_TILES.PAID_SERVICE.DESC" | translate }}
              </div>
            </div>
            <div class="dashboard__single_info_tile_right">
              <ng-container *ngIf="statement.serviceType === packageOptions.tax_adviser">
                <ng-container
                  *ngIf="statement.vollmachtSigned && statement.status !== statementStatuses.CLIENT_APPROVAL"
                >
                  <button class="btn_arrow" (click)="editStatement(statement)">
                    {{ "DASHBOARD.SERVICE_TILES.TAX_ADVISER.VOLMACHT_BUTTON" | translate }}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                      <rect class="a" />
                      <path
                        class="b"
                        d="M25.525,21.439l-7.262-7.262a1.735,1.735,0,1,0-2.454,2.454L20.1,20.943H4.235a1.735,1.735,0,1,0,0,3.47H20.1L15.809,28.7a1.744,1.744,0,1,0,2.478,2.454l7.237-7.237a1.764,1.764,0,0,0,.52-1.239A1.666,1.666,0,0,0,25.525,21.439Z"
                        transform="translate(1.5 -6.675)"
                      />
                    </svg>
                  </button>
                </ng-container>
                <ng-container
                  *ngIf="!statement.vollmachtSigned && statement.status !== statementStatuses.CLIENT_APPROVAL"
                >
                  <button class="btn_arrow" (click)="editStatement(statement)">
                    {{ "DASHBOARD.SERVICE_TILES.TAX_ADVISER.NO_VOLMACHT_BUTTON" | translate }}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                      <rect class="a" />
                      <path
                        class="b"
                        d="M25.525,21.439l-7.262-7.262a1.735,1.735,0,1,0-2.454,2.454L20.1,20.943H4.235a1.735,1.735,0,1,0,0,3.47H20.1L15.809,28.7a1.744,1.744,0,1,0,2.478,2.454l7.237-7.237a1.764,1.764,0,0,0,.52-1.239A1.666,1.666,0,0,0,25.525,21.439Z"
                        transform="translate(1.5 -6.675)"
                      />
                    </svg>
                  </button>
                </ng-container>
                <ng-container *ngIf="statement.isForClientApproval">
                  <button class="btn_arrow" (click)="editStatement(statement)">
                    {{ "DASHBOARD.SERVICE_TILES.TAX_ADVISER.FOR_CLIENT_APPROVAL.BUTTON" | translate }}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                      <rect class="a" />
                      <path
                        class="b"
                        d="M25.525,21.439l-7.262-7.262a1.735,1.735,0,1,0-2.454,2.454L20.1,20.943H4.235a1.735,1.735,0,1,0,0,3.47H20.1L15.809,28.7a1.744,1.744,0,1,0,2.478,2.454l7.237-7.237a1.764,1.764,0,0,0,.52-1.239A1.666,1.666,0,0,0,25.525,21.439Z"
                        transform="translate(1.5 -6.675)"
                      />
                    </svg>
                  </button>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="statement.serviceType === packageOptions.paid && statement.status === 'paid'">
                <button class="btn_arrow qwe" (click)="editStatement(statement)">
                  {{ "DASHBOARD.SERVICE_TILES.PAID_SERVICE.BUTTON" | translate }}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <rect class="a" />
                    <path
                      class="b"
                      d="M25.525,21.439l-7.262-7.262a1.735,1.735,0,1,0-2.454,2.454L20.1,20.943H4.235a1.735,1.735,0,1,0,0,3.47H20.1L15.809,28.7a1.744,1.744,0,1,0,2.478,2.454l7.237-7.237a1.764,1.764,0,0,0,.52-1.239A1.666,1.666,0,0,0,25.525,21.439Z"
                      transform="translate(1.5 -6.675)"
                    />
                  </svg>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard__header" *ngIf="viewType === viewTypeEnum.List">
        <h2 class="dashboard__title dashboard__title--small title"
          >{{ "COMMON.TAX_RETURN" | translate }}
          <ng-container *ngIf="bcFilterYear > 0">
            {{ "NEW_STATEMENT.DASHBOARD_YEAR" | translate }} {{ bcFilterYear }}</ng-container
          >
        </h2>
      </div>

      <!-- <ng-container *ngIf="bcFilterYear>0">
                <div class="new-deductions-info is_icon">
                    <div class="new-deductions-info__wrap">
                        <div class="new-deductions-info__toltip-wrap">
                            <img src="../../../assets/svg/infoMark.svg" class="alert_icon">
                        </div>
                        <div class="new-deductions-info__wrap-text">
                            <span class="new-deductions-info__text">
                                {{'NEW_STATEMENT.DASHBOARD_INFO' | translate}}
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container> -->
      <app-card-view
        *ngIf="viewType === viewTypeEnum.Card"
        [statements]="paginatedStatementsList"
        (downloadPdf)="downloadPdf($event)"
        (downloadInvoice)="downloadInvoice($event)"
        (showDownloadDialog)="showDownloadDialog($event)"
        (deleteStatement)="deleteStatement($event)"
        (cloneStatement)="cloneStatement($event)"
        [(yearsList)]="yearsList"
      >
      </app-card-view>
      <div class="dashboard__list">
        <app-list-view
          *ngIf="viewType === viewTypeEnum.List"
          [statements]="paginatedStatementsList"
          (downloadPdf)="downloadPdf($event)"
          (downloadInvoice)="downloadInvoice($event)"
          (showDownloadDialog)="showDownloadDialog($event)"
          (deleteStatement)="deleteStatement($event)"
          (cloneStatement)="cloneStatement($event)"
        >
        </app-list-view>
      </div>
    </div>
    <app-paginator [pageCount]="pageCount" [currentPage]="page" (changePage)="changePage($event)"></app-paginator>
  </div>

  <div class="dashboard-business" *ngIf="isBusiness && !isTaxAdviser">
    <div class="dashboard-business__list" *ngIf="batches && batches.length">
      <div class="dashboard-business__batch" *ngFor="let batch of batches; let i = index">
        <div>
          <div class="dashboard-business__batch-text">
            <span>{{ "COMMON.BATCH_FILE" | translate }}</span>
            <div class="dashboard-business__buttons">
              <button
                class="btn btn--info btn--small"
                *ngIf="!hideEditButton(i)"
                (click)="editBatch(i)"
                [id]="'batch_edit_' + i"
                [attr.data-cy]="'btnEditStatementBatch_' + i"
              >
                {{ "COMMON.EDIT_BATCH" | translate }}
              </button>
              <button
                class="btn btn--info btn--small"
                *ngIf="!hideRemoveButton(i)"
                [disabled]="batchRemoving"
                (click)="removeBatch(i)"
                [attr.data-cy]="'btnRemoveStatementBatch_' + i"
              >
                {{ "COMMON.REMOVE_BATCH" | translate }}
              </button>

              <span class="ico-remove" *ngIf="!hideRemoveButton(i)" (click)="removeBatch(i)"
                ><i class="fa fa-trash"></i
              ></span>
            </div>
          </div>
          <div class="dashboard-business__batch-content">
            <app-accordion [title]="batch.code" [titleFrame]="true" [transparentBg]="true" [noPadding]="true">
              <app-list-view-b2b
                [statements]="batch.statements"
                (deleteStatement)="deleteBatchStatement($event, i)"
                (cloneStatement)="cloneStatement($event)"
                [hideEditing]="true"
                [hideInvoice]="true"
                [labelList]="labelList"
              ></app-list-view-b2b>
              <div class="dashboard-business__batch-empty" *ngIf="!batch?.statements?.length">
                {{ "COMMON.BATCH_EMPTY" | translate }}</div
              >
            </app-accordion>
          </div>
        </div>
      </div>
    </div>

    <app-paginator [pageCount]="pageCount" [currentPage]="page" (changePage)="changePage($event)"></app-paginator>
  </div>

  <div class="dashboard-business" *ngIf="isTaxAdviser">
    <div class="dashboard-business__list" *ngIf="paginatedStatementsList && paginatedStatementsList.length">
      <div class="dashboard-business__batch" *ngFor="let statement of paginatedStatementsList; let i = index">
        <div>
          <div class="dashboard-business__batch-text">
            <span>{{ "COMMON.BATCH_FILE" | translate }}</span>
            <div class="dashboard-business__buttons">
              <button
                class="btn btn--info btn--small"
                *ngIf="!hideEditButtonTaxAdviser(i)"
                (click)="editStatementTaxAdviser(i)"
                [id]="'batch_edit_' + i"
                [attr.data-cy]="'btnEditStatementBatch_' + i"
              >
                {{ "COMMON.EDIT_BATCH" | translate }}
              </button>
              <button
                class="btn-remove"
                *ngIf="!hideRemoveButtonTaxAdviser(i)"
                [disabled]="batchRemoving"
                (click)="removeBatchTaxAdviser(i)"
                [attr.data-cy]="'btnRemoveStatementBatch_' + i"
              >
                {{ "COMMON.REMOVE_BATCH" | translate }}
              </button>

              <span class="ico-remove" *ngIf="!hideRemoveButtonTaxAdviser(i)" (click)="removeBatchTaxAdviser(i)"
                ><i class="fa fa-trash"></i
              ></span>
            </div>
          </div>
          <div class="dashboard-business__batch-content">
            <app-accordion
              [title]="paginatedStatementsList[i].emailAddress"
              [titleFrame]="true"
              [transparentBg]="true"
              [noPadding]="true"
            >
              <app-list-view
                [statements]="[paginatedStatementsList[i]]"
                (downloadPdf)="downloadPdf($event)"
                (showDownloadDialog)="showDownloadDialog($event)"
                (deleteStatement)="deleteStatement($event, i)"
                (cloneStatement)="cloneStatement($event)"
                [hideEditing]="true"
                [hideInvoice]="true"
                [labelList]="labelList"
              ></app-list-view>
              <div class="dashboard-business__batch-empty" *ngIf="!paginatedStatementsList[i]">
                {{ "COMMON.BATCH_EMPTY" | translate }}</div
              >
            </app-accordion>
          </div>
        </div>
      </div>
    </div>

    <app-paginator [pageCount]="pageCount" [currentPage]="page" (changePage)="changePage($event)"></app-paginator>
  </div>
</div>
