import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { FirebaseEventsService } from 'app/common/firebase-events.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageSelectService {
  private readonly defaultLanguage = 'de';
  private readonly LANGUAGE_SELECTED_KEY = 'siteVisitedLng';
  private readonly CURRENT_LANGUAGE_KEY = 'taxando-lang';
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private reloadOnChange = false;

  constructor(
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly translate: TranslateService,
    private readonly cookieService: CookieService,
    private readonly firebaseEventsService: FirebaseEventsService,
  ) {
  }

  public init(_ngUnsubscribe: Subject<void>): void {
    this.ngUnsubscribe = _ngUnsubscribe;

    this.translate.setDefaultLang(this.defaultLanguage);

    this.useLanguage(this.getLanguage() || this.defaultLanguage);

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.onLanguageChange(this.translate.currentLang);
    });
  }

  public compareLanguageCodes(languageCode: string): boolean {
    return this.translate.currentLang === languageCode;
  }

  public useLanguage(language: string, options: {
    reload?: boolean,
    forceReload?: boolean,
    firebaseLog?: boolean,
    setIsSelected?: boolean,
    forceSave?: boolean,
  } = {}): void {
    const reload = options.reload ?? options.forceReload ?? false,
      forceReload = options.forceReload ?? false,
      firebaseLog = options.firebaseLog ?? false,
      setIsSelected = options.setIsSelected ?? false,
      forceSave = options.forceSave ?? false,
      prevLanguage = this.translate.currentLang;

    this.reloadOnChange = reload;
    this.translate.use(language);
    if (prevLanguage === language && forceReload) {
      this.onLanguageChange(language);
    }

    if (firebaseLog) {
      this.firebaseEventsService.logEvent('click_choose_language', { language: language });
    }

    if (setIsSelected) {
      this.setLanguageIsSelected();
    }

    if (forceSave) {
      this.saveLanguage(language);
    }
  }

  public saveLanguage(language: string): void {
    localStorage.setItem(this.CURRENT_LANGUAGE_KEY, language);
  }

  public setLanguageIsSelected(): void {
    localStorage.setItem(this.LANGUAGE_SELECTED_KEY, '1');
  }

  public isLanguageSelected(): boolean {
    return localStorage.getItem(this.LANGUAGE_SELECTED_KEY) === '1';
  }

  public getLanguage(): string {
    return localStorage.getItem(this.CURRENT_LANGUAGE_KEY)
  }

  private onLanguageChange(languageCode: string): void {
    const html = document.querySelector('html');
    if (html) {
      html.setAttribute('lang', languageCode);
    }

    this.dateAdapter.setLocale(languageCode);
    this.saveLanguage(languageCode);

    if (this.reloadOnChange) {
      location.reload();
    }
  }
}
