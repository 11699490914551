import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { SubscribingComponent } from "app/common";
import { ContentLoaderComponent } from "app/shared/standalone/components/content-loader/content-loader.component";
import { AnimationOptions, LottieComponent } from "ngx-lottie";

@Component({
  selector: `thank-you-redirect`,
  templateUrl: "./thank-you-redirect.component.html",
  standalone: true,
  styleUrls: ["./thank-you-redirect.component.scss"],
  imports: [ContentLoaderComponent, LottieComponent, TranslateModule]
})
export class ThankYouRedirectComponent extends SubscribingComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  public lottieConfig: AnimationOptions<"canvas"> = {
    path: "../../../assets/anim/loader.json",
    renderer: "canvas",
    autoplay: true,
    loop: true
  };

  ngOnInit(): void {
    const statementId = this.route.snapshot.paramMap.get("statementId");
    if (["processing-selfemployment-premium", "processing-premium"].includes(this.router.url)) {
      this.makeRedirect(`new/statement/${statementId}/vollmacht-authorization`);
    } else {
      this.makeRedirect(`/new/statement/${statementId}/thank-you`);
    }
  }

  private makeRedirect(pageAddress: string): void {
    setTimeout(() => {
      this.router.navigate([pageAddress]);
    }, 3000);
  }
}
