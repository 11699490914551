import { createSelector, ActionReducerMap } from '@ngrx/store';

import * as fromAuthUser from './auth-user';
import * as fromUser from './user';
import * as fromSelectsData from './selects-data';
import * as fromRefundDetails from './refund-details';
import * as fromSnackbar from './snackbar';
import * as fromDeductionsInfo from './deductions-info';
import * as fromLoader from './loader';

export interface State {
  authUser: fromAuthUser.State;
  user: fromUser.State;
  selectsData: fromSelectsData.State;
  refundDetails: fromRefundDetails.State;
  snackbar: fromSnackbar.State;
  deductionsInfo: fromDeductionsInfo.State;
  loader: fromLoader.State;
}

export const reducers: ActionReducerMap<State> = {
  authUser: fromAuthUser.reducer,
  user: fromUser.reducer,
  selectsData: fromSelectsData.reducer,
  refundDetails: fromRefundDetails.reducer,
  snackbar: fromSnackbar.reducer,
  deductionsInfo: fromDeductionsInfo.reducer,
  loader: fromLoader.reducer,
};


export const getAuthUserState = (state: State) => state.authUser;
export const getUserState = (state: State) => state.user;
export const getSelectsDataState = (state: State) => state.selectsData;
export const getRefundDetailsState = (state: State) => state.refundDetails;
export const getSnackbarInfo = (state: State) => state.snackbar;
export const getDeductionsInfoState = (state: State) => state.deductionsInfo;
export const getLoaderState = (state: State) => state.loader;

export const getIsUserLogged = createSelector(getAuthUserState, fromAuthUser.isUserLogged);
export const getAuthUser = createSelector(getAuthUserState, fromAuthUser.getUser);
export const getUser = createSelector(getUserState, fromUser.getUser);
export const getUserType = createSelector(getUserState, fromUser.getUserType);
export const getSelectsData = createSelector(getSelectsDataState, fromSelectsData.getSelectsData);
export const getShouldUpdateRefundDetails = createSelector(getRefundDetailsState, fromRefundDetails.getShouldUpdate);
export const getDeductionsInfo = createSelector(getDeductionsInfoState, fromDeductionsInfo.getDeductionsInfo);
export const getSpouseDeductionsInfo = createSelector(getDeductionsInfoState, fromDeductionsInfo.getDeductionsSpouseInfo);
export const getViewSpinner = createSelector(getLoaderState, fromLoader.getViewSpinner);
