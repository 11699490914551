
import {takeUntil} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Store} from '@ngrx/store';

import {State} from '../../reducers';
import {SubscribingComponent} from '../../common';
import {StatementService, StatementsResponse} from '../../statements/statement.service';
import {Statement} from '../../statements';


@Component({
  selector: 'app-congratulations-business',
  templateUrl: './congratulations-business.component.html',
  styleUrls: ['./congratulations-business.component.scss']
})
export class CongratulationsBusinessComponent extends SubscribingComponent implements OnInit {
  batchId: number;
  statements: Statement[];
  batchCode = '';
  batchCreatedBy = '';


  constructor(private statementService: StatementService,
              private store: Store<State>,
              private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.params.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.batchId = parseInt(params['batchId'], 10);
        this.getBatchStatements();
      });
  }

  getBatchStatements() {
    this.statementService.getBatchStatements(this.batchId).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((response: StatementsResponse) => {
        this.statements = response.statements;
        this.getBatchCode();
      });
  }

  getBatchCode() {
    if (this.statements.length) {
      this.batchCode = this.statements[0].batchCode;
      this.batchCreatedBy = this.statements[0].batchCreatedBy;
    }
  }
}
