import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { PhoneVerificationComponentPath } from 'app/core/workflow/page-path/statement-page-path/phone-verification-component.path';

export class WorkflowPhoneVerificationService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.canGo && this.statement && this.statement.hasVerifiedPhoneNumber;
  }

  public get isRequired(): boolean {
    return !this.isBusiness && this.statement && this.statement.stagePackageSelection
      && this.statement.isPremiumPackage;
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.isRequired;
  }

  public get urlRegex(): RegExp {
    return PhoneVerificationComponentPath.regex();
  }

  public get url(): string {
    return PhoneVerificationComponentPath.url(this.getStatementId);
  }
}
