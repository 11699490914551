import { Component, Input } from '@angular/core';
import { SubscribingComponent } from '../../common';
import { TaxCardSingleRow } from '../tax-card-single-row';
import { Employer } from 'app/statements/employer';
import { LohnsteuerValidator } from 'app/statements/lohnsteuerValidator';
import { ErrorMessage } from 'app/core/form-validator/validatorInterface';

@Component({
  selector: 'app-tax-card-single-row',
  templateUrl: './tax-card-single-row.component.html',
  styleUrls: ['./tax-card-single-row.component.scss']
})
export class TaxCardSingleRowComponent extends SubscribingComponent {
  @Input() row: TaxCardSingleRow = new TaxCardSingleRow();
  @Input() employer: Employer;
  @Input() employers: Employer[];
  @Input() currentIndex: number;
  @Input() spouseId: number;
  @Input() epValidator: ErrorMessage[] = [];
  @Input() empWarningList: LohnsteuerValidator[] = [];
  @Input() unlocked = '';
  @Input() codes: Object;
  @Input() isBusiness: boolean;
  @Input() isVerification: boolean;
  @Input() isSummary: boolean;
  @Input() isEditableSummaryEmployer: boolean;
  @Input() employerFieldEdit: boolean;
  @Input() toggleField: Function;

  constructor(
  ) {
    super();
  }

  public findValidationByFieldName(validationFieldNames: string[]): boolean {
    return !!this.epValidator.find(x => validationFieldNames.indexOf(x.fieldName) !== -1);
  }
}
