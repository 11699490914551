<app-ctr-base
  [templateRef]="personalInfo"
  [title]="title"
  [categoryProperties]="categoryProperties"
  [dataSelects]="dataSelects"
  [summary]="summary"
></app-ctr-base>

<ng-template #personalInfo>
    <div class="card card-white shadow-0 mb-2" id="personalInfoTab">
        <div class="card__row mb-1">
            <div class="table">
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.FIRST_NAME.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ statement.firstName }}" data-cy="inputApplicantFirstName"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.LAST_NAME.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ statement.lastName }}" data-cy="inputApplicantLastName"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.DOB.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ statement.birthDate }}" data-cy="inputApplicantBirthDate"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.IDENTIFIKATIONSNUMMER.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ statement.identifikationsnummer ? statement.identifikationsnummer : '-' }}" data-cy="inputApplicantIdentifikationNummer"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'SELECT.RELIGION.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input
                            readonly
                            value="{{ findInArray(dataSelects.religionsTranslated, 'id', statement.religion, 'name').toUpperCase() | translate }}"
                            data-cy="inputApplicantReligion"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.STREET.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ statement.street }}" data-cy="inputApplicantStreet"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.HOUSE_NUMBER_WITH_SUPPLEMENT.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ statement.houseNumberWithSupplement }}" data-cy="inputApplicantHouseNumber"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.ADDITIONAL_ADDRESS_INFO.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ statement.additionalAddressInfo ? statement.additionalAddressInfo : '-' }}" data-cy="inputApplicantAdditionalAddress"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.POSTAL_CODE.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ statement.postalCode }}" data-cy="inputApplicantPostalCode"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.CITY.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ statement.city }}" data-cy="inputApplicantCity"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'SELECT.COUNTRY.PLACEHOLDER' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ findInArray(dataSelects.countries, 'code', statement.country, 'name') }}" data-cy="inputApplicantCountry"/>
                    </div>
                </div>
                <div class="table__row" *ngIf="statement.isLiveInGermanyFieldRequired && statement.liveInGermany !== undefined">
                    <div class="table__col">
                        <div class="row__title">{{ 'SELECT.LIVE_IN_GERMANY.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ (!!statement.liveInGermany ? 'COMMON.YES' : 'COMMON.NO') | translate }}" data-cy="inputApplicantLiveInGermany"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.PHONE_NUMBER.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ statement.phoneNumber ? statement.phoneNumber : '-' }}" data-cy="inputApplicantPhoneNumber"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'SELECT.TAX_AUTHORITY.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ findInArray(dataSelects.taxAuthorities, 'id', statement.taxAuthority, 'name') }}" data-cy="inputApplicantTax"/>
                    </div>
                </div>
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'INPUT.STEUERNUMMER.LABEL' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input readonly value="{{ statement.steuernummer ? statement.steuernummer : '-' }}" data-cy="inputApplicantSteuernummer"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
