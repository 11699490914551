import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { getUser, getUserType, State } from "../../reducers";
import { takeUntil } from "rxjs/operators";
import { SubscribingComponent } from "../../common";

import { Statement } from "../../statements";
import { RefundDialogComponent } from "../../statements/refund-dialog/refund-dialog.component";
import { CustomLabels } from "app/common/labels-edit/CustomLabels";
import { PackageOptions } from "app/statements/package-options";
import { ListViewAttachmentsComponent } from "../list-view-attachments/list-view-attachments.component";
import { DownloadFileDialogComponent } from "app/common/decision-dialog/download-file-dialog.component";
import { User } from "app/users";
import {
  MESSAGE_DOCUMENT_TYPE,
  NewMessageDialogComponent,
  ATTACHMENT_SUBTYPE
} from "app/common/send-message-dialog/send-message-dialog.component";

@Component({
  selector: "app-list-view-item",
  templateUrl: "./list-view-item.component.html",
  styleUrls: ["./list-view-item.component.scss"]
})
export class ListViewItemComponent extends SubscribingComponent implements OnInit, AfterViewInit {
  @Input() openedItems: boolean[];
  @Input() index: number;
  @Input() statement: Statement;
  @Input() hideEditing = false;
  @Input() hideRemoving = false;
  @Input() hideDecision = false;
  @Input() hideInvoice = false;
  @Input() hidePdf = false;
  @Input() showEditButton = false;
  @Input() labelList: CustomLabels[] = [];
  @Output() deleteStatement = new EventEmitter<void>();
  @Output() downloadPdf = new EventEmitter<void>();
  @Output() downloadInvoice = new EventEmitter<void>();
  @Output() showDownloadDialog = new EventEmitter<void>();
  @Output() showDecision = new EventEmitter<void>();
  @Output() editButton = new EventEmitter<void>();
  @Output() cloneStatement = new EventEmitter<void>();
  @Output() importStatement = new EventEmitter<void>();

  @ViewChildren("statusItem") statusItem: QueryList<ElementRef>;

  public isBusiness = false;
  public isTaxAdviser = false;
  public numberOfFilesToDownload = 0;
  private attachmentsDialogComponent = ListViewAttachmentsComponent;
  public can_send_elster_messages = false;
  public isMasterAccount = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private store: Store<State>
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(getUserType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userType: string) => {
        if (userType) {
          this.isTaxAdviser = userType === "tax_adviser";
          this.isBusiness = userType === "business";
        }
      });

    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          this.isMasterAccount = user.isMasterAccount;
          this.can_send_elster_messages = user.can_send_elster_messages;
        }
      });
  }

  ngAfterViewInit() {
    this.checkStatusItemValueWidth();
  }

  public displayDownloadsButton(): boolean {
    const currentView = this.router.url.split("/").pop();

    switch (currentView) {
      case "thank-you":
        return this.statement.isPaid || this.statement.statusPaidReady();
      default:
        return (
          this.statement.isSent() ||
          this.statement.isApproved ||
          this.statement.isForClientApproval ||
          this.statement.isReportedErrors
        );
    }
  }

  openRefundDetailsDialog() {
    const dialogRef = this.dialog.open(RefundDialogComponent);
    dialogRef.componentInstance.statement = this.statement;
  }

  emitDownloadPdf() {
    this.downloadPdf.emit();
  }

  emitDownloadInvoice() {
    this.downloadInvoice.emit();
  }

  emitShowDecision() {
    this.showDecision.emit();
  }

  emitDeleteStatement() {
    this.deleteStatement.emit();
  }

  emitOnEditButton() {
    this.editButton.emit();
  }

  emitShowDownloadDialog() {
    this.showDownloadDialog.emit();
  }

  getRefund(): string {
    let plusSign = "";
    const euroSign = "€";
    const returnRefund = Math.abs(this.statement.estimatedRefund);

    if (this.statement.estimatedRefund > 0) {
      plusSign = "+";
    } else if (this.statement.estimatedRefund < 0) {
      plusSign = "-";
    }

    return `${plusSign} ${euroSign} ${returnRefund.toFixed(2)}`;
  }

  getPayment(): string {
    let paymentAmount = 0;
    if (this.statement.paymentAmount) paymentAmount = Number(this.statement.paymentAmount);
    else return "-";

    const euroSign = "€";
    return `${euroSign} ${paymentAmount.toFixed(2)}`;
  }

  editStatement() {
    let urlPath = "";
    let url = "";
    if (!this.isTaxAdviser) {
      if (this.isBusiness) urlPath = this.statement.isPaid ? "thank-you" : `employer/${this.statement.employers[0].id}`;
      else urlPath = this.statement.isPaid ? "thank-you" : `b2c/tax-card`;
      urlPath = this.statement.isPaid && this.statement.serviceType === PackageOptions.tax_adviser ? "finish" : urlPath;

      url = `new/statement/${this.statement.id}/${urlPath}`;
    } else {
      const employerId =
        this.statement.employers && this.statement.employers.length ? this.statement.employers[0].id : 0;
      if (this.isBusiness) urlPath = this.statement.isPaid ? `employer/${employerId}` : "dashboard";
      else urlPath = this.statement.isPaid ? `b2c/tax-card` : "dashboard";
      url = `new/statement/${this.statement.id}/${urlPath}`;
    }

    this.router.navigate([url]);
  }

  public showAttachments() {
    const refDialog = this.dialog.open(this.attachmentsDialogComponent, {
      panelClass: ["attachments_dialogbox"],
      backdropClass: "attachments_dialogbox_backdrop",
      data: { statement: this.statement }
    });

    refDialog.componentInstance.quantity.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: number) => {
      this.statement.numberOfAttachments = this.statement.numberOfAttachments + value;
    });
  }

  public emitCloneStatement() {
    this.cloneStatement.emit();
  }

  public emitImportStatement() {
    this.importStatement.emit();
  }

  public getDashboardStatus() {
    return `DASHBOARD.STATUSES.${this.statement.dashboardStatus}`;
  }

  public getDashboardFinishPrecent() {
    let percent = 0;

    if (this.statement.status === "on_hold") {
      percent = 0;
    } else if (
      this.statement.status === "created" ||
      this.statement.status === "processing" ||
      this.statement.status === "updated"
    ) {
      if (this.statement.stageVerification) percent = 80;
      else if (this.statement.stageDeductionInfo) percent = 75;
      else if (this.statement.stagePackageSelection) percent = 70;
      else if (this.statement.stageChildrenInfo) percent = 65;
      else if (this.statement.stagePersonalInfo) percent = 60;
      else if (this.statement.stageAbroadIncome) percent = 50;
      else if (this.statement.stageSteuerAdditionalOption) percent = 45;
      else if (this.statement.stageAdditionalIncome) percent = 40;
      else if (this.statement.stageEmployers) percent = 30;
    } else if (this.statement.status === "ready") {
      percent = 85;
    } else if (this.statement.status === "paid") {
      percent = 90;
    } else if (this.statement.status === "paid_ready") {
      percent = 95;
    } else if (this.statement.status === "sent") {
      percent = 100;
    }

    return percent;
  }

  private checkStatusItemValueWidth() {
    if (this.statusItem !== undefined && this.statusItem !== null) {
      const item = this.statusItem.toArray()[0].nativeElement;
      const itemWidth = item.offsetWidth;
      const itemChild = item.querySelector(".list-item__col-value");
      const itemChildText = item.querySelector("b");

      if (itemChildText && itemChild) {
        const itemChildWidth = itemChild.offsetWidth;
        const itemChildTextWidth = itemChildText.offsetWidth;

        if (itemChildTextWidth > itemChildWidth) {
          item.classList.add("sm");
        }
      }
    }
  }

  public displayMessageButtons(): boolean {
    if (this.isTaxAdviser) return this.statement.isSent();

    return this.statement.isSent() && this.can_send_elster_messages;
  }

  public displayMessageBellege(): boolean {
    if (this.isTaxAdviser) return !this.statement.isSent();

    return !this.statement.isSent() && this.can_send_elster_messages;
  }

  public openAppealDecisionDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-new-message-dialog",
      data: {
        statementData: this.statement,
        titleMaxLength: 99,
        textMaxLength: 1500,
        messageType: MESSAGE_DOCUMENT_TYPE.APPEAL,
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.APPEAL.MODAL_TITLE",
          titleLabel: "SEND_MESSAGE_DIALOG.APPEAL.TITLE_LABEL",
          messageLabel: "SEND_MESSAGE_DIALOG.APPEAL.MESSAGE_LABEL",
          addFileButton: "SEND_MESSAGE_DIALOG.APPEAL.ADD_FILE_BUTTON",
          checkboxText: "SEND_MESSAGE_DIALOG.APPEAL.CHECKBOX_TEXT",
          titleTooltip: "SEND_MESSAGE_DIALOG.APPEAL.TOOLTIP.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.APPEAL.TOOLTIP.MESSAGE"
        }
      }
    });
  }

  public openSendNewMessageDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-new-message-dialog",
      data: {
        statementData: this.statement,
        titleMaxLength: 99,
        textMaxLength: 1500,
        messageType: MESSAGE_DOCUMENT_TYPE.MESSAGE,
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.MESSAGE.MODAL_TITLE",
          titleLabel: "SEND_MESSAGE_DIALOG.MESSAGE.TITLE_LABEL",
          messageLabel: "SEND_MESSAGE_DIALOG.MESSAGE.MESSAGE_LABEL",
          addFileButton: "SEND_MESSAGE_DIALOG.MESSAGE.ADD_FILE_BUTTON",
          titleTooltip: "SEND_MESSAGE_DIALOG.MESSAGE.TOOLTIP.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.MESSAGE.TOOLTIP.MESSAGE"
        }
      }
    });
  }

  public openSendAttachmentDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-attachment-dialog",
      data: {
        statementData: this.statement,
        messageType: MESSAGE_DOCUMENT_TYPE.ATTACHMENT,
        subtype: "",
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.ATTACHMENT.MODAL_TITLE",
          addFileButton: "SEND_MESSAGE_DIALOG.ATTACHMENT.ADD_FILE_BUTTON",
          titleTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.MESSAGE"
        }
      }
    });
  }

  public openSendBellegeDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-attachment-dialog",
      data: {
        statementData: this.statement,
        messageType: MESSAGE_DOCUMENT_TYPE.ATTACHMENT,
        subtype: ATTACHMENT_SUBTYPE.BELEGE,
        onlySave: true,
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.ATTACHMENT.BELLEGE_TITLE",
          addFileButton: "SEND_MESSAGE_DIALOG.ATTACHMENT.ADD_FILE_BUTTON",
          sendByEmailText: "SEND_MESSAGE_DIALOG.ATTACHMENT.SEND_BY_EMAIL",
          titleTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.SEND_BELEGE.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.SEND_BELEGE.MESSAGE",
          onResponse: {
            successTitle: "SEND_MESSAGE_DIALOG.SUCCESS.SEND_BELEGE.TITLE",
            successMessage: "SEND_MESSAGE_DIALOG.SUCCESS.SEND_BELEGE.REQUEST"
          }
        }
      }
    });
  }

  public openSendVollmachtDialogComponent() {
    this.dialog.open(NewMessageDialogComponent, {
      panelClass: "send-attachment-dialog",
      data: {
        statementData: this.statement,
        messageType: MESSAGE_DOCUMENT_TYPE.ATTACHMENT,
        subtype: ATTACHMENT_SUBTYPE.VOLMACHT,
        onlySave: true,
        text: {
          modalTitle: "SEND_MESSAGE_DIALOG.ATTACHMENT.VOLMACHT_TITLE",
          addFileButton: "SEND_MESSAGE_DIALOG.ATTACHMENT.ADD_FILE_BUTTON",
          titleTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.SEND_VOLLMACHT.TITLE",
          messageTooltip: "SEND_MESSAGE_DIALOG.ATTACHMENT.TOOLTIP.SEND_VOLLMACHT.MESSAGE",
          onResponse: {
            successTitle: "SEND_MESSAGE_DIALOG.SUCCESS.SEND_VOLLMACHT.TITLE",
            successMessage: "SEND_MESSAGE_DIALOG.SUCCESS.SEND_VOLLMACHT.REQUEST"
          }
        }
      }
    });
  }

  get shouldShowHamburgerMenu(): boolean {
    return this.displayMessageButtons() || this.displayMessageBellege() || this.isMasterAccount;
  }
}
