import { SelectChoiceListInterface } from 'app/core/models/select-choice-list.interface';
import {
  DeductionDisabilityCareTaker
} from 'app/shared/modules/statement-deduction-base/enums/deduction-disability-care-taker.enum';
import {
  DeductionDisabilityCarePflegegrad
} from 'app/shared/modules/statement-deduction-base/enums/deduction-disability-care-pflegegrad.enum';

export class DeductionDictionaryConstant {
  static DISABILITY_CARE_PFLEGEGRAD_CARETAKER_NAMES: SelectChoiceListInterface[] = [
    {
      optionName: 'DEDUCTION.DISABILITY_CARE_PFLEGEGRAD.CARETAKER.SELECT.APPLICANT',
      valueName: DeductionDisabilityCareTaker.APPLICANT
    },
    {
      optionName: 'DEDUCTION.DISABILITY_CARE_PFLEGEGRAD.CARETAKER.SELECT.SPOUSE',
      valueName: DeductionDisabilityCareTaker.SPOUSE
    },
    {
      optionName: 'DEDUCTION.DISABILITY_CARE_PFLEGEGRAD.CARETAKER.SELECT.APPLICANT_AND_SPOUSE',
      valueName: DeductionDisabilityCareTaker.APPLICANT_AND_SPOUSE
    },
  ];

  static DISABILITY_CARE_PFLEGEGRAD_PFLEGEGRAD_NAMES: SelectChoiceListInterface[] = [
    {
      optionName: 'DEDUCTION.DISABILITY_CARE_PFLEGEGRAD.PFLEGEGRAD.SELECT.PFLEGEGRAD_2',
      valueName: DeductionDisabilityCarePflegegrad.PFLEGEGRAD_2
    },
    {
      optionName: 'DEDUCTION.DISABILITY_CARE_PFLEGEGRAD.PFLEGEGRAD.SELECT.PFLEGEGRAD_3',
      valueName: DeductionDisabilityCarePflegegrad.PFLEGEGRAD_3
    },
    {
      optionName: 'DEDUCTION.DISABILITY_CARE_PFLEGEGRAD.PFLEGEGRAD.SELECT.PFLEGEGRAD_4_5',
      valueName: DeductionDisabilityCarePflegegrad.PFLEGEGRAD_4_5
    },
  ];
}
