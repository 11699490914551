import { Injectable } from '@angular/core';
import { AuthHttpService } from '../users';
import { getApiPath } from '../common';
import { map } from 'rxjs/operators';
import { CurrencyConverter } from './currency-converter';
import { Observable } from 'rxjs';

@Injectable()
export class CurrencyConverterService {
  constructor(
    private authHttp: AuthHttpService,
  ) { }

  public getCurrencyConverter(currencyId: number, year: number): Observable<CurrencyConverter> {
    return this.authHttp.get(`${getApiPath()}statements/currency-converter/${currencyId}/${year}/`)
      .pipe(
        map((response: any) => CurrencyConverter.fromResponse(response))
      );
  }
}
