<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close="" tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content pb-1 px-0 px-md-1_5">
      <h1 class="font-size-22 px-1 font-weight-bold mb-1 text-center">{{ 'COOKIES_LAW_DIALOG.TITLE' | translate }}</h1>
      <p class="mb-1_5 px-1 text-center">{{ 'COOKIES_LAW_DIALOG.DESCRIPTION' | translate }}</p>
      <div class="p-1">
        <mat-expansion-panel class="mb-0_5">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'COOKIES_LAW_DIALOG.COOKIES.NECESSARY.TITLE' | translate }}
            </mat-panel-title>
            <mat-panel-description [class]="'justify-content-end mr-1_5'">
              {{ 'COOKIES_LAW_DIALOG.COOKIES.NECESSARY.ALWAYS_ACTIVE' | translate }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>
            {{ 'COOKIES_LAW_DIALOG.COOKIES.NECESSARY.DESCRIPTION' | translate }}
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mb-0_5">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'COOKIES_LAW_DIALOG.COOKIES.ANALYTICS.TITLE' | translate }}
            </mat-panel-title>
            <mat-panel-description [class]="'justify-content-end mr-1_5'">
              <mat-slide-toggle
                (click)="$event.stopPropagation()"
                [(ngModel)]="analyticsEnabled"
              >
              </mat-slide-toggle>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p [innerHTML]="'COOKIES_LAW_DIALOG.COOKIES.ANALYTICS.DESCRIPTION' | translate"></p>
          <p [innerHTML]="'COOKIES_LAW_DIALOG.COOKIES.ANALYTICS.LIST' | translate"></p>
        </mat-expansion-panel>
<!--        <mat-expansion-panel class="mb-0_5">-->
<!--          <mat-expansion-panel-header>-->
<!--            <mat-panel-title>-->
<!--              {{ 'COOKIES_LAW_DIALOG.COOKIES.TARGETING.TITLE' | translate }}-->
<!--            </mat-panel-title>-->
<!--            <mat-panel-description [class]="'justify-content-end mr-1_5'">-->
<!--              <mat-slide-toggle-->
<!--                (click)="$event.stopPropagation()"-->
<!--                [(ngModel)]="targetingEnabled"-->
<!--              >-->
<!--              </mat-slide-toggle>-->
<!--            </mat-panel-description>-->
<!--          </mat-expansion-panel-header>-->
<!--          <p>-->
<!--            {{ 'COOKIES_LAW_DIALOG.COOKIES.TARGETING.DESCRIPTION' | translate }}-->
<!--          </p>-->
<!--        </mat-expansion-panel>-->
      </div>
      <div class="d-flex justify-content-between flex-wrap">
        <app-button-primary
          [class]="'flex-grow-1 mb-1 mx-1'"
          (clickEmitter)="onClickDisable()"
          [isSmallMobile]="true"
        >
          {{ 'COOKIES_LAW_DIALOG.BUTTON.DISABLE' | translate }}
        </app-button-primary>
        <app-button-primary
          [class]="'flex-grow-1 mb-1 mx-1'"
          (clickEmitter)="onClickAllow()"
          [isSmallMobile]="true"
        >
          {{ 'COOKIES_LAW_DIALOG.BUTTON.ALLOW' | translate }}
        </app-button-primary>
        <app-button-primary
          [class]="'flex-grow-1 mb-1 mx-1'"
          (clickEmitter)="onClickSave()"
          [isSmallMobile]="true"
        >
          {{ 'COOKIES_LAW_DIALOG.BUTTON.SAVE' | translate }}
        </app-button-primary>
      </div>
    </div>
</div>
