import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { ErrorMessage } from "app/core/form-validator/validatorInterface";
import { INPUT_TYPE } from "app/modules/statement/components/additional-income-form/additional-income-form-freelance/forms-elements/income-input/income-input.component";

@Component({
  selector: "app-add-value-list",
  templateUrl: "./add-value-list.component.html",
  styleUrls: ["./add-value-list.component.scss"]
})
export class AddValueList {
  @Input() list: [];
  @Input() title: String = "";
  @Input() valueListViewTemplate: TemplateRef<any> = null;
  @Input() addValueViewTemplate: TemplateRef<any> = null;
  @Input() titleTooltip: String = null;
  @Input() messageTooltip: String = null;
  @Input() tooltipContentRef: TemplateRef<any> = null;
  @Input() showAddValueViewTemplate: boolean = false;
  @Input() epValidator: ErrorMessage[] = [];
  @Input() epValidatorField: string = "";
  @Input() showAddButton: boolean = true;
  @Input() buttonText = "PERIOD.ADD";
  @Input() inputType: INPUT_TYPE = INPUT_TYPE.INCOMES;
  @Input() isLump: boolean = false;
  @Output() addNewValue: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  public handleAddAction() {
    // this.showAddValueViewTemplate = true;
    this.addNewValue.emit();
  }

  public displayTooltip(): boolean {
    return !!this.titleTooltip || !!this.messageTooltip || !!this.tooltipContentRef;
  }

  public displayAddButton(): boolean {
    if (this.showAddButton && !this.showAddValueViewTemplate) return true;

    return false;
  }

  public handleIsDisabledButton() {
    if (this.inputType === INPUT_TYPE.COSTS && this.isLump && !!this.list.length) return true;

    return false;
  }
}
