import { Observable, of as observableOf } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { AuthHttpService } from '../users/auth-http.service';
import { Child } from './child';
import { getApiPath } from '../common';

@Injectable()
export class ChildrenService {

  constructor(private authHttp: AuthHttpService) { }

  getChildren(statementId: number): Observable<Child[]> {
    return this.authHttp.get(`${getApiPath()}statements/${statementId}/children/`).pipe(
      map((response: any) => {
        // const responseJson = response;
        let childs: Child[] = [];
        response.forEach(item => {
          childs.push(Child.fromResponse(item));
        });
        return childs;
      }),
      catchError(_ => observableOf([])));
  }

  getChild(statementId: number, childId: number): Observable<Child> {
    return this.authHttp.get(`${getApiPath()}statements/${statementId}/children/${childId}/`).pipe(
      map((response: any) => Child.fromResponse(response)));
  }

  createChild(statementId: number): Observable<Child> {
    return this.authHttp.post(`${getApiPath()}statements/${statementId}/children/`, {}).pipe(
       map((response: any) => Child.fromResponse(response)),
      catchError((error: any) => observableOf(error)));
  }

  updateChild(statementId: number, childId: number, data): Observable<Child> {
    return this.authHttp.patch(`${getApiPath()}statements/${statementId}/children/${childId}/`, data)
      .pipe(map((response: any) => Child.fromResponse(response)));
  }

  updateChildren(statementId: number, data): Observable<Child[]> {
    return this.authHttp.patch(`${getApiPath()}statements/${statementId}/children/`, data)
      .pipe(
        map((response: any) => {
          return response.map(Child.fromResponse);
        })
      );
  }

  deleteChild(statementId: number, childId: number) {
    return this.authHttp.remove(`${getApiPath()}statements/${statementId}/children/${childId}/`);
  }

  deleteMultipleChildren(statementId: number, childIds: number[]): Observable<any> {
    const payload = {
      child_ids: childIds
    };
    return this.authHttp.remove(`${getApiPath()}statements/${statementId}/children/`, payload);
  }
}
