import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StatementService } from '../statements/statement.service';
import { SubscribingComponent } from './subscribing-component';
import { Store } from '@ngrx/store';
import { getUserType, State } from '../reducers';
import { takeUntil } from 'rxjs/operators';

declare const window;

export class Events {
  /**
   * standard events
   */
  static sign_up = 'sign_up';
  /**
   * purchasing events
   */
  static purchase = 'purchase_complete';
  static purchase_free = 'purchase_complete_free';
  static purchase_standard = 'purchase_complete_premium';
  static purchase_premium = 'purchase_complete_steuer';
  static click_downgrade_standard = 'click_downgrade_standard';
  static click_upgrade_premium = 'click_upgrade_steuer';
  static click_payment = 'click_payment';
}

/**
 * @deprecated
 * Probably it is unnecessary service, should be removed.
 */
@Injectable()
export class FirebaseEventsService extends SubscribingComponent {
  private saveFlag: Events[] = [
    Events.purchase,
    Events.purchase_free,
    Events.purchase_standard,
    Events.purchase_premium
  ]

  private deviceInfo: any = null;
  private isMobile: boolean;
  private isTablet: boolean;
  private isDesktop: boolean;
  private isBusiness: boolean = false;

  constructor(
    // private angularFireAnalytics: AngularFireAnalytics,
    private deviceDetectorService: DeviceDetectorService,
    private statementService: StatementService,
    private store: Store<State>
  ) {
    super();

    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.isMobile = this.deviceDetectorService.isMobile();
    this.isTablet = this.deviceDetectorService.isTablet();
    this.isDesktop = this.deviceDetectorService.isDesktop();

    this.store.select(getUserType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userType: string) => this.isBusiness = userType ? userType === 'business' : false)
  }

  private isNotIndividualMessage = (event?: string) => {
    if (!environment.production) {
      console.log(`[firebase]: This is not an individual account! Event: ${event}`)
    }
  }

  private setFlag(event: string, statement_id: number): void {
    const logSuccess = () => {
      if (!environment.production) {
        console.log(`[firebase] A flag has been set for the event ${event}`)
      }
    }

    if (statement_id) {
      if (!this.saveFlag.includes(event)) return;
      else {
        const body =
            event === Events.purchase ? { event_purchase_complete: true } :
                [Events.purchase_free, Events.purchase_standard, Events.purchase_premium].includes(event) ?
                    { event_purchase_complete_type: true } : null;

        if (body === null) return;
        else this.statementService.events.update(statement_id, body).pipe(takeUntil(this.ngUnsubscribe)).subscribe(logSuccess, console.error)
      }
    }
  }

  /**
   * Logs web firebase events.
   * @param name Firebase event name. The suffix "_web" is added automatically.
   * @param params
   * @param statement_id
   */
  public webEvent(name: string, params: any = null, statement_id: number = null) {
    if (!this.isBusiness) {
      if (!environment.production)
        console.log('[desktop] firebase event: ', `${name}_web`, '\nparams: ', params)
    } else this.isNotIndividualMessage(`${name}_web`)
  }

  /**
   * This function pass Firebase event to native code
   * @param {string} name
   * @param {object} params
   * @param statementId
   */
  logEvent(name, params, statementId = null) {
    return;

    if (!this.isBusiness) {
      if (!environment.production)
        console.log('[mobile] firebase event: ', name, '\nparams: ', params)

      if (this.isMobile || this.isTablet) {
        if (!name || !environment.production) {
          return;
        }

        this.setFlag(name, statementId)

        if (window.AnalyticsWebInterface) {
          // Call Android interface
          window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
        } else if (window.webkit
          && window.webkit.messageHandlers
          && window.webkit.messageHandlers.firebase) {
          // Call iOS interface
          const message = {
            command: 'logEvent',
            name: name,
            parameters: params
          };
          window.webkit.messageHandlers.firebase.postMessage(message);
        } else {
          // No Android or iOS interface found
          console.log("No native APIs found.");
        }
      }
    } else this.isNotIndividualMessage(name)
  }
}
