import { ALLOWED_EXTENSION } from 'app/common';

export class FileHelper {
  static isValidFile(file: File, allowedExtensions = ALLOWED_EXTENSION): boolean {
    const ext = file.name.split('.').pop().toLocaleLowerCase();
    return allowedExtensions.includes(ext);
  }

  static openFile(url: string, target: string = '_blank'): void {
    window.open(url, target);
  }
}
