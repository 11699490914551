import { filter, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { SubscribingComponent } from 'app/common';
import { User } from 'app/users';
import { BuyCreditsDialogComponent } from '../buy-credits-dialog/buy-credits-dialog.component';
import { UserDataService } from 'app/users';
import { LOAD_USER_DATA } from 'app/reducers/actions/user';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent extends SubscribingComponent implements OnInit {
  @Input() batchId: number;
  public user: User;

  constructor(
    private store: Store<State>,
    private dialog: MatDialog,
    private userDataService: UserDataService,
  ) {
    super();
  }

  ngOnInit() {
    this.refreshUserProfile();
  }

  public refreshUserProfile(): void {
    this.userDataService.getUserData().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.store.dispatch({type: LOAD_USER_DATA, payload: user});

        this.user = user;
      });
  }

  public buyCredits(): void {
    const dialogRef = this.dialog.open(BuyCreditsDialogComponent);
    dialogRef.afterClosed()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((shouldUpdate) => !!shouldUpdate)
      ).subscribe(() => {
      setTimeout(() => {
        this.refreshUserProfile();
      }, 2000);
    });

    if (this.batchId) {
      dialogRef.componentInstance.batchId = this.batchId;
    }
  }
}
