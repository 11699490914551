import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";

@Component({
  selector: "app-form-list",
  templateUrl: "./form-list.component.html",
  styleUrls: ["./form-list.component.scss"]
})
export class FormListComponent {
  @Input() formTemplateRef: TemplateRef<any>;
  @Input() beforeTemplateRef: TemplateRef<any>;
  @Input() isRemoveButtonEnabled = true;
  @Input() isAddButtonEnabled = true;
  @Input() isAddButtonDisabled = false;
  @Input() addButtonTitle = "COMMON.ADD";
  @Input() listIsEmptyMessage = "COMMON.EMPTY_LIST";
  @Input() itemTitle: string;
  @Input() list: Array<any>;
  @Output() addButtonClickEmitter = new EventEmitter<void>();
  @Output() removeButtonClickEmitter = new EventEmitter<number>();
  @Input() deductionInfoText: string;

  constructor() {}

  public trackBy(index: number): number {
    return index;
  }
}
