export class AutofocusHelper {
  public static autofocusInputMainContent(): void {
    AutofocusHelper.autofocus({query: '.main-content__container input.form-input__input'});
  }

  public static autofocusMatSelectMainContent(): void {
    AutofocusHelper.autofocus({query: '.main-content__container mat-select'});
  }

  public static autofocus(options: {query?: string, maxTimeWait?: number} = {}): void {
    const query = options.query || '.main-content__container input.form-input__input', maxTimeWait = options.maxTimeWait || 2000;
    const intervalMs = 50;
    const maxIntervalCount = maxTimeWait / intervalMs;
    let intervalCount = 0;

    const interval = setInterval(() => {
      if (intervalCount >= maxIntervalCount) {
        clearInterval(interval);
      }

      if (document.querySelector(query)) {
        autofocus();
      }
      intervalCount++;
    }, 50);

    const autofocus = () => {
      const el: HTMLElement = document.querySelector(query);

      if (el) {
        el.focus();
      }

      clearInterval(interval);
    };
  }
}
