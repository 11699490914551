import { finalize, takeUntil } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { getUser, State } from "app/reducers";
import { SHOW_SNACKBAR } from "app/reducers/actions/snackbar";
import { UPDATE_REFUND_DETAILS } from "app/reducers/actions/refund-details";
import { Statement } from "../statement";
import { deductionsFlags } from "app/shared/modules/statement-deduction-base/models/deductions";
import { StatementService } from "../statement.service";
import { SnackbarConfig, SubscribingComponent } from "app/common";
import { SET_DEDUCTIONS_INFO, SET_SPOUSE_DEDUCTIONS_INFO } from "app/reducers/actions/deductions-info";
import { HideViewSpinnerAction, ShowViewSpinnerAction } from "app/reducers/actions/loader";
import { FirebaseEventsService } from "app/common/firebase-events.service";
import { ErrorMessage } from "app/core/form-validator/validatorInterface";
import { TranslateService } from "@ngx-translate/core";
import { WorkflowControllerService } from "app/core/workflow/workflow-controller/workflow-controller.service";
import { User } from "app/users";
import { PersonalInfoOnLoginInterface } from "app/users/personal-info-on-login.interface";
import { combineLatest } from "rxjs";
import {
  ToolsBarButtonCommonTitle,
  ToolsBarButtonInterface,
  ToolsBarButtonType
} from "app/shared/components/tools-bar/tools-bar.component";
import { ValidateFieldV2Service } from "app/core/form-validator/validate-field-v2.service";
import { VALIDATOR_FIELD_NAMES_PERSONAL_INFO } from "app/core/form-validator/validator-field-names/validator-field-names-personal-info";
import { StatementErrors } from "app/modules/statement/models/statement/statement.errors.interface";

@Component({
  selector: "app-personal-info",
  templateUrl: "./personal-info.component.html",
  styleUrls: ["./personal-info.component.scss"]
})
export class PersonalInfoComponent extends SubscribingComponent implements OnInit {
  public statementId: number;
  public spouseId: number;
  public statement: Statement;
  public baseYear: number;
  public formStatement: Statement;
  public errors: StatementErrors;
  public hideNotification: boolean;
  public isBusiness = false;
  public epValidator: ErrorMessage[] = [];
  public contentLoaded = false;
  public buttons: ToolsBarButtonInterface[] = [
    {
      type: ToolsBarButtonType.back,
      label: ToolsBarButtonCommonTitle.back,
      action: this.goBack.bind(this)
    },
    {
      type: ToolsBarButtonType.proceed,
      label: ToolsBarButtonCommonTitle.proceed,
      action: this.proceed.bind(this)
    }
  ];
  private user: User;
  private loadingStatementData = true;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private firebaseEventsService: FirebaseEventsService,
    private translateService: TranslateService,
    private validateFieldV2Service: ValidateFieldV2Service,
    public workFlowController: WorkflowControllerService
  ) {
    super();
  }

  ngOnInit() {
    this.clearErrors();

    this.store.dispatch(new ShowViewSpinnerAction());

    combineLatest([this.route.params, this.store.select(getUser)])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([params, user]) => {
        if (user && params) {
          this.user = user;
          this.isBusiness = this.user.isBusiness;

          this.statementId = parseInt(params["statementId"], 10);
          this.spouseId = parseInt(params["spouseId"], 10);

          if (this.statementId) {
            this.getStatementData();
          }
        }
      });
  }

  public copyAddressToSpouse(): void {
    const spouse = this.statement.spouse;
    spouse.street = this.statement.street;
    spouse.additionalAddressInfo = this.statement.additionalAddressInfo;
    spouse.houseNumberWithSupplement = this.statement.houseNumberWithSupplement;
    spouse.postalCode = this.statement.postalCode;
    spouse.city = this.statement.city;
    spouse.country = this.statement.country;
  }

  public showCopyNotification(): boolean {
    return (
      this.spouseId &&
      this.statement &&
      this.statement.filedTogether &&
      !(
        this.statement.spouse.street ||
        this.statement.spouse.additionalAddressInfo ||
        this.statement.spouse.houseNumberWithSupplement ||
        this.statement.spouse.postalCode ||
        this.statement.spouse.city
      )
    );
  }

  private hasApplicantCheckedDeduction(): boolean {
    return deductionsFlags.some((flag) => this.statement[flag]);
  }

  private hasSpouseCheckedDeduction(): boolean {
    return this.statement.spouse && deductionsFlags.some((flag) => this.statement.spouse[flag]);
  }

  private handleSpouseDeductionsFlags(): void {
    this.statement.spouse.resetDeductionsFlags();
    this.store.dispatch({
      type: SET_SPOUSE_DEDUCTIONS_INFO,
      payload: "DEDUCTION_INFO.RESET_FLAGS"
    });
  }

  private handleDeductionsFlags(): void {
    this.statement.resetDeductionsFlags();
    this.store.dispatch({
      type: SET_DEDUCTIONS_INFO,
      payload: "DEDUCTION_INFO.RESET_FLAGS"
    });
  }

  private updateStatement(goForward = false, goBack = false, responseToSave = false, updateRefund = false): void {
    const type = this.spouseId ? (this.statement.filedTogether ? "fullPersonalInfo" : "minimalPersonalInfo") : "";
    const defaultValue = responseToSave ? undefined : "";
    let addDeductionsFlag = false;
    if (this.statement.year !== this.baseYear && this.hasApplicantCheckedDeduction()) {
      this.handleDeductionsFlags();
      addDeductionsFlag = true;
    }
    if (this.statement.year !== this.baseYear && this.hasSpouseCheckedDeduction()) {
      this.handleSpouseDeductionsFlags();
      addDeductionsFlag = true;
    }
    if (!this.statement.emailAddress) {
      this.statement.emailAddress = this.user.email;
    }

    const toResponse = this.statement.personalInfoToResponse(defaultValue, type, addDeductionsFlag);
    this.contentLoaded = false;
    this.statementService
      .updateStatement(this.statement.id, toResponse)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.contentLoaded = true;
        })
      )
      .subscribe({
        next: (response: Statement) => {
          Object.assign(this.statement, response);
          this.onDataUpdateSuccess(goForward, goBack, updateRefund);
        },
        error: (error: any) => {
          this.contentLoaded = true;
          const errors = error.error;
          this.errors = errors;

          if (this.spouseId && errors && errors["spouses"] && errors["spouses"].length) {
            this.errors = errors["spouses"][0];
          }
          console.error("errors: ", this.errors);
        }
      });
  }

  private onDataUpdateSuccess(goForward = false, goBack = false, updateRefund = false): void {
    this.clearErrors();
    this.store.dispatch({
      type: SHOW_SNACKBAR,
      payload: new SnackbarConfig("SNACKBAR.STATEMENT_UPDATED")
    });

    if (goForward) {
      this.firebaseEventsService.logEvent("personal_data_success", null, this.statement.id);
      this.firebaseEventsService.webEvent("personal_data_success", null, this.statement.id);

      this.workFlowController.goToTheNextStep();
      return;
    }
    if (goBack) {
      this.workFlowController.goToThePreviousStep();
      return;
    }
    if (updateRefund) {
      this.store.dispatch({ type: UPDATE_REFUND_DETAILS });
    }

    this.contentLoaded = true;
  }

  public get isInitialized(): boolean {
    return !this.loadingStatementData;
  }

  public get isLoading(): boolean {
    return !this.isInitialized || !this.contentLoaded;
  }

  private clearErrors(): void {
    this.errors = {};
  }

  private goBack(): void {
    this.clearErrors();
    this.formValidation();
    if (this.epValidator.length > 0) {
      return;
    }

    this.updateStatement(false, true, true, true);
  }

  private proceed(): void {
    this.clearErrors();
    this.formValidation();
    if (this.epValidator.length > 0) {
      return;
    }

    this.firebaseEventsService.logEvent("click_tax_card", null, this.statementId);

    this.updateStatement(true, false);
  }

  private formValidation(): void {
    this.checkIfApplicantAndSpouseHaveDifferentIdNumber();

    let validationFieldKeys = Object.values(VALIDATOR_FIELD_NAMES_PERSONAL_INFO);

    if (this.spouseId) {
      validationFieldKeys = validationFieldKeys.filter(
        (x) =>
          [VALIDATOR_FIELD_NAMES_PERSONAL_INFO.tax_authority, VALIDATOR_FIELD_NAMES_PERSONAL_INFO.tax_number].indexOf(
            x
          ) === -1
      );
    }

    this.epValidator = [
      ...this.validateFieldV2Service.validateFieldAndGetMessageV2(this.formStatement, validationFieldKeys)
    ];
  }

  private checkIfApplicantAndSpouseHaveDifferentIdNumber(): void {
    if (
      this.formStatement.isSpouse() &&
      this.formStatement.identifikationsnummer &&
      this.statement.identifikationsnummer &&
      this.formStatement.identifikationsnummer === this.statement.identifikationsnummer
    ) {
      this.errors.identifikationsnummer = [
        this.translateService.instant("SPOUSE_IDENTYFICATIONSNUMMBER_ERROR.ERROR_MESSAGE")
      ];
    } else {
      this.errors.identifikationsnummer = [];
    }
  }

  private getStatementData(): void {
    this.loadingStatementData = true;
    this.statementService
      .getStatement(this.statementId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.loadingStatementData = false;
          this.contentLoaded = true;
          this.store.dispatch(new HideViewSpinnerAction());
        })
      )
      .subscribe({
        next: (response: Statement) => {
          this.statement = response;
          this.validateFieldV2Service._init(this.statement);
          this.workFlowController.init(this.statement);

          if (!this.statement.country) {
            this.statement.country = "DE";
          }
          if (this.statement.spouse && !this.statement.spouse.country) {
            this.statement.spouse.country = "DE";
          }

          if (sessionStorage.getItem("personalInfoOnLogin")) {
            const personalInfoOnLogin: PersonalInfoOnLoginInterface = JSON.parse(
              sessionStorage.getItem("personalInfoOnLogin")
            );

            if (!this.statement.firstName && personalInfoOnLogin.emailAddress === response.emailAddress) {
              this.statement.firstName = personalInfoOnLogin.firstname;
            }
          }

          this.baseYear = this.statement.year;
          this.formStatement = this.spouseId ? this.statement.spouse : this.statement;
        },
        error: () => {
          this.router.navigate(["/new"]);
        }
      });
  }
}
