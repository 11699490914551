import { Component, Input } from '@angular/core';
import { NumberFormatHelper } from 'app/core/helpers/number-format.helper';

@Component({
  selector: 'app-tooltip-template-package-info',
  templateUrl: './tooltip-template-package-info.component.html',
  styleUrls: ['./tooltip-template-package-info.component.scss']
})

export class PackageInfoTooltipTemplate{
  @Input() isPremiumPackage : boolean = false;
  @Input() isStandardPackage : boolean = false;
  @Input() isPackageBenefits : boolean = false;
  @Input() isHowDoesItWork : boolean = false;
  @Input() standardAmount : number = 0;
  @Input() premiumAmount : number = 0;
  @Input() currencySymbol : number = 0;

  public numberFormatHelper = NumberFormatHelper;

  constructor() {}
}
