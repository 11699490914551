import { finalize, takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { StatementService } from '../statement.service';
import { Statement } from '../statement';
import { SubscribingComponent, saveFile } from 'app/common';
import { TranslateService } from '@ngx-translate/core';
import { UserDataService } from 'app/users';
import { AcceptAtthorneyDialogComponent } from 'app/common/accept-atthorney-dialog/accept-atthorney-dialog.component';
import { FirebaseEventsService } from 'app/common/firebase-events.service';
import {WorkflowControllerService} from 'app/core/workflow/workflow-controller/workflow-controller.service';
import { ToolsBarButtonInterface } from 'app/shared/components/tools-bar/tools-bar.component';
import { DateHelper } from 'app/core/helpers/date.helper';

@Component({
  selector: 'app-vollmacht-authorization',
  templateUrl: './vollmacht-authorization.component.html',
  styleUrls: ['./vollmacht-authorization.component.scss']
})
export class VollmachtAuthorizationComponent extends SubscribingComponent implements OnInit {
  public statementId: number;
  public statement: Statement;
  public loadingData = false;
  public contentLoaded = false;
  public showAlert = false;
  public acceptThePowerOfAttorney = false;
  public buttons: ToolsBarButtonInterface[] = [
    {
      type: 'proceed',
      label: 'VOLLMACHTAUTHORIZATION.BUTTON_NAV',
      action: this.acceptVollmacht.bind(this),
      disabled: () => this.disableProceed()
    }
  ];

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dialog: MatDialog,
    private userDataService: UserDataService,
    private firebaseEventsService: FirebaseEventsService,
    public workFlowController: WorkflowControllerService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.statementId = parseInt(params['statementId'], 10);
        this.getData();
      });
  }

  public downloadPdf() {
    let downloadingPdf = true;
    this.statementService.downloadVollmachtPdf(this.statementId).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => downloadingPdf = false))
      .subscribe(
        (file: Blob) => saveFile(file, `vollmacht.pdf`),
      );
  }

  public disableProceed(): boolean {
    if (!this.statement.canSendWithoutResignationFromClaim) {
      return !this.acceptThePowerOfAttorney;
    }

    return false;
  }

  public clickedAcceptAtthorney(): void {
    this.acceptThePowerOfAttorney = !this.acceptThePowerOfAttorney;
  }

  public displaypopup(): void {
    const refDialog = this.dialog.open(AcceptAtthorneyDialogComponent);
    refDialog.componentInstance.statement = this.statement;
  }

  private acceptVollmacht(): void {
    this.firebaseEventsService.logEvent('click_accept_proxy', null, this.statementId);

    const data = {
      vollmacht_signed: true,
      vollmacht_authorization_datetime: DateHelper.getFormatted('', 'YYYY-MM-DD HH:mm:ss'),
      is_informed_that_cannot_resign_from_claim: true
    };

    this.contentLoaded = false;

    const spouse: Statement = this.statement.spouse;
    if (spouse) {
      Object.assign(data, { spouses: [{ id: spouse.id }] });
    }

    const redirect = () => {
      this.workFlowController.goToTheNextStep();
    };

    const error = (errorStatement: Error): void => {
      this.contentLoaded = true;
      console.log(errorStatement);
    };

    const response = (responseStatement: Statement) => {
      Object.assign(this.statement, responseStatement);

      const handle = () => this.statementService.checkOnfidoVollmacht(this.statementId)
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe(redirect, error);

      this.statementService.notifyUser(this.statement.id).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(handle, error);
    };

    this.statementService.updateStatement(this.statement.id, data).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response, error);
  }

  private getData(): void {
    this.loadingData = true;
    this.statementService.getStatement(this.statementId).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.loadingData = false))
      .subscribe((response: Statement) => {
        this.contentLoaded = true;
        this.statement = response;
        this.workFlowController.init(this.statement);
      }, () => {
        this.contentLoaded = true;
        this.router.navigate(['/new']);
      });
  }
}
