<div class="cookies-law-banner d-flex flex-wrap align-items-center justify-content-center pl-1_5 pt-1_5 pb-0_5 pr-1" *ngIf="cookiesLawService.isBannerRequired | async">
  <div class="text-center">
    <div class="pb-1 pr-0_5" [innerHTML]="'COOKIES_LAW_BANNER.TITLE' | translate">
      <!-- {{ 'COOKIES_LAW_BANNER.TITLE' | translate }}qwe -->
      &nbsp;
      <app-button-link
        (clickEmitter)="onClickMore()"
        [isSmall]="true"
        [class]="'d-inline-flex'"
      >
        {{ 'COOKIES_LAW_BANNER.BUTTON.MORE_ABOUT' | translate }}
      </app-button-link>&nbsp;
    </div>
  </div>
  <div class="d-flex flex-wrap align-items-center justify-content-center">
    <app-button-link
      (clickEmitter)="onClickCustomize()"
      [isSmall]="true"
      [class]="'mr-0_5 flex-grow-1'"
      [extraClasses]="'mb-1'"
    >
      {{ 'COOKIES_LAW_BANNER.BUTTON.CUSTOMIZE' | translate }}
    </app-button-link>
    <div class="d-flex flex-wrap align-items-center justify-content-center flex-grow-1">
      <app-button-primary
        (clickEmitter)="onClickDisable()"
        [isSmall]="true"
        [class]="'mr-0_5 flex-grow-1'"
        [extraClasses]="'mb-1'"
        [attr.data-cy]="'btnCookiesLawBannerDisable'"
      >
        {{ 'COOKIES_LAW_BANNER.BUTTON.DISABLE' | translate }}
      </app-button-primary>
      <app-button-primary
        (clickEmitter)="onClickAllow()"
        [isSmall]="true"
        [class]="'mr-0_5 flex-grow-1'"
        [extraClasses]="'mb-1'"
        [attr.data-cy]="'btnCookiesLawBannerAllow'"
      >
        {{ 'COOKIES_LAW_BANNER.BUTTON.ALLOW' | translate }}
      </app-button-primary>
    </div>
  </div>

  <div class="aside-menu__stages aside-menu__info-container">
    <a class="aside-menu__stage" (click)="showImpressumDialog()">Impressum</a>
    <span class="aside-menu_separator"></span>
    <a class="aside-menu__stage" (click)="showPrivacyPolicyDialog()">{{ 'COMMON.PRIVACY_POLICY' | translate }}</a>
    <span class="aside-menu_separator"></span>
    <a class="aside-menu__stage" (click)="showTermsAndConditionsDialog()">{{ 'COMMON.TERMS_AND_CONDITIONS_NOT_LOGGED' | translate }}</a>
  </div>
</div>
