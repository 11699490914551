import { ErrorMessage, ErrorMessageContent } from 'app/core/form-validator/validatorInterface';
import { RecognizerHelper } from 'app/core/helpers/recognizer.helper';

export class ErrorHandleHelper {
  static getErrorMessage(errors: any): string {
    if (Array.isArray(errors)) {
      let message = '';

      errors.forEach(el => {
        if (RecognizerHelper.isString(el)) {
          if (message) {
            message += ' ';
          }

          message += el;
        }
      });

      return message;
    }

    return null;
  }

  static findEpValidatorMessages(
    epValidator: ErrorMessage[],
    fieldName: string,
    index: number = 0
  ): ErrorMessageContent[] {
    const errorMessage: ErrorMessageContent[] = [];

    if (!epValidator) {
      return errorMessage;
    }

    epValidator.forEach(item => {
      if (item.messages) {
        if (index > -1) {
          if (item.fieldName === fieldName && item.index === index) {
            item.messages.forEach(msg => {
              errorMessage.push(ErrorHandleHelper.prepareEpValidatorErrorMessage(msg));
            });
          }
        } else {
          if (item.fieldName === fieldName) {
            item.messages.forEach(msg => {
              errorMessage.push(ErrorHandleHelper.prepareEpValidatorErrorMessage(msg));
            });
          }
        }
      }
    });

    return errorMessage;
  }

  private static prepareEpValidatorErrorMessage(msg: string|ErrorMessageContent): ErrorMessageContent {
    if (msg instanceof ErrorMessageContent) {
      return msg;
    }

    return new ErrorMessageContent(msg);
  }
}
