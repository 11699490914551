import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatNativeDateModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTabsModule } from "@angular/material/tabs";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

export const materialModuleImports = [
  MatSlideToggleModule,
  MatSelectModule,
  MatInputModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatDialogModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatButtonModule,
  MatSnackBarModule,
  MatRadioModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatAutocompleteModule,
  NgxUsefulSwiperModule,
  MatExpansionModule,
  MatTabsModule,
  FormsModule,
  ReactiveFormsModule,
  MatSlideToggleModule
];
