import { Component, Input, OnInit } from "@angular/core";
import { Statement } from "app/statements";
import { DateHelper } from "app/core/helpers/date.helper";

@Component({
  selector: "app-charges-summary",
  templateUrl: "./charges-summary.component.html",
  styleUrls: ["./charges-summary.component.scss"]
})
export class ChargesSummaryComponent implements OnInit {
  @Input() statements: Statement[];

  constructor() {}

  ngOnInit() {}

  getTodayDate() {
    return DateHelper.getFormatted("", "DD/MM/YY");
  }

  public tokensRequiredSum(): number {
    return this.statements.reduce((tokensRequiredSum, statement) => tokensRequiredSum + statement.tokens_required, 0);
  }
}
