import * as snackbar from './actions/snackbar';
import {SnackbarConfig} from '../common';


export interface State {
  isSnackbarShown: boolean;
  snackbarConfig: SnackbarConfig;
}

export const initialState: State = {
  isSnackbarShown: false,
  snackbarConfig: new SnackbarConfig('')
};

export function reducer(state = initialState, action: snackbar.Actions): State {
  switch (action.type) {
    case snackbar.SHOW_SNACKBAR: {
      return {
        isSnackbarShown: true,
        snackbarConfig: action.payload
      };
    }

    case snackbar.RESET_SNACKBAR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
