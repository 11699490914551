import {ClientType} from '../users/client-type';


export interface RegisterErrors {
  first_name?: string[];
  last_name?: string[];
  user?: {
    email?: string[],
    password?: string[],
    confirm_password?: string[],
    non_field_errors?: string[]
    referal_code?: string[]
  };
  non_field_errors?: string[];
}

export interface RegisterDataResponse {
  first_name: string;
  last_name: string;
  client_type: string;
  language: string;
  receive_newsletters: boolean;
  referal_code: string,
  user: {
    email: string,
    password: string,
    confirm_password: string
  };
}

export class RegisterData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  language: string;
  receiveNewsletters = false;
  clientType: ClientType;
  referal_code: string;

  constructor() { }

  toResponse(): RegisterDataResponse {
    return {
      referal_code: this.referal_code ? this.referal_code : "",
      first_name: this.firstName ? this.firstName : null,
      last_name: this.lastName ? this.lastName : null,
      client_type: this.clientType,
      language: this.language,
      receive_newsletters: this.receiveNewsletters,
      user: {
        email: this.email,
        password: this.password,
        confirm_password: this.confirmPassword
      }
    };
  }
}
