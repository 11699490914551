export class WorkflowStepsBaseClassesService {
  public get classLeftLineNone(): string {
    return ' left-line-none ';
  }

  public get classSubSubMenuItem(): string {
    return ' pl-1_25 ';
  }

  public get classHasSubs(): string {
    return ' has-subs ';
  }

  public get classSplit(): string {
    return ' split ';
  }

  public get classMarginBottom(): string {
    return ' mb-1 ';
  }

  public get classMarginTop(): string {
    return ' mt-1 ';
  }
}
