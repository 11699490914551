import { Component, Input } from '@angular/core';
import { Statement } from 'app/statements/statement';
import { StatementPaymentAmount } from '../../statements/statement-payment-amont';
import { PaymentCheckoutComponent } from 'app/statements/payment-checkout/payment-checkout.component';

@Component({
  selector: 'app-payment-banner',
  templateUrl: './payment-banner.component.html',
  styleUrls: ['./payment-banner.component.scss']
})
export class PaymentBannerComponent {
  @Input() statement: Statement;
  @Input() statementPaymentAmount: StatementPaymentAmount;
  @Input() paymentCheckoutComponent: PaymentCheckoutComponent;
}
