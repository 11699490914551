import { Component, Input } from '@angular/core';
import { AdditionalIncomeService } from 'app/modules/statement/services/additional-income.service';
import { PensionIncome } from 'app/modules/statement/models/additional-income/pension-income.model';

@Component({
  selector: 'app-additional-income-form-pension',
  templateUrl: './additional-income-form-pension.component.html',
})
export class AdditionalIncomeFormPensionComponent {
  @Input() pensionIncome: PensionIncome;
  @Input() index = 0;

  constructor(
    public additionalIncomeService: AdditionalIncomeService,
  ) {
  }
}
