<div class="dialog-box" [class.login-dialog-box]="isLogin || isForgot || isForgotSent">
  <div class="dialog-box__header">
    <button mat-icon-button mat-dialog-close tabindex="-1">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>
  </div>

  <div class="dialog-box__content mt-n1_25">
    <div class="first_screen_login login-dialog" *ngIf="!showLoginForm">
      <div class="btn_wrap">
        <div class="btn_label">
          {{ "LOGIN_DIALOG.FIRST_SCREEN.LABEL_1" | translate }}
        </div>
        <button type="button" class="btn btn--primary" (click)="showLoginBox()">
          <span class="btn--tx">{{ "LOGIN_DIALOG.FIRST_SCREEN.BTN_1" | translate }}</span>
        </button>
      </div>
      <div class="btn_wrap">
        <div class="btn_label">
          {{ "LOGIN_DIALOG.FIRST_SCREEN.LABEL_2" | translate }}
        </div>
        <button type="button" class="btn btn--info" (click)="showSignInBox()">
          <span class="btn--tx">{{ "LOGIN_DIALOG.FIRST_SCREEN.BTN_2" | translate }}</span>
        </button>
      </div>
      <div class="aside-menu__stages aside-menu__info-container d-flex flex-wrap">
        <a class="aside-menu__stage" (click)="showImpressumDialog()">Impressum</a>
        <span class="aside-menu_separator">|</span>
        <a class="aside-menu__stage" (click)="showPrivacyPolicyDialog($event)">
          {{ "COMMON.PRIVACY_POLICY" | translate }}
        </a>
        <span class="aside-menu_separator">|</span>
        <a class="aside-menu__stage" (click)="showTermsAndConditionsDialog($event)">
          {{ "COMMON.TERMS_AND_CONDITIONS_NOT_LOGGED" | translate }}
        </a>
        <span class="aside-menu_separator">|</span>
        <a class="aside-menu__stage" (click)="showCookieBanner($event)">{{ "COMMON.COOKIE_BANNER" | translate }}</a>
      </div>
    </div>
    <div class="register-logo-box" *ngIf="isRegistration"
      ><img class="logo pt-1 pb-2" src="../../../../../assets/svg/logo.svg" alt="taxando"
    /></div>
    <div class="login-dialog" *ngIf="showLoginForm">
      <form class="login-dialog__second" *ngIf="isLogin" (submit)="login()">
        <div *ngIf="!positiveRegistration" class="font-size-40 text-center font-weight-bold mb-0_5">
          {{ "LOGIN_DIALOG.LOGIN_TITLE" | translate }}
        </div>
        <div *ngIf="positiveRegistration" class="font-size-24 text-center font-weight-bold mb-0_5">
          {{ "LOGIN_DIALOG.LOGIN_DESC_AFTER_REGISTRATION" | translate }}
        </div>
        <div *ngIf="!positiveRegistration" class="desc font-size-20 text-center">
          {{ "LOGIN_DIALOG.LOGIN_DESC" | translate }}
        </div>

        <div class="form-group mb-1">
          <app-form-input
            [label]="'INPUT.EMAIL.LABEL'"
            [inputType]="'email'"
            [required]="true"
            [autofocus]="true"
            [name]="'email'"
            [placeholder]="'INPUT.EMAIL.PLACEHOLDER'"
            [(model)]="email"
            (modelChange)="enableEditing()"
            [currentInputId]="'loginEmail'"
            [autocomplete]="'username'"
          >
          </app-form-input>
        </div>
        <div class="form-group mb-1">
          <app-form-input
            [label]="'INPUT.PASSWORD.LABEL'"
            [inputType]="'password'"
            [required]="true"
            [appShowPassword]="true"
            [name]="'password'"
            [placeholder]="'INPUT.PASSWORD.PLACEHOLDER'"
            [(model)]="password"
            (modelChange)="enableEditing()"
            [currentInputId]="'loginPassword'"
            [autocomplete]="'current-password'"
          >
          </app-form-input>
        </div>
        <div>
          <div class="alert-box" *ngIf="loginErrors?.non_field_errors?.length">
            {{ loginErrors.non_field_errors[0] }}
          </div>
          <div class="alert-box" *ngIf="loginErrors?.detail">
            {{ loginErrors.detail }}
          </div>
        </div>
        <app-captcha [resetCaptcha]="resetCaptcha" class="mb-1" (resolved)="captchaResolved($event)"></app-captcha>
        <div class="form-group form-submit">
          <button
            #trigerLogin
            id="loginSubmitBtn"
            type="submit"
            class="btn btn--primary btn--block m-0"
            [class.is-loading]="submitted"
            [disabled]="isLoginDisabled || !(captchaToken$ | async)"
          >
            {{ "LOGIN.LOGIN" | translate }}
          </button>
          <div class="forgot-password mt-0_5">
            <a
              class="forgot-password__text text-muted"
              (click)="forgotPassword(); executeFirebaseEvent('click_reset_password', null)"
            >
              {{ "LOGIN.FORGOT_PASSWORD" | translate }}
            </a>
          </div>
        </div>
        <div class="d-flex justify-content-center logo">
          <img src="../../../../../assets/svg/logo.svg" alt="taxando" />
        </div>
        <div class="register-row text-center font-size-20">
          <span>{{ "LOGIN.ARE_YOU_NEW" | translate }} </span>
          <a
            class="text-primary font-size-20"
            (click)="activeStep = steps.REGISTRATION; executeFirebaseEvent('click_sign_up', null)"
          >
            {{ "COMMON.SIGN_UP" | translate }}
          </a>
        </div>
      </form>

      <form
        class="login-dialog__second"
        *ngIf="isForgot"
        (ngSubmit)="onSubmitForgot()"
        [formGroup]="forgotPasswordForm"
      >
        <div class="font-size-40 text-center font-weight-bold mb-0_5">
          {{ "PASSWORD.FORGOT" | translate }}
        </div>
        <div class="desc font-size-20 text-center">
          {{ "PASSWORD.INSTRUCTION_FIRST" | translate }}
          {{ "PASSWORD.INSTRUCTION_SECOND" | translate }}
        </div>

        <div class="form-group mb-1">
          <app-form-input-reactive
            [autofocus]="true"
            [control]="forgotPasswordFormEmail"
            [inputType]="'email'"
            [label]="'INPUT.EMAIL.LABEL'"
            [placeholder]="'INPUT.EMAIL.PLACEHOLDER'"
            [autocomplete]="'username'"
            [errors]="forgotPasswordFormEmailError"
          >
          </app-form-input-reactive>
        </div>
        <div>
          <div class="alert-box" *ngIf="loginErrors?.non_field_errors?.length">
            {{ loginErrors.non_field_errors[0] }}
          </div>
          <div class="alert-box" *ngIf="loginErrors?.detail">
            {{ loginErrors.detail }}
          </div>
        </div>
        <app-captcha class="mb-1" (resolved)="captchaResolved($event)"></app-captcha>
        <div class="form-group form-submit">
          <button
            type="submit"
            class="btn btn--primary btn--block m-0"
            [class.is-loading]="isForgotPasswordLoading"
            [disabled]="isForgotPasswordLoading || !(captchaToken$ | async)"
          >
            {{ "PASSWORD.RESET" | translate }}
          </button>
          <div class="forgot-password mt-0_5">
            <a class="forgot-password__text text-muted" (click)="activeStep = steps.LOGIN">
              {{ "COMMON.LOG_IN" | translate }}
            </a>
          </div>
        </div>
        <div class="d-flex justify-content-center logo">
          <img src="../../../../../assets/svg/logo.svg" alt="taxando" />
        </div>
        <div class="register-row text-center font-size-20">
          <span>{{ "LOGIN.ARE_YOU_NEW" | translate }} </span>
          <a
            class="text-primary font-size-20"
            (click)="activeStep = steps.REGISTRATION; executeFirebaseEvent('click_sign_up', null)"
          >
            {{ "COMMON.SIGN_UP" | translate }}
          </a>
        </div>
      </form>

      <div class="login-dialog__second" *ngIf="isForgotSent">
        <div class="font-size-40 text-center font-weight-bold mb-0_5">
          {{ "PASSWORD.FORGOT" | translate }}
        </div>
        <div class="desc font-size-20 text-center">
          {{ "PASSWORD.INSTRUCTION_THIRD" | translate: { email: forgotPasswordFormEmail.value } }}
          {{ "PASSWORD.INSTRUCTION_FOURTH" | translate }}
        </div>
        <div class="form-group form-submit mt-2">
          <button (click)="dialogRef.close()" type="button" class="btn btn--primary btn--block m-0">
            {{ "COMMON.CLOSE" | translate }}
          </button>
        </div>
        <div class="d-flex justify-content-center logo">
          <img src="../../../../../assets/svg/logo.svg" alt="taxando" />
        </div>
        <div class="register-row text-center font-size-20">
          <span>{{ "LOGIN.ARE_YOU_NEW" | translate }} </span>
          <a
            class="text-primary font-size-20"
            (click)="activeStep = steps.REGISTRATION; executeFirebaseEvent('click_sign_up', null)"
          >
            {{ "COMMON.SIGN_UP" | translate }}
          </a>
        </div>
      </div>

      <div class="bg-grey-layer hidden_sm"></div>
      <div class="login-dialog__first" *ngIf="isRegistration">
        <div class="benefits font-size-20">
          <!-- <img class="logo pt-1 pb-2" src="../../../../../assets/svg/logo.svg" alt="taxando"> -->
          <div class="description">{{ "LOGIN_DIALOG.DESCRIPTION2" | translate }}</div>
          <ul>
            <li><i class="fa fa-check-circle pr-0_75"></i>{{ "LOGIN_DIALOG.BENEFITS.FIRST" | translate }}</li>
            <li><i class="fa fa-check-circle pr-0_75"></i>{{ "LOGIN_DIALOG.BENEFITS.SECOND" | translate }}</li>
            <li><i class="fa fa-check-circle pr-0_75"></i>{{ "LOGIN_DIALOG.BENEFITS.THIRD" | translate }}</li>
            <li><i class="fa fa-check-circle pr-0_75"></i>{{ "LOGIN_DIALOG.BENEFITS.FOURTH" | translate }}</li>
          </ul>
        </div>
      </div>

      <form class="login-dialog__second" *ngIf="isRegistration" (submit)="register()">
        <!-- <div class="font-size-40 text-center font-weight-bold mb-3">
          {{ "LOGIN_DIALOG.REGISTER_TITLE" | translate }}
        </div> -->

        <div class="form-group mb-1">
          <app-form-input
            [errors]="registerErrors?.user?.email"
            [currentInputId]="'email'"
            [label]="'INPUT.EMAIL.LABEL'"
            [inputType]="'email'"
            [required]="true"
            [autofocus]="true"
            [name]="'email'"
            [placeholder]="'INPUT.EMAIL.PLACEHOLDER'"
            [(model)]="registerData.email"
            (modelChange)="enableEditing()"
            [autocomplete]="'off'"
          >
          </app-form-input>
        </div>
        <div class="form-group has-feedback mb-1">
          <app-form-input
            [errors]="registerErrors?.user?.password"
            [label]="'INPUT.PASSWORD.LABEL'"
            [inputType]="'password'"
            [required]="true"
            [appShowPassword]="true"
            [name]="'password'"
            [placeholder]="'INPUT.PASSWORD.PLACEHOLDER'"
            [(model)]="registerData.password"
            (modelChange)="enableEditing()"
            [autocomplete]="'off'"
          >
          </app-form-input>
        </div>
        <div class="form-group mb-1">
          <app-form-input
            [errors]="registerErrors?.user?.referal_code"
            [label]="'INPUT.REFERAL_CODE.LABEL'"
            inputType="text"
            [required]="false"
            [name]="'referal_code'"
            [placeholder]="'INPUT.REFERAL_CODE.PLACEHOLDER'"
            [(model)]="registerData.referal_code"
            (modelChange)="enableEditing()"
            [autocomplete]="'off'"
          >
          </app-form-input>
        </div>

        <div class="form-group mb-1 mt-0_5">
          <div class="terms-acceptation">
            <mat-checkbox [checked]="areTermsAccepted" (change)="areTermsAccepted = !areTermsAccepted">
              {{ "REGISTER.AGREED" | translate }}
              <span class="is-bold cursor-pointer" (click)="showTermsAndConditionsDialog($event)">{{
                "REGISTER.TERMS_AND_CONDITIONS" | translate
              }}</span>
              {{ "REGISTER.AND" | translate }}
              <span class="is-bold cursor-pointer" (click)="showPrivacyPolicyDialog($event)">{{
                "REGISTER.PRIVACY_POLICY" | translate
              }}</span>
              {{ "REGISTER.END" | translate }}
            </mat-checkbox>
          </div>
        </div>
        <div class="form-group mb-1">
          <div class="receive-newsletters">
            <mat-checkbox
              [checked]="registerData.receiveNewsletters"
              (change)="registerData.receiveNewsletters = !registerData.receiveNewsletters"
            >
              {{ "RECEIVE_NEWSLETTERS.START" | translate }}
              <b class="cursor-pointer" (click)="showPrivacyPolicyDialog($event)">{{
                "RECEIVE_NEWSLETTERS.PRIVACY_POLICY" | translate
              }}</b>
              {{ "RECEIVE_NEWSLETTERS.END" | translate }}
            </mat-checkbox>
          </div>
        </div>
        <div>
          <div class="alert-box" *ngIf="registerErrors?.non_field_errors?.length">
            {{ registerErrors.non_field_errors[0] }}
          </div>
          <div class="alert-box" *ngIf="registerErrors?.user?.non_field_errors?.length">
            {{ registerErrors.user.non_field_errors[0] }}
          </div>
        </div>
        <app-captcha class="mb-1" (resolved)="captchaResolved($event)"></app-captcha>
        <div class="form-group form-submit">
          <button
            type="submit"
            class="btn btn--primary btn--block m-0"
            [class.is-loading]="submitted"
            [disabled]="isRegisterDisabled() || !(captchaToken$ | async)"
          >
            {{ "REGISTER.SIGN_UP" | translate }}
          </button>
        </div>
        <!-- <div class="d-flex justify-content-center logo hidden_above_sm">
          <img src="../../../../../assets/svg/logo.svg" alt="taxando" />
        </div> -->
        <div class="login-row text-center font-size-20">
          <span>{{ "REGISTER.ALREADY_USER" | translate }} </span>
          <a (click)="activeStep = steps.LOGIN" class="text-primary font-size-20">{{ "COMMON.LOG_IN" | translate }}</a>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="bot_login_wrap" *ngIf="!showLoginForm">
  <div class="logo_wrap">
    <img class="logo" src="../../../../../assets/svg/logo.svg" />
  </div>
  <div class="lg_wrap">
    <app-choose-language class="header-bar__lang-wr" [mode]="'header'" [loginModal]="true"></app-choose-language>
  </div>
</div>
