import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Statement} from '../../statements';
import {StatementService} from '../../statements/statement.service';

import * as FileSaver from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { SubscribingComponent } from '../../common';

@Component({
  selector: 'app-list-view-downloads',
  templateUrl: './list-view-downloads.component.html',
  styleUrls: ['./list-view-downloads.component.scss']
})
export class ListViewDownloadsComponent extends SubscribingComponent implements OnInit {
  public files: { name: string; type: string }[] = [];

  constructor(
    public dialogRef: MatDialogRef<ListViewDownloadsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {statement: Statement},
    private statementService: StatementService
  ) {
    super();

    this.files = this.data.statement.filesToDownload;
  }

  ngOnInit() { }

  public onDownload(index: number) {
    this.statementService.file.get(this.data.statement.id, this.files[index].type).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => this.saveFile(response, index), console.error)
  }

  public onView(index: number) {
    this.statementService.file.get(this.data.statement.id, this.files[index].type).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.showFile, console.error)
  }

  private getBlob = (base64: string) => {
    const byteArray = new Uint8Array(atob(base64).split('').map(char => char.charCodeAt(0)));
    return new Blob([byteArray], {type: 'application/pdf'});
  }

  private saveFile = (response, index) => {
    const blob = this.getBlob(response['base64']);
    FileSaver.saveAs(blob, this.files[index].name);
  }

  private showFile = (response) => {
    const blob = this.getBlob(response['base64']);
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL, '_blank');
  }
}
