import { Platform } from '@angular/cdk/platform';
import { isPlatformServer } from '@angular/common';
import {
  ComponentFactoryResolver,
  Inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { SmartAppBannerConfig } from 'app/configs/smart-app-banner.config';
import { CookieService } from 'ngx-cookie-service';
import { SmartAppBannerSettingsInterface } from 'app/shared/modules/smart-app-banner/models/smart-app-banner-settings.interface';
import { SmartAppBannerPlatformEnum } from 'app/shared/modules/smart-app-banner/enums/smart-app-banner-platform.enum';
import { DeviceRecognizerHelper } from 'app/core/helpers/device-recognizer.helper';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SmartAppBannerService {
  public isShown = false;
  public settings: SmartAppBannerSettingsInterface;

  private readonly isServer: boolean;

  constructor(
    private readonly platform: Platform,
    private readonly componentResolver: ComponentFactoryResolver,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly cookieService: CookieService,
    private readonly translate: TranslateService,
  ) {
    this.isServer = isPlatformServer(this.platformId);

    this.settings = {
      daysHidden: SmartAppBannerConfig.daysHidden,
      daysReminder: SmartAppBannerConfig.daysReminder,
      price: '',
      priceSuffix: {
        ios: '',
        android: '',
      },
      title: 'Taxando - Digital tax advisor',
      author: SmartAppBannerConfig.author,
      rating: {
        ios: SmartAppBannerConfig.ratingIos,
        android: SmartAppBannerConfig.ratingAndroid,
      },
      icon: {
        ios: SmartAppBannerConfig.icon,
        android: SmartAppBannerConfig.icon,
      },
      buttonUrl: {
        ios: SmartAppBannerConfig.urlIos,
        android: SmartAppBannerConfig.urlAndroid,
      },
      closeLabel: 'Close',
      buttonLabel: 'VIEW',
      enabledPlatforms: [
        SmartAppBannerPlatformEnum.Android,
        SmartAppBannerPlatformEnum.IOS,
      ],
    };
  }

  public hide(): void {
    this.isShown = false;
  }

  public async initialize(settings: SmartAppBannerSettingsInterface = {}): Promise<void> {
    this.isShown = false;

    if (this.platform.SAFARI || DeviceRecognizerHelper.isWebView()) {
      return;
    }

    this.settings = {
      ...this.settings,
      title: await firstValueFrom(this.translate.get('SMART_APP_BANNER.APP_NAME')),
      closeLabel: await firstValueFrom(this.translate.get('SMART_APP_BANNER.CLOSE')),
      buttonLabel: await firstValueFrom(this.translate.get('SMART_APP_BANNER.VIEW')),
      ...settings
    };

    if (this.isServer || this.cookieService.check('smartbanner_closed')) {
      return;
    }

    if (!this.platformEnabled) {
      return;
    }

    this.isShown = true;
  }

  private get platformEnabled(): boolean {
    if (this.platform.ANDROID) {
      return this.settings.enabledPlatforms.includes(
        SmartAppBannerPlatformEnum.Android,
      );
    }

    if (this.platform.IOS) {
      return this.settings.enabledPlatforms.includes(
        SmartAppBannerPlatformEnum.IOS,
      );
    }

    return false;
  }
}
