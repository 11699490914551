import { Employer } from "./employer";
import { DeductionHealthSteps, Deductions } from "app/shared/modules/statement-deduction-base/models/deductions";
import { Child } from "./child";
import { AbroadIncome, AdditionalAbroadIncomeResponse } from "./abroad-income";
import { PackageOptions } from "./package-options";
import { Currency } from "./currency";
import { toResponseDate } from "../common/dateRange";
import { SelectsDataInterface } from "./selects-data";
import { CategoryProperties } from "../common/confirm-tax-return/ctr-categories";
import { ArrayHelper } from "../core/helpers/array.helper";
import { RecognizerHelper } from "app/core/helpers/recognizer.helper";
import { StatementResponse } from "app/modules/statement/models/statement/statement.response.interface";
import { FreelanceJob } from "app/modules/statement/models/additional-income/freelance-job.model";
import { PensionIncome } from "app/modules/statement/models/additional-income/pension-income.model";
import { SocialIncome } from "app/modules/statement/models/additional-income/social-income.model";
import {
  StatementDashboardStatusSetter,
  StatementStatuses
} from "app/modules/statement/models/statement/statement.statuses";
import { SocialSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/social-sub.steps";
import { PensionSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/pension.sub-steps";
import { FreelanceJobSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/freelance-job.sub-steps";
import { CryptocurrencyIncome } from "app/modules/statement/models/additional-income/cryptocurrency.model";
import { CryptocurrencySubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/cryptocurrency.sub-steps";

export class Statement {
  isFull?: boolean;
  id: number;
  uuid?: string;
  createdDate?: string;
  createdTime?: string;
  fullYear?: number;
  year?: number;
  firstName?: string;
  lastName?: string;
  status?: string;
  statusText?: string;
  code?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  houseNumberWithSupplement?: string;
  additionalAddressInfo?: string;
  birthDate?: string;
  taxAuthority?: number;
  religion?: number;
  maritalStatus?: number;
  hasBankAccount = true;
  iban?: string;
  bic?: string;
  moneyInstitution?: string;
  otherPersonOwnsBankAccount?: boolean;
  spouseOwnsBankAccount?: boolean;
  bankAccountHolder?: string;
  identifikationsnummer?: string;
  steuernummer?: string;
  employers?: Employer[];
  name?: string;
  taxClass?: number;
  dateOfMarriage?: string;
  dateOfPartnership?: string;
  dateOfSeparation?: string;
  dateOfWidowed?: string;
  dateOfDivorce?: string;
  spouse?: Statement;
  estimatedRefund: number;
  deductions?: Deductions;
  abroadIncome?: AbroadIncome;
  residentAbroadIncome?: AbroadIncome[];
  additional_abroad_incomes?: AdditionalAbroadIncomeResponse[];
  freelanceJobs?: FreelanceJob[];
  additionalIncome?: SocialIncome[];
  pensionIncome?: PensionIncome[];
  cryptocurrencyIncome?: CryptocurrencyIncome[];
  emailAddress?: string;
  phoneNumber?: string;
  filedTogether?: boolean;
  children?: Child[];
  payment?: number;
  hasChildren?: boolean;
  hasChildrenDeductions?: boolean;
  hasDeductions?: boolean;
  hasAbroadIncome?: boolean;
  hasAdditionalIncome?: boolean;
  isPaid?: boolean;
  germanTotalIncome?: number;
  spouseCalcIncome?: string;
  relationshipStatus?: number;
  canHaveSpouseAndChildren?: boolean;
  batchCode?: string;
  batchCreatedBy?: string;
  batchId?: string;
  serviceType?: PackageOptions;
  clonedFrom: string;
  vollmachtDate?: string;
  vollmachtCity?: string;
  paymentMethod?: string;
  paymentAmount?: string | number;
  paymentCurrency?: string;
  vollmachtSigned?: boolean;
  vollmachtAuthorizationDateTime?: Date;
  hasVerifiedIdentity?: boolean;
  isInformedThatCannotResignFromClaim?: boolean;
  additionalInformation?: string;
  hasUploadedScan?: boolean;
  onfidoCompletedDate?: Date;
  taxAdviserWillSendInDays?: string;
  tokens_required?: number;

  // marital statuses
  isMarried: boolean;
  isSeparated: boolean;
  isWidowed: boolean;
  isDivorced: boolean;

  // For statement list (displaying spinner):
  downloadingPdf = false;
  downloadingInvoice = false;
  deleting = false;
  hasErrors = false;

  // deductions
  hasEquipmentCost?: boolean;
  hasPremiumForTradeUnions?: boolean;
  hasRemovalExpanses?: boolean;
  hasHomeOffice?: boolean;
  hasBudgetaryEmploymentCost?: boolean;
  hasDoubleHousehold?: boolean;
  hasWorkTripCost?: boolean;
  hasRiester?: boolean;
  hasPrivateInsurance?: boolean;
  hasPublicInsurance?: boolean;
  hasForeignInsurance?: boolean;
  hasForeignSocialInsurance?: boolean;
  hasProffesionalInsurance?: boolean;
  hasRecruitmentCost?: boolean;
  hasBusinessTrips?: boolean;
  hasDonationsDe?: boolean;
  hasDonationsEu?: boolean;
  hasDonationsPolitical?: boolean;
  hasHealthCosts?: boolean;
  hasCareCosts?: boolean;
  hasDisability?: boolean;
  hasHouseHoldMiniJobsCosts?: boolean;
  hasHouseHoldServicesCosts?: boolean;
  hasCraftsmanServicesCosts?: boolean;

  hasFreeMealsOnTravel?: boolean;
  hasFreeMealsOnTravelAbroad?: boolean;

  hasDonation?: boolean;

  hasTaxSettlementCost?: boolean;
  hasPhoneCost?: boolean;
  hasWinterbauCost?: boolean;
  hasBankCost?: boolean;
  hasOtherCost?: boolean;

  hasSpouseEmployers?: boolean;
  canDownloadInvoice?: boolean;
  canDownloadDecision?: boolean;
  isDecisionReady?: boolean;
  canSendWithoutResignationFromClaim?: boolean;
  canVerifyWithOnfido?: boolean;
  typeOfSending?: string;
  canDownloadPdf?: boolean;
  hasEmployers = false;

  // stages
  stageEmployers?: boolean;
  stageProfile?: boolean;
  stageAbroadIncome?: boolean;
  stagePersonalInfo?: boolean;
  stageAdditionalIncome?: boolean;
  stageDeductionInfo?: boolean;
  stageChildrenInfo?: boolean;
  stageVerification?: boolean;
  stageSteuerAdditionalOption?: boolean;
  stagePackageSelection?: boolean;
  stageTaxReliefs?: number;

  // Steuer flags
  hasRentalIncome?: boolean;
  hasCryptocurrencyIncome?: boolean;
  hasFarmingIncome?: boolean;
  hasCapitalIncome?: boolean;
  hasRealestateIncome?: boolean;
  paysFamilySupport?: boolean;
  photovoltaicInstallationCosts?: boolean;

  // custom labels
  customLabels?: number[];

  // dashboard statuses
  dashboardStatus = "";
  hasCertificate = false;

  // unfinishedStatements
  isOpenDesc = false;

  numberOfAttachments?: number;
  numberOfFilesToDownload?: number;
  filesToDownload?: { name: string; type: string }[] = [];
  documents?: {} = {};
  uploaded_files?: any[];
  hasUploadedFile = false;
  hasNoId = false;
  // flags for Firebase and Facebook Pixel events
  eventPurchaseComplete = false;
  eventPurchaseCompleteType = false;
  canBeFilled?: boolean;
  canBeSaved?: boolean;
  canBeFreeService = false;
  healthBenefitsStep?: boolean;
  pensionsStep?: boolean;
  freelanceJobStep?: boolean;
  cryptocurrencySteps?: boolean;
  stagePersonalInfoChildren?: boolean;
  stageDeductionInfoChildren?: boolean;

  hasVerifiedPhoneNumber?: boolean;
  isStandardPackageAvailable?: boolean;

  citizenship?: string;

  liveInGermany?: boolean;

  static fromResponse(response: StatementResponse, isFull = true): Statement {
    const statement = new Statement();

    statement.isFull = isFull;
    statement.id = response.id;
    statement.uuid = response.uuid;
    statement.createdDate = response.created_date;
    statement.createdTime = response.created_time;
    statement.status = response.status;
    statement.statusText = response.status_text;
    statement.street = response.street;
    statement.houseNumberWithSupplement = response.house_number_with_supplement;
    statement.additionalAddressInfo = response.additional_address_info;
    statement.firstName = response.first_name;
    statement.lastName = response.last_name;
    statement.postalCode = response.postal_code;
    statement.city = response.city;
    statement.country = response.country;
    statement.birthDate = response.birth_date;
    statement.year = response.statement_year;
    statement.fullYear = response.year;
    statement.taxClass = response.tax_class;
    statement.taxAuthority = response.tax_authority;
    statement.religion = response.religion;
    statement.maritalStatus = response.marital_status;
    statement.hasBankAccount = !RecognizerHelper.isNullOrUndefined(response.has_bank_account)
      ? response.has_bank_account
      : true;
    statement.iban = response.iban;
    statement.bic = response.bic;
    statement.moneyInstitution = response.money_institution;
    statement.otherPersonOwnsBankAccount = response.other_person_owns_bank_account;
    statement.spouseOwnsBankAccount = response.spouse_owns_bank_account;
    statement.bankAccountHolder = response.bank_account_holder;
    statement.identifikationsnummer = response.identifikationsnummer;
    statement.steuernummer = response.steuernummer;
    statement.dateOfMarriage = response.date_of_marriage;
    statement.dateOfPartnership = response.date_of_partnership;
    statement.dateOfSeparation = response.date_of_separation;
    statement.dateOfWidowed = response.date_of_widowed;
    statement.dateOfDivorce = response.date_of_divorce;
    statement.estimatedRefund = response.estimated_refund;
    statement.deductions = Deductions.fromResponse(response.deductions);
    statement.emailAddress = response.email_address;
    statement.phoneNumber = response.phone_number;
    statement.filedTogether = response.filed_together;
    statement.payment = response.payment;
    statement.hasChildren = response.has_children;
    statement.hasChildrenDeductions = response.has_children_deductions;
    statement.hasDeductions = response.has_deductions;
    statement.hasAbroadIncome = response.has_abroad_income ? response.has_abroad_income : false;
    statement.hasAdditionalIncome = response.has_additional_income;
    statement.isPaid = response.is_paid;
    statement.germanTotalIncome = response.german_total_income;
    statement.canHaveSpouseAndChildren = response.can_have_spouse_and_children;
    statement.serviceType = response.service_type;
    statement.spouseCalcIncome = response.spouse_calc_income ? response.spouse_calc_income : "0.00";
    statement.relationshipStatus = response.relationship_status;
    statement.clonedFrom = response.cloned_from;
    statement.vollmachtCity = response.vollmacht_city;
    statement.vollmachtDate = response.vollmacht_date;
    statement.paymentMethod = response.payment_method;
    statement.paymentAmount = response.payment_amount;
    statement.paymentCurrency = response.payment_currency;
    statement.vollmachtSigned = response.vollmacht_signed;
    statement.vollmachtAuthorizationDateTime = response.vollmacht_authorization_datetime;
    statement.hasVerifiedIdentity = response.has_verified_identity;
    statement.isInformedThatCannotResignFromClaim = response.is_informed_that_cannot_resign_from_claim;
    statement.additionalInformation = response.additional_information;
    statement.onfidoCompletedDate = response.onfido_completed_date;
    statement.taxAdviserWillSendInDays = response.tax_adviser_will_send_in_days;
    statement.tokens_required = response.tokens_required;

    // deductions
    statement.hasEquipmentCost = response.has_equipment_cost;
    statement.hasPremiumForTradeUnions = response.has_premium_for_trade_unions;
    statement.hasRemovalExpanses = response.has_removal_expanses;
    statement.hasHomeOffice = response.has_home_office;
    statement.hasBudgetaryEmploymentCost = response.has_budgetary_employment_cost;
    // statement.hasDoubleHousehold = response.has_double_household;
    statement.hasWorkTripCost = response.has_work_trip_cost;
    statement.hasRiester = response.has_riester;
    statement.hasPrivateInsurance = response.has_private_insurance;
    statement.hasPublicInsurance = response.has_public_insurance;
    statement.hasForeignInsurance = response.has_foreign_insurance;
    statement.hasForeignSocialInsurance = response.has_foreign_social_insurance;
    statement.hasProffesionalInsurance = response.has_proffesional_insurance;
    statement.hasRecruitmentCost = response.has_recruitment_cost;
    statement.hasDonationsDe = response.has_donations_de;
    statement.hasDonationsEu = response.has_donations_eu;
    statement.hasDonationsPolitical = response.has_donations_political;
    statement.hasHealthCosts = response.has_health_costs;
    statement.hasCareCosts = response.has_care_costs;
    statement.hasDisability = response.has_disability;

    statement.hasHouseHoldMiniJobsCosts = response.has_house_hold_mini_jobs_costs;
    statement.hasHouseHoldServicesCosts = response.has_house_hold_services_costs;
    statement.hasCraftsmanServicesCosts = response.has_craftsman_services_costs;

    statement.hasBusinessTrips = response.has_business_trips;
    statement.hasFreeMealsOnTravel = response.has_free_meals_on_travel;
    statement.hasFreeMealsOnTravelAbroad = response.has_free_meals_on_travel_abroad;
    statement.hasDonationsDe = response.has_donations_de;
    statement.hasDonationsEu = response.has_donations_eu;
    statement.hasDonationsPolitical = response.has_donations_political;

    statement.hasTaxSettlementCost = response.has_tax_settlement_cost;
    statement.hasPhoneCost = response.has_phone_cost;
    statement.hasWinterbauCost = response.has_winterbau_cost;
    statement.hasBankCost = response.has_bank_cost;
    statement.hasOtherCost = response.has_other_cost;

    statement.hasEmployers = response.has_employers;
    statement.hasSpouseEmployers = response.has_spouse_employers;
    statement.canDownloadInvoice = response.can_download_invoice;
    statement.canDownloadDecision = response.can_download_decision;
    statement.isDecisionReady = response.is_decision_ready;
    statement.canSendWithoutResignationFromClaim = response.can_send_without_resignation_from_claim;
    statement.canVerifyWithOnfido = response.can_verify_with_onfido;
    statement.typeOfSending = response.type_of_sending;
    statement.canDownloadPdf = response.can_download_pdf;
    statement.hasUploadedScan = response.has_uploaded_scan;

    statement.employers =
      response.employers && response.employers.length ? response.employers.map(Employer.fromResponse) : [];

    statement.spouse =
      response.spouses && response.spouses.length && response.status !== "spouse"
        ? Statement.fromResponse(response.spouses[0])
        : null;

    statement.freelanceJobs = ArrayHelper.isArrayAndNotEmpty(response.freelance_jobs)
      ? response.freelance_jobs.map(FreelanceJob.fromResponse)
      : [];

    statement.cryptocurrencyIncome = ArrayHelper.isArrayAndNotEmpty(response.cryptocurrency_income)
      ? response.cryptocurrency_income.map(CryptocurrencyIncome.fromResponse)
      : [];

    statement.additionalIncome = ArrayHelper.isArrayAndNotEmpty(response.additional_income)
      ? response.additional_income.map(SocialIncome.fromResponse)
      : [];

    statement.pensionIncome = ArrayHelper.isArrayAndNotEmpty(response.pension_income)
      ? response.pension_income.map(PensionIncome.fromResponse)
      : [];

    statement.children = response.children && response.children.length ? response.children.map(Child.fromResponse) : [];

    statement.abroadIncome = response.abroad_income
      ? AbroadIncome.fromResponse(response.abroad_income, response.year)
      : new AbroadIncome(response.year);

    statement.residentAbroadIncome = ArrayHelper.isArrayAndNotEmpty(response.residentAbroadIncome)
      ? response.residentAbroadIncome.map(AbroadIncome.fromResponse)
      : [];

    statement.additional_abroad_incomes = response.additional_abroad_incomes ? response.additional_abroad_incomes : [];

    statement.batchCode =
      response.batch_statement && response.batch_statement.code ? response.batch_statement.code : null;

    statement.batchCreatedBy =
      response.batch_statement && response.batch_statement.created_by ? response.batch_statement.created_by : null;

    statement.batchId = response.batch_statement && response.batch_statement.id ? response.batch_statement.id : null;

    statement.isMarried = response.is_married;
    statement.isSeparated = !!response.date_of_separation;
    statement.isWidowed = !!response.date_of_widowed;
    statement.isDivorced = !!response.date_of_divorce;

    statement.stageEmployers = response.stage_employers;
    statement.stageProfile = response.stage_profile;
    statement.stageAbroadIncome = response.stage_abroad_income;
    statement.stagePersonalInfo = response.stage_personal_info;
    statement.stageAdditionalIncome = response.stage_additional_income;
    statement.stageDeductionInfo = response.stage_deduction_info;
    statement.stageChildrenInfo = response.stage_children_info;
    statement.stageVerification = response.stage_verification;
    statement.stageSteuerAdditionalOption = response.stage_steuer_additional_options;
    statement.stagePackageSelection = response.stage_package_selection;
    statement.stageTaxReliefs = response.stage_tax_reliefs;
    statement.customLabels = response.custom_labels;

    // Steuer flags
    statement.hasRentalIncome = response.has_rental_income;
    statement.hasCryptocurrencyIncome = response.has_cryptocurrency_income;
    statement.hasFarmingIncome = response.has_farming_income;
    statement.hasCapitalIncome = response.has_capital_income;
    statement.hasRealestateIncome = response.has_realestate_income;
    statement.paysFamilySupport = response.pays_family_support;
    statement.photovoltaicInstallationCosts = response.photovoltaic_installation_costs;

    // set dashboard status slug
    StatementDashboardStatusSetter.set(statement);

    // check if statement has certificate
    if (response.type_of_sending) {
      if (response.type_of_sending !== "no_signature" && response.type_of_sending !== "not_sent") {
        statement.hasCertificate = true;
      }
    }

    statement.numberOfAttachments = response.number_of_attachments;
    statement.filesToDownload = response.files_to_download;
    statement.documents = response.documents;
    statement.numberOfFilesToDownload = response.files_to_download?.length;
    statement.uploaded_files = response.uploaded_files;

    if (response.has_uploaded_file) {
      statement.hasUploadedFile = response.has_uploaded_file;
    }
    if (response.has_no_id) {
      statement.hasNoId = response.has_no_id;
    }
    // flags for Firebase and Facebook Pixel events
    statement.eventPurchaseComplete = response.event_purchase_complete;
    statement.eventPurchaseCompleteType = response.event_purchase_complete_type;
    statement.canBeFreeService = response.can_be_free_service;
    statement.healthBenefitsStep = response.health_benefits_step;
    statement.pensionsStep = response.pensions_step;
    statement.freelanceJobStep = response.freelance_job_step;
    statement.cryptocurrencySteps = response.cryptocurrency_step;
    statement.canBeFreeService = response.can_be_free_service;
    statement.canBeFilled = response.can_be_filled;
    statement.canBeSaved = response.can_be_saved;
    statement.stagePersonalInfoChildren = response.stage_personal_info_children;
    statement.stageDeductionInfoChildren = response.stage_deduction_info_children;

    statement.hasVerifiedPhoneNumber = response.has_verified_phone_number;
    statement.isStandardPackageAvailable = response.is_standard_package_available;

    statement.citizenship = response.citizenship;

    // statement.liveInGermany = response.live_in_germany; //Code was commented becouse of task TAX-2984 In that task we dont want to validate and display on front select box with info if person live_in_germany

    statement.updatePensionIncomeFieldsShown();

    return statement;
  }

  static get empty(): Statement {
    return this.fromStorage(new this());
  }

  static fromStorage(response: Statement): Statement {
    const statement = new Statement();
    statement.id = response.id;
    statement.uuid = response.uuid;
    statement.createdDate = response.createdDate;
    statement.status = response.status;
    statement.statusText = response.statusText;
    statement.street = response.street;
    statement.houseNumberWithSupplement = response.houseNumberWithSupplement;
    statement.additionalAddressInfo = response.additionalAddressInfo;
    statement.firstName = response.firstName;
    statement.lastName = response.lastName;
    statement.postalCode = response.postalCode;
    statement.city = response.city;
    statement.country = response.country;
    statement.birthDate = response.birthDate;
    statement.year = response.year;
    statement.fullYear = response.fullYear;
    statement.taxClass = response.taxClass;
    statement.taxAuthority = response.taxAuthority;
    statement.religion = response.religion;
    statement.maritalStatus = response.maritalStatus;
    statement.hasBankAccount = response.hasBankAccount;
    statement.iban = response.iban;
    statement.bic = response.bic;
    statement.moneyInstitution = response.moneyInstitution;
    statement.otherPersonOwnsBankAccount = response.otherPersonOwnsBankAccount;
    statement.spouseOwnsBankAccount = response.spouseOwnsBankAccount;
    statement.bankAccountHolder = response.bankAccountHolder;
    statement.identifikationsnummer = response.identifikationsnummer;
    statement.steuernummer = response.steuernummer;
    statement.dateOfMarriage = response.dateOfMarriage;
    statement.dateOfPartnership = response.dateOfPartnership;
    statement.dateOfSeparation = response.dateOfSeparation;
    statement.dateOfWidowed = response.dateOfWidowed;
    statement.dateOfDivorce = response.dateOfDivorce;
    statement.estimatedRefund = response.estimatedRefund;
    statement.deductions = Deductions.fromStorage(response.deductions);
    statement.emailAddress = response.emailAddress;
    statement.phoneNumber = response.phoneNumber;
    statement.filedTogether = response.filedTogether;
    statement.payment = response.payment;
    statement.hasChildren = response.hasChildren;
    statement.hasChildrenDeductions = response.hasChildrenDeductions;
    statement.hasDeductions = response.hasDeductions;
    statement.hasAbroadIncome = response.hasAbroadIncome;
    statement.hasAdditionalIncome = response.hasAdditionalIncome;
    statement.isPaid = response.isPaid;
    statement.germanTotalIncome = response.germanTotalIncome;
    statement.canHaveSpouseAndChildren = response.canHaveSpouseAndChildren;
    statement.serviceType = response.serviceType;
    statement.spouseCalcIncome = response.spouseCalcIncome;
    statement.relationshipStatus = response.relationshipStatus;

    // deductions
    statement.hasEquipmentCost = response.hasEquipmentCost;
    statement.hasPremiumForTradeUnions = response.hasPremiumForTradeUnions;
    statement.hasRemovalExpanses = response.hasRemovalExpanses;
    statement.hasHomeOffice = response.hasHomeOffice;
    statement.hasBudgetaryEmploymentCost = response.hasBudgetaryEmploymentCost;
    statement.hasDoubleHousehold = response.hasDoubleHousehold;
    statement.hasWorkTripCost = response.hasWorkTripCost;
    statement.hasRiester = response.hasRiester;
    statement.hasPrivateInsurance = response.hasPrivateInsurance;
    statement.hasPublicInsurance = response.hasPublicInsurance;
    statement.hasForeignInsurance = response.hasForeignInsurance;
    statement.hasForeignSocialInsurance = response.hasForeignSocialInsurance;
    statement.hasRecruitmentCost = response.hasRecruitmentCost;
    statement.hasBusinessTrips = response.hasBusinessTrips;
    statement.hasProffesionalInsurance = response.hasProffesionalInsurance;
    statement.hasTaxSettlementCost = response.hasTaxSettlementCost;
    statement.hasPhoneCost = response.hasPhoneCost;
    statement.hasWinterbauCost = response.hasWinterbauCost;
    statement.hasOtherCost = response.hasOtherCost;
    statement.hasHealthCosts = response.hasHealthCosts;
    statement.hasCareCosts = response.hasCareCosts;
    statement.hasDisability = response.hasDisability;

    statement.hasEmployers = response.hasEmployers;
    statement.hasSpouseEmployers = response.hasSpouseEmployers;
    statement.canDownloadDecision = response.canDownloadDecision;
    statement.isDecisionReady = response.isDecisionReady;
    statement.canSendWithoutResignationFromClaim = response.canSendWithoutResignationFromClaim;
    statement.canVerifyWithOnfido = response.canVerifyWithOnfido;
    statement.typeOfSending = response.typeOfSending;
    statement.canDownloadPdf = response.canDownloadPdf;

    statement.employers =
      response.employers && response.employers.length ? response.employers.map(Employer.fromStorage) : [];

    statement.spouse = response.spouse ? Statement.fromStorage(response.spouse) : null;

    statement.freelanceJobs = ArrayHelper.isArrayAndNotEmpty(response.freelanceJobs)
      ? response.freelanceJobs.map(FreelanceJob.fromStorage)
      : [];

    statement.cryptocurrencyIncome = ArrayHelper.isArrayAndNotEmpty(response.cryptocurrencyIncome)
      ? response.cryptocurrencyIncome.map(CryptocurrencyIncome.fromStorage)
      : [];

    statement.additionalIncome = ArrayHelper.isArrayAndNotEmpty(response.additionalIncome)
      ? response.additionalIncome.map(SocialIncome.fromStorage)
      : [];

    statement.pensionIncome = ArrayHelper.isArrayAndNotEmpty(response.pensionIncome)
      ? response.pensionIncome.map(PensionIncome.fromStorage)
      : [];

    statement.children = response.children && response.children.length ? response.children.map(Child.fromStorage) : [];

    statement.abroadIncome = response.abroadIncome
      ? AbroadIncome.fromStorage(response.abroadIncome)
      : new AbroadIncome();

    statement.batchCode = response.batchCode;
    statement.batchCreatedBy = response.batchCreatedBy;
    statement.batchId = response.batchId;

    statement.isMarried = response.isMarried;
    statement.isSeparated = response.isSeparated;
    statement.isWidowed = response.isWidowed;
    statement.isDivorced = response.isDivorced;

    statement.stageEmployers = response.stageEmployers;
    statement.stageProfile = response.stageProfile;
    statement.stageAbroadIncome = response.stageAbroadIncome;
    statement.stagePersonalInfo = response.stagePersonalInfo;
    statement.stageAdditionalIncome = response.stageAdditionalIncome;
    statement.stageDeductionInfo = response.stageDeductionInfo;
    statement.stageChildrenInfo = response.stageChildrenInfo;
    statement.stageVerification = response.stageVerification;
    statement.stageSteuerAdditionalOption = response.stageSteuerAdditionalOption;

    // Steuer flags
    statement.hasRentalIncome = response.hasRentalIncome;
    statement.hasCryptocurrencyIncome = response.hasCryptocurrencyIncome;
    statement.hasFarmingIncome = response.hasFarmingIncome;
    statement.hasCapitalIncome = response.hasCapitalIncome;
    statement.hasRealestateIncome = response.hasRealestateIncome;
    statement.paysFamilySupport = response.paysFamilySupport;
    statement.photovoltaicInstallationCosts = response.photovoltaicInstallationCosts;

    statement.canBeFilled = response.canBeFilled;
    statement.canBeSaved = response.canBeSaved;
    statement.hasBankCost = response.hasBankCost;
    statement.hasFreeMealsOnTravel = response.hasFreeMealsOnTravel;
    statement.hasFreeMealsOnTravelAbroad = response.hasFreeMealsOnTravelAbroad;
    statement.hasDonationsDe = response.hasDonationsDe;
    statement.hasDonationsEu = response.hasDonationsEu;
    statement.hasDonationsPolitical = response.hasDonationsPolitical;
    statement.hasHouseHoldMiniJobsCosts = response.hasHouseHoldMiniJobsCosts;
    statement.hasHouseHoldServicesCosts = response.hasHouseHoldServicesCosts;
    statement.hasCraftsmanServicesCosts = response.hasCraftsmanServicesCosts;

    statement.eventPurchaseComplete = response.eventPurchaseComplete;
    statement.eventPurchaseCompleteType = response.eventPurchaseCompleteType;
    statement.canBeFreeService = response.canBeFreeService;
    statement.healthBenefitsStep = response.healthBenefitsStep;
    statement.pensionsStep = response.pensionsStep;
    statement.freelanceJobStep = response.freelanceJobStep;
    statement.cryptocurrencySteps = response.cryptocurrencySteps;
    statement.stagePersonalInfoChildren = response.stagePersonalInfoChildren;
    statement.stageDeductionInfoChildren = response.stageDeductionInfoChildren;

    statement.hasVerifiedPhoneNumber = response.hasVerifiedPhoneNumber;
    statement.isStandardPackageAvailable = response.isStandardPackageAvailable;

    statement.citizenship = response.citizenship;

    statement.liveInGermany = response.liveInGermany;

    statement.updatePensionIncomeFieldsShown();

    return statement;
  }

  public clone(): Statement {
    return Statement.fromStorage({ ...this });
  }

  public extraIncomeOptionsPayload(defaultValue = undefined): StatementResponse {
    return {
      has_rental_income: !!this.hasRentalIncome,
      has_cryptocurrency_income: !!this.hasCryptocurrencyIncome,
      has_farming_income: !!this.hasFarmingIncome,
      has_capital_income: !!this.hasCapitalIncome,
      has_realestate_income: !!this.hasRealestateIncome,
      pays_family_support: !!this.paysFamilySupport,
      photovoltaic_installation_costs: !!this.photovoltaicInstallationCosts,
      stage_steuer_additional_options: true,
      spouses: this.spouse ? [this.spouse.spouseToResponse(defaultValue)] : [],
      stage_employers: true
    };
  }

  public chooseProfileToResponse(defaultValue = undefined, spouseHasDeductions = ""): StatementResponse {
    const response: StatementResponse = {
      spouses: this.spouse ? [this.spouse.spouseToResponse(undefined, spouseHasDeductions)] : [],
      filed_together: this.filedTogether,
      has_additional_income: this.hasAdditionalIncome,
      has_spouse_employers: this.filedTogether,
      has_abroad_income: this.hasAbroadIncome,
      has_deductions: this.hasDeductions,
      has_children: this.hasChildren,
      has_children_deductions: this.hasChildrenDeductions,
      is_married: this.isMarried,
      is_separated: this.isSeparated,
      relationship_status: this.relationshipStatus,
      date_of_marriage: this.dateOfMarriage ? toResponseDate(this.dateOfMarriage) : null,
      date_of_partnership: this.dateOfPartnership ? toResponseDate(this.dateOfPartnership) : null,
      date_of_separation: this.isSeparated
        ? this.dateOfSeparation
          ? toResponseDate(this.dateOfSeparation)
          : this.batchId
            ? null
            : ""
        : null,
      date_of_widowed: this.isWidowed
        ? this.dateOfWidowed
          ? toResponseDate(this.dateOfWidowed)
          : this.batchId
            ? null
            : ""
        : null,
      date_of_divorce: this.isDivorced
        ? this.dateOfDivorce
          ? toResponseDate(this.dateOfDivorce)
          : this.batchId
            ? null
            : ""
        : null,
      stage_additional_income: true,
      health_benefits_step: this.healthBenefitsStep,
      pensions_step: this.pensionsStep,
      freelance_job_step: this.freelanceJobStep,
      cryptocurrency_step: this.cryptocurrencySteps
    };

    if (this.maritalStatus) {
      response.marital_status = this.maritalStatus;
      response.stage_profile = true;
    }
    return response;
  }

  public personalInfoToResponse(
    defaultValue = undefined,
    spouseType = "",
    addDeductionsFlag = false
  ): StatementResponse {
    const response: StatementResponse = {
      tax_authority: this.taxAuthority || this.taxAuthority === 0 ? this.taxAuthority : defaultValue,
      spouses: this.spouse ? [this.spouse.spouseToResponse(defaultValue, spouseType, this.filedTogether)] : [],
      statement_year: this.year || this.year === 0 ? this.year : defaultValue
    };
    this.personalInfoFullPayload(response, defaultValue);

    if (addDeductionsFlag) {
      this.addDeductionFlagsToResponse(response);
    }
    return response;
  }

  public verificationStepRequest(options: { defaultValue?: any; isBusiness?: boolean } = {}): StatementResponse {
    const defaultValue = options.defaultValue || undefined;
    const isBusiness = options.isBusiness || false;

    this.updateDeductionsFlag({ updateHasDeductions: true });
    this.updateHasAdditionalIncomeFlag();
    if (this.spouse) {
      this.spouse.updateDeductionsFlag({ updateHasDeductions: true });
      this.spouse.updateHasAdditionalIncomeFlag();
    }

    const response: StatementResponse = {
      spouses: this.spouse ? [this.spouse.spouseToResponse(defaultValue, "verificationStep")] : [],
      stage_verification: true
    };

    if (isBusiness) {
      response.vollmacht_city = this.vollmachtCity ? this.vollmachtCity : defaultValue;
      response.vollmacht_date = this.vollmachtDate ? toResponseDate(this.vollmachtDate) : null;
      response.additional_information = this.additionalInformation ? this.additionalInformation : defaultValue;
      response.has_bank_account = this.hasBankAccount;
      response.iban = this.iban ? this.ibanToResponse(this.iban) : defaultValue;
      response.bic = this.bic ? this.bic.toUpperCase() : defaultValue;
      response.money_institution = this.moneyInstitution ? this.moneyInstitution : defaultValue;
      response.other_person_owns_bank_account = this.otherPersonOwnsBankAccount;
      response.spouse_owns_bank_account = this.spouseOwnsBankAccount;
      response.bank_account_holder = this.bankAccountHolder ? this.bankAccountHolder : defaultValue;
    }

    response.has_deductions = this.hasDeductions;
    response.deductions = this.hasDeductions ? this.deductions.toResponse() : {};
    this.addDeductionFlagsToResponse(response);

    this.additionalIncomeSectionPayload(response, defaultValue);

    return response;
  }

  public bankAccountStepRequest(defaultValue = undefined): StatementResponse {
    return {
      has_bank_account: this.hasBankAccount,
      iban: this.iban ? this.ibanToResponse(this.iban) : defaultValue,
      bic: this.bic ? this.bic.toUpperCase() : defaultValue,
      money_institution: this.moneyInstitution ? this.moneyInstitution : defaultValue,
      other_person_owns_bank_account: this.otherPersonOwnsBankAccount,
      spouse_owns_bank_account: this.spouseOwnsBankAccount,
      bank_account_holder: this.bankAccountHolder ? this.bankAccountHolder : defaultValue,
      spouses: this.spouse ? [this.spouse.spouseToResponse(undefined)] : []
    };
  }

  summaryToResponse(defaultValue = undefined): StatementResponse {
    const response: StatementResponse = {
      has_bank_account: this.hasBankAccount,
      iban: this.iban ? this.ibanToResponse(this.iban) : defaultValue,
      bic: this.bic ? this.bic.toUpperCase() : defaultValue,
      money_institution: this.moneyInstitution ? this.moneyInstitution : defaultValue,
      other_person_owns_bank_account: this.otherPersonOwnsBankAccount,
      spouse_owns_bank_account: this.spouseOwnsBankAccount,
      bank_account_holder: this.bankAccountHolder ? this.bankAccountHolder : defaultValue,
      tax_authority: this.taxAuthority || this.taxAuthority === 0 ? this.taxAuthority : defaultValue,
      spouses: this.spouse ? [this.spouse.spouseToResponse(undefined)] : [],
      abroad_income: this.hasAbroadIncome && this.abroadIncome ? this.abroadIncome.toResponse() : defaultValue,
      vollmacht_city: this.vollmachtCity ? this.vollmachtCity : defaultValue,
      vollmacht_date: this.vollmachtDate ? toResponseDate(this.vollmachtDate) : null,
      additional_information: this.additionalInformation ? this.additionalInformation : defaultValue,
      stage_verification: true
    };

    this.personalInfoFullPayload(response, defaultValue);

    if (this.hasAdditionalIncome) {
      this.additionalIncomeSectionPayload(response, defaultValue);
    }
    if (
      this.hasDeductions &&
      (this.serviceType === PackageOptions.paid || this.serviceType === PackageOptions.tax_adviser)
    ) {
      response.deductions = this.deductions ? this.deductions.toResponse() : defaultValue;
      this.addDeductionFlagsToResponse(response);
    } else {
      response.has_deductions = false;
    }
    return response;
  }

  emailPhoneToResponse() {
    const response: StatementResponse = {
      email_address: this.emailAddress,
      phone_number: this.phoneNumber
    };
    return response;
  }

  phoneToResponse() {
    const response: StatementResponse = {
      phone_number: this.phoneNumber,
      spouses: this.spouse ? [this.spouse.spouseToResponse(undefined)] : []
    };
    return response;
  }

  hasChildrenPayload() {
    const response: StatementResponse = {
      has_children: this.hasChildren,
      spouses: this.spouse ? [this.spouse.spouseToResponse(undefined)] : []
    };

    return response;
  }

  deductionStepSkipToResponse(stageTaxReliefs): StatementResponse {
    return {
      stage_tax_reliefs: stageTaxReliefs,
      spouses: this.spouse ? [this.spouse.spouseToResponse(undefined)] : []
    };
  }

  deductionsInfoToResponse(defaultValue = undefined, spouseType = "", deductionFlagName = ""): StatementResponse {
    const response: StatementResponse = {
      spouses: this.spouse ? [this.spouse.spouseToResponse(defaultValue, spouseType, false, deductionFlagName)] : [],
      stage_deduction_info: true
    };

    if (this.hasDeductions) {
      response.deductions = this.deductions ? this.deductions.toResponse(deductionFlagName) : defaultValue;
      this.addDeductionFlagsToResponse(response);
    }
    return response;
  }

  deductionsProfilesToResponse(defaultValue = undefined, spouseType = ""): StatementResponse {
    const response: StatementResponse = {
      spouses: this.spouse ? [this.spouse.spouseToResponse(defaultValue, spouseType)] : []
    };
    if (this.hasDeductions) {
      this.addDeductionFlagsToResponse(response);
    }
    return response;
  }

  yearToResponse(defaultValue = undefined, resetAdditionalIncome = false): StatementResponse {
    const response: StatementResponse = {
      spouses: this.spouse ? [this.spouse.spouseToResponse(defaultValue)] : [],
      statement_year: this.year ? this.year : defaultValue,
      stage_employers: true
    };
    if (resetAdditionalIncome) {
      response.additional_income = [];
      if (response.spouses && response.spouses.length) {
        response.spouses.forEach((spouse) => (spouse.additional_income = []));
      }
    }
    if (this.firstName && this.lastName) {
      response.first_name = this.firstName;
      response.last_name = this.lastName;
    }
    return response;
  }

  newStatementToResponse(defaultValue = undefined): StatementResponse {
    return {
      spouses: this.spouse ? [this.spouse.spouseToResponse(defaultValue)] : [],
      statement_year: this.year ? this.year : defaultValue,
      stage_employers: true,
      first_name: this.firstName ? this.firstName : undefined,
      last_name: this.lastName ? this.lastName : undefined,
      email_address: this.emailAddress ? this.emailAddress : undefined,
      can_be_filled: RecognizerHelper.isBoolean(this.canBeFilled) ? this.canBeFilled : undefined,
      can_be_saved: RecognizerHelper.isBoolean(this.canBeSaved) ? this.canBeSaved : undefined
    };
  }

  incomeInfoToResponse(defaultValue = undefined, spouseType = "", onlySpouse = false): StatementResponse {
    const response: StatementResponse = {};
    response.spouses = this.spouse ? [this.spouse.spouseToResponse(defaultValue, spouseType)] : [];

    if (!onlySpouse) {
      this.additionalIncomeSectionPayload(response, defaultValue);
      response.stage_additional_income = true;
      response.health_benefits_step = this.healthBenefitsStep;
      response.pensions_step = this.pensionsStep;
      response.freelance_job_step = this.freelanceJobStep;
      response.cryptocurrency_step = this.cryptocurrencySteps;
    }

    return response;
  }

  public additionalIncomeApplicantPayload(
    defaultValue = undefined,
    group: SocialSubSteps | PensionSubSteps | FreelanceJobSubSteps | CryptocurrencySubSteps = null
  ): StatementResponse {
    const payload: StatementResponse = {};
    payload.spouses = this.spouse ? [this.spouse.spouseToResponse()] : [];
    this.additionalIncomeBasePayload(payload, defaultValue, group);
    payload.stage_additional_income = true;
    return payload;
  }

  public additionalIncomeSpousePayload(
    defaultValue = undefined,
    group: SocialSubSteps | PensionSubSteps | FreelanceJobSubSteps | CryptocurrencySubSteps
  ): StatementResponse {
    const payload: StatementResponse = {};

    if (!this.spouse) {
      payload.spouses = [];
    } else {
      const spousePayload = this.spouse.spouseToResponse();
      this.spouse.additionalIncomeBasePayload(spousePayload, defaultValue, group);
      spousePayload.stage_additional_income = true;

      payload.spouses = [spousePayload];
    }

    return payload;
  }

  abroadIncomeToResponse(defaultValue = undefined, spouseType = ""): StatementResponse {
    return {
      filed_together: this.filedTogether,
      spouse_calc_income: this.spouseCalcIncome,
      abroad_income: this.abroadIncome ? this.abroadIncome.toResponse() : defaultValue,
      residentAbroadIncome: this.residentAbroadIncome ? this.residentAbroadIncome : [],
      additional_abroad_incomes: this.additional_abroad_incomes ? this.additional_abroad_incomes : [],
      stage_abroad_income: true,
      has_abroad_income: this.hasAbroadIncome,
      spouses: this.spouse ? [this.spouse.spouseToResponse(defaultValue, spouseType)] : []
    };
  }

  spousesToResponse(defaultValue = undefined, spouseType = ""): StatementResponse {
    return {
      spouses: this.spouse ? [this.spouse.spouseToResponse(defaultValue, spouseType)] : []
    };
  }

  spouseToResponse(
    defaultValue = undefined,
    spouseType = "",
    hasSpouseEmployers = true,
    deductionFlagName = ""
  ): StatementResponse {
    const response: StatementResponse = {
      id: this.id ? this.id : defaultValue,
      uuid: this.uuid ? this.uuid : defaultValue
    };

    if (spouseType === "verificationStep") {
      response.deductions = this.hasDeductions ? this.deductions.toResponse(deductionFlagName) : {};
      this.addDeductionFlagsToResponse(response);
      this.additionalIncomeSectionPayload(response, defaultValue);
    } else if (spouseType === "deductionInfo") {
      response.stage_deduction_info = true;
      if (this.hasDeductions) {
        response.deductions = this.deductions ? this.deductions.toResponse(deductionFlagName) : defaultValue;
        this.addDeductionFlagsToResponse(response);
      }
    } else if (spouseType === "deductionsProfiles" && this.hasDeductions) {
      this.addDeductionFlagsToResponse(response);
    } else if (spouseType === "minimalPersonalInfo") {
      this.personalInfoBasePayload(response, defaultValue);
    } else if (spouseType === "fullPersonalInfo") {
      this.personalInfoFullPayload(response, defaultValue);
    } else if (spouseType === "incomeInfo") {
      this.additionalIncomeSectionPayload(response, defaultValue);
      response.stage_additional_income = true;
      response.health_benefits_step = this.healthBenefitsStep;
      response.pensions_step = this.pensionsStep;
      response.freelance_job_step = this.freelanceJobStep;
      response.cryptocurrency_step = this.cryptocurrencySteps;
      response.has_employers = this.hasEmployers;
      response.tax_class = this.taxClass;
    } else if (spouseType === "summary") {
      // TODO: probably unnecessary type "summary"
      this.personalInfoFullPayload(response, defaultValue);
      response.has_employers = this.hasEmployers;
      if (hasSpouseEmployers) {
        response.tax_class = this.taxClass || this.taxClass === 0 ? this.taxClass : defaultValue;
        response.tax_authority = this.taxAuthority || this.taxAuthority === 0 ? this.taxAuthority : defaultValue;
      }
      if (this.hasAdditionalIncome) {
        this.additionalIncomeSectionPayload(response, defaultValue);
      }
      if (this.hasDeductions) {
        response.deductions = this.deductions ? this.deductions.toResponse() : defaultValue;
        this.addDeductionFlagsToResponse(response);
      }
    } else if (spouseType === "hasDeductions") {
      response.has_deductions = this.hasDeductions;
    } else if (spouseType === "stageEmployers") {
      response.stage_employers = true;
    } else if (spouseType === "abroadIncome") {
      response.additional_abroad_incomes = this.additional_abroad_incomes ? this.additional_abroad_incomes : [];
    }

    return response;
  }

  public hasEmployersToResponse(defaultValue = undefined): StatementResponse {
    return {
      has_employers: this.hasEmployers,
      spouses: this.spouse ? [this.spouse.spouseToResponse(defaultValue)] : [],
      stage_employers: true
    };
  }

  public stageEmployersToResponse(spouse = false): StatementResponse {
    return {
      id: this.id,
      spouses: this.spouse ? [this.spouse.spouseToResponse(undefined, spouse ? "stageEmployers" : "")] : [],
      stage_employers: spouse ? undefined : true
    };
  }

  public packageSelectionStepPayload(): StatementResponse {
    return {
      service_type: this.serviceType,
      stage_package_selection: true,
      spouses: this.spouse ? [{ id: this.spouse.id }] : []
    };
  }

  public confirmStatementToResponse(
    data: CategoryProperties[],
    dataSpouse: CategoryProperties[],
    additionalComment: string
  ): Record<string, unknown> {
    let resultObject = {};
    let reportError = false;

    data.forEach((el) => {
      if (el.reportError) {
        reportError = true;
      }
      resultObject = { ...resultObject, [el.key]: el.reportError ? el.errorMessage : "" };
    });

    dataSpouse.forEach((el) => {
      if (el.reportError) {
        reportError = true;
      }
      resultObject = { ...resultObject, ["spouse_" + el.key]: el.reportError ? el.errorMessage : "" };
    });

    return {
      ...resultObject,
      additional_comment: reportError ? additionalComment : "",
      reportError: reportError
    };
  }

  public get isStandardPackage(): boolean {
    return this.serviceType === PackageOptions.paid;
  }

  public get isPremiumPackage(): boolean {
    return this.serviceType === PackageOptions.tax_adviser;
  }

  public setStandardPackage(): void {
    if (this.isStandardPackageAvailable) {
      this.serviceType = PackageOptions.paid;
    }
  }

  public setPremiumPackage(): void {
    if (this.isPremiumPackageAvailable) {
      this.serviceType = PackageOptions.tax_adviser;
    }
  }

  public get isPremiumPackageAvailable(): boolean {
    return this.fullYear !== 2016;
  }

  isCreated() {
    return this.status === StatementStatuses.CREATED;
  }

  isProcessing() {
    return this.status === StatementStatuses.PROCESSING;
  }

  isUpdated() {
    return this.status === StatementStatuses.UPDATED;
  }

  isReady() {
    return this.status === StatementStatuses.READY;
  }

  isSent() {
    return this.status === StatementStatuses.SENT;
  }

  statusPaidReady() {
    return this.status === StatementStatuses.PAID_READY;
  }

  get isForClientApproval() {
    return this.status === StatementStatuses.CLIENT_APPROVAL;
  }

  get isApproved(): boolean {
    return this.status === StatementStatuses.APPROVED;
  }

  get isReportedErrors(): boolean {
    return this.status === StatementStatuses.REPORTED_ERRORS;
  }

  isSpouse() {
    return this.status === StatementStatuses.SPOUSE || this.isSpouseProcessing;
  }

  get isJointStatement(): boolean {
    return (this.spouse || this.isSpouse()) && this.filedTogether;
  }

  public get isSpouseProcessing() {
    return this.status === StatementStatuses.SPOUSE_PROCESSING;
  }

  getFullname() {
    if (this.firstName && this.lastName) {
      return this.firstName + " " + this.lastName;
    } else {
      return "";
    }
  }

  addfreelanceJobStage(): void {
    this.freelanceJobs.push(new FreelanceJob());
  }

  removeFreelanceJob(index: number): void {
    this.freelanceJobs.splice(index, 1);
  }

  addCryptoCurrencyStage(): void {
    this.cryptocurrencyIncome.push(new CryptocurrencyIncome());
  }

  removeCryptoCurrencyStage(index: number): void {
    this.cryptocurrencyIncome.splice(index, 1);
  }

  addAdditionalIncomeStage() {
    this.additionalIncome.push(new SocialIncome());
  }

  removeAdditionalIncome(index) {
    this.additionalIncome.splice(index, 1);
  }

  addPensionIncomeStage() {
    this.pensionIncome.push(new PensionIncome());
  }

  removePensionIncome(index) {
    this.pensionIncome.splice(index, 1);
  }

  getEmployersIds() {
    return this.employers.map((employer) => employer.id);
  }

  getAbsoluteRefund(): string {
    return Math.abs(this.estimatedRefund).toFixed(2);
  }

  totalAbroadIncome(currencies: Currency[]): number {
    return this.abroadIncome ? this.abroadIncome.totalAbroadIncome(currencies, this.fullYear) : 0;
  }

  passes90Condition(currencies: Currency[]): boolean {
    if (!this.abroadIncome) {
      return true;
    }
    return (
      10 * (this.germanTotalIncome + Number(this.spouseCalcIncome)) >=
      9 * (this.germanTotalIncome + Number(this.spouseCalcIncome) + this.totalAbroadIncome(currencies))
    );
  }

  getCurrentTotalIncomeThreshold(selectsData: SelectsDataInterface): number {
    const currentChildTaxCredit = selectsData.childTaxCredits[this.fullYear] || 0;
    const currentThreshold = selectsData.totalIncomeThresholds[this.fullYear] || 0;
    let persons = 1;
    const childrenCount = this.children.length;
    let childTaxCreditRatio = 0.5;
    if (this.spouse) {
      persons += 1;
      childTaxCreditRatio = 1;
    }
    return currentThreshold * persons + childrenCount * childTaxCreditRatio * currentChildTaxCredit;
  }

  checkIncomeSummaryUnderTaxBracketForDeductions(selectsData: SelectsDataInterface): boolean {
    return this.getCurrentTotalIncomeThreshold(selectsData) > this.getIncomeSummary(selectsData.currencies);
  }

  getIncomeSummary(currencies: Currency[]): number {
    return this.germanTotalIncome + Number(this.spouseCalcIncome) + this.totalAbroadIncome(currencies);
  }

  ibanToResponse(iban) {
    return typeof iban === "string" ? iban.split(" ").join("").toUpperCase() : "";
  }

  resetDeductionsFlags() {
    this.hasEquipmentCost = false;
    this.hasPremiumForTradeUnions = false;
    this.hasRemovalExpanses = false;
    this.hasHomeOffice = false;
    this.hasBudgetaryEmploymentCost = false;
    this.hasDoubleHousehold = false;
    this.hasWorkTripCost = false;
    this.hasRiester = false;
    this.hasPrivateInsurance = false;
    this.hasPublicInsurance = false;
    this.hasForeignInsurance = false;
    this.hasRecruitmentCost = false;
    this.hasBusinessTrips = false;
    this.hasForeignSocialInsurance = false;
    this.hasProffesionalInsurance = false;
    this.hasTaxSettlementCost = false;
    this.hasPhoneCost = false;
    this.hasWinterbauCost = false;
    this.hasOtherCost = false;
    this.hasDonation = false;
    this.hasDonationsDe = false;
    this.hasDonationsEu = false;
    this.hasDonationsPolitical = false;
  }

  public get spouseHasEmployersToResponse(): StatementResponse {
    const response: StatementResponse = {
      ...this.spousesToResponse()
    };

    if (this.spouse.employers && this.spouse.employers.length) {
      this.spouse.taxClass = this.spouse.employers[this.spouse.employers.length - 1].taxClass;
    }

    response.spouses.forEach((spouse) => {
      spouse.id = this.spouse.id;
      spouse.has_employers = this.spouse.hasEmployers;
      spouse.stage_employers = true;
      spouse.tax_class = this.spouse.taxClass;
    });

    return response;
  }

  public get stagePersonalInfoChildrenToResponse() {
    const response: StatementResponse = {
      id: this.id,
      spouses: this.spouse ? [this.spouse.spouseToResponse(undefined, "")] : [],
      stage_personal_info_children: true
    };

    return response;
  }

  public get stageDeductionInfoChildrenToResponse() {
    const response: StatementResponse = {
      id: this.id,
      spouses: this.spouse ? [this.spouse.spouseToResponse(undefined, "")] : [],
      stage_deduction_info_children: true
    };

    return response;
  }

  public employerDateRange(): number {
    if (!this.employers || this.employers.length === 0) {
      return 0;
    }

    let germanWorkTime = 0;

    this.employers.forEach((emp) => {
      if (emp.periodOfEmployment && emp.periodOfEmployment.lower && emp.periodOfEmployment.upper) {
        const lower = new Date(emp.periodOfEmployment.lower);
        const upper = new Date(emp.periodOfEmployment.upper);
        const timeDif = upper.valueOf() - lower.valueOf();
        germanWorkTime += timeDif / 1000 / 60 / 60 / 24;
      }
    });

    return germanWorkTime;
  }

  public get employerDateRangeStatus(): boolean {
    return this.employerDateRange() > 183;
  }

  public updateHasAdditionalIncomeFlag(): void {
    this.hasAdditionalIncome =
      ArrayHelper.isArrayAndNotEmpty(this.additionalIncome) ||
      ArrayHelper.isArrayAndNotEmpty(this.pensionIncome) ||
      ArrayHelper.isArrayAndNotEmpty(this.freelanceJobs) ||
      ArrayHelper.isArrayAndNotEmpty(this.cryptocurrencyIncome);
  }

  public updateDeductionsFlag(options: { updateHasDeductions?: boolean } = {}): void {
    const updateHasDeductions = options.updateHasDeductions || false;

    this.hasDonationsDe =
      RecognizerHelper.isTrue(this.deductions.donationDeName) &&
      RecognizerHelper.isTrue(this.deductions.donationDeValue);

    this.hasDonationsEu =
      RecognizerHelper.isTrue(this.deductions.donationEuName) &&
      RecognizerHelper.isTrue(this.deductions.donationEuValue);

    this.hasDonationsPolitical =
      RecognizerHelper.isTrue(this.deductions.donationPoliticalName) &&
      RecognizerHelper.isTrue(this.deductions.donationPoliticalValue);

    this.hasHouseHoldMiniJobsCosts =
      RecognizerHelper.isTrue(this.deductions.houseHoldMiniJobsCostsNames) &&
      RecognizerHelper.isTrue(this.deductions.houseHoldMiniJobsCosts);

    this.hasHouseHoldServicesCosts =
      RecognizerHelper.isTrue(this.deductions.houseHoldServicesCostsNames) &&
      RecognizerHelper.isTrue(this.deductions.houseHoldServicesCosts);

    this.hasCraftsmanServicesCosts =
      RecognizerHelper.isTrue(this.deductions.craftsmanServicesCostsNames) &&
      RecognizerHelper.isTrue(this.deductions.craftsmanServicesCosts);

    this.hasRemovalExpanses =
      (RecognizerHelper.isTrue(this.deductions.movingExpansesCostsNames) &&
        RecognizerHelper.isTrue(this.deductions.movingExpansesCosts)) ||
      this.deductions.haveMovingExpanses;

    this.hasDoubleHousehold = !!RecognizerHelper.isTrue(this.deductions.householdPostalCode);
    this.deductions.haveSeparatedHousehold = this.hasDoubleHousehold;

    this.hasEquipmentCost =
      !!this.deductions.haveJobCostsLumpSum ||
      !!this.deductions.purchasedJobEquipment ||
      !!this.deductions.haveWorkwear ||
      !!this.deductions.haveTrainingCosts;

    this.hasPremiumForTradeUnions =
      RecognizerHelper.isTrue(this.deductions.tradeUnionNames) &&
      RecognizerHelper.isTrue(this.deductions.tradeUnionContributions);

    this.hasHomeOffice =
      (RecognizerHelper.isTrue(this.deductions.homeOfficeCostsNames) &&
        RecognizerHelper.isTrue(this.deductions.homeOfficeCosts)) ||
      (RecognizerHelper.isTrue(this.deductions.homeOfficeCovid19) &&
        RecognizerHelper.isTrue(this.deductions.homeOfficeCovid19DayInHome));

    this.hasRecruitmentCost = RecognizerHelper.isTrue(this.deductions.recruitmentCosts);

    this.hasWorkTripCost =
      RecognizerHelper.isTrue(this.deductions.absencesAtWork) ||
      RecognizerHelper.isTrue(this.deductions.presencesAtWork) ||
      RecognizerHelper.isTrue(this.deductions.publicTransportCosts) ||
      RecognizerHelper.isTrue(this.deductions.placeOfEmploymentAddress) ||
      RecognizerHelper.isTrue(this.deductions.periodOfEmployment) ||
      RecognizerHelper.isTrue(this.deductions.workingDaysPerWeek) ||
      RecognizerHelper.isTrue(this.deductions.distanceFromWork);

    this.hasBusinessTrips =
      (RecognizerHelper.isTrue(this.deductions.haveCarTravels) &&
        RecognizerHelper.isTrue(this.deductions.carTravelsDistance) &&
        RecognizerHelper.isTrue(this.deductions.carTravelsNumber)) ||
      (RecognizerHelper.isTrue(this.deductions.purchasedTravelTickets) &&
        RecognizerHelper.isTrue(this.deductions.travelTicketsCosts)) ||
      (RecognizerHelper.isTrue(this.deductions.accommodationCosts) &&
        RecognizerHelper.isTrue(this.deductions.haveAccommodationCosts)) ||
      (RecognizerHelper.isTrue(this.deductions.employerRefunds) &&
        RecognizerHelper.isTrue(this.deductions.haveEmployerRefunds));

    this.hasFreeMealsOnTravel =
      RecognizerHelper.isTrue(this.deductions.daysInMultiDayTravel) ||
      RecognizerHelper.isTrue(this.deductions.daysInOneDayTravel) ||
      RecognizerHelper.isTrue(this.deductions.daysInTravelToWork) ||
      RecognizerHelper.isTrue(this.deductions.daysInTravelFromWork) ||
      RecognizerHelper.isTrue(this.deductions.freeMealsDietAmount) ||
      RecognizerHelper.isTrue(this.deductions.haveRegularFreeMealsOnTravel) ||
      RecognizerHelper.isTrue(this.deductions.haveFreeMealsOnTravel) ||
      RecognizerHelper.isTrue(this.deductions.mealsInTravel) ||
      RecognizerHelper.isTrue(this.deductions.mealsInOneDayTravel);

    this.hasFreeMealsOnTravelAbroad =
      RecognizerHelper.isTrue(this.deductions.daysInMultiDayTravelAbroad) ||
      RecognizerHelper.isTrue(this.deductions.daysInOneDayTravelAbroad) ||
      RecognizerHelper.isTrue(this.deductions.daysInTravelAbroadToWork) ||
      RecognizerHelper.isTrue(this.deductions.daysInTravelAbroadFromWork) ||
      RecognizerHelper.isTrue(this.deductions.freeMealsDietAmountAbroad) ||
      RecognizerHelper.isTrue(this.deductions.haveFreeMealsOnTravelAbroad) ||
      RecognizerHelper.isTrue(this.deductions.mealsInTravelAbroad) ||
      RecognizerHelper.isTrue(this.deductions.mealsInOneDayTravelAbroad) ||
      RecognizerHelper.isTrue(this.deductions.travelAbroadCity) ||
      RecognizerHelper.isTrue(this.deductions.travelAbroadCountry) ||
      RecognizerHelper.isTrue(this.deductions.haveRegularFreeMealsOnTravelAbroad);

    this.updateHaveRiester();

    this.hasPrivateInsurance =
      !!Number(this.deductions.additionalLongTermCareInsurance) ||
      !!Number(this.deductions.contributionsToPrivateHealthInsurance) ||
      !!Number(this.deductions.healthAndPrivateInsuranceContributionsGrants) ||
      !!Number(this.deductions.healthAndPrivateInsuranceContributionsRefunds) ||
      !!Number(this.deductions.privateElectiveAndSupplementaryInsurance) ||
      !!Number(this.deductions.privateLongTermCareInsuranceContributions);

    this.hasPublicInsurance =
      !!Number(this.deductions.contributionsToHealthInsurance) ||
      !!Number(this.deductions.contributionsToSicknessBenefit) ||
      !!Number(this.deductions.electiveAndSupplementaryInsurance) ||
      !!Number(this.deductions.healthAndInsuranceContributionsGrants) ||
      !!Number(this.deductions.healthAndInsuranceContributionsRefunds) ||
      !!Number(this.deductions.longTermCareInsuranceContributions) ||
      !!Number(this.deductions.sicknessBenefitReimbursements);

    this.hasForeignInsurance =
      !!Number(this.deductions.contributionsToForeignHealthInsurance) ||
      !!Number(this.deductions.contributionsToForeignSicknessBenefit) ||
      !!Number(this.deductions.foreignElectiveAndSupplementaryInsurance) ||
      !!Number(this.deductions.foreignLongTermCareInsuranceContributions) ||
      !!Number(this.deductions.foreignSicknessBenefitReimbursements) ||
      !!Number(this.deductions.healthAndForeignInsuranceContributionsRefunds);

    this.hasForeignSocialInsurance =
      !!Number(this.deductions.foreignSocialInsuranceEmployeeContributions) ||
      !!Number(this.deductions.foreignSocialInsuranceEmployerContributions);

    this.hasProffesionalInsurance =
      RecognizerHelper.isTrue(this.deductions.professionInsuranceCostNames) &&
      RecognizerHelper.isTrue(this.deductions.professionInsuranceCosts);

    this.hasHealthCosts =
      RecognizerHelper.isTrue(this.deductions.healthCostsNames) ||
      RecognizerHelper.isTrue(this.deductions.healthCosts) ||
      RecognizerHelper.isTrue(this.deductions.healthRefundAmount);

    this.deductions.haveHealthCosts = this.hasHealthCosts;

    this.hasCareCosts =
      RecognizerHelper.isTrue(this.deductions.careCostsNames) ||
      RecognizerHelper.isTrue(this.deductions.careCosts) ||
      RecognizerHelper.isTrue(this.deductions.careRefundAmount);

    this.deductions.haveCareCosts = this.hasCareCosts;

    this.hasDisability =
      !!this.deductions.applyingForDisabilityWithoutLump ||
      (!!this.deductions.applyingForDisabilityWithLump &&
        (!!this.deductions.requireConstantCare ||
          !!this.deductions.mobilityImpaired ||
          RecognizerHelper.isTrue(this.deductions.disabilityPercent) ||
          RecognizerHelper.isTrue(this.deductions.disabilityFrom) ||
          RecognizerHelper.isTrue(this.deductions.disabilityTo) ||
          RecognizerHelper.isTrue(this.deductions.disabilityCostsNames) ||
          RecognizerHelper.isTrue(this.deductions.disabilityCosts) ||
          RecognizerHelper.isTrue(this.deductions.disabilityRefundAmount)));

    this.deductions.haveDisability = this.hasDisability;

    // TODO: check if it is needed
    // this.deductions.hasDisabilityCarePflegegrad = !!this.deductions.disabilityPflegegrad
    //   || !!this.deductions.disabledPersonData;

    this.hasTaxSettlementCost =
      RecognizerHelper.isTrue(this.deductions.taxSettlementCostNames) &&
      RecognizerHelper.isTrue(this.deductions.taxSettlementCosts);

    this.hasPhoneCost = RecognizerHelper.isTrue(this.deductions.phoneAndInternetCosts);

    this.hasWinterbauCost =
      RecognizerHelper.isTrue(this.deductions.winterbauCostNames) &&
      RecognizerHelper.isTrue(this.deductions.winterbauCosts);

    this.hasBankCost = RecognizerHelper.isTrue(this.deductions.bankCosts);

    this.hasOtherCost =
      RecognizerHelper.isTrue(this.deductions.otherRecruitmentCostNames) ||
      RecognizerHelper.isTrue(this.deductions.otherRecruitmentCosts);

    if (!this.hasDonationsDe) {
      this.deductions.deleteDeductionByFlag("hasDonationsDe");
    }
    if (!this.hasDonationsEu) {
      this.deductions.deleteDeductionByFlag("hasDonationsEu");
    }
    if (!this.hasDonationsPolitical) {
      this.deductions.deleteDeductionByFlag("hasDonationsPolitical");
    }
    if (!this.hasHouseHoldMiniJobsCosts) {
      this.deductions.deleteDeductionByFlag("hasHouseHoldMiniJobsCosts");
    }
    if (!this.hasHouseHoldServicesCosts) {
      this.deductions.deleteDeductionByFlag("hasHouseHoldServicesCosts");
    }
    if (!this.hasCraftsmanServicesCosts) {
      this.deductions.deleteDeductionByFlag("hasCraftsmanServicesCosts");
    }
    if (!this.hasRemovalExpanses) {
      this.deductions.deleteDeductionByFlag("hasRemovalExpanses");
    }
    if (!this.hasDoubleHousehold) {
      this.deductions.deleteDeductionByFlag("hasDoubleHousehold");
    }
    if (!this.hasEquipmentCost) {
      this.deductions.deleteDeductionByFlag("hasEquipmentCost");
    }
    if (!this.hasPremiumForTradeUnions) {
      this.deductions.deleteDeductionByFlag("hasPremiumForTradeUnions");
    }
    if (!this.hasHomeOffice) {
      this.deductions.deleteDeductionByFlag("hasHomeOffice");
    }
    if (!this.hasRecruitmentCost) {
      this.deductions.deleteDeductionByFlag("hasRecruitmentCost");
    }
    if (!this.hasWorkTripCost) {
      this.deductions.deleteDeductionByFlag("hasWorkTripCost");
    }
    if (!this.hasBusinessTrips) {
      this.deductions.deleteDeductionByFlag("hasBusinessTrips");
    }
    if (!this.hasFreeMealsOnTravel) {
      this.deductions.deleteDeductionByFlag("hasFreeMealsOnTravel");
    }
    if (!this.hasFreeMealsOnTravelAbroad) {
      this.deductions.deleteDeductionByFlag("hasFreeMealsOnTravelAbroad");
    }
    if (!this.hasRiester) {
      this.deductions.deleteDeductionByFlag("hasRiester");
    }
    if (!this.hasPrivateInsurance) {
      this.deductions.deleteDeductionByFlag("hasPrivateInsurance");
    }
    if (!this.hasPublicInsurance) {
      this.deductions.deleteDeductionByFlag("hasPublicInsurance");
    }
    if (!this.hasForeignInsurance) {
      this.deductions.deleteDeductionByFlag("hasForeignInsurance");
    }
    if (!this.hasForeignSocialInsurance) {
      this.deductions.deleteDeductionByFlag("hasForeignSocialInsurance");
    }
    if (!this.hasProffesionalInsurance) {
      this.deductions.deleteDeductionByFlag("hasProffesionalInsurance");
    }
    if (!this.hasHealthCosts) {
      this.deductions.deleteDeductionByFlag("hasHealthCosts");
    }
    if (!this.hasCareCosts) {
      this.deductions.deleteDeductionByFlag("hasCareCosts");
    }
    if (!this.hasDisability) {
      this.deductions.deleteDeductionByFlag("hasDisability");
    }
    if (!this.deductions.hasDisabilityCarePflegegrad) {
      this.deductions.deleteDeductionByFlag(DeductionHealthSteps.DISABILITY_CARE_PFLEGEGRAD);
    }
    if (!this.hasTaxSettlementCost) {
      this.deductions.deleteDeductionByFlag("hasTaxSettlementCost");
    }
    if (!this.hasPhoneCost) {
      this.deductions.deleteDeductionByFlag("hasPhoneCost");
    }
    if (!this.hasWinterbauCost) {
      this.deductions.deleteDeductionByFlag("hasWinterbauCost");
    }
    if (!this.hasBankCost) {
      this.deductions.deleteDeductionByFlag("hasBankCost");
    }
    if (!this.hasOtherCost) {
      this.deductions.deleteDeductionByFlag("hasOtherCost");
    }

    if (updateHasDeductions) {
      this.hasDeductions =
        this.hasDonationsDe ||
        this.hasDonationsEu ||
        this.hasDonationsPolitical ||
        this.hasHouseHoldMiniJobsCosts ||
        this.hasHouseHoldServicesCosts ||
        this.hasCraftsmanServicesCosts ||
        this.hasRemovalExpanses ||
        this.hasDoubleHousehold ||
        this.hasEquipmentCost ||
        this.hasPremiumForTradeUnions ||
        this.hasHomeOffice ||
        this.hasRecruitmentCost ||
        this.hasWorkTripCost ||
        this.hasBusinessTrips ||
        this.hasFreeMealsOnTravel ||
        this.hasFreeMealsOnTravelAbroad ||
        this.hasRiester ||
        this.hasPrivateInsurance ||
        this.hasPublicInsurance ||
        this.hasForeignInsurance ||
        this.hasForeignSocialInsurance ||
        this.hasProffesionalInsurance ||
        this.hasHealthCosts ||
        this.hasCareCosts ||
        this.hasDisability ||
        this.deductions.hasDisabilityCarePflegegrad ||
        this.hasTaxSettlementCost ||
        this.hasPhoneCost ||
        this.hasWinterbauCost ||
        this.hasBankCost ||
        this.hasOtherCost ||
        !!this.deductions.driverLumpsum;
    }
  }

  public updateHaveRiester(): void {
    this.deductions.haveRiester =
      RecognizerHelper.isTrue(this.deductions.socialSecurityNumber) ||
      RecognizerHelper.isTrue(this.deductions.retirementFundNumber) ||
      RecognizerHelper.isTrue(this.deductions.indirectlyFavored) ||
      !!Number(this.deductions.agreementNumbers) ||
      !!Number(this.deductions.contributionsAmount) ||
      !!Number(this.deductions.lastYearIncome);
    this.hasRiester = this.deductions.haveRiester;
  }

  public setDefaultCurrency(dataSelects: SelectsDataInterface): void {
    const currencyId = this.abroadIncome.abroadIncomeCurrency;
    const spouseCurrencyId = this.abroadIncome.spouseAbroadIncomeCurrency;

    if (dataSelects.currencies && dataSelects.currencies.length) {
      const eur = Currency.findEUR(dataSelects.currencies);
      const eur_id = eur ? eur.id : dataSelects.currencies[0].id;

      if (!(currencyId || currencyId === 0)) {
        this.abroadIncome.abroadIncomeCurrency = eur_id;
      }
      if (!(spouseCurrencyId || spouseCurrencyId === 0)) {
        this.abroadIncome.spouseAbroadIncomeCurrency = eur_id;
      }
    }
  }

  public check90ConditionOrSmallAbroadIncome(dataSelects: SelectsDataInterface) {
    let threshold = this.getCountryAbroadIncomeThreshold(dataSelects);

    if (this.spouse) {
      if (this.abroadIncome && this.abroadIncome.spouseLiveAbroad) {
        threshold += this.getCountryAbroadIncomeThreshold(dataSelects, true);
      } else {
        threshold *= 2;
      }
    }

    const abroadIncome = this.totalAbroadIncome(dataSelects.currencies);
    return this.passes90Condition(dataSelects.currencies) || abroadIncome < threshold;
  }

  public getCountryAbroadIncomeThreshold(dataSelects: SelectsDataInterface, isSpouse = false): number {
    const countryCode = this.abroadIncome
      ? isSpouse
        ? this.abroadIncome.spouseAbroadIncomeCountry
        : this.abroadIncome.abroadIncomeCountry
      : 0;

    if (!dataSelects.abroadIncomeThresholds) {
      return 0;
    }

    const thresholds = dataSelects.abroadIncomeThresholds[this.fullYear];
    return thresholds && thresholds[countryCode] ? thresholds[countryCode] : 0;
  }

  public canChangeTaxYear(newFullYear: number): boolean {
    return !(this.fullYear >= 2019 && newFullYear < 2019 && !!this.additionalIncome && !!this.additionalIncome.length);
  }

  public get identificationNumberIsMissing(): boolean {
    const applicant = !this.identifikationsnummer && !this.hasUploadedFile && !this.hasNoId;
    const spouse =
      !!this.spouse && !this.spouse.identifikationsnummer && !this.spouse.hasUploadedFile && !this.spouse.hasNoId;

    return applicant || spouse;
  }

  public get firstEmployerId(): number {
    return this.employers && this.employers.length ? this.employers[0].id : 0;
  }

  public get spouseId(): number {
    return this.spouse ? this.spouse.id : 0;
  }

  public get isSpouseDeductionRiesterNeeded(): boolean {
    return (
      !!this.spouse &&
      this.deductions.haveRiester &&
      this.deductions.indirectlyFavored &&
      (!this.spouse.deductions || !this.spouse.deductions.haveRiester)
    );
  }

  public get hasExtraIncomeOption(): boolean {
    return (
      this.hasRentalIncome ||
      this.hasCryptocurrencyIncome ||
      this.hasFarmingIncome ||
      this.hasCapitalIncome ||
      this.hasRealestateIncome ||
      this.paysFamilySupport ||
      this.photovoltaicInstallationCosts
    );
  }

  public get isAddressCountryGermany(): boolean {
    return this.country === "DE";
  }

  public get isLiveInGermanyFieldRequired(): boolean {
    return !this.isAddressCountryGermany && !this.isSpouse();
  }

  private updatePensionIncomeFieldsShown(): void {
    this.pensionIncome.forEach((el) => el.incomeStartChange(this));
  }

  private addDeductionFlagsToResponse(response: any) {
    response.stage_deduction_info = this.stageDeductionInfo;
    response.has_equipment_cost = this.hasEquipmentCost;
    response.has_premium_for_trade_unions = this.hasPremiumForTradeUnions;
    response.has_removal_expanses = this.hasRemovalExpanses;
    response.has_home_office = this.hasHomeOffice;
    response.has_budgetary_employment_cost = this.hasBudgetaryEmploymentCost;
    response.has_double_household = this.hasDoubleHousehold;
    response.has_work_trip_cost = this.hasWorkTripCost;
    response.has_riester = this.hasRiester;
    response.has_private_insurance = this.hasPrivateInsurance;
    response.has_public_insurance = this.hasPublicInsurance;
    response.has_foreign_insurance = this.hasForeignInsurance;
    response.has_foreign_social_insurance = this.hasForeignSocialInsurance;
    response.has_recruitment_cost = this.hasRecruitmentCost;
    response.has_business_trips = this.hasBusinessTrips;
    response.has_house_hold_mini_jobs_costs = this.hasHouseHoldMiniJobsCosts;
    response.has_house_hold_services_costs = this.hasHouseHoldServicesCosts;
    response.has_craftsman_services_costs = this.hasCraftsmanServicesCosts;
    response.has_free_meals_on_travel = this.hasFreeMealsOnTravel;
    response.has_free_meals_on_travel_abroad = this.hasFreeMealsOnTravelAbroad;
    response.has_tax_settlement_cost = this.hasTaxSettlementCost;
    response.has_phone_cost = this.hasPhoneCost;
    response.has_winterbau_cost = this.hasWinterbauCost;
    response.has_bank_cost = this.hasBankCost;
    response.has_other_cost = this.hasOtherCost;
    response.has_proffesional_insurance = this.hasProffesionalInsurance;
    response.has_deductions = this.hasDeductions;
    response.has_donations_de = this.hasDonationsDe;
    response.has_donations_eu = this.hasDonationsEu;
    response.has_donations_political = this.hasDonationsPolitical;
    response.has_health_costs = this.hasHealthCosts;
    response.has_care_costs = this.hasCareCosts;
    response.has_disability = this.hasDisability;
  }

  private additionalIncomeBasePayload(
    payload: StatementResponse,
    defaultValue = undefined,
    group: SocialSubSteps | PensionSubSteps | FreelanceJobSubSteps | CryptocurrencySubSteps = null
  ): void {
    if (Object.values(SocialSubSteps).includes(group as SocialSubSteps)) {
      this.socialIncomePayload(payload, defaultValue);
    }
    if (Object.values(PensionSubSteps).includes(group as PensionSubSteps)) {
      this.pensionIncomePayload(payload, defaultValue);
    }
    if (Object.values(FreelanceJobSubSteps).includes(group as FreelanceJobSubSteps)) {
      this.freelanceIncomePayload(payload, defaultValue);
    }
    if (Object.values(CryptocurrencySubSteps).includes(group as CryptocurrencySubSteps)) {
      this.cryptocurrencyIncomePayload(payload, defaultValue);
    }

    payload.stage_additional_income = true;
    payload.health_benefits_step = this.healthBenefitsStep;
    payload.pensions_step = this.pensionsStep;
    payload.freelance_job_step = this.freelanceJobStep;
    payload.cryptocurrency_step = this.cryptocurrencySteps;
  }

  private additionalIncomeSectionPayload(payload: StatementResponse, defaultValue = undefined): void {
    this.socialIncomePayload(payload, defaultValue);
    this.pensionIncomePayload(payload, defaultValue);
    this.freelanceIncomePayload(payload, defaultValue);
    this.cryptocurrencyIncomePayload(payload, defaultValue);
  }

  private socialIncomePayload(payload: StatementResponse, defaultValue = undefined): void {
    this.updateHasAdditionalIncomeFlag();
    payload.has_additional_income = this.hasAdditionalIncome;
    payload.additional_income = ArrayHelper.isArrayAndNotEmpty(this.additionalIncome)
      ? this.additionalIncome.map((job) => job.payload(defaultValue))
      : [];
  }

  private pensionIncomePayload(payload: StatementResponse, defaultValue = undefined): void {
    this.updateHasAdditionalIncomeFlag();
    payload.has_additional_income = this.hasAdditionalIncome;
    payload.pension_income = ArrayHelper.isArrayAndNotEmpty(this.pensionIncome)
      ? this.pensionIncome.map((job) => job.payload(defaultValue))
      : [];
  }

  private freelanceIncomePayload(payload: StatementResponse, defaultValue = undefined): void {
    this.updateHasAdditionalIncomeFlag();
    payload.has_additional_income = this.hasAdditionalIncome;
    payload.freelance_jobs = ArrayHelper.isArrayAndNotEmpty(this.freelanceJobs)
      ? this.freelanceJobs.map((job) => job.payload(defaultValue))
      : [];
  }

  private cryptocurrencyIncomePayload(payload: StatementResponse, defaultValue = undefined): void {
    this.updateHasAdditionalIncomeFlag();
    payload.has_additional_income = this.hasAdditionalIncome;
    payload.cryptocurrency_income = ArrayHelper.isArrayAndNotEmpty(this.cryptocurrencyIncome)
      ? this.cryptocurrencyIncome.map((job) => job.payload(defaultValue))
      : [];
  }

  private personalInfoBasePayload(payload: StatementResponse, defaultValue = undefined): void {
    payload.first_name = this.firstName || defaultValue;
    payload.last_name = this.lastName || defaultValue;
    payload.birth_date = this.birthDate ? toResponseDate(this.birthDate) : defaultValue;
    payload.religion = this.religion || this.religion === 0 ? this.religion : defaultValue;
    payload.stage_personal_info = true;
    payload.citizenship = this.citizenship || defaultValue;
    // payload.live_in_germany = this.liveInGermany; //Code was commented becouse of task TAX-2984 In that task we dont want to validate and display on front select box with info if person live_in_germany
  }

  private personalInfoFullPayload(payload: StatementResponse, defaultValue = undefined): void {
    this.personalInfoBasePayload(payload, defaultValue);
    payload.street = this.street || defaultValue;
    payload.postal_code = this.postalCode || defaultValue;
    payload.city = this.city || defaultValue;
    payload.house_number_with_supplement = this.houseNumberWithSupplement || defaultValue;
    payload.additional_address_info = this.additionalAddressInfo || defaultValue;
    payload.tax_class = this.taxClass || this.taxClass === 0 ? this.taxClass : defaultValue;
    payload.country = this.country || this.country === "" ? this.country : defaultValue;
    payload.identifikationsnummer = this.identifikationsnummer || defaultValue;
    payload.steuernummer = this.steuernummer || defaultValue;
    payload.email_address = this.emailAddress || defaultValue;
    payload.phone_number =
      this.phoneNumber || (this.isSpouse() || this.isStandardPackage || !this.stagePackageSelection ? undefined : "");
  }
}
