import { PathParamKeys } from 'app/core/workflow/page-path/path-param-keys';
import { BaseStatementPagePath } from 'app/core/workflow/page-path/statement-page-path/base-statement-page.path';

export class PersonalInfoChildAdultComponentPath extends BaseStatementPagePath {
  public static path(): string {
    return `statement/:${PathParamKeys.statementId}/children/:${PathParamKeys.childId}/adult/personal-info`;
  }

  public static regex(step?: number): RegExp {
    return this.componentRegex(this.path(), null, step);
  }
}
