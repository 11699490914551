<div class="tooltip-voluntering-income">
  <div class="list-box row">
    <h3 class="list-box-title">{{'ADDITIONAL_INCOME.FREELANCE_GROUP.VOLUNTERING_INCOME.TOOLTIP.LIST_1.TITLE' | translate}}</h3>
    <ul class="list">
      <li><span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.VOLUNTERING_INCOME.TOOLTIP.LIST_1.ELEMENT_1' | translate"></span></li>
      <li><span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.VOLUNTERING_INCOME.TOOLTIP.LIST_1.ELEMENT_2' | translate"></span></li>
    </ul>
  </div>
  <div class="list-box row">
    <h3 class="list-box-title">{{'ADDITIONAL_INCOME.FREELANCE_GROUP.VOLUNTERING_INCOME.TOOLTIP.LIST_2.TITLE' | translate}}</h3>
    <ul class="list">
      <li><span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.VOLUNTERING_INCOME.TOOLTIP.LIST_2.ELEMENT_1' | translate"></span></li>
      <li><span [innerHtml]="'ADDITIONAL_INCOME.FREELANCE_GROUP.VOLUNTERING_INCOME.TOOLTIP.LIST_2.ELEMENT_2' | translate"></span></li>
    </ul>
  </div>
</div>
