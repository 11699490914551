<div #accordionHeader class="accordion" [ngClass]="{'accordion--collapsed': isCollapsed,
                                   'accordion--framed': titleFrame,
                                   'accordion--transparent': transparentBg,
                                   'accordion--small': isSmall,
                                   'accordion--no-padding': noPadding}">
    <div class="accordion__header level is-mobile" (click)="toggleCollapse()" *ngIf="showHeader">
        <div class="level-left" [class.flexbox]="showActiveNr">
            <div class="level-item">
                <div class="accordion__title is-bold is-medium">
                    <span class="preIndex" *ngIf="preIndex">{{ preIndex }}.</span>
                    <span *ngIf="translateTitle">
                        <ng-container *ngIf="!titleChildTranslate && !isChildVerification">
                            {{ title | translate:{ prefix: prefix, postfix: postfix } }}
                        </ng-container>
                        <ng-container *ngIf="titleChildTranslate">
                            <span>{{ title | translate }}</span>
                            <span class="child_name"> - {{ prefix }} {{ postfix }}</span>
                        </ng-container>
                        <ng-container *ngIf="isChildVerification">
                            <span>{{ title | translate }} </span>
                            <span class="child_name">{{ childFirstName }} {{ childLastName }}:</span>
                        </ng-container>
                    </span>
                    <span *ngIf="!translateTitle">{{ title }}</span>
                    <span class="postIndex" *ngIf="postIndex">{{ postIndex }}</span>
                </div>
                <div class="accordion__subtitle" *ngIf="subTitle" [innerHTML]="subTitle | translate: {year: statement?.fullYear || ''}">
                </div>
            </div>
            <div class="active_nr_box" *ngIf="showActiveNr">
                <span>{{ currActiveNr }}</span>
                <span>/</span>
                <span>{{ allActiveNr }}</span>
            </div>
        </div>
        <div class="level-right" *ngIf="canCollapse">
            <!-- <div class="level-item" (click)="onRemoveClicked($event)" *ngIf="showRemove">
                <a [ngClass]="isRemoveActive ? 'accordion__remove' : 'accordion__remove inactive'">
                    <div class="svg-icon">
                        <img src="../../../assets/svg/delete_simply_gray.svg" alt="delete" class="svg-icon__img" />
                        <span class="svg-icon__label">{{ 'COMMON.REMOVE' | translate }}</span>
                    </div>
                </a>
            </div> -->
            <div class="level-item">
                <ng-container *ngIf="currentId">
                    <img src="../../../assets/svg/minus_accordion.svg" class="icon_up" id="close_{{currentId}}">
                    <img src="../../../assets/svg/plus_accordion.svg" class="icon_down" id="open_{{currentId}}">
                </ng-container>
                <ng-container *ngIf="!currentId">
                    <img src="../../../assets/svg/minus_accordion.svg" class="icon_up">
                    <img src="../../../assets/svg/plus_accordion.svg" class="icon_down">
                </ng-container>
            </div>
        </div>
    </div>

     <!--Start OCR Alert-->
     <div class="employer__alert" [hidden]="isCollapsed" *ngIf="showScanAlert && !isBusiness && usedOCR">
        <div class="employer__alert-desc">
            {{ 'OCR_ALERT.DESCRIPTION' | translate }}
        </div>
        <div class="employer__alert-box">
            <div class="left_part">
                <div class="employer__alert-title">
                    {{ 'OCR_ALERT.TITLE' | translate }}
                </div>
                <div class="employer__alert-info">
                    {{ 'OCR_ALERT.ALERT_INFO' | translate }} <strong>{{ 'OCR_ALERT.ALERT_INFO_2' | translate }}</strong>
                </div>
            </div>
            <div class="right_part">
                <img src="../../../assets/svg/1ludz1.svg" class="icon_1">
                <img src="../../../assets/svg/1ludz2.svg" class="icon_2">
                <img src="../../../assets/svg/1ludz3.svg" class="icon_3">
            </div>
        </div>
    </div>
    <!-- End OCR Alert -->

    <div class="accordion__content form-content {{contentClass}}" [class.no-bg]="noBg" [hidden]="isCollapsed" [class.isEmployer]="isEmployer">
        <div class="accordion__remove" *ngIf="showRemove" [class.m_bot]="btnRemoveMargin">
            <div class="level-title" [class.isEmployer]="isEmployer">
                <span class="preIndex" *ngIf="prefixLabel">{{ prefixLabel }}.</span>
                <span *ngIf="transalteDelLabel">{{ deleteLabel | translate }}</span>
                <span *ngIf="!transalteDelLabel">{{ deleteLabel }}</span>
                <span class="postIndex" *ngIf="postfixLabel">{{ postfixLabel }}</span>
            </div>
            <div class="level-item" (click)="onRemoveClicked($event)" *ngIf="isRemoveShown">
                <a [ngClass]="isRemoveActive ? 'accordion__remove' : 'accordion__remove inactive'">
                    <div class="svg-icon">
                        <span class="svg-icon__label">{{ 'COMMON.REMOVE' | translate }}</span>
                        <img src="../../../assets/svg/del_accordion.svg" alt="delete" class="svg-icon__img" />
                    </div>
                </a>
            </div>
        </div>
        <ng-content></ng-content>
    </div>
</div>
