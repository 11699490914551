<ng-container *ngIf="!contentLoaded">
  <app-content-loader></app-content-loader>
</ng-container>
<div class="profile-edit" *ngIf="contentLoaded">
  <div class="profile-edit__section" *ngIf="currentStep === 1 || isBusiness">
    <app-accordion
      [preIndex]="preIndexArray[0]"
      [title]="'PROFILE.MARITAL_STATUS'"
      [subTitle]="'PROFILE.MARITAL_STATUS_DESCRIPTION'"
      [isCollapsed]="false"
      [noBg]="true"
      [canCollapse]="isBusiness"
      [currentId]="'maritalStatus'"
    >
      <div class="ep_new_tiles_wrap top" *ngIf="tilesStatuses.length">
        <div class="ep_new_single_tile_wrap" *ngFor="let tileStatuse of tilesStatuses; let i = index">
          <div
            class="ep_new_single_tile bot"
            [ngClass]="isBusiness ? 'marital_status_tile_' + i : ''"
            [class.is_input]="i > 0"
            (click)="selectStatus(tileStatuse)"
            [class.active]="tileStatuse.active"
            [attr.data-cy]="'maritalStatus_' + i"
          >
            <div class="ep_desc_wrap wrap profil_custom">
              <div class="icon_tile_wrap">
                <img src="../../../assets/svg/profile/{{ tileStatuse.icon }}" class="icon_tile custom" />
              </div>
              <h4 class="title_tile_sm" [innerHTML]="tileStatuse.name | translate"> </h4>
              <div
                class="inputs_wrap"
                *ngIf="tileStatuse.active && statement.maritalStatus != null && status != null && status !== 1"
              >
                <div class="single_input" *ngIf="tileStatuse.status === 2" (click)="$event.stopPropagation()">
                  <app-form-date-input
                    [errors]="errors?.date_of_marriage"
                    [warnings]="warnings?.date_of_marriage"
                    [label]="'INPUT.DATE_OF_MARRIAGE.LABEL'"
                    [(model)]="statement.dateOfMarriage"
                    [centerLabel]="true"
                    [centerTxtInput]="true"
                    [smLabel]="true"
                    [whiteColor]="true"
                    (modelChange)="dateChange($event)"
                    [attr.data-cy]="'inputDateOfMarriageB2B'"
                  ></app-form-date-input>
                  <ng-container *ngIf="tileStatuse.status === 2">
                    <app-validator-error-message
                      [epValidator]="epValidator"
                      [index]="0"
                      [fieldName]="'chose_profile_marriage_data'"
                    >
                    </app-validator-error-message>
                  </ng-container>
                </div>

                <div class="single_input" *ngIf="tileStatuse.status === 3" (click)="$event.stopPropagation()">
                  <app-form-date-input
                    [errors]="errors?.date_of_partnership"
                    [warnings]="warnings?.date_of_partnership"
                    [label]="'INPUT.DATE_OF_PARTNERSHIP.LABEL'"
                    [(model)]="statement.dateOfPartnership"
                    [centerLabel]="true"
                    [whiteColor]="true"
                    [centerTxtInput]="true"
                    [smLabel]="true"
                    (modelChange)="dateChange($event)"
                  ></app-form-date-input>

                  <app-validator-error-message
                    [epValidator]="epValidator"
                    [index]="0"
                    [fieldName]="'chose_profile_partners_data'"
                  >
                  </app-validator-error-message>
                </div>

                <div class="single_input" *ngIf="tileStatuse.status === 4" (click)="$event.stopPropagation()">
                  <app-form-date-input
                    [errors]="errors?.date_of_widowed"
                    [warnings]="warnings?.date_of_widowed"
                    [label]="'INPUT.DATE_OF_WIDOWED.LABEL'"
                    [(model)]="statement.dateOfWidowed"
                    [centerLabel]="true"
                    [centerTxtInput]="true"
                    [smLabel]="true"
                    [whiteColor]="true"
                    (modelChange)="dateChange($event)"
                  ></app-form-date-input>
                  <app-validator-error-message
                    [epValidator]="epValidator"
                    [index]="0"
                    [fieldName]="'chose_profile_widower_end_date'"
                  >
                  </app-validator-error-message>
                </div>

                <div class="single_input" *ngIf="tileStatuse.status === 5" (click)="$event.stopPropagation()">
                  <app-form-date-input
                    [errors]="errors?.date_of_separation"
                    [warnings]="warnings?.date_of_separation"
                    [label]="'INPUT.DATE_OF_SEPARATION.LABEL'"
                    [(model)]="statement.dateOfSeparation"
                    [centerLabel]="true"
                    [whiteColor]="true"
                    [centerTxtInput]="true"
                    [smLabel]="true"
                    (modelChange)="dateChange($event)"
                  ></app-form-date-input>
                  <app-validator-error-message [epValidator]="epValidator" [index]="0" [fieldName]="'c'">
                  </app-validator-error-message>
                </div>

                <div class="single_input" *ngIf="tileStatuse.status === 6" (click)="$event.stopPropagation()">
                  <app-form-date-input
                    [errors]="errors?.date_of_divorce || !canFilTogether()"
                    [warnings]="warnings?.date_of_divorce || !canFilTogether()"
                    [label]="'INPUT.DATE_OF_DIVORCE.LABEL'"
                    [(model)]="statement.dateOfDivorce"
                    [centerLabel]="true"
                    [whiteColor]="true"
                    [centerTxtInput]="true"
                    [smLabel]="true"
                    (modelChange)="dateChange($event)"
                  ></app-form-date-input>

                  <app-validator-error-message
                    [epValidator]="epValidator"
                    [index]="0"
                    [fieldName]="'chose_profile_divorce_end_date'"
                  >
                  </app-validator-error-message>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-accordion>
  </div>

  <div class="profile-edit__section" *ngIf="canFilTogether()" #jointSettlement>
    <app-accordion
      [preIndex]="preIndexArray[1]"
      [title]="'PROFILE.JOINT_SETTLEMENT'"
      [subTitle]="'PROFILE.JOINT_SETTLEMENT_DESCRIPTION'"
      [isCollapsed]="collapseJointSettlement"
      [noBg]="true"
      [canCollapse]="isBusiness"
      [currentId]="'jointSettlement'"
    >
      <div class="ep_new_tiles_wrap no_pad">
        <div class="ep_new_single_tile_wrap">
          <div
            class="ep_new_single_tile"
            [ngClass]="isBusiness ? 'joint_s_tile' : ''"
            [class.active]="statement.filedTogether"
            (click)="changeFieldTogether()"
          >
            <div class="ep_desc_wrap">
              <div class="icon_tile_wrap">
                <img src="../../../assets/svg/profile/joint_settlement.svg" class="icon_tile custom" />
              </div>
              <h4 class="title_tile_sm">
                {{ "PERSONAL_INFO.DECLARE_SPOUSE_TAXCARDS" | translate }}
              </h4>
            </div>
          </div>
          <div class="tooltip_info_wrap">
            <app-more-info-tooltip
              [message]="'TOOLTIP.DECLARE_SPOUSE_TAXCARDS'"
              [tileTooltip]="true"
              [isSmall]="true"
              [showText]="false"
            ></app-more-info-tooltip>
          </div>
        </div>
      </div>
    </app-accordion>
  </div>

  <div class="profile-edit__section" #childrensection>
    <app-accordion
      [preIndex]="preIndexArray[2]"
      [title]="'PROFILE.CHILDREN'"
      [subTitle]="'PROFILE.CHILDREN_DESCRIPTION'"
      [isCollapsed]="collapseChildrenSettlement"
      [noBg]="true"
      [canCollapse]="isBusiness"
      [currentId]="'children'"
    >
      <div class="ep_new_tiles_wrap top">
        <div class="ep_new_single_tile_wrap">
          <div class="ep_new_single_tile top" [class.active]="statement.hasChildren" (click)="selectHasChildren()">
            <div class="ep_desc_wrap">
              <div class="icon_tile_wrap">
                <img src="../../../assets/svg/profile/has_children.svg" class="icon_tile custom" />
              </div>
              <h4 class="title_tile_sm">
                {{ "SELECT.HAVE_CHILDREN.LABEL" | translate }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </app-accordion>
  </div>
</div>
