<app-smart-app-banner *ngIf="smartAppBannerService.isShown"></app-smart-app-banner>
<app-welcome-modal></app-welcome-modal>
<mat-sidenav-container class="slidenav-container">
  <div class="app">
    <app-header-bar></app-header-bar>
    <app-spinner [isGlobal]="true"></app-spinner>
    <router-outlet></router-outlet>
  </div>
  <mat-sidenav #sidenav class="example-sidenav" position="end" mode="over">
    <app-aside-menu></app-aside-menu>
  </mat-sidenav>
</mat-sidenav-container>
<!-- <app-cookies-law-banner></app-cookies-law-banner> -->
