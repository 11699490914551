import { Component, Input } from '@angular/core';
import { AdditionalIncomeService } from 'app/modules/statement/services/additional-income.service';
import { FreelanceJob } from 'app/modules/statement/models/additional-income/freelance-job.model';
import { CryptocurrencyIncome } from 'app/modules/statement/models/additional-income/cryptocurrency.model';

@Component({
  selector: 'app-additional-income-form-cryptocurrency',
  templateUrl: './additional-income-form-cryptocurrency.component.html',
})
export class AdditionalIncomeFormCryptocurrencyComponent {
  @Input() cryptocurrencyIncome: CryptocurrencyIncome;
  @Input() index = 0;

  constructor(
    public additionalIncomeService: AdditionalIncomeService,
  ) {
  }
}
