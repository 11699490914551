import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { B2bTaxCardComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.tax-card-component.path';
import { TaxCardComponentPath } from 'app/core/workflow/page-path/statement-page-path/tax-card-component.path';

export class WorkflowTaxCardService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.statement && this.statement.stageEmployers;
  }

  public get isVisible(): boolean {
    return true;
  }

  public get title(): string {
    return 'ASIDE_BAR.SNAP';
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.statement && !!this.statement.fullYear;
  }

  public get urlRegex(): RegExp {
    if (this.isBusiness) {
      return B2bTaxCardComponentPath.regex();
    }

    return TaxCardComponentPath.regex();
  }

  public get url(): string {
    if (this.isBusiness) {
      return B2bTaxCardComponentPath.url(this.getStatementId, this.getEmployerId);
    }

    return TaxCardComponentPath.url(this.getStatementId, this.getEmployerId);
  }
}
