<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>

    <div class="dialog-box__content">
        <swiper [config]="config" #usefulSwiper>
            <div class="swiper-wrapper">
                <div class="swiper-slide taxcard-quality">
                    <div class="swiper-slide__content">
                        <div class="taxcard-quality__content">
                            <img class="taxcard-quality__logo is-hidden-tablet" src="assets/img/logo_small.png">
                            <div class="taxcard-quality__title">
                                <h1>{{ 'TAXCARD_QUALITY.LIGHT_TITLE' | translate }}</h1>
                                <img src="assets/img/lamp.png">
                            </div>
                            <div class="taxcard-quality__message">{{ 'TAXCARD_QUALITY.LIGHT_MESSAGE' | translate }}</div>
                        </div>
                    </div>
                </div>

                <div class="swiper-slide taxcard-quality">
                    <div class="swiper-slide__content">
                        <div class="taxcard-quality__content">
                            <img class="taxcard-quality__logo" src="assets/img/logo_small.png">
                            <div class="taxcard-quality__title">
                                <h1>{{ 'TAXCARD_QUALITY.PERSPECTIVE_TITLE' | translate }}</h1>
                                <img src="assets/img/mobile.png">
                            </div>
                            <div class="taxcard-quality__message">{{ 'TAXCARD_QUALITY.PERSPECTIVE_MESSAGE' | translate }}</div>
                        </div>
                    </div>
                </div>

                <div class="swiper-slide taxcard-quality">
                    <div class="swiper-slide__content">
                        <div class="taxcard-quality__content">
                            <img class="taxcard-quality__logo" src="assets/img/logo_small.png">
                            <div class="taxcard-quality__title">
                                <h1>{{ 'TAXCARD_QUALITY.SAD_TITLE' | translate }}</h1>
                                <img src="assets/img/sad.png">
                            </div>
                            <div class="taxcard-quality__message">{{ 'TAXCARD_QUALITY.SAD_MESSAGE' | translate }}</div>
                        </div>
                    </div>
                </div>

                <div class="swiper-slide taxcard-quality">
                    <div class="swiper-slide__content">
                        <div class="taxcard-quality__content taxcard-quality__content--last">
                            <img class="taxcard-quality__logo" src="assets/img/logo_small.png">
                            <div class="taxcard-quality__title">
                                <h1>{{ 'TAXCARD_QUALITY.PDF_TITLE' | translate }}</h1>
                                <img src="assets/img/pdf.png">
                            </div>
                            <div class="taxcard-quality__message">{{ 'TAXCARD_QUALITY.PDF_MESSAGE' | translate }}</div>
                        </div>

                        <a class="btn btn-dark is-hidden-mobile" (click)="onAccept()">
                            {{ 'TAXCARD_QUALITY.ACCEPT' | translate }}
                            <img src="assets/img/forward.png">
                        </a>
                    </div>
                </div>
            </div>

            <div class="taxcard-quality__logo-left is-hidden-mobile" [class.is-hidden]="currentSlide > 0">
                <img src="assets/img/logo_small.png">
            </div>

            <div class="taxcard-quality__switch taxcard-quality__switch--prev is-hidden-mobile" [class.is-hidden]="currentSlide === 0">
                <img src="assets/img/backward.png">
            </div>

            <div class="taxcard-quality__switch taxcard-quality__switch--next is-hidden-mobile" [class.is-hidden]="currentSlide === SLIDES_COUNT - 1">
                <img src="assets/img/forward.png">
            </div>
        </swiper>
    </div>

    <div class="dialog-box__footer is-hidden-tablet" [class.dialog-box__footer--last]="currentSlide === SLIDES_COUNT - 1">
        <div class="taxcard-quality__switch taxcard-quality__switch--prev is-hidden-tablet"
             (click)="usefulSwiper.swiper.slidePrev()"
             [class.is-hidden]="currentSlide === 0 || currentSlide === SLIDES_COUNT - 1"
        >
            <img src="assets/img/backward.png">
        </div>

        <div class="taxcard-quality__switch taxcard-quality__switch--next is-hidden-tablet"
             (click)="usefulSwiper.swiper.slideNext()"
             [class.is-hidden]="currentSlide === SLIDES_COUNT - 1"
        >
            <img src="assets/img/forward.png">
        </div>

        <a class="btn btn-dark"
           [class.is-hidden]="currentSlide < SLIDES_COUNT - 1"
           (click)="onAccept()"
        >
            {{ 'TAXCARD_QUALITY.ACCEPT' | translate }}
            <img src="assets/img/forward.png">
        </a>
    </div>
</div>
