export interface CurrencyConverterResponse {
  rate: number;
}

export class CurrencyConverter {
  rate: number;

  static fromResponse(response: CurrencyConverterResponse): CurrencyConverter {
    const currency = new CurrencyConverter();
    currency.rate = response.rate;

    return currency;
  }
}
