export interface CountryResponse {
    code: string;
    name: string;
}

export class Country {
    code: string;
    name: string;

    static fromResponse(response: CountryResponse): Country {
        const country = new Country();
        country.code = response.code;
        country.name = response.name;
        return country;
    }
}
