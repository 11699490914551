<ng-container *ngIf="isLoading">
  <app-content-loader></app-content-loader>
</ng-container>
<div class="step-container columns is-desktop" [class.d-none]="isLoading">
  <div class="payment-placeholder" *ngIf="isPaymentStatusProcessing">
    <div class="payment-placeholder__title">{{ "COMMON.PAYMENT_PLACEHOLDER" | translate }}</div>
  </div>
  <app-aside-bar [statement]="statement" [workFlowController]="workFlowController"></app-aside-bar>
  <ng-container *ngIf="statement">
    <div class="thank-you" *ngIf="statement.serviceType !== packageOption.tax_adviser">
      <h1 class="title_42">{{ "THANK_YOU.TITLE" | translate }}</h1>
      <p *ngIf="!isVeryfying; else isVeryfyingInfo" class="subdesc">{{ "THANK_YOU.DESCRIPTION" | translate }}</p>

      <div *ngIf="!isIdentityVarificationActive" class="thank-you__summary_wrapper">
        <h2 class="title_24">
          {{ "THANK_YOU.TAX_RETURN_TITLE" | translate }}
        </h2>
        <div class="thank-you__summary form-content">
          <app-card-view-item
            [statement]="statement"
            [hideEditing]="true"
            [hideRemoving]="true"
            (downloadPdf)="printStatement()"
            (downloadInvoice)="downloadInvoice()"
            [hideCloning]="true"
            (showDownloadDialog)="showDownloadDialog(statement)"
          >
          </app-card-view-item>
        </div>
        <div class="thank-you__actions mt-3">
          <div
            class="thank-you__resign-from-contract mat-checkbox-top alert-box alert-box--info"
            *ngIf="statement && !statement.canSendWithoutResignationFromClaim"
          >
            <div class="desc_wrap" [innerHTML]="'THANK_YOU.ATTENTION_DESC' | translate"></div>
            <mat-checkbox
              [checked]="isResigningFromClaim"
              (change)="checkResigningFromClaim()"
              [attr.data-cy]="'checkboxResigningFromClaim'"
            >
              <span class="title_20">{{ "THANK_YOU.RESIGNATION_FROM_CLAIM" | translate }}</span>
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="thank-you__onfido" *ngIf="isIdentityVarificationActive">
        <div *ngIf="!isVeryfying">
          <div id="sumsub-websdk-container"></div>
        </div>
        <div *ngIf="isVeryfying" class="thank-you__onfido-verify">
          <ng-container *ngIf="!cantGetOnfidoStatusCheck">
            <div class="thank-you__onfido-verify-info alert-box alert-box--info">
              {{ "ONFIDO.PLACEHOLDER" | translate }}</div
            >
            <div class="thank-you__onfido-verify-spinner">
              <ng-lottie [options]="lottieConfig"></ng-lottie>
            </div>
          </ng-container>
          <ng-container *ngIf="cantGetOnfidoStatusCheck">
            <div class="thank-you__onfido-verify-info alert-box alert-box--error">
              {{ "ONFIDO.WARNING_MESSAGE.PLEASE_WAIT" | translate }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="thank-you" *ngIf="statement.serviceType === packageOption.tax_adviser">
      <h1 class="title_42">{{ "THANK_YOU.TITLE" | translate }}</h1>
      <div *ngIf="!isVeryfying; else isVeryfyingInfo" class="subdesc">{{
        "THANK_YOU.TAX_ADVISER_DESCRIPTION" | translate
      }}</div>

      <div class="thank-you__onfido" *ngIf="isIdentityVarificationActive">
        <div *ngIf="!isVeryfying">
          <div id="sumsub-websdk-container"></div>
        </div>
        <div *ngIf="isVeryfying" class="thank-you__onfido-verify">
          <ng-container *ngIf="!cantGetOnfidoStatusCheck">
            <div class="thank-you__onfido-verify-info alert-box alert-box--info">
              {{ "ONFIDO.PLACEHOLDER" | translate }}</div
            >
            <div class="thank-you__onfido-verify-spinner">
              <ng-lottie [options]="lottieConfig"></ng-lottie>
            </div>
          </ng-container>
          <ng-container *ngIf="cantGetOnfidoStatusCheck"> </ng-container>
        </div>
      </div>
    </div>

    <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
  </ng-container>
</div>

<ng-template #isVeryfyingInfo>
  <p *ngIf="isVeryfying" class="subdesc">{{ "THANK_YOU.VERIFICATION_PROCESS" | translate }}</p>
</ng-template>
