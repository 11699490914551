export interface PersonalRefundDetailsResponse {
  einkunfte_aus_nichtselbstandiger_arbeit: number;
  bereits_getilgt_einkommensteuer: number;
  bereits_getilgt_solidaritatszuschlag: number;
  bereits_getilgt_kirchenssteuer: number;
  gezahlte_kirchensteuer: number;
  summe_der_altersvorsorgeaufwendungen: number;
  beitrage_zur_krankenversicherung: number;
  beitrage_zur_pflegeversicherung: number;
  sonstige_vorsorgeaufwendungen: number;
  //other cost
  bewerbungskosten: number;
  kosten_der_steuererklarung: number;
  telekommunikationskosten: number;
  winterbeschaftigungsumlage: number;
  kontofuhrungsgebuhren: number;
  //end other cost
  ubrige_werbungskosten: number;
  aufwendungen_fur_arbeitsmittel: number;
  fortbildungskosten: number;
  beitrage_zu_berufsverbanden: number;
  mehraufwendungen_fur_doppelte_haushaltsfuhrung: number;
  entfernungspauschale: number;
  aufwendungen_fur_offentliche_verkehrsmittel: number;
  umzugskosten: number;
  auswartstatigkeit: number;
  ubernachtungspaushal_fur_berufskraftfahrer: number;
  aufwendungen_fur_ein_hausliches_arbeitszimmer: number;
  beschaftigungen_im_privathaushalt: number;
  beschaftigungen_im_privathaushalt_und_haushaltsnahe_dienstleistungen: number;
  handwerkerleistungen: number;

  einkunfte_aus_selbstandiger_arbeit: number; //TODO: REMOVE
  einkunfte_aus_selbstandiger_arbeit_freelance: number; //TODO: REMOVE
  einkunfte_aus_selbstandiger_arbeit_gewerbe: number; //TODO: REMOVE

  einkunfte_aus_selbstandiger_arbeit_freelance_sum: Array<number>;
  einkunfte_aus_selbstandiger_arbeit_freelance_name: Array<string>;
  einkunfte_aus_selbstandiger_arbeit_freelance_income: Array<number>;
  einkunfte_aus_selbstandiger_arbeit_freelance_costs: Array<number>;
  einkunfte_aus_selbstandiger_arbeit_gewerbe_sum: Array<number>;
  einkunfte_aus_selbstandiger_arbeit_gewerbe_name: Array<string>;
  einkunfte_aus_selbstandiger_arbeit_gewerbe_income: Array<number>;
  einkunfte_aus_selbstandiger_arbeit_gewerbe_costs: Array<number>;
  einkunfte_aus_selbstandiger_arbeit_volunteering: number;

  crypto_currency_income: number;
  ab_betrag_nach_46: number;
  arbeitnehmer_pauschbetrag: number;
  summe_werbungskosten: number;
  pauschbetrag: number;
  max_sonstige_vorsorgeaufwendungen: number;
  spenden_und_mitgliedsbeiträge: number;
  ermäßigung_für_zuwendungen_an_politische_parteien: number;
  zuwendungen_an_politische_parteien: number;
  pension_total_taxable: number;
  pension_total_amount: number;
}

export interface ChildrenRefundDetailsResponse {
  freibetrage_fur_kinder: number;
  schulgeldes: number;
  kinderbetreuungskosten: number;
  entlastungsbetrag_fur_alleinerziehende: number;
  ausbildungskosten: number;
  hinterbliebenen_pauschbetrag_fur_kinder: number;
  behinderten_pauschbetrag_fur_kinder: number;
}

export interface RefundDetailsResponse {
  total: number;
  steuersatz: number;
  grenzsteuersatz: number;
  einkommensteuer: number;
  festgesetzte_einkommensteuer: number;
  festgesetzter_solidaritatszuschlag: number;
  festgesetzte_kirchensteuersteuer: number;
  zu_versteuern_mit_progressionsvorbehalt: number;
  verbleibende_betrage_einkommensteuer: number;
  verbleibende_betrage_kirchensteuer: number;
  verbleibende_betrage_solidaritatszuschlag: number;
  zu_versteuerndes_einkommen: number;
  insgesamt: number;
  behinderten_pauschbetrag: number;
  pflege_pauschbetrag: number;
  gesundheitskosten: number;
  applicant: PersonalRefundDetailsResponse;
  spouse: PersonalRefundDetailsResponse;
  children: ChildrenRefundDetailsResponse;
}

export class PersonalRefundDetails {
  einkunfteAusNichtselbstandigerArbeit = 0;
  bereitsGetilgtEinkommensteuer = 0;
  bereitsGetilgtSolidaritatszuschlag = 0;
  bereitsGetilgtKirchenssteuer = 0;
  gezahlteKirchensteuer = 0;
  summeDerAltersvorsorgeaufwendungen = 0;
  beitrageZurKrankenversicherung = 0;
  beitrageZurPflegeversicherung = 0;
  sonstigeVorsorgeaufwendungen = 0;
  //other cost group
  bewerbungskosten = 0;
  kosten_der_steuererklarung = 0;
  telekommunikationskosten = 0;
  winterbeschaftigungsumlage = 0;
  kontofuhrungsgebuhren = 0;
  //end other cost group
  ubrigeWerbungskosten = 0;
  aufwendungenFurArbeitsmittel = 0;
  fortbildungskosten = 0;
  beitrageZuBerufsverbanden = 0;
  mehraufwendungenFurDoppelteHaushaltsfuhrung = 0;
  entfernungspauschale = 0;
  aufwendungenFurOffentlicheVerkehrsmittel = 0;
  umzugskosten = 0;
  auswartstatigkeit = 0;
  ubernachtungspaushaleFurBerufskraftfahrer = 0;
  aufwendungenFurEinHauslichesArbeitszimmer = 0;
  beschaftigungenImPrivathaushalt = 0;
  beschaftigungenImPrivathaushaltUndHaushaltsnaheDienstleistungen = 0;
  handwerkerleistungen = 0;
  einkunfteAusSelbstandigerArbeit = 0;

  einkunfte_aus_selbstandiger_arbeit_freelance = 0;
  einkunfte_aus_selbstandiger_arbeit_gewerbe = 0;

  einkunfte_aus_selbstandiger_arbeit_freelance_sum: Array<number> = [];
  einkunfte_aus_selbstandiger_arbeit_freelance_name: Array<string> = [];
  einkunfte_aus_selbstandiger_arbeit_freelance_income: Array<number> = [];
  einkunfte_aus_selbstandiger_arbeit_freelance_costs: Array<number> = [];
  einkunfte_aus_selbstandiger_arbeit_gewerbe_sum: Array<number> = [];
  einkunfte_aus_selbstandiger_arbeit_gewerbe_name: Array<string> = [];
  einkunfte_aus_selbstandiger_arbeit_gewerbe_income: Array<number> = [];
  einkunfte_aus_selbstandiger_arbeit_gewerbe_costs: Array<number> = [];

  einkunfte_aus_selbstandiger_arbeit_volunteering: number = 0;

  cryptoCurrencyIncome = 0;
  abBetragNach46 = 0;
  arbeitnehmerPauschbetrag = 0;
  summeWerbungskosten = 0;
  pauschbetrag = 0;
  max_sonstige_vorsorgeaufwendungen = 0;
  spendenUndMitgliedsbeitrage = 0;
  ermabigungFurZuwendungenAnPolitischeParteien = 0;
  ZuwendungenAnPolitischeParteien = 0;
  pensionTotalTaxable = 0;
  pensionTotalAmount = 0;

  static fromResponse(response: PersonalRefundDetailsResponse): PersonalRefundDetails {
    const refundDetails = new PersonalRefundDetails();
    refundDetails.einkunfteAusNichtselbstandigerArbeit = Math.round(response.einkunfte_aus_nichtselbstandiger_arbeit);
    refundDetails.bereitsGetilgtEinkommensteuer = Math.round(response.bereits_getilgt_einkommensteuer);
    refundDetails.bereitsGetilgtSolidaritatszuschlag = Math.round(response.bereits_getilgt_solidaritatszuschlag);
    refundDetails.bereitsGetilgtKirchenssteuer = Math.round(response.bereits_getilgt_kirchenssteuer);
    refundDetails.gezahlteKirchensteuer = -Math.round(response.gezahlte_kirchensteuer);
    refundDetails.summeDerAltersvorsorgeaufwendungen = -Math.round(response.summe_der_altersvorsorgeaufwendungen);
    refundDetails.beitrageZurKrankenversicherung = -Math.round(response.beitrage_zur_krankenversicherung);
    refundDetails.beitrageZurPflegeversicherung = -Math.round(response.beitrage_zur_pflegeversicherung);
    refundDetails.sonstigeVorsorgeaufwendungen = -Math.round(response.sonstige_vorsorgeaufwendungen);
    //other cost
    refundDetails.bewerbungskosten = -Math.round(response.bewerbungskosten);
    refundDetails.kosten_der_steuererklarung = -Math.round(response.kosten_der_steuererklarung);
    refundDetails.telekommunikationskosten = -Math.round(response.telekommunikationskosten);
    refundDetails.winterbeschaftigungsumlage = -Math.round(response.winterbeschaftigungsumlage);
    refundDetails.kontofuhrungsgebuhren = -Math.round(response.kontofuhrungsgebuhren);
    //end other cost
    refundDetails.ubrigeWerbungskosten = -Math.round(response.ubrige_werbungskosten);
    refundDetails.aufwendungenFurArbeitsmittel = -Math.round(response.aufwendungen_fur_arbeitsmittel);
    refundDetails.fortbildungskosten = -Math.round(response.fortbildungskosten);
    refundDetails.beitrageZuBerufsverbanden = -Math.round(response.beitrage_zu_berufsverbanden);
    refundDetails.mehraufwendungenFurDoppelteHaushaltsfuhrung = -Math.round(
      response.mehraufwendungen_fur_doppelte_haushaltsfuhrung
    );
    refundDetails.entfernungspauschale = -Math.round(response.entfernungspauschale);
    refundDetails.aufwendungenFurOffentlicheVerkehrsmittel = -Math.round(
      response.aufwendungen_fur_offentliche_verkehrsmittel
    );
    refundDetails.umzugskosten = -Math.round(response.umzugskosten);
    refundDetails.auswartstatigkeit = -Math.round(response.auswartstatigkeit);
    refundDetails.ubernachtungspaushaleFurBerufskraftfahrer = -Math.round(
      response.ubernachtungspaushal_fur_berufskraftfahrer
    );
    refundDetails.aufwendungenFurEinHauslichesArbeitszimmer = -Math.round(
      response.aufwendungen_fur_ein_hausliches_arbeitszimmer
    );
    refundDetails.beschaftigungenImPrivathaushalt = -Math.round(response.beschaftigungen_im_privathaushalt);
    refundDetails.beschaftigungenImPrivathaushaltUndHaushaltsnaheDienstleistungen = -Math.round(
      response.beschaftigungen_im_privathaushalt_und_haushaltsnahe_dienstleistungen
    );
    refundDetails.handwerkerleistungen = -Math.round(response.handwerkerleistungen);
    refundDetails.einkunfteAusSelbstandigerArbeit = Math.round(response.einkunfte_aus_selbstandiger_arbeit);
    refundDetails.einkunfte_aus_selbstandiger_arbeit_freelance = Math.round(
      response.einkunfte_aus_selbstandiger_arbeit_freelance
    );
    refundDetails.einkunfte_aus_selbstandiger_arbeit_gewerbe = Math.round(
      response.einkunfte_aus_selbstandiger_arbeit_gewerbe
    );
    refundDetails.einkunfte_aus_selbstandiger_arbeit_volunteering = Math.round(
      response.einkunfte_aus_selbstandiger_arbeit_volunteering
    );

    refundDetails.einkunfte_aus_selbstandiger_arbeit_freelance_sum =
      response.einkunfte_aus_selbstandiger_arbeit_freelance_sum.filter((listElement) => Math.round(listElement));

    refundDetails.einkunfte_aus_selbstandiger_arbeit_freelance_name =
      response.einkunfte_aus_selbstandiger_arbeit_freelance_name;

    refundDetails.einkunfte_aus_selbstandiger_arbeit_freelance_income =
      response.einkunfte_aus_selbstandiger_arbeit_freelance_income.filter((listElement) => Math.round(listElement));

    refundDetails.einkunfte_aus_selbstandiger_arbeit_freelance_costs =
      response.einkunfte_aus_selbstandiger_arbeit_freelance_costs.filter((listElement) => Math.round(listElement));

    refundDetails.einkunfte_aus_selbstandiger_arbeit_gewerbe_sum =
      response.einkunfte_aus_selbstandiger_arbeit_gewerbe_sum.filter((listElement) => Math.round(listElement));

    refundDetails.einkunfte_aus_selbstandiger_arbeit_gewerbe_name =
      response.einkunfte_aus_selbstandiger_arbeit_gewerbe_name;

    refundDetails.einkunfte_aus_selbstandiger_arbeit_gewerbe_income =
      response.einkunfte_aus_selbstandiger_arbeit_gewerbe_income.filter((listElement) => Math.round(listElement));

    refundDetails.einkunfte_aus_selbstandiger_arbeit_gewerbe_costs =
      response.einkunfte_aus_selbstandiger_arbeit_gewerbe_costs.filter((listElement) => Math.round(listElement));

    refundDetails.einkunfte_aus_selbstandiger_arbeit_volunteering = Math.round(
      response.einkunfte_aus_selbstandiger_arbeit_volunteering
    );

    refundDetails.cryptoCurrencyIncome = Math.round(response.crypto_currency_income);
    refundDetails.abBetragNach46 = -Math.round(response.ab_betrag_nach_46);
    refundDetails.arbeitnehmerPauschbetrag = -Math.round(response.arbeitnehmer_pauschbetrag);
    refundDetails.summeWerbungskosten = -Math.round(response.summe_werbungskosten);
    refundDetails.pauschbetrag = -Math.round(response.pauschbetrag);
    refundDetails.max_sonstige_vorsorgeaufwendungen = response.max_sonstige_vorsorgeaufwendungen;
    refundDetails.spendenUndMitgliedsbeitrage = -Math.round(response.spenden_und_mitgliedsbeiträge);
    refundDetails.ermabigungFurZuwendungenAnPolitischeParteien = -Math.round(
      response.ermäßigung_für_zuwendungen_an_politische_parteien
    );
    refundDetails.ZuwendungenAnPolitischeParteien = -Math.round(response.zuwendungen_an_politische_parteien);
    refundDetails.pensionTotalAmount = response.pension_total_amount ? Math.round(response.pension_total_amount) : 0;
    refundDetails.pensionTotalTaxable = response.pension_total_taxable ? Math.round(response.pension_total_taxable) : 0;
    return refundDetails;
  }

  getPauschbetrag(): string {
    return this.pauschbetrag ? this.pauschbetrag.toString() : "-";
  }

  getArbeitnehmerPauschbetrag(): string {
    return this.arbeitnehmerPauschbetrag ? this.arbeitnehmerPauschbetrag.toString() : "-";
  }
}

export class ChildrenRefundDetails {
  freibetrageFurKinder: number;
  schulgeldes: number;
  kinderbetreuungskosten: number;
  entlastungsbetragFurAlleinerziehende: number;
  ausbildungskosten: number;
  hinterbliebenenPauschbetragFurKinder: number;
  behindertenPauschbetragFurKinder: number;

  static fromResponse(response: ChildrenRefundDetailsResponse): ChildrenRefundDetails {
    const refundDetails = new ChildrenRefundDetails();
    refundDetails.freibetrageFurKinder = -Math.round(response.freibetrage_fur_kinder);
    refundDetails.schulgeldes = -Math.round(response.schulgeldes);
    refundDetails.kinderbetreuungskosten = -Math.round(response.kinderbetreuungskosten);
    refundDetails.entlastungsbetragFurAlleinerziehende = -Math.round(response.entlastungsbetrag_fur_alleinerziehende);
    refundDetails.ausbildungskosten = -Math.round(response.ausbildungskosten);
    refundDetails.hinterbliebenenPauschbetragFurKinder = -Math.round(response.hinterbliebenen_pauschbetrag_fur_kinder);
    refundDetails.behindertenPauschbetragFurKinder = -Math.round(response.behinderten_pauschbetrag_fur_kinder);
    return refundDetails;
  }
}

export class RefundDetails {
  total = 0;
  steuersatz = 0;
  grenzsteuersatz = 0;
  einkommensteuer = 0;
  festgesetzteEinkommensteuer = 0;
  festgesetzterSolidaritatszuschlag = 0;
  festgesetzteKirchensteuersteuer = 0;
  zuVersteuernMitProgressionsvorbehalt = 0;
  verbleibendeBetrageEinkommensteuer: number;
  verbleibendeBetrageKirchensteuer: number;
  verbleibendeBetrageSolidaritatszuschlag: number;
  zuVersteuerndesEinkommen: number;
  insgesamt: number;
  behindertenPauschbetrag: number;
  pflegePauschbetrag: number;
  gesundheitskosten: number;
  applicant = new PersonalRefundDetails();
  spouse = new PersonalRefundDetails();
  children = new ChildrenRefundDetails();

  static fromResponse(response: RefundDetailsResponse): RefundDetails {
    const refundDetails = new RefundDetails();
    refundDetails.total = response.total;
    refundDetails.steuersatz = response.steuersatz;
    refundDetails.grenzsteuersatz = response.grenzsteuersatz;
    refundDetails.einkommensteuer = response.einkommensteuer;
    refundDetails.festgesetzteEinkommensteuer = response.festgesetzte_einkommensteuer;
    refundDetails.festgesetzterSolidaritatszuschlag = response.festgesetzter_solidaritatszuschlag;
    refundDetails.festgesetzteKirchensteuersteuer = response.festgesetzte_kirchensteuersteuer;
    refundDetails.zuVersteuernMitProgressionsvorbehalt = response.zu_versteuern_mit_progressionsvorbehalt;
    refundDetails.verbleibendeBetrageEinkommensteuer = response.verbleibende_betrage_einkommensteuer;
    refundDetails.verbleibendeBetrageKirchensteuer = response.verbleibende_betrage_kirchensteuer;
    refundDetails.verbleibendeBetrageSolidaritatszuschlag = response.verbleibende_betrage_solidaritatszuschlag;
    refundDetails.zuVersteuerndesEinkommen = Math.round(response.zu_versteuerndes_einkommen);
    refundDetails.behindertenPauschbetrag = -response.behinderten_pauschbetrag;
    refundDetails.pflegePauschbetrag = -response.pflege_pauschbetrag;
    refundDetails.gesundheitskosten = -response.gesundheitskosten;
    refundDetails.insgesamt = response.insgesamt;
    refundDetails.applicant = PersonalRefundDetails.fromResponse(response.applicant);
    refundDetails.spouse = PersonalRefundDetails.fromResponse(response.spouse);
    refundDetails.children = ChildrenRefundDetails.fromResponse(response.children);
    return refundDetails;
  }

  getAbsoluteRefund(): string {
    return Math.abs(this.total).toFixed(2);
  }

  getAbsoluteRefundWithoutComma(): string {
    return Math.abs(this.total).toFixed(0);
  }

  getSumPauschbetrag(): string {
    const sum = this.applicant.pauschbetrag + this.spouse.pauschbetrag;
    return sum ? sum.toString() : "-";
  }

  getSumArbeitnehmerPauschbetrag(): string {
    const sum = this.applicant.arbeitnehmerPauschbetrag + this.spouse.arbeitnehmerPauschbetrag;
    return sum ? sum.toString() : "-";
  }

  public isDeductionsEmpty(isSpouse = false): boolean {
    return (
      this.isBeschranktAbziehbareSonderausgabenEmpty(isSpouse) &&
      this.isBewerbungskostenEmpty(isSpouse) &&
      this.isUnbeschranktAbziehbareSonderausgabenEmpty(isSpouse)
    );
  }

  public isBeschranktAbziehbareSonderausgabenEmpty(isSpouse = false): boolean {
    const person = isSpouse ? "spouse" : "applicant";

    return (
      !this[person].summeDerAltersvorsorgeaufwendungen &&
      !this[person].beitrageZurKrankenversicherung &&
      !this[person].beitrageZurPflegeversicherung &&
      !this[person].sonstigeVorsorgeaufwendungen
    );
  }

  public isBewerbungskostenEmpty(isSpouse = false): boolean {
    const person = isSpouse ? "spouse" : "applicant";

    return (
      !this[person].arbeitnehmerPauschbetrag &&
      !this[person].entfernungspauschale &&
      !this[person].aufwendungenFurOffentlicheVerkehrsmittel &&
      !this[person].auswartstatigkeit &&
      !this[person].aufwendungenFurArbeitsmittel &&
      !this[person].aufwendungenFurEinHauslichesArbeitszimmer &&
      !this[person].fortbildungskosten &&
      !this[person].mehraufwendungenFurDoppelteHaushaltsfuhrung &&
      !this[person].umzugskosten &&
      !this[person].bewerbungskosten &&
      !this[person].kosten_der_steuererklarung &&
      !this[person].telekommunikationskosten &&
      !this[person].winterbeschaftigungsumlage &&
      !this[person].ubrigeWerbungskosten &&
      !this[person].summeWerbungskosten &&
      !this[person].ubernachtungspaushaleFurBerufskraftfahrer
    );
  }

  public isUnbeschranktAbziehbareSonderausgabenEmpty(isSpouse = false): boolean {
    const person = isSpouse ? "spouse" : "applicant";

    if (
      !this[person].spendenUndMitgliedsbeitrage &&
      !this[person].ZuwendungenAnPolitischeParteien &&
      !this[person].pauschbetrag
    ) {
      if (isSpouse) {
        return true;
      }

      if (
        !this.children.kinderbetreuungskosten &&
        !this.children.schulgeldes &&
        !this.children.ausbildungskosten &&
        !this.children.freibetrageFurKinder &&
        !this.children.entlastungsbetragFurAlleinerziehende &&
        !this.children.hinterbliebenenPauschbetragFurKinder &&
        !this.children.behindertenPauschbetragFurKinder &&
        !this.behindertenPauschbetrag &&
        !this.pflegePauschbetrag &&
        !this.gesundheitskosten &&
        !this.zuVersteuernMitProgressionsvorbehalt &&
        !this.zuVersteuerndesEinkommen
      ) {
        return true;
      }
    }

    return false;
  }

  public get reductionForMiniJobs20Percent(): number {
    return (this.applicant.beschaftigungenImPrivathaushalt + this.spouse.beschaftigungenImPrivathaushalt) * 0.2;
  }

  public get reductionForPrivateHousehold20Percent(): number {
    return (
      (this.applicant.beschaftigungenImPrivathaushaltUndHaushaltsnaheDienstleistungen +
        this.spouse.beschaftigungenImPrivathaushaltUndHaushaltsnaheDienstleistungen) *
      0.2
    );
  }

  public get reductionForCraftsman20Percent(): number {
    return (this.applicant.handwerkerleistungen + this.spouse.handwerkerleistungen) * 0.2;
  }
}
