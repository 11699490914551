import { Component, Input, TemplateRef } from "@angular/core";
import { TooltipBaseComponent } from "app/common/tooltip-base/tooltip-base.component";
import { MatDialog } from "@angular/material/dialog";
import { MoreInfoTooltipDialogComponent } from "app/common/more-info-tooltip-dialog/more-info-tooltip-dialog.component";
import { CommonModule } from "@angular/common";
import { ClickElsewhereDirective } from "app/shared/standalone/directives/click-elsewhere.directive";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  ...TooltipBaseComponent.metaData,
  selector: "app-more-info-tooltip",
  templateUrl: "./more-info-tooltip.component.html",
  styleUrls: ["./more-info-tooltip.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, ClickElsewhereDirective]
})
export class MoreInfoTooltipComponent extends TooltipBaseComponent {
  @Input() contentTemplateRef: TemplateRef<any>;
  @Input() showCustomTemplateView: boolean;
  @Input() message: string;
  @Input() displayTooltip = false;
  @Input() sellect_button = true;
  @Input() isSmall = false;
  @Input() showText = false;
  @Input() alignLeft = false;
  @Input() questionMark = false;
  @Input() additionalDeductions = false;
  @Input() tooltipInInput = false;
  @Input() revert = false;
  @Input() lgImgMobile = false;
  @Input() xlImgMobile = false;
  @Input() tileTooltip = false;
  @Input() tileBottom = false;
  @Input() tileTooltipMobile = false;
  @Input() titleTooltip = "";
  @Input() isInnerHTML = false;
  @Input() isSmallIcon = false;
  @Input() isPackageInfo = false;
  @Input() isPremiumPackage = false;
  @Input() isStandardPackage = false;
  @Input() isPackageBenefits = false;
  @Input() isHowDoesItWork = false;
  @Input() isXSmallIcon = false;
  @Input() standardAmount: string | number;
  @Input() premiumAmount: string | number;
  @Input() currencySymbol: string;
  @Input() isHomeService = false;
  @Input() tooltipAutoShow = false;
  @Input() confirmButtonText = "";
  @Input() confirmButtonTitle = "";
  @Input() confirmButtonBottomText = "";

  public onLeft = false;
  public onRight = false;
  public center = false;
  public onTop = false;
  public onLeftDialog = false;
  public onRightDialog = false;
  public onLeftDialogDeduction = false;

  private dialogComponent = MoreInfoTooltipDialogComponent;

  constructor(private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    if (this.tooltipAutoShow) {
      this.showTooltip();
    }
  }

  public showTooltip() {
    const refDialog = this.dialog.open(this.dialogComponent, {
      panelClass: ["tooltip_dialogbox", "animate__animated"],
      backdropClass: "tooltip_dialogbox_backdrop"
    });
    console.log(this.message);

    refDialog.componentInstance.titleTooltip = this.titleTooltip;
    refDialog.componentInstance.descTooltip = this.message;
    refDialog.componentInstance.isInnerHTML = this.isInnerHTML;
    refDialog.componentInstance.isPackageInfo = this.isPackageInfo;
    refDialog.componentInstance.isPremiumPackage = this.isPremiumPackage;
    refDialog.componentInstance.isStandardPackage = this.isStandardPackage;
    refDialog.componentInstance.isPackageBenefits = this.isPackageBenefits;
    refDialog.componentInstance.isHowDoesItWork = this.isHowDoesItWork;
    refDialog.componentInstance.standardAmount = this.standardAmount;
    refDialog.componentInstance.premiumAmount = this.premiumAmount;
    refDialog.componentInstance.currencySymbol = this.currencySymbol;
    refDialog.componentInstance.currencySymbol = this.currencySymbol;
    refDialog.componentInstance.contentTemplateRef = this.contentTemplateRef;
    refDialog.componentInstance.showCustomTemplateView = this.showCustomTemplateView;
    refDialog.componentInstance.confirmButtonText = this.confirmButtonText;
    refDialog.componentInstance.confirmButtonTitle = this.confirmButtonTitle;
    refDialog.componentInstance.confirmButtonBottomText = this.confirmButtonBottomText;
    refDialog.componentInstance.tooltipAutoShow = this.tooltipAutoShow;
  }
}
