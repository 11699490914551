import { BaseStatementPagePath } from 'app/core/workflow/page-path/statement-page-path/base-statement-page.path';

export class TaxCardComponentPath extends BaseStatementPagePath {
  public static path(): string {
    return 'statement/:statementId/b2c/tax-card';
  }

  public static regex(): RegExp {
    return /(\/statement\/)\d+\/b2c\/tax-card((\/?#?)|(\/?#.*?))$/;
  }
}
