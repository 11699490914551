import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SubscribingComponent } from 'app/common';
import { TaxCardAddService } from 'app/common/tax-card-add.service';
import { CommonModule } from '@angular/common';
import { ButtonTileLoaderComponent } from 'app/shared/standalone/components/loaders/button-tile-loader/button-tile-loader.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-tax-card-add',
  templateUrl: './tax-card-add.component.html',
  styleUrls: ['./tax-card-add.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonTileLoaderComponent,
  ]
})
export class TaxCardAddComponent extends SubscribingComponent {
  @ViewChild('pdfField', { static: false }) pdfFieldRef: ElementRef;
  @Input() taxCardAddService: TaxCardAddService;
  @Input() hasCardButtonEnabled = false;
  @Input() uploadButtonEnabled = true;
  @Input() manuallyAddButtonEnabled = true;

  constructor(
  ) {
    super();
  }

  onPhotoClick(): void {
    this.taxCardAddService.uploadScanClick();
  }

  onUpload(): void {
    this.taxCardAddService.onPDFUpload(this.pdfFieldRef);
  }
}
