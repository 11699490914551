export interface TradeConditionResponse {
  id: number;
  name: string;
}

export class TradeCondition {
  public id: number;
  public name: string;

  public static fromResponse(response: TradeConditionResponse) {
    const tradeCondition = new TradeCondition();
    tradeCondition.id = response.id;
    tradeCondition.name = response.name;
    return tradeCondition;
  }
}
