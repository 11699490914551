<div class="dialog-box">
    <div class="dialog-box__header">
        <div class="title">{{'NEW_STATEMENT.MODAL_TITLE' | translate }}</div>
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content">
        <div class="description">
            {{'NEW_STATEMENT.MODAL_INFO' | translate}}
        </div>
        <div class="remove-batch-actions">
            <button class="btn btn--secondary"
                (click)="newStatement()">{{'NEW_STATEMENT.MODAL_NEW_STATEMENT' | translate}}</button>
            <button class="btn btn--primary"
                (click)="editStatement()">{{ 'NEW_STATEMENT.MODAL_EDIT_STATEMENT' | translate}}</button>
        </div>
    </div>
</div>