import { Component } from '@angular/core';
import { ButtonBaseComponent } from 'app/shared/components/buttons/button-base.component';

@Component({
  ...ButtonBaseComponent.metaData,
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent extends ButtonBaseComponent {
}
