<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content">
        <div class="title">{{ 'REMOVE_BATCH.TITLE' | translate }}</div>
        <div class="description">
            {{ 'REMOVE_BATCH.DESCRIPTION' | translate }}
        </div>
        <div class="remove-batch-actions">
            <button class="btn btn--secondary btn--longer" mat-dialog-close>{{ 'COMMON.CANCEL' | translate }}</button>
            <button class="btn btn--primary btn--longer" (click)="removeBatch()"
                    [disabled]="batchRemoving"
                    [attr.btn-cy]="'btnDeleteStatementBatchConfirm'"
            >
              {{ 'COMMON.REMOVE' | translate }}
            </button>
        </div>
    </div>
</div>
