import { EventEmitter, Injectable } from '@angular/core';
import { catchError, finalize, map, mergeMap, takeUntil } from 'rxjs/operators';
import {SHOW_SNACKBAR} from '../reducers/actions/snackbar';
import {SnackbarConfig} from './snackbar-config';
import { Statement, TaxAuthority } from '../statements';
import {Store} from '@ngrx/store';
import {State} from '../reducers';
import {StatementService} from '../statements/statement.service';
import {SubscribingComponent} from './subscribing-component';
import {Employer} from '../statements/employer';
import { UPDATE_REFUND_DETAILS } from '../reducers/actions/refund-details';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { LohnsteuerValidator } from '../statements/lohnsteuerValidator';
import { ArrayHelper } from '../core/helpers/array.helper';
import { ErrorMessage } from 'app/core/form-validator/validatorInterface';
import { ValidateFieldService } from 'app/core/form-validator/validate-field.service';
import { VALIDATION_FIELD_KEYS_FOR_EMPLOYER } from 'app/core/form-validator/validtorField';

@Injectable({
  providedIn: 'root'
})
export class TaxCardIncomeInfoService {
  public uploadingScan = false;
  public loading = false;
  public scanUploaded = false;
  public scanWasSuccessfullyUploaded = false;
  public mainStatement: Statement;
  public currentStatement: Statement;
  public currentEmployer: Employer;
  public onAddEmployerCardEmitter = new EventEmitter();
  public onSuccessfullyUpdateEmployerEmitter = new EventEmitter();
  public removeEmployerCallbackEmitter = new EventEmitter();
  public enableScanUpload = false;
  public formsError = [];
  public employerHasErrors = [];
  public errors = [];
  public disableOnProceed = false;
  public addTaxCardToStatement = null;
  public validatorIndex = 0;
  public defaultTaxClass = 0;
  public epValidator: ErrorMessage[] = [];
  public empWarningList: LohnsteuerValidator[] = [];
  private ngUnsubscribe: Subject<void>;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private validatorService: ValidateFieldService,
  ) {
  }

  public _init(
    _mainStatement: Statement,
    _currentStatement: Statement = null,
    _ngUnsubscribe: Subject<void>,
  ): void {
    if (_mainStatement) {
      this.mainStatement = _mainStatement;
    }
    if (_currentStatement) {
      this.currentStatement = _currentStatement;
    } else if (_mainStatement) {
      this.currentStatement = _mainStatement;
    }

    this.ngUnsubscribe = _ngUnsubscribe;
  }

  public get _isInitialized(): boolean {
    return !!this.mainStatement && !!this.currentStatement;
  }

  public get isCurrentEmployerHasId(): boolean
  {
    return this.currentEmployer && !!this.currentEmployer.id;
  }

  public formValidationErrors(): void {
    this.epValidator = [];
    if (!this.currentStatement) {
      return;
    }

    this.validatorService.currentYear = this.mainStatement.fullYear;
    let i = 0;

    this.currentStatement.employers.forEach((employer: Employer) => {
      this.validatorService.clearErrorMessages();
      this.validatorService.index = i;

      this.epValidator = [
        ...this.epValidator,
        ...this.validatorService.validateFieldAndGetMessage(employer, VALIDATION_FIELD_KEYS_FOR_EMPLOYER)
      ];

      i++;
    });
  }

  public formValidationWarnings(): void {
    this.empWarningList = [];

    this.currentStatement.employers.forEach((employer, index) => {
      this.empWarningList.push(new LohnsteuerValidator(employer, index));
    });
  }

  public setPeriodOfEmployment(): void {
    this.currentStatement.employers.forEach((item: Employer) => {
      item.formatPeriodOfEmployment(this.mainStatement.fullYear);
    });
  }

  public selectHasEmployerCard(hasEmployerCard: boolean) {
    this.currentStatement.hasEmployers = hasEmployerCard;
    this.addTaxCardToStatement = hasEmployerCard;

    if (!this.currentStatement.hasEmployers) {
      this.currentStatement.employers = [];
    }
  }

  public updateEmployer(showSnackbar = false): void {
    this.loading = true;
    this.disableOnProceed = true;

    this.formsError = [];

    const id = this.currentStatement.id;
    const payload = this.currentStatement.employers.map(employer => employer.toResponse(null));

    const handleResponse = (response: Employer[]) => {
      this.currentStatement.employers = [...response];
      this.onDataUpdateSuccess(true, showSnackbar);
      this.currentEmployer = this.currentStatement.employers[this.validatorIndex];

      this.onSuccessfullyUpdateEmployerEmitter.emit();
    };

    if (id) {
      this.statementService.updateEmployers(id, payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(handleResponse,
          (errors: any) => this.handleErrors(errors.error),
          () => {
            this.disableOnProceed = false;
          });
    }
  }

  public deleteSelectedEmployer(_index: number): void {
    if (this.currentStatement?.employers[_index]?.id) {
      this.removeEmployer(_index);
    } else {
      this.onRemoveEmployerSuccess(_index);
    }
  }

  public removeEmployer(index): void {
    this.mainStatement.deleting = true;
    const currentEmployer = this.currentStatement.employers[index];
    if (currentEmployer) {
      if (currentEmployer.id) {
        this.loading = true;
        this.disableOnProceed = true;

        this.statementService.deleteEmployer(this.currentStatement.id, currentEmployer.id)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => this.mainStatement.deleting = false)
          )
          .subscribe(
            () => {
              this.store.dispatch({
                type: SHOW_SNACKBAR,
                payload: new SnackbarConfig('SNACKBAR.EMPLOYER_DELETED')
              });

              this.onRemoveEmployerSuccess(index);
            },
            (errors: any) => {
              this.errors = errors.error;
            },
            () => {
              this.loading = false;
              this.disableOnProceed = false;
            }
          );
      } else {
        this.store.dispatch({
          type: SHOW_SNACKBAR,
          payload: new SnackbarConfig('SNACKBAR.EMPLOYER_DELETED')
        });

        this.onRemoveEmployerSuccess(index);
      }
    }
  }

  public handleErrors(error: any[]): void {
    this.disableOnProceed = false;
    this.errors = error;
    this.setFormErrors();
  }

  public clearErrors(): void {
    this.errors = [];
    this.employerHasErrors = [];
  }

  public onDataUpdateSuccess(updateRefund = false, showSnackbar = true): void {
    this.clearErrors();

    if (showSnackbar) {
      this.store.dispatch({
        type: SHOW_SNACKBAR,
        payload: new SnackbarConfig('SNACKBAR.EMPLOYER_UPDATED')
      });
    }

    if (updateRefund) {
      this.store.dispatch({ type: UPDATE_REFUND_DETAILS });
    }
  }

  public addNewEmployerCard(): void {
    this.formsError = [];

    const lastIndex = this.currentStatement.employers.length - 1;

    if (lastIndex >= 0 && this.currentStatement.employers[lastIndex].isEmpty()) {
      this.validatorIndex = lastIndex;
      this.currentEmployer = this.currentStatement.employers[lastIndex];

      this.store.dispatch({
        type: SHOW_SNACKBAR,
        payload: new SnackbarConfig('SNACKBAR.LAST_EMPLOYER_IS_EMPTY')
      });

      return;
    }

    const newEmployer = new Employer();
    newEmployer.clear();
    newEmployer.taxClass = this.defaultTaxClass;
    if (this.currentStatement.taxClass) {
      newEmployer.taxClass = this.mainStatement.taxClass;
    }
    this.currentStatement.employers.push(newEmployer);
    this.validatorIndex = this.currentStatement.employers.length - 1;
    this.currentEmployer = newEmployer;
    this.currentStatement.hasEmployers = true;

    this.onAddEmployerCardEmitter.emit();
  }

  public updateHasEmployers(isSpouse = false): Observable<Statement> {
    let data;

    if (!isSpouse) {
      data = this.mainStatement.hasEmployersToResponse();
    } else {
      data = this.mainStatement.spouseHasEmployersToResponse;
    }

    return this.statementService.updateStatement(this.mainStatement.id, data).pipe(
      mergeMap((responseStatement) => {
        const employers = [...this.currentStatement.employers];
        const notExistsEmployerIds = [];
        let employersToCompare = [];
        if (
          this.currentStatement.isSpouse()
          && this.currentStatement.employers.length !== responseStatement.spouse.employers.length
        ) {
          employersToCompare = [...responseStatement.spouse.employers];
        } else if (this.currentStatement.employers.length !== responseStatement.employers.length) {
          employersToCompare = [...responseStatement.employers];
        }

        employersToCompare.some(employer => {
          if (!this.currentStatement.employers.find(x => x.id === employer.id)) {
            notExistsEmployerIds.push(employer.id);
          }
        });

        if (this.currentStatement.isSpouse()) {
          responseStatement.spouse.employers = employers;
        } else {
          responseStatement.employers = employers;
        }

        if (notExistsEmployerIds.length) {
          return this.statementService.deleteMultipleEmployers(this.currentStatement.id, notExistsEmployerIds).pipe(
            map(() => responseStatement),
            catchError(() => observableOf(responseStatement))
          );
        }

        return observableOf(responseStatement);
      })
    );
  }

  private onRemoveEmployerSuccess(index: number): void {
    this.currentStatement.employers.splice(index, 1);

    if (!this.currentStatement.employers.length) {
      this.addTaxCardToStatement = null;
      this.validatorIndex = 0;
      this.currentEmployer = null;
      this.currentStatement.hasEmployers = false;
    }

    this.removeEmployerCallbackEmitter.emit();
  }

  private setFormErrors(): any {
    this.formsError = [];
    this.employerHasErrors = [];
    let index = 0;
    if (this.currentStatement) {
      if (this.currentStatement.employers && this.errors) {
        this.currentStatement.employers.forEach(emp => {
          if (ArrayHelper.isArrayAndNotEmpty(this.errors)) {
            const err = this.errors.filter(er => er.index === index)[0];
            this.formsError[index] = err ? err : [];

            if (err) {
              this.employerHasErrors.push(true);
            } else {
              this.employerHasErrors.push(false);
            }
          }
          index++;
        });
      }
    }
  }
}
