import { WorkflowDeductionApplicantSubBaseService } from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-applicant-sub-base.service';

export class WorkflowDeductionInfoHomeServiceService extends WorkflowDeductionApplicantSubBaseService {
  public get title(): string {
    return 'ASIDE_BAR.APPLICANT_DEDUCTION_SUBLIST.HOME_SERVICE';
  }

  public get subStepNumber(): number {
    return WorkflowDeductionInfoHomeServiceService._subStepNumber;
  }

  public static get _subStepNumber(): number {
    return 4;
  }
}
