import { FieldRuleValue, FieldValidationRules } from "app/core/form-validator/validatorInterface";
import { RegExValidator, ValidatorErrorMessage, ValidatorRules } from "app/core/form-validator/validatorRules";
import { VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME } from "app/core/form-validator/validator-field-names/validator-field-names-additional-income";
import { FormValidatorConfig } from "app/configs/form-validator.config";

export const VALIDATOR_FIELD_RULES_ADDITIONAL_INCOME: FieldValidationRules[] = [
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.social_name,
    classFieldName: "name",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 1, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.social_other_name,
    classFieldName: "income_name",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 1, 50, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.social_amount,
    classFieldName: "amount",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_INDEX,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_DECIMAL,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_NUMBER_MIN
    ],
    fieldRulesValue: [true, true, true, 1, 12, 0],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FILED_NUMBER_TO_LOW
    ]
  },
  {
    year: [2016, 2017, 2018],
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.social_period_lower,
    classFieldName: "periodOfIncome.lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [true, true, true, new FieldRuleValue([`periodOfIncome.upper`])],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: [2016, 2017, 2018],
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.social_period_upper,
    classFieldName: "periodOfIncome.upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [true, true, true, new FieldRuleValue([`periodOfIncome.lower`])],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.pension_from,
    classFieldName: "from",
    fieldRules: [ValidatorRules.IS_REQUIRED_INDEX],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.pension_amount,
    classFieldName: "amount",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_INDEX,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_DECIMAL,
      ValidatorRules.FIELD_NUMBER_MIN
    ],
    fieldRulesValue: [true, true, true, 0],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER,
      ValidatorErrorMessage.FILED_NUMBER_TO_LOW
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.pension_start,
    classFieldName: "start",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DATE],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_DATE]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.pension_adjustment,
    classFieldName: "adjustment",
    fieldRules: [ValidatorRules.FIELD_NUMBER_MIN, ValidatorRules.FIELD_IS_DECIMAL],
    fieldRulesValue: [0, true],
    fieldErrorMessage: [ValidatorErrorMessage.FILED_NUMBER_TO_LOW, ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.freelance_name,
    classFieldName: "name",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 3, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.freelance_income,
    classFieldName: "income",
    fieldRules: [ValidatorRules.IS_REQUIRED_INDEX, ValidatorRules.FIELD_IS_DECIMAL],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.freelance_costs,
    classFieldName: "costs",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DECIMAL, ValidatorRules.FIELD_NUMBER_MIN],
    fieldRulesValue: [true, true, 0],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER,
      ValidatorErrorMessage.FILED_NUMBER_TO_LOW
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.cryptocurrency_income_net,
    classFieldName: "incomeNet",
    fieldRules: [ValidatorRules.IS_REQUIRED_INDEX, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_DECIMAL],
    fieldRulesValue: [true, true, true],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER,
      ValidatorErrorMessage.FILED_NUMBER_TO_LOW
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.tax_authority,
    classFieldName: "tax_authority",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_steuernummer,
    classFieldName: "business_steuernummer",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.STEUER_NUMBER],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_NOT_STEUER]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_postal_code,
    nestedArrayFieldName: true,
    classFieldName: "business_postal_code",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.POSTAL_CODE_DE],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_POSTAL_CODE_DE]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.private_postal_code,
    nestedArrayFieldName: true,
    classFieldName: "private_postal_code",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.POSTAL_CODE_DE],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_POSTAL_CODE_DE]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_start,
    nestedArrayFieldName: true,
    classFieldName: "business_start",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [true, true, true],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_end,
    nestedArrayFieldName: true,
    classFieldName: "business_end",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [true, true, true],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_name,
    nestedArrayFieldName: true,
    classFieldName: "business_name",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_street,
    nestedArrayFieldName: true,
    classFieldName: "business_street",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_city,
    nestedArrayFieldName: true,
    classFieldName: "business_city",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.private_street,
    nestedArrayFieldName: true,
    classFieldName: "private_street",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.private_city,
    nestedArrayFieldName: true,
    classFieldName: "private_city",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.incomes,
    nestedArrayFieldName: true,
    classFieldName: "incomes",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.income_costs,
    nestedArrayFieldName: true,
    classFieldName: "income_costs",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.income_value,
    nestedArrayFieldName: true,
    classFieldName: "income_value",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DECIMAL,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX
    ],
    fieldRulesValue: [true, true, 1, 12],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.vat_value,
    nestedArrayFieldName: true,
    classFieldName: "vat_value",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DECIMAL,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX
    ],
    fieldRulesValue: [true, true, 1, 12],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.operating_vat_type,
    classFieldName: "operating_vat_type",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.total_payments,
    classFieldName: "total_payments",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DECIMAL, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_12],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_12CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.has_sold_business_real_estate,
    classFieldName: "has_sold_business_real_estate",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_type,
    classFieldName: "business_type",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX],
    fieldRulesValue: [true, 25],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_LENGTH_TO_LONG]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.total_withdrawals,
    classFieldName: "total_withdrawals",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DECIMAL, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_12],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_12CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.corona_value,
    classFieldName: "corona_value",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DECIMAL],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER]
  }
];
