import { Component } from '@angular/core';
import { CtrBaseComponent } from '../ctr-base.component';

@Component({
    ...CtrBaseComponent.metaData,
    selector: 'app-ctr-abroad-income',
    templateUrl: './ctr-abroad-income.component.html',
    styleUrls: ['../ctr-base.component.scss'],
})
export class CtrAbroadIncomeComponent extends CtrBaseComponent {

    public checkIfIsResident() : boolean{
        if(this.person === 'applicant'){
            return this.statement.additional_abroad_incomes.length > 0
        }else{
            return this.statement.spouse.additional_abroad_incomes.length > 0
        }
    }

    public getAbroadIncome(){
        if(this.person == 'applicant') 
            return this.statement.additional_abroad_incomes;
        else 
            return this.statement.spouse.additional_abroad_incomes
    }
}
