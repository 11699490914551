import {
  TaxAuthorityResponse,
  TaxClassResponse,
  MaritalStatusResponse,
  StatementYearResponse,
  ReligionResponse
} from 'app/statements/';
import { Religion } from 'app/statements/religion';
import { TaxAuthority } from 'app/statements/tax-authority';
import { TaxClass } from 'app/statements/tax-class';
import { StatementYear } from 'app/statements/statement-year';
import { MaritalStatus } from 'app/statements/marital-status';
import { Country, CountryResponse } from 'app/statements/country';
import { City, CityResponse } from 'app/statements/city';
import { Currency, CurrencyResponse } from 'app/statements/currency';
import { Citizenship, CitizenshipResponse } from 'app/statements/citizenship';

export enum MaritalStatusNames {
  single = 'single',
  married = 'married',
}

export interface SelectsDataResponse {
  religions: ReligionResponse[];
  tax_authorities: TaxAuthorityResponse[];
  tax_classes: TaxClassResponse[];
  marital_statuses: MaritalStatusResponse[];
  statement_years: StatementYearResponse[];
  countries: CountryResponse[];
  citizenship?: CitizenshipResponse[];
  currencies: CurrencyResponse[];
  eu_country_codes: string[];
  income_types: any[];
  volunteering_income_types: any[];
  vat_types: any[];
  costs_types: any[];
  abroad_income_countries: CountryResponse[];
  abroad_income_thresholds: {[year: number]: {[country: string]: number}};
  travel_abroad_countries: CountryResponse[];
  travel_abroad_cities: {[country_code: string]: CityResponse[]};
  total_income_thresholds: {[year: number]: number};
  kinderfreibetrag: {[year: number]: number};
  disability_percentages?: {[year: number]: string[]};
  package_options?: {
    currency_symbol: {code:  string, symbol: string}, default_premium_amount: number, default_standard_amount: number
  };
}

export interface PackageOptionsInterface {
  currencySymbol: CurrencySymbolInterface;
  defaultPremiumAmount: number;
  defaultStandardAmount: number;
  defaultFreeAmount?: number;
}

export interface CurrencySymbolInterface {
  code: string;
  symbol: string;
}

export interface SelectsDataInterface {
  religions?: Religion[];
  religionsTranslated?: Religion[];
  taxAuthorities?: TaxAuthority[];
  taxClasses?: TaxClass[];
  maritalStatuses?: MaritalStatus[];
  statementYears?: StatementYear[];
  countries?: Country[];
  citizenships?: Citizenship[];
  currencies?: Currency[];
  euCountryCodes?: string[];
  abroadIncomeCountries?: Country[];
  abroadIncomeThresholds?: {[year: number]: {[country: string]: number}};
  travelAbroadCountries?: Country[];
  travelAbroadCities?: {[countryCode: string]: City[]};
  totalIncomeThresholds?: {[year: number]: number};
  childTaxCredits?: {[year: number]: number};
  disabilityPercentages?: {[year: number]: string[]};
  packageOptions?: PackageOptionsInterface;
  incomeTypes: any[];
  vat_types: any[];
  costs_types: any[];
  volunteeringIncomeTypes: any[];
  getDisabilityPercentages(fullYear: number): string[];
  getMarriedMaritalStatusId(): number;
  getSingleMaritalStatusId(): number;
  getCountryName(countryCode: string): string;
}

export class SelectsData implements SelectsDataInterface {
  religions = [];
  religionsTranslated = [];
  taxAuthorities = [];
  taxClasses = [];
  maritalStatuses = [];
  statementYears = [];
  countries = [];
  citizenships = [];
  currencies = [];
  euCountryCodes = [];
  abroadIncomeCountries = [];
  incomeTypes = [];
  volunteeringIncomeTypes = []
  abroadIncomeThresholds = {};
  travelAbroadCountries = [];
  travelAbroadCities = {};
  totalIncomeThresholds = {};
  childTaxCredits = {};
  disabilityPercentages = {};
  vat_types: any[] = [];
  costs_types: any[] = [];
  packageOptions = {
    currencySymbol: {
      code: 'EUR',
      symbol: '€'
    },
    defaultPremiumAmount: 99.00,
    defaultStandardAmount: 34.90,
    defaultFreeAmount: 0.00,

  };

  public static fromResponse(response: SelectsDataResponse): SelectsDataInterface {
    const selectsData = new SelectsData();
    selectsData.religions = response.religions.map(Religion.fromResponse);
    selectsData.religions = Array.isArray(selectsData.religions)
      ? selectsData.religions.sort(SelectsData.sortReligionByOrder)
      : selectsData.religions;
    selectsData.religionsTranslated = SelectsData.translateReligions(selectsData.religions);
    selectsData.taxAuthorities = response.tax_authorities.map(TaxAuthority.fromResponse);
    selectsData.taxClasses = response.tax_classes.map(TaxClass.fromResponse);
    selectsData.maritalStatuses = response.marital_statuses.map(MaritalStatus.fromResponse);
    selectsData.statementYears = response.statement_years.map(StatementYear.fromResponse);
    selectsData.countries = response.countries.map(Country.fromResponse);
    selectsData.citizenships = response.countries.map(Citizenship.fromResponse);
    selectsData.incomeTypes = response.income_types;
    selectsData.volunteeringIncomeTypes = response.volunteering_income_types;
    selectsData.vat_types = response.vat_types;
    selectsData.costs_types = response.costs_types;
    selectsData.citizenships = selectsData.citizenships.sort(a => {
      if (a.code === 'PL') {
        return -2;
      }
      if (a.code === 'DE') {
        return -1;
      }
      return 0;
    });
    selectsData.currencies = response.currencies.map(Currency.fromResponse);
    selectsData.euCountryCodes = response.eu_country_codes;
    selectsData.abroadIncomeCountries = response.abroad_income_countries.map(Country.fromResponse);
    selectsData.abroadIncomeThresholds = response.abroad_income_thresholds;
    selectsData.travelAbroadCountries = response.travel_abroad_countries.map(Country.fromResponse);
    Object.keys(response.travel_abroad_cities).some(x => {
      selectsData.travelAbroadCities[x] = response.travel_abroad_cities[x]
        .map(City.fromResponse).concat(City.TRAVEL_ABROAD_OTHER_CITY);
    });
    selectsData.totalIncomeThresholds = response.total_income_thresholds;
    selectsData.childTaxCredits = response.kinderfreibetrag;
    selectsData.disabilityPercentages = response.disability_percentages;
    if (response.package_options && response.package_options.currency_symbol) {
      selectsData.packageOptions = {
        currencySymbol: {
          code: response.package_options.currency_symbol.code || selectsData.packageOptions.currencySymbol.code,
          symbol: response.package_options.currency_symbol.symbol || selectsData.packageOptions.currencySymbol.symbol
        },
        defaultPremiumAmount: response.package_options.default_premium_amount
          || selectsData.packageOptions.defaultPremiumAmount,
        defaultStandardAmount: response.package_options.default_standard_amount
          || selectsData.packageOptions.defaultStandardAmount,
        defaultFreeAmount: selectsData.packageOptions.defaultStandardAmount
      };
    }

    return selectsData;
  }

  private static translateReligions(religions: Religion[]): Religion[] {
    if (!religions[0].name.includes('RELIGIONS')) {
      return religions.map(religion => {
        religion.name = `RELIGIONS.${religion.name.toUpperCase()}`;
        return religion;
      });
    }

    return religions;
  }

  private static sortReligionByOrder = (religion1: Religion, religion2: Religion) => {
    return religion1.order - religion2.order;
  }

  public getDisabilityPercentages(fullYear: number): string[] {
    if (this.disabilityPercentages) {
      if (this.disabilityPercentages[fullYear]) {
        return this.disabilityPercentages[fullYear];
      }

      const disabilityLevelKeys = Object.keys(this.disabilityPercentages);
      return  this.disabilityPercentages[disabilityLevelKeys.length - 1];
    }

    return [];
  }

  public getMarriedMaritalStatusId(): number {
    const status = this.maritalStatuses.find(item => item.name.toLowerCase() === MaritalStatusNames.married);
    return status ? status.id : 0;
  }

  public getSingleMaritalStatusId(): number {
    const status = this.maritalStatuses.find(item => item.name.toLowerCase() === MaritalStatusNames.single);
    return status ? status.id : 0;
  }

  public getCountryName(countryCode: string): string {
    const country = this.countries.find(item => item.code === countryCode);
    return country ? country.name : '';
  }
}
