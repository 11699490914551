import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EnsureModuleLoadedOnceGuard } from 'app/core/guards/ensure-module-loaded-once.guard';
import { LoginDialogComponent } from 'app/shared/standalone/components/login-dialog/login-dialog.component';
import { StarterBoardPage } from 'app/core/pages/starter-board-page/starter-board.page';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';
import { StepsControllerService } from 'app/core/workflow/steps-controller/steps-controller.service';
import { ValidateFieldService } from 'app/core/form-validator/validate-field.service';
import { ValidateFieldV2Service } from 'app/core/form-validator/validate-field-v2.service';
import { UserHttpService } from 'app/modules/user/services/http/user.http.service';
import { AdditionalIncomeService } from 'app/modules/statement/services/additional-income.service';
import { DeductionsService } from 'app/modules/statement/services/deductions.service';
import { PersonalInfoChildService } from 'app/modules/statement/services/personal-info-child.service';
import { CaptchaModule } from 'app/shared/modules/captcha/captcha.module';
import { TermsFilesService } from 'app/core/services/terms-files.service';

@NgModule({
  declarations: [
    StarterBoardPage,
  ],
  imports: [
    CommonModule,
    SharedModule,
    LoginDialogComponent,
    CaptchaModule,
  ],
  providers: [
    WorkflowControllerService,
    StepsControllerService,
    ValidateFieldService,
    ValidateFieldV2Service,
    UserHttpService,
    AdditionalIncomeService, // TODO: move to StatementModule
    DeductionsService, // TODO: move to StatementModule
    PersonalInfoChildService, // TODO: move to StatementModule
    TermsFilesService,
  ],
})

export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
