import { Component, Input } from '@angular/core';
import { SubscribingComponent } from 'app/common/subscribing-component';

@Component({
  template: ''
})
export abstract class TooltipBaseComponent extends SubscribingComponent {
  static metaData = {
    inputs: [
      'message',
      'displayTooltip',
      'sellect_button',
    ]
  };
  @Input() message: string;
  @Input() displayTooltip = false;
  @Input() sellect_button = true;

  private firstFocus = true;

  constructor() {
    super();
  }

  onFocus(opened = true) {
    if (this.firstFocus && opened) {
      this.openTooltip();
      setTimeout(() => this.closeTooltip(), 5000);
    } else {
      this.closeTooltip();
    }
  }

  toggleTooltip() {
    if (this.displayTooltip) {
      this.closeTooltip();
    } else {
      this.openTooltip();
    }
  }

  openTooltip() {
    this.displayTooltip = true;
    this.firstFocus = false;
  }

  closeTooltip() {
    this.displayTooltip = false;
  }
}
