import { finalize, mergeMap, takeUntil } from "rxjs/operators";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { State, getUser } from "../../reducers";
import { SHOW_SNACKBAR } from "../../reducers/actions/snackbar";
import { StatementService, StatementsResponse } from "../../statements/statement.service";
import { SubscribingComponent, SnackbarConfig } from "../../common";
import { Statement } from "../../statements";
import { ValidationErrorsDialogComponent } from "../../common/validation-errors-dialog/validation-errors-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ProcessingPlaceholderComponent } from "../../statements/processing-placeholder/processing-placeholder.component";

@Component({
  selector: "app-client-details",
  templateUrl: "./client-details.component.html",
  styleUrls: ["./client-details.component.scss"]
})
export class ClientDetailsComponent extends SubscribingComponent implements OnInit {
  @ViewChild("ericField", { static: false }) ericField: ElementRef;

  batchId: number;
  statementId: number;
  statements: Statement[];
  batchCode: string;
  isStatementCreating = false;
  buttons = [
    {
      type: "back",
      label: "COMMON.BACK",
      action: this.goBack.bind(this)
    },
    {
      type: "proceed",
      label: "COMMON.PROCEED",
      action: this.proceed.bind(this),
      disabled: this.disabledProceedButton.bind(this)
    }
  ];
  public validationErrorsDialogRef: MatDialogRef<ValidationErrorsDialogComponent>;
  private processingDialogOpenedTime: number;
  private jsonValidationErrors: any;
  private processingDialogRef: MatDialogRef<ProcessingPlaceholderComponent>;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.batchId = parseInt(params["batchId"], 10);
      this.getBatchStatements();
    });
  }

  goBack() {
    this.router.navigate([`new`]);
  }

  proceed() {
    this.router.navigate([`new/batch/${this.batchId}/client-summary`]);
  }

  editClient(statement) {
    if (statement.employer) {
      this.router.navigate([`new/statement/${statement.id}/employer/${statement.employers.pop().id}`]);
    }
    this.router.navigate([`new/statement/${statement.id}/employer/0`]);
  }

  getBatchStatements() {
    this.statementService
      .getBatchStatements(this.batchId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: StatementsResponse) => {
        this.statements = response.statements;
        this.getBatchCode();
      });
  }

  disabledProceedButton() {
    return !this.statements || !this.statements.length || this.statements.some((statement) => !statement.isReady());
  }

  editButton(statement: Statement) {
    this.editClient(statement);
  }

  getBatchCode() {
    if (this.statements.length) {
      this.batchCode = this.statements[0].batchCode;
    } else {
      this.batchCode = "COMMON.BATCH";
    }

    this.closeProcessingPlaceholder();
  }

  createStatement() {
    this.isStatementCreating = true;
    let newStatementId = null;
    this.statementService
      .createStatement()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        mergeMap((statement: Statement) => {
          newStatementId = statement.id;
          return this.statementService.createEmployer(statement.id);
        }),
        takeUntil(this.ngUnsubscribe),
        mergeMap(() => {
          return this.statementService.updateStatement(newStatementId, {
            id: newStatementId,
            batch_statement: this.batchId
          });
        }),
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.isStatementCreating = false)),
        mergeMap(() => {
          return this.statementService.getBatchStatements(this.batchId);
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((response: StatementsResponse) => {
        this.statements = response.statements;
        this.getBatchCode();
        this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig("SNACKBAR.STATEMENT_CREATED") });
      });
  }

  public importStatement(statement: Statement) {
    if (statement) {
      this.statementId = statement.id;
      const el = this.ericField.nativeElement;
      el.click();
    }
  }

  public uploadFile() {
    this.uploadJson(this.ericField);
  }
  private uploadJson(ericField: ElementRef) {
    this.showProcessingPlaceholder();
    const file: File = ericField.nativeElement.files.item(0);
    this.statementService
      .uploadEricJson(file, false, this.statementId, this.batchId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {})
      )
      .subscribe(
        (response) => {
          this.getBatchStatements();
        },
        (error: any) => {
          if (error.error) {
            this.jsonValidationErrors = error.error;
          }
          this.closeProcessingPlaceholder(!!error.error);
        }
      );
  }

  private showProcessingPlaceholder() {
    this.processingDialogOpenedTime = performance.now();
    this.processingDialogRef = this.dialog.open(ProcessingPlaceholderComponent, {
      width: "975px",
      disableClose: true
    });
  }

  private closeProcessingPlaceholder(showErrorsDialog = false): void {
    const close = () => {
      if (this.processingDialogRef) {
        this.processingDialogRef.close();
      }
      if (showErrorsDialog) {
        this.validationErrorsDialogRef = this.dialog.open(ValidationErrorsDialogComponent);
        this.validationErrorsDialogRef.componentInstance.validationErrors = this.jsonValidationErrors;
      }
    };
    const minTime = 2000;
    const timeLeft = this.processingDialogOpenedTime ? performance.now() - this.processingDialogOpenedTime : 0;
    if (timeLeft < minTime) {
      setTimeout(() => {
        close();
      }, minTime - timeLeft);
    } else {
      close();
    }
  }
}
