<div *ngIf="!categoryProperties.isEmpty" id="{{categoryProperties.key}}">
  <div class="d-flex justify-content-between align-items-center mb-1_25">
    <div class="font-size-24 font-weight-bold" data-cy-class="title" >{{ title }}</div>
    <div class="report_error d-flex align-items-center hidden_sm" *ngIf="!summary">
      <mat-checkbox
        class="mat-checkbox-danger"
        (change)="handleCheckboxError(textareaError)"
        [(ngModel)]="categoryProperties.reportError"
        [attr.data-cy]="'checkboxConfirmTaxReturnReportError_'+categoryProperties.key"
      >
      </mat-checkbox>
      <div class="checkbox_label font-weight-bold font-size-20 ml-0_75">
        {{'CONFIRM_TAX_RETURN.REPORT_ERROR.CHECKBOX_LABEL' | translate }}
      </div>
    </div>
    <div
      class="report_error d-flex align-items-center hidden_sm"
      *ngIf="summary && categoryProperties.stepUrl"
    >
      <app-button-edit
        [withText]="true"
        (clickEmitter)="goToStepWorkflow()"
      ></app-button-edit>
    </div>
  </div>

  <ng-container *ngTemplateOutlet='templateRef'></ng-container>

  <div class="report_error d-flex align-items-center hidden_above_sm mt-n1 mb-2" *ngIf="!summary">
    <mat-checkbox
      class="mat-checkbox-danger"
      (change)="handleCheckboxError(textareaError)"
      [(ngModel)]="categoryProperties.reportError"
    >
    </mat-checkbox>
    <div class="checkbox_label font-weight-bold font-size-20 ml-0_75">
      {{'CONFIRM_TAX_RETURN.REPORT_ERROR.CHECKBOX_LABEL' | translate }}
    </div>
  </div>
  <div #textareaError class="mb-2 mt-n1" [hidden]="!categoryProperties.reportError" *ngIf="!summary">
    <app-form-input
      [textArea]="true"
      [placeholder]="'CONFIRM_TAX_RETURN.REPORT_ERROR.TEXTAREA_PLACEHOLDER'"
      [label]="'CONFIRM_TAX_RETURN.REPORT_ERROR.TEXTAREA_LABEL'"
      [(model)]="categoryProperties.errorMessage"
      [attr.data-cy]="'inputConfirmTaxReturnReportError_'+categoryProperties.key"
    ></app-form-input>
  </div>
  <div
    class="report_error d-flex align-items-center hidden_above_sm mt-n1 mb-2"
    *ngIf="summary && categoryProperties.stepUrl"
  >
    <app-button-edit
      [withText]="true"
      [isSmallMobile]="true"
      (clickEmitter)="goToStepWorkflow()"
    ></app-button-edit>
  </div>
</div>
