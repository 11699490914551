<div class="dialog-box">
    <div class="dialog-box__header">
        <h1 class="title">
            <span>{{ 'UPSELL.TITLE' | translate }}</span>
            <img src="../../../assets/svg/logo-premium.svg" alt="taxando premium">
        </h1>
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>

    <div class="dialog-box__content">
        <div class="dialog-box__description">
            <p class="description">{{ 'UPSELL.DESCRIPTION' | translate }}</p>
        </div>
        <div class="dialog-box__section">
            <div class="dialog-box__section__icon">
                <img src="../../../assets/svg/upsell_online.svg">
            </div>
            <div class="dialog-box__section__label">
                <p class="dialog-box__section__header">{{ 'UPSELL.SEND_ONLINE' | translate }}</p>
                <p class="dialog-box__section__desc">{{ 'UPSELL.SEND_ONLINE_DESCRIPTION' | translate }}</p>
            </div>
        </div>

        <div class="dialog-box__section">
            <div class="dialog-box__section__wrap">
                <div class="dialog-box__section__icon">
                    <img src="../../../assets/svg/upsell_return.svg">
                </div>
                <div class="dialog-box__section__label">
                    <div>
                        <p class="dialog-box__section__header">{{ 'UPSELL.ADDITIONAL_TAX_RETURN' | translate }}</p>
                        <p class="dialog-box__section__desc">{{ 'UPSELL.ADDITIONAL_TAX_RETURN_DESCRIPTION' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="possible-return-wrap">
                <div class="possible-return-title desktop"> {{ 'UPSELL.LABEL_RETURN' | translate }} </div>
                <div class="possible-return">
                    <p>+ {{possibleTaxReturn | number : '1.2-2'}} &euro;</p>
                </div>
                <div class="possible-return-title mobile"> {{ 'UPSELL.LABEL_RETURN' | translate }} </div>
            </div>
        </div>

        <div class="dialog-box__section">
            <div class="dialog-box__section__icon">
                <img src="../../../assets/svg/upsell_decision.svg">
            </div>
            <div class="dialog-box__section__label">
                <p class="dialog-box__section__header">{{ 'UPSELL.DECISION' | translate }}</p>
                <p class="dialog-box__section__desc">{{ 'UPSELL.DECISION_DESCRIPTION' | translate }}</p>
            </div>
        </div>

        <div class="dialog-box__actions">
            <button class="btn btn--secondary" mat-dialog-close>{{ 'UPSELL.NO' | translate }}</button>
            <button class="btn btn--primary" (click)="confirm()">{{ 'UPSELL.YES' | translate }}</button>
        </div>
    </div>
</div>
