<div class="form-input" (clickElsewhere)="closeTooltip()">
    <div class="form-input__header level">
        <div class="level-left" (click)="closeTooltip()">
            <label class="form-input__label" [class.center]="centerLabel" [class.sm]="smLabel">
                <span class="form-input__label-tx">{{ label | translate }}</span>
            </label>
        </div>
        <app-more-info-tooltip class="tooltipInInput" *ngIf="message" [message]="message" [isSmall]="true" [showText]="false" [additionalDeductions]="true" [tooltipInInput]=true></app-more-info-tooltip>

        <!-- <div class="level-right" *ngIf="message">
          <span class="more-info__ico info-ico is-unselectable" (click)="toggleTooltip()">i</span>
        </div> -->
    </div>
    <div class="form-input__content columns is-mobile" [class.wrap]="wrapInputs">
        <div class="form-input__part column" [class.full]="wrapInputs">
            <span *ngIf="prefix">{{ prefix | translate }}</span>
            <input matInput
                   [placeholder]="placeholder | translate"
                   (focus)="onFocus()"
                   (ngModelChange)="change(0, $event)"
                   maxlength="10"
                   [ngModel]="formatDate(model)"
                   [class.form-input--error]="errors && errors.length"
                   [class.center]="centerTxtInput">
         </div>
        <div class="form-input__part column" [class.full]="wrapInputs">
            <span *ngIf="prefix2">{{ prefix2 | translate }}</span>
            <input matInput
                   [placeholder]="placeholder2 | translate"
                   (focus)="onFocus()"
                   (ngModelChange)="change(1, $event)"
                   maxlength="10"
                   [ngModel]="formatDate(model2)"
                   [class.form-input--error]="errors2 && errors2.length"
                   [class.center]="centerTxtInput">
         </div>
    </div>
    <!-- <div class="tooltip-container bottom" *ngIf="displayTooltip && message" role="tooltip">
      <div class="tooltip-container__close">
        <span class="fa fa-times-circle" (click)="closeTooltip()"></span>
      </div>
      <div class="tooltip-container__text">{{ message | translate }}</div>
    </div> -->
    <div class="form-input__error" *ngIf="errors">{{ errors[0] }}</div>
    <div class="form-input__error" *ngIf="errors2">{{ errors2[0] }}</div>
</div>
