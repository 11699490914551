<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>

    <div class="dialog-box__content confirmation-content">
        <h1>{{ 'COMMON.CONFIRMATION' | translate }}</h1>
        <p>{{ (data.question) | translate }}</p>
        <div class="confirmation-actions">
            <button class="btn btn--secondary btn--longer" mat-dialog-close>{{ 'COMMON.NO' | translate }}</button>
            <button class="btn btn--primary btn--longer" (click)="confirm()">{{ 'COMMON.YES' | translate }}</button>
        </div>
    </div>
</div>