import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';

export class WorkflowBatchClientDetailsService extends WorkflowStepsBaseService {
  public get isRequired(): boolean {
    return this.isBusiness && !this.isTaxAdviser;
  }

  public get checkCanGo(): boolean {
    if (this.isRequired && this.statement) {
      if (this.statement.isReady() && this.isBankAccountPassed) {
        return true;
      }
    }

    return false;
  }

  public get url(): string {
    return `batch/${this.statement && this.statement.batchId ? this.statement.batchId : 0}/client-details`;
  }
}
