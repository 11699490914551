export interface ChildKindergeldResponse {
  amount: number;
}

export interface ChildKindergeldInterface {
  amount: number;
  amountFormatted: string;
}

export class ChildKindergeld implements ChildKindergeldInterface {
  amount = 0;

  static fromResponse(response: ChildKindergeldResponse): ChildKindergeld {
    const childKindergeld = new ChildKindergeld();
    childKindergeld.amount = response.amount;

    return childKindergeld;
  }

  public get amountFormatted(): string {
    return this.amount.toFixed(2);
  }
}
