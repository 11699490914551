import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectsDataInterface, Statement } from 'app/statements';
import { Store } from '@ngrx/store';
import { getSelectsData, State } from '../../reducers';
import { SubscribingComponent } from '../subscribing-component';

@Component({
  selector: 'app-accept-atthorney-dialog',
  templateUrl: './accept-atthorney-dialog.component.html',
  styleUrls: ['./accept-atthorney-dialog.component.scss']
})
export class AcceptAtthorneyDialogComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  dataSelects: SelectsDataInterface;
  language = 'DE';
  LANGUAGES = {
    'pl': 'PL',
    'en': 'EN',
    'de': 'DE'
  };

  constructor(private translate: TranslateService, private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.language = this.LANGUAGES[this.translate.currentLang] || this.language;
    this.store.select(getSelectsData).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((selectsData: SelectsDataInterface) => {
        this.dataSelects = selectsData;
      });
  }

  public displayCountry() {
    try {
      return this.dataSelects.countries.filter(kk => kk.code === this.statement.country)[0].name;
    } catch {
      return '';
    }
  }
}
