import { Injectable } from '@angular/core';
import { AuthHttpService } from '../users';
import { getApiPath } from '../common';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StatementPaymentAmount } from './statement-payment-amont';

@Injectable()
export class StatementPaymentAmountService {
  constructor(
    private authHttp: AuthHttpService,
  ) { }

  public getStatementPaymentAmount(statementId: number): Observable<StatementPaymentAmount> {
    return this.authHttp.get(
      `${getApiPath()}statements/${statementId}/payment-amount`
    ).pipe(
      map((response: any) => StatementPaymentAmount.fromResponse(response))
    );
  }
}
