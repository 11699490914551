import { Component, Input } from "@angular/core";
import { AdditionalIncomeService } from "app/modules/statement/services/additional-income.service";
import { SocialIncome } from "app/modules/statement/models/additional-income/social-income.model";
import { SocialIncomeTypes } from "app/modules/statement/models/additional-income/social-income.types";
@Component({
  selector: "app-additional-income-form-social",
  templateUrl: "./additional-income-form-social.component.html"
})
export class AdditionalIncomeFormSocialComponent {
  @Input() socialIncome: SocialIncome;
  @Input() index = 0;

  constructor(public additionalIncomeService: AdditionalIncomeService) {}

  public get requireIncomeName(): boolean {
    if (this.socialIncome.incomeType === SocialIncomeTypes.OTHER) {
      return true;
    }
    return false;
  }
}
