import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { Statement } from "../../statements/statement";
import { TaxYearInterface } from "app/statements/tax-year.interface";

@Component({
  selector: "app-card-view",
  templateUrl: "./card-view.component.html",
  styleUrls: ["./card-view.component.scss"]
})
export class CardViewComponent implements OnInit {
  @Input() statements: Statement[];
  @Input() yearsList: TaxYearInterface[] = [];
  @Output() downloadPdf = new EventEmitter<Statement>();
  @Output() downloadInvoice = new EventEmitter<Statement>();
  @Output() showDownloadDialog = new EventEmitter<Statement>();
  @Output() deleteStatement = new EventEmitter<{ statement: Statement; listIndex: number }>();
  @Output() cloneStatement = new EventEmitter<Statement>();

  public yearList: number[] = [];

  constructor() {}

  ngOnInit() {}

  emitDownloadPdf(statement: Statement) {
    this.downloadPdf.emit(statement);
  }

  emitDownloadInvoice(statement: Statement) {
    this.downloadInvoice.emit(statement);
  }

  emitShowDownloadDialog(statement: Statement) {
    this.showDownloadDialog.emit(statement);
  }

  emitDeleteStatement(statement: Statement, listIndex: number) {
    this.deleteStatement.emit({ statement, listIndex });
  }

  emitCloneStatement(statement: Statement) {
    this.cloneStatement.emit(statement);
  }

  public expandYearList(_item: TaxYearInterface) {
    _item.active = !_item.active;
  }
}
