<ng-container *ngIf="showLoader$ | async">
  <app-content-loader></app-content-loader>
</ng-container>
<ng-container *ngIf="isInitialized$ | async">
  <div class="step-container columns is-desktop" [class.d-none]="showLoader$ | async">
    <app-aside-bar
      [showEvenWithoutStatement]="false"
      [statement]="statement"
      [workFlowController]="workFlowController"
    ></app-aside-bar>
    <div class="main-content__container payment-checkout">
      <ng-container *ngIf="showSquareLoader$ | async">
        <app-square-loader></app-square-loader>
      </ng-container>
      <div [class.d-none]="showSquareLoader$ | async">
        <h1 class="title_42">{{ 'CHECKOUT.TITLE' | translate }}</h1>
        <p class="subdesc">{{ 'CHECKOUT.DESCRIPTION' | translate }}</p>

        <div class="payment-checkout__content form-content">
          <div class="payment-checkout__stage payment-banner__wrap">
            <app-payment-banner
              [statement]="statement"
              [statementPaymentAmount]="statementPaymentAmount"
            ></app-payment-banner>
          </div>

          <ng-container *ngIf="statement.isStandardPackage">
            <div class="payment-checkout__feedback_banner" *ngIf="statementPaymentAmount.isFree">
              <img src="../../../assets/svg/feedback.svg" alt="Feedback" />
              <div class="font-size-20 text-muted">
                {{ 'CHECKOUT.CLIENT_FEEDBACK' | translate }}
              </div>
            </div>

            <div class="payment-checkout__change_package" *ngIf="statement.isPremiumPackageAvailable">
              <div class="payment-checkout__change_package-left">
                <div class="desc_checkout_change" [innerHTML]="'CHECKOUT.CHANGE_TO_STEUER_DESC' | translate"></div>
                <div class="tooltip_wrap">
                  <app-more-info-tooltip
                      [showText]="false"
                      [titleTooltip]="'CHECKOUT.STEUER_TOOLTIP_TITLE'"
                      [contentTemplateRef]="premiumPackageTooltipTemplate"
                    ></app-more-info-tooltip>
                    <ng-template #premiumPackageTooltipTemplate>
                      <app-tooltip-template-package-info 
                        [isPremiumPackage]="true"
                        [isPackageBenefits]="true"
                        [isHowDoesItWork]="true"
                        [standardAmount]="statementPaymentAmount.standardAmountTotal"
                        [premiumAmount]="statementPaymentAmount.premiumAmountTotal"
                        [currencySymbol]="statementPaymentAmount.currencySymbol">
                      </app-tooltip-template-package-info>
                    </ng-template>
                </div>
              </div>
              <button
                class="select_struer btn_simple_arrow"
                (click)="setPremiumPackage()"
              >
                {{ 'CHECKOUT.SELECT_STEUER' | translate }}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <rect class="a"/><path class="b" d="M25.525,21.439l-7.262-7.262a1.735,1.735,0,1,0-2.454,2.454L20.1,20.943H4.235a1.735,1.735,0,1,0,0,3.47H20.1L15.809,28.7a1.744,1.744,0,1,0,2.478,2.454l7.237-7.237a1.764,1.764,0,0,0,.52-1.239A1.666,1.666,0,0,0,25.525,21.439Z" transform="translate(1.5 -6.675)"/>
                </svg>
              </button>
            </div>
          </ng-container>


          <ng-container *ngIf="statement.isPremiumPackage">
            <div class="payment-checkout__change_package">
              <ng-container *ngIf="statement.isStandardPackageAvailable">
                <div class="payment-checkout__change_package-left">
                  <div
                    class="desc_checkout_change"
                    [innerHTML]="'CHECKOUT.CHANGE_TO_PREMIUM_DESC' | translate"
                  >
                  </div>
                  <div class="tooltip_wrap">
                    <app-more-info-tooltip
                        [showText]="false"
                        [titleTooltip]="'CHECKOUT.STANDARD_TOOLTIP_TITLE'"
                        [message]="'CHECKOUT.STANDARD_TOOLTIP_DESC'"
                        [contentTemplateRef]="standardPackageTooltipTemplate2"
                      ></app-more-info-tooltip>

                      <ng-template #standardPackageTooltipTemplate2>
                        <app-tooltip-template-package-info 
                          [isStandardPackage]="true"
                          [isPackageBenefits]="true"
                          [isHowDoesItWork]="true"
                          [standardAmount]="statementPaymentAmount.standardAmountTotal"
                          [premiumAmount]="statementPaymentAmount.premiumAmountTotal"
                          [currencySymbol]="statementPaymentAmount.currencySymbol">
                        </app-tooltip-template-package-info>
                      </ng-template>
                  </div>
                </div>
                <button class="btn_standard btn_simple_arrow" (click)="setStandardPackage()">
                  {{ 'CHECKOUT.CHANGE_TO_PREMIUM_BUTTON'|translate }}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <rect class="a"/><path class="b" d="M25.525,21.439l-7.262-7.262a1.735,1.735,0,1,0-2.454,2.454L20.1,20.943H4.235a1.735,1.735,0,1,0,0,3.47H20.1L15.809,28.7a1.744,1.744,0,1,0,2.478,2.454l7.237-7.237a1.764,1.764,0,0,0,.52-1.239A1.666,1.666,0,0,0,25.525,21.439Z" transform="translate(1.5 -6.675)"/>
                  </svg>
                </button>
              </ng-container>
            </div>
          </ng-container>

          <div
            class="payment-checkout__coupon_code"
            *ngIf="(statement.isPremiumPackage && !statementPaymentAmount.shouldBeIndividualService)
            || (statement.isStandardPackage && statementPaymentAmount.standardAmountTotal)"
          >
            <div
              class="payment-checkout__coupon_code_check"
              (click)="showCoupon = true"
              *ngIf="!showCoupon && !couponCode"
            >
              {{ 'CHECKOUT.COUPON_CODE_CHECK' | translate }}
            </div>
            <ng-container *ngIf="showCoupon || couponCode">
              <div *ngIf="statement" class='payment-checkout__coupon_form'>
                <app-form-input
                  class="w-100"
                  [placeholder]="'INPUT.COUPON_CODE.PLACEHOLDER'"
                  (modelChange)="couponChanged()"
                  [(model)]="couponCode"
                  [disableLabel]="true"
                  [iconSrc]="couponSuccess ? '../../../assets/svg/checkout_check.svg' : null"
                  [errors]="couponError"
                >
                </app-form-input>
                <button (click)="addCode()" class="btn_sm ml-1">
                  {{ 'CHECKOUT.USE_COUPON' | translate }}
                </button>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="statement.isStandardPackage">
            <div class="payment-checkout__order">
              <h3 class="title_24">
                {{ 'CHECKOUT.YOUR_ORDER_TITLE' | translate }}
              </h3>
              <div class="payment-checkout__order_wrap">
                <ul class="payment-checkout__order_list">
                  <li>
                    <div class="payment-checkout__order_list_title">
                      {{ 'CHECKOUT.ORDER_LIST_STANDARD_NETTO' | translate }}
                    </div>
                    <div class="payment-checkout__order_list_value">
                      {{ statementPaymentAmount.currencySymbol }} {{ statementPaymentAmount.standardNet | number: '1.2-2' }}
                    </div>
                  </li>
                  <li>
                    <div class="payment-checkout__order_list_title">
                      {{ 'CHECKOUT.ORDER_LIST_VAT' | translate }} ({{ statementPaymentAmount.vatPercentFormatted }})
                    </div>
                    <div class="payment-checkout__order_list_value">
                      {{ statementPaymentAmount.currencySymbol }} {{ statementPaymentAmount.standardAmountVat | number: '1.2-2' }}
                    </div>
                  </li>
                  <li>
                    <div class="payment-checkout__order_list_title">
                      <b>{{ 'CHECKOUT.ORDER_LIST_TOTAL' | translate }}</b>
                    </div>
                    <div class="payment-checkout__order_list_value">
                      <b>{{ statementPaymentAmount.currencySymbol }} {{ statementPaymentAmount.standardAmountTotalFormatted(coupon) | number: '1.2-2' }}</b>
                    </div>
                  </li>
                  <li *ngIf="coupon && couponSuccess && statementPaymentAmount.standardAmountTotalBase">
                    <div class="payment-checkout__order_list_title">
                      {{ 'CHECKOUT.ORDER_LIST_COUPON' | translate }} 
                    </div>
                    <div class="payment-checkout__order_list_value">
                      - {{ statementPaymentAmount.currencySymbol }} {{ coupon.amount(statementPaymentAmount.standardAmountTotalBase) | number: '1.2-2' }}
                    </div>
                  </li>
                </ul>
                <div class="payment-checkout__customer_data">
                  <p>
                    <b>{{ 'CHECKOUT.CUSTOMER_PERSONAL_DATA' | translate }}</b>
                  </p>
                  <p>
                    {{ statement.getFullname() }}
                  </p>
                  <p>
                    {{ statement.street }} {{ statement.houseNumberWithSupplement }}
                  </p>
                  <p *ngIf="statement.additionalAddressInfo">
                    {{ statement.additionalAddressInfo }}
                  </p>
                  <p>
                    {{ statement.postalCode }} {{ statement.city }}
                  </p>
                  <p>
                    {{ dataSelects.getCountryName(statement.country) }}
                  </p>
                </div>
              </div>
            </div>

            <div class="payment-checkout__stage flex_box">
              <div class="payment-checkout__payments">
                <ng-container
                  *ngIf="!statementPaymentAmount.isFree"
                >
                  <h3 class="title_24">
                    {{ 'CHECKOUT.PAYMENT_DETAILS' | translate }}
                  </h3>
                  <div class="payment-checkout__payments_wrap">
                    <mat-radio-group name="client_type" [(ngModel)]="selectedPaymentType" class="pay_grid">
                      <ng-container *ngFor="let choice of paymentChoices">
                        <mat-radio-button
                          [value]="choice.platform"
                          class="pay_btn"
                        >
                          <div class="icon_img_wrap">
                            <img
                              class="icon_img"
                              [src]="choice.ico"
                              [alt]="choice.name"
                              [ngClass]="{'klarna': choice.platform === paymentPlatforms.klarna}"
                            >
                          </div>
                          <span class="payment_name">{{ choice.name }}</span>
                          <img src="../../../assets/svg/checkout_check.svg" class="check_icon" alt="Checked">
                        </mat-radio-button>
                      </ng-container>
                    </mat-radio-group>
                  </div>
                </ng-container>
                <div class="all_payments_button_wrap" *ngIf="!showAllPaymentsMethod && showPaymentsButton">
                  <button
                    class="btn btn--primary all_payments"
                    (click)="showAllPayments()"
                  >
                    {{ 'CHECKOUT.SHOW_ALL_PAYMENT_METHODS' | translate}}
                  </button>
                </div>
                <div class="alert-box" *ngIf="showSelectedPaymentError" id="selectedPaymentAlertBox">
                  {{ 'CHECKOUT.PAYMENT_NOT_SELECTED' | translate }}
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="statement.isPremiumPackage && !statementPaymentAmount.shouldBeIndividualService">
            <div class="payment-checkout__order">
              <h3 class="title_24">
                {{ 'CHECKOUT.YOUR_ORDER_TITLE' | translate }}
              </h3>
              <div class="payment-checkout__order_wrap">
                <ul class="payment-checkout__order_list">
                  <li>
                    <div class="payment-checkout__order_list_title">
                      {{ 'CHECKOUT.ORDER_LIST_STEUER_NETTO' | translate }}
                    </div>
                    <div class="payment-checkout__order_list_value">
                      {{ statementPaymentAmount.currencySymbol }} {{ statementPaymentAmount.premiumNet | number: '1.2-2' }}
                    </div>
                  </li>
                  <li>
                    <div class="payment-checkout__order_list_title">
                      {{ 'CHECKOUT.ORDER_LIST_VAT' | translate }} ({{ statementPaymentAmount.vatPercentFormatted }})
                    </div>
                    <div class="payment-checkout__order_list_value">
                      {{ statementPaymentAmount.currencySymbol }} {{ statementPaymentAmount.premiumAmountVat | number: '1.2-2' }}
                    </div>
                  </li>
                  <li>
                    <div class="payment-checkout__order_list_title">
                      <b>{{ 'CHECKOUT.ORDER_LIST_TOTAL_PREMIUM' | translate }}</b>
                    </div>
                    <div class="payment-checkout__order_list_value">
                      <b>{{ statementPaymentAmount.currencySymbol }} {{ statementPaymentAmount.premiumAmountTotalFormatted(coupon) | number: '1.2-2' }}</b>
                    </div>
                  </li>
                  <li *ngIf="coupon && couponSuccess && statementPaymentAmount.premiumAmountTotalBase">
                    <div class="payment-checkout__order_list_title">
                      {{ 'CHECKOUT.ORDER_LIST_COUPON' | translate }} 
                    </div>
                    <div class="payment-checkout__order_list_value">
                      - {{ statementPaymentAmount.currencySymbol }} {{ coupon.amount(statementPaymentAmount.premiumAmountTotalBase) | number: '1.2-2' }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="payment-checkout__regulations" [class.pt-0]="statement.isPremiumPackage && statementPaymentAmount.shouldBeIndividualService">
          <app-payment-regulations
            [statement]="statement"
            [termRegulationsIsChecked]="termRegulationsIsChecked$ | async"
            [termRealDataIsChecked]="termRealDataIsChecked$ | async"
            (clickTermRegulations)="clickTermRegulations($event)"
            (clickTermRealData)="clickTermRealData($event)"
          ></app-payment-regulations>
        </div>
        <ng-container *ngIf="errors?.length">
          <div class="alert-box" *ngIf="!errors[0].package">{{ this.errors[0] }}</div>
        </ng-container>
      </div>
    </div>
    <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
  </div>
</ng-container>
