export interface PackageResponse {
  id: number;
  quantity: number;
  amount: number;
  currency_code: string;
}

export class Package {
  id: number;
  quantity: number;
  amount: number;
  currencyCode: string;

  static fromResponse(response: PackageResponse): Package {
    const statementPackage = new Package();
    statementPackage.id = Number(response.id);
    statementPackage.quantity = Number(response.quantity);
    statementPackage.amount = Number(response.amount);
    statementPackage.currencyCode = response.currency_code;
    return statementPackage;
  }

  toResponse(): PackageResponse {
    return {
      id: this.id,
      quantity: this.quantity,
      amount: this.amount,
      currency_code: this.currencyCode
    };
  }
}
