import { finalize, takeUntil } from "rxjs/operators";
import { Component, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { StatementService } from "../../statements/statement.service";
import { getSelectsData, getUser, State } from "../../reducers";
import { Currency } from "../../statements/currency";
import { SelectsData, SelectsDataInterface, Statement } from "../../statements";
import { User } from "../../users";
import { AccordionComponent } from "../accordion/accordion.component";
import { SubscribingComponent } from "../subscribing-component";
import { ErrorMessage, ErrorMessageContent } from "app/core/form-validator/validatorInterface";
import { AbroadIncome } from "app/statements/abroad-income";
import { DateRange } from "../dateRange";

@Component({
  selector: "app-b2b-abroad-income-edit",
  templateUrl: "./b2b-abroad-income-edit.component.html",
  styleUrls: ["./b2b-abroad-income-edit.component.scss"]
})
export class B2bAbroadIncomeEditComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  @Input() isSpouse = false;
  @Input() errors;
  @Input() epValidator: ErrorMessage[] = [];
  @Input() orderForEmployerPeriod: boolean;
  @Input() orderApplyForMessage: boolean;
  @Output() updateStatementEvent = new EventEmitter();

  @ViewChildren(AccordionComponent) accordions: QueryList<AccordionComponent>;

  dataSelects: SelectsDataInterface = new SelectsData();
  loadingSelectsData = false;
  currencyCode = "";
  spouseCurrencyCode = "";
  translationsValue = {};
  hideNotification = false;
  isBusiness = false;
  showApplyForResidencyStatusNotification = false;
  initialFiledTogether = false;

  public nrLiveAbroad = 1;
  public nrSpouse = 2;

  public coolapseAbroadIncome = true;
  public coolapseAbroadIncomeReport = true;

  public isResident: boolean;
  public abroadIncomeStatement: any = {};
  public residentAbroadIncome: AbroadIncome[] = [];
  public residentSpouseAbroadIncome: AbroadIncome[] = [];
  public employerDate = 0;

  public editEmployerDateIndex = 0;
  public showPeriodForm = false;
  public employerDateFormValue: any;
  public periodFormType = "create_new";
  public showResidentFormView = false;
  public employerDateFormValidator: any = {
    lowerValidated: true,
    upperValidated: true
  };

  constructor(private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.loadingSelectsData = true;
    this.store
      .select(getSelectsData)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loadingSelectsData = false))
      )
      .subscribe((selectsData: SelectsDataInterface) => {
        this.dataSelects = selectsData;
        if (this.dataSelects) {
          this.statement.setDefaultCurrency(this.dataSelects);
          this.setCurrencyCode();
          this.setSpouseCurrencyCode();
        }
      });

    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          this.isBusiness = user.isBusiness;
        }
      });

    this.initialFiledTogether = this.statement.filedTogether;
    this.translationsValue = { year: this.statement.fullYear };
    this.setAILiveInGermanyDefault();

    this.abroadIncomeStatement = this.statement.abroadIncome;
    this.employerDate = this.statement.employerDateRange();

    this.countResidencyDays();
    this.initResidentData();
  }

  public canApplyForTaxResidencyStatus(): boolean {
    if (this.initialFiledTogether !== this.statement.filedTogether) {
      this.showApplyForResidencyStatusNotification = true;
    }

    return (
      this.statement.check90ConditionOrSmallAbroadIncome(this.dataSelects) ||
      this.statement.employerDateRangeStatus ||
      this.statement.abroadIncome.accepted_tax_residency_message
    );
  }

  reinstantiateFilledTogetherState() {
    this.statement.filedTogether = true;
  }

  canApplyForFamilyTaxPrivileges() {
    return this.statement.abroadIncome.applyForTaxResidencyStatus && this.statement.filedTogether;
  }

  updateStatement() {
    this.setApplyForTaxResidencyStatus();
    this.updateStatementEvent.emit(this.statement);
  }

  clearErrors() {
    this.errors = {};
  }

  showFieldsInputs() {
    return this.statement.abroadIncome.liveAbroad || this.statement.abroadIncome.spouseLiveAbroad;
  }
  shouldShowAdditionalQuestions() {
    return this.statement.abroadIncome.liveAbroad;
  }

  showEogAplicationInfo() {
    return this.statement.abroadIncome.liveAbroad || this.statement.abroadIncome.spouseLiveAbroad;
  }

  shouldShowSpouseAdditionalQuestions() {
    return this.statement.abroadIncome.spouseLiveAbroad;
  }

  isCountryInEU(countryCode: string): boolean {
    return this.dataSelects.euCountryCodes.includes(countryCode);
  }

  getCountryIncomeThreshold(countryCode: string): number {
    const thresholds = this.dataSelects.abroadIncomeThresholds[this.statement.fullYear];
    return thresholds && thresholds[countryCode] ? thresholds[countryCode] : 0;
  }

  getEWRUrl(): string {
    let countryCode = "";
    let ewrUrl = "/assets/downloads/EWR-DE.pdf";

    if (!this.statement.abroadIncome) return ewrUrl;

    if (!this.statement.abroadIncome.abroadIncomeCountry) return ewrUrl;

    countryCode = this.statement.abroadIncome.abroadIncomeCountry;

    switch (countryCode) {
      case "AL":
        ewrUrl = "/assets/downloads/n_ewr_en.pdf";
        break;
      case "BY":
        ewrUrl = "/assets/downloads/n_ewr_en.pdf";
        break;
      case "AU":
        ewrUrl = "/assets/downloads/n_ewr_en.pdf";
        break;
      case "CN":
        ewrUrl = "/assets/downloads/n_ewr_en.pdf";
        break;
      case "IN":
        ewrUrl = "/assets/downloads/n_ewr_en.pdf";
        break;
      case "IL":
        ewrUrl = "/assets/downloads/n_ewr_en.pdf";
        break;
      case "NZ":
        ewrUrl = "/assets/downloads/n_ewr_en.pdf";
        break;
      case "US":
        ewrUrl = "/assets/downloads/n_ewr_en.pdf";
        break;
      case "BA":
        ewrUrl = "/assets/downloads/n_ewr_en.pdf";
        break;
      case "ME":
        ewrUrl = "/assets/downloads/n_ewr_en.pdf";
        break;
      case "RU":
        ewrUrl = "/assets/downloads/n_ewr_ru.pdf";
        break;
      case "RS":
        ewrUrl = "/assets/downloads/n_ewr_rs.pdf";
        break;
      case "TR":
        ewrUrl = "/assets/downloads/n_ewr_tr.pdf";
        break;
      case "JP":
        ewrUrl = "/assets/downloads/n_ewr_en.pdf";
        break;
    }

    if (this.statement.abroadIncome.abroadIncomeCountry === "PL") {
      ewrUrl = `/assets/downloads/EWR-PL.pdf`;
    }
    if (this.statement.abroadIncome.abroadIncomeCountry === "LT") {
      ewrUrl = `/assets/downloads/EWR-LT.pdf`;
    }
    return ewrUrl;
  }

  setCurrencyCode() {
    const currency = Currency.findById(this.dataSelects.currencies, this.statement.abroadIncome.abroadIncomeCurrency);
    this.currencyCode = currency ? currency.code : this.currencyCode;
  }

  public setAdditionalIncomeCurrencyCode(item: any): string {
    const currency = Currency.findById(this.dataSelects.currencies, item.abroad_income_currency);

    return currency ? currency.code : this.currencyCode;
  }

  setSpouseCurrencyCode() {
    const currency = Currency.findById(
      this.dataSelects.currencies,
      this.statement.abroadIncome.spouseAbroadIncomeCurrency
    );
    this.spouseCurrencyCode = currency ? currency.code : this.spouseCurrencyCode;
  }

  setSpouseAbroadState(liveAbroad: boolean) {
    this.statement.abroadIncome.spouseLiveAbroad = liveAbroad;
    this.setApplyForFamilyTaxPrivileges();
  }

  private setApplyForFamilyTaxPrivileges() {
    this.statement.abroadIncome.applyForFamilyTaxPrivileges = this.statement.abroadIncome.spouseLiveAbroad;
    if (!this.statement.abroadIncome.liveAbroad) {
      this.statement.abroadIncome.applyForTaxResidencyStatus = true;
    }
    this.updateStatement();
  }

  private setApplyForTaxResidencyStatus() {
    this.statement.abroadIncome.applyForTaxResidencyStatus = this.statement.abroadIncome.spouseLiveAbroad
      ? this.statement.abroadIncome.spouseLiveAbroad
      : this.statement.abroadIncome.liveAbroad;
  }

  private setAILiveInGermanyDefault() {
    if (this.statement === null && this.statement === undefined) return;
    if (this.statement.abroadIncome === null || this.statement.abroadIncome === undefined) return;
    if (this.statement.abroadIncome.liveInGermany === null || this.statement.abroadIncome.liveInGermany === undefined)
      this.statement.abroadIncome.liveInGermany = false;
  }

  public checkIfclearApplyFlags() {
    if (
      this.statement.filedTogether &&
      !this.statement.abroadIncome.liveAbroad &&
      !this.statement.abroadIncome.spouseLiveAbroad
    ) {
      this.statement.abroadIncome.apply_for_unlimited_tax_liability = false;
      this.statement.abroadIncome.apply_for_unlimited_tax_liability_with_relief = false;
    } else if (!this.statement.filedTogether && !this.statement.abroadIncome.liveAbroad) {
      this.statement.abroadIncome.apply_for_unlimited_tax_liability = false;
      this.statement.abroadIncome.apply_for_unlimited_tax_liability_with_relief = false;
    }
  }

  public clearBasicData() {
    this.statement.hasAbroadIncome = false;
    this.statement.abroadIncome.liveAbroad = false;
    this.statement.abroadIncome.abroadIncome = "0.00";
    this.statement.abroadIncome.abroadIncomeCountry = null;
    this.statement.additional_abroad_incomes = [];
  }

  public clearBasicDataSpouse() {
    this.statement.abroadIncome.spouseLiveAbroad = false;
    this.statement.abroadIncome.spouseAbroadIncome = "0.00";
    this.statement.abroadIncome.spouseAbroadIncomeCountry = null;

    if (this?.statement?.spouse) {
      this.statement.spouse.hasAbroadIncome = false;
      this.statement.spouse.additional_abroad_incomes = [];
    }
  }

  public updateLiveAbroad() {
    this.statement.abroadIncome.liveAbroad = !this.statement.abroadIncome.liveAbroad;
    this.checkIfclearApplyFlags();

    if (!this.statement.abroadIncome.liveAbroad) this.clearBasicData();

    this.updateStatement();
    this.initResidentData();
    this.checkNrForms();
  }

  public updateSpouseLiveAbroad() {
    this.statement.abroadIncome.spouseLiveAbroad = !this.statement.abroadIncome.spouseLiveAbroad;
    this.checkIfclearApplyFlags();
    if (!this.statement.abroadIncome.spouseLiveAbroad) this.clearBasicDataSpouse();

    this.updateStatementEvent.emit(this.statement);
    this.setSpouseAbroadState(this.statement.abroadIncome.spouseLiveAbroad);
    this.initResidentData();
    this.checkNrForms();
  }

  public updateLiveInGermany() {
    this.statement.abroadIncome.liveInGermany = !this.statement.abroadIncome.liveInGermany;
  }

  public toggleCollapseAbroad() {
    if (window.innerWidth < 768) {
      this.coolapseAbroadIncome = !this.coolapseAbroadIncome;
      this.statement.hasAbroadIncome = !this.coolapseAbroadIncomeReport;
    }
  }

  public toggleCollapseAbroadReport() {
    if (window.innerWidth < 768) {
      this.coolapseAbroadIncomeReport = !this.coolapseAbroadIncomeReport;
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (event.target.innerWidth > 767) {
      this.coolapseAbroadIncome = true;
      this.coolapseAbroadIncomeReport = true;
    }
  }

  private checkNrForms() {
    if (!this.statement.abroadIncome.liveAbroad && this.statement.abroadIncome.spouseLiveAbroad) {
      this.nrSpouse = 1;
    } else {
      this.nrSpouse = 2;
    }
  }

  public initResidentData(): void {
    if (this.isResident) {
      this.clearNoResidentAbroadIncomeDataStructure();
    }

    if (this.isResident && this.statement.additional_abroad_incomes.length == 0) {
      this.addResidenAbroadIncome();
    }

    if (this.isResident && this.statement.spouse?.additional_abroad_incomes.length == 0) {
      this.addSpouseResidenAbroadIncome();
    }
  }

  public getResidentAbroadIncome() {
    return this.statement.additional_abroad_incomes;
  }

  public getResidentSpouseAbroadIncome() {
    return this.statement.spouse.additional_abroad_incomes;
  }

  public removeResidenAbroadIncome(index: number) {
    this.residentAbroadIncome.splice(index, 1);
    this.statement.additional_abroad_incomes.splice(index, 1);
  }

  public removeResidenSpouseAbroadIncome(index: number) {
    this.residentSpouseAbroadIncome.splice(index, 1);
    this.statement.spouse.additional_abroad_incomes.splice(index, 1);
  }

  public addResidenAbroadIncome() {
    if (this.statement.abroadIncome.liveAbroad) {
      const residentAbroadIncome = new AbroadIncome();
      this.residentAbroadIncome.push(residentAbroadIncome);

      const additional_abroad_income = {
        abroad_income: residentAbroadIncome.abroadIncome,
        abroad_income_country: residentAbroadIncome.abroadIncomeCountry,
        abroad_income_currency: residentAbroadIncome.abroadIncomeCurrency,
        income_type: residentAbroadIncome.income_type
      };

      this.statement.additional_abroad_incomes.push(additional_abroad_income);
    }
  }

  public addSpouseResidenAbroadIncome() {
    if (this.statement.abroadIncome.spouseLiveAbroad) {
      const residentAbroadIncome = new AbroadIncome();
      this.residentSpouseAbroadIncome.push(residentAbroadIncome);

      const additional_abroad_income = {
        abroad_income: residentAbroadIncome.spouseAbroadIncome,
        abroad_income_country: residentAbroadIncome.spouseAbroadIncomeCountry,
        abroad_income_currency: residentAbroadIncome.spouseAbroadIncomeCurrency,
        income_type: residentAbroadIncome.spouse_income_type
      };

      this.statement.spouse.additional_abroad_incomes.push(additional_abroad_income);
    }
  }

  public validateEmployerDate(dateString: string) {
    const currentYear = this.statement.fullYear;

    if (!/^\d{2}\/\d{2}\/\d{4}$/.test(`${dateString}/${currentYear}`)) {
      return false;
    }

    const parts = dateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    if (month < 1 || month > 12) {
      return false;
    }

    if (year < 1900) {
      return false;
    }

    function isLeapYear(year) {
      return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    }

    const daysInMonth = [0, 31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (day < 1 || day > daysInMonth[month]) {
      return false;
    }

    return true;
  }

  private addDateEpValidatorMessage(field_name: string, message: string): void {
    const errorMessage = new ErrorMessage();
    errorMessage.fieldName = field_name;
    errorMessage.index = 0;
    errorMessage.messages = [new ErrorMessageContent(message)];
    this.epValidator.push(errorMessage);
  }

  public checkIfDateIsLower(lower: string, upper: string) {
    const lowerParts = lower.split("/").map(Number);
    const upperParts = upper.split("/").map(Number);

    return (
      new Date(this.statement.fullYear, lowerParts[1] - 1, lowerParts[0]) <
      new Date(this.statement.fullYear, upperParts[1] - 1, upperParts[0])
    );
  }

  public addEmployerDate(): void {
    if (this.statement.abroadIncome.liveAbroad || this.statement.abroadIncome.spouseLiveAbroad) {
      this.employerDateFormValidator.lowerValidated = this.validateEmployerDate(this.employerDateFormValue.lower);
      this.employerDateFormValidator.upperValidated = this.validateEmployerDate(this.employerDateFormValue.upper);

      const isDateLower = this.checkIfDateIsLower(this.employerDateFormValue.lower, this.employerDateFormValue.upper);

      if (
        isDateLower &&
        this.employerDateFormValidator.lowerValidated &&
        this.employerDateFormValidator.upperValidated
      ) {
        this.abroadIncomeStatement.period_of_employment.push(this.employerDateFormValue);
        this.showPeriodForm = false;

        this.countResidencyDays();
      } else {
        this.epValidator = [];
        if (!isDateLower) this.addDateEpValidatorMessage("abroad_income_period_lower", "FIELD_EMP_DATE_IS_LOWER_THAN");
        if (!this.employerDateFormValidator.lowerValidated)
          this.addDateEpValidatorMessage("abroad_income_period_lower", "ABROAD_INCOME_EMPLOYER_PERIOD");
        if (!this.employerDateFormValidator.upperValidated)
          this.addDateEpValidatorMessage("abroad_income_period_upper", "ABROAD_INCOME_EMPLOYER_PERIOD");
      }
    }
  }

  public updateEmployerDate() {
    if (this.statement.abroadIncome.liveAbroad || this.statement.abroadIncome.spouseLiveAbroad) {
      this.employerDateFormValidator.lowerValidated = this.validateEmployerDate(this.employerDateFormValue.lower);
      this.employerDateFormValidator.upperValidated = this.validateEmployerDate(this.employerDateFormValue.upper);

      const isDateLower = this.checkIfDateIsLower(this.employerDateFormValue.lower, this.employerDateFormValue.upper);

      if (
        isDateLower &&
        this.employerDateFormValidator.lowerValidated &&
        this.employerDateFormValidator.upperValidated
      ) {
        this.abroadIncomeStatement.period_of_employment[this.editEmployerDateIndex] = this.employerDateFormValue;
        this.showPeriodForm = false;

        this.countResidencyDays();
      } else {
        this.epValidator = [];
        if (!this.employerDateFormValidator.lowerValidated)
          this.addDateEpValidatorMessage("abroad_income_period_lower", "ABROAD_INCOME_EMPLOYER_PERIOD");
        if (!this.employerDateFormValidator.upperValidated)
          this.addDateEpValidatorMessage("abroad_income_period_upper", "ABROAD_INCOME_EMPLOYER_PERIOD");
      }
    }
  }

  public createEmployerDate(): void {
    this.employerDateFormValue = new DateRange();
    this.showPeriodForm = true;
    this.periodFormType = "create_new";
  }

  public editEmployerDate(index: number) {
    this.employerDateFormValue = structuredClone(this.statement.abroadIncome.period_of_employment[index]);
    this.showPeriodForm = true;
    this.editEmployerDateIndex = index;
    this.periodFormType = "edit";
  }

  public removeEmployerDate(index: number): void {
    this.abroadIncomeStatement.period_of_employment.splice(index, 1);

    this.countResidencyDays();
  }

  public clearNoResidentAbroadIncomeDataStructure() {
    // this.statement.abroadIncome.
    this.statement.abroadIncome.abroadIncome = "0.00";
    this.statement.abroadIncome.test = 0;
    this.statement.abroadIncome.abroadIncomeCountry = null;
    this.statement.abroadIncome.spouseAbroadIncome = "0.00";
    this.statement.abroadIncome.spouseAbroadIncomeCountry = null;
    this.statement.abroadIncome.liveInGermany = false;

    //new flags
    this.statement.abroadIncome.apply_for_unlimited_tax_liability = false;
    this.statement.abroadIncome.spouse_apply_for_unlimited_tax_liability = false;
    this.statement.abroadIncome.accepted_tax_residency_message = false;
    this.statement.abroadIncome.spouse_accepted_tax_residency_message = false;
    this.statement.abroadIncome.income_type = "Contract of employment";
    this.statement.abroadIncome.spouse_income_type = "Contract of employment";

    this.statement.abroadIncome.applyForTaxResidencyStatus = false;
    this.statement.abroadIncome.applyForFamilyTaxPrivileges = false;

    if (this.statement.additional_abroad_incomes.length == 0) {
      this.addResidenAbroadIncome();
    }

    if (this.statement.spouse?.additional_abroad_incomes.length == 0) {
      this.addSpouseResidenAbroadIncome();
    }
  }

  public clearResidentAbroadIncomeDataStructure() {
    //clear main resident data
    if (this.statement.additional_abroad_incomes.length > 0) {
      this.statement.additional_abroad_incomes = [];
      this.residentAbroadIncome = [];
    }

    //clear spouse data
    if (this.statement.spouse?.additional_abroad_incomes.length > 0) {
      this.statement.spouse.additional_abroad_incomes = [];
      this.residentSpouseAbroadIncome = [];
    }
  }

  public countResidencyDays() {
    const uniqueDays = new Set();
    const periods = [...this.statement.abroadIncome.period_of_employment];

    if (this.statement.employers.length > 0) {
      for (let i = 0; i < this.statement.employers.length; i++) {
        if (this.statement.employers[i].workDateEnd && this.statement.employers[i].workDateStart) {
          periods.push({
            lower: this.statement.employers[i].workDateStart,
            upper: this.statement.employers[i].workDateEnd
          });
        }
      }
    }

    periods.forEach((period) => {
      const [startDay, startMonth] = period.lower.split("/").map(Number);
      const [endDay, endMonth] = period.upper.split("/").map(Number);

      const currentDate = new Date(this.statement.fullYear, startMonth - 1, startDay);
      const endDate = new Date(this.statement.fullYear, endMonth - 1, endDay);

      while (currentDate <= endDate) {
        uniqueDays.add(currentDate.toISOString().split("T")[0]);
        currentDate.setDate(currentDate.getDate() + 1);
      }
    });

    this.abroadIncomeStatement.days_employed = uniqueDays.size;

    if (this.abroadIncomeStatement.days_employed > 183) {
      this.isResident = true;
      if (this.statement.additional_abroad_incomes.length == 0) this.addResidenAbroadIncome();
      if (this.statement.spouse?.additional_abroad_incomes.length == 0) this.addSpouseResidenAbroadIncome();
      this.clearNoResidentAbroadIncomeDataStructure();
    } else if (this.isResident) {
      this.isResident = false;
      this.clearResidentAbroadIncomeDataStructure();
    }
  }

  public handleCheckbox(statement?: string) {
    this.abroadIncomeStatement.apply_for_unlimited_tax_liability =
      !this.abroadIncomeStatement.apply_for_unlimited_tax_liability;
    this.abroadIncomeStatement.accepted_tax_residency_message =
      !this.abroadIncomeStatement.accepted_tax_residency_message;
  }
}
