<div class="dialog-box decision-wrapper">
  <div class="dialog-box__header">
    <h3 class="title_24">{{ "DOWNLOAD_FILES_DIALOG.TITLE" | translate }}</h3>
    <button mat-icon-button mat-dialog-close tabindex="-1">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>
  </div>

  <div class="dialog-box__content">
    <div class="attachments__content" *ngIf="filesToDownload.length > 0">
      <ul class="attachments__list">
        <li class="attachments__single downloads" *ngFor="let file of filesToDownload; index as i">
          <div class="attachments_label">{{ generateTypeName(file.file_type) | translate }}</div>
          <div class="attachments__box-action">
            <div class="attachments__single_box">
              <div class="attachments__single_name">
                {{ generateFileName(file.file_name, file.file_type) }}
              </div>
            </div>
            <button
              class="btn_w_icon"
              (click)="onDownloadFreelance(file.file_type, file.id, file.file_type)"
              *ngIf="displayFreelanceDownloadButton(file.file_type); else decisionsDownloadButton"
            >
              <img alt="download" src="../../../assets/svg/download.svg" />
            </button>

            <ng-template #decisionsDownloadButton>
              <button class="btn_w_icon" (click)="onDownload(file.file_type, file.file_type, file.id)">
                <img alt="download" src="../../../assets/svg/download.svg" />
              </button>
            </ng-template>
          </div>
          <div class="attachments_label">{{ file.file_date }}</div>
        </li>
      </ul>
    </div>

    <div class="alert-box" *ngIf="error">{{ error }}</div>
  </div>
</div>
