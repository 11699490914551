import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import {
  GroupSectionBaseAbstractComponent,
  SelectButtonsData
} from 'app/shared/modules/group-section/components/group-section-base.abstract.component';
import { AdditionalIncomeService } from 'app/modules/statement/services/additional-income.service';
import { StepsControllerService, SubStepSaveDataInterface } from 'app/core/workflow/steps-controller/steps-controller.service';
import { FreelanceJobSubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/freelance-job.sub-steps';
import { FreelanceJobManager } from 'app/modules/statement/models/additional-income/freelance-job.manager';
import { AutofocusHelper } from 'app/core/helpers/autofocus.helper';

@Component({
  ...GroupSectionBaseAbstractComponent.metaData,
  selector: 'app-additional-income-group-freelance',
  templateUrl: './additional-income-group-freelance.component.html',
})
export class AdditionalIncomeGroupFreelanceComponent extends GroupSectionBaseAbstractComponent implements OnInit {
  public groupTitle: string;
  public freelanceJobSteps = FreelanceJobSubSteps;
  public freelanceJobManager = FreelanceJobManager;

  constructor(
    public additionalIncomeService: AdditionalIncomeService,
    public dialog: MatDialog,
    public store: Store<State>,
    public stepsControllerService: StepsControllerService,
  ) {
    super(dialog, store, stepsControllerService);
  }

  ngOnInit() {
    this.groupTitle = this.title;
    this.init();

    this.stepsControllerService.reInitEmitter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.init();
    });

    this.stepsControllerService.subStepSaveEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data: SubStepSaveDataInterface) => this.saveGroup(data),
      });

    super.ngOnInit();
  }

  public isSelected = (selectButtonName: string): boolean => {
    switch (selectButtonName) {
      case FreelanceJobSubSteps.FREELANCE:
        return !!FreelanceJobManager.getFreelance(this.additionalIncomeService.currentStatement).length;
      case FreelanceJobSubSteps.GEWERBE:
        return !!FreelanceJobManager.getGewerbe(this.additionalIncomeService.currentStatement).length;
      case FreelanceJobSubSteps.VOLUNTERING_INCOME:
        return !!FreelanceJobManager.getVolunteringIncome(this.additionalIncomeService.currentStatement).length;
    }

    return false;
  }

  public selectButtonClickAction = (selectButtonName: string): void => {
    switch (selectButtonName) {
      case FreelanceJobSubSteps.FREELANCE:
        if (
          !!FreelanceJobManager.getFreelance(this.additionalIncomeService.currentStatement).length
        ) {
          this.showAreYouSurePopup((dialog) => {
            FreelanceJobManager.removeAllFreelance(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          FreelanceJobManager.createFreelance(this.additionalIncomeService.currentStatement);
        }
        break;
      case FreelanceJobSubSteps.GEWERBE:
        if (
          !!FreelanceJobManager.getGewerbe(this.additionalIncomeService.currentStatement).length
        ) {
          this.showAreYouSurePopup((dialog) => {
            FreelanceJobManager.removeAllGewerbe(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          FreelanceJobManager.createGewerbe(this.additionalIncomeService.currentStatement);
        }
        break;
      case FreelanceJobSubSteps.VOLUNTERING_INCOME:
        if (
          !!FreelanceJobManager.getVolunteringIncome(this.additionalIncomeService.currentStatement).length
        ) {
          this.showAreYouSurePopup((dialog) => {
            FreelanceJobManager.removeVolunteringIncome(this.additionalIncomeService.currentStatement);
            this.updateStatement(() => {
              dialog.close();
            });
          });
        } else {
          FreelanceJobManager.createVolunteringIncome(this.additionalIncomeService.currentStatement);
        }
        break;
    }

    this.selectedButtonsChanged();
  }

  private setSelectButtonsData = (): void => {
    let currentYear = this.additionalIncomeService.getMainStatement().fullYear; //Temporary solution for TAX-3030_2 TODO: REMOVE IN THE FUTURE

    const selectButtonsData: SelectButtonsData[] = [
      {
        id: FreelanceJobSubSteps.FREELANCE,
        image: 'assets/svg/additional_income/self_employment.svg',
        title: currentYear == 2020 ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.FREELANCE.BTN_TITLE_PREMIUM' : 'ADDITIONAL_INCOME.FREELANCE_GROUP.FREELANCE.BTN_TITLE',
      },
      {
        id: FreelanceJobSubSteps.GEWERBE,
        image: 'assets/img/additional_income/gewerbe.png',
        title: currentYear == 2020 ? 'ADDITIONAL_INCOME.FREELANCE_GROUP.GEWERBE.BTN_TITLE_PREMIUM' : 'ADDITIONAL_INCOME.FREELANCE_GROUP.GEWERBE.BTN_TITLE',
      },
      {
        id: FreelanceJobSubSteps.VOLUNTERING_INCOME,
        image: 'assets/svg/additional_income/voluntering.svg',
        title: 'ADDITIONAL_INCOME.FREELANCE_GROUP.VOLUNTERING_INCOME.BTN_TITLE',
      },
    ];

    this.setSelectButtons(selectButtonsData);
  }

  private setSteps(): void {
    this.subSteps = [
      {
        id: FreelanceJobSubSteps.MAIN,
        isActive: true,
      },
      {
        id: FreelanceJobSubSteps.FREELANCE,
        isActive: false,
      },
      {
        id: FreelanceJobSubSteps.GEWERBE,
        isActive: false,
      },
      {
        id: FreelanceJobSubSteps.VOLUNTERING_INCOME,
        isActive: false,
      },
    ];
  }

  private updateStatement(callback = () => {}): void {
    this.additionalIncomeService.updateAdditionalIncome(
      this.getActiveSubStep().id as FreelanceJobSubSteps,
      callback
    );
  }

  private saveGroup({index, callback}: SubStepSaveDataInterface): void {
    const currentStep = this.subSteps[index];

    if (currentStep && currentStep.id !== FreelanceJobSubSteps.MAIN) {
      this.additionalIncomeService.formValidationErrors(currentStep.id as FreelanceJobSubSteps);
      if (this.additionalIncomeService.epValidator.length) {
        callback(false);
        return;
      }
    }

    callback(true);
    AutofocusHelper.autofocusInputMainContent();
  }

  private init(): void {
    this.setSteps();
    this.setSelectButtonsData();
    AutofocusHelper.autofocusInputMainContent();
  }
  
}
