import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[appShowPassword]'
})
export class ShowPasswordDirective {

  private _shown = false;

  public get shown(): boolean {
    return this._shown;
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  public toggle() {
    this._shown = this._shown ? this.hidePassword() : this.showPassword();
  }

  private hidePassword(): boolean {
    this.renderer.setAttribute(this.el.nativeElement, 'type', 'password');
    return false;
  }

  private showPassword(): boolean {
    this.renderer.setAttribute(this.el.nativeElement, 'type', 'text');
    return true;
  }
}
