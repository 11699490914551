<div class="dialog-box">
    <div class="dialog-box__content">
        <div class="data-processing-agreement" *ngIf="language === LANGUAGES.de">
            <h1 class="data-processing-agreement__title data-processing-agreement__title--main is-bold">Umowa powierzenia przetwarzania danych osobowych stanowiąca uzupełnienie do Umowy zawartej na podstawie akceptacji Regulaminu i Umowy licencyjnej dotyczącej korzystania z aplikacji Taxando.de</h1>
            <br>
            <div class="data-processing-agreement__stage">
                <p class="data-processing-agreement__content">
                    Umowa zostaje zawarta w formie akceptacji elektronicznej, gdzie wcześniej zweryfikowane konto biznesowe (b2b) zostało zarejestrowane w systemie Taxando z wprowadzeniem pełnych danych podmiotu, który przy pomocy  adresu email identyfikuje się podczas logowania do systemu Taxando – dalej zwanym Administratorem.<br>
                    Umowa elektroniczna zostaje zawarta pomiędzy:<br>
                    <strong>Administratorem</strong><br>
                    a<br>
                    spółką Taxando GmbH, z siedzibą w Berlinie pod adresem: Kurfürstendamm 226, 10719 Berlin, numer ewidencyjny (rejestru) : HRB 189179 B (<strong>„Przetwarzający”</strong>)<br>
                    (dalej łącznie jako: <strong>„Strony”</strong>)<br>
                <br>
                    Zważywszy, że:<br>
                    i.	Strony zawarły a podstawie akceptacji Regulaminu i Umowy licencyjnej Taxando.de umowę dotyczącą usługi udostępniania aplikacji Taxando.de („Umowa Podstawowa”), w związku, z wykonywaniem której Administrator powierzy Przetwarzającemu przetwarzanie danych osobowych w zakresie określonym Umową;<br>
                    ii.	Celem Umowy jest ustalenie warunków, na jakich Przetwarzający wykonuje operacje przetwarzania Danych Osobowych w imieniu Administratora;<br>
                    iii.	 Strony zawierając Umowę dążą do takiego uregulowania zasad przetwarzania Danych Osobowych, aby odpowiadały one w pełni postanowieniom rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz.Urz. UE L 119, s. 1) – dalej RODO.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">1.	Opis Przetwarzania</h2>

                <p class="data-processing-agreement__content">
                    1.1.	Przedmiot [zgodnie z art. 28 ust. 3 RODO] Na warunkach określonych niniejszą Umową oraz Umową Podstawową Administrator powierza Przetwarzającemu przetwarzanie (w rozumieniu RODO) dalej opisanych Danych Osobowych.<br>
                    1.2.	Czas [zgodnie art. 28 ust. 3 RODO] Przetwarzanie będzie wykonywane w okresie obowiązywania Umowy Podstawowej.<br>
                    1.3.	Charakter i cel [zgodnie z art. 28 ust. 3 RODO] Charakter i cel przetwarzania wynikają z Umowy Podstawowej. W szczególności:<br>
                    1.3.1.	charakter przetwarzania określony jest następującą rolą Przetwarzającego: udostępnianie Administratorowi licencji oprogramowania, które umożliwia obsługę klientów Administratora pod kątem rozliczeń podatkowych na terenie Niemiec, zaś<br>
                    1.3.2. celem przetwarzania jest umożliwienie Administratorowi wywiązywania się z zobowiązań wobec swoich klientów poprzez wykonanie usługi rozliczeń podatkowych na terenie Niemiec<br>
                    1.4.	Rodzaj danych [zgodnie z art. 28 ust. 3 RODO] Przetwarzanie obejmować będzie następujące rodzaje danych osobowych („Dane”):<br>
                    <br>
                    <strong>Dane zwykłe:</strong><br>
                        (1)	imię i nazwisko,<br>
                        (2)	numer podatkowy<br>
                        (3)	stan cywilny,<br>
                        (4)	adres zamieszkania,<br>
                        (5)	data urodzenia,<br>
                        (6)	wyznanie (religia)<br>
                        (7)	informacja o dochodzie w Niemczech<br>
                        (8)	informacja o dochodzie poza granicami Niemiec<br>
                        (9)	numer rachunku bankowego,<br>
                        (10)	imię i nazwisko współmałżonka,<br>
                        (11)	numer ewidencyjny  współmałżonka,<br>
                        (12)	wysokość dochodu za dany rok,<br>
                        (13)	dane zagranicznego pracodawcy,<br>
                    <br>
                    <strong>Dane dzieci:</strong>
                    <br>
                        (14)	imię i nazwisko,<br>
                        (15)	data urodzenia,<br>
                        (16)	dane osobowe i adres rodziców<br>
                    <br>
                    <strong>Dane nieustrukturyzowane</strong>
                    <br>
                    kontent o potencjalnej i prawdopodobnej zawartości danych osobowych (wpisy, dokumenty tekstowe).<br>
                    1.5.	Kategorie osób [zgodnie z art. 28 ust. 3 RODO] Przetwarzanie Danych będzie dotyczyć następujących kategorii osób:<br>
                    (1)	klienci Administratora.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">2.	Podpowierzenie</h2>
                <p class="data-processing-agreement__content">
                    2.1.	Podpowierzenie [zgodnie z art. 28 ust. 2 RODO] Przetwarzający może powierzyć konkretne operacje przetwarzania Danych („podpowierzenie”) w drodze pisemnej umowy podpowierzenia („Umowa Podpowierzenia”) innym podmiotom przetwarzającym. („Podprzetwarzający”), pod warunkiem uprzedniej akceptacji Podprzetwarzającego przez Administratora lub braku sprzeciwu.<br>
                    2.2.	Sprzeciw. Powierzenie przetwarzania Danych Podprzetwarzającym spoza Listy Zaakceptowanych Podprzetwarzających wymaga uprzedniego zgłoszenia Administratorowi w celu umożliwienia wyrażenia sprzeciwu. Administrator może z uzasadnionych przyczyn zgłosić udokumentowany sprzeciw względem powierzenia Danych konkretnemu Podprzetwarzającemu. W razie zgłoszenia sprzeciwu Przetwarzający nie ma prawa powierzyć Danych Podprzetwarzającemu objętemu sprzeciwem, a jeżeli sprzeciw dotyczy aktualnego Podprzetwarzającego, musi niezwłocznie zakończyć podpowierzenie temu Podprzetwarzającemu. Wątpliwości co do zasadności sprzeciwu i ewentualnych negatywnych konsekwencji Przetwarzający zgłosi Administratorowi w czasie umożliwiającym zapewnienie ciągłości przetwarzania.<br>
                    2.3.	Transfer obowiązków [zgodnie z art. 28 ust. 4 RODO] Dokonując podpowierzenia Przetwarzający ma obowiązek zobowiązać Podprzetwarzającego do realizacji wszystkich obowiązków Przetwarzającego wynikających z niniejszej Umowy powierzenia, z wyjątkiem tych, które nie mają zastosowania ze względu na naturę konkretnego podpowierzenia.<br>
                    2.4.	Zobowiązanie względem Administratora. Przetwarzający ma obowiązek zapewnić, aby Podprzetwarzający złożył Administratorowi zobowiązanie do wykonania obowiązków, o których mowa w poprzednim ustępie. Może to zostać wykonane przez podpisanie stosownego oświadczenia adresowanego do Administratora wraz z podpisaniem Umowy Podpowierzenia, zawierającego listę obowiązków Podprzetwarzającego.<br>
                    2.5.	Zakaz podzlecenia świadczenia głównego [zgodnie z art. 28 ust. 4 RODO] Przetwarzający nie ma prawa przekazać Podprzetwarzającemu całości wykonania Umowy.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">3.  Obowiązki Przetwarzającego</h2>
                <p class="data-processing-agreement__content">
                    Przetwarzający ma następujące obowiązki:<br>
                    3.1.	Udokumentowane polecenia [zgodnie z art. 28 ust. 3 lit. a RODO] Przetwarzający przetwarza Dane wyłącznie zgodnie z udokumentowanymi poleceniami lub instrukcjami Administratora.<br>
                    3.2.	Nieprzetwarzanie poza EOG [zgodnie z art. 28 ust. 3 lit. a RODO] Przetwarzający oświadcza, że nie przekazuje Danych do państwa trzeciego lub organizacji międzynarodowej (czyli poza Europejski Obszar Gospodarczy („EOG”)). Przetwarzający oświadcza również, że nie korzysta z podwykonawców, którzy przekazują Dane poza EOG.<br>
                    3.3.	Poinformowanie o zamiarze przetwarzania poza EOG. [zgodnie z art. 28 ust. 3 lit. a RODO] Jeżeli Przetwarzający ma zamiar lub obowiązek przekazywać Dane poza EOG, informuje o tym Administratora, w celu umożliwienia Administratorowi podjęcia decyzji i działań niezbędnych do zapewnienia zgodności przetwarzania z prawem lub zakończenia powierzenia przetwarzania.<br>
                    3.4.	Tajemnica [zgodnie z art. 28 ust. 3 lit. b RODO] Przetwarzający uzyskuje od osób, które zostały upoważnione do przetwarzania Danych w wykonaniu Umowy, udokumentowane zobowiązania do zachowania tajemnicy, ewentualnie upewnia się, że te osoby podlegają ustawowemu obowiązkowi zachowania tajemnicy.<br>
                    3.5.	Bezpieczeństwo [zgodnie z art. 28 ust. 3 lit. c RODO] Przetwarzający zapewnia ochronę Danych i podejmuje środki ochrony danych, o których mowa w art. 32 RODO, zgodnie z dalszymi postanowieniami Umowy.<br>
                    3.6.	Podprzetwarzanie [zgodnie z art. 28 ust. 3 lit. d RODO] Przetwarzający przestrzega warunków korzystania z usług innego podmiotu przetwarzającego (Podprzetwarzającego).<br>
                    3.7.	Współpraca przy realizacji praw jednostki [zgodnie z art. 28 ust. 3 lit. e RODO] Przetwarzający zobowiązuje się wobec Administratora do odpowiadania na żądania osoby, której dane dotyczą, w zakresie wykonywania praw określonych w rozdziale III RODO („Prawa jednostki”). Przetwarzający oświadcza, że zapewnia obsługę Praw jednostki w odniesieniu do powierzonych Danych. Szczegóły obsługi Praw jednostki zostaną pomiędzy Stronami uzgodnione. Strony ustaliły procedurę obsługi Praw jednostki odrębnym dokumentem.<br>
                    3.8.	Wsparcie przy obowiązkach bezpieczeństwa [zgodnie z art. 28 ust. 3 lit. f RODO] Przetwarzający współpracuje z Administratorem przy wykonywaniu przez Administratora obowiązków z obszaru ochrony danych osobowych, o których mowa w art. 32-36 RODO (ochrona danych, zgłaszanie naruszeń organowi nadzorczemu, zawiadamianie osób dotkniętych naruszeniem ochrony danych, ocena skutków dla ochrony danych i uprzednie konsultacje z organem nadzorczym).<br>
                    3.9.	Legalność poleceń [zgodnie z art. 28 ust. 3 ak. 2 RODO] Jeżeli Przetwarzający poweźmie wątpliwości co do zgodności z prawem wydanych przez Administratora poleceń lub instrukcji, Przetwarzający informuje Administratora o stwierdzonej wątpliwości.<br>
                    3.10.	Projektowanie prywatności [zgodnie z art. 25 ust. 1 RODO] Planując dokonanie zmian w sposobie przetwarzania Danych, Przetwarzający ma obowiązek zastosować się do wymogu projektowania prywatności, o którym mowa w art. 25 ust. 1 RODO i ma obowiązek z wyprzedzeniem informować Administratora o planowanych zmianach w taki sposób i terminach, aby zapewnić Administratorowi realną możliwość reagowania, jeżeli planowane przez Przetwarzającego zmiany w opinii Administratora grożą uzgodnionemu poziomowi bezpieczeństwa Danych lub zwiększają ryzyko naruszenia praw lub wolności osób, wskutek przetwarzania Danych przez Przetwarzającego.<br>
                    3.11.	Minimalizacja [zgodnie z art. 25 ust. 2 RODO] Przetwarzający zobowiązuje się do ograniczenia dostępu do Danych Osobowych wyłącznie do osób, których dostęp do Danych jest potrzebny dla realizacji Umowy i posiadających odpowiednie upoważnienie.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">4.	Obowiązki Administratora</h2>
                <p class="data-processing-agreement__content">
                    4.1. Administrator zobowiązany jest współdziałać z Przetwarzającym w wykonaniu Umowy, udzielać Przetwarzającemu wyjaśnień w razie wątpliwości co do legalności poleceń Administratora, jak też wywiązywać się terminowo ze swoich szczegółowych obowiązków.<br>
                    4.2.  Administrator  zobowiązany dochować wszelkich środków ostrożności niezbędnych do umożliwienia realizacji obowiązków Przetwarzającego, w szczególności zapewnić legalność pozyskiwania i przekazywania danych osobowych, oraz wydawać polecenia lub instrukcje jedynie w pełni zgodne z prawem.<br>
                    4.3.  Administrator zobowiązuje się do korzystania z oprogramowania i usługi świadczonej na podstawie Umowy Podstawowej zgodnie z obowiązującym prawem, w sposób nie narażający Przetwarzającego na odpowiedzialność prawną. Administrator przyjmuje na siebie pełną odpowiedzialność za naruszenia prawa (w szczególności RODO) będące pośrednim bądź bezpośrednim wynikiem jego działań.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">5. Nadzór</h2>
                <p class="data-processing-agreement__content">
                    5.1.	Sprawowanie kontroli [zgodnie z art. 28 ust. 3 lit. h RODO] Administrator kontroluje sposób przetwarzania powierzonych Danych Osobowych po uprzednim poinformowaniu Przetwarzającego o planowanej kontroli. Administrator uprawniony jest do żądania od Przetwarzającego udzielania informacji dotyczących przebiegu przetwarzania Danych Osobowych jedynie w zakresie niezbędnym do kontroli przetwarzania powierzonych Danych Osobowych. Administrator nie może żądać dostępu do danych Przetwarzającego ponad informacje niezbędne dla kontroli. Administrator zobowiązuje się do zachowania pełnej poufności. Strony postanawiają, że wszystkie informacje (poza powierzonymi przez Administratora Danymi Osobowymi) do jakich Administrator miał dostęp podczas kontroli, stanowią tajemnice przedsiębiorstwa Przetwarzającego, a Administrator ponosi pełną odpowiedzialność za ich wyjawienie.<br>
                    5.2.	Współpraca przy kontroli. [zgodnie z art. 28 ust. 3 lit. h RODO] Przetwarzający współpracuje z urzędem ochrony danych osobowych w zakresie wykonywanych przez niego zadań.<br>
                    5.3.	Przetwarzający:<br>
                     (1)	udostępnia Administratorowi wszelkie informacje niezbędne do wykazania zgodności działania Administratora z przepisami RODO,<br>
                     (2)	umożliwia Administratorowi lub upoważnionemu audytorowi przeprowadzanie audytów lub inspekcji. Przetwarzający współpracuje w zakresie realizacji audytów lub inspekcji.
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">6.	Oświadczenia Stron</h2>
                <p class="data-processing-agreement__content">
                    6.1.	Oświadczenie Administratora. Administrator oświadcza, że jest Administratorem Danych oraz, że jest uprawniony do ich przetwarzania w zakresie, w jakim powierzył je Przetwarzającemu.<br>
                    6.2.	Oświadczenie Przetwarzającego [zgodnie z art. 28 ust. 1 RODO]. Przetwarzający oświadcza, że w ramach prowadzonej działalności gospodarczej profesjonalnie zajmuje się przetwarzaniem danych osobowych objętych niniejszą  Umową i Umową Podstawową, posiada w tym zakresie niezbędną wiedzę, odpowiednie środki techniczne i organizacyjne.<br>
                    6.3.	Referencje [zgodnie z art. 28 ust. 1 RODO]. Na żądanie Administratora Przetwarzający okaże Administratorowi referencje, na dowód iż Przetwarzający zapewnia wystarczające gwarancje wdrożenia odpowiednich środków technicznych i organizacyjnych, by przetwarzanie spełniało wymogi RODO i chroniło prawa osób, których dane dotyczą.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">7.	Odpowiedzialność</h2>
                <p class="data-processing-agreement__content">
                    7.1.	Odpowiedzialność Przetwarzającego [zgodnie z art. 82 ust. 3 RODO] Przetwarzający odpowiada za szkody spowodowane swoim działaniem w związku z niedopełnieniem obowiązków, które RODO nakłada bezpośrednio na Przetwarzającego lub gdy działał poza zgodnymi z prawem instrukcjami Administratora lub wbrew tym instrukcjom.<br>
                    7.2.	Odpowiedzialność za Podprzetwarzających [zgodnie z art. 28 ust. 4 RODO] Jeżeli Podprzetwarzający nie wywiąże się ze spoczywających na nim obowiązków ochrony danych, odpowiedzialność wobec Administratora za wypełnienie obowiązków przez Podprzetwarzającego spoczywa na Przetwarzającym.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">8.	Okres Obowiązywania Umowy Powierzenia</h2>
                <p class="data-processing-agreement__content">
                    8.1.	Umowa została zawarta na czas obowiązywania Umowy Podstawowej.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">9.	Usunięcie Danych</h2>
                <p class="data-processing-agreement__content">
                    9.1.	Usunięcie danych [zgodnie z art. 28 ust. 3 lit g RODO] Z chwilą rozwiązania Umowy Przetwarzający nie ma prawa do dalszego przetwarzania powierzonych Danych i jest zobowiązany do:<br>
                      (1) usunięcia Danych,<br>
                      (2) usunięcia wszelkich ich istniejących kopii lub zwrotu Danych, chyba że Administrator postanowi inaczej lub prawo Unii Europejskiej lub prawo państwa członkowskiego nakazują dalej przechowywanie Danych.<br>
                      (3) Strony uzgodnią sposób usunięcia Danych odrębnym dokumentem w ciągu 30 dni od zawarcia Umowy Powierzenia.<br>
                    9.2.	Karencja. Przetwarzający dokona usunięcia Danych po upływie 180 dni od zakończenia Umowy, chyba że Administrator poleci mu to uczynić wcześniej.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">10.	Postanowienia Końcowe</h2>
                <p class="data-processing-agreement__content">
                    10.1.	Pierwszeństwo. W razie sprzeczności pomiędzy postanowieniami niniejszej Umowy Powierzenia a Umowy Podstawowej, pierwszeństwo mają postanowienia Umowy Powierzenia. Oznacza to także, że kwestie dotyczące przetwarzania danych osobowych pomiędzy Administratorem a Przetwarzającym należy regulować poprzez zmiany niniejszej Umowy lub w wykonaniu jej postanowień.<br>
                    10.2.	Egzemplarze. Umowa została sporządzona w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej ze Stron.<br>
                    10.3.	Właściwość prawa. Umowa podlega prawu polskiemu oraz RODO.<br>
                </p>
            </div>
        </div>

        <div class="data-processing-agreement" *ngIf="language === LANGUAGES.en">
            <h1 class="data-processing-agreement__title data-processing-agreement__title--main is-bold">Umowa powierzenia przetwarzania danych osobowych stanowiąca uzupełnienie do Umowy zawartej na podstawie akceptacji Regulaminu i Umowy licencyjnej dotyczącej korzystania z aplikacji Taxando.de</h1>
            <br>
            <div class="data-processing-agreement__stage">
                <p class="data-processing-agreement__content">
                    Umowa zostaje zawarta w formie akceptacji elektronicznej, gdzie wcześniej zweryfikowane konto biznesowe (b2b) zostało zarejestrowane w systemie Taxando z wprowadzeniem pełnych danych podmiotu, który przy pomocy  adresu email identyfikuje się podczas logowania do systemu Taxando – dalej zwanym Administratorem.<br>
                    Umowa elektroniczna zostaje zawarta pomiędzy:<br>
                    <strong>Administratorem</strong><br>
                    a<br>
                    spółką Taxando GmbH, z siedzibą w Berlinie pod adresem: Kurfürstendamm 226, 10719 Berlin, numer ewidencyjny (rejestru) : HRB 189179 B (<strong>„Przetwarzający”</strong>)<br>
                    (dalej łącznie jako: <strong>„Strony”</strong>)<br>
                <br>
                    Zważywszy, że:<br>
                    i.	Strony zawarły a podstawie akceptacji Regulaminu i Umowy licencyjnej Taxando.de umowę dotyczącą usługi udostępniania aplikacji Taxando.de („Umowa Podstawowa”), w związku, z wykonywaniem której Administrator powierzy Przetwarzającemu przetwarzanie danych osobowych w zakresie określonym Umową;<br>
                    ii.	Celem Umowy jest ustalenie warunków, na jakich Przetwarzający wykonuje operacje przetwarzania Danych Osobowych w imieniu Administratora;<br>
                    iii.	 Strony zawierając Umowę dążą do takiego uregulowania zasad przetwarzania Danych Osobowych, aby odpowiadały one w pełni postanowieniom rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz.Urz. UE L 119, s. 1) – dalej RODO.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">1.	Opis Przetwarzania</h2>

                <p class="data-processing-agreement__content">
                    1.1.	Przedmiot [zgodnie z art. 28 ust. 3 RODO] Na warunkach określonych niniejszą Umową oraz Umową Podstawową Administrator powierza Przetwarzającemu przetwarzanie (w rozumieniu RODO) dalej opisanych Danych Osobowych.<br>
                    1.2.	Czas [zgodnie art. 28 ust. 3 RODO] Przetwarzanie będzie wykonywane w okresie obowiązywania Umowy Podstawowej.<br>
                    1.3.	Charakter i cel [zgodnie z art. 28 ust. 3 RODO] Charakter i cel przetwarzania wynikają z Umowy Podstawowej. W szczególności:<br>
                    1.3.1.	charakter przetwarzania określony jest następującą rolą Przetwarzającego: udostępnianie Administratorowi licencji oprogramowania, które umożliwia obsługę klientów Administratora pod kątem rozliczeń podatkowych na terenie Niemiec, zaś<br>
                    1.3.2. celem przetwarzania jest umożliwienie Administratorowi wywiązywania się z zobowiązań wobec swoich klientów poprzez wykonanie usługi rozliczeń podatkowych na terenie Niemiec<br>
                    1.4.	Rodzaj danych [zgodnie z art. 28 ust. 3 RODO] Przetwarzanie obejmować będzie następujące rodzaje danych osobowych („Dane”):<br>
                    <br>
                    <strong>Dane zwykłe:</strong><br>
                        (1)	imię i nazwisko,<br>
                        (2)	numer podatkowy<br>
                        (3)	stan cywilny,<br>
                        (4)	adres zamieszkania,<br>
                        (5)	data urodzenia,<br>
                        (6)	wyznanie (religia)<br>
                        (7)	informacja o dochodzie w Niemczech<br>
                        (8)	informacja o dochodzie poza granicami Niemiec<br>
                        (9)	numer rachunku bankowego,<br>
                        (10)	imię i nazwisko współmałżonka,<br>
                        (11)	numer ewidencyjny  współmałżonka,<br>
                        (12)	wysokość dochodu za dany rok,<br>
                        (13)	dane zagranicznego pracodawcy,<br>
                    <br>
                    <strong>Dane dzieci:</strong>
                    <br>
                        (14)	imię i nazwisko,<br>
                        (15)	data urodzenia,<br>
                        (16)	dane osobowe i adres rodziców<br>
                    <br>
                    <strong>Dane nieustrukturyzowane</strong>
                    <br>
                    kontent o potencjalnej i prawdopodobnej zawartości danych osobowych (wpisy, dokumenty tekstowe).<br>
                    1.5.	Kategorie osób [zgodnie z art. 28 ust. 3 RODO] Przetwarzanie Danych będzie dotyczyć następujących kategorii osób:<br>
                    (1)	klienci Administratora.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">2.	Podpowierzenie</h2>
                <p class="data-processing-agreement__content">
                    2.1.	Podpowierzenie [zgodnie z art. 28 ust. 2 RODO] Przetwarzający może powierzyć konkretne operacje przetwarzania Danych („podpowierzenie”) w drodze pisemnej umowy podpowierzenia („Umowa Podpowierzenia”) innym podmiotom przetwarzającym. („Podprzetwarzający”), pod warunkiem uprzedniej akceptacji Podprzetwarzającego przez Administratora lub braku sprzeciwu.<br>
                    2.2.	Sprzeciw. Powierzenie przetwarzania Danych Podprzetwarzającym spoza Listy Zaakceptowanych Podprzetwarzających wymaga uprzedniego zgłoszenia Administratorowi w celu umożliwienia wyrażenia sprzeciwu. Administrator może z uzasadnionych przyczyn zgłosić udokumentowany sprzeciw względem powierzenia Danych konkretnemu Podprzetwarzającemu. W razie zgłoszenia sprzeciwu Przetwarzający nie ma prawa powierzyć Danych Podprzetwarzającemu objętemu sprzeciwem, a jeżeli sprzeciw dotyczy aktualnego Podprzetwarzającego, musi niezwłocznie zakończyć podpowierzenie temu Podprzetwarzającemu. Wątpliwości co do zasadności sprzeciwu i ewentualnych negatywnych konsekwencji Przetwarzający zgłosi Administratorowi w czasie umożliwiającym zapewnienie ciągłości przetwarzania.<br>
                    2.3.	Transfer obowiązków [zgodnie z art. 28 ust. 4 RODO] Dokonując podpowierzenia Przetwarzający ma obowiązek zobowiązać Podprzetwarzającego do realizacji wszystkich obowiązków Przetwarzającego wynikających z niniejszej Umowy powierzenia, z wyjątkiem tych, które nie mają zastosowania ze względu na naturę konkretnego podpowierzenia.<br>
                    2.4.	Zobowiązanie względem Administratora. Przetwarzający ma obowiązek zapewnić, aby Podprzetwarzający złożył Administratorowi zobowiązanie do wykonania obowiązków, o których mowa w poprzednim ustępie. Może to zostać wykonane przez podpisanie stosownego oświadczenia adresowanego do Administratora wraz z podpisaniem Umowy Podpowierzenia, zawierającego listę obowiązków Podprzetwarzającego.<br>
                    2.5.	Zakaz podzlecenia świadczenia głównego [zgodnie z art. 28 ust. 4 RODO] Przetwarzający nie ma prawa przekazać Podprzetwarzającemu całości wykonania Umowy.
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">3.  Obowiązki Przetwarzającego</h2>
                <p class="data-processing-agreement__content">
                    Przetwarzający ma następujące obowiązki:<br>
                    3.1.	Udokumentowane polecenia [zgodnie z art. 28 ust. 3 lit. a RODO] Przetwarzający przetwarza Dane wyłącznie zgodnie z udokumentowanymi poleceniami lub instrukcjami Administratora.<br>
                    3.2.	Nieprzetwarzanie poza EOG [zgodnie z art. 28 ust. 3 lit. a RODO] Przetwarzający oświadcza, że nie przekazuje Danych do państwa trzeciego lub organizacji międzynarodowej (czyli poza Europejski Obszar Gospodarczy („EOG”)). Przetwarzający oświadcza również, że nie korzysta z podwykonawców, którzy przekazują Dane poza EOG.<br>
                    3.3.	Poinformowanie o zamiarze przetwarzania poza EOG. [zgodnie z art. 28 ust. 3 lit. a RODO] Jeżeli Przetwarzający ma zamiar lub obowiązek przekazywać Dane poza EOG, informuje o tym Administratora, w celu umożliwienia Administratorowi podjęcia decyzji i działań niezbędnych do zapewnienia zgodności przetwarzania z prawem lub zakończenia powierzenia przetwarzania.<br>
                    3.4.	Tajemnica [zgodnie z art. 28 ust. 3 lit. b RODO] Przetwarzający uzyskuje od osób, które zostały upoważnione do przetwarzania Danych w wykonaniu Umowy, udokumentowane zobowiązania do zachowania tajemnicy, ewentualnie upewnia się, że te osoby podlegają ustawowemu obowiązkowi zachowania tajemnicy.<br>
                    3.5.	Bezpieczeństwo [zgodnie z art. 28 ust. 3 lit. c RODO] Przetwarzający zapewnia ochronę Danych i podejmuje środki ochrony danych, o których mowa w art. 32 RODO, zgodnie z dalszymi postanowieniami Umowy.<br>
                    3.6.	Podprzetwarzanie [zgodnie z art. 28 ust. 3 lit. d RODO] Przetwarzający przestrzega warunków korzystania z usług innego podmiotu przetwarzającego (Podprzetwarzającego).<br>
                    3.7.	Współpraca przy realizacji praw jednostki [zgodnie z art. 28 ust. 3 lit. e RODO] Przetwarzający zobowiązuje się wobec Administratora do odpowiadania na żądania osoby, której dane dotyczą, w zakresie wykonywania praw określonych w rozdziale III RODO („Prawa jednostki”). Przetwarzający oświadcza, że zapewnia obsługę Praw jednostki w odniesieniu do powierzonych Danych. Szczegóły obsługi Praw jednostki zostaną pomiędzy Stronami uzgodnione. Strony ustaliły procedurę obsługi Praw jednostki odrębnym dokumentem.<br>
                    3.8.	Wsparcie przy obowiązkach bezpieczeństwa [zgodnie z art. 28 ust. 3 lit. f RODO] Przetwarzający współpracuje z Administratorem przy wykonywaniu przez Administratora obowiązków z obszaru ochrony danych osobowych, o których mowa w art. 32-36 RODO (ochrona danych, zgłaszanie naruszeń organowi nadzorczemu, zawiadamianie osób dotkniętych naruszeniem ochrony danych, ocena skutków dla ochrony danych i uprzednie konsultacje z organem nadzorczym).<br>
                    3.9.	Legalność poleceń [zgodnie z art. 28 ust. 3 ak. 2 RODO] Jeżeli Przetwarzający poweźmie wątpliwości co do zgodności z prawem wydanych przez Administratora poleceń lub instrukcji, Przetwarzający informuje Administratora o stwierdzonej wątpliwości.<br>
                    3.10.	Projektowanie prywatności [zgodnie z art. 25 ust. 1 RODO] Planując dokonanie zmian w sposobie przetwarzania Danych, Przetwarzający ma obowiązek zastosować się do wymogu projektowania prywatności, o którym mowa w art. 25 ust. 1 RODO i ma obowiązek z wyprzedzeniem informować Administratora o planowanych zmianach w taki sposób i terminach, aby zapewnić Administratorowi realną możliwość reagowania, jeżeli planowane przez Przetwarzającego zmiany w opinii Administratora grożą uzgodnionemu poziomowi bezpieczeństwa Danych lub zwiększają ryzyko naruszenia praw lub wolności osób, wskutek przetwarzania Danych przez Przetwarzającego.<br>
                    3.11.	Minimalizacja [zgodnie z art. 25 ust. 2 RODO] Przetwarzający zobowiązuje się do ograniczenia dostępu do Danych Osobowych wyłącznie do osób, których dostęp do Danych jest potrzebny dla realizacji Umowy i posiadających odpowiednie upoważnienie.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">4.	Obowiązki Administratora</h2>
                <p class="data-processing-agreement__content">
                    4.1. Administrator zobowiązany jest współdziałać z Przetwarzającym w wykonaniu Umowy, udzielać Przetwarzającemu wyjaśnień w razie wątpliwości co do legalności poleceń Administratora, jak też wywiązywać się terminowo ze swoich szczegółowych obowiązków.<br>
                    4.2.  Administrator  zobowiązany dochować wszelkich środków ostrożności niezbędnych do umożliwienia realizacji obowiązków Przetwarzającego, w szczególności zapewnić legalność pozyskiwania i przekazywania danych osobowych, oraz wydawać polecenia lub instrukcje jedynie w pełni zgodne z prawem.<br>
                    4.3.  Administrator zobowiązuje się do korzystania z oprogramowania i usługi świadczonej na podstawie Umowy Podstawowej zgodnie z obowiązującym prawem, w sposób nie narażający Przetwarzającego na odpowiedzialność prawną. Administrator przyjmuje na siebie pełną odpowiedzialność za naruszenia prawa (w szczególności RODO) będące pośrednim bądź bezpośrednim wynikiem jego działań.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">5. Nadzór</h2>
                <p class="data-processing-agreement__content">
                    5.1.	Sprawowanie kontroli [zgodnie z art. 28 ust. 3 lit. h RODO] Administrator kontroluje sposób przetwarzania powierzonych Danych Osobowych po uprzednim poinformowaniu Przetwarzającego o planowanej kontroli. Administrator uprawniony jest do żądania od Przetwarzającego udzielania informacji dotyczących przebiegu przetwarzania Danych Osobowych jedynie w zakresie niezbędnym do kontroli przetwarzania powierzonych Danych Osobowych. Administrator nie może żądać dostępu do danych Przetwarzającego ponad informacje niezbędne dla kontroli. Administrator zobowiązuje się do zachowania pełnej poufności. Strony postanawiają, że wszystkie informacje (poza powierzonymi przez Administratora Danymi Osobowymi) do jakich Administrator miał dostęp podczas kontroli, stanowią tajemnice przedsiębiorstwa Przetwarzającego, a Administrator ponosi pełną odpowiedzialność za ich wyjawienie.<br>
                    5.2.	Współpraca przy kontroli. [zgodnie z art. 28 ust. 3 lit. h RODO] Przetwarzający współpracuje z urzędem ochrony danych osobowych w zakresie wykonywanych przez niego zadań.<br>
                    5.3.	Przetwarzający:<br>
                     (1)	udostępnia Administratorowi wszelkie informacje niezbędne do wykazania zgodności działania Administratora z przepisami RODO,<br>
                     (2)	umożliwia Administratorowi lub upoważnionemu audytorowi przeprowadzanie audytów lub inspekcji. Przetwarzający współpracuje w zakresie realizacji audytów lub inspekcji.
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">6.	Oświadczenia Stron</h2>
                <p class="data-processing-agreement__content">
                    6.1.	Oświadczenie Administratora. Administrator oświadcza, że jest Administratorem Danych oraz, że jest uprawniony do ich przetwarzania w zakresie, w jakim powierzył je Przetwarzającemu.<br>
                    6.2.	Oświadczenie Przetwarzającego [zgodnie z art. 28 ust. 1 RODO]. Przetwarzający oświadcza, że w ramach prowadzonej działalności gospodarczej profesjonalnie zajmuje się przetwarzaniem danych osobowych objętych niniejszą  Umową i Umową Podstawową, posiada w tym zakresie niezbędną wiedzę, odpowiednie środki techniczne i organizacyjne.<br>
                    6.3.	Referencje [zgodnie z art. 28 ust. 1 RODO]. Na żądanie Administratora Przetwarzający okaże Administratorowi referencje, na dowód iż Przetwarzający zapewnia wystarczające gwarancje wdrożenia odpowiednich środków technicznych i organizacyjnych, by przetwarzanie spełniało wymogi RODO i chroniło prawa osób, których dane dotyczą.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">7.	Odpowiedzialność</h2>
                <p class="data-processing-agreement__content">
                    7.1.	Odpowiedzialność Przetwarzającego [zgodnie z art. 82 ust. 3 RODO] Przetwarzający odpowiada za szkody spowodowane swoim działaniem w związku z niedopełnieniem obowiązków, które RODO nakłada bezpośrednio na Przetwarzającego lub gdy działał poza zgodnymi z prawem instrukcjami Administratora lub wbrew tym instrukcjom.<br>
                    7.2.	Odpowiedzialność za Podprzetwarzających [zgodnie z art. 28 ust. 4 RODO] Jeżeli Podprzetwarzający nie wywiąże się ze spoczywających na nim obowiązków ochrony danych, odpowiedzialność wobec Administratora za wypełnienie obowiązków przez Podprzetwarzającego spoczywa na Przetwarzającym.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">8.	Okres Obowiązywania Umowy Powierzenia</h2>
                <p class="data-processing-agreement__content">
                    8.1.	Umowa została zawarta na czas obowiązywania Umowy Podstawowej.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">9.	Usunięcie Danych</h2>
                <p class="data-processing-agreement__content">
                    9.1.	Usunięcie danych [zgodnie z art. 28 ust. 3 lit g RODO] Z chwilą rozwiązania Umowy Przetwarzający nie ma prawa do dalszego przetwarzania powierzonych Danych i jest zobowiązany do:<br>
                      (1) usunięcia Danych,<br>
                      (2) usunięcia wszelkich ich istniejących kopii lub zwrotu Danych, chyba że Administrator postanowi inaczej lub prawo Unii Europejskiej lub prawo państwa członkowskiego nakazują dalej przechowywanie Danych.<br>
                      (3) Strony uzgodnią sposób usunięcia Danych odrębnym dokumentem w ciągu 30 dni od zawarcia Umowy Powierzenia.<br>
                    9.2.	Karencja. Przetwarzający dokona usunięcia Danych po upływie 180 dni od zakończenia Umowy, chyba że Administrator poleci mu to uczynić wcześniej.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">10.	Postanowienia Końcowe</h2>
                <p class="data-processing-agreement__content">
                    10.1.	Pierwszeństwo. W razie sprzeczności pomiędzy postanowieniami niniejszej Umowy Powierzenia a Umowy Podstawowej, pierwszeństwo mają postanowienia Umowy Powierzenia. Oznacza to także, że kwestie dotyczące przetwarzania danych osobowych pomiędzy Administratorem a Przetwarzającym należy regulować poprzez zmiany niniejszej Umowy lub w wykonaniu jej postanowień.<br>
                    10.2.	Egzemplarze. Umowa została sporządzona w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej ze Stron.<br>
                    10.3.	Właściwość prawa. Umowa podlega prawu polskiemu oraz RODO.<br>
                </p>
            </div>
        </div>

        <div class="data-processing-agreement" *ngIf="language === LANGUAGES.pl">
            <h1 class="data-processing-agreement__title data-processing-agreement__title--main is-bold">Umowa powierzenia przetwarzania danych osobowych stanowiąca uzupełnienie do Umowy zawartej na podstawie akceptacji Regulaminu i Umowy licencyjnej dotyczącej korzystania z aplikacji Taxando.de</h1>
            <br>
            <div class="data-processing-agreement__stage">
                <p class="data-processing-agreement__content">
                    Umowa zostaje zawarta w formie akceptacji elektronicznej, gdzie wcześniej zweryfikowane konto biznesowe (b2b) zostało zarejestrowane w systemie Taxando z wprowadzeniem pełnych danych podmiotu, który przy pomocy  adresu email identyfikuje się podczas logowania do systemu Taxando – dalej zwanym Administratorem.<br>
                    Umowa elektroniczna zostaje zawarta pomiędzy:<br>
                    <strong>Administratorem</strong><br>
                    a<br>
                    spółką Taxando GmbH, z siedzibą w Berlinie pod adresem: Kurfürstendamm 226, 10719 Berlin, numer ewidencyjny (rejestru) : HRB 189179 B (<strong>„Przetwarzający”</strong>)<br>
                    (dalej łącznie jako: <strong>„Strony”</strong>)<br>
                <br>
                    Zważywszy, że:<br>
                    i.	Strony zawarły a podstawie akceptacji Regulaminu i Umowy licencyjnej Taxando.de umowę dotyczącą usługi udostępniania aplikacji Taxando.de („Umowa Podstawowa”), w związku, z wykonywaniem której Administrator powierzy Przetwarzającemu przetwarzanie danych osobowych w zakresie określonym Umową;<br>
                    ii.	Celem Umowy jest ustalenie warunków, na jakich Przetwarzający wykonuje operacje przetwarzania Danych Osobowych w imieniu Administratora;<br>
                    iii.	 Strony zawierając Umowę dążą do takiego uregulowania zasad przetwarzania Danych Osobowych, aby odpowiadały one w pełni postanowieniom rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz.Urz. UE L 119, s. 1) – dalej RODO.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">1.	Opis Przetwarzania</h2>

                <p class="data-processing-agreement__content">
                    1.1.	Przedmiot [zgodnie z art. 28 ust. 3 RODO] Na warunkach określonych niniejszą Umową oraz Umową Podstawową Administrator powierza Przetwarzającemu przetwarzanie (w rozumieniu RODO) dalej opisanych Danych Osobowych.<br>
                    1.2.	Czas [zgodnie art. 28 ust. 3 RODO] Przetwarzanie będzie wykonywane w okresie obowiązywania Umowy Podstawowej.<br>
                    1.3.	Charakter i cel [zgodnie z art. 28 ust. 3 RODO] Charakter i cel przetwarzania wynikają z Umowy Podstawowej. W szczególności:<br>
                    1.3.1.	charakter przetwarzania określony jest następującą rolą Przetwarzającego: udostępnianie Administratorowi licencji oprogramowania, które umożliwia obsługę klientów Administratora pod kątem rozliczeń podatkowych na terenie Niemiec, zaś<br>
                    1.3.2. celem przetwarzania jest umożliwienie Administratorowi wywiązywania się z zobowiązań wobec swoich klientów poprzez wykonanie usługi rozliczeń podatkowych na terenie Niemiec<br>
                    1.4.	Rodzaj danych [zgodnie z art. 28 ust. 3 RODO] Przetwarzanie obejmować będzie następujące rodzaje danych osobowych („Dane”):<br>
                    <br>
                    <strong>Dane zwykłe:</strong><br>
                        (1)	imię i nazwisko,<br>
                        (2)	numer podatkowy<br>
                        (3)	stan cywilny,<br>
                        (4)	adres zamieszkania,<br>
                        (5)	data urodzenia,<br>
                        (6)	wyznanie (religia)<br>
                        (7)	informacja o dochodzie w Niemczech<br>
                        (8)	informacja o dochodzie poza granicami Niemiec<br>
                        (9)	numer rachunku bankowego,<br>
                        (10)	imię i nazwisko współmałżonka,<br>
                        (11)	numer ewidencyjny  współmałżonka,<br>
                        (12)	wysokość dochodu za dany rok,<br>
                        (13)	dane zagranicznego pracodawcy,<br>
                    <br>
                    <strong>Dane dzieci:</strong>
                    <br>
                        (14)	imię i nazwisko,<br>
                        (15)	data urodzenia,<br>
                        (16)	dane osobowe i adres rodziców<br>
                    <br>
                    <strong>Dane nieustrukturyzowane</strong>
                    <br>
                    kontent o potencjalnej i prawdopodobnej zawartości danych osobowych (wpisy, dokumenty tekstowe).<br>
                    1.5.	Kategorie osób [zgodnie z art. 28 ust. 3 RODO] Przetwarzanie Danych będzie dotyczyć następujących kategorii osób:<br>
                    (1)	klienci Administratora.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">2.	Podpowierzenie</h2>
                <p class="data-processing-agreement__content">
                    2.1.	Podpowierzenie [zgodnie z art. 28 ust. 2 RODO] Przetwarzający może powierzyć konkretne operacje przetwarzania Danych („podpowierzenie”) w drodze pisemnej umowy podpowierzenia („Umowa Podpowierzenia”) innym podmiotom przetwarzającym. („Podprzetwarzający”), pod warunkiem uprzedniej akceptacji Podprzetwarzającego przez Administratora lub braku sprzeciwu.<br>
                    2.2.	Sprzeciw. Powierzenie przetwarzania Danych Podprzetwarzającym spoza Listy Zaakceptowanych Podprzetwarzających wymaga uprzedniego zgłoszenia Administratorowi w celu umożliwienia wyrażenia sprzeciwu. Administrator może z uzasadnionych przyczyn zgłosić udokumentowany sprzeciw względem powierzenia Danych konkretnemu Podprzetwarzającemu. W razie zgłoszenia sprzeciwu Przetwarzający nie ma prawa powierzyć Danych Podprzetwarzającemu objętemu sprzeciwem, a jeżeli sprzeciw dotyczy aktualnego Podprzetwarzającego, musi niezwłocznie zakończyć podpowierzenie temu Podprzetwarzającemu. Wątpliwości co do zasadności sprzeciwu i ewentualnych negatywnych konsekwencji Przetwarzający zgłosi Administratorowi w czasie umożliwiającym zapewnienie ciągłości przetwarzania.<br>
                    2.3.	Transfer obowiązków [zgodnie z art. 28 ust. 4 RODO] Dokonując podpowierzenia Przetwarzający ma obowiązek zobowiązać Podprzetwarzającego do realizacji wszystkich obowiązków Przetwarzającego wynikających z niniejszej Umowy powierzenia, z wyjątkiem tych, które nie mają zastosowania ze względu na naturę konkretnego podpowierzenia.<br>
                    2.4.	Zobowiązanie względem Administratora. Przetwarzający ma obowiązek zapewnić, aby Podprzetwarzający złożył Administratorowi zobowiązanie do wykonania obowiązków, o których mowa w poprzednim ustępie. Może to zostać wykonane przez podpisanie stosownego oświadczenia adresowanego do Administratora wraz z podpisaniem Umowy Podpowierzenia, zawierającego listę obowiązków Podprzetwarzającego.<br>
                    2.5.	Zakaz podzlecenia świadczenia głównego [zgodnie z art. 28 ust. 4 RODO] Przetwarzający nie ma prawa przekazać Podprzetwarzającemu całości wykonania Umowy.
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">3.  Obowiązki Przetwarzającego</h2>
                <p class="data-processing-agreement__content">
                    Przetwarzający ma następujące obowiązki:<br>
                    3.1.	Udokumentowane polecenia [zgodnie z art. 28 ust. 3 lit. a RODO] Przetwarzający przetwarza Dane wyłącznie zgodnie z udokumentowanymi poleceniami lub instrukcjami Administratora.<br>
                    3.2.	Nieprzetwarzanie poza EOG [zgodnie z art. 28 ust. 3 lit. a RODO] Przetwarzający oświadcza, że nie przekazuje Danych do państwa trzeciego lub organizacji międzynarodowej (czyli poza Europejski Obszar Gospodarczy („EOG”)). Przetwarzający oświadcza również, że nie korzysta z podwykonawców, którzy przekazują Dane poza EOG.<br>
                    3.3.	Poinformowanie o zamiarze przetwarzania poza EOG. [zgodnie z art. 28 ust. 3 lit. a RODO] Jeżeli Przetwarzający ma zamiar lub obowiązek przekazywać Dane poza EOG, informuje o tym Administratora, w celu umożliwienia Administratorowi podjęcia decyzji i działań niezbędnych do zapewnienia zgodności przetwarzania z prawem lub zakończenia powierzenia przetwarzania.<br>
                    3.4.	Tajemnica [zgodnie z art. 28 ust. 3 lit. b RODO] Przetwarzający uzyskuje od osób, które zostały upoważnione do przetwarzania Danych w wykonaniu Umowy, udokumentowane zobowiązania do zachowania tajemnicy, ewentualnie upewnia się, że te osoby podlegają ustawowemu obowiązkowi zachowania tajemnicy.<br>
                    3.5.	Bezpieczeństwo [zgodnie z art. 28 ust. 3 lit. c RODO] Przetwarzający zapewnia ochronę Danych i podejmuje środki ochrony danych, o których mowa w art. 32 RODO, zgodnie z dalszymi postanowieniami Umowy.<br>
                    3.6.	Podprzetwarzanie [zgodnie z art. 28 ust. 3 lit. d RODO] Przetwarzający przestrzega warunków korzystania z usług innego podmiotu przetwarzającego (Podprzetwarzającego).<br>
                    3.7.	Współpraca przy realizacji praw jednostki [zgodnie z art. 28 ust. 3 lit. e RODO] Przetwarzający zobowiązuje się wobec Administratora do odpowiadania na żądania osoby, której dane dotyczą, w zakresie wykonywania praw określonych w rozdziale III RODO („Prawa jednostki”). Przetwarzający oświadcza, że zapewnia obsługę Praw jednostki w odniesieniu do powierzonych Danych. Szczegóły obsługi Praw jednostki zostaną pomiędzy Stronami uzgodnione. Strony ustaliły procedurę obsługi Praw jednostki odrębnym dokumentem.<br>
                    3.8.	Wsparcie przy obowiązkach bezpieczeństwa [zgodnie z art. 28 ust. 3 lit. f RODO] Przetwarzający współpracuje z Administratorem przy wykonywaniu przez Administratora obowiązków z obszaru ochrony danych osobowych, o których mowa w art. 32-36 RODO (ochrona danych, zgłaszanie naruszeń organowi nadzorczemu, zawiadamianie osób dotkniętych naruszeniem ochrony danych, ocena skutków dla ochrony danych i uprzednie konsultacje z organem nadzorczym).<br>
                    3.9.	Legalność poleceń [zgodnie z art. 28 ust. 3 ak. 2 RODO] Jeżeli Przetwarzający poweźmie wątpliwości co do zgodności z prawem wydanych przez Administratora poleceń lub instrukcji, Przetwarzający informuje Administratora o stwierdzonej wątpliwości.<br>
                    3.10.	Projektowanie prywatności [zgodnie z art. 25 ust. 1 RODO] Planując dokonanie zmian w sposobie przetwarzania Danych, Przetwarzający ma obowiązek zastosować się do wymogu projektowania prywatności, o którym mowa w art. 25 ust. 1 RODO i ma obowiązek z wyprzedzeniem informować Administratora o planowanych zmianach w taki sposób i terminach, aby zapewnić Administratorowi realną możliwość reagowania, jeżeli planowane przez Przetwarzającego zmiany w opinii Administratora grożą uzgodnionemu poziomowi bezpieczeństwa Danych lub zwiększają ryzyko naruszenia praw lub wolności osób, wskutek przetwarzania Danych przez Przetwarzającego.<br>
                    3.11.	Minimalizacja [zgodnie z art. 25 ust. 2 RODO] Przetwarzający zobowiązuje się do ograniczenia dostępu do Danych Osobowych wyłącznie do osób, których dostęp do Danych jest potrzebny dla realizacji Umowy i posiadających odpowiednie upoważnienie.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">4.	Obowiązki Administratora</h2>
                <p class="data-processing-agreement__content">
                    4.1. Administrator zobowiązany jest współdziałać z Przetwarzającym w wykonaniu Umowy, udzielać Przetwarzającemu wyjaśnień w razie wątpliwości co do legalności poleceń Administratora, jak też wywiązywać się terminowo ze swoich szczegółowych obowiązków.<br>
                    4.2.  Administrator  zobowiązany dochować wszelkich środków ostrożności niezbędnych do umożliwienia realizacji obowiązków Przetwarzającego, w szczególności zapewnić legalność pozyskiwania i przekazywania danych osobowych, oraz wydawać polecenia lub instrukcje jedynie w pełni zgodne z prawem.<br>
                    4.3.  Administrator zobowiązuje się do korzystania z oprogramowania i usługi świadczonej na podstawie Umowy Podstawowej zgodnie z obowiązującym prawem, w sposób nie narażający Przetwarzającego na odpowiedzialność prawną. Administrator przyjmuje na siebie pełną odpowiedzialność za naruszenia prawa (w szczególności RODO) będące pośrednim bądź bezpośrednim wynikiem jego działań.<br>
                </p>


                <h2 class="data-processing-agreement__subtitle is-bold">5. Nadzór</h2>
                <p class="data-processing-agreement__content">
                    5.1.	Sprawowanie kontroli [zgodnie z art. 28 ust. 3 lit. h RODO] Administrator kontroluje sposób przetwarzania powierzonych Danych Osobowych po uprzednim poinformowaniu Przetwarzającego o planowanej kontroli. Administrator uprawniony jest do żądania od Przetwarzającego udzielania informacji dotyczących przebiegu przetwarzania Danych Osobowych jedynie w zakresie niezbędnym do kontroli przetwarzania powierzonych Danych Osobowych. Administrator nie może żądać dostępu do danych Przetwarzającego ponad informacje niezbędne dla kontroli. Administrator zobowiązuje się do zachowania pełnej poufności. Strony postanawiają, że wszystkie informacje (poza powierzonymi przez Administratora Danymi Osobowymi) do jakich Administrator miał dostęp podczas kontroli, stanowią tajemnice przedsiębiorstwa Przetwarzającego, a Administrator ponosi pełną odpowiedzialność za ich wyjawienie.<br>
                    5.2.	Współpraca przy kontroli. [zgodnie z art. 28 ust. 3 lit. h RODO] Przetwarzający współpracuje z urzędem ochrony danych osobowych w zakresie wykonywanych przez niego zadań.<br>
                    5.3.	Przetwarzający:<br>
                     (1)	udostępnia Administratorowi wszelkie informacje niezbędne do wykazania zgodności działania Administratora z przepisami RODO,<br>
                     (2)	umożliwia Administratorowi lub upoważnionemu audytorowi przeprowadzanie audytów lub inspekcji. Przetwarzający współpracuje w zakresie realizacji audytów lub inspekcji.
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">6.	Oświadczenia Stron</h2>
                <p class="data-processing-agreement__content">
                    6.1.	Oświadczenie Administratora. Administrator oświadcza, że jest Administratorem Danych oraz, że jest uprawniony do ich przetwarzania w zakresie, w jakim powierzył je Przetwarzającemu.<br>
                    6.2.	Oświadczenie Przetwarzającego [zgodnie z art. 28 ust. 1 RODO]. Przetwarzający oświadcza, że w ramach prowadzonej działalności gospodarczej profesjonalnie zajmuje się przetwarzaniem danych osobowych objętych niniejszą  Umową i Umową Podstawową, posiada w tym zakresie niezbędną wiedzę, odpowiednie środki techniczne i organizacyjne.<br>
                    6.3.	Referencje [zgodnie z art. 28 ust. 1 RODO]. Na żądanie Administratora Przetwarzający okaże Administratorowi referencje, na dowód iż Przetwarzający zapewnia wystarczające gwarancje wdrożenia odpowiednich środków technicznych i organizacyjnych, by przetwarzanie spełniało wymogi RODO i chroniło prawa osób, których dane dotyczą.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">7.	Odpowiedzialność</h2>
                <p class="data-processing-agreement__content">
                    7.1.	Odpowiedzialność Przetwarzającego [zgodnie z art. 82 ust. 3 RODO] Przetwarzający odpowiada za szkody spowodowane swoim działaniem w związku z niedopełnieniem obowiązków, które RODO nakłada bezpośrednio na Przetwarzającego lub gdy działał poza zgodnymi z prawem instrukcjami Administratora lub wbrew tym instrukcjom.<br>
                    7.2.	Odpowiedzialność za Podprzetwarzających [zgodnie z art. 28 ust. 4 RODO] Jeżeli Podprzetwarzający nie wywiąże się ze spoczywających na nim obowiązków ochrony danych, odpowiedzialność wobec Administratora za wypełnienie obowiązków przez Podprzetwarzającego spoczywa na Przetwarzającym.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">8.	Okres Obowiązywania Umowy Powierzenia</h2>
                <p class="data-processing-agreement__content">
                    8.1.	Umowa została zawarta na czas obowiązywania Umowy Podstawowej.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">9.	Usunięcie Danych</h2>
                <p class="data-processing-agreement__content">
                    9.1.	Usunięcie danych [zgodnie z art. 28 ust. 3 lit g RODO] Z chwilą rozwiązania Umowy Przetwarzający nie ma prawa do dalszego przetwarzania powierzonych Danych i jest zobowiązany do:<br>
                      (1) usunięcia Danych,<br>
                      (2) usunięcia wszelkich ich istniejących kopii lub zwrotu Danych, chyba że Administrator postanowi inaczej lub prawo Unii Europejskiej lub prawo państwa członkowskiego nakazują dalej przechowywanie Danych.<br>
                      (3) Strony uzgodnią sposób usunięcia Danych odrębnym dokumentem w ciągu 30 dni od zawarcia Umowy Powierzenia.<br>
                    9.2.	Karencja. Przetwarzający dokona usunięcia Danych po upływie 180 dni od zakończenia Umowy, chyba że Administrator poleci mu to uczynić wcześniej.<br>
                </p>

                <h2 class="data-processing-agreement__subtitle is-bold">10.	Postanowienia Końcowe</h2>
                <p class="data-processing-agreement__content">
                    10.1.	Pierwszeństwo. W razie sprzeczności pomiędzy postanowieniami niniejszej Umowy Powierzenia a Umowy Podstawowej, pierwszeństwo mają postanowienia Umowy Powierzenia. Oznacza to także, że kwestie dotyczące przetwarzania danych osobowych pomiędzy Administratorem a Przetwarzającym należy regulować poprzez zmiany niniejszej Umowy lub w wykonaniu jej postanowień.<br>
                    10.2.	Egzemplarze. Umowa została sporządzona w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej ze Stron.<br>
                    10.3.	Właściwość prawa. Umowa podlega prawu polskiemu oraz RODO.<br>
                </p>
            </div>
        </div>
        <div class="buttons">
            <a class="btn btn--secondary" (click)="decline()">{{ 'COMMON.DECLINE' | translate }}</a>
            <a class="btn btn--primary" (click)="accept()">{{ 'COMMON.ACCEPT' | translate }}</a>
        </div>
    </div>
</div>
