<div>
  <ng-container *ngIf="isLoading">
    <app-content-loader></app-content-loader>
  </ng-container>
  <ng-container *ngIf="isInitialized">
    <div class="step-container columns is-desktop" [class.d-none]="isLoading">
      <app-aside-bar
        [showEvenWithoutStatement]="false"
        [statement]="statement"
        [isBusiness]="isBusiness"
        [workFlowController]="workFlowController"
      ></app-aside-bar>
      <form class="main-content__container" (keydown.enter)="onEnterKeyDown($event)">
        <ng-container *ngTemplateOutlet='templateRef'></ng-container>
      </form>
      <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
    </div>
  </ng-container>
</div>
