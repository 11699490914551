import { Injectable } from '@angular/core';
import { FirebaseConfig } from 'app/shared/modules/firebase/configs/firebase.config';
import { FirebaseApp, getApp, getApps } from '@firebase/app';
import { initializeApp } from 'firebase/app';
import { environment } from 'environments/environment';

@Injectable()
export class FirebaseService {
  constructor(
  ) {
  }

  public get app(): FirebaseApp | undefined {
    if (!getApps().find(app => app.name === FirebaseConfig.appName)) {
      return this.init();
    }

    return getApp(FirebaseConfig.appName) ?? undefined;
  }

  private init(): FirebaseApp {
    return initializeApp({
      ...environment.firebase
    }, FirebaseConfig.appName);
  }
}
