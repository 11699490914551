
import { finalize, takeUntil } from 'rxjs/operators';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { Statement } from '../statement';
import { StatementService } from '../statement.service';
import { SubscribingComponent } from '../../common/subscribing-component';

@Component({
  selector: 'app-send-ewr-attachment',
  templateUrl: './send-ewr-attachment.component.html',
  styleUrls: ['./send-ewr-attachment.component.scss']
})
export class SendEwrAttachmentComponent extends SubscribingComponent implements OnInit {

  sending = false;
  @ViewChild('ewrField', { static: false }) scanField: ElementRef;
  @Input() statement: Statement;
  errors = [];
  ewr: File;

  constructor(
    private statementService: StatementService,
    private router: Router,
    private dialogRef: MatDialogRef<SendEwrAttachmentComponent>) {
    super();
  }

  ngOnInit() {
  }

  onSubmit() {
    this.clearResponseInfo();
    this.sending = true;
    this.statementService.uploadEWRAttatchment(this.statement.id, this.ewr).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.sending = false))
      .subscribe(
        (response: Response) => {
          const resp = response;
          this.handleResponse(resp);
        },
        (error: any) => {
          this.errors = error.error;
        });
  }

  onEWRSelect() {
    if (this.scanField.nativeElement.files.length > 0) {
      this.ewr = this.scanField.nativeElement.files.item(0);
    }
  }

  clearResponseInfo() {
    this.errors = [];
  }

  handleResponse(response) {
    if (response['statement_id'] === this.statement.id) {
      this.dialogRef.close();
    }
  }
}
