import { RegExValidator, ValidatorErrorMessage, ValidatorRules } from "app/core/form-validator/validatorRules";
import {
  FIELD_RULE_VALUE_OPERATORS,
  FieldRuleValue,
  FieldValidationRules,
  FIELD_OPERATOR_HELPER
} from "app/core/form-validator/validatorInterface";
import {
  SHOW_ADDITIONAL_LONG_TERM_CARE_INSURANCE_YEARS,
  SHOW_RIESTER_AGREEMENT_NUMBERS_FIELD_YEARS,
  SHOW_RIESTER_SOCIAL_SECURITY_NUMBERS_FIELD_YEARS
} from "app/shared/modules/statement-deduction-base/models/deductions";
import { VALIDATOR_FIELD_NAMES_DEDUCTIONS } from "app/core/form-validator/validator-field-names/validator-field-names-deductions";
import { VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS } from "app/core/form-validator/validator-field-names/validator-field-names-child-deductions";
import { VALIDATOR_FIELD_NAMES_PERSONAL_INFO } from "app/core/form-validator/validator-field-names/validator-field-names-personal-info";
import { VALIDATOR_FIELD_RULES_ALL } from "app/core/form-validator/validator-field-rules/validator-field-rules-all";
import { FormValidatorConfig } from "app/configs/form-validator.config";
import { DateHelper } from "app/core/helpers/date.helper";
import { DeductionConstant } from "app/shared/modules/statement-deduction-base/constants/deduction.constant";

export const HAVE_MIN_16_YEARS: number = DateHelper.currentYear - 16;

export const FieldRulesList: FieldValidationRules[] = [
  ...VALIDATOR_FIELD_RULES_ALL,
  /*********************************************
   * ADIDIONAL INCOME SOCIAL FORM
   *******************************************/
  // ADDIATIONAL INCOME SOCIAL NAME
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "additional_income_social_name",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MIN, ValidatorRules.FIELD_LENGTH_MAX],
    fieldRulesValue: [true, 1, 999],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG
    ]
  },
  // ADDITIONAL INCOME SOCIAL VALUE
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "additional_income_social_value",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DECIMAL,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX
    ],
    fieldRulesValue: [true, true, 1, 12],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG
    ]
  },
  // ADDITIONAL INCOME SOCIAL FROM DATE
  {
    year: [2016, 2017, 2018],
    fieldName: "additional_income_social_from_date",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DATE, ValidatorRules.FIELD_YEAR_IS_CURRENT],
    fieldRulesValue: [true, true, true],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT
    ]
  },
  // ADDITIONAL INCOME SOCIAL FROM DATE
  {
    year: [2016, 2017, 2018],
    fieldName: "additional_income_social_to_date",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DATE, ValidatorRules.FIELD_YEAR_IS_CURRENT],
    fieldRulesValue: [true, true, true],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT
    ]
  },
  /*******************************************
   *
   * ADDITIONAL INCOME PENSION
   *
   *******************************************/
  // ADDITIONAL INCOME PENSION FROM
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "additional_income_pension_from",
    fieldRules: [ValidatorRules.IS_NOT_NULL],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  // ADDITIONAL INCOME PENSION AMOUNT
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "additional_income_pension_amount",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DECIMAL],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER]
  },
  // ADDITIONAL INCOME PENSION START
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "additional_income_pension_start",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DATE],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_DATE]
  },
  // ADDITIONAL INCOME PENSION ADJUSTMENT
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "additional_income_pension_adjustment",
    fieldRules: [ValidatorRules.FIELD_IS_DECIMAL],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER]
  },
  /********************************************
   *
   * ADDITIONAL INCOME FRELANCE JOBS FORM
   *
   *********************************************/
  // ADDITIONAl INCOME FREELANCE JOBS NAME
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "additional_icome_freelance_name",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MIN],
    fieldRulesValue: [true, 3],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT]
  },
  // ADDITIONAL INCOME FREELANCE JOBS VALUE
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "additional_income_freelance_value",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DECIMAL],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER]
  },
  /********************************************
   *
   * ADDITIONAL INCOME CRYPTOCURRENCY FORM
   *
   *********************************************/

  // ADDITIONAL INCOME CRYPTOCURRENCY VALUE
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "additional_income_cryptocurrency_value",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DECIMAL],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_SHOULD_BE_NUMBER]
  },
  /**************************************************
   *
   * ABROAD INCOME
   *
   **************************************************/
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "period_of_employments",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ai_currency",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ai_country",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ai_income_type",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_15CHAR]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ai_income",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_15CHAR]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ais_currency",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ais_country",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ais_income",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_15CHAR]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ais_income_type",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_15CHAR]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ais_gr_income",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_15CHAR]
  },
  /***************************************************
   *
   *  EMPLOYER
   *
   ****************************************************/
  // EMPLOYER DATE FROM
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib_date_from",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.DATE_NO_YEAR],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_EMP_DATE_FROM_WRONG_FORMAT]
  },
  // EMPLOYER DATE TO
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib_date_to",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.DATE_NO_YEAR],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_EMP_DATE_TO_WRONG_FORMAT]
  },
  // EMPPLOYER PERIOD OF EMPLOYMENT LOWER
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib_period_lower",
    fieldRules: [ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN]
  },
  // EMPPLOYER PERIOD OF EMPLOYMENT UPPER
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib_period_upper",
    fieldRules: [ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN]
  },
  // EMPLOYER CARD ib03
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib03",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_15CHAR]
  },
  // EMPLOYER CARD ib04
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib04",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_15CHAR]
  },
  // EMPLOYER CARD ib05
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib05",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_15CHAR]
  },
  // EMPLOYER CARD ib06
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib06",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_15CHAR]
  },
  // EMPLOYER CARD ib07
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib07",
    fieldRules: [ValidatorRules.FIELD_IS_DECIMAL],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_15CHAR]
  },
  // EMPLOYER CARD ib15
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib15",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_12],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_12CHAR]
  },
  // EMPLOYER CARD ib17
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib17",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR]
  },
  // EMPLOYER CARD ib18
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib18",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR]
  },
  // EMPLOYER CARD ib19
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib19",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAl_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_15CHAR]
  },
  // EMPLOYER CARD ib20
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib20",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR]
  },
  // EMPLOYER CARD ib21
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib21",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR]
  },
  // EMPLOYER CARD ib22a
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib22a",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_ALLOW_NEGATIVE_8],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_8CHAR]
  },
  // EMPLOYER CARD ib23a
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib23a",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_ALLOW_NEGATIVE_8],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_8CHAR]
  },
  // EMPLOYER CARD ib24a
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib24a",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_8],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_8CHAR]
  },
  // EMPLOYER CARD ib24b
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib24b",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_8],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_8CHAR]
  },
  // EMPLOYER CARD ib24c
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib24c",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_ALLOW_NEGATIVE_8],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_8CHAR]
  },
  // EMPLOYER CARD ib25
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib25",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_ALLOW_NEGATIVE_8],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_8CHAR]
  },
  // EMPLOYER CARD ib26
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib26",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_ALLOW_NEGATIVE_7],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_7CHAR]
  },
  // EMPLOYER CARD ib27
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "ib27",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.NUMBER_2_DECIMAL_ALLOW_NEGATIVE_7],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_DECIMAL_NEGATIVE_7CHAR]
  },
  // EMPLOYER CARD emp_taxClass
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "emp_taxClass",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  /*********************************************************
   *
   * PERSONAL INFO
   *
   *********************************************************/
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.first_name,
    classFieldName: "firstName",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 1, 25, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.last_name,
    classFieldName: "lastName",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 1, 25, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.street,
    classFieldName: "street",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 1, 25, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.house_nr,
    classFieldName: "houseNumberWithSupplement",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.additional_address_info,
    classFieldName: "additionalAddressInfo",
    fieldRules: [ValidatorRules.FIELD_LENGTH_MAX],
    fieldRulesValue: [25],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_LENGTH_TO_LONG]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.post_code,
    classFieldName: "postalCode",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MIN, ValidatorRules.FIELD_LENGTH_MAX],
    fieldRulesValue: [true, 1, 25],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG
    ]
  },
  {
    year: FormValidatorConfig.TO_2023_YEAR,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.city,
    classFieldName: "city",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 1, 20, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.FROM_2024,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.city,
    classFieldName: "city",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 1, 25, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.country,
    classFieldName: "country",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.birth_date,
    classFieldName: "birthDate",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_YEAR_MAX],
    fieldRulesValue: [true, HAVE_MIN_16_YEARS],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_HAVE_MIN_16_YEARS]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.religion,
    classFieldName: "religion",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.tax_authority,
    classFieldName: "taxAuthority",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.id_number,
    classFieldName: "identifikationsnummer",
    fieldRules: [ValidatorRules.FIELD_IS_INTIGER],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_SHOULD_BE_INTIGER]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.tax_number,
    classFieldName: "steuernummer",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.STEUER_NUMBER],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_NOT_STEUER]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_PERSONAL_INFO.phone_number,
    classFieldName: "phoneNumber",
    fieldRules: [ValidatorRules.FIELD_LENGTH_MIN, ValidatorRules.FIELD_LENGTH_MAX],
    fieldRulesValue: [7, 16],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT, ValidatorErrorMessage.FIELD_LENGTH_TO_LONG]
  },
  /************************************************************
   *
   * DEDUCTION
   * validate filed service v2
   *
   ************************************************************/
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.job_equipment_cost_name,
    classFieldName: "jobEquipmentCostNames",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.job_equipment_costs,
    classFieldName: "jobEquipmentCosts",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.work_wear_cost,
    classFieldName: "workwearCost",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      new FieldRuleValue(
        [`${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}workwearWashingCost`],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      "PLEASE_ENTER_WORKWEAR_OR_WASHING_COST",
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.work_wear_washing_cost,
    classFieldName: "workwearWashingCost",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      new FieldRuleValue(
        [`${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}workwearCost`],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      "PLEASE_ENTER_WORKWEAR_OR_WASHING_COST",
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.training_cost_names,
    classFieldName: "trainingCostNames",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.training_costs,
    classFieldName: "trainingCosts",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.recruitment_costs,
    classFieldName: "recruitmentCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.place_employment_address,
    classFieldName: "placeOfEmploymentAddress",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.period_employment_lower,
    classFieldName: "periodOfEmployment",
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`periodOfEmployment.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true)
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.period_employment_upper,
    classFieldName: "periodOfEmployment",
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`periodOfEmployment.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true)
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.working_days_per_week,
    classFieldName: "workingDaysPerWeek",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [true, true, [1, 7]],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldErrorMessageArguments: [null, null, { from: 1, to: 7 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.absences_work,
    classFieldName: "absencesAtWork",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ],
    fieldRulesValue: [true, [0, 366]],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ],
    fieldErrorMessageArguments: [null, { from: 0, to: 366 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.presences_work,
    classFieldName: "presencesAtWork",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ],
    fieldRulesValue: [true, [1, 366]],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ],
    fieldErrorMessageArguments: [null, null, { from: 1, to: 366 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.distance_from_work,
    classFieldName: "distanceFromWork",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [true, true, [0, 9999]],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldErrorMessageArguments: [null, null, { from: 0, to: 9999 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.public_transport_cost,
    classFieldName: "publicTransportCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_8],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_8CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.trade_union_name,
    classFieldName: "tradeUnionNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.trade_union_contribution,
    classFieldName: "tradeUnionContributions",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: [2020],
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.home_office_covid19_day_in_home,
    classFieldName: "homeOfficeCovid19DayInHome",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [true, true, [1, 120]],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldErrorMessageArguments: [null, null, { from: 1, to: 120 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.home_office_costs_name,
    classFieldName: "homeOfficeCostsNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.home_office_costs,
    classFieldName: "homeOfficeCosts",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.car_travels_distance,
    classFieldName: "carTravelsDistance",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [true, true, [1, 9999]],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldErrorMessageArguments: [null, null, { from: 1, to: 9999 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.car_travels_number,
    classFieldName: "carTravelsNumber",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [true, true, [1, 366]],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldErrorMessageArguments: [null, null, { from: 1, to: 366 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.travel_tickets_cost_value,
    classFieldName: "travelTicketsCosts",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.accommodation_costs,
    classFieldName: "accommodationCosts",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.employer_refund_value,
    classFieldName: "employerRefunds",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_one_day_travel,
    classFieldName: "daysInOneDayTravel",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_INDEX,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      true,
      new FieldRuleValue(
        [
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
        ],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      [
        0,
        new FieldRuleValue(
          [
            366,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
          ],
          FIELD_RULE_VALUE_OPERATORS.MINUS
        )
      ]
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.ONE_OF_THIS_IS_REQUIRED,
      "FIELDS_SUM_TOO_BIG_DIETS"
    ],
    fieldErrorMessageArguments: [null, { sum: 366 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_travel_to_work,
    classFieldName: "daysInTravelToWork",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_INDEX,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      true,
      new FieldRuleValue(
        [
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
        ],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      new FieldRuleValue(
        [`${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      [
        0,
        new FieldRuleValue(
          [
            366,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
          ],
          FIELD_RULE_VALUE_OPERATORS.MINUS
        )
      ]
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.ONE_OF_THIS_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      "FIELDS_SUM_TOO_BIG_DIETS"
    ],
    fieldErrorMessageArguments: [null, { sum: 366 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_travel_from_work,
    classFieldName: "daysInTravelFromWork",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_INDEX,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      true,
      new FieldRuleValue(
        [
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
        ],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      new FieldRuleValue(
        [`${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      [
        0,
        new FieldRuleValue(
          [
            366,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
          ],
          FIELD_RULE_VALUE_OPERATORS.MINUS
        )
      ]
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.ONE_OF_THIS_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      "FIELDS_SUM_TOO_BIG_DIETS"
    ],
    fieldErrorMessageArguments: [null, { sum: 366 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_in_multi_day_travel,
    classFieldName: "daysInMultiDayTravel",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_INDEX,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      true,
      new FieldRuleValue(
        [
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
        ],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      [
        0,
        new FieldRuleValue(
          [
            366,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
          ],
          FIELD_RULE_VALUE_OPERATORS.MINUS
        )
      ]
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.ONE_OF_THIS_IS_REQUIRED,
      "FIELDS_SUM_TOO_BIG_DIETS"
    ],
    fieldErrorMessageArguments: [null, { sum: 366 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.free_meals_diet_amount,
    classFieldName: "freeMealsDietAmount",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.travel_abroad_country,
    classFieldName: "travelAbroadCountry",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.travel_abroad_city,
    classFieldName: "travelAbroadCity",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_in_one_day_travel_abroad,
    classFieldName: "daysInOneDayTravelAbroad",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_INDEX,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      true,
      new FieldRuleValue(
        [
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
        ],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      [
        0,
        new FieldRuleValue(
          [
            366,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
          ],
          FIELD_RULE_VALUE_OPERATORS.MINUS
        )
      ]
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.ONE_OF_THIS_IS_REQUIRED,
      "FIELDS_SUM_TOO_BIG_DIETS"
    ],
    fieldErrorMessageArguments: [null, { sum: 366 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_in_travel_abroad_to_work,
    classFieldName: "daysInTravelAbroadToWork",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_INDEX,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      true,
      new FieldRuleValue(
        [
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
        ],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      new FieldRuleValue(
        [
          `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
        ],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      [
        0,
        new FieldRuleValue(
          [
            366,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
          ],
          FIELD_RULE_VALUE_OPERATORS.MINUS
        )
      ]
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.ONE_OF_THIS_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      "FIELDS_SUM_TOO_BIG_DIETS"
    ],
    fieldErrorMessageArguments: [null, { sum: 366 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_in_travel_abroad_from_work,
    classFieldName: "daysInTravelAbroadFromWork",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_INDEX,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      true,
      new FieldRuleValue(
        [
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
        ],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      new FieldRuleValue(
        [
          `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
        ],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      [
        0,
        new FieldRuleValue(
          [
            366,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
          ],
          FIELD_RULE_VALUE_OPERATORS.MINUS
        )
      ]
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.ONE_OF_THIS_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      "FIELDS_SUM_TOO_BIG_DIETS"
    ],
    fieldErrorMessageArguments: [null, { sum: 366 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_in_multi_day_travel_abroad,
    classFieldName: "daysInMultiDayTravelAbroad",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_INDEX,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      true,
      new FieldRuleValue(
        [
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
          `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
        ],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      [
        0,
        new FieldRuleValue(
          [
            366,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInMultiDayTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInOneDayTravelAbroad.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadToWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysInTravelAbroadFromWork.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
          ],
          FIELD_RULE_VALUE_OPERATORS.MINUS
        )
      ]
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.ONE_OF_THIS_IS_REQUIRED,
      "FIELDS_SUM_TOO_BIG_DIETS"
    ],
    fieldErrorMessageArguments: [null, { sum: 366 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.free_meals_diet_amount_abroad,
    classFieldName: "freeMealsDietAmountAbroad",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: DateHelper.createListOfYearsBetween(DeductionConstant.DRIVER_LUMP_SUM_YEAR_START), // above 2020
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.driver_lump_sum,
    classFieldName: "driverLumpsum",
    fieldRules: [ValidatorRules.IS_REQUIRED_INDEX, ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ],
    fieldRulesValue: [true, [1, 366]],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.moving_expanses_costs_names,
    classFieldName: "movingExpansesCostsNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.moving_expanses_costs,
    classFieldName: "movingExpansesCosts",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.household_postal_code,
    nestedArrayFieldName: true,
    classFieldName: "householdPostalCode",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 50, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.household_city,
    nestedArrayFieldName: true,
    classFieldName: "householdCity",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.household_country,
    nestedArrayFieldName: true,
    classFieldName: "householdCountry",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.household_date_from,
    nestedArrayFieldName: true,
    classFieldName: "householdDateFrom",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_DATE],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_DATE]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.residence_at_place_of_employment_postal_code,
    nestedArrayFieldName: true,
    classFieldName: "residenceAtPlaceOfEmploymentPostalCode",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.POSTAL_CODE_DE],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_POSTAL_CODE_DE]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.residence_at_place_of_employment_city,
    nestedArrayFieldName: true,
    classFieldName: "residenceAtPlaceOfEmploymentCity",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.period_of_residence_at_place_of_employment_lower,
    classFieldName: `periodOfResidenceAtPlaceOfEmployment`,
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`periodOfResidenceAtPlaceOfEmployment.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.period_of_residence_at_place_of_employment_upper,
    classFieldName: `periodOfResidenceAtPlaceOfEmployment`,
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`periodOfResidenceAtPlaceOfEmployment.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.residence_at_place_of_employment_costs,
    nestedArrayFieldName: true,
    classFieldName: "residenceAtPlaceOfEmploymentCosts",
    fieldRules: [ValidatorRules.IS_REQUIRED_INDEX, ValidatorRules.FIELD_IS_REG_EX, ValidatorRules.FIELD_NUMBER_MAX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_8, 12000],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_8CHAR,
      ValidatorErrorMessage.FIELD_NUMBER_TO_BIG
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.first_and_last_travel_kilometers,
    nestedArrayFieldName: true,
    classFieldName: "firstAndLastTravelKilometers",
    fieldRules: [
      // ValidatorRules.IS_REQUIRED_INDEX,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      // true,
      // true,
      [0, 9999]
    ],
    fieldErrorMessage: [
      // ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.first_and_last_travel_ticket_costs,
    nestedArrayFieldName: true,
    classFieldName: "firstAndLastTravelTicketCosts",
    fieldRules: [
      // ValidatorRules.IS_REQUIRED_INDEX,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      // true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      // ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.weekend_trips_distance,
    nestedArrayFieldName: true,
    classFieldName: "weekendTripsDistance",
    fieldRules: [
      // ValidatorRules.IS_REQUIRED_INDEX,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      // true,
      // true,
      [0, 9999]
    ],
    fieldErrorMessage: [
      // ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.weekend_trips_number,
    nestedArrayFieldName: true,
    classFieldName: "weekendTripsNumber",
    fieldRules: [
      // ValidatorRules.IS_REQUIRED_INDEX,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      // true,
      // true,
      [0, 51]
    ],
    fieldErrorMessage: [
      // ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.weekend_trips_ticket_costs,
    classFieldName: "weekendTripsTicketCosts",
    nestedArrayFieldName: true,
    fieldRules: [
      // ValidatorRules.IS_REQUIRED_INDEX,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      // true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      // ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_spent_in_travel,
    classFieldName: "daysSpentInTravel",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.FIELD_IS_INTIGER,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      true,
      // new FieldRuleValue(
      //   [
      //     `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysSpentAtPlaceOfEmployment.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
      //   ],
      //   FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      // ),
      [
        0,
        new FieldRuleValue(
          [
            91,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysSpentAtPlaceOfEmployment.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
          ],
          FIELD_RULE_VALUE_OPERATORS.MINUS
        )
      ]
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_SHOULD_BE_INTIGER,
      // ValidatorErrorMessage.ONE_OF_THIS_IS_REQUIRED,
      ValidatorErrorMessage.FIELDS_SUM_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldErrorMessageArguments: [
      null,
      // null,
      { from: 0, to: 91 }
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.days_spent_at_place_of_employment,
    classFieldName: "daysSpentAtPlaceOfEmployment",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.FIELD_IS_INTIGER,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldRulesValue: [
      true,
      // new FieldRuleValue(
      //   [
      //     `${FIELD_OPERATOR_HELPER.IS_NOT_OPERATOR}${FIELD_OPERATOR_HELPER.TO_NUMBER}daysSpentAtPlaceOfEmployment.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`,
      //   ],
      //   FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      // ),
      [
        0,
        new FieldRuleValue(
          [
            91,
            `${FIELD_OPERATOR_HELPER.TO_NUMBER}daysSpentInTravel.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`
          ],
          FIELD_RULE_VALUE_OPERATORS.MINUS
        )
      ]
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_SHOULD_BE_INTIGER,
      // ValidatorErrorMessage.ONE_OF_THIS_IS_REQUIRED,
      ValidatorErrorMessage.FIELDS_SUM_IS_INTEGER_IN_RANGE_EQ
    ],
    fieldErrorMessageArguments: [
      null,
      // null,
      { from: 0, to: 91 }
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.other_costs_name,
    classFieldName: "otherCostsName",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [
      new FieldRuleValue(
        [`${FIELD_OPERATOR_HELPER.TO_NUMBER}otherCosts.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      999,
      RegExValidator.ALLOWED_CHARACTERS_ERIC
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.other_costs,
    classFieldName: "otherCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [
      new FieldRuleValue(
        [`otherCostsName.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      new FieldRuleValue(
        [`otherCostsName.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}`],
        FIELD_RULE_VALUE_OPERATORS.BOOLEAN_AND
      ),
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.house_hold_mini_jobs_costs_names,
    classFieldName: "houseHoldMiniJobsCostsNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.house_hold_mini_jobs_costs,
    classFieldName: "houseHoldMiniJobsCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.house_hold_services_costs_names,
    classFieldName: "houseHoldServicesCostsNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.house_hold_services_costs,
    classFieldName: "houseHoldServicesCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.craftsman_services_costs_names,
    classFieldName: "craftsmanServicesCostsNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.craftsman_services_costs,
    classFieldName: "craftsmanServicesCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: SHOW_RIESTER_SOCIAL_SECURITY_NUMBERS_FIELD_YEARS,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.social_security_number,
    classFieldName: "socialSecurityNumber",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.SOCIAL_SECURITY_NUMBER],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_NOT_SOCIAL_SECURITY_NUMBER
    ]
  },
  {
    year: SHOW_RIESTER_AGREEMENT_NUMBERS_FIELD_YEARS,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.agreement_numbers,
    classFieldName: "agreementNumbers",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.AGREEMENT_NUMBERS],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_NOT_AGREEMENT_NUMBERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_amount,
    classFieldName: "contributionsAmount",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.INTEGER_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_NOT_VALID_FORMAT
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.last_year_income,
    classFieldName: "lastYearIncome",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [RegExValidator.INTEGER_6],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_NOT_VALID_FORMAT]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_to_private_health_insurance,
    classFieldName: "contributionsToPrivateHealthInsurance",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_9
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_9CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.private_long_term_care_insurance_contributions,
    classFieldName: "privateLongTermCareInsuranceContributions",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_8
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_8CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_and_private_insurance_contributions_refunds,
    classFieldName: "healthAndPrivateInsuranceContributionsRefunds",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_and_private_insurance_contributions_grants,
    classFieldName: "healthAndPrivateInsuranceContributionsGrants",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.private_elective_and_supplementary_insurance,
    classFieldName: "privateElectiveAndSupplementaryInsurance",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: SHOW_ADDITIONAL_LONG_TERM_CARE_INSURANCE_YEARS,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.additional_long_term_care_insurance,
    classFieldName: "additionalLongTermCareInsurance",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_to_health_insurance,
    classFieldName: "contributionsToHealthInsurance",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_to_sickness_benefit,
    classFieldName: "contributionsToSicknessBenefit",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.long_term_care_insurance_contributions,
    classFieldName: "longTermCareInsuranceContributions",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_and_insurance_contributions_refunds,
    classFieldName: "healthAndInsuranceContributionsRefunds",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.sickness_benefit_reimbursements,
    classFieldName: "sicknessBenefitReimbursements",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_and_insurance_contributions_grants,
    classFieldName: "healthAndInsuranceContributionsGrants",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.elective_and_supplementary_insurance,
    classFieldName: "electiveAndSupplementaryInsurance",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      // ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [
      true,
      // true,
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      // ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_to_foreign_health_insurance,
    classFieldName: "contributionsToForeignHealthInsurance",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.contributions_to_foreign_sickness_benefit,
    classFieldName: "contributionsToForeignSicknessBenefit",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.foreign_long_term_care_insurance_contributions,
    classFieldName: "foreignLongTermCareInsuranceContributions",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_and_foreign_insurance_contributions_refunds,
    classFieldName: "healthAndForeignInsuranceContributionsRefunds",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.foreign_sickness_benefit_reimbursements,
    classFieldName: "foreignSicknessBenefitReimbursements",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.foreign_elective_and_supplementary_insurance,
    classFieldName: "foreignElectiveAndSupplementaryInsurance",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.foreign_social_insurance_employee_contributions,
    classFieldName: "foreignSocialInsuranceEmployeeContributions",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.foreign_social_insurance_employer_contributions,
    classFieldName: "foreignSocialInsuranceEmployerContributions",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.profession_insurance_cost_names,
    classFieldName: "professionInsuranceCostNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.profession_insurance_costs,
    classFieldName: "professionInsuranceCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_de_name,
    classFieldName: "donationDeName",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 255, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_de_value,
    classFieldName: "donationDeValue",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_eu_name,
    classFieldName: "donationEuName",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 255, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_eu_value,
    classFieldName: "donationEuValue",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_political_name,
    classFieldName: "donationPoliticalName",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 255, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.donation_political_value,
    classFieldName: "donationPoliticalValue",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.tax_settlement_costs,
    classFieldName: "taxSettlementCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.phone_and_internet_costs,
    classFieldName: "phoneAndInternetCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.winterbau_costs,
    classFieldName: "winterbauCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.bank_costs,
    classFieldName: "bankCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.other_recruitment_cost_names,
    classFieldName: "otherRecruitmentCostNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 255, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.other_recruitment_costs,
    classFieldName: "otherRecruitmentCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_cost_name,
    classFieldName: "healthCostsNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_cost,
    classFieldName: "healthCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.health_cost_refund,
    classFieldName: "healthRefundAmount",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.care_cost_name,
    classFieldName: "careCostsNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.care_cost,
    classFieldName: "careCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.care_cost_refund,
    classFieldName: "careRefundAmount",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_percent,
    classFieldName: "disabilityPercent",
    fieldRules: [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_cost_name,
    classFieldName: "disabilityCostsNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_cost,
    classFieldName: "disabilityCosts",
    nestedArrayFieldName: true,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.IS_NOT_ZERO_NUMBER,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_refund_amount,
    classFieldName: "disabilityRefundAmount",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: DateHelper.createListOfYearsBetween(DeductionConstant.DISABILITY_CARE_YEAR_START),
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_pflegegrad_caretaker,
    classFieldName: "disabilityPflegegradCaretaker",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [new FieldRuleValue([`hasDisabilityCarePflegegrad`])],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: DateHelper.createListOfYearsBetween(DeductionConstant.DISABILITY_CARE_YEAR_START),
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.disability_pflegegrad,
    classFieldName: "disabilityPflegegrad",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [new FieldRuleValue([`hasDisabilityCarePflegegrad`])],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: DateHelper.createListOfYearsBetween(DeductionConstant.DISABILITY_CARE_YEAR_START),
    fieldName: VALIDATOR_FIELD_NAMES_DEDUCTIONS.disabled_person_data,
    classFieldName: "disabledPersonData",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MIN, ValidatorRules.FIELD_LENGTH_MAX],
    fieldRulesValue: [new FieldRuleValue([`hasDisabilityCarePflegegrad`]), 9, 255],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG
    ]
  },
  /************************************************************
   *
   * PHONE NUMBER
   *
   ************************************************************/
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: "phone_number",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MIN, ValidatorRules.FIELD_LENGTH_MAX],
    fieldRulesValue: [true, 7, 16],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG
    ]
  },
  /************************************************************
   *
   * CHILD DEDUCTIONS
   * validate filed service v2
   *
   ************************************************************/
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.paid_school_names,
    classFieldName: "paidSchoolNames",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 255, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.paid_school_fees,
    classFieldName: "paidSchoolFees",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.applicant_participation_in_school_costs,
    classFieldName: "applicantParticipationInSchoolCosts",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX, ValidatorRules.FIELD_NUMBER_MAX],
    fieldRulesValue: [
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15,
      new FieldRuleValue([`paidSchoolFees`], FIELD_RULE_VALUE_OPERATORS.PLUS, true)
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR,
      ValidatorErrorMessage.FIELD_NUMBER_TO_BIG
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.childcare_name_and_address,
    classFieldName: "childcareNameAndAddress",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_LENGTH_MAX, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, 255, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.childcare_types,
    classFieldName: "childcareTypes",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.childcare_dates_lower,
    classFieldName: `childcareDates`,
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`childcareDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true)
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.childcare_dates_upper,
    classFieldName: `childcareDates`,
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`childcareDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true)
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.childcare_costs,
    classFieldName: "childcareCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.IS_NOT_ZERO_NUMBER, ValidatorRules.FIELD_IS_REG_EX],
    fieldRulesValue: [true, true, RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_NOT_ZERO,
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.applicant_participation_in_childcare_costs,
    classFieldName: "applicantParticipationInChildcareCosts",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX, ValidatorRules.FIELD_NUMBER_MAX],
    fieldRulesValue: [
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15,
      new FieldRuleValue([`childcareCosts`], FIELD_RULE_VALUE_OPERATORS.PLUS, true)
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR,
      ValidatorErrorMessage.FIELD_NUMBER_TO_BIG
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.childcare_amount_of_funding,
    classFieldName: "childcareAmountOfFunding",
    nestedArrayFieldName: true,
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX, ValidatorRules.FIELD_NUMBER_MAX],
    fieldRulesValue: [
      RegExValidator.NUMBER_2_DECIMAL_NOT_ALLOW_NEGATIVE_15,
      new FieldRuleValue([`childcareCosts`], FIELD_RULE_VALUE_OPERATORS.PLUS, true)
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_DECIMAL_NON_NEGATIVE_15CHAR,
      ValidatorErrorMessage.FIELD_NUMBER_TO_BIG
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.parents_didnt_live_with_child_dates_lower,
    classFieldName: "parentsDidntLiveWithChildDates",
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`parentsDidntLiveWithChildDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.parents_didnt_live_with_child_dates_upper,
    classFieldName: "parentsDidntLiveWithChildDates",
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`parentsDidntLiveWithChildDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.child_lived_with_applicant_dates_lower,
    classFieldName: "childLivedWithApplicantDates",
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL,
      ValidatorRules.FIELD_EMP_DATE_IS_NOT_IN_RANGE
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`childLivedWithApplicantDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`],
        null,
        true
      ),
      new FieldRuleValue(
        [
          `childLivedWithSecondParentDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`,
          `childLivedWithSecondParentDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`
        ],
        FIELD_RULE_VALUE_OPERATORS.ARRAY,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN,
      ValidatorErrorMessage.FIELD_EMP_DATE_SHOULD_BE_DIFFERENT_FROM_NEXT_DATE_RANGE
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.child_lived_with_applicant_dates_upper,
    classFieldName: "childLivedWithApplicantDates",
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL,
      ValidatorRules.FIELD_EMP_DATE_IS_NOT_IN_RANGE
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`childLivedWithApplicantDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`],
        null,
        true
      ),
      new FieldRuleValue(
        [
          `childLivedWithSecondParentDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`,
          `childLivedWithSecondParentDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`
        ],
        FIELD_RULE_VALUE_OPERATORS.ARRAY,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN,
      ValidatorErrorMessage.FIELD_EMP_DATE_SHOULD_BE_DIFFERENT_FROM_NEXT_DATE_RANGE
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.child_lived_with_second_parent_dates_lower,
    classFieldName: "childLivedWithSecondParentDates",
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL,
      ValidatorRules.FIELD_EMP_DATE_IS_NOT_IN_RANGE
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`childLivedWithSecondParentDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`],
        null,
        true
      ),
      new FieldRuleValue(
        [
          `childLivedWithApplicantDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`,
          `childLivedWithApplicantDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`
        ],
        FIELD_RULE_VALUE_OPERATORS.ARRAY,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN,
      ValidatorErrorMessage.FIELD_EMP_DATE_SHOULD_BE_DIFFERENT_FROM_PREV_DATE_RANGE
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.child_lived_with_second_parent_dates_upper,
    classFieldName: "childLivedWithSecondParentDates",
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL,
      ValidatorRules.FIELD_EMP_DATE_IS_NOT_IN_RANGE
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`childLivedWithSecondParentDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`],
        null,
        true
      ),
      new FieldRuleValue(
        [
          `childLivedWithApplicantDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`,
          `childLivedWithApplicantDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`
        ],
        FIELD_RULE_VALUE_OPERATORS.ARRAY,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN,
      ValidatorErrorMessage.FIELD_EMP_DATE_SHOULD_BE_DIFFERENT_FROM_PREV_DATE_RANGE
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.payments_for_childcare_dates_lower,
    classFieldName: "paymentsForChildcareDates",
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`paymentsForChildcareDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.payments_for_childcare_dates_upper,
    classFieldName: "paymentsForChildcareDates",
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`paymentsForChildcareDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.not_registered_dates_lower,
    classFieldName: "notRegisteredDates",
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`notRegisteredDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true)
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.not_registered_dates_upper,
    classFieldName: "notRegisteredDates",
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`notRegisteredDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true)
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.registered_in_applicants_flat_dates_lower,
    classFieldName: "registeredInApplicantsFlatDates",
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`registeredInApplicantsFlatDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.registered_in_applicants_flat_dates_upper,
    classFieldName: "registeredInApplicantsFlatDates",
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`registeredInApplicantsFlatDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  // kindergeld_dates
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.kindergeld_dates_lower,
    classFieldName: "kindergeldDates",
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`kindergeldDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`], null, true)
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.kindergeld_dates_upper,
    classFieldName: "kindergeldDates",
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue([`kindergeldDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`], null, true)
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.other_adults_registered_dates_lower,
    classFieldName: "otherAdultsRegisteredDates",
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`otherAdultsRegisteredDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.other_adults_registered_dates_upper,
    classFieldName: "otherAdultsRegisteredDates",
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`otherAdultsRegisteredDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.common_household_dates_lower,
    classFieldName: "commonHouseholdDates",
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`commonHouseholdDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.upper`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.common_household_dates_upper,
    classFieldName: "commonHouseholdDates",
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`commonHouseholdDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.common_household_person_name,
    classFieldName: "commonHouseholdPersonName",
    nestedArrayFieldName: FIELD_OPERATOR_HELPER.NESTED_ARRAY_IN_NESTED_ARRAY,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 1, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.common_household_person_propinquity,
    classFieldName: "commonHouseholdPersonPropinquity",
    nestedArrayFieldName: FIELD_OPERATOR_HELPER.NESTED_ARRAY_IN_NESTED_ARRAY,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 1, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.common_household_person_profession,
    classFieldName: "commonHouseholdPersonProfession",
    nestedArrayFieldName: FIELD_OPERATOR_HELPER.NESTED_ARRAY_IN_NESTED_ARRAY,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 1, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.place_of_residence,
    classFieldName: "placeOfResidence",
    nestedArrayFieldName: FIELD_OPERATOR_HELPER.NESTED_ARRAY_IN_NESTED_ARRAY,
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_LENGTH_MIN,
      ValidatorRules.FIELD_LENGTH_MAX,
      ValidatorRules.FIELD_IS_REG_EX
    ],
    fieldRulesValue: [true, 1, 999, RegExValidator.ALLOWED_CHARACTERS_ERIC],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_LENGTH_TO_SHORT,
      ValidatorErrorMessage.FIELD_LENGTH_TO_LONG,
      ValidatorErrorMessage.FIELD_WITHOUT_SPECIAL_CHARACTERS
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.studied_outside_place_of_residence_dates_lower,
    classFieldName: "studiedOutsidePlaceOfResidenceDates",
    nestedArrayFieldName: "lower",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_LOWER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`studiedOutsidePlaceOfResidenceDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_LOWER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.studied_outside_place_of_residence_dates_upper,
    classFieldName: "studiedOutsidePlaceOfResidenceDates",
    nestedArrayFieldName: "upper",
    fieldRules: [
      ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED,
      ValidatorRules.FIELD_IS_DATE,
      ValidatorRules.FIELD_YEAR_IS_CURRENT,
      ValidatorRules.FIELD_EMP_DATE_IS_BIGGER_OR_EQUAL
    ],
    fieldRulesValue: [
      true,
      true,
      true,
      new FieldRuleValue(
        [`studiedOutsidePlaceOfResidenceDates.${FIELD_OPERATOR_HELPER.FIELD_NAME_INDEX_TO_REPLACE}.lower`],
        null,
        true
      )
    ],
    fieldErrorMessage: [
      ValidatorErrorMessage.FIELD_IS_REQUIRED,
      ValidatorErrorMessage.FIELD_IS_DATE,
      ValidatorErrorMessage.FIELD_YEAR_IS_CURRENT,
      ValidatorErrorMessage.FIELD_EMP_DATE_IS_BIGGER_THAN
    ]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.participation_in_studies_allowance,
    classFieldName: "participationInStudiesAllowance",
    fieldRules: [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED, ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ],
    fieldRulesValue: [true, [1, 100]],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ],
    fieldErrorMessageArguments: [null, null, { from: 1, to: 100 }]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.disability_level,
    classFieldName: "disabilityLevel",
    fieldRules: [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS.participation_in_disability_allowance,
    classFieldName: "participationInDisabilityAllowance",
    fieldRules: [ValidatorRules.IS_REQUIRED_IF_NOT_UNDEFINED, ValidatorRules.FIELD_IS_INTEGER_IN_RANGE_EQ],
    fieldRulesValue: [true, [1, 100]],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_IS_INTEGER_IN_RANGE_EQ],
    fieldErrorMessageArguments: [null, null, { from: 1, to: 100 }]
  }
];

export const VALIDATION_FIELD_KEYS_FOR_EMPLOYER = [
  ["ib_date_from", "workDateStart"],
  ["ib_date_to", "workDateEnd"],
  ["ib_period_lower", "periodOfEmployment.lower", "periodOfEmployment.upper"],
  ["ib_period_upper", "periodOfEmployment.upper", "periodOfEmployment.lower"],
  ["ib03"],
  ["ib04"],
  ["ib05"],
  ["ib06"],
  ["ib07"],
  ["ib15"],
  ["ib17"],
  ["ib18"],
  ["ib19"],
  ["ib20"],
  ["ib21"],
  ["ib22a"],
  ["ib23a"],
  ["ib24a"],
  ["ib24b"],
  ["ib24c"],
  ["ib25"],
  ["ib26"],
  ["ib27"],
  ["emp_taxClass", "taxClass"]
];

export const VALIDATION_FIELD_KEYS_FOR_ADDITIONAL_INCOME = [
  ["additional_income_social_value", "amount"],
  ["additional_income_social_name", "incomeType"],
  ["additional_income_social_from_date", "periodOfIncome.lower"],
  ["additional_income_social_to_date", "periodOfIncome.upper"]
];

export const VALIDATION_FIELD_KEYS_FOR_FREELANCE_JOB = [
  ["additional_icome_freelance_name", "name"],
  ["additional_income_freelance_value", "income"]
];

export const VALIDATION_FIELD_KEYS_FOR_CRYPTOCURRENCY_INCOME = [
  ["additional_income_cryptocurrency_value", "incomeNet"]
];

export const VALIDATION_FIELD_KEYS_FOR_PENSION_INCOME = [
  ["additional_income_pension_from", "pensionFrom"],
  ["additional_income_pension_amount", "pensionAmount"],
  ["additional_income_pension_start", "pensionStart"],
  ["additional_income_pension_adjustment", "pensionAdjustment"]
];
