import { Component, Input, TemplateRef} from '@angular/core';


@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitle {
  @Input() title: String = '';
  @Input() titleTooltip: String = '';
  @Input() message: String = '';
  @Input() tooltipContentRef: TemplateRef<any>= null;
  @Input() isInnerHTML: String = ''
  
  constructor(){

  }

  public displayTooltip() : boolean{
    return !!this.titleTooltip || !!this.message || !!this.tooltipContentRef
  }
}
