<div class="dialog-box">
  <div class="dialog-box__header">
    <button mat-icon-button mat-dialog-close="" tabindex="-1">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>
  </div>

  <div class="dialog-box__content">
    <div class="title">{{ 'MENU.CHANGE_PASSWORD' | translate }}</div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <input class="d-none" [value]="email" autocomplete="username"/>
      <div class="form-group mb-1">
        <app-form-input-reactive
          [autofocus]="true"
          [inputType]="'password'"
          [label]="'INPUT.OLD_PASSWORD.LABEL'"
          [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
          [control]="formCurrentPassword"
          [errors]="formError.currentPassword"
          [autocomplete]="'current-password'"
          (modelChange)="clearErrors()"
          [attr.data-cy]="'currentPassword'"
        >
        </app-form-input-reactive>
      </div>
      <div class="form-group mb-1">
        <app-form-input-reactive
          [inputType]="'password'"
          [label]="'INPUT.NEW_PASSWORD.LABEL'"
          [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
          [control]="formNewPassword"
          [errors]="formError.newPassword"
          [autocomplete]="'new-password'"
          (modelChange)="clearErrors()"
          [attr.data-cy]="'newPassword'"
        >
        </app-form-input-reactive>
      </div>
      <div class="form-group mb-1_5">
        <app-form-input-reactive
          [inputType]="'password'"
          [label]="'INPUT.NEW_PASSWORD_REPEAT.LABEL'"
          [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
          [control]="formNewPasswordRepeat"
          [errors]="formError.newPasswordRepeat"
          [autocomplete]="'new-password'"
          (modelChange)="clearErrors()"
          [attr.data-cy]="'newPasswordRepeat'"
        >
        </app-form-input-reactive>
      </div>
      <div>
        <div class="alert-box" *ngIf="errors?.non_field_errors?.length">
          {{ errors.non_field_errors[0] }}
        </div>
        <div class="alert-box" *ngIf="errors?.non_field_errors?.length">{{ errors.non_field_errors[0] }}</div>
        <div class="alert-box" *ngIf="errors?.uid?.length">{{ errors.uid[0] }}</div>
        <div class="alert-box" *ngIf="errors?.token?.length">{{ errors.token[0] }}</div>
      </div>
      <div class="d-flex justify-content-end">
        <app-button
          [class]="'mr-0_5 flex-grow-1'"
          (clickEmitter)="closeDialog()"
        >
          {{ 'COMMON.CANCEL' | translate }}
        </app-button>
        <app-button-primary
          [class]="'flex-grow-1'"
          [buttonType]="'submit'"
          [preventDefault]="false"
          [isLoading]="isFormLoading"
          [isDisabled]="isFormLoading || !email"
        >
          {{ 'COMMON.SAVE' | translate }}
        </app-button-primary>
      </div>
      <div class="d-flex justify-content-center logo mt-3 mb-2">
        <img src="../../../../assets/svg/logo.svg" alt="taxando" style="max-width: 175px;">
      </div>
    </form>
  </div>
</div>
