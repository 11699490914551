<button
  mat-button
  class="button button-del button-icon {{ extraClasses }}"
  [class.button-icon-text]="withText"
  [class.is-loading]="isLoading"
  [disabled]="isDisabled"
  (click)="clickEvent($event)"
  type="button"
  [attr.tabIndex]="autoFocus === false ? -1 : undefined"
>
  <img src="assets/svg/del_icon.svg" alt="delete" />
  <!-- <span *ngIf="withText">{{ title | translate }}</span> -->
</button>
