import { takeUntil, finalize } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { Statement } from '../statement';
import { StatementService } from '../statement.service';
import { SubscribingComponent} from '../../common';
import { RefundDetails } from '../refund-details';
import { SelectsData, SelectsDataInterface } from '../selects-data';
import { State, getSelectsData, getUser } from '../../reducers';
import { Store } from '@ngrx/store';
import { Currency } from '../currency';
import { AbroadIncome } from '../abroad-income';
import { User } from '../../users';
import { AuthenticationDialogFlag, LoginDialogComponent } from '../../core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-refund-dialog',
  templateUrl: './refund-dialog.component.html',
  styleUrls: ['./refund-dialog.component.scss']
})
export class RefundDialogComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  @Input() refundDetails?: RefundDetails;
  public isLogged = false;
  dataSelects: SelectsDataInterface = new SelectsData();
  currencyCode = '';
  initialFiledTogether = false;
  showApplyForResidencyStatusNotification = false;
  spouseCurrencyCode = '';
  private isRefundDetailsLoading = false;
  private isStatementLoading = false;
  private isDataSelectsLoading = false;

  constructor(
    private statementService: StatementService,
    private store: Store<State>,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    if (!this.statement.abroadIncome) {
      this.statement.abroadIncome = new AbroadIncome();
    }

    this.store.select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.isLogged = !!user;
      });

    this.isDataSelectsLoading = true;
    this.store.select(getSelectsData).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => { }))
      .subscribe((selectsData: SelectsDataInterface) => {
        this.dataSelects = selectsData;
        if (this.dataSelects) {
          this.isDataSelectsLoading = false;
          this.statement.setDefaultCurrency(this.dataSelects);
          this.setCurrencyCode();
          this.setSpouseCurrencyCode();
        }
      });

    if (!this.refundDetails && this.statement && this.statement.id) {
      this.isRefundDetailsLoading = true;
      this.statementService.getRefundDetails(this.statement.id).pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe((refundDetails: RefundDetails) => {
          this.isRefundDetailsLoading = false;
          this.refundDetails = refundDetails;
        });
    }

    if (this.statement && this.statement.id && !this.statement.isFull) {
      this.isStatementLoading = true;
      this.statementService.getStatement(this.statement.id).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Statement) => {
          this.isStatementLoading = false;
          Object.assign(this.statement, response);
          this.initialFiledTogether = this.statement.filedTogether;
        });
    } else {
      if (this.statement) {
        this.initialFiledTogether = this.statement.filedTogether;
      }
    }
    this.initialFiledTogether = this.statement.filedTogether;

  }

  public get isLoading(): boolean {
    return this.isRefundDetailsLoading || this.isStatementLoading || this.isDataSelectsLoading;
  }

  public get isLoaded(): boolean {
    return !this.isLoading;
  }

  public openRegisterModal() {
    this.dialog.open(LoginDialogComponent, {
      data: { loginFlag: AuthenticationDialogFlag.REGISTRATION},
      panelClass: ['login_dialog_wrap', 'new-dialog-wrap'],
      backdropClass: 'backdropBackgroundWhite'
    });
  }

  public isOverMax(): boolean {
    if (
      this.refundDetails.applicant.sonstigeVorsorgeaufwendungen + this.refundDetails.spouse.sonstigeVorsorgeaufwendungen > this.refundDetails.applicant.max_sonstige_vorsorgeaufwendungen
    ) {
      this.refundDetails.applicant.sonstigeVorsorgeaufwendungen = 0;
      this.refundDetails.spouse.sonstigeVorsorgeaufwendungen = 0;
      return true;
    }

    return false;
  }

  public hasAbroadIncome(): boolean {
    return !!(this.statement
      && (
        Number(this.statement.abroadIncome.abroadIncome)
        || Number(this.statement.abroadIncome.spouseAbroadIncome)
      ));
  }

  public canApplyForTaxResidencyStatus(): boolean {
    if (this.initialFiledTogether !== this.statement.filedTogether) {
      this.showApplyForResidencyStatusNotification = true;
    }

    return this.statement.check90ConditionOrSmallAbroadIncome(this.dataSelects)
      || this.statement.employerDateRangeStatus
      || this.statement.abroadIncome.liveInGermany;
  }

  setCurrencyCode(): void {
    const currency = Currency.findById(this.dataSelects.currencies, this.statement.abroadIncome.abroadIncomeCurrency);
    this.currencyCode = currency ? currency.code : this.currencyCode;
  }

  setSpouseCurrencyCode(): void {
    const currency = Currency.findById(this.dataSelects.currencies, this.statement.abroadIncome.spouseAbroadIncomeCurrency);
    this.spouseCurrencyCode = currency ? currency.code : this.spouseCurrencyCode;
  }

  public getBehindertenPauschbetrag(): boolean {
    return !!(this.refundDetails && this.refundDetails.behindertenPauschbetrag);
  }

  public getGesundheitskosten(): boolean {
    return !!(this.refundDetails && this.refundDetails.gesundheitskosten);
  }
}
