import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./core";
import { AuthGuard } from "app/users";
import { DashboardComponent } from "app/dashboard";
import { ChooseProfileComponent } from "./statements/choose-profile/choose-profile.component";
import { PersonalInfoComponent } from "./statements/personal-info/personal-info.component";
import { ThankYouComponent } from "./statements/thank-you/thank-you.component";
import { ClientDetailsComponent } from "./batches/client-details/client-details.component";
import { CongratulationsComponent } from "./statements/congratulations/congratulations.component";
import { PaymentCheckoutComponent } from "./statements/payment-checkout/payment-checkout.component";
import { ClientSummaryComponent } from "./batches/client-summary/client-summary.component";
import { ThankYouBusinessComponent } from "./batches/thank-you-business/thank-you-business.component";
import { CongratulationsBusinessComponent } from "./batches/congratulations-business/congratulations-business.component";
import { PaymentAbortComponent } from "./payment/payment-abort/payment-abort.component";
import { PaymentTimeOutComponent } from "./payment/payment-time-out/payment-time-out.component";
import { PackageSelectionComponent } from "./statements/package-selection/package-selection.component";
import { DownloadStatementComponent } from "./statements/download-statement/download-statement.component";
import { FinishComponent } from "./statements/finish/finish.component";
import { VollmachtAuthorizationComponent } from "./statements/vollmacht-authorization/vollmacht-authorization.component";
import { ActivationComponent } from "./core/activation/activation.component";
import { AdditionalIncomeOptionsComponent } from "./statements/additional-income-options/additional-income-options.component";
import { BankDetailComponent } from "./statements/bank-detail/bank-detail.component";
import { TaxAdvisorAdditionalValidationComponent } from "./statements/tax-advisor-additional-validation/tax-advisor-additional-validation.component";
import { ConfirmTaxReturnComponent } from "./statements/confirm-tax-return/confirm-tax-return.component";
import { B2bAbroadIncomeInfoComponent } from "./statements/b2b-abroad-income-info/b2b-abroad-income-info.component";
import { TaxCardComponentPath } from "app/core/workflow/page-path/statement-page-path/tax-card-component.path";
import { B2bTaxCardComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.tax-card-component.path";
import { TaxCardComponent } from "./statements/tax-card/tax-card.component";
import { TaxCardSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/tax-card-spouse-component.path";
import { B2bTaxCardSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.tax-card-spouse-component.path";
import { BankDetailComponentPath } from "app/core/workflow/page-path/statement-page-path/bank-detail-component.path";
import { B2bAbroadIncomeComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.abroad-income-component.path";
import { AdditionalIncomeSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/additional-income-spouse-component.path";
import { PersonalInfoComponentPath } from "app/core/workflow/page-path/statement-page-path/personal-info-component.path";
import { PersonalInfoSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/personal-info-spouse-component.path";
import { PaymentExecutionComponent } from "./payment/payment-execution/payment-execution.component";
import { PaymentConfirmationComponent } from "./payment/payment-confirmation/payment-confirmation.component";
import { PaymentHistoryComponent } from "./payment/payment-history/payment-history.component";
import { PackageListComponent } from "./payment/package-list/package-list.component";
import { PaymentCheckoutComponentPath } from "app/core/workflow/page-path/statement-page-path/payment-checkout-component.path";
import { PackageSelectionComponentPath } from "app/core/workflow/page-path/statement-page-path/package-selection-component.path";
import { PhoneVerificationComponentPath } from "app/core/workflow/page-path/statement-page-path/phone-verification-component.path";
import { VollmachtAuthorizationComponentPath } from "app/core/workflow/page-path/statement-page-path/vollmacht-authorization-component.path";
import { ThankYouComponentPath } from "app/core/workflow/page-path/statement-page-path/thank-you-component.path";
import { B2cAdditionalIncomePage } from "app/modules/statement/pages/b2c.additional-income/b2c.additional-income.page";
import { NgModule } from "@angular/core";
import { FinishPagePath } from "app/core/workflow/page-path/statement-page-path/finish-page.path";
import { DashboardPagePath } from "app/core/workflow/page-path/dashboard-page.path";
import { ActivatePagePath } from "app/core/workflow/page-path/activate-page.path";
import { StarterBoardPage } from "app/core/pages/starter-board-page/starter-board.page";
import { RateAppPagePath } from "app/core/workflow/page-path/rate-app-page.path";
import { DeductionSkipComponentPath } from "app/core/workflow/page-path/statement-page-path/deduction-skip-component.path";
import { DeductionInfoSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/deduction-info-spouse-component.path";
import { DeductionInfoChildComponentPath } from "app/core/workflow/page-path/statement-page-path/deduction-info-child-component.path";
import { DeductionInfoComponentPath } from "app/core/workflow/page-path/statement-page-path/deduction-info-component.path";
import { B2bDeductionInfoComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.deduction-info-component.path";
import { B2bDeductionInfoSpouseComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.deduction-info-spouse-component.path";
import { B2bDeductionInfoChildComponentPath } from "app/core/workflow/page-path/statement-page-path/b2b.deduction-info-child-component.path";
import { TermsAndConditionsPagePath } from "app/core/workflow/page-path/terms-and-conditions-page.path";
import { PrivacyPolicyPagePath } from "app/core/workflow/page-path/privacy-policy-page.path";
import { CouponsPagePath } from "./core/workflow/page-path/coupons.path";
import { ThankYouRedirectComponent } from "./standalone-pages/thank-you-redirect/thank-you-redirect.component";
const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "new",
    pathMatch: "full"
  },
  {
    path: RateAppPagePath.path(),
    loadChildren: () => import("app/modules/rateapp/rateapp.module").then((m) => m.RateappModule)
  },
  {
    path: "disable-analytics",
    loadChildren: () =>
      import("app/modules/disable-analytics/disable-analytics.module").then((m) => m.DisableAnalyticsModule)
  },
  {
    path: PrivacyPolicyPagePath.path(),
    loadComponent: () =>
      import("app/standalone-pages/privacy-policy/privacy-policy.component").then((c) => c.PrivacyPolicyComponent)
  },
  {
    path: CouponsPagePath.path(),
    loadComponent: () => import("app/standalone-pages/coupons/coupons.page").then((c) => c.CouponsPage)
  },
  {
    path: TermsAndConditionsPagePath.path(),
    loadComponent: () =>
      import("app/standalone-pages/terms-and-conditions/terms-and-conditions.component").then(
        (c) => c.TermsAndConditionsComponent
      )
  },
  {
    path: "new",
    component: HomeComponent,
    children: [
      {
        path: "",
        component: StarterBoardPage
      },
      {
        path: "statement/:statementId/processing-standard",
        component: ThankYouRedirectComponent
      },
      {
        path: "statement/:statementId/processing-free",
        component: ThankYouRedirectComponent
      },
      {
        path: "statement/:statementId/processing-selfemployment",
        component: ThankYouRedirectComponent
      },
      {
        path: "statement/:statementId/processing-selfemployments",
        component: ThankYouRedirectComponent
      },
      {
        path: "statement/:statementId/processing-premium",
        component: ThankYouRedirectComponent
      },
      {
        path: "statement/:statementId/processing-selfemployment-premium",
        component: ThankYouRedirectComponent
      },
      {
        path: B2bTaxCardComponentPath.path(),
        loadComponent: () =>
          import("app/standalone-pages/b2b-tax-card/b2b.tax-card.page").then((c) => c.B2bTaxCardPage),
        canActivate: [AuthGuard]
      },
      {
        path: B2bTaxCardSpouseComponentPath.path(),
        loadComponent: () =>
          import("app/standalone-pages/b2b-tax-card/b2b.tax-card.page").then((c) => c.B2bTaxCardPage),
        canActivate: [AuthGuard]
      },
      {
        path: DeductionSkipComponentPath.path(),
        loadComponent: () =>
          import("app/standalone-pages/b2c.deduction-skip/b2c.deduction-skip.page").then((c) => c.B2cDeductionSkipPage),
        canActivate: [AuthGuard]
      },
      {
        path: DeductionInfoSpouseComponentPath.path(),
        loadComponent: () =>
          import("app/standalone-pages/b2c.deduction-info/b2c.deduction-info.page").then((c) => c.B2cDeductionInfoPage),
        canActivate: [AuthGuard]
      },
      {
        path: DeductionInfoChildComponentPath.path(),
        loadComponent: () =>
          import("app/standalone-pages/b2c.deduction-info/b2c.deduction-info.page").then((c) => c.B2cDeductionInfoPage),
        canActivate: [AuthGuard]
      },
      {
        path: DeductionInfoComponentPath.path(),
        loadComponent: () =>
          import("app/standalone-pages/b2c.deduction-info/b2c.deduction-info.page").then((c) => c.B2cDeductionInfoPage),
        canActivate: [AuthGuard]
      },
      {
        path: B2bDeductionInfoComponentPath.path(),
        loadComponent: () =>
          import("app/standalone-pages/b2b.deduction-info/b2b.deduction-info.page").then((c) => c.B2bDeductionInfoPage),
        canActivate: [AuthGuard]
      },
      {
        path: B2bDeductionInfoSpouseComponentPath.path(),
        loadComponent: () =>
          import("app/standalone-pages/b2b.deduction-info/b2b.deduction-info.page").then((c) => c.B2bDeductionInfoPage),
        canActivate: [AuthGuard]
      },
      {
        path: B2bDeductionInfoChildComponentPath.path(),
        loadComponent: () =>
          import("app/standalone-pages/b2b.deduction-child-info/b2b.deduction-child-info.page").then(
            (c) => c.B2bDeductionChildInfoPage
          ),
        canActivate: [AuthGuard]
      },
      {
        path: TaxCardComponentPath.path(),
        component: TaxCardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: TaxCardSpouseComponentPath.path(),
        component: TaxCardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: B2bAbroadIncomeComponentPath.path(),
        component: B2bAbroadIncomeInfoComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "statement/:statementId/profile",
        component: ChooseProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "",
        loadChildren: () => import("app/modules/statement/statement.module").then((m) => m.StatementModule),
        canActivate: [AuthGuard]
      },
      {
        path: PersonalInfoComponentPath.path(),
        component: PersonalInfoComponent,
        canActivate: [AuthGuard]
      },
      {
        path: AdditionalIncomeSpouseComponentPath.path(),
        component: B2cAdditionalIncomePage,
        canActivate: [AuthGuard]
      },
      {
        path: PersonalInfoSpouseComponentPath.path(),
        component: PersonalInfoComponent,
        canActivate: [AuthGuard]
      },
      {
        path: BankDetailComponentPath.path(),
        component: BankDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: PackageSelectionComponentPath.path(),
        component: PackageSelectionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: PhoneVerificationComponentPath.path(),
        loadComponent: () =>
          import("app/standalone-pages/phone-verification/phone-verification.component").then(
            (c) => c.PhoneVerificationComponent
          ),
        canActivate: [AuthGuard]
      },
      {
        path: ThankYouComponentPath.pathWithHash(),
        component: ThankYouComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ThankYouComponentPath.path(),
        component: ThankYouComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "statement/:statementId/confirm-tax-return",
        component: ConfirmTaxReturnComponent,
        canActivate: [AuthGuard]
      },
      {
        path: PaymentCheckoutComponentPath.path(),
        component: PaymentCheckoutComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "statement/:statementId/congratulations",
        component: CongratulationsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "statement/:statementId/payment-execute",
        component: PaymentExecutionComponent
      },
      {
        path: FinishPagePath.path(),
        component: FinishComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "batch/:batchId/client-details",
        component: ClientDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "batch/:batchId/client-summary",
        component: ClientSummaryComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "batch/:batchId/payment-execute",
        component: PaymentExecutionComponent
      },
      {
        path: "batch/:batchId/thank-you",
        component: ThankYouBusinessComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "batch/:batchId/congratulations",
        component: CongratulationsBusinessComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "statement/:statementId/download-statement",
        component: DownloadStatementComponent,
        canActivate: [AuthGuard]
      },
      {
        path: VollmachtAuthorizationComponentPath.path(),
        component: VollmachtAuthorizationComponent
      },
      {
        path: "statement/:statementId/tax-advisor-additional-validation",
        component: TaxAdvisorAdditionalValidationComponent
      },
      {
        path: "statement/:statementId/additional-options",
        component: AdditionalIncomeOptionsComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: "login",
    component: HomeComponent,
    children: [
      {
        path: "",
        component: StarterBoardPage,
        data: {
          openedDialog: "login"
        }
      }
    ]
  },
  {
    path: "register",
    component: HomeComponent,
    children: [
      {
        path: "",
        component: StarterBoardPage,
        data: {
          openedDialog: "register"
        }
      }
    ]
  },
  {
    path: "forgot-password",
    component: HomeComponent,
    children: [
      {
        path: "",
        component: StarterBoardPage,
        data: {
          openedDialog: "forgot"
        }
      }
    ]
  },
  {
    path: "",
    loadChildren: () => import("app/modules/user/user.module").then((m) => m.UserModule)
  },
  {
    path: "password/reset",
    loadChildren: () =>
      import("app/modules/user/modules/reset-password/reset-password.module").then((m) => m.ResetPasswordModule)
  },
  {
    path: DashboardPagePath.path(),
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "dashboard/:year/:yearid",
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "packages",
    component: PackageListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "payment-history",
    component: PaymentHistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ActivatePagePath.path(),
    component: ActivationComponent
  },
  // Without AuthGuard because user can be logged out when he is redirected:
  {
    path: "payment-confirmation",
    component: PaymentConfirmationComponent
  },
  {
    path: "payment-execute",
    component: PaymentExecutionComponent
  },
  {
    path: "payment-timeout",
    component: PaymentTimeOutComponent
  },
  {
    path: "payment-abort/:statementId",
    component: PaymentAbortComponent
  },
  {
    path: "payment-abort",
    component: PaymentAbortComponent
  },
  {
    path: "**",
    redirectTo: "/new"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
