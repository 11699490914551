import { finalize, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnChanges, SimpleChanges, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { State, getUserType } from '../../reducers';
import { SHOW_SNACKBAR } from '../../reducers/actions/snackbar';
import { UPDATE_REFUND_DETAILS } from '../../reducers/actions/refund-details';
import { Statement } from '../statement';
import { SubscribingComponent, SnackbarConfig } from '../../common';
import { StatementService } from '../statement.service';
import { BinaryDecision } from './../binary-decision';
import { HideViewSpinnerAction, ShowViewSpinnerAction } from 'app/reducers/actions/loader';
import { MatDialog } from '@angular/material/dialog';
import { FirebaseEventsService } from 'app/common/firebase-events.service';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';

export interface AddOptionsInterface {
  slug: string;
  icon: string;
  text: string;
  active: boolean;
}

@Component({
  selector: 'app-additional-income-options',
  templateUrl: './additional-income-options.component.html',
  styleUrls: ['./additional-income-options.component.scss']
})
export class AdditionalIncomeOptionsComponent extends SubscribingComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() isNested = false;
  @Input() additionalIncome = false;
  @Input() freelanceJobs = false;
  @Input() pensionIncome = false;
  @Input() cryptocurrencyIncome = false;
  @Input() nestedStatement: Statement;
  @Input() spouseErrors: any[] = [];
  @Input() amountExceededError: boolean = false;
  @Input() contentLoaded: boolean = false;

  @ViewChildren('tile') tiles: QueryList<AdditionalIncomeOptionsComponent>

  public errors: any[] = [];
  public statementId: number;
  public spouseId: number;
  public statement: Statement;
  public formStatement: Statement;
  public loadingStatementData = true;
  public dataSelects: BinaryDecision[];
  public isBusiness: boolean = false;

  public buttons = [
    {
      type: 'back',
      label: 'COMMON.BACK',
      action: this.goBack.bind(this)
    },
    {
      type: 'proceed',
      label: 'COMMON.SKIP',
      action: this.proceed.bind(this)
    }
  ];

  public addOptions: AddOptionsInterface[] = [];
  public tilesActiveCount: number = 0;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private firebaseEventsService: FirebaseEventsService,
    public workFlowController: WorkflowControllerService
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new ShowViewSpinnerAction());
    this.dataSelects = BinaryDecision.options;
    this.route.params.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.statementId = parseInt(params['statementId'], 10);
        this.spouseId = parseInt(params['spouseId'], 10);

        if (this.nestedStatement) {
          this.statement = this.nestedStatement;
          this.workFlowController.init(this.statement);
          this.formStatement = this.nestedStatement.spouse;
          this.loadingStatementData = false;
          this.store.dispatch(new HideViewSpinnerAction());
        } else {
          this.getStatementData(this.statementId);
        }

      });

    this.store.select(getUserType).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((userType: string) => {
        if (userType) {
          this.isBusiness = userType === 'business';
        }
      });

    this.setaddOptionsData();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngAfterViewInit() {
    this.tiles.changes
      .subscribe(() => {
        this.checkHeightOfTilesText();
      });
  }

  public goBack() {
    this.save();
  }

  public save() {
    this.updateStatementDataBeforeSave();
    this.updateStatement(false, true);
    // if (this.isAnyOptionSelected()) {
    //   let dialogRef = this.dialog.open(AdditionalOptionsSteuerAcceptComponent);
    //   dialogRef.afterClosed().subscribe(response => {
    //     this.updateStatement(_goBackUrl);
    //   });
    // } else {
    //   this.updateStatement(_goBackUrl);
    // }
  }

  public proceed() {
    
    
    this.firebaseEventsService.logEvent('click_add_extra_options', { extra_incomes: this.tilesActiveCount }, this.statementId)

    this.updateStatementDataBeforeSave();
    this.updateStatement(true);
    // if (this.isAnyOptionSelected()) {
    //   let dialogRef = this.dialog.open(AdditionalOptionsSteuerAcceptComponent);
    //   dialogRef.afterClosed().subscribe(response => {
    //     if (dialogRef.componentInstance.accepted) {
    //       this.updateStatement(redirectUrl);
    //     }
    //     this.updateStatement();
    //   });
    // } else {
    //   this.updateStatement(redirectUrl);
    // }
  }

  public selectAddOption(option: AddOptionsInterface) {
    option.active = !option.active;
    this.checkButtonsAndTiles();
  }

  public removeAddOption(option: AddOptionsInterface) {
    option.active = false;
  }

  updateStatement(goForward = false, goBack = false) {
    this.contentLoaded = false;
    this.errors = [];

    const toResponse = this.statement.extraIncomeOptionsPayload();

    this.statementService.updateStatement(this.statement.id, toResponse).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((response: Statement) => {
        Object.assign(this.statement, response);
        if (goForward) {
          this.workFlowController.goToTheNextStep();
        }
        if (goBack) {
          this.workFlowController.goToThePreviousStep();
        }
      }, (error: any) => {
        this.contentLoaded = true;
      });
  }

  private getStatementData(id: number) {
    this.loadingStatementData = true;
    this.statementService.getStatement(id).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => {
        this.store.dispatch(new HideViewSpinnerAction());
        this.loadingStatementData = false;
      }))
      .subscribe((response: Statement) => {
        this.statement = response;
        this.workFlowController.init(this.statement);
        this.setButtonsFromStatement();
        this.checkButtonsAndTiles();

        this.formStatement = this.spouseId ? this.statement.spouse : this.statement;
        this.contentLoaded = true;
      }, (error: Error) => {
        this.router.navigate(['/new']);
      });
  }

  private updateStatementDataBeforeSave() {
    this.addOptions.forEach(item => {
      if (item.slug === 'has_rental_income') {
        this.statement.hasRentalIncome = item.active;
      } else if (item.slug === 'has_cryptocurrency_income') {
        this.statement.hasCryptocurrencyIncome = item.active;
      } else if (item.slug === 'has_farming_income') {
        this.statement.hasFarmingIncome = item.active;
      } else if (item.slug === 'has_capital_income') {
        this.statement.hasCapitalIncome = item.active;
      } else if (item.slug === 'has_realestate_income') {
        this.statement.hasRealestateIncome = item.active;
      } else if (item.slug === 'pays_family_support') {
        this.statement.paysFamilySupport = item.active;
      } else if (item.slug === 'photovoltaic_installation_costs') {
        this.statement.photovoltaicInstallationCosts = item.active;
      }
    });
  }

  private setButtonsFromStatement() {
    this.addOptions.forEach(item => {
      if (item.slug === 'has_rental_income') {
        item.active = this.statement.hasRentalIncome;
      } else if (item.slug === 'has_cryptocurrency_income') {
        item.active = this.statement.hasCryptocurrencyIncome;
      } else if (item.slug === 'has_farming_income') {
        item.active = this.statement.hasFarmingIncome;
      } else if (item.slug === 'has_capital_income') {
        item.active = this.statement.hasCapitalIncome;
      } else if (item.slug === 'has_realestate_income') {
        item.active = this.statement.hasRealestateIncome;
      } else if (item.slug === 'pays_family_support') {
        item.active = this.statement.paysFamilySupport;
      } else if (item.slug === 'photovoltaic_installation_costs') {
        item.active = this.statement.photovoltaicInstallationCosts;
      }
    });
  }

  private checkButtonsAndTiles() {
    this.tilesActiveCount = this.addOptions.filter(kk => kk.active).length;
    if (this.addOptions.filter(kk => kk.active).length > 0) {
      this.buttons = [
        {
          type: 'back',
          label: 'COMMON.BACK',
          action: this.goBack.bind(this)
        },
        // {
        //   type: 'save',
        //   label: 'COMMON.SAVE',
        //   action: this.save.bind(this)
        // },
        {
          type: 'proceed',
          label: 'COMMON.PROCEED',
          action: this.proceed.bind(this)
        }
      ];
    } else {
      this.buttons = [
        {
          type: 'back',
          label: 'COMMON.BACK',
          action: this.goBack.bind(this)
        },
        // {
        //   type: 'save',
        //   label: 'COMMON.SAVE',
        //   action: this.save.bind(this)
        // },
        {
          type: 'skip',
          label: 'COMMON.SKIP',
          action: this.proceed.bind(this)
        }
      ];
    }
  }

  private checkHeightOfTilesText() {
    this.tiles.forEach((item: any) => {
      let itemText: any = item.nativeElement.querySelector('.title_tile_sm');
      let itemImg: any = item.nativeElement.querySelector('.icon_tile_wrap');
      if (itemText && itemText.clientHeight > 65) {
        if (itemImg)
          itemImg.classList.add('sm_m');
      }
    });
  }

  private setaddOptionsData() {
    this.addOptions = [
      {
        slug: 'has_rental_income',
        icon: 'icon_1_dodatkowe.png',
        text: 'ADDITIONAL_INCOME_OPTIONS.OPTIONS.OPTION_1',
        active: false
      },
      {
        slug: 'has_cryptocurrency_income',
        icon: 'icon_2_dodatkowe.png',
        text: 'ADDITIONAL_INCOME_OPTIONS.OPTIONS.OPTION_2',
        active: false
      },
      // {
      //   slug: 'has_farming_income',
      //   icon: 'icon_3_dodatkowe.png',
      //   text: 'ADDITIONAL_INCOME_OPTIONS.OPTIONS.OPTION_3',
      //   active: false
      // },
      // {
      //   slug: 'has_capital_income',
      //   icon: 'icon_4_dodatkowe.png',
      //   text: 'ADDITIONAL_INCOME_OPTIONS.OPTIONS.OPTION_4',
      //   active: false
      // },
      // {
      //   slug: 'has_realestate_income',
      //   icon: 'icon_5_dodatkowe.png',
      //   text: 'ADDITIONAL_INCOME_OPTIONS.OPTIONS.OPTION_5',
      //   active: false
      // },
      // {
      //   slug: 'pays_family_support',
      //   icon: 'icon_7_dodatkowe.png',
      //   text: 'ADDITIONAL_INCOME_OPTIONS.OPTIONS.OPTION_6',
      //   active: false
      // },
      // {
      //   slug: 'photovoltaic_installation_costs',
      //   icon: 'icon_6_dodatkowe.png',
      //   text: 'ADDITIONAL_INCOME_OPTIONS.OPTIONS.OPTION_7',
      //   active: false
      // },
    ];
  }
}
