import { Component, Input } from "@angular/core";

@Component({
  selector: "app-section-title-h3",
  templateUrl: "./section-title-h3.component.html",
  styleUrls: ["./section-title-h3.component.scss"]
})
export class SectionTitleH3Component {
  @Input() title: string;
  @Input() titleTooltip: string;
  @Input() messageTooltip: string;
}
