import { Component } from '@angular/core';
import { CtrBaseComponent } from '../ctr-base.component';

@Component({
    ...CtrBaseComponent.metaData,
    selector: 'app-ctr-deductions',
    templateUrl: './ctr-deductions.component.html',
    styleUrls: ['../ctr-base.component.scss'],
})
export class CtrDeductionsComponent extends CtrBaseComponent {
}
