import { Component, Input, TemplateRef } from "@angular/core";
import { NumberFormatHelper } from "../../core/helpers/number-format.helper";

@Component({
  selector: "app-more-info-tooltip-dialog",
  templateUrl: "./more-info-tooltip-dialog.component.html",
  styleUrls: ["./more-info-tooltip-dialog.component.scss"]
})
export class MoreInfoTooltipDialogComponent {
  @Input() contentTemplateRef: TemplateRef<any>;
  @Input() showCustomTemplateView: boolean;
  @Input() titleTooltip = "";
  @Input() descTooltip = "";
  @Input() isInnerHTML = false;
  @Input() isPackageInfo = false;
  @Input() isPremiumPackage = false;
  @Input() isStandardPackage = false;
  @Input() isPackageBenefits = false;
  @Input() isHowDoesItWork = false;
  @Input() standardAmount: string | number;
  @Input() premiumAmount: string | number;
  @Input() currencySymbol: string;
  @Input() confirmButtonText = "";
  @Input() confirmButtonTitle = "";
  @Input() confirmButtonBottomText = "";
  @Input() tooltipAutoShow = false;

  public numberFormatHelper = NumberFormatHelper;
}
