<div class="title_w_tooltip mb-3_125">
  <h2 class="title_42 mb-0_625">
    {{ title }}
  </h2>
  <p class="subdesc">
    {{ desc }}
  </p>
</div>
<div class="card card-white shadow-0 d-flex flex-wrap mb-2">
  <div class="mr-3 mb-1">
    <p class="font-size-22">{{ 'CONFIRM_TAX_RETURN.TAX_YEAR' | translate }}</p>
    <p class="font-weight-bold text-primary font-size-20" data-cy="statementYear">{{ statement.fullYear }}</p>
  </div>
  <div class="mr-3 mb-1">
    <p class="font-size-22">{{ 'CONFIRM_TAX_RETURN.NAME' | translate }}</p>
    <p class="font-weight-bold font-size-20" data-cy="applicantName">{{ statement.firstName }} {{ statement.lastName }}</p>
  </div>
  <div class="mr-3 mb-1">
    <p class="font-size-22">{{ 'CONFIRM_TAX_RETURN.CURRENT_STATUS' | translate }}</p>
    <p class="font-weight-bold font-size-20" data-cy="statementStatus">{{ ('DASHBOARD.STATUSES.' + statement.dashboardStatus) | translate }}</p>
  </div>
</div>
