import { Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BoolValuePopupConfirm } from '../bool-value-popup-confirm/bool-value-popup-confirm';

export enum CHOOSER_TYPE {
  DISPLAY_TEMPLATE, HANDLE_BOOLEAN_VALUE
}

@Component({
  selector: 'app-bool-value-chooser',
  templateUrl: './bool-value-chooser.component.html',
  styleUrls: ['./bool-value-chooser.component.scss']
})
export class BoolValueChooser{
  @Input() templateRef: TemplateRef<any> = null;
  @Input() title: String = null;
  @Input() chooserType: CHOOSER_TYPE = null;
  @Input() titleTooltip: String = null;
  @Input() messageTooltip: String = null
  @Input() tooltipContentRef: TemplateRef<any> = null;
  @Input() selectedOption : boolean = false;
  @Input() handleModal: boolean = false;
  @Output() changeValueEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public popup: MatDialog,
  ) {
  }

  public handlePopupSelection(value : boolean){
    let dialogRef = this.popup.open(BoolValuePopupConfirm, {
      panelClass: 'mat-coupons-popup',
      disableClose: false,
      hasBackdrop: true,
    }) 

    dialogRef.afterClosed().subscribe(result => {
      if(result) this.handleSelectButtons(value)
    });
    
  }

  public handleSelectButtons(value : boolean){
    if(this.changeValueEvent) this.changeValueEvent.emit(value);

    if(this.chooserType !== CHOOSER_TYPE.DISPLAY_TEMPLATE){
      this.selectedOption = value;
    }
  }

}
