import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() set pageCount(count: number) {
    this._pageCount = count;
    this.pages = [];
    for (let i = 1; i <= this._pageCount; i++) {
      this.pages.push(i);
    }
  }
  @Input() currentPage: number;
  @Output() changePage = new EventEmitter<number>();

  pages: number[];
  _pageCount: number;

  constructor() { }

  ngOnInit() { }

  jumpToPage(page: number) {
    if (page > 0 && page <= this._pageCount) {
      this.changePage.emit(page);
    }
  }

}
