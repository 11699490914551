import { Injectable } from "@angular/core";
import { SnackbarConfig } from "app/common";
import { Statement } from "app/statements";
import { ErrorMessage, ErrorMessageContent } from "app/core/form-validator/validatorInterface";
import { StatementService } from "app/statements/statement.service";
import { Store } from "@ngrx/store";
import { State } from "app/reducers";
import { ValidateFieldV2Service } from "app/core/form-validator/validate-field-v2.service";
import { FreelanceJobSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/freelance-job.sub-steps";
import { CryptocurrencySubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/cryptocurrency.sub-steps";
import {
  PensionFromList,
  PensionIncomeErrors,
  pensionList2019,
  pensionListBefore2019
} from "app/modules/statement/models/additional-income/pension-income.model";
import { DateHelper } from "app/core/helpers/date.helper";
import { PensionSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/pension.sub-steps";
import { SocialSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/social-sub.steps";
import { FreelanceJobManager } from "app/modules/statement/models/additional-income/freelance-job.manager";
import { CryptocurrencyIncomeManager } from "app/modules/statement/models/additional-income/cryptocurrency.manager";
import { VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME } from "app/core/form-validator/validator-field-names/validator-field-names-additional-income";
import { PensionIncomeManager } from "app/modules/statement/models/additional-income/pension-income.manager";
import { SocialIncomeManager } from "app/modules/statement/models/additional-income/social-income.manager";
import { take } from "rxjs/operators";
import { SHOW_SNACKBAR } from "app/reducers/actions/snackbar";
import { PensionIncomeTypes } from "app/modules/statement/models/additional-income/pension-income.types";
import { SocialIncomeErrors } from "app/modules/statement/models/additional-income/social-income.model";
import { FreelanceJobErrors } from "app/modules/statement/models/additional-income/freelance-job.model";
import { CryptocurrencyIncomeErrors } from "app/modules/statement/models/additional-income/cryptocurrency.model";
import { AdditionalIncomeConfig } from "app/configs/statement/additional-income.config";
import { FreelanceJobTypes } from "app/modules/statement/models/additional-income/freelance-job.types";
import { SocialIncomeTypes } from "app/modules/statement/models/additional-income/social-income.types";
import { CryptocurrencyIncomeTypes } from "app/modules/statement/models/additional-income/cryptocurrency.types";
import { UPDATE_REFUND_DETAILS } from "app/reducers/actions/refund-details";
import { Subject } from "rxjs";

@Injectable()
export class AdditionalIncomeService {
  public originalStatement: Statement;
  public mainStatement: Statement;
  public currentStatement: Statement;
  public errors: FreelanceJobErrors[] | SocialIncomeErrors[] | PensionIncomeErrors[] | CryptocurrencyIncomeErrors[];
  public epValidator: ErrorMessage[];
  public disabilityLevels = [];
  public loading = false;
  public readonly inputIdBase = "additional-income-input__";
  public readonly VALIDATOR_KEYS = VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME;
  public readonly dateHelper = DateHelper;
  private ngUnsubscribe: Subject<void>;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private validateFieldV2Service: ValidateFieldV2Service
  ) {}

  public _init(
    _mainStatement: Statement,
    _currentStatement: Statement,
    _ngUnsubscribe: Subject<void>,
    _originalStatement?: Statement
  ): void {
    this.resetInitialVariables();

    if (_originalStatement) {
      this.originalStatement = _originalStatement;
    } else {
      this.originalStatement = _mainStatement.clone();
    }

    this.mainStatement = _mainStatement;
    this.currentStatement = _currentStatement;

    this.ngUnsubscribe = _ngUnsubscribe;

    this.validateFieldV2Service._init(this.mainStatement);
  }

  public get isLoading(): boolean {
    return this.loading;
  }

  public clearErrors(): void {
    this.errors = [];
  }

  public getError(key: string, index = 0): string[] {
    if (this.errors && this.errors[index] && Array.isArray(this.errors[index][key])) {
      return this.errors[index][key];
    }

    return [];
  }

  public pensionNamesList(): PensionFromList[] {
    if (this.mainStatement.fullYear >= 2019) {
      return pensionList2019;
    }

    return pensionListBefore2019;
  }

  public get socialIncomeLimit(): number {
    return AdditionalIncomeConfig.ITEMS_LIMIT_IN_SUBSECTION_SOCIAL_INCOME;
  }

  public get pensionIncomeLimit(): number {
    return AdditionalIncomeConfig.ITEMS_LIMIT_IN_SUBSECTION_PENSION_INCOME;
  }

  public get freelanceIncomeLimit(): number {
    return AdditionalIncomeConfig.ITEMS_LIMIT_IN_SUBSECTION_FREELANCE_INCOME;
  }

  public get cryptocurrencyIncomeLimit(): number {
    return AdditionalIncomeConfig.ITEMS_LIMIT_IN_SUBSECTION_CRYPTOCURRENCY_INCOME;
  }

  public setFromOriginalStatement(): void {
    Object.assign(this.mainStatement, this.originalStatement.clone());

    this.updateAssignCurrentStatement();
  }

  public updateOriginalStatement(): void {
    Object.assign(this.originalStatement, this.mainStatement.clone());
  }

  public updateAdditionalIncome(
    group: SocialSubSteps | PensionSubSteps | FreelanceJobSubSteps | CryptocurrencySubSteps,
    callbackSuccess: (result: boolean) => void,
    assignResponse = true
  ): void {
    this.clearErrors();

    const payload = !this.currentStatement.isSpouse()
      ? this.mainStatement.additionalIncomeApplicantPayload("", group)
      : this.mainStatement.additionalIncomeSpousePayload("", group);

    this.statementService
      .updateStatement(this.mainStatement.id, payload)
      .pipe(take(1))
      .subscribe({
        next: (response: Statement) => {
          if (assignResponse) {
            Object.assign(this.mainStatement, response);

            this.updateAssignCurrentStatement();
          }

          this.updateOriginalStatement();

          this.store.dispatch({ type: UPDATE_REFUND_DETAILS });

          callbackSuccess(true);
        },
        error: (errors) => {
          this.showGlobalError();

          // if (errors.status === HttpStatusCodeConfig.INTERNAL_SERVER_ERROR) {
          //   this.showGlobalError();
          // } else {
          //   const error = errors.error
          //     ? errors.error.spouses && errors.error.spouses.length ? errors.error.spouses[0] : errors.error
          //     : null;
          //
          //   if (error) {
          //     this.errors = error;
          //   } else {
          //     this.showGlobalError();
          //   }
          // }

          callbackSuccess(false);
        },
        complete: () => (this.loading = false)
      });
  }

  public isSocialIncomeSection(
    sectionName:
      | SocialSubSteps
      | PensionSubSteps
      | FreelanceJobSubSteps
      | CryptocurrencySubSteps
      | FreelanceJobTypes
      | PensionIncomeTypes
      | SocialIncomeTypes
      | CryptocurrencyIncomeTypes
  ): boolean {
    return (
      Object.values(SocialSubSteps).includes(sectionName as SocialSubSteps) ||
      Object.values(SocialIncomeTypes).includes(sectionName as SocialIncomeTypes)
    );
  }

  public isPensionIncomeSection(
    sectionName:
      | SocialSubSteps
      | PensionSubSteps
      | FreelanceJobSubSteps
      | CryptocurrencySubSteps
      | FreelanceJobTypes
      | PensionIncomeTypes
      | SocialIncomeTypes
      | CryptocurrencyIncomeTypes
  ): boolean {
    return (
      Object.values(PensionSubSteps).includes(sectionName as PensionSubSteps) ||
      Object.values(PensionIncomeTypes).includes(sectionName as PensionIncomeTypes)
    );
  }

  public isFreelanceIncomeSection(
    sectionName:
      | SocialSubSteps
      | PensionSubSteps
      | FreelanceJobSubSteps
      | CryptocurrencySubSteps
      | FreelanceJobTypes
      | PensionIncomeTypes
      | SocialIncomeTypes
      | CryptocurrencyIncomeTypes
  ): boolean {
    return (
      Object.values(FreelanceJobSubSteps).includes(sectionName as FreelanceJobSubSteps) ||
      Object.values(FreelanceJobTypes).includes(sectionName as FreelanceJobTypes)
    );
  }

  public isCryptocurrencyIncomeSection(
    sectionName:
      | SocialSubSteps
      | PensionSubSteps
      | FreelanceJobSubSteps
      | CryptocurrencySubSteps
      | FreelanceJobTypes
      | PensionIncomeTypes
      | SocialIncomeTypes
      | CryptocurrencyIncomeTypes
  ): boolean {
    return (
      Object.values(CryptocurrencySubSteps).includes(sectionName as CryptocurrencySubSteps) ||
      Object.values(CryptocurrencyIncomeTypes).includes(sectionName as CryptocurrencyIncomeTypes)
    );
  }

  public checkAdditionalIncomeFields(): void {}

  public formValidationErrors(
    group: SocialSubSteps | PensionSubSteps | FreelanceJobSubSteps | CryptocurrencySubSteps
  ): void {
    this.checkAdditionalIncomeFields();

    this.epValidator = [];

    let errorMessages: Array<ErrorMessage> = [];

    if (!this.mainStatement) {
      return;
    }

    let dataForValidation,
      validationFieldKeys = [];

    switch (group) {
      case SocialSubSteps.UNEMPLOYMENT:
        dataForValidation = SocialIncomeManager.getUnemployment(this.currentStatement);
        break;
      case SocialSubSteps.PARENTAL:
        dataForValidation = SocialIncomeManager.getParental(this.currentStatement);
        break;
      case SocialSubSteps.INSOLVENCY:
        dataForValidation = SocialIncomeManager.getInsolvency(this.currentStatement);
        break;
      case SocialSubSteps.SICKNESS:
        dataForValidation = SocialIncomeManager.getSickness(this.currentStatement);
        break;
      case SocialSubSteps.MATERNITY:
        dataForValidation = SocialIncomeManager.getMaternity(this.currentStatement);
        break;
      case SocialSubSteps.INJURY:
        dataForValidation = SocialIncomeManager.getInjury(this.currentStatement);
        break;
      case SocialSubSteps.TRANSITION:
        dataForValidation = SocialIncomeManager.getTransition(this.currentStatement);
        break;
      case SocialSubSteps.OTHER:
        dataForValidation = SocialIncomeManager.getOther(this.currentStatement);
        break;
      case PensionSubSteps.PENSION:
        dataForValidation = PensionIncomeManager.getPension(this.currentStatement);
        break;
      case FreelanceJobSubSteps.FREELANCE:
        dataForValidation = FreelanceJobManager.getFreelance(this.currentStatement);
        break;
      case FreelanceJobSubSteps.GEWERBE:
        dataForValidation = FreelanceJobManager.getGewerbe(this.currentStatement);
        break;
      case FreelanceJobSubSteps.VOLUNTERING_INCOME:
        dataForValidation = FreelanceJobManager.getVolunteringIncome(this.currentStatement);
        break;
      case CryptocurrencySubSteps.CRYPTOCURRENCY:
        dataForValidation = CryptocurrencyIncomeManager.getCryptoCurrencyPension(this.currentStatement);
        break;
    }

    if (this.isSocialIncomeSection(group)) {
      console.log(group);

      validationFieldKeys = [VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.social_amount];

      if (group === SocialSubSteps.OTHER) {
        validationFieldKeys.push(VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.social_other_name);
      }

      if (this.mainStatement.fullYear <= 2018) {
        validationFieldKeys.push(
          VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.social_period_lower,
          VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.social_period_upper
        );
      }
    }

    if (this.isPensionIncomeSection(group)) {
      this.currentStatement.pensionIncome.forEach((el) => {
        if (el.incomeType === PensionIncomeTypes.PENSION) {
          if (!el.adjustment) {
            el.adjustment = 0.0;
          }
        }
      });

      validationFieldKeys = [
        VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.pension_from,
        VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.pension_amount,
        VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.pension_start,
        VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.pension_adjustment
      ];
    }

    if (this.isFreelanceIncomeSection(group)) {
      if (group == FreelanceJobSubSteps.VOLUNTERING_INCOME) {
        validationFieldKeys = [
          VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.freelance_name,
          VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.freelance_income
          // VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.freelance_costs,
        ];
      } else {
        errorMessages = [...this.validateFreelanceAndGewerbe(dataForValidation)];
      }
    }

    if (this.isCryptocurrencyIncomeSection(group)) {
      validationFieldKeys = [VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.cryptocurrency_income_net];
    }

    //check if it has Freelance and Gewerbe validation
    const validateFieldAndGetMessage =
      errorMessages.length > 0
        ? [...errorMessages]
        : [...this.validateFieldV2Service.validateFieldAndGetMessageV2(dataForValidation, validationFieldKeys)];

    this.epValidator = [...this.epValidator, ...validateFieldAndGetMessage];

    console.log(this.epValidator);

    this.validateFreelanceAmount();
  }

  public validateFreelanceAndGewerbe(dataForValidation: any): Array<ErrorMessage> {
    const checkIfItIs2020 = this.mainStatement.fullYear === 2020; //TODO: remove after 2 moths, it is temporary solution

    let freelanceValidationMessage = [];
    let freelanceValidationFieldKeys = [];

    dataForValidation.forEach((data, index) => {
      const customErrorMessage = [];
      freelanceValidationFieldKeys = checkIfItIs2020
        ? [VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_type]
        : [
            VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.total_payments,
            VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.total_withdrawals,
            VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_type,
            VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.has_sold_business_real_estate,
            VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_postal_code,
            VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_street,
            VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_city
          ];

      if (data.full_year) {
        freelanceValidationFieldKeys.push(
          VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_start,
          VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_end
        );
      }

      if (data.has_steuernummer) {
        freelanceValidationFieldKeys.push(
          VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.tax_authority,
          VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_steuernummer
        );
      }

      if (data.has_business_name) {
        freelanceValidationFieldKeys.push(VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.business_name);
      }

      if (data.has_corona) {
        freelanceValidationFieldKeys.push(VALIDATOR_FIELD_NAMES_ADDITIONAL_INCOME.corona_value);
      } else if (data.corona_value) {
        data.corona_value = null; //set value for null if has_corona is set to false and corona_value has some values
      }

      if (data.business_type === data.business_name) {
        //business_type and business_name can not have equal value TAX-3086
        customErrorMessage.push(
          this.addListEpValidatorMessage(this.VALIDATOR_KEYS.business_name, "BUSSINES_NAME_IS_EQUAL_TO_BUSINESS_TYPE")
        );
      }

      if (data.business_type === this.currentStatement.lastName) {
        //business_type and lastName can not have equal value TAX-3089
        customErrorMessage.push(
          this.addListEpValidatorMessage(this.VALIDATOR_KEYS.business_type, "BUSSINES_TYPE_IS_EQUAL_TO_LAST_NAME")
        );
      }

      freelanceValidationMessage = [
        ...customErrorMessage,
        ...freelanceValidationMessage,
        ...this.validateFieldV2Service.validateFieldAndGetMessageV2(data, freelanceValidationFieldKeys, index)
      ];
    });

    return freelanceValidationMessage;
  }

  private addListEpValidatorMessage(field_name: string, message: string): ErrorMessage {
    const errorMessage = new ErrorMessage();
    errorMessage.fieldName = field_name;
    errorMessage.index = 0;
    errorMessage.messages = [new ErrorMessageContent(message)];
    return errorMessage;
  }

  private validateFreelanceAmount(): void {
    const freelanceNetSum = FreelanceJobManager.getFreelance(this.currentStatement).reduce(
      (a, b) => a + Number(b.incomeNet),
      0
    );

    if (AdditionalIncomeConfig.FREELANCE_JOB_THRESHOLD <= freelanceNetSum) {
      FreelanceJobManager.getFreelance(this.currentStatement).forEach((el, i) => {
        this.addFreelanceAmountExceededError(i);
      });
    }

    const gewerbeNetSum = FreelanceJobManager.getGewerbe(this.currentStatement).reduce(
      (a, b) => a + Number(b.incomeNet),
      0
    );

    if (AdditionalIncomeConfig.FREELANCE_JOB_THRESHOLD <= gewerbeNetSum) {
      FreelanceJobManager.getGewerbe(this.currentStatement).forEach((el, i) => {
        this.addFreelanceAmountExceededError(i);
      });
    }
  }

  private addFreelanceAmountExceededError(index: number): void {
    const errorMessage = new ErrorMessage();
    errorMessage.fieldName = this.VALIDATOR_KEYS.freelance_income_net;
    errorMessage.index = index;
    errorMessage.messages = [
      new ErrorMessageContent("_ADDITIONAL_INCOME.FREELANCE_GROUP.ALERT.AMOUNT_EXCEEDED_ERROR", {
        amount: AdditionalIncomeConfig.FREELANCE_JOB_THRESHOLD
      })
    ];
    this.epValidator.push(errorMessage);
  }

  private showGlobalError(): void {
    this.store.dispatch({
      type: SHOW_SNACKBAR,
      payload: new SnackbarConfig("COMMON.GLOBAL_ERROR", "ERROR")
    });
  }

  private resetInitialVariables(): void {
    this.originalStatement = undefined;
    this.mainStatement = undefined;
    this.currentStatement = undefined;
    this.errors = undefined;
    this.epValidator = undefined;
    this.disabilityLevels = [];
    this.loading = false;
  }

  private updateAssignCurrentStatement(): void {
    if (this.currentStatement.isSpouse() && this.mainStatement.spouse) {
      Object.assign(this.currentStatement, this.mainStatement.spouse);
    } else {
      Object.assign(this.currentStatement, this.mainStatement);
    }
  }

  public getMainStatement() {
    return this.mainStatement;
  }
}
