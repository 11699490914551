import {of as observableOf, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {State, getAuthUser} from '../reducers';
import {AuthHttpService} from './auth-http.service';
import {USER_LOGOUT, USER_LOGIN} from '../reducers/actions/auth-user';
import {REMOVE_USER_DATA} from '../reducers/actions/user';
import {RegisterData} from '../core';
import {getApiPath} from '../common';
import { CookieService } from 'ngx-cookie-service';
import { AuthModel } from 'app/modules/user/models/auth/auth.model';

@Injectable()
export class AuthService {
  public redirectUrl: string;
  private loggedUser: AuthModel;

  constructor(
    private authHttp: AuthHttpService,
    private store: Store<State>,
    private cookieService: CookieService,
    private router: Router
  ) {
    this.store.select(getAuthUser)
      .subscribe({
        next: (user: AuthModel) => {
          this.loggedUser = user;
          if (user) {
            this.setUserInStorage(user);
          }
        }
      });
  }

  getUserFromStorage(): AuthModel {
    const userFromLS: string = localStorage.getItem('user');
    const userString: string = userFromLS;
    if (!userString) {
      return null;
    }
    const userParsed = JSON.parse(userString);

    if (typeof userParsed === 'object' && userParsed.access && userParsed.refresh ) {
      return AuthModel.fromResponse(userParsed);
    }

    this.removeUserFromStorage();
    return null;
  }

  setUserInStorage(user: AuthModel) {
    const userJSON = JSON.stringify(user);
    localStorage.setItem('user', userJSON);
  }

  removeUserFromStorage() {
    localStorage.removeItem('user');
    this.cookieService.delete('user');
    localStorage.removeItem('statement');
    sessionStorage.removeItem('personalInfoOnLogin');
  }

  login(email: string, password: string, captchaToken?: string): Observable<AuthModel> {
    const data = {
      email: email,
      password: password,
      'cf-turnstile-response': captchaToken,
    };
    return this.authHttp.post(`${getApiPath()}accounts/login/`, data).pipe(
      map((response: any) => {
        const user: AuthModel = AuthModel.fromResponse(response);
        // user.username = data.email;
        this.store.dispatch({ type: USER_LOGIN, payload: user });
        if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
          this.redirectUrl = null;
        }
        return user;
      }));
  }

  logout(): void {
    if(this.loggedUser?.refresh) this.authHttp.post(`${getApiPath()}accounts/logout/`, {refresh : this.loggedUser.refresh})

    this.removeUserFromStorage();
    this.store.dispatch({ type: USER_LOGOUT });
    this.store.dispatch({ type: REMOVE_USER_DATA });
    this.router.navigate(['/', 'new']);
  }

  register(data: RegisterData, captchaToken?: string): Observable<Response> {
    return this.authHttp.post(`${getApiPath()}accounts/`, {
      ...data.toResponse(),
      'cf-turnstile-response': captchaToken,
    });
  }

  refreshToken(): Observable<AuthModel> {
    if (!this.loggedUser || !this.loggedUser.refresh) {
      return observableOf(null);
    }
    const body = {
      refresh: this.loggedUser.refresh
    };
    return this.authHttp.post(`${getApiPath()}accounts/token-refresh/`, body)
      .pipe(
        map((response: any) => AuthModel.fromResponse({...this.loggedUser, access: response.access})),
        catchError(_ => observableOf(null)),
      );
  }

  /** Authenticating account via email activation link   */
  verifyEmail(activationKey): Observable<Response> {
    return this.authHttp.get(`${getApiPath()}activate/${activationKey}`);
  }

  updateTokenInLocalStorageAndCookies(authUser: AuthModel): void {
    const user = this.getUserFromStorage();

    if (user) {
      user.access = authUser.access;
      user.refresh = authUser.refresh;
      this.setUserInStorage(user);
    }
  }

  hasUserInStorage(): boolean {
    return Boolean(this.getUserFromStorage());
  }
}
