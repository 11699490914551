import { finalize, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Employer } from '../../statements/employer';
import { SelectsData, SelectsDataInterface } from '../../statements/selects-data';
import { getSelectsData, State } from '../../reducers';
import { VerificationCodes } from 'app/statements/verification-codes';
import { SubscribingComponent } from '../subscribing-component';
import { ErrorMessage } from 'app/core/form-validator/validatorInterface';
import { LohnsteuerValidator } from 'app/statements/lohnsteuerValidator';
import { Router } from '@angular/router';
import { TaxCardSingleRow } from '../tax-card-single-row';
import { ValidateFieldService } from 'app/core/form-validator/validate-field.service';

@Component({
  selector: 'app-employer-edit',
  templateUrl: './employer-edit.component.html',
  styleUrls: ['./employer-edit.component.scss'],
})
export class EmployerEditComponent extends SubscribingComponent implements OnInit {
  @Input() employer: Employer;
  @Input() employers: Employer[];
  @Input() errors;
  @Input() isVerification = false;
  @Input() unlocked = '';
  @Input() toggleField;
  @Input() spouseId = null;
  @Input() currentIndex = 0;
  @Input() epValidator: ErrorMessage[] = [];
  @Input() isBusiness = false;
  @Input() isEmployerCom = false;
  @Input() isSummaryEmployer = false;
  @Input() isEditableSummaryEmployer = false;
  @Input() empWarningList: LohnsteuerValidator[] = [];
  public dataSelects: SelectsDataInterface = new SelectsData();
  public taxCardRows: TaxCardSingleRow[];
  public loadingSelectsData = true;
  public codes = VerificationCodes;
  public validationFieldsDate = [
    'ib_date_from',
    'ib_date_to',
    'ib_period_lower',
    'ib_period_upper',
  ];
  public warningValidationFields = [
    'ib04vsib03',
    'ib05vsib04',
    'ib06vsib04',
    'ib07vsib04',
  ];
  public employerFieldEdit = true;
  private fieldsToCalc = [
    'ib03', 'ib04', 'ib05', 'ib06', 'ib07', 'ib15', 'ib17', 'ib18', 'ib19', 'ib20', 'ib21', 'ib22a', 'ib23a',
    'ib24a', 'ib24b', 'ib24c', 'ib25', 'ib26', 'ib27', 'ib28'
  ];

  constructor(
    private store: Store<State>,
    private router: Router,
    private validatorService: ValidateFieldService,
  ) {
    super();
  }

  ngOnInit() {
    this.loadingSelectsData = true;
    this.store.select(getSelectsData).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.loadingSelectsData = false))
      .subscribe((selectsData: SelectsDataInterface) => {
        this.dataSelects = selectsData;
      });

    if (this.isSummary) {
      this.createEmployerSummary();
    }

    this.validatorService.validatorChangedEmitter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.router.navigate([]);

      setTimeout(() => {
        this.scrollToError();
      }, 10);
    });

    this.prepareTaxCardRowsData();
  }

  public get isSummary(): boolean {
    return !!this.employers && !!this.employers.length;
  }

  public findValidationByFieldName(validationFieldNames: string[]): boolean {
    return !!this.epValidator
      .find(x => validationFieldNames.indexOf(x.fieldName) !== -1 && x.index === this.currentIndex);
  }

  public createEmployerSummary() {
    this.employer = new Employer();
    for (const employer of this.employers) {
      this.addEmployerValues(this.employer, employer);
    }
    this.formatEmployerFields();
  }

  private formatEmployerFields() {
    this.fieldsToCalc.forEach(field => {
      this.employer[field] = this.employer[field].toFixed(2);
    });
  }

  private addEmployerValues(destEmployer: Employer, employer: Employer) {
    for (const field of this.fieldsToCalc) {
      if (destEmployer[field] === '') {
        destEmployer[field] = 0;
      }
      destEmployer[field] += parseFloat(employer[field]);
    }
  }

  private scrollToError() {
    const firstError = this.epValidator[0];

    if (!firstError || this.currentIndex !== firstError.index) {
      return;
    }

    let fragment = firstError.fieldName;

    if (this.validationFieldsDate.indexOf(firstError.fieldName) !== -1) {
      fragment = 'ib_date_from';
    }

    const itemToScrollTo = document.getElementById(fragment);
    if (itemToScrollTo) {
      itemToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    // this.router.navigate([], {fragment: fragment});
  }

  private prepareTaxCardRowsData(): void {
    this.taxCardRows = [
      new TaxCardSingleRow(
        'INPUT.GROSS_WORKING_WAGES.LABEL',
        true,
        'INPUT.GROSS_WORKING_WAGES.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib03),
        'INPUT.GROSS_WORKING_WAGES.LABEL',
        'ib03',
        this.errors ? this.errors.gross_working_wages : null,
        ['ib03'],
        ['ib04vsib03']
      ),
      new TaxCardSingleRow(
        'INPUT.INCOME_TAX.LABEL',
        true,
        'INPUT.INCOME_TAX.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib04),
        'INPUT.INCOME_TAX.LABEL',
        'ib04',
        this.errors ? this.errors.income_tax : null,
        ['ib04'],
        this.warningValidationFields
      ),
      new TaxCardSingleRow(
        'INPUT.SOLIDARITY_SURCHARGE.LABEL',
        true,
        'INPUT.SOLIDARITY_SURCHARGE.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib05),
        'INPUT.SOLIDARITY_SURCHARGE.LABEL',
        'ib05',
        this.errors ? this.errors.solidarity_surcharge : null,
        ['ib05'],
        ['ib05vsib04']
      ),
      new TaxCardSingleRow(
        'INPUT.CHURCH_TAX.LABEL',
        true,
        'INPUT.CHURCH_TAX.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib06),
        'INPUT.CHURCH_TAX.LABEL',
        'ib06',
        this.errors ? this.errors.church_tax : null,
        ['ib06'],
        ['ib06vsib04']
      ),
      new TaxCardSingleRow(
        'INPUT.RETAINED_CHURCH_TAX.LABEL',
        false,
        'INPUT.RETAINED_CHURCH_TAX.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib07),
        'INPUT.RETAINED_CHURCH_TAX.LABEL',
        'ib07',
        this.errors ? this.errors.retained_church_tax : null,
        ['ib07'],
        ['ib07vsib04']
      ),
      new TaxCardSingleRow(
        'INPUT.SHORTTERM_ALLOWANCE.LABEL',
        false,
        'INPUT.SHORTTERM_ALLOWANCE.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib15),
        'INPUT.SHORTTERM_ALLOWANCE.LABEL',
        'ib15',
        this.errors ? this.errors.shortterm_allowance : null,
        ['ib15'],
      ),
      new TaxCardSingleRow(
        'INPUT.BUSINESS_TRIPS_COSTS.LABEL',
        false,
        'INPUT.BUSINESS_TRIPS_COSTS.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib17),
        'INPUT.BUSINESS_TRIPS_COSTS.LABEL',
        'ib17',
        this.errors ? this.errors.business_trips_costs : null,
        ['ib17'],
      ),
      new TaxCardSingleRow(
        'INPUT.TRIPS_TO_WORK_COSTS.LABEL',
        false,
        'INPUT.TRIPS_TO_WORK_COSTS.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib18),
        'INPUT.TRIPS_TO_WORK_COSTS.LABEL',
        'ib18',
        this.errors ? this.errors.trips_to_work_costs : null,
        ['ib18'],
      ),
      new TaxCardSingleRow(
        'INPUT.WAGES_NOT_COVERED_BY_REDUCED_TAX.LABEL',
        false,
        'INPUT.WAGES_NOT_COVERED_BY_REDUCED_TAX.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib19),
        'INPUT.WAGES_NOT_COVERED_BY_REDUCED_TAX.LABEL',
        'ib19',
        this.errors ? this.errors.wages_not_covered_by_reduced_tax : null,
        ['ib19'],
      ),
      new TaxCardSingleRow(
        'INPUT.TAXABLE_GRANTS_FOR_EXTERNAL_ACTIVITIES.LABEL',
        false,
        'INPUT.TAXABLE_GRANTS_FOR_EXTERNAL_ACTIVITIES.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib20),
        'INPUT.TAXABLE_GRANTS_FOR_EXTERNAL_ACTIVITIES.LABEL',
        'ib20',
        this.errors ? this.errors.taxable_grants_for_external_activities : null,
        ['ib20'],
      ),
      new TaxCardSingleRow(
        'INPUT.TAX_FREE_EMPLOYER_BENEFITS.LABEL',
        false,
        'INPUT.TAX_FREE_EMPLOYER_BENEFITS.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib21),
        'INPUT.TAX_FREE_EMPLOYER_BENEFITS.LABEL',
        'ib21',
        this.errors ? this.errors.tax_free_employer_benefits : null,
        ['ib21'],
      ),
      new TaxCardSingleRow(
        'INPUT.PENSION_INCURANCE_EMPLOYER.LABEL',
        true,
        'INPUT.PENSION_INCURANCE_EMPLOYER.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib22a),
        'INPUT.PENSION_INCURANCE_EMPLOYER.LABEL',
        'ib22a',
        this.errors ? this.errors.pension_insurance_employer : null,
        ['ib22a'],
      ),
      new TaxCardSingleRow(
        'INPUT.PENSION_INCURANCE_EMPLOYEE.LABEL',
        true,
        'INPUT.PENSION_INCURANCE_EMPLOYEE.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib23a),
        'INPUT.PENSION_INCURANCE_EMPLOYEE.LABEL',
        'ib23a',
        this.errors ? this.errors.pension_insurance_employee : null,
        ['ib23a'],
      ),
      new TaxCardSingleRow(
        'INPUT.HEALTH_INSURANCE_EMPLOYER_SUBSIDIES.LABEL',
        false,
        'INPUT.HEALTH_INSURANCE_EMPLOYER_SUBSIDIES.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib24a),
        'INPUT.HEALTH_INSURANCE_EMPLOYER_SUBSIDIES.LABEL',
        'ib24a',
        this.errors ? this.errors.health_insurance_employer_subsidies : null,
        ['ib24a'],
      ),
      new TaxCardSingleRow(
        'INPUT.PRIVATE_HEALTH_INSURANCE_EMPLOYER_SUBSIDIES.LABEL',
        false,
        'INPUT.PRIVATE_HEALTH_INSURANCE_EMPLOYER_SUBSIDIES.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib24b),
        'INPUT.PRIVATE_HEALTH_INSURANCE_EMPLOYER_SUBSIDIES.LABEL',
        'ib24b',
        this.errors ? this.errors.private_health_insurance_employer_subsidies : null,
        ['ib24b'],
      ),
      new TaxCardSingleRow(
        'INPUT.CARE_INSURANCE_EMPLOYER_SUBSIDIES.LABEL',
        false,
        'INPUT.CARE_INSURANCE_EMPLOYER_SUBSIDIES.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib24c),
        'INPUT.CARE_INSURANCE_EMPLOYER_SUBSIDIES.LABEL',
        'ib24c',
        this.errors ? this.errors.care_insurance_employer_subsidies : null,
        ['ib24c'],
      ),
      new TaxCardSingleRow(
        'INPUT.HEALTH_INSURANCE_EMPLOYEE_CONTRIBUTIONS.LABEL',
        true,
        'INPUT.HEALTH_INSURANCE_EMPLOYEE_CONTRIBUTIONS.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib25),
        'INPUT.HEALTH_INSURANCE_EMPLOYEE_CONTRIBUTIONS.LABEL',
        'ib25',
        this.errors ? this.errors.health_insurance_employee_contributions : null,
        ['ib25'],
      ),
      new TaxCardSingleRow(
        'INPUT.LONGTERM_CARE_INSURANCE.LABEL',
        true,
        'INPUT.LONGTERM_CARE_INSURANCE.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib26),
        'INPUT.LONGTERM_CARE_INSURANCE.LABEL',
        'ib26',
        this.errors ? this.errors.longterm_care_insurance : null,
        ['ib26'],
      ),
      new TaxCardSingleRow(
        'INPUT.UNEMPLOYMENT_INSURANCE.LABEL',
        true,
        'INPUT.UNEMPLOYMENT_INSURANCE.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib27),
        'INPUT.UNEMPLOYMENT_INSURANCE.LABEL',
        'ib27',
        this.errors ? this.errors.unemployment_insurance : null,
        ['ib27'],
      ),
      new TaxCardSingleRow(
        'INPUT.PROVEN_PRIVATE_HEALTH_INSURANCE.LABEL',
        false,
        'INPUT.PROVEN_PRIVATE_HEALTH_INSURANCE.LABEL',
        'INPUT.DEFAULT.PLACEHOLDER',
        () => this.isSummary || (this.isVerification && this.unlocked !== this.codes.ib28),
        'INPUT.PROVEN_PRIVATE_HEALTH_INSURANCE.LABEL',
        'ib28',
        null,
        ['ib28'],
      ),
    ];
  }
}
