<div class="dialog-box" [attr.data-cy]="'popupErrorOCR'">
    <div class="dialog-box__content deductions-content">
        <div class="dialog-box__title">
            <img src="../../../assets/svg/ocr/erorr.svg"/>
            <span>
                {{ 'OCR_FAILED.TITLE' | translate }}
            </span>
            <button mat-icon-button mat-dialog-close tabindex="-1">
                <mat-icon aria-hidden="true">close</mat-icon>
            </button>
        </div>
        <div class="dialog-box__description">
            {{ 'OCR_FAILED.DESCRIPTION' | translate }}
        </div>
        <div class="dialog-box__labels">
            <div class="dialog-box__labels-first">
                <div class="dialog-box__labels-title">
                    1. {{ 'OCR_FAILED.LABEL_ONE' | translate }}
                </div>
                <div class="dialog-box__labels-tile">
                    <img src="../../../assets/svg/ocr/desc_p3.svg">
                    <div class="desc">
                        <div class="desc_cont">{{ 'OCR_FAILED.LABEL_ONE_DESCRIPTION' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="dialog-box__labels-second">
                <div class="left_wrap">
                    <div class="dialog-box__labels-title">
                        2. {{ 'OCR_FAILED.LABEL_TWO' | translate }}
                    </div>
                    <div class="desc">
                        {{ 'OCR_FAILED.LABEL_TWO_DESCRIPTION' | translate }}
                    </div>
                </div>
                <div class="right_wrap">
                    <img src="../../../assets/img/ocr/img_OCR.png">
                </div>
            </div>
        </div>
        <div class="dialog-box__form">
            <div class="dialog-box__form-buttons">
                <button class="btn btn--primary" mat-dialog-close>{{ 'OCR_FAILED.BACK' | translate }}</button>
            </div>
        </div>
    </div>
</div>
