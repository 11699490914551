import { WorkflowDeductionApplicantSubBaseService } from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-applicant-sub-base.service';

export class WorkflowDeductionInfoOtherCostsService extends WorkflowDeductionApplicantSubBaseService {
  public get title(): string {
    return 'ASIDE_BAR.APPLICANT_DEDUCTION_SUBLIST.OTHER_COSTS';
  }

  public get subStepNumber(): number {
    return 7;
  }

  public get isFakeStep(): boolean {
    return !this.isRequired;
  }

  public get checkCanGo(): boolean {
    return super.checkCanGo && this.isRequired;
  }

  public get isRequired(): boolean {
    return super.isRequired && this.applicantHasEmployers;
  }
}
