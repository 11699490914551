import { Component, OnInit } from "@angular/core";
import { SideNavService } from "app/core/side-nav.service";
import { WorkflowControllerService } from "app/core/workflow/workflow-controller/workflow-controller.service";

@Component({
  selector: "app-menu-bar-mobile",
  templateUrl: "./menu-bar-mobile.component.html",
  styleUrls: ["./menu-bar-mobile.component.scss"]
})
export class MenuBarMobileComponent implements OnInit {
  constructor(
    private sideNavService: SideNavService,
    public workflowControllerService: WorkflowControllerService
  ) {}

  ngOnInit() {}

  toggleSideNav() {
    this.sideNavService.toggle().then(() => {});
  }
}
