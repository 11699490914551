<ng-container *ngIf="dataSelects && employer">
    <div
      class="employer-edit"
      [class.isEmployerCom]="isEmployerCom"
      [class.isSummaryEmployer]="isSummaryEmployer"
    >
        <div class="employer-edit__wrap">
            <div class="employer-edit__left" *ngIf="!isSummary">
                <div class="employer-edit__stage tax_class" id="emp_taxClass">
                    <div class="employer-edit__stage_select">
                        <div class="tax_class_label">
                            {{ 'INPUT.TAX_CLASS.LABEL' | translate }}
                        </div>
                        <app-form-select
                          [errors]="errors?.tax_class"
                          [isVerification]="isVerification"
                          [employerStage]="true"
                          [placeholder]="'INPUT.TAX_CLASS.PLACEHOLDER'"
                          [(model)]="employer.taxClass"
                          [choices]="dataSelects.taxClasses"
                          [valueName]="'id'"
                          [optionName]="'name'"
                          [isDisabled]="isVerification && unlocked !== codes.taxClasses"
                          [currentIndex]="currentIndex"
                          [currentName]="'taxClass'"
                          [addClassB2B]="isBusiness"
                          [classB2B]="'card_'+currentIndex+'_eid_select_tc'"
                        ></app-form-select>
                        <app-single-field-edit
                          *ngIf="isVerification"
                          [field]="codes.taxClasses"
                          [activeField]="unlocked"
                          (toggle)="toggleField($event)"
                          [offsetRight]="true"
                          [employerSelectEdit]="employerFieldEdit"
                        ></app-single-field-edit>
                        <app-validator-error-message
                          [employerStage]="true"
                          [epValidator]="epValidator"
                          [index]="currentIndex"
                          [fieldName]="'emp_taxClass'"
                        >
                        </app-validator-error-message>
                    </div>
                </div>

            </div>

            <div
              class="employer-edit__right"
              [class.isEmployerCom]="isEmployerCom"
              [class.isSummaryEmployer]="isSummaryEmployer"
            >
                <div class="employer-edit__row" *ngIf="!isSummary">
                    <div class="employer-edit__label_wrap stage_row">
                        <span>{{ 'INPUT.CERTIFICATION_PERIOD.LABEL' | translate }}</span>
                    </div>
                    <div class="employer-edit__stage stage_row" id="ib_date_from">
                        <div class="employer-edit__stage_col">
                            <app-form-date-input
                              [errors]="errors?.period_of_employment"
                              [placeholder]="'INPUT.PERIOD_OF_EMPLOYMENT_LOWER.PLACEHOLDER'"
                              [label]="'INPUT.PERIOD_OF_EMPLOYMENT_LOWER.LABEL'"
                              [(model)]="employer.workDateStart"
                              [isDisabled]="isVerification && unlocked !== codes.periodOfEmploymentLower"
                              [employerStage]="true"
                              [addClassB2B]="isBusiness"
                              [isEditableSummaryEmployer]="isEditableSummaryEmployer"
                              [classB2B]="'card_' + currentIndex + '_eid_date_poel'"
                              [noYear]="true"
                              [attr.data-cy]="'inputWorkDateStart'"
                            >
                            </app-form-date-input>
                            <app-single-field-edit
                              *ngIf="isVerification"
                              [field]="codes.periodOfEmploymentLower"
                              [activeField]="unlocked"
                              (toggle)="toggleField($event)"
                              [offset]="true"
                              [employerEdit]="employerFieldEdit"
                            >
                            </app-single-field-edit>
                        </div>
                        <div class="employer-edit__stage_sep"></div>
                        <div class="employer-edit__stage_col">
                            <app-form-date-input
                              [errors]="errors?.period_of_employment"
                              [placeholder]="'INPUT.PERIOD_OF_EMPLOYMENT_UPPER.PLACEHOLDER'"
                              [label]="'INPUT.PERIOD_OF_EMPLOYMENT_UPPER.LABEL'"
                              [(model)]="employer.workDateEnd"
                              [isDisabled]="isVerification && unlocked !== codes.periodOfEmploymentUpper"
                              [employerStage]="true"
                              [addClassB2B]="isBusiness"
                              [isEditableSummaryEmployer]="isEditableSummaryEmployer"
                              [classB2B]="'card_' + currentIndex + '_eid_date_poeu'"
                              [noYear]="true"
                              [attr.data-cy]="'inputWorkDateEnd'"
                            >
                            </app-form-date-input>
                            <app-single-field-edit
                              *ngIf="isVerification"
                              [field]="codes.periodOfEmploymentUpper"
                              [activeField]="unlocked"
                              (toggle)="toggleField($event)"
                              [offset]="true"
                              [employerEdit]="employerFieldEdit"
                            >
                            </app-single-field-edit>
                        </div>
                    </div>
                </div>

                <div
                  class="employer-edit__error_wrapper active"
                  *ngIf="!isSummary && (findValidationByFieldName(validationFieldsDate) || errors?.period_of_employment)"
                >
                  <div class="employer-edit__error_bc" *ngIf="errors?.period_of_employment">
                    {{ errors?.period_of_employment[0] }}
                  </div>
                  <ng-container *ngFor="let fieldName of validationFieldsDate;">
                    <app-validator-error-message
                      [epValidator]="epValidator"
                      [index]="currentIndex"
                      [employerStage]="true"
                      [fieldName]="fieldName"
                      [noTopLine]="true"
                    >
                    </app-validator-error-message>
                  </ng-container>
                </div>

                <ng-container *ngFor="let row of taxCardRows">
                  <app-tax-card-single-row
                    [row]="row"
                    [employer]="employer"
                    [employers]="employers"
                    [currentIndex]="currentIndex"
                    [spouseId]="spouseId"
                    [epValidator]="epValidator"
                    [empWarningList]="empWarningList"
                    [unlocked]="unlocked"
                    [codes]="codes"
                    [isBusiness]="isBusiness"
                    [isVerification]="isVerification"
                    [isSummary]="isSummary"
                    [isEditableSummaryEmployer]="isEditableSummaryEmployer"
                    [employerFieldEdit]="employerFieldEdit"
                    [toggleField]="toggleField"
                  >
                  </app-tax-card-single-row>
                </ng-container>

            </div>
        </div>
    </div>
</ng-container>
