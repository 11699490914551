import { B2bDeductionInfoComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.deduction-info-component.path';
import { DeductionInfoComponentPath } from 'app/core/workflow/page-path/statement-page-path/deduction-info-component.path';
import { WorkflowDeductionBaseService } from 'app/core/workflow/workflow-steps/deduction/workflow-deduction-base.service';
import {
  WorkflowDeductionInfoWorkAndOfficeService
} from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-info-work-and-office.service';
import {
  WorkflowDeductionInfoHomeServiceService
} from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-info-home-service.service';

export class WorkflowDeductionInfoService extends WorkflowDeductionBaseService {
  public get isDone(): boolean {
    return this.statement && this.statement.stageDeductionInfo;
  }

  public get isFakeStep(): boolean {
    return !this.isBusiness;
  }

  public get isRequired(): boolean {
    return this.statement && this.isDeductionOptionAdd;
  }

  public get isVisible(): boolean {
    if (!this.hasChildren && !this.hasSpouseAndFilledTogether) {
      return false;
    }

    return this.isRequired
      && this.isDeductionRoute;
  }

  public get isActiveOnStepsBar(): boolean {
    const locationHref = window.location.href;

    return locationHref.search(B2bDeductionInfoComponentPath.regex()) !== -1
      || locationHref.search(DeductionInfoComponentPath.regex()) !== -1;
  }

  public get isActive(): boolean {
    if (this.isBusiness) {
      return this.defaultIsActiveFunction;
    }

    return false;
  }

  public get title(): string {
    return 'ASIDE_BAR.APLICANT_DEDUCTION';
  }

  public get checkCanGo(): boolean {
    if (
      !this.blockedDefaultWorkflow && this.statement
      && this.isDeductionOptionAdd
    ) {
      if (this.hasChildren) {
        return this.statement.stageDeductionInfoChildren;
      }

      return true;
    }

    return false;
  }

  public get urlRegex(): RegExp {
    if (this.isBusiness) {
      return B2bDeductionInfoComponentPath.regex();
    }

    return DeductionInfoComponentPath.regex();
  }

  public get url(): string {
    if (this.isBusiness) {
      return B2bDeductionInfoComponentPath.url(this.getStatementId);
    }

    return DeductionInfoComponentPath.url(
      this.getStatementId,
      this.applicantHasEmployers
        ? WorkflowDeductionInfoWorkAndOfficeService._subStepNumber
        : WorkflowDeductionInfoHomeServiceService._subStepNumber,
    );
  }

  public get stepClass(): string {
    let classes = this.classLeftLineNone;

    if (!this.isBusiness) {
      classes += this.classHasSubs;
    }

    return classes;
  }

  public get isSubStep(): boolean {
    return true;
  }
}
