import {Injectable} from '@angular/core';

@Injectable()
export class CurrencyUtil {

  private readonly limiters = {
    UNACCEPTABLE: ',',
    CORRECT: '.'
  };

  constructor() {
  }

  public replaceLimiters(value: string): string {
    return value.replace(this.limiters.UNACCEPTABLE, this.limiters.CORRECT);
  }
}
