import { Statement } from 'app/statements';
import { ArrayHelper } from 'app/core/helpers/array.helper';
import { CryptocurrencyIncome } from './cryptocurrency.model';
import { CryptocurrencyIncomeTypes } from './cryptocurrency.types';

export class CryptocurrencyIncomeManager {
  public static getCryptoCurrencyPension(statement: Statement): CryptocurrencyIncome[] {
    return CryptocurrencyIncomeManager.get(
      statement,
      CryptocurrencyIncomeTypes.CRYPTOCURRENCY
    );
  }

  public static createCryptoCurrencyPension(statement: Statement): CryptocurrencyIncome {
    return CryptocurrencyIncomeManager.create(
      statement,
      CryptocurrencyIncomeTypes.CRYPTOCURRENCY
    );
  }

  public static removeAllCryptoCurrencyPension(statement: Statement): void {
    CryptocurrencyIncomeManager.removeAll(
      statement,
      CryptocurrencyIncomeTypes.CRYPTOCURRENCY
    );
  }

  public static removeCryptoCurrencyPension(statement: Statement, index: number): void {
    CryptocurrencyIncomeManager.remove(
      statement,
      CryptocurrencyIncomeTypes.CRYPTOCURRENCY,
      index
    );
  }

  public static get(statement: Statement, incomeType: CryptocurrencyIncomeTypes): CryptocurrencyIncome[] {
    // console.log(statement.cryptocurrencyIncome);
    
    return statement.cryptocurrencyIncome;

    // If new types will appear
    // return ArrayHelper.arrayFilter(
    //   statement.cryptocurrencyIncome,
    //   'incomeType',
    //   incomeType
    // );
  }

  public static create(statement: Statement, incomeType: CryptocurrencyIncomeTypes): CryptocurrencyIncome {
    const cryptocurrencyIncome = new CryptocurrencyIncome(incomeType);

    console.log(cryptocurrencyIncome);
    statement.cryptocurrencyIncome.push(cryptocurrencyIncome);

    return cryptocurrencyIncome;
  }

  public static removeAll(statement: Statement, incomeType: CryptocurrencyIncomeTypes): void {
    statement.cryptocurrencyIncome = [];

    // If new types will appear
    // statement.cryptocurrencyIncome = statement.cryptocurrencyIncome.filter(x => x.incomeType !== incomeType);
  }

  public static remove(statement: Statement, incomeType: CryptocurrencyIncomeTypes, index: number): void {
    statement.cryptocurrencyIncome.splice(index, 1);

    // If new types will appear
    // const cryptocurrencyIncomesList = CryptocurrencyIncomeManager.get(statement, incomeType);
    // cryptocurrencyIncomesList.splice(index, 1);
    // CryptocurrencyIncomeManager.removeAll(statement, incomeType);
    //
    // statement.cryptocurrencyIncome = [...statement.cryptocurrencyIncome, ...cryptocurrencyIncomesList];
  }
}
