import { NgModule } from '@angular/core';
import { AnalyticsService } from 'app/shared/modules/analytics/services/analytics.service';
import { FirebaseModule } from 'app/shared/modules/firebase/firebase.module';

@NgModule({
  imports: [
    FirebaseModule,
  ],
  providers: [
    AnalyticsService,
  ],
})
export class AnalyticsModule {
}
