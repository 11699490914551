import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-payment-time-out',
  templateUrl: './payment-time-out.component.html',
  styleUrls: ['./payment-time-out.component.scss']
})
export class PaymentTimeOutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
