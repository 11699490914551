export interface StatementYearResponse {
  id: number;
  year: number;
}

export class StatementYear {
  public id: number;
  public year: number;
  public active = false;

  static fromResponse(response: StatementYearResponse): StatementYear {
    const statementYear = new StatementYear();
    statementYear.id = response.id;
    statementYear.year = response.year;
    statementYear.active = false;
    return statementYear;
  }
}
