export interface OpinionRequestResponseInterface {
  id?: number;
  popup_viewed?: boolean;
  request_completed?: boolean;
  user_id?: number;
  uuid?: string;
}

export interface OpinionRequestInterface {
  id?: number;
  popupViewed: boolean;
  requestCompleted: boolean;
  userId?: number;
  uuid: string;
}

export class OpinionRequest implements OpinionRequestInterface {
  public id;
  public popupViewed = false;
  public requestCompleted = false;
  public userId;
  public uuid;

  static fromResponse(response: OpinionRequestResponseInterface): OpinionRequest {
    const opinionRequest = new this();
    opinionRequest.id = response.id;
    opinionRequest.popupViewed = response.popup_viewed;
    opinionRequest.requestCompleted = response.request_completed;
    opinionRequest.userId = response.user_id;
    opinionRequest.uuid = response.uuid;

    return opinionRequest;
  }

  public payload(): OpinionRequestResponseInterface {
    return {
      id: this.id || undefined,
      popup_viewed: this.popupViewed,
      request_completed: this.popupViewed,
      user_id: this.userId || undefined,
      uuid: this.uuid || undefined,
    };
  }
}
