<div
  class="more-info__container"
  [class.tooltipInInput]="tooltipInInput"
  (clickElsewhere)="closeTooltip()"
  [class.sellect_button]="sellect_button"
  [class.questionMark]="questionMark"
  (click)="showTooltip()"
  [class.onTop]="onTop"
  [class.onLeft]="onLeft"
  [class.onRight]="onRight"
  [class.onLeftDialog]="onLeftDialog"
  [class.onRightDialog]="onRightDialog"
  [class.onLeftDialogDeduction]="onLeftDialogDeduction"
  [class.center]="center"
>
  <div
    class="more-info"
    [ngClass]="{ 'more-info--small': isSmall }"
    (click)="toggleTooltip()"
    *ngIf="!additionalDeductions"
  >
    <ng-container *ngIf="!revert">
      <span class="more-info__text info-text is-unselectable" *ngIf="showText">{{
        "COMMON.MORE_INFO" | translate
      }}</span>
      <div
        class="more-info__img_wrap"
        [class.isXSmallIcon]="isXSmallIcon"
        [class.isSmallIcon]="isSmallIcon"
        [class.xl_img_mobile]="xlImgMobile"
        [class.no_text]="!showText"
        [class.displayTooltip]="displayTooltip"
      >
        <img class="more-info__img" [class.no_text]="!showText" src="../../../../../assets/svg/tooltip_icon.svg" />
      </div>
    </ng-container>
    <ng-container *ngIf="revert">
      <div
        class="more-info__img_wrap revert"
        [class.isXSmallIcon]="isXSmallIcon"
        [class.xl_img_mobile]="xlImgMobile"
        [class.isSmallIcon]="isSmallIcon"
        [class.displayTooltip]="displayTooltip"
      >
        <img class="more-info__img revert" src="../../../../../assets/svg/tooltip_icon.svg" />
      </div>
      <span class="more-info__text info-text is-unselectable revert" *ngIf="showText">{{
        "COMMON.MORE_INFO" | translate
      }}</span>
    </ng-container>
  </div>
  <ng-container *ngIf="additionalDeductions">
    <div
      class="more-info__img_wrap"
      [class.no_text]="!showText"
      [class.lg_img]="lgImgMobile"
      [class.xl_img_mobile]="xlImgMobile"
      [class.displayTooltip]="displayTooltip"
      [class.isXSmallIcon]="isXSmallIcon"
      [class.isSmallIcon]="isSmallIcon"
    >
      <img
        class="more-info__img"
        [class.lg_img]="lgImgMobile"
        [class.no_text]="!showText"
        src="../../../../../assets/svg/tooltip_icon.svg"
        *ngIf="!questionMark"
        (click)="toggleTooltip()"
      />
      <img
        class="more-info__img"
        [class.lg_img]="lgImgMobile"
        [class.no_text]="!showText"
        src="../../../../../assets/svg/tooltip_icon.svg"
        *ngIf="questionMark"
        (click)="toggleTooltip()"
      />
    </div>
  </ng-container>

  <!-- <div class="tooltip-container bottom" (click)="closeTooltip()" [class.tileTooltip]="tileTooltip"
        [class.tileTooltipMobile]="tileTooltipMobile" [class.tileBottom]="tileBottom"
        [ngClass]="{ 'align-left': alignLeft }" *ngIf="displayTooltip" role="tooltip" [class.revert]="revert">
        <div class="tooltip-container__close">
            <span class="fa fa-times-circle"></span>
        </div>
        <div class="tooltip-container__text">{{ message | translate }}</div>
    </div> -->
</div>
