import { Injectable } from '@angular/core';
import { AuthHttpService } from 'app/users';
import { getApiPath } from 'app/common';
import { takeUntil } from 'rxjs';

@Injectable()
export class CryptoCurrencyPartnersHttpService {

  constructor(private authHttp: AuthHttpService) { }

  getPartners() {
    return this.authHttp.get(`${getApiPath()}statements/partner-logos`, {}, true);
  }

  getPartnerLogo(parnetLogoApiPath: string){
    return this.authHttp.get<Blob>(`${window.location.origin}${parnetLogoApiPath}`, { responseType: 'blob'}, true);
  }
}
