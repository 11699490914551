export interface FirebaseNotificationResponse {
    id: number;
    user: number;
    statement: number;
    notification_name: string;
    event_one_triggered: Boolean;
    event_two_triggered: Boolean;
    notification_sent: Boolean;
    created: Date;
    updated: Date;
  }
  
  export class FirebaseNotification {
    id: number;
    user: number;
    statement: number
    notificationName: string;
    eventOneTriggered: Boolean;
    eventTwoTriggered: Boolean;
    notificationSent: Boolean;
    created: Date;
    updated: Date;
  
    static fromResponse(response: FirebaseNotificationResponse): FirebaseNotification {
      const notification = new FirebaseNotification();
      notification.id = response.id;
      notification.user = response.user;
      notification.statement = response.statement;
      notification.notificationName = response.notification_name;
      notification.eventOneTriggered = response.event_one_triggered;
      notification.eventTwoTriggered = response.event_two_triggered;
      notification.notificationSent = response.notification_sent;
      notification.created = response.created;
      notification.updated = response.updated;
      return notification;
    }

    toResponse(): FirebaseNotificationResponse {
      return {
        id: this.id,
        user: this.user,
        statement: this.statement,
        notification_name: this.notificationName,
        event_one_triggered: this.eventOneTriggered,
        event_two_triggered: this.eventTwoTriggered,
        notification_sent: this.notificationSent,
        created: this.created,
        updated: this.updated
      };
    }
  }
