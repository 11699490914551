import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-square-loader',
  templateUrl: './square-loader.component.html',
  styleUrls: ['./square-loader.component.scss'],
  standalone: true,
})
export class SquareLoaderComponent {
  @Input() size = 100;

  public lottieConfig: Object = {
    path: '../../../assets/anim/loader.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };

  constructor() { }
}
