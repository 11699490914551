import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'ngx-useful-swiper';

@Component({
  selector: 'app-taxcard-quality-info',
  templateUrl: './taxcard-quality-info.component.html',
  styleUrls: ['./taxcard-quality-info.component.scss']
})
export class TaxcardQualityInfoComponent implements OnInit {
  SLIDES_COUNT = 4;

  @Input() callback: any;
  @ViewChild('usefulSwiper', {static: false}) usefulSwiper: SwiperComponent;
  currentSlide = 0;

  config: SwiperOptions = {
    navigation: {
      nextEl: '.taxcard-quality__switch--next',
      prevEl:  '.taxcard-quality__switch--prev',
    },
    spaceBetween: 0,
    on: {
      slideChangeTransitionStart: (slider) => this.currentSlide = slider['activeIndex'] || 0
    },
  };

  constructor(private dialogRef: MatDialogRef<TaxcardQualityInfoComponent>) { }

  ngOnInit() {
  }

  onAccept() {
    if (typeof this.callback === 'function') {
      this.callback();
    }
    this.dialogRef.close();
  }
}
