import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Statement } from '../../statements';
import { CustomLabels } from 'app/common/labels-edit/CustomLabels';
import {finalize, takeUntil} from 'rxjs/operators';
import {saveFile, SnackbarConfig, SubscribingComponent} from '../../common';
import {SHOW_SNACKBAR} from '../../reducers/actions/snackbar';
import {Store} from '@ngrx/store';
import {State} from '../../reducers';
import {StatementService} from '../../statements/statement.service';

@Component({
  selector: 'app-list-view-b2b',
  templateUrl: './list-view-b2b.component.html',
  styleUrls: ['./list-view-b2b.component.scss']
})
export class ListViewB2bComponent extends SubscribingComponent implements OnInit {
  openedItems: boolean[] = [];
  @Input() statements: Statement[];
  @Input() hideEditing = false;
  @Input() hideRemoving = false;
  @Input() hideDecision = false;
  @Input() hideInvoice = false;
  @Input() hidePdf = false;
  @Input() showEditButton = false;
  @Input() labelList: CustomLabels[] = [];
  @Output() downloadPdf = new EventEmitter<Statement>();
  @Output() downloadInvoice = new EventEmitter<Statement>();
  @Output() deleteStatement = new EventEmitter<{ statement: Statement, listIndex: number }>();
  @Output() editButton = new EventEmitter<Statement>();
  @Output() cloneStatement = new EventEmitter<Statement>();
  @Output() importStatement = new EventEmitter<Statement>();

  private isSent = true;

  constructor(
    private statementService: StatementService,
    private store: Store<State>,
  ) {
    super();
  }

  ngOnInit() {
    this.clearOpenedItems();
  }

  showPDFError() {
    this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig('SNACKBAR.PDF_NOT_AVAILABLE', 'ERROR') });
  }

  emitDownloadPdf(statement: Statement) {
    statement.downloadingPdf = true;
    this.statementService.downloadStatementPdf(statement.id).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => statement.downloadingPdf = false))
      .subscribe(
        (file: Blob) => saveFile(file, `statement-${statement.id}.pdf`),
        this.showPDFError.bind(this)
      );
  }

  emitDownloadInvoice(statement: Statement) {
    this.downloadInvoice.emit(statement);
  }

  emitOnEditButton(statement: Statement) {
    this.editButton.emit(statement);
  }

  emitDeleteStatement(statement: Statement, listIndex: number) {
    this.deleteStatement.emit({ statement, listIndex });
  }

  public emitCloneStatement(statement: Statement) {
    this.cloneStatement.emit(statement);
  }

  public emitImportStatement(statement: Statement) {
    this.importStatement.emit(statement);
  }

  toggleItem(index) {
    this.clearOpenedItems();
    this.openedItems[index] = true;
  }

  clearOpenedItems() {
    this.openedItems = this.openedItems.map(item => false);
  }
}
