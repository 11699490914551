import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import {DeductionInputValues} from 'app/shared/modules/statement-deduction-base/models/deduction';
import { BankDetailComponentPath } from 'app/core/workflow/page-path/statement-page-path/bank-detail-component.path';

export class WorkflowBankDetailService extends WorkflowStepsBaseService {
  public get isRequired(): boolean {
    return !this.isBusiness;
  }

  public get isDone(): boolean {
    return this.canGo && this.statement && this.isBankAccountPassed;
  }

  public get checkCanGo(): boolean {
    if (!this.blockedDefaultWorkflow && this.statement && this.isRequired) {
      if (this.statement.stageTaxReliefs === DeductionInputValues.SKIP_OPTION) {
        return true;
      }

      if (this.hasChildren) {
        return this.statement.stageDeductionInfoChildren;
      }

      if (this.hasSpouseAndFilledTogether) {
        return this.statement.spouse.stageDeductionInfo;
      }

      return this.statement.stageDeductionInfo;
    }

    return false;
  }

  public get urlRegex(): RegExp {
    return BankDetailComponentPath.regex();
  }

  public get url(): string {
    return BankDetailComponentPath.url(this.getStatementId);
  }
}
