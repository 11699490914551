import { Statement } from 'app/statements';
import { ArrayHelper } from 'app/core/helpers/array.helper';
import { PensionIncome } from 'app/modules/statement/models/additional-income/pension-income.model';
import { PensionIncomeTypes } from 'app/modules/statement/models/additional-income/pension-income.types';

export class PensionIncomeManager {
  public static getPension(statement: Statement): PensionIncome[] {
    return PensionIncomeManager.get(
      statement,
      PensionIncomeTypes.PENSION
    );
  }

  public static createPension(statement: Statement): PensionIncome {
    return PensionIncomeManager.create(
      statement,
      PensionIncomeTypes.PENSION
    );
  }

  public static removeAllPension(statement: Statement): void {
    PensionIncomeManager.removeAll(
      statement,
      PensionIncomeTypes.PENSION
    );
  }

  public static removePension(statement: Statement, index: number): void {
    PensionIncomeManager.remove(
      statement,
      PensionIncomeTypes.PENSION,
      index
    );
  }

  public static get(statement: Statement, incomeType: PensionIncomeTypes): PensionIncome[] {
    return statement.pensionIncome;

    // If new types will appear
    // return ArrayHelper.arrayFilter(
    //   statement.pensionIncome,
    //   'incomeType',
    //   incomeType
    // );
  }

  public static create(statement: Statement, incomeType: PensionIncomeTypes): PensionIncome {
    const pensionIncome = new PensionIncome(incomeType);
    statement.pensionIncome.push(pensionIncome);

    return pensionIncome;
  }

  public static removeAll(statement: Statement, incomeType: PensionIncomeTypes): void {
    statement.pensionIncome = [];

    // If new types will appear
    // statement.pensionIncome = statement.pensionIncome.filter(x => x.incomeType !== incomeType);
  }

  public static remove(statement: Statement, incomeType: PensionIncomeTypes, index: number): void {
    statement.pensionIncome.splice(index, 1);

    // If new types will appear
    // const pensionIncomesList = PensionIncomeManager.get(statement, incomeType);
    // pensionIncomesList.splice(index, 1);
    // PensionIncomeManager.removeAll(statement, incomeType);
    //
    // statement.pensionIncome = [...statement.pensionIncome, ...pensionIncomesList];
  }
}
