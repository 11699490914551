<div class="ep_new_tiles_cont {{extraContainerClasses}}">
    <div class="ep_new_tiles_wrap">
        <ng-container *ngFor="let button of buttons; let i = index">
            <div class="ep_new_single_tile_wrap"
                [ngClass]="[inRow === 4 ? 'is-one-quarter' : 'is-half', isMarginOpenedInRow(i) ? 'margin-big' : '']"
                [class.hasError]="button.hasError" *ngIf="!button.hidden">
                <app-more-info-tooltip *ngIf="button.message" class="button-tooltip" [message]="button.message"
                    [tileTooltipMobile]="true" [additionalDeductions]=true></app-more-info-tooltip>
                <button mat-icon-button *ngIf="button.deleteAction && button.isSelected(buttonsData)"
                    class="button-delete" (click)="button.deleteAction(button)">
                    <span></span>
                </button>
              <button
                class="ep_new_single_tile"
                [class.bottom-straight]="button.extenderActive"
                [class.is-loading]="button.isLoading"
                [style.border]="button.hasError ? '1px solid red' : ''"
                [class.isRed]="button.hasError"
                (click)="button.clickAction(buttonsData, button)"
                [class.active]="button.isSelected(buttonsData)"
                [id]="button.currentId || ''"
              >
                    <div class="ep_desc_wrap">
                        <div class="icon_tile_wrap">
                            <img *ngIf="button.imageSrc" class="icon_tile" [src]="button.imageSrc" />
                            <img
                              *ngIf="button.imageSrcAction" class="icon_tile"
                              [src]="button.imageSrcAction(buttonsData)"
                            />
                        </div>
                        <span
                          class="title_tile_sm"
                          [innerHTML]="button.label | translate"
                        ></span>
                    </div>
                </button>
                <div class="info-extender hidden" *ngIf="!button.extenderActive"></div>
                <div class="info-extender" *ngIf="button.extenderActive">
                    <div class="level">
                        <div class="message-top level-left" *ngIf="button.textTop">{{ button.textTop | translate }}
                        </div>
                        <div class="level-right" *ngIf="button.extenderMessage">
                            <app-more-info-tooltip [tileTooltipMobile]="true" [message]="button.extenderMessage"
                                [sellect_button]="mit_sellect_button_style" [questionMark]=true
                                [additionalDeductions]=true></app-more-info-tooltip>
                        </div>
                    </div>
                </div>
                <a
                  *ngIf="button.goToAction && button.isSelected(buttonsData)"
                  (click)="button.goToAction(buttonsData)"
                  class="select-section__picker"
                  [class.extender-opened]="button.extenderActive"
                >
                  {{ 'COMMON.GO_TO' | translate }}<img src="../../../assets/img/gotoimg.png" />
                </a>
            </div>
        </ng-container>
    </div>
</div>
