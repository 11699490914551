<div class="bool-chooser">
  <div class="bool-chooser__title">
    <app-section-title
      [title]="title"
      [titleTooltip]="titleTooltip"
      [message]="messageTooltip"
      [isInnerHTML]="true"
      [tooltipContentRef]="tooltipContentRef"
    ></app-section-title>
  </div>
  <div class="bool-chooser__content">
    <div class="bool-chooser__content--buttons">
      <app-button-radio-two
        (clickEmitter)="handleModal ? handlePopupSelection($event) : handleSelectButtons($event)"
        [changeSelectOption]="handleModal ? false : true"
        [selectedOption]="selectedOption"
      ></app-button-radio-two>
    </div>
    <div class="bool-chooser__content--template" *ngIf="selectedOption">
      <ng-container *ngTemplateOutlet='templateRef'></ng-container>  
    </div>
  </div>
</div>
