import { Component } from '@angular/core';
import { CtrBaseComponent } from '../ctr-base.component';

@Component({
  ...CtrBaseComponent.metaData,
  selector: 'app-ctr-tax-card',
  templateUrl: './ctr-tax-card.component.html',
  styleUrls: ['../ctr-base.component.scss'],
})
export class CtrTaxCardComponent extends CtrBaseComponent {

}
