import * as deductionsInfo from './actions/deductions-info';


export interface State {
  deductionsInfo: string;
  deductionsSpouseInfo: string;
}

export const initialState: State = {
  deductionsInfo: '',
  deductionsSpouseInfo: ''
};

export function reducer(state = initialState, action: deductionsInfo.Actions): State {
  switch (action.type) {
    case deductionsInfo.SET_DEDUCTIONS_INFO: {
      return { ...state, deductionsInfo: action.payload };
    }

    case deductionsInfo.RESET_DEDUCTIONS_INFO: {
      return { ...state, deductionsInfo: '' };
    }

    case deductionsInfo.SET_SPOUSE_DEDUCTIONS_INFO: {
      return { ...state, deductionsSpouseInfo: action.payload };
    }

    case deductionsInfo.RESET_SPOUSE_DEDUCTIONS_INFO: {
      return { ...state, deductionsSpouseInfo: '' };
    }

    default: {
      return state;
    }
  }
}

export const getDeductionsInfo = (state: State) => state.deductionsInfo;
export const getDeductionsSpouseInfo = (state: State) => state.deductionsSpouseInfo;
