import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { AppComponent } from "app/app.component";
import { appModuleImports } from "app/app.module.imports";
import { appModuleProviders } from "app/app.module.providers";
import { ClickElsewhereDirective } from "app/shared/standalone/directives/click-elsewhere.directive";
import { appModuleDeclarations } from "./app.module.declarations";

@NgModule({
  declarations: [
    // ...appModuleDeclarations,
  ],
  imports: [...appModuleImports, ClickElsewhereDirective],
  providers: [...appModuleProviders],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {}
