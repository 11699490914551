import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { DeductionInfoComponentPath } from 'app/core/workflow/page-path/statement-page-path/deduction-info-component.path';
import { DeductionSkipComponentPath } from 'app/core/workflow/page-path/statement-page-path/deduction-skip-component.path';
import { DeductionInfoSpouseComponentPath } from 'app/core/workflow/page-path/statement-page-path/deduction-info-spouse-component.path';
import { B2bDeductionInfoSpouseComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.deduction-info-spouse-component.path';
import { B2bDeductionInfoComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.deduction-info-component.path';
import { DeductionInfoChildComponentPath } from 'app/core/workflow/page-path/statement-page-path/deduction-info-child-component.path';
import { B2bDeductionInfoChildComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.deduction-info-child-component.path';
import { DeductionInputValues } from 'app/shared/modules/statement-deduction-base/models/deduction';

export class WorkflowDeductionBaseService extends WorkflowStepsBaseService {
  public get isDeductionRoute(): boolean {
    const locationHref = window.location.href;

    return locationHref.search(DeductionSkipComponentPath.regex()) !== -1
      || locationHref.search(B2bDeductionInfoChildComponentPath.regex()) !== -1
      || locationHref.search(DeductionInfoChildComponentPath.regex()) !== -1
      || locationHref.search(B2bDeductionInfoComponentPath.regex()) !== -1
      || locationHref.search(DeductionInfoComponentPath.regex()) !== -1
      || locationHref.search(B2bDeductionInfoSpouseComponentPath.regex()) !== -1
      || locationHref.search(DeductionInfoSpouseComponentPath.regex()) !== -1;
  }

  public get stepClass(): string {
    return this.classLeftLineNone;
  }

  public get isSubStep(): boolean {
    return true;
  }

  public get isDeductionOptionAdd(): boolean {
    return (!!this.statement && this.statement.stageTaxReliefs === DeductionInputValues.ADD_OPTION) || this.isBusiness;
  }

  public get isDeductionOptionSkip(): boolean {
    return !!this.statement && this.statement.stageTaxReliefs === DeductionInputValues.SKIP_OPTION && !this.isBusiness;
  }
}
