import { Component, Input, OnInit } from "@angular/core";
import { AdditionalIncomeService } from "app/modules/statement/services/additional-income.service";
import { FreelanceJob } from "app/modules/statement/models/additional-income/freelance-job.model";
import { FreelanceJobSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/freelance-job.sub-steps";
import { SelectsData, SelectsDataInterface } from "app/statements";
import { Store } from "@ngrx/store";
import { getSelectsData, State } from "app/reducers";
import { SubscribingComponent } from "app/common";
import { takeUntil } from "rxjs";
import { VALIDATOR_FIELD_NAMES_PERSONAL_INFO } from "app/core/form-validator/validator-field-names/validator-field-names-personal-info";

@Component({
  selector: "app-additional-income-form-freelance",
  templateUrl: "./additional-income-form-freelance.component.html",
  styleUrls: ["./additional-income-form-freelance.component.scss"]
})
export class AdditionalIncomeFormFreelanceComponent extends SubscribingComponent implements OnInit {
  @Input() freelanceJobIncomeType: FreelanceJobSubSteps;
  @Input() freelanceJob: FreelanceJob;
  @Input() index = 0;

  public freelanceJobSteps = FreelanceJobSubSteps;
  public dataSelects: SelectsDataInterface = new SelectsData();
  public showIncomeViewTemplate: boolean = false;
  public showCostsViewTemplate: boolean = false;
  public showIncomeEditTemplate: boolean = false;
  public showCostsEditTemplate: boolean = false;
  public editIndex: number = 0;
  public incomeSelectValueList: [] = [];
  public costsSelectValueList: [] = [];

  constructor(
    public additionalIncomeService: AdditionalIncomeService,
    private store: Store<State>
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(getSelectsData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectsData: SelectsDataInterface) => {
        this.dataSelects = selectsData;
      });
  }

  public initEdit(index: number, formType: string) {
    this.editIndex = index;

    if (formType == "income") this.changeShowEditIncome(true);
    else this.changeShowEditCost(true);
  }

  public initAddValue(formType: string) {
    if (formType == "income") {
      this.changeShowIncomeViewTemplate(true);
      this.editIndex = this.freelanceJob.income_data.length;
    } else {
      this.changeShowCostsViewTemplate(true);
      this.editIndex = this.freelanceJob.income_costs.length;
    }
  }

  public changeShowIncomeViewTemplate(value: boolean) {
    this.showIncomeViewTemplate = value;
  }

  public changeShowCostsViewTemplate(value: boolean) {
    this.showCostsViewTemplate = value;
  }

  public changeShowEditIncome(value: boolean) {
    this.showIncomeEditTemplate = value;
  }

  public changeShowEditCost(value: boolean) {
    this.showCostsEditTemplate = value;
  }

  public handleFieldsValue(fieldName: string, value: boolean) {
    this.freelanceJob[fieldName] = value;
  }

  public handleLumpSum(value: boolean) {
    this.freelanceJob.has_lump_sum_costs = value;
    this.freelanceJob.income_costs = []; //reset this array on every lump sum change
  }

  public getCostsDataSelect() {
    return this.dataSelects.costs_types.filter((el) => {
      if (el[el.length - 2] == "income costs") return el;
    });
  }

  public getLumpDataSelect() {
    return this.dataSelects.costs_types.filter((el) => {
      if (el[el.length - 2] == "lump sum costs") return el;
    });
  }

  public aksForCorona(): boolean {
    return [2020, 2021, 2022].includes(this.additionalIncomeService.getMainStatement().fullYear);
  }

  public checkIfItIs2020() {
    return [2020].includes(this.additionalIncomeService.getMainStatement().fullYear); //TODO: remove after 2 moths, it is temporary solution
  }
}
