import { BaseStatementPagePath } from "app/core/workflow/page-path/statement-page-path/base-statement-page.path";

export class ThankYouComponentPath extends BaseStatementPagePath {
  public static path(): string {
    return "statement/:statementId/thank-you";
  }

  public static pathWithHash(): string {
    return "statement/:statementId/thank-you/:hash";
  }

  public static regex(): RegExp {
    return /\/statement\/\d+\/thank-you((\/?#?)|(\/?#.*?))$/;
  }
}
