export const VALIDATOR_FIELD_NAMES_DEDUCTIONS = {
  job_equipment_cost_name: "job_equipment_cost_name",
  job_equipment_costs: "job_equipment_costs",
  work_wear_cost: "work_wear_cost",
  work_wear_washing_cost: "work_wear_washing_cost",
  training_cost_names: "training_cost_names",
  training_costs: "training_costs",
  recruitment_costs: "recruitment_costs",
  trade_union_name: "trade_union_name",
  trade_union_contribution: "trade_union_contribution",
  home_office_covid19_day_in_home: "home_office_covid19_day_in_home",
  home_office_costs_name: "home_office_costs_name",
  home_office_costs: "home_office_cost_value",
  place_employment_address: "place_employment_address",
  period_employment_lower: "period_employment_lower",
  period_employment_upper: "period_employment_upper",
  working_days_per_week: "working_days_per_week",
  absences_work: "absences_work",
  presences_work: "presences_work",
  distance_from_work: "distance_from_work",
  public_transport_cost: "public_transport_cost",
  car_travels_distance: "car_travels_distance",
  car_travels_number: "car_travels_number",
  travel_tickets_cost_value: "travel_tickets_cost_value",
  accommodation_costs: "accommodation_cost_value",
  employer_refund_value: "employer_refund_value",
  days_one_day_travel: "days_one_day_travel",
  days_travel_to_work: "days_travel_to_work",
  days_travel_from_work: "days_travel_from_work",
  days_in_multi_day_travel: "days_in_multi_day_travel",
  free_meals_diet_amount: "free_meals_diet_amount",
  travel_abroad_country: "travel_abroad_country",
  travel_abroad_city: "travel_abroad_city",
  days_in_one_day_travel_abroad: "days_in_one_day_travel_abroad",
  days_in_travel_abroad_to_work: "days_in_travel_abroad_to_work",
  days_in_travel_abroad_from_work: "days_in_travel_abroad_from_work",
  days_in_multi_day_travel_abroad: "days_in_multi_day_travel_abroad",
  free_meals_diet_amount_abroad: "free_meals_diet_amount_abroad",
  driver_lump_sum: "driver_lump_sum",
  moving_expanses_costs_names: "moving_expanses_costs_names",
  moving_expanses_costs: "moving_expanses_costs",
  household_postal_code: "house_hold_postal_code",
  household_city: "household_city",
  household_country: "household_country",
  household_date_from: "household_date_from",
  residence_at_place_of_employment_postal_code: "residence_at_place_of_employment_postal_code",
  residence_at_place_of_employment_city: "residence_at_place_of_employment_city",
  period_of_residence_at_place_of_employment_lower: "period_of_residence_at_place_of_employment_lower",
  period_of_residence_at_place_of_employment_upper: "period_of_residence_at_place_of_employment_upper",
  residence_at_place_of_employment_costs: "residence_at_place_of_employment_costs",
  first_and_last_travel_kilometers: "first_and_last_travel_kilometers",
  first_and_last_travel_ticket_costs: "first_and_last_travel_ticket_costs",
  weekend_trips_distance: "weekend_trips_distance",
  weekend_trips_number: "weekend_trips_number",
  weekend_trips_ticket_costs: "weekend_trips_ticket_costs",
  days_spent_in_travel: "days_spent_in_travel",
  days_spent_at_place_of_employment: "days_spent_at_place_of_employment",
  other_costs_name: "other_costs_name",
  other_costs: "other_costs",
  house_hold_mini_jobs_costs_names: "house_hold_mini_jobs_costs_names",
  house_hold_mini_jobs_costs: "house_hold_mini_jobs_costs",
  house_hold_services_costs_names: "house_hold_services_costs_names",
  house_hold_services_costs: "house_hold_services_costs",
  craftsman_services_costs_names: "craftsman_services_costs_names",
  craftsman_services_costs: "craftsman_services_costs",
  social_security_number: "social_security_number",
  agreement_numbers: "agreement_numbers",
  contributions_amount: "contributions_amount",
  last_year_income: "last_year_income",
  contributions_to_private_health_insurance: "contributions_to_private_health_insurance",
  private_long_term_care_insurance_contributions: "private_long_term_care_insurance_contributions",
  health_and_private_insurance_contributions_refunds: "health_and_private_insurance_contributions_refunds",
  health_and_private_insurance_contributions_grants: "health_and_private_insurance_contributions_grants",
  private_elective_and_supplementary_insurance: "private_elective_and_supplementary_insurance",
  additional_long_term_care_insurance: "additional_long_term_care_insurance",
  contributions_to_health_insurance: "contributions_to_health_insurance",
  contributions_to_sickness_benefit: "contributions_to_sickness_benefit",
  long_term_care_insurance_contributions: "long_term_care_insurance_contributions",
  health_and_insurance_contributions_refunds: "health_and_insurance_contributions_refunds",
  sickness_benefit_reimbursements: "sickness_benefit_reimbursements",
  health_and_insurance_contributions_grants: "health_and_insurance_contributions_grants",
  elective_and_supplementary_insurance: "elective_and_supplementary_insurance",
  contributions_to_foreign_health_insurance: "contributions_to_foreign_health_insurance",
  contributions_to_foreign_sickness_benefit: "contributions_to_foreign_sickness_benefit",
  foreign_long_term_care_insurance_contributions: "foreign_long_term_care_insurance_contributions",
  health_and_foreign_insurance_contributions_refunds: "health_and_foreign_insurance_contributions_refunds",
  foreign_sickness_benefit_reimbursements: "foreign_sickness_benefit_reimbursements",
  foreign_elective_and_supplementary_insurance: "foreign_elective_and_supplementary_insurance",
  foreign_social_insurance_employee_contributions: "foreign_social_insurance_employee_contributions",
  foreign_social_insurance_employer_contributions: "foreign_social_insurance_employer_contributions",
  profession_insurance_cost_names: "profession_insurance_cost_names",
  profession_insurance_costs: "profession_insurance_costs",
  donation_de_name: "donation_de_name",
  donation_de_value: "donation_de_value",
  donation_eu_name: "donation_eu_name",
  donation_eu_value: "donation_eu_value",
  donation_political_name: "donation_political_name",
  donation_political_value: "donation_political_value",
  tax_settlement_costs: "tax_settlement_costs",
  phone_and_internet_costs: "phone_and_internet_costs",
  winterbau_costs: "winterbau_costs",
  bank_costs: "bank_costs",
  other_recruitment_cost_names: "other_recruitment_cost_names",
  other_recruitment_costs: "other_recruitment_costs",
  health_cost_name: "health_cost_name",
  health_cost: "health_cost",
  health_cost_refund: "health_cost_refund",
  care_cost_name: "care_cost_name",
  care_cost: "care_cost",
  care_cost_refund: "care_cost_refund",
  disability_percent: "disability_percent",
  disability_cost_name: "disability_cost_name",
  disability_cost: "disability_cost",
  disability_refund_amount: "disability_refund_amount",
  disability_pflegegrad_caretaker: "disability_pflegegrad_caretaker",
  disability_pflegegrad: "disability_pflegegrad",
  disabled_person_data: "disabled_person_data"
};
