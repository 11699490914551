import { ActivatePagePath } from 'app/core/workflow/page-path/activate-page.path';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageRecognizerService {
  private _isActivationPage = false;
  private _isStatementFlowPages = false;
  private _isPrivacyPolicyPage = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public static checkIsActivationPage(): boolean {
    return window.location.href.search(ActivatePagePath.regex()) !== -1;
  }

  public static checkIsStatementFlowPages(): boolean {
    return window.location.href.search(new RegExp(/\/new\/statement\//)) !== -1;
  }

  public static checkIsPrivacyPolicyPage(): boolean {
    return window.location.href.search(new RegExp(/privacy-policy/)) !== -1;
  }

  constructor(
    private router: Router,
  ) {
    this._isActivationPage = PageRecognizerService.checkIsActivationPage();
    this._isStatementFlowPages = PageRecognizerService.checkIsStatementFlowPages();
    this._isPrivacyPolicyPage = PageRecognizerService.checkIsPrivacyPolicyPage();

    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this._isActivationPage = PageRecognizerService.checkIsActivationPage();
      this._isStatementFlowPages = PageRecognizerService.checkIsStatementFlowPages();
    });
  }

  public init(_ngUnsubscribe: Subject<void>): void {
    this.ngUnsubscribe = _ngUnsubscribe;
  }

  public get isActivationPage(): boolean {
    return this._isActivationPage;
  }

  public get isStatementFlowPages(): boolean {
    return this._isStatementFlowPages;
  }

  public get isPrivacyPolicyPage (): boolean {
    return this._isPrivacyPolicyPage;
  }
}
