import { Component, Input } from "@angular/core";
import { NumberFormatHelper } from "app/core/helpers/number-format.helper";

@Component({
  selector: "app-tooltip-voluntering-income",
  templateUrl: "./tooltip-voluntering-income.component.html",
  styleUrls: ["./tooltip-voluntering-income.component.scss"]
})
export class VolunteringIncomeTooltipTemplate {
  constructor() {}
}
