<div class="step-container columns is-desktop">
    <app-aside-bar [showEvenWithoutStatement]="false" [statement]="statement"></app-aside-bar>
    <div class="congratulations">
        <h1 class="title">{{ 'CONGRATS.TITLE' | translate }}</h1>
        <div class="description" *ngIf="statement && statement.typeOfSending === 'no_signature'">{{ 'CONGRATS.DESCRIPTION_NOT_SIGNED' | translate }}</div>
        <div class="description" *ngIf="statement && statement.typeOfSending !== 'no_signature'">{{ 'CONGRATS.DESCRIPTION_SIGNED' | translate }}</div>
        <div class="congratulations__steps form-content" *ngIf="statement && statement.typeOfSending === 'no_signature'">
            <div class="columns">
                <div class="congratulations__step column is-4-desktop">
                    <div class="congratulations__step-ico">
                        <img src="../../../assets/svg/download.svg" alt="download" />
                    </div>
                    <div class="congratulations__step-label">{{ 'CONGRATS.DOWNLOAD_FORM' | translate }}</div>
                </div>
                <div class="congratulations__step column is-4-desktop">
                    <div class="congratulations__step-ico">
                        <img src="../../../assets/svg/note.svg" alt="sign document" />
                    </div>
                    <div class="congratulations__step-label">{{ 'CONGRATS.SIGN' | translate }}</div>
                    <div class="congratulations__step-description">
                        <div class="congratulations__step-description-excl">!</div>
                        <div class="congratulations__step-description-text">
                            {{ 'CONGRATS.SIGN_DESCRIPTION' | translate }}
                        </div>
                    </div>
                </div>
                <div class="congratulations__step column is-4-desktop">
                    <div class="congratulations__step-ico">
                        <img src="../../../assets/svg/send.svg" alt="sign document" />
                    </div>
                    <div class="congratulations__step-label">{{ 'CONGRATS.SEND' | translate }}</div>
                    <div class="congratulations__step-description">
                        <div class="congratulations__step-description-excl">!</div>
                        <div class="congratulations__step-description-text"
                             *ngIf="statement && selectsData && selectsData.taxAuthorities">
                            <div>{{ 'CONGRATS.YOUR_TAX_OFFICE' | translate }}:</div>
                            <div>{{ getTaxOffice() }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="congratulations__summary form-content">
            <app-card-view-item
                    [statement]="statement"
                    [hideEditing]="true"
                    [hideRemoving]="true"
                    (downloadPdf)="printStatement()"
                    (downloadInvoice)="downloadInvoice()"
                    (showDecision)="showDecision()">
            </app-card-view-item>
        </div>
        <div class="congratulations__action">
            <div class="congratulations__action-description">{{ 'CONGRATS.THANKS_TEXT' | translate }}</div>
            <div class="congratulations__action-btn">
                <button class="btn btn--primary btn--block"
                        [routerLink]="['/dashboard']">{{ 'CONGRATS.VIEW_DASHBOARD' | translate }}</button>
            </div>
        </div>
    </div>
</div>
