<!-- 
<div class="mb-1">
  <app-form-input
    [errors]="additionalIncomeService.getError('income', index)"
    [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INPUT.INCOME.LABEL'"
    [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
    [(model)]="cryptocurrencyIncome.income"
    [isCurrency]="true"
  ></app-form-input>
  <app-validator-error-message
    [epValidator]="additionalIncomeService.epValidator"
    [index]="index"
    [fieldName]="additionalIncomeService.VALIDATOR_KEYS.cryptocurrency_income"
  ></app-validator-error-message>
</div>
<div class="mb-1">
  <app-form-input
    [errors]="additionalIncomeService.getError('costs', index)"
    [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INPUT.COSTS.LABEL'"
    [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
    [(model)]="cryptocurrencyIncome.costs"
    [isCurrency]="true"
  ></app-form-input>
  <app-validator-error-message
    [epValidator]="additionalIncomeService.epValidator"
    [index]="index"
    [fieldName]="additionalIncomeService.VALIDATOR_KEYS.cryptocurrency_costs"
  ></app-validator-error-message>
</div> -->
<div class="mb-1">
  <app-form-input
    [errors]="additionalIncomeService.getError('income', index)"
    [label]="'ADDITIONAL_INCOME.FREELANCE_GROUP.INPUT.INCOME_NET.LABEL'"
    [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
    [(model)]="cryptocurrencyIncome.incomeNet"
    [isCurrency]="true"
  ></app-form-input>
  <app-validator-error-message
    [epValidator]="additionalIncomeService.epValidator"
    [index]="index"
    [fieldName]="additionalIncomeService.VALIDATOR_KEYS.cryptocurrency_income_net"
  ></app-validator-error-message>
</div>
