import { Component, OnInit } from '@angular/core';
import { LottieComponent } from 'ngx-lottie';
import { AnimationOptions } from 'ngx-lottie/lib/symbols';

@Component({
  selector: 'app-content-loader',
  templateUrl: './content-loader.component.html',
  styleUrls: ['./content-loader.component.scss'],
  standalone: true,
  imports: [
    LottieComponent,
  ]
})
export class ContentLoaderComponent implements OnInit {

  constructor() { }

  public lottieConfig: AnimationOptions<'canvas'> = {
    path: '../../../assets/anim/loader.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  }

  ngOnInit() {
  }

}
