import { BaseStatementPagePath } from 'app/core/workflow/page-path/statement-page-path/base-statement-page.path';

export class AdditionalIncomeComponentPath extends BaseStatementPagePath {
  public static path(): string {
    return 'statement/:statementId/additional-income/:step';
  }

  public static regex(step?: number): RegExp {
    return this.componentRegex(this.path(), null, step);
  }
}
