import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getViewSpinner, State } from 'app/reducers';
import { takeUntil } from 'rxjs/operators';
import { SubscribingComponent } from 'app/common/subscribing-component';
import { LottieComponent } from 'ngx-lottie';
import { CommonModule } from '@angular/common';
import { AnimationOptions } from 'ngx-lottie/lib/symbols';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LottieComponent,
  ]
})
export class SpinnerComponent extends SubscribingComponent implements OnInit {
  @Input() isGlobal = false;
  public spinnerOn$ = new BehaviorSubject(false);

  public lottieConfig: AnimationOptions<'canvas'> = {
    path: '../../../assets/anim/loader.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };

  constructor(private store: Store<State>) {
    super();
  }

  ngOnInit() {
    if (!this.isGlobal) {
      return;
    }

    this.store.select(getViewSpinner).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((spinnerOn: boolean) => {
        this.spinnerOn$.next(spinnerOn);
      });
  }
}
