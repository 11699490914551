<div class="header-bar" [ngClass]="{'header-bar--logged': isUserLogged, 'is-hidden-touch': isUserLogged}">
  <div class="level">
    <div class="level-left level-bar is-hidden-touch" [class.nb]="!isBusiness && !isTaxAdvisor" [ngClass]="{ 'level-bar--logout': !isUserLogged}">
      <div class="logo-wrapper level-item">
        <a routerLink="/dashboard">
          <img *ngIf="isUserLogged; else nonLoggedLogo" src="assets/svg/logo_sm.svg">
          <ng-template #nonLoggedLogo>
            <img src="assets/svg/logo_sm.svg">
          </ng-template>
        </a>
      </div>
      <div class="header-bar__search-engine" *ngIf="isUserLogged">
        <ng-container *ngIf="isBusiness || isTaxAdvisor">
          <app-search-engine></app-search-engine>
        </ng-container>
      </div>
    </div>
    <div class="level-right logout-wrapper is-hidden-touch" [class.nb]="!isBusiness && !isTaxAdvisor" *ngIf="isUserLogged">
      <button *ngIf="!isBusiness" class="coupons-btn" (click)="openCoupons()">{{"COUPONS_HEADER" | translate}}</button>
      <span *ngIf="isBusiness" class="username level-item">{{ (userLogin?.length>20)? (userLogin | slice:0:20)+'...':(userLogin || '') }}</span>
      <app-choose-language></app-choose-language>
      <div class="header-bar__hamburger" (click)="toggleSideNav()">
        <div class="header-bar__hamburger-stage"></div>
        <div class="header-bar__hamburger-stage"></div>
        <div class="header-bar__hamburger-stage"></div>
      </div>
    </div>
    <div class="level-right is-hidden-touch" *ngIf="!isUserLogged">
      <div class="level-item header-bar__lang">
        <app-choose-language class="header-bar__lang-wr" [mode]="'header'"></app-choose-language>
      </div>
      <div class="level-item header-bar__lang">
        <app-my-account [mobile]="false"></app-my-account>
      </div>
    </div>
    <div class="level-right is-hidden-desktop" *ngIf="!isUserLogged">
      <div class="logo-wrapper level-item">
        <a routerLink="/">
          <img src="assets/svg/logo_sm.svg">
        </a>
      </div>
      <div class="level-item header-bar__lang">
        <app-choose-language class="header-bar__lang-wr" [mode]="'header'"></app-choose-language>
        <app-my-account [mobile]="true"></app-my-account>
      </div>
    </div>
  </div>
  <ng-container *ngIf="workflowControllerService.isWorkflowHeaderProgressShown">
    <div id="headerProgress" [style.width]="(workflowControllerService.statement | statementProgressPipe) + '%'"></div>
  </ng-container>
</div>
