import {
  WorkflowAdditionalIncomeApplicantSubBaseService
} from 'app/core/workflow/workflow-steps/applicant/sub/workflow-additional-income-applicant-sub-base.service';

export class WorkflowAdditionalIncomeOneService extends WorkflowAdditionalIncomeApplicantSubBaseService {
  public get title(): string {
    return 'ASIDE_BAR.ADDITIONAL_INCOME_SUBLIST.HEALTH_BENEFITS';
  }

  public get subStepNumber(): number {
    return 1;
  }
}
