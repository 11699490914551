import { ArrayHelper } from 'app/core/helpers/array.helper';

export class RecognizerHelper {
  public static isNotEmpty(value: any): boolean {
    if (Array.isArray(value)) {
      return ArrayHelper.isArrayAndNotEmptyAndFirstValueIsTrue(value, true);
    }

    if (RecognizerHelper.isBoolean(value)) {
      return true;
    }

    return !!value;
  }

  public static isTrue(value: any): boolean {
    if (Array.isArray(value)) {
      return ArrayHelper.isArrayAndNotEmptyAndFirstValueIsTrue(value);
    }

    return !!value;
  }

  public static isUndefined(value: any): boolean {
    return value === undefined;
  }

  public static isNullOrUndefined(value: any): boolean {
    return value === null || RecognizerHelper.isUndefined(value);
  }

  public static isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  public static isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  public static isString(value: any): boolean {
    return typeof value === 'string';
  }

  public static isFunction(value: any): boolean {
    return typeof value === 'function';
  }
}
