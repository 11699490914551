import { Injectable } from '@angular/core';
import { AuthHttpService } from 'app/users';
import { firstValueFrom, of } from 'rxjs';
import { getApiPath } from 'app/common';
import { catchError, map, take } from 'rxjs/operators';
import { OpinionRequest, OpinionRequestResponseInterface } from 'app/core/models/opinion-request/OpinionRequest.model';
import { HttpResponseInterface } from 'app/core/models/http/http-response.interface';
import { HttpResponseModel } from 'app/core/models/http/http-response.model';

@Injectable({
  providedIn: 'root',
})
export class RateAppHttpService {
  constructor(
    private authHttp: AuthHttpService,
  ) {}

  public async getOpinionRequest(): Promise<HttpResponseInterface<OpinionRequest>> {
    return await firstValueFrom(
      this.authHttp.get<OpinionRequestResponseInterface>(`${getApiPath()}opinion-request`, {}, true)
        .pipe(
          take(1),
          map((response) =>
            HttpResponseModel.fromResponse<OpinionRequest>(response ? OpinionRequest.fromResponse(response) : null)),
          catchError(() => of(HttpResponseModel.fromError<null>(null))),
        )
    );
  }

  public async markThatOpinionIsSent(
    payload: OpinionRequestResponseInterface
  ): Promise<HttpResponseInterface<OpinionRequest>> {
    return await firstValueFrom(
      this.authHttp.put<OpinionRequestResponseInterface>(`${getApiPath()}opinion-request`, payload, {}, true)
        .pipe(
          take(1),
          map((response) =>
            HttpResponseModel.fromResponse<OpinionRequest>(response ? OpinionRequest.fromResponse(response) : null)),
          catchError(() => of(HttpResponseModel.fromError<null>(null))),
        )
    );
  }
}
