<ng-container *ngIf="message">
    <div class="error-message">
        <div class="error-message__container">
            <div class="error-message__ico">
                <img class="edit_icon" src="/assets/svg/employer_erorr.svg" alt="Error"/>
            </div>
            <div class="error-message__message">
                <b>Error:</b> {{ message }}
            </div>
            <div class="error-message__close" (click)="hideMessage()">
                <i class="tiny material-icons">close</i>
            </div>
        </div>
    </div>
    <div class="error-message fake">
        <div class="error-message__container">
            <div class="error-message__ico">
                <img class="edit_icon" src="/assets/svg/employer_erorr.svg" alt="Error"/>
            </div>
            <div class="error-message__message">
                <b>Error:</b> {{ message }}
            </div>
            <div class="error-message__close" (click)="hideMessage()">
                <i class="tiny material-icons">close</i>
            </div>
        </div>
    </div>
</ng-container>
