import { of as observableOf, Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap, takeUntil, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthHttpService, AuthService } from "../users";
import { Statement } from "./statement";
import { SelectsData } from "./selects-data";
import { MaritalStatus } from "./marital-status";
import { TaxAuthority } from "./tax-authority";
import { FinanzamtAddress } from "./finanzamt-address";
import { Employer } from "./employer";
import { RefundDetails } from "./refund-details";
import { getApiPath } from "../common";
import { FilterStatementsPayload } from "app/modules/statement/models/statement/filter-statements.model";
import { getAuthUser, getUser, getUserType, State } from "app/reducers";
import { AuthModel } from "app/modules/user/models/auth/auth.model";
import { Store } from "@ngrx/store";
import { TOKEN_REFRESH } from "app/reducers/actions/auth-user";

export interface StatementsResponse {
  statements: Statement[];
}

export interface EmployersResponse {
  employers: Statement[];
}

export interface BatchesPaginatedResponse {
  total_pages: number;
  results: any[];
}

export interface StatementsPaginatedResponse {
  total_pages: number;
  results: any[];
}

export interface CanBeFilledResponse {
  result: boolean;
}

@Injectable()
export class StatementService {
  private loggedUser: AuthModel;

  constructor(
    private authHttp: AuthHttpService,
    private store: Store<State>,
    private authService: AuthService
  ) {
    this.store.select(getAuthUser).subscribe((user: AuthModel) => (this.loggedUser = user));
  }
  ngUnsubscribe(ngUnsubscribe: any): any {
    throw new Error("Method not implemented.");
  }

  public get getFromStorage(): Statement {
    let result: Statement = new Statement();

    if (this.isExistsInLocalStorage()) {
      try {
        const statement = JSON.parse(localStorage.getItem("statement"));

        if (statement) {
          result = Statement.fromStorage(statement as Statement);
        }
      } catch (e) {}
    }

    return result;
  }

  public isExistsInLocalStorage(): boolean {
    return !!localStorage.getItem("statement");
  }

  public setToLocalStorage(statement: Statement): void {
    localStorage.setItem("statement", JSON.stringify(statement));
  }

  public removeFromLocalStorage(): void {
    localStorage.removeItem("statement");
  }

  getStatements(searchValue?: string): Observable<StatementsResponse> {
    const searchParam = searchValue ? `?search=${searchValue}` : "";
    return this.authHttp.get(`${getApiPath()}statements/${searchParam}`).pipe(
      map((response: any) => {
        return {
          statements: response.map((el) => Statement.fromResponse(el, false))
        };
      }),
      catchError((_) => observableOf({ statements: [] }))
    );
  }

  getEmployers(statementId: number): Observable<EmployersResponse> {
    return this.authHttp.get(`${getApiPath()}statements/${statementId}/employers/`).pipe(
      map((response: any) => {
        return {
          employers: response.map(Employer.fromResponse)
        };
      }),
      catchError((_) => observableOf({ employers: [] }))
    );
  }

  uploadStatement(
    file,
    statementId?: number,
    isGuest = false,
    isForce = false,
    captchaToken?: string
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("files", file, file.name);

    if (statementId) {
      formData.append("statement_id", statementId.toString());
      formData.append("action", "update");
    }

    if (isForce) {
      formData.append("method", "force");
    }

    formData.append("cf-turnstile-response", captchaToken);

    return this.authHttp
      .post(`${getApiPath()}statements/upload/`, formData)
      .pipe(map((response: Response) => response));
  }

  uploadEricJson(file, many = false, statementId = null, batchId = null): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("uploaded_file", file, file.name);
    if (many) {
      formData.append("many", "True");
    } else {
      formData.append("many", "False");
    }
    if (statementId) {
      formData.append("statement_id", statementId.toString());
    }
    if (batchId) {
      formData.append("batch_id", batchId.toString());
    }
    return this.authHttp
      .post(`${getApiPath()}statements/eric-upload/`, formData)
      .pipe(map((response: Response) => response));
  }

  uploadStatementGuest(file, uuid: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("files", file, file.name);
    formData.append("uuid", uuid);

    return this.authHttp
      .post(`${getApiPath()}statements/upload/guest/`, formData)
      .pipe(map((response: Response) => response));
  }

  getStatement(statementId: number): Observable<Statement> {
    return this.authHttp
      .get(`${getApiPath()}statements/${statementId}/`)
      .pipe(map((response: any) => Statement.fromResponse(response)));
  }

  getStatementGuest(uuid: string): Observable<Statement | null> {
    return this.authHttp.get(`${getApiPath()}statements/guest/${uuid}`).pipe(
      map((response: Response) => {
        const statements = response;
        if (statements && statements[0]) {
          return Statement.fromResponse(statements[0]);
        }

        return null;
      })
    );
  }

  getBatchStatements(batchId: number): Observable<StatementsResponse> {
    return this.authHttp.get(`${getApiPath()}statements/?batch_statement_id=${batchId}`).pipe(
      map((response: any) => {
        const statements: Statement[] = [];
        response.forEach((item) => {
          statements.push(Statement.fromResponse(item, false));
        });

        return {
          statements: statements
        };
      }),
      catchError((_) => observableOf({ statements: [] }))
    );
  }

  getBatchStatus(taskId: string): Observable<any> {
    return this.authHttp
      .get(`${getApiPath()}statements/tasks/${taskId}/status/`)
      .pipe(map((response: Response) => response));
  }

  createBatch(): Observable<any> {
    return this.authHttp.post(`${getApiPath()}statements/batches/`, {});
  }

  deleteBatch(batchId: number): Observable<any> {
    return this.authHttp.remove(`${getApiPath()}statements/batches/${batchId}/`);
  }

  verifyBatch(batchCode: string): Observable<any> {
    return this.authHttp.put(`${getApiPath()}statements/${batchCode}/verify/`, {});
  }

  getBatches(page?: number): Observable<BatchesPaginatedResponse> {
    return this.authHttp
      .get(`${getApiPath()}statements/batches/`, page ? { params: { page } } : {})
      .pipe(map((response: any) => response));
  }

  getPaginatedStatements(page?: number): Observable<BatchesPaginatedResponse> {
    return this.authHttp
      .get(`${getApiPath()}statements/paginated/`, page ? { params: { page } } : {})
      .pipe(map((response: any) => response));
  }

  getPaginatedStatementsForYear(page?: number, year?: number) {
    return this.authHttp.get(
      `${getApiPath()}statements/list-year-statements/${year}/`,
      page ? { params: { page } } : {}
    );
  }

  getStatementInProgress() {
    return this.authHttp.get(`${getApiPath()}statements/paginated-not-sent/`).pipe(map((response: any) => response));
  }

  filterBatches(filters: FilterStatementsPayload): Observable<any> {
    return this.authHttp.get(`${getApiPath()}statements/filter-batches/`, filters.page ? { params: filters } : {});
  }

  filterStatementsTaxAdviser(filterStatements: FilterStatementsPayload): Observable<any> {
    return this.authHttp.get(`${getApiPath()}statements/filter-statement-tax-adviser/`, { params: filterStatements });
  }

  searchBatches(searchValue?: string): Observable<any> {
    return this.authHttp
      .get(`${getApiPath()}statements/search-batches/`, searchValue ? { params: { search: searchValue } } : {})
      .pipe(map((response: any) => response));
  }

  searchStatementsTaxAdviser(searchValue?: string): Observable<any> {
    return this.authHttp
      .get(
        `${getApiPath()}statements/search-statements-tax-adviser/`,
        searchValue ? { params: { search: searchValue } } : {}
      )
      .pipe(map((response: any) => response));
  }

  sendBatch(batchCode: string, isCommonCert = false): Observable<Response> {
    const body = {};
    if (isCommonCert) {
      body["common_certificate"] = true;
    }
    return this.authHttp.put(`${getApiPath()}statements/${batchCode}/send/`, body);
  }

  getEmployer(statementId: number, employerId: number): Observable<Employer | any> {
    return this.authHttp.get(`${getApiPath()}statements/${statementId}/employers/${employerId}/`).pipe(
      map((response: any) => Employer.fromResponse(response)),
      catchError((error: any) => observableOf(new Error(error.non_field_errors)))
    );
  }

  getSelectsData(): Observable<SelectsData | any> {
    return this.authHttp.get(`${getApiPath()}statements/data-selects/`).pipe(
      map((response: any) => SelectsData.fromResponse(response)),
      catchError((error: any) => observableOf(new Error(error.non_field_errors)))
    );
  }

  getMaritalStatuses(): Observable<MaritalStatus[]> {
    return this.authHttp.get(`${getApiPath()}statements/marital-statuses/`).pipe(
      map((response: any) => response.map(MaritalStatus.fromResponse)),
      catchError(() => observableOf([]))
    );
  }

  createStatement(data?): Observable<Statement> {
    const body = data ? data : {};
    return this.authHttp.post(`${getApiPath()}statements/`, body).pipe(
      map((response: any) => Statement.fromResponse(response)),
      catchError((error: any) => observableOf(error))
    );
  }

  assignStatement(uuid: string): Observable<null> {
    const payload = { uuid };

    return this.authHttp.post(`${getApiPath()}statements/assign-user`, payload).pipe(
      map((response: any) => observableOf(null)),
      catchError((error: any) => observableOf(error))
    );
  }

  createMultipleEmployers(statementId: number, quantity: number): Observable<EmployersResponse> {
    const payload = quantity ? { quantity } : {};
    return this.authHttp.post(`${getApiPath()}statements/${statementId}/employers/`, payload).pipe(
      map((response: any) => {
        const employers: Statement[] = [];
        response.forEach((item) => {
          employers.push(Statement.fromResponse(item));
        });
        return {
          employers: employers
        };
      }),
      catchError((error: any) => observableOf(error))
    );
  }

  createEmployer(statementId: number, data = {}): Observable<Employer> {
    return this.authHttp
      .post(`${getApiPath()}statements/${statementId}/employers/`, data)
      .pipe(map((response: any) => Employer.fromResponse(response)));
  }

  updateStatement(statementId: number, data): Observable<Statement> {
    return this.authHttp
      .patch(`${getApiPath()}statements/${statementId}/`, data)
      .pipe(map((response: any) => Statement.fromResponse(response)));
  }

  // Use this only for PAID_READY STATUS after onfido passes
  updateStatementStatus(statementId: number) {
    return this.authHttp.put(`${getApiPath()}statements/${statementId}/update-status/`, {});
  }

  updateEmployer(statementId: number, employerId: number, data): Observable<Employer> {
    return this.authHttp
      .patch(`${getApiPath()}statements/${statementId}/employers/${employerId}/`, data)
      .pipe(map((response: any) => Employer.fromResponse(response)));
  }

  updateEmployers(statementId: number, data): Observable<Employer[]> {
    return this.authHttp.patch(`${getApiPath()}statements/${statementId}/employers/`, data).pipe(
      map((response: any) => {
        const employers: Employer[] = [];
        response.forEach((item) => {
          employers.push(Employer.fromResponse(item));
        });
        return employers;
      })
    );
  }

  verifyStatement(statementId: number): Observable<Response> {
    return this.authHttp.put(`${getApiPath()}statements/${statementId}/verify/`, {});
  }

  getPaymentStatus(statementId: number): Observable<Response> {
    return this.authHttp.get(`${getApiPath()}statements/${statementId}/payment-status/`);
  }

  sendStatement(statementId: number): Observable<Response> {
    return this.authHttp.put(`${getApiPath()}statements/${statementId}/send/`, {});
  }

  sendStatementWithCert(statementId: number, file?: any, pin?: string): Observable<Response> {
    const data = new FormData();
    if (file) {
      data.append("certificate", file, file.name);
    } else {
      const value = pin ? pin : "0";
      data.append("pin", value);
    }
    return this.authHttp.post(`${getApiPath()}statements/${statementId}/send/`, data);
  }

  uploadEWRAttatchment(statementId: number, file?: any): Observable<Response> {
    const data = new FormData();
    if (file) {
      data.append("ewr", file, file.name);
    }
    return this.authHttp.post(`${getApiPath()}statements/${statementId}/attachment/`, data);
  }

  cloneStatement(statementId: number) {
    const serviceUrl = `${getApiPath()}statements/${statementId}/clone/`;
    return this.authHttp.post(serviceUrl, { testData: 0 });
  }

  downloadStatementPdf(statementId: number): Observable<Blob> {
    return this.authHttp.get(`${getApiPath()}statements/${statementId}/pdf/`, { responseType: "blob" }).pipe(
      // .map((response: Response) => new Blob([response['_body']], {type: 'application/pdf'}));
      map((response: any) => {
        return new Blob([response], { type: "application/pdf" });
      })
    );
  }

  downloadVollmachtPdf(statementId: number): Observable<Blob> {
    return this.authHttp.get(`${getApiPath()}statements/${statementId}/vollmacht-pdf/`, { responseType: "blob" }).pipe(
      map((response: any) => {
        return new Blob([response], { type: "application/pdf" });
      })
    );
  }

  deleteStatement(statementId: number) {
    return this.authHttp.remove(`${getApiPath()}statements/${statementId}/`);
  }

  deleteEmployer(statementId: number, employerId: number) {
    return this.authHttp.remove(`${getApiPath()}statements/${statementId}/employers/${employerId}/`);
  }

  deleteEmployerGuest(statementId: number, uuid: string, employerId: number) {
    const payload = {
      statement_id: statementId
    };
    return this.authHttp.remove(`${getApiPath()}statements/guest/${uuid}/employers/${employerId}/`, payload);
  }

  deleteMultipleEmployers(statementId: number, employerIds: number[]) {
    const payload = {
      employer_ids: employerIds
    };
    return this.authHttp.remove(`${getApiPath()}statements/${statementId}/employers/`, payload);
  }

  initOnfidoVerification(statementId: number) {
    return this.authHttp
      .get(`${getApiPath()}statements/${statementId}/init-onfido-verification/`)
      .pipe(map((response: any) => response));
  }

  checkOnfidoVerification(statementId: number) {
    return this.authHttp
      .get(`${getApiPath()}statements/${statementId}/check-onfido-verification/`)
      .pipe(map((response: any) => response));
  }

  getTaxAuthorityByPostalCode(postalCode: string, country: string): Observable<TaxAuthority[]> {
    const serviceURL = `${getApiPath()}statements/tax-authorities/?postal_code=${postalCode}&country=${country}`;
    return this.authHttp.get(serviceURL).pipe(
      map((response: any) => {
        const taxauts: TaxAuthority[] = [];
        response.forEach((item) => {
          taxauts.push(TaxAuthority.fromResponse(item));
        });
        return taxauts;
      }),
      catchError(() => observableOf([]))
    );
  }

  downloadDecision(statementId: number): any {
    return this.authHttp.get(`${getApiPath()}statements/${statementId}/decision/`, {
      params: { decision: "True", diva: "True" }
    });
  }

  downloadDecisionPDF_desktop(statementId: number, type: string): Observable<Blob> {
    //download files on desktop TAX-3030
    return this.authHttp
      .get(`${getApiPath()}statements/${statementId}/documents-pdf?type=${type.toString()}`, { responseType: "blob" })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  downloadDecisionFreelancePDF(statementId: number, fileId: number): Observable<Blob> {
    //download files on desktop TAX-3030
    return this.authHttp
      .get(`${getApiPath()}statements/${statementId}/documents-pdf/${fileId}`, { responseType: "blob" })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  downloadDecisionPDF(statementId: number, type: string, fileId?: number): Observable<any> {
    return this.authService.refreshToken().pipe(
      switchMap((authUser: AuthModel) => {
        if (!authUser) {
          return throwError(() => new Error("Brak autoryzacji"));
        }
        const apiUrl = fileId
          ? `statements/${statementId}/documents-pdf/${fileId}?type=${type}&access_token=${authUser.access}`
          : `statements/${statementId}/documents-pdf?type=${type}&access_token=${authUser.access}`;

        return this.authHttp.post(`${getApiPath()}${apiUrl}`, true);
      })
    );
  }

  getRefundDetails(statementId: number): Observable<RefundDetails> {
    return this.authHttp.get(`${getApiPath()}statements/${statementId}/refund-details/`).pipe(
      map((response: any) => RefundDetails.fromResponse(response)),
      catchError(() => observableOf(new RefundDetails()))
    );
  }

  getFinanzamtAddress(name) {
    const serviceURL = `${getApiPath()}statements/tax-authorities-address/${name}/`;
    return this.authHttp.get(serviceURL).pipe(
      map((response: any) => FinanzamtAddress.fromResponse(response)),
      catchError(() => observableOf([]))
    );
  }

  deleteDeductions(deductionsId: number) {
    const serviceURL = `${getApiPath()}statements/deductions/${deductionsId}/`;
    return this.authHttp.remove(serviceURL);
  }

  getAllLabels() {
    const serviceURL = `${getApiPath()}statements/labels/`;
    return this.authHttp.get(serviceURL);
  }

  getStatementCurrentLabels(statementId: number) {
    const serviceUrl = `${getApiPath()}statements/${statementId}/labels/`;
    return this.authHttp.get(serviceUrl);
  }

  saveLabel(statemenstid: number, labelId: number) {
    const formData = new FormData();
    formData.append("pk", statemenstid.toString());
    formData.append("lid", labelId.toString());

    return this.authHttp
      .post(`${getApiPath()}statements/labels/`, formData)
      .pipe(map((response: Response) => response));
  }

  notifyUser(statementId: number) {
    const serviceURL = `${getApiPath()}statements/${statementId}/vollmacht-notification/`;
    return this.authHttp.get(serviceURL);
  }

  getOpenedStatement(year: number) {
    const serviceUrl = `${getApiPath()}statements/count-statements/${year}/`;
    return this.authHttp.get(serviceUrl);
  }

  getAllOpenedStatement() {
    return this.authHttp.get(`${getApiPath()}statements/count-statements-pre-sent/`);
  }

  createMandate(statementId: number, data) {
    return this.authHttp.post(`${getApiPath()}statements/${statementId}/mandate/`, data);
  }

  sendActivationLink(statementId: number) {
    return this.authHttp.post(`${getApiPath()}statements/${statementId}/send-verification-link/`, {});
  }

  checkOnfidoVollmacht(statementId: number) {
    const serviceUrl = `${getApiPath()}statements/check-onfido-vollmacht/${statementId}/`;
    return this.authHttp.get(serviceUrl);
  }

  public attachment = {
    get: (statement: number | string, attachment: number | string) =>
      this.authHttp.get(`${getApiPath()}statements/${statement}/attachments/${attachment}/`),

    getList: (statement: number | string): Observable<any> =>
      this.authHttp.get(`${getApiPath()}statements/${statement}/attachments/`),

    create: (statement: number | string, file: File, type: string) => {
      const formData: FormData = new FormData();
      formData.append("file", file, file.name);
      formData.append("type", type);

      return this.authHttp
        .post(`${getApiPath()}statements/${statement}/attachments/`, formData)
        .pipe(map((response: Response) => response));
    },

    createMany: (statement: string | number, formData) =>
      this.authHttp
        .post(`${getApiPath()}statements/${statement}/attachments/`, { many: true, objects: formData })
        .pipe(map((response: Response) => response)),

    delete: (statement: number | string, attachment: number | string) =>
      this.authHttp
        .remove(`${getApiPath()}statements/${statement}/attachments/${attachment}/`)
        .pipe(map((response: Response) => response))
  };

  public file = {
    get: (statement, fileData) =>
      this.authHttp.get(`${getApiPath()}statements/${statement}/files/`, { params: { file: fileData } })
  };

  getConstantTaxAdviserWillSendInDays() {
    return this.authHttp.get(`${getApiPath()}statements/get-tax-adviser-send-date/`);
  }

  public events = {
    update: (statement: number | string, data: { [key: string]: boolean }) =>
      this.authHttp.put(`${getApiPath()}statements/${statement}/events`, data)
  };

  confirmStatementOrReportErrors(statement: number, data) {
    return this.authHttp
      .post(`${getApiPath()}statements/${statement}/confirm/`, data)
      .pipe(map((response: any) => response));
  }

  public getCanBeFilled = (): Observable<CanBeFilledResponse> =>
    this.authHttp.get(`${getApiPath()}statements/can-be-filled/`).pipe(
      map((response: any) => {
        return {
          result: response.result
        };
      })
    );

  public sendPitBotNotification(statement: Statement): Observable<any> {
    return this.authHttp
      .post(`${getApiPath()}statements/${statement.id}/send-pitbot-notification`, {})
      .pipe(catchError(() => of(null)));
  }
}
