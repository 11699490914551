import { Component } from '@angular/core';
import { CtrBaseComponent } from '../ctr-base.component';

@Component({
    ...CtrBaseComponent.metaData,
    selector: 'app-ctr-base-info',
    templateUrl: './ctr-base-info.component.html',
    styleUrls: ['../ctr-base.component.scss'],
})
export class CtrBaseInfoComponent extends CtrBaseComponent {
}
