import { Statement } from 'app/statements';

export enum StatementStatuses {
  CREATED = 'created',
  PROCESSING = 'processing',
  UPDATED = 'updated',
  READY = 'ready',
  SENT = 'sent',
  ON_HOLD = 'on_hold',
  PAID = 'paid',
  PAID_READY = 'paid_ready',
  CLIENT_APPROVAL = 'client_approval',
  APPROVED = 'approved',
  REPORTED_ERRORS = 'reported_erros',
  SPOUSE = 'spouse',
  SPOUSE_PROCESSING = 'spouse_processing',
}

export enum StatementDashboardStatuses {
  CREATED = 'CREATED',
  ASSESSMENT = 'ASSESSMENT',
  SUBMITTED = 'SUBMITTED',
  PAID = 'PAID',
  ON_HOLD = 'ON_HOLD',
  CANCELED = 'CANCELED',
  IN_APPROVAL = 'IN_APPROVAL',
  ORDERED = 'ORDERED',
  CLIENT_APPROVAL = 'CLIENT_APPROVAL',
  APPROVED = 'APPROVED',
  REPORTED_ERRORS = 'REPORTED_ERROS',
}

export class StatementDashboardStatusSetter {
  public static set(statement: Statement): void {
    if (statement.status && statement.serviceType) {
      switch (statement.status) {
        case StatementStatuses.CREATED:
        case StatementStatuses.PROCESSING:
        case StatementStatuses.UPDATED:
        case StatementStatuses.READY:
          statement.dashboardStatus = StatementDashboardStatuses.CREATED;
          return;
        case StatementStatuses.SENT:
          if (statement.isDecisionReady) {
            statement.dashboardStatus = StatementDashboardStatuses.ASSESSMENT;
          } else {
            statement.dashboardStatus = StatementDashboardStatuses.SUBMITTED;
          }
          return;
      }

      if (statement.isStandardPackage) {
        switch (statement.status) {
          case StatementStatuses.PAID:
            statement.dashboardStatus = StatementDashboardStatuses.PAID;
            break;
          case StatementStatuses.ON_HOLD:
            statement.dashboardStatus = StatementDashboardStatuses.ON_HOLD;
            break;
        }
      } else if (statement.isPremiumPackage) {
        switch (statement.status) {
          case StatementStatuses.PAID:
            statement.dashboardStatus = StatementDashboardStatuses.ORDERED;
            break;
          case StatementStatuses.ON_HOLD:
            statement.dashboardStatus = StatementDashboardStatuses.CANCELED;
            break;
          case StatementStatuses.PAID_READY:
            if (statement.hasVerifiedIdentity) {
              statement.dashboardStatus = StatementDashboardStatuses.IN_APPROVAL;
            } else {
              statement.dashboardStatus = StatementDashboardStatuses.ORDERED;
            }
            break;
          case StatementStatuses.CLIENT_APPROVAL:
            statement.dashboardStatus = StatementDashboardStatuses.CLIENT_APPROVAL;
            break;
          case StatementStatuses.APPROVED:
            statement.dashboardStatus = StatementDashboardStatuses.APPROVED;
            break;
          case StatementStatuses.REPORTED_ERRORS:
            statement.dashboardStatus = StatementDashboardStatuses.REPORTED_ERRORS;
            break;
        }
      }
    }
  }
}
