import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SubscribingComponent } from 'app/common';
import { CouponsPopup } from 'app/standalone-pages/coupons/coupons-popup/coupons-popup.component';
import { CouponHttpService } from 'app/standalone-pages/coupons/services/coupon.http.service';
import { AuthHttpService } from 'app/users';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
  providers: [CouponHttpService, AuthHttpService,]
})
export class HomeComponent extends SubscribingComponent implements OnInit {
  couponsArr ;

  constructor(private couponHttpService: CouponHttpService, private dialog: MatDialog,){
    super()
  }

  ngOnInit(){
    // this.activateReferalCodePopup()
  }

  activateReferalCodePopup(){
    this.couponHttpService.getCoupons().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (response) => {
          this.couponsArr = response
          if(this.couponsArr.length == 0){
            let modal = this.dialog.open(CouponsPopup, {panelClass: 'mat-coupons-popup'})
            setTimeout(()=>{modal}, 500)  
    
          }
        }
      });
  }
}
