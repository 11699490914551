import { DateRange, DateRangeResponse } from "app/common/dateRange";
import { SocialIncomeTypes } from "app/modules/statement/models/additional-income/social-income.types";
import { NumberFormatHelper } from "app/core/helpers/number-format.helper";

export interface SocialIncomeResponse {
  id: number;
  income_type: SocialIncomeTypes;
  amount: string;
  period_of_income: DateRangeResponse;
  income_name?: string;
}

export interface SocialIncomeErrors {
  income_type?: string[];
  amount?: string[];
  start_date?: string[];
  end_date?: string[];
  period_of_income?: string[];
  income_name?: string[];
}

export class SocialIncome {
  id: number;
  incomeType: SocialIncomeTypes;
  amount = 0.0;
  periodOfIncome: DateRange = new DateRange();
  income_name: string;

  public static fromResponse(response: SocialIncomeResponse): SocialIncome {
    const socialIncome = new SocialIncome();
    socialIncome.id = response.id;
    socialIncome.incomeType = response.income_type;
    socialIncome.income_name = response.income_name || null;
    socialIncome.amount = Number(response.amount) || 0.0;
    socialIncome.periodOfIncome = DateRange.fromResponse(response.period_of_income);
    return socialIncome;
  }

  public static fromStorage(response: SocialIncome): SocialIncome {
    const socialIncome = new SocialIncome();
    socialIncome.id = response.id;
    socialIncome.incomeType = response.incomeType;
    socialIncome.income_name = response.income_name || null;
    socialIncome.amount = Number(response.amount) || 0.0;
    socialIncome.periodOfIncome = DateRange.fromResponse(response.periodOfIncome);
    return socialIncome;
  }

  constructor(income?: SocialIncomeTypes) {
    this.incomeType = income;
  }

  public payload(defaultValue = undefined): SocialIncomeResponse {
    const payload: SocialIncomeResponse = {
      id: this.id || undefined,
      income_type: this.incomeType || defaultValue,
      period_of_income: this.periodOfIncome.toResponse(defaultValue),
      amount: this.amount ? NumberFormatHelper.toFixed2(this.amount) : defaultValue
    };

    if (this.incomeType === SocialIncomeTypes.OTHER) {
      payload.income_name = this.income_name;
    }
    return payload;
  }
}
