import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-statement-dialog',
  templateUrl: './new-statement-dialog.component.html',
  styleUrls: ['./new-statement-dialog.component.scss']
})
export class NewStatementDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<NewStatementDialogComponent>) { }

  ngOnInit() { }

  newStatement(){
    this.dialogRef.close('new');
  }

  editStatement(){
    this.dialogRef.close('edit');
  }
}
