import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { TooltipBaseComponent } from '../tooltip-base/tooltip-base.component';
import { DateHelper } from 'app/core/helpers/date.helper';

@Component({
  selector: 'app-double-form-date-input',
  templateUrl: './double-form-date-input.component.html',
  styleUrls: ['./double-form-date-input.component.scss']
})
export class DoubleFormDateInputComponent extends TooltipBaseComponent implements OnInit {
  @Input() label: string;
  @Input() prefix = 'INPUT.DEFAULT.FROM';
  @Input() prefix2 = 'INPUT.DEFAULT.TO';
  @Input() model: any;
  @Input() model2: any;
  @Output() modelChange = new EventEmitter();
  @Output() model2Change = new EventEmitter();
  @Input() errors: any;
  @Input() errors2: any;
  @Input() placeholder: string;
  @Input() placeholder2: string;
  @Input() isDisabled = false;
  @Input() centerLabel: boolean = false;
  @Input() smLabel: boolean = false;
  @Input() centerTxtInput: boolean = false;
  @Input() wrapInputs: boolean = false;

  constructor() {
    super();
  }

  ngOnInit() {
    this.placeholder = this.placeholder || 'COMMON.DATE_FORMAT';
    this.placeholder2 = this.placeholder2 || 'COMMON.DATE_FORMAT';
    this.model = this.model || '';
    this.model2 = this.model2 || '';

    if (this.model) {
      setTimeout(() => {
        this.model = this.formatDate(this.model);
        this.change(0, this.model);
      });
    }

    if (this.model2) {
      setTimeout(() => {
        this.model2 = this.formatDate(this.model2);
        this.change(1, this.model2);
      });
    }
  }


  formatDate(model) {
    if (model.length < 10) {
      return model;
    } else {
      return DateHelper.getFormatted(model, 'DD.MM.YYYY');
    }
  }

  isValidDate(date: Date): boolean {
    return DateHelper.getInstance(date).isValid();
  }

  change(index, newValue) {
    if (newValue.length === 2 || newValue.length === 5) {
      const leadingZerosRegexOne = /^[0-9]{1}[.]{1}$/;
      const leadingZerosRegexTwo = /^[0-9]{2}[.]{1}[0-9]{1}[.]{1}$/;

      if (leadingZerosRegexOne.test(newValue)) {
        if (index === 0) {
          this.modelChange.emit(`0${newValue}`);
        } else {
          this.model2Change.emit(`0${newValue}`);
        }
      } else if (leadingZerosRegexTwo.test(newValue)) {
        const splited = newValue.split('.');
        if (index === 0) {
          this.modelChange.emit(`${splited[0]}.0${splited[1]}.`);
        } else {
          this.model2Change.emit(`${splited[0]}.0${splited[1]}.`);
        }
      } else {
        if (index === 0) {
          this.modelChange.emit(`${newValue}.`);
        } else {
          this.model2Change.emit(`${newValue}.`);
        }
      }
    } else if (newValue.length === 10) {
      const splited = newValue.split('.');
      const newDate = new Date(parseInt(splited[2], 10), parseInt(splited[1], 10) - 1, parseInt(splited[0], 10));

      if (index === 0) {
        this.modelChange.emit(this.isValidDate(newDate) ? newDate : '');
      } else {
        this.model2Change.emit(this.isValidDate(newDate) ? newDate : '');
      }
    } else {
      const doubleDotsRegexp = /^[^]*[\.]{2}$/;
      if (doubleDotsRegexp.test(newValue)) {
        const properValue = newValue.substring(0, newValue.length - 1);
        /*
          Kod w linach 60 - 65 jest rozwiązaniem błędu który powodował,
          że wyemitowane properValue aktualizowało się w modelu ale nie w inpucie.
          Problem dotyczył tylko sytuacji kiedy użytkownik wpisał np. '12..' i po usunięciu kropki emitowane było '12.',
          ponieważ emitowanie '12.0' czy '12. ' lub '12.dlamniemaszstajla' działało poprawnie.
          Dlatego pierwemitujemy properValue ze spacją a potem to już docelowe.
         */
        setTimeout(() => {
          if (index === 0) {
            this.modelChange.emit(`${properValue} `);
          } else {
            this.model2Change.emit(`${properValue} `);
          }
          setTimeout(() => {
            if (index === 0) {
              this.modelChange.emit(`${properValue}`);
            } else {
              this.model2Change.emit(`${properValue}`);
            }
          }, 1);
        }, 1);
      } else {
        if (index === 0) {
          this.modelChange.emit(newValue);
        } else {
          this.model2Change.emit(newValue);
        }
      }
    }
  }
}
