import { of as observableOf, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Store } from "@ngrx/store";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { CookieService } from "ngx-cookie-service";
import { getAuthUser, State } from "../reducers";
import { USER_LOGOUT } from "../reducers/actions/auth-user";
import { AuthModel } from "app/modules/user/models/auth/auth.model";
import { LanguagesConfig } from "app/configs/languages.config";

@Injectable()
export class AuthHttpService {
  private loggedUser: AuthModel;
  private languagesList = LanguagesConfig.languagesList;

  constructor(
    private http: HttpClient,
    private store: Store<State>,
    private cookieService: CookieService
  ) {
    this.store.select(getAuthUser).subscribe((user: AuthModel) => (this.loggedUser = user));
  }

  public get<T = Response>(url: string, options?, onlyAuthenticated = false): Observable<T> {
    return this.makeRequest(this.http.get.bind(this.http), url, null, options, onlyAuthenticated);
  }

  public post<T = Response>(url: string, body: any, options?, onlyAuthenticated = false): Observable<T> {
    return this.makeRequest(this.http.post.bind(this.http), url, body, options, onlyAuthenticated);
  }

  public put<T = Response>(url: string, body: any, options?, onlyAuthenticated = false): Observable<T> {
    return this.makeRequest(this.http.put.bind(this.http), url, body, options, onlyAuthenticated);
  }

  public patch<T = Response>(url: string, body: any, options?, onlyAuthenticated = false): Observable<T> {
    return this.makeRequest(this.http.patch.bind(this.http), url, body, options, onlyAuthenticated);
  }

  public remove<T = Response>(
    url: string,
    optionsBody: any = false,
    options?,
    onlyAuthenticated = false
  ): Observable<T> {
    return this.makeRequest<T>(this.http.delete.bind(this.http), url, null, options, onlyAuthenticated, optionsBody);
  }

  private makeRequest<T = Response>(
    httpFunction: (url: string, body?: any, options?) => Observable<T>,
    url: string,
    body?: any,
    options?,
    onlyAuthenticated = false,
    optionsBody = false
  ): Observable<T> {
    const isLoggedIn = this.verifyUserLoggedIn();
    if (!isLoggedIn && onlyAuthenticated) {
      return observableOf(null);
    }

    const newOptions = isLoggedIn ? this.getAuthOptions(optionsBody, options) : this.getOptions(options);

    if (body) {
      return httpFunction(url, body, newOptions);
    }
    return httpFunction(url, newOptions);
  }

  /** Verify if user token is expired based on decoded information */
  private verifyToken(token: string): boolean {
    const decodedToken = jwtDecode<JwtPayload>(token);
    return decodedToken.exp * 1000 < new Date().getTime();
  }

  /** Verify is user is logged in. */
  private verifyUserLoggedIn(): boolean {
    if (!this.loggedUser) {
      return false;
    }
    const isExpired = this.verifyToken(this.loggedUser.access);
    if (isExpired) {
      return false;
    }

    return true;
  }

  /** Return Authentication header needed for JWT Auth */
  private getAuthOptions(optionsBody, options?) {
    const newOptions = options ? Object.assign({}, options) : {};
    if (!newOptions.headers || !(newOptions.headers instanceof HttpHeaders)) {
      newOptions.headers = new HttpHeaders();
    }

    newOptions.headers = newOptions.headers.append(
      "Authorization",
      this.loggedUser ? `JWT ${this.loggedUser.access}` : ""
    );

    newOptions.headers = this.updateHeadersWithLang(newOptions.headers);

    if (optionsBody) {
      newOptions.body = optionsBody;
    }

    return newOptions;
  }

  private getOptions(options?) {
    const newOptions = options ? Object.assign({}, options) : {};

    if (!newOptions.headers) {
      newOptions.headers = new HttpHeaders();
    }

    newOptions.headers = this.updateHeadersWithLang(newOptions.headers);
    return newOptions;
  }

  private updateHeadersWithLang(headers) {
    const lang: string = localStorage.getItem("taxando-lang");
    headers = headers.append("Accept-Language", lang ? lang : this.languagesList[0].code); //set default lang as de

    return headers;
  }
}
