import { DateHelper } from 'app/core/helpers/date.helper';

export interface FilterStatementsPayload {
  page: number;
  statements_id?: number;
  statements_identifikationsnummer?: string;
  statements_steuernummer?: string;
  statements_birth_date?: string;
  statements_tax_authority?: number;
  statements_custom_labels?: number;
}

export class FilterStatements {
  id: number;
  identifikationsnummer: string;
  steuernummer: string;
  birthDate: string;
  taxAuthority: number;
  customLabels: number;
  page = 1;

  constructor(
    id?: number,
    identifikationsnummer?: string,
    steuernummer?: string,
    birthDate?: string,
    taxAuthority?: number,
    customLabels?: number,
  ) {
    this.id = id;
    this.identifikationsnummer = identifikationsnummer;
    this.steuernummer = steuernummer;
    this.birthDate = birthDate;
    this.taxAuthority = taxAuthority;
    this.customLabels = customLabels;
  }

  public payload(): FilterStatementsPayload {
    const payload: FilterStatementsPayload = {
      page: this.page,
    };

    if (this.id) {
      payload.statements_id = this.id;
    }
    if (this.identifikationsnummer) {
      payload.statements_identifikationsnummer = this.identifikationsnummer;
    }
    if (this.steuernummer) {
      payload.statements_steuernummer = this.steuernummer;
    }
    if (this.birthDate) {
      payload.statements_birth_date = DateHelper.toPayload(this.birthDate);
    }
    if (this.taxAuthority) {
      payload.statements_tax_authority = this.taxAuthority;
    }
    if (this.customLabels) {
      payload.statements_custom_labels = this.customLabels;
    }

    return payload;
  }

  public get isEmpty(): boolean {
    return !this.id &&
      !this.identifikationsnummer &&
      !this.steuernummer &&
      !this.birthDate &&
      !this.taxAuthority &&
      !this.customLabels;
  }
}
