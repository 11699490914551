import { CountryResponse } from 'app/statements/country';

export interface CitizenshipResponse {
  code: string;
  name: string;
}

export class Citizenship {
  code: string;
  name: string;

  static fromResponse(response: CitizenshipResponse|CountryResponse): Citizenship {
    const citizenship = new Citizenship();
    citizenship.code = response.code;
    citizenship.name = response.name;

    return citizenship;
  }
}
