export interface CityResponse {
  code: string;
  name: string;
}

export class City {
  static TRAVEL_ABROAD_OTHER_CITY = new City('other', 'INPUT.TRAVEL_ABROAD_CITY.OTHER');

  static fromResponse(response: CityResponse): City {
    return new City(response.code, response.name);
  }

  constructor(public code: string, public name: string) { }
}
