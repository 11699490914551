import { CryptocurrencyIncomeTypes } from './cryptocurrency.types';
import { NumberFormatHelper } from 'app/core/helpers/number-format.helper';

export interface CryptocurrencyIncomeResponse {
  incomeNet: string;
}

export interface CryptocurrencyIncomeErrors {
  incomeNet?: string[];
  cryptocurrency_income_type?: string[];
}

export class CryptocurrencyIncome {
  id: number;
  incomeType: CryptocurrencyIncomeTypes;
  name = '';
  incomeNet = 0.00

  public static fromResponse(response: CryptocurrencyIncomeResponse): CryptocurrencyIncome {
    const cryptocurrencyIncome = new CryptocurrencyIncome();
    cryptocurrencyIncome.incomeNet = Number(response) || 0.00;

    return cryptocurrencyIncome;
  }

  public static fromStorage(response: CryptocurrencyIncome): CryptocurrencyIncome {
    const cryptocurrencyIncome = new CryptocurrencyIncome();

    cryptocurrencyIncome.incomeNet = Number(response) || 0.00;

    return cryptocurrencyIncome;
  }

  constructor(incomeType?: CryptocurrencyIncomeTypes) {
    this.incomeType = incomeType;
  }

  public payload(defaultValue = undefined): any {
    return NumberFormatHelper.toFixed2(this.incomeNet)
  }
}
