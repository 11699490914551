import {Action} from '@ngrx/store';

export const SHOW_VIEW_SPINNER = '[Loader] Show view spinner';
export const HIDE_VIEW_SPINNER = '[Loader] Hide view spinner';

export class ShowViewSpinnerAction implements Action {
  readonly type = SHOW_VIEW_SPINNER;

  constructor() { }
}

export class HideViewSpinnerAction implements Action {
  readonly type = HIDE_VIEW_SPINNER;

  constructor() { }
}

export type Actions = ShowViewSpinnerAction | HideViewSpinnerAction;
