export class NumberFormatHelper {
  public static decimal(amount: number|string): string {
    const decimal = String(Math.round((Number(amount) - Math.floor(Number(amount))) * 100));
    return decimal.length < 2 ? decimal + '0' : decimal;
  }

  public static integer(amount: number|string): string {
    const result = parseInt(String(amount), 10);
    return !isNaN(result) ? String(result) : '';
  }

  public static toFixed2(value: number|string): string {
    const result = parseFloat(String(value));

    return !isNaN(result) ? String(result.toFixed(2)) : '';
  }
}
