import {Injectable} from '@angular/core';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class SideNavService {
  private sidenav: MatSidenav;

  constructor() { }

  public setSideNav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public open(): Promise<MatDrawerToggleResult> {
    return this.sidenav.open();
  }

  public close(): Promise<MatDrawerToggleResult> {
    return this.sidenav.close();
  }

  public toggle(): Promise<MatDrawerToggleResult> {
    return this.sidenav.toggle();
  }
}
