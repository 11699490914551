import { Component, Input } from "@angular/core";

@Component({
  selector: "app-error-dialog",
  templateUrl: "./error-dialog.component.html",
  styleUrls: ["./error-dialog.component.scss"]
})
export class ErrorDialogComponent {
  @Input() message: string;
  @Input() title = "COMMON.ERROR";
  @Input() endpoint: string;
  @Input() isCustom = false;
  @Input() afterCloseAction = false;
  @Input() understandButton = false;

  constructor() {}

  public submitAction() {
    this.afterCloseAction = true;
  }
}
