import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';

export class WorkflowAdditionalOptionsService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.statement && this.statement.stageSteuerAdditionalOption;
  }

  public get isRequired(): boolean {
    return !this.isBusiness || this.isTaxAdviser;
  }

  public get isVisible(): boolean {
    return this.isRequired;
  }

  public get title(): string {
    return 'ASIDE_BAR.STEUER_INCOME_DEDUCTION';
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.statement && this.isRequired && !!this.statement.stageAdditionalIncome;
  }

  public get url(): string {
    return `statement/${this.statement && this.statement.id ? this.statement.id : 0}/additional-options`;
  }
}
