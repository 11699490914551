import { Component, Input } from "@angular/core";
import { MessageSenderHttpService } from "app/common/send-message-dialog/http-service/message-sender.http.service";
import { ErrorMessage, ErrorMessageContent } from "app/core/form-validator/validatorInterface";
import { PDFDocument } from "pdf-lib";

export interface FileData {
  isValidated: boolean;
  file: File;
}

export interface SendedFileData {
  file: string;
  id: number;
  message_id: number;
  statement: number;
}

@Component({
  selector: "app-add-attachment-list",
  templateUrl: "./add-attachment-list.component.html",
  styleUrls: ["./add-attachment-list.component.scss"]
})
export class AddAttachmentListComponent {
  @Input() attachmentsList: Array<FileData> = [];
  @Input() buttonText = "";
  @Input() maxFilesNumber = 2;
  @Input() sendedAttachments: Array<SendedFileData> = [];

  public maxFilesNumberError = false;

  constructor(public messageSenderHttpService: MessageSenderHttpService) {}

  public async validateFile(file: File): Promise<FileData> {
    const fileObject: FileData = {
      isValidated: true,
      file: file
    };

    if (file.type !== "application/pdf") {
      fileObject.isValidated = false;
      return fileObject;
    }

    if (file.size / (1024 * 1024) > 10) {
      fileObject.isValidated = false;
      return fileObject;
    }

    try {
      const pdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const pageCount = pdfDoc.getPageCount();

      if (pageCount > 100 || pageCount === 0) {
        fileObject.isValidated = false;
        return fileObject;
      }
    } catch (error) {
      console.log("Błąd podczas odczytu liczby stron");
      console.error(error);
      fileObject.isValidated = false;
      return fileObject;
    }

    return fileObject;
  }

  public async handleAddAttachment(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;

    if (!input.files) return;

    const files = Array.from(input.files);

    if (this.checkAttachmentsListLength(files)) {
      this.maxFilesNumberError = false;
      const results = await Promise.all(files.map((file) => this.validateFile(file)));
      results.forEach((fileObject) => this.attachmentsList.push(fileObject));
    } else {
      this.maxFilesNumberError = true;
    }
  }

  private checkAttachmentsListLength(files: File[]): boolean {
    console.log(this.sendedAttachments);

    return this.attachmentsList.length + files.length + this.sendedAttachments.length <= this.maxFilesNumber;
  }

  public handleRemoveAttachment(index: number): void {
    this.attachmentsList.splice(index, 1);
  }

  public handleRemoveSendedAttachment(index: number): void {
    const { statement, id, message_id } = this.sendedAttachments[index];

    this.messageSenderHttpService.removeMessageAttachment(statement, message_id, id).subscribe(
      (response) => {
        console.log("success");
        this.sendedAttachments.splice(index, 1);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private addListEpValidatorMessage(field_name: string, message: string): ErrorMessage {
    const errorMessage = new ErrorMessage();
    errorMessage.fieldName = field_name;
    errorMessage.index = 0;
    errorMessage.messages = [new ErrorMessageContent(message)];
    return errorMessage;
  }
}
