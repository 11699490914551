<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content">
        <div class="title">{{ 'TAX_ADVISER_CONFIRM_DIALOG.TITLE' | translate }}</div>
        <div>{{'TAX_ADVISER_CONFIRM_DIALOG.CONTENT'| translate}}</div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button class="mat-raised-button btn btn--longer btn--back btn--small"
        (click)="close()">{{ 'TAX_ADVISER_CONFIRM_DIALOG.CLOSE' | translate }}</button>
</mat-dialog-actions>
