import { BaseStatementPagePath } from 'app/core/workflow/page-path/statement-page-path/base-statement-page.path';

export class AbroadIncomeComponentPath extends BaseStatementPagePath {
  public static path(): string {
    return 'statement/:statementId/abroad-income-b2c';
  }

  public static regex(): RegExp {
    return /\/statement\/\d+\/abroad-income-b2c((\/?#?)|(\/?#.*?))$/;
  }
}
