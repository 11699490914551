import { Component, OnInit, Input } from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {ClientType} from '../../users/client-type';

@Component({
  selector: 'app-tax-advisor-licence',
  templateUrl: './tax-advisor-licence.component.html',
  styleUrls: ['./tax-advisor-licence.component.scss']
})
export class TaxAdvisorLicenceComponent implements OnInit {

  language = 'DE';
  LANGUAGES = {
    'pl': 'PL',
    'en': 'EN',
    'de': 'DE'
  };

  // Temporarily not used (same terms and conditions for individual and business clients).
  @Input() clientType: ClientType = 'individual';

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.language = this.LANGUAGES[this.translate.currentLang] || this.language;
  }

}
