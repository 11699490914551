import { Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Package } from './package';
import { AuthHttpService } from '../users';
import { Payment } from './payment';
import { getApiPath } from '../common';
import { Coupon } from 'app/statements/coupon';
import { TradeCondition } from './trade-conditions';

@Injectable()
export class PaymentService {

  constructor(
    private authHttp: AuthHttpService,
    private router: Router
  ) {
  }

  getPackages(): Observable<Package[]> {
    return this.authHttp.get(`${getApiPath()}statement-packages/`).pipe(
      map((response: any) => {
        const packs: Package[] = [];
        response.forEach(item => {
          packs.push(Package.fromResponse(item));
        });
        return packs;
      }),
      catchError(_ => observableOf([])));
  }

  getDiscountPackages(id: number): Observable<Package[]> {
    return this.authHttp.get(`${getApiPath()}statement-discount-packages/${id}`)
      .pipe(
        map((response: any) => {
          const packs: Package[] = [];
          response.forEach(item => {
            packs.push(Package.fromResponse(item));
          });
          return packs;
        }),
        catchError(_ => observableOf([]))
      );
  }

  getAllTradeConditions(): Observable<TradeCondition[]> {
    return this.authHttp.get(`${getApiPath()}payments/trade-conditions/`)
      .pipe(map((response: any) => {
          const tradeConditions = [];
          response.forEach(item => {
            tradeConditions.push(TradeCondition.fromResponse(item));
          });
          return tradeConditions;
        }),
        catchError(_ => observableOf([])));
  }

  getPaymentHistory(): Observable<Payment[]> {
    return this.authHttp.get(`${getApiPath()}payments/`).pipe(
      map((response: any) => {
        const pays: Payment[] = [];
        response.forEach(item => {
          pays.push(Payment.fromResponse(item));
        });
        return pays;
      }),
      catchError(_ => observableOf([])));
  }

  downloadInvoice(paymentId: number): Observable<Blob> {
    const params = { responseType: 'blob' };
    return this.authHttp.get(`${getApiPath()}payments/${paymentId}/invoice/`, params).pipe(
      map((response: any) => {
        return new Blob([response], {type: 'application/pdf'});
      })
    );
  }

  afterPayPalExecution(): Observable<Response> {
    let url = this.router.url;
    if (url.includes('batch') || url.includes('statement')) {
      const splitedUrl = url.split('/');
      url = splitedUrl[splitedUrl.length - 1];
      return this.authHttp.get(`${getApiPath()}payments/${url}`);
    } else {
      return this.authHttp.get(`${getApiPath()}payments${url}`);
    }
  }

  checkCoupon(statementId: number, code: string): Observable<Coupon> {
    return this.authHttp.get(`${getApiPath()}payments/check-coupon/${statementId}/${code}`)
      .pipe(
        map((response: any) => Coupon.fromResponse(response))
    );
  }

  getVatRate(pk : number): Observable<any>{
    return this.authHttp.get(`${getApiPath()}statements/${pk}/vat-rate`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  //statements/<int:pk>/vat-rate
}
