export class ErrorMessageContent {
  public message: string;
  public arguments?: Object;

  constructor(_message: string, _arguments?: Object) {
    this.message = _message;
    this.arguments = _arguments;
  }
}

export class ErrorMessage {
  public index: number;
  public messages: (ErrorMessageContent|string)[] = [];
  public fieldName = '';
}

export interface FieldValidationRules {
  year: number[];
  fieldName: string;
  classFieldName?: string;
  nestedArrayFieldName?: boolean|string;
  fieldRules: string[];
  fieldRulesValue: any[];
  fieldErrorMessage: string[];
  fieldErrorMessageArguments?: Object[];
}

export class FieldRuleValue {
  public isNestedArrayField: boolean;
  public operator?: FIELD_RULE_VALUE_OPERATORS;
  public classFieldNames: string[];

  constructor(
    _classFieldNames = [],
    _operator = null,
    _isNestedArrayField = false,
  ) {
    this.isNestedArrayField = _isNestedArrayField;
    this.operator = _operator;
    this.classFieldNames = _classFieldNames;
  }
}

export enum FIELD_RULE_VALUE_OPERATORS {
  PLUS = '+',
  MINUS = '-',
  ARRAY = 'ARRAY',
  BOOLEAN_AND = 'BOOLEAN_AND',
}

export const FIELD_OPERATOR_HELPER = {
  FIELD_NAME_INDEX_TO_REPLACE: '$index$',
  IS_NOT_OPERATOR: '$!$',
  TO_NUMBER: '$Number()$',
  NESTED_ARRAY_IN_NESTED_ARRAY: '$NESTED_ARRAY_IN_NESTED_ARRAY$',
};
