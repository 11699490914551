import { finalize, takeUntil } from "rxjs/operators";
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import { Store } from "@ngrx/store";

import { getApiPath } from "../../common";
import { State, getUser } from "../../reducers";
import { SHOW_SNACKBAR } from "../../reducers/actions/snackbar";
import { Statement } from "../statement";
import { StatementService } from "../statement.service";
import { SubscribingComponent, SnackbarConfig, saveFile } from "../../common";
import { SendWithCertComponent } from "../send-with-cert/send-with-cert.component";
import { PaymentService } from "../../payment/payment.service";
import { SendStatementOnfidoComponent } from "../send-statement-onfido/send-statement-onfido.component";
import { ErrorDialogComponent } from "../../common/error-dialog/error-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { Events, FirebaseEventsService } from "app/common/firebase-events.service";
import { PackageOptions } from "../package-options";
import { REFRESH_STATEMENTS_INTERVAL } from "../../common/settings";
import { ONFIDO_STATUS_CHECK_LIMIT } from "../../common/settings";
import { ONFIDO_REFRESH_STATUS_INTERVAL } from "../../common/settings";
import { FirebaseNotification } from "app/firebase/notification";
import { FirebaseHttpService } from "app/firebase/firebase.http.service";
import { AuthHttpService, User } from "app/users";
import { WorkflowControllerService } from "app/core/workflow/workflow-controller/workflow-controller.service";
import { FinishPagePath } from "app/core/workflow/page-path/statement-page-path/finish-page.path";
import { DashboardPagePath } from "app/core/workflow/page-path/dashboard-page.path";
import { LanguageSelectService } from "app/core/services/language-select.service";
import { DownloadFileDialogComponent } from "app/common/decision-dialog/download-file-dialog.component";
import { lastValueFrom } from "rxjs";

import snsWebSdk from "@sumsub/websdk";

@Component({
  selector: "app-thank-you",
  templateUrl: "./thank-you.component.html",
  styleUrls: ["./thank-you.component.scss"]
})
export class ThankYouComponent extends SubscribingComponent implements OnInit, OnDestroy {
  @ViewChild("iframe", { static: false }) iframe: ElementRef;

  statement: Statement;
  loadingStatementData = true;
  statementId: number;
  downloading = false;
  sending = false;
  isPaymentStatusProcessing = true;
  isResigningFromClaim = false;
  isIdentityVarificationActive = false;
  isIdentityVarificationActiveProcess = false;
  canSendWithTaxandoCert = false;
  isVeryfying = false;
  iframeSrc: SafeResourceUrl;
  packageOption = PackageOptions;
  public sendWithCertCheckbox: boolean = false;
  public canSendWithTaxandoCertActive: boolean = true;
  public sendStatementActive: boolean = false;
  private onfidoStatusCheckLimit: number = 0;
  public cantGetOnfidoStatusCheck: boolean = false;
  public user_id;
  public buttons = [];
  public lottieConfig: Object = {
    path: "../../../assets/anim/loader_after_onfido.json",
    renderer: "canvas",
    autoplay: true,
    loop: true
  };
  private _isLoading = false;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private paymentService: PaymentService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private firebaseEventsService: FirebaseEventsService,
    private firebaseService: FirebaseHttpService,
    public workFlowController: WorkflowControllerService,
    public readonly languageSelectService: LanguageSelectService,
    private authHttp: AuthHttpService
  ) {
    super();
    this.onVerificationComplete = this.onVerificationComplete.bind(this);
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      const statementId = parseInt(params["statementId"], 10);

      this.statementId = statementId;
      this.observePaymentStatus();
    });

    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          this.user_id = user.id;
        }
      });

    this.setButtons();
  }

  ngOnDestroy() {
    window.removeEventListener("message", this.onVerificationComplete);
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  /** TAX-2569 firebase events
   */
  private firebaseEvents = () => {
    if (this.statement.serviceType === PackageOptions.paid) {
      // TAX-2569 purchase_complete_premium
      if (
        this.statement.canBeFreeService &&
        sessionStorage.getItem(Events.purchase_free) !== this.statement.id.toString() &&
        !this.statement.eventPurchaseCompleteType
      ) {
        sessionStorage.setItem(Events.purchase_free, this.statement.id.toString());

        const freeParams = {
          currency: "EUR",
          value: 0
        };

        this.firebaseEventsService.logEvent(Events.purchase_free, freeParams, this.statementId);
        this.firebaseEventsService.webEvent(Events.purchase_free, freeParams, this.statementId);
      } else if (
        sessionStorage.getItem("purchase_complete_premium") !== this.statement.id.toString() &&
        !this.statement.eventPurchaseCompleteType
      ) {
        sessionStorage.setItem("purchase_complete_premium", this.statement.id.toString());

        const premiumParams = {
          currency: "EUR",
          value: this.statement.paymentCurrency == "EUR" ? this.statement.paymentAmount : 33.8
        };

        this.firebaseEventsService.logEvent(Events.purchase_standard, premiumParams, this.statementId);
        this.firebaseEventsService.webEvent(Events.purchase_standard, premiumParams, this.statementId);
      }

      if (sessionStorage.getItem("use_premium_steuerberater") !== this.statement.id.toString()) {
        sessionStorage.setItem("use_premium_steuerberater", this.statement.id.toString());

        if (this.user_id > 0) {
          const notification = new FirebaseNotification();
          notification.user = this.user_id;
          notification.statement = this.statement.id;
          notification.notificationName = "use_premium_steuerberater";
          notification.eventTwoTriggered = true;
          this.firebaseService.udpateFirebaseNotification(notification).subscribe(
            (response) => {},
            (error) => {
              console.log(error);
            }
          );
        }
      }
    }

    if (this.statement.serviceType === PackageOptions.tax_adviser) {
      if (sessionStorage.getItem("use_premium_steuerberater") !== this.statement.id.toString()) {
        if (this.user_id > 0) {
          const notification = new FirebaseNotification();
          notification.user = this.user_id;
          notification.statement = this.statement.id;
          notification.notificationName = "use_premium_steuerberater";
          notification.eventTwoTriggered = true;
          this.firebaseService.udpateFirebaseNotification(notification).subscribe(
            (response) => {},
            (error) => {
              console.log(error);
            }
          );
        }
      }
    }
  };

  getStatementData(id: number) {
    this.loadingStatementData = true;
    this.statementService
      .getStatement(id)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.loadingStatementData = false;
          this.startOnfido();
        })
      )
      .subscribe(
        (response: Statement) => {
          this.statement = response;

          this.workFlowController.init(this.statement);
          // (Firebase event) purchase_complete
          if (
            sessionStorage.getItem("purchase_complete") !== this.statement.id.toString() &&
            !this.statement.eventPurchaseComplete &&
            this.statement.serviceType === PackageOptions.paid
          ) {
            sessionStorage.setItem("purchase_complete", this.statement.id.toString());

            let purchaseParams = {};

            if (this.statement.canBeFreeService) {
              purchaseParams = {
                type: "free",
                currency: "EUR",
                value: 0
              };
            } else {
              purchaseParams = {
                type: "standard",
                currency: "EUR",
                value: this.statement.paymentCurrency == "EUR" ? this.statement.paymentAmount : 33.8
              };
            }

            this.firebaseEventsService.logEvent(Events.purchase, purchaseParams, this.statement.id);
            this.firebaseEventsService.webEvent(Events.purchase, purchaseParams, this.statement.id);
          }

          this.canSendWithTaxandoCert = this.statement.canVerifyWithOnfido;
          if (this.statement.status === "sent") {
            // this.router.navigate([`new/statement/${this.statement.id}/finish`]);
          } else if (!this.statement.canVerifyWithOnfido) {
            this.cantGetOnfidoStatusCheck = true;
            this.isIdentityVarificationActive = true;
            this.isVeryfying = true;
          } else {
            this.firebaseEvents();
          }

          this.workFlowController.init(this.statement);
        },
        () => this.router.navigate(["/new"])
      );
  }

  public startOnfido() {
    if (this.statement.serviceType === this.packageOption.tax_adviser) {
      this.prepareOnfido();
    }
  }

  printStatement() {
    this.downloading = true;
    this.statementService
      .downloadStatementPdf(this.statementId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.downloading = false))
      )
      .subscribe((file: Blob) => saveFile(file, `statement-${this.statementId}.pdf`), this.showPDFError.bind(this));
  }

  sendStatement() {
    this.sending = true;
    this.statementService
      .sendStatement(this.statementId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.sending = false))
      )
      .subscribe(
        () => this.router.navigate([`/new/statement/${this.statementId}/download-statement`]),
        (error: any) => {
          this._isLoading = false;
          const message = error.error[0];
          if (typeof message === "string") {
            this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig(message, "ERROR") });
          }
        }
      );
  }

  goBack() {
    const step = this.statement.spouse && this.statement.filedTogether ? `spouse/${this.statement.spouse.id}` : "";
    const url = `/new/statement/${this.statement.id}/${step}/summary`;
    this.router.navigate([url]);
  }

  downloadInvoice() {
    this.downloading = true;
    this.paymentService
      .downloadInvoice(this.statement.payment)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.downloading = false))
      )
      .subscribe((file: Blob) => saveFile(file, `invoice-${this.statementId}.pdf`));
  }

  sendWithCert() {
    if (this.statement) {
      this._isLoading = false;
      const dialogRef = this.dialog.open(SendWithCertComponent);
      dialogRef.componentInstance.statement = this.statement;
    }
  }

  proceed() {
    this._isLoading = true;
    this.router.navigate([DashboardPagePath.fullUrl()]);
  }

  observePaymentStatus() {
    this.statementService
      .getPaymentStatus(this.statementId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: Response) => {
        const status = response["payment_status"];
        this.checkPaymentStatus(status);
      });
  }

  checkPaymentStatus(status: string) {
    if (status === "waiting") {
      setTimeout(this.observePaymentStatus.bind(this), REFRESH_STATEMENTS_INTERVAL);
    } else if (status === "cancelled") {
      this.router.navigate([`/payment-abort/${this.statementId}`]);
    } else {
      this.isPaymentStatusProcessing = false;
      this.getStatementData(this.statementId);
    }
  }

  showPDFError() {
    this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig("SNACKBAR.PDF_NOT_AVAILABLE", "ERROR") });
  }

  disableSendButton() {
    return (
      this.sending ||
      !this.statement ||
      this.statement.isSent() ||
      (!this.statement.canSendWithoutResignationFromClaim && !this.isResigningFromClaim)
    );
  }

  sendWithCommonCert() {
    this.statementService.checkOnfidoVollmacht(this.statementId).subscribe((response) => {
      if (response["has_verified_identity"] === true) {
        this.router.navigate([FinishPagePath.fullUrl(this.statementId)]);
      } else {
        this._isLoading = false;
        const dialogRef = this.dialog.open(SendStatementOnfidoComponent, {
          disableClose: true,
          panelClass: "send-statement-onfido-dialog"
        });
        dialogRef.componentInstance.onConfirmation = () => {
          this.prepareOnfido();
        };
      }
    });
  }

  // sumsub verification

  prepareOnfido() {
    this.isIdentityVarificationActive = true;
    this.statementService
      .initOnfidoVerification(this.statementId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.launchIdentityVerificationSDK(response.onfido_token);
        },
        (error: any) => {
          this.showErrorDialog(error.error);
        }
      );
  }

  /**
   * launchIdentityVerificationSDK() - it uses sumsub
   */

  public launchIdentityVerificationSDK(accessToken) {
    let snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => this.getNewAccessToken())
      .withConf({
        lang: this.choseLanguage(), //language of WebSDK texts and comments (ISO 639-1 format)
        theme: "light"
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on("idCheck.onApplicantStatusChanged", (payload) => {
        if (payload.reviewStatus === "pending") this.onVerificationComplete();
      })
      .on("idCheck.onError", (error) => {
        console.log("onError", error);
      })
      .build();

    snsWebSdkInstance.launch("#sumsub-websdk-container");
  }

  public async getNewAccessToken(): Promise<string> {
    return lastValueFrom(
      this.statementService.initOnfidoVerification(this.statementId).pipe(takeUntil(this.ngUnsubscribe))
    ).then((response) => response.onfido_token);
  }

  choseLanguage() {
    let chosenLang = this.languageSelectService.getLanguage();

    if (chosenLang == "ua") return "uk"; //TAXANDO handle Ukrainian as UA, sumsub i using UK

    return chosenLang;
  }

  onVerificationComplete() {
    this.isVeryfying = true;
    this.observeIdentityVerificationProcess();
  }

  observeIdentityVerificationProcess() {
    this.statementService
      .checkOnfidoVerification(this.statementId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          const status = response["is_verified"];
          this.checkIdentityVerificationStatus(status);
        },
        (error: any) => {
          const message = error.error;
          this.showErrorDialog(message);
        }
      );
  }

  checkIdentityVerificationStatus(status) {
    if (status === true) {
      this.setButtons();
      if (this.statement.serviceType == PackageOptions.tax_adviser) {
        this.statementService.updateStatementStatus(this.statementId).subscribe(() => {
          this.router.navigate([FinishPagePath.fullUrl(this.statementId)]);
        });
      } else {
        this.router.navigate([FinishPagePath.fullUrl(this.statementId)]);
      }
    } else if (status === false) {
      this.setButtons();
      this.translate
        .get("ONFIDO.FAILED_VERIFICATION")
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((message: string) => this.showErrorDialog(message, true));
    } else {
      // check status again after 3000ms
      if (this.onfidoStatusCheckLimit <= ONFIDO_STATUS_CHECK_LIMIT) {
        this.onfidoStatusCheckLimit++;
        setTimeout(this.observeIdentityVerificationProcess.bind(this), ONFIDO_REFRESH_STATUS_INTERVAL);
      } else {
        this.setButtons();
        this.cantGetOnfidoStatusCheck = true;
      }
    }
  }

  showErrorDialog(message, hideOnfidoButton = false) {
    this.isIdentityVarificationActive = false;
    if (hideOnfidoButton) {
      this.canSendWithTaxandoCert = false;
    }
    if (message && typeof message === "string") {
      const dialogRef = this.dialog.open(ErrorDialogComponent);
      dialogRef.componentInstance.message = message;
    }
    this.isVeryfying = false;
  }

  public sendWithSelectedMethod() {
    this._isLoading = true;

    this.firebaseEventsService.logEvent("click_send_tax_card", {
      shipment_method: this.canSendWithTaxandoCert ? "rekomendowane" : "opcjonalne"
    });

    this.canSendWithTaxandoCertActive = true; // TODO

    if (this.sendWithCertCheckbox) {
      this.sendWithCert();
    } else {
      if (this.canSendWithTaxandoCertActive) {
        this.sendWithCommonCert();
      }
      if (this.sendStatementActive) {
        this.sendStatement();
      }
    }
  }

  public checkResigningFromClaim() {
    this.isResigningFromClaim = !this.isResigningFromClaim;
    if (!this.isResigningFromClaim) {
      this.sendWithCertCheckbox = false;
    } else {
      if (!this.canSendWithTaxandoCertActive && !this.sendStatementActive) {
        this.canSendWithTaxandoCertActive = true;
      }
    }

    this.setButtons();
  }

  public setcanSendWithTaxandoCert() {
    this.sendStatementActive = false;
    this.canSendWithTaxandoCertActive = true;
    this.sendWithCertCheckbox = false;
    this.setButtons();
  }

  public setsendStatement() {
    this.sendStatementActive = true;
    this.canSendWithTaxandoCertActive = false;
    this.sendWithCertCheckbox = false;
    this.setButtons();
  }

  public checksendWithCert() {
    this.sendWithCertCheckbox = !this.sendWithCertCheckbox;
    this.sendStatementActive = false;
    this.canSendWithTaxandoCertActive = false;
    this.setButtons();
  }

  public setButtons() {
    this.buttons = [
      {
        type: "proceed",
        label: "COMMON.SEND",
        action: this.sendWithSelectedMethod.bind(this),
        disabled: () =>
          !(
            this.sendWithCertCheckbox ||
            this.isResigningFromClaim ||
            (this.isResigningFromClaim && this.sendStatementActive) ||
            (this.isResigningFromClaim && this.canSendWithTaxandoCertActive) ||
            this.statement.canSendWithoutResignationFromClaim
          )
      }
    ];
  }

  private sendFireBaseEvent(eventName: string, statementId: any) {
    let firebaseEvents = localStorage.getItem("firebaseEvents");
    if (firebaseEvents) {
    } else {
    }
    localStorage.setItem("firebaseEvents", firebaseEvents);
  }

  showDownloadDialog(statement: Statement) {
    //tax-3030, downlaod all files in one modal
    const dialogRef = this.dialog.open(DownloadFileDialogComponent);
    dialogRef.componentInstance.statement = statement;
  }
}
