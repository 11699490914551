import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { TaxcardQualityInfoComponent } from 'app/statements/taxcard-quality-info/taxcard-quality-info.component';
import { MatDialog } from '@angular/material/dialog';
import { finalize, takeUntil } from 'rxjs/operators';
import { SHOW_SNACKBAR } from 'app/reducers/actions/snackbar';
import { SnackbarConfig } from 'app/common/snackbar-config';
import { OcrFailedDialogComponent } from 'app/common/ocr-failed-dialog/ocr-failed-dialog.component';
import { Statement } from 'app/statements';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { StatementService } from 'app/statements/statement.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidateFieldService } from 'app/core/form-validator/validate-field.service';
import { ProcessingPlaceholderComponent } from 'app/statements/processing-placeholder/processing-placeholder.component';
import { REFRESH_STATEMENTS_INTERVAL } from 'app/common/settings';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FileHelper } from 'app/core/helpers/file.helper';
import { ArrayHelper } from 'app/core/helpers/array.helper';
import { Subject, Subscription } from 'rxjs';
import { Employer } from 'app/statements/employer';

declare const Camera: any;
declare const navigator: any;

@Injectable({
  providedIn: 'root'
})
export class TaxCardAddService {
  public uploadingScan = false;
  public scanUploaded = false;
  public scanWasSuccessfullyUploaded = false;
  public mainStatement: Statement;
  public currentStatement: Statement;
  public onChooseIfHasCardEmitter = new EventEmitter();
  public onManuallyTaxCardAddedEmitter = new EventEmitter();
  public onSuccessfullyCardUploadedEmitter = new EventEmitter();
  public removeEmployerEmitter = new EventEmitter();
  public statementCreatedEmitter = new EventEmitter();
  public onOcrFailedEmitter = new EventEmitter();
  public enableScanUpload = false;
  private processingDialogRef;
  private observerStatementProcessing: Subscription;
  private ngUnsubscribe: Subject<void>;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private validatorService: ValidateFieldService,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
  ) {
  }

  public _init(
    _mainStatement: Statement,
    _currentStatement: Statement = null,
    _ngUnsubscribe: Subject<void>,
  ): void {
    this.mainStatement = _mainStatement || this.mainStatement;
    this.currentStatement = _currentStatement || this.mainStatement;

    this.ngUnsubscribe = _ngUnsubscribe;
  }

  public get _isInitialized(): boolean {
    return !!this.mainStatement && !!this.currentStatement;
  }

  public get hasEmployers(): boolean {
    return this.currentStatement.hasEmployers;
  }

  public onManuallyTaxCardAdded(): void {
    if (this.uploadingScan) {
      return;
    }

    this.onManuallyTaxCardAddedEmitter.emit();
  }

  public onChooseIfHasCard(): void {
    if (this.uploadingScan) {
      return;
    }

    this.onChooseIfHasCardEmitter.emit();
  }

  public onPDFUpload(pdfField: ElementRef, captchaToken?: string): void {
    this.onTaxCardUpload(pdfField, captchaToken);
    this.currentStatement.hasEmployers = true;
  }

  public canSnapTaxCard(): boolean {
    return navigator && navigator.camera;
  }

  public uploadScanClick(): void {
    if (this.canSnapTaxCard()) {
      const dialogRef = this.dialog.open(
        TaxcardQualityInfoComponent,
        { panelClass: 'taxcard-quality-info-dialog' }
      );
      dialogRef.componentInstance.callback = () => {
        this.snapTaxCard();
      };
    }
  }

  public onScanUploadInfo(event, pdfField: ElementRef): void {
    if (this.uploadingScan) {
      return;
    }

    const dialogRef = this.dialog.open(
      TaxcardQualityInfoComponent,
      { panelClass: 'taxcard-quality-info-dialog' }
    );
    dialogRef.componentInstance.callback = () => {
      pdfField.nativeElement.dispatchEvent(event);
    };
  }

  private uploadImg(scan, captchaToken?: string): void {
    this.statementService.uploadStatement(scan, this.currentStatement.id, false, false, captchaToken)
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.uploadingScan = false))
      .subscribe(() => {
        this.showProcessingPlaceholder();
        this.observeStatementProcessing();
        this.scanWasSuccessfullyUploaded = true;

        this.onSuccessfullyCardUploadedEmitter.emit();
      }, (errors: any) => {
        if (errors && errors.length) {
          this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig(errors, 'ERROR') });
        }

        this.onOcrFailed();
      });
  }

  private showProcessingPlaceholder(): void {
    this.processingDialogRef = this.dialog.open(ProcessingPlaceholderComponent, {
      width: '975px',
      disableClose: true
    });
  }

  private closeProcessingPlaceholder(): void {
    this.processingDialogRef.close();
  }

  private onTaxCardUpload(taxCardField: ElementRef, captchaToken?: string): void {
    this.uploadingScan = true;
    this.scanUploaded = true;
    if (taxCardField.nativeElement.files.length > 0) {
      const scan: File = taxCardField.nativeElement.files.item(0);
      taxCardField.nativeElement.value = null;

      if (FileHelper.isValidFile(scan)) {
        this.uploadImg(scan, captchaToken);
      } else {
        this.uploadingScan = false;
        this.store.dispatch({
          type: SHOW_SNACKBAR,
          payload: new SnackbarConfig('SNACKBAR.INVALID_EXTENSION', 'ERROR')
        });
      }
    }
    taxCardField.nativeElement.value = '';
  }

  private snapTaxCard(captchaToken?: string): void {
    if (this.uploadingScan) {
      return;
    }

    this.uploadingScan = true;
    const options = {
      quality: 100,
      destinationType: Camera.DestinationType.FILE_URI,
      encodingType: Camera.EncodingType.JPEG,
      mediaType: Camera.MediaType.PICTURE
    };

    navigator.camera.getPicture(fileURI => {
      window['resolveLocalFileSystemURL'](fileURI, function (fileEntry) {
        fileEntry.file(function (file) {
          const reader = new FileReader();
          reader.onloadend = (e) => {
            const imgBlob = new Blob([e.target['_result']], { type: 'image/jpeg' });
            // imgBlob['name'] = 'scan_taxcard.jpg';
            this.uploadImg(imgBlob, captchaToken);
          };
          reader.readAsArrayBuffer(file);
        }.bind(this));
      }.bind(this));
    }, () => {
      this.uploadingScan = false;
    }, options);
  }

  private checkStatementProcessing(statement: Statement): void {
    if (statement.isProcessing() || (statement.spouse && statement.spouse.isSpouseProcessing)) {
      setTimeout(this.observeStatementProcessing.bind(this), REFRESH_STATEMENTS_INTERVAL);
    } else {
      this.statementProcessed(statement);
      this.removeFirstEmployerIfEmpty();
      this.closeProcessingPlaceholder();
    }
  }

  private observeStatementProcessing(): void {
    if (this.observerStatementProcessing) {
      this.observerStatementProcessing.unsubscribe();
    }
    this.observerStatementProcessing = this.statementService.getStatement(this.mainStatement.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: Statement) => this.checkStatementProcessing(response));
  }

  private removeFirstEmployerIfEmpty(): void {
    if (this.currentStatement.employers.length >= 2 && this.currentStatement.employers[0].isEmpty()) {
      this.removeEmployerEmitter.emit(0);
    }
  }

  private statementProcessed(statement: Statement): void {
    const currentEmployers = this.currentStatement.isSpouse() && statement.spouse
      ? statement.spouse.employers
      : statement.employers;

    if (this.checkIfNewEmployersExistInOcrResult(currentEmployers)) {
      currentEmployers.forEach(employer => {
        if (!this.currentStatement.employers.find(x => x.id === employer.id)) {
          employer.usedOCR = true;
        }
      });
      this.currentStatement.employers = currentEmployers;
      this.store.dispatch({
        type: SHOW_SNACKBAR,
        payload: new SnackbarConfig('SNACKBAR.EMPLOYER_CREATED')
      });

      this.statementCreatedEmitter.emit();
    } else {
      this.onOcrFailed();
    }
  }

  private checkIfNewEmployersExistInOcrResult(employers: Employer[]): boolean {
    const oldEmployerToCompare = [...this.currentStatement.employers].filter(e => e.id);

    if (ArrayHelper.isArrayAndNotEmpty(employers)) {
      if (employers.length === oldEmployerToCompare.length) {
        let isSameLengthAndDifferentIds = false;

        for (const oldEmployer of oldEmployerToCompare) {
          if (!employers.find(x => x.id === oldEmployer.id)) {
            isSameLengthAndDifferentIds = true;
            break;
          }
        }

        return isSameLengthAndDifferentIds;
      }

      return true;
    }

    return false;
  }

  private onOcrFailed(): void {
    this.dialog.open(OcrFailedDialogComponent, {disableClose: true});

    this.onOcrFailedEmitter.emit();
  }
}
