export enum SocialSubSteps {
  MAIN = "main",
  UNEMPLOYMENT = "arbeitslosengeld",
  PARENTAL = "elterngeld",
  INSOLVENCY = "insolvenzgeld",
  SICKNESS = "krankengeld",
  MATERNITY = "mutterschaftsgeld",
  INJURY = "verletztengeld",
  TRANSITION = "uebergangsgeld",
  OTHER = "other"
}
