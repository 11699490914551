import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { AdditionalIncomeComponentPath } from 'app/core/workflow/page-path/statement-page-path/additional-income-component.path';

export class WorkflowAdditionalIncomeApplicantSubBaseService extends WorkflowStepsBaseService {
  public get subStepNumber(): number {
    return 1;
  }

  public get isRequired(): boolean {
    return !this.isBusiness;
  }

  public get isDone(): boolean {
    return this.statement && this.statement.stageAdditionalIncome;
  }

  public get isVisible(): boolean {
    return this.isRequired
      && window.location.href.search(AdditionalIncomeComponentPath.regex()) !== -1;
  }

  public get title(): string {
    return 'ASIDE_BAR.ADDITIONAL_INCOME_SUBLIST.HEALTH_BENEFITS';
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.isRequired && this.statement
      && this.isStageEmployerDone;
  }

  public get urlRegex(): RegExp {
    return AdditionalIncomeComponentPath.regex(this.subStepNumber);
  }

  public get url(): string {
    return AdditionalIncomeComponentPath.url(this.getStatementId, this.subStepNumber);
  }

  public get isSubStep(): boolean {
    return true;
  }
}
