import { DateHelper } from 'app/core/helpers/date.helper';


export interface CurrencyRatesResponse {
  [year: number]: number;
}

export interface CurrencyResponse {
  id: number;
  code: string;
  rates: CurrencyRatesResponse;
}

export class CurrencyRates {
  [year: number]: number;
}

export class Currency {
  id: number;
  code: string;
  rates: CurrencyRates;

  static fromResponse(response: CurrencyResponse): Currency {
    const currency = new Currency();
    currency.id = response.id;
    currency.code = response.code;
    currency.rates = response.rates;
    return currency;
  }

  static findById(currencies: Currency[], id: number): Currency | null {
    return currencies?.find(currency => currency.id === id) || null;
  }

  static findEUR(currencies: Currency[]): Currency | null {
    return currencies?.find(currency => currency.code === 'EUR') || null;
  }

  toEuro(value: number, year: number): number {
    const defaultYear = DateHelper.getInstance().year() - 1;
    const rate = this.rates[year || defaultYear] || 1;
    return value / rate;
  }
}
