<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
          <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content">
        <div class="lhs">
            <div class="title">{{ 'PHONE_EMAIL_DIALOG.TITLE' | translate }}</div>
            <div class="desc">
                {{ 'PHONE_EMAIL_DIALOG.TITLE_2' | translate }}
            </div>
            <div class="required_fields">
                <div class="required_fields__form form-content" [errors]="statement?.emailAddress" [appShowFormError]>
                    <div class="required_fields__form-list">
                        <div class="required_fields__form-stage">
                            <app-form-input placeholder="Email"
                                            label="{{'PHONE_EMAIL_DIALOG.EMAIL_LABEL' | translate}}"
                                            [model]="statement.emailAddress"
                                            (modelChange)="changeEmailAddress($event)"
                                            [errors]=errors.email_address></app-form-input>
                        </div>
                        <div class="required_fields__form-stage">
                            <app-form-input placeholder="{{'PHONE_EMAIL_DIALOG.PHONE_NUMBER_LABEL' | translate}}"
                                            label="{{'PHONE_EMAIL_DIALOG.PHONE_NUMBER' | translate}}"
                                            [model]="statement.phoneNumber"
                                            (modelChange)="changePhoneNumber($event)"
                                            type="number"
                                            [errors]="errors.phone_number"></app-form-input>
                        </div>
                        <div class="dialog-box__buttons">
                            <button class="btn btn--longer btn--primary" (click)="save()">
                                {{ 'PHONE_EMAIL_DIALOG.BUTTON' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rhs">
            <picture>
                <img class="dialog_img" src="../../../assets/img/podaj_kontakt_grafika.png" alt="dialog image">
            </picture>
        </div>
    </div>
</div>
