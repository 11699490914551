import { Component, Input } from '@angular/core';
import { ErrorMessage, ErrorMessageContent } from 'app/core/form-validator/validatorInterface';
import { ErrorHandleHelper } from 'app/core/helpers/error-handle.helper';

@Component({
  selector: 'app-validator-error-message',
  templateUrl: './validator-error-message.component.html',
  styleUrls: ['./validator-error-message.component.scss']
})
export class ValidatorErrorMessageComponent {
  get epValidator(): ErrorMessage[] {
    return this._epValidator;
  }
  @Input() set epValidator(_epValidator: ErrorMessage[]) {
    this._epValidator = _epValidator;
    this.findMessages();
  }
  @Input() fieldName = '';
  @Input() index = -1;
  @Input() employerStage = false;
  @Input() noTopLine = false;
  public errorMessage: ErrorMessageContent[] = [];
  private _epValidator: ErrorMessage[] = [];

  public getMessage(errorMessageContent: ErrorMessageContent): string {
    const msg = errorMessageContent.message;

    if (msg.indexOf('_') === 0) {
      return msg.substring(1);
    }

    return 'EP_VALIDATOR.' + msg;
  }

  private findMessages(): void {
    this.errorMessage = ErrorHandleHelper.findEpValidatorMessages(this.epValidator, this.fieldName, this.index);
  }
}
