export const VerificationCodes = {
    ib03: 'ib03',
    ib04: 'ib04',
    ib05: 'ib05',
    ib06: 'ib06',
    ib07: 'ib07',
    ib15: 'ib15',
    ib17: 'ib17',
    ib18: 'ib18',
    ib19: 'ib19',
    ib20: 'ib20',
    ib21: 'ib21',
    ib22a: 'ib22a',
    ib23a: 'ib23a',
    ib24a: 'ib24a',
    ib24b: 'ib24b',
    ib24c: 'ib24c',
    ib25: 'ib25',
    ib26: 'ib26',
    ib27: 'ib27',
    ib28: 'ib28',
    taxClasses: 'taxClasses',
    periodOfEmploymentLower: 'periodOfEmployment.lower',
    periodOfEmploymentUpper: 'periodOfEmployment.upper',
    firstName: 'firstName',
    lastName: 'lastName',
    street: 'street',
    additionalAddressInfo: 'additionalAddressInfo',
    houseNumberWithSupplement: 'houseNumberWithSupplement',
    postalCode: 'postalCode',
    city: 'city',
    country: 'country',
    year: 'year',
    taxClass: 'taxClass',
    birthDate: 'birthDate',
    religion: 'religion',
    taxAuthorities: 'taxAuthorities',
    identifikationsnummer: 'identifikationsnummer',
    steuernummer: 'steuernummer',
    emailAddress: 'emailAddress',
    phoneNumber: 'phoneNumber',
    iban: 'iban',
    bic: 'bic',
    moneyInstitution: 'moneyInstitution',
    bankAccountHolder: 'bankAccountHolder',
    propinquity: 'propinquity',
    propinquityToSpouse: 'propinquityToSpouse',
    differentAddress: 'differentAddress',
    familienkasse: 'familienkasse',
    kindergeld: 'kindergeld',
};
