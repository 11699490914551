import { Component, Input } from "@angular/core";
import { CtrBaseComponent } from "../ctr-base.component";
import { FreelanceJobTypes } from "app/modules/statement/models/additional-income/freelance-job.types";

@Component({
  ...CtrBaseComponent.metaData,
  selector: "app-ctr-freelance-job",
  templateUrl: "./ctr-freelance-job.component.html",
  styleUrls: ["../ctr-base.component.scss"]
})
export class CtrFreelanceJobComponent extends CtrBaseComponent {
  @Input() displayFreelanceType: FreelanceJobTypes = null;

  public freelanceJobTypes: Object = FreelanceJobTypes;
  public businessNameList: Array<string> = [];

  ngOnInit() {
    this.getBusinessNameList();
  }

  private getMappedValue(mapping: Record<string, string>): any {
    const key = this.displayFreelanceType;
    return key && mapping[key] ? this.refundDetails[this.person]?.[mapping[key]] : null;
  }

  public displayJobSummit(): boolean {
    const mapping = {
      [FreelanceJobTypes.GEWERBE]: "einkunfte_aus_selbstandiger_arbeit_gewerbe_name",
      [FreelanceJobTypes.FREELANCE]: "einkunfte_aus_selbstandiger_arbeit_freelance_name",
      [FreelanceJobTypes.VOLUNTERING_INCOME]: "einkunfte_aus_selbstandiger_arbeit_volunteering"
    };
    return !!this.getMappedValue(mapping);
  }

  public getBusinessNameList() {
    const mapping = {
      [FreelanceJobTypes.GEWERBE]: "einkunfte_aus_selbstandiger_arbeit_gewerbe_name",
      [FreelanceJobTypes.FREELANCE]: "einkunfte_aus_selbstandiger_arbeit_freelance_name"
    };

    this.businessNameList = this.getMappedValue(mapping);
  }

  public displayBusinessName(index: number): string {
    return this.businessNameList[index] || "";
  }

  public displayIncomeSum(index: number): number {
    const mapping = {
      [FreelanceJobTypes.GEWERBE]: "einkunfte_aus_selbstandiger_arbeit_gewerbe_income",
      [FreelanceJobTypes.FREELANCE]: "einkunfte_aus_selbstandiger_arbeit_freelance_income"
    };
    return this.getMappedValue(mapping)[index] || 0;
  }

  public displayCostSum(index: number): number {
    const mapping = {
      [FreelanceJobTypes.GEWERBE]: "einkunfte_aus_selbstandiger_arbeit_gewerbe_costs",
      [FreelanceJobTypes.FREELANCE]: "einkunfte_aus_selbstandiger_arbeit_freelance_costs"
    };
    return this.getMappedValue(mapping)[index] || 0;
  }

  public displaySum(index: number): number {
    const mapping = {
      [FreelanceJobTypes.GEWERBE]: "einkunfte_aus_selbstandiger_arbeit_gewerbe_sum",
      [FreelanceJobTypes.FREELANCE]: "einkunfte_aus_selbstandiger_arbeit_freelance_sum",
      [FreelanceJobTypes.VOLUNTERING_INCOME]: "einkunfte_aus_selbstandiger_arbeit_volunteering"
    };
    return this.getMappedValue(mapping)[index] || 0;
  }

  public templateID(): string {
    return `freelanceJobTab_${this.displayFreelanceType}`;
  }

  public displayElement(): boolean {
    return [FreelanceJobTypes.FREELANCE, FreelanceJobTypes.GEWERBE].includes(this.displayFreelanceType);
  }
}
