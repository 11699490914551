import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getAuthUser, getUser, getUserType, State } from 'app/reducers';
import { SideNavService } from '../side-nav.service';
import { SubscribingComponent } from 'app/common';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';
import { AuthModel } from 'app/modules/user/models/auth/auth.model';

import { Router } from '@angular/router';
import { CouponsPagePath } from "app/core/workflow/page-path/coupons.path";
import { User } from 'app/users';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent extends SubscribingComponent implements OnInit {
  userLogin: string = '';
  isUserLogged = false;
  public isBusiness: boolean = false;
  public isTaxAdvisor: boolean = false;
  fullName: string;

  constructor(
    private store: Store<State>,
    private sideNavService: SideNavService,
    public workflowControllerService: WorkflowControllerService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.store.select(getAuthUser).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((user: AuthModel) => {
        if (user) {
          this.isUserLogged = true;
        } else {
          this.isUserLogged = false;
        }
      });

    this.store.select(getUser).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          this.fullName = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : '';
          this.isBusiness = user.isBusiness;
          this.userLogin = user.email;
        }
      });

    this.store.select(getUserType).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((userType: string) => {
        if (userType) {
          this.isBusiness = userType === 'business';
          this.isTaxAdvisor = userType === 'tax_adviser';
        }
      });
  }

  toggleSideNav() {
    this.sideNavService.toggle().then(() => { });
  }

  public openCoupons(): void {
    this.router.navigate([CouponsPagePath.path()]);
  }
}
