<!-- <div class="columns table-header">
    <div class="column table-header__column">{{ 'LIST.ID' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.DATE' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.NAME' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.SURNAME' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.STEUER_NO' | translate }}</div>
    <div class="column table-header__column table-header__column--dob">{{ 'LIST.DOB' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.TAX_YEAR' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.STATUS' | translate }}</div>
    <div class="column table-header__column table-header__column--refund">{{ 'LIST.REFUND_TAX' | translate }} (&euro;)</div>
    <div class="column table-header__column">{{ 'LIST.ACTIONS' | translate }}</div>
    <div class="column table-header__column" *ngIf="labelList.length>0">{{ 'LIST.LABELS' | translate }}</div>
</div>
<div class="list-view-list">
    <div class="list-view-item" *ngFor="let statement of statements, let i = index" (click)="toggleItem(i)">
        <app-list-view-item
                [statement]="statement"
                (downloadPdf)="emitDownloadPdf(statement)"
                (downloadInvoice)="emitDownloadInvoice(statement)"
                (showDecision)="emitShowDecision(statement)"
                (deleteStatement)="emitDeleteStatement(statement, i)"
                (editButton)="emitOnEditButton(statement)"
                (cloneStatement)="emitCloneStatement(statement)"
                (importStatement)="emitImportStatement(statement)"
                [openedItems]="openedItems"
                [index]="i"
                [hideEditing]="hideEditing"
                [hideRemoving]="hideRemoving"
                [hideInvoice]="hideInvoice"
                [hideDecision]="hideDecision"
                [hidePdf]="hidePdf"
                [showEditButton]="showEditButton"
                [labelList]="labelList">
        </app-list-view-item>

    </div>
</div> -->

<div class="table_wrapper">
  <div class="table_header">
    <div class="table_header_wrap">
      <div class="th tax_year">{{ "LIST.TAX_YEAR" | translate }}</div>
      <div class="th name">{{ "LIST.NAME" | translate }}</div>
      <div class="th surname">{{ "LIST.SURNAME" | translate }}</div>
      <div class="th id">{{ "LIST.ID" | translate }}</div>
      <div class="th date">{{ "LIST.DATE" | translate }}</div>
      <div class="th progress_col">{{ "LIST.PROGRESS" | translate }}</div>
      <div class="th refund_tax">{{ "LIST.REFUND_TAX" | translate }}</div>
      <div class="th steuer_no">{{ "LIST.STEUER_NO" | translate }}</div>
      <div class="th cost">{{ "LIST.COST" | translate }}</div>
      <div class="th status">{{ "LIST.STATUS" | translate }}</div>
      <div class="th actions">{{ "LIST.ACTIONS" | translate }}</div>
      <div class="th hamburger"></div>
    </div>
  </div>
  <div class="table_body">
    <div class="list-view-item" *ngFor="let statement of statements; let i = index" (click)="toggleItem(i)">
      <app-list-view-item
        [statement]="statement"
        (downloadPdf)="emitDownloadPdf(statement)"
        (downloadInvoice)="emitDownloadInvoice(statement)"
        (showDownloadDialog)="emitShowDownloadDialog(statement)"
        (showDecision)="emitShowDecision(statement)"
        (deleteStatement)="emitDeleteStatement(statement, i)"
        (editButton)="emitOnEditButton(statement)"
        (cloneStatement)="emitCloneStatement(statement)"
        (importStatement)="emitImportStatement(statement)"
        [openedItems]="openedItems"
        [index]="i"
        [hideEditing]="hideEditing"
        [hideRemoving]="hideRemoving"
        [hideInvoice]="hideInvoice"
        [hideDecision]="hideDecision"
        [hidePdf]="hidePdf"
        [showEditButton]="showEditButton"
        [labelList]="labelList"
      >
      </app-list-view-item>
    </div>
  </div>
</div>
