<div class="hamburger-container">
  <div class="menu-hamburger" (click)="handleShowHamburgerMenu()">
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="menu-hamburger-elements" [class.active]="isVisible">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
</div>
