import { DateRange, DateRangeResponse } from 'app/common/dateRange';
import {Currency} from './currency';
import { DateHelper } from 'app/core/helpers/date.helper';

export interface AbroadIncomeResponse {
  id?: number;
  live_abroad?: boolean;
  spouse_live_abroad?: boolean;
  abroad_income?: string;
  abroad_income_country?: string;
  spouse_abroad_income?: string;
  spouse_abroad_income_country?: string;
  apply_for_tax_residency_status?: boolean;
  apply_for_family_tax_privileges?: boolean;
  abroad_income_currency?: number;
  spouse_abroad_income_currency?: number;
  live_in_germany?: boolean; //Code was commented becouse of task TAX-2984 In that task we dont want to validate and display on front select box with info if person live_in_germany 

  apply_for_unlimited_tax_liability: boolean;
  spouse_apply_for_unlimited_tax_liability: boolean;
  apply_for_unlimited_tax_liability_with_relief: boolean;
  accepted_tax_residency_message: boolean;
  spouse_accepted_tax_residency_message: boolean;
  days_employed: number;
  spouse_days_employed: number;
  income_type: string;
  spouse_income_type: string;
  period_of_employment?: any[];
  spouse_period_of_employment?: any[];
}

export interface AdditionalAbroadIncomeResponse {
  apply_for_unlimited_tax_liability?: boolean;
  spouse_apply_for_unlimited_tax_liability?: boolean;
  apply_for_unlimited_tax_liability_with_relief? :boolean;
  accepted_tax_residency_message?: boolean;
  spouse_accepted_tax_residency_message?: boolean;
  days_employed?: number;
  spouse_days_employed?: number;
  income_type?: string;
  spouse_income_type?: string;
  abroad_income? :string;
  abroad_income_country? :string;
  abroad_income_currency? :number;
}

export interface AbroadIncomeErrors {
  live_abroad?: string[];
  spouse_live_abroad?: string[];
  abroad_income?: string[];
  abroad_income_country?: string[];
  spouse_abroad_income?: string[];
  spouse_abroad_income_country?: string[];
  apply_for_tax_residency_status?: string[];
  apply_for_family_tax_privileges?: string[];
  abroad_income_currency?: string[];
  spouse_abroad_income_currency?: string[];

  non_field_errors?: string[];
  period_of_employments?: string[];
}

export class AbroadIncome {
  id?: number;
  liveAbroad = false;
  spouseLiveAbroad = false;
  abroadIncome = '0.00';
  test = 0;
  abroadIncomeCountry = null;
  spouseAbroadIncome = '0.00';
  spouseAbroadIncomeCountry = null;
  applyForTaxResidencyStatus?: boolean;
  applyForFamilyTaxPrivileges?: boolean;
  abroadIncomeCurrency?: number;
  spouseAbroadIncomeCurrency?: number;
  liveInGermany = false;
  currYear?: number

  //new flags
  apply_for_unlimited_tax_liability: boolean = false;
  spouse_apply_for_unlimited_tax_liability: boolean = false;
  accepted_tax_residency_message: boolean = false;
  spouse_accepted_tax_residency_message: boolean = true;
  apply_for_unlimited_tax_liability_with_relief: boolean = false;
  days_employed: number = 0;
  spouse_days_employed: number = 0;
  income_type: string = "Contract of employment";
  spouse_income_type: string = "Contract of employment";
  period_of_employment: any[] = [];
  spouse_period_of_employment: any[] = [];

  constructor(currYear? : number){
    if(currYear){
      this.currYear = currYear;
    }
  }
  
  

  static fromResponse(response: AbroadIncomeResponse, currYear? : any): AbroadIncome {
    const abroadIncome = new AbroadIncome();

    abroadIncome.id = response.id;
    abroadIncome.liveAbroad = response.live_abroad;
    abroadIncome.liveInGermany = response.live_in_germany; //Code was commented becouse of task TAX-2984 In that task we dont want to validate and display on front select box with info if person live_in_germany 
    abroadIncome.spouseLiveAbroad = response.spouse_live_abroad;
    abroadIncome.abroadIncome = response.abroad_income;
    abroadIncome.abroadIncomeCountry = response.abroad_income_country;
    abroadIncome.spouseAbroadIncome = response.spouse_abroad_income;
    abroadIncome.spouseAbroadIncomeCountry = response.spouse_abroad_income_country;
    abroadIncome.applyForTaxResidencyStatus = response.apply_for_tax_residency_status;
    abroadIncome.applyForFamilyTaxPrivileges = response.apply_for_family_tax_privileges;
    abroadIncome.abroadIncomeCurrency = response.abroad_income_currency;
    abroadIncome.spouseAbroadIncomeCurrency = response.spouse_abroad_income_currency;

    abroadIncome.apply_for_unlimited_tax_liability = response.apply_for_unlimited_tax_liability;
    abroadIncome.spouse_apply_for_unlimited_tax_liability = response.spouse_apply_for_unlimited_tax_liability;
    abroadIncome.apply_for_unlimited_tax_liability_with_relief =response.apply_for_unlimited_tax_liability_with_relief;
    abroadIncome.accepted_tax_residency_message = response.accepted_tax_residency_message;
    abroadIncome.spouse_accepted_tax_residency_message = response.spouse_accepted_tax_residency_message;
    abroadIncome.days_employed = response.days_employed;
    abroadIncome.spouse_days_employed = response.spouse_days_employed;
    abroadIncome.income_type = response.income_type;
    abroadIncome.spouse_income_type = response.spouse_income_type;

    abroadIncome.period_of_employment =
      response && response.period_of_employment?.length > 0 ? response.period_of_employment.map(period=>{
        return {lower: DateHelper.getFormatted(period.lower, 'DD/MM'), upper: DateHelper.getFormatted(period.upper, 'DD/MM')}
      }) : [];

    abroadIncome.spouse_period_of_employment =
    response && response.spouse_period_of_employment?.length > 0 ? response.spouse_period_of_employment.map(period=>{
      return {lower: DateHelper.getFormatted(period.lower, 'DD/MM'), upper: DateHelper.getFormatted(period.upper, 'DD/MM')}
    }) : [];

    if(currYear){
      abroadIncome.currYear = currYear;
    }
    

    return abroadIncome;
  }

  static fromStorage(response: AbroadIncome): AbroadIncome {
    const abroadIncome = new AbroadIncome();
    abroadIncome.id = response.id;
    abroadIncome.liveAbroad = response.liveAbroad;
    abroadIncome.liveInGermany = response.liveInGermany;
    abroadIncome.spouseLiveAbroad = response.spouseLiveAbroad;
    abroadIncome.abroadIncome = response.abroadIncome;
    abroadIncome.abroadIncomeCountry = response.abroadIncomeCountry;
    abroadIncome.spouseAbroadIncome = response.spouseAbroadIncome;
    abroadIncome.spouseAbroadIncomeCountry = response.spouseAbroadIncomeCountry;
    abroadIncome.applyForTaxResidencyStatus = response.applyForTaxResidencyStatus;
    abroadIncome.applyForFamilyTaxPrivileges = response.applyForFamilyTaxPrivileges;
    abroadIncome.abroadIncomeCurrency = response.abroadIncomeCurrency;
    abroadIncome.spouseAbroadIncomeCurrency = response.spouseAbroadIncomeCurrency;

    abroadIncome.apply_for_unlimited_tax_liability = response.apply_for_unlimited_tax_liability;
    abroadIncome.spouse_apply_for_unlimited_tax_liability = response.spouse_apply_for_unlimited_tax_liability;
    abroadIncome.apply_for_unlimited_tax_liability_with_relief = response.apply_for_unlimited_tax_liability_with_relief;
    abroadIncome.accepted_tax_residency_message = response.accepted_tax_residency_message;
    abroadIncome.spouse_accepted_tax_residency_message = response.spouse_accepted_tax_residency_message;
    abroadIncome.days_employed = response.days_employed;
    abroadIncome.spouse_days_employed = response.spouse_days_employed;
    abroadIncome.income_type = response.income_type;
    abroadIncome.spouse_income_type = response.spouse_income_type;
    abroadIncome.period_of_employment = response.period_of_employment;
    abroadIncome.spouse_period_of_employment = response.spouse_period_of_employment;

    return abroadIncome;
  }

  toResponse(): AbroadIncomeResponse {
    const toDateRangeResponse = (value: any) => {
      if(value.lower && value.upper){
        let lower = value.lower.split("/");
        let upper = value.upper.split("/");
        
        let lowerDateToFrormat = this.currYear+ "-" + lower[1] +"-"+lower[0];
        let upperDateToFrormat = this.currYear+ "-" + upper[1] +"-"+upper[0];
        
        return {lower: DateHelper.getFormatted(lowerDateToFrormat, 'YYYY-MM-DD'), upper: DateHelper.getFormatted(upperDateToFrormat, 'YYYY-MM-DD')}
      }
      
      return
    };
    return {
      live_abroad: this.liveAbroad,
      spouse_live_abroad: this.spouseLiveAbroad,
      abroad_income: this.liveAbroad ? this.abroadIncome : '0.00',
      abroad_income_country: this.abroadIncomeCountry,
      spouse_abroad_income: this.spouseLiveAbroad ? this.spouseAbroadIncome : '0.00',
      spouse_abroad_income_country: this.spouseAbroadIncomeCountry,
      apply_for_tax_residency_status: this.applyForTaxResidencyStatus,
      apply_for_family_tax_privileges: this.applyForFamilyTaxPrivileges,
      live_in_germany: this.liveInGermany, //Code was commented becouse of task TAX-2984 In that task we dont want to validate and display on front select box with info if person live_in_germany 
      abroad_income_currency:
        this.abroadIncomeCurrency || this.abroadIncomeCurrency === 0 ? this.abroadIncomeCurrency : null,
      spouse_abroad_income_currency:
        this.spouseAbroadIncomeCurrency || this.spouseAbroadIncomeCurrency === 0 ? this.spouseAbroadIncomeCurrency : null,

      apply_for_unlimited_tax_liability : this.apply_for_unlimited_tax_liability,
      spouse_apply_for_unlimited_tax_liability : this.spouse_apply_for_unlimited_tax_liability,
      apply_for_unlimited_tax_liability_with_relief: this.apply_for_unlimited_tax_liability_with_relief,
      accepted_tax_residency_message : this.accepted_tax_residency_message,
      spouse_accepted_tax_residency_message : this.spouse_accepted_tax_residency_message,
      days_employed : this.days_employed,
      spouse_days_employed : this.spouse_days_employed,
      income_type : this.income_type,
      spouse_income_type : this.spouse_income_type,
      period_of_employment : this.period_of_employment ? this.period_of_employment.map(toDateRangeResponse) : [],
      spouse_period_of_employment : this.spouse_period_of_employment ? this.spouse_period_of_employment.map(toDateRangeResponse) : [],
    };
  }

  totalAbroadIncome(currencies: Currency[], statementYear: number): number {
    let abroadIncome = 0;
    let spouseAbroadIncome = 0;

    if (this.liveAbroad) {
      const currency = Currency.findById(currencies, this.abroadIncomeCurrency);
      abroadIncome = parseFloat(this.abroadIncome) || 0;
      abroadIncome = currency ? currency.toEuro(abroadIncome, statementYear) : abroadIncome;
    }

    if (this.spouseLiveAbroad) {
      const spouseCurrency = Currency.findById(currencies, this.spouseAbroadIncomeCurrency);
      spouseAbroadIncome = parseFloat(this.spouseAbroadIncome) || 0;
      spouseAbroadIncome = spouseCurrency ? spouseCurrency.toEuro(spouseAbroadIncome, statementYear) : spouseAbroadIncome;
    }

    return abroadIncome + spouseAbroadIncome;
  }

  resetSpouseAbroadIncome() {
    this.spouseAbroadIncome = '0.00';
    this.spouseLiveAbroad = false;
  }
}
