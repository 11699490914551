import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {State, getIsUserLogged} from 'app/reducers';
import {takeUntil} from 'rxjs/operators';

import {SubscribingComponent} from '../../common';
import {PaymentService} from 'app/payment/payment.service';
import { AuthService } from 'app/users';

@Component({
  selector: 'app-payment-execution',
  templateUrl: './payment-execution.component.html',
  styleUrls: ['./payment-execution.component.scss']
})
export class PaymentExecutionComponent extends SubscribingComponent implements OnInit {

  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private authService: AuthService,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.waitForLogin();
  }

  redirectTo(destination: string): string {
    const url = this.router.url.split('/').slice(0, -1).join('/');
    return `${url}/${destination}`;
  }

  private waitForLogin(): void {
    let timeout = null;
    this.store.select(getIsUserLogged).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((isLogged: boolean) => {
        if (isLogged) {
          this.executePayment();
          clearTimeout(timeout);
        } else {
          timeout = setTimeout(() => this.authService.logout(), 10000);
        }
      });
  }

  private executePayment(): void {
    const url = this.router.url;
    let destination = '';
    if (url.includes('batch')) {
      destination = this.redirectTo('client-summary');
    } else if (url.includes('statement')) {
      destination = this.redirectTo('thank-you');
    } else {
      destination = '/payment-confirmation';
    }

    this.paymentService.afterPayPalExecution().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => this.router.navigate([destination]),
        () => this.router.navigate(['/payment-abort'])
      );
  }
}
