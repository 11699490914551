<!-- <div class="columns table-header">
    <div class="column table-header__column">{{ 'LIST.ID' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.DATE' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.NAME' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.SURNAME' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.STEUER_NO' | translate }}</div>
    <div class="column table-header__column table-header__column--dob">{{ 'LIST.DOB' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.TAX_YEAR' | translate }}</div>
    <div class="column table-header__column">{{ 'LIST.STATUS' | translate }}</div>
    <div class="column table-header__column table-header__column--refund">{{ 'LIST.REFUND_TAX' | translate }} (&euro;)</div>
    <div class="column table-header__column">{{ 'LIST.ACTIONS' | translate }}</div>
    <div class="column table-header__column" *ngIf="labelList.length>0">{{ 'LIST.LABELS' | translate }}</div>
</div>
<div class="list-view-list">
    <div class="list-view-item" *ngFor="let statement of statements, let i = index" (click)="toggleItem(i)">
        <app-list-view-item-b2b
                [statement]="statement"
                (downloadPdf)="emitDownloadPdf(statement)"
                (downloadInvoice)="emitDownloadInvoice(statement)"
                (showDecision)="emitShowDecision(statement)"
                (deleteStatement)="emitDeleteStatement(statement, i)"
                (editButton)="emitOnEditButton(statement)"
                (cloneStatement)="emitCloneStatement(statement)"
                (importStatement)="emitImportStatement(statement)"
                [openedItems]="openedItems"
                [index]="i"
                [hideEditing]="hideEditing"
                [hideRemoving]="hideRemoving"
                [hideInvoice]="hideInvoice"
                [hideDecision]="hideDecision"
                [hidePdf]="hidePdf"
                [showEditButton]="showEditButton"
                [labelList]="labelList">
        </app-list-view-item-b2b>

    </div>
</div> -->

<div class="table_scroll">
  <div class="table_wrapper">
    <div class="table_header">
      <div class="table_header_wrap">
        <div class="th tax_year" [title]="'LIST.TAX_YEAR' | translate">{{ "LIST.TAX_YEAR" | translate }}</div>
        <div class="th name" [title]="'LIST.NAME' | translate">{{ "LIST.NAME" | translate }}</div>
        <div class="th surname" [title]="'LIST.SURNAME' | translate">{{ "LIST.SURNAME" | translate }}</div>
        <div class="th id" [title]="'LIST.ID' | translate">{{ "LIST.ID" | translate }}</div>
        <div class="th date" [title]="'LIST.DATE' | translate">{{ "LIST.DATE" | translate }}</div>
        <div class="th refund_tax" [title]="'LIST.REFUND_TAX' | translate"
          >{{ "LIST.REFUND_TAX" | translate }} (&euro;)</div
        >
        <div class="th steuer_no" [title]="'LIST.STEUER_NO' | translate">{{ "LIST.STEUER_NO" | translate }}</div>
        <div class="th dob" [title]="'LIST.DOB' | translate">{{ "LIST.DOB" | translate }}</div>
        <div class="th status" [title]="'LIST.STATUS' | translate">{{ "LIST.STATUS" | translate }}</div>

        <div class="th actions" [title]="'LIST.ACTIONS' | translate">{{ "LIST.ACTIONS" | translate }}</div>
        <div class="th labels" [title]="'LIST.LABELS' | translate" *ngIf="labelList.length > 0">{{
          "LIST.LABELS" | translate
        }}</div>
        <div class="th hamburger"></div>
      </div>
    </div>
    <div class="table_body">
      <div class="list-view-item" *ngFor="let statement of statements; let i = index" (click)="toggleItem(i)">
        <app-list-view-item-b2b
          [statement]="statement"
          (downloadPdf)="emitDownloadPdf(statement)"
          (downloadInvoice)="emitDownloadInvoice(statement)"
          (deleteStatement)="emitDeleteStatement(statement, i)"
          (editButton)="emitOnEditButton(statement)"
          (cloneStatement)="emitCloneStatement(statement)"
          (importStatement)="emitImportStatement(statement)"
          [openedItems]="openedItems"
          [index]="i"
          [hideEditing]="hideEditing"
          [hideRemoving]="hideRemoving"
          [hideInvoice]="hideInvoice"
          [hideDecision]="hideDecision"
          [hidePdf]="hidePdf"
          [showEditButton]="showEditButton"
          [showCloneButton]="cloneStatement.observers && cloneStatement.observers.length"
          [labelList]="labelList"
        >
        </app-list-view-item-b2b>
      </div>
    </div>
  </div>
</div>
