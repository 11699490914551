import { finalize, takeUntil } from "rxjs/operators";
import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { Store } from "@ngrx/store";

import { State, getUser } from "../../reducers";

import { User } from "app/users";
import { Statement } from "../../statements";
import { StatementService } from "../../statements/statement.service";
import { SubscribingComponent } from "../subscribing-component";
import { MaritalStatus } from "../../statements";
import { ErrorMessage } from "app/core/form-validator/validatorInterface";
import { TranslateService } from "@ngx-translate/core";
import { DateHelper } from "../../core/helpers/date.helper";

class DateWarnings {
  date_of_marriage: string[];
  date_of_partnership: string[];
  date_of_widowed: string[];
  date_of_separation: string[];
  date_of_divorce: string[];
}

@Component({
  selector: "app-profile-edit",
  templateUrl: "./profile-edit.component.html",
  styleUrls: ["./profile-edit.component.scss"]
})
export class ProfileEditComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  @Input() errors;
  contentLoaded: boolean = false;
  @Input() currentStep: number = 1;
  @Input() epValidator: ErrorMessage[] = [];
  @ViewChild("jointSettlement", { static: false }) jointSettlement: ElementRef;
  @ViewChild("childrensection", { static: false }) childrensection: ElementRef;
  public warnings = new DateWarnings();

  loadingMaritalStatuses = false;
  maritalStatuses: MaritalStatus[] = [];
  status: number;
  statuses = [
    [1, "SELECT.MARITAL_STATUS.TYPES.SINGLE"],
    [2, "SELECT.MARITAL_STATUS.TYPES.MARRIED"],
    [3, "SELECT.MARITAL_STATUS.TYPES.PARTNERSHIP"],
    [4, "SELECT.MARITAL_STATUS.TYPES.WIDOWED"],
    [5, "SELECT.MARITAL_STATUS.TYPES.SEPARETED"],
    [6, "SELECT.MARITAL_STATUS.TYPES.DIVORCED"]
  ];
  isBusiness: boolean = true;
  public preIndexArray: string[] = ["1", "2", "3"];

  public tilesStatuses = [
    {
      status: 1,
      icon: "single.svg",
      name: "SELECT.MARITAL_STATUS.TYPES.SINGLE",
      active: false
    },
    {
      status: 2,
      icon: "marriage.svg",
      name: "SELECT.MARITAL_STATUS.TYPES.MARRIED",
      active: false
    },
    {
      status: 3,
      icon: "partnership.svg",
      name: "SELECT.MARITAL_STATUS.TYPES.PARTNERSHIP",
      active: false
    },
    {
      status: 4,
      icon: "widowed.svg",
      name: "SELECT.MARITAL_STATUS.TYPES.WIDOWED",
      active: false
    },
    {
      status: 5,
      icon: "separeted.svg",
      name: "SELECT.MARITAL_STATUS.TYPES.SEPARETED",
      active: false
    },
    {
      status: 6,
      icon: "divorced.svg",
      name: "SELECT.MARITAL_STATUS.TYPES.DIVORCED",
      active: false
    }
  ];

  //public childrenIndex: number = 2;

  public collapseJointSettlement: boolean = true;
  public collapseChildrenSettlement: boolean = true;

  constructor(
    private statementService: StatementService,
    private store: Store<State>,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user) {
          this.isBusiness = user.isBusiness;
          this.getMaritalStatuses();
          if (this.isBusiness) {
            if (this.statement) {
              if (this.statement.filedTogether) {
                this.collapseJointSettlement = false;
              } else {
                this.collapseJointSettlement = true;
              }

              if (this.statement.hasChildren) {
                this.collapseChildrenSettlement = false;
              } else {
                this.collapseChildrenSettlement = true;
              }
            }
          } else {
            this.preIndexArray = ["", "", ""];
          }
        }
      });

    this.getMaritalStatuses();
    this.checkIfStatusIsSet();
  }

  public dateChange(date: any) {
    let self = this;

    setTimeout((e) => {
      if (self.jointSettlement !== undefined && typeof date.getMonth === "function") {
        self.collapseJointSettlement = false;
        setTimeout((e) => {
          if (self.jointSettlement !== undefined) {
            self.jointSettlement.nativeElement.scrollIntoView();
          }
        }, 100);
      } else {
        if (this.isBusiness) self.collapseJointSettlement = true;
      }
    }, 100);
  }

  private checkIfStatusIsSet() {
    if (this.statement.relationshipStatus) {
      let tilesStatusesFilter = this.tilesStatuses.filter((item) => item.status === this.statement.relationshipStatus);
      tilesStatusesFilter[0].active = true;
      this.status = this.statement.relationshipStatus;

      if (this.isBusiness) {
        if (this.canFilTogether()) {
          // this.childrenIndex = 3;
          this.preIndexArray = ["1", "2", "3"];
        } else {
          this.preIndexArray = ["1", "2", "2"];
          // this.childrenIndex = 2;
        }
      }
    }
  }

  canFilTogether() {
    if (!this.errors) {
      this.errors = {};
    }

    this.errors.date_of_marriage = [];
    this.errors.date_of_partnership = [];
    this.errors.date_of_widowed = [];
    this.errors.date_of_separation = [];
    this.errors.date_of_divorce = [];
    this.warnings = new DateWarnings();

    if (this.status === 1) {
      return false;
    }

    if (this.status === 2) {
      if (this.statement.dateOfMarriage && DateHelper.getFullYearFromString(this.statement.dateOfMarriage)) {
        if (DateHelper.getInstance(this.statement.dateOfMarriage).diff(DateHelper.getInstance()) > 0) {
          this.errors.date_of_marriage = [this.translate.instant("PROFILE_BC.THE_DATE_CANNOT_BE_IN_THE_FUTURE")];

          return false;
        }
        if (DateHelper.getFullYearFromString(this.statement.dateOfMarriage) > this.statement.fullYear) {
          this.warnings.date_of_marriage = [this.translate.instant("PROFILE_BC.DATE_OF_MARRIAGE_ERROR")];

          return false;
        }

        return true;
      }
    }

    if (this.status === 3) {
      if (this.statement.dateOfPartnership && DateHelper.getFullYearFromString(this.statement.dateOfPartnership)) {
        if (DateHelper.getInstance(this.statement.dateOfPartnership).diff(DateHelper.getInstance()) > 0) {
          this.errors.date_of_partnership = [this.translate.instant("PROFILE_BC.THE_DATE_CANNOT_BE_IN_THE_FUTURE")];

          return false;
        }
        if (DateHelper.getFullYearFromString(this.statement.dateOfPartnership) > this.statement.fullYear) {
          this.warnings.date_of_partnership = [this.translate.instant("PROFILE_BC.DATE_OF_PARTNERSHIP_ERROR")];

          return false;
        }

        return true;
      }
    }

    if (
      this.status === 4 &&
      this.statement.dateOfWidowed &&
      DateHelper.getFullYearFromString(this.statement.dateOfWidowed)
    ) {
      if (DateHelper.getFullYearFromString(this.statement.dateOfWidowed) + 1 < this.statement.fullYear) {
        this.warnings.date_of_widowed = [this.translate.instant("PROFILE_BC.DATE_OF_WIDOWED_CAN_NOT_BE_EARLIER_ERROR")];

        return false;
      }
      if (DateHelper.getInstance(this.statement.dateOfWidowed).diff(DateHelper.getInstance()) > 0) {
        this.errors.date_of_widowed = [this.translate.instant("PROFILE_BC.THE_DATE_CANNOT_BE_IN_THE_FUTURE")];

        return false;
      }

      return true;
    }

    if (
      this.status === 5 &&
      this.statement.dateOfSeparation &&
      DateHelper.getFullYearFromString(this.statement.dateOfSeparation)
    ) {
      if (DateHelper.getFullYearFromString(this.statement.dateOfSeparation) < this.statement.fullYear) {
        this.warnings.date_of_separation = [
          this.translate.instant("PROFILE_BC.DATE_OF_SEPARATION_CAN_NOT_BE_EARLIER_ERROR")
        ];

        return false;
      }
      if (DateHelper.getInstance(this.statement.dateOfSeparation).diff(DateHelper.getInstance()) > 0) {
        this.errors.date_of_separation = [this.translate.instant("PROFILE_BC.THE_DATE_CANNOT_BE_IN_THE_FUTURE")];

        return false;
      }

      return true;
    }

    if (
      this.status === 6 &&
      this.statement.dateOfDivorce &&
      DateHelper.getFullYearFromString(this.statement.dateOfDivorce)
    ) {
      if (DateHelper.getFullYearFromString(this.statement.dateOfDivorce) < this.statement.fullYear) {
        this.warnings.date_of_divorce = [this.translate.instant("PROFILE_BC.DATE_OF_DIVORCE_CAN_NOT_BE_EARLIER_ERROR")];

        return false;
      }
      if (DateHelper.getInstance(this.statement.dateOfDivorce).diff(DateHelper.getInstance()) > 0) {
        this.errors.date_of_divorce = [this.translate.instant("PROFILE_BC.THE_DATE_CANNOT_BE_IN_THE_FUTURE")];

        return false;
      }

      return true;
    }

    return false;
  }

  statusChanged(newStatus) {
    this.statement.dateOfMarriage = "";
    this.statement.dateOfPartnership = "";
    this.statement.dateOfWidowed = "";
    this.statement.dateOfSeparation = "";
    this.statement.dateOfDivorce = "";
    this.statement.isMarried = false;
    this.statement.isWidowed = false;
    this.statement.isSeparated = false;
    this.statement.isDivorced = false;
    this.statement.filedTogether = false;
    this.statement.relationshipStatus = newStatus;
    if (this.isBusiness) {
      this.collapseChildrenSettlement = true;
    }

    if (newStatus === 1) {
      const single: MaritalStatus = this.findMaritalStatus("single");
      if (single) {
        this.statement.maritalStatus = single.id;
      }
    } else {
      const married: MaritalStatus = this.findMaritalStatus("married");
      if (married) {
        this.statement.maritalStatus = married.id;
      }
      if (newStatus === 2 || newStatus === 3) {
        this.statement.isMarried = true;
      } else if (newStatus === 4) {
        this.statement.isWidowed = true;
      } else if (newStatus === 5) {
        this.statement.isSeparated = true;
      } else if (newStatus === 6) {
        this.statement.isDivorced = true;
      }
    }
  }

  public selectStatus(tileStatuse: any) {
    tileStatuse.active = !tileStatuse.active;

    let statusNr = 0;
    if (tileStatuse.status === this.status && !tileStatuse.active) {
      this.status = 0;
    } else {
      statusNr = tileStatuse.status;
      this.status = statusNr;
    }

    if (tileStatuse.active) {
      this.tilesStatuses.forEach((item) => {
        if (item.status !== tileStatuse.status) item.active = false;
      });
    }

    this.statusChanged(statusNr);
    this.canFilTogether();
    if (this.canFilTogether()) {
      this.preIndexArray = ["1", "2", "3"];
    } else {
      this.preIndexArray = ["1", "2", "2"];
    }
    this.collapseJointSettlement = true;
    if (tileStatuse.status === 1) {
      this.collapseChildrenSettlement = false;
      this.childrensection.nativeElement.scrollIntoView();
    }
  }

  public preventClick(event) {
    event.preventDefault();
  }

  public changeFieldTogether() {
    this.statement.filedTogether = !this.statement.filedTogether;
    this.collapseChildrenSettlement = false;
    this.childrensection.nativeElement.scrollIntoView();
  }

  public selectHasChildren() {
    this.statement.hasChildren = !this.statement.hasChildren;
    this.statement.hasChildrenDeductions = this.statement.hasChildren;
    // if (!this.statement.hasChildren) {
    //   this.statement.hasChildrenDeductions = false;
    // }
    // this.selectHasChildrenDeductions();
  }

  public selectHasChildrenDeductions() {
    if (this.statement.hasChildren) {
      this.statement.hasChildrenDeductions = !this.statement.hasChildrenDeductions;
    }
  }

  isStatusFilledCorrectly(): boolean {
    if (this.status !== 1) {
      const married: MaritalStatus = this.findMaritalStatus("married");

      if (
        (this.status === 2 && this.statement.dateOfMarriage) ||
        (this.status === 3 && this.statement.dateOfPartnership) ||
        (this.status === 4 && this.statement.dateOfWidowed) ||
        (this.status === 5 && this.statement.dateOfSeparation) ||
        (this.status === 6 && this.statement.dateOfDivorce && married && this.statement.maritalStatus === married.id) ||
        (this.isBusiness && this.status)
      ) {
        return true;
      }
    } else {
      const single: MaritalStatus = this.findMaritalStatus("single");
      if (
        (this.status === 1 && single && this.statement.maritalStatus === single.id) ||
        (this.isBusiness && this.status)
      ) {
        return true;
      }
    }

    return false;
  }

  isDateFormatsCorrectly(): boolean {
    if (!this.statement) {
      return false;
    }
    // if (this.isBusiness) {
    //   return true;
    // }

    return true;
    // switch (this.status) {
    //   case 1: {
    //     return true;
    //   }
    //   case 2: {
    //     // console.log(this.isCorrectDate(this.statement.dateOfMarriage));
    //     return this.isCorrectDate(this.statement.dateOfMarriage);
    //   }
    //   case 3: {
    //     return this.isCorrectDate(this.statement.dateOfPartnership);
    //   }
    //   case 4: {
    //     return this.isCorrectDate(this.statement.dateOfMarriage) && this.isCorrectDate(this.statement.dateOfWidowed);
    //   }
    //   case 5: {
    //     return this.isCorrectDate(this.statement.dateOfMarriage) && this.isCorrectDate(this.statement.dateOfSeparation);
    //   }
    //   case 6: {
    //     return this.isCorrectDate(this.statement.dateOfMarriage) && this.isCorrectDate(this.statement.dateOfDivorce);
    //   }
    //   default: {
    //     return true;
    //   }
    // }
  }

  private isValidDate(date) {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
  }

  private isCorrectDate(date: string) {
    return date ? DateHelper.getInstance(date, "YYYY-MM-DD", true).isValid() : true;
    //return date ? DateHelper.getInstance(date, 'DD.MM.YYYY',).isValid() : true;
  }

  private getMaritalStatuses() {
    this.loadingMaritalStatuses = true;
    this.statementService
      .getMaritalStatuses()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.loadingMaritalStatuses = false;
          this.contentLoaded = true;
        })
      )
      .subscribe((statuses: MaritalStatus[]) => {
        this.maritalStatuses = statuses;
        this.fillMaritalInfo();
      });
  }

  private fillMaritalInfo() {
    const single: MaritalStatus = this.findMaritalStatus("single");
    if (this.statement.maritalStatus === single.id) {
      this.status = 1;
    } else {
      if (
        (this.statement.dateOfMarriage &&
          !(this.statement.dateOfWidowed || this.statement.dateOfSeparation || this.statement.dateOfDivorce)) ||
        (this.isBusiness && this.statement.relationshipStatus === 2)
      ) {
        this.status = 2;
      } else if (this.statement.dateOfPartnership || (this.isBusiness && this.statement.relationshipStatus === 3)) {
        this.status = 3;
      } else if (this.statement.dateOfWidowed || (this.isBusiness && this.statement.relationshipStatus === 4)) {
        this.status = 4;
      } else if (this.statement.dateOfSeparation || (this.isBusiness && this.statement.relationshipStatus === 5)) {
        this.status = 5;
      } else if (this.statement.dateOfDivorce || (this.isBusiness && this.statement.relationshipStatus === 6)) {
        this.status = 6;
      }
    }
  }

  private findMaritalStatus(name: string) {
    return this.maritalStatuses.find((status: MaritalStatus) => status.name.toLowerCase() === name);
  }
}
