<div class="w_modal_bg" *ngIf="language || step1 || step2 || step3 || step4">
  <div class="w_modal_green w_modal" *ngIf="language">
    <div class="close" (click)="closeDialog()">
      <span class="close_box"></span>
    </div>
    <div class="language_wrap">
      <div class="language" *ngFor="let language of languagesList" (click)="setLang(language.code)">
        <p> {{ language.originalFullName }} </p>
      </div>
    </div>
    <div class="svg language_svg">
      <img src="../../../assets/svg/new_logo.svg" />
    </div>
  </div>

  <div class="w_modal" *ngIf="step1">
    <!-- <div class="close" (click)="language = false; step1 = false; step2 = false; step3 = false; step4 = false">
        <span>{{ 'WELCOME_MODAL.SKIP' | translate }}</span>
        <span class="close_box"></span>
    </div> -->
    <div class="w_modal_cont step_1_wrap">
      <div class="title_wrap">
        <h2 [innerHTML]="'WELCOME_MODAL.WELCOME' | translate "></h2>
        <p class="subdesc" [innerHTML]="'WELCOME_MODAL.DESC' | translate"></p>
      </div>
      <div class="svg">
        <img src="../../../assets/svg/welcome_fullhd.svg" />
      </div>
    </div>
    <div class="nav_wrap">
      <div class="login_btns_wrap" (click)="openLoginDialog()">
        <span>{{ 'COMMON.ALREADY_REGISTERED' | translate }}</span>
        <span class="green">{{ 'COMMON.LOG_IN' | translate }}</span>
      </div>
      <div class="button_wrap step_1">
        <button (click)="step1 = false" class="skip navButton">
          <span>{{ 'WELCOME_MODAL.SKIP' | translate }}</span>
        </button>
        <button
          class="navButton"
          (click)="step1 = false; step2 = true; checkButtonsTextWidth(); executeFirebaseEvent('tutorial_begin', null)"
        >
          <span>{{ 'WELCOME_MODAL.SHOW_MORE' | translate }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="w_modal" *ngIf="step2">
    <!-- <div class="close" (click)="step2 = false">
        <span class="close_box"></span>
    </div> -->
    <div class="w_modal_cont step_2_wrap">
      <div class="title_wrap">
        <h2 [innerHTML]="'WELCOME_MODAL.TITLE_1' | translate"></h2>
        <p class="subdesc" [innerHTML]="'WELCOME_MODAL.DESC_1' | translate"></p>
      </div>
      <!-- <div class="svg">
          <img src="../../../assets/svg/welcome1.svg" />
      </div> -->
      <div class="w_modal_desc">
        <div class="w_modal_col">
          <h3 class="left" [innerHTML]="'WELCOME_MODAL.CONT_1.TITLE_1' | translate"></h3>
          <ul>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_1.LIST.LIST_1' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_1.LIST.LIST_2' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_1.LIST.LIST_3' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_1.LIST.LIST_4' | translate }}</span>
            </li>
          </ul>
        </div>
        <div class="w_modal_col">
          <h3 [innerHTML]="'WELCOME_MODAL.CONT_1.TITLE_2' | translate"></h3>
          <img class="step_img" src="../../../assets/svg/welcome2.svg" />
        </div>
        <div class="w_modal_col full">
          <p class="subdesc" [innerHTML]=" 'WELCOME_MODAL.CONT_1.DESC' | translate"></p>
        </div>
      </div>
    </div>
    <div class="nav_wrap">
      <div class="login_btns_wrap" (click)="openLoginDialog()">
        <span>{{ 'COMMON.ALREADY_REGISTERED' | translate }}</span>
        <span class="green">{{ 'COMMON.LOG_IN' | translate }}</span>
      </div>
      <div class="button_wrap">
        <button (click)="step2 = false" class="skip navButton">
          <span>{{ 'WELCOME_MODAL.SKIP' | translate }}</span>
        </button>
        <button (click)="step2 = false; step1 = true; checkButtonsTextWidth()" class="back">
          <img src="../../../assets/svg/back_btn.svg" alt="back">
        </button>
        <button class="navButton" (click)="step2 = false; step3 = true; checkButtonsTextWidth()">
          <span>{{ 'WELCOME_MODAL.NEXT' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="w_modal dialog-box position-relative" [class.is-loading]="loadingSelectsData" *ngIf="step3">
    <!-- <div class="close" (click)="step3 = false">
        <span class="close_box"></span>
    </div> -->
    <div class="w_modal_cont step_3_wrap" *ngIf="!loadingSelectsData">
      <div class="title_wrap">
        <h2 [innerHTML]="'WELCOME_MODAL.TITLE_2' | translate"></h2>
      </div>
      <!-- <div class="svg">
          <img src="../../../assets/svg/welcome1.svg" />
      </div> -->
      <div class="w_modal_desc">
        <div class="w_modal_col">
          <h3 class="text-primary font-size-24" [innerHTML]="'WELCOME_MODAL.CONT_2.COL_1.TITLE' | translate"></h3>
          <div class="text-muted text-center">{{ 'WELCOME_MODAL.CONT_2.COL_1.UPTO' | translate }}</div>
          <div class="price text-center">
            <span class="font-weight-bolder">{{ selectsData.packageOptions.currencySymbol.symbol }}</span>
            <span class="font-weight-bold">{{ numberFormatHelper.integer(selectsData.packageOptions.defaultFreeAmount) }}</span>
            <span class="font-weight-bolder">{{ numberFormatHelper.decimal(selectsData.packageOptions.defaultFreeAmount) }}</span>
          </div>
          <div class="text-muted text-center">{{ 'WELCOME_MODAL.CONT_2.COL_1.PER_TAX_RETURN' | translate }}</div>
          <ul>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_2.COL_1.OPTION_1' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_2.COL_1.OPTION_2' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_2.COL_1.OPTION_3' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_2.COL_1.OPTION_4' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_2.COL_1.OPTION_5' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_2.COL_1.OPTION_6' | translate }}</span>
            </li>
          </ul>
        </div>
        <div class="w_modal_col">
          <h3 class="text-primary font-size-24" [innerHTML]="'WELCOME_MODAL.CONT_2.COL_2.TITLE' | translate"></h3>
          <div class="text-muted text-center">{{ 'WELCOME_MODAL.CONT_2.COL_2.FROM' | translate }}</div>
          <div class="price position-relative text-center">
            <span class="font-weight-bolder">{{ selectsData.packageOptions.currencySymbol.symbol }}</span>
            <span class="font-weight-bold">{{ numberFormatHelper.integer(selectsData.packageOptions.defaultPremiumAmount) }}</span>
            <span class="font-weight-bolder">{{ numberFormatHelper.decimal(selectsData.packageOptions.defaultPremiumAmount) }}</span>
            <img class="visible_lg diamond" src="../../../assets/svg/diamond.svg" />
          </div>
          <div class="text-muted text-center">{{ 'WELCOME_MODAL.CONT_2.COL_2.PER_TAX_RETURN' | translate }}</div>
          <ul>
            <li class="bg-white rounded-pill align-items-center">
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_2.COL_2.OPTION_1' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_2.COL_2.OPTION_2' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_2.COL_2.OPTION_3' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_2.COL_2.OPTION_4' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_2.COL_2.OPTION_5' | translate }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nav_wrap">
      <div class="login_btns_wrap" (click)="openLoginDialog()">
        <span>{{ 'COMMON.ALREADY_REGISTERED' | translate }}</span>
        <span class="green">{{ 'COMMON.LOG_IN' | translate }}</span>
      </div>
      <div class="button_wrap">
        <button (click)="step3 = false" class="skip navButton">
          <span>{{ 'WELCOME_MODAL.SKIP' | translate }}</span>
        </button>
        <button (click)="step3 = false; step2 = true; checkButtonsTextWidth()" class="back">
          <img src="../../../assets/svg/back_btn.svg" alt="back">
        </button>
        <button class="navButton" (click)="step3 = false; step4 = true; checkButtonsTextWidth();
                    executeFirebaseEvent('tutorial_complete', { screen_name : 'tutorial_last_step' })">
          <span>{{ 'WELCOME_MODAL.NEXT' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="w_modal last" *ngIf="step4">
    <!-- <div class="close" (click)="step4 = false">
        <span class="close_box"></span>
    </div> -->
    <div class="w_modal_cont step_4_wrap">
      <div class="title_wrap">
        <h2 [innerHTML]="'WELCOME_MODAL.TITLE_3' | translate"></h2>
        <p class="subdesc" [innerHTML]="'WELCOME_MODAL.DESC_3' | translate"></p>
      </div>
      <!-- <div class="svg">
          <img src="../../../assets/svg/welcome3.svg" />
      </div> -->
      <div class="w_modal_desc a_center">
        <div class="w_modal_col">
          <ul>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_3.LIST.LIST_1' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_3.LIST.LIST_2' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_3.LIST.LIST_3' | translate }}</span>
            </li>
            <li>
              <img src="../../../assets/svg/list_check.svg" alt="check">
              <span>{{ 'WELCOME_MODAL.CONT_3.LIST.LIST_4' | translate }}</span>
            </li>
          </ul>
        </div>
        <div class="w_modal_col">
          <img class="step_img" src="../../../assets/svg/welcome2.svg" />
        </div>
      </div>
    </div>
    <div class="nav_wrap j_end">
      <div class="login_btns_wrap" (click)="openLoginDialog()" >
        <span>{{ 'COMMON.ALREADY_REGISTERED' | translate }}</span>
        <span class="green">{{ 'COMMON.LOG_IN' | translate }}</span>
      </div>
      <div class="button_wrap last_step">
        <button (click)="step4 = false; step3 = true; checkButtonsTextWidth()" class="back">
          <img src="../../../assets/svg/back_btn.svg" alt="back">
        </button>
        <button class="navButton" (click)="step4 = false;">
          <span>{{ 'WELCOME_MODAL.TAXYEAR' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
