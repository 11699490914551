<div *ngIf="additionalIncomeService.isSocialIncomeSection(incomeType)">
  <app-form-list
    [itemTitle]="title"
    [formTemplateRef]="socialForm"
    [list]="socialIncomeManager.get(currentStatement, incomeType)"
    [isAddButtonDisabled]="
      socialIncomeManager.get(currentStatement, incomeType).length >= additionalIncomeService.socialIncomeLimit
    "
    (removeButtonClickEmitter)="socialIncomeManager.remove(currentStatement, incomeType, $event)"
    (addButtonClickEmitter)="socialIncomeManager.create(currentStatement, incomeType)"
    [deductionInfoText]="getDeductionInfoText()"
  ></app-form-list>

  <ng-template #socialForm let-item="item" let-index="index">
    <app-additional-income-form-social [socialIncome]="item" [index]="index"></app-additional-income-form-social>
  </ng-template>
</div>

<div *ngIf="additionalIncomeService.isPensionIncomeSection(incomeType)">
  <app-form-list
    [itemTitle]="title"
    [formTemplateRef]="pensionForm"
    [list]="pensionIncomeManager.get(currentStatement, incomeType)"
    [isAddButtonDisabled]="
      pensionIncomeManager.get(currentStatement, incomeType).length >= additionalIncomeService.pensionIncomeLimit
    "
    (removeButtonClickEmitter)="pensionIncomeManager.remove(currentStatement, incomeType, $event)"
    (addButtonClickEmitter)="pensionIncomeManager.create(currentStatement, incomeType)"
  ></app-form-list>

  <ng-template #pensionForm let-item="item" let-index="index">
    <app-additional-income-form-pension [pensionIncome]="item" [index]="index"></app-additional-income-form-pension>
  </ng-template>
</div>

<div *ngIf="additionalIncomeService.isFreelanceIncomeSection(incomeType)">
  <ng-container *ngIf="incomeType == freelanceJobTypes.FREELANCE">
    <app-form-list
      [itemTitle]="'ADDITIONAL_INCOME.FREELANCE_GROUP.FREELANCE.TITLE'"
      [formTemplateRef]="pensionForm"
      [list]="freelanceJobManager.getFreelance(additionalIncomeService.currentStatement)"
      (removeButtonClickEmitter)="freelanceJobManager.removeFreelance(additionalIncomeService.currentStatement, $event)"
      (addButtonClickEmitter)="freelanceJobManager.createFreelance(additionalIncomeService.currentStatement)"
      [addButtonTitle]="'ADDITIONAL_INCOME.FREELANCE_GROUP.FREELANCE.ADD_BUTTON_TEXT'"
    ></app-form-list>
  </ng-container>
  <ng-container *ngIf="incomeType == freelanceJobTypes.GEWERBE">
    <app-form-list
      [itemTitle]="'ADDITIONAL_INCOME.FREELANCE_GROUP.GEWERBE.TITLE'"
      [formTemplateRef]="pensionForm"
      [list]="freelanceJobManager.getGewerbe(additionalIncomeService.currentStatement)"
      (removeButtonClickEmitter)="freelanceJobManager.removeGewerbe(additionalIncomeService.currentStatement, $event)"
      (addButtonClickEmitter)="freelanceJobManager.createGewerbe(additionalIncomeService.currentStatement)"
      [addButtonTitle]="'ADDITIONAL_INCOME.FREELANCE_GROUP.GEWERBE.ADD_BUTTON_TEXT'"
    ></app-form-list>
  </ng-container>
  <ng-container *ngIf="incomeType == freelanceJobTypes.VOLUNTERING_INCOME">
    <app-form-list
      [itemTitle]="title"
      [formTemplateRef]="pensionForm"
      [list]="freelanceJobManager.get(currentStatement, incomeType)"
      [isAddButtonDisabled]="
        freelanceJobManager.get(currentStatement, incomeType).length >= additionalIncomeService.freelanceIncomeLimit
      "
      (removeButtonClickEmitter)="freelanceJobManager.remove(currentStatement, incomeType, $event)"
      (addButtonClickEmitter)="freelanceJobManager.create(currentStatement, incomeType)"
    ></app-form-list>
  </ng-container>
</div>

<div *ngIf="additionalIncomeService.isCryptocurrencyIncomeSection(incomeType)">
  <app-form-list
    [itemTitle]="title"
    [formTemplateRef]="cryptoinputs"
    [list]="cryptocurrencyIncomeManager.get(currentStatement, incomeType)"
    [isAddButtonDisabled]="
      cryptocurrencyIncomeManager.get(currentStatement, incomeType).length >=
      additionalIncomeService.cryptocurrencyIncomeLimit
    "
    (removeButtonClickEmitter)="cryptocurrencyIncomeManager.remove(currentStatement, incomeType, $event)"
    (addButtonClickEmitter)="cryptocurrencyIncomeManager.create(currentStatement, incomeType)"
  ></app-form-list>

  <ng-template #cryptoinputs let-item="item" let-index="index">
    <app-additional-income-form-cryptocurrency
      [cryptocurrencyIncome]="item"
      [index]="index"
    ></app-additional-income-form-cryptocurrency>
  </ng-template>
</div>

<ng-template #pensionForm let-item="item" let-index="index">
  <app-additional-income-form-freelance
    [freelanceJob]="item"
    [index]="index"
    [freelanceJobIncomeType]="item.incomeType"
  ></app-additional-income-form-freelance>
</ng-template>
