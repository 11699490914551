import { Router } from '@angular/router';
import { TermsAndConditionsPagePath } from 'app/core/workflow/page-path/terms-and-conditions-page.path';
import { PrivacyPolicyPagePath } from 'app/core/workflow/page-path/privacy-policy-page.path';
import { Injectable } from '@angular/core';

@Injectable()
export class TermsFilesService {
  constructor(
    private router: Router,
  ) {
  }

  public openPrivacyPolicy(): void {
    this.router.navigate([PrivacyPolicyPagePath.path()]);
  }

  public openTermsAndConditions(): void {
    this.router.navigate([TermsAndConditionsPagePath.path()])
  }
}
