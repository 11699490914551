import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State, getShouldUpdateRefundDetails } from 'app/reducers';
import { RESET_REFUND_DETAILS } from 'app/reducers/actions/refund-details';
import { Statement } from 'app/statements/statement';
import { RefundDetails } from 'app/statements/refund-details';
import { StatementService } from 'app/statements/statement.service';
import { SubscribingComponent } from 'app/common';
import { RefundDialogComponent } from 'app/statements/refund-dialog/refund-dialog.component';
import { RecognizerHelper } from 'app/core/helpers/recognizer.helper';
import { fromEvent, Observable } from 'rxjs';
import { MatButton } from '@angular/material/button';
import { UtilityHelper } from 'app/core/helpers/utility.helper';

type ToolsBarButtonTypeOld = 'info' | 'proceed' | 'standard' | 'back' | 'save' | 'skip' | 'danger';

export enum ToolsBarButtonType {
  info = 'info',
  proceed = 'proceed',
  standard = 'standard',
  back = 'back',
  save = 'save',
  skip = 'skip',
  danger = 'danger',
}

export enum ToolsBarButtonCommonTitle {
  back = 'COMMON.BACK',
  proceed = 'COMMON.PROCEED',
  skip = 'COMMON.SKIP',
  skipThanks = 'COMMON.SKIP_THANKS',
  dashboard = 'COMMON.GO_TO_DASHBOARD',
}

export interface ToolsBarButtonInterface {
  disabled$?: Observable<boolean>;
  disabled?: Function;
  label: string;
  type: ToolsBarButtonType | ToolsBarButtonTypeOld;
  action(): void;
}

@Component({
  selector: 'app-tools-bar',
  templateUrl: './tools-bar.component.html',
  styleUrls: ['./tools-bar.component.scss']
})
export class ToolsBarComponent extends SubscribingComponent implements OnInit {
  _statement?: Statement;
  refundDetails?: RefundDetails = new RefundDetails();

  @ViewChildren('buttons') buttonsRef: QueryList<MatButton>;
  @Input() isLogged = true;
  @Input() set buttons(_buttons) {
    this._buttons = _buttons;
    this.checkWidthOfButtons();
  }
  get buttons(): ToolsBarButtonInterface[] {
    return this._buttons;
  }
  @Input() set statement(statement: Statement) {
    this._statement = statement;
    this.updateRefund();
  }
  private _buttons: ToolsBarButtonInterface[];

  constructor(private statementService: StatementService,
    private store: Store<State>,
    private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.store.select(getShouldUpdateRefundDetails).pipe(
      takeUntil(this.ngUnsubscribe),
      filter((shouldUpdate: boolean) => shouldUpdate))
      .subscribe(() => {
        this.updateRefund();
        this.store.dispatch({ type: RESET_REFUND_DETAILS });
      });

    setTimeout(() => {
      this.checkWidthOfButtons();
    }, 200);
    this.checkWidthOfButtonsListener();
  }

  showRefundDialog() {
    if (this._statement) {
      const dialogRef = this.dialog.open(RefundDialogComponent, {
        panelClass: ['refund_dialogbox', 'animate__animated'],
        backdropClass: 'refund_dialogbox_backdrop',
      });
      dialogRef.componentInstance.statement = this._statement;
      dialogRef.componentInstance.refundDetails = this.refundDetails;
    }
  }

  updateRefund() {
    if (this._statement && this._statement.id && this.isLogged) {
      this.statementService.getRefundDetails(this._statement.id).pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe((refundDetails: RefundDetails) => this.refundDetails = refundDetails);
    }
  }

  public setButtonId(buttonType: string) {
    if (buttonType === 'proceed') {
      return 'buttonProced';
    } else if (buttonType === 'standard') {
      return 'buttonStandard';
    } else if (buttonType === 'back') {
      return 'buttonBack';
    } else if (buttonType === 'save') {
      return 'buttonSave';
    } else if (buttonType === 'skip') {
      return 'buttonSkip';
    } else {
      return 'buttonInfo';
    }
  }

  checkWidthOfButtonsListener() {
    fromEvent(window, 'resize').pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.checkWidthOfButtons();
        }
      })
  }

  private async checkWidthOfButtons(): Promise<void> {
    await UtilityHelper.sleep({ms: 100});

    const fitText: (el: HTMLElement, compressor?: number, options?: {}) => ElementRef = window['fitText'];
    if (window.innerWidth < 768 && RecognizerHelper.isFunction(fitText)) {
      this.buttonsRef?.forEach((item, index) => {
        const button = item._elementRef.nativeElement;
        if (!button) {
          return;
        }
        const itemWidth = button.offsetWidth;
        let check = itemWidth > 120;
        if (window.innerWidth <= 370) {
          check = itemWidth > 99;
        }
        if (window.innerWidth <= 359) {
          check = itemWidth > 80;
        }
        if (check) {
          fitText(
            button,
            .95,
            {
              maxFontSize: 18,
              resizeEventEnabled: false,
              orientationChangeEventEnabled: false,
            });
        }
      });
    }
  }
}
