import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { ConfirmTaxReturnComponentPath } from 'app/core/workflow/page-path/statement-page-path/confirm-tax-return-component.path';

export class WorkflowConfirmTaxReturnService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.statement
      && (this.statement.isApproved || this.statement.isReportedErrors);
  }

  public get isRequired(): boolean {
    return this.statement
      && this.statement.isForClientApproval
      && !this.isBusiness
      && !this.isTaxAdviser
      && this.statement.isPremiumPackage;
  }

  public get checkCanGo(): boolean {
    return this.statement
      && this.isRequired;
  }

  public get urlRegex(): RegExp {
    return ConfirmTaxReturnComponentPath.regex();
  }

  public get url(): string {
    return ConfirmTaxReturnComponentPath.url(this.getStatementId);
  }
}
