
import { finalize, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';

import { Statement } from '../../statements/statement';
import { StatementService } from '../../statements/statement.service';
import { SubscribingComponent } from '../subscribing-component';
import { FirebaseEventsService } from '../firebase-events.service';

@Component({
  selector: 'app-remove-statement-dialog',
  templateUrl: './remove-statement-dialog.component.html',
  styleUrls: ['./remove-statement-dialog.component.scss']
})
export class RemoveStatementDialogComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  @Input() isSpouse: boolean;
  @Input() onSuccessCallback: any;
  errors = [];

  constructor(private statementService: StatementService,
    private dialogRef: MatDialogRef<RemoveStatementDialogComponent>, private firebaseEventsService: FirebaseEventsService) {
    super();
  }

  ngOnInit() {
  }

  removeStatement() {
    this.firebaseEventsService.logEvent('delete_tax_card', null, this.statement.id)
    const id = this.isSpouse ? this.statement.spouse.id : this.statement.id;
    this.statement.deleting = true;
    this.statementService.deleteStatement(id).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.statement.deleting = false))
      .subscribe((response: Response) => {
        if (this.onSuccessCallback) {
          this.onSuccessCallback();
          this.dialogRef.close();
        }
      }, (errors: any) => {
        this.errors = errors.error
      });
  }
}
