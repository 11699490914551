import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { HttpClient } from "@angular/common/http";
import { AuthGuard, AuthHttpService, AuthService, UserDataService } from "app/users";
import { NotAuthGuard } from "app/users/not-auth.guard";
import { StatementService } from "app/statements/statement.service";
import { StatementManagementService } from "app/statements/statement-management.service";
import { PaymentService } from "app/payment/payment.service";
import { SideNavService } from "app/core/side-nav.service";
import { ChildrenService } from "app/statements/children.service";
import { CurrencyUtil } from "app/common/currency.util";
import { FirebaseEventsService } from "app/common/firebase-events.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { APP_DATE_FORMATS, AppDateAdapter } from "app/common/app-date-adapter";
import { FirebaseHttpService } from "app/firebase/firebase.http.service";
import { TaxCardAddService } from "app/common/tax-card-add.service";
import { TaxCardIncomeInfoService } from "app/common/tax-card-income-info.service";
import { CurrencyConverterService } from "app/payment/currency-converter.service";
import { StatementPaymentService } from "app/statements/statement-payment.service";
import { StatementPaymentAmountService } from "app/statements/statement-payment-amount.service";
import { CookiesLawService } from "app/core/services/cookies-law.service";
import { ErrorHandler } from "@angular/core";
import { SentryErrorHandler } from "app/sentry-error.handler";
import { CookieService } from "ngx-cookie-service";
import { provideNgxMask } from "ngx-mask";
import { MessageSenderHttpService } from "./common/send-message-dialog/http-service/message-sender.http.service";

export const appModuleProviders = [
  AuthGuard,
  NotAuthGuard,
  AuthService,
  AuthHttpService,
  UserDataService,
  StatementService,
  StatementManagementService,
  PaymentService,
  SideNavService,
  ChildrenService,
  CurrencyUtil,
  FirebaseEventsService,
  HttpClient,
  { provide: DeviceDetectorService },
  { provide: DateAdapter, useClass: AppDateAdapter },
  { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  { provide: MAT_DIALOG_DATA, useValue: {} },
  { provide: MatDialogRef, useValue: {} },
  {
    provide: ErrorHandler,
    useClass: SentryErrorHandler
  },
  provideNgxMask({ validation: false }),
  /**
   * PERFORMANCE MONITORING: Enable Tracing
   *   {
   *     provide: TraceService,
   *     deps: [Router],
   *   },
   *   {
   *     provide: APP_INITIALIZER,
   *     useFactory: () => () => { },
   *     deps: [TraceService],
   *     multi: true,
   *   },
   */
  FirebaseHttpService,
  TaxCardAddService,
  TaxCardIncomeInfoService,
  CurrencyConverterService,
  StatementPaymentService,
  StatementPaymentAmountService,
  CookiesLawService,
  CookieService,
  MessageSenderHttpService
];
