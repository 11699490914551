import { WorkflowDeductionSpouseSubBaseService } from 'app/core/workflow/workflow-steps/deduction/spouse/sub/workflow-deduction-spouse-sub-base.service';

export class WorkflowDeductionInfoSpouseBusinessTripsService extends WorkflowDeductionSpouseSubBaseService {
  public get title(): string {
    return 'ASIDE_BAR.SPOUSES_DEDUCTION_SUBLIST.BUSINESS_TRIPS';
  }

  public get subStepNumber(): number {
    return 2;
  }

  public get isFakeStep(): boolean {
    return !this.isRequired;
  }

  public get checkCanGo(): boolean {
    return super.checkCanGo && this.isRequired;
  }

  public get isRequired(): boolean {
    return super.isRequired && this.spouseHasEmployers;
  }
}
