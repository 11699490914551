export interface TaxClassResponse {
    id: number;
    name: string;
}

export class TaxClass {
    id: number;
    name: string;

    static fromResponse(response: TaxClassResponse): TaxClass {
        const taxClass = new TaxClass();
        taxClass.id = response.id;
        taxClass.name = response.name;
        return taxClass;
    }
}
