import {AttachmentType} from './attachment-type';

export class Attachment {
  public id?: number;
  public created_at?: Date;
  public data_file?: string;
  public file_name?: string;
  public file_size?: number;
  public type?: string;
  public created_date?: string;
  public file?: File;
  public types = AttachmentType;

  constructor(file: File) {
    this.id = null;
    this.type = this.types[Object.keys(this.types)[0]].id;
    this.file_size = file.size;
    this.file_name = file.name;
    this.file = file;
    this.created_at = new Date;
  }

  public get formattedSize(): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (!this.file_size) {
      return '0 Byte';
    }
    const i = parseInt(String(Math.floor(Math.log(this.file_size) / Math.log(1024))), 0);
    return Math.round(this.file_size / Math.pow(1024, i)) + ' ' + sizes[i];
  }
}
