import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { VollmachtAuthorizationComponentPath } from 'app/core/workflow/page-path/statement-page-path/vollmacht-authorization-component.path';

export class WorkflowVollmachtAuthorizationService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.statement && this.statement.vollmachtSigned;
  }

  public get isRequired(): boolean {
    return this.statement
      && (this.statement.isPaid || this.statement.isSent())
      && !this.statement.isForClientApproval
      && !this.statement.isApproved
      && !this.statement.isReportedErrors
      && !this.statement.batchId
      && !this.isBusiness
      && !this.isTaxAdviser
      && this.statement.isPremiumPackage;
  }

  public get checkCanGo(): boolean {
    return this.statement
      && this.isRequired;
  }

  public get urlRegex(): RegExp {
    return VollmachtAuthorizationComponentPath.regex();
  }

  public get url(): string {
    return VollmachtAuthorizationComponentPath.url(this.getStatementId);
  }
}
