import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TooltipBaseComponent } from 'app/common/tooltip-base/tooltip-base.component';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { RecognizerHelper } from 'app/core/helpers/recognizer.helper';
import { DateHelper } from 'app/core/helpers/date.helper';

@Component({
  selector: 'app-form-date-input',
  templateUrl: './form-date-input.component.html',
  styleUrls: ['./form-date-input.component.scss']
})
export class FormDateInputComponent extends TooltipBaseComponent implements OnInit {
  @Input() label: string;
  @Input() model: any;
  @Output() modelChange = new EventEmitter();
  @Output() debounce = new EventEmitter();
  @Input() errors: any;
  @Input() warnings: any;
  @Input() placeholder: string;
  @Input() isDisabled = false;
  @Input() centerLabel = false;
  @Input() smLabel = false;
  @Input() centerTxtInput = false;
  @Input() addClassB2B = false;
  @Input() classB2B = '';
  @Input() whiteColor = false;
  @Input() employerStage = false;
  @Input() noYear = false;
  @Input() autoYear = false;
  @Input() noDay = false;
  @Input() isEditableSummaryEmployer = false;
  @Input() currentInputId = null;
  @Input() currentYear: number;
  @Input() cyName: string;

  public activeInput = false;
  public inputChange = false;
  public inputControl = new UntypedFormControl();

  constructor() {
    super();
  }

  ngOnInit() {
    this.placeholder = this.placeholder || 'COMMON.DATE_FORMAT';
    this.placeholder = this.noDay ? 'COMMON.DATE_FORMAT_NO_DAY' : this.placeholder;

    this.model = this.model || '';

    if (this.model) {
      setTimeout(() => {
        if (this.noYear) {
          this.model = this.formatDate(this.model);
          this.change(this.model);
        }

        /// show/hide placeholder and active line if input is not empty///
        if (this.model.toString() != '') {
          this.inputChange = true;
        }
      });
    }

    this.modelChange.pipe(takeUntil(this.ngUnsubscribe), debounceTime(1000))
      .subscribe(() => {
        this.debounce.emit();
      });
  }

  formatDate(model) {
    if (!model) {
      return null;
    }

    if (model.length < 10) {
      return model;
    }

    return DateHelper.getFormatted(model, 'DD.MM.YYYY');
  }

  isValidDate(date: Date): boolean {
    return DateHelper.getInstance(date).isValid();
  }

  change(newValue) {
    if (this.noYear) {
      this.modelChange.emit(newValue);
      return;
    }

    if (RecognizerHelper.isNullOrUndefined(newValue)) {
      newValue = '';
    }

    if (this.autoYear) {
      if (newValue.length === 5) {
        const leadingZerosRegexOne = /^[0-9]{1}[.]{1}$/;
        const leadingZerosRegexTwo = /^[0-9]{2}[.]{1}[0-9]{1}[.]{1}$/;

        if (leadingZerosRegexOne.test(newValue)) {
          newValue = `0${newValue}.${this.currentYear}`;
        } else if (leadingZerosRegexTwo.test(newValue)) {
          const splited = newValue.split('.');
          newValue = `${splited[0]}.0${splited[1]}.${this.currentYear}`;
        } else {
          newValue = `${newValue}.${this.currentYear}`;
        }
      } else if (newValue.length > 5 && newValue.length <= 8) {
        newValue = '';
        this.modelChange.emit(newValue);
        return;
      }
    }

    if (this.noDay) {
      this.modelChange.emit(newValue);
      return;
    }


    if (newValue.length === 2 || newValue.length === 5) {
      const leadingZerosRegexOne = /^[0-9]{1}[.]{1}$/;
      const leadingZerosRegexTwo = /^[0-9]{2}[.]{1}[0-9]{1}[.]{1}$/;

      if (leadingZerosRegexOne.test(newValue)) {
        this.modelChange.emit(`0${newValue}`);
      } else if (leadingZerosRegexTwo.test(newValue)) {
        const splited = newValue.split('.');
        this.modelChange.emit(`${splited[0]}.0${splited[1]}.`);
      } else {
        this.modelChange.emit(`${newValue}.`);
      }
    } else if (newValue.length === 10) {
      const splited = newValue.split('.');
      const newDate = new Date(parseInt(splited[2], 10), parseInt(splited[1], 10) - 1, parseInt(splited[0], 10));
      this.modelChange.emit(this.isValidDate(newDate) ? newDate : '');
    } else {
      const doubleDotsRegexp = /^[^]*[\.]{2}$/;
      if (doubleDotsRegexp.test(newValue)) {
        const properValue = newValue.substring(0, newValue.length - 1);
        /*
          Kod w linach 60 - 65 jest rozwiązaniem błędu który powodował,
          że wyemitowane properValue aktualizowało się w modelu ale nie w inpucie.
          Problem dotyczył tylko sytuacji kiedy użytkownik wpisał np. '12..' i po usunięciu kropki emitowane było '12.',
          ponieważ emitowanie '12.0' czy '12. ' lub '12.dlamniemaszstajla' działało poprawnie.
          Dlatego pierwemitujemy properValue ze spacją a potem to już docelowe.
         */
        setTimeout(() => {
          this.modelChange.emit(`${properValue} `);
          setTimeout(() => {
            this.modelChange.emit(`${properValue}`);
          }, 1);
        }, 1);
      } else {
        this.modelChange.emit(newValue);
      }
    }

    /// show/hide placeholder///
    if (newValue.toString() !== '') {
      this.inputChange = true;
    } else {
      this.inputChange = false;
    }
  }

  public onBlur($event: any) {
    const newValue = $event.srcElement.value;
    if (this.noYear) {
      const leadingZerosRegexTwoSlash = /^[0-9]{2}[/]{1}[0-9]{1}$/;
      if (leadingZerosRegexTwoSlash.test(newValue)) {
        const splited = newValue.split('/');
        if (parseInt(splited[1]) <= 9) {
          this.modelChange.emit(`${splited[0]}/0${splited[1]}`);
        } else {
          this.modelChange.emit(`${newValue}`);
        }
      }
    }
    const thisItem = $event.target;
    this.activeInput = false;
    if (thisItem.value.toString() === '') {
      this.inputChange = false;
    }
  }

  public onFocus($event: any) {
    this.activeInput = true;
  }
}
