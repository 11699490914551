import { WorkflowDeductionApplicantSubBaseService } from 'app/core/workflow/workflow-steps/deduction/applicant/sub/workflow-deduction-applicant-sub-base.service';

export class WorkflowDeductionInfoHouseService extends WorkflowDeductionApplicantSubBaseService {
  public get title(): string {
    return 'ASIDE_BAR.APPLICANT_DEDUCTION_SUBLIST.HOUSE';
  }

  public get subStepNumber(): number {
    return 3;
  }

  public get isFakeStep(): boolean {
    return !this.isRequired;
  }

  public get checkCanGo(): boolean {
    return super.checkCanGo && this.isRequired;
  }

  public get isRequired(): boolean {
    return super.isRequired && this.applicantHasEmployers;
  }
}
