import { Component } from '@angular/core';
import { ButtonBaseComponent } from 'app/shared/components/buttons/button-base.component';

@Component({
  ...ButtonBaseComponent.metaData,
  selector: 'app-button-fill-primary',
  templateUrl: './button-fill-primary.component.html',
  styleUrls: ['./button-fill-primary.component.scss']
})
export class ButtonFillPrimaryComponent extends ButtonBaseComponent {
}
