import { finalize, take, takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SHOW_SNACKBAR } from 'app/reducers/actions/snackbar';
import { SubscribingComponent, SnackbarConfig, MAX_FILE_SIZE } from 'app/common';
import { UserDataService } from '../user-data.service';
import { User } from '../user';
import { LOAD_USER_DATA } from 'app/reducers/actions/user';
import { SelectsData, SelectsDataInterface } from 'app/statements';
import { getSelectsData, State } from 'app/reducers';
import { Attachment } from 'app/statements/attachment';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesConfig } from 'app/configs/languages.config';
import { UserHttpService } from 'app/modules/user/services/http/user.http.service';
import { ConfirmCancelDialogComponent } from 'app/shared/components/confirm-cancel-dialog/confirm-cancel-dialog.component';
import { UserDeleteModel } from 'app/core/models/user/user-delete.model';
import { AuthService } from 'app/users/auth.service';
import { ErrorDialogComponent } from 'app/common/error-dialog/error-dialog.component';
import { DateHelper } from 'app/core/helpers/date.helper';
import { TermsFilesService } from 'app/core/services/terms-files.service';

interface UserChangeDataErrors {
  first_name?: string[];
  last_name?: string[];
  country?: string;
  city?: string;
  postal_code?: string;
  street?: string;
  street_number?: string;
  phone_number?: string;
  non_field_errors?: string[];
  language?: string;
  nip?: string;
  certificate?: string[];
  pin?: string[];
  citizenship?: string;
}

interface OwnCertificate {
  data_file?: string | string[];
  created_date?: string | string[];
  pin?: number | string | string[];
}

interface OwnCertificateErrors {
  data_file?: string | string[];
  created_date?: string | string[];
  pin?: string | string[];
  error?: string | string[];
}

@Component({
  selector: 'app-user-change-data',
  templateUrl: './user-change-data.component.html',
  styleUrls: ['./user-change-data.component.scss']
})
export class UserChangeDataComponent extends SubscribingComponent implements OnInit {
  loading = true;
  userData: User;
  errors: UserChangeDataErrors = {};
  ownCertificateErrors: OwnCertificateErrors = {};
  dataSelects: SelectsDataInterface = new SelectsData();
  langList = LanguagesConfig.languagesList;
  public ownCertificate: OwnCertificate;
  public certificateFile: Attachment;
  public certificatePin: number;
  public isBusiness = false;
  public isMaster = false;
  public loadingDeleteCertificate = false;
  public loadingUploadCertificate = false;
  public loadingSave = false;

  constructor(
    private userDataService: UserDataService,
    private dialogRef: MatDialogRef<UserChangeDataComponent>,
    private dialog: MatDialog,
    private store: Store<State>,
    private translate: TranslateService,
    private userHttpService: UserHttpService,
    private authService: AuthService,
    private termsFilesService: TermsFilesService,
  ) {
    super();
  }

  ngOnInit() {
    this.userDataService.getUserData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (user: User) => {
          this.userData = user;
          if (this.userData) {
            this.isBusiness = this.userData.isBusiness;
            this.isMaster = this.userData.isMasterAccount;
            this.loading = false;
            if (this.isMaster) {
              this.getOwnCertificate();
            }
          }
        }
      });

    this.store.select(getSelectsData).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((selectsData: SelectsDataInterface) => {
        this.dataSelects = selectsData;
      });
  }

  showPrivacyPolicyDialog(event): void {
    event.preventDefault();
    this.termsFilesService.openPrivacyPolicy();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.userData.extendPersonalInfo) {
      if (this.userData.extendPersonalInfo.applicantBirthdate) {
        this.userData.extendPersonalInfo.applicantBirthdate = DateHelper.getFormatted(
          this.userData.extendPersonalInfo.applicantBirthdate,
          'YYYY-MM-DD'
        );
      }
    }
    this.loadingSave = true;
    this.userDataService.updateUserData(this.userData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.loadingSave = false;
          this.onUpdateUserDataSuccess();
        },
        error: (error: any) => {
          this.loadingSave = false;
          this.errors = error.error;
        }
      });
  }

  public onSubmitCertificate(): void {
    if (this.isMaster) {
      if (this.ownCertificate) {
        this.updateCertificate();
      } else {
        this.addCertificate();
      }
    }
  }

  public handleDeleteAccount(): void {
    this.showAreYouSureDeleteAccountPopup(async (dialog) => {
      const response = await this.userHttpService.deleteAccount();

      dialog.close();

      if (response.success) {
        this.accountDeletedSuccessfully();
      } else {
        const error = response.error;

        if (error.confirmation_needed) {
          if (error.has_sent_statements) {
            this.showPopupAboutYouHaveSentStatement(async (dialogConfirm) => {
              this.accountDeletionWithConfirmation(dialogConfirm, true);
            });

            return;
          }

          if (error.has_paid_statements) {
            this.showAreYouSureDeleteConfirmationNeededPopup(async (dialogConfirm) => {
              this.accountDeletionWithConfirmation(dialogConfirm, false);
            });

            return;
          }
        }

        this.showErrorDialog();
      }
    });
  }

  onUpdateUserDataSuccess() {
    this.store.dispatch({ type: LOAD_USER_DATA, payload: this.userData });
    if (this.userData.isIndividual) {
      this.closeDialog();
    }
    this.store.dispatch(
      { type: SHOW_SNACKBAR, payload: new SnackbarConfig('SNACKBAR.USER_DATA_UPDATED') }
    );
  }

  public onUploadCertificate($event): void {
    const file: File = $event.target.files[0];

    const object = new Attachment(file);

    if (object.file_size > MAX_FILE_SIZE) {
      this.store.dispatch({
        type: SHOW_SNACKBAR,
        payload: new SnackbarConfig('SNACKBAR.MAX_FILE_SIZE_5_MB', 'ERROR')
      });
      return;
    }

    const ext = file.name.split('.').pop().toLocaleLowerCase();
    if (ext !== 'pfx') {
      this.store.dispatch({
        type: SHOW_SNACKBAR,
        payload: new SnackbarConfig(
          'MENU.ACCOUNT.TAX_CERTIFICATE.NOTIFICATION.ALLOWED_EXTENSION',
          'ERROR'
        )
      });
      return;
    }

    this.certificateFile = object;
  }

  public deleteCertificate(): void {
    this.ownCertificateErrors = {};

    if (!this.isMaster) {
      return;
    }

    if (this.certificateFile) {
      this.certificateFile = null;
      this.certificatePin = null;

      return;
    }

    if (!this.ownCertificate) {
      return;
    }

    this.loadingDeleteCertificate = true;

    this.userDataService.deleteOwnCertificate().pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => {
        this.loadingDeleteCertificate = false;
        this.certificatePin = null;
      })
    ).subscribe(
      () => {
        this.ownCertificate = null;
        this.store.dispatch({
          type: SHOW_SNACKBAR,
          payload: new SnackbarConfig('MENU.ACCOUNT.TAX_CERTIFICATE.NOTIFICATION.DELETED', 'INFO')
        });
      },
      () => {
        this.showSnackbarError();
      }
    );
  }

  private updateCertificate(): void {
    this.ownCertificateErrors = {};

    if (!this.isMaster || !this.ownCertificate) {
      return;
    }

    if (!this.certificateFile && !this.certificatePin) {
      return;
    }

    this.loadingUploadCertificate = true;

    this.userDataService.updateOwnCertificate(
      this.certificateFile ? this.certificateFile.file : null,
      this.certificatePin
    ).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => {
        this.loadingUploadCertificate = false;
        this.certificatePin = null;
      })
    ).subscribe(
      (response: any) => {
        this.ownCertificate = response && response.data_file ? response : null;
        this.certificateFile = null;
        this.store.dispatch({
          type: SHOW_SNACKBAR,
          payload: new SnackbarConfig('MENU.ACCOUNT.TAX_CERTIFICATE.NOTIFICATION.UPDATED', 'INFO')
        });
      },
      (error: any) => {
        this.store.dispatch({
          type: SHOW_SNACKBAR,
          payload: new SnackbarConfig(
            error.error && error.error.length ? error.error[0] : error.error,
            'ERROR'
          ),
        });
        this.handleCertificateErrors(error.error);
      }
    );
  }

  private addCertificate(): void {
    this.ownCertificateErrors = {};

    if (!this.isMaster) {
      return;
    }

    if (!this.certificateFile || !this.certificatePin) {
      if (this.certificateFile && !this.certificatePin) {
        this.ownCertificateErrors.pin = [
          this.translate.instant('INPUT.CERTIFICATE_PIN.ERROR_REQUIRED')
        ];
      }
      return;
    }

    this.loadingUploadCertificate = true;

    this.userDataService.uploadOwnCertificate(this.certificateFile.file, this.certificatePin).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => {
        this.loadingUploadCertificate = false;
        this.certificatePin = null;
      })
    ).subscribe(
      (response: any) => {
        this.ownCertificate = response && response.data_file ? response : null;
        this.certificateFile = null;
        this.store.dispatch({
          type: SHOW_SNACKBAR,
          payload: new SnackbarConfig('MENU.ACCOUNT.TAX_CERTIFICATE.NOTIFICATION.UPLOADED', 'INFO')
        });
      },
      (error: any) => {
        this.store.dispatch({
          type: SHOW_SNACKBAR,
          payload: new SnackbarConfig(
            error.error && error.error.length ? error.error[0] : error.error,
            'ERROR'
          ),
        });
        this.handleCertificateErrors(error.error);
      }
    );
  }

  private getOwnCertificate(): void {
    this.ownCertificateErrors = {};
    this.loading = true;
    this.userDataService.getOwnCertificate().pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.loading = false)
    ).subscribe(
      (response: any) => this.ownCertificate = response && response.data_file ? response : null,
      (error: any) => {
        this.store.dispatch({
          type: SHOW_SNACKBAR,
          payload: new SnackbarConfig(
            error.error && error.error.length ? error.error[0] : error.error,
            'ERROR'
          ),
        });

        this.handleCertificateErrors(error.error);
      }
    );
  }

  private handleCertificateErrors(errors: any): void {
    if (Array.isArray(errors)) {
      this.ownCertificateErrors.error = errors;
    } else {
      this.ownCertificateErrors = errors;
    }
  }

  private showSnackbarError(message?: string): void {
    this.store.dispatch({
      type: SHOW_SNACKBAR,
      payload: new SnackbarConfig(message || 'COMMON.GLOBAL_ERROR', 'ERROR')
    });
  }

  private accountDeletedSuccessfully(hasSent?: boolean): void {
    this.store.dispatch({
      type: SHOW_SNACKBAR,
      payload: new SnackbarConfig(
        hasSent ? 'MENU.ACCOUNT.ACCOUNT_DISABLED' : 'MENU.ACCOUNT.ACCOUNT_DELETED',
        'INFO',
        20000
      ),
    });
    this.authService.logout();
    this.dialogRef.close();
  }

  private showAreYouSureDeleteAccountPopup(callback: (d: MatDialogRef<ConfirmCancelDialogComponent>) => any = () => {}): void {
    const dialog = this.dialog.open(ConfirmCancelDialogComponent);
    dialog.componentInstance.id = 'areYouSureDeletePopup';
    dialog.componentInstance.title = 'MENU.ACCOUNT.ACCOUNT_DELETION_MODAL.TITLE';
    dialog.componentInstance.description = 'MENU.ACCOUNT.ACCOUNT_DELETION_MODAL.DESCRIPTION';
    dialog.componentInstance.closeOnSubmit = false;
    dialog.componentInstance.submitActionEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        dialog.disableClose = true;
        callback(dialog);
      });
  }

  private showAreYouSureDeleteConfirmationNeededPopup(
    callback: (d: MatDialogRef<ConfirmCancelDialogComponent>) => any = () => {},
  ): void {
    const dialog = this.dialog.open(ConfirmCancelDialogComponent);
    dialog.componentInstance.id = 'areYouSureDeleteConfirmationNeededPopup';
    dialog.componentInstance.title = 'MENU.ACCOUNT.ACCOUNT_DELETION_HAS_PAID_STATEMENT_MODAL.TITLE';
    dialog.componentInstance.description = 'MENU.ACCOUNT.ACCOUNT_DELETION_HAS_PAID_STATEMENT_MODAL.DESCRIPTION';
    dialog.componentInstance.closeOnSubmit = false;
    dialog.componentInstance.submitActionEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        dialog.disableClose = true;
        callback(dialog);
      });
  }

  private showPopupAboutYouHaveSentStatement(
    callback: (d: MatDialogRef<ConfirmCancelDialogComponent>) => any = () => {},
  ): void {
    const dialog = this.dialog.open(ConfirmCancelDialogComponent);
    dialog.componentInstance.id = 'youHaveSentStatement';
    dialog.componentInstance.title = 'MENU.ACCOUNT.ACCOUNT_DELETION_HAS_SENT_STATEMENT_MODAL.TITLE';
    dialog.componentInstance.description = 'MENU.ACCOUNT.ACCOUNT_DELETION_HAS_SENT_STATEMENT_MODAL.DESCRIPTION';
    dialog.componentInstance.submitButtonTitle = 'MENU.ACCOUNT.ACCOUNT_DELETION_HAS_SENT_STATEMENT_MODAL.BUTTON_DISABLE';
    dialog.componentInstance.submitActionEmitter.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        dialog.disableClose = true;
        callback(dialog);
      });
  }

  private showErrorDialog(message?: string): void {
    const dialogRef = this.dialog.open(ErrorDialogComponent);
    dialogRef.componentInstance.message = '<div class="text-center">'
      + (message || this.translate.instant('COMMON.GLOBAL_ERROR'))
      + '</div>';
    dialogRef.componentInstance.understandButton = true;
  }

  private async accountDeletionWithConfirmation(dialog: MatDialogRef<any>, hasSent: boolean): Promise<void> {
    const confirmResponse = await this.userHttpService.deleteAccount(UserDeleteModel.confirmDeletionPayload());

    dialog.close();

    if (confirmResponse.success) {
      this.accountDeletedSuccessfully(hasSent);
    } else {
      this.showErrorDialog();
    }
  }
}
