<div class="vollmacht-info-edit">
    <div class="vollmacht-info-edit__row columns">
        <div class="vollmacht-info-edit__stage column">
            <app-form-input
              [errors]="errors?.vollmacht_city"
              [placeholder]="'INPUT.VOLLMACHT_CITY.PLACEHOLDER'"
              [label]="'INPUT.VOLLMACHT_CITY.LABEL'"
              [(model)]="statement.vollmachtCity"
              [currentInputId]="'vollmachtCity'"
              [isVerification]="true"
            >
            </app-form-input>
        </div>
        <div class="vollmacht-info-edit__stage column">
            <app-form-date-input
              [errors]="errors?.vollmacht_date"
              [placeholder]="'INPUT.VOLLMACHT_DATE.PLACEHOLDER'"
              [label]="'INPUT.VOLLMACHT_DATE.LABEL'"
              [(model)]="statement.vollmachtDate"
              [currentInputId]="'vollmachtDate'"
            ></app-form-date-input>
        </div>
    </div>
</div>
