import { FieldRuleValue, FieldValidationRules } from "app/core/form-validator/validatorInterface";
import { RegExValidator, ValidatorErrorMessage, ValidatorRules } from "app/core/form-validator/validatorRules";
import { FormValidatorConfig } from "app/configs/form-validator.config";
import { VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG } from "../validator-field-names/validator-field-names-send-message-dialog";

export const VALIDATOR_FIELD_RULES_SEND_MESSAGE_DIALOG: FieldValidationRules[] = [
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG.topic,
    classFieldName: "topic",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG.message,
    classFieldName: "message",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG.tax_authority,
    classFieldName: "tax_authority",
    fieldRules: [ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG.tax_number,
    classFieldName: "steuernummer",
    fieldRules: [ValidatorRules.FIELD_IS_REG_EX, ValidatorRules.IS_REQUIRED],
    fieldRulesValue: [RegExValidator.STEUER_NUMBER, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_NOT_STEUER, ValidatorErrorMessage.FIELD_IS_REQUIRED]
  },
  {
    year: FormValidatorConfig.ALL_YEAR_LIST,
    fieldName: VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG.id_number,
    classFieldName: "identifikationsnummer",
    fieldRules: [ValidatorRules.IS_REQUIRED, ValidatorRules.FIELD_IS_INTIGER],
    fieldRulesValue: [true, true],
    fieldErrorMessage: [ValidatorErrorMessage.FIELD_IS_REQUIRED, ValidatorErrorMessage.FIELD_SHOULD_BE_INTIGER]
  }
];
