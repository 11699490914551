// Authentication
export const REFRESH_TOKEN_INTERVAL = 1 * 1000 * 60;

// Statements
export const REFRESH_STATEMENTS_INTERVAL = 8000;
export const ONFIDO_REFRESH_STATUS_INTERVAL = 3000;
export const ONFIDO_STATUS_CHECK_LIMIT = 300;
export const DEFAULT_COUNTRY = 'DE';
export const ABROAD_INCOME_DEFAULT_COUNTRY = 'PL';
export const MAX_NUMBER_OF_OPENED_STATEMENT = 4;

// Sizes:
export const PHONE_SIZE = 400;
export const TABLET_SIZE = 750;
export const DESKTOP_SIZE = 1008;
export const WIDESCREEN_SIZE = 1200;
export const FULLHD_SIZE = 1392;

// Thresholds
export const FREELANCE_JOB_THRESHOLD = 60000;
export const KINDERGELD_THRESHOLD = 9999;

// Other:
export const MAX_FILE_SIZE = 5242880;
export const ALLOWED_EXTENSION = ['jpeg', 'jpg', 'pdf', 'tif', 'tiff'];
export const ERIC_ALLOWED_EXTENSION = ['json'];
export const FILES_DIR = 'assets/downloads/';
export const ADULT_AGE = 18;

// Analytic cookie keys
export const DISABLE_GOOGLE_TAG_MANAGER_COOKIE_KEY = 'google-analytics-opt-out';
export const DISABLE_ANALYTICS_COOKIES_COOKIE_KEY = 'disable-analytics-cookies';
export const DISABLE_TARGETING_COOKIES_COOKIE_KEY = 'disable-targeting-cookies';
