import { of as observableOf } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { State, getUserType } from 'app/reducers';
import { StatementService } from '../statement.service';
import { Statement } from '../statement';
import { SubscribingComponent } from 'app/common';
import { UserDataService } from 'app/users';
import { FirebaseEventsService } from 'app/common/firebase-events.service';
import {WorkflowControllerService} from 'app/core/workflow/workflow-controller/workflow-controller.service';
import { ToolsBarButtonInterface } from 'app/shared/components/tools-bar/tools-bar.component';
import { StatementErrors } from 'app/modules/statement/models/statement/statement.errors.interface';

@Component({
  selector: 'app-bank-detail',
  templateUrl: './bank-detail.component.html',
  styleUrls: ['./bank-detail.component.scss']
})
export class BankDetailComponent extends SubscribingComponent implements OnInit {
  public statementId: number;
  public statement: Statement;
  public isBusiness = false;
  public loadingData = false;
  public contentLoaded = false;
  public buttons: ToolsBarButtonInterface[] = [];
  public errors: StatementErrors;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private router: Router,
    private route: ActivatedRoute,
    private userDataService: UserDataService,
    private firebaseEventsService: FirebaseEventsService,
    public workFlowController: WorkflowControllerService
  ) {
    super();
  }

  ngOnInit() {
    this.store.select(getUserType).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (userType: string) => {
          if (userType) {
            this.isBusiness = userType === 'business';
          }
        }
      });

    this.route.params.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: params => {
          this.statementId = parseInt(params['statementId'], 10);
          this.getData();
        }
      });

    this.setButtons();
  }

  private getData() {
    this.loadingData = true;
    this.statementService.getStatement(this.statementId).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.loadingData = false))
      .subscribe({
        next: (response: Statement) => {
          this.contentLoaded = true;
          this.statement = response;
          this.workFlowController.init(this.statement);

          if (!this.isBusiness) {
            this.statement.hasBankAccount = true;
          }
        },
        error: () => {
          this.router.navigate(['/new']);
        }
      });
  }

  private setButtons() {
    this.buttons = [
      {
        type: 'standard',
        label: 'COMMON.BACK',
        action: this.goBack.bind(this)
      },
      {
        type: 'proceed',
        label: 'COMMON.PROCEED',
        action: this.proceed.bind(this),
        disabled: () => {
          return false;
        }
      }
    ];
  }

  private goBack() {
    this.workFlowController.goToThePreviousStep();
  }

  private proceed() {
    this.firebaseEventsService.logEvent('bank_account_data_success', null, this.statementId);
    this.updateStatement(true, false);
  }

  private updateStatement(goForward = false, goBack = false): void {
    this.contentLoaded = false;
    this.statementService.updateStatement(this.statement.id, this.statement.bankAccountStepRequest(''))
      .pipe(takeUntil(this.ngUnsubscribe),
        catchError((errors: any) => {
          this.handleErrors(errors);
          return observableOf();
        }),
        takeUntil(this.ngUnsubscribe),
        finalize(() => { this.contentLoaded = true; })
      )
      .subscribe({
        next: (response: Statement) => {
          Object.assign(this.statement, response);
          this.clearErrors();
          this.onDataUpdateSuccess(goForward, goBack);
        }
      });
  }

  private onDataUpdateSuccess(goForward = false, goBack = false) {
    if (goForward) {
      this.contentLoaded = false;
      this.workFlowController.goToTheNextStep();
    }

    if (goBack) {
      this.contentLoaded = false;
      this.workFlowController.goToThePreviousStep();
    }
  }

  private clearErrors() {
    this.errors = {};
  }

  private handleErrors(error: any) {
    const errors = error.error;
    if (errors.spouses) {
      if (errors.spouses.length) {
        this.errors = errors.spouses[0];
      }
    } else {
      this.errors = errors;
    }
  }
}
