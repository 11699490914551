<div class="attachments__dialog-box">
    <div class="attachments__header">
        <h2 class="title_40">Downloads</h2>
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="attachments__content">
        <ul class="attachments__list">

            <li class="attachments__single downloads" *ngFor="let file of files; index as i">
                <div class="attachments__single_box">
                    <div class="attachments__single_name">
                        {{ file.name }}
                    </div>
                    <a (click)="onView(i)" target="_blank" class="attachments__single_view">
                        <span>View</span>
                        <img alt="view" src="../../../assets/svg/view_icon.svg">
                    </a>
                </div>
                <button class="btn_w_icon" (click)="onDownload(i)">
                    <img alt="download" src="../../../assets/svg/download.svg">
                </button>
                <div class="attachments__single_year_wrap">
                    <div class="attachments__single_year">
                        Uploaded on 20/11/20
                    </div>
                </div>
            </li>

        </ul>
    </div>
</div>

<!--  pdf_decisions/2021/04/28/foreign_income_certificate_1.pdf

-->
