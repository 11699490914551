import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy, OnInit,
  Output, SimpleChange, ViewChild
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CaptchaOptionsInterface } from 'app/shared/modules/captcha/interfaces/captcha-options.interface';
import { CaptchaModelInterface } from 'app/shared/modules/captcha/interfaces/captcha-model.interface';
import { CaptchaWindowModelInterface } from 'app/shared/modules/captcha/interfaces/captcha-window-model.interface';
import { CaptchaConfig } from 'app/shared/modules/captcha/configs/captcha.config';
import { ScriptHelper } from 'app/core/helpers/script.helper';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [':host {display: block; min-height: 71px;}'],
})
export class CaptchaComponent implements OnInit, OnDestroy {
  @ViewChild('turnstileContainer', {static: true}) public turnstileContainer: ElementRef<HTMLDivElement>;
  @Input() siteKey = environment.cloudflareCaptchaKey;
  @Input() action?: string;
  @Input() cData?: string;
  @Input() theme?: 'light' | 'dark' | 'auto' = 'light';
  @Input() size?: 'compact' | 'normal' = 'normal';
  @Input() tabIndex?: number;
  @Input() resetCaptcha: boolean;
  @Output() resolved = new EventEmitter<string | null>();
  public isLoading$ = new BehaviorSubject(true);
  public isFailed$ = new BehaviorSubject(false);

  private readonly window: Window & CaptchaWindowModelInterface;
  private widgetId!: string;
  private errorCallbackCounter = 0;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService
  ) {
    this.window = this.document.defaultView as unknown as Window & CaptchaWindowModelInterface;
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['resetCaptcha'] && !changes['resetCaptcha'].isFirstChange()) {
      this.reset();
    }
  }

  ngOnInit(): void {
    this.init();
  }

  public reset(): void {
    if (this.widgetId) {
      this.resolved.emit(null);
      this.captcha?.reset(this.widgetId);
      return;
    }

    this.init();
  }

  public ngOnDestroy(): void {
    this.captcha?.remove(this.widgetId);
  }

  private get captcha(): CaptchaModelInterface {
    return this.window.turnstile
  }

  private init(): void {
    this.isLoading$.next(true);
    this.isFailed$.next(false);
    this.resolved.emit(null);

    const captchaOptions: CaptchaOptionsInterface = {
      sitekey: this.siteKey,
      theme: this.theme,
      tabindex: this.tabIndex,
      action: this.action,
      cData: this.cData,
      size: this.size,
      language: this.translate.currentLang,
      callback: (token: string) => {
        this.errorCallbackCounter = 0;
        this.zone.run(() => this.resolved.emit(token));
      },
      'expired-callback': () => {
        this.zone.run(() => this.reset());
      },
      'timeout-callback': () => {
        this.zone.run(() => this.reset());
      },
      'error-callback': () => {
        if (this.errorCallbackCounter > 10) {
          return;
        }

        this.errorCallbackCounter++;
        this.zone.run(() => this.reset());
      },
    };

    const onloadTurnstileCallback = () => {
      this.isLoading$.next(false);

      if (!this.turnstileContainer?.nativeElement) {
        return;
      }

      this.widgetId = this.captcha?.render(
        this.turnstileContainer.nativeElement,
        captchaOptions
      );
    }

    this.window.onloadTurnstileCallback = onloadTurnstileCallback;

    const onload = () => {
      this.isFailed$.next(false);
    }
    const onerror = () => {
      this.isLoading$.next(false);
      this.isFailed$.next(true);
    }

    if (
      !ScriptHelper.load({
        id: 'cloudflare-turnstile-captcha',
        src: `${CaptchaConfig.url}?render=explicit&onload=onloadTurnstileCallback`,
        defer: true,
        onload: onload,
        onerror: onerror,
      })
    ) {
      onloadTurnstileCallback();
    }
  }
}
