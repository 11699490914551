export class DeviceRecognizerHelper {
  public static isWebView(): boolean {
    const webViewRules = ["WebView", "(iPhone|iPod|iPad)(?!.*Safari)", "Android.*(wv|Version\\/\\d+)"];

    return DeviceRecognizerHelper.compareUserAgent(webViewRules) && !DeviceRecognizerHelper.isAndroidBrowser();
  }

  public static isIos(): boolean {
    return DeviceRecognizerHelper.compareUserAgent(["iPad", "iPhone", "iPod"]);
  }

  public static isAndroid(): boolean {
    return DeviceRecognizerHelper.compareUserAgent(["Android"]);
  }

  public static isIosSafari(): boolean {
    return DeviceRecognizerHelper.compareUserAgent(["(iPhone|iPod|iPad).*Safari"]);
  }

  public static isIosWebview(): boolean {
    return DeviceRecognizerHelper.compareUserAgent(["(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)"]);
  }

  public static isAndroidWebview(): boolean {
    return DeviceRecognizerHelper.compareUserAgent(["Android.*(wv|Version\\/\\d+)"]);
  }

  public static isAndroidBrowser(): boolean {
    return DeviceRecognizerHelper.compareUserAgent([
      "Chrome\\/\\d+", // Google Chrome
      "Firefox\\/\\d+", // Firefox
      "OPR\\/\\d+", // Opera
      "SamsungBrowser\\/\\d+", // Samsung Internet
      "Edg\\/\\d+" // Microsoft Edge
    ]);
  }

  public static compareUserAgent(userAgentPatterns: string[]): boolean {
    return new RegExp("(" + userAgentPatterns.join("|") + ")", "i").test(navigator.userAgent);
  }
}
