export class BinaryDecision {
  value: boolean;
  name: string;

  static get options(): BinaryDecision[] {
    return [
      {value: false, name: 'COMMON.NO'},
      {value: true, name: 'COMMON.YES'},
    ];
  }
}
