import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Statement } from 'app/statements/statement';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';
import {
  ToolsBarButtonInterface,
} from 'app/shared/components/tools-bar/tools-bar.component';

@Component({
  selector: 'app-statement-base-template',
  templateUrl: './statement-base-template.page.html',
})
export class StatementBaseTemplatePage {
  static metaData = {
    inputs: [
      'statement',
      'isBusiness',
      'templateRef',
      'buttons',
      'isLoading',
      'isInitialized',
    ],
  };
  @Input() templateRef: TemplateRef<any>;
  @Input() buttons: ToolsBarButtonInterface[];
  @Input() statement: Statement;
  @Input() isBusiness: boolean;
  @Input() isLoading: boolean;
  @Input() isInitialized: boolean;
  @Output() onEnterKeyDownEmitter = new EventEmitter<KeyboardEvent>();

  constructor(
    public workFlowController: WorkflowControllerService,
  ) {
  }

  public onEnterKeyDown(event: KeyboardEvent): void {
    this.onEnterKeyDownEmitter.emit(event);
  }
}
