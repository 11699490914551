<div class="dialog-box">
  <ng-container *ngIf="!isCustom">
    <div class="dialog-box__header">
      <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon aria-hidden="true">close</mat-icon>
      </button>
    </div>
    <div class="dialog-box__content">
      <div class="title">{{ title | translate }}</div>
      <div class="error-dialog">
        <div class="error-dialog__message" *ngIf="!understandButton" [innerHTML]="message"></div>
        <div class="error-dialog__message" *ngIf="understandButton" [innerHTML]="message | translate"></div>
        <ng-container *ngIf="!understandButton">
          <ng-container *ngIf="!endpoint">
            <div class="error-dialog__buttons">
              <button class="btn btn--primary btn--longer" mat-dialog-close>
                {{ "ERROR_MODAL_SUMMARY.SEND_INFO_EMAIL" | translate }}
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="endpoint">
            <div class="error-dialog__buttons">
              <button class="btn btn--primary btn--longer" mat-dialog-close>
                {{ "ERROR_MODAL_SUMMARY.GO_TO_ERROR_COMPONENT" | translate }}
              </button>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="understandButton">
          <div class="error-dialog__buttons">
            <button id="closeErrorPopup" class="btn btn--primary btn--longer" mat-dialog-close>
              {{ "REISTER_ERROR.UNDERSTAND" | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isCustom">
    <div class="dialog-box__custom_wrap">
      <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
          <mat-icon aria-hidden="true">close</mat-icon>
        </button>
      </div>
      <div class="dialog-box__content">
        <div class="dialog-box__custom" [class.error]="endpoint">
          <div class="lhs">
            <div class="title">{{ "ERROR_MODAL_SUMMARY.TITLE" | translate }}</div>
            <div class="desc">
              <ng-container *ngIf="!endpoint">
                {{ message | translate }}
                <a href="mailto:{{ 'ERROR_MODAL_SUMMARY.EMAIL' | translate }}">
                  {{ "ERROR_MODAL_SUMMARY.EMAIL" | translate }}
                </a>
              </ng-container>
              <ng-container *ngIf="endpoint">
                <div class="error">{{ message }}</div>
              </ng-container>
            </div>
            <div class="btns_wrap" [class.error]="endpoint">
              <ng-container *ngIf="!endpoint">
                <div class="single_btn">
                  <button class="btn btn--longer btn--small red" mat-dialog-close>
                    {{ "ERROR_MODAL_SUMMARY.IGNORE_BUTTON" | translate }}
                  </button>
                </div>
                <div class="single_btn">
                  <button class="btn btn--primary btn--longer" (click)="submitAction()" mat-dialog-close>
                    {{ "ERROR_MODAL_SUMMARY.SEND_INFO_EMAIL" | translate }}
                  </button>
                </div>
              </ng-container>
              <ng-container *ngIf="endpoint">
                <div class="single_btn">
                  <button class="btn btn--longer btn--small red" mat-dialog-close i18n>
                    {{ "ERROR_MODAL_SUMMARY.IGNORE_BUTTON" | translate }}
                  </button>
                </div>
                <div class="single_btn">
                  <button class="btn btn--primary is_icon" (click)="submitAction()" mat-dialog-close i18n>
                    <img class="btn_icon" src="../../../assets/img/edit_icon_taxando_error.png" alt="Button icon" />
                    {{ "ERROR_MODAL_SUMMARY.GO_TO_ERROR_COMPONENT" | translate }}
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="rhs">
            <picture>
              <img class="dialog_icon" src="../../../assets/img/graphic_taxando_error.png" alt="Dialog icon" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
