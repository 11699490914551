import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Statement } from 'app/statements/statement';
import { StatementService } from 'app/statements/statement.service';
import { takeUntil } from 'rxjs/operators';
import { SubscribingComponent } from '../subscribing-component';

@Component({
  selector: 'app-email-verification-dialog',
  templateUrl: './email-verification-dialog.component.html',
  styleUrls: ['./email-verification-dialog.component.scss']
})
export class EmailVerificationDialogComponent extends SubscribingComponent implements OnInit {
  public canSendLink: boolean = true;
  @Input() statement: Statement;

  constructor(
    private statementService: StatementService,
    private dialogRef: MatDialogRef<EmailVerificationDialogComponent>
  ) {
    super();
  }

  ngOnInit() {
  }

  public sendActivationLink() {
    if (this.canSendLink) {
      this.canSendLink = false;
      this.statementService.sendActivationLink(this.statement.id).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.canSendLink = true;
          this.dialogRef.close();
        }, errors => {
          this.canSendLink = true;
          console.log(errors);
        })
    }
  }
}
