import * as user from './actions/auth-user';
import { AuthModel } from 'app/modules/user/models/auth/auth.model';

export interface State {
  loggedUser: AuthModel | null;
}

export const initialState: State = {
  loggedUser: null
};

export function reducer(state = initialState, action: user.Actions): State {
  switch (action.type) {
    case user.USER_LOGIN: {
      const authUser = action.payload;
      return {
        loggedUser: authUser
      };
    }
    case user.USER_LOGOUT: {
      return {
        loggedUser: null
      };
    }
    case user.TOKEN_REFRESH: {
      if (!state.loggedUser) {
        console.log('nie zalogowany');
        
        return {
          loggedUser: null,
        };
      }

      const authUser = new AuthModel();
      // authUser.username = state.loggedUser.username;
      authUser.access = action.payload.access;
      authUser.refresh = action.payload.refresh;
      return {
        loggedUser: authUser
      };
    }
    default: {
      return state;
    }
  }
}

export const getUser = (state: State) => state.loggedUser;
export const isUserLogged = (state: State) => !!state.loggedUser;
