<div class="upload-buttons__wrap">
  <div class="upload-buttons__wrap-btn-container" *ngIf="hasCardButtonEnabled">
    <div class="ep_single_tile top" [class.active]="taxCardAddService.hasEmployers">
      <div
        class="ep_desc_wrap"
        (click)="taxCardAddService.onChooseIfHasCard();"
      >
        <div class="icon_tile_wrap">
          <img src="../../../../../assets/svg/11.svg" class="icon_tile lg">
        </div>
        <div class="title_tile_sm">
          {{ 'EMPLOYER.TILE_TITLE' | translate }}
        </div>
      </div>
    </div>

    <ng-container *ngIf="taxCardAddService.uploadingScan">
      <app-button-tile-loader></app-button-tile-loader>
    </ng-container>
  </div>

  <div class="upload-buttons__wrap-btn-container" *ngIf="uploadButtonEnabled">
    <label>
      <input
        type="file"
        accept="application/pdf"
        [disabled]="taxCardAddService.uploadingScan"
        (change)="onUpload()"
        #pdfField
      />
      <a
        class="ep_single_tile top"
        [class.is-loading]="taxCardAddService.uploadingScan"
      >
        <div class="ep_desc_wrap">
          <div class="icon_tile_wrap">
            <img src="../../../../../assets/svg/upload_pdf.svg" class="icon_tile sm">
          </div>
          <h4 class="title_tile_sm sm_m">
            {{ 'EMPLOYER.UPLOAD_TAXCARD_PDF' | translate }}
          </h4>
        </div>
      </a>

      <ng-container *ngIf="taxCardAddService.uploadingScan">
        <app-button-tile-loader></app-button-tile-loader>
      </ng-container>
    </label>
  </div>

  <div class="upload-buttons__wrap-btn-container" *ngIf="taxCardAddService.enableScanUpload && uploadButtonEnabled && taxCardAddService.canSnapTaxCard()">
    <label>
      <div
        class="ep_single_tile top"
        [class.is-loading]="taxCardAddService.uploadingScan"
        (click)="onPhotoClick()"
      >
        <div class="ep_desc_wrap">
          <div class="icon_tile_wrap">
            <img src="../../../../../assets/svg/upload_jpg.svg" class="icon_tile sm">
          </div>
          <div class="title_tile_sm sm_m">
            {{ 'EMPLOYER.UPLOAD_TAXCARD_PDF' | translate }}
          </div>
        </div>
      </div>

      <ng-container *ngIf="taxCardAddService.uploadingScan">
        <app-button-tile-loader></app-button-tile-loader>
      </ng-container>
    </label>
  </div>

  <div class="upload-buttons__wrap-btn-container" *ngIf="manuallyAddButtonEnabled" [attr.data-cy]="'btnTaxCardAddManually'">
    <div class="ep_single_tile top" [class.is-loading]="taxCardAddService.uploadingScan">
      <div class="ep_desc_wrap" (click)="taxCardAddService.onManuallyTaxCardAdded();" [attr.data-cy]="'btnAddTaxCardManually'">
        <div class="icon_tile_wrap">
          <img src="../../../../../assets/svg/details_manually.svg" class="icon_tile sm">
        </div>
        <div class="title_tile_sm">
          {{ 'EMPLOYER.MANUAL_CARD_LABEL' | translate }}
        </div>
      </div>
    </div>

    <ng-container *ngIf="taxCardAddService.uploadingScan">
      <app-button-tile-loader></app-button-tile-loader>
    </ng-container>
  </div>
</div>
