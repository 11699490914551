import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';

export class WorkflowProfileService extends WorkflowStepsBaseService {
  public get isDone(): boolean {
    return this.statement && this.statement.stageProfile;
  }

  public get isVisible(): boolean {
    return true;
  }

  public get title(): string {
    return 'ASIDE_BAR.FORM_PERSONALIZATION';
  }

  public get checkCanGo(): boolean {
    if (!this.blockedDefaultWorkflow && this.statement) {
      if (this.isBusiness) {
        return !!this.statement.stageAdditionalIncome;
      }

      return !!this.statement.stageSteuerAdditionalOption;
    }

    return false;
  }

  public get url(): string {
    return `statement/${this.statement && this.statement.id ? this.statement.id : 0}/profile`;
  }
}
