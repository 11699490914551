import { DeductionInfoChildComponentPath } from 'app/core/workflow/page-path/statement-page-path/deduction-info-child-component.path';
import { WorkflowDeductionBaseService } from 'app/core/workflow/workflow-steps/deduction/workflow-deduction-base.service';

export class WorkflowDeductionInfoChildSubService extends WorkflowDeductionBaseService {
  public get isRequired(): boolean {
    return !this.isBusiness && this.hasChildren && this.isDeductionOptionAdd;
  }

  public get isDone(): boolean {
    return this.statement && this.statement.stageDeductionInfoChildren;
  }

  public get isVisible(): boolean {
    return this.isRequired && this.statement.children.length > 1
      && window.location.href.search(DeductionInfoChildComponentPath.regex()) !== -1;
  }

  public get checkCanGo(): boolean {
    if (!this.blockedDefaultWorkflow && this.isRequired) {
      if (this.isBusiness) {
        return this.isSpouseStageEmployerDone;
      }

      return this.statement.stagePackageSelection;
    }

    return false;
  }

  public get isSubStep(): boolean {
    return true;
  }

  public get stepClass(): string {
    return this.classLeftLineNone + this.classSubSubMenuItem;
  }

  public get urlRegex(): RegExp {
    return DeductionInfoChildComponentPath.regex(this.subStepNumber);
  }

  public get url(): string {
    return DeductionInfoChildComponentPath.url(this.getStatementId, this.subStepNumber);
  }
}
