<ng-container *ngIf="isLoading">
  <app-content-loader></app-content-loader>
</ng-container>
<ng-container *ngIf="isInitialized">
  <div class="step-container columns is-desktop" [class.d-none]="isLoading">
    <app-aside-bar
      [showEvenWithoutStatement]="false"
      [statement]="statement"
      [workFlowController]="workFlowController"
    ></app-aside-bar>
    <div class="package-selection">
      <ng-container *ngIf="statement.isStandardPackageAvailable">
        <h1 class="title package_title">
          {{ 'PACKAGE_SELECTION.TITLE' | translate }}
        </h1>
        <p class="package_desc">
          {{ 'PACKAGE_SELECTION.DESCRIPTION' | translate }}
        </p>
      </ng-container>

      <ng-container *ngIf="!statement.isStandardPackageAvailable && statement.isPremiumPackageAvailable">
        <h1 class="title package_title">
          {{ 'PACKAGE_SELECTION.TITLE_ONE_PACKAGE' | translate }}
        </h1>
        <p class="package_desc">
          {{ 'PACKAGE_SELECTION.DESCRIPTION_ONE_PACKAGE' | translate }}
        </p>
      </ng-container>

      <ng-container *ngIf="!statement.isStandardPackageAvailable && !statement.isPremiumPackageAvailable">
        <h1 class="title package_title">
          {{ 'PACKAGE_SELECTION.TITLE_NO_PACKAGE' | translate }}
        </h1>
        <p class="package_desc">
          {{ 'PACKAGE_SELECTION.DESCRIPTION_NO_PACKAGE' | translate }}
        </p>
      </ng-container>

      <div class="package_choise_sec">

        <div class="top_select_btns_cont hidden_lg">
          <swiper [config]="configNav" #navSwiper>
            <div class="top_select_btns_wrap swiper-wrapper">
              <div class="single_btn swiper-slide" (click)="changeSlide(0)" [class.active_slide]="currentSlide == 0" *ngIf="statement.isPremiumPackageAvailable" [class.active_tab]="statement.isPremiumPackage">
                <span>{{'PACKAGE_SELECTION2.STEUER.TAX_OPTION_STEUER_LOW' | translate}}</span>
              </div>
              <div class="single_btn swiper-slide" (click)="changeSlide(1)" [class.active_slide]="currentSlide == 1" *ngIf="statement.isStandardPackageAvailable" [class.active_tab]="statement.isStandardPackage">
                <span>{{'PACKAGE_SELECTION2.PREMIUM.TAX_OPTION_PREMIUM_LOW' | translate}}</span>
              </div>
            </div>
          </swiper>
        </div>

        <div class="package_choise_cont">
          <div class="package_choise options active">
            <ul class="package_choise_list">
              <li>
                <span>{{'PACKAGE_SELECTION2.LIST.OPTION_2' | translate}}</span>
              </li>
              <li>
                <span>{{'PACKAGE_SELECTION2.LIST.OPTION_3' | translate}}</span>
              </li>
              <li>
                <span>{{'PACKAGE_SELECTION2.LIST.OPTION_4' | translate}}</span>
              </li>
              <li>
                <span>{{'PACKAGE_SELECTION2.LIST.OPTION_5' | translate}}</span>
              </li>
              <li>
                <span>{{'PACKAGE_SELECTION2.LIST.OPTION_6' | translate}}</span>
              </li>
              <li>
                <span>{{'PACKAGE_SELECTION2.LIST.OPTION_7' | translate}}</span>
              </li>
              <li>
                <div class="btn_list_green">
                  <span>{{'PACKAGE_SELECTION2.LIST.OPTION_8' | translate}}</span>
                </div>
              </li>
              <li>
                <span>{{'PACKAGE_SELECTION2.LIST.OPTION_9' | translate}}</span>
              </li>
            </ul>
          </div>

          <div class="package_choise_slider">
            <div class="package_choise_slider_header visible_lg">
              <span>{{'PACKAGE_SELECTION2.HOW_DOES_IT_WORK' | translate}}</span>
              <span>
                <app-more-info-tooltip
                  [showText]="false"
                  [titleTooltip]="'PACKAGE_SELECTION2.HOW_DOES_IT_WORK'"
                  [contentTemplateRef]="standardPackageTooltipTemplate1"
                ></app-more-info-tooltip>

                <ng-template #standardPackageTooltipTemplate1>
                  <app-tooltip-template-package-info
                    [isStandardPackage]="true"
                    [isHowDoesItWork]="true"
                    [standardAmount]="dataSelect.packageOptions.defaultStandardAmount"
                    [premiumAmount]="dataSelect.packageOptions.defaultPremiumAmount"
                    [currencySymbol]="dataSelect.packageOptions.currencySymbol.symbol">
                  </app-tooltip-template-package-info>
                </ng-template>

              </span>
            </div>
            <div class="package_choise_slider_header visible_lg">
              <span>{{'PACKAGE_SELECTION2.HOW_DOES_IT_WORK' | translate}}</span>
              <app-more-info-tooltip
                [showText]="false"
                [titleTooltip]="'PACKAGE_SELECTION2.HOW_DOES_IT_WORK'"
                [contentTemplateRef]="premiumPackageTooltipTemplate1"
              ></app-more-info-tooltip>

              <ng-template #premiumPackageTooltipTemplate1>
                <app-tooltip-template-package-info
                  [isPremiumPackage]="true"
                  [isHowDoesItWork]="true"
                  [standardAmount]="dataSelect.packageOptions.defaultStandardAmount"
                  [premiumAmount]="dataSelect.packageOptions.defaultPremiumAmount"
                  [currencySymbol]="dataSelect.packageOptions.currencySymbol.symbol">
                </app-tooltip-template-package-info>
              </ng-template>

            </div>
            <swiper [config]="configCont" #contSwiper>
              <div class="swiper-wrapper d-flex">
                <div
                  [attr.data-cy]="'packageOptionPaid'"
                  class="package_choise order-1-mobile center_row swiper-slide"
                  [class.selected]="statement.isStandardPackage"
                  [class.active_tab]="activeStandardTab"
                  [class.no_free]="statement.hasAbroadIncome"
                  [class.active]="statement.isStandardPackageAvailable"
                >
                  <div class="package_choise_content" (click)="selectPackageClick(packageOption.paid)">

                    <div class="package_choise_wrap premium" [class.no_free]="statement.hasAbroadIncome">
                      <div class="top_title">
                        <span>{{'PACKAGE_SELECTION2.PREMIUM.TAX_OPTION_PREMIUM' | translate}}</span>
                      </div>

                      <div class="select_package">
                        <div class="select_package_icon_wrap">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                            <path class="a" d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"/>
                            <g transform="translate(-321 -854)"><rect class="b" width="16" height="16" transform="translate(329 862)"/>
                              <g transform="translate(331 865)">
                                <path class="c" d="M1.348,5.414h0a1.729,1.729,0,0,1,2.412,0l1.32,1.321L9.947,1.906a1.727,1.727,0,0,1,2.411,0h0a1.685,1.685,0,0,1,0,2.386L6.272,10.3h0l-.029.029-.029.028-.029.029-.029.028-.043.029-.029.029-.043.014-.029.029-.043.028-.029.014-.043.029-.029.014-.043.015-.029.014-.043.029-.043.014-.029.014-.043.014h0l-.044.015-.029.014H5.527l-.043.014-.043.014H5.4l-.029.014H5.326l-.043.014h-.4l-.043-.014H4.794l-.043-.014H4.708l-.043-.014h0l-.029-.014H4.594l-.043-.014h0L4.522,10.7h0l-.043-.014h0l-.043-.014-.043-.014h0l-.029-.029h0l-.043-.014h0L4.292,10.6l-.043-.014-.043-.029-.029-.014h0l-.029-.028h0l-.043-.029-.029-.014-.043-.029-.029-.029-.029-.028-.043-.029-.029-.028L3.877,10.3,1.351,7.8a1.682,1.682,0,0,1,0-2.386h0Z" transform="translate(-0.853 -1.415)"/>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div class="select_package_desc">
                          <ng-container *ngIf="statement.isStandardPackage">
                            {{'PACKAGE_SELECTION2.SELECTED_LABEl' | translate}}
                          </ng-container>
                          <ng-container *ngIf="statement.isPremiumPackage">
                            {{'PACKAGE_SELECTION2.SELECT_LABEl' | translate}}
                          </ng-container>
                        </div>
                      </div>

                      <div class="package_choise_list_price">
                        <div class="package_choise_list_price_desc">
                          <div class="text-muted text-center">{{ 'PACKAGE_SELECTION2.STANDARD.UPTO' | translate }}</div>
                          <div class="price">
                            <span class="cur">{{ dataSelect.packageOptions.currencySymbol.symbol }}</span>
                            <span class="lg_price">{{ numberFormatHelper.integer(statementPaymentAmount.standardAmountTotal) }}</span>
                            <span class="sm_price">
                              {{ numberFormatHelper.decimal(statementPaymentAmount.standardAmountTotal) }}
                              <div class="tooltip_package_free">
                                <app-more-info-tooltip
                                  [titleTooltip]="'PACKAGE_SELECTION2.STANDARD.PRICING_DETAILS_FREE.TITLE'"
                                  [message]="'PACKAGE_SELECTION2.STANDARD.PRICING_DETAILS_FREE.DESCRIPTION'"
                                ></app-more-info-tooltip>
                            </div>
                          </span>
                          </div>
                          <div class="text-muted text-center">
                            {{'PACKAGE_SELECTION2.PREMIUM.FOR_EVERY_STATEMENT' | translate}}
                          </div>
                        </div>
                      </div>

                      <ul class="package_choise_list">
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_2' | translate}}</span>
                          <img src="../../../assets/svg/true.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_3' | translate}}</span>
                          <img src="../../../assets/svg/true.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_4' | translate}}</span>
                          <img src="../../../assets/svg/true.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_5' | translate}}</span>
                          <img class="no" src="../../../assets/svg/false.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_6' | translate}}</span>
                          <img class="no" src="../../../assets/svg/false.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_7' | translate}}</span>
                          <img class="no" src="../../../assets/svg/false.svg">
                        </li>
                        <li>
                          <div class="btn_list_green visible_sm">
                            <span>{{'PACKAGE_SELECTION2.LIST.OPTION_8' | translate}}</span>
                          </div>
                          <img class="no" src="../../../assets/svg/false.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_9' | translate}}</span>
                          <img class="no" src="../../../assets/svg/false.svg">
                        </li>
                      </ul>
                    </div>
                    <div class="package_choise_slider_footer visible_sm">
                      <span>{{'PACKAGE_SELECTION2.HOW_DOES_IT_WORK' | translate}}</span>
                      <app-more-info-tooltip
                        [showText]="false"
                        [titleTooltip]="'PACKAGE_SELECTION2.HOW_DOES_IT_WORK'"
                        [xlImgMobile]="true"
                        [contentTemplateRef]="standardPackageTooltipTemplate2"
                      ></app-more-info-tooltip>

                      <ng-template #standardPackageTooltipTemplate2>
                        <app-tooltip-template-package-info
                          [isStandardPackage]="true"
                          [isHowDoesItWork]="true"
                          [standardAmount]="dataSelect.packageOptions.defaultStandardAmount"
                          [premiumAmount]="dataSelect.packageOptions.defaultPremiumAmount"
                          [currencySymbol]="dataSelect.packageOptions.currencySymbol.symbol">
                        </app-tooltip-template-package-info>
                      </ng-template>
                    </div>
                  </div>
                </div>

                <div
                  [attr.data-cy]="'packageOptionPremium'"
                  class="package_choise order-0-mobile steuer swiper-slide"
                  [class.selected]="statement.isPremiumPackage"
                  [class.active_tab]="activeSteuerTab"
                  [class.active]="statement.isPremiumPackageAvailable"
                  [class.no_free]="statement.hasAbroadIncome"
                >
                  <div class="package_choise_content" (click)="selectPackageClick(packageOption.tax_adviser)">

                    <div class="package_choise_wrap steuer">
                      <div class="top_title">
                        <span>{{'PACKAGE_SELECTION2.STEUER.TAX_OPTION_STEUER' | translate}}</span>
                      </div>

                      <div class="select_package">
                        <div class="select_package_icon_wrap">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                            <path class="a" d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"/>
                            <g transform="translate(-321 -854)"><rect class="b" width="16" height="16" transform="translate(329 862)"/>
                              <g transform="translate(331 865)">
                                <path class="c" d="M1.348,5.414h0a1.729,1.729,0,0,1,2.412,0l1.32,1.321L9.947,1.906a1.727,1.727,0,0,1,2.411,0h0a1.685,1.685,0,0,1,0,2.386L6.272,10.3h0l-.029.029-.029.028-.029.029-.029.028-.043.029-.029.029-.043.014-.029.029-.043.028-.029.014-.043.029-.029.014-.043.015-.029.014-.043.029-.043.014-.029.014-.043.014h0l-.044.015-.029.014H5.527l-.043.014-.043.014H5.4l-.029.014H5.326l-.043.014h-.4l-.043-.014H4.794l-.043-.014H4.708l-.043-.014h0l-.029-.014H4.594l-.043-.014h0L4.522,10.7h0l-.043-.014h0l-.043-.014-.043-.014h0l-.029-.029h0l-.043-.014h0L4.292,10.6l-.043-.014-.043-.029-.029-.014h0l-.029-.028h0l-.043-.029-.029-.014-.043-.029-.029-.029-.029-.028-.043-.029-.029-.028L3.877,10.3,1.351,7.8a1.682,1.682,0,0,1,0-2.386h0Z" transform="translate(-0.853 -1.415)"/>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div class="select_package_desc">
                          <ng-container *ngIf="statement.isPremiumPackage">
                            {{'PACKAGE_SELECTION2.SELECTED_LABEl' | translate}}
                          </ng-container>
                          <ng-container *ngIf="statement.isStandardPackage">
                            {{'PACKAGE_SELECTION2.SELECT_LABEl' | translate}}
                          </ng-container>
                        </div>
                      </div>

                      <div class="package_choise_list_price">
                        <div class="package_choise_list_price_desc">
                          <ng-container *ngIf="statementPaymentAmount.shouldBeIndividualService">
                            <div class="font-weight-bolder text-center px-0_5">
                              {{ 'PACKAGE_SELECTION2.SHOULD_BE_INDIVIDUAL_SERVICE' | translate }}
                              <app-more-info-tooltip
                                class="v-align-mid"
                                [message]="'PACKAGE_SELECTION2.SHOULD_BE_INDIVIDUAL_SERVICE_TOOLTIP'"
                                [isSmallIcon]="true"
                              ></app-more-info-tooltip>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="!statementPaymentAmount.shouldBeIndividualService">
                            <div class="price">
                              <span class="cur">{{ statementPaymentAmount.currencySymbol }}</span>
                              <span class="lg_price">{{ numberFormatHelper.integer(statementPaymentAmount.premiumAmountTotal) }}</span>
                              <span class="sm_price">{{ numberFormatHelper.decimal(statementPaymentAmount.premiumAmountTotal) }}</span>
                            </div>
                            <div class="text-muted text-center">
                              {{'PACKAGE_SELECTION2.STEUER.FOR_EVERY_STATEMENT' | translate}}
                            </div>
                          </ng-container>
                        </div>

                        <div class="package_choise_btn">
                          {{'PACKAGE_SELECTION2.STEUER.PAY_LATER' | translate}}
                        </div>
                      </div>

                      <ul class="package_choise_list">
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_2' | translate}}</span>
                          <img src="../../../assets/svg/true.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_3' | translate}}</span>
                          <img src="../../../assets/svg/true.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_4' | translate}}</span>
                          <img src="../../../assets/svg/true.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_5' | translate}}</span>
                          <img src="../../../assets/svg/true.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_6' | translate}}</span>
                          <img src="../../../assets/svg/true.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_7' | translate}}</span>
                          <img src="../../../assets/svg/true.svg">
                        </li>
                        <li>
                          <div class="btn_list_green visible_sm">
                            <span>{{'PACKAGE_SELECTION2.LIST.OPTION_8' | translate}}</span>
                          </div>
                          <img src="../../../assets/svg/true.svg">
                        </li>
                        <li>
                          <span class="visible_sm">{{'PACKAGE_SELECTION2.LIST.OPTION_9' | translate}}</span>
                          <img src="../../../assets/svg/true.svg">
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="package_choise_slider_footer visible_sm">
                    <span>{{'PACKAGE_SELECTION2.HOW_DOES_IT_WORK' | translate}}</span>
                    <app-more-info-tooltip
                      [showText]="false"
                      [titleTooltip]="'PACKAGE_SELECTION2.HOW_DOES_IT_WORK'"
                      [xlImgMobile]="true"
                      [contentTemplateRef]="premiumPackageTooltipTemplate2"
                    ></app-more-info-tooltip>
                    <ng-template #premiumPackageTooltipTemplate2>
                      <app-tooltip-template-package-info
                        [isPremiumPackage]="true"
                        [isHowDoesItWork]="true"
                        [standardAmount]="dataSelect.packageOptions.defaultStandardAmount"
                        [premiumAmount]="dataSelect.packageOptions.defaultPremiumAmount"
                        [currencySymbol]="dataSelect.packageOptions.currencySymbol.symbol">
                      </app-tooltip-template-package-info>
                    </ng-template>
                  </div>
                </div>

              </div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
  </div>
</ng-container>
