import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Statement } from '../../statements/statement';
import { Batch } from '../../statements/batch';
import { SelectsData, SelectsDataInterface } from '../../statements/selects-data';
import { getSelectsData, State } from '../../reducers';
import { SubscribingComponent } from '../subscribing-component';
import { finalize, takeUntil } from 'rxjs/operators';
import { CustomLabels } from '../labels-edit/CustomLabels';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterStatements } from 'app/modules/statement/models/statement/filter-statements.model';

@Component({
  selector: 'app-sort-list',
  templateUrl: './sort-list.component.html',
  styleUrls: ['./sort-list.component.scss']
})
export class SortListComponent extends SubscribingComponent implements OnInit {
  sortType = 'creationDateDec';
  @Input() isOpened = false;
  @Input() isBusiness = false;
  @Input() isTaxAdviser = false;
  @Input() statements: Statement[];
  @Input() batches: Batch[];
  @Input() filterButtonDisabled = false;
  @Input() labelList: CustomLabels[] = [];
  @Output() filterEvent = new EventEmitter();
  dataSelects: SelectsDataInterface = new SelectsData();

  public searchId: number;
  public nrIdent: string;
  public stNumber = '';
  public birthDate = '';
  public fOffice = '';
  public aOffice: any = null;
  public office: number;
  public label: CustomLabels;
  public taxAuthorities: any[] = [];
  private rawTaxAuthorities: any[] = [];


  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.store.select(getSelectsData).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => { }))
      .subscribe((selectsData: SelectsDataInterface) => {
        if (selectsData) {
          this.rawTaxAuthorities = selectsData.taxAuthorities;
          this.taxAuthorities = selectsData.taxAuthorities;
        }
      });

    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        if (params.statement_id) {
          this.searchId = params.statement_id;
          this.filter();

          this.router.navigate(
            [],
            {
              queryParams: {statement_id: undefined},
              queryParamsHandling: 'merge'
            });
        }
      });
  }

  showFilters() {
    this.isOpened = true;
  }

  compareInc(a, b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  compareDec(a, b) {
    return -this.compareInc(a, b);
  }

  public filter() {
    this.filterEvent.emit(new FilterStatements(
      this.searchId,
      this.nrIdent,
      this.stNumber,
      this.birthDate,
      this.office,
      this.label ? this.label.id : undefined,
    ));
  }

  public selectOffice(selectedOffice: any) {
    if (selectedOffice.id) {
      this.office = selectedOffice.id;
    } else {
      this.office = 0;
    }
  }

  public filterOffice() {
    if (this.fOffice.length === 0) {
      this.office = 0;
    }
    this.taxAuthorities = [];
    this.rawTaxAuthorities.forEach(item => {
      this.taxAuthorities.push(item);
    });

    this.taxAuthorities = this.taxAuthorities.filter(kk => kk.name.toLowerCase().includes(this.fOffice.toLowerCase()));
  }

  public startFilter() {
    this.filter();
  }

  sort() {
    this.isBusiness ? this.sortBatches() : this.sortStatements();
    this.close();
  }

  sortStatements() {
    switch (this.sortType) {
      case 'firstNameInc':
        this.statements.sort((a, b) => this.compareInc(a['firstName'], b['firstName']));
        break;
      case 'firstNameDec':
        this.statements.sort((a, b) => this.compareDec(a['firstName'], b['firstName']));
        break;
      case 'lastNameInc':
        this.statements.sort((a, b) => this.compareInc(a['lastName'], b['lastName']));
        break;
      case 'lastNameDec':
        this.statements.sort((a, b) => this.compareDec(a['lastName'], b['lastName']));
        break;
      case 'statementYearInc':
        this.statements.sort((a, b) => this.compareInc(a['fullYear'], b['fullYear']));
        break;
      case 'statementYearDec':
        this.statements.sort((a, b) => this.compareDec(a['fullYear'], b['fullYear']));
        break;
      case 'creationDateInc':
        this.statements.sort((a, b) => this.compareInc(this.getDateFormat(a), this.getDateFormat(b)));
        break;
      case 'creationDateDec':
        this.statements.sort((a, b) => this.compareDec(this.getDateFormat(a), this.getDateFormat(b)));
        break;
      case 'identifikationsnummerInc':
        this.statements.sort((a, b) => this.compareInc(a['identifikationsnummer'] || 'x', b['identifikationsnummer'] || 'x'));
        break;
      case 'identifikationsnummerDec':
        this.statements.sort((a, b) => this.compareDec(a['identifikationsnummer'] || '-', b['identifikationsnummer'] || '-'));
        break;
    }
  }

  sortBatches() {
    if (!this.isTaxAdviser) {
      switch (this.sortType) {
        case 'creationDateInc':
          this.batches.sort((a, b) => this.compareDec(this.getBatchDateFormat(a), this.getBatchDateFormat(b)));
          break;
        case 'creationDateDec':
          this.batches.sort((a, b) => this.compareInc(this.getBatchDateFormat(a), this.getBatchDateFormat(b)));
          break;
      }
    } else if (this.isTaxAdviser) {
      switch (this.sortType) {
        case 'creationDateInc':
          this.statements.sort((a, b) => this.compareDec(this.getDateFormat(a), this.getDateFormat(b)));
          break;
        case 'creationDateDec':
          this.statements.sort((a, b) => this.compareInc(this.getDateFormat(a), this.getDateFormat(b)));
          break;
      }

    }
  }

  getDateFormat(statement: Statement) {
    const dateArray = statement.createdDate.split('/');
    return new Date(`20${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`);
  }

  getBatchDateFormat(batch) {
    return new Date(batch.created);
  }

  close() {
    this.isOpened = false;
  }
}
