<div class="employer-field-summary" [ngClass]="{'employer-field-summary--top': isPopupOpen}" (clickElsewhere)="hidePopup()">
    <div class="employer-field-summary__panel" (click)="openPopup()">
        <span class="employer-field-summary__panel-magnifier icon">
            <img src="../../../assets/svg/new_edit.svg" alt="field summary" />
        </span>
    </div>
    <div class="employer-field-summary__popup" [ngClass]="{'employer-field-summary__popup--visible': isPopupOpen}">
        <div class="employer-field-summary__popup-header">
            <span class="employer-field-summary__popup-header-close" (click)="hidePopup()">+</span>
            <span class="employer-field-summary__popup-header-title">{{ (fieldName) | translate }}</span>
            <span class="employer-field-summary__popup-header-currency">(€)</span>
        </div>
        <div class="employer-field-summary__popup-list">
            <div class="employer-field-summary__popup-item" *ngFor="let employer of employers; let i = index">
                <div class="employer-field-summary__popup-item-col"><span>{{ 'COMMON.TAXCARD' | translate }}</span> {{ i + 1 }}:</div>
                <div class="employer-field-summary__popup-item-col">{{ employers[i][fieldSymbol] }} &euro;</div>
                <div class="employer-field-summary__popup-item-col">
                    <div class="employer-field-summary__popup-item-edit" (click)="editEmployer(employer.id)">
                        <span class="icon">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                        </span>
                        <span>{{ 'COMMON.EDIT' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="employer-field-summary__popup-total">
            <span class="employer-field-summary__popup-total-tx is-bold">TOTAL</span>
            <span class="employer-field-summary__popup--value">{{ totalAmount }} &euro;</span>
        </div>
    </div>
</div>