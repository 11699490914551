import { BehaviorSubject, of as observableOf } from "rxjs";
import { mergeMap, takeUntil } from "rxjs/operators";
import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/users";
import { RegisterData, RegisterErrors } from "app/core/register-data";
import { Events, FirebaseEventsService } from "app/common/firebase-events.service";
import { StatementManagementService } from "app/statements/statement-management.service";
import { SubscribingComponent } from "app/common";
import { DeviceDetectorService } from "ngx-device-detector";
import { FirebaseHttpService } from "app/firebase/firebase.http.service";
import { FirebaseNotification } from "app/firebase/notification";
import { getUser, State } from "app/reducers";
import { Store } from "@ngrx/store";
import { User } from "app/users/user";
import { StatementService } from "app/statements/statement.service";
import { ImpressumComponent } from "app/core/impressum/impressum.component";
import { PersonalInfoOnLoginInterface } from "app/users";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { UserChangePasswordHttpService } from "app/modules/user/services/http/user-change-password.http.service";
import { DashboardPagePath } from "app/core/workflow/page-path/dashboard-page.path";
import { CaptchaModule } from "app/shared/modules/captcha/captcha.module";
import { CommonModule } from "@angular/common";
import { FormInputReactiveComponent } from "app/shared/standalone/components/form-input-reactive/form-input-reactive.component";
import { ClickElsewhereDirective } from "app/shared/standalone/directives/click-elsewhere.directive";
import { MoreInfoTooltipComponent } from "app/shared/standalone/components/more-info-tooltip/more-info-tooltip.component";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { FormInputComponent } from "app/shared/standalone/components/form-input/form-input.component";
import { ChooseLanguageComponent } from "app/shared/standalone/components/choose-language/choose-language.component";
import { AnalyticsService } from "app/shared/modules/analytics/services/analytics.service";
import { AnalyticsModule } from "app/shared/modules/analytics/analytics.module";
import { TermsFilesService } from "app/core/services/terms-files.service";
import { CookiesLawService } from "app/core/services/cookies-law.service";
import { CaptchaComponent } from "app/shared/modules/captcha/components/captcha/captcha.component";

interface LoginErrors {
  email?: string[];
  password?: string[];
  non_field_errors?: string[];
  detail?: string;
}

interface ViewSelection {
  loginFlag: AuthenticationDialogFlag;
}

export enum AuthenticationDialogFlag {
  LOGIN = "login",
  FORGOT = "forgot",
  FORGOT_SENT = "forgot_sent",
  REGISTRATION = "register"
}

@Component({
  selector: "app-login-dialog",
  templateUrl: "./login-dialog.component.html",
  styleUrls: ["./login-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ClickElsewhereDirective,
    MoreInfoTooltipComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    CaptchaModule,
    FormInputComponent,
    FormInputReactiveComponent,
    ChooseLanguageComponent,
    AnalyticsModule
  ],
  providers: [StatementManagementService, FirebaseEventsService, DeviceDetectorService, StatementService]
})
export class LoginDialogComponent extends SubscribingComponent implements OnInit {
  public captchaToken$ = new BehaviorSubject<string>(undefined);
  returnUrl: string;
  submitted: boolean;
  email: string;
  password: string;
  registerData: RegisterData;
  loginErrors: LoginErrors;
  registerErrors: RegisterErrors;
  areTermsAccepted = false;
  public readonly steps = AuthenticationDialogFlag;
  public activeStep: AuthenticationDialogFlag = AuthenticationDialogFlag.LOGIN;
  public showLoginForm = true;
  public personalInfoOnLogin: PersonalInfoOnLoginInterface = {
    firstname: "",
    lastname: "",
    emailAddress: ""
  };
  public forgotPasswordFormEmailError: string[];
  public forgotPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl({ value: "", disabled: this.isForgotPasswordLoading }, [
      Validators.required,
      Validators.email
    ])
  });
  personalInfoOnRegister: any;
  public set isForgotPasswordLoading(_isForgotPasswordLoading: boolean) {
    this._isForgotPasswordLoading = _isForgotPasswordLoading;
    this._isForgotPasswordLoading
      ? this.forgotPasswordForm.get("email").disable()
      : this.forgotPasswordForm.get("email").enable();
  }
  public get isForgotPasswordLoading(): boolean {
    return this._isForgotPasswordLoading;
  }
  private _isForgotPasswordLoading = false;
  private user: User;
  public positiveRegistration = false;

  public resetCaptcha = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userChangePasswordHttpService: UserChangePasswordHttpService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private statementManagementService: StatementManagementService,
    private analyticsService: AnalyticsService,
    private firebaseEventsService: FirebaseEventsService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private store: Store<State>,
    private firebaseHttpService: FirebaseHttpService,
    private statementService: StatementService,
    private termsFilesService: TermsFilesService,
    @Inject(MAT_DIALOG_DATA) private data: ViewSelection,
    private cookiesLawService: CookiesLawService
  ) {
    super();
  }

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.registerData = new RegisterData();

    if (this.data && this.data.loginFlag) {
      this.activeStep = this.data.loginFlag;
      if (sessionStorage.getItem("personalInfoOnLogin")) {
        this.personalInfoOnLogin = JSON.parse(sessionStorage.getItem("personalInfoOnLogin"));

        this.email = this.personalInfoOnLogin.emailAddress;
        this.registerData.email = this.personalInfoOnLogin.emailAddress;
      }
    }
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";

    this.registerData.clientType = "individual";
    this.statementManagementService.loginDialogOpened.emit();

    if (window.innerWidth < 768) {
      this.showLoginForm = false;
    }
  }

  captchaResolved(value: string | null): void {
    this.captchaToken$.next(value);
  }

  public get isLogin(): boolean {
    return this.activeStep === this.steps.LOGIN;
  }

  public get isForgot(): boolean {
    return this.activeStep === this.steps.FORGOT;
  }

  public get isForgotSent(): boolean {
    return this.activeStep === this.steps.FORGOT_SENT;
  }

  public get isRegistration(): boolean {
    return this.activeStep === this.steps.REGISTRATION;
  }

  public get forgotPasswordFormEmail(): UntypedFormControl {
    return this.forgotPasswordForm.get("email") as UntypedFormControl;
  }

  public async onSubmitForgot(): Promise<void> {
    this.forgotPasswordFormEmailErrorCheck();

    if (this.forgotPasswordForm.valid) {
      this.executeFirebaseEvent("reset_password", null);

      this.isForgotPasswordLoading = true;
      const response = await this.userChangePasswordHttpService.resetPasswordRequest(
        this.forgotPasswordFormEmail.value,
        this.captchaToken$.getValue()
      );
      this.isForgotPasswordLoading = false;

      if (response.success) {
        this.activeStep = this.steps.FORGOT_SENT;
        return;
      }

      if (response.error?.email) {
        this.forgotPasswordFormEmailError = response.error?.email;
      } else {
        this.forgotPasswordFormEmailError = [this.translate.instant("COMMON.GLOBAL_ERROR")];
      }
    }
  }

  public executeFirebaseEvent = (event: string, params: any) => this.firebaseEventsService.logEvent(event, params);

  login() {
    if (this.submitted) {
      return;
    }

    this.submitted = true;
    this.email = this.email.toLocaleLowerCase();

    if (sessionStorage.getItem("personalInfoOnLogin")) {
      this.personalInfoOnLogin = JSON.parse(sessionStorage.getItem("personalInfoOnLogin"));
    }
    this.personalInfoOnLogin.emailAddress = this.email;
    sessionStorage.setItem("personalInfoOnLogin", JSON.stringify(this.personalInfoOnLogin));

    return this.authService
      .login(this.email, this.password, this.captchaToken$.getValue())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.firebaseEventsService.logEvent("login", null);
          this.closeDialog();
        },
        error: (error: any) => {
          this.submitted = false;
          this.loginErrors = error.error;
          this.clearLoginPassword();
          console.log(this.captchaToken$);
          this.resetCaptcha = !this.resetCaptcha;
        }
      });
  }

  register() {
    if (this.submitted) {
      return;
    }

    this.submitted = true;
    this.registerData.language = this.translate.currentLang;
    this.registerData.email = this.registerData.email.toLocaleLowerCase();

    if (sessionStorage.getItem("personalInfoOnLogin")) {
      this.personalInfoOnLogin = JSON.parse(sessionStorage.getItem("personalInfoOnLogin"));
    }
    this.personalInfoOnLogin.emailAddress = this.registerData.email;
    sessionStorage.setItem("personalInfoOnLogin", JSON.stringify(this.personalInfoOnLogin));

    sessionStorage.setItem(
      "personalInfoOnRegister",
      JSON.stringify({ email: this.registerData.email, password: this.registerData.password })
    );

    this.authService
      .register(this.registerData, this.captchaToken$.getValue())
      .pipe(
        takeUntil(this.ngUnsubscribe),
        // mergeMap((response) => {
        //   if (response) {
        //     return this.authService.login(this.registerData.email, this.registerData.password, this.captchaToken$.getValue());
        //   } else {
        //     observableOf(null);
        //   }
        // }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        next: () => {
          this.firebaseEventsService.logEvent(Events.sign_up, null);
          this.firebaseEventsService.webEvent(Events.sign_up, null);
          this.analyticsService.logSignUpEvent();
          // this.closeDialog(true);
          this.showLoginBox();
        },
        error: (error: any) => {
          this.submitted = false;
          this.registerErrors = error && error.error ? error.error : null;
          this.clearRegisterPassword();
        }
      });
  }

  enableEditing() {
    this.submitted = false;
    this.loginErrors = {};
    this.registerErrors = {};
  }

  public get isLoginDisabled(): boolean {
    return this.submitted || !this.password || !this.email;
  }

  isRegisterDisabled() {
    return this.isRegisterFormInvalid() || !this.areTermsAccepted || this.submitted;
  }

  public showTermsAndConditionsDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
    this.termsFilesService.openTermsAndConditions();
  }

  public showCookieBanner(event): void {
    event.preventDefault();
    this.dialogRef.close();
    this.cookiesLawService.activeCookieBanner();
  }

  public showPrivacyPolicyDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
    this.termsFilesService.openPrivacyPolicy();
  }

  public showImpressumDialog() {
    this.dialog.open(ImpressumComponent);
  }

  public showLoginBox() {
    this.activeStep = this.steps.LOGIN;
    this.showLoginForm = true;
    this.enableEditing();
    this.getUserDataAfterRegister();
  }

  public showSignInBox() {
    this.activeStep = this.steps.REGISTRATION;
    this.showLoginForm = true;
    this.enableEditing();
  }

  public forgotPassword(): void {
    this.activeStep = this.steps.FORGOT;
    this.showLoginForm = true;
    this.enableEditing();
  }

  getUserDataAfterRegister() {
    if (sessionStorage.getItem("personalInfoOnRegister")) {
      this.personalInfoOnRegister = JSON.parse(sessionStorage.getItem("personalInfoOnRegister"));
      this.email = this.personalInfoOnRegister.email;
      this.password = this.personalInfoOnRegister.password;
      sessionStorage.removeItem("personalInfoOnRegister");

      this.positiveRegistration = true;
    }
  }

  closeDialog(register = false): void {
    this.store
      .select(getUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user && user.id) {
          this.user = user;
          if (!user.isBusiness && !register) {
            this.statementService
              .getAllOpenedStatement()
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (response: any): void => {
                  let numberOfOpenedStatement = 0;
                  if (response.statements_count) {
                    numberOfOpenedStatement = Number(response.statements_count);
                  }
                  if (numberOfOpenedStatement > 0) {
                    this.router.navigate([DashboardPagePath.fullUrl()]);
                    this.dialogRef.close();
                  } else {
                    this.getStatement();
                  }
                },
                error: () => {
                  this.getStatement();
                }
              });
          } else if (register) {
            this.getStatement();
          } else {
            this.firebaseNtificationOnLoginOrRegister();
            this.dialogRef.close();
          }
        }
      });
  }

  firebaseNtificationOnLoginOrRegister(): void {
    if (!this.user || !this.user.id) {
      return;
    }

    const notification = new FirebaseNotification();
    notification.user = this.user.id;
    notification.statement = 0;
    notification.notificationName = "login_or_register";
    notification.eventOneTriggered = true;
    notification.notificationSent = false;

    this.firebaseHttpService
      .udpateFirebaseNotification(notification)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {},
        (error) => {
          console.log(error);
        }
      );
  }

  private getStatement() {
    if (this.statementService.isExistsInLocalStorage()) {
      this.firebaseNtificationOnLoginOrRegister();
      this.dialogRef.close({
        next: this.user && !this.user.isBusiness
      });

      return;
    }

    this.firebaseNtificationOnLoginOrRegister();
    this.dialogRef.close();
    this.router.navigate([`/new/`]);
  }

  private isRegisterFormInvalid() {
    return !this.registerData.email || !this.registerData.password;
  }

  private clearLoginPassword() {
    this.password = "";
  }

  private clearRegisterPassword(): void {
    this.registerData.password = "";
    this.registerData.confirmPassword = "";
  }

  private forgotPasswordFormEmailErrorCheck(): void {
    if (this.forgotPasswordFormEmail.invalid) {
      if (this.forgotPasswordFormEmail.errors?.["required"]) {
        this.forgotPasswordFormEmailError = [this.translate.instant("EP_VALIDATOR.FIELD_IS_REQUIRED")];
        return;
      }
      if (this.forgotPasswordFormEmail.errors?.["email"]) {
        this.forgotPasswordFormEmailError = [this.translate.instant("EP_VALIDATOR.FIELD_WRONG_FORMAT")];
        return;
      }
    }

    this.forgotPasswordFormEmailError = [];
  }
}
