<ng-container *ngIf="dataSelects">
  <div class="personal-info-edit__section">
    <app-accordion [preIndex]="1" [title]="'PERSONAL_INFO.BASIC_DETAILS'" [canCollapse]="false">
      <div class="personal-info-edit__row columns">
        <div class="personal-info-edit__stage column">
          <app-form-input
            [errors]="errors?.first_name"
            [label]="'INPUT.FIRST_NAME.LABEL'"
            [placeholder]="'INPUT.FIRST_NAME.PLACEHOLDER'"
            [(model)]="formStatement.firstName"
            [currentInputId]="formStatement.isSpouse() ? 'spouseFirstName' : 'applicantFirstName'"
            [addClassB2B]="isBusiness"
            [classB2B]="formStatement.isSpouse() ? 'fname_input_pip' : 'fname_input_pit'"
          ></app-form-input>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.first_name"
          >
          </app-validator-error-message>
        </div>
        <div class="personal-info-edit__stage column">
          <app-form-input
            [errors]="errors?.last_name"
            [label]="'INPUT.LAST_NAME.LABEL'"
            [placeholder]="'INPUT.LAST_NAME.PLACEHOLDER'"
            [(model)]="formStatement.lastName"
            [addClassB2B]="isBusiness"
            [currentInputId]="formStatement.isSpouse() ? 'spouseLastName' : 'applicantLastName'"
            [classB2B]="formStatement.isSpouse() ? 'lname_input_pip' : 'lname_input_pit'"
          ></app-form-input>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.last_name"
          ></app-validator-error-message>
        </div>
      </div>

      <div class="personal-info-edit__row columns">
        <div class="personal-info-edit__stage column">
          <app-form-date-input
            [errors]="errors?.birth_date"
            [label]="'INPUT.DOB.LABEL'"
            [(model)]="formStatement.birthDate"
            [addClassB2B]="isBusiness"
            [attr.data-cy]="formStatement.isSpouse() ? 'inputSpouseBirthDate' : 'inputApplicantBirthDate'"
            [currentInputId]="formStatement.isSpouse() ? 'spouseBirthDate' : 'applicantBirthDate'"
            [classB2B]="formStatement.isSpouse() ? 'birthdate_input_pip' : 'birthdate_input_pit'"
          ></app-form-date-input>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.birth_date"
          >
          </app-validator-error-message>
        </div>
        <div class="personal-info-edit__stage column empty"></div>
      </div>

      <div class="personal-info-edit__row columns">
        <div class="personal-info-edit__stage column">
          <app-form-input
            [errors]="errors?.identifikationsnummer"
            [label]="'INPUT.IDENTIFIKATIONSNUMMER.LABEL'"
            [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
            [(model)]="formStatement.identifikationsnummer"
            [addClassB2B]="isBusiness"
            [currentInputId]="
              formStatement.isSpouse() ? 'spouseIdentifikationsnummer' : 'applicantIdentifikationsnummer'
            "
            [classB2B]="formStatement.isSpouse() ? 'idtkat_input_pip' : 'idkat_input_pit'"
          ></app-form-input>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.id_number"
          ></app-validator-error-message>
        </div>
        <div class="column column--form info_box">
          <div class="hidden-xs">
            <app-more-info-tooltip
              [alignLeft]="true"
              [revert]="true"
              [isSmall]="true"
              [showText]="true"
              [message]="'TOOLTIP.IDENTIFIKATIONSNUMMER'"
            ></app-more-info-tooltip>
          </div>
          <div class="visible-xs">
            <app-more-info-tooltip
              [alignLeft]="true"
              [isSmall]="true"
              [showText]="true"
              [message]="'TOOLTIP.IDENTIFIKATIONSNUMMER'"
            ></app-more-info-tooltip>
          </div>
        </div>
      </div>

      <div class="personal-info-edit__row columns">
        <div class="personal-info-edit__stage column">
          <app-form-select
            [errors]="errors?.religion"
            [label]="'SELECT.RELIGION.LABEL'"
            [placeholder]="
              formStatement.isSpouse() ? 'SELECT.RELIGION.SPOUSE_PLACEHOLDER' : 'SELECT.RELIGION.PLACEHOLDER'
            "
            [(model)]="formStatement.religion"
            [choices]="dataSelects.religionsTranslated"
            [valueName]="'id'"
            [optionName]="'name'"
            [addClassB2B]="isBusiness"
            [attr.data-cy]="'selectReligion'"
            [classB2B]="formStatement.isSpouse() ? 'religion_input_pip' : 'religion_input_pit'"
          >
          </app-form-select>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.religion"
          ></app-validator-error-message>
        </div>
        <div class="column column--form info_box">
          <div class="hidden-xs">
            <app-more-info-tooltip
              [alignLeft]="true"
              [revert]="true"
              [isSmall]="true"
              [showText]="true"
              [message]="'TOOLTIP.RELIGION'"
            ></app-more-info-tooltip>
          </div>
          <div class="visible-xs">
            <app-more-info-tooltip
              [alignLeft]="true"
              [isSmall]="true"
              [showText]="true"
              [message]="'TOOLTIP.RELIGION'"
            ></app-more-info-tooltip>
          </div>
        </div>
      </div>
    </app-accordion>
  </div>

  <!-- CONTACT DETAILS TAXPAYER -->
  <div class="personal-info-edit__section">
    <app-accordion [preIndex]="2" [title]="'PERSONAL_INFO.CONTACT_DETAILS'" [canCollapse]="false">
      <div class="personal-info-edit__row columns">
        <div class="personal-info-edit__stage column">
          <app-form-input
            [errors]="errors?.street"
            [label]="'INPUT.STREET.LABEL'"
            [placeholder]="'INPUT.STREET.PLACEHOLDER'"
            [(model)]="formStatement.street"
            [addClassB2B]="isBusiness"
            [currentInputId]="formStatement.isSpouse() ? 'spouseStreet' : 'applicantStreet'"
            [classB2B]="formStatement.isSpouse() ? 'cpstreet_input_pip' : 'cstreet_input_pit'"
          ></app-form-input>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.street"
          ></app-validator-error-message>
        </div>
        <div class="personal-info-edit__stage column">
          <app-form-input
            [errors]="
              errors?.house_number_with_supplement?.house_number ||
              errors?.house_number_with_supplement?.house_number_supplement ||
              errors?.house_number_with_supplement
            "
            [label]="'INPUT.HOUSE_NUMBER_WITH_SUPPLEMENT.LABEL'"
            [placeholder]="'INPUT.HOUSE_NUMBER_WITH_SUPPLEMENT.PLACEHOLDER'"
            [(model)]="formStatement.houseNumberWithSupplement"
            [addClassB2B]="isBusiness"
            [currentInputId]="formStatement.isSpouse() ? 'spouseHouseNumber' : 'applicantHouseNumber'"
            [classB2B]="formStatement.isSpouse() ? 'cphousenr_input_pip' : 'chousenr_input_pit'"
          ></app-form-input>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.house_nr"
          ></app-validator-error-message>
        </div>
      </div>

      <div class="personal-info-edit__row columns">
        <div class="personal-info-edit__stage column">
          <app-form-input
            [errors]="errors?.additional_address_info"
            [label]="'INPUT.ADDITIONAL_ADDRESS_INFO.LABEL'"
            [placeholder]="'INPUT.ADDITIONAL_ADDRESS_INFO.PLACEHOLDER'"
            [(model)]="formStatement.additionalAddressInfo"
            [addClassB2B]="isBusiness"
            [currentInputId]="formStatement.isSpouse() ? 'spouseAddress' : 'applicantAddress'"
            [classB2B]="formStatement.isSpouse() ? 'cpaddadr_input_pip' : 'caddaddress_input_pit'"
          ></app-form-input>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.additional_address_info"
          ></app-validator-error-message>
        </div>
      </div>

      <div class="personal-info-edit__row columns">
        <div class="personal-info-edit__stage column">
          <app-form-input
            [errors]="errors?.postal_code"
            [label]="'INPUT.POSTAL_CODE.LABEL'"
            [placeholder]="'INPUT.POSTAL_CODE.PLACEHOLDER'"
            [(model)]="formStatement.postalCode"
            (modelChange)="onPlaceChange($event, formStatement.country)"
            [addClassB2B]="isBusiness"
            [currentInputId]="formStatement.isSpouse() ? 'spousePostalCode' : 'applicantPostalCode'"
            [classB2B]="formStatement.isSpouse() ? 'cppostalcode_input_pip' : 'cpostalcode_input_pit'"
          ></app-form-input>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.post_code"
          ></app-validator-error-message>
        </div>

        <div class="personal-info-edit__stage column">
          <app-form-input
            [errors]="errors?.city"
            [label]="'INPUT.CITY.LABEL'"
            [placeholder]="'INPUT.CITY.PLACEHOLDER'"
            [(model)]="formStatement.city"
            [addClassB2B]="isBusiness"
            [currentInputId]="formStatement.isSpouse() ? 'spouseCity' : 'applicantCity'"
            [classB2B]="formStatement.isSpouse() ? 'cpcity_input_pip' : 'ccity_input_pit'"
          ></app-form-input>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.city"
          ></app-validator-error-message>
        </div>
      </div>

      <!-- USER NAME AND PHONE -->
      <div class="personal-info-edit__row columns a-start">
        <div class="personal-info-edit__stage column">
          <app-form-select
            [errors]="errors?.country"
            [label]="'SELECT.COUNTRY.LABEL'"
            [placeholder]="'SELECT.COUNTRY.PLACEHOLDER'"
            [(model)]="formStatement.country"
            (modelChange)="onPlaceChange(formStatement.postalCode, formStatement.country)"
            [choices]="dataSelects.countries"
            [valueName]="'code'"
            [optionName]="'name'"
            [addClassB2B]="isBusiness"
            [attr.data-cy]="'selectCountryOfResidence'"
            [classB2B]="formStatement.isSpouse() ? 'cpcountrie_input_pip' : 'ccountries_input_pit'"
          ></app-form-select>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.country"
          ></app-validator-error-message>
        </div>
        <ng-container *ngIf="!formStatement.isSpouse()">
          <div class="personal-info-edit__stage column">
            <app-form-input
              [errors]="errors?.phone_number"
              [label]="'INPUT.PHONE_NUMBER.LABEL'"
              [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
              [(model)]="formStatement.phoneNumber"
              [addClassB2B]="isBusiness"
              [classB2B]="'cphonenr_input_pit'"
            ></app-form-input>
            <app-validator-error-message
              [epValidator]="epValidator"
              [index]="0"
              [fieldName]="validatorFieldNames.phone_number"
            ></app-validator-error-message>
          </div>
        </ng-container>
      </div>
    </app-accordion>
  </div>

  <div class="personal-info-edit__section" *ngIf="isTaxAuthorityShown">
    <app-accordion [preIndex]="3" [title]="'PERSONAL_INFO.TAX_OFFICE_TITLE'" [canCollapse]="false">
      <div class="personal-info-edit__row columns">
        <div class="personal-info-edit__stage column">
          <app-personal-info-tax-authority
            [taxAuthority]="formStatement.taxAuthority"
            (setTaxAuthority)="formStatement.taxAuthority = $event"
            [taxAuthorities]="dataSelects.taxAuthorities"
            [errors]="errors?.tax_authority"
            [addClassB2B]="isBusiness"
            [isLoading]="isTaxAuthorityLoading"
          ></app-personal-info-tax-authority>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.tax_authority"
          >
          </app-validator-error-message>
        </div>
        <div class="personal-info-edit__stage column empty"></div>
      </div>

      <div class="personal-info-edit__row columns">
        <div class="personal-info-edit__stage column">
          <app-form-input
            [errors]="errors?.steuernummer"
            [label]="'INPUT.STEUERNUMMER.LABEL'"
            [currentInputId]="'applicantSteuernummer'"
            [placeholder]="'INPUT.DEFAULT.PLACEHOLDER'"
            [(model)]="formStatement.steuernummer"
            [addClassB2B]="isBusiness"
            [classB2B]="'steuernr_input_pit'"
          ></app-form-input>
          <app-validator-error-message
            [epValidator]="epValidator"
            [index]="0"
            [fieldName]="validatorFieldNames.tax_number"
          ></app-validator-error-message>
        </div>
        <div class="column column--form info_box">
          <div class="hidden-xs">
            <app-more-info-tooltip
              [alignLeft]="true"
              [revert]="true"
              [isSmall]="true"
              [showText]="true"
              [message]="'TOOLTIP.STEUERNUMMER'"
            ></app-more-info-tooltip>
          </div>
          <div class="visible-xs">
            <app-more-info-tooltip
              [alignLeft]="true"
              [isSmall]="true"
              [showText]="true"
              [message]="'TOOLTIP.STEUERNUMMER'"
            ></app-more-info-tooltip>
          </div>
        </div>
      </div>
    </app-accordion>
  </div>
</ng-container>
