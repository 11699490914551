<app-group-section-base
  [templateRef]="additionalIncomeGroupCryptocurrency"
  [isActive]="isMainStepActive"
  [statementFullYear]="additionalIncomeService.mainStatement?.fullYear"
  [selectedCounter]="selectedCounter"
  [selectButtons]="selectButtons"
  [title]="groupTitle"
  [description]="description"
  [titleTooltip]="'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.TOOLTIP.TITLE' | translate"
  [messageTooltip]="'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.TOOLTIP.MESSAGE' | translate"
>
</app-group-section-base>

<ng-template #additionalIncomeGroupCryptocurrency>
  <ng-container *ngIf="subSteps">
    <ng-container *ngIf="isSubStepActive(cryptocurrencySteps?.CRYPTOCURRENCY)">
      <app-group-section-edit-single-base
        [templateRef]="cryptocurrency"
        [overTitle]=""
        [title]="'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.CRYPTOCURRENCY_FORM.TITLE'"
        [description]="'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.CRYPTOCURRENCY_FORM.DESCRIPTION'"
        [titleTooltip]="'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.TOOLTIP.TITLE' | translate"
        [messageTooltip]="'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.TOOLTIP.MESSAGE' | translate"
      >
      </app-group-section-edit-single-base>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #cryptocurrency>
  <app-form-list
    [itemTitle]=""
    [formTemplateRef]="cryptoinputs"
    [list]="cryptocurrencyIncomeManager.getCryptoCurrencyPension(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      cryptocurrencyIncomeManager.getCryptoCurrencyPension(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.cryptocurrencyIncomeLimit
    "
    (removeButtonClickEmitter)="
      cryptocurrencyIncomeManager.removeCryptoCurrencyPension(additionalIncomeService.currentStatement, $event)
    "
    (addButtonClickEmitter)="
      cryptocurrencyIncomeManager.createCryptoCurrencyPension(additionalIncomeService.currentStatement)
    "
  ></app-form-list>

  <app-partner-info
    *ngIf="cryptoCurrencyPartners.length > 0"
    [text]="'ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.CRYPTOCURRENCT_PARTNERS.DESCRIPTION'"
    [partnersList]="cryptoCurrencyPartners"
  ></app-partner-info>
</ng-template>

<ng-template #cryptoinputs let-item="item" let-index="index">
  <app-additional-income-form-cryptocurrency
    [cryptocurrencyIncome]="item"
    [index]="index"
  ></app-additional-income-form-cryptocurrency>
</ng-template>
