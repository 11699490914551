import { DeductionInfoComponentPath } from 'app/core/workflow/page-path/statement-page-path/deduction-info-component.path';
import { WorkflowDeductionBaseService } from 'app/core/workflow/workflow-steps/deduction/workflow-deduction-base.service';

export class WorkflowDeductionApplicantSubBaseService extends WorkflowDeductionBaseService {
  public get subStepNumber(): number {
    return 1;
  }

  public get isRequired(): boolean {
    return !this.isBusiness && this.isDeductionOptionAdd;
  }

  public get isDone(): boolean {
    return this.checkCanGo;
  }

  public get isVisible(): boolean {
    return this.isRequired
      && window.location.href.search(DeductionInfoComponentPath.regex()) !== -1;
  }

  public get checkCanGo(): boolean {
    if (
      !this.blockedDefaultWorkflow
      && this.isRequired
      && this.statement
      && this.isDeductionOptionAdd
    ) {
      if (this.hasChildren) {
        return this.statement.stageDeductionInfoChildren;
      }

      return true;
    }

    return false;
  }

  public get isSubStep(): boolean {
    return true;
  }

  public get stepClass(): string {
    return this.classLeftLineNone + this.classSubSubMenuItem;
  }

  public get urlRegex(): RegExp {
    return DeductionInfoComponentPath.regex(this.subStepNumber);
  }

  public get url(): string {
    return DeductionInfoComponentPath.url(this.getStatementId, this.subStepNumber);
  }
}
