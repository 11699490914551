<ng-container *ngIf="!contentLoaded">
    <app-content-loader></app-content-loader>
</ng-container>
<ng-container *ngIf="contentLoaded">
    <div class="step-container columns is-desktop">
        <app-aside-bar
          [showEvenWithoutStatement]="false"
          [statement]="statement"
          [isNested]="isNested"
          [isBusiness]="isBusiness"
          [workFlowController]="workFlowController"
        ></app-aside-bar>
        <div class="add-income-options">
            <ng-container *ngIf="tilesActiveCount > 0">
                <div class="selected_wrapper">
                    {{ 'ADDITIONAL_INCOME_OPTIONS.SELECTED' | translate }} ({{ tilesActiveCount }}/{{addOptions.length}})
                </div>
            </ng-container>
            <div class="title_w_tooltip">
                <h1 class="title_42">
                    {{ 'ADDITIONAL_INCOME_OPTIONS.TITLE' | translate }}
                    <span class="tooltip_wrap">
                        <app-more-info-tooltip [titleTooltip]="'ADDITIONAL_INCOME_OPTIONS.TOOLTIP_TITLE'"
                        [message]="'ADDITIONAL_INCOME_OPTIONS.TOOLTIP_DESC'" [isInnerHTML]="true">
                        </app-more-info-tooltip>
                    </span>
                </h1>
            </div>
            <p class="subdesc" [innerHTML]="'ADDITIONAL_INCOME_OPTIONS.DESCRIPTION' | translate">
            </p>

            <div class="ep_new_tiles_wrap" *ngIf="addOptions">
                <div class="ep_new_single_tile_wrap" #tile *ngFor="let addOption of addOptions; let i = index;">
                    <!-- <button mat-icon-button class="button-delete" *ngIf="addOption.active" (click)="removeAddOption(addOption)">
                        <span></span>
                    </button> -->
                    <div class="ep_new_single_tile" [class.active]="addOption.active" (click)="selectAddOption(addOption)">
                        <div class="ep_desc_wrap">
                            <div class="icon_tile_wrap">
                                <img src="../../../assets/img/add_options/{{ addOption.icon }}"
                                    class="icon_tile">
                            </div>
                            <h4 class="title_tile_sm" [innerHTML]="addOption.text | translate">
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
    </div>
</ng-container>
