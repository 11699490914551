import {Component, Input, OnInit} from '@angular/core';
import { StatementErrors } from 'app/modules/statement/models/statement/statement.errors.interface';
import { SubscribingComponent } from '../subscribing-component';

@Component({
  selector: 'app-validation-errors-dialog',
  templateUrl: './validation-errors-dialog.component.html'
})
export class ValidationErrorsDialogComponent extends SubscribingComponent implements OnInit {
  @Input() validationErrors: StatementErrors;
  public formattedValidationErrors: Array<any>;

  constructor(
  ) {
    super();
  }

  ngOnInit() {
    this.formattedValidationErrors = this.nestedObjectSimplify(this.validationErrors);
  }

  private nestedObjectSimplify(objectInput: object, keyPrefix = ''): Array<any> {
    let resultArray = [];

    if (objectInput) {
      Object.keys(objectInput).forEach(key => {
        const valueAlfa = objectInput[key];
        if (valueAlfa) {
          if (Array.isArray(valueAlfa)) {
            const arrayOfObjects = valueAlfa.filter(x => typeof x === 'object' && Object.keys(x).length);
            arrayOfObjects.forEach(valueAlfaItem => {
              resultArray = [...resultArray, ...this.nestedObjectSimplify(valueAlfaItem, key + '.')];
            });
            const arrayOfStrings = valueAlfa.filter(x => typeof x === 'string');
            if (arrayOfStrings.length) {
              resultArray = [...resultArray, {key: keyPrefix + key, value: valueAlfa}];
            }
          } else if (typeof valueAlfa === 'object' && Object.keys(valueAlfa).length) {
            resultArray = [...resultArray, ...this.nestedObjectSimplify(valueAlfa, key + '.')];
          } else if (typeof valueAlfa === 'string') {
            resultArray = [{key: 'errorString', value: valueAlfa}];
          }
        }
      });
    }

    return resultArray;
  }
}
