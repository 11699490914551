import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ErrorMessage } from 'app/core/form-validator/validatorInterface';
import { Statement } from 'app/statements';
import { AdditionalIncomeService } from 'app/modules/statement/services/additional-income.service';
import { SocialSubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/social-sub.steps';
import { PensionSubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/pension.sub-steps';
import { CryptocurrencySubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/cryptocurrency.sub-steps';
import { FreelanceJobSubSteps } from 'app/core/workflow/steps-controller/sub-steps/additional-income/freelance-job.sub-steps';
import { takeUntil } from 'rxjs/operators';
import { SubscribingComponent } from 'app/common/subscribing-component';
import { SocialIncomeTypes } from 'app/modules/statement/models/additional-income/social-income.types';
import { FreelanceJobTypes } from 'app/modules/statement/models/additional-income/freelance-job.types';
import { PensionIncomeTypes } from 'app/modules/statement/models/additional-income/pension-income.types';
import { CryptocurrencyIncomeTypes } from 'app/modules/statement/models/additional-income/cryptocurrency.types';
import { SelectButton } from 'app/common/select-buttons/select-buttons.component';
import { log } from 'console';

@Component({
  selector: 'app-additional-income-dialog',
  templateUrl: './additional-income-dialog.component.html',
  styleUrls: ['./additional-income-dialog.component.scss']
})
export class AdditionalIncomeDialogComponent extends SubscribingComponent implements OnInit {
  @Input() mainStatement: Statement;
  @Input() currentStatement: Statement;
  @Input() type: SocialIncomeTypes|FreelanceJobTypes|PensionIncomeTypes|CryptocurrencyIncomeTypes;
  @Input() selectButton: SelectButton;
  @Input() tooltip = false;
  @Input() tooltipMsg = '';
  @Input() isBusiness: boolean;
  @Input() assignStatementResponse = true;
  public epValidator: ErrorMessage[];

  constructor(
    private dialogRef: MatDialogRef<AdditionalIncomeDialogComponent>,
    public additionalIncomeService: AdditionalIncomeService,
  ) {
    super();
  }

  ngOnInit() {
    this.dialogRef.afterOpened().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      const input = document.querySelector('app-form-list input') as HTMLElement;

      if (input) {
        input.focus();
      }
    });
  }

  public save(): void {
    this.formValidation();
    if (this.epValidator.length) {
      return;
    }
        
    this.dialogRef.disableClose = true;
    
    this.additionalIncomeService.updateAdditionalIncome(
      this.type as unknown as SocialSubSteps|PensionSubSteps|FreelanceJobSubSteps|CryptocurrencySubSteps,
      (success) => {
        if (success) {
          this.dialogRef.close(true);
        }
      },
      this.assignStatementResponse
    );
  }

  private formValidation(): void {
    this.additionalIncomeService.formValidationErrors(
      this.type as unknown as SocialSubSteps|PensionSubSteps|FreelanceJobSubSteps|CryptocurrencySubSteps
    );

    this.epValidator = [...this.additionalIncomeService.epValidator];
  }
}
