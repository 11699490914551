<ng-container *ngIf="showLabels()">
  <div>{{ 'LIST.LABELS' | translate }}</div>
  <ng-container *ngIf="currentId">
    <mat-select [(ngModel)]="selectedLabel" (ngModelChange)="setStatementLabel($event)" id="{{currentId}}">
      <mat-option class="label_none mat-option">NONE</mat-option>
      <mat-option *ngFor="let label of labelList; let i = index" [value]="label" class="{{'label_'+i}} mat-option">
        {{label.name}}
      </mat-option>
    </mat-select>
  </ng-container>
  <ng-container *ngIf="!currentId">
    <mat-select [(ngModel)]="selectedLabel" (ngModelChange)="setStatementLabel($event)">
      <mat-option >NONE</mat-option>
      <mat-option *ngFor="let label of labelList; let i=index" [value]="label">
        {{label.name}}
      </mat-option>
    </mat-select>
  </ng-container>
</ng-container>
