<div class="dialog-box">
  <div class="dialog-box__header">
    <button mat-icon-button mat-dialog-close tabindex="-1">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>
  </div>
  <div class="dialog-box__content">
    <div class="title">{{ 'BUY_CREDITS.TITLE' | translate }}</div>
    <div class="buy-credits" *ngIf="packages">
      <div class="buy-credits__gray">
        <mat-select
          placeholder="{{ 'BUY_CREDITS.SELECT_CREDITS' | translate }}"
          [(ngModel)]="selectedPackage" name="credit"
          #credit="ngModel"
        >
          <mat-option *ngFor="let availablePackage of getPackages()" [value]="availablePackage.id">
            {{ availablePackage.quantity }} {{ (getTaxFilesText(availablePackage.quantity)) | translate }}
            - {{ availablePackage.amount }} {{ availablePackage.currencyCode }}
          </mat-option>
        </mat-select>
      </div>
      <div class="buy-credits__methods">
        <h3 class="font-size-24 mb-0_5">{{ 'CHECKOUT.PAYMENT_DETAILS' | translate }}</h3>
        <div class="payment-checkout__payments">
          <mat-radio-group name="client_type" [(ngModel)]="selectedPaymentType">
            <ng-container *ngFor="let choice of paymentChoices">
              <mat-radio-button [value]="choice.platform" class="mr-1 mb-1">
                {{ choice.name }}
              </mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </div>
      </div>
      <div class="buy-credits__buttons">
        <button class="btn btn--secondary" mat-dialog-close>{{ 'COMMON.CANCEL' | translate }}</button>
        <button
          class="btn btn--primary btn--longer"
          [class.is-loading]="isButtonLoading"
          (click)="makePayment()"
          [disabled]="isButtonDisabled"
        >
          {{ 'BUY_CREDITS.BUY_CREDITS' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
