export interface CustomLabelsResponse{
    pk?:number;
    model?:string;
    fields?:{
        created_by?:number;
        name:string
    }
}

export class CustomLabels {
    public id: number;
    public name: string;
    public createdBy: number;

    public map(item: CustomLabelsResponse) {
        this.id = item.pk;
        this.name = item.fields.name;
        this.createdBy = item.fields.created_by;
    }
}