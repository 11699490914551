import { BasePagePath } from 'app/core/workflow/page-path/base-page.path';
import { PathParamKeys } from 'app/core/workflow/page-path/path-param-keys';

export class ActivatePagePath extends BasePagePath {
  public static path(): string {
    return `activate/:${PathParamKeys.activationKey}`;
  }

  public static get defaultPathParamRegex(): string {
    return '\(\[a-z\]\|\[0-9\]\)+';
  }
}
