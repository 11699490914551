import { Component, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Statement } from "app/statements";
import { AccordionComponent } from "../accordion/accordion.component";
import { AdditionalIncomeDialogComponent } from "../additional-income-dialog/additional-income-dialog.component";
import { SubscribingComponent } from "../subscribing-component";
import { SocialIncomeTypes } from "app/modules/statement/models/additional-income/social-income.types";
import { SocialIncomeManager } from "app/modules/statement/models/additional-income/social-income.manager";
import { FreelanceJobManager } from "app/modules/statement/models/additional-income/freelance-job.manager";
import { PensionIncomeManager } from "app/modules/statement/models/additional-income/pension-income.manager";
import { FreelanceJobTypes } from "app/modules/statement/models/additional-income/freelance-job.types";
import { PensionIncomeTypes } from "app/modules/statement/models/additional-income/pension-income.types";
import { AdditionalIncomeService } from "app/modules/statement/services/additional-income.service";
import { SocialSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/social-sub.steps";
import { PensionSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/pension.sub-steps";
import { FreelanceJobSubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/freelance-job.sub-steps";
import { SelectButton } from "app/common/select-buttons/select-buttons.component";
import { CryptocurrencyIncomeManager } from "app/modules/statement/models/additional-income/cryptocurrency.manager";
import { CryptocurrencyIncomeTypes } from "app/modules/statement/models/additional-income/cryptocurrency.types";
import { CryptocurrencySubSteps } from "app/core/workflow/steps-controller/sub-steps/additional-income/cryptocurrency.sub-steps";

@Component({
  selector: "app-income-info-edit",
  templateUrl: "./income-info-edit.component.html",
  styleUrls: ["./income-info-edit.component.scss"]
})
export class IncomeInfoEditComponent extends SubscribingComponent implements OnInit {
  @Input() mainStatement: Statement;
  @Input() currentStatement: Statement;
  @Input() isNested: boolean;
  @Input() active: boolean;
  @Input() isBusiness = false;
  @ViewChildren(AccordionComponent) accordions: QueryList<AccordionComponent>;
  public showRemove = true;
  public allowEdit = true;
  public freelanceJobManager = FreelanceJobManager;
  public pensionIncomeManager = PensionIncomeManager;
  public socialIncomeManager = SocialIncomeManager;
  public cryptoCurrencyIncomeManager = CryptocurrencyIncomeManager;
  public selectButtonsSocial: SelectButton[];
  public selectButtonsFreelance: SelectButton[];
  public selectButtonsPension: SelectButton[];
  public selectButtonsCryptocurrency: SelectButton[];

  constructor(
    private dialog: MatDialog,
    private additionalIncomeService: AdditionalIncomeService
  ) {
    super();
  }

  ngOnInit() {
    this.setSocialIncomeData();
    this.setFreeLanceData();
    this.setPensionData();
    this.setCryptocurrencyData();

    this.additionalIncomeService._init(this.mainStatement, this.currentStatement, this.ngUnsubscribe);
  }

  public openSocialIncomeModal(button: SelectButton) {
    const type = button.name as SocialIncomeTypes;
    const refDialog = this.openDialog();
    refDialog.componentInstance.selectButton = button;
    refDialog.componentInstance.type = type;

    if (!SocialIncomeManager.get(this.currentStatement, type).length) {
      SocialIncomeManager.create(this.currentStatement, type);
    }
  }

  public openFreelanceJobModal(button: SelectButton, freelanceJobType?: FreelanceJobTypes) {
    const type = button.name as FreelanceJobTypes;
    const refDialog = this.openDialog();
    refDialog.componentInstance.selectButton = button;
    refDialog.componentInstance.type = type;
    refDialog.componentInstance.tooltip =
      !!freelanceJobType && [FreelanceJobTypes.GEWERBE, FreelanceJobTypes.FREELANCE].includes(freelanceJobType)
        ? false
        : true;
    refDialog.componentInstance.tooltipMsg = "ADDITIONAL_INCOME.FREELANCE_GROUP.TOOLTIP.MESSAGE";

    if (!FreelanceJobManager.get(this.currentStatement, type).length) {
      FreelanceJobManager.create(this.currentStatement, type);
    }
  }

  public openCryptocurrencyJobModal(button: SelectButton) {
    const type = button.name as CryptocurrencyIncomeTypes;
    const refDialog = this.openDialog();
    refDialog.componentInstance.selectButton = button;
    refDialog.componentInstance.type = type;
    refDialog.componentInstance.tooltip = true;
    refDialog.componentInstance.tooltipMsg = "ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.TOOLTIP.MESSAGE";

    if (!CryptocurrencyIncomeManager.get(this.currentStatement, type).length) {
      CryptocurrencyIncomeManager.create(this.currentStatement, type);
    }
  }

  public openPensionModal(button: SelectButton) {
    const type = button.name as PensionIncomeTypes;
    const refDialog = this.openDialog();
    refDialog.componentInstance.selectButton = button;
    refDialog.componentInstance.type = type;
    refDialog.componentInstance.tooltip = true;
    refDialog.componentInstance.tooltipMsg = "ADDITIONAL_INCOME.PENSION_GROUP.TOOLTIP.MESSAGE";

    if (!PensionIncomeManager.get(this.currentStatement, type).length) {
      PensionIncomeManager.create(this.currentStatement, type);
    }
  }

  public socialIncomeCount(): number {
    let allNumber = 0;

    if (SocialIncomeManager.getUnemployment(this.currentStatement).length) {
      allNumber++;
    }
    if (SocialIncomeManager.getParental(this.currentStatement).length) {
      allNumber++;
    }
    if (SocialIncomeManager.getInsolvency(this.currentStatement).length) {
      allNumber++;
    }
    if (SocialIncomeManager.getSickness(this.currentStatement).length) {
      allNumber++;
    }
    if (SocialIncomeManager.getMaternity(this.currentStatement).length) {
      allNumber++;
    }
    if (SocialIncomeManager.getInjury(this.currentStatement).length) {
      allNumber++;
    }
    if (SocialIncomeManager.getTransition(this.currentStatement).length) {
      allNumber++;
    }

    return allNumber;
  }

  public freelanceIncomeCount(): number {
    let allNumber = 0;
    if (FreelanceJobManager.getFreelance(this.currentStatement).length) {
      allNumber++;
    }
    if (FreelanceJobManager.getGewerbe(this.currentStatement).length) {
      allNumber++;
    }
    if (FreelanceJobManager.getVolunteringIncome(this.currentStatement).length) {
      allNumber++;
    }
    return allNumber;
  }

  public cryptoCurrencyIncomeCount(): number {
    let allNumber = 0;
    if (CryptocurrencyIncomeManager.getCryptoCurrencyPension(this.currentStatement).length) {
      allNumber++;
    }
    return allNumber;
  }

  public pensionIncomeCount(): number {
    let allNumber = 0;
    if (PensionIncomeManager.getPension(this.currentStatement).length) {
      allNumber++;
    }
    return allNumber;
  }

  public deleteSocialIncome(type: SocialIncomeTypes) {
    SocialIncomeManager.removeAll(this.currentStatement, type);
    this.additionalIncomeService.loading = true;
    this.additionalIncomeService.updateAdditionalIncome(
      type as unknown as SocialSubSteps,
      (success) => {
        if (!success) {
          this.additionalIncomeService.setFromOriginalStatement();
        }
      },
      !this.currentStatement.isSpouse()
    );
  }

  public deleteFreelanceJobs(type: FreelanceJobTypes) {
    FreelanceJobManager.removeAll(this.currentStatement, type);
    this.additionalIncomeService.loading = true;
    this.additionalIncomeService.updateAdditionalIncome(
      type as unknown as FreelanceJobSubSteps,
      (success) => {
        if (!success) {
          this.additionalIncomeService.setFromOriginalStatement();
        }
      },
      !this.currentStatement.isSpouse()
    );
  }

  public deleteCryptocurrencyJobs(type: CryptocurrencyIncomeTypes) {
    CryptocurrencyIncomeManager.removeAll(this.currentStatement, type);
    this.additionalIncomeService.loading = true;
    this.additionalIncomeService.updateAdditionalIncome(
      type as unknown as CryptocurrencySubSteps,
      (success) => {
        if (!success) {
          this.additionalIncomeService.setFromOriginalStatement();
        }
      },
      !this.currentStatement.isSpouse()
    );
  }

  public deletePensionIncome(type: PensionIncomeTypes) {
    PensionIncomeManager.removeAll(this.currentStatement, type);
    this.additionalIncomeService.loading = true;
    this.additionalIncomeService.updateAdditionalIncome(
      type as unknown as PensionSubSteps,
      (success) => {
        if (!success) {
          this.additionalIncomeService.setFromOriginalStatement();
        }
      },
      !this.currentStatement.isSpouse()
    );
  }

  private setSocialIncomeData() {
    this.selectButtonsSocial = [
      {
        currentId: SocialIncomeTypes.UNEMPLOYMENT,
        name: SocialIncomeTypes.UNEMPLOYMENT,
        label: "ADDITIONAL_INCOME.SOCIAL_GROUP.UNEMPLOYMENT.TITLE",
        isSelected: () => SocialIncomeManager.getUnemployment(this.currentStatement).length,
        imageSrc: "assets/svg/additional_income/unemployment_benefit.svg",
        clickAction: (a, button: SelectButton) => this.openSocialIncomeModal(button),
        deleteAction: (button: SelectButton) => this.deleteSocialIncome(button.name as SocialIncomeTypes)
      },
      {
        currentId: SocialIncomeTypes.PARENTAL,
        name: SocialIncomeTypes.PARENTAL,
        label: "ADDITIONAL_INCOME.SOCIAL_GROUP.PARENTAL.TITLE",
        isSelected: () => SocialIncomeManager.getParental(this.currentStatement).length,
        imageSrc: "assets/svg/additional_income/parental_benefits.svg",
        clickAction: (a, button: SelectButton) => this.openSocialIncomeModal(button),
        deleteAction: (button: SelectButton) => this.deleteSocialIncome(button.name as SocialIncomeTypes)
      },
      {
        currentId: SocialIncomeTypes.INSOLVENCY,
        name: SocialIncomeTypes.INSOLVENCY,
        label: "ADDITIONAL_INCOME.SOCIAL_GROUP.INSOLVENCY.TITLE",
        isSelected: () => SocialIncomeManager.getInsolvency(this.currentStatement).length,
        imageSrc: "assets/svg/additional_income/parental_benefits.svg",
        clickAction: (a, button: SelectButton) => this.openSocialIncomeModal(button),
        deleteAction: (button: SelectButton) => this.deleteSocialIncome(button.name as SocialIncomeTypes)
      },
      {
        currentId: SocialIncomeTypes.SICKNESS,
        name: SocialIncomeTypes.SICKNESS,
        label: "ADDITIONAL_INCOME.SOCIAL_GROUP.SICKNESS.TITLE",
        isSelected: () => SocialIncomeManager.getSickness(this.currentStatement).length,
        imageSrc: "assets/svg/additional_income/sickness_benefit.svg",
        clickAction: (a, button: SelectButton) => this.openSocialIncomeModal(button),
        deleteAction: (button: SelectButton) => this.deleteSocialIncome(button.name as SocialIncomeTypes)
      },
      {
        currentId: SocialIncomeTypes.MATERNITY,
        name: SocialIncomeTypes.MATERNITY,
        label: "ADDITIONAL_INCOME.SOCIAL_GROUP.MATERNITY.TITLE",
        isSelected: () => SocialIncomeManager.getMaternity(this.currentStatement).length,
        imageSrc: "assets/svg/additional_income/maternity_benefit.svg",
        clickAction: (a, button: SelectButton) => this.openSocialIncomeModal(button),
        deleteAction: (button: SelectButton) => this.deleteSocialIncome(button.name as SocialIncomeTypes)
      },
      {
        currentId: SocialIncomeTypes.INJURY,
        name: SocialIncomeTypes.INJURY,
        label: "ADDITIONAL_INCOME.SOCIAL_GROUP.INJURY.TITLE",
        isSelected: () => SocialIncomeManager.getInjury(this.currentStatement).length,
        imageSrc: "assets/svg/additional_income/accident_benefit.svg",
        clickAction: (a, button: SelectButton) => this.openSocialIncomeModal(button),
        deleteAction: (button: SelectButton) => this.deleteSocialIncome(button.name as SocialIncomeTypes)
      },
      {
        currentId: SocialIncomeTypes.TRANSITION,
        name: SocialIncomeTypes.TRANSITION,
        label: "ADDITIONAL_INCOME.SOCIAL_GROUP.TRANSITION.TITLE",
        isSelected: () => SocialIncomeManager.getTransition(this.currentStatement).length,
        imageSrc: "assets/svg/additional_income/compensatory_allowance.svg",
        clickAction: (a, button: SelectButton) => this.openSocialIncomeModal(button),
        deleteAction: (button: SelectButton) => this.deleteSocialIncome(button.name as SocialIncomeTypes)
      },
      {
        currentId: SocialIncomeTypes.OTHER,
        name: SocialIncomeTypes.OTHER,
        label: "ADDITIONAL_INCOME.SOCIAL_GROUP.OTHER.TITLE",
        isSelected: () => SocialIncomeManager.getOther(this.currentStatement).length,
        imageSrc: "assets/svg/deductions/deduction_other.svg",
        clickAction: (a, button: SelectButton) => this.openSocialIncomeModal(button),
        deleteAction: (button: SelectButton) => this.deleteSocialIncome(button.name as SocialIncomeTypes)
      }
    ];
  }
  private setFreeLanceData() {
    this.selectButtonsFreelance = [
      {
        currentId: FreelanceJobTypes.FREELANCE,
        name: FreelanceJobTypes.FREELANCE,
        label: "ADDITIONAL_INCOME.FREELANCE_GROUP.FREELANCE.TITLE",
        isSelected: () => FreelanceJobManager.getFreelance(this.currentStatement).length,
        imageSrc: "assets/svg/additional_income/self_employment.svg",
        clickAction: (a, button: SelectButton) => this.openFreelanceJobModal(button, FreelanceJobTypes.FREELANCE),
        deleteAction: (button: SelectButton) => this.deleteFreelanceJobs(button.name as FreelanceJobTypes)
      },
      {
        currentId: FreelanceJobTypes.GEWERBE,
        name: FreelanceJobTypes.GEWERBE,
        label: "ADDITIONAL_INCOME.FREELANCE_GROUP.GEWERBE.TITLE",
        isSelected: () => FreelanceJobManager.getGewerbe(this.currentStatement).length,
        imageSrc: "assets/img/additional_income/gewerbe.png",
        clickAction: (a, button: SelectButton) => this.openFreelanceJobModal(button, FreelanceJobTypes.GEWERBE),
        deleteAction: (button: SelectButton) => this.deleteFreelanceJobs(button.name as FreelanceJobTypes)
      },
      {
        currentId: FreelanceJobTypes.VOLUNTERING_INCOME,
        name: FreelanceJobTypes.VOLUNTERING_INCOME,
        label: "ADDITIONAL_INCOME.FREELANCE_GROUP.VOLUNTERING_INCOME.TITLE",
        isSelected: () => FreelanceJobManager.getVolunteringIncome(this.currentStatement).length,
        imageSrc: "assets/svg/additional_income/voluntering.svg",
        clickAction: (a, button: SelectButton) =>
          this.openFreelanceJobModal(button, FreelanceJobTypes.VOLUNTERING_INCOME),
        deleteAction: (button: SelectButton) => this.deleteFreelanceJobs(button.name as FreelanceJobTypes)
      }
    ];
  }

  private setCryptocurrencyData() {
    this.selectButtonsCryptocurrency = [
      {
        currentId: CryptocurrencyIncomeTypes.CRYPTOCURRENCY,
        name: CryptocurrencyIncomeTypes.CRYPTOCURRENCY,
        label: "ADDITIONAL_INCOME.CRYPTOCURRENCT_GROUP.TITLE",
        isSelected: () => CryptocurrencyIncomeManager.getCryptoCurrencyPension(this.currentStatement).length,
        imageSrc: "assets/img/additional_income/cryptocurrency.png",
        clickAction: (a, button: SelectButton) => this.openCryptocurrencyJobModal(button),
        deleteAction: (button: SelectButton) => this.deleteCryptocurrencyJobs(button.name as CryptocurrencyIncomeTypes)
      }
    ];
  }

  private setPensionData() {
    this.selectButtonsPension = [
      {
        currentId: PensionIncomeTypes.PENSION,
        name: PensionIncomeTypes.PENSION,
        label: "ADDITIONAL_INCOME.PENSION_GROUP.PENSION.TITLE",
        isSelected: () => PensionIncomeManager.getPension(this.currentStatement).length,
        imageSrc: "assets/svg/additional_income/pension_income.svg",
        clickAction: (a, button: SelectButton) => this.openPensionModal(button),
        deleteAction: (button: SelectButton) => this.deletePensionIncome(button.name as PensionIncomeTypes)
      }
    ];
  }

  private openDialog(): MatDialogRef<AdditionalIncomeDialogComponent> {
    const refDialog = this.dialog.open(AdditionalIncomeDialogComponent, { autoFocus: false });
    refDialog.componentInstance.mainStatement = this.mainStatement;
    refDialog.componentInstance.currentStatement = this.currentStatement;
    refDialog.componentInstance.isBusiness = this.isBusiness;
    refDialog.componentInstance.assignStatementResponse = !this.isNested;

    if (this.isNested) {
      this.additionalIncomeService.updateOriginalStatement();
    }

    refDialog.afterClosed().subscribe((submitSuccess) => {
      if (!submitSuccess) {
        this.additionalIncomeService.setFromOriginalStatement();
      }
    });

    return refDialog;
  }
}
