import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Statement } from 'app/statements/statement';
import { combineLatest } from 'rxjs';
import { getSelectsData, getUser, State } from 'app/reducers';
import { take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SelectsData, SelectsDataInterface } from 'app/statements/selects-data';
import { ActivatedRoute, Router } from '@angular/router';
import { StatementService } from 'app/statements/statement.service';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';
import { AbstractComponent } from 'app/core/abstract.component';
import {
  ToolsBarButtonCommonTitle,
  ToolsBarButtonInterface,
  ToolsBarButtonType
} from 'app/shared/components/tools-bar/tools-bar.component';
import { HideViewSpinnerAction, ShowViewSpinnerAction } from 'app/reducers/actions/loader';
import { User } from 'app/users';
import { SHOW_SNACKBAR } from 'app/reducers/actions/snackbar';
import { SnackbarConfig } from 'app/common';
import { StatementComponentInterface } from 'app/statements/statement-component.interface';
import { PathParamKeys } from 'app/core/workflow/page-path/path-param-keys';

@Component({
  template: ''
})
export class StatementBasePage extends AbstractComponent implements OnInit, StatementComponentInterface {
  public statement: Statement;
  public spouseId: number;
  public childId: number;
  public stepNo: number;
  public dataSelects: SelectsDataInterface = new SelectsData();
  public user: User;
  public isBusiness = false;
  public toolsBarButtons: ToolsBarButtonInterface[] = [
    {
      type: ToolsBarButtonType.back,
      label: ToolsBarButtonCommonTitle.back,
      action: this.goBack.bind(this),
    },
    {
      type: ToolsBarButtonType.proceed,
      label: ToolsBarButtonCommonTitle.proceed,
      action: this.proceed.bind(this),
    }
  ];
  @Input() templateRef: TemplateRef<any>;
  @Input() buttons: ToolsBarButtonInterface[] = this.toolsBarButtons;
  private lastUrl: string;

  constructor(
    public store: Store<State>,
    public statementService: StatementService,
    public route: ActivatedRoute,
    public router: Router,
    public workFlowController: WorkflowControllerService,
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new ShowViewSpinnerAction());

    combineLatest([
      this.store.select(getUser),
      this.store.select(getSelectsData),
      this.route.params
    ]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: ([user, dataSelect, params]) => {
          if (user && dataSelect && params) {
            this.user = user;
            this.isBusiness = user.isBusiness;
            this.dataSelects = dataSelect;

            const currentUrl = this.router.url;

            if (!this.lastUrl || this.lastUrl !== currentUrl) {
              const statementId = Number(params[PathParamKeys.statementId]);
              this.childId = Number(params[PathParamKeys.childId]);
              this.spouseId = Number(params[PathParamKeys.spouseId]);
              this.stepNo = Number(params[PathParamKeys.stepNo]);
              this.getStatementData(statementId);

              this.lastUrl = currentUrl;
            }

            this.store.dispatch(new HideViewSpinnerAction());
          }
        }
      });

    this.afterOnInit();
  }

  public afterOnInit(): void {
  }

  public onGetStatementDataSuccess(): void {
    this._isInitialized = true;
  }

  public goBack(): void {
    this.loading = true;
    this.workFlowController.goToThePreviousStep();
  }

  public proceed(): void {
    this.loading = true;
    this.workFlowController.goToTheNextStep();
  }

  public onEnterKeyDown(): void {
    this.proceed();
  }

  protected showGlobalError(): void {
    this.store.dispatch({
      type: SHOW_SNACKBAR,
      payload: new SnackbarConfig('COMMON.GLOBAL_ERROR', 'ERROR')
    });
  }

  private getStatementData(statementId: number) {
    this.loadingStatementData = true;
    this._isInitialized = false;
    this.statementService.getStatement(statementId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        take(1)
      )
      .subscribe({
        next: async (response: Statement) => {
          this.statement = response;
          this.workFlowController.init(this.statement);

          this.onGetStatementDataSuccess();

          this.loadingStatementData = false;
        },
        error: () => {
          this.router.navigate(['/new']);
        }
      });
  }
}
