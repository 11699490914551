import {of as observableOf, merge as observableMerge, Subject} from 'rxjs';
import {finalize, mergeMap, takeUntil} from 'rxjs/operators';
import {Injectable, EventEmitter} from '@angular/core';
import {Statement} from './statement';
import {StatementService} from './statement.service';
import {Employer} from './employer';


@Injectable()
export class StatementManagementService {
  public loginDialogOpened = new EventEmitter<void>();
  public loginEmitter = new EventEmitter<any>();

  constructor(
    private statementService: StatementService,
  ) {
  }

  createOrAssignStatement(statement: Statement,
                          takeUntil: Subject<void>,
                          finallyCallback = () => {},
                          subscribeCallback = (employers: Employer[] | Employer, returnStatement?: Statement) => {}) {

  	if (statement.id) this.assignStatement(statement, takeUntil, finallyCallback, subscribeCallback);
    else if (statement.uuid) this.createStatementFromExisting(statement, takeUntil, finallyCallback, subscribeCallback);
    else this.createNewStatement(statement, takeUntil, finallyCallback, subscribeCallback);
  }

  assignStatement(statement: Statement,
                  takeUntil$: Subject<void>,
                  finallyCallback = () => {},
                  subscribeCallback = (employers: Employer[]) => {}) {

		let existingEmployers;
    this.statementService.assignStatement(statement.uuid).pipe(
      takeUntil(takeUntil$),
      mergeMap(() => {
        this.statementService.removeFromLocalStorage();
        existingEmployers = statement.employers.filter(em => em.id != null);
        const notExistingEmployers = statement.employers.filter(em => em.id == null);
        const arrayObservables = [];

        arrayObservables.push(this.statementService.updateStatement(statement.id, statement.summaryToResponse()));

        if (notExistingEmployers.length > 0) {
          notExistingEmployers.forEach(em =>
            arrayObservables.push(this.statementService.createEmployer(statement.id, em.toResponse()))
          );
        }

        return observableMerge(...arrayObservables);
      }),
      mergeMap(() => {
        if (existingEmployers.length > 0) {
          return this.statementService.updateEmployers(statement.id, existingEmployers);
        }

        return observableOf([]);
      }),
      takeUntil(takeUntil$),
      finalize(finallyCallback),)
      .subscribe(subscribeCallback);
  }

  createStatementFromExisting(statement: Statement,
                              takeUntil$: Subject<void>,
                              finallyCallback = () => {},
                              subscribeCallback = (employers: Employer[], returnStatement: Statement) => {}) {

    let returnStatement;
    this.statementService.createStatement(statement.newStatementToResponse()).pipe(
      takeUntil(takeUntil$),
      mergeMap((newStatement: Statement) => {
        this.statementService.removeFromLocalStorage();
        newStatement.employers = statement.employers;
        returnStatement = newStatement;

        if (newStatement.employers.length > 0) {
          const arrayObservables = [];
          newStatement.employers.forEach(em =>
            arrayObservables.push(this.statementService.createEmployer(newStatement.id, em.toResponse()))
          );

          return observableMerge(...arrayObservables);
        }

        return observableOf([]);
      }),
      takeUntil(takeUntil$),
      finalize(finallyCallback),)
      .subscribe((employers: Employer[]) => subscribeCallback(employers, returnStatement));
  }

  createNewStatement(statement: Statement,
										 takeUntil$: Subject<void>,
                     finallyCallback = () => {},
                     subscribeCallback = (employer: Employer, returnStatement: Statement) => {}) {

		const merge = (newStatement: Statement) => {
			returnStatement = newStatement;
			return observableOf(null);
		}

		let returnStatement;
		this.statementService
				.createStatement(statement?.newStatementToResponse())
				.pipe(takeUntil(takeUntil$), mergeMap(merge), takeUntil(takeUntil$), finalize(finallyCallback),)
      	.subscribe(() => subscribeCallback(null, returnStatement));
  }
}
