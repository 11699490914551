import { Component } from '@angular/core';
import { ButtonBaseComponent } from 'app/shared/components/buttons/button-base.component';

@Component({
  ...ButtonBaseComponent.metaData,
  selector: 'app-button-outline-primary',
  templateUrl: './button-outline-primary.component.html',
  styleUrls: ['./button-outline-primary.component.scss']
})
export class ButtonOutlinePrimaryComponent extends ButtonBaseComponent {
}
