import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptchaComponent } from 'app/shared/modules/captcha/components/captcha/captcha.component';
import { SquareLoaderComponent } from 'app/shared/standalone/components/square-loader/square-loader.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CaptchaComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SquareLoaderComponent,
  ],
  exports: [
    CaptchaComponent,
  ]
})
export class CaptchaModule {
}
