<div class="partners-info">
  <span class="partners-info__text">{{text | translate}}</span>
  <div class="partners-info__references">
    <div class="partners-info__partner" *ngFor="let partner of partnersList">
      <a href="{{partner.link}}" target="_blank">
        <figure>
          <img  src="{{partner.logo}}" alt="alt">
        </figure>
      </a>
    </div>
  </div>
</div>