<div class="bool-value-popup-confirm">
  <span class="subdesc">{{'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.MODAL_SWITCHER.MAIN_TEXT' | translate}}</span>
  <div class="actions">
    <button class="" (click)="onCancel()"
      >{{'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.MODAL_SWITCHER.BUTTON_2' | translate}}</button
    >
    <button class="" (click)="onConfirm()"
      >{{'ADDITIONAL_INCOME.FREELANCE_GROUP.LUMP_COSTS.MODAL_SWITCHER.BUTTON_1' | translate}}</button
    >
  </div>
</div>
