import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LohnsteuerValidator } from 'app/statements/lohnsteuerValidator';
import { Statement } from 'app/statements';
import { CardItemInterface } from 'app/shared/components/cards-items-list/cards-items-list.component';
import { DateHelper } from 'app/core/helpers/date.helper';
import { SubscribingComponent } from 'app/common/subscribing-component';
import { TaxCardIncomeInfoService } from 'app/common/tax-card-income-info.service';

@Component({
  selector: 'app-tax-cards-list',
  templateUrl: './tax-cards-list.component.html',
  styleUrls: ['./tax-cards-list.component.scss']
})
export class TaxCardsListComponent extends SubscribingComponent implements OnInit {
  get currentStatement(): Statement {
    return this._statement;
  }
  @Input() set currentStatement(_statement: Statement) {
    this._statement = _statement;
    this.prepareDataForCardsItemsList();
  }
  get taxCardIncomeInfoService(): TaxCardIncomeInfoService {
    return this._taxCardIncomeInfoService;
  }
  @Input() set taxCardIncomeInfoService(_taxCardIncomeInfoService: TaxCardIncomeInfoService) {
    this._taxCardIncomeInfoService = _taxCardIncomeInfoService;
    this.prepareDataForCardsItemsList();
  }
  get empWarningList(): LohnsteuerValidator[] {
    return this._empWarningList;
  }
  @Input() set empWarningList(_empWarningList: LohnsteuerValidator[]) {
    this._empWarningList = _empWarningList;
    this.prepareDataForCardsItemsList();
  }
  @Output() clickButtonAddEmitter = new EventEmitter();
  @Output() editSelectedEmployerEmitter = new EventEmitter();
  public dataList: CardItemInterface[] = [];
  public dateHelper = DateHelper;
  private _statement: Statement;
  private _empWarningList: LohnsteuerValidator[] = [];
  private _taxCardIncomeInfoService: TaxCardIncomeInfoService;
  private _isInitialized = false;

  constructor(
  ) {
    super();
  }

  ngOnInit() {
    this._isInitialized = true;

    this.prepareDataForCardsItemsList();
  }

  public prepareDataForCardsItemsList(): void {
    this.dataList = [];
    if (this._isInitialized && this.currentStatement && this.currentStatement.employers.length) {
      this.currentStatement.employers.forEach((employer, index) => {
        const textOne = employer.periodOfEmployment.lower || employer.periodOfEmployment.upper
          ? (
            this.dateHelper.removeYearFromDate(employer.periodOfEmployment.lower)
            + ' - '
            + this.dateHelper.removeYearFromDate(employer.periodOfEmployment.upper)
          )
          : null;
        this.dataList.push({
          id: employer.id,
          idForActionButtons: index,
          titleShort: 'EMPLOYER.TAXCARD_LIST_TITLE_SHORT',
          titleShortAfter: String(index + 1),
          title: 'EMPLOYER.TAXCARD_LIST_TITLE',
          titleAfter: String(index + 1),
          textOneShort: textOne,
          textOne: textOne,
          textTwo: employer.displayAmount ? employer.displayAmount : '0.00',
          warning: this.empWarningList.length && this.empWarningList[index] && !this.empWarningList[index].isValid()
            ? 'EMPLOYER.WARNING_INFO'
            : null,
          error: this.taxCardIncomeInfoService.employerHasErrors
          && this.taxCardIncomeInfoService.employerHasErrors.length
          && this.taxCardIncomeInfoService.employerHasErrors[index]
            ? 'EMPLOYER.ERRORS_INFO'
            : null,
          noError: 'EMPLOYER.NO_ERRORS_INFO',
        });
      });
    }
  }
}
