import { ChangePasswordModelInterface } from 'app/modules/user/models/change-password/change-password.model.interface';
import { ChangePasswordResponseInterface } from 'app/modules/user/models/change-password/change-password.response.interface';

export class ChangePasswordModel implements ChangePasswordModelInterface {
  public newPassword: string;
  public repeatNewPassword: string;
  public currentPassword?: string;
  public securityCode?: string;
  public uid?: string;
  public token?: string;

  public isPasswordsEqual(): boolean {
    return this.newPassword === this.repeatNewPassword;
  }

  public get payload(): ChangePasswordResponseInterface {
    return {
      new_password: this.newPassword || undefined,
      re_new_password: this.repeatNewPassword || undefined,
      current_password: this.currentPassword || undefined,
      security_code: this.securityCode || undefined,
      uid: this.uid || undefined,
      token: this.token || undefined,
    };
  }
}
