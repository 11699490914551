<div class="buttons-container">
  <button type="button" class="button btn_cancel" (click)="cancel()" [attr.data-cy]="'btnRadioNo'">
    <span class="txt">{{ btnTextFirst | translate }}</span>
  </button>
  <button
    type="button"
    class="button button-add"
    [disabled]="isDisabled"
    (click)="add()"
    [type]="buttonType"
    [attr.data-cy]="'btnRadioYes'"
    [class.is-loading]="isLoading"
  >
    <span class="txt">{{ btnTextSecond | translate }}</span>
  </button>
</div>
