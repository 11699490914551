<div class="payment-confirmation">
    <div class="payment-confirmation__header">
        <h1 class="title">{{ 'PAYMENT_ABORT.TITLE' | translate }}</h1>
    </div>
    <div class="payment-confirmation__message">
        <div class="payment-confirmation__message-desc">
            <p class="red">{{ 'PAYMENT_ABORT.MESSAGE' | translate }}</p>
            <p>{{ 'PAYMENT_ABORT.MESSAGE_P2' | translate }}</p>
        </div>

        <div class="payment-confirmation__message__links">
            <div class="btn_link" (click)="goBack()">{{ 'PAYMENT_ABORT.BUTTON_PAYMENT' | translate }}</div>
            <a class="btn_link gray" routerLink="/dashboard">{{ 'PAYMENT_ABORT.BUTTON_DASHBOARD' | translate }}</a>
            <ng-container *ngIf="isBusiness">
                <a class="btn_link gray" routerLink="/packages">{{ 'MENU.BUY_PACKAGES' | translate }}</a>s
            </ng-container>
        </div>
    </div>

    <div class="payment-confirmation__img">
        <img src="../../../assets/svg/payment_failed.svg">
    </div>
</div>
