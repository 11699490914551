<div class="list-item-item row list-item"
     [ngClass]="{ 'list-item--opened': openedItems[index] }">
    <div class="list-item__row columns is-mobile">
        <div class="list-item__col column row__column">
            <div class="list-item__col-title">{{ 'PAYMENT.QUANTITY' | translate }}</div>
            <div class="list-item__col-value">{{ payment.quantity }}</div>
        </div>
        <div class="list-item__col column row__column">
            <div class="list-item__col-title">{{ 'PAYMENT.AMOUNT' | translate }}</div>
            <div class="list-item__col-value">{{ payment.amount }}</div>
        </div>
        <div class="list-item__col column row__column">
            <div class="list-item__col-title">{{ 'PAYMENT.CREATION_DATE' | translate }}</div>
            <div class="list-item__col-value">{{ payment.creationDate }}</div>
        </div>
    </div>
    <div class="list-item__row columns is-mobile">
        <div class="list-item__col column row__column">
            <div class="list-item__col-title">{{ 'PAYMENT.CLEARED_DATE' | translate }}</div>
            <div class="list-item__col-value">{{ payment.clearedDate }}</div>
        </div>
        <div class="list-item__col column row__column">
            <div class="list-item__col-title">{{ 'PAYMENT.STATUS' | translate }}</div>
            <div class="list-item__col-value">{{ payment.status }}</div>
        </div>
        <div class="list-item__col column row__column">
            <div class="list-item__col-title">{{ 'PAYMENT.INVOICE' | translate }}</div>
            <div class="list-item__col-value">
                <button class="btn btn--info btn--icon btn--small"
                        title="{{ 'COMMON.DOWNLOAD' | translate }}"
                        [class.is-loading]="false"
                        [disabled]="false"
                        *ngIf="payment.canDownloadInvoice"
                        (click)="emitDownloadInvoice()">
                <span class="icon is-small">
                    <i class="fa fa-euro" aria-hidden="true"></i>
                </span>
                </button>
            </div>
        </div>
    </div>
</div>
