<div class="card_view_wrap">
  <ng-container *ngFor="let year of yearsList">
    <div class="year_title_wrap">
      <h3 class="title_24">
        {{ "DASHBOARD.TAX_YEAR_TITLE" | translate }} <strong>{{ year.year }}</strong> ({{ year.qty }})
      </h3>
      <button class="visible-xs" (click)="expandYearList(year)" mat-icon-button>
        <mat-icon *ngIf="!year.active">expand_more</mat-icon>
        <mat-icon *ngIf="year.active">expand_less</mat-icon>
      </button>
    </div>
    <div class="year_lists" [class.active]="year.active">
      <ng-container *ngFor="let statement of statements; let i = index">
        <ng-container *ngIf="statement.fullYear === year.year">
          <app-card-view-item
            [statement]="statement"
            (downloadPdf)="emitDownloadPdf(statement)"
            (downloadInvoice)="emitDownloadInvoice(statement)"
            (showDownloadDialog)="emitShowDownloadDialog(statement)"
            (deleteStatement)="emitDeleteStatement(statement, i)"
            (cloneStatement)="emitCloneStatement(statement)"
          >
          </app-card-view-item>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
