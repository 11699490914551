import { finalize, takeUntil } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

import { Store } from "@ngrx/store";

import { SHOW_SNACKBAR } from "../../reducers/actions/snackbar";
import { SubscribingComponent, SnackbarConfig, saveFile } from "../../common";
import { Statement } from "../statement";
import { StatementService } from "../statement.service";
import { PaymentService } from "../../payment/payment.service";
import { DownloadFileDialogComponent } from "../../common/decision-dialog/download-file-dialog.component";
import { getSelectsData, State } from "../../reducers";
import { SelectsData, SelectsDataInterface } from "../selects-data";

@Component({
  selector: "app-congratulations",
  templateUrl: "./congratulations.component.html",
  styleUrls: ["./congratulations.component.scss"]
})
export class CongratulationsComponent extends SubscribingComponent implements OnInit {
  statement: Statement;
  loadingStatementData = true;
  statementId: number;
  downloading = false;
  selectsData: SelectsDataInterface;

  constructor(
    private statementService: StatementService,
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private dialog: MatDialog,
    private store: Store<State>
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(getSelectsData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectsData: SelectsDataInterface) => {
        this.selectsData = selectsData;
      });

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.statementId = parseInt(params["statementId"], 10);
      this.getStatementData(this.statementId);
    });
  }

  getStatementData(id: number) {
    this.loadingStatementData = true;
    this.statementService
      .getStatement(id)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loadingStatementData = false))
      )
      .subscribe(
        (response: Statement) => (this.statement = response),
        () => this.router.navigate(["/new"])
      );
  }

  printStatement() {
    this.downloading = true;
    this.statementService
      .downloadStatementPdf(this.statementId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.downloading = false))
      )
      .subscribe((file: Blob) => saveFile(file, `statement-${this.statementId}.pdf`), this.showPDFError.bind(this));
  }

  showDecision() {
    const dialogRef = this.dialog.open(DownloadFileDialogComponent);
    dialogRef.componentInstance.statement = this.statement;
  }

  downloadInvoice() {
    this.downloading = true;
    this.paymentService
      .downloadInvoice(this.statement.payment)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.downloading = false))
      )
      .subscribe((file: Blob) => saveFile(file, `invoice-${this.statementId}.pdf`));
  }

  getTaxOffice() {
    return this.selectsData.taxAuthorities.find((taxAuthority) => taxAuthority.id === this.statement.taxAuthority).name;
  }

  showPDFError() {
    this.store.dispatch({ type: SHOW_SNACKBAR, payload: new SnackbarConfig("SNACKBAR.PDF_NOT_AVAILABLE", "ERROR") });
  }
}
