<ng-container *ngIf="isLoading">
  <app-content-loader></app-content-loader>
</ng-container>
<ng-container *ngIf="isInitialized">
  <div [class.d-none]="isLoading">
    <div class="step-container columns is-desktop">
      <app-aside-bar
        [showEvenWithoutStatement]="false"
        [statement]="statement"
        [isBusiness]="isBusiness"
        [workFlowController]="workFlowController"
      >
      </app-aside-bar>
      <div class="personal-info">
        <h1 class="title" *ngIf="!spouseId">{{ 'PERSONAL_INFO.TITLE' | translate }}</h1>
        <h1 class="title" *ngIf="spouseId">{{ 'PERSONAL_INFO.SPOUSE_DETAILS' | translate }}</h1>

        <div class="description">{{ 'PERSONAL_INFO.DESCRIPTION' | translate }}</div>

        <div class="copy-notification" *ngIf="!hideNotification && showCopyNotification()">
          <strong>{{ 'PERSONAL_INFO.ATTENTION' | translate }}</strong>
          <div class="copy-notification__section">
            <p>{{ 'PERSONAL_INFO.YOU_CAN_COPY_ADDRESS' | translate }}</p>
          </div>
          <div class="copy-notification__section">
            <button
              class="btn btn--secondary btn--longer btn--small"
              (click)="hideNotification = true"
              [attr.data-cy]="'btnNotCopyApplicantAddressToSpouse'"
            >
              {{ 'COMMON.NO' | translate }}
            </button>
            <button
              class="btn btn--primary btn--longer btn--small"
              (click)="copyAddressToSpouse()"
              [attr.data-cy]="'btnCopyApplicantAddressToSpouse'"
            >
              {{ 'COMMON.YES' | translate }}
            </button>
          </div>
        </div>

        <div
          class="personal-info__form"
          [appShowFormError]
          [errors]="errors"
          [epValidator]="epValidator"
          [openAccordions]="true"
        >
          <app-personal-info-edit
            [formStatement]="formStatement"
            [errors]="errors"
            [isBusiness]="isBusiness"
            [epValidator]="epValidator"
          ></app-personal-info-edit>
        </div>
      </div>
      <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
    </div>
  </div>
</ng-container>
