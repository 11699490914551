export const environment = {
  environment: 'staging',
  production: false,
  sentryDsn: 'https://2b95e7a88d1148deb38a6ee2de2af178@sentry.io/300981',
  firebase: {
    apiKey: "AIzaSyC4AbpwsQmlit5wdfuF6DiZ9hOMml4P8dA",
    authDomain: "taxando-staging.firebaseapp.com",
    projectId: "taxando-staging",
    storageBucket: "taxando-staging.appspot.com",
    messagingSenderId: "738094662595",
    appId: "1:738094662595:web:246dfeef482e3ba76a3376",
    measurementId: "G-HEVM2970HE",
  },
  stripe: 'pk_test_vzvCEXunPE8A9U3fwgQH3UZd00QelV3Y3Q',
  cloudflareCaptchaKey: '0x4AAAAAAACBDzi8-HQSIho6',
  googleTagManagerId: 'GTM-WRHG85P',
};
