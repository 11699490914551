<app-group-section-base
  [templateRef]="additionalIncomeGroupSocial"
  [isActive]="isMainStepActive"
  [statementFullYear]="additionalIncomeService.mainStatement?.fullYear"
  [selectedCounter]="selectedCounter"
  [selectButtons]="selectButtons"
  [title]="groupTitle"
  [description]="description"
  [titleTooltip]="'ADDITIONAL_INCOME.SOCIAL_GROUP.TOOLTIP.TITLE' | translate"
  [messageTooltip]="'ADDITIONAL_INCOME.SOCIAL_GROUP.TOOLTIP.MESSAGE' | translate"
>
</app-group-section-base>

<ng-template #additionalIncomeGroupSocial>
  <ng-container *ngIf="subSteps">
    <ng-container *ngIf="isSubStepActive(additionalSubSteps.UNEMPLOYMENT)">
      <app-group-section-edit-single-base
        [templateRef]="unemployment"
        [overTitle]="groupTitle"
        [title]="'ADDITIONAL_INCOME.SOCIAL_GROUP.UNEMPLOYMENT.TITLE'"
        [description]="'ADDITIONAL_INCOME.SOCIAL_GROUP.UNEMPLOYMENT.DESCRIPTION'"
      >
      </app-group-section-edit-single-base>
    </ng-container>
    <ng-container *ngIf="isSubStepActive(additionalSubSteps.PARENTAL)">
      <app-group-section-edit-single-base
        [templateRef]="parental"
        [overTitle]="groupTitle"
        [title]="'ADDITIONAL_INCOME.SOCIAL_GROUP.PARENTAL.TITLE'"
        [description]="'ADDITIONAL_INCOME.SOCIAL_GROUP.PARENTAL.DESCRIPTION'"
      >
      </app-group-section-edit-single-base>
    </ng-container>
    <ng-container *ngIf="isSubStepActive(additionalSubSteps.INSOLVENCY)">
      <app-group-section-edit-single-base
        [templateRef]="insolvency"
        [overTitle]="groupTitle"
        [title]="'ADDITIONAL_INCOME.SOCIAL_GROUP.INSOLVENCY.TITLE'"
        [description]="'ADDITIONAL_INCOME.SOCIAL_GROUP.INSOLVENCY.DESCRIPTION'"
      >
      </app-group-section-edit-single-base>
    </ng-container>
    <ng-container *ngIf="isSubStepActive(additionalSubSteps.SICKNESS)">
      <app-group-section-edit-single-base
        [templateRef]="sickness"
        [overTitle]="groupTitle"
        [title]="'ADDITIONAL_INCOME.SOCIAL_GROUP.SICKNESS.TITLE'"
        [description]="'ADDITIONAL_INCOME.SOCIAL_GROUP.SICKNESS.DESCRIPTION'"
      >
      </app-group-section-edit-single-base>
    </ng-container>
    <ng-container *ngIf="isSubStepActive(additionalSubSteps.MATERNITY)">
      <app-group-section-edit-single-base
        [templateRef]="maternity"
        [overTitle]="groupTitle"
        [title]="'ADDITIONAL_INCOME.SOCIAL_GROUP.MATERNITY.TITLE'"
        [description]="'ADDITIONAL_INCOME.SOCIAL_GROUP.MATERNITY.DESCRIPTION'"
      >
      </app-group-section-edit-single-base>
    </ng-container>
    <ng-container *ngIf="isSubStepActive(additionalSubSteps.INJURY)">
      <app-group-section-edit-single-base
        [templateRef]="injury"
        [overTitle]="groupTitle"
        [title]="'ADDITIONAL_INCOME.SOCIAL_GROUP.INJURY.TITLE'"
        [description]="'ADDITIONAL_INCOME.SOCIAL_GROUP.INJURY.DESCRIPTION'"
      >
      </app-group-section-edit-single-base>
    </ng-container>
    <ng-container *ngIf="isSubStepActive(additionalSubSteps.TRANSITION)">
      <app-group-section-edit-single-base
        [templateRef]="transition"
        [overTitle]="groupTitle"
        [title]="'ADDITIONAL_INCOME.SOCIAL_GROUP.TRANSITION.TITLE'"
        [description]="'ADDITIONAL_INCOME.SOCIAL_GROUP.TRANSITION.DESCRIPTION'"
      >
      </app-group-section-edit-single-base>
    </ng-container>
    <ng-container *ngIf="isSubStepActive(additionalSubSteps.OTHER)">
      <app-group-section-edit-single-base
        [templateRef]="other"
        [overTitle]="groupTitle"
        [title]="'ADDITIONAL_INCOME.SOCIAL_GROUP.OTHER.TITLE'"
        [description]="'ADDITIONAL_INCOME.SOCIAL_GROUP.OTHER.DESCRIPTION'"
      >
      </app-group-section-edit-single-base>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #unemployment>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.SOCIAL_GROUP.UNEMPLOYMENT.TITLE'"
    [formTemplateRef]="inputs"
    [list]="socialIncomeManager.getUnemployment(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      socialIncomeManager.getUnemployment(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.socialIncomeLimit
    "
    (removeButtonClickEmitter)="
      socialIncomeManager.removeUnemployment(additionalIncomeService.currentStatement, $event)
    "
    (addButtonClickEmitter)="socialIncomeManager.createUnemployment(additionalIncomeService.currentStatement)"
  ></app-form-list>
</ng-template>

<ng-template #parental>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.SOCIAL_GROUP.PARENTAL.TITLE'"
    [formTemplateRef]="inputs"
    [list]="socialIncomeManager.getParental(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      socialIncomeManager.getParental(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.socialIncomeLimit
    "
    (removeButtonClickEmitter)="socialIncomeManager.removeParental(additionalIncomeService.currentStatement, $event)"
    (addButtonClickEmitter)="socialIncomeManager.createParental(additionalIncomeService.currentStatement)"
  ></app-form-list>
</ng-template>

<ng-template #insolvency>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.SOCIAL_GROUP.INSOLVENCY.TITLE'"
    [formTemplateRef]="inputs"
    [list]="socialIncomeManager.getInsolvency(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      socialIncomeManager.getInsolvency(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.socialIncomeLimit
    "
    (removeButtonClickEmitter)="socialIncomeManager.removeInsolvency(additionalIncomeService.currentStatement, $event)"
    (addButtonClickEmitter)="socialIncomeManager.createInsolvency(additionalIncomeService.currentStatement)"
  ></app-form-list>
</ng-template>

<ng-template #sickness>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.SOCIAL_GROUP.SICKNESS.TITLE'"
    [formTemplateRef]="inputs"
    [list]="socialIncomeManager.getSickness(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      socialIncomeManager.getSickness(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.socialIncomeLimit
    "
    (removeButtonClickEmitter)="socialIncomeManager.removeSickness(additionalIncomeService.currentStatement, $event)"
    (addButtonClickEmitter)="socialIncomeManager.createSickness(additionalIncomeService.currentStatement)"
  ></app-form-list>
</ng-template>

<ng-template #maternity>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.SOCIAL_GROUP.MATERNITY.TITLE'"
    [formTemplateRef]="inputs"
    [list]="socialIncomeManager.getMaternity(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      socialIncomeManager.getMaternity(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.socialIncomeLimit
    "
    (removeButtonClickEmitter)="socialIncomeManager.removeMaternity(additionalIncomeService.currentStatement, $event)"
    (addButtonClickEmitter)="socialIncomeManager.createMaternity(additionalIncomeService.currentStatement)"
  ></app-form-list>
</ng-template>

<ng-template #injury>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.SOCIAL_GROUP.INJURY.TITLE'"
    [formTemplateRef]="inputs"
    [list]="socialIncomeManager.getInjury(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      socialIncomeManager.getInjury(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.socialIncomeLimit
    "
    (removeButtonClickEmitter)="socialIncomeManager.removeInjury(additionalIncomeService.currentStatement, $event)"
    (addButtonClickEmitter)="socialIncomeManager.createInjury(additionalIncomeService.currentStatement)"
  ></app-form-list>
</ng-template>

<ng-template #transition>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.SOCIAL_GROUP.TRANSITION.TITLE'"
    [formTemplateRef]="inputs"
    [list]="socialIncomeManager.getTransition(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      socialIncomeManager.getTransition(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.socialIncomeLimit
    "
    (removeButtonClickEmitter)="socialIncomeManager.removeTransition(additionalIncomeService.currentStatement, $event)"
    (addButtonClickEmitter)="socialIncomeManager.createTransition(additionalIncomeService.currentStatement)"
  ></app-form-list>
</ng-template>

<ng-template #other>
  <app-form-list
    [itemTitle]="'ADDITIONAL_INCOME.SOCIAL_GROUP.OTHER.TITLE'"
    [formTemplateRef]="inputs"
    [list]="socialIncomeManager.getOther(additionalIncomeService.currentStatement)"
    [isAddButtonDisabled]="
      socialIncomeManager.getOther(additionalIncomeService.currentStatement).length >=
      additionalIncomeService.socialIncomeLimit
    "
    (removeButtonClickEmitter)="socialIncomeManager.removeOther(additionalIncomeService.currentStatement, $event)"
    (addButtonClickEmitter)="socialIncomeManager.createOther(additionalIncomeService.currentStatement)"
    [deductionInfoText]="'ADDITIONAL_INCOME.SOCIAL_GROUP.OTHER.DEDUCTION_INFO'"
  ></app-form-list>
</ng-template>

<ng-template #inputs let-item="item" let-index="index">
  <app-additional-income-form-social [socialIncome]="item" [index]="index"></app-additional-income-form-social>
</ng-template>
