import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { StatementService } from "../../statements/statement.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Statement } from "../../statements";
import { Loading } from "../../common/loading";
import { Attachment } from "../../statements/attachment";
import { AttachmentType } from "../../statements/attachment-type";
import { SHOW_SNACKBAR } from "../../reducers/actions/snackbar";
import { MAX_FILE_SIZE, SnackbarConfig } from "../../common";
import { Store } from "@ngrx/store";
import { State } from "../../reducers";
import { ErrorDialogComponent } from "../../common/error-dialog/error-dialog.component";

@Component({
  selector: "app-list-view-attachments",
  templateUrl: "./list-view-attachments.component.html",
  styleUrls: ["./list-view-attachments.component.scss"]
})
export class ListViewAttachmentsComponent {
  @Output() quantity = new EventEmitter<number>();

  public loadingData = new Loading();
  public newFiles = new Loading(false);
  public loading = false;
  public loadingDelete = false;
  public attachments: Attachment[] = [];
  public types = AttachmentType;
  public statementId: number;

  /** Specifies whether to close the modal window after saving changes. */
  private close_after_saving = true;

  constructor(
    public dialogRef: MatDialogRef<ListViewAttachmentsComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: { statement: Statement },
    private statementService: StatementService,
    private store: Store<State>
  ) {
    this.statementId = this.data.statement.id;
    this.statementService.attachment
      .getList(this.statementId)
      .subscribe(this.handleData, console.error, this.loadingData.disable);
  }

  public onDelete(index: number): void {
    const item = this.attachments[index] as Attachment;
    if (item.id !== null) {
      this.loadingDelete = true;
      this.statementService.attachment.delete(this.data.statement.id, item.id).subscribe(() => {
        this.quantity.emit(-1);
        this.attachments.splice(index, 1);
        this.loadingDelete = false;
      });
    } else {
      this.attachments.splice(index, 1);
      this.setButtons();
    }
  }

  public onAdd($event) {
    const file: File = $event.target.files[0];

    const object = new Attachment(file);

    if (object.file_size > MAX_FILE_SIZE) {
      this.store.dispatch({
        type: SHOW_SNACKBAR,
        payload: new SnackbarConfig("SNACKBAR.MAX_FILE_SIZE_5_MB", "ERROR")
      });
      return;
    }

    this.attachments.push(object);
    this.newFiles.enable();
  }

  public onSave(): void {
    this.loading = true;

    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_) => resolve(reader.result);
        reader.onerror = (e) => reject(e);
      });
    };

    const elements = this.attachments
      .filter((element: Attachment) => !element.id)
      .map(async (element) => {
        return { type: element.type, file: await getBase64(element.file) };
      });

    const handleResponse = (response) => {
      this.quantity.emit(response.length);

      if (this.close_after_saving) {
        this.dialogRef.close();
      } else {
        this.attachments = this.attachments.filter((element: Attachment) => !!element.id).concat(response);

        this.setButtons();
      }

      this.loading = false;
    };

    const handleError = (error) => {
      console.error(error);

      this.loading = false;
      let errorMessage = error.error;

      if (Array.isArray(errorMessage)) {
        errorMessage = errorMessage.join(". ");
      }

      this.attachments = this.attachments.filter((element: Attachment) => !!element.id);

      this.dialogRef.close();
      const dialogRef = this.dialog.open(ErrorDialogComponent);
      dialogRef.componentInstance.message = '<div class="text-center">' + errorMessage + "</div>";
      dialogRef.componentInstance.understandButton = true;
    };

    Promise.all(elements).then((result) => {
      this.statementService.attachment
        .createMany(this.data.statement.id, result)
        .subscribe(handleResponse, handleError);
    });
  }

  public onView(index: number) {
    if (this.attachments[index].id) {
      this.statementService.attachment.get(this.data.statement.id, this.attachments[index].id).subscribe((response) => {
        const byteArray = new Uint8Array(
          atob(response["base64"])
            .split("")
            .map((char) => char.charCodeAt(0))
        );

        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobURL = URL.createObjectURL(blob);

        window.open(blobURL, "_blank");
      });
    } else {
      const file = this.attachments[index].file;
      const blobURL = URL.createObjectURL(file);

      window.open(blobURL, "_blank");
    }
  }

  private handleData = (result: Attachment[]) => {
    this.attachments = result;
  };

  private setButtons = () => {
    const newFile = (element) => !element.id;
    const array = this.attachments.filter(newFile);

    if (array.length === 0) {
      this.newFiles.disable();
    }
  };
}
