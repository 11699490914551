import { finalize, takeUntil } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { Store } from "@ngrx/store";

import { getUserType, State } from "../../reducers";
import { SubscribingComponent, saveFile } from "../../common";
import { Statement } from "../../statements";
import { StatementService, StatementsResponse } from "../../statements/statement.service";
import { DownloadFileDialogComponent } from "../../common/decision-dialog/download-file-dialog.component";
import { PaymentService } from "../../payment/payment.service";
import { RemoveStatementDialogComponent } from "app/common/remove-statement-dialog/remove-statement-dialog.component";

@Component({
  selector: "app-search-engine",
  templateUrl: "./search-engine.component.html",
  styleUrls: ["./search-engine.component.scss"]
})
export class SearchEngineComponent extends SubscribingComponent implements OnInit {
  value: string;
  oldValue: string;
  isBusiness: boolean;
  public isTaxAdvisor: boolean = false;
  loading = false;
  filteredStatements: Statement[];
  filteredBatches: any[];
  isOpened = false;

  constructor(
    private store: Store<State>,
    private statementService: StatementService,
    private paymentService: PaymentService,
    private dialog: MatDialog,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(getUserType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userType: string) => {
        if (userType) {
          this.isBusiness = userType === "business";
          this.isTaxAdvisor = userType === "tax_adviser";
        }
      });
  }

  getStatements() {
    this.loading = true;
    this.statementService
      .getStatements(this.value)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe((response: StatementsResponse) => (this.filteredStatements = response.statements));
  }

  getBatches() {
    this.loading = true;
    this.statementService
      .searchBatches(this.value)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe((response) => {
        this.filteredBatches = response.map((batch) => {
          batch.statements = batch.statements.map((el) => Statement.fromResponse(el, false));
          return batch;
        });
      });
  }

  getStatementsTaxAdviser() {
    this.loading = true;
    this.statementService
      .searchStatementsTaxAdviser(this.value)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe((response) => {
        this.filteredStatements = response.map((el) => Statement.fromResponse(el, false));
      });
  }

  downloadInvoice(statement: Statement) {
    statement.downloadingPdf = true;
    this.paymentService
      .downloadInvoice(statement.payment)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (statement.downloadingPdf = false))
      )
      .subscribe((file: Blob) => saveFile(file, `invoice-${statement.id}.pdf`));
  }

  showDecision(statement: Statement) {
    const dialogRef = this.dialog.open(DownloadFileDialogComponent);
    dialogRef.componentInstance.statement = statement;
  }

  search() {
    this.isOpened = true;
    if (!this.value || this.value === this.oldValue) {
      this.value = this.oldValue = "";
      return;
    }
    if (this.isBusiness) {
      this.getBatches();
    } else if (this.isTaxAdvisor) {
      this.getStatementsTaxAdviser();
    } else {
      this.getStatements();
    }
    this.oldValue = this.value;
  }

  editBatch(index) {
    const batchId = this.filteredBatches[index].id;
    let urlPath = "";
    if (this.filteredBatches[index].statements.length) {
      urlPath = this.filteredBatches[index].statements[0].isPaid ? "thank-you" : "client-details";
    } else {
      urlPath = "client-details";
    }
    this.closeResults();
    this.router.navigate([`new/batch/${batchId}/${urlPath}`]);
  }

  deleteBatchStatement({ statement, listIndex }: { statement: Statement; listIndex: number }, batchIndex) {
    const dialogRef = this.dialog.open(RemoveStatementDialogComponent);
    dialogRef.componentInstance.statement = statement;
    dialogRef.componentInstance.onSuccessCallback = () => {
      this.filteredBatches[batchIndex].statements.splice(listIndex, 1);
    };
  }

  hideEditButton(index) {
    if (this.filteredBatches[index].statements.length) {
      return this.filteredBatches[index].statements[0].isSent();
    }
    return false;
  }

  closeResults() {
    this.isOpened = false;
  }
}
