<div class="dialog-box">
    <div class="dialog-box__header">
        <button mat-icon-button mat-dialog-close tabindex="-1">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="dialog-box__content">
        <div class="terms-and-conditions" *ngIf="language === LANGUAGES.de">
            <h1 class="terms-and-conditions__title terms-and-conditions__title--main is-bold">Allgemeine
                Geschäftsbedingungen
                für Steuerberater, Steuerbevollmächtigte und Steuerberatungsgesellschaften</h1>
            <div class="sub-header is-bold">
                Stand: Januar 2022
            </div>
            <div class="sub-header2">
                Die folgenden „Allgemeinen Geschäftsbedingungen“ gelten für Verträge zwischen Steuerberatern,
                Steuerbevollmächtigten und Steuerberatungsgesellschaften (im Folgenden „Steuerberater“ genannt) und
                ihren Auftraggebern, soweit nicht etwas anderes ausdrücklich in Textform vereinbart oder gesetzlich
                zwingend vorgeschrieben ist.
            </div>

            <div class="terms-and-conditions__stage">
                <ol>
                    <li>
                        Umfang und Ausführung des Auftrags
                        <ol>
                            <li>
                                Für den Umfang der vom Steuerberater zu erbringenden Leistungen ist der erteilte Auftrag
                                maßgebend. Der Auftrag wird nach den Grundsätzen ordnungsgemäßer Berufsausübung unter
                                Beachtung der einschlägigen berufsrechtlichen Normen und der Berufspflichten (vgl.
                                StBerG, BOStB) ausgeführt.
                            </li>
                            <li>
                                Die Berücksichtigung ausländischen Rechts bedarf einer ausdrücklichen Vereinbarung in
                                Textform.
                            </li>
                            <li>
                                Ändert sich die Rechtslage nach abschließender Erledigung einer Angelegenheit, so ist
                                der Steuerberater nicht verpflichtet, den Auftraggeber auf die Änderung oder die sich
                                daraus ergebenden Folgen hinzuweisen.
                            </li>
                            <li>
                                Die Prüfung der Richtigkeit, Vollständigkeit und Ordnungsmäßigkeit der dem Steuerberater
                                übergebenen Unterlagen und Zahlen, insbesondere der Buchführung und Bilanz, gehört nur
                                zum Auftrag, wenn dies in Textform vereinbart ist. Der Steuerberater wird die vom
                                Auftraggeber gemachten Angaben, insbesondere Zahlenangaben, als richtig zu Grunde legen.
                                Soweit er offensichtliche Unrichtigkeiten feststellt, ist er verpflichtet, darauf
                                hinzuweisen.
                            </li>
                            <li>
                                Der Auftrag stellt keine Vollmacht für die Vertretung vor Behörden, Gerichten und
                                sonstigen Stellen dar. Sie ist gesondert zu erteilen. Ist wegen der Abwesenheit des
                                Auftraggebers eine Abstimmung mit diesem über die Einlegung von Rechtsbehelfen oder
                                Rechtsmitteln nicht möglich, ist der Steuerberater im Zweifel zu fristwahrenden
                                Handlungen berechtigt und verpflichtet.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Verschwiegenheitspflicht
                        <ol>
                            <li>
                                Der Steuerberater ist nach Maßgabe der Gesetze verpflichtet, über alle Tatsachen, die
                                ihm im Zusammenhang mit der Ausführung des Auftrags zur Kenntnis gelangen,
                                Stillschweigen zu bewahren, es sei denn, der Auftraggeber entbindet ihn von dieser
                                Verpflichtung. Die Verschwiegenheitspflicht besteht auch nach Beendigung des
                                Vertragsverhältnisses fort. Die Verschwiegenheitspflicht besteht im gleichen Umfang auch
                                für die Mitarbeiter des Steuerberaters.
                            </li>
                            <li>
                                Die Verschwiegenheitspflicht besteht nicht, soweit die Offenlegung zur Wahrung
                                berechtigter Interessen des Steuerberaters erforderlich ist. Der Steuerberater ist auch
                                insoweit von der Verschwiegenheitspflicht entbunden, als er nach den
                                Versicherungsbedingungen seiner Berufshaftpflichtversicherung zur Information und
                                Mitwirkung verpflichtet ist.
                            </li>
                            <li>
                                Gesetzliche Auskunfts- und Aussageverweigerungsrechte nach § 102 AO, § 53 StPO und § 383
                                ZPO bleiben unberührt.
                            </li>
                            <li>
                                Der Steuerberater ist von der Verschwiegenheitspflicht entbunden, soweit dies zur
                                Durchführung eines Zertifizierungsaudits in der Kanzlei des Steuerberaters erforderlich
                                ist und die insoweit tätigen Personen ihrerseits über ihre Verschwiegenheitspflicht
                                belehrt worden sind. Der Auftraggeber erklärt sich damit einverstanden, dass durch den
                                Zertifizierer/Auditor Einsicht in seine – vom Steuerberater angelegte und geführte –
                                Handakte genommen wird.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Mitwirkung Dritter
                        <p>
                            Der Steuerberater ist berechtigt, zur Ausführung des Auftrags Mitarbeiter und unter den
                            Voraussetzungen des § 62a StBerG auch externe Dienstleister (insbesondere datenverarbeitende
                            Unternehmen) heranzuziehen. Die Beteiligung fachkundiger Dritter zur Mandatsbearbeitung (z.
                            B. andere Steuerberater, Wirtschaftsprüfer, Rechtsanwälte) bedarf der Einwilligung und des
                            Auftrags des Auftraggebers. Der Steuerberater ist nicht berechtigt und verpflichtet, diese
                            Dritten ohne Auftrag des Auftraggebers hinzuzuziehen.
                        </p>
                    </li>
                    <li value="3">a Elektronische Kommunikation, Datenschutz
                        <ol>
                            <li>
                                Der Steuerberater ist berechtigt, personenbezogene Daten des Auftraggebers im Rahmen der
                                erteilten Aufträge maschinell zu erheben und in einer automatisierten Datei zu
                                verarbeiten oder einem Dienstleistungsrechenzentrum zur weiteren
                                Auftragsdatenverarbeitung zu übertragen.
                            </li>
                            <li>
                                Der Steuerberater ist berechtigt, in Erfüllung seiner Pflichten nach der DSGVO und dem
                                Bundesdatenschutzgesetz einen Beauftragten für den Datenschutz zu bestellen. Sofern
                                dieser Beauftragte für den Datenschutz nicht bereits nach Ziff. 2 Abs. 1 Satz 3 der
                                Verschwiegenheitspflicht unterliegt, hat der Steuerberater dafür Sorge zu tragen, dass
                                der Beauftragte für den Datenschutz sich mit Aufnahme seiner Tätigkeit zur Wahrung des
                                Datengeheimnisses verpflichtet.
                            </li>
                            <li>
                                Soweit der Auftraggeber mit dem Steuerberater die Kommunikation per Telefaxanschluss
                                oder über eine E-Mail-Adresse wünscht, hat der Auftraggeber sich an den Kosten zur
                                Einrichtung und Aufrechterhaltung des Einsatzes von Signaturverfahren und
                                Verschlüsselungsverfahren des Steuerberaters (bspw. zur Anschaffung und Einrichtung
                                notwendiger Soft- bzw. Hardware) zu beteiligen.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Mängelbeseitigung
                        <ol>
                            <li value="(1)">
                                Der Auftraggeber hat Anspruch auf Beseitigung etwaiger Mängel. Dem Steuerberater ist
                                Gelegenheit zur Nachbesserung zu geben. Der Auftraggeber hat das Recht – wenn und soweit
                                es sich bei dem Mandat um einen Dienstvertrag i. S. d. §§ 611, 675 BGB handelt –, die
                                Nachbesserung durch den Steuerberater abzulehnen, wenn das Mandat durch den Auftraggeber
                                beendet und der Mangel erst nach wirksamer Beendigung des Mandats festgestellt wird.
                            </li>
                            <li>
                                Beseitigt der Steuerberater die geltend gemachten Mängel nicht innerhalb einer
                                angemessenen Frist oder lehnt er die Mängelbeseitigung ab, so kann der Auftraggeber auf
                                Kosten des Steuerberaters die Mängel durch einen anderen Steuerberater beseitigen lassen
                                bzw. nach seiner Wahl Herabsetzung der Vergütung oder Rückgängigmachung des Vertrags
                                verlangen.
                            </li>
                            <li>
                                Offenbare Unrichtigkeiten (z. B. Schreibfehler, Rechenfehler) können vom Steuerberater
                                jederzeit, auch Dritten gegenüber, berichtigt werden. Sonstige Mängel darf der
                                Steuerberater Dritten gegenüber mit Einwilligung des Auftraggebers berichtigen. Die
                                Einwilligung ist nicht erforderlich, wenn berechtigte Interessen des Steuerberaters den
                                Interessen des Auftraggebers vorgehen.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Haftung
                        <ol>
                            <li>
                                Die Haftung des Steuerberaters und seiner Erfüllungsgehilfen für einen Schaden, der aus
                                einer oder – bei einheitlicher Schadensfolge – aus mehreren Pflichtverletzungen
                                anlässlich der Erfüllung eines Auftrags resultiert, wird auf 1.000.000,00 € (in Worten:
                                eine Million €) begrenzt. Die Haftungsbegrenzung bezieht sich allein auf Fahrlässigkeit.
                                Die Haftung für Vorsatz bleibt insoweit unberührt. Von der Haftungsbegrenzung
                                ausgenommen sind Haftungsansprüche für Schäden aus der Verletzung des Lebens, des
                                Körpers oder der Gesundheit. Die Haftungsbegrenzung gilt für die gesamte Tätigkeit des
                                Steuerberaters für den Auftraggeber, also insbesondere auch für eine Ausweitung des
                                Auftragsinhalts; einer erneuten Vereinbarung der Haftungsbegrenzung bedarf es insoweit
                                nicht. Die Haftungsbegrenzung gilt auch bei Bildung einer Sozietät/Partnerschaft und
                                Übernahme des Auftrags durch die Sozietät/Partnerschaft sowie für neu in die
                                Sozietät/Partnerschaft eintretende Sozien/Partner. Die Haftungsbegrenzung gilt ferner
                                auch gegenüber Dritten, soweit diese in den Schutzbereich des Mandatsverhältnisses
                                fallen; § 334 BGB wird insoweit ausdrücklich nicht abbedungen. Einzelvertragliche
                                Haftungsbegrenzungsvereinbarungen gehen dieser Regelung vor, lassen die Wirksamkeit
                                dieser Regelung jedoch – soweit nicht ausdrücklich anders geregelt – unberührt.
                            </li>
                            <li>
                                Die Haftungsbegrenzung gilt, wenn entsprechend hoher Versicherungsschutz bestanden hat,
                                rückwirkend von Beginn des Mandatsverhältnisses bzw. dem Zeitpunkt der Höherversicherung
                                an und erstreckt sich, wenn der Auftragsumfang nachträglich geändert oder erweitert
                                wird, auch auf diese Fälle.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Pflichten des Auftraggebers; unterlassene Mitwirkung und Annahmeverzug des Auftraggebers
                        <ol>
                            <li>
                                Der Auftraggeber ist zur Mitwirkung verpflichtet, soweit es zur ordnungsgemäßen
                                Erledigung des Auftrags erforderlich ist. Insbesondere hat er dem Steuerberater
                                unaufgefordert alle für die Ausführung des Auftrags notwendigen Unterlagen vollständig
                                und so rechtzeitig zu übergeben, dass dem Steuerberater eine angemessene
                                Bearbeitungszeit zur Verfügung steht. Entsprechendes gilt für die Unterrichtung über
                                alle Vorgänge und Umstände, die für die Ausführung des Auftrags von Bedeutung sein
                                können. Der Auftraggeber ist verpflichtet, alle schriftlichen und mündlichen
                                Mitteilungen des Steuerberaters zur Kenntnis zu nehmen und bei Zweifelsfragen
                                Rücksprache zu halten.
                            </li>
                            <li>
                                Der Auftraggeber hat alles zu unterlassen, was die Unabhängigkeit des Steuerberaters
                                oder seiner Erfüllungsgehilfen beeinträchtigen könnte.
                            </li>
                            <li>
                                Der Auftraggeber verpflichtet sich, Arbeitsergebnisse des Steuerberaters nur mit dessen
                                Einwilligung weiterzugeben, soweit sich nicht bereits aus dem Auftragsinhalt die
                                Einwilligung zur Weitergabe an einen bestimmten Dritten ergibt.
                            </li>
                            <li>
                                Setzt der Steuerberater beim Auftraggeber in dessen Räumen Datenverarbeitungsprogramme
                                ein, so ist der Auftraggeber verpflichtet, den Hinweisen des Steuerberaters zur
                                Installation und Anwendung der Programme nachzukommen. Des Weiteren ist der Auftraggeber
                                verpflichtet, die Programme nur in dem vom Steuerberater vorgeschriebenen Umfang zu
                                nutzen, und er ist auch nur in dem Umfang zur Nutzung berechtigt. Der Auftraggeber darf
                                die Programme nicht verbreiten. Der Steuerberater bleibt Inhaber der Nutzungsrechte. Der
                                Auftraggeber hat alles zu unterlassen, was der Ausübung der Nutzungsrechte an den
                                Programmen durch den Steuerberater entgegensteht.
                            </li>
                            <li>
                                Unterlässt der Auftraggeber eine ihm nach Ziff. 6 Abs. 1 bis 4 oder anderweitig
                                obliegende Mitwirkung oder kommt er mit der Annahme der vom Steuerberater angebotenen
                                Leistung in Verzug, so ist der Steuerberater berechtigt, den Vertrag fristlos zu
                                kündigen (vgl. Ziff. 9 Abs. 3). Unberührt bleibt der Anspruch des Steuerberaters auf
                                Ersatz der ihm durch den Verzug oder die unterlassene Mitwirkung des Auftraggebers
                                entstandenen Mehraufwendungen sowie des verursachten Schadens, und zwar auch dann, wenn
                                der Steuerberater von dem Kündigungsrecht keinen Gebrauch macht.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Urheberrechtsschutz
                        <p>Die Leistungen des Steuerberaters stellen dessen geistiges Eigentum dar. Sie sind
                            urheberrechtlich geschützt. Eine Weitergabe von Arbeitsergebnissen außerhalb der
                            bestimmungsgemäßen Verwendung ist nur mit vorheriger Zustimmung des Steuerberaters in
                            Textform zulässig.</p>
                    </li>
                    <li>
                        Vergütung, Vorschuss und Aufrechnung
                        <ol>
                            <li>
                                Die Vergütung (Gebühren und Auslagenersatz) des Steuerberaters für seine Berufstätigkeit
                                nach § 33 StBerG bemisst sich nach der Steuerberatervergütungsverordnung (StBVV). Eine
                                höhere oder niedrigere als die gesetzliche Vergütung kann in Textform vereinbart werden.
                                Die Vereinbarung einer niedrigeren Vergütung ist nur in außergerichtlichen
                                Angelegenheiten zulässig. Sie muss in einem angemessenen Verhältnis zu der Leistung, der
                                Verantwortung und dem Haftungsrisiko des Steuerberaters stehen (§ 4 Abs. 3 StBVV).
                            </li>
                            <li>
                                Für Tätigkeiten, die in der Vergütungsverordnung keine Regelung erfahren (z. B. § 57
                                Abs. 3 Nrn. 2 und 3 StBerG), gilt die vereinbarte Vergütung, anderenfalls die für diese
                                Tätigkeit vorgesehene gesetzliche Vergütung, ansonsten die übliche Vergütung (§§ 612
                                Abs. 2 und 632 Abs. 2 BGB).
                            </li>
                            <li>
                                Eine Aufrechnung gegenüber einem Vergütungsanspruch des Steuerberaters ist nur mit
                                unbestrittenen oder rechtskräftig festgestellten Forderungen zulässig.
                            </li>
                            <li>
                                Für bereits entstandene und voraussichtlich entstehende Gebühren und Auslagen kann der
                                Steuerberater einen Vorschuss fordern. Wird der eingeforderte Vorschuss nicht gezahlt,
                                kann der Steuerberater nach vorheriger Ankündigung seine weitere Tätigkeit für den
                                Auftraggeber einstellen, bis der Vorschuss eingeht. Der Steuerberater ist verpflichtet,
                                seine Absicht, die Tätigkeit einzustellen, dem Auftraggeber rechtzeitig bekanntzugeben,
                                wenn dem Auftraggeber Nachteile aus einer Einstellung der Tätigkeit erwachsen können.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Beendigung des Vertrags
                        <ol>
                            <li>
                                Der Vertrag endet mit Erfüllung der vereinbarten Leistungen, durch Ablauf der
                                vereinbarten Laufzeit oder durch Kündigung. Der Vertrag endet nicht durch den Tod, durch
                                den Eintritt der Geschäftsunfähigkeit des Auftraggebers oder im Falle einer Gesellschaft
                                durch deren Auflösung.
                            </li>
                            <li>
                                Der Vertrag kann – wenn und soweit er einen Dienstvertrag i. S. d. §§ 611, 675 BGB
                                darstellt – von jedem Vertragspartner außerordentlich gekündigt werden, es sei denn, es
                                handelt sich um ein Dienstverhältnis mit festen Bezügen, § 627 Abs. 1 BGB; die Kündigung
                                hat in Textform zu erfolgen. Soweit im Einzelfall hiervon abgewichen werden soll, bedarf
                                es einer Vereinbarung, die zwischen Steuerberater und Auftraggeber auszuhandeln ist.
                            </li>
                            <li>
                                Bei Kündigung des Vertrags durch den Steuerberater sind zur Vermeidung von
                                Rechtsnachteilen des Auftraggebers in jedem Fall noch diejenigen Handlungen durch den
                                Steuerberater vorzunehmen, die zumutbar sind und keinen Aufschub dulden (z. B.
                                Fristverlängerungsantrag bei drohendem Fristablauf).
                            </li>
                            <li>
                                Der Steuerberater ist verpflichtet, dem Auftraggeber alles, was er zur Ausführung des
                                Auftrags erhält oder erhalten hat und was er aus der Geschäftsbesorgung erlangt,
                                herauszugeben. Außerdem ist der Steuerberater verpflichtet, dem Auftraggeber auf
                                Verlangen über den Stand der Angelegenheit Auskunft zu erteilen und Rechenschaft
                                abzulegen.
                            </li>
                            <li>
                                Mit Beendigung des Vertrags hat der Auftraggeber dem Steuerberater die beim Auftraggeber
                                zur Ausführung des Auftrags eingesetzten Datenverarbeitungsprogramme einschließlich
                                angefertigter Kopien sowie sonstige Programmunterlagen unverzüglich herauszugeben bzw.
                                sie von der Festplatte zu löschen.
                            </li>
                            <li>
                                Nach Beendigung des Auftragsverhältnisses sind die Unterlagen beim Steuerberater
                                abzuholen.
                            </li>
                            <li>
                                Endet der Auftrag vor seiner vollständigen Ausführung, so richtet sich der
                                Vergütungsanspruch des Steuerberaters nach dem Gesetz. Soweit im Einzelfall hiervon
                                abgewichen werden soll, bedarf es einer gesonderten Vereinbarung in Textform.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Aufbewahrung, Herausgabe und Zurückbehaltungsrecht in Bezug auf Arbeitsergebnisse und Unterlagen
                        <ol>
                            <li>
                                Der Steuerberater hat die Handakten für die Dauer von zehn Jahren nach Beendigung des
                                Auftrags aufzubewahren. Diese Verpflichtung erlischt jedoch schon vor Beendigung dieses
                                Zeitraums, wenn der Steuerberater den Auftraggeber aufgefordert hat, die Handakten in
                                Empfang zu nehmen, und der Auftraggeber dieser Aufforderung binnen sechs Monaten,
                                nachdem er sie erhalten hat, nicht nachgekommen ist.
                            </li>
                            <li>
                                Handakten i. S. v. Abs. 1 sind nur die Schriftstücke, die der Steuerberater aus Anlass
                                seiner beruflichen Tätigkeit von dem Auftraggeber oder für ihn erhalten hat, nicht aber
                                der Briefwechsel zwischen dem Steuerberater und seinem Auftraggeber und für die
                                Schriftstücke, die dieser bereits in Urschrift oder Abschrift erhalten hat, sowie für
                                die zu internen Zwecken gefertigten Arbeitspapiere (§ 66 Abs. 3 StBerG).
                            </li>
                            <li>
                                Auf Anforderung des Auftraggebers, spätestens aber nach Beendigung des Auftrags, hat der
                                Steuerberater dem Auftraggeber die Handakten innerhalb einer angemessenen Frist
                                herauszugeben. Der Steuerberater kann von Unterlagen, die er an den Auftraggeber
                                zurückgibt, Abschriften oder Fotokopien anfertigen und zurückbehalten.
                            </li>
                            <li>
                                Der Steuerberater kann die Herausgabe der Handakten verweigern, bis er wegen seiner
                                Gebühren und Auslagen befriedigt ist. Dies gilt nicht, soweit die Vorenthaltung der
                                Handakten und der einzelnen Schriftstücke nach den Umständen unangemessen wäre (§ 66
                                Abs. 2 Satz 2 StBerG).
                            </li>
                        </ol>
                    </li>
                    <li>
                        Sonstiges
                        <p>
                            Für den Auftrag, seine Ausführung und die sich hieraus ergebenden Ansprüche gilt
                            ausschließlich deutsches Recht. Erfüllungsort ist der Wohnsitz des Auftraggebers, soweit er
                            nicht Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches
                            Sondervermögen ist, ansonsten die berufliche Niederlassung des Steuerberaters. Der
                            Steuerberater ist – nicht – bereit, an einem Streitbeilegungsverfahren vor einer
                            Verbraucherschlichtungsstelle teilzunehmen (§§ 36, 37 VSBG).
                        </p>
                    </li>
                    <li>
                        Wirksamkeit bei Teilnichtigkeit
                        <p>
                            Falls einzelne Bestimmungen dieser Geschäftsbedingungen unwirksam sein oder werden sollten,
                            wird die Wirksamkeit der übrigen Bestimmungen dadurch nicht berührt.
                        </p>
                    </li>
                </ol>
            </div>
        </div>

        <ng-container *ngIf="language === LANGUAGES.en">
        <div class="terms-and-conditions">
            <h1 class="terms-and-conditions__title terms-and-conditions__title--main is-bold">
                General terms and conditions for German tax advisors (Steuerberater, Steuerbevollmächtigte) and firms of
                tax advisors (Steuerberatungsgesellschaften)
            </h1>

            <div class="sub-header is-bold">
                as of January 2022
            </div>

            <div class="sub-header2">
                These “general terms and conditions” shall govern contracts between German qualified tax advisors
                (Steuerberater, Steuerbevollmächtigte) as well as firms of tax advisors (Steuerberatungsgesellschaften)
                (hereinafter collectively referred to as the ‘Tax Advisors’, and each of them a ‘Tax Advisor’) and their
                clients (Auftraggeber), unless otherwise expressly agreed in text form (Textform) or prescribed by law.
            </div>
        </div>

        <div class="terms-and-conditions__stage">
            <ol>
                <li>
                    Scope and execution of the engagement
                    <ol>
                        <li>
                            The scope of the services to be rendered by the Tax Advisor shall be governed by the
                            specific engagement. The engagement shall be executed in accordance with the principles
                            of proper professional practice and in compliance with the relevant rules of
                            professional conduct and professional obligations (cf. German Act Regulating the
                            Profession of Tax Advisors [Steuerberatungsgesetz – StBerG] [hereinafter “StBerG”],
                            German Professional Code of Conduct for Tax Advisors [Berufsordnung der Steuerberater –
                            BOStB] ).
                        </li>
                        <li>
                            Foreign law shall only be taken into account if this has been expressly agreed in text
                            form.
                        </li>
                        <li>
                            In the event that the legal position changes after a matter has been conclusively
                            completed, the Tax Advisor shall not be under any obligation to alert the client to such
                            change or the resulting implications.
                        </li>
                        <li>
                            The review of the documents and figures provided to the Tax Advisor, in particular the
                            accounts and balance sheet, with regard to accuracy, completeness and conformity with
                            applicable rules shall not form part of the engagement unless otherwise expressly agreed
                            in text form. The Tax Advisor will assume that the information provided by the client,
                            in particular the figures, is correct and will use it as a basis for his/her work. To
                            the extent that he/she detects any evident inaccuracies, the Tax Advisor shall be
                            obliged to point them out.
                        </li>
                        <li>
                            The engagement shall not be deemed to constitute an authorization to represent the
                            client before public authorities, courts and other bodies. Such authorization would need
                            to be granted separately. Where, owing to the client’s absence, it proves impossible to
                            coordinate with him/her as to the filing of legal remedies, the Tax Advisor shall be
                            deemed, in case of doubt, to be both authorized and obliged to take action with a view
                            to meeting a deadline.
                        </li>
                    </ol>
                </li>
                <li>
                    Duty of confidentiality
                    <ol>
                        <li>
                            In accordance with the law, the Tax Advisor shall be under a duty to maintain
                            confidentiality with regard to all facts that have come to his/her attention in
                            connection with the execution of the engagement unless the client releases him/her from
                            this duty. The duty of confidentiality shall continue even beyond a termination of the
                            contractual relationship. The duty of confidentiality shall apply, to the same extent,
                            to the Tax Advisor’s staff.
                        </li>
                        <li>
                            The duty of confidentiality shall not apply to the extent that a disclosure is necessary
                            in order to protect the Tax Advisor’s legitimate interests. Furthermore, the Tax Advisor
                            is hereby released from the duty of confidentiality to the extent that, under the terms
                            and conditions of his/her professional liability insurance, he/ she has a duty to
                            provide information and cooperate.
                        </li>
                        <li>
                            The foregoing shall not affect any statutory rights to refuse to provide information or
                            to refuse to testify under sect. 102 German General Tax Code (Abgabenordnung – AO),
                            sect. 53 German Code of Criminal Procedure (Strafprozessordnung – StPO) and sect. 383
                            German Code of Civil Procedure (Zivilprozessordnung – ZPO).
                        </li>
                        <li>
                            The Tax Advisor is hereby released from the duty of confidentiality to the extent that
                            (i) this is necessary for purposes of carrying out a certification audit in the Tax
                            Advisor’s firm and (ii) the individuals who are acting in this regard, for their part,
                            have been instructed as to their duty of confidentiality. The client hereby agrees that
                            the person carrying out the certification/audit may inspect the client file which was
                            created and is being maintained by the Tax Advisor.
                        </li>
                    </ol>
                </li>
                <li>
                    Involvement of third parties
                    <p>
                        The Tax Advisor shall be entitled to involve staff and, subject to the prerequisites of
                        sect. 62a StBerG, also external service providers (in particular data-processing companies)
                        for purposes of carrying out the engagement. The bringing-in of third-party experts (e.g.
                        other Tax Advisors, auditors, German qualified attorneys [ Rechtsanwälte]) shall require
                        consent and instruction on the part of the client. Without having been instructed by the
                        client, the Tax Advisor shall be neither entitled nor obliged to bring in such third
                        parties.
                    </p>
                </li>
                <li value="3">
                    a. Electronic communication, data protection
                    <ol>
                        <li>
                            In the context of the engagements, the Tax Advisor shall be entitled to electronically
                            collect personal data of the client and to process such data in an automated file or to
                            transmit such data to a service computer center for further processing of the data
                            related to the engagement.
                        </li>
                        <li>
                            In order to satisfy his/her obligations under the EU General Data Protection Regulation
                            (hereinafter “GDPR”) and the German Federal Data Protection Act (Bundesdatenschutzgesetz
                            – BDSG), the Tax Advisor shall be entitled to appoint a data-protection officer. Unless
                            this data-protection officer is already subject to a duty of confidentiality under
                            clause 2(1) sent. 3 above, the Tax Advisor shall ensure that the data-protection
                            officer, upon taking up his/her activity, shall undertake to maintain data secrecy.
                        </li>
                        <li>
                            To the extent that the client wants to communicate with the Tax Advisor via a fax line
                            or an e‑mail address, the client must share in the costs for setting up and maintaining
                            the use of signature procedures and encryption procedures of the Tax Advisor (e.g. for
                            acquiring and setting up any necessary software and/or hardware).
                        </li>
                    </ol>
                </li>
                <li>
                    Remedying of deficiencies
                    <ol>
                        <li>
                            The client shall have a right to demand that any deficiencies be remedied. The Tax
                            Advisor must be afforded an opportunity to take remedial action. If and to the extent
                            that the engagement constitutes a contract for services (Dienstvertrag) within the
                            meaning of sects. 611, 675 German Civil Code (Bürgerliches Gesetzbuch – BGB)
                            (hereinafter “BGB”), the client may refuse any remedial action by the Tax Advisor if the
                            engagement is terminated by the client and the deficiency is detected only after the
                            engagement has been validly terminated.
                        </li>
                        <li>
                            Should the Tax Advisor fail to remedy the asserted deficiencies within a reasonable
                            period or refuse to remedy the deficiencies, then the client may, at the Tax Advisor’s
                            expense, have the deficiencies remedied by another Tax Advisor and/or — at the client’s
                            choice — demand a reduction of the fees or rescission of the contract.
                        </li>
                        <li>
                            The Tax Advisor may at any time, also vis-à-vis third parties, correct obvious
                            inaccuracies (e.g. clerical errors, or errors in calculation). Other deficiencies may be
                            corrected by the Tax Advisor vis-à-vis third parties subject to the client’s consent.
                            Such consent shall not be required where the Tax Advisor’s legitimate interests take
                            precedence over the client’s interests.
                        </li>
                    </ol>
                </li>
                <li>
                    Liability
                    <ol>
                        <li>
                            The liability of the Tax Advisor and his/her ‘persons employed in performing a
                            contractual obligation for whom the Tax Advisor is vicariously liable’
                            [Erfüllungsgehilfen] [hereinafter the ‘Vicarious Agents’] for any loss/damage resulting
                            from one breach of duty or — in the context of a uniform injurious effect (einheitliche
                            Schadensfolge) — from several breaches of duty on the occasion of executing an
                            engagement shall be capped at EUR 1.000.000,00 (in words: on million euros). The
                            limitation of liability shall apply in relation to negligence only; liability for intent
                            shall not be subject to such limitation. Liability claims in relation to any loss/damage
                            arising from injuries to life, body or health shall be excluded from this limitation of
                            liability. The limitation of liability shall apply to the Tax Advisor’s entire activity
                            for the client, i.e. also, in particular, to an extension to the scope of the
                            engagement; in this regard, there shall be no need for agreeing the limitation of
                            liability again. The limitation of liability shall also apply in the case of the
                            establishment of a joint practice (Sozietät) / partnership company (Partnerschaft) and
                            assumption of the engagement by the joint practice / partnership company as well as for
                            partners who join the joint practice / partnership company. Furthermore, the limitation
                            of liability shall also apply vis-à-vis third parties to the extent that these fall
                            within the scope of protection of the engagement; in this regard, sect. 334 BGB is
                            expressly not waived. Any agreements, contained in individual contracts, providing for a
                            limitation of liability shall take precedence over this provision but — unless otherwise
                            expressly stipulated — shall not affect the validity of this provision.
                        </li>
                        <li>
                            Provided there was a sufficiently high insurance cover in place, the limitation of
                            liability shall apply retroactively from the beginning of the engagement or, as the case
                            may be, from the point of taking out higher insurance cover. If the scope of the
                            engagement is subsequently modified or expanded, then the limitation of liability shall
                            also extend to these cases.
                        </li>
                    </ol>
                </li>
                <li>
                    Duties on the part of the client; client’s failure to cooperate and client’s default of
                    acceptance
                    <ol>
                        <li>
                            The client shall be obliged to cooperate to the extent that this is necessary in order
                            for the engagement to be duly executed. In particular, he/she shall submit to the Tax
                            Advisor, unprompted, a complete set of all documents necessary in order to execute the
                            engagement; such submission shall occur in such a timely manner as to afford the Tax
                            Advisor a reasonable processing time. The same shall apply with regard to briefings
                            about all events and circumstances which may be of importance for purposes of executing
                            the engagement. The client shall be obliged to take note of all written and oral
                            communications issued by the Tax Advisor and to consult him/her when in doubt.
                        </li>
                        <li>
                            The client shall refrain from anything that may prejudice the independence of the Tax
                            Advisor or the Tax Advisor’s Vicarious Agents.
                        </li>
                        <li>
                            The client hereby undertakes to pass on the results of the Tax Advisor’s work only with
                            the Tax Advisor’s consent unless the consent to such results being passed on to a
                            specific third party already flows from the content of the engagement.
                        </li>
                        <li>
                            Should the Tax Advisor employ data-processing programs at the client’s premises, then
                            the client shall be obliged to comply with the instructions by the Tax Advisor with
                            regard to installation and application of such programs. In addition, the client shall
                            be obliged to only use the programs within the scope prescribed by the Tax Advisor,
                            which shall also be the scope of use to which the client is entitled. The client must
                            not disseminate the programs. The Tax Advisor shall remain the owner of the rights of
                            use. The client shall refrain from anything which constitutes an obstacle to the
                            exercise by the Tax Advisor of the rights of use with regard to the programs.
                        </li>
                        <li>
                            Should the client fail to comply with a duty to cooperate incumbent on him/her under
                            clause 6(1) – (4) or as provided for elsewhere or be in default of acceptance in
                            relation to the services tendered by the Tax Advisor, then the Tax Advisor shall have
                            the right to terminate the contract without notice (cf. clause 9(3)). This shall not
                            affect the Tax Advisor’s claim to be compensated for the additional expenses incurred by
                            him/her owing to the client’s default or failure to cooperate as well as for any
                            loss/damage caused, even in the event that the Tax Advisor opts not to exercise his/her
                            right of termination.
                        </li>
                    </ol>
                </li>
                <li>
                    Copyright protection
                    <p>
                        The services rendered by the Tax Advisor constitute his/her intellectual property. They are
                        protected by copyright. Beyond their intended use, work results may be passed on only upon
                        prior written consent by the Tax Advisor.
                    </p>
                </li>
                <li>
                    Fees, advance payment and offsetting
                    <ol>
                        <li>
                            The Tax Advisor’s fees (professional fees and reimbursement of out-of-pocket expenses)
                            for his/her professional activity in accordance with sect. 33 StBerG shall be determined
                            pursuant to the German Regulation on Tax Advisors’ Fees
                            (Steuerberatervergütungsverordnung – StBVV) (hereinafter “StBVV”). Fees above or below
                            the statutory fees may be agreed in text form. Agreeing fees below the statutory fees is
                            permissible in out-of-court matters only. Such lower fees must bear an adequate relation
                            to the services, responsibility and liability risk of the Tax Advisor (sect. 4(3)
                            StBVV).
                        </li>
                        <li>
                            For activities not dealt with in the Regulation on Fees (e.g. sect. 57(3) nos. 2 and 3
                            StBerG), the applicable fees shall be those agreed; otherwise, the fees determined by
                            statute for such activity; or else the customary fees (sects. 612(2) and 632(2) BGB).
                        </li>
                        <li>
                            Only claims that are undisputed or have been determined with final and absolute effect
                            (rechtskräftig) may be set off against a fee claim of the Tax Advisor.
                        </li>
                        <li>
                            The Tax Advisor shall be entitled to request an advance payment for professional fees
                            and out-of-pocket expenses already incurred or expected to be incurred. In the event
                            that the requested advance payment is not made, the Tax Advisor may, upon prior notice,
                            cease working for the client until the advance payment is received. Where a cessation of
                            work may adversely affect the client, the Tax Advisor shall be obliged to notify the
                            client, in a timely manner, of the Tax Advisor’s intention to cease working.
                        </li>
                    </ol>
                </li>
                <li>
                    Termination of the contract
                    <ol>
                        <li>
                            The contract shall terminate upon completion of the services, upon expiry of the agreed
                            term, or by giving notice. The contract shall not terminate upon the client’s death or
                            upon the client becoming legally incapacitated or, in the case of a company, upon the
                            company’s dissolution.
                        </li>
                        <li>
                            If and to the extent that the contract constitutes a contract for services within the
                            meaning of sects. 611, 675 BGB, either party may terminate the contract for cause
                            (außerordentlich) except in the case of a service relationship with fixed earnings
                            (Dienstverhältnis mit festen Bezügen), sect. 627(1) BGB; notice must be given in text
                            form. Any deviation from the foregoing in individual cases shall require an agreement to
                            be negotiated between the Tax Advisor and the client.
                        </li>
                        <li>
                            In order to prevent legal disadvantages for the client, upon termination of the contract
                            by the Tax Advisor the Tax Advisor must, in any event, still take those actions which
                            may reasonably be expected of him/her and which ought not to be postponed (e.g.
                            application for the extension of a deadline which is about to expire).
                        </li>
                        <li>
                            The Tax Advisor shall be obliged to hand over to the client anything the Tax Advisor
                            receives or has received for purposes of executing the engagement and anything the Tax
                            Advisor obtains in the context of the management of the affairs of another
                            (Geschäftsbesorgung). In addition, the Tax Advisor shall be obliged, upon request, to
                            provide the client with a progress report and to render account for the Tax Advisor’s
                            activities.
                        </li>
                        <li>
                            Upon termination of the contract, the client must promptly hand over to the Tax Advisor
                            the data-processing programs employed at the client’s office for purposes of executing
                            the engagement, including any copies created, as well as any other program documents,
                            and/or delete them from the hard drive.
                        </li>
                        <li>
                            Upon termination of the engagement, the documents must be collected from the Tax
                            Advisor.
                        </li>
                        <li>
                            In the event that the engagement terminates before it has been completed, the Tax
                            Advisor’s fee claim shall be governed by statute. Any deviation from the foregoing in
                            individual cases shall require a separate agreement in text form.
                        </li>
                    </ol>
                </li>
                <li>
                    Storage, delivery and right of retention with regard to work results and documents
                    <ol>
                        <li>
                            The Tax Advisor must store the client files for a period of ten years after the
                            engagement has terminated. However, this obligation shall expire before the above period
                            has elapsed if the Tax Advisor has asked the client to take receipt of the client files
                            and the client has failed to comply with such request within six months of having
                            received it.
                        </li>
                        <li>
                            ‘Client files’ within the meaning of para. 1 shall only include such documents as have
                            been obtained by the Tax Advisor, on the occasion of his/her professional activity, from
                            or for the client; by contrast, they shall not include the correspondence between the
                            Tax Advisor and his/her client and the documents which the client has already received
                            in the original or as a copy as well as the working papers produced for internal
                            purposes (sect. 66(3) StBerG).
                        </li>
                        <li>
                            At the request of the client, but no later than after termination of the engagement, the
                            Tax Advisor shall hand over the client files to the client within a reasonable period.
                            The Tax Advisor may create and retain copies or photocopies of documents which he/she
                            returns to the client.
                        </li>
                        <li>
                            The Tax Advisor may refuse to hand over the client files until his/her fees and
                            out-of-pocket expenses have been settled. This shall not apply to the extent that
                            withholding the client files and the individual documents would be unreasonable under
                            the circumstances (sect. 66(2) sent. 2 StBerG).
                        </li>
                    </ol>
                </li>
                <li>
                    Miscellaneous
                    <p>
                        The engagement, its execution and the claims resulting therefrom shall be exclusively
                        governed by German law. The place of performance shall be the client’s place of residence
                        unless he/she is a merchant (Kaufmann), legal person under public law, or special fund
                        (Sondervermögen) under public law; otherwise, the place of performance shall be the
                        professional establishment of the Tax Advisor. The Tax Advisor is — not — prepared to
                        participate in dispute-resolution proceedings before a consumer conciliation body (sects.
                        36, 37 German Act on Alternative Dispute Resolution in Consumer Matters [Gesetz über die
                        alternative Streitbeilegung in Verbrauchersachen – VSBG]).
                    </p>
                </li>
                <li>
                    Validity in the event of partial nullity
                    <p>
                        Should individual provisions of these terms and conditions of engagement be or become
                        invalid, then this shall not affect the validity of the remaining provisions.
                    </p>
                </li>
            </ol>
        </div>
        </ng-container>

        <div class="terms-and-conditions" *ngIf="language === LANGUAGES.pl">
            <h1 class="terms-and-conditions__title terms-and-conditions__title--main is-bold">Allgemeine
                Geschäftsbedingungen
                für Steuerberater, Steuerbevollmächtigte und Steuerberatungsgesellschaften</h1>
            <div class="sub-header is-bold">
                Stand: Januar 2022
            </div>
            <div class="sub-header2">
                Die folgenden „Allgemeinen Geschäftsbedingungen“ gelten für Verträge zwischen Steuerberatern,
                Steuerbevollmächtigten und Steuerberatungsgesellschaften (im Folgenden „Steuerberater“ genannt) und
                ihren Auftraggebern, soweit nicht etwas anderes ausdrücklich in Textform vereinbart oder gesetzlich
                zwingend vorgeschrieben ist.
            </div>

            <div class="terms-and-conditions__stage">
                <ol>
                    <li>
                        Umfang und Ausführung des Auftrags
                        <ol>
                            <li>
                                Für den Umfang der vom Steuerberater zu erbringenden Leistungen ist der erteilte Auftrag
                                maßgebend. Der Auftrag wird nach den Grundsätzen ordnungsgemäßer Berufsausübung unter
                                Beachtung der einschlägigen berufsrechtlichen Normen und der Berufspflichten (vgl.
                                StBerG, BOStB) ausgeführt.
                            </li>
                            <li>
                                Die Berücksichtigung ausländischen Rechts bedarf einer ausdrücklichen Vereinbarung in
                                Textform.
                            </li>
                            <li>
                                Ändert sich die Rechtslage nach abschließender Erledigung einer Angelegenheit, so ist
                                der Steuerberater nicht verpflichtet, den Auftraggeber auf die Änderung oder die sich
                                daraus ergebenden Folgen hinzuweisen.
                            </li>
                            <li>
                                Die Prüfung der Richtigkeit, Vollständigkeit und Ordnungsmäßigkeit der dem Steuerberater
                                übergebenen Unterlagen und Zahlen, insbesondere der Buchführung und Bilanz, gehört nur
                                zum Auftrag, wenn dies in Textform vereinbart ist. Der Steuerberater wird die vom
                                Auftraggeber gemachten Angaben, insbesondere Zahlenangaben, als richtig zu Grunde legen.
                                Soweit er offensichtliche Unrichtigkeiten feststellt, ist er verpflichtet, darauf
                                hinzuweisen.
                            </li>
                            <li>
                                Der Auftrag stellt keine Vollmacht für die Vertretung vor Behörden, Gerichten und
                                sonstigen Stellen dar. Sie ist gesondert zu erteilen. Ist wegen der Abwesenheit des
                                Auftraggebers eine Abstimmung mit diesem über die Einlegung von Rechtsbehelfen oder
                                Rechtsmitteln nicht möglich, ist der Steuerberater im Zweifel zu fristwahrenden
                                Handlungen berechtigt und verpflichtet.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Verschwiegenheitspflicht
                        <ol>
                            <li>
                                Der Steuerberater ist nach Maßgabe der Gesetze verpflichtet, über alle Tatsachen, die
                                ihm im Zusammenhang mit der Ausführung des Auftrags zur Kenntnis gelangen,
                                Stillschweigen zu bewahren, es sei denn, der Auftraggeber entbindet ihn von dieser
                                Verpflichtung. Die Verschwiegenheitspflicht besteht auch nach Beendigung des
                                Vertragsverhältnisses fort. Die Verschwiegenheitspflicht besteht im gleichen Umfang auch
                                für die Mitarbeiter des Steuerberaters.
                            </li>
                            <li>
                                Die Verschwiegenheitspflicht besteht nicht, soweit die Offenlegung zur Wahrung
                                berechtigter Interessen des Steuerberaters erforderlich ist. Der Steuerberater ist auch
                                insoweit von der Verschwiegenheitspflicht entbunden, als er nach den
                                Versicherungsbedingungen seiner Berufshaftpflichtversicherung zur Information und
                                Mitwirkung verpflichtet ist.
                            </li>
                            <li>
                                Gesetzliche Auskunfts- und Aussageverweigerungsrechte nach § 102 AO, § 53 StPO und § 383
                                ZPO bleiben unberührt.
                            </li>
                            <li>
                                Der Steuerberater ist von der Verschwiegenheitspflicht entbunden, soweit dies zur
                                Durchführung eines Zertifizierungsaudits in der Kanzlei des Steuerberaters erforderlich
                                ist und die insoweit tätigen Personen ihrerseits über ihre Verschwiegenheitspflicht
                                belehrt worden sind. Der Auftraggeber erklärt sich damit einverstanden, dass durch den
                                Zertifizierer/Auditor Einsicht in seine – vom Steuerberater angelegte und geführte –
                                Handakte genommen wird.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Mitwirkung Dritter
                        <p>
                            Der Steuerberater ist berechtigt, zur Ausführung des Auftrags Mitarbeiter und unter den
                            Voraussetzungen des § 62a StBerG auch externe Dienstleister (insbesondere datenverarbeitende
                            Unternehmen) heranzuziehen. Die Beteiligung fachkundiger Dritter zur Mandatsbearbeitung (z.
                            B. andere Steuerberater, Wirtschaftsprüfer, Rechtsanwälte) bedarf der Einwilligung und des
                            Auftrags des Auftraggebers. Der Steuerberater ist nicht berechtigt und verpflichtet, diese
                            Dritten ohne Auftrag des Auftraggebers hinzuzuziehen.
                        </p>
                    </li>
                    <li value="3">a Elektronische Kommunikation, Datenschutz
                        <ol>
                            <li>
                                Der Steuerberater ist berechtigt, personenbezogene Daten des Auftraggebers im Rahmen der
                                erteilten Aufträge maschinell zu erheben und in einer automatisierten Datei zu
                                verarbeiten oder einem Dienstleistungsrechenzentrum zur weiteren
                                Auftragsdatenverarbeitung zu übertragen.
                            </li>
                            <li>
                                Der Steuerberater ist berechtigt, in Erfüllung seiner Pflichten nach der DSGVO und dem
                                Bundesdatenschutzgesetz einen Beauftragten für den Datenschutz zu bestellen. Sofern
                                dieser Beauftragte für den Datenschutz nicht bereits nach Ziff. 2 Abs. 1 Satz 3 der
                                Verschwiegenheitspflicht unterliegt, hat der Steuerberater dafür Sorge zu tragen, dass
                                der Beauftragte für den Datenschutz sich mit Aufnahme seiner Tätigkeit zur Wahrung des
                                Datengeheimnisses verpflichtet.
                            </li>
                            <li>
                                Soweit der Auftraggeber mit dem Steuerberater die Kommunikation per Telefaxanschluss
                                oder über eine E-Mail-Adresse wünscht, hat der Auftraggeber sich an den Kosten zur
                                Einrichtung und Aufrechterhaltung des Einsatzes von Signaturverfahren und
                                Verschlüsselungsverfahren des Steuerberaters (bspw. zur Anschaffung und Einrichtung
                                notwendiger Soft- bzw. Hardware) zu beteiligen.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Mängelbeseitigung
                        <ol>
                            <li value="(1)">
                                Der Auftraggeber hat Anspruch auf Beseitigung etwaiger Mängel. Dem Steuerberater ist
                                Gelegenheit zur Nachbesserung zu geben. Der Auftraggeber hat das Recht – wenn und soweit
                                es sich bei dem Mandat um einen Dienstvertrag i. S. d. §§ 611, 675 BGB handelt –, die
                                Nachbesserung durch den Steuerberater abzulehnen, wenn das Mandat durch den Auftraggeber
                                beendet und der Mangel erst nach wirksamer Beendigung des Mandats festgestellt wird.
                            </li>
                            <li>
                                Beseitigt der Steuerberater die geltend gemachten Mängel nicht innerhalb einer
                                angemessenen Frist oder lehnt er die Mängelbeseitigung ab, so kann der Auftraggeber auf
                                Kosten des Steuerberaters die Mängel durch einen anderen Steuerberater beseitigen lassen
                                bzw. nach seiner Wahl Herabsetzung der Vergütung oder Rückgängigmachung des Vertrags
                                verlangen.
                            </li>
                            <li>
                                Offenbare Unrichtigkeiten (z. B. Schreibfehler, Rechenfehler) können vom Steuerberater
                                jederzeit, auch Dritten gegenüber, berichtigt werden. Sonstige Mängel darf der
                                Steuerberater Dritten gegenüber mit Einwilligung des Auftraggebers berichtigen. Die
                                Einwilligung ist nicht erforderlich, wenn berechtigte Interessen des Steuerberaters den
                                Interessen des Auftraggebers vorgehen.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Haftung
                        <ol>
                            <li>
                                Die Haftung des Steuerberaters und seiner Erfüllungsgehilfen für einen Schaden, der aus
                                einer oder – bei einheitlicher Schadensfolge – aus mehreren Pflichtverletzungen
                                anlässlich der Erfüllung eines Auftrags resultiert, wird auf 1.000.000,00 € (in Worten:
                                eine Million €) begrenzt. Die Haftungsbegrenzung bezieht sich allein auf Fahrlässigkeit.
                                Die Haftung für Vorsatz bleibt insoweit unberührt. Von der Haftungsbegrenzung
                                ausgenommen sind Haftungsansprüche für Schäden aus der Verletzung des Lebens, des
                                Körpers oder der Gesundheit. Die Haftungsbegrenzung gilt für die gesamte Tätigkeit des
                                Steuerberaters für den Auftraggeber, also insbesondere auch für eine Ausweitung des
                                Auftragsinhalts; einer erneuten Vereinbarung der Haftungsbegrenzung bedarf es insoweit
                                nicht. Die Haftungsbegrenzung gilt auch bei Bildung einer Sozietät/Partnerschaft und
                                Übernahme des Auftrags durch die Sozietät/Partnerschaft sowie für neu in die
                                Sozietät/Partnerschaft eintretende Sozien/Partner. Die Haftungsbegrenzung gilt ferner
                                auch gegenüber Dritten, soweit diese in den Schutzbereich des Mandatsverhältnisses
                                fallen; § 334 BGB wird insoweit ausdrücklich nicht abbedungen. Einzelvertragliche
                                Haftungsbegrenzungsvereinbarungen gehen dieser Regelung vor, lassen die Wirksamkeit
                                dieser Regelung jedoch – soweit nicht ausdrücklich anders geregelt – unberührt.
                            </li>
                            <li>
                                Die Haftungsbegrenzung gilt, wenn entsprechend hoher Versicherungsschutz bestanden hat,
                                rückwirkend von Beginn des Mandatsverhältnisses bzw. dem Zeitpunkt der Höherversicherung
                                an und erstreckt sich, wenn der Auftragsumfang nachträglich geändert oder erweitert
                                wird, auch auf diese Fälle.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Pflichten des Auftraggebers; unterlassene Mitwirkung und Annahmeverzug des Auftraggebers
                        <ol>
                            <li>
                                Der Auftraggeber ist zur Mitwirkung verpflichtet, soweit es zur ordnungsgemäßen
                                Erledigung des Auftrags erforderlich ist. Insbesondere hat er dem Steuerberater
                                unaufgefordert alle für die Ausführung des Auftrags notwendigen Unterlagen vollständig
                                und so rechtzeitig zu übergeben, dass dem Steuerberater eine angemessene
                                Bearbeitungszeit zur Verfügung steht. Entsprechendes gilt für die Unterrichtung über
                                alle Vorgänge und Umstände, die für die Ausführung des Auftrags von Bedeutung sein
                                können. Der Auftraggeber ist verpflichtet, alle schriftlichen und mündlichen
                                Mitteilungen des Steuerberaters zur Kenntnis zu nehmen und bei Zweifelsfragen
                                Rücksprache zu halten.
                            </li>
                            <li>
                                Der Auftraggeber hat alles zu unterlassen, was die Unabhängigkeit des Steuerberaters
                                oder seiner Erfüllungsgehilfen beeinträchtigen könnte.
                            </li>
                            <li>
                                Der Auftraggeber verpflichtet sich, Arbeitsergebnisse des Steuerberaters nur mit dessen
                                Einwilligung weiterzugeben, soweit sich nicht bereits aus dem Auftragsinhalt die
                                Einwilligung zur Weitergabe an einen bestimmten Dritten ergibt.
                            </li>
                            <li>
                                Setzt der Steuerberater beim Auftraggeber in dessen Räumen Datenverarbeitungsprogramme
                                ein, so ist der Auftraggeber verpflichtet, den Hinweisen des Steuerberaters zur
                                Installation und Anwendung der Programme nachzukommen. Des Weiteren ist der Auftraggeber
                                verpflichtet, die Programme nur in dem vom Steuerberater vorgeschriebenen Umfang zu
                                nutzen, und er ist auch nur in dem Umfang zur Nutzung berechtigt. Der Auftraggeber darf
                                die Programme nicht verbreiten. Der Steuerberater bleibt Inhaber der Nutzungsrechte. Der
                                Auftraggeber hat alles zu unterlassen, was der Ausübung der Nutzungsrechte an den
                                Programmen durch den Steuerberater entgegensteht.
                            </li>
                            <li>
                                Unterlässt der Auftraggeber eine ihm nach Ziff. 6 Abs. 1 bis 4 oder anderweitig
                                obliegende Mitwirkung oder kommt er mit der Annahme der vom Steuerberater angebotenen
                                Leistung in Verzug, so ist der Steuerberater berechtigt, den Vertrag fristlos zu
                                kündigen (vgl. Ziff. 9 Abs. 3). Unberührt bleibt der Anspruch des Steuerberaters auf
                                Ersatz der ihm durch den Verzug oder die unterlassene Mitwirkung des Auftraggebers
                                entstandenen Mehraufwendungen sowie des verursachten Schadens, und zwar auch dann, wenn
                                der Steuerberater von dem Kündigungsrecht keinen Gebrauch macht.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Urheberrechtsschutz
                        <p>Die Leistungen des Steuerberaters stellen dessen geistiges Eigentum dar. Sie sind
                            urheberrechtlich geschützt. Eine Weitergabe von Arbeitsergebnissen außerhalb der
                            bestimmungsgemäßen Verwendung ist nur mit vorheriger Zustimmung des Steuerberaters in
                            Textform zulässig.</p>
                    </li>
                    <li>
                        Vergütung, Vorschuss und Aufrechnung
                        <ol>
                            <li>
                                Die Vergütung (Gebühren und Auslagenersatz) des Steuerberaters für seine Berufstätigkeit
                                nach § 33 StBerG bemisst sich nach der Steuerberatervergütungsverordnung (StBVV). Eine
                                höhere oder niedrigere als die gesetzliche Vergütung kann in Textform vereinbart werden.
                                Die Vereinbarung einer niedrigeren Vergütung ist nur in außergerichtlichen
                                Angelegenheiten zulässig. Sie muss in einem angemessenen Verhältnis zu der Leistung, der
                                Verantwortung und dem Haftungsrisiko des Steuerberaters stehen (§ 4 Abs. 3 StBVV).
                            </li>
                            <li>
                                Für Tätigkeiten, die in der Vergütungsverordnung keine Regelung erfahren (z. B. § 57
                                Abs. 3 Nrn. 2 und 3 StBerG), gilt die vereinbarte Vergütung, anderenfalls die für diese
                                Tätigkeit vorgesehene gesetzliche Vergütung, ansonsten die übliche Vergütung (§§ 612
                                Abs. 2 und 632 Abs. 2 BGB).
                            </li>
                            <li>
                                Eine Aufrechnung gegenüber einem Vergütungsanspruch des Steuerberaters ist nur mit
                                unbestrittenen oder rechtskräftig festgestellten Forderungen zulässig.
                            </li>
                            <li>
                                Für bereits entstandene und voraussichtlich entstehende Gebühren und Auslagen kann der
                                Steuerberater einen Vorschuss fordern. Wird der eingeforderte Vorschuss nicht gezahlt,
                                kann der Steuerberater nach vorheriger Ankündigung seine weitere Tätigkeit für den
                                Auftraggeber einstellen, bis der Vorschuss eingeht. Der Steuerberater ist verpflichtet,
                                seine Absicht, die Tätigkeit einzustellen, dem Auftraggeber rechtzeitig bekanntzugeben,
                                wenn dem Auftraggeber Nachteile aus einer Einstellung der Tätigkeit erwachsen können.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Beendigung des Vertrags
                        <ol>
                            <li>
                                Der Vertrag endet mit Erfüllung der vereinbarten Leistungen, durch Ablauf der
                                vereinbarten Laufzeit oder durch Kündigung. Der Vertrag endet nicht durch den Tod, durch
                                den Eintritt der Geschäftsunfähigkeit des Auftraggebers oder im Falle einer Gesellschaft
                                durch deren Auflösung.
                            </li>
                            <li>
                                Der Vertrag kann – wenn und soweit er einen Dienstvertrag i. S. d. §§ 611, 675 BGB
                                darstellt – von jedem Vertragspartner außerordentlich gekündigt werden, es sei denn, es
                                handelt sich um ein Dienstverhältnis mit festen Bezügen, § 627 Abs. 1 BGB; die Kündigung
                                hat in Textform zu erfolgen. Soweit im Einzelfall hiervon abgewichen werden soll, bedarf
                                es einer Vereinbarung, die zwischen Steuerberater und Auftraggeber auszuhandeln ist.
                            </li>
                            <li>
                                Bei Kündigung des Vertrags durch den Steuerberater sind zur Vermeidung von
                                Rechtsnachteilen des Auftraggebers in jedem Fall noch diejenigen Handlungen durch den
                                Steuerberater vorzunehmen, die zumutbar sind und keinen Aufschub dulden (z. B.
                                Fristverlängerungsantrag bei drohendem Fristablauf).
                            </li>
                            <li>
                                Der Steuerberater ist verpflichtet, dem Auftraggeber alles, was er zur Ausführung des
                                Auftrags erhält oder erhalten hat und was er aus der Geschäftsbesorgung erlangt,
                                herauszugeben. Außerdem ist der Steuerberater verpflichtet, dem Auftraggeber auf
                                Verlangen über den Stand der Angelegenheit Auskunft zu erteilen und Rechenschaft
                                abzulegen.
                            </li>
                            <li>
                                Mit Beendigung des Vertrags hat der Auftraggeber dem Steuerberater die beim Auftraggeber
                                zur Ausführung des Auftrags eingesetzten Datenverarbeitungsprogramme einschließlich
                                angefertigter Kopien sowie sonstige Programmunterlagen unverzüglich herauszugeben bzw.
                                sie von der Festplatte zu löschen.
                            </li>
                            <li>
                                Nach Beendigung des Auftragsverhältnisses sind die Unterlagen beim Steuerberater
                                abzuholen.
                            </li>
                            <li>
                                Endet der Auftrag vor seiner vollständigen Ausführung, so richtet sich der
                                Vergütungsanspruch des Steuerberaters nach dem Gesetz. Soweit im Einzelfall hiervon
                                abgewichen werden soll, bedarf es einer gesonderten Vereinbarung in Textform.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Aufbewahrung, Herausgabe und Zurückbehaltungsrecht in Bezug auf Arbeitsergebnisse und Unterlagen
                        <ol>
                            <li>
                                Der Steuerberater hat die Handakten für die Dauer von zehn Jahren nach Beendigung des
                                Auftrags aufzubewahren. Diese Verpflichtung erlischt jedoch schon vor Beendigung dieses
                                Zeitraums, wenn der Steuerberater den Auftraggeber aufgefordert hat, die Handakten in
                                Empfang zu nehmen, und der Auftraggeber dieser Aufforderung binnen sechs Monaten,
                                nachdem er sie erhalten hat, nicht nachgekommen ist.
                            </li>
                            <li>
                                Handakten i. S. v. Abs. 1 sind nur die Schriftstücke, die der Steuerberater aus Anlass
                                seiner beruflichen Tätigkeit von dem Auftraggeber oder für ihn erhalten hat, nicht aber
                                der Briefwechsel zwischen dem Steuerberater und seinem Auftraggeber und für die
                                Schriftstücke, die dieser bereits in Urschrift oder Abschrift erhalten hat, sowie für
                                die zu internen Zwecken gefertigten Arbeitspapiere (§ 66 Abs. 3 StBerG).
                            </li>
                            <li>
                                Auf Anforderung des Auftraggebers, spätestens aber nach Beendigung des Auftrags, hat der
                                Steuerberater dem Auftraggeber die Handakten innerhalb einer angemessenen Frist
                                herauszugeben. Der Steuerberater kann von Unterlagen, die er an den Auftraggeber
                                zurückgibt, Abschriften oder Fotokopien anfertigen und zurückbehalten.
                            </li>
                            <li>
                                Der Steuerberater kann die Herausgabe der Handakten verweigern, bis er wegen seiner
                                Gebühren und Auslagen befriedigt ist. Dies gilt nicht, soweit die Vorenthaltung der
                                Handakten und der einzelnen Schriftstücke nach den Umständen unangemessen wäre (§ 66
                                Abs. 2 Satz 2 StBerG).
                            </li>
                        </ol>
                    </li>
                    <li>
                        Sonstiges
                        <p>
                            Für den Auftrag, seine Ausführung und die sich hieraus ergebenden Ansprüche gilt
                            ausschließlich deutsches Recht. Erfüllungsort ist der Wohnsitz des Auftraggebers, soweit er
                            nicht Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches
                            Sondervermögen ist, ansonsten die berufliche Niederlassung des Steuerberaters. Der
                            Steuerberater ist – nicht – bereit, an einem Streitbeilegungsverfahren vor einer
                            Verbraucherschlichtungsstelle teilzunehmen (§§ 36, 37 VSBG).
                        </p>
                    </li>
                    <li>
                        Wirksamkeit bei Teilnichtigkeit
                        <p>
                            Falls einzelne Bestimmungen dieser Geschäftsbedingungen unwirksam sein oder werden sollten,
                            wird die Wirksamkeit der übrigen Bestimmungen dadurch nicht berührt.
                        </p>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>
