import { Component, OnDestroy } from '@angular/core';

import {Subject} from 'rxjs';

/**
 * Base class for components that uses subscribe() method on some observable.
 *
 * Remember to import 'rxjs/add/operator/takeUntil' and call takeUntil() before subscribe():
 *     this.myThingService.getThings()
 *         .takeUntil(this.ngUnsubscribe)
 *         .subscribe(things => console.log(things));
 */
@Component({
  template: ''
})
export abstract class SubscribingComponent implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
