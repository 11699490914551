<div class="notification-alert-box">
  <a
    *ngIf="buttonCloseIsEnabled"
    class="button-close"
    (click)="buttonCloseClickEvent()"
  >
    <img src="../../../../../assets/svg/close.svg" alt="Close">
  </a>
  <img src="../../../../../assets/svg/employer_erorr.svg" class="alert-box-icon" alt="Error">
  <div class="description-container">
    <b>{{ preTitle | translate}}</b>&nbsp;
    <span>{{ notificationMessage | translate }}</span>
  </div>
  <div class="buttons-container">
    <app-button
      *ngIf="buttonStandardTitle"
      [class]="'mb-1 mr-1 flex-grow-1 ' + buttonStandardExtraClasses"
      [title]="buttonStandardTitle | translate"
      [isLoading]="buttonStandardIsLoading"
      [isDisabled]="buttonStandardIsDisabled"
      (clickEmitter)="buttonStandardClickEvent()"
      [attr.data-cy]="'btnStandardNotificationAlertBox'"
    ></app-button>
    <app-button-primary
      *ngIf="buttonPrimaryTitle"
      [class]="'mb-1 mr-1 flex-grow-1 ' + buttonPrimaryExtraClasses"
      [title]="buttonPrimaryTitle | translate"
      [isLoading]="buttonPrimaryIsLoading"
      [isDisabled]="buttonPrimaryIsDisabled"
      (clickEmitter)="buttonPrimaryClickEvent()"
      [attr.data-cy]="'btnPrimaryNotificationAlertBox'"
    ></app-button-primary>
  </div>
</div>
