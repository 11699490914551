<ng-container *ngIf="isLogged">

  <div class="workflow-steps"
       [ngClass]="{ 'workflow-steps--condensed': isMobileCondensed }"
       *ngIf="workFlowController">

    <div class="workflow-steps-mode" *ngFor="let workflowBlock of workFlowController?.workflowForMenu">

      <div class="workflow-steps__title">
        <div
          class="mb-0_75 mt-1"
          *ngIf="workflowBlock.title && workflowBlock.title()"
          [innerHTML]="workflowBlock.title() | translate:{firstNameApplicant: statement && statement.firstName ? statement.firstName : '', firstNameSpouse: statement && statement.spouse && statement.spouse.firstName ? statement.spouse.firstName : '', statementYear: statement && statement.fullYear ? statement.fullYear : ''}"
        ></div>
      </div>

      <ng-container *ngFor="let workflowStep of workflowBlock?.steps">
        <div
          class="workflow-steps__steps-list {{workflowStep.stepClass}}"
          [attr.data-cy]="'btnWorkflow_' + workflowStep.constructor.name"
          [class.sub]="workflowStep.isSubStep"
          [class.workflow-steps__steps-list--disabled]="workflowStep.blockedDefaultWorkflow || !workflowStep.canGo"
          *ngIf="workflowStep.isVisible"
          (click)="goToStepWorkflow(workflowStep)"
        >

          <div
            class="workflow-steps__step workflow-steps__step-list"
            [class.workflow-steps__step--active]="workflowStep.isActive || workflowStep.isActiveOnStepsBar"
            [class.workflow-steps__step--done]="workflowStep.isDone"
          >

            <div class="workflow-steps__step-header">
              <div class="workflow-steps__step-point"></div>

              <div class="workflow-steps__step-title">
                <a
                  [class.mobile-text-long-1]="(workflowStep.title | translate).length > 19"
                  [class.mobile-text-long-2]="(workflowStep.title | translate).length > 23"
                >
                  {{ workflowStep.title | translate }}
                </a>
              </div>
            </div>
          </div>

        </div>
      </ng-container>

    </div>
  </div>
</ng-container>

<app-labels-edit [statement]="statement" [currentId]="'selectLabelLeft'"></app-labels-edit>
