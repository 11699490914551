import { NgModule } from '@angular/core';
import { GoogleTagManagerService } from 'app/shared/modules/google-analytics/services/google-tag-manager.service';

@NgModule({
  providers: [
    GoogleTagManagerService,
  ],
})
export class GoogleAnalyticsModule {
  constructor(
    readonly googleTagManagerService: GoogleTagManagerService, // init google tag manager
  ) {
  }
}
