<div class="dialog-box">
    <div class="dialog-box__content">
        <div class="title_wrap">
            <span class="dialog-box__title_center">
                {{  'EMAIL_VERIFICATION_DIALOG.TITLE' | translate  }}
            </span>
            <button mat-icon-button mat-dialog-close tabindex="-1">
                <mat-icon aria-hidden="true">close</mat-icon>
            </button>
        </div>
        <div class="desc_cont">
            <div class="img_wrap">
                <img src="../../../assets/svg/email_ver_img.svg" class="img">
            </div>
            <div class="desc_wrap">
                <div class="desc">
                    {{ 'EMAIL_VERIFICATION_DIALOG.DESCRIPTION' | translate }}
                </div>
            </div>
            <div class="button_wrap">
                <button class="btn btn--primary" (click)="sendActivationLink()" [disabled]="!canSendLink">
                    <span class="btn--tx">{{ 'EMAIL_VERIFICATION_DIALOG.BUTTON_TEXT' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>