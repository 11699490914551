<ng-container *ngIf="!contentLoaded">
    <app-content-loader></app-content-loader>
</ng-container>
<ng-container *ngIf="contentLoaded">
    <div class="step-container columns is-desktop">
        <app-aside-bar [showEvenWithoutStatement]="false" [statement]="statement" [isBusiness]="isBusiness">
        </app-aside-bar>
        <div class="finish">
            <ng-container *ngIf="statement.serviceType!==packageOption.tax_adviser">
                <div class="finish__wrapper">
                    <div class="finish__header">
                        <h1 class="title_42" translate="FINISH.TITLE"></h1>
                        <p class="subdesc">
                            {{'FINISH.DESCRIPTION' | translate }}
                        </p>
                    </div>
                    <div class="finish__img">
                        <ng-lottie [options]="lottieConfig" ></ng-lottie>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="statement.serviceType===packageOption.tax_adviser">
                <div class="finish__wrapper">
                    <div class="finish__header">
                        <h1 class="title_42" translate="FINISH.TITLE"></h1>
                        <p class="subdesc">
                            {{'FINISH.TAX_ADVISER_DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div class="finish__img">
                        <ng-lottie [options]="lottieConfig" ></ng-lottie>
                    </div>
                </div>
            </ng-container>
        </div>
        <app-tools-bar [statement]="statement" [buttons]="buttons"></app-tools-bar>
    </div>
</ng-container>
