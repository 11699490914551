import { UserDeleteResponseInterface } from 'app/core/models/user/user-delete.response.interface';
import { UserDeleteModelInterface } from 'app/core/models/user/user-delete.model.interface';

export class UserDeleteModel implements UserDeleteModelInterface {
  confirmationNeeded = false;
  deleteConfirmation = false;

  public static fromResponse(response: UserDeleteResponseInterface): UserDeleteModel {
    const userDeleteModel = new UserDeleteModel();
    userDeleteModel.confirmationNeeded = response.confirmation_needed;
    userDeleteModel.deleteConfirmation = response.delete_confirmation;
    return userDeleteModel;
  }

  public static confirmDeletionPayload(): UserDeleteResponseInterface {
    return {
      delete_confirmation: true,
    };
  }
}
