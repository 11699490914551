import { DeductionInfoChildComponentPath } from 'app/core/workflow/page-path/statement-page-path/deduction-info-child-component.path';
import { WorkflowDeductionBaseService } from 'app/core/workflow/workflow-steps/deduction/workflow-deduction-base.service';
import { B2bDeductionInfoChildComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.deduction-info-child-component.path';

export class WorkflowDeductionInfoChildService extends WorkflowDeductionBaseService {
  public get isDone(): boolean {
    return this.statement && this.statement.stageDeductionInfoChildren;
  }

  public get isFakeStep(): boolean {
    return !this.isBusiness
      && this.statement
      && this.statement.children.length > 1;
  }

  public get isVisible(): boolean {
    return this.isRequired
      && this.isDeductionRoute;
  }

  public get isRequired(): boolean {
    return !!this.statement && this.hasChildren
      && this.isDeductionOptionAdd;
  }

  public get title(): string {
    return 'ASIDE_BAR.CHILDREN_DEDUCTION';
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.isRequired;
  }

  public get isActive(): boolean {
    if (this.isFakeStep) {
      return false;
    }

    return this.defaultIsActiveFunction;
  }

  public get urlRegex(): RegExp {
    if (this.isBusiness) {
      return B2bDeductionInfoChildComponentPath.regex();
    }

    return DeductionInfoChildComponentPath.regex();
  }

  public get url(): string {
    if (this.isBusiness) {
      return B2bDeductionInfoChildComponentPath.url(this.getStatementId);
    }

    return DeductionInfoChildComponentPath.url(this.getStatementId, this.getFirstChildId);
  }

  public get isSubStep(): boolean {
    return true;
  }

  public get stepClass(): string {
    let classes = this.classLeftLineNone;

    if (this.isFakeStep) {
      classes += this.classHasSubs;
    }

    return classes;
  }
}
