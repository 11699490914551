export const VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG = {
  topic: "sm_topic",
  message: "sm_message",
  tax_authority: "sm_tax_authority",
  tax_number: "sm_tax_number",
  id_number: "sm_id_number",
  attachment_size: "sm_attachment_size",
  attachment_type: "sm_attachment_type",
  attachment_max_page_number: "sm_attachment_max_page_number",
  attachment_max_file_number: "sm_attachment_max_file_number"
};
