<div class="tooltip_dialogbox">
  <div class="header_wrap">
    <button mat-icon-button mat-dialog-close tabindex="-1" *ngIf="!tooltipAutoShow">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>
    <h2 class="title_42 text-center" *ngIf="titleTooltip">
      {{ titleTooltip | translate }}
    </h2>
  </div>
  <ng-container *ngIf="descTooltip">
    <div class="desc_wrap" [innerHTML]="descTooltip | translate"></div>
    <div class="confirm-button-container">
      <h4 *ngIf="confirmButtonTitle">
        {{ confirmButtonTitle | translate }}
      </h4>
      <app-button-outline-primary
        *ngIf="tooltipAutoShow"
        [title]="confirmButtonText"
        mat-dialog-close
        extraClasses="dialog_button"
        [isDisabled]="false"
      ></app-button-outline-primary>
      <h5 *ngIf="confirmButtonBottomText">
        {{ confirmButtonBottomText | translate }}
      </h5>
    </div>
  </ng-container>

  <div *ngIf="!!contentTemplateRef && !!!descTooltip"
    ><ng-container *ngTemplateOutlet="contentTemplateRef"></ng-container
  ></div>
</div>
