<div class="aside-bar-wrapper" *ngIf="!isNested">
    <div class="aside-bar__header" *ngIf="showEvenWithoutStatement || statement">
        <div class="aside-bar__header-open-dialog" (click)="openDialog()"></div>
        <app-workflow-steps
          [isLogged]="isLogged"
          [isMobileCondensed]="true"
          [statement]="statement"
          [isBusiness]="isBusiness"
          [workFlowController]="workFlowController"
        ></app-workflow-steps>
    </div>
    <app-menu-bar-mobile *ngIf="isLogged && (showEvenWithoutStatement || statement)"></app-menu-bar-mobile>
</div>
