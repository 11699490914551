import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { AnimationOptions } from 'ngx-lottie/lib/symbols';

@Component({
  selector: 'app-processing-placeholder',
  templateUrl: './processing-placeholder.component.html',
  styleUrls: ['./processing-placeholder.component.scss']
})
export class ProcessingPlaceholderComponent implements OnInit {
  config: SwiperOptions = {
    navigation: {
      nextEl: '.processing-placeholder__switch--next',
      prevEl: '.processing-placeholder__switch--prev',
    },
    spaceBetween: 0
  };

  public lottieConfig: AnimationOptions = {
    path: '../../../assets/anim/anim_OCR.json',
    autoplay: true,
    loop: true
  };

  constructor() {
  }

  ngOnInit() {
  }
}
