import { BaseStatementPagePath } from 'app/core/workflow/page-path/statement-page-path/base-statement-page.path';

export class BankDetailComponentPath extends BaseStatementPagePath {
  public static path(): string {
    return 'statement/:statementId/bank-detail';
  }

  public static regex(): RegExp {
    return /\/statement\/\d+\/bank-detail((\/?#?)|(\/?#.*?))$/;
  }
}
