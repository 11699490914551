import { Component, Input, TemplateRef } from "@angular/core";
import { SubscribingComponent } from "app/common";
import { SelectButton } from "app/common/select-buttons/select-buttons.component";

@Component({
  selector: "app-group-section-base",
  templateUrl: "./group-section-base.component.html",
  styleUrls: ["./group-section-base.component.scss"]
})
export class GroupSectionBaseComponent extends SubscribingComponent {
  @Input() templateRef: TemplateRef<any>;
  @Input() isActive = false;
  @Input() title: string;
  @Input() description: string;
  @Input() titleTooltip: string;
  @Input() messageTooltip: string;
  @Input() statementFullYear: number;
  @Input() selectedCounter: number;
  @Input() selectButtons: SelectButton[];
  @Input() isHomeService = false;
  @Input() tooltipAutoShow = false;
  @Input() confirmButtonText = "";
  @Input() confirmButtonTitle = "";
  @Input() confirmButtonBottomText = "";
}
