<app-ctr-base
  [templateRef]="cryptocurrency"
  [title]="title"
  [categoryProperties]="categoryProperties"
  [dataSelects]="dataSelects"
  [summary]="summary"
></app-ctr-base>

<ng-template #cryptocurrency>
    <div class="card card-white shadow-0 mb-2" id="cryptocurrencyTab">
        <div class="card__row mb-1">
            <div class="table">
                <div class="table__row">
                    <div class="table__col">
                        <div class="row__title">{{ 'CONFIRM_TAX_RETURN.CRYPTOCURRENCY_INCOME.TOTAL_AMOUNT' | translate }}</div>
                    </div>
                    <div class="table__col d-flex">
                        <input
                          [attr.data-cy]="'verificationFreelanceJobTotalAmount_' + person"
                          readonly
                          value="{{ refundDetails[person].cryptoCurrencyIncome | number: '1.2-2' }} €"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
