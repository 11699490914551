import { CommonModule } from '@angular/common';
import { LottieModule } from 'ngx-lottie';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'app/shared/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { SmartAppBannerModule } from 'app/shared/modules/smart-app-banner/smart-app-banner.module';
import { RatingModule } from 'app/shared/modules/rating/rating.module';
import { ContentLoaderComponent } from 'app/shared/standalone/components/content-loader/content-loader.component';
import { SpinnerComponent } from 'app/shared/standalone/components/spinner/spinner.component';
import { SquareLoaderComponent } from 'app/shared/standalone/components/square-loader/square-loader.component';
import { FormInputReactiveComponent } from 'app/shared/standalone/components/form-input-reactive/form-input-reactive.component';
import { FormInputComponent } from 'app/shared/standalone/components/form-input/form-input.component';
import { ChooseLanguageComponent } from 'app/shared/standalone/components/choose-language/choose-language.component';
import { MoreInfoTooltipComponent } from 'app/shared/standalone/components/more-info-tooltip/more-info-tooltip.component';
import { TaxCardAddComponent } from 'app/shared/standalone/components/tax-card-add/tax-card-add.component';
import { ButtonTileLoaderComponent } from 'app/shared/standalone/components/loaders/button-tile-loader/button-tile-loader.component';

export const sharedModuleImports = [
  CommonModule,
  LottieModule,
  TranslateModule,
  MaterialModule,
  RouterModule,
  SmartAppBannerModule,
  RatingModule,
  ContentLoaderComponent,
  SpinnerComponent,
  SquareLoaderComponent,
  MoreInfoTooltipComponent,
  FormInputComponent,
  FormInputReactiveComponent,
  ChooseLanguageComponent,
  ButtonTileLoaderComponent,
  TaxCardAddComponent,
];
