import * as refundDetails from './actions/refund-details';


export interface State {
  shouldUpdateRefundDetails: boolean;
}

export const initialState: State = {
  shouldUpdateRefundDetails: false
};

export function reducer(state = initialState, action: refundDetails.Actions): State {
  switch (action.type) {
    case refundDetails.UPDATE_REFUND_DETAILS: {
      return {shouldUpdateRefundDetails: true};
    }

    case refundDetails.RESET_REFUND_DETAILS: {
      return {shouldUpdateRefundDetails: false};
    }

    default: {
      return state;
    }
  }
}

export const getShouldUpdate = (state: State) => state.shouldUpdateRefundDetails;
