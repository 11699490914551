<div class="columns is-mobile table-header">
    <div class="column table-header__column">{{ 'PAYMENT.QUANTITY' | translate }}</div>
    <div class="column table-header__column">{{ 'PAYMENT.AMOUNT' | translate }}</div>
    <div class="column table-header__column">{{ 'PAYMENT.CREATION_DATE' | translate }}</div>
    <div class="column table-header__column">{{ 'PAYMENT.CLEARED_DATE' | translate }}</div>
    <div class="column table-header__column">{{ 'PAYMENT.STATUS' | translate }}</div>
    <div class="column table-header__column">{{ 'PAYMENT.INVOICE' | translate }}</div>
</div>
<div class="list-view-list">
    <div class="list-view-item" *ngFor="let payment of payments, let i = index" (click)="toggleItem(i)">
        <app-payment-list-item
                [payment]="payment"
                [openedItems]="openedItems"
                [index]="i"
                (downloadInvoice)="emitDownloadInvoice(payment)">
        </app-payment-list-item>
    </div>
</div>
