import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-button-base",
  template: "",
  styleUrls: ["./button-base.component.scss"]
})
export class ButtonBaseComponent {
  static metaData = {
    inputs: ["title", "isSmall", "isSmallMobile", "isDisabled", "isLoading", "extraClasses", "buttonType", "autoFocus"],
    outputs: ["clickEmitter"]
  };
  @Input() title;
  @Input() isSmall: boolean;
  @Input() isSmallMobile: boolean;
  @Input() isDisabled: boolean;
  @Input() isLoading: boolean;
  @Input() extraClasses: string;
  @Input() buttonType: "submit" | "button" = "button";
  @Input() preventDefault = true;
  @Input() autoFocus = true;
  @Output() clickEmitter = new EventEmitter();

  public clickEvent(event: Event): void {
    if (this.preventDefault) {
      event.preventDefault();
    }

    if (this.isDisabled) {
      return;
    }

    this.clickEmitter.emit();
  }
}
