import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SelectsDataInterface, Statement } from "app/statements";
import { Store } from "@ngrx/store";
import { getSelectsData, State } from "app/reducers";
import { takeUntil } from "rxjs";
import { SubscribingComponent } from "app/common/subscribing-component";
import { ErrorMessage } from "app/core/form-validator/validatorInterface";
import { MessageSenderHttpService } from "./http-service/message-sender.http.service";
import { VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG } from "app/core/form-validator/validator-field-names/validator-field-names-send-message-dialog";
import { ValidateFieldV2Service } from "app/core/form-validator/validate-field-v2.service";
import { StatementErrors } from "app/modules/statement/models/statement/statement.errors.interface";
import { FileData, SendedFileData } from "app/shared/components/add-attachment/add-attachment-list.component";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";

export enum MESSAGE_DOCUMENT_TYPE {
  ATTACHMENT = "Attachment",
  MESSAGE = "Message",
  APPEAL = "Appeal"
}

export enum ATTACHMENT_SUBTYPE {
  EWR = "Ewr",
  BELEGE = "Belege",
  VOLMACHT = "Vollmacht"
}

enum MESSAGE_STATUS_DIALOG_TYPE {
  SUCCESS,
  ERROR
}

export interface Message {
  id: string;
  type: MESSAGE_DOCUMENT_TYPE;
  subtype: ATTACHMENT_SUBTYPE;
  statement: string;
  files: SendedFileData[];
  steuernummer?: string;
  identifikationsnummer?: string;
  tax_authority?: number;
  stay_of_enforcement?: boolean;
  send_by_email?: boolean;
}

export type MessageDataTypes = {
  type: MESSAGE_DOCUMENT_TYPE;
  decision_date?: Date;
  identifikationsnummer?: string;
  tax_authority?: number;
  steuernummer?: string;
  stay_of_enforcement?: boolean;
  topic?: string;
  message?: string;
  subtype?: ATTACHMENT_SUBTYPE; // only for attachment
  files?: Array<File>;
  send_by_email?: boolean;
};

@Component({
  selector: "app-send-message-dialog",
  templateUrl: "./send-message-dialog.component.html",
  styleUrls: ["./send-message-dialog.component.scss"]
})
export class NewMessageDialogComponent extends SubscribingComponent implements OnInit {
  public errors: StatementErrors;
  public dataSelects: SelectsDataInterface;
  public title = "";
  public text = "";
  public attachments: File[] = [];
  public epValidator: ErrorMessage[] = [];
  public validatorFieldNames = VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG;
  public attachmentsList: FileData[] = [];
  protected messageDocumentTypes = MESSAGE_DOCUMENT_TYPE;
  public sendedAttachments: SendedFileData[] = [];
  public localSteuernummer = "";
  public localIdNumber = "";
  public localTaxAuthority: number = null;
  public localDecisionDate: Date = null;

  public titleMaxLength = 99;
  public textMaxLength = 1500;
  public messageType: MESSAGE_DOCUMENT_TYPE = MESSAGE_DOCUMENT_TYPE.MESSAGE;
  public attachmentSubType: ATTACHMENT_SUBTYPE = null;
  public statementData: Statement;
  public messageID: number = null;

  public requireEnforcementValue = false;
  public sendByEmail = false;

  public isLoading = false;
  public isRequestError = false;

  public initNewMessage = true;
  public shouldBePatched = false;
  public onlySave = false;
  public responseMessage = null;

  constructor(
    protected store: Store<State>,
    public messageSenderHttpService: MessageSenderHttpService,
    public dialogRef: MatDialogRef<NewMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected validateFieldV2Service: ValidateFieldV2Service,
    private dialog: MatDialog
  ) {
    super();
    this.initializeComponent();
  }

  private initializeComponent(): void {
    if (this.data.titleMaxLength) {
      this.titleMaxLength = this.data.titleMaxLength;
    }
    if (this.data.textMaxLength) {
      this.textMaxLength = this.data.textMaxLength;
    }
    if (this.data.messageType) {
      this.messageType = this.data.messageType;
    }
    if (this.data.statementData) {
      this.statementData = this.data.statementData;
      this.localSteuernummer = this.statementData.steuernummer;
      this.localIdNumber = this.statementData.identifikationsnummer;
      this.localTaxAuthority = this.statementData.taxAuthority;
      this.onlySave = this.data.onlySave;
    }

    if (this.data.subtype) this.attachmentSubType = this.data.subtype;

    // if (this.data.responseMessage) this.responseMessage = this.data.responseMessage;
  }

  ngOnInit(): void {
    this.store
      .select(getSelectsData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectsData: SelectsDataInterface) => {
        this.dataSelects = selectsData;
      });

    this.validateFieldV2Service._init(this.statementData);

    if (
      this.attachmentSubType === ATTACHMENT_SUBTYPE.BELEGE ||
      (this.attachmentSubType === ATTACHMENT_SUBTYPE.EWR && this.onlySave)
    ) {
      this.getMessageAttachmentData();
    }
  }

  public getMessageAttachmentData(): void {
    this.messageSenderHttpService.getMessageData(this.statementData.id).subscribe({
      next: (response: Message[]) => {
        const message = response.find(
          (msg: Message) =>
            msg.type === MESSAGE_DOCUMENT_TYPE.ATTACHMENT &&
            [ATTACHMENT_SUBTYPE.BELEGE, ATTACHMENT_SUBTYPE.EWR].includes(msg.subtype)
        );

        if (message) {
          this.processMessageAttachment(message);
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  private processMessageAttachment(message: Message): void {
    const filteredFiles: SendedFileData[] = message.files.map((file) => ({
      ...file,
      message_id: Number(message.id),
      statement: Number(message.statement)
    }));

    this.messageID = Number(message.id);
    this.sendedAttachments.push(...filteredFiles);
    this.localSteuernummer = message.steuernummer || "";
    this.localIdNumber = message.identifikationsnummer || "";
    this.localTaxAuthority = message.tax_authority || null;
    this.requireEnforcementValue = message.stay_of_enforcement || false;
    this.sendByEmail = message.send_by_email || false;
    this.shouldBePatched = true;
  }

  public handleSendMessage(): void {
    const payloadData: MessageDataTypes = this.makePayload();
    this.errors = {};
    if (this.isValidated(payloadData)) {
      this.isLoading = true;
      this.shouldBePatched ? this.updateMessage(payloadData) : this.createNewMessage(payloadData);
    }
  }

  private createNewMessage(payloadData: MessageDataTypes): void {
    this.messageSenderHttpService
      .sendMessage(this.statementData.id, payloadData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => this.handleSuccess(),
        error: (error: any) => this.handleError(error)
      });
  }

  private updateMessage(payloadData: MessageDataTypes): void {
    this.messageSenderHttpService
      .updateMessageData(this.statementData.id, this.messageID, payloadData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => this.handleSuccess(),
        error: (error: any) => this.handleError(error)
      });
  }

  private handleSuccess(): void {
    this.isLoading = false;

    const title = this.data?.text?.onResponse?.successTitle
      ? this.data.text.onResponse.successTitle
      : "SEND_MESSAGE_DIALOG.SUCCESS.TITLE";

    const message = this.data?.text?.onResponse?.successMessage
      ? this.data.text.onResponse.successMessage
      : "SEND_MESSAGE_DIALOG.SUCCESS.REQUEST";

    this.showRequestResultModal(title, message);
  }

  private handleError(error: any): void {
    this.isLoading = false;
    this.errors = error?.error;

    if (this.errors?.send_by_email?.length > 0) {
      this.showRequestResultModal("SEND_MESSAGE_DIALOG.ERROR.TITLE", this.errors.send_by_email[0]);
    } else if (this.errors?.statement?.length > 0) {
      this.showRequestResultModal("SEND_MESSAGE_DIALOG.ERROR.TITLE", this.errors.statement[0]);
    } else if (!this.errors.steuernummer && !this.errors.identifikationsnummer && !this.errors.tax_authority) {
      this.isRequestError = true;
    }

    console.error(error);
  }

  public showRequestResultModal(title: string, message: string): void {
    this.closeModal();
    const dialogRef = this.dialog.open(ErrorDialogComponent);
    Object.assign(dialogRef.componentInstance, { title, message, understandButton: true });
  }

  public checkIfFileIsValidated(): boolean {
    return this.attachmentsList.length > 0 || this.sendedAttachments.length > 0
      ? this.attachmentsList.every((attachment) => attachment.isValidated)
      : [MESSAGE_DOCUMENT_TYPE.APPEAL, MESSAGE_DOCUMENT_TYPE.MESSAGE].includes(this.messageType);
  }

  public checkIfSendMessageIsDisabled(): boolean {
    const switchCondition =
      this.attachmentSubType === ATTACHMENT_SUBTYPE.VOLMACHT ? this.attachmentSubType : this.messageType;

    switch (switchCondition) {
      case MESSAGE_DOCUMENT_TYPE.APPEAL:
        return !(
          this.text &&
          this.localDecisionDate &&
          this.localSteuernummer &&
          this.localTaxAuthority &&
          this.checkIfFileIsValidated()
        );
      case MESSAGE_DOCUMENT_TYPE.ATTACHMENT:
        if (this.sendByEmail) {
          return !this.checkIfFileIsValidated();
        } else {
          return !(
            this.localIdNumber &&
            this.localSteuernummer &&
            this.localTaxAuthority &&
            this.checkIfFileIsValidated()
          );
        }
      case MESSAGE_DOCUMENT_TYPE.MESSAGE:
        return !(
          this.text &&
          this.localIdNumber &&
          this.localSteuernummer &&
          this.localTaxAuthority &&
          this.title &&
          this.checkIfFileIsValidated()
        );
      case ATTACHMENT_SUBTYPE.VOLMACHT:
        return !this.checkIfFileIsValidated();
      default:
        throw new Error(`checkIfSendMessageIsDisabled ERROR: ${this.messageType} does not exist in MessageDataTypes`);
    }
  }

  public getTextLength(text: string): number {
    return text.length;
  }

  public checkIfTextIsTooLong(text: string, maxLength: number): boolean {
    return text.length > maxLength;
  }

  public isValidated(dataForValidation: MessageDataTypes): boolean {
    const validationFieldKeys: string[] = [];

    if (this.messageType === MESSAGE_DOCUMENT_TYPE.MESSAGE) {
      validationFieldKeys.push(VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG.topic);
    }
    if ([MESSAGE_DOCUMENT_TYPE.MESSAGE, MESSAGE_DOCUMENT_TYPE.APPEAL].includes(this.messageType)) {
      validationFieldKeys.push(VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG.message);
    }
    if (dataForValidation.steuernummer) {
      validationFieldKeys.push(VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG.tax_number);
    }
    if (dataForValidation.tax_authority) {
      validationFieldKeys.push(VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG.tax_authority);
    }
    if (dataForValidation.identifikationsnummer) {
      validationFieldKeys.push(VALIDATOR_FIELD_NAMES_SEND_MESSAGE_DIALOG.id_number);
    }

    this.epValidator = this.validateFieldV2Service.validateFieldAndGetMessageV2(dataForValidation, validationFieldKeys);

    return this.epValidator.length === 0;
  }

  public makePayload(): MessageDataTypes {
    let payload: MessageDataTypes;

    switch (this.messageType) {
      case MESSAGE_DOCUMENT_TYPE.APPEAL:
        payload = this.makeAppealPayload();
        break;
      case MESSAGE_DOCUMENT_TYPE.MESSAGE:
        payload = this.makeMessagePayload();
        break;
      case MESSAGE_DOCUMENT_TYPE.ATTACHMENT:
        payload = this.makeAttachmentPayload();
        break;
      default:
        throw new Error(`makePayload ERROR: ${this.messageType} does not exist in MessageDataTypes`);
    }

    if (this.localSteuernummer !== this.statementData.steuernummer && this.requireSteuernummer()) {
      payload.steuernummer = this.localSteuernummer;
    }
    if (this.localTaxAuthority !== this.statementData.taxAuthority && this.requireTaxAuthority()) {
      payload.tax_authority = this.localTaxAuthority;
    }
    if (this.localIdNumber !== this.statementData.identifikationsnummer && this.requireIdNumber()) {
      payload.identifikationsnummer = this.localIdNumber;
    }

    payload.files = this.attachmentsList.map((attachment) => attachment.file);

    return payload;
  }

  public makeAppealPayload(): MessageDataTypes {
    return {
      type: MESSAGE_DOCUMENT_TYPE.APPEAL,
      message: this.text,
      decision_date: this.localDecisionDate,
      stay_of_enforcement: this.requireEnforcementValue
    };
  }

  public makeMessagePayload(): MessageDataTypes {
    return {
      type: MESSAGE_DOCUMENT_TYPE.MESSAGE,
      topic: this.title,
      message: this.text
    };
  }

  public makeAttachmentPayload(): MessageDataTypes {
    return {
      type: MESSAGE_DOCUMENT_TYPE.ATTACHMENT,
      subtype: this.data.subtype,
      send_by_email: this.sendByEmail
    };
  }

  // Warunki wyświetlania pól
  public requireTitle(): boolean {
    return this.messageType === MESSAGE_DOCUMENT_TYPE.MESSAGE;
  }

  public requireMessage(): boolean {
    return [MESSAGE_DOCUMENT_TYPE.MESSAGE, MESSAGE_DOCUMENT_TYPE.APPEAL].includes(this.messageType);
  }

  public requireEnforcement(): boolean {
    return this.messageType === MESSAGE_DOCUMENT_TYPE.APPEAL;
  }

  public requireDecisionDate(): boolean {
    return this.messageType === MESSAGE_DOCUMENT_TYPE.APPEAL;
  }

  public requireIdNumber(): boolean {
    if (this.messageType === MESSAGE_DOCUMENT_TYPE.ATTACHMENT && this.attachmentSubType === ATTACHMENT_SUBTYPE.BELEGE) {
      return !this.sendByEmail;
    } else if (this.attachmentSubType === ATTACHMENT_SUBTYPE.VOLMACHT) {
      return false;
    } else {
      return [MESSAGE_DOCUMENT_TYPE.MESSAGE, MESSAGE_DOCUMENT_TYPE.ATTACHMENT].includes(this.messageType);
    }
  }

  public requireSteuernummer(): boolean {
    if (this.messageType === MESSAGE_DOCUMENT_TYPE.ATTACHMENT && this.attachmentSubType === ATTACHMENT_SUBTYPE.BELEGE) {
      return !this.sendByEmail;
    } else if (this.attachmentSubType === ATTACHMENT_SUBTYPE.VOLMACHT) {
      return false;
    }

    return true;
  }

  public requireTaxAuthority(): boolean {
    if (this.messageType === MESSAGE_DOCUMENT_TYPE.ATTACHMENT && this.attachmentSubType === ATTACHMENT_SUBTYPE.BELEGE) {
      return !this.sendByEmail;
    } else if (this.attachmentSubType === ATTACHMENT_SUBTYPE.VOLMACHT) {
      return false;
    }

    return true;
  }

  public requireSendByEmail(): boolean {
    return (
      this.messageType === MESSAGE_DOCUMENT_TYPE.ATTACHMENT && this.attachmentSubType === ATTACHMENT_SUBTYPE.BELEGE
    );
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public getMaxFilesNumber(): number {
    return 20;
  }
}
