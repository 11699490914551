import {Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ImpressumComponent} from '../../core/impressum/impressum.component';
import { TermsFilesService } from 'app/core/services/terms-files.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentLang: string;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private termsFilesService: TermsFilesService,
  ) { }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
  }

  openPrivacyPolicy() {
    this.termsFilesService.openPrivacyPolicy();
  }

  openTermsAndConditions() {
    this.termsFilesService.openTermsAndConditions();
  }

  openImpressum() {
    this.dialog.open(ImpressumComponent);
  }

  getUrl() {
    return `https://taxando.de/impressum/?lang=${this.currentLang}`;
  }
}
