import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { SubscribingComponent } from '../subscribing-component';
import { SideNavService } from 'app/core/side-nav.service';
import { Statement } from 'app/statements';
import {Store} from '@ngrx/store';
import { WorkflowControllerService } from 'app/core/workflow/workflow-controller/workflow-controller.service';
import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';

@Component({
  selector: 'app-workflow-steps',
  templateUrl: './workflow-steps.component.html',
  styleUrls: ['./workflow-steps.component.scss']
})
export class WorkflowStepsComponent extends SubscribingComponent implements OnInit {
  @Input() statement: Statement;
  @Input() workFlowController: WorkflowControllerService;
  @Input() isLogged = true;
  @Input() isBusiness = false;
  @Input() isMobileCondensed: boolean;

  @Output() stepChange = new EventEmitter();

  constructor(
    private router: Router,
    private store: Store<any>,
    private sideNavService: SideNavService,
    private activatedRoute: ActivatedRoute,
    private _workFlowController: WorkflowControllerService,
  ) {
    super();
  }

  ngOnInit() {
    this.getWorkflowController();
  }

  public goToStepWorkflow(workflowStep: WorkflowStepsBaseService): void {
    if (workflowStep.canGo && !workflowStep.blockedDefaultWorkflow) {
      this.stepChange.emit();
      console.log(workflowStep.fullUrl);
      
      
      this.router.navigate([workflowStep.fullUrl]);
    }
  }

  private getWorkflowController(): void {
    if (!this.workFlowController) {
      this.workFlowController = this._workFlowController;
      this.workFlowController.init(this.statement || Statement.empty, true);
    }
  }
}
