<button
  class="button button-primary {{ extraClasses }}"
  [class.is-loading]="isLoading"
  [class.button-sm]="isSmall"
  [class.button-sm-mobile]="isSmallMobile"
  mat-button
  [disabled]="isDisabled"
  (click)="clickEvent($event)"
  [type]="buttonType"
>
  {{ title | translate }}
  <ng-content></ng-content>
</button>
