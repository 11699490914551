import {
  PersonalInfoChildAdultComponentPath
} from 'app/core/workflow/page-path/statement-page-path/personal-info-child-adult-component-path';
import { WorkflowStepsBaseService } from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { PersonalInfoChildComponentPath } from 'app/core/workflow/page-path/statement-page-path/personal-info-child-component.path';

export class WorkflowPersonalInfoChildAdultService extends WorkflowStepsBaseService {
  public get isRequired(): boolean {
    const currentChild = this.getChild;

    if (currentChild) {
      return currentChild.isAdult(this.statement.fullYear);
    }

    return false;
  }

  public get isFakeStep(): boolean {
    return !this.isRequired;
  }

  public get skipWhenGoBack(): boolean {
    return true;
  }

  public get isDone(): boolean {
    return this.statement && this.hasChildren && this.statement.children.length
      && this.statement.stagePersonalInfoChildren;
  }

  public get isVisible(): boolean {
    return false;
  }

  public get checkCanGo(): boolean {
    if (!this.blockedDefaultWorkflow && this.isRequired) {
      const currentChild = this.getChild;

      if (currentChild) {
        return !!currentChild.firstName && !!currentChild.lastName && !!currentChild.birthDate;
      }
    }
    return false;
  }

  public get urlRegex(): RegExp {
    return PersonalInfoChildAdultComponentPath.regex(this.subStepNumber);
  }

  public get url(): string {
    return PersonalInfoChildAdultComponentPath.url(this.getStatementId, this.subStepNumber);
  }

  public get goBackUrl(): string {
    return PersonalInfoChildComponentPath.url(this.getStatementId, this.subStepNumber);
  }
}
