export class AndroidAppHelpers {
  private static androidJsObjectKey = 'AndroidCommunication';
  private static androidAppRatedEventKey = 'androidAppRatedEvent';
  private static rateAppEventKey = 'RateApp';

  public static callRateApp(): void {
    AndroidAppHelpers.sendDataToAndroid(this.rateAppEventKey);
  }

  public static sendDataToAndroid(data: string): void {
    if (window[this.androidJsObjectKey]) {
      window[this.androidJsObjectKey].shareData(data);
    }
  }

  public static addEventAndroidAppRated(callback: () => any = () => {}): void {
    // window[this.androidAppRatedEventKey] = new Event(this.androidAppRatedEventKey);
    // window.addEventListener(this.androidAppRatedEventKey, callback, false);
  }

  public static removeEventAndroidAppRated(callback: () => any = () => {}): void {
    // window.removeEventListener(this.androidAppRatedEventKey, callback, false);
  }
}
