import {Directive, ElementRef, AfterViewInit, Input, ViewContainerRef, QueryList} from '@angular/core';
import {AccordionComponent} from './accordion/accordion.component';
import { ErrorMessage } from 'app/core/form-validator/validatorInterface';
import { DomElementHelper } from 'app/core/helpers/dom-element.helper';

/**
 * Scrolls form to the first error from the top (field errors first and then non field errors)
 * and opens all accordions that contains errors.
 *
 * @example
 *  <app-personal-info-edit [errors]="errors" [appShowFormError]></app-personal-info-edit>
 *
 * Errors have to be passed as prop to host component.
 * If host component contains accordions it should define accordions attribute:
 *  @ViewChildren(AccordionComponent) accordions: QueryList<AccordionComponent>;
 *
 * Without it opening accordions on errors will not work.
 *
 * Props:
 *  appShowFormError - true/false - turns on/off whole functionality (default true),
 *  errors - object with form errors (required),
 *  openAccordions - true/false - turns on/off opening accordions on error (default true),
 *  scrollToError - true/false - turns on/off scrolling to error (default true),
 *  additionalAccordions - QueryList of accordions that lives beyond host component (default empty).
 */
@Directive({
  selector: '[appShowFormError]'
})
export class ShowFormErrorDirective implements AfterViewInit {
  readonly FIELD_ERROR_SELECTOR = '.form-select__error, .form-input__error, app-validator-error-message .singleErrorMessage, .alert-box:not(.alert-box--info):not(.alert-box--empty)';
  readonly ACCORDION_ERROR_SELECTOR = this.FIELD_ERROR_SELECTOR;

  @Input() appShowFormError = true;
  @Input() openAccordions = false;
  @Input() scrollToError = true;
  @Input() additionalAccordions: QueryList<AccordionComponent> = new QueryList<AccordionComponent>();
  get errors(): any {
    return this._errors;
  }
  @Input() set errors(_errors: any) {
    this._errors = _errors;
    this.onErrorsChange();
  }
  get epValidator(): any {
    return this._epValidator;
  }
  @Input() set epValidator(_epValidator: ErrorMessage[]) {
    this._epValidator = _epValidator;
    this.onErrorsChange();
  }
  private hostComponent: any;
  private _errors: any = [];
  private _epValidator: ErrorMessage[] = [];

  constructor(private element: ElementRef, private viewContainerRef: ViewContainerRef) { }

  ngAfterViewInit() {
    if (this.viewContainerRef && this.viewContainerRef['_data'] && this.viewContainerRef['_data'].componentView) {
      this.hostComponent = this.viewContainerRef['_data'].componentView.component;
    }
  }

  onErrorsChange(): void {
    if (!this.appShowFormError || (!this.errors && !this.epValidator?.length)) {
      return;
    }

    if (this.openAccordions) {
      const timeout = setTimeout(() => {
        this.handleAccordions();
        clearTimeout(timeout);
      }, 25);
    }

    if (this.scrollToError) {
      const timeout2 = setTimeout(() => {
        this.handleScrollToError();
        clearTimeout(timeout2);
      }, 50);
    }
  }

  private handleAccordions(): void {
    if (this.hostComponent && this.hostComponent.accordions) {
      this.hostComponent.accordions.forEach(this.openAccordion.bind(this));
    }
    if (this.additionalAccordions) {
      this.additionalAccordions.forEach(this.openAccordion.bind(this));
    }
  }

  private openAccordion(accordion: AccordionComponent): void {
    if (!accordion || !accordion.element || !accordion.element.nativeElement) {
      return;
    }

    if (accordion.element.nativeElement.querySelectorAll(this.ACCORDION_ERROR_SELECTOR).length) {
      accordion.open();
    }
  }

  private handleScrollToError(): void {
    const fieldErrors = this.element.nativeElement.querySelector(this.FIELD_ERROR_SELECTOR);
    if (fieldErrors) {
      if (fieldErrors.parentElement) {
        const container = fieldErrors.parentElement.closest('mat-dialog-container') || window;

        container.scrollTo({ top: DomElementHelper.getCoordinates(fieldErrors.parentElement).top - 100, behavior: 'smooth' });
      }
      return;
    }
  }
}
