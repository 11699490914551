import {WorkflowStepsBaseService} from 'app/core/workflow/workflow-steps/workflow-steps-base.service';
import { PersonalInfoChildComponentPath } from 'app/core/workflow/page-path/statement-page-path/personal-info-child-component.path';
import {
  PersonalInfoChildSummaryComponentPath
} from 'app/core/workflow/page-path/statement-page-path/personal-info-child-summary-component-path';
import {
  PersonalInfoChildAdultComponentPath
} from 'app/core/workflow/page-path/statement-page-path/personal-info-child-adult-component-path';

export class WorkflowPersonalInfoChildService extends WorkflowStepsBaseService {
  public get isRequired(): boolean {
    return this.hasChildren;
  }

  public get isFakeStep(): boolean {
    return !this.isDone;
  }

  public get isDone(): boolean {
    let validChildren = false;

    if (this.statement && this.statement.children.length) {
      validChildren = !!this.statement.children[0].isFilledBasicInfo;
    }

    return this.statement && this.hasChildren
      && this.statement.stagePersonalInfoChildren && validChildren;
  }

  public get isActiveOnStepsBar(): boolean {
    const locationHref = window.location.href;

    return locationHref.search(PersonalInfoChildAdultComponentPath.regex()) !== -1
      || locationHref.search(PersonalInfoChildSummaryComponentPath.regex()) !== -1
      || locationHref.search(PersonalInfoChildComponentPath.regex()) !== -1;
  }

  public get isVisible(): boolean {
    return this.isRequired;
  }

  public get title(): string {
    return 'ASIDE_BAR.CHILDREN';
  }

  public get checkCanGo(): boolean {
    return !this.blockedDefaultWorkflow && this.isRequired;
  }

  public get urlRegex(): RegExp {
    return PersonalInfoChildComponentPath.regex(0);
  }

  public get url(): string {
    if (this.isDone) {
      return PersonalInfoChildSummaryComponentPath.url(this.getStatementId);
    }

    return PersonalInfoChildComponentPath.url(this.getStatementId, this.getFirstChildId);
  }

  public get isActive(): boolean {
    return false;
  }

  public get stepClass(): string {
    let classes = this.classLeftLineNone;

    if (this.statement && this.statement.children.length > 1) {
      classes += this.classHasSubs;
    }

    return classes;
  }

  public get goBackUrl(): string {
    return PersonalInfoChildSummaryComponentPath.url(this.getStatementId);
  }

  public get skipWhenGoBack(): boolean {
    return true;
  }
}
