export class RateAppConfig {
  public static readonly timeoutCallRateAppAfterLogin = 2000;

  public static trustPilotUrl(stars?: number): string {
    if (stars && stars > 0 && stars < 6) {
      return `https://www.trustpilot.com/evaluate/taxando.de?stars=${stars}`;
    }
    return 'https://www.trustpilot.com/review/taxando.de';
  }

  public static googlePlayUrl(): string {
    return 'https://play.google.com/store/apps/details?id=pl.dlabs.taxando.android';
  }

  public static iosUrl(): string {
    return 'https://apps.apple.com/pl/app/taxando-digital-tax-advisor/id1445008039';
  }
}
