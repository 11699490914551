import { DeductionSkipComponentPath } from 'app/core/workflow/page-path/statement-page-path/deduction-skip-component.path';
import { WorkflowDeductionBaseService } from 'app/core/workflow/workflow-steps/deduction/workflow-deduction-base.service';

export class WorkflowDeductionSkipService extends WorkflowDeductionBaseService {
  public get isDone(): boolean {
    return this.statement &&
      (
        this.isDeductionOptionSkip
        || (
          this.isBusiness
          && this.isDeductionOptionAdd
          && this.statement.stageDeductionInfo
        ) || (
          !this.isBusiness
          && (
            this.isDeductionOptionAdd
            || this.statement.stageDeductionInfo
          )
        )
      );
  }

  public get isVisible(): boolean {
    return true;
  }

  public get isRequired(): boolean {
    return !this.isBusiness;
  }

  public get isFakeStep(): boolean {
    return this.isBusiness;
  }

  public get title(): string {
    return 'ASIDE_BAR.DEDUCTION_SECTION';
  }

  public get stepClass(): string {
    let classes = this.classMarginTop + this.classSplit;

    if (!this.hasChildren && !this.hasSpouseAndFilledTogether) {
      return classes;
    }

    if (this.isDeductionOptionAdd || this.isBusiness) {
      classes += this.classHasSubs;
    }

    return classes;
  }

  public get isActiveOnStepsBar(): boolean {
    return this.isDeductionRoute;
  }

  public get checkCanGo(): boolean {
    if (!this.blockedDefaultWorkflow && this.statement) {
      if (this.isBusiness) {
        if (this.hasChildren) {
          return this.statement.stagePersonalInfoChildren;
        }

        if (this.hasSpouseAndFilledTogether) {
          return this.isSpouseStageEmployerDone || this.statement.spouse.stageAdditionalIncome;
        }

        return this.statement.stagePersonalInfo;
      }

      // B2C
      if (this.statement.stagePackageSelection) {
        if (this.statement.isPremiumPackage) {
          return this.statement.hasVerifiedPhoneNumber;
        }

        return true;
      }
    }

    return false;
  }

  public get urlRegex(): RegExp {
    return DeductionSkipComponentPath.regex();
  }

  public get url(): string {
    return DeductionSkipComponentPath.url(this.getStatementId);
  }

  public get isSubStep(): boolean {
    return false;
  }

  public get skipWhenGoBack(): boolean {
    return (
      this.statement
      && this.selectsData
      && !this.statement.checkIncomeSummaryUnderTaxBracketForDeductions(this.selectsData)
    ) || (this.user && this.user.isBusiness);
  }
}
