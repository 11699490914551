
import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscribingComponent } from '../../common/subscribing-component';
import { Store } from '@ngrx/store';
import { getUserType, State } from '../../reducers';
import { Employer } from '../employer';
import { B2bTaxCardComponentPath } from 'app/core/workflow/page-path/statement-page-path/b2b.tax-card-component.path';


@Component({
  selector: 'app-employer-field-summary',
  templateUrl: './employer-field-summary.component.html',
  styleUrls: ['./employer-field-summary.component.scss']
})
export class EmployerFieldSummaryComponent extends SubscribingComponent implements OnInit {
  @Input() employers: Employer[];
  @Input() fieldSymbol: string;
  @Input() fieldName: string;
  @Input() spouseId = null;
  statementId = null;
  batchId = null;
  isPopupOpen = false;
  totalAmount: string;
  public isBusiness: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private store: Store<State>) {
    super();

    this.route.params.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.statementId = parseInt(params['statementId'], 10);
        this.batchId = parseInt(params['batchId'], 10);
      });
  }

  ngOnInit() {
    this.countTotalAmount();

    this.store.select(getUserType).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((userType: string) => {
        if (userType) {
          this.isBusiness = userType === 'business';
        }
      });
  }

  hidePopup() {
    this.isPopupOpen = false;
  }

  openPopup() {
    this.isPopupOpen = true;
  }

  countTotalAmount() {
    let totalAmount = 0;
    this.employers.forEach(employer => {
      totalAmount += parseFloat(employer[this.fieldSymbol]);
    });
    this.totalAmount = totalAmount.toFixed(2);
  }

  editEmployer(employerId) {
    const step = this.spouseId ? `spouse/${this.spouseId}` : '';
    const batchPart = this.batchId ? `/batch/${this.batchId}` : '';
    let url = '';
    if (this.isBusiness) {
      url = `/new/statement/${this.statementId}/${step}/employer/${employerId}/summary/1${batchPart}`;
    } else {
      url = `/new/statement/${this.statementId}/${step}/employerbc/${employerId}/summary/1${batchPart}`;
    }
    this.router.navigate([url]);
  }
}
