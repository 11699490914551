
import {takeUntil} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {TranslateService} from '@ngx-translate/core';

import {UserDataService} from '../../users/user-data.service';
import {SubscribingComponent} from '../../common';
import {AuthService} from '../../users/auth.service';

@Component({
  selector: 'app-gdpr-license-agreement',
  templateUrl: './gdpr-license-agreement.component.html',
  styleUrls: ['./gdpr-license-agreement.component.scss']
})
export class GdprLicenseAgreementComponent extends SubscribingComponent implements OnInit {

  language = 'EN';
  LANGUAGES = {
    'pl': 'PL',
    'en': 'EN',
    'de': 'DE'
  };

  constructor(private dialogRef: MatDialogRef<GdprLicenseAgreementComponent>,
              private userDataService: UserDataService,
              private authService: AuthService,
              private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.language = this.LANGUAGES[this.translate.currentLang] || this.language;
  }

  decline() {
    this.authService.logout();
    this.dialogRef.close();
  }

  accept() {
    this.userDataService.acceptGdprLicenseAgreement().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.dialogRef.close());
  }

}
