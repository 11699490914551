<button
  class="button button-link {{ extraClasses }}"
  [class.button-link-sm]="isSmall"
  [class.is-loading]="isLoading"
  [disabled]="isDisabled"
  (click)="clickEvent($event)"
  type="button"
>
  {{ title | translate }}
  <ng-content></ng-content>
</button>
