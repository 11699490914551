import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionWithRatingBaseComponent } from 'app/shared/modules/rating/components/section-with-rating-base/section-with-rating-base.component';
import {
  SectionWithTrustpilotComponent
} from 'app/shared/modules/rating/components/section-with-rating-trustpilot/section-with-trustpilot.component';
import {
  SectionWithGoogleplayComponent
} from 'app/shared/modules/rating/components/section-with-rating-googleplay/section-with-googleplay.component';
import {
  SectionWithApplestoreComponent
} from 'app/shared/modules/rating/components/section-with-rating-applestore/section-with-applestore.component';
import { StarterBoardSectionComponent } from 'app/shared/modules/rating/components/starter-board-section/starter-board-section.component';

@NgModule({
  declarations: [
    SectionWithRatingBaseComponent,
    SectionWithTrustpilotComponent,
    SectionWithGoogleplayComponent,
    SectionWithApplestoreComponent,
    StarterBoardSectionComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SectionWithRatingBaseComponent,
    SectionWithTrustpilotComponent,
    SectionWithGoogleplayComponent,
    SectionWithApplestoreComponent,
    StarterBoardSectionComponent,
  ]
})
export class RatingModule {
}
