import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/users';
import { SubscribingComponent } from 'app/common';
import { takeUntil, finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LanguageSelectService } from 'app/core/services/language-select.service';
import { PathParamKeys } from 'app/core/workflow/page-path/path-param-keys';

interface ActivateEmailErrors {
  non_field_errors?: string[];
}

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})
export class ActivationComponent extends SubscribingComponent implements OnInit {
  public isSuccess = false;
  public isLoading = false;
  public errors: ActivateEmailErrors;

  constructor(
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly languageSelectService: LanguageSelectService,
  ) {
    super();
   }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (params) => {
          const activationKey = params[PathParamKeys.activationKey];

          if (activationKey) {
            this.verify(activationKey);
          }
        }
      });
  }

  onUpdateUserDataSuccess() {
    this.isLoading = false;
    this.isSuccess = true;
  }

  clearErrors() {
    this.errors = {};
  }

  private verify(activationKey: string): void {
    this.isLoading = true;
    this.authService.verifyEmail(activationKey).pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => this.isLoading = false)
    ).subscribe({
      next: (response: Response) => {
        const language = response['user_language'];
        if (!this.languageSelectService.isLanguageSelected() && !!language) {
          this.languageSelectService.useLanguage(language, {setIsSelected: true});
        }

        this.onUpdateUserDataSuccess();
      },
      error: (error: any) => {
        const language = error.error['user_language'];

        if (!this.languageSelectService.isLanguageSelected() && !!language) {
          this.languageSelectService.useLanguage(language, {setIsSelected: true, forceSave: true});

          this.verify(activationKey);
        }

        this.errors = error.error.error;
      }
    });
  }
}
