import {Component, AfterViewInit, Input, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FirebaseEventsService } from 'app/common/firebase-events.service';

export interface DialogData {
  possibleTaxReturn: number;
}

@Component({
  selector: 'app-upsell-dialog',
  templateUrl: './upsell-dialog.component.html',
  styleUrls: ['./upsell-dialog.component.scss']
})
export class UpsellDialogComponent implements AfterViewInit {
  @Input() possibleTaxReturn = 0;

  constructor(
    private dialogRef: MatDialogRef<UpsellDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private firebaseEventsService: FirebaseEventsService
  ) { this.possibleTaxReturn = data.possibleTaxReturn; }



  ngAfterViewInit() {
    // const labels = document.getElementsByClassName('dialog-box__section__label');
    // this.setLabelsWidth(labels);
  }

  confirm() {
    this.firebaseEventsService.logEvent('click_upgrade_standard', null)
    this.dialogRef.close(true);
  }

  private setLabelsWidth(labels) {
    const widths = [];

    for (const label of labels) {
      widths.push(label.offsetWidth);
    }

    widths.sort((a, b) => b - a);

    for (const label of labels) {
      label.style.width = `${widths[0]}px`;
    }
  }
}
