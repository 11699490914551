<div class="mb-1">
  <app-form-select
    [errors]="additionalIncomeService.getError('pension_from', index)"
    [label]="'ADDITIONAL_INCOME.PENSION_GROUP.INPUT.FROM.LABEL'"
    [placeholder]="'ADDITIONAL_INCOME.PENSION_GROUP.INPUT.FROM.PLACEHOLDER'"
    [(model)]="pensionIncome.from"
    [choices]="additionalIncomeService.pensionNamesList()"
    [valueName]="'id'"
    [optionName]="'name'"
  ></app-form-select>
  <app-validator-error-message
    [epValidator]="additionalIncomeService.epValidator"
    [index]="index"
    [fieldName]="additionalIncomeService.VALIDATOR_KEYS.pension_from"
  >
  </app-validator-error-message>
</div>
<div class="mb-1">
  <app-form-input
    [errors]="additionalIncomeService.getError('pension_amount', index)"
    [label]="'ADDITIONAL_INCOME.PENSION_GROUP.INPUT.AMOUNT.LABEL'"
    [placeholder]="'ADDITIONAL_INCOME.PENSION_GROUP.INPUT.AMOUNT.PLACEHOLDER'"
    [(model)]="pensionIncome.amount"
    [isCurrency]="true"
  ></app-form-input>
  <app-validator-error-message
    [epValidator]="additionalIncomeService.epValidator"
    [index]="index"
    [fieldName]="additionalIncomeService.VALIDATOR_KEYS.pension_amount"
  >
  </app-validator-error-message>
</div>
<div class="mb-1">
  <app-form-date-input
    [label]="'ADDITIONAL_INCOME.PENSION_GROUP.INPUT.START.LABEL'"
    [placeholder]="'ADDITIONAL_INCOME.PENSION_GROUP.INPUT.START.PLACEHOLDER'"
    [(model)]="pensionIncome.start"
    (modelChange)="pensionIncome.incomeStartChange(additionalIncomeService.mainStatement)"
  ></app-form-date-input>
  <app-validator-error-message
    [epValidator]="additionalIncomeService.epValidator"
    [index]="index"
    [fieldName]="additionalIncomeService.VALIDATOR_KEYS.pension_start"
  ></app-validator-error-message>
</div>
<ng-container *ngIf="pensionIncome.showAdjustment">
  <div class="mb-1">
    <app-form-input
      [errors]="additionalIncomeService.getError('pension_adjustment', index)"
      [label]="'ADDITIONAL_INCOME.PENSION_GROUP.INPUT.ADJUSTMENT.LABEL'"
      [placeholder]="'ADDITIONAL_INCOME.PENSION_GROUP.INPUT.ADJUSTMENT.PLACEHOLDER'"
      [(model)]="pensionIncome.adjustment"
      [isCurrency]="true"
    ></app-form-input>
    <app-validator-error-message
      [epValidator]="additionalIncomeService.epValidator"
      [index]="index"
      [fieldName]="additionalIncomeService.VALIDATOR_KEYS.pension_adjustment"
    >
    </app-validator-error-message>
  </div>
</ng-container>
