<button
  class="button button-add {{ extraClasses }}"
  [class.is-loading]="isLoading"
  mat-button
  [disabled]="isDisabled"
  (click)="clickEvent($event)"
  [type]="buttonType"
  [attr.tabIndex]="autoFocus === false ? -1 : undefined"
>
  <img src="assets/svg/add.svg" class="icon_add" alt="Add">
  {{ title | translate }}
</button>
