<div class="charges_summary">
  <div class="charges_summary__table">
    <div class="charges_summary__table-header">
      <div class="charges_summary__table-stage">{{ "CHARGES.DATE" | translate }}</div>
      <div class="charges_summary__table-stage">{{ "CHARGES.PRODUCT" | translate }}</div>
      <div class="charges_summary__table-stage charges_summary__table-stage--center">{{
        "CHARGES.NO_OF_CLIENTS" | translate
      }}</div>
      <div class="charges_summary__table-stage charges_summary__table-stage--right">{{
        "CHARGES.TOTAL_AMOUNT" | translate
      }}</div>
    </div>
    <div class="charges_summary__table-body">
      <div *ngIf="statements && statements.length">
        <div class="charges_summary__table-row">
          <div class="charges_summary__table-stage">{{ getTodayDate() }}</div>
          <div class="charges_summary__table-stage">Taxando Solo</div>
          <div class="charges_summary__table-stage charges_summary__table-stage--center">{{ statements.length }}</div>
          <div class="charges_summary__table-stage charges_summary__table-stage--right"
            >{{ tokensRequiredSum() }} <span>{{ "CHARGES.TOKENS" | translate }}</span></div
          >
        </div>
      </div>
    </div>
  </div>
</div>
