export const VALIDATOR_FIELD_NAMES_CHILD_DEDUCTIONS = {
  paid_school_names: 'paid_school_names',
  paid_school_fees: 'paid_school_fees',
  applicant_participation_in_school_costs: 'applicant_participation_in_school_costs',
  childcare_name_and_address: 'childcare_name_and_address',
  childcare_types: 'childcare_types',
  childcare_dates_lower: 'childcare_dates_lower',
  childcare_dates_upper: 'childcare_dates_upper',
  childcare_costs: 'childcare_costs',
  applicant_participation_in_childcare_costs: 'applicant_participation_in_childcare_costs',
  childcare_amount_of_funding: 'childcare_amount_of_funding',
  parents_didnt_live_with_child_dates_lower: 'parents_didnt_live_with_child_dates_lower',
  parents_didnt_live_with_child_dates_upper: 'parents_didnt_live_with_child_dates_upper',
  child_lived_with_applicant_dates_lower: 'child_lived_with_applicant_dates_lower',
  child_lived_with_applicant_dates_upper: 'child_lived_with_applicant_dates_upper',
  child_lived_with_second_parent_dates_lower: 'child_lived_with_second_parent_dates_lower',
  child_lived_with_second_parent_dates_upper: 'child_lived_with_second_parent_dates_upper',
  payments_for_childcare_dates_lower: 'payments_for_childcare_dates_lower',
  payments_for_childcare_dates_upper: 'payments_for_childcare_dates_upper',
  not_registered_dates_lower: 'not_registered_dates_lower',
  not_registered_dates_upper: 'not_registered_dates_upper',
  registered_in_applicants_flat_dates_lower: 'registered_in_applicants_flat_dates_lower',
  registered_in_applicants_flat_dates_upper: 'registered_in_applicants_flat_dates_upper',
  kindergeld_dates_lower: 'kindergeld_dates_lower',
  kindergeld_dates_upper: 'kindergeld_dates_upper',
  other_adults_registered_dates_lower: 'other_adults_registered_dates_lower',
  other_adults_registered_dates_upper: 'other_adults_registered_dates_upper',
  common_household_dates_lower: 'common_household_dates_lower',
  common_household_dates_upper: 'common_household_dates_upper',
  common_household_person_name: 'common_household_person_name',
  common_household_person_propinquity: 'common_household_person_propinquity',
  common_household_person_profession: 'common_household_person_profession',
  studied_outside_place_of_residence_dates_lower: 'studied_outside_place_of_residence_dates_lower',
  studied_outside_place_of_residence_dates_upper: 'studied_outside_place_of_residence_dates_upper',
  place_of_residence: 'place_of_residence',
  participation_in_studies_allowance: 'participation_in_studies_allowance',
  disability_level: 'disability_level',
  participation_in_disability_allowance: 'participation_in_disability_allowance',
};
